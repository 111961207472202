import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountIdpsState, getSocialLoginSettings } from '../redux/account-idps.slice';

export const useSocialLogin = () => {
    const socialLoginAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.socialSetting);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSocialLoginSettings());
    }, [dispatch]);

    return {
        socialLoginAppData,
    };
};

export default useSocialLogin;
