import styled from 'styled-components';

export const TextFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
`;

export const FieldLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #2f426c;
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export const TextFieldDivider = styled.div`
    margin: 10px 0;
`;

export const IconContainer = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    padding: 1.771px 1.352px 1.848px 1.315px;
    justify-content: center;
    align-items: center;
`;

export const SmallVideoContainer = styled.video`
    position: absolute;
    z-index: 9999;
    display: flex;
    width: 700px;
    height: 450px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    border-radius: 12px;
    border: 2px solid #385feb;
    box-shadow: 0px 12px 16px 0px rgba(47, 61, 83, 0.11);
`;

export const SmallVideoContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 100%;
`;
