import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartners, PartnersState } from '../redux/partners.slice';

export const usePartners = () => {
    const partnersAppData = useSelector((state: { partnersState: PartnersState }) => state.partnersState.partners);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPartners());
    }, [dispatch]);

    return {
        partnersAppData,
    };
};

export default usePartners;
