import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    InputVariant,
    IconButton,
    Tooltip,
} from '@walkme/ui-core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Copy } from '@walkme/ui-icons';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { Dialog } from '@material-ui/core';
import { Close as CloseIcon } from '@walkme/ui-icons/large';

const StyledInput = styled(Input)`
    & input {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
    }
`;

const IconContainer = styled.div`
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        width: 500px;
        border-radius: 12px;
    }
`;

export interface UGenerateUserTempPasswordProps {
    onClose: () => void;
    isOpen: boolean;
    generatedPassword: string;
    email: string;
}

export function GenerateUserTempPassword({ isOpen, onClose, generatedPassword, email }: UGenerateUserTempPasswordProps) {
    const { t } = useTranslation('general');
    const [clickedCopy, setClickedCopy] = useState(false);

    return (
        <StyledDialog open={isOpen} onClose={onClose}>
            <IconContainer onClick={onClose}>
                <CloseIcon />
            </IconContainer>
            <DialogTitle>{t('users-and-roles-tab.users.edit-user-form.generated-temp-password-title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('users-and-roles-tab.users.edit-user-form.generated-temp-password-text', { email }),
                        }}
                    />
                </DialogContentText>
                <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <StyledInput
                        style={{ marginRight: '10px' }}
                        disabled
                        onChange={function noRefCheck() {}}
                        placeholder={generatedPassword}
                        variant={InputVariant.primary}
                        width={380}
                    />
                    <div onMouseLeave={() => setClickedCopy(false)}>
                        <Tooltip title={clickedCopy ? t('common.copied') : t('common.copy')} placement='top'>
                            <IconButton
                                active
                                variant='ghost'
                                onClick={() => {
                                    setClickedCopy(true);
                                    navigator.clipboard.writeText(generatedPassword);
                                }}>
                                <Copy />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined'>
                    {t('buttons.done')}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}
export default GenerateUserTempPassword;
