import React from 'react';
import { IdpSystem } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { StyledSystemHeaderContainer } from './system-style';
import { getSystemTag, shrinkText } from '../../../../common/helpers';
import { useTranslation } from 'react-i18next';

export interface SystemProps {
    system: IdpSystem;
}

export const System = ({ system }: SystemProps) => {
    const { t } = useTranslation('general');

    return (
        <StyledSystemHeaderContainer>
            <span className={'system-title'}>{shrinkText(system?.displayName, 17, '0')}</span>
            <div style={{ display: 'flex' }}>{getSystemTag(system.typeKey, t)}</div>
            <span className={'envsCount'}>
                ({system.envs.length} {t('idp-tab.table.system-list.environments')})
            </span>
        </StyledSystemHeaderContainer>
    );
};

export default System;
