import React, { Fragment, ReactElement, useCallback } from 'react';

import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { MenuItem, SelectProps } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';

import { StyledSelect } from '@walkme-admin-center/libs/shared-styles';
import styled from 'styled-components';

interface OptionObject {
    [key: string]: number | string | readonly string[];
}

const defaultRenderMenuItem = ({ value, label }: OptionObject): ReactElement<typeof MenuItem> => {
    const valueString = Array.isArray(value) ? value.join(':') : value.toString();
    return (
        <MenuItem key={valueString} value={value}>
            <ListItemText primary={label} />
        </MenuItem>
    );
};

type Props = SelectProps & {
    items: Array<OptionObject>;
    label?: ReactElement | string;
    renderMenuItem?: (item: OptionObject) => ReactElement<typeof MenuItem>;
    renderMenuItems?: (items: Array<OptionObject>) => Array<ReactElement>;
};

export const LabelContainerDiv = styled(InputLabel)`
    padding-bottom: 3px;
`;

export const Select = ({
    label,
    items,
    name,
    renderMenuItem = defaultRenderMenuItem,
    renderMenuItems,
    renderValue,
    ...rest
}: Props) => {
    const defaultRenderValue = useCallback(
        (selected) =>
            rest.multiple
                ? selected.map((item) => items.find(({ value }) => value === item).label).join(', ')
                : items.find(({ value }) => value === selected).label,
        [items, rest.multiple]
    );

    return (
        <Fragment>
            {label && <LabelContainerDiv htmlFor={name}>{label}</LabelContainerDiv>}
            <StyledSelect {...rest} renderValue={renderValue || defaultRenderValue}>
                {renderMenuItems ? renderMenuItems(items) : items.map(renderMenuItem)}
            </StyledSelect>
        </Fragment>
    );
};
