import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Environment } from 'wm-accounts-sdk';
import { AppThunkDispatch, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { restoreEnvironment } from '@walkme-admin-center/libs/state-management-extensions';
import Tooltip from '@walkme/wm-ui/components/WMTooltip/Tooltip';
import { Box } from '@material-ui/core';
import RestoreEnvironment from './components/restore-environment';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping } from 'apps/home/src/localization';
import { DateText, TimeText, StyledButton, StyledWMDataGrid } from './deleted-environments-page.styles';

export const DeletedEnvironmentsPage = ({ pageData, loadingData }) => {
    const { t, i18n } = useTranslation('general');
    const dispatch = useDispatch();
    const [showRestoreEnvironmentDialog, setShowRestoreEnvironmentDialog] = useState(false);
    const { usersAppData } = useUsers();

    const dateCreatedValueFormatter = (date) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DateText>{moment(date).format('ll')}</DateText>
                <TimeText>{moment(date).format('LT')}</TimeText>
            </div>
        );
    };

    const handleRestoreEnvironmentClick = (data: Environment) => {
        pageData.setSelectedEnvironment(data);
        setShowRestoreEnvironmentDialog(true);
    };

    const handleRestoreEnvironment = useCallback(
        (envId: number) => {
            (dispatch as AppThunkDispatch)(
                restoreEnvironment({
                    systemId: pageData.systemId,
                    envId,
                })
            );
        },
        [dispatch]
    );

    const deletedEnvironmentRows = useMemo(() => {
        if (!pageData.environments) {
            return [];
        }

        return pageData.environments.filter((env) => env.isDeleted);
    }, [pageData.environments]);

    const triggerRestoreEnvironmentDialog = () => {
        return (
            <RestoreEnvironment
                open={showRestoreEnvironmentDialog}
                onClose={() => setShowRestoreEnvironmentDialog(false)}
                setOpenSuccessSnackbar={pageData.setOpenSuccessSnackbar}
                setOpenErrorSnackbar={pageData.setOpenErrorSnackbar}
                restoreEnvironment={handleRestoreEnvironment}
                envId={pageData.selectedEnvironment.id}
            />
        );
    };

    function getActionColumn<T>() {
        return {
            colId: 'actions-column',
            pinned: 'right',
            suppressSizeToFit: true,
            width: 90,
            sortable: true,
            filter: false,
            cellRenderer: function (params) {
                return (
                    <Tooltip title='Restore' arrow>
                        <StyledButton
                            iconComponent={<img src={`/assets/icons/restore-icon-grey.svg`} width='20px' height='20px' />}
                            onClick={() => handleRestoreEnvironmentClick(params.data)}
                        />
                    </Tooltip>
                );
            },
        };
    }

    const headCells: any = useMemo(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
        return [
            {
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: false,
                checkboxSelection: false,
                showDisabledCheckboxes: false,
                headerName: t('common.name'),
                lockVisible: true,
                pinned: true,
                sortable: true,
                minWidth: 250,
                field: 'name',
                cellRenderer: function (params) {
                    return (
                        <Box display='flex' flexDirection='row' alignItems='center' fontWeight={600}>
                            {params.value}
                        </Box>
                    );
                },
            },
            {
                headerName: t('common.deletion-date'),
                minWidth: 320,
                sortable: true,
                cellStyle: { display: 'flex', alignItems: 'center' },
                cellRenderer: (params) => dateCreatedValueFormatter(params.data.updatedAt),
            },
            getActionColumn<Environment>(),
        ];
    }, [usersAppData.data, i18n.language]);

    const onColumnVisible = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);

    const getRowId = useCallback((params) => params.data.id, []);

    const gridConfig = useMemo(() => {
        return {
            getRowId,
            onColumnVisible: onColumnVisible,
            onFirstDataRendered: onColumnVisible,
            suppressCellSelection: true,
        };
    }, []);

    return (
        <>
            {showRestoreEnvironmentDialog && triggerRestoreEnvironmentDialog()}

            <StyledWMDataGrid
                rows={deletedEnvironmentRows}
                columnDefs={headCells}
                hideColumnPicker
                hideExport
                hideSearch
                title=''
                entityName='Environment'
                gridConfig={gridConfig}
                loadingData={loadingData}
                columnPickerOptions={{ persistColumnIds: ['name', 'settings', 'environmentTypeKey'] }}
            />
        </>
    );
};
