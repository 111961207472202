import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Toaster } from '@walkme/ui-core';

export const StyledCreateSystemCardTitle = styled(Typography)`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const StyledCreateSystemCardSubTitle = styled(Typography)`
    color: var(--Color-Semantic-Text-text-secondary, #637191);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const StyledCreateSystemCardIcon = styled.div`
    width: 36px;
    height: 36px;
    background: var(--Blue-Primary-Primary-tint-6, #e4e9fc);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledCreateSystemCardContainer = styled.div<{ selected: boolean }>`
    border-radius: 8px;
    border: ${(props) =>
        props.selected ? '1px solid #385FEB' : '1px solid var(--color-semantic-input-dropdown-primary-stroke-primary-default, #E3E9FC)'};
    opacity: 0.88;
    background: ${(props) =>
        props.selected ? 'var(--Color-Semantic-Background-bg-primary, #F7F9FC)' : 'var(--Primaries-White-White-Main, #FFF)'};
    display: flex;
    height: 36px;
    align-items: center;
    gap: 12px;
    padding: 20px;
    cursor: pointer;

    &:hover {
        border-color: #92a7f4;
    }
`;

export const StyledPurposeAndUsageTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
`;

export const StyledPurposeAndUsageDescription = styled.div`
    color: var(--Color-Semantic-Text-text-secondary, #637191);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const StyledPurposeAndUsageCardContainer = styled.div`
    display: flex;
    gap: 40px;
    margin-top: 20px;
`;

export const StyledPurposeAndUsageContainer = styled.div<{ marginTop: number | null }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 32px;
    margin: ${(props) => (props.marginTop ? `${props.marginTop}px` : 'auto')} auto auto auto;
`;

export const StyledPurposeAndUsageSection = styled.div<{ hidden: boolean }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
    width: 530px;
    opacity: ${(props) => (props.hidden ? '0' : '1')};
`;

export const StyledContentContainer = styled.div<{ scroll: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: ${(props) => (props.scroll ? 'scroll' : 'hidden')};
    flex-wrap: wrap;
    border-top: 1px solid #ebeff7;
    border-bottom: 1px solid #ebeff7;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledTrialMessage = styled(Toaster)`
    margin-top: 12px;
`;
