export enum Status {
    InProgress = 'inProgress',
    Pending = 'pending',
    Ready = 'ready',
    Failed = 'failed',
}

export enum JobType {
    FILE = 'File',
    WEB = 'Web',
}

export enum ScrapeStatus {
    RUNNING = 'RUNNING',
    SCANNING = 'SCANNING',
    SCANNING_COMPLETED = 'SCANNING_COMPLETED',
    FINISHED = 'FINISHED',
    CRAWLING = 'CRAWLING',
    FAILED = 'FAILED',
    PAUSE = 'PAUSE',
    MISSING_CREDS = 'MISSING_CREDS',
    PUBLISHING = 'PUBLISHING',
    INIT_SCAN = 'INIT_SCAN',
    STOP = 'STOP',
}

export enum Feedback {
    LIKE = 'like',
    DISLIKE = 'dislike',
}

export enum AuthType {
    NO_AUTH = 'None',
    BEARER_TOKEN = 'static',
    BASIC_AUTH = 'basic',
    OAUTH2 = 'oauth',
    FORM_AUTHENTICATION = 'sso',
}

export enum FormMode {
    STEPPER = 'STEPPER',
    TABS = 'TABS',
    ONE_PAGE = 'ONE_PAGE',
}

export enum ScanFrequency {
    NONE = 'none',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    CUSTOM = 'custom',
}

export enum IntegrationChannelOptions {
    DISABLED = 'Disabled',
    ALL = 'All',
    RULES = 'Rules',
}

export enum FormTypes {
    CRAWLER = 'Crawler',
    FILE_SCAN = 'FileScan',
    SLACK = 'Slack',
    TEAMS = 'Teams',
    ASSIGN_SYSTEMS = 'AssignSystems',
    SETTINGS = 'Settings',
    GUIDED_ANSWERS = 'GuidedAnswers',
    SOURCE_SETTINGS = 'SourceSettings',
    SCAN_SUMMARY = 'ScanSummary',
}

export enum RuleType {
    START_WITH = 'startsWith',
    END_WITH = 'endsWith',
    IS = 'is',
    CONTAINS = 'contains',
}

export enum ChannelStatus {
    APPROVED = 'Approved',
    DENIED = 'Denied',
}

export enum SourcesViewStyle {
    CARDS = 'cards',
    TABLE = 'table',
}

export enum IntegrationType {
    TEAMS = 'teams',
    SLACK = 'slack',
}

export enum RevertStatus {
    NOT_REVERTED = 'notReverted',
    REVERTED = 'reverted',
    REVERT_FAILED = 'revertFailed',
}

export enum ScanSummaryType {
    LAST_SCAN_DIFF = 'lastScanDiff',
    CURRENT_URLS = 'currentUrls',
}
