import { RuleType } from './enums';

export interface IIntegration {
    id: string;
    guid: string;
    name: string;
    type: string;
    userId: string;
    settings: IIntegrationSettings;
    slackChannels: IIntegrationChannels;
}

export interface IIntegrationSettings {
    appKey: string;
    appSecret: string;
}

export interface IIntegrationForm {
    name: string;
    appKey: string;
    appSecret: string;
}

export interface IIntegrationFormSubmit {
    name: string;
    type: string;
    settings: IIntegrationSettings;
    guid: string;
}

export interface IIntegrationChannel {
    channelIntegrationGuid: string;
    channelName: string;
    isPrivate: boolean;
}

export interface IIntegrationRules {
    startsWith?: string[];
    endsWith?: string[];
    contains?: string[];
    is?: string[];
}

export interface IIntegrationRulesForm {
    ruleType: RuleType;
    values: string[];
    ruleText: string;
}

export interface IIntegrationType {
    icon: string;
    name: string;
}

export interface IAskMeIntegrationSystem {
    id?: number;
    name?: string;
    isPrivate?: boolean;
    channelGuid: string;
    integrationGuid: string;
    jobGuid?: string;
    status: string;
}

export declare type IAskMeIntegrationSystems = IAskMeIntegrationSystem[];
export declare type IIntegrationChannels = IIntegrationChannel[];
export declare type IIntegrations = IIntegration[];
