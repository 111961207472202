import styled from 'styled-components';
import { Select, withStyles } from '@material-ui/core';

export const ImportFieldsContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
`;

export const StyledHelperText = styled.span`
    color: #2f426c8c;

    &.fontSmall {
        font-size: 12px;
    }
`;
