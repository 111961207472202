import KnowledgeAiChatService from '@walkme/knowledge-ai-sdk';
import { WMAuthManager } from 'wm-accounts-auth';
import { Feedback } from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { askMeAppApi } from '@walkme-admin-center/libs/ask-me-api';

export class AskMeService {
    service: KnowledgeAiChatService;
    private path = '/deepui/api/askme';
    private endpointUrl = `${window.clientConfig.NX_WM_PAPI}${this.path}`;
    private privateEndpointUrl = `${window.clientConfig.NX_WM_API_URL}${this.path}`;
    constructor({ systemGuid, sourceGuid, msaGuid }) {
        this.service = new KnowledgeAiChatService({
            guid: systemGuid,
            endpointUrl: this.endpointUrl,
            privateEndpointUrl: this.privateEndpointUrl,
            useStream: true,
            retries: 5,
            sourceGuid,
            accessToken: this.getAccessToken(),
            accountMsa: msaGuid,
        });
    }

    getAccessToken() {
        const accessToken = WMAuthManager.getInstance().getUserToken();
        if (accessToken) {
            return accessToken;
        } else {
            return null;
        }
    }

    async reset() {
        this.service.reset();
    }

    async sendQuestionFeedBack(question_uuid: string, feedback_text: string, feedback: Feedback, system_guid: string) {
        try {
            await askMeAppApi.sendQuestionFeedBack({
                question_uuid,
                feedback_text,
                feedback: feedback === Feedback.LIKE ? 1 : -1,
                system_guid,
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    async sendGeneralFeedBack(feedback: string, rank: number, system_guid: string) {
        try {
            await askMeAppApi.sendFeedBack({
                system_guid,
                feedback,
                rank,
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    async ask(query: string, chunkCb?: (text: string) => void) {
        try {
            const response = await this.service.askAi(query, undefined, (text) => {
                chunkCb(text);
            });

            if (response && 'answer' in response) {
                return {
                    //text: response.answer,
                    id: response.question_uuid,
                    chatId: response.chat_uuid,
                    sources: response.links,
                };
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }
}
