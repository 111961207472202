import { DataIntegrationState } from '../redux/data-integration.slice';
import { useSelector } from 'react-redux';

export const useIntegrations = () => {
    const integrationsData = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.integrations;
    });

    return {
        integrationsData,
    };
};

export default useIntegrations;
