import React from 'react';
import { FieldOption, FieldOptions } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { locale } from 'moment';

export enum CONSTS {
    SAML = 'saml',
    OAUTH = 'oAuth2',
    DELETE_ACTION = 'Delete',
    OPEN_ASSIGN_SYSTEMS = 'open_assign_systems',
    PROVIDER_GROUPS = 'providerGroups',
    MARGIN_TOP_SNACKBAR = '44px',
    MARGIN_TOP_SNACKBAR_STEPPER = '152px',
    SNACK_BAR_SEVERITY_SUCCESS = 'success',
    SNACK_BAR_SEVERITY_ERROR = 'error',
}
export const StepperMode = {
    NEW: 'NEW_PROVIDER',
    EDIT: 'EDIT_PROVIDER',
    EDIT_ATTRIBUTES: 'EDIT_ATTRIBUTES',
    ASSIGN_SYSTEMS: 'ASSIGN_SYSTEMS',
};

export interface SystemTypeInfo {
    key: string;
    tooltip: string;
    localeKey: string;
}

export const SystemsType: Record<any, SystemTypeInfo> = {
    DESKTOP_MAC: { key: 'DesktopMac', tooltip: 'Desktop Mac', localeKey: 'systems-tab.data.system-types.desktop-mac' },
    DESKTOP_WINDOWS: { key: 'DesktopWindows', tooltip: 'Desktop Windows', localeKey: 'systems-tab.data.system-types.desktop-windows' },
    DESKTOP_ELECTRON: { key: 'DesktopElectron', tooltip: 'Desktop Electron', localeKey: 'systems-tab.data.system-types.desktop-electron' },
    WEB: { key: 'Web', tooltip: 'Desktop Web', localeKey: 'systems-tab.data.system-types.web' },
    WORKSTATION: { key: 'Workstation', tooltip: 'Workstation', localeKey: 'systems-tab.data.system-types.workstation' },
    MOBILE_WEB: { key: 'MobileWeb', tooltip: 'Mobile Web', localeKey: 'systems-tab.data.system-types.mobileweb' },
    DESKTOP: { key: 'Desktop', tooltip: 'Desktop', localeKey: 'systems-tab.data.system-types.desktop' },
};

export const TypesOptions: FieldOptions = [
    {
        key: 'String',
        value: 'String',
        label: 'String',
    },
    {
        key: 'Number',
        value: 'Number',
        label: 'Number',
    },
    {
        key: 'Date',
        value: 'Date',
        label: 'Date',
    },
    {
        key: 'Boolean',
        value: 'Boolean',
        label: 'Boolean',
    },
];

export const ArrayTypeOption: FieldOption = {
    key: 'Array',
    value: 'Array',
    label: 'Array',
};

export const previewEnv = {
    name: 'Preview',
    path: '/preview',
    id: +window.clientConfig.NX_PREVIEW_ENV_ID,
    userId: -1,
    isDev: false,
};

export const successEnv = {
    name: 'Success',
    path: '/success',
    id: +window.clientConfig.NX_SUCCESS_ENV_ID,
    userId: -1,
    isDev: false,
};
