import styled from 'styled-components';
import { WMDataGridActionDialog } from '@walkme/wm-ui';

export const StyledWMDataGridActionDialog = styled(WMDataGridActionDialog)`
    position: relative;
    bottom: 90px;
    width: calc(100% - 300px);
    margin-left: 100px;
    border-radius: 8px;
    border: 1px solid var(--secondary-secondary-secondary-main, #dde5f5);
    background: var(--wmDark);
    box-shadow: 0px 24px 36px 0px rgba(47, 61, 83, 0.11);
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    z-index: 5;
    background-color: var(--wmPrimary);
    .WMDataGridActionDialog-actionButton {
        color: var(--wmDark);
    }
    .WMDataGridActionDialog-title {
        color: white;
    }
    .WMDataGridActionDialog-spacer {
        background: var(--Color-Semantic-Elements-el-divider-inverted, var(--wmGrayLighter)) !important;
        height: 30px;
    }
    .WMDataGridActionDialog-selectCounter {
        color: white;
    }
    .export-button {
        .WMDataGridActionDialog-actionButton {
            color: white;
        }
    }
    .delete-button {
        background: white !important;
        .MuiButton-label {
            color: 'var(--wmDark)';
        }
    }
    .WMDataGridActionDialog-cancelButton {
        color: white;
    }
    .WMDataGridActionDialog-title {
        font-weight: 400;
    }
`;
