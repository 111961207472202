import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { Button, OutlinedInput, TextField } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
`;

export const StyledButton = styled(Button)`
    border-radius: 17px;
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: 150px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #e1e9f5;
    background-color: #3b61eb;
    color: #ffffff;
    text-transform: none;

    &:hover {
        background-color: #1f46d1;
        opacity: 0.9;
    }
`;

export const StyledTextField = styled(TextField)`
    background-color: white;

    .MuiOutlinedInput-root {
        height: 32px;
        border-radius: 4px;
        border-color: #6d81a6;
        border-width: 1px;
    }
`;

export const StyledSearchInput = styled(OutlinedInput)`
    width: 252px;
    height: 32px;
    border-radius: 16px;
    background-color: #e1e9f5;
    font-family: 'ProximaNova';

    &hover {
        background-color: #d8e2f2;
    }

    .MuiOutlinedInput-inputAdornedStart {
        padding-left: 14px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0px solid;
    }

    .MuiInputBase-input {
        color: #6d81a6;
    }
`;

export const StyledRightDiv = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 240px;
    margin-bottom: 20px;
`;

export const StyledLeftDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 10px;
    width: 240px;
    margin-bottom: 20px;
`;

export const StyledTableContainer = styled(TableContainer)`
    box-sizing: border-box;
`;

export const StyledTable = styled(Table)`
    width: 100%;
    min-width: 750px;

    .data-row {
        &:hover {
            background-color: #edf0f8 !important;

            .deleteButton {
                opacity: 1;
            }

            .editButton {
                opacity: 1;
            }
        }
    }
`;

export const StyledHeaderDiv = styled.div`
    width: 240px;
    margin-bottom: 24px;
    margin-right: 14px;
`;

export const Cell = styled(TableCell)`
    font-size: 14px;
    max-width: 80%;
    cursor: pointer;
    font-family: 'ProximaNova';
    padding: 0 26px;
`;

export const StyledCheckboxCell = styled(TableCell)`
    font-size: 14px;
    max-width: 10px;
    width: 10px;
    cursor: pointer;
`;

export const StyledPaper = styled(Paper)`
    box-shadow: none;
`;

export const StyledContainer = styled(Paper)`
    border-radius: 12px;
    box-shadow: none;
`;

export const StyledHeader = styled.div`
    padding: 12px 20px 6px;
`;

export const StyledTableBody = styled(TableBody)`
    .table-row-hover {
        &:hover {
            background-color: #edf0f8 !important;
        }
    }
`;
