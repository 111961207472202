import Base from './base';
import { IdpSystemsFormValue, Provider, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import { oAuth2OktaSettingsSchema } from '../validation';
import { sharedFields } from '../shared-fields';

const setupGuide = 'https://support.walkme.com/knowledge-base/walkme-oidc-integration-with-okta/';

export class Okta extends Base {
    constructor() {
        super('Okta', oAuth2OktaSettingsSchema, setupGuide);
    }

    getFormFields(): Array<any> {
        return [
            sharedFields.idpName,
            sharedFields.clientId,
            sharedFields.clientSecret,
            sharedFields.idpDomain,
            sharedFields.enforceSSOIframeFlow,
        ];
    }

    initForm(provider: Provider, assignSystemsForm: IdpSystemsFormValue): ProviderFormValues {
        return {
            config: {
                type: this.getProtocol(),
                clientSecret: get(provider, 'config.clientSecret', ''),
                domain: get(provider, 'config.domain', ''),
                clientId: get(provider, 'config.clientId', ''),
                enforceSSOIframeFlow: get(provider, 'config.enforceSSOIframeFlow', false),
            },
            name: get(provider, 'name', ''),
            fields: get(provider, 'fields', []),
            userIdentifier: get(provider, 'userIdentifier', ''),
            systems: this.prepareSystems(get(provider, 'id', null), get(provider, 'systems', []), assignSystemsForm),
        };
    }
}
