import React, { useEffect, useState } from 'react';
import { StyledMenuItem, StyledSubMenu } from './sub-menu.styles';
import { ChevronDown, ChevronUp } from '@walkme/ui-icons/large';
import { Settings } from '@walkme/ui-icons';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import SettingsIcon from './assets/settings-icon.svg';
export const SubMenu = ({ items, activeTab, onClick, openAsDefault, title, getIcon }) => {
    const [isOpen, setIsOpen] = useState(!!openAsDefault);
    const { t } = useTranslation('general');

    const toggleIsOpen = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    const handleActiveTabChanged = () => {
        if (items.some((item) => item.key === activeTab)) {
            setIsOpen(true);
        }
    };

    useEffect(handleActiveTabChanged, [activeTab]);

    return (
        <StyledSubMenu isOpen={isOpen}>
            <div className='header' onClick={toggleIsOpen}>
                <div className='content'>
                    {getIcon()}
                    <span className='title'>{title}</span>
                </div>

                {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
            {isOpen &&
                items?.map((item, index) => {
                    return (
                        <StyledMenuItem key={index} className='menuItem' onClick={item.onClick} isSelected={index === activeTab}>
                            {item.name}
                        </StyledMenuItem>
                    );
                })}
        </StyledSubMenu>
    );
};
