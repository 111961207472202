import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconCopy = (props: SvgIconProps) => {
    return (
        <SvgIcon width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <rect x='8.5' y='8.5' width='9' height='9' rx='1.5' stroke='currentColor' fill='none' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8 7H14C14.5523 7 15 7.44772 15 8H16C16 6.89543 15.1046 6 14 6H8C6.89543 6 6 6.89543 6 8V14C6 15.1046 6.89543 16 8 16V15C7.44772 15 7 14.5523 7 14V8C7 7.44772 7.44772 7 8 7Z'
                fill='currentColor'
            />
        </SvgIcon>
    );
};

export { IconCopy };
