import styled from 'styled-components';
import { Divider, DropdownSelection } from '@walkme/ui-core';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 40px;
        width: 300px;
        margin: 0;
        outline: none !important;
    }
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const ExpectedFormatSelectorDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const UuidText = styled.div`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;

export const UuidButtonText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
    color: ${({ isSelected }) => (isSelected ? '#385FEB' : '#2F426C')};

    &:hover {
        background: var(--Primaries-Blue-P8, #f5f7fe);
    }
`;
