import React, { SVGProps } from 'react';

export const SearchCategoryIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
        <circle cx='12' cy='12' r='4.5' stroke='#6D81A6' />
        <path d='M15.5 15.5L17.5 17.5' stroke='#6D81A6' strokeLinecap='round' />
        <path d='M5.5 8.5H2.5V2.5H8.5V4.5' stroke='#6D81A6' strokeLinecap='round' />
        <path d='M11.5 4.5V2.5H17.5V6.5' stroke='#6D81A6' strokeLinecap='round' />
        <path d='M4.5 11.5H2.5V17.5H6.5' stroke='#6D81A6' strokeLinecap='round' />
    </svg>
);
