/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Route, Routes } from 'react-router-dom';
import React, { useMemo } from 'react';
import { PageWrapper, Dialog, LoadingPage } from '@walkme-admin-center/libs/common';
import { Users, Roles } from '@walkme-admin-center/pages/home/users-roles';
import { Systems, Installation, SystemPage } from '@walkme-admin-center/pages/home/systems';
import { Partners } from '@walkme-admin-center/pages/home/partners';
import DesktopTrackedAppsPage, { DesktopTrackedAppsNotSupported } from '@walkme-admin-center/pages/home/desktop-tracked-apps';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { AuditLogsView } from '@walkme-admin-center/pages/home/audit-log';
import DesktopTrackedAppsDetails from 'packages/pages/home/desktop-tracked-apps/src/lib/desktop-tracked-app-details/desktop-tracked-app-details';
import AddDesktopTrackedApp from 'packages/pages/home/desktop-tracked-apps/src/lib/desktop-tracked-app-details/add-desktop-tracked-app';
import { useDesktopSystemsExists } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { HostingPage } from '@walkme-admin-center/pages/home/hosting';
import SsoConfigurationPage from '../../../../packages/pages/home/sso-configuration/src/lib/sso-configuration-page';
import { ApiKeysPage } from '@walkme-admin-center/pages/home/api-keys';
import { Dashboard } from '../../../../packages/pages/home/dashboard/src/lib/pages-home-dashboard';

import { MainSecurityConfigurationPage } from '@walkme-admin-center/pages/home/security-configuration';
import { OldDashboard } from '@walkme-admin-center/pages/home/dashboard';
import { DataIntegrationsPage } from '@walkme-admin-center/pages/home/data-integrations';
import { EntryPointPage } from '@walkme-admin-center/pages/home/dashboard';
import { MultiLanguageDeprecatedPage } from '@walkme-admin-center/pages/home/multi-language';
import { ContractsEntry } from '@walkme-admin-center/pages/home/contracts';
import AiUsageWrapper from '../../../../packages/pages/home/ai-usage/src/lib/components/ai-usage-wrapper/ai-usage-wrapper';
import AiEmptyState from '../../../../packages/pages/home/ai-usage/src/lib/AiEmptyState';

export const renderMultiRoutes = ({ element: Element, paths, ...rest }) =>
    paths.map((path) => <Route key={path} path={path} {...rest} element={Element} />);

export const MainRoutes = () => {
    const { loggedInUserAppData, accountFeatureEnabled } = useLoggedInUser();
    const { desktopSystemsExists } = useDesktopSystemsExists();
    const showDesktopTrackedApps = useMemo(() => {
        const accountFeatures = loggedInUserAppData.data.account.features ? loggedInUserAppData.data.account.features.names.split(' ') : [];

        return !desktopSystemsExists.loading && desktopSystemsExists.data && accountFeatures.includes('desktopTrackedAppsAdminCenter');
    }, [desktopSystemsExists, loggedInUserAppData]);

    return (
        <Routes>
            <Route path={'/'} element={<EntryPointPage />} />
            <Route
                path={'/admin-dashboard'}
                element={
                    <PageWrapper page={accountFeatureEnabled('newDashboard') ? Dashboard : OldDashboard} name='admin-dashboard' container />
                }
            />
            <Route path={'/installation'} element={<PageWrapper page={Installation} name='installation' container />} />
            {renderMultiRoutes({
                paths: ['/users?', '/users/:id', '/users/manage/:id', '/users-and-roles'],
                element: <PageWrapper page={Users} name='users' container requiredAction={{ actionKey: 'Management' }} />,
            })}
            <Route
                path='/partners/:tabName?'
                element={<PageWrapper page={Partners} name='partners' container requiredAction={{ actionKey: 'Management' }} />}
            />
            <Route
                path='/roles/:tabName?'
                element={<PageWrapper page={Roles} name='roles' container requiredAction={{ actionKey: 'Management' }} />}
            />
            {renderMultiRoutes({
                paths: ['/systems/:tabName?/:id?/:systemTabName?/:innerTabName?', '/systems/all-systems/:id'],
                element: <PageWrapper page={Systems} name='systems' container />,
            })}
            <Route
                path='/idp-integrations'
                element={
                    <PageWrapper
                        page={SsoConfigurationPage}
                        name='sso-configuration'
                        container
                        // requiredAction={{ actionKey: 'IDP' }}
                    />
                }
            />

            <Route path='/ai-settings' element={<PageWrapper page={DataIntegrationsPage} name='Ai Settings' container />} />
            <Route path='/ai-usage' element={<PageWrapper page={AiUsageWrapper} name='Ai Usage' container />} />

            <Route path='/ai-center' element={<PageWrapper page={AiEmptyState} name='Ai Center' container />} />

            <Route
                path='/desktop-tracked-apps'
                element={
                    <PageWrapper
                        page={showDesktopTrackedApps ? DesktopTrackedAppsPage : DesktopTrackedAppsNotSupported}
                        name='desktopTrackedApps'
                        container
                        extraProps={{
                            isLoading: desktopSystemsExists.loading,
                        }}
                    />
                }
            />
            <Route
                path='/desktop-tracked-apps/new'
                element={
                    <PageWrapper
                        page={showDesktopTrackedApps ? AddDesktopTrackedApp : DesktopTrackedAppsNotSupported}
                        name='newDesktopTrackedApps'
                        container
                        extraProps={{
                            isLoading: desktopSystemsExists.loading,
                        }}
                    />
                }
            />
            {renderMultiRoutes({
                paths: ['/desktop-tracked-apps/app-details', '/desktop-tracked-apps/app-details/:id'],
                element: (
                    <PageWrapper
                        page={showDesktopTrackedApps ? DesktopTrackedAppsDetails : DesktopTrackedAppsNotSupported}
                        name='editDesktopTrackedApps'
                        container
                        extraProps={{
                            isLoading: desktopSystemsExists.loading,
                        }}
                    />
                ),
            })}
            <Route
                path='/multi-language'
                element={
                    <PageWrapper
                        extraProps={{
                            allowNotAdmin: true,
                            allowNotEnterprise: true,
                            isLanguageEditPermissionRequired: true,
                        }}
                        page={MultiLanguageDeprecatedPage}
                        name='multi-language'
                        container
                    />
                }
            />

            <Route
                path='/activity-log'
                element={
                    <PageWrapper
                        extraProps={{
                            allowNotAdmin: true,
                            allowNotEnterprise: true,
                        }}
                        page={AuditLogsView}
                        name='activity-log'
                        container
                    />
                }
            />

            <Route
                path='/session-lifetime'
                element={
                    <PageWrapper
                        page={MainSecurityConfigurationPage}
                        name='session-lifetime'
                        container
                        requiredAction={{ actionKey: 'Security' }}
                    />
                }
            />

            <Route
                path='/security'
                element={
                    <PageWrapper
                        page={MainSecurityConfigurationPage}
                        name='security'
                        container
                        requiredAction={{ actionKey: 'Security' }}
                    />
                }
            />

            <Route
                path='/external-identity-providers'
                element={
                    <PageWrapper
                        page={MainSecurityConfigurationPage}
                        name='external-identity-providers'
                        container
                        requiredAction={{ actionKey: 'Security' }}
                    />
                }
            />

            <Route
                path='/account-api-keys'
                element={<PageWrapper page={ApiKeysPage} name='api-keys' container requiredAction={{ actionKey: 'APIKeys' }} />}
            />

            <Route
                path='/social-login'
                element={
                    <PageWrapper
                        page={MainSecurityConfigurationPage}
                        name='social-login'
                        container
                        requiredAction={{ actionKey: 'Security' }}
                    />
                }
            />

            <Route
                path='/end-user-experience'
                element={
                    <PageWrapper
                        extraProps={{ allowIfFeaturesEnabled: ['multiCloudFileStorage'] }}
                        page={HostingPage}
                        name='End User Experience'
                        container
                    />
                }
            />

            <Route
                path='/editor-and-apps'
                element={
                    <PageWrapper
                        extraProps={{ allowIfFeaturesEnabled: ['multiCloudFileStorage'] }}
                        page={HostingPage}
                        name='Editor & Apps'
                        container
                        pageData={{ editDisabled: true, defaultCloudStorage: 'awsS3' }}
                    />
                }
            />

            <Route
                path='/analytics'
                element={
                    <PageWrapper
                        extraProps={{ allowIfFeaturesEnabled: ['multiCloudFileStorage'] }}
                        page={HostingPage}
                        name='Analytics'
                        container
                        pageData={{ editDisabled: true, defaultCloudStorage: 'awsS3' }}
                    />
                }
            />
            <Route path='/contracts' element={<PageWrapper page={ContractsEntry} name='contracts' container />} />
        </Routes>
    );
};

export default () => {
    const { loggedInUserAppData } = useLoggedInUser();
    if (!loggedInUserAppData.data.id) {
        return <LoadingPage />;
    }
    return <MainRoutes />;
};
