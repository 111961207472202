import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { FormActions as IFormActions } from '@walkme-admin-center/libs/types';
import { FormActions } from '@walkme-admin-center/libs/common';
import styled from 'styled-components';

interface BottomSectionProps {
    actions: IFormActions;
    className?: string;
    pristine: boolean;
    submitting: boolean;
    saveActionName: string;
    invalid: boolean;
}

const StyledFormButtonsContainer = styled.div`
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    height: fit-content;
`;

const StyledEmptyPlaceHolder = styled.div`
    display: flex;
    flex: 1;
`;

const BottomSection = ({
    submitting,
    pristine,
    actions,
    saveActionName,
    className='',
    invalid
}: BottomSectionProps) => {
    return (
        <CardContent className={className}>
            <CardActions>
                <StyledEmptyPlaceHolder />
                <StyledFormButtonsContainer>
                    <FormActions
                        actions={actions}
                        submitting={submitting}
                        pristine={pristine}
                        saveActionName={saveActionName}
                        invalid={invalid}
                    />
                </StyledFormButtonsContainer>
            </CardActions>
        </CardContent>
    );
};

export default BottomSection;
