import React from 'react';
import { RouteHeader, styledTheme } from '@walkme-admin-center/libs/shared-styles';
import styled from 'styled-components';
import { Typography, Card, Grid, Link } from '@material-ui/core';
import DesktopTrackedAppTitle from '../desktop-tracked-app-title/desktop-tracked-app-title';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IconError } from '@walkme-admin-center/libs/common';

const StyledCard = styled(Card)`
    padding: 0 30px;
    box-sizing: border-box;
    height: 250px;
`;

const StyledGridContainer = styled(Grid)`
    min-height: 250px;
`;

const StyledGridItem = styled(Grid)`
    text-align: center;
`;

const StyledTitle = styled(Typography)`
    color: ${styledTheme.colors.darkGray};
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
`;

const StyledText = styled(Typography)`
    color: ${styledTheme.colors.mainGray};
    margin-top: 20px;
`;

const StyledIconError = styled(IconError)`
    width: 32px;
    height: 32px;
`;

export const DesktopTrackedAppsNotSupported = () => {
    return (
        <>
            <DesktopTrackedAppTitle />

            <StyledCard>
                <StyledGridContainer
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    spacing={0}
                >
                    <StyledGridItem item xs={8} >
                        <div>
                            <StyledIconError htmlColor={styledTheme.colors.mainGray} />
                        </div>
                        <StyledTitle>
                            In order to monitor desktop apps usage, users need to have WalkMe Workstation version 6.5 or higher deployed on their computers
                        </StyledTitle>
                        <StyledText>
                            Contact your customer success manager for more details and to enable this feature
                        </StyledText>
                    </StyledGridItem>
                </StyledGridContainer>
            </StyledCard>
        </>
    );
};

export default DesktopTrackedAppsNotSupported;
