import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { IFilterState, IDateRange, IAiSettings, ICapViewRowsData, IItemsUsageExpenseRowData } from '../types';
import rootReducer from '../../../../../../../apps/home/src/redux/rootReducer';
import { AppData } from '@walkme-admin-center/libs/types';

export interface AiFilterState {
    fromDate: string;
    toDate: string;
}

export const initialAiFilterState: AiFilterState = {
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().endOf('month').format('YYYY-MM-DD'),
};

const aiFilterSlice = createSlice({
    name: 'aiFiltersSlice',
    initialState: initialAiFilterState,
    reducers: {
        setDateRangeFilter(state: AiFilterState, action: PayloadAction<IDateRange>) {
            state.fromDate = action.payload.fromDate;
            state.toDate = action.payload.toDate;
            return state;
        },
    },
});

export { aiFilterSlice };

const { setDateRangeFilter } = aiFilterSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;

type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export { setDateRangeFilter };
