import styled from 'styled-components';
import { WMConfirmationDialog, WMConfirmationDialogProps, WMLoader } from '@walkme/wm-ui';
import { cancelButtonStyle, confirmButtonStyle, styledScrollbar } from '../common/styled-components';
import { Box, Dialog } from '@material-ui/core';
import { SkippableConfirmationDialog } from '../common/skippable-confirmation-dialog';

const StyledTitleRowNewShell = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledDialog = styled(Dialog)`
    position: relative;

    .MuiDialogTitle-root {
        margin: 24px 24px 0 32px;
        padding: 0;
        color: var(--wmGrayUltraDark);

        & .dialogTitle-closeBtn {
            width: 20px;
            height: 20px;

            & img {
                width: 11px;
                height: 11px;
            }
        }

        & .dialogTitle-titleTxt {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }

        & not(${StyledTitleRowNewShell}) {
            & .dialogTitle-titleTxt {
                margin-top: 12px;
            }
        }

        & .dialogTitle-systemInfo,
        & .WMDivider-wmDivider {
            margin-top: 16px;
        }
    }

    .MuiDialogContent-root {
        padding-right: 24px;
        padding-left: 32px;
        padding-top: 33px;
        margin-bottom: 24px;

        ${styledScrollbar}
    }

    .MuiDialogActions-root {
        gap: 20px;
        margin: 16px 32px 32px 0;
        padding: 0;
    }
`;

const StyledTitleTopRow = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .MuiBox-root {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    & .MuiTypography-root {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }

    & .dialogTitle-backBtn {
        height: 16px;
        width: 16px;
        padding: 0;

        & svg {
            color: var(--wmBermudaGray);
            font-size: 16px;
        }
    }
`;

interface StyledWmConfirmationDialogProps extends WMConfirmationDialogProps {
    $closeIconSize?: string;
}

const StyledWMConfirmationDialog = styled(WMConfirmationDialog).attrs((props) => ({
    ds2: true,
    disableHeaderDivider: true,
}))<StyledWmConfirmationDialogProps>`
    width: 570px;

    .WMDialog-header {
        padding: 24px 28px 16px 32px;
    }

    .WMDialog-titleIcon {
        margin-right: 8px;
        .MuiSvgIcon-root {
            fill: transparent;
            color: var(--wmBermudaGray);
            width: 17px;
            height: 17px;
        }
    }

    .WMDialog-closeButton {
        width: 20px;
        height: 20px;

        .WMDialog-closeIcon {
            font-size: ${(props) => props.$closeIconSize || '9px'};
        }
    }

    .WMDialog-content {
        padding: 10px 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .WMCheckbox-formControlLabel {
            align-items: start;
            height: auto;
        }

        .WMCheckbox-label {
            font-size: 14px;
            line-height: 20px;
            margin-top: -3px;
        }
    }

    .WMConfirmationDialog-default {
        &.WMConfirmationDialog-confirmButton {
            ${confirmButtonStyle};
        }
    }

    .WMConfirmationDialog-cancelButton {
        ${cancelButtonStyle};
        margin-right: 20px;
    }
`;

const StyledSkippableConfirmationDialog = styled(SkippableConfirmationDialog)`
    .MuiDialogContent-root {
        padding: 10px 32px 16px 32px;

        & > .MuiBox-root {
            display: flex;
            flex-direction: column;
            gap: 18px;

            & > .MuiTypography-root {
                margin-right: 20px;
            }
        }
    }
`;

const StyledLoaderContainer = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: inherit;
    z-index: 1;

    .WMLoader-wmLoader {
        top: 50%;
        left: 50%;
        width: 60%;
        transform: translate(-50%, -50%);
    }
`;

export {
    StyledDialog,
    StyledTitleTopRow,
    StyledWMConfirmationDialog,
    StyledSkippableConfirmationDialog,
    StyledLoaderContainer,
    StyledTitleRowNewShell,
};
