import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const StyledContainer = styled.div`
    border-radius: 12px;
    background: var(--color-semantic-input-dropdown-primary-stroke-primary-default, #e3e9fc);
    border: 1px solid #e3e9fc;
    max-width: 584px;
    margin-top: 8px;
`;

export const StyledHeader = styled.div<{ open: boolean }>`
    background: var(--Primaries-Blue-Primary-Primary-tint-7, #edf1fd);
    box-shadow: 0px -10px 15px 0px rgba(212, 223, 245, 0.2), 0px 10px 15px 0px rgba(212, 223, 245, 0.2);
    display: flex;
    padding: 20px 16px;
    border-bottom: ${(props) => (props.open ? '1px solid #E3E9FC' : '')};
    justify-content: space-between;
    border-radius: ${(props) => (props.open ? '12px 12px 0 0' : '12px')};
`;

export const StyledHeaderText = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledContent = styled.div`
    background: var(--Primaries-Blue-Primary-Primary-tint-7, #edf1fd);
    padding: 20px 16px;
    border-bottom: 1px solid #e3e9fc;
`;

export const StyledRadioLabel = styled(Typography)`
    margin-left: 20px;
    margin-right: 10px;
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const StyledRadioLabelContainer = styled.div`
    display: flex;
`;

export const StyledFooter = styled.div`
    border-radius: 0px 0px var(--corner-radus-global-cr-204, 12px) var(--corner-radus-global-cr-204, 12px);
    background: var(--color-semantic-input-dropdown-primary-stroke-primary-default, #e3e9fc);
    padding: var(--spacers-global-sp-3012, 12px) 20px 20px 20px;
    align-self: stretch;

    display: flex;
    align-items: center;
    gap: var(--spacers-global-sp-4016, 16px);
    flex: 1 0 0;
    flex-wrap: wrap;
`;

export const StyledFooterText = styled.div`
    max-width: 480px;
    padding: var(--Spacers-Global-SP2, 2px) 0px;
`;

export const StyledContentContainer = styled.div<{ open: boolean }>`
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: ${(props) => (props.open ? '100%' : '0')};
    opacity: ${(props) => (props.open ? '1' : '0')};
    overflow: hidden;
`;
