import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FooterContainer, FooterActions } from './footer-style';
import { useAskMeForm } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { InfoBox } from '../../../common/components/info-box';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';

export interface ICreateFileScanFooter {
    step: number;
    stepsCount: number;
    onClose: () => void;
    changeActiveStep: (step: number) => void;
    showSystems?: boolean;
}

export const CreateSourceFooter = ({ step, stepsCount, onClose, changeActiveStep, showSystems }: ICreateFileScanFooter) => {
    const [loading, setLoading] = useState(false);
    const formApi = useForm();

    const { askMeForm } = useAskMeForm();

    const handleSubmit = async () => {
        setLoading(true);
        await formApi.submit();
        setLoading(false);
    };

    const disabled = askMeForm.isFooterDisabled || loading;
    const isLastStep = step === stepsCount - 1;

    return (
        <>
            <FooterContainer>
                <div>
                    <Collapse in={!disabled && isLastStep}>
                        <InfoBox>
                            The knowledge source will be available in all system environments without requiring a settings publish.
                        </InfoBox>
                    </Collapse>
                </div>
                <FooterActions>
                    <WMButton onClick={() => (step === 0 ? onClose() : changeActiveStep(step - 1))} variant={WMButtonVariant.Text}>
                        {step === 0 ? 'Cancel' : 'Back'}
                    </WMButton>
                    {!isLastStep ? (
                        <WMButton disabled={formApi.getState().invalid} onClick={() => changeActiveStep(step + 1)}>
                            {' '}
                            Next{' '}
                        </WMButton>
                    ) : (
                        <WMButton onClick={handleSubmit} disabled={disabled} loading={loading}>
                            Create Source
                        </WMButton>
                    )}
                </FooterActions>
            </FooterContainer>
        </>
    );
};

export default CreateSourceFooter;
