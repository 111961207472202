import { CONSTS } from '../../../../../pages/home/sso-configuration/src/lib/common/consts';

export interface ProviderConfig {
    type?: CONSTS.SAML | CONSTS.OAUTH;
    clientSecret?: string;
    domain?: string;
    clientId?: string;
    directoryId?: string;
    domainHint?: string;
    discoveryEndpoint?: string;
    cspDomains?: string;
    pullGroups?: boolean;
    providerName?: string;
    scope?: string;
    encryptAssertion?: boolean;
    signAuthRequest?: boolean;
    enforceSSOIframeFlow?: boolean;
    useIdTokenForProperties?: boolean;
    hasCertificate?: boolean;
    entityId?: string;
    papi?: string;
}
