import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';

class SiteConfigApi {
    constructor(private httpClient: WMhttpClient) {}

    async getUserConfiguration(systemId: number): Promise<any> {
        const { data } = await this.httpClient.instance.get(`/editor/recorder/GetUserConfiguration?systemId=${systemId}`);

        return data;
    }

    async saveSiteConfiguration({ configurationString, systemId, isImpersonated, configurationData = null, ...rest }): Promise<any> {
        return await this.httpClient.instance.post(`editor/recorder/SaveSiteConfiguration`, {
            systemId,
            configurationString,
            isImpersonated,
            configurationData,
            ...rest,
        });
    }

    async isThemingEnabled(systemId: number): Promise<any> {
        const { data } = await this.httpClient.instance.get(`/editor/recorder/IsThemingEnabled?systemId=${systemId}`);

        return data?.toLowerCase() == 'true';
    }

    async enableTheming({ systemId, isImpersonated }): Promise<any> {
        return await this.httpClient.instance.post(`editor/recorder/EnableTheming`, { systemId, isImpersonated });
    }

    async updateAndPublish({
        systemId,
        params,
        isImpersonated,
        userId,
        impPassword,
    }: {
        systemId: string;
        params: any;
        isImpersonated: boolean;
        userId: string;
        impPassword?: string;
    }) {
        const payload: any = {
            systemId,
            userId,
            params,
            isImpersonated,
        };

        if (impPassword) {
            payload.impPassword = impPassword;
        }

        return await this.httpClient.instance.post(`workflow-manager/workflow/update-and-publish`, payload);
    }

    async updateUserFeatures({ systemId, updatedUserFeatures, systemName = null, userId = null }) {
        await this.httpClient.instance.post('featuresmanagement/userFeature/updateUserFeatures', {
            systemId,
            updatedUserFeatures,
            systemName,
            userId,
        });
    }

    async publishStatusCheck({ trackingId, systemId }) {
        return await this.httpClient.instance.get(`workflow-manager/workflow/${trackingId}?poll=true&source=editor&systemId=${systemId}`);
    }

    async getUserFeatureNames({ systemId }) {
        return await this.httpClient.instance.post('featuresmanagement/userFeature/getUserFeatureNames', { systemId });
    }

    async getAccountDefaultMasterFeatures({ accountId }) {
        return await this.httpClient.instance.get(
            `featuresmanagement/accountsDefaultFeatures/masterFeatures?accountId=${accountId}&platform=Web`
        );
    }

    async updateAccountDefaultMasterFeatures({ accountId, masterFeatureName, platform, modifiedBy }) {
        return await this.httpClient.instance.post(`featuresmanagement/accountsDefaultFeatures/${accountId}/masterFeature`, {
            accountId,
            masterFeatureName,
            platform,
            modifiedBy,
        });
    }

    async removeAccountDefaultMasterFeature({ accountId, masterFeatureName, platform }) {
        return await this.httpClient.instance.delete(
            `featuresmanagement/accountsDefaultFeatures/${accountId}/masterFeature?masterFeatureName=${masterFeatureName}&platform=${platform}`,
            { data: { masterFeatureName, platform } }
        );
    }

    async getEnvironments({ systemId, isImpersonated }) {
        return await this.httpClient.instance.post(`editor/recorder/GetEnvironments`, { systemId, isImpersonated });
    }
}

export const siteConfigApi = new SiteConfigApi(wmhttpClientSharedInstance);
