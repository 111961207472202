import styled from 'styled-components';
import { WMButton } from '@walkme/wm-ui';
import { confirmButtonStyle } from './styled-components';

const StyledSvg = styled('svg')`
    position: absolute;
    pointer-events: none;
`;

const StyledConfirmButton = styled(WMButton)`
    ${confirmButtonStyle};

    &.WMButton-loading {
        .MuiCircularProgress-circle {
            stroke: ${(props) => `url(#${props.id}-loading-circle-gradient)`};
            stroke-width: 5;
        }
    }
,
`;

export { StyledSvg, StyledConfirmButton };
