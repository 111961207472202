// colors
const white = '#ffffff';
const black = '#000000';
const mainBlue = '#0A1B39';
const menuBlue = '#303D53';

const mainGray = '#303D53';
const mainShadowColor = 'rgba(221,229,245,0.75)';
const darkGray = '#5E5E5E';
const mainBtnGreen = '#44bc67';
const mainBtnGrey = '#78868F';
const mainHoverBgGrey = '#9DA8AE';
const lightFocusBlue = '#c9e0f0';
const separator = '#DDE5F5';
const appBarFirstTextColor = '#6F7AA1';
const appBarSecondTextColor = '#6180EE';
const linkColor = '#9ba6c4';
const hoverColor = '#F4F7FD';
const iconColor = '#E4EBFC';

// multi-language colors
const lightGray = '#fafafa';
const mainBgGray = '#eeeeee';

// weights
const boldWeight = 700;
const lightWeight = 100;
const mediumWeight = 600;

// status colors
const pendingColor = '#77838d';
const successColor = '#44bc67';
const errorColor = '#e14141';
const warningColor = '#e1a032';

// sizes
const fontSizeSmall = '0.9rem';

export const styledTheme = {
    colors: {
        separator,
        mainGray,
        darkGray,
        mainBlue,
        appBarFirstTextColor,
        appBarSecondTextColor,
        pendingColor,
        pendingBgColor: `${pendingColor}25`,
        successColor,
        successBgColor: `${successColor}25`,
        errorColor,
        errorBgColor: `${errorColor}25`,
        warningColor,
        warningBgColor: `${warningColor}25`,
        mainBgGray,

        main: {
            mainTitle: mainGray,
            mainText: menuBlue,
            inlineLinks: '#0072e5',
            mainBorder: mainGray,
            sideBarLinks: {
                main: '#B6C9FC',
                hover: '#0E254D',
                active: '#142F5D',
            },
            topHeaderLinks: {
                main: black,
                hover: mainBlue,
                active: mainBlue,
            },
            mainBg: white,
            userAvatarBg: menuBlue,
            mainShadowColor,
            lightGray,
            helpDialerBg: '#ebeffd',
            unFocused: '#0a2333',
            linkColor,
            hoverColor,
            iconColor,
        },

        apps: {
            preview: {
                header: {
                    bg: '#f4f7fd',
                    links: '#3d63eb',
                },
            },
            menuitemColor: '#6C7AA5',
            teach: {
                mainTitle: '#007DEA',
                iconsHover: '#3b61eb',
            },
            share: {
                mainTitle: '#8A61FF',
            },
        },

        pages: {
            innerTabs: {
                backgroundColor: {
                    focus: lightFocusBlue,
                },
            },
        },

        sideBar: {
            sideBarBgColor: mainBlue,
        },

        buttons: {
            danger: '#e33737',
            mainBgColor: mainBtnGreen,
            mainBgGrey: mainBtnGrey,
            mainHoverBgColor: '#79cd91',
            mainHoverBgGrey: mainHoverBgGrey,

            form: {
                border: separator,
            },

            specialTypes: {
                warning: '#fc4040',
                cancel: mainGray,
            },
        },

        messages: {
            outputs: {
                snippet: {
                    bgColor: '#eaf6ff',
                    borderColor: '#c3d2dd',
                    textColor: '#616161',
                },
            },
            fail: {
                bgColor: '#f7c2ba',
            },
        },
    },
    typography: {
        fontWeight: {
            light: lightWeight,
            medium: mediumWeight,
            heavy: boldWeight,
        },
        fontSize: {
            small: fontSizeSmall,
        },
    },
    sizes: {
        drawerWidth: '192px',
        minHeaderWidth: '64px',
    },
    dialogs: {
        colors: {
            mainDialogBg: 'white',
            separator: separator,
        },
        layouts: {
            sideScreen: {
                width: '480px',
                minHeight: '100%',
                maxWidth: '480px',
                margin: 0,
                borderRadius: 0,

                rightSide: {
                    justifyContent: 'flex-end',
                },
                leftSide: {
                    justifyContent: 'flex-start',
                },
            },
        },
    },
};

export default styledTheme;
