import {
    SystemsState,
    getSystem,
    systemsSlice,
    updateExistingSystemName,
    useSystems,
} from '@walkme-admin-center/libs/state-management-systems';
import { Environments } from '@walkme-admin-center/pages/home/environments';
import { AppData } from '@walkme-admin-center/libs/types';
import React, { JSXElementConstructor, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { System } from 'wm-accounts-sdk';
import { Users, Settings } from '@walkme/ui-icons/large';
import { ArrowRight, ExternalLink, MultiLanguage } from '@walkme/ui-icons';
import { ClickAwayListener } from '@material-ui/core';
import { Button, useToaster, ToasterVariant, Tooltip } from '@walkme/ui-core';
import { SystemUsersTab } from './system-users/system-users';
import { PagesHomeImportLexicon } from '@walkme-admin-center/pages/home/import-lexicon';
import { Extension } from '@walkme-admin-center/pages/home/extension-pages';
import { importLexiconApi } from '@walkme-admin-center/libs/import-lexicon-api';
import { AppThunkDispatch, useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import {
    SoonDiv,
    StyledTabContent,
    SystemTypeDiv,
    NavigationBar,
    ContentDiv,
    NavigationTab,
    SystemHeaderName,
    TabName,
    SystemsBackButton,
    MiniTitle,
    StyledLexiconIcon,
    StyledAccessibilityIcon,
    StyledUsersIcon,
    StyledSideMenu,
    StyledSideMenuItem,
    StyledLexiconTab,
} from './new-system-page.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { SubMenu } from './sub-menu';
import { UUID } from './uuid/uuid';
import { DataCollection } from './data-collection/data-collection';
import { BuildingSettings } from './building-settings/building-settings';
import { LanguageIdentification } from './language-identification/language-identification';
import { AccessibilityIcon } from './assets/accessibility-icon';
import { Accessibility } from './accessibility/accessibility';
import useExistingSystemSiteConfiguration from '../../../../../libs/state-management-systems/src/lib/hooks/use-existing-system-site-configuration';
import { WMSnackbar } from '@walkme/wm-ui';

export interface SystemPageProps {}
export interface SideMenuProps {
    key: string;
    name: string;
    icon: (isSelected) => JSX.Element;
    data?: JSX.Element;
    disabled?: boolean;
    hide?: boolean;
    items?: Omit<SideMenuProps, 'icon'>[];
    component?: JSXElementConstructor<any>;
    onClick?: () => void;
}

const DISABLE_LEXICON_IMPORT_FF = 'disableLexiconTranslationsImport';

export const SystemPage = (props: SystemPageProps) => {
    const { t, rt } = useTranslation('general');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addToaster } = useToaster();
    const { systemsAppData } = useSystems();
    const { id, tabName, systemTabName, innerTabName } = useParams<{ id; tabName; systemTabName; innerTabName }>();
    const [hover, setHover] = useState(false);
    const [docked, setDocked] = useState(false);
    const [error, setError] = useState(false);

    const currentSystemAppData: AppData<System> = useSelector((state: { systemsState: SystemsState }) => state.systemsState.currentSystem);
    const updateSystemNameAppData: AppData<System> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.updatedSystemName
    );
    const currentSnackbarMessage = useSelector((state: { systemsState: SystemsState }) => state.systemsState.notificationMessage);
    const [updatedSystemName, setUpdatedSystemName] = useState(currentSystemAppData.data?.displayName);
    const [isLexiconEnabled, setIsLexiconEnabled] = useState(false);
    const { userPermissions, accountFeatureEnabled } = useLoggedInUser();
    const isAdmin = userPermissions('Management', 'Write');
    const disableLexiconImport = currentSystemAppData.data?.featureFlags?.some((ff) => ff.flagName === DISABLE_LEXICON_IMPORT_FF);
    const isAllowsToUploadTranslations = isAdmin && isLexiconEnabled && !disableLexiconImport;
    const { siteConfiguration } = useExistingSystemSiteConfiguration(id);

    useEffect(() => {
        const accountGuid = currentSystemAppData?.data?.guid;

        if (accountGuid) {
            importLexiconApi
                .isLexiconEnabled(accountGuid)
                .then((result) => {
                    setIsLexiconEnabled(result);
                })
                .catch((error) => {
                    setIsLexiconEnabled(false);
                });
        }
    }, [currentSystemAppData]);

    useEffect(() => {
        (dispatch as AppThunkDispatch)(getSystem(Number(id)));
        setUpdatedSystemName(currentSystemAppData.data?.displayName);
    }, [currentSystemAppData.data?.displayName, dispatch, id]);

    useEffect(() => {
        if (updateSystemNameAppData.data) {
            addToaster({
                message: t('systems-tab.all-systems-page.success-message-change-name'),
                variant: ToasterVariant.Success,
                width: 'content',
                autoDismiss: true,
                distance: '60',
            });
            dispatch(systemsSlice.actions.cleanupUpdatedSystemName());
        }
    }, [updateSystemNameAppData]);

    useEffect(() => {
        if (systemsAppData.data?.length > 0) {
            const isSystemExist = systemsAppData.data.some((system) => system.id === Number(id));
            if (!isSystemExist) {
                navigate({
                    pathname: `/systems`,
                });
            }
        }
    }, [systemsAppData]);

    const sideMenu: SideMenuProps[] = [
        {
            key: 'environments',
            name: t('systems-tab.all-systems-page.assign-users-form.environments'),
            icon: (isSelected) =>
                isSelected ? <img src={`/assets/icons/icon-globe.svg`} /> : <img src={`/assets/icons/icon-globe-gray.svg`} />,
            data: <Environments systemId={Number(id)} />,
        },
        {
            key: 'extension',
            name: t('systems-tab.all-systems-page.assign-users-form.extension'),
            icon: (isSelected) =>
                isSelected ? (
                    <img src={`/assets/icons/extension-tab-icon-selected.svg`} />
                ) : (
                    <img src={`/assets/icons/extension-tab-icon-not-selected.svg`} />
                ),
            data: <Extension systemId={Number(id)} />,
            hide: currentSystemAppData.data.systemTypeKey !== 'Web',
        },
        {
            key: 'systemUsers',
            name: t('systems-tab.all-systems-page.assign-users-form.system-users'),
            icon: () => (
                <StyledUsersIcon>
                    <Users />
                </StyledUsersIcon>
            ),
            data: <SystemUsersTab systemId={Number(id)} systemName={currentSystemAppData.data.displayName} />,
        },
        {
            key: 'multi-language',
            name: t(`systems-tab.new-system-form.configure-step.multi-language.side-menu-title`),
            icon: () => (
                <StyledLexiconIcon>
                    <MultiLanguage width={15} height={15} />
                </StyledLexiconIcon>
            ),
            disabled: false,
            component: SubMenu,
            items: [
                {
                    key: 'language-identification',
                    name: t('systems-tab.new-system-form.configure-step.multi-language.language-identification.side-menu-title'),
                    data: <LanguageIdentification systemId={Number(id)} />,
                },
                {
                    key: 'lexicon',
                    name: t('systems-tab.all-systems-page.assign-users-form.lexicon'),
                    data: (
                        <StyledLexiconTab>
                            {isAllowsToUploadTranslations ? (
                                <PagesHomeImportLexicon systemId={Number(id)} />
                            ) : (
                                <div>{t('systems-tab.all-systems-page.assign-users-form.lexicon-not-allowed')}</div>
                            )}
                        </StyledLexiconTab>
                    ),
                    hide: !isAllowsToUploadTranslations,
                },
            ],
        },
        {
            key: 'data-settings',
            name: t(`systems-tab.new-system-form.configure-step.side-menu.data-settings.title`),
            icon: () => <Settings className='settings-icon' />,
            disabled: false,
            component: SubMenu,
            items: [
                {
                    key: 'uuid',
                    name: t('systems-tab.new-system-form.configure-step.uuid.main-title'),
                    data: <UUID systemId={Number(id)} />,
                },
                {
                    key: 'data-collection',
                    name: t('systems-tab.new-system-form.configure-step.data-collection-level.side-menu-title'),
                    data: <DataCollection systemId={Number(id)} />,
                },
            ],
        },
        {
            key: 'accessibility',
            name: t(`systems-tab.new-system-form.configure-step.accessibility.side-menu-title`),
            icon: (isSelected) => (
                <StyledAccessibilityIcon>
                    {isSelected ? <AccessibilityIcon fill='#3a5ee8' /> : <AccessibilityIcon fill='#6D81A6' />}
                </StyledAccessibilityIcon>
            ),
            data: <Accessibility systemId={Number(id)} />,
            disabled: false,
            hide: !currentSystemAppData?.data?.type || currentSystemAppData?.data?.type === 'workstation',
        },
        {
            key: 'building-settings',
            name: t(`systems-tab.new-system-form.configure-step.building-settings.side-menu-title`),
            icon: () => <Settings className='settings-icon' />,
            data: <BuildingSettings systemId={Number(id)} />,
            disabled: false,
        },
    ];

    const tabNameExists = systemTabName && sideMenu.findIndex((tab) => tab.key === systemTabName);
    const subTabExists = innerTabName && sideMenu[tabNameExists]?.items?.findIndex((item) => item.key === innerTabName);

    const findTabInIndex = !tabNameExists || tabNameExists === -1 ? 0 : tabNameExists;
    const findSubTabIndex = !subTabExists || subTabExists === -1 ? 0 : subTabExists;

    const [selectedTab, setSelectedTab] = useState(findTabInIndex);
    const [selectedSubTab, setSelectedSubTab] = useState(findSubTabIndex);

    const getBodyContent = () => {
        const selectedTabHasComponent = !!sideMenu[selectedTab].component;

        return selectedTabHasComponent ? sideMenu[selectedTab]?.items?.[selectedSubTab]?.data : sideMenu[selectedTab].data;
    };

    const onClickOpenEditor = () => {
        const appDomain = `walkme-editor://impersonateCallback?impUserInput=${currentSystemAppData.data.email}`;
        window.open(appDomain);
    };

    const updateSystemCallback = useCallback(
        (updatedDisplayName: string) => {
            (dispatch as AppThunkDispatch)(updateExistingSystemName(currentSystemAppData.data, updatedDisplayName));
        },
        [dispatch, currentSystemAppData]
    );
    const divRef = useRef(null);
    const hiddenInputRef = useRef(null);

    const handleClickAway = () => {
        divRef.current.textContent !== '' &&
            divRef.current.textContent !== currentSystemAppData.data?.displayName &&
            updateSystemCallback(divRef.current.textContent);
    };

    const handleInputChange = () => {
        if (divRef.current) {
            divRef.current.textContent === '' ? setError(true) : setError(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleBlur = () => {
        if (divRef.current && hiddenInputRef.current) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const preSelectionRange = range.cloneRange();
            preSelectionRange.selectNodeContents(divRef.current);
            preSelectionRange.setEnd(range.startContainer, range.startOffset);

            hiddenInputRef.current.value = preSelectionRange.toString().length;
        }
    };

    const handleSnackBarClose = () => {
        dispatch(systemsSlice.actions.cleanUpNotificationMessage());
    };

    const isWorkstation = useMemo(() => currentSystemAppData.data?.type === 'workstation', [currentSystemAppData.data?.type]);

    return (
        <div style={{ height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: ' 0px 0px 24px 0px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <SystemsBackButton
                        onClick={() =>
                            navigate({
                                pathname: `/systems`,
                            })
                        }>
                        {t('common.systems')}
                    </SystemsBackButton>
                    <ArrowRight />
                    <MiniTitle>{currentSystemAppData.data?.displayName}</MiniTitle>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                            <img
                                src={isWorkstation ? '/assets/icons/Workstation.png' : currentSystemAppData.data?.settings?.icons?.highRes}
                                width='20px'
                                height='20px'
                                alt='systemIcon'
                            />
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <SystemHeaderName
                                    error={error}
                                    ref={divRef}
                                    spellCheck={false}
                                    contentEditable={true}
                                    onInput={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleBlur}
                                    defaultValue={t('systems-tab.new-system-form.system-name')}>
                                    {updatedSystemName}
                                    <input
                                        type='text'
                                        className='hidden-input'
                                        ref={hiddenInputRef}
                                        style={{ position: 'absolute', top: '-9999px' }}
                                    />
                                </SystemHeaderName>
                            </ClickAwayListener>
                            {updateSystemNameAppData.loading && <Button loading={updateSystemNameAppData.loading} variant='ghost'></Button>}
                            <SystemTypeDiv>{rt(currentSystemAppData.data.systemTypeKey)}</SystemTypeDiv>
                        </div>
                        {error && (
                            <span
                                style={{
                                    color: 'var(--danger-danger-shade-1, #B02626)',
                                    fontFamily: 'Proxima Nova',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '16px',
                                    marginLeft: '32px',
                                }}>
                                {t('systems-tab.all-systems-page.new-system-page.no-name-text')}
                            </span>
                        )}
                    </div>
                    <Button onClick={onClickOpenEditor} startIcon={<ExternalLink />}>
                        {t('buttons.open-in-editor')}
                    </Button>
                </div>
            </div>
            <div
                style={{
                    height: `calc(100% - ${error ? '116' : '92'}px)`,
                    backgroundColor: 'white',
                    display: 'flex',
                    borderRadius: '12px',
                }}>
                <NavigationBar>
                    <div
                        style={{
                            display: 'flex',
                            padding: '24px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <StyledSideMenu>
                            {sideMenu.map((tab, index) => {
                                const TabComponent: any = tab.component;
                                if (TabComponent) {
                                    const itemsWithOnOnClick = [];
                                    tab.items.forEach((item, itemIndex) => {
                                        if (item.hide) return;
                                        item.onClick = () => {
                                            setSelectedTab(index);
                                            setSelectedSubTab(itemIndex);
                                            navigate({
                                                pathname: `/systems/all-systems/${id}/${tab.key}/${item.key}`,
                                            });
                                        };
                                        itemsWithOnOnClick.push(item);
                                    });

                                    return !tab.hide ? (
                                        <TabComponent
                                            items={itemsWithOnOnClick}
                                            activeTab={selectedTab === index ? selectedSubTab : undefined}
                                            openAsDefault={selectedTab === index}
                                            title={tab.name}
                                            getIcon={tab.icon}
                                        />
                                    ) : null;
                                }

                                return (
                                    !tab.hide && (
                                        <NavigationTab
                                            key={index}
                                            onClick={() => {
                                                setSelectedTab(index);
                                                setSelectedSubTab(null);
                                                navigate({
                                                    pathname: `/systems/all-systems/${id}/${tab.key}`,
                                                });
                                            }}
                                            className={tab.disabled ? 'disabledTab' : selectedTab === index ? 'selectedTab' : null}>
                                            <StyledSideMenuItem isSelected={selectedTab === index}>
                                                <Tooltip arrow placement='right' title={tab.name}>
                                                    {tab.icon(selectedTab === index)}
                                                </Tooltip>
                                                <TabName>{tab.name}</TabName>
                                            </StyledSideMenuItem>
                                            {tab.disabled && <SoonDiv>{t('common.soon')}</SoonDiv>}
                                        </NavigationTab>
                                    )
                                );
                            })}
                        </StyledSideMenu>
                    </div>
                </NavigationBar>
                <ContentDiv>
                    <StyledTabContent key={sideMenu[selectedTab].key} value={selectedTab} index={selectedTab}>
                        {getBodyContent()}
                    </StyledTabContent>
                </ContentDiv>
                <WMSnackbar
                    open={currentSnackbarMessage.isOpen}
                    onClose={handleSnackBarClose}
                    variant={currentSnackbarMessage.variant}
                    message={currentSnackbarMessage.text}
                />
            </div>
        </div>
    );
};
