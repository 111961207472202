import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormHeader } from './form-header/form-header';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { getFormManager } from './data-integrations-form-manager/data-integrations-form-manager';
import CustomStepper from '../common/components/custom-stepper';
import { FormContent, FormContainer, FormFooter, CssTabs, CssTab } from './data-integration-form-style';
import { useDispatch } from 'react-redux';
import { IAskMeForm, FormMode, FormTypes, IAssignSystemsForm } from '../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { FormApi } from 'final-form';
import { useIntegrations, initAskMeFormState } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { CssDivider } from './form-header/form-header-style';
import styled from 'styled-components';
import IdpDialog, { IdpDialogProps } from 'packages/pages/home/sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

const FormContentFooterWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow-x: auto;
`;

export interface IDataIntegrationForm {
    formMode: FormMode;
    onClose: () => void;
    instanceData?: any;
    showSnackBar: (any) => void;
    fromType: FormTypes;
    checkedIntegrations: Set<string>;
    triggerModal?: (formType: FormTypes, name: string) => void;
}

export const DataIntegrationForm = ({
    formMode,
    onClose,
    instanceData,
    fromType,
    showSnackBar,
    checkedIntegrations,
    triggerModal,
}: IDataIntegrationForm) => {
    const changeActiveStep = (step: number): void => {
        setActiveStep(step);
    };

    const [dialogProps, setDialogProps] = useState<IdpDialogProps | undefined>();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const { loggedInUserAppData } = useLoggedInUser();

    const onCloseWithConfirmation = useCallback(
        () =>
            isFormDirty
                ? setDialogProps({
                      open: true,
                      handleClose: () => setDialogProps(undefined),
                      confirm: () => {
                          onClose();
                          setDialogProps(undefined);
                      },
                      title: 'Discard changes?',
                      content: "All unsaved changes will be lost. This can't be undone.",
                      type: 'errorButton',
                      buttonText: 'Discard',
                      cancelButtonText: 'Cancel',
                  })
                : onClose(),
        [onClose, isFormDirty]
    );

    const dispatch = useDispatch();
    const { integrationsData } = useIntegrations();

    const [activeStep, setActiveStep] = useState(0);
    const [formManager] = useState(getFormManager(fromType, dispatch, showSnackBar, onCloseWithConfirmation, changeActiveStep));

    useEffect(() => {
        return () => {
            dispatch(initAskMeFormState());
        };
    }, []);

    const initialValues = useMemo(() => formManager.initForm({ data: instanceData }), []);

    const validate = useCallback(
        async (values: any) => {
            return formManager.validate(values, activeStep, formMode);
        },
        [formManager, activeStep]
    );

    const handleSubmit = async (values: any): Promise<void> => {
        const integrationsObj = {};
        for (const integration of integrationsData.data) {
            integrationsObj[integration.type] = integration;
        }

        await formManager.handleSubmit({
            values,
            formMode,
            instanceData,
            checkedIntegrations,
            integrations: integrationsObj,
            user: loggedInUserAppData.data,
        });
    };

    const changeTab = async (event, formApi: FormApi<IAssignSystemsForm | IAskMeForm>, tabIndex: number) => {
        const errorsObj = await validate(formApi.getState().values);
        if (!errorsObj) {
            setActiveStep(tabIndex);
        }
    };

    return (
        <>
            {dialogProps && (
                <IdpDialog
                    open={dialogProps.open}
                    handleClose={dialogProps.handleClose}
                    confirm={dialogProps.confirm}
                    title={dialogProps.title}
                    content={dialogProps.content}
                    type={dialogProps.type ?? 'defaultButton'}
                    buttonText={dialogProps.buttonText ?? 'I understand, continue'}
                    cancelButtonText={dialogProps.cancelButtonText ?? 'Cancel'}
                />
            )}
            <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validate={validate}
                mutators={{
                    ...arrayMutators,
                    setFieldTouched: (args, state) => {
                        const [name] = args;
                        const field = state.fields[name];
                        if (field) {
                            field.touched = true;
                        }
                    },
                }}
                render={({ form: formApi }) => {
                    const { dirty } = formApi.getState();

                    useEffect(() => {
                        setIsFormDirty(dirty);
                    }, [dirty]);

                    return (
                        <FormContainer>
                            <div>
                                <FormHeader
                                    formMode={formMode}
                                    step={activeStep}
                                    onClose={onCloseWithConfirmation}
                                    headerText={formManager.getFormHeader(instanceData, formMode)}
                                    goBack={() => setActiveStep(activeStep - 1)}
                                    actions={formManager.getFormHeaderActions(instanceData, formMode)}
                                />
                                {(formMode === FormMode.TABS || formMode === FormMode.STEPPER) && <CssDivider style={{ margin: 0 }} />}
                                {formMode === FormMode.STEPPER && (
                                    <CustomStepper activeStep={activeStep} steps={formManager.getStepperLabels()} />
                                )}
                                {formMode === FormMode.TABS && (
                                    <CssTabs value={activeStep} onChange={(event, index) => changeTab(event, formApi, index)}>
                                        {formManager.getTabsLabels().map((label, index) => {
                                            return <CssTab disableRipple key={label} label={label} />;
                                        })}
                                    </CssTabs>
                                )}
                            </div>
                            <FormContentFooterWrapper>
                                <FormContent>
                                    {formMode === FormMode.STEPPER && formManager.getFormByStep(activeStep, triggerModal)}
                                    {formMode === FormMode.TABS && formManager.getFormByTab(activeStep, instanceData, triggerModal)}
                                    {formMode === FormMode.ONE_PAGE && formManager.getOnePageForm()}
                                </FormContent>
                                <FormFooter>
                                    {formMode === FormMode.STEPPER && formManager.getStepperFooter(activeStep)}
                                    {formMode === FormMode.TABS && formManager.getTabsFooter()}
                                    {formMode === FormMode.ONE_PAGE && formManager.getOnePageFooter()}
                                </FormFooter>
                            </FormContentFooterWrapper>
                        </FormContainer>
                    );
                }}
            />
        </>
    );
};
