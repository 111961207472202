import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from "./components/styles";
import { DialogImage } from "./components/dialog-image.component";
import { MainTitle } from "./components/main-title.component";
import { AnalyticsLink } from "./components/analytics-link.component";

interface SelfHostedDialogProps {
    open: boolean;
    onClose: () => void;
}

const SelfHostedDialog = ({ open, onClose }: SelfHostedDialogProps) => {
    const classes = useStyles();

    const dialogTitle =
        <DialogTitle>
            <Box className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitleText}>
                    Self Hosting
                </Typography>
                <IconButton onClick={onClose}>
                    <img src='assets/icons/close.svg' alt='close' />
                </IconButton>
            </Box>
        </DialogTitle >;


    const dialogContent =
        <DialogContent className={classes.paper}>
            <Box display='flex'>
                <Box flexGrow={1} display='flex' flexDirection='column' alignItems='center'>
                    <DialogImage/>
                    <MainTitle/>
                    <AnalyticsLink onClick={() => onClose()}/>
                </Box>
            </Box>
        </DialogContent>;

    return (
        <Dialog
            onClose={() => onClose()}
            open={open}
            classes={{ paper: classes.dialog }}
            fullWidth={true}
            maxWidth={'xl'}
        >
            {dialogTitle}
            {dialogContent}
        </Dialog>
    );
};

export { SelfHostedDialog };
