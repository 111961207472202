import { AuthType, RuleType, ScanFrequency } from '../../../../../../libs/state-mangment-data-integration/src/lib/types';

export const CloseIcon = 'assets/icons/data-integrations/close - large.svg';

export const TEXT = {
    PAGE_TITLE: 'AI Knowledge Sources',
    PAGE_DESCRIPTION: 'Connect your knowledge sources and integrate tools to enhance the AI-conversational search.',
};

export const WeeklyOptions = [
    {
        id: 1,
        displayName: 'Sunday',
        value: 1,
    },
    {
        id: 2,
        displayName: 'Monday',
        value: 2,
    },
    {
        id: 3,
        displayName: 'Tuesday',
        value: 3,
    },
    {
        id: 4,
        displayName: 'Wednesday',
        value: 4,
    },
    {
        id: 5,
        displayName: 'Thursday',
        value: 5,
    },
    {
        id: 6,
        displayName: 'Friday',
        value: 6,
    },
    {
        id: 7,
        displayName: 'Saturday',
        value: 7,
    },
];

function monthDayName(i: number) {
    return i % 10 === 1 && i !== 11 ? `${i}st` : i % 10 === 2 && i !== 12 ? `${i}nd` : i % 10 === 3 && i !== 13 ? `${i}rd` : `${i}th`;
}

export const MonthlyOptions = Array.from({ length: 31 }, (_, i) => ({
    id: i + 1,
    displayName: monthDayName(i + 1),
    value: i + 1,
}));

export const ScheduleMethodOptions = {
    NONE: {
        displayName: "Don't audit",
        value: ScanFrequency.NONE,
    },
    WEEKLY: {
        displayName: 'Weekly',
        value: ScanFrequency.WEEKLY,
        optionsLabel: 'Every',
        options: WeeklyOptions,
    },
    MONTHLY: {
        displayName: 'Monthly',
        value: ScanFrequency.MONTHLY,
        optionsLabel: 'Every',
        options: MonthlyOptions,
    },
    CUSTOM: {
        displayName: 'On a chosen date',
        value: ScanFrequency.CUSTOM,
        optionsLabel: 'Choose date',
        choose: 'date',
    },
};

export const SecondsOptions = [
    {
        id: 1,
        displayName: '2 sec',
        value: 2,
    },
    {
        id: 2,
        displayName: '3 sec',
        value: 3,
    },
    {
        id: 3,
        displayName: '4 sec',
        value: 4,
    },
];

export const RuleTypeOptions = [
    {
        id: 1,
        displayName: 'Is',
        value: RuleType.IS,
    },
    {
        id: 2,
        displayName: 'Contains',
        value: RuleType.CONTAINS,
    },
    {
        id: 3,
        displayName: 'Starts With',
        value: RuleType.START_WITH,
    },
    {
        id: 4,
        displayName: 'Ends With',
        value: RuleType.END_WITH,
    },
];

export const AuthMethodOptions = [
    {
        id: 1,
        displayName: 'No Authentication',
        value: AuthType.NO_AUTH,
    },
    {
        id: 2,
        displayName: 'Bearer Token',
        value: AuthType.BEARER_TOKEN,
    },
    {
        id: 3,
        displayName: 'Basic Authentication',
        value: AuthType.BASIC_AUTH,
    },
    {
        id: 4,
        displayName: 'OAuth 2.0 Authentication',
        value: AuthType.OAUTH2,
    },
    {
        id: 5,
        displayName: 'Form Authentication',
        value: AuthType.FORM_AUTHENTICATION,
    },
];

export const SECONDS_TO_PROCESS_MB = 60;

export enum AiFeatureFlags {
    ENABLE_ASK_ME = 'enable_askMe',
    ENABLE_AI_USAGE = 'enable_aiUsage',
    AI_ANSWERS_SLACK = 'aiAnswersSlack',
    AI_ANSWERS_TEAMS = 'aiAnswersTeams',
    AI_CENTER_ON_DEMAND = 'aiCenterOnDemand',
}

export const integrationsArray = [
    {
        name: 'Slack',
        type: 'slack',
        featureFlag: AiFeatureFlags.AI_ANSWERS_SLACK,
        isGa: true,
        info: 'Define which channels should have access to the knowledge source',
        preSetupInfo: 'Allows for direct question-asking in messages',
    },
    {
        name: 'Microsoft Teams',
        type: 'teams',
        featureFlag: AiFeatureFlags.AI_ANSWERS_TEAMS,
        isGa: false,
        info: 'Define which teams should have access to the knowledge source',
        preSetupInfo: 'Allows for direct question-asking in messages',
    },
];
