import React, { useCallback, useState } from 'react';
import { IdpEnvs, IdpSystemFormValue } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import { Environments } from 'wm-accounts-sdk';
import { FormApi } from 'final-form';
import IdpDialog from '../../../common/components/idp-dialog/idp-dialog';
import EnvItem from './env-item.tsx/env-item';

export interface EnvBlockProps {
    providerId: number;
    system: IdpSystemFormValue;
    assignIdpSystemsByEnv: IdpEnvs;
    envs: Environments;
    fields: any;
    index: number;
    formApi: FormApi;
}

const EnvBlock = ({ providerId, system, assignIdpSystemsByEnv, envs, formApi, fields, index }: EnvBlockProps) => {
    const [confirmData, setConfirmData] = useState({
        showConfirm: false,
        content: null,
        confirm: null,
        title: '',
    });

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={confirmData.showConfirm}
                handleClose={() => setConfirmData({ ...confirmData, showConfirm: false })}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={'defaultButton'}
                buttonText={'I understand, continue'}
            />
        );
    }, [confirmData]);

    const isChecked = (system: IdpSystemFormValue, envId: number): boolean | null => {
        const checkedSystems = get(formApi.getState().values, `systems`);
        if (checkedSystems) {
            return checkedSystems.some((s) => s.guid === system.guid && s.wmEnv === envId);
        }
        return null;
    };

    const getSystemIndex = (system: IdpSystemFormValue, envId: number): number => {
        const checkedSystems = get(formApi.getState().values, `systems`);
        return checkedSystems.findIndex((s) => s.guid === system.guid && s.wmEnv === envId);
    };

    return (
        <>
            {confirmData.showConfirm && triggerConfirm()}
            {envs && envs.length && (
                <>
                    {envs.map((env) => {
                        return (
                            <EnvItem
                                key={`${system.guid}_${env.id}`}
                                providerId={providerId}
                                isChecked={isChecked}
                                getSystemIndex={getSystemIndex}
                                system={system}
                                systemEnv={env}
                                fields={fields}
                                assignTo={assignIdpSystemsByEnv.find((systemByEnv) => systemByEnv.id === env.id)}
                                index={index}
                                formApi={formApi}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

export default EnvBlock;
