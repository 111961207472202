import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';

export const CheckmarkIcon = (props: SvgIconProps) => {
  return <SvgIcon id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 15.2 15.2" {...props} >
      <g id="Icon_-_Success" data-name="Icon - Success">
        <path id="Path_11703" data-name="Path 11703" d="M10.86,4,6,10.18H5.9L4.36,8a.6.6,0,0,0-.84-.15.61.61,0,0,0-.14.84l1.51,2.15a1.25,1.25,0,0,0,1,.55,1.31,1.31,0,0,0,.71-.21,1.2,1.2,0,0,0,.28-.27l4.89-6.18a.622.622,0,0,0-1-.74Z" fill="#4164e3"/>
        <path id="Path_11704" data-name="Path 11704" d="M7.6,0a7.6,7.6,0,1,0,7.6,7.6A7.6,7.6,0,0,0,7.6,0Zm0,14A6.4,6.4,0,1,1,14,7.6,6.4,6.4,0,0,1,7.6,14Z" fill="#4164e3"/>
      </g>
  </SvgIcon>
}
