export const enum ValidationErrorType {
    FIELD_EMPTY = 'field-empty',
    INVALID_REGEX = 'invalid-regex',
    MISSING_PROTOCOL_PREFIX = 'missing-protocol-prefix',
    INVALID_DOMAIN_AND_PROTOCOL = 'invalid-domain-and-protocol',
    EMPTY_HOST = 'empty-host',
    EMPTY_PATH = 'empty-path',
    HOST_FORMAT_INVALID = 'host-format-invalid',
    LEADING_OR_TRAILING_SPACE = 'leading-or-trailing-space',
    LEADING_OR_DOUBLE_PIPES = 'leading-or-double-pipes',
    URL_TRAILING_SPACE = 'url-trailing-space',
    URL_CONTAINS_PIPE = 'url-contains-pipe',
}

export interface ValidationResult {
    isValid: boolean;
    errorMessage?: string;
    errorType?: ValidationErrorType;
}
