import React, { useCallback, useEffect, useMemo } from 'react';
import styledComponents from 'styled-components';
import { WMDataGrid, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import cc from 'classcat';
import { useDispatch, useSelector } from 'react-redux';
import { SystemsState } from '@walkme-admin-center/libs/state-management-systems';
import { AiHubDashboardState, getDashboardData } from '@walkme-admin-center/libs/state-mangment-data-integration';
import useFilters from '../../../../../libs/state-mangment-data-integration/src/lib/ai-center/filters/hooks/useFilters';
import useAiHubDashboard from '../../../../../libs/state-mangment-data-integration/src/lib/ai-center/AiHubDashboard/hooks/useAiHubDashboard';
import { getDates } from './service';
import AlertBanner from './components/AlertBanner/AlertBanner';
import classes from './ai-usage-page.module.scss';
import MonthPicker from './components/MonthPicker/MonthPicker';
import CapViewTable from './components/CapViewTable';
import ItemsUsageExpenseTable from './components/ItemsUsageExpenseTable';
import { ICapViewRowData, ICapViewRowsData } from '../../../../../libs/state-mangment-data-integration/src/lib/ai-center/types';

export const Container = styledComponents.div`
  display: flex;
  flex-direction: column;
`;

export const CssWMDataGrid = styledComponents(WMDataGrid)`
    & .WMDataGridHeader-wmDataGridHeader {
        padding: 24px 28px 12px 28px;
    }

`;

export const TablesScrollContainer = styledComponents.div`
    overflow: auto;
    margin-top: 20px;
    height: calc(100vh - 300px);
`;

const AiUsagePage = () => {
    const systems = useSelector((state: { systemsState: SystemsState }) => state.systemsState.systems);
    const aiSettings = useSelector((state: { aiHubDashboardState: AiHubDashboardState }) => state.aiHubDashboardState.settings);

    const filters = useFilters();

    const dispatch = useDispatch();

    useEffect(() => {
        //@ts-ignore
        dispatch(getDashboardData({ ...filters }));
    }, [dispatch, filters]);

    const { capViewTableData, itemsUsageExpenseRowData } = useAiHubDashboard();

    const dates = useMemo(() => {
        return getDates();
    }, []);

    return (
        <Container>
            {(capViewTableData.error || itemsUsageExpenseRowData.error) && (
                <WMSnackbar
                    style={{ marginTop: '60px' }}
                    open={true}
                    variant={WMSnackbarVariant.Error}
                    message={'Unexpected error occurred, please try again'}
                />
            )}
            <span className={cc([classes.pageHeader])}>AI Usage</span>
            <AlertBanner aiSettings={aiSettings.data} />
            <MonthPicker hideQuarter availableDates={dates} isDisabled={false} />
            <TablesScrollContainer>
                <ItemsUsageExpenseTable
                    loading={itemsUsageExpenseRowData.loading}
                    data={itemsUsageExpenseRowData.data}
                    allSystems={systems.data}
                    integrations={aiSettings.data.integrations}
                    fullHeight={Object.keys(aiSettings.data.systemsCap).length === 0}
                />
                {Object.keys(aiSettings.data.systemsCap).length > 0 && (
                    <CapViewTable
                        loading={capViewTableData.loading}
                        data={capViewTableData.data}
                        allSystems={systems.data}
                        integrations={aiSettings.data.integrations}
                    />
                )}
            </TablesScrollContainer>
        </Container>
    );
};
export default AiUsagePage;
