import { MatcherChanges, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import isEqual from 'lodash/isEqual';

const getMatcherChanges = (originalMatcher: SystemMatcher, currentMatcher: SystemMatcher): MatcherChanges => {
    const oldValues = {};
    const newValues = {};

    Object.keys(currentMatcher).forEach((key) => {
        if (!isEqual(currentMatcher[key], originalMatcher[key])) {
            oldValues[key] = originalMatcher[key];
            newValues[key] = currentMatcher[key];
        }
    });

    return { oldValues, newValues };
};

export { getMatcherChanges };
