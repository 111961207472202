import styled from 'styled-components';
import { Input, Toaster } from '@walkme/ui-core';
import { WMTooltip } from '@walkme/wm-ui';
import { IconCopy } from '../../../../../../../self-hosting/src/lib/icons/icon-copy';

export const StyledUUIDBodyContainer = styled.div`
    width: 100%;
`;

export const StyledInput = styled(Input)`
    & input {
        height: 40px;
    }

    & input:focus {
        border-width: 1px;
        outline: none;
    }

    & input:disabled {
        background-color: var(--Primaries-Disable-Disable-tint-8, #f8f8f8);
        color: rgba(200, 200, 200, 1);
    }
`;

export const StyledVariableInputContainer = styled.div`
    display: flex;
`;

export const StyledRecommendedValuesContainer = styled.div`
    position: relative;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    width: 200px;
    margin-left: 8px;
`;

export const StyledRecommendedValues = styled.div<{ isWindowOpen: boolean }>`
    position: absolute;
    top: 0;
    padding: 8px 10px;
    border-radius: 4px;
    box-shadow: ${({ isWindowOpen }) => (isWindowOpen ? '0px 36px 52px 0px rgba(47, 61, 83, 0.11)' : 'none')};
    border: ${({ isWindowOpen }) => (isWindowOpen ? '1px solid var(--Color-Local-Semantic-Bg-primary, #F0F3F9)' : '1px solid white')};
`;

export const StyledRecommendedValueHeader = styled.div<{ isWindowOpen: boolean }>`
    display: flex;
    align-items: center;
    width: 200px;
    height: 100%;
    cursor: pointer;
    color: ${({ isWindowOpen }) => (isWindowOpen ? '#385feb' : '#637191')};
    font-weight: 600;
    font-size: 16px;

    &:hover {
        color: var(--Color-Local-Semantic-Bg-accent, #385feb);
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const StyledInputLabel = styled.div`
    line-height: 16px;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 12px;
    color: rgb(47, 66, 108);
    padding-bottom: 8px;
`;

export const StyledExpectedFormatInputContainer = styled.div`
    margin: 24px 0 24px 0;
`;

export const StyledRegexpInputContainer = styled.div`
    margin: 24px 0 24px 0;
`;

export const StyledExpectedFormatTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 32px 0 8px 0;

    .main-title {
        font-size: 14px;
    }

    .description {
        display: flex;
        color: var(--Color-Semantic-Text-secondary, #637191);
        font-weight: 200;
        margin-top: 4px;
        align-items: center;

        .description-text {
            margin-right: 4px;
        }
    }
`;

export const StyledRecommendedValuesBody = styled.div`
    margin-top: 8px;

    .description {
        color: var(--Color-Global-Semantic-On-light-secondary-1, #637191);
        font-family: 'Proxima Nova';
        font-size: 12px;
    }
`;

export const StyledRecommendedValue = styled.div`
    border-radius: 4px;
    background: var(--Color-Global-Semantic-On-light-primary-7, rgba(56, 95, 235, 0.05));
    padding: 8px;
    margin-top: 8px;
    display: flex;

    &:hover {
        background: var(--Color-Global-Semantic-On-light-primary-6, rgba(56, 95, 235, 0.09));
    }

    .text {
        width: 90%;
        color: var(--Color-Local-Semantic-Text-secondary, #637191);
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
        display: inline-block;
        white-space: nowrap;
    }
    .copy {
    }
`;

export const StyledWMTooltip = styled(WMTooltip)`
    &.WMTooltip-wmTooltip {
        box-sizing: border-box;
        padding: 6px 12px;
        background-color: #233251;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        max-width: 312px;
    }

    & .WMTooltip-htmlWrapper {
        padding: 0;
    }

    & .WMTooltip-arrow {
        color: #233251;
    }
`;

export const StyledCopyIcon = styled(IconCopy)`
    cursor: pointer;
`;

export const StyledToasterContainer = styled(Toaster)`
    margin-bottom: 20px;
    box-shadow: none;
`;
