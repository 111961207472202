import React, { useEffect, useState, useCallback, useMemo } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getComparator, stableSort, filterData } from './utils';
import Head from '../table-head/table-head';
import { TableHeadCell, TableRowData, Order } from '@walkme-admin-center/libs/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox } from '../checkbox/checkbox';
import Footer, { FooterButtonsConfig, FooterLocale } from '../table-footer/table-footer';
import EmptySearch, { EmptySearchProps } from '../empty-search/empty-search';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { WMDivider } from '@walkme/wm-ui';
import {
    Cell,
    LoadingContainer,
    StyledButton,
    StyledCheckboxCell,
    StyledContainer,
    StyledHeader,
    StyledPaper,
    StyledSearchInput,
    StyledTable,
    StyledTableBody,
    StyledTableContainer,
} from './data-table.styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import * as locales from '@material-ui/core/locale';

export interface DataTableLocale {
    emptySearchLocale?: EmptySearchProps;
    footerLocale?: FooterLocale;
}

export interface DataTableProps {
    data: TableRowData[];
    buttonText?: string;
    buttonWidth?: number;
    heads?: TableHeadCell[];
    title?: JSX.Element;
    hasToolbar?: boolean;
    loading?: boolean;
    rowsPerPage?: number;
    onSortChange?: (s: string) => void;
    onRowsPerPageChange?: (n: number) => void;
    currentPage?: number;
    onPageChange?: (n: number) => void;
    config?: Record<string, number[]>;
    currentOrder?: Order;
    currentOrderBy?: string;
    onSetOrderBy?: (u: unknown) => void;
    onToolbarButtonClick?: () => void;
    onRowClick?: (n: unknown) => void;
    onDataChanged?: (n: unknown[]) => void;
    customToolbarButton?: JSX.Element;
    rowsSelectable?: boolean;
    rowsSelectableDisabled?: boolean;
    onAllRowsToggled?: (areAllSelected: boolean) => void;
    onRowToggled?: (n: unknown, isSelected: boolean) => void;
    showFooter?: boolean;
    footerButtons: FooterButtonsConfig[];
    footerMessage?: string | JSX.Element;
    tableHeader?: JSX.Element;
    query?: string;
    serverSideRender?: boolean;
    serverSidePropsRenderProps?: any;
    jobsCount?: number;
    disableEmptyRows?: boolean;
    showSearchInput?: boolean;
    hideSort?: boolean;
    emptyTableButton?: JSX.Element;
    handleQueryChange?: (sortBy: string, sortOrder: Order) => void;
    rowStyle?: object;
    searchPlaceholder?: string;
    muiLocale?: string;
    locale?: DataTableLocale;
}

const getCellDisplayValue = (cellData: any) => {
    const value = cellData instanceof Object && cellData.hasOwnProperty('displayValue') ? cellData.displayValue : cellData;

    if (value instanceof Date && value.toString() === 'Invalid Date') {
        return 'Invalid Date';
    } else if (value instanceof Date) {
        return `${value.toDateString()} ${value.toTimeString().slice(0, 8)}`;
    }

    return value;
};

const getRowId = (row) => {
    return row.id;
};

export const DataTable = ({
    data = [],
    buttonText,
    buttonWidth,
    heads = [],
    title,
    hasToolbar,
    loading,
    onRowsPerPageChange,
    rowsPerPage,
    onPageChange,
    currentPage,
    config,
    onSortChange,
    currentOrder,
    currentOrderBy,
    onSetOrderBy,
    onToolbarButtonClick,
    onRowClick,
    rowsSelectable,
    rowsSelectableDisabled,
    onAllRowsToggled,
    onRowToggled,
    showFooter = true,
    footerButtons,
    footerMessage,
    onDataChanged,
    customToolbarButton,
    tableHeader,
    query,
    serverSideRender,
    serverSidePropsRenderProps,
    handleQueryChange,
    disableEmptyRows = false,
    showSearchInput = true,
    hideSort = false,
    emptyTableButton,
    rowStyle,
    searchPlaceholder,
    muiLocale,
    locale,
}: DataTableProps) => {
    const { rowsPerPageOptions } = config;
    const [myQuery, setMyQuery] = useState(query);
    const [filteredRows, setFiltered] = useState(data);
    const updatedPage = serverSideRender
        ? serverSidePropsRenderProps.currentPage
        : useMemo(() => (filteredRows.length / rowsPerPage > currentPage ? currentPage : 0), [currentPage, filteredRows]);
    const [selectedRows, setSelectedRows] = useState({});
    const [indeterminateSelectedRows, setIndeterminateSelectedRows] = useState(false);
    const [allRowsSelected, setAllRowsSelected] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        const selectedRows = data.reduce((obj: object, row: any) => {
            obj[row.id] = false;

            return obj;
        }, {});

        setSelectedRows(selectedRows);
        setAllRowsSelected(false);
        setIndeterminateSelectedRows(false);
        setFiltered(data);
    }, [data]);

    const handleInputChange = useCallback(
        (e) => {
            setMyQuery(e.target.value);
        },
        [setMyQuery]
    );

    const cancelSearch = useCallback(() => {
        setMyQuery('');
    }, [setMyQuery]);

    const saveFilteredData = useCallback(() => {
        const filteredData = filterData(data, myQuery);
        setFiltered(filteredData);
        onDataChanged && onDataChanged(filteredData);
    }, [setFiltered, data, myQuery, onDataChanged]);

    useEffect(() => {
        const timeout = setTimeout(saveFilteredData, 0, filterData(data, myQuery));
        return () => clearTimeout(timeout);
    }, [data, myQuery]);

    const handleRequestSort = useCallback(
        (event: React.MouseEvent<unknown>, property: unknown) => {
            const isAsc = currentOrder === 'asc';
            const actualSort = isAsc ? 'desc' : 'asc';
            onSortChange(actualSort);
            onSetOrderBy(property);

            if (handleQueryChange) {
                handleQueryChange(property as string, actualSort);
            }
        },
        [onSortChange, onSetOrderBy, currentOrder]
    );

    const handleChangePage = useCallback(
        (event: unknown, newPage: number) => {
            onPageChange(newPage - 1);
        },
        [onPageChange]
    );

    const handleItemClick = (row) => {
        onRowClick(row.id || row.roleId);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedRowsPerPageNum = parseInt(event.target.value);
        onRowsPerPageChange(selectedRowsPerPageNum);
        onPageChange(0);
    };

    const handleRowSelected = (row) => {
        const currentSelectedRows = !selectedRows[row.id];
        const updatedSelectedRows = {
            ...selectedRows,
            [row.id]: currentSelectedRows,
        };
        const count = Object.values(updatedSelectedRows).reduce((count: number, isRowSelected) => {
            if (isRowSelected) {
                count++;
            }

            return count;
        }, 0) as number;

        const allRowsSelected = count === data.length;

        setSelectedRows(updatedSelectedRows);
        setAllRowsSelected(allRowsSelected);
        setIndeterminateSelectedRows(!allRowsSelected && count > 0);

        onRowToggled(row.id, currentSelectedRows);

        if (allRowsSelected || count === 0) {
            onAllRowsToggled(allRowsSelected);
        }
    };

    const handleAllRowsSelected = () => {
        let updatedSelectedRows;
        let updatedAllRowsSelected;

        if (allRowsSelected || indeterminateSelectedRows) {
            updatedSelectedRows = Object.keys(selectedRows).reduce((obj: object, rowKey) => {
                obj[rowKey] = false;

                return obj;
            }, {});

            updatedAllRowsSelected = false;
        } else {
            updatedSelectedRows = Object.keys(selectedRows).reduce((obj: object, rowKey) => {
                obj[rowKey] = true;

                return obj;
            }, {});

            updatedAllRowsSelected = true;
        }

        setAllRowsSelected(updatedAllRowsSelected);
        setIndeterminateSelectedRows(false);
        setSelectedRows(updatedSelectedRows);
        onAllRowsToggled(updatedAllRowsSelected);
    };

    const emptyRows = serverSideRender
        ? rowsPerPage - filteredRows.length
        : rowsPerPage - Math.min(rowsPerPage, filteredRows.length - updatedPage * rowsPerPage);
    let sortedRows;

    if (!handleQueryChange) {
        sortedRows = stableSort(filteredRows, getComparator(currentOrder, currentOrderBy));
    } else {
        sortedRows = filteredRows;
    }

    const slicedSortedRows = serverSideRender
        ? sortedRows
        : sortedRows.slice(updatedPage * rowsPerPage, updatedPage * rowsPerPage + rowsPerPage);

    const MainTable = () => (
        <StyledTable aria-labelledby='tableTitle' aria-label='enhanced table'>
            <Head
                order={currentOrder}
                orderBy={currentOrderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredRows.length}
                heads={heads}
                rowsSelectable={rowsSelectable}
                rowsSelectableDisabled={rowsSelectableDisabled}
                indeterminateSelectedRows={indeterminateSelectedRows}
                allRowsSelected={allRowsSelected}
                onAllRowsToggled={handleAllRowsSelected}
                hideSort={hideSort}
            />
            {filteredRows.length === 0 ? (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={5} align='center'>
                            <EmptySearch
                                title={locale?.emptySearchLocale?.title}
                                text={locale?.emptySearchLocale?.text}
                                textWithQuery={locale?.emptySearchLocale?.text}
                                query={myQuery}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            ) : (
                <StyledTableBody>
                    {slicedSortedRows.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                className={`data-row table-row-hover`}
                                hover
                                classes={rowStyle}
                                style={{ height: '40px' }}
                                role='checkbox'
                                tabIndex={-1}
                                key={index}>
                                {rowsSelectable && (
                                    <StyledCheckboxCell id='checkItems' scope='row'>
                                        <Checkbox
                                            checked={selectedRows[getRowId(row)] || false}
                                            onChange={() => handleRowSelected(row)}
                                            disabled={rowsSelectableDisabled}
                                        />
                                    </StyledCheckboxCell>
                                )}
                                {heads.map((head) => (
                                    <Cell key={head.id} id={labelId} scope='row' onClick={() => handleItemClick(row)}>
                                        {getCellDisplayValue(row[head.id])}
                                    </Cell>
                                ))}
                            </TableRow>
                        );
                    })}
                    {!disableEmptyRows && emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <Cell colSpan={6} />
                        </TableRow>
                    )}
                </StyledTableBody>
            )}
            {showFooter && (
                <Footer
                    rowsPerPageOptions={rowsPerPageOptions}
                    rowsLength={serverSideRender ? serverSidePropsRenderProps.jobsCount : filteredRows.length}
                    colsNumber={heads.length + (rowsSelectable ? 1 : 0)}
                    rowsPerPage={serverSideRender ? serverSidePropsRenderProps.rowsPerPage : rowsPerPage}
                    currentPage={updatedPage}
                    onChangePage={serverSideRender ? serverSidePropsRenderProps.handleChangePage : handleChangePage}
                    onChangeRowsPerPage={serverSideRender ? serverSidePropsRenderProps.onChangeRowsPerPage : handleChangeRowsPerPage}
                    buttons={footerButtons}
                    message={footerMessage}
                    serverSideRender={serverSideRender}
                    {...serverSidePropsRenderProps}
                    footerLocale={locale?.footerLocale}
                />
            )}
        </StyledTable>
    );

    return (
        <StyledContainer>
            <StyledHeader>
                <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                    <Grid item style={{ marginBottom: '10px' }}>
                        {tableHeader}
                    </Grid>
                    <Grid item style={{ marginBottom: '10px' }}>
                        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                            {showSearchInput && (
                                <Grid item>
                                    <StyledSearchInput
                                        placeholder={searchPlaceholder || 'Enter search keywords'}
                                        onChange={handleInputChange}
                                        value={myQuery}
                                        startAdornment={
                                            <img
                                                src='assets/icons/search.svg'
                                                alt='system added'
                                                style={{ width: '14px', height: '14px' }}
                                            />
                                        }
                                        endAdornment={
                                            <IconButton onClick={cancelSearch} edge='end'>
                                                <img src='assets/icons/close.svg' alt='system added' />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            )}

                            {customToolbarButton}
                            {!customToolbarButton && (
                                <Grid item>
                                    <StyledButton
                                        style={{ width: `${buttonWidth || '150'}px` }}
                                        onClick={onToolbarButtonClick}
                                        variant='contained'
                                        startIcon={<AddRoundedIcon />}>
                                        {buttonText}
                                    </StyledButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </StyledHeader>
            <WMDivider />
            <StyledPaper>
                {loading && !data.length ? (
                    <LoadingContainer>
                        <CircularProgress size={50} thickness={4} />
                    </LoadingContainer>
                ) : (
                    <StyledTableContainer>
                        {muiLocale ? (
                            <ThemeProvider theme={createTheme(theme, locales[muiLocale])}>
                                <MainTable></MainTable>
                            </ThemeProvider>
                        ) : (
                            <MainTable></MainTable>
                        )}
                    </StyledTableContainer>
                )}
            </StyledPaper>
        </StyledContainer>
    );
};

DataTable.defaultProps = {
    data: [],
    buttonText: '',
    title: '',
    hasToolbar: false,
    config: {},
    rowsPerPageOptions: [],
    rowsPerPage: 10,
    currentPage: 0,
    rowsSelectable: false,
    showFooter: true,
    footerButtons: [],
    query: '',
};
