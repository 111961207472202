import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

interface IdentifierRowErrorMessageProps {
    errorMessage: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            display: 'flex', 
            flex: 1,
            width: '430px',
            color: styledTheme.colors.errorColor,
            marginTop: '10px'
        },
        errorIcon: {
            color: styledTheme.colors.errorColor
        }
    })
);

const IdentifierRowErrorMessage = ({
    errorMessage
}: IdentifierRowErrorMessageProps) => {
    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <ReportProblemOutlinedIcon className={`inline-icon ${classes.errorIcon}`} />
            <Typography>
                {errorMessage}
            </Typography>
        </div>
    );
};

export default IdentifierRowErrorMessage;
