import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ActionsTree } from '../actions-tree/actions-tree';
import { FormActions as IFormActions } from '@walkme-admin-center/libs/types';
import { ActionsGraph } from '../utils/create-actions-graph';
import keyBy from 'lodash/keyBy';
import clone from 'lodash/clone';
import CloseIcon from '@material-ui/icons/Close';
import { Permission, Role } from 'wm-accounts-sdk';
import { Box, Divider, Drawer, TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { StyledPageTitleLabel } from '../../users.styles';
import { WMButton, WMButtonVariant, WMTextField } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';

const StyledWMButton = styled(WMButton)`
    margin-left: 16px;
    width: 80px;
    height: 40px;
    border-radius: 40px;
`;

const StyledBox = styled(Box)`
    padding: 32px;
    overflow: hidden;
`;

const StyledDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        width: 480px;
    }
    & .MuiBackdrop-root {
        background: rgba(47, 66, 108, 0.61);
    }
`;

const DrawerHeaderContainer = styled.div`
    height: fit-content;
    min-height: 68px;
`;

const DrawerHeaderStyled = styled.div`
    margin: 24px 32px 16px 32px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const StyledDivider = styled(Divider)`
    margin: 20px 32px 32px;
`;

const DrawerContentContainer = styled.div`
    height: 100%;
    overflow-y: overlay;
`;

const DrawerContentStyled = styled.div`
    margin: 16px 32px 0 32px;
`;

const DrawerButtonsContainer = styled.div`
    height: 88px;
    min-height: 88px;
`;

const DrawerButtonsStyled = styled.div`
    margin: 16px 32px 32px 32px;
    justify-content: flex-end;
    display: flex;
`;

export interface RoleFormProps {
    actionsGraph: ActionsGraph;
    role?: Role | null;
    actions?: IFormActions;
    title?: string;
    submittingRole?: boolean;
    deletingRole?: boolean;
    showDrawer?: boolean;
    onClose: () => void;
}

export interface RoleFormValues {
    existingRole: Role | null;
    roleName: string;
    permissions: Record<string, string>;
    allowedActions?: string[];
}

const getInitialPermissions = (actionsGraph: ActionsGraph, permissions: Record<string, Permission>) => {
    const result: Record<string, string> = {};
    const stack: ActionsGraph = clone(actionsGraph);
    while (stack.length) {
        const node = stack.pop();
        result[node.key] = permissions[node.key] ? permissions[node.key].permission : 'None';
    }
    return result;
};

export const RoleForm = ({ actionsGraph, role, actions, title, submittingRole, showDrawer, onClose }: RoleFormProps) => {
    const { t, rt } = useTranslation('general');
    const [roleName, setRoleName] = useState<string>(role ? rt(role.displayName) : '');
    const [permissions, setPermissions] = useState<Record<string, string>>(
        getInitialPermissions(actionsGraph, keyBy(role ? role.permissions : [], 'actionKey'))
    );

    const [initialValues, setInitialValues] = useState<{ permissions: Record<string, string>; roleName: string }>({
        permissions: {},
        roleName: '',
    });

    useEffect(() => {
        setInitialValues({
            permissions: getInitialPermissions(actionsGraph, keyBy(role ? role.permissions : [], 'actionKey')),
            roleName,
        });
    }, []);

    const [dirty, setDirty] = useState<boolean>(false);

    const allowedActions = useMemo(() => actionsGraph.filter((action) => action.category).map((action) => action.key), [actionsGraph]);

    const isPredefinedRole = role ? !!!role.accountId : false;

    const onPermissionsChange = useCallback(
        (permissions: Record<string, string>) => {
            setPermissions({ ...permissions });
        },
        [setPermissions]
    );

    useEffect(() => {
        JSON.stringify({ permissions: initialValues.permissions, roleName: initialValues.roleName }) !=
        JSON.stringify({ permissions, roleName })
            ? setDirty(true)
            : setDirty(false);
    }, [roleName, permissions, initialValues]);

    const DrawerHeader = (title: string, onClose: () => void) => {
        return (
            <DrawerHeaderContainer>
                <DrawerHeaderStyled>
                    <label style={{ fontStyle: 'normal', fontWeight: 600, fontSize: '18px', lineHeight: '28px' }}>{title}</label>
                    <CloseIcon onClick={onClose} />
                </DrawerHeaderStyled>
                <StyledDivider />
            </DrawerHeaderContainer>
        );
    };

    const DrawerContent = () => {
        return (
            <DrawerContentContainer>
                <DrawerContentStyled>
                    {role && role.shortDescription && (
                        <StyledPageTitleLabel id='form-title' dangerouslySetInnerHTML={{ __html: rt(role.shortDescription) }} />
                    )}
                    <Box>
                        {!isPredefinedRole ? (
                            <Box style={{ padding: '0px 6px' }}>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, paddingBottom: '10px' }}>
                                    {t(`users-and-roles-tab.roles.custom-roles-tab.role-form.role-name`)}
                                </Typography>
                                <WMTextField
                                    ds2
                                    onChange={(e) => setRoleName(e.target.value)}
                                    value={roleName}
                                    status={roleName === '' ? 'warning' : 'default'}
                                />
                                {roleName === '' && (
                                    <label
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            color: '#B98800',
                                            fontSize: '10px',
                                            marginTop: '5px',
                                        }}>
                                        {t(`users-and-roles-tab.roles.custom-roles-tab.role-form.role-name-warning`)}
                                    </label>
                                )}
                            </Box>
                        ) : null}
                        <ActionsTree
                            actionsGraph={actionsGraph}
                            currentValues={{ existingRole: role, permissions, roleName } as RoleFormValues}
                            onPermissionsChange={onPermissionsChange}
                            isPredefinedRole={isPredefinedRole}
                        />
                    </Box>
                </DrawerContentStyled>
            </DrawerContentContainer>
        );
    };

    const DrawerButtons = () => {
        return (
            <DrawerButtonsContainer>
                <DrawerButtonsStyled>
                    <StyledWMButton onClick={() => actions && actions.onCancel(!dirty)} variant={WMButtonVariant.Secondary}>
                        {t('buttons.cancel')}
                    </StyledWMButton>
                    <StyledWMButton
                        style={{ marginLeft: '16px' }}
                        onClick={() => actions.onConfirm({ existingRole: role, permissions, roleName, allowedActions })}
                        loading={submittingRole}
                        disabled={submittingRole || !dirty || !roleName}>
                        {t('buttons.save')}
                    </StyledWMButton>
                </DrawerButtonsStyled>
            </DrawerButtonsContainer>
        );
    };

    return (
        <StyledDrawer anchor='right' open={showDrawer} onClose={actions.onCancel}>
            {DrawerHeader(title, onClose)}
            {DrawerContent()}
            {DrawerButtons()}
        </StyledDrawer>
    );
};

export default RoleForm;
