import styled from 'styled-components';
import { IconButton } from '@walkme/ui-core';

export const StyledFieldPropertiesContainer = styled.div`
    display: flex;
    flex-direction: row;

    &.alignItemsCenter {
        align-items: center;
    }
`;

export const StyledIconButton = styled(IconButton)`
    padding: 0;
    margin-left: 2px;
    color: #7083a7;
`;
