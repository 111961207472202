import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState, getMfaSettings } from '../redux/account.slice';

export const useMfaSettings = (forceLoad = true) => {
    const mfaSettingsAppData = useSelector((state: { accountState: AccountState }) => state.accountState.mfaSettings);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMfaSettings(forceLoad));
    }, [dispatch]);

    return {
        mfaSettingsAppData,
    };
};

export default useMfaSettings;
