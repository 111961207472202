import moment from 'moment';
import { SystemSettings } from 'wm-accounts-sdk';
import { IDateRange } from '../../../../../libs/state-mangment-data-integration/src/lib/ai-center/types';

export const getDates = () => {
    const date = moment().subtract(1, 'years').subtract(7, 'months');

    const result = [];

    for (let i = 0; i <= 19; i++) {
        const fromDate = moment(date).add(i, 'months').startOf('month');
        const toDate = moment(fromDate).endOf('month');

        result.push({ fromDate: fromDate.format('YYYY-MM-DD'), toDate: toDate.format('YYYY-MM-DD') });
    }

    return result;
};

export const getCurrnetMonthDateRange = (): IDateRange => {
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    return {
        fromDate: startOfMonth.format('YYYY-MM-DD'),
        toDate: endOfMonth.format('YYYY-MM-DD'),
    };
};

export const retrieveSlackIcon = () => {
    return 'assets/icons/data-integrations/integrations-icons/system-icons/slack-icon-small.svg';
};

export const retrieveTeamsIcons = () => {
    return 'assets/icons/data-integrations/integrations-icons/system-icons/teams-icon-small.svg';
};

export const retrieveAiWelcomeImage = () => {
    return 'assets/icons/data-integrations/ai-welcome-page.png';
};
