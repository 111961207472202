import { useState, useEffect, useCallback } from 'react';

function useLocalStorage(key: string): [string, (value: string) => void] {
    // Initialize state from localStorage or default value
    const [state, setState] = useState<string>(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue;
    });

    // Update localStorage when state changes
    const setStateAndStorage = useCallback(
        (value: string) => {
            setState(value);
            localStorage.setItem(key, value);
        },
        [key]
    );

    // Handle localStorage changes from other tabs/windows
    const handleStorageChange = useCallback(
        (event: StorageEvent) => {
            if (event.key === key) {
                const newValue = event.newValue;
                setState(newValue);
            }
        },
        [key]
    );

    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [handleStorageChange]);

    useEffect(() => {
        const storedValue = localStorage.getItem(key);
        setState(storedValue);
    }, [key]);

    return [state, setStateAndStorage];
}

export function setLocalStorage(key: string, newValue: string) {
    const oldValue = localStorage.getItem(key);
    localStorage.setItem(key, newValue);

    const storageEvent = new StorageEvent('storage', {
        key,
        newValue,
        oldValue,
        storageArea: localStorage,
        url: window.location.href,
    });

    window.dispatchEvent(storageEvent);
}

export default useLocalStorage;
