import React from 'react';
import ConfirmationDialog from './confirmation-dialog';

interface UnsavedChangesConfirmationDialogProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const UnsavedChangesConfirmationDialog = ({ isOpen, onConfirm, onCancel }: UnsavedChangesConfirmationDialogProps) => {
    return (
        <ConfirmationDialog
            isOpen={isOpen}
            title='Unsaved Changes'
            message='Are you sure you want to continue? Your changes will be lost.'
            confirmButtonColor='danger'
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
};

export default UnsavedChangesConfirmationDialog;
