import React, { useContext } from 'react';
import Routes from './routes';
import { WMStylesProvider } from '@walkme/wm-ui';
import { Main } from '@walkme-admin-center/libs/shared-styles';
import { LoadingPage, SideBar, TopBar } from '@walkme-admin-center/libs/common';
import { useAuth } from 'wm-accounts-auth';
import { useLocation } from 'react-router-dom';
import { PublicRoutes } from './public-routes';
import { ComponentRoutes } from './component-routes';
import { OnboardingWidget } from '@walkme/onboarding-widget';
import { ToasterProvider } from '@walkme/ui-core';

const ComponentRoutesPaths = ['/users-full', '/systems-requests'];

const PublicRoutesPrefix = ['/signin'];

export const ComponentApp = () => {
    return (
        <WMStylesProvider>
            <ComponentRoutes />
        </WMStylesProvider>
    );
};

export const PublicApp = () => {
    return (
        <WMStylesProvider>
            <PublicRoutes />
        </WMStylesProvider>
    );
};

export const App = () => {
    return (
        <WMStylesProvider>
            {window.clientConfig.NX_ONBOARDING_APP_URL && <OnboardingWidget onboardingUrl={window.clientConfig.NX_ONBOARDING_APP_URL} />}
            <Main>
                <TopBar />
                <div id='mainPage' className='mainPage'>
                    <SideBar />
                    <div id='mainContent' className='mainContent'>
                        <Routes />
                    </div>
                </div>
            </Main>
        </WMStylesProvider>
    );
};

export default () => {
    const location = useLocation();
    if (PublicRoutesPrefix.filter((publicRoute) => location.pathname.startsWith(publicRoute)).length > 0) {
        return <PublicApp />;
    }
    const shouldEnableSilentLoginFeature = window.clientConfig.NX_AUTH_SILENT_LOGIN_FEATURE === 'true';
    const authenticated = useAuth(shouldEnableSilentLoginFeature);
    if (!authenticated) {
        return <LoadingPage />;
    }

    const isComponentRoutes = ComponentRoutesPaths.filter((path) => location.pathname.startsWith(path)).length > 0;

    if (isComponentRoutes) {
        console.log(`isComponentRoutes`);
        return <ComponentApp />;
    }

    return (
        <ToasterProvider>
            <App />
        </ToasterProvider>
    );
};
