import styled from 'styled-components';

const FormWarningBox = styled.div`
    display: flex;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    align-items: flex-start;
    gap: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #f7b500;
    background: #fef8e8;
    margin: 0.75rem 0;
`;

export default FormWarningBox;
