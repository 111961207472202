import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState, getSessionLifetime } from '../redux/account.slice';

export const useSessionLifetime = () => {
    const sessionLifetimeAppData = useSelector((state: { accountState: AccountState }) => state.accountState.sessionLifetime);
    const sessionLifetimeOptionsAppData = useSelector((state: { accountState: AccountState }) => state.accountState.sessionLifetimeOptions);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSessionLifetime());
    }, [dispatch]);

    return { sessionLifetimeAppData, sessionLifetimeOptionsAppData };
};

export default useSessionLifetime;
