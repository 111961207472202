import styled from 'styled-components';

export const VideoStepNumber = styled.b`
    color: #2f426c;
`;

export const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 28px;
    gap: 20px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 16px 24px 0px rgba(47, 61, 83, 0.11);
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 633px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    .flex-row {
        display: flex;
        flex-direction: row;
    }
`;

export const TitleText = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    olor: #2f426c;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`;

export const BoldText = styled.div`
    color: #2f426c;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;
export const SmallBoldText = styled.span`
    color: #2f426c;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
`;

export const SubtitleText = styled.div`
    align-items: center;
    margin-left: 30px;
    gap: 3px;
    align-self: stretch;
    color: #637191;
    line-height: 22px;
`;

export const ImageContainer = styled.div`
    position: relative;
`;

export const Image = styled.img`
    display: flex;
    width: 632px;
    height: 336px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #edf1fd;

    &.expanded {
        width: 1180px;
        height: 691px;
    }
`;

export const ExpandButtonContainer = styled.div`
    position: absolute;
    right: 12px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background: #fff;
    margin-left: auto;
    cursor: pointer;
    box-shadow: 0px 4px 12px 0px rgba(47, 61, 83, 0.3);
    &.close {
        top: 20px;
        right: 20px;
    }

    &.expand {
        bottom: 12px;
    }
`;

export const CssVideoContainer = styled.div`
    position: relative;
`;

export const VideoOverlay = styled.div<{ isPlaying: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(47, 66, 108, 0.61);
    display: ${(props) => (props.isPlaying ? 'none' : 'block')};
    border-radius: 8px;
`;

export const Video = styled.video`
    display: flex;
    width: 632px;
    height: 336px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #edf1fd;
    cursor: pointer;
`;

export const PlayButtonContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 1px solid rgba(59, 97, 235, 0.2);
    background: #fff;
    cursor: pointer;

    &.exapnded {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const CssExpandedVideoContainer = styled.div`
    position: relative;
`;

export const ExpandedVideo = styled.video`
    display: flex;
    width: 1180px;
    height: 691px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #f7f9fc;
    box-shadow: 0px 36px 52px 0px rgba(47, 61, 83, 0.11);

    @media screen and (max-width: 768px) {
        width: 90%;
        height: auto;
    }
`;

export const CssExpanded = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledLink = styled.a`
    cursor: pointer;
    font-weight: 600;
    text-decoration-line: underline;
    color: #385feb;
`;

export const SubtitleTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
