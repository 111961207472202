/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ThunkAction } from 'redux-thunk';
import { AppData } from '@walkme-admin-center/libs/types';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import { askMeApi, askMeAppApi } from '@walkme-admin-center/libs/ask-me-api';
import { deployablesApi, IDeployableUser } from '@walkme-admin-center/libs/deployables-api';
import {
    IAskMe,
    IAskMeFormSubmit,
    IAskMeSystems,
    IAsksMe,
    ICreateAskmeResponse,
    IDataIntegrationAppSettings,
    IExecute,
    IExecutionsStatus,
    IWhiteList,
    IIntegration,
    IIntegrationFormSubmit,
    IIntegrations,
    IUpdateAskmeResponse,
    IAskMeFileStore,
    IAskMeFile,
    IAskMeFormState,
    IGlobalSettings,
    IGuidedAnswersBulkUpdateData,
    Status,
    ScanSummaryType,
} from '../types';
import { keyBy } from 'lodash';

export interface DataIntegrationState {
    askMeIntegrations: AppData<IAsksMe>;
    settings: AppData<IDataIntegrationAppSettings>;
    integrations: AppData<IIntegrations>;
    files: AppData<IAskMeFileStore>;
    form: AppData<IAskMeFormState>;
    globalSettings: AppData<IGlobalSettings>;
}

export const initialDataIntegrationsState: DataIntegrationState = {
    askMeIntegrations: {
        data: [],
        error: null,
        loading: true,
    },
    integrations: {
        data: [],
        error: null,
        loading: true,
    },
    settings: {
        data: {
            whiteList: null,
        },
        error: null,
        loading: true,
    },
    files: {
        data: {},
        error: null,
        loading: false,
    },
    form: {
        data: {},
        error: null,
        loading: false,
    },
    globalSettings: {
        data: {
            isAdminMode: false,
        },
        error: null,
        loading: false,
    },
};

const dataIntegrationsSlice = createSlice({
    name: 'dataIntegrationsSlice',
    initialState: initialDataIntegrationsState,
    reducers: {
        startGetAskMeIntegrations(state: DataIntegrationState) {
            state.askMeIntegrations.loading = true;
            return state;
        },
        getAskMeIntegrationsSuccess(state: DataIntegrationState, action: PayloadAction<{ askMe: IAsksMe; status: IExecutionsStatus }>) {
            state.askMeIntegrations.loading = false;
            state.askMeIntegrations.data = action.payload.askMe.map((res) => {
                const updatedStatus = action.payload.status.find((s) => s.sourceGuid === res.sourceId);
                if (!updatedStatus) {
                    return res;
                }
                return {
                    ...res,
                    jobStatus: {
                        ...res.jobStatus,
                        ...updatedStatus,
                    },
                };
            });
        },
        createAskMeSuccess(state: DataIntegrationState, action: PayloadAction<ICreateAskmeResponse>) {
            state.askMeIntegrations.data.push(action.payload.askMe);
            state.settings.data.whiteList = action.payload.whiteList;
            return state;
        },
        updateAskMeSuccess(state: DataIntegrationState, action: PayloadAction<IUpdateAskmeResponse>) {
            const { askMe: askMeResult, whiteList } = action.payload;
            state.askMeIntegrations.data = state.askMeIntegrations.data.map((askMe) => {
                if (askMe.sourceId === askMeResult.sourceId) {
                    return {
                        ...askMeResult,
                        jobStatus: {
                            ...askMe.jobStatus,
                            ...askMeResult.jobStatus,
                        },
                    };
                }
                return askMe;
            });
            state.settings.data.whiteList = whiteList;
            return state;
        },
        executeSuccess(state: DataIntegrationState, action: PayloadAction<IExecute>) {
            state.askMeIntegrations.data = state.askMeIntegrations.data.map((askMe) => {
                if (askMe.sourceId === action.payload.sourceGuid) {
                    return {
                        ...askMe,
                        isExecuting: action.payload.status === Status.InProgress,
                        jobStatus: {
                            ...askMe.jobStatus,
                            ...action.payload,
                        },
                    };
                }
                return askMe;
            });
            return state;
        },
        getStatusSuccess(state: DataIntegrationState, action: PayloadAction<IExecutionsStatus>) {
            state.askMeIntegrations.data = state.askMeIntegrations.data.map((askMe) => {
                const updatedJobStatus = action.payload.find((s) => s.sourceGuid === askMe.sourceId);
                if (updatedJobStatus) {
                    return {
                        ...askMe,
                        isExecuting: updatedJobStatus.isExecuting,
                        lastCrawl: updatedJobStatus.lastCrawl || askMe.lastCrawl,
                        jobStatus: {
                            ...askMe.jobStatus,
                            ...updatedJobStatus,
                        },
                    };
                }
                return askMe;
            });
            return state;
        },
        deleteAskMeSuccess(state: DataIntegrationState, action: PayloadAction<Array<string>>) {
            state.askMeIntegrations.data = state.askMeIntegrations.data.filter((askMe) => {
                if (!action.payload.includes(askMe.sourceId)) {
                    return askMe;
                }
            });
            return state;
        },
        assignSystemsSuccess(state: DataIntegrationState, action: PayloadAction<{ systems: IAskMeSystems; sourceGuids: Set<string> }>) {
            state.askMeIntegrations.data = state.askMeIntegrations.data.map((askMe) => {
                if (!action.payload.sourceGuids.has(askMe.sourceId)) {
                    return askMe;
                }
                askMe.systems = askMe.systems.concat(action.payload.systems);
                const uniqueKeysSet = new Set();
                askMe.systems = askMe.systems.filter((system) => !uniqueKeysSet.has(system.guid) && uniqueKeysSet.add(system.guid));
                return askMe;
            });
            return state;
        },
        startGetWhiteList(state: DataIntegrationState) {
            state.settings.loading = true;
            return state;
        },
        getWhiteListSuccess(state: DataIntegrationState, action: PayloadAction<IWhiteList>) {
            state.settings.loading = false;
            state.settings.data.whiteList = action.payload;
        },
        createWhiteListSuccess(state: DataIntegrationState, action: PayloadAction<IWhiteList>) {
            state.settings.data.whiteList = action.payload;
        },
        updateWhiteListSuccess(state: DataIntegrationState, action: PayloadAction<IWhiteList>) {
            state.settings.data.whiteList = action.payload;
        },
        startGetIntegrations(state: DataIntegrationState) {
            state.integrations.loading = true;
            return state;
        },
        getAllIntegrationsSuccess(state: DataIntegrationState, action: PayloadAction<IIntegrations>) {
            state.integrations.loading = false;
            state.integrations.data = action.payload;
        },
        createIntegrationsSuccess(state: DataIntegrationState, action: PayloadAction<IIntegration>) {
            state.integrations.data.push(action.payload);
            return state;
        },
        deleteIntegrationSuccess(state: DataIntegrationState, action: PayloadAction<string>) {
            state.integrations.data = state.integrations.data.filter((integration) => {
                return integration.guid !== action.payload;
            });
            return state;
        },
        addAskMeFiles(state: DataIntegrationState, action: PayloadAction<IAskMeFile[]>) {
            state.files.data = {
                ...state.files.data,
                ...keyBy(action.payload, 'guid'),
            };
            return state;
        },
        initAskMeFormState(state: DataIntegrationState) {
            state.form.data = {};
            return state;
        },
        updateAskMeFormState(state: DataIntegrationState, action: PayloadAction<Partial<IAskMeFormState>>) {
            state.form.data = { ...state.form.data, ...action.payload };
            return state;
        },
        setGlobalSettings(state: DataIntegrationState, action: PayloadAction<Partial<IGlobalSettings>>) {
            state.globalSettings.data = { ...state.globalSettings.data, ...action.payload };
            return state;
        },
        updateSourceGuidedAnswersCount(
            state: DataIntegrationState,
            action: PayloadAction<{ sourceGuid: string; guidedAnswersCount: number }>
        ) {
            const { sourceGuid, guidedAnswersCount } = action.payload;
            state.askMeIntegrations.data = state.askMeIntegrations.data.map((integration) => {
                if (integration.sourceId === sourceGuid) {
                    return {
                        ...integration,
                        guidedAnswersCount,
                    };
                }
                return integration;
            });
            return state;
        },
    },
});

export { dataIntegrationsSlice };

export const {
    startGetAskMeIntegrations,
    getAskMeIntegrationsSuccess,
    createAskMeSuccess,
    updateAskMeSuccess,
    deleteAskMeSuccess,
    executeSuccess,
    getStatusSuccess,
    assignSystemsSuccess,
    startGetWhiteList,
    getWhiteListSuccess,
    createWhiteListSuccess,
    updateWhiteListSuccess,
    startGetIntegrations,
    getAllIntegrationsSuccess,
    createIntegrationsSuccess,
    deleteIntegrationSuccess,
    addAskMeFiles,
    initAskMeFormState,
    updateAskMeFormState,
    setGlobalSettings,
    updateSourceGuidedAnswersCount,
} = dataIntegrationsSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const initData = (): AppThunk => async (dispatch) => {
    try {
        await Promise.all([dispatch(getAskMeIntegrations()), dispatch(getWhiteList()), dispatch(getIntegrations())]);
    } catch (error) {
        throw new Error('failed init data');
    }
};

export const getAskMeIntegrations = (): AppThunk => async (dispatch, getState) => {
    dispatch(startGetAskMeIntegrations());
    const [askMe, status] = await Promise.all([askMeApi.getAskMe(), askMeApi.getStatus()]);
    dispatch(
        getAskMeIntegrationsSuccess({
            askMe,
            status,
        })
    );
};

export const getAskMeById =
    (sourceGuid: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const data = await askMeApi.getAskMeById(sourceGuid);
            if (data?.files?.length) {
                dispatch(addAskMeFiles(data.files));
                delete data.files;
            }
            return data;
        } catch (err) {
            throw new Error('failed to get askMe by id');
        }
    };

export const createAskMe =
    (body: IAskMeFormSubmit): AppThunk =>
    async (dispatch, getState) => {
        try {
            const data = await askMeApi.createAskMe(body);
            dispatch(createAskMeSuccess(data));
        } catch (err) {
            throw new Error('failed');
        }
    };

export const updateAskMe =
    (sourceGuid: string, body: IAskMeFormSubmit): AppThunk =>
    async (dispatch) => {
        try {
            const data = await askMeApi.updateAskMe(sourceGuid, body);
            dispatch(updateAskMeSuccess(data));
        } catch (err) {
            throw new Error('failed');
        }
    };

export const deleteAskMe =
    (sourceGuids: Array<string>): AppThunk =>
    async (dispatch) => {
        try {
            await askMeApi.deleteAskMe({ sourceGuids });
            dispatch(deleteAskMeSuccess(sourceGuids));
        } catch (err) {
            throw new Error('failed to delete');
        }
    };

export const assignSystems =
    (systems: IAskMeSystems, sourceGuids: Set<string>): AppThunk =>
    async (dispatch) => {
        try {
            const rules = await askMeApi.assignSystems({ systems, sourceGuids: [...sourceGuids] });
            dispatch(assignSystemsSuccess({ systems, sourceGuids }));
        } catch (err) {
            throw new Error('failed to assign systems');
        }
    };

export const getStatus = (): AppThunk => async (dispatch, getState) => {
    const jobs = getState().dataIntegrationsState.askMeIntegrations;
    if (jobs.data.length) {
        try {
            const data = await askMeApi.getStatus();
            dispatch(getStatusSuccess(data));
        } catch (err) {
            return false;
        }
    }
};

export const executeAskMe =
    (sourceGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            const data = await askMeApi.executeAskMe(sourceGuid);
            dispatch(executeSuccess({ ...data, sourceGuid }));
        } catch (err) {
            return false;
        }
    };

export const pauseScan =
    (sourceGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            const data = await askMeApi.pauseScan(sourceGuid);
            dispatch(executeSuccess({ ...data, sourceGuid }));
        } catch (err) {
            return false;
        }
    };

export const resumeScan =
    (sourceGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            const data = await askMeApi.resumeScan(sourceGuid);
            dispatch(executeSuccess({ ...data, sourceGuid }));
        } catch (err) {
            return false;
        }
    };

export const stopScan =
    (sourceGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            const data = await askMeApi.stopScan(sourceGuid);
            dispatch(executeSuccess({ ...data, sourceGuid }));
        } catch (err) {
            return false;
        }
    };

export const getUrls =
    (sourceGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            return await askMeApi.getUrls(sourceGuid);
        } catch (err) {
            throw new Error('Fetch scan summary failed');
        }
    };

export const revertScan =
    (sourceGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            const data = await askMeApi.revertScan(sourceGuid);
            dispatch(executeSuccess({ ...data, sourceGuid }));
        } catch (err) {
            throw new Error('Revert failed');
        }
    };

export const downloadScanSummaryReport =
    (sourceGuid: string, type: ScanSummaryType): AppThunk =>
    async (dispatch) => {
        try {
            await askMeApi.downloadScanSummaryReport(sourceGuid, type);
        } catch (err) {
            throw new Error('Download scan summary report failed');
        }
    };

export const getWhiteList = (): AppThunk => async (dispatch) => {
    dispatch(startGetWhiteList());
    const res = await askMeApi.getWhiteList();
    dispatch(getWhiteListSuccess(res || null));
};

export const createWhiteList =
    (ipList: string): AppThunk =>
    async (dispatch) => {
        try {
            const res = await askMeApi.createWhiteList(ipList);
            dispatch(createWhiteListSuccess(res));
        } catch (err) {
            throw new Error('failed to create white list IPs');
        }
    };

export const updateWhiteList =
    (id: number, ipList: string): AppThunk =>
    async (dispatch) => {
        try {
            const res = await askMeApi.updateWhiteList(id, ipList);
            dispatch(updateWhiteListSuccess(res));
        } catch (err) {
            throw new Error('failed to create white list IPs');
        }
    };

export const createIntegration =
    (body: IIntegrationFormSubmit): AppThunk =>
    async (dispatch, getState) => {
        try {
            const data = await askMeApi.createIntegration(body);
            dispatch(createIntegrationsSuccess(data));
        } catch (err) {
            throw new Error('failed');
        }
    };

export const deleteIntegration =
    (guid: string): AppThunk =>
    async (dispatch) => {
        try {
            await askMeApi.deleteIntegration(guid);
            dispatch(deleteIntegrationSuccess(guid));
        } catch (err) {
            throw new Error('failed');
        }
    };

export const getIntegrations = (): AppThunk => async (dispatch, getState) => {
    dispatch(startGetIntegrations());
    const integrations = await askMeApi.getAllIntegrations();
    dispatch(getAllIntegrationsSuccess(integrations));
};

export const refreshIntegrations = (): AppThunk => async (dispatch, getState) => {
    try {
        const integrations = await askMeApi.getAllIntegrations();
        dispatch(getAllIntegrationsSuccess(integrations));
    } catch (err) {
        throw new Error('failed');
    }
};

export const getSegments =
    (user: IDeployableUser): AppThunk =>
    async (dispatch, getState) => {
        try {
            const segments = await deployablesApi.getTags(user);
            return segments;
        } catch (err) {
            throw new Error('fetching segments failed');
        }
    };

export const getGuidedAnswers =
    (sourceGuid: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const guidedAnswers = await askMeAppApi.getGuidedAnswers(sourceGuid);
            return guidedAnswers;
        } catch (err) {
            throw new Error('fetching guided answers failed');
        }
    };

export const bulkUpdateGuidedAnswers =
    (sourceGuid: string, data: IGuidedAnswersBulkUpdateData): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await askMeAppApi.bulkUpdateGuidedAnswers(sourceGuid, data);
            dispatch(updateSourceGuidedAnswersCount({ sourceGuid, guidedAnswersCount: response.guided_answers_count }));
        } catch (err) {
            throw new Error('bulk updating guided answers failed');
        }
    };
