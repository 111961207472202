import React from 'react';
import { Close, NavigateBeforeOutlined } from '@material-ui/icons';
import { StepperMode } from '../../common/consts';
import { BackButton, Header, HeaderContainer, StyledIconButton } from './form-header-style';

export interface FormHeaderProps {
    onClose: () => void;
    providerName: string;
    activeStep: number;
    stepperMode: string;
    handleBack: () => void;
}

export const FormHeader = ({ onClose, providerName, activeStep, stepperMode, handleBack }: FormHeaderProps) => {
    const showBackButton = (): React.ReactElement => {
        const backButton = (
            <BackButton>
                <StyledIconButton disableRipple aria-label='back' onClick={() => handleBack()}>
                    <NavigateBeforeOutlined className={'back-icon-color'} fontSize={'large'} />
                </StyledIconButton>
                <div className={'pipe'} />
            </BackButton>
        );
        if (activeStep === 0) {
            return null;
        } else {
            switch (stepperMode) {
                case StepperMode.NEW:
                    return activeStep !== 2 ? backButton : null;
                case StepperMode.EDIT:
                    return backButton;
                case StepperMode.EDIT_ATTRIBUTES:
                case StepperMode.ASSIGN_SYSTEMS:
                    return null;
            }
        }
    };

    return (
        <HeaderContainer>
            <div className={'title'}>
                {showBackButton()}
                <Header>{stepperMode !== StepperMode.NEW ? `Edit ${providerName}` : 'Add IDP'}</Header>
            </div>
            <StyledIconButton disableRipple style={{ marginRight: '32px' }} onClick={onClose}>
                <Close />
            </StyledIconButton>
        </HeaderContainer>
    );
};
