import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import { WMIconClose, WMButton, WMButtonVariant, WMSnackbar, WMSnackbarVariant, WMIconSettings } from '@walkme/wm-ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useUsers, useLoggedInUser, usePendingUsers, getUsers } from '@walkme-admin-center/libs/state-management-users';
import { AccountsSdk, EditUserDetailsDto, Systems, SystemsSettings, SystemTypeKey, Users, WMPendingUserRequestItem } from 'wm-accounts-sdk';
import { createSystem, getSystems, useSystems, useSystemsSettings } from '@walkme-admin-center/libs/state-management-systems';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppToUrlMapping } from '@walkme-admin-center/libs/types';
import {
    CancelButton,
    CircularProgressGrid,
    DialogButtons,
    DialogContent,
    DialogItemIcon,
    DialogSeconaryButton,
    DialogItemText,
    DialogItemValueText,
    DialogSubTitle,
    DialogTitle,
    ItemRow,
    ItemsGrid,
    TitleGrid,
    DialogStyled,
} from './systems-automated-actions.styles';

const ButtonProcessingText = 'Processing';
const ButtonConfirmText = 'Confirm';
const TitleProcessingText = 'Processing Account Changes';
const SubTitleProcessingText = 'Please wait while we configure your WalkMe for {{systemNames}} experience';
const TitleConfirmText = 'Confirm Account Changes';
const SubTitleExplainText =
    'You are about to perform changes to your account, please make sure you review the following actions before you approve them:';
export interface SystemsAutomatedActionsProps {
    onCancel?: () => void;
    onFinish: (successMessage: string) => void;
    showDialog?: boolean;
}

interface AutomatedActionItem {
    fieldText: string;
    fieldValue: string;
}

export const SystemsAutomatedActions = ({ showDialog, onCancel, onFinish }: SystemsAutomatedActionsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    const autoCreateTypes = urlSearchParams.get('autoCreate') && urlSearchParams.get('autoCreate').split(',');
    const autoAssignUsers =
        urlSearchParams.get('assign') &&
        urlSearchParams
            .get('assign')
            .split(',')
            .map((email) => decodeURI(email));
    const defaultAppToOpen = urlSearchParams.get('defaultAppToOpen');
    const redirectUrl = AppToUrlMapping[defaultAppToOpen];
    const [automatedActionItems, setAutomatedActionItems] = useState<AutomatedActionItem[]>([]);
    const [screenTitleText, setScreenTitleText] = useState(TitleProcessingText);
    const [screenSubTitleText, setScreenSubTitleText] = useState(SubTitleProcessingText);
    const [screenButtonText, setScreenButtonText] = useState(ButtonProcessingText);
    const [screenDataReady, setScreenDataReady] = useState(false);
    const [autoCreateLoader, setAutoCreateLoader] = useState(true);
    const [autoCreateLoaderErrorText, setAutoCreateLoaderErrorText] = useState('');
    const { loggedInUserAppData } = useLoggedInUser();
    const { pendingUsersAppData } = usePendingUsers();
    const { systemsSettingsAppData } = useSystemsSettings();
    const { usersAppData } = useUsers();
    const { systemsAppData } = useSystems();

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setAutoCreateLoaderErrorText('');
    };

    const getValidatedSystemsToCreate = (systemsSettings: SystemsSettings, autoCreateTypes: string[]) => {
        const autoCreateTypesArray = autoCreateTypes || [];
        const validatedSystemsToCreate: SystemsSettings = [];
        const validatedSystemsSettingsExsits: SystemsSettings = [];
        const existingSystems: Systems = [];
        for (const type of autoCreateTypesArray) {
            const systemSettings = systemsSettings.find((systemSettings) => systemSettings.name === type);
            if (!systemSettings) continue;
            const existingSystem = systemsAppData.data.find((system) => system.name === type);
            if (existingSystem) {
                validatedSystemsSettingsExsits.push(systemSettings);
                existingSystems.push(existingSystem);
                continue;
            }
            validatedSystemsToCreate.push(systemSettings);
        }
        return {
            validatedSystemsToCreate,
            validatedSystemsSettingsExsits,
            existingSystems,
        };
    };

    const getUsersToAttach = (pendingUsers: WMPendingUserRequestItem[], accountUsers: Users, autoAssignUsers: string[]) => {
        const autoAssignUsersData = autoAssignUsers || [];
        const existingUsersToAttach: Users = [];
        const nonExistingUsersWithPendingRequests: WMPendingUserRequestItem[] = [];
        for (const userEmail of autoAssignUsersData) {
            const existingUser = accountUsers.find((user) => user.email === userEmail);
            if (existingUser) {
                existingUsersToAttach.push(existingUser);
                continue;
            }
            const pendingUser = pendingUsers.find((pendingUser) => pendingUser.requestedUserEmail === userEmail);
            if (pendingUser) {
                nonExistingUsersWithPendingRequests.push(pendingUser);
            }
        }
        return {
            existingUsersToAttach,
            nonExistingUsersWithPendingRequests,
        };
    };

    useEffect(() => {
        if (
            !pendingUsersAppData.loading &&
            systemsAppData.data.length > 0 &&
            usersAppData.data.length > 0 &&
            loggedInUserAppData.data.id &&
            systemsSettingsAppData.data.length > 0
        ) {
            const { validatedSystemsToCreate, validatedSystemsSettingsExsits, existingSystems } = getValidatedSystemsToCreate(
                systemsSettingsAppData.data,
                autoCreateTypes
            );
            const { existingUsersToAttach, nonExistingUsersWithPendingRequests } = getUsersToAttach(
                pendingUsersAppData.data,
                usersAppData.data,
                autoAssignUsers
            );

            if (validatedSystemsToCreate.length === 0 && existingUsersToAttach.length === 0) {
                if (nonExistingUsersWithPendingRequests.length > 0) {
                    navigate(`/users/access-requests`);
                } else {
                    onFinish('All set');
                }
                return;
            }
            const newAutomatedActionItems: AutomatedActionItem[] = [];
            if (validatedSystemsToCreate.length > 0) {
                newAutomatedActionItems.push({
                    fieldText: `Create new ${validatedSystemsToCreate.length > 1 ? 'systems' : 'system'} - `,
                    fieldValue: validatedSystemsToCreate.map((systemSettings) => systemSettings.displayName).join(', '),
                });
            }
            const systemsNames = validatedSystemsToCreate
                .concat(validatedSystemsSettingsExsits)
                .map((systemSettings) => systemSettings.displayName)
                .join(', ');
            if (existingUsersToAttach.length > 0 && systemsNames.length > 0) {
                newAutomatedActionItems.push({
                    fieldText: `Assign ${existingUsersToAttach.length > 1 ? 'users' : 'user'} to  ${systemsNames} - `,
                    fieldValue: existingUsersToAttach.map((user) => user.email).join(', '),
                });
            }
            setAutomatedActionItems(newAutomatedActionItems);
            setScreenDataReady(true);
            const assignedOnlyAdmin =
                autoAssignUsers &&
                existingUsersToAttach.length === 1 &&
                autoAssignUsers.find((assignUserEmail) => assignUserEmail === loggedInUserAppData.data.email);
            if (validatedSystemsToCreate.length === 0 && assignedOnlyAdmin) {
                setScreenTitleText(TitleProcessingText);
                setScreenSubTitleText(SubTitleProcessingText.replace('{{systemNames}}', systemsNames));
                setScreenButtonText(ButtonProcessingText);
                setAutoCreateLoader(true);
                onHandleConfirm();
            } else {
                setScreenTitleText(TitleConfirmText);
                setScreenSubTitleText(SubTitleExplainText);
                setScreenButtonText(ButtonConfirmText);
                setAutoCreateLoader(false);
            }
        }
    }, [pendingUsersAppData, systemsAppData, usersAppData, loggedInUserAppData, systemsSettingsAppData]);

    const onHandleConfirm = useCallback(() => {
        async function executeProccess() {
            try {
                const { validatedSystemsToCreate, existingSystems } = getValidatedSystemsToCreate(
                    systemsSettingsAppData.data,
                    autoCreateTypes
                );
                const newSystemsPromises = validatedSystemsToCreate.map((systemSettings) =>
                    createSystem(systemSettings.name, systemSettings.displayName, systemSettings.regexp)
                );
                const newSystems = await Promise.all(newSystemsPromises);
                const allSystemsIdsToAttach = newSystems.concat(existingSystems).map((system) => system.id);
                const assignedOnlyAdmin =
                    autoAssignUsers &&
                    autoAssignUsers.length === 1 &&
                    autoAssignUsers.find((assignUserEmail) => assignUserEmail === loggedInUserAppData.data.email);

                if (allSystemsIdsToAttach.length === 0) {
                    onCancel();
                    return;
                }

                const { existingUsersToAttach, nonExistingUsersWithPendingRequests } = getUsersToAttach(
                    pendingUsersAppData.data,
                    usersAppData.data,
                    autoAssignUsers
                );

                if (existingUsersToAttach.length > 0) {
                    const updateUsersData = existingUsersToAttach.map((user) => {
                        const userAllAssignedSystemsIds = user.systems.map((system) => system.id).concat(allSystemsIdsToAttach);
                        const userAllAssignedSystemsIdsUnique = [...new Set(userAllAssignedSystemsIds)];
                        const dataToUpdate: EditUserDetailsDto = {
                            defaultAppToOpenAfterSystemAssign: defaultAppToOpen,
                            systemIds: userAllAssignedSystemsIdsUnique,
                            userId: user.id,
                        };
                        return dataToUpdate;
                    });
                    const updateMultipleUsersResults = await AccountsSdk.getInstance().updateMultipleUsers({
                        editUsers: updateUsersData,
                    });
                    if (updateMultipleUsersResults.errors && updateMultipleUsersResults.errors.length > 0) {
                        setAutoCreateLoaderErrorText(
                            `Failed to assign users: ${updateMultipleUsersResults.errors.map((user) => user.email)}`
                        );
                        setAutoCreateLoader(false);
                    }
                }
                if (nonExistingUsersWithPendingRequests.length > 0) {
                    navigate(`/users/access-requests`);
                } else if (redirectUrl && assignedOnlyAdmin) {
                    window.location.replace(redirectUrl);
                } else {
                    onFinish('Changes Applied');
                    dispatch(getSystems(true, [SystemTypeKey.Web]));
                    dispatch(getUsers(true));
                }
            } catch (e) {
                setAutoCreateLoaderErrorText(`Something went wrong please try again`);
                setAutoCreateLoader(false);
            }
        }
        executeProccess();
    }, [history, autoCreateTypes, pendingUsersAppData, systemsAppData, usersAppData, loggedInUserAppData, systemsSettingsAppData]);

    return (
        <div>
            <WMSnackbar
                open={autoCreateLoaderErrorText ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={autoCreateLoaderErrorText}
            />

            <DialogStyled
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onCancel();
                    }
                }}
                open={showDialog}
                fullWidth
                maxWidth='sm'>
                <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
                    <TitleGrid item xs={12}>
                        <Grid container direction='row' alignItems='flex-start' justifyContent='space-between'>
                            <Grid item>
                                <DialogTitle>{screenTitleText}</DialogTitle>
                            </Grid>
                            <Grid item>
                                {!autoCreateLoader && (
                                    <CancelButton
                                        onClick={() => onCancel()}
                                        variant={WMButtonVariant.Text}
                                        iconComponent={<WMIconClose size={12} color='var(--wmGrayDark)' />}></CancelButton>
                                )}
                            </Grid>
                        </Grid>
                    </TitleGrid>
                    {!screenDataReady && (
                        <DialogContent container direction='column' alignItems='center' justifyContent='center'>
                            <CircularProgressGrid item xs={12}>
                                <CircularProgress size={40} />
                            </CircularProgressGrid>
                        </DialogContent>
                    )}
                    {screenDataReady && (
                        <DialogContent container direction='column' alignItems='flex-start' justifyContent='flex-start'>
                            <Grid item xs={12}>
                                <DialogSubTitle>{screenSubTitleText}</DialogSubTitle>
                            </Grid>
                            <ItemsGrid item xs={12}>
                                <Grid container direction='column' alignItems='flex-start' justifyContent='flex-start'>
                                    {automatedActionItems.map((actionItem, index) => {
                                        return (
                                            <ItemRow
                                                key={`actionItems-${index}`}
                                                container
                                                direction='row'
                                                justifyContent='flex-start'
                                                alignItems='baseline'>
                                                <Grid item>
                                                    <DialogItemIcon color={'var(--wmGrayDark)'} size={15} />
                                                </Grid>
                                                <Grid item>
                                                    <DialogItemText>{actionItem.fieldText}</DialogItemText>
                                                </Grid>
                                                <Grid item>
                                                    <DialogItemValueText>{actionItem.fieldValue}</DialogItemValueText>
                                                </Grid>
                                            </ItemRow>
                                        );
                                    })}
                                </Grid>
                            </ItemsGrid>
                        </DialogContent>
                    )}
                    <DialogButtons item xs={12}>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                            <Grid item>
                                {!autoCreateLoader && (
                                    <DialogSeconaryButton variant={WMButtonVariant.Text} disabled={autoCreateLoader} onClick={onCancel}>
                                        Cancel
                                    </DialogSeconaryButton>
                                )}
                            </Grid>
                            <Grid item>
                                <WMButton
                                    onClick={() => {
                                        setAutoCreateLoader(true);
                                        onHandleConfirm();
                                    }}
                                    disabled={autoCreateLoader || automatedActionItems.length === 0}
                                    loading={autoCreateLoader}>
                                    {screenButtonText}
                                </WMButton>
                            </Grid>
                        </Grid>
                    </DialogButtons>
                </Grid>
            </DialogStyled>
        </div>
    );
};

export default SystemsAutomatedActions;
