import { WMRadio, WMSwitch } from '@walkme/wm-ui';
import React, { ChangeEvent } from 'react';
import Regex from './regex';
import {
    ControlContainer,
    ControlHeader,
    ControlSubHeader,
    StyledWmDivider,
    UrlsContainer,
    TopSitesModeContainer,
    FlexDivContainer,
    TopSitesModeOptionsContainer,
} from './shared-styled-components';
import URLMatchPatterns from './url-match-patterns';
import { LoadOption, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import cloneDeep from 'lodash/cloneDeep';
import { RadioGroup } from '@material-ui/core';
import { TopSitesMode, TopSitesModeRadioValue, TOP_SITES_MODE_RADIO_VALUES } from './values';
import { ALL_URLS_MATCH_PATTERN, ALL_URLS_REGEX } from './consts';
import isEqual from 'lodash/isEqual';
import { getInitialTopSitesMode } from './utils';
import styled from 'styled-components';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

const RegexTitle = styled('span')`
    color: var(--typography-typography, #2f426c);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-right: 10px;
`;

interface AdvancedConfigurationsProps {
    selectedMatcher: SystemMatcher | undefined;
    isAddingNewUrl: boolean;
    isSaveInProgress: boolean;
    setIsAddingNewUrl: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedMatcher: React.Dispatch<React.SetStateAction<SystemMatcher>>;
    setAreTopUrlsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setIsTopRegexValid: React.Dispatch<React.SetStateAction<boolean>>;
    onAddNewTopUrlClosed: () => void;
}

const AdvancedConfigurations = ({
    isSaveInProgress,
    isAddingNewUrl,
    selectedMatcher,
    setSelectedMatcher,
    setIsAddingNewUrl,
    setAreTopUrlsValid,
    onAddNewTopUrlClosed,
    setIsTopRegexValid,
}: AdvancedConfigurationsProps) => {
    const { t } = useTranslation('general');
    const isSnippetDetectConfigValid = selectedMatcher?.isSnippetDetectConfigValid ?? false;
    const currentTopSitesMode: TopSitesMode = getInitialTopSitesMode(selectedMatcher, !!selectedMatcher?.isRegex);

    const onLoadOptionChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const matcherClone = cloneDeep(selectedMatcher);
        matcherClone.loadOptions = selectedMatcher?.loadOptions === LoadOption.OnlyTop ? LoadOption.TopAndCdIframes : LoadOption.OnlyTop;
        setSelectedMatcher(matcherClone);
    };

    const onPreferSiteSnippetChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const matcherClone = cloneDeep(selectedMatcher);
        matcherClone.preferSiteSnippet = !matcherClone.preferSiteSnippet;
        setSelectedMatcher(matcherClone);
    };

    const onWaitForSiteSnippetWithTimeoutChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const matcherClone = cloneDeep(selectedMatcher);
        matcherClone.waitForSiteSnippetWithTimeout = !matcherClone.waitForSiteSnippetWithTimeout;
        setSelectedMatcher(matcherClone);
    };

    const onTopSitesModeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const matcherClone = cloneDeep(selectedMatcher);
        const newValue: TopSitesMode = (event.target as HTMLInputElement).value as TopSitesMode;
        const isRegexMode = selectedMatcher?.isRegex;
        const isAllTopSitesMode = newValue === TopSitesMode.All;

        if (isRegexMode) {
            matcherClone.topRegex = isAllTopSitesMode ? ALL_URLS_REGEX : '';
        } else {
            matcherClone.topDomains = isAllTopSitesMode ? [...ALL_URLS_MATCH_PATTERN] : [];
            // When switching between sites mode adding new url state changes.
            setIsAddingNewUrl(!isAllTopSitesMode);
        }

        setSelectedMatcher(matcherClone);

        if (newValue === TopSitesMode.All) {
            //Resetting the urls validity, since error in urls is not relevant anymore, since we changed it.
            if (isRegexMode) {
                setIsTopRegexValid(true);
            } else {
                setAreTopUrlsValid(true);
            }
        }
    };

    const shouldDisableSnippetDetectControls = isSaveInProgress || isAddingNewUrl;

    const snippetDetectControls = (
        <>
            <ControlContainer>
                <FlexDivContainer>
                    <ControlHeader style={{ flexGrow: 1 }}>{t('systems-tab.all-systems-page.extension-page.advanced-title')}</ControlHeader>
                    <WMSwitch
                        disabled={shouldDisableSnippetDetectControls}
                        checked={selectedMatcher?.preferSiteSnippet ?? false}
                        onChange={onPreferSiteSnippetChange}
                    />
                </FlexDivContainer>
                <ControlSubHeader>{t('systems-tab.all-systems-page.extension-page.advanced-subtitle')}</ControlSubHeader>
            </ControlContainer>
            {selectedMatcher?.preferSiteSnippet ? (
                <>
                    <StyledWmDivider />
                    <FlexDivContainer>
                        <ControlHeader style={{ flexGrow: 1 }}>
                            {t('systems-tab.all-systems-page.extension-page.load-extension-switch')}
                        </ControlHeader>
                        <WMSwitch
                            disabled={shouldDisableSnippetDetectControls}
                            checked={selectedMatcher?.waitForSiteSnippetWithTimeout ?? false}
                            onChange={onWaitForSiteSnippetWithTimeoutChange}
                        />
                    </FlexDivContainer>
                </>
            ) : null}
            <StyledWmDivider />
        </>
    );

    const getTopRegexControl = () => (
        <>
            <RegexTitle>{t('common.regex')}</RegexTitle>
            <Regex
                setIsValid={setIsTopRegexValid}
                value={selectedMatcher?.topRegex || ''}
                setValue={(newValue: string) => {
                    if (newValue !== selectedMatcher.topRegex) {
                        const selectedMatcherClone = cloneDeep(selectedMatcher);
                        selectedMatcherClone.topRegex = newValue;
                        setSelectedMatcher(selectedMatcherClone);
                    }
                }}
            />
        </>
    );

    const getTopDomainsControl = () => (
        <URLMatchPatterns
            urls={selectedMatcher?.topDomains || []}
            setAreUrlsValid={setAreTopUrlsValid}
            setUrls={(newUrls: string[]) => {
                if (!isEqual(newUrls, selectedMatcher.topDomains)) {
                    const selectedMatcherClone = cloneDeep(selectedMatcher);
                    selectedMatcherClone.topDomains = newUrls;
                    setSelectedMatcher(selectedMatcherClone);
                }
            }}
            shouldShowNewLine={selectedMatcher && selectedMatcher?.topDomains.length === 0}
            onAddUrlClicked={() => setIsAddingNewUrl(true)}
            onAddNewUrlSaved={() => setIsAddingNewUrl(false)}
            onAddNewUrlClosed={onAddNewTopUrlClosed}
        />
    );

    const topSitesControl = selectedMatcher && selectedMatcher.isRegex ? getTopRegexControl() : getTopDomainsControl();
    const topSitesModeOptionsControl = (
        <TopSitesModeOptionsContainer>
            <RadioGroup style={{ gap: '14px' }} value={currentTopSitesMode} onChange={onTopSitesModeChange} name='TopSitesMode'>
                {TOP_SITES_MODE_RADIO_VALUES.map((topSitesModeRadioValue: TopSitesModeRadioValue) => {
                    const { value, label, translationKey } = topSitesModeRadioValue;
                    return (
                        <WMRadio
                            ds2
                            disabled={isSaveInProgress}
                            key={value}
                            value={value}
                            label={t(`systems-tab.all-systems-page.extension-page.${translationKey}`)}
                        />
                    );
                })}
            </RadioGroup>
            {currentTopSitesMode === TopSitesMode.Specific ? (
                <UrlsContainer style={{ marginTop: '16px' }}>{topSitesControl}</UrlsContainer>
            ) : null}
        </TopSitesModeOptionsContainer>
    );

    return (
        <>
            {isSnippetDetectConfigValid ? snippetDetectControls : null}
            <FlexDivContainer>
                <ControlHeader style={{ flexGrow: 1 }}>
                    {t('systems-tab.all-systems-page.extension-page.load-cross-iframe-switch')}
                </ControlHeader>
                <WMSwitch
                    disabled={isSaveInProgress || isAddingNewUrl}
                    checked={selectedMatcher?.loadOptions === LoadOption.TopAndCdIframes}
                    onChange={onLoadOptionChange}
                />
            </FlexDivContainer>
            <StyledWmDivider />
            <TopSitesModeContainer>
                <ControlHeader>{t('systems-tab.all-systems-page.extension-page.website-validation-title')}</ControlHeader>
                <ControlSubHeader>{t('systems-tab.all-systems-page.extension-page.website-validation-subtitle')}</ControlSubHeader>
                {topSitesModeOptionsControl}
            </TopSitesModeContainer>
        </>
    );
};

export default AdvancedConfigurations;
