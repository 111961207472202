import { WMhttpClient } from '@walkme-admin-center/libs/http-service';
import { saveAs } from 'file-saver';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import {
    ProviderGroups,
    Provider,
    Providers,
    AuthUrlResponse,
    Fields,
    FieldsFailedResponse,
    ProviderFormValues,
    AccountConfig,
    OneId,
    IdpSystems,
    Certificate,
    IdpSystemsFormValue,
} from '@walkme-admin-center/libs/state-management-sso-configuration';

class IdpApi {
    private readonly httpClient: WMhttpClient;
    private readonly path: string;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
        this.path = '/ic/idp/bo';
        this.initializeDebugRequestsInterceptor();
    }

    initializeDebugRequestsInterceptor() {
        this.httpClient.instance.interceptors.request.use(
            async (config) => {
                const isDebugMode = Cookies.get('idpDebugMode');
                if (isDebugMode === 'true') {
                    let IdpWmTransactionId = localStorage.getItem('idp-Wm-Transaction-Id');
                    if (!IdpWmTransactionId) {
                        IdpWmTransactionId = uuidv4();
                        localStorage.setItem('idp-Wm-Transaction-Id', IdpWmTransactionId);
                    }
                    config.headers['wm-transaction-id'] = IdpWmTransactionId;
                    config.headers['is-debug-mode'] = isDebugMode;
                }
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    async getGroups(): Promise<ProviderGroups> {
        const response = await this.httpClient.instance.get<ProviderGroups>(`${this.path}/providers/groups`);
        return response.data;
    }

    async getProviders(isImpersonator: boolean): Promise<Providers> {
        const headers = isImpersonator ? { 'is-impersonator': true } : {};
        const response = await this.httpClient.instance.get<Providers>(`${this.path}/providers`, {
            headers,
        });
        return response.data;
    }

    async downloadSamlMetaData(): Promise<any> {
        const { data } = await axios.get(`${window.clientConfig.NX_CDN_URL}/ic/idp/saml/metadata.xml`);
        const blob = new Blob([data], { type: 'text/xml;charset=utf-8' });
        saveAs(blob, 'metadata.xml');
    }

    async getAuthUrlForCandidate(formValues: ProviderFormValues, providerId: number, groupId: number): Promise<AuthUrlResponse> {
        const response = await this.httpClient.instance.post<AuthUrlResponse>(`${this.path}/providers/groups/${groupId}/authorization`, {
            ...formValues.config,
            providerId: providerId || null,
        });
        return response.data;
    }

    async getAuthUrl(providerId: number): Promise<AuthUrlResponse> {
        const response = await this.httpClient.instance.get<AuthUrlResponse>(`${this.path}/providers/${providerId}/authorization`);
        return response.data;
    }

    async getProviderFields(providerId: number): Promise<Fields | FieldsFailedResponse> {
        const response = await this.httpClient.instance.get<any>(`${this.path}/providers/${providerId}/fields`);

        if (response.data.success === false) {
            throw { response };
        }
        return response.data;
    }

    async getCandidateFields(candidateId: number): Promise<Fields | FieldsFailedResponse> {
        const response = await this.httpClient.instance.get<any>(`${this.path}/providers/candidates/${candidateId}/fields`);
        if (response.data.success === false) {
            throw { response };
        }
        return response.data;
    }

    async saveProvider(body): Promise<Provider> {
        const { data } = await this.httpClient.instance.post(`${this.path}/providers/`, body);
        return data;
    }

    async updateProvider(providerId: number, body): Promise<Provider> {
        const { data } = await this.httpClient.instance.put<Provider>(`${this.path}/providers/${providerId}`, body);
        return data;
    }

    async assignSystemsToProvider(providerId: number, systems: IdpSystemsFormValue, isImpersonator: boolean): Promise<IdpSystems> {
        const headers = isImpersonator ? { 'is-impersonator': true } : {};
        const { data } = await this.httpClient.instance.put<IdpSystems>(`${this.path}/providers/${providerId}/systems`, systems, {
            headers,
        });
        return data;
    }

    async generateCertificate(providerGroupId): Promise<Certificate> {
        const { data } = await this.httpClient.instance.post<Certificate>(`${this.path}/providers/groups/${providerGroupId}/cert`, {});
        return data;
    }

    async downloadCertificate(hasCertificate: boolean, certificateId: number): Promise<void> {
        const buildPath = hasCertificate ? certificateId : `candidates/${certificateId}`;
        const { data } = await this.httpClient.instance.get<string>(`${this.path}/providers/${buildPath}/cert`);
        const blob = new Blob([data], { type: 'text/plain' });
        saveAs(blob, 'cert.pem');
    }

    async getAccountConfig(): Promise<AccountConfig> {
        const { data } = await this.httpClient.instance.get<AccountConfig>(`${this.path}/account/config`);
        return data;
    }

    async setOneId(oneId: OneId): Promise<OneId> {
        const { data } = await this.httpClient.instance.post<OneId>(`${this.path}/providers/oneId`, { providerId: oneId.oneIdProvider });
        return data;
    }

    async turnOffOneId(): Promise<any> {
        return this.httpClient.instance.delete(`${this.path}/providers/oneId`);
    }

    async getAccountDefaultIdpProvider(accountGuid: string) {
        const defaultProvidersRes = await this.httpClient.instance.get(`${this.path}/accounts/${accountGuid}`);

        return defaultProvidersRes?.data;
    }
}

export const idpApi = new IdpApi(new WMhttpClient(window.clientConfig.NX_WM_API_URL));

// export const idpApi = new IdpApi(new WMhttpClient('http://localhost:4002'));
