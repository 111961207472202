import React from 'react';
import { Radio } from '@material-ui/core';
import styled from 'styled-components';

const StyledRadio = styled(Radio)`
    &.MuiRadio-root {
        padding: 0px;
        width: 16px;

        &:hover {
            background: none;

            .icon {
                border-color: #6C89F0;
            }
        }
    }

    .icon {
        border-radius: 50%;
        border: solid 1px #6D81A6;
        width: 14px;
        height: 14px;
    }

    .checkedIcon {
        border: solid 6px #385FEB;
        width: 4px;
        height: 4px;
    },
`;

export const CustomRadioButton = (props) => {
    return (
        <StyledRadio
            disableRipple
            color='default'
            checkedIcon={<span className={'icon checkedIcon'} />}
            icon={<span className={'icon'} />}
            {...props}
        />
    );
};

export default CustomRadioButton;
