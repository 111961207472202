import isEqual from 'lodash/isEqual';
import { SystemMatcher, SystemMatchers, webSystemsExposureApi } from '@walkme-admin-center/libs/exposure-api';
import { buildAddNewMatcherData } from '../../../../../extension-pages/src/lib/new-design/system-matcher-builders';
import { regexPatternByFormat, UUID } from '../steps/optional-configure/uuid/uuid.lib';
import { DataCollectionLevel } from '../steps/optional-configure/data-collection/data-collection.lib';

interface SaveAddNewSystemFlowParams {
    accountId: number;
    systemId: number;
    originalMatchers: SystemMatchers;
    environmentMatchers: SystemMatchers;
}

export const handleSaveAndFinishDeploymentMethod = async ({
    originalMatchers,
    environmentMatchers,
    accountId,
    systemId,
}: SaveAddNewSystemFlowParams) => {
    // save deployment method changes
    if (!isEqual(originalMatchers, environmentMatchers)) {
        for (let env in environmentMatchers) {
            const envMatcher = environmentMatchers[env][0];
            const originalEnvMatcher = originalMatchers[env][0];

            if (!isEqual(envMatcher, originalEnvMatcher)) {
                const isActiveChanged = envMatcher.isActive !== originalEnvMatcher.isActive;

                const oldValues: Partial<SystemMatcher> = {};
                const newValues: Partial<SystemMatcher> = {};

                if (isActiveChanged) {
                    oldValues.isActive = originalEnvMatcher.isActive;
                    newValues.isActive = envMatcher.isActive;
                }

                if (originalEnvMatcher.isRegex) {
                    const selfRegexChanged = !isEqual(envMatcher.selfRegex, originalEnvMatcher.selfRegex);

                    if (selfRegexChanged) {
                        oldValues.selfRegex = originalEnvMatcher.selfRegex;
                        newValues.selfRegex = envMatcher.selfRegex;
                    }
                } else {
                    const selfDomainsChanged = !isEqual(envMatcher.selfDomains, originalEnvMatcher.selfDomains);

                    if (selfDomainsChanged) {
                        oldValues.selfDomains = originalEnvMatcher.selfDomains;
                        newValues.selfDomains = envMatcher.selfDomains;
                    }
                }

                if (originalEnvMatcher.isDefault) {
                    const newMatcher = buildAddNewMatcherData(envMatcher);
                    await webSystemsExposureApi.addSystemMatcher({
                        accountId,
                        systemId,
                        value: newMatcher,
                    });
                } else {
                    await webSystemsExposureApi.updateSystemMatcher({
                        accountId,
                        systemId,
                        matcherId: originalEnvMatcher._id,
                        oldValues,
                        newValues,
                    });
                }
            }
        }
    }
};

export const handleSaveDefaultDeploymentMethod = ({ extensionAsDefaultSelected, extensionIsDefaultDeploymentMethod }) => {
    if (!extensionAsDefaultSelected && extensionIsDefaultDeploymentMethod) {
        localStorage.removeItem('extensionIsDefaultDeploymentMethod');
    } else if (extensionAsDefaultSelected && !extensionIsDefaultDeploymentMethod) {
        localStorage.setItem('extensionIsDefaultDeploymentMethod', 'true');
    }
};

export const generateConfigurationString = ({ currentEndUserSettings, uuidType, variableParameter, expectedFormat, regexp }) => {
    const currentConfiguration = { ...currentEndUserSettings };

    switch (uuidType) {
        case UUID.IDP:
        case UUID.SEGMENTCOM:
        case UUID.SALESFORCE:
        case UUID.WALKME:
        case UUID.NONE:
            currentConfiguration.Method = uuidType;
            currentConfiguration.Parameters = {};
            break;
        case UUID.VARIABLE:
        case UUID.JQUERY:
        case UUID.COOKIE:
            currentConfiguration.Method = uuidType;
            currentConfiguration.Parameters = {
                VarName: variableParameter,
            };
            break;
    }

    switch (expectedFormat) {
        case 'Custom_Regexp':
            currentConfiguration.IdpFormat = { Type: 'Custom_Regexp', Regexp: regexp };
            currentConfiguration.IdFormat = { Type: 'Custom_Regexp', Regexp: regexp };
            break;
        case 'Email':
        case 'GUID':
        case 'Integer':
            currentConfiguration.IdpFormat = { Type: expectedFormat, Regexp: regexPatternByFormat[expectedFormat] };
            currentConfiguration.IdFormat = { Type: expectedFormat, Regexp: regexPatternByFormat[expectedFormat] };
            break;
        case 'None':
            currentConfiguration.IdpFormat = { Type: 'None' };
            currentConfiguration.IdFormat = { Type: 'None' };
            break;
    }

    return currentConfiguration;
};
