import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';


export const StyledContent = styled(CardContent)`
    padding: 10px 16px;
`;

export const StyledText = styled(Typography)`
    color: ${styledTheme.colors.mainGray};
    display: flex;
    align-items: center;
    margin-bottom: 7px;
`;

export const StyledContainer = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    `;

export const StyledBottomSection = styled.div`
        display: flex;
        flex-flow: column wrap;
        padding: 16px 16px 0px 16px;
        border-top: 1px solid ${styledTheme.colors.separator};
    `;

export const StyledActions = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    `;

export const StyledH2 = styled.h2`
    font-family: 'ProximaNova';
`;

export const StyledSlider = styled(Slider)`
    margin: 20px;
    width: 95%;
`;
