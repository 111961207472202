import React, { SVGProps } from 'react';

export const LightBulbIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g id='Vector'>
            <g id='Vector_2'>
                <mask id='path-1-inside-1_1403_20118' fill='white'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12 14.2422C13.206 13.435 14 12.0602 14 10.5C14 8.01472 11.9853 6 9.5 6C7.01472 6 5 8.01472 5 10.5C5 12.0602 5.79401 13.435 7 14.2422V15C7 15.5523 7.44772 16 8 16H11C11.5523 16 12 15.5523 12 15V14.2422Z'
                    />
                </mask>
                <path
                    d='M12 14.2422L11.4437 13.4112C11.1664 13.5968 11 13.9085 11 14.2422H12ZM7 14.2422H8C8 13.9085 7.83356 13.5968 7.55626 13.4112L7 14.2422ZM13 10.5C13 11.7125 12.3842 12.7817 11.4437 13.4112L12.5563 15.0732C14.0277 14.0883 15 12.4079 15 10.5H13ZM9.5 7C11.433 7 13 8.567 13 10.5H15C15 7.46243 12.5376 5 9.5 5V7ZM6 10.5C6 8.567 7.567 7 9.5 7V5C6.46243 5 4 7.46243 4 10.5H6ZM7.55626 13.4112C6.61575 12.7817 6 11.7125 6 10.5H4C4 12.4079 4.97228 14.0883 6.44374 15.0732L7.55626 13.4112ZM8 15V14.2422H6V15H8ZM8 15H6C6 16.1046 6.89543 17 8 17V15ZM11 15H8V17H11V15ZM11 15V17C12.1046 17 13 16.1046 13 15H11ZM11 14.2422V15H13V14.2422H11Z'
                    fill='#6D81A6'
                    mask='url(#path-1-inside-1_1403_20118)'
                />
            </g>
            <path id='Vector_3' d='M7.5 17.5H11.5' stroke='#6D81A6' strokeLinecap='round' />
            <path id='Vector_4' d='M17.5 10.5H16' stroke='#6D81A6' strokeLinecap='round' />
            <path id='Vector_5' d='M3 10.5H1.5' stroke='#6D81A6' strokeLinecap='round' />
            <path id='Vector_6' d='M4.5 5.5L3.5 4.5' stroke='#6D81A6' strokeLinecap='round' />
            <path id='Vector_7' d='M14.5 5.5L15.5 4.5' stroke='#6D81A6' strokeLinecap='round' />
            <path id='Vector_8' d='M9.5 4V2.5' stroke='#6D81A6' strokeLinecap='round' />
        </g>
    </svg>
);
