import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Link, Navigate, Route, Routes, useLocation, useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Environment } from 'wm-accounts-sdk';
import { WMButton, WMDivider, WMIconPlus, WMSnackbar, WMSnackbarVariant, WMTooltip } from '@walkme/wm-ui';
import { PageWrapper, UnsupportedErrorPage } from '@walkme-admin-center/libs/common';
import { Box } from '@material-ui/core';
import { Tabs } from '@walkme/ui-core';
import { InputSearch } from '@walkme/ui-core';
import { StyledPageTitle } from '@walkme-admin-center/libs/shared-styles';
import { AppThunkDispatch, useLoggedInUser, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { useSystem, useUpdatedEnvironment } from '@walkme-admin-center/libs/state-management-systems';
import {
    createNewEnvironment,
    useCreatedEnvironment,
    useDeletedEnvironment,
    useRestoredEnvironment,
} from '@walkme-admin-center/libs/state-management-extensions';
import { StyledPageTitleLabel } from '../../../users-roles/src/lib/users.styles';
import { AllEnvironmentsPage } from './all-environments-page';
import { DeletedEnvironmentsPage } from './deleted-environments-page';
import { getSystemEnvs } from '../../../sso-configuration/src/lib/common/helpers';
import { generateSnippet } from '../../../extension-pages/src/lib/web-systems-advanced-settings/site_snippet/generate-snippet';
import NewEnvironmentForm from './components/new-environment-form';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { StyledTabsRow } from 'packages/pages/home/systems/src/lib/systems.styles';
import {
    StyledTitleText,
    StyledRootDiv,
    StyledContainerDiv,
    StyledTabsContainer,
    StyledTab,
    StyledSearchAndAddBox,
    StyledTitleDiv,
    StyledTabs,
    StyledButton,
    StyleSubtitleText,
} from './environments.styles';

enum TabsPaths {
    MainPath = '/environments',
    AllEnvironments = '/environments/all-environments',
    DeletedEnvironments = '/environments/deleted-environments',
}

export interface EnvironmentsTabProps {
    systemId: number;
}

export const Environments = (props: EnvironmentsTabProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const location = useLocation();
    const { envId } = useParams<{ envId }>();
    const { systemId = props.systemId } = useParams<{ systemId }>();
    const {
        loggedInUserAppData: {
            data: { impersonator, id: userId },
        },
    } = useLoggedInUser();
    const { usersAppData } = useUsers();
    const { createdEnvironmentAppData } = useCreatedEnvironment();
    const { deletedEnvironmentAppData } = useDeletedEnvironment();
    const { updatedEnvironmentAppData } = useUpdatedEnvironment();
    const { restoredEnvironmentAppData } = useRestoredEnvironment();
    const { currentSystemAppData } = useSystem(systemId);
    const [allSystemEnvs, setAllSystemEnvs] = useState<Environment[]>([]);
    const currentTab = location.pathname.includes(generatePath(systemId, TabsPaths.DeletedEnvironments))
        ? generatePath(systemId, TabsPaths.DeletedEnvironments)
        : generatePath(systemId, TabsPaths.AllEnvironments);
    const [currentPath, setCurrentPath] = useState<string>(currentTab);
    const shouldAutoOpenCreateEnvironment = envId === 'new';
    const [showCreateDialog, setShowCreateDialog] = useState(shouldAutoOpenCreateEnvironment);
    const [filter, setFilter] = useState('');
    const [filteredSystemEnvs, setFilteredSystemEnvs] = useState<Environment[]>();
    const [selectedEnvironment, setSelectedEnvironment] = useState<Environment>();
    const [environmentLoading, setEnvironmentLoading] = useState(true);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState('');
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState('');
    const [openWarningSnackbar, setOpenWarningSnackbar] = useState('');
    const { innerTabName } = useParams<{ innerTabName }>();
    const navigate = useNavigate();

    const isSystemAssignedToUser = useMemo(
        () => usersAppData.data.find((user) => user.id === userId)?.systems?.some((system) => system.id === systemId),
        [usersAppData.data, userId, systemId]
    );

    useEffect(() => {
        getEnvs();
        document.title = 'Admin Center - Environments';
    }, []);

    useEffect(() => {
        if (
            createdEnvironmentAppData.data.action ||
            deletedEnvironmentAppData.data.action ||
            updatedEnvironmentAppData.data.action ||
            restoredEnvironmentAppData.data.action
        ) {
            getEnvs();
        }
    }, [createdEnvironmentAppData, deletedEnvironmentAppData, updatedEnvironmentAppData, restoredEnvironmentAppData]);

    useEffect(() => {
        const envs = allSystemEnvs.filter((env) => env.name.toLowerCase().includes(filter.toLowerCase()));
        setFilteredSystemEnvs(envs);
    }, [filter]);

    const handleSnackBarSuccessClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSuccessSnackbar('');
    };

    const handleSnackBarWarningClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenWarningSnackbar('');
    };

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar('');
    };

    const createEnvironment = useCallback(
        (envName: string) => {
            (dispatch as AppThunkDispatch)(
                createNewEnvironment({
                    isDev: false,
                    userId: systemId,
                    systemId,
                    envName,
                })
            );
        },
        [dispatch]
    );

    const triggerEnvironmentCreateFormDialog = () => {
        return (
            <NewEnvironmentForm
                open={showCreateDialog}
                onClose={() => setShowCreateDialog(false)}
                environments={allSystemEnvs}
                createEnvironment={createEnvironment}
                generateSnippetCode={generateSnippetCode}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                setOpenWarningSnackbar={setOpenWarningSnackbar}
                systemId={systemId}
                loading
            />
        );
    };

    function generatePath(id: number, path: TabsPaths) {
        return `/systems/all-systems/${id}${path}`;
    }

    function generateSnippetCode(environment: Environment) {
        return generateSnippet(environment.path, currentSystemAppData.data.guid, window.clientConfig.NX_CDN_URL).trim();
    }

    async function getEnvs() {
        const envs = await getSystemEnvs(systemId, true, !!impersonator, true);
        setAllSystemEnvs(envs);
        setFilteredSystemEnvs(envs);
        setEnvironmentLoading(false);
    }

    const [activeTab, setActiveTab] = useState(innerTabName === 'deleted-environments' ? 1 : 0);

    const handleTabChange = (newValue: number) => {
        if (activeTab == newValue) return;
        setActiveTab(newValue);
        if (newValue === 0) {
            navigate({
                pathname: generatePath(systemId, TabsPaths.AllEnvironments),
            });
        } else {
            navigate({
                pathname: generatePath(systemId, TabsPaths.DeletedEnvironments),
            });
        }
    };

    return (
        <>
            <WMSnackbar
                open={openSuccessSnackbar ? true : false}
                onClose={handleSnackBarSuccessClose}
                variant={WMSnackbarVariant.Success}
                message={openSuccessSnackbar}
            />

            <WMSnackbar
                open={openWarningSnackbar ? true : false}
                onClose={handleSnackBarWarningClose}
                variant={WMSnackbarVariant.Warning}
                message={openWarningSnackbar}
            />

            <WMSnackbar
                open={openErrorSnackbar ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={openErrorSnackbar}
            />

            {showCreateDialog && triggerEnvironmentCreateFormDialog()}

            <StyledRootDiv className='noPadding'>
                <StyledContainerDiv>
                    <StyledTitleDiv>
                        <StyledTitleText>{t('systems-tab.all-systems-page.environments-page.title')}</StyledTitleText>
                        <StyleSubtitleText>{t('systems-tab.all-systems-page.environments-page.sub-title')}</StyleSubtitleText>
                    </StyledTitleDiv>
                    {usersAppData.data.length > 0 && isSystemAssignedToUser && (
                        <>
                            <StyledTabsRow>
                                <StyledTabsContainer>
                                    <StyledTabs
                                        initialValue={activeTab}
                                        onChange={(tabItem) => handleTabChange(Number(tabItem.value))}
                                        items={[
                                            {
                                                content: (
                                                    <AllEnvironmentsPage
                                                        pageData={{
                                                            environments: filteredSystemEnvs,
                                                            generateSnippetCode,
                                                            setOpenErrorSnackbar,
                                                            setOpenSuccessSnackbar,
                                                            setOpenWarningSnackbar,
                                                            setSelectedEnvironment,
                                                            setShowCreateDialog,
                                                            selectedEnvironment,
                                                            showCreateDialog,
                                                            systemId,
                                                            envId,
                                                        }}
                                                        loadingData={environmentLoading}
                                                    />
                                                ),
                                                label: t('systems-tab.all-systems-page.environments-page.all-envs'),
                                                value: '0',
                                            },
                                            {
                                                content: (
                                                    <DeletedEnvironmentsPage
                                                        pageData={{
                                                            environments: filteredSystemEnvs,
                                                            setOpenSuccessSnackbar,
                                                            setOpenErrorSnackbar,
                                                            setSelectedEnvironment,
                                                            selectedEnvironment,
                                                            systemId,
                                                        }}
                                                        loadingData={environmentLoading}
                                                    />
                                                ),
                                                label: t('systems-tab.all-systems-page.environments-page.deleted-envs'),
                                                value: '1',
                                            },
                                        ]}
                                    />
                                    <StyledSearchAndAddBox>
                                        <div>
                                            <InputSearch placeholder={t('common.search')} onChange={(query) => setFilter(query)} />
                                        </div>
                                        <WMDivider orientation='vertical' />
                                        <WMTooltip title={t('systems-tab.all-systems-page.environments-page.add-environment')} arrow>
                                            <StyledButton
                                                onClick={() => setShowCreateDialog(true)}
                                                iconComponent={<WMIconPlus />}></StyledButton>
                                        </WMTooltip>
                                    </StyledSearchAndAddBox>
                                </StyledTabsContainer>
                            </StyledTabsRow>
                        </>
                    )}
                    {usersAppData.data.length > 0 && !isSystemAssignedToUser && (
                        <UnsupportedErrorPage
                            whyText={t('systems-tab.all-systems-page.environments-page.no-access.why')}
                            whatToDoText={t('systems-tab.all-systems-page.environments-page.no-access.what-to-do')}
                        />
                    )}
                </StyledContainerDiv>
            </StyledRootDiv>
        </>
    );
};
