import styled from 'styled-components';
import { WMSelect } from '@walkme/wm-ui';
import { Menu } from '@walkme/ui-core';

export const StyledUUIDWrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 9.25fr 0.75fr;
`;
export const StyledUUIDContainer = styled.div`
    padding-left: 24px;
    background-color: white;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledUUIDTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 32px 0 8px 0;

    .main-title {
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 24px;
        font-weight: 600;
    }

    .sub-title {
        font-size: 14px;

        .learn-more {
            color: var(--Color-Semantic-Text-secondary, #637191);
            font-weight: 200;
            margin-top: 4px;

            a {
                text-decoration: none;
                color: var(--Blue-Primary-Primary-Main, #385feb) !important;
            }
        }
    }
`;

export const StyledUUIDMenuWrapper = styled.div`
    margin: 24px 0 14px 0;

    .MuiMenu-list {
        padding: 0 !important;
    }
`;

export const StyledUUIDSelect = styled(WMSelect)`
    max-width: 300px;
`;

export const StyledWMMenu = styled(Menu)`
    margin-top: 8px;

    .MuiMenu-list {
        padding: 0 !important;
    }
`;

export const StyledUUIDMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    .menu-window {
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;

        .menu-item {
            display: flex;
            justify-content: space-between;
            padding: 12px 16px;
            cursor: pointer;

            &:hover {
                background-color: #f0f3fa;
            }
        }
    }

    .description-window {
        background-color: #f0f3fa;
        padding: 12px 16px;

        .title {
            margin-top: 14px;
        }

        .description {
            color: #697396;
        }
    }
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean; isDisabled?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ isSelected, isDisabled }) => (isSelected ? '#f0f3fa' : 'white')};
    color: ${({ isSelected, isDisabled }) => (isDisabled ? '#989898' : isSelected ? '#2e62dc' : '#2f426c')};
    &:hover {
        background-color: ${({ isDisabled }) => (isDisabled ? '#f8f8f8' : '#f0f3fa')};
    }
`;

export const StyledToasterMessage = styled.div`
    a {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;

export const StyledSaveAndPublish = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 32px;

    .save-and-publish {
        margin-left: 8px;
    }
`;
