import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    containerBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        width: '100%',
    },
    systemIconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
        borderRadius: '4px',
        backgroundColor: '#EDF1FD',
        flexGrow: 0,
        flexShrink: 0,
        '& img': {
            width: '28px',
            height: '28px',
        },
    },
    systemNamesBox: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    systemTypeName: {
        fontFamily: 'ProximaNova',
        color: '#8D97AE',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
    },
    systemName: {
        fontFamily: 'ProximaNova',
        color: '#2F426C',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    platformTypeBox: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-end',
        height: '24px',
        flexGrow: 0,
        flexShrink: 0,
        gap: '8px',
    },
    platformTypeChip: {
        '&.WMChip-wmChip': {
            borderRadius: '4px',
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
}));

export { useStyles };
