import React from 'react';
import { BoldText, SmallBoldText, StepContainer, SubtitleText, TitleContainer, TitleText, VideoStepNumber } from './slack-steps-styles';
import { retriveCreateNewAppVideo } from '@walkme-admin-center/libs/common';
import VideoContainer from './video-container';

const CreateNewAppStep = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>3.</VideoStepNumber>Click<BoldText>Create New App</BoldText>
                </TitleText>
                <SubtitleText className={'flex-row'}>
                    Then click <SmallBoldText>From an app manifest</SmallBoldText> and select your workspace from the dropdown
                </SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveCreateNewAppVideo()} />
        </StepContainer>
    );
};

export default CreateNewAppStep;
