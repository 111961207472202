import React, { useEffect, useState } from 'react';
import { PageTitle, RouteHeader } from '@walkme-admin-center/libs/shared-styles';
import NoticeMessage from '@walkme-admin-center/libs/common/lib/notice-message/notice-message';
import ViewContainer from './components/view/view-container';
import { EditContainer } from './components/edit/edit-container';
import { cloudStorageApi } from './api/cloud-storage-api';

interface PageData {
  editDisabled?: boolean;

  defaultCloudStorage: string;
}

interface HostingPageProps {
  name: string;

  pageData?: PageData;
}

export const HostingPage = (props: HostingPageProps) => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [cloudStorage, setCloudStorage] = useState<string>(null);
  const { editDisabled = false, defaultCloudStorage } = props.pageData || {};

  const toggleEditDialog = () => setShowEditDialog(!showEditDialog);

  const initCloudStorage = async () => {
    const result = defaultCloudStorage || await cloudStorageApi.getUserCloudStorage();

    setCloudStorage(result);
  }

  useEffect(() => {
    if (!cloudStorage) {
      initCloudStorage();
    }
  })

  return (
    <>
      <PageTitle>{props.name} Hosting Management</PageTitle>
      <NoticeMessage>Changing settings may require deployment (snippet / extension) and to publish global settings</NoticeMessage>
      {cloudStorage && (
        <>
          <ViewContainer cloudStorage={cloudStorage} toggleEditDialog={toggleEditDialog} editDisabled={editDisabled}/>
          <EditContainer showEditDialog={showEditDialog} toggleEditDialog={toggleEditDialog}/>
        </>
      )}
    </>
  );
}

export default HostingPage;
