import React, { useState, useEffect, useMemo } from 'react';
import { ContractsComponent } from '@walkme/accounts-contracts-ui';
import { AccountsSdk, BoConnectSystemsDto, ContractsRawData } from 'wm-accounts-sdk';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import { useLoggedInUser, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { StyledTitleContainer, StyledPageTitle, StyledPageTitleLabel, StyledContractsPage } from './contracts-entry.styles';
import { contractsAdminCenterConfig } from './contracts-entry.lib';
import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const ContractsEntry = () => {
    const { t } = useTranslation('general');
    const { accountFeatureEnabled } = useLoggedInUser();
    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);

    const [contracts, setContracts] = useState<ContractsRawData>({
        contracts: [],
        systems: [],
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        variant: WMSnackbarVariant.Warning,
        message: '',
    });
    const { systemsAppData } = useSystems();
    const { usersAppData } = useUsers();
    const accountUsers = usersAppData?.data || [];
    const accountSystems = systemsAppData?.data || [];

    const getContracts = async () => {
        try {
            const contracts = await AccountsSdk.getInstance().contractsManagement.getContracts();
            setContracts(contracts);
        } catch (error) {
            showSnackbar(WMSnackbarVariant.Error, error.message);
        }
    };

    const connectSystemToContract = async (connectSystemsDto: BoConnectSystemsDto) => {
        try {
            const contract = contracts.contracts.find((c) => c.sfId === connectSystemsDto.contractId);
            const response = await AccountsSdk.getInstance().contractsManagement.connectSystemToContract(connectSystemsDto);
            responseValidation(response, true, contract?.description);
        } catch (e) {
            showSnackbar(WMSnackbarVariant.Error, e.message);
        }
    };

    const disconnectSystemFromContract = async (disconnectSystemsDto: BoConnectSystemsDto) => {
        try {
            const contract = contracts.contracts.find((c) => c.sfId === disconnectSystemsDto.contractId);

            const response = await AccountsSdk.getInstance().contractsManagement.adminDisconnectSystemToContract(disconnectSystemsDto);
            responseValidation(response, false, contract?.description);
        } catch (e) {
            showSnackbar(WMSnackbarVariant.Error, e.message);
        }
    };

    const findDisplayName = (guids) => {
        const formatGuid = (guid) => guid.replace(/-/g, '');
        const systems = accountSystems?.filter((system) => guids.map((guid) => formatGuid(guid)).includes(formatGuid(system.guid)));
        return systems.map((s) => s.displayName);
    };

    const responseValidation = (response, isConnectRequest, contractDescription: string) => {
        if (response.errorSystemGuids.length === 0) {
            const displayNames = findDisplayName(response.successSystemGuids).join(', ');
            showSnackbar(
                WMSnackbarVariant.Success,
                `System ${displayNames} ${isConnectRequest ? 'connected to' : 'detached from'} ${contractDescription ?? 'contract'}`
            );
            return;
        }
        const displayNames = findDisplayName(response.errorSystemGuids).join(', ');
        showSnackbar(
            WMSnackbarVariant.Error,
            `Error ${isConnectRequest ? 'connecting' : 'disconnecting'} ${displayNames} ${isConnectRequest ? 'to' : 'from'} contract`
        );
    };

    const fetchData = async () => {
        setLoading(true);
        await getContracts();
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        document.title = t('contracts-tab.document-title');
    }, []);

    const showSnackbar = (variant: WMSnackbarVariant, message) => {
        setSnackbar({ open: true, variant, message });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <StyledContractsPage>
            <WMSnackbar open={snackbar.open} onClose={handleSnackbarClose} variant={snackbar.variant} message={snackbar.message} />
            <StyledTitleContainer>
                <StyledPageTitle>{t('contracts-tab.title')}</StyledPageTitle>
                <StyledPageTitleLabel>{t('contracts-tab.description')}</StyledPageTitleLabel>
            </StyledTitleContainer>
            <ContractsComponent
                contractsData={contracts}
                systemsData={accountSystems}
                usersData={accountUsers}
                getContracts={getContracts}
                connectSystemToContract={connectSystemToContract}
                disconnectSystemFromContract={disconnectSystemFromContract}
                loading={loading}
                config={contractsAdminCenterConfig}
            />
        </StyledContractsPage>
    );
};
