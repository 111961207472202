import { useSelector } from 'react-redux';
import { DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useUpdatedDesktopTrackedApp = () => {
    const updatedDesktopTrackedAppData = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.updatedDesktopTrackedApp
    );

    return {
        updatedDesktopTrackedAppData
    };
};

export default useUpdatedDesktopTrackedApp;
