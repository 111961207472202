import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import { ThunkAction } from 'redux-thunk';

export const IMPERSONATION_PASSWORD_ERROR = 'Impersonate password was wrong';
export const IMPERSONATION_PASSWORD_ERROR_TYPE = 'WmWrongImpersonatePaswordException';

export class ImpersonationPasswordError extends Error {}

export type ActionWithPassword = (password: string) => Promise<void> | void;

export interface ImpersonationState {
    isDialogOpen: boolean;
    storedPassword: string;
    passwordCandidate: string;
    currentPassword: string;
    pendingAction: ActionWithPassword;
}

export const initialImpersonationState: ImpersonationState = {
    isDialogOpen: false,
    storedPassword: null,
    passwordCandidate: null,
    currentPassword: null,
    pendingAction: null,
};

const impersonationSlice = createSlice({
    name: 'impersonationSlice',
    initialState: initialImpersonationState,
    reducers: {
        setIsDialogOpen(state: ImpersonationState, action: PayloadAction<boolean>) {
            state.isDialogOpen = action.payload;
            return state;
        },
        setPendingAction(state: ImpersonationState, action: PayloadAction<ActionWithPassword>) {
            state.pendingAction = action.payload;
            return state;
        },
        setStoredPassword(state: ImpersonationState, action: PayloadAction<string>) {
            const { passwordCandidate } = state;
            state.storedPassword = action.payload;
            state.currentPassword = state.storedPassword || passwordCandidate;
            return;
        },
        setPasswordCandidate(state: ImpersonationState, action: PayloadAction<string>) {
            const { storedPassword } = state;
            state.passwordCandidate = action.payload;
            state.currentPassword = storedPassword || state.passwordCandidate;
            return;
        },
    },
});

export { impersonationSlice };

const { setIsDialogOpen, setPendingAction, setStoredPassword, setPasswordCandidate } = impersonationSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk<ReturnType = void> = ThunkAction<ReturnType, rootReducerType, unknown, Action<string>>;

const startActionWithPassword =
    (action: ActionWithPassword): AppThunk =>
    async (dispatch, getState) => {
        const { storedPassword } = getState().impersonationState;
        if (storedPassword) {
            if (action && typeof action == 'function') {
                await action(storedPassword);
            }
        } else {
            dispatch(setPendingAction(action));
            dispatch(setIsDialogOpen(true));
        }
    };

const actionWithPasswordSucceeded = (): AppThunk => async (dispatch, getState) => {
    const { passwordCandidate, storedPassword: impersonationMasterPassword } = getState().impersonationState;
    if (!impersonationMasterPassword && passwordCandidate) {
        dispatch(setStoredPassword(passwordCandidate));
    }
};

const actionWithPasswordFailed = (): AppThunk => async (dispatch, getState) => {
    dispatch(setStoredPassword(null));
    dispatch(setPasswordCandidate(null));
};

const impersonationDialogConfirmed =
    (passwordCandidate: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(impersonationSlice.actions.setPasswordCandidate(passwordCandidate));
        dispatch(setIsDialogOpen(false));

        const { pendingAction } = getState().impersonationState;
        try {
            if (typeof pendingAction == 'function') {
                await pendingAction(passwordCandidate);
            }
        } catch (e) {
            console.error(e);
        }
    };

const impersonationDialogCanceled = (): AppThunk => async (dispatch, getState) => {
    dispatch(impersonationSlice.actions.setPasswordCandidate(null));
    dispatch(setIsDialogOpen(false));
};

const thunks = {
    impersonationDialogCanceled,
    impersonationDialogConfirmed,
    startActionWithPassword,
    actionWithPasswordSucceeded,
    actionWithPasswordFailed,
};

export { thunks as impersonationThunks };
