import React from 'react';
import { CancelButton, DeleteButton } from '../../../integrations-page/integrations-forms/warning-modal-style';
import { DeleteHeaderText } from '../../../integrations-page/integration-style';
import { CloseButtonContainer } from '../../../integrations-page/integrations-forms/modal-style';
import { CssCheckBox } from '../../../../../data-integrations-list/integration-row/integration-row-style';
import { CloseIcon } from '../../../../../common/consts';
import styled from 'styled-components';
import { DialogBody, DialogContainer, DialogFooter, DialogHeader } from './rules/rules-style';

const CheckBoxContainer = styled.div`
    display: flex;
    padding: 8px 32px;
    gap: 8px;

    .checkbox-pointer {
        cursor: pointer;
    }
`;

interface ChannelWarningDialogProps {
    cancel: () => void;
    confirm: {
        (): void;
        (): void;
    };
    setShowDialogAgain: (value: boolean) => void;
    text: string;
    header: string;
    buttonText: string;
}

const ChannelWarningDialog = ({ cancel, confirm, setShowDialogAgain, text, header, buttonText }: ChannelWarningDialogProps) => {
    return (
        <DialogContainer>
            <DialogHeader>
                <div className={'dialog-header-container'}>
                    <DeleteHeaderText>{header}</DeleteHeaderText>
                    <CloseButtonContainer onClick={cancel}>
                        <img src={CloseIcon} alt='Close' style={{ height: '12px', width: '12px', padding: '4px' }} />
                    </CloseButtonContainer>
                </div>
            </DialogHeader>
            <DialogBody>{text}</DialogBody>
            <div className={'check-box-wrapper'}>
                <CheckBoxContainer>
                    <CssCheckBox
                        className={'checkbox-pointer'}
                        type='checkbox'
                        onChange={(event) => setShowDialogAgain(event.target.checked)}
                    />
                    <label style={{ color: '#2F426C' }}>Do not show this message again</label>
                </CheckBoxContainer>
            </div>
            <DialogFooter>
                <div className={'dialog-footer-container'}>
                    <CancelButton onClick={cancel}>Cancel</CancelButton>
                    <DeleteButton onClick={confirm}>{buttonText}</DeleteButton>
                </div>
            </DialogFooter>
        </DialogContainer>
    );
};

export default ChannelWarningDialog;
