import { useSelector } from 'react-redux';
import { SsoConfigurationState } from '../redux/sso-configuration.slice';

export const useError = () => {
  const error = useSelector((state: { ssoConfigurationState: SsoConfigurationState }) => state.ssoConfigurationState.error);
  return {
    error,
  };
};

export default useError;
