import {
    AuthType,
    ScanFrequency,
    ScrapeStatus,
    Status,
    IntegrationChannelOptions,
    IAskMeIntegrationSystems,
    IIntegrationRules,
    IIntegrationRulesForm,
    JobType,
    RevertStatus,
} from './index';

export interface ICreateAskmeResponse {
    askMe: IAskMe;
    whiteList: IWhiteList;
}

export interface IUpdateAskmeResponse extends ICreateAskmeResponse {
    askMe: IAskMe;
    whiteList: IWhiteList;
}

export interface IAskMe {
    id: number;
    createdAt: string;
    isExecuting: boolean;
    jobStatus: IJobStatus;
    type: JobType;
    name: string;
    settings: IAskMeSettings;
    lastCrawl: string;
    runningCrawl?: string;
    sourceId: string;
    systems: IAskMeSystems;
    updatedAt: string;
    userId: number;
    privacyLevel: boolean;
    slackSystems: IAskMeIntegrationSystems;
    slackRules: IIntegrationRules;
    teamsSystems: IAskMeIntegrationSystems;
    teamsRules: IIntegrationRules;
    files: IAskMeFile[];
    guidedAnswersCount?: number;
    isOmni?: boolean;
    isPrivate?: boolean;
}

export interface IAskMeFile {
    objectKey: string;
    metadata: {
        name: string;
        size: string;
    };
    createdAt: number;
}

export type IAskMeFileStore = { [guid: string]: IAskMeFile };

export interface IAskMeFormState {
    isFooterDisabled?: boolean;
    warning?: string;
    files?: any[];
}

export interface IAskMeById {
    settings: IAskMeSettings;
    slackSystems: IAskMeIntegrationSystems;
    slackRules: IIntegrationRules;
    files?: IAskMeFile[];
}

export interface IAuditScan {
    frequency?: ScanFrequency;
    dayOfWeek?: number;
    dayOfMonth?: number;
    customDate?: string;
}

export interface IAskMeSettings {
    allowPath: Array<string>;
    denyPath: Array<string>;
    auth: IAuth;
    authType: AuthType;
    controlCss: boolean;
    controlDelay: boolean;
    controlDomains: boolean;
    cssExclude: string;
    cssInclude: string;
    delay: number;
    includeSubDomain: boolean;
    auditScan?: IAuditScan;
    startingUrl: string;
    includeHash: boolean;
    slackChannelsConfig: IntegrationChannelOptions;
    teamsChannelsConfig: IntegrationChannelOptions;
    files?: Array<string>;
}

interface IAuth {
    accessTokenUrl?: string;
    clientId?: string;
    clientSecret?: string;
    scope?: string;
    token?: string;
    userName?: string;
    password?: string;
}

export interface IAskMeForm {
    name: string;
    type: JobType;
    settings: IAskMeSettingsForm;
    systems: IAskMeSystems;
    isOmni?: boolean;
    isPrivate?: boolean;
    slackSystems: IAskMeIntegrationSystemForm[];
    slackRules: IIntegrationRulesForm[];
    teamsSystems: IAskMeIntegrationSystemForm[];
    teamsRules: IIntegrationRulesForm[];
    rescan?: boolean;
}

export interface IAskMeIntegrationSystemForm {
    channelGuid: string;
}

export interface IAskMeSettingsForm extends ISettings {
    controlDelay: string;
}

export interface IAskMeSystem {
    guid: string;
    name: string;
    isActive?: boolean;
    type: string;
    segment_ids?: number[];
}

export interface IAssignSystemsForm {
    systems: IAskMeSystems;
}

export interface IGuidedAnswer {
    guid?: string;
    question: string;
    answer: string;
    created_at?: string;
}

export type IGuidedAnswers = IGuidedAnswer[];

export interface IGuidedAnswersBulkUpdateData {
    add: IGuidedAnswers;
    edit: Required<IGuidedAnswer>[];
    delete: string[]; // guids
}

export interface IGuidedAnswersBulkUpdateRequest {
    sourceGuid: string;
    guidedAnswers: IGuidedAnswersBulkUpdateData;
}

export interface IAskMeFormSubmit {
    name?: string;
    settings: IAskMeSettings;
    systems: IAskMeSystems;
    slackSystems: IAskMeSystems;
    slackRules: IIntegrationRules;
}

export interface IJobStatus extends IExecutionStatus {
    startedAt: string;
    completedAt: string;
}

export interface IAskMeFormUrlPath {
    value: string;
}

export declare type IAskMeFormUrlsPath = IAskMeFormUrlPath[];

interface ISettings {
    auth: IAuth;
    allowPath: Array<string>;
    denyPath: Array<string>;
    authType: AuthType;
    controlCss: boolean;
    controlDomains: boolean;
    cssExclude: string;
    cssInclude: string;
    delay: number;
    includeSubDomain: boolean;
    auditScan?: IAuditScan;
    startingUrl: string;
    includeHash: boolean;
    slackChannelsConfig: IntegrationChannelOptions;
    teamsChannelsConfig: IntegrationChannelOptions;
}

export interface IExecutionStatus {
    sourceGuid: string;
    isExecuting: boolean;
    lastCrawl: string;
    runningCrawl?: string;
    scan: number;
    crawl?: number;
    scrape: number;
    crawl_failed?: number;
    scrape_failed?: number;
    jobStatus?: ScrapeStatus;
    status: Status;
    executionId: number;
    lastRevertStatus: RevertStatus;
}

export interface IExecute {
    askMeId?: number;
    sourceGuid?: string;
    scan: number;
    scrape: number;
    crawl_failed: number;
    scrape_failed: number;
    status: Status;
}

export interface IWhiteList {
    id: number;
    ipList: string;
}

export interface IDataIntegrationAppSettings {
    whiteList: IWhiteList;
}

export interface IDataIntegrationAppSettingsForm {
    ipList: Array<string>;
}

export interface ISystemID {
    id: string;
    systemGuid: string;
}

export interface ISystemSource {
    sourceId: number;
    systemsId: Array<ISystemID>;
}

export interface IHeaderText {
    header: string;
    subHeader?: string;
}

export interface ITag {
    Settings: {
        Color: string;
    };
    Name: string;
    Id: number;
}

export interface IQuestionFeedbackPayload {
    question_uuid: string;
    feedback_text?: string;
    feedback: number;
    system_guid: string;
}

export interface IFeedbackPayload {
    system_guid: string;
    feedback: string;
    rank: number;
}

export interface IGlobalSettings {
    isAdminMode?: boolean;
}

export interface IUrlSummary {
    count: number;
    list: string[];
}

export interface IScanSummary {
    startingUrl: string;
    canRevert?: boolean;
    aiSummary: string;
    urls: {
        success?: IUrlSummary;
        failed?: IUrlSummary;
        added?: IUrlSummary;
        modified?: IUrlSummary;
        removed?: IUrlSummary;
    };
}

export declare type IAskMeSystems = IAskMeSystem[];
export declare type IAsksMe = IAskMe[];
export declare type IExecutionsStatus = IExecutionStatus[];
export declare type ITags = ITag[];
export declare type ISystemSegments = { [systemId: number]: ITags };
