import * as yup from 'yup';
import isValidDomain from 'is-valid-domain';
import { setIn } from 'final-form';

const isValidHttpUrl = (str: string): boolean => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );
    return !!pattern.test(str);
};

export const oAuth2OktaSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientId: yup.string().trim().max(255).required('Required'),
        clientSecret: yup.string().trim().max(255).required('Required'),
        domain: yup
            .string()
            .test('Valid Application Domain', 'Please enter valid Application Domain', (value) => isValidDomain(value))
            .max(255)
            .required('Required'),
    }),
});

export const oAuth2AzureSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientId: yup.string().trim().max(255).required('Required'),
        clientSecret: yup.string().trim().max(255).required('Required'),
        directoryId: yup.string().trim().max(255).required('Required'),
        domainHint: yup.string().trim().max(255),
    }),
});

export const oAuth2adfsSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientId: yup.string().trim().max(255).required('Required'),
        domain: yup
            .string()
            .test('Valid Application Domain', 'Please enter valid Application Domain', (value) => isValidDomain(value))
            .max(255)
            .required('Required'),
    }),
});

export const oAuth2GsuiteSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientId: yup.string().trim().max(255).required('Required'),
        clientSecret: yup.string().trim().max(255).required('Required'),
    }),
});

export const oAuth2pingIDSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientId: yup.string().trim().max(255).required('Required'),
        clientSecret: yup.string().trim().max(255).required('Required'),
        discoveryEndpoint: yup
            .string()
            .matches(/^https:\/\/([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}/, 'Please enter valid domain name')
            .matches(/\/\.well-known\/openid-configuration$/, 'Please enter valid discovery endpoint')
            .matches(/^https:\/\//, 'Please use only secured connection over HTTPS')
            .max(255)
            .required('Required'),
    }),
});

export const oAuth2OIDCSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientId: yup
            .string()
            .trim()
            .matches(/^[a-zA-Z0-9_`'".,?!@#$%^&*\\/|;:+=-]+$/, 'Wrong format, not allowed symbol(s)')
            .max(255)
            .required('Required'),
        clientSecret: yup.string().trim().max(255).required('Required'),
        discoveryEndpoint: yup
            .string()
            .matches(/^https:\/\/([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}/, 'Please enter valid domain name')
            .matches(/^https:\/\//, 'Please use only secured connection over HTTPS')
            .max(255)
            .required('Required'),
        scope: yup
            .string()
            .matches(/^[\w-]+(,[\w-]+)*$/, {
                message: 'Not valid value. Allowed symbols are letters, digits and -.',
                excludeEmptyString: true,
            })
            .max(255)
            .required('Required'),
        cspDomains: yup
            .string()
            .matches(/^([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}(,([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,})*$/, {
                message: 'Please enter valid domains name comma separated',
                excludeEmptyString: true,
            })
            .max(255),
        providerName: yup.string().max(255, `Integration name can't contain more than 255 characters`),
    }),
});

export const samlSettingsSchema = yup.object().shape({
    name: yup.string().max(255, 'IDP Name must be at most 255 characters').required('Required'),
    config: yup.object().shape({
        clientSecret: yup
            .string()
            .test('Has "BEGIN CERTIFICATE"', 'certificate must start with -----BEGIN CERTIFICATE-----', (value) => {
                return value === 'secret-is-secret' || value?.startsWith('-----BEGIN CERTIFICATE-----');
            })
            .test('Has "END CERTIFICATE"', 'certificate must end with -----END CERTIFICATE-----', (value) => {
                return value === 'secret-is-secret' || value?.endsWith('-----END CERTIFICATE-----');
            })
            .max(5000)
            .required('Required'),
        domain: yup
            .string()
            .test('Valid Application single sign-on url', 'Please enter valid Application single sign-on url', (value) =>
                value ? isValidHttpUrl(value) : true
            )
            .max(255)
            .required('Required'),
    }),
});

export const getErrorObject = async (schema, values): Promise<object> => {
    if (typeof schema === 'function') schema = schema();
    try {
        await schema.validate(values, { abortEarly: false });
    } catch (e) {
        return e.inner.reduce((errors, error) => {
            return setIn(errors, error.path, error.message);
        }, {});
    }
};
