import { Select } from '@material-ui/core';
import styled from 'styled-components';
import { ToggleButton } from '@material-ui/lab';

export const StyledSelect = styled(Select)`
    width: 100%;

    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 12px 14px;
    }

    .root {
        padding: 12px 0;
    }

    .selectMenuItem {
        & .MuiMenu-paper {
            max-height: 250px;
            margin-top: 8px;
            border-radius: 10px;
            box-shadow: 0px 10px 25px #121b4e1c;
        }

        &.one-id-select .MuiMenu-paper {
            max-height: 144px;
        }
    }
`;

export const StyledProviderForm = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    & .typeContainer {
        display: flex;
        flex-direction: column;
        padding-top: 35px;
    }
`;

export const StyledToggleButton = styled(ToggleButton)`
    &.toggleButtonRoot {
        width: 140px;
        height: 40px;
    }

    &.toggleButtonSelected {
        color: white !important;
        background-color: rgb(59, 97, 235) !important;
    }
`;
