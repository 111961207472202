import React from 'react';
import { retriveTeamsStepVideo } from '@walkme-admin-center/libs/common';
import VideoContainer from '../../../slack-form/steps/manifest-step/slack-steps/video-container';
import {
    BoldText,
    StepContainer,
    SubtitleText,
    TitleContainer,
    TitleText,
    VideoStepNumber,
} from '../../../slack-form/steps/manifest-step/slack-steps/slack-steps-styles';

const CreateAzureBot2 = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>3.</VideoStepNumber>Complete<BoldText>Azure Bot creation</BoldText>
                </TitleText>
                <SubtitleText className={'flex-row'}>Follow the steps in the video to complete the Azure Bot creation process</SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveTeamsStepVideo(2)} />
        </StepContainer>
    );
};

export default CreateAzureBot2;
