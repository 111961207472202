import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { FormActions as IFormActions } from '@walkme-admin-center/libs/types';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { StyledActions } from '@walkme-admin-center/libs/common/lib/form-actions/styles/styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { ButtonContainer } from './user-form-actions.styles';

export interface FormActionsProps {
    actions: IFormActions;
    submitting?: boolean;
    deleting?: boolean;
    pristine?: boolean;
    invalid?: boolean;
    className?: string;
    values?: unknown;
    next?: () => void;
    saveActionName?: string;
    status?: string;
    userId?: number;
    onUserActivation?: () => void;
    userActivationLoading?: boolean;
}

export const UsersFormActions = ({
    actions: { onCancel, onConfirm, onResend },
    submitting = false,
    pristine = false,
    invalid = false,
    values,
    next,
    status,
    userId,
    className = 'actions',
    saveActionName = 'Save',
    onUserActivation,
    userActivationLoading,
}: FormActionsProps) => {
    const { t } = useTranslation('general');
    const { accountFeatureEnabled } = useLoggedInUser();
    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);
    return (
        <StyledActions className={className}>
            <div className='actions-row'>
                {!!onResend &&
                    (isFeatureEnabled('noEmailActivationRequired') ? (
                        !!onUserActivation && (
                            <WMButton
                                variant={WMButtonVariant.Secondary}
                                type='button'
                                className='form resend'
                                onClick={() => onUserActivation()}
                                loading={userActivationLoading}
                                data-testid='resend-button'>
                                {t('buttons.activate-user')}
                            </WMButton>
                        )
                    ) : (
                        <WMButton
                            variant={WMButtonVariant.Secondary}
                            type='button'
                            className='form resend'
                            onClick={() => onResend(values)}
                            data-testid='resend-button'>
                            {t('buttons.resend-email')}
                        </WMButton>
                    ))}
                <ButtonContainer>
                    <WMButton
                        variant={WMButtonVariant.Text}
                        className='form cancel'
                        type='button'
                        onClick={() => onCancel(pristine)}
                        data-testid='cancel-button'>
                        {t('buttons.cancel')}
                    </WMButton>
                    <WMButton
                        variant={WMButtonVariant.Primary}
                        className={classNames({ form: true }, { save: true }, { main: !(submitting || pristine || invalid) })}
                        disabled={submitting || pristine || invalid}
                        type='submit'
                        loading={submitting}
                        data-testid='confirm-button'
                        onClick={
                            next
                                ? next
                                : () => {
                                      onConfirm(values);
                                  }
                        }>
                        {saveActionName}
                    </WMButton>
                </ButtonContainer>
            </div>
        </StyledActions>
    );
};

export default UsersFormActions;
