interface EnvironmentURLQueryResult {
    envName: string;
    envValue: number;
    urlMatchData: URLMatchData[];
    isRegex: boolean;
    matcherId: string | number;
    configurationSet?: number;
}

interface URLMatchData {
    selfDomainOrRegex: string;
    isMatching: boolean;
}

const enum DomainType {
    Iframe = 1,
    App = 2,
}

export { URLMatchData, EnvironmentURLQueryResult, DomainType };
