import React from 'react';
import { StyledDialog, CloseDialog, StyledContent, StyledTitle } from './styles/styles';
import CloseIcon from '@material-ui/icons/Close';

export interface DialogProps {
    view?: string;
    title?: string;
    side?: string;
    toggleShowDialog?: () => void;
    clearDialog?: () => void;
    showDialog: boolean;
    children?: JSX.Element;
    onClose?: () => void;
    closeText?: string;
    customClass?: string;
}

export const Dialog = ({
    title = '',
    view = 'default',
    side = 'right',
    showDialog,
    children,
    closeText,
    onClose,
    customClass
}: DialogProps) => {
    return (
        <div className="dialog-container">
            <StyledDialog
                fullScreen={view === 'fullScreen'}
                open={showDialog}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                classes={
                    view === 'sideScreen'
                        ? { paper: `dialog sideScreen ${customClass}`, scrollPaper: `sideScreenContainer ${side}` }
                        : { paper: `dialog default ${customClass}` }
                }
            >
                {title && (
                    <StyledTitle className={view} id="form-dialog-title">
                        {title}
                    </StyledTitle>
                )}
                <StyledContent>{children}</StyledContent>
            </StyledDialog>
        </div>
    );
};
