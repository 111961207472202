import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';
import { IdpEnvs, IdpSystemFormValue, IdpSystemsFormValue, Provider } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { uniqBy } from 'lodash';
import NoResult from '../../common/components/no-result/no-result';
import { useNavigate } from 'react-router-dom';
import { Info, Search } from '@material-ui/icons';
import { StepperMode } from '../../common/consts';
import { StepSectionHeaderContainer, StyledCSSSearchField } from '../../common/style';
import {
    EditSystemsWarning,
    StyledAccordion,
    StyledAccordionSummary,
    StyledSystemAssignmentContainer,
    StyledSystemCounterContainer,
    SystemCounter,
} from './system-assignment-step-style';
import { AccordionDetails, AccordionSummary, InputAdornment } from '@material-ui/core';
import { getSystemEnvsWithOutAjax, getSystemTag } from '../../common/helpers';
import EnvBlock from './env-block/env-block';
import { Environments } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization';

export interface SystemAssignmentStepProps {
    provider: Provider;
    allSystems: IdpSystemsFormValue;
    assignIdpSystemsByEnv: IdpEnvs;
    systemsEnvs: Map<string, Environments>;
    stepperMode: string;
    isImpersonator: boolean;
}

export const SystemAssignmentStep = ({
    provider,
    allSystems,
    stepperMode,
    systemsEnvs,
    assignIdpSystemsByEnv,
    isImpersonator,
}: SystemAssignmentStepProps) => {
    const [search, setSearch] = useState('');
    const [expanded, setExpanded] = useState(null);
    const [systemEnv, setSystemEnv] = useState([]);
    const navigate = useNavigate();
    const formApi = useForm();
    const expandIcon = <img src='assets/icons/arrow-down.svg' alt='expand provider' style={{ width: '24px', height: '24px' }} />;

    const { t } = useTranslation('general');

    const initializeAssignSystemsToProvider = new Set(provider.systems.map((x) => x.guid));

    const systems: IdpSystemsFormValue = allSystems.sort((firstElement, secondElement) => {
        const isFirstAssignToProvider = initializeAssignSystemsToProvider.has(firstElement.guid);
        const isSecondAssignToProvider = initializeAssignSystemsToProvider.has(secondElement.guid);
        if (isFirstAssignToProvider && isSecondAssignToProvider) {
            return firstElement.displayName.localeCompare(secondElement.displayName);
        } else if (isFirstAssignToProvider) {
            return -1;
        } else if (isSecondAssignToProvider) {
            return 1;
        }
        return firstElement.displayName.localeCompare(secondElement.displayName);
    });

    const filterSystems = systems.filter((system) => {
        return system.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    const handleChange =
        (system: IdpSystemFormValue) =>
        async (event: React.ChangeEvent, isExpanded: boolean): Promise<void> => {
            if (!isExpanded) {
                setExpanded(null);
            } else {
                const envs = getSystemEnvsWithOutAjax(systemsEnvs.get(system.guid), isImpersonator);
                setSystemEnv(envs || []);
                setExpanded(system.guid);
            }
        };

    const checkEqual = (init: IdpSystemsFormValue, current: IdpSystemsFormValue) => {
        if (init && current) {
            return (
                JSON.stringify(
                    init
                        .map((x) => {
                            return {
                                guid: x.guid,
                                enforceSso: x.enforceSso,
                                wmEnv: x.wmEnv,
                            };
                        })
                        .sort((a, b) => a.guid.localeCompare(b.guid) || b.wmEnv - a.wmEnv)
                ) ===
                JSON.stringify(
                    current
                        .map((x) => {
                            return {
                                guid: x.guid,
                                enforceSso: x.enforceSso,
                                wmEnv: x.wmEnv,
                            };
                        })
                        .sort((a, b) => a.guid.localeCompare(b.guid) || b.wmEnv - a.wmEnv)
                )
            );
        }
        return false;
    };

    const isSelected = (system: IdpSystemFormValue) => {
        return (formApi.getState().values.systems || []).find((s) => s.guid === system.guid && s.providerId === provider.id);
    };

    return (
        <FieldArray name='systems' isEqual={checkEqual}>
            {({ fields }) => (
                <>
                    <StyledSystemAssignmentContainer>
                        <StepSectionHeaderContainer>
                            <span className={'title'}>
                                {stepperMode === StepperMode.NEW ? 'Assign Systems' : 'Manage System Assignment'}
                            </span>
                            <span className={'title-helper'}>
                                Select which Systems you want to assign the IDP Integration to, and whether you want to enforce SSO.
                            </span>
                        </StepSectionHeaderContainer>
                        {stepperMode !== StepperMode.NEW && (
                            <EditSystemsWarning>
                                <div className={'pipe'} />
                                <Info fontSize={'small'} />
                                <span style={{ paddingLeft: '8px' }}>Once a system is unassigned new data will not be collected.</span>
                            </EditSystemsWarning>
                        )}
                        {systems.length > 0 && (
                            <StyledSystemCounterContainer>
                                <SystemCounter>
                                    {`Selected Systems: ${uniqBy(formApi.getState().values.systems, 'guid').length}/${systems.length}`}
                                </SystemCounter>
                                <StyledCSSSearchField
                                    className={'assign-search'}
                                    value={search}
                                    variant='outlined'
                                    placeholder={'Search...'}
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </StyledSystemCounterContainer>
                        )}
                    </StyledSystemAssignmentContainer>

                    {filterSystems.length ? (
                        filterSystems.map((system, index) => {
                            return (
                                <StyledAccordion
                                    classes={{ root: 'root', rounded: 'rounded', expanded: 'expanded' }}
                                    key={system.guid}
                                    expanded={expanded === system.guid}
                                    onChange={handleChange(system)}>
                                    <StyledAccordionSummary
                                        classes={{ root: 'root', content: 'content' }}
                                        expandIcon={expandIcon}
                                        style={{ background: isSelected(system) ? '#F5F7FE' : 'none' }}>
                                        <span>{system.displayName}</span>
                                        <div style={{ display: 'flex' }}>{getSystemTag(system.typeKey, t)}</div>
                                    </StyledAccordionSummary>
                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                        <EnvBlock
                                            providerId={provider.id}
                                            envs={systemEnv}
                                            assignIdpSystemsByEnv={assignIdpSystemsByEnv.filter((s) => s.systemGuid === system.guid)}
                                            system={system}
                                            index={index}
                                            fields={fields}
                                            formApi={formApi}
                                        />
                                    </AccordionDetails>
                                </StyledAccordion>
                            );
                        })
                    ) : allSystems.length === 0 ? (
                        <NoResult
                            image={'/assets/icons/not-found.svg'}
                            paddingTop={'138px'}
                            title={'No Systems Added Yet.'}
                            content={'You have not added any systems yet, go a head and '}
                            actionText={'add a system.'}
                            onAction={() => navigate('systems')}
                        />
                    ) : systems.length ? (
                        <NoResult
                            image={'/assets/icons/not-found.svg'}
                            paddingTop={'138px'}
                            title={''}
                            content={'No systems match your search.'}
                            actionText={'  Clear search'}
                            onAction={() => setSearch('')}
                        />
                    ) : (
                        <NoResult
                            image={'/assets/icons/not-found.svg'}
                            paddingTop={'138px'}
                            title={'All Systems Assigned.'}
                            content={'All systems have already been assigned to an identity provider'}
                            actionText={''}
                            onAction={() => null}
                        />
                    )}
                </>
            )}
        </FieldArray>
    );
};

export default SystemAssignmentStep;
