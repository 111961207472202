import styled from 'styled-components';
import { WMButton } from '@walkme/wm-ui';
import { Box, Dialog, MenuItem, Menu } from '@material-ui/core';
import { styledScrollbar } from '../common/styled-components';

const StyledDialog = styled(Dialog)`
    .MuiDialogTitle-root {
        margin: 24px 32px 0 32px;
        padding: 0;
        color: var(--wmGrayUltraDark);

        & .dialogTitle-closeBtn {
            width: 28px;
            height: 28px;

            & img {
                width: 9px;
                height: 9px;
            }
        }

        & .dialogTitle-titleTxt {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }

        & .dialogTitle-systemInfo {
            margin-top: 24px;
        }

        & .WMDivider-wmDivider {
            margin-top: 16px;
        }
    }

    .MuiDialogContent-root {
        padding-right: 15px;
        padding-left: 32px;
        margin-top: 16px;
        margin-bottom: 8px;

        ${styledScrollbar}
    }

    .MuiDialogActions-root {
        justify-content: space-between;
        margin: 16px 32px 32px 32px;
        padding: 0;

        & > .MuiBox-root {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
`;

const StyledTitleRow = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledSettingsButton = styled(WMButton)`
    &.WMButton-wmButton {
        text-transform: none;
        font-weight: 600;
        line-height: 20px;
        color: #385feb;

        &:hover {
            color: #2b47ac;
        }

        &:active {
            color: #223786;
        }

        &.WMButton-disabled {
            color: #c5cad6;
        }

        & .WMButton-icon {
            margin-right: 9px;
        }
    }
`;

const StyledEnvironmentSelectContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > .MuiTypography-root {
        font-family: 'ProximaNova';
        color: var(--wmGrayUltraDark);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
`;

const StyledInstructionsContainer = styled(Box)`
    margin: 10px 32px;
    padding: 0;

    ol {
        list-style: none;
        counter-reset: steps-counter;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            counter-increment: steps-counter;
            font-family: 'ProximaNova';
            color: var(--wmGrayUltraDark);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-left: 17px;
            text-indent: -1px;

            &:before {
                content: counter(steps-counter) '. ';
                display: inline-flex;
                position: absolute;
                left: -16px;
                justify-content: center;
                width: 14px;
            }
        }
    }
`;

const StyledMenuItem = styled(MenuItem)`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledMenu = styled(Menu)`
    .MuiMenu-paper {
        margin-top: 50px;
        width: 180px;
    }
`;

export {
    StyledDialog,
    StyledTitleRow,
    StyledSettingsButton,
    StyledEnvironmentSelectContainer,
    StyledInstructionsContainer,
    StyledMenuItem,
    StyledMenu,
};
