import { Box, Typography } from '@material-ui/core';
import { TabItem, Tabs, Tooltip } from '@walkme/ui-core';
import { WMTextField } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';
import { ValidationResult, getUrlVerifierFormatValidationResult } from 'packages/pages/util/validation';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { getTooltipIcon } from '../new-design/utils';
import { StyledLink } from './common';
import { URLVerifierDialogContext } from './url-verifier-dialog';
import { DomainType } from './values';

interface IframeTabProps {
    appUrl: string;
    setAppUrl: (url: string) => void;
    appValidationResult: ValidationResult;
    setAppValidationResult: (result: ValidationResult) => void;
    iframeUrl: string;
    setIframeUrl: (url: string) => void;
    iframeValidationResult: ValidationResult;
    setIframeValidationResult: (result: ValidationResult) => void;
}

interface DomainTextFieldProps {
    label: string;
    onChange: (url: string) => void;
    value: string;
    tooltipText: string;
    validationResult: ValidationResult;
    setValidationResult: (result: ValidationResult) => void;
}

interface AppTabProps {
    url: string;
    setUrl: (url: string) => void;
    validationResult: ValidationResult;
    setValidationResult: (result: ValidationResult) => void;
}

interface URLVerifierDialogTabsProps {
    appUrl: string;
    setAppUrl: (url: string) => void;
    iframeUrl: string;
    setIframeUrl: (url: string) => void;
    currentTab: DomainType;
    setCurrentTab: (tab: DomainType) => void;
}

const StyledTextField = styled(WMTextField)({
    paddingTop: '12px',

    '& .WMTextField-inputRoot': {
        borderRadius: '20px',
    },

    '& .WMTextField-input': {
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '20px',
        padding: '0 8px',
        height: '40px',
        '&:hover': {
            border: '1px solid #3b61eb',
        },
        '&:focus': {
            backgroundColor: '#ffffff',
            border: '1px solid #3b61eb',
        },
    },
    '& .WMTextField-old.WMTextField-label': {
        width: '100%',
    },
});

const StyledTabs = styled(Tabs)({
    width: '100%',
    '& .MuiTab-root': {
        width: '204px',
        paddingTop: 0,
    },
    '& .MuiTabs-root .MuiTabs-flexContainer': {
        borderBottom: 'none',
    },
});

const URLVerifierDialogTabs: React.FC<URLVerifierDialogTabsProps> = ({
    appUrl,
    setAppUrl,
    iframeUrl,
    setIframeUrl,
    currentTab,
    setCurrentTab,
}) => {
    const { t } = useTranslation('general');
    const { setIsUrlFormatValid } = useContext(URLVerifierDialogContext);
    const [appUrlFormatValidationResult, setAppUrlFormatValidationResult] = React.useState<ValidationResult>({ isValid: true });
    const [iframeUrlFormatValidationResult, setIframeUrlFormatValidationResult] = React.useState<ValidationResult>({ isValid: true });

    const handleTabChange = (tabItem: TabItem) => {
        setIframeUrl('');
        setIframeUrlFormatValidationResult({ isValid: true });
        setCurrentTab(tabItem.value as DomainType);
    };

    setIsUrlFormatValid(appUrlFormatValidationResult.isValid && iframeUrlFormatValidationResult.isValid);

    return (
        <>
            <StyledTabs
                onChange={handleTabChange}
                items={
                    [
                        {
                            value: DomainType.App,
                            label: t(`systems-tab.all-systems-page.extension-page.open-url-verifier.app-url-tab-title`),
                        },
                        {
                            value: DomainType.Iframe,
                            label: t(`systems-tab.all-systems-page.extension-page.open-url-verifier.iframe-url-tab-title`),
                        },
                    ] as TabItem[]
                }
            />
            {currentTab === DomainType.Iframe ? (
                <IframeTab
                    appUrl={appUrl}
                    setAppUrl={setAppUrl}
                    appValidationResult={appUrlFormatValidationResult}
                    setAppValidationResult={setAppUrlFormatValidationResult}
                    iframeUrl={iframeUrl}
                    setIframeUrl={setIframeUrl}
                    iframeValidationResult={iframeUrlFormatValidationResult}
                    setIframeValidationResult={setIframeUrlFormatValidationResult}
                />
            ) : (
                <AppTab
                    url={appUrl}
                    setUrl={setAppUrl}
                    validationResult={appUrlFormatValidationResult}
                    setValidationResult={setAppUrlFormatValidationResult}
                />
            )}
        </>
    );
};

const AppTab: React.FC<AppTabProps> = ({ url, setUrl, validationResult, setValidationResult }) => {
    const { t } = useTranslation('general');

    return (
        <>
            <Typography style={{ padding: '20px 0 8px 0', color: '#2F426C' }}>
                {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.app-url-tab-description`)}
                <StyledLink href='https://support.walkme.com/knowledge-base/extension-settings/#url-verifier' target='_blank'>
                    {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.learn-more`)}
                </StyledLink>
            </Typography>
            <UrlTextField
                label={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.app-url-tab-title`)}
                onChange={setUrl}
                value={url}
                tooltipText={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.app-url-tooltip-text`)}
                validationResult={validationResult}
                setValidationResult={setValidationResult}
            />
        </>
    );
};

const IframeTab: React.FC<IframeTabProps> = ({
    appUrl,
    setAppUrl,
    appValidationResult,
    setAppValidationResult,
    iframeUrl,
    setIframeUrl,
    iframeValidationResult,
    setIframeValidationResult,
}) => {
    const { t } = useTranslation('general');

    return (
        <>
            <Typography style={{ padding: '20px 0 8px 0' }}>
                {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.iframe-url-tab-description`)}
                <StyledLink href='https://support.walkme.com/knowledge-base/extension-settings/#url-verifier' target='_blank'>
                    {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.learn-more`)}
                </StyledLink>
            </Typography>
            <UrlTextField
                onChange={setAppUrl}
                value={appUrl}
                label={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.app-url-tab-title`)}
                tooltipText={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.app-url-tooltip-text`)}
                validationResult={appValidationResult}
                setValidationResult={setAppValidationResult}
            />
            <UrlTextField
                value={iframeUrl}
                onChange={setIframeUrl}
                label={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.iframe-url-tab-title`)}
                tooltipText={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.iframe-url-tooltip-text`)}
                validationResult={iframeValidationResult}
                setValidationResult={setIframeValidationResult}
            />
        </>
    );
};

const UrlTextField: React.FC<DomainTextFieldProps> = ({ label, onChange, value, tooltipText, validationResult, setValidationResult }) => {
    const { t } = useTranslation('general');

    const validateUrl = (url: string) => {
        const result = getUrlVerifierFormatValidationResult(url, t);
        setValidationResult(result);
        return result.isValid;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = validateUrl(e.target.value);
        if (isValid) {
            onChange(e.target.value);
        }
    };

    return (
        <StyledTextField
            label={
                <Box display='flex' flexDirection='row' alignItems='start' justifyContent='space-between'>
                    <Typography style={{ fontSize: '12px', color: '#2F426C' }}>{label}</Typography>
                    <Tooltip title={tooltipText}>{getTooltipIcon()}</Tooltip>
                </Box>
            }
            placeholder={t(`systems-tab.all-systems-page.extension-page.open-url-verifier.url-text-field-placeholder`)}
            value={value}
            onChange={handleChange}
            error={validationResult.errorMessage}
        />
    );
};

export { URLVerifierDialogTabs };
