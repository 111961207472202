import { Dialog, Grid } from '@material-ui/core';
import { WMButton, WMIconSettings } from '@walkme/wm-ui';
import styled from 'styled-components';

export const TitleGrid = styled(Grid)({
    marginTop: '20px',
    paddingLeft: '32px',
    paddingRight: '32px',
    marginBottom: '20px',
});

export const DialogStyled = styled(Dialog)({
    borderRadius: 15,
});

export const CircularProgressGrid = styled(Grid)({
    paddingTop: '43px',
    paddingBottom: '43px',
});

export const ItemRow = styled(Grid)({
    marginBottom: '20px',
});

export const ItemsGrid = styled(Grid)({
    paddingTop: '43px',
    paddingBottom: '43px',
});

export const DialogContent = styled(Grid)({
    paddingLeft: '32px',
    paddingRight: '32px',
});

export const DialogButtons = styled(Grid)({
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px -2px 0px #0000000A',
    paddingTop: '15px',
    paddingBottom: '22px',
    paddingRight: '32px',
});

export const DialogSeconaryButton = styled(WMButton)({
    marginRight: '22px',
});

export const DialogItemIcon = styled(WMIconSettings)({
    marginRight: '12px',
});

export const CancelButton = styled(WMButton)({
    marginRight: '12px',
});

export const DialogItemText = styled('span')({
    marginRight: '4px',
    fontFamily: 'proximaNova',
    fontSize: '14px',
    color: '#2f426c',
});

export const DialogItemValueText = styled('span')({
    fontFamily: 'proximaNova',
    fontSize: '14px',
    color: '#3B61EB',
});

export const DialogSubTitle = styled('span')({
    marginTop: '36px',
    fontFamily: 'proximaNova',
    fontSize: '14px',
    color: '#2F426C',
});

export const DialogTitle = styled('span')({
    fontFamily: 'proximaNova',
    fontSize: '18px',
    color: '#2f426c',
    lineHeight: 1.5,
    fontWeight: 600,
});
