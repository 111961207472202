import React from 'react';
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

export const IconDesktop = (props: SvgIconProps) => (
  <SvgIcon width="25px" height="24px" viewBox="0 0 25 24" {...props}>
    <g id="Assets---Desktop-Systems" transform="translate(-1053.000000, -314.000000)" fill={props.htmlColor || props.color || '#0A2333'} fillRule="nonzero">
      <g id="icon-desktop" transform="translate(1053.500000, 314.000000)">
        <path
          d="M8.45,21.4 C7.95294373,21.4 7.55,20.9970563 7.55,20.5 C7.55,20.0411788 7.89333667,19.6625482 8.33710592,19.6070123 L8.45,19.6 L11.049,19.599 L11.049,17.899 L4.7,17.9 C3.42974508,17.9 2.38997876,16.913161 2.30553687,15.6643187 L2.3,15.5 L2.3,4.5 C2.3,3.1745166 3.3745166,2.1 4.7,2.1 L4.7,2.1 L19.2,2.1 C20.5254834,2.1 21.6,3.1745166 21.6,4.5 L21.6,4.5 L21.6,15.5 C21.6,16.8254834 20.5254834,17.9 19.2,17.9 L19.2,17.9 L12.849,17.899 L12.849,19.599 L15.45,19.6 C15.9470563,19.6 16.35,20.0029437 16.35,20.5 C16.35,20.9588212 16.0066633,21.3374518 15.5628941,21.3929877 L15.45,21.4 L8.45,21.4 Z M19.2,3.9 L4.7,3.9 C4.36862915,3.9 4.1,4.16862915 4.1,4.5 L4.1,4.5 L4.1,15.5 C4.1,15.8313708 4.36862915,16.1 4.7,16.1 L4.7,16.1 L19.2,16.1 C19.5313709,16.1 19.8,15.8313708 19.8,15.5 L19.8,15.5 L19.8,4.5 C19.8,4.16862915 19.5313709,3.9 19.2,3.9 L19.2,3.9 Z"
          id="Combined-Shape" />
      </g>
    </g>
  </SvgIcon>
);
