import { SystemPurpose as SystemPurposeOptions, SystemUsage as SystemUsageOptions } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const getSystemPurposeAndUsage = () => {
    const { t } = useTranslation('general');

    const SystemPurpose = {
        title: t('systems-tab.new-system-form.purpose.title'),
        description: t('systems-tab.new-system-form.purpose.description'),
        type: 'purpose',
        options: [
            {
                value: SystemPurposeOptions.COMMERCIAL,
                title: t('systems-tab.new-system-form.purpose.commercial'),
                subTitle: t('systems-tab.new-system-form.purpose.commercial-subtitle'),
                type: 'purpose',
            },
            {
                value: SystemPurposeOptions.TRIAL,
                title: t('systems-tab.new-system-form.purpose.trial'),
                subTitle: t('systems-tab.new-system-form.purpose.trial-subtitle'),
                type: 'purpose',
            },
        ],
    };

    const SystemUsage = {
        title: t('systems-tab.new-system-form.usage.title'),
        description: t('systems-tab.new-system-form.usage.description'),
        type: 'usage',
        options: [
            {
                value: SystemUsageOptions.INTERNAL,
                title: t('systems-tab.new-system-form.usage.internal'),
                subTitle: t('systems-tab.new-system-form.usage.internal-subtitle'),
                type: 'usage',
            },
            {
                value: SystemUsageOptions.EXTERNAL,
                title: t('systems-tab.new-system-form.usage.external'),
                subTitle: t('systems-tab.new-system-form.usage.external-subtitle'),
                type: 'usage',
            },
        ],
    };

    return [SystemPurpose, SystemUsage];
};

export const SectionTypes = {
    Purpose: 'purpose',
    Usage: 'usage',
};
