import React from 'react';
import { WMButtonVariant } from '@walkme/wm-ui';
import WMDialog from '@walkme/wm-ui/components/WMDialog';
import { Typography } from '@material-ui/core';
import { TFunction } from 'i18next';

export interface NoPasswordWarningModalProps {
    onCancel?: () => void;
    onFinish: () => void;
    showDialog?: boolean;
    t: TFunction<string, string>;
}

export const NoPasswordWarningModal = ({ showDialog, onCancel, onFinish, t }: NoPasswordWarningModalProps) => {
    const buttons = [
        {
            children: t('buttons.cancel'),
            onClick: () => onCancel(),
            variant: WMButtonVariant.Text,
            style: { marginRight: '30px', color: '#637191' },
        },
        {
            children: t('buttons.proceed'),
            onClick: () => onFinish(),
            className: 'DeleteItemsButton',
        },
    ];

    return (
        <div>
            <WMDialog
                ds2
                disableHeaderDivider={true}
                open={showDialog}
                onClose={() => onCancel()}
                title={t('security-tab.idps.no-password-dialog.title')}
                buttons={buttons}
                className={'DeletionDialog'}>
                <Typography className={'DeletionDialogText'}>{t('security-tab.idps.no-password-dialog.text1')}</Typography>
                <Typography className={'DeletionDialogText'}>{t('security-tab.idps.no-password-dialog.text2')}</Typography>
            </WMDialog>
        </div>
    );
};

export default NoPasswordWarningModal;
