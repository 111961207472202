import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    WMButton,
    WMButtonVariant,
    WMIconSuccess,
    WMIconTemplate,
    WMIconFileUpload,
    WMIconErrorTriangle,
    WMDialog,
    WMIconDelete,
    WMTagVariant,
    WMTag,
    WMIconCheck,
    WMIconClose,
    WMIconBackArrow,
    WMLinearProgress,
    WMIconWarning,
    WMTooltip,
} from '@walkme/wm-ui';
import {
    importLexiconApi,
    AddManualAndAutomatonTranslationsBulkResults,
    TranslationsListObject,
} from '@walkme-admin-center/libs/import-lexicon-api';
import { useSystem } from '@walkme-admin-center/libs/state-management-systems';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import { useStyles } from './styles';
import Papa from 'papaparse';
import Hashes from 'jshashes';
import styled from 'styled-components';
import { System } from 'wm-accounts-sdk';
import { useMockProgress } from 'mock-progress-react';
import {
    summaryColumns,
    headCells,
    translationActions,
    translationStatus,
    translationIssues,
    downloadTemplateLink,
    downloadTemplateFileName,
    downloadSummaryFileName,
    timeoutErrorMessage,
    summaryIndexes,
    maxFileSize,
    structuralErrorTitle,
    maxTranslationsError,
    maxTranslations,
    invalidCharacters,
    invalidCharacterErrorMessage,
    errorsWithoutDownloadTemplate,
    heavyFileError,
} from './constants';

const StyledTableCell = styled(TableCell)`
    align: center;
    user-select: none;
    td: {
        border: none;
    }
`;

interface PagesHomeImportLexiconProps {
    systemId?: number;
}

const StickyTableCell = withStyles((theme) => ({
    head: {
        left: 0,
        position: 'sticky',
        zIndex: theme.zIndex.appBar + 2,
        background: '#fff',
    },
    body: {
        padding: '8px',
        left: 0,
        position: 'sticky',
        background: '#fff',
        zIndex: theme.zIndex.appBar + 1,
    },
}))(TableCell);

const sha1 = new Hashes.SHA1();

export const PagesHomeImportLexicon = ({ systemId }: PagesHomeImportLexiconProps) => {
    const classes = useStyles();
    const [isFileParsed, setIsFileParsed] = useState(false);
    const [isTemplateDownloaded, setIsTemplateDownloaded] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [hasUploadBeenApproved, setHasUploadBeenApproved] = useState(false);
    const [bulkId, setBulkId] = useState(null);
    const [overriddenSummaryIndexes, setOverriddenSummaryIndexes] = useState([]);
    const [overriddenTranslationIndexes, setOverriddenTranslationIndexes] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [firstSendDone, setFirstSendDone] = useState(false);
    const [parsingError, setParsingError] = useState([]);
    const [structuralErrors, setStructuralErrors] = useState([]);
    const [parsedTranslationObjects, setParsedTranslationObjects] = useState([]);
    const [downloadSummary, setDownloadSummary] = useState([]);
    const [translationSummaryAssociation, setTranslationSummaryAssociation] = useState([]);
    const [duplicationCoupling, setDuplicationCoupling] = useState([]);
    const [occupiedColumnNames, setOccupiedColumnNames] = useState([]);
    const [ambiguityTranslations, setAmbiguityTranslations] = useState([]);
    const [serverError, setServerError] = useState('');
    const { progress, finish: finishProgress, start: startProgress } = useMockProgress();
    const { currentSystemAppData } = useSystem(systemId);

    const clearFile = () => {
        setIsFileParsed(false);
        setUploadedFile(null);
        setHasUploadBeenApproved(false);
        setFirstSendDone(false);
        setOverriddenSummaryIndexes([]);
        setOverriddenTranslationIndexes([]);
        setParsingError([]);
        setStructuralErrors([]);
        setParsedTranslationObjects([]);
        setDownloadSummary([]);
        setTranslationSummaryAssociation([]);
        setDuplicationCoupling([]);
        setOccupiedColumnNames([]);
        setAmbiguityTranslations([]);
        setServerError('');
    };

    const addManualAndAutomatonTranslationsBulk = async (
        translationsList: TranslationsListObject[],
        columnNames: string[],
        overriddenIndexes?: number[][]
    ): Promise<AddManualAndAutomatonTranslationsBulkResults> => {
        const data = await importLexiconApi.addManualAndAutomatonTranslationsBulk(
            currentSystemAppData.data.id,
            bulkId,
            translationsList,
            columnNames,
            overriddenIndexes
        );
        return data;
    };

    const onDownloadTemplate = () => {
        downloadFile(downloadTemplateLink, downloadTemplateFileName);
    };

    const onDownloadSummary = () => {
        if (downloadSummary.length && uploadedFile) {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += objectToCSVRow(Object.keys(downloadSummary[0]));
            downloadSummary.forEach(function (item) {
                csvContent += objectToCSVRow(item);
            });
            downloadFile(encodeURI(csvContent), `${uploadedFile.name?.split('.csv')[0]} - ${downloadSummaryFileName}`);
        }
    };

    const objectToCSVRow = (dataObject) => {
        const dataArray = [];
        for (const o in dataObject) {
            const innerValue = !dataObject[o] ? '' : dataObject[o].toString();
            let result = innerValue.replace(/"/g, '""');
            result = '"' + result + '"';
            dataArray.push(result);
        }
        return dataArray.join(',') + '\r\n';
    };

    const downloadFile = (content, fileName) => {
        const anchor = document.createElement('a');
        anchor.href = content;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        setIsTemplateDownloaded(true);
    };

    const onDropFile = (acceptedFiles) => {
        clearFile();
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setUploadedFile(file);
        if (file.size <= maxFileSize) {
            handleCsvParse(file);
        } else {
            setParsingError([heavyFileError]);
            setIsFileParsed(true);
        }
    };

    const Dropzone = ({ onDrop, accept, open }) => {
        const { getRootProps, getInputProps } = useDropzone({
            accept,
            onDrop,
            multiple: false,
        });

        return (
            <div className={classes.uploadBox} {...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                <WMIconFileUpload size={24} color='#405278' />
                <span className={classes.sortText}>{!uploadedFile ? 'Drag & drop your .CSV file here' : uploadedFile.name}</span>
                {uploadedFile ? (
                    <WMButton
                        variant={WMButtonVariant.Text}
                        onClick={(e) => {
                            e.stopPropagation();
                            clearFile();
                        }}>
                        <WMIconDelete color={'#385feb'} size={24}></WMIconDelete>
                    </WMButton>
                ) : (
                    <WMButton variant={WMButtonVariant.Text}>Upload File</WMButton>
                )}
            </div>
        );
    };

    const coupleDuplications = (indexesLists, duplicationMap) => {
        const indexListName = summaryIndexes;

        for (const [key, value] of Object.entries(duplicationMap)) {
            // @ts-ignore
            if (key === indexListName && value.length > 1) {
                indexesLists.push(value);
            } else if (value.constructor?.name === 'Object') {
                coupleDuplications(indexesLists, value);
            }
        }
    };

    const isCorruption = (phrase: string): boolean => {
        const trimmedPhrase = phrase.trim();
        const phrasesArray = trimmedPhrase.split(/\s/);

        for (let i = 0; i < phrasesArray.length; i++) {
            const word = phrasesArray[i];
            const wordlength = word.length;
            for (let j = 0; j < wordlength; j++) {
                const char = word[j];
                if (char === '?' && j !== wordlength - 1) {
                    return true;
                }
            }
        }
        return false;
    };

    const checkCorruptionsAndAddToTranslations = (translationPhraseObject, originalPhraseObject, translationsObjectsList) => {
        const currTranslationIndex = translationsObjectsList.length - 1;

        if (isCorruption(translationPhraseObject.phrase) || isCorruption(originalPhraseObject.phrase)) {
            translationsObjectsList[currTranslationIndex].corruption = true;
        }
    };

    const checkDuplicatesAndAddToTranslations = (
        currSummaryIndex,
        originalPhraseObject,
        translationPhraseObject,
        translationsObjectsList,
        translationSummaryAssociation,
        duplicationMap,
        deployableId,
        downloadSummary
    ) => {
        translationsObjectsList.push({
            originalPhraseObject,
            translationPhraseObject,
            deployableId,
        });
        const { phrase: translationPhrase, language } = translationPhraseObject;
        const { phrase: originalPhrase } = originalPhraseObject;
        const currTranslationIndex = translationsObjectsList.length - 1;
        const newObjectToAdd = {
            summaryIndexes: [currSummaryIndex],
            firstTranslationIndex: currTranslationIndex,
            translationPhrases: new Set([translationPhrase]),
        };
        const noDeployable = 'noDeployable';
        const phraseMapping = duplicationMap[originalPhrase];
        const languageMapping = phraseMapping && phraseMapping[language];
        const deployableMapping = languageMapping && languageMapping[deployableId || noDeployable];

        const addToDuplicationMapOrPop = () => {
            if (!deployableMapping.translationPhrases.has(translationPhrase)) {
                translationsObjectsList[currTranslationIndex].duplication = true;
                translationsObjectsList[deployableMapping.firstTranslationIndex].duplication = true;
                deployableMapping.translationPhrases.add(translationPhrase);
                deployableMapping.summaryIndexes.push(currSummaryIndex);
            } else {
                translationsObjectsList.pop();
                downloadSummary.pop();
                translationSummaryAssociation.pop();
            }
        };

        const createNewInMap = () => {
            if (phraseMapping) {
                phraseMapping[language] = {
                    ...(languageMapping || {}),
                    ...{ [deployableId || noDeployable]: newObjectToAdd },
                };
            } else {
                duplicationMap[originalPhrase] = { [language]: { [deployableId || noDeployable]: newObjectToAdd } };
            }
        };

        if (deployableMapping) {
            addToDuplicationMapOrPop();
        } else {
            createNewInMap();
        }
    };

    const handleCsvTransmission = async () => {
        setIsLoading(true);
        startProgress();
        try {
            const startTime = performance.now();
            const data = await addManualAndAutomatonTranslationsBulk(parsedTranslationObjects, occupiedColumnNames);
            console.log(`Translations: ${parsedTranslationObjects.length}, Time: ${performance.now() - startTime}`);
            const lexiconResults = data.lexiconResults;
            const lexiconStructuralErrors = data.lexiconStructuralErrors;
            if (structuralErrors.length || lexiconStructuralErrors?.length) {
                const newErrors = [...structuralErrors, ...(lexiconStructuralErrors || [])];
                setStructuralErrors(newErrors);
                setFirstSendDone(true);
                setIsLoading(false);
                return;
            }
            await processCsvResults(lexiconResults, downloadSummary);
            setIsLoading(false);
            finishProgress();
            setFirstSendDone(true);
        } catch (error) {
            if (error.response) {
                setServerError(error.response.data.message || error.response.data);
            } else if (error.message.indexOf('timeout') !== -1) {
                setServerError(timeoutErrorMessage);
            } else {
                setServerError(error.message);
            }
            setFirstSendDone(true);
            finishProgress();
            setIsLoading(false);
        }
    };

    const processCsvResults = async (lexiconResults, downloadSummary) => {
        const ambiguityTranslations = [];

        if (!lexiconResults?.length) {
            setServerError('Empty CSV bulk translations addition results from lexicon - unknown error');
            return;
        }

        for (let i = 0; i < lexiconResults.length; i++) {
            const {
                Status,
                Issue,
                verifiedAmbiguity,
                duplicationAmbiguity,
                corruptionAmbiguity,
                shouldNotUpdateSummary,
                modifiedLanguageCode,
                verifiedTranslation,
            } = lexiconResults[i];

            const summaryIndex = translationSummaryAssociation[i];
            const currSummary = downloadSummary[summaryIndex];

            if (modifiedLanguageCode && currSummary[summaryColumns.Language] !== modifiedLanguageCode) {
                currSummary[summaryColumns.Language] = `${currSummary[summaryColumns.Language]} -> ${modifiedLanguageCode}`;
            }

            if ((duplicationAmbiguity || verifiedAmbiguity || corruptionAmbiguity) && !Issue) {
                if (duplicationAmbiguity) {
                    currSummary[summaryColumns.Issue] = currSummary[summaryColumns.Issue]
                        ? `${currSummary[summaryColumns.Issue]}, ${translationIssues.duplication}`
                        : translationIssues.duplication;
                }
                if (verifiedAmbiguity) {
                    currSummary[summaryColumns.Issue] = currSummary[summaryColumns.Issue]
                        ? `${currSummary[summaryColumns.Issue]}, ${translationIssues.verified}`
                        : translationIssues.verified;
                    currSummary[summaryColumns.VerifiedTranslation] = verifiedTranslation;
                }
                if (corruptionAmbiguity) {
                    currSummary[summaryColumns.Issue] = currSummary[summaryColumns.Issue]
                        ? `${currSummary[summaryColumns.Issue]}, ${translationIssues.corruption}`
                        : translationIssues.corruption;
                }

                currSummary[summaryColumns.Status] = currSummary[summaryColumns.Status]
                    ? `${currSummary[summaryColumns.Status]}, ${translationStatus.ambiguity}`
                    : translationStatus.ambiguity;
                ambiguityTranslations.push({ ...currSummary, translationIndex: i, summaryIndex });
            } else if (!shouldNotUpdateSummary) {
                // this will store the action that happened in practice, and not necessarily the chosen action
                currSummary[summaryColumns.Action] =
                    Status === translationStatus.success ? translationActions.upload : translationActions.skip;
                currSummary[summaryColumns.Status] =
                    currSummary[summaryColumns.Status] && Status
                        ? `${currSummary[summaryColumns.Status]}, ${Status}`
                        : currSummary[summaryColumns.Status] || Status;
                currSummary[summaryColumns.Issue] =
                    currSummary[summaryColumns.Issue] && Issue
                        ? `${currSummary[summaryColumns.Issue]}, ${Issue}`
                        : currSummary[summaryColumns.Issue] || Issue;
            }
        }

        if (ambiguityTranslations.length) {
            const overriddenTranslationIndexes = [];
            const overriddenSummaryIndexes = [];
            // Create new list of all the duplication couples withe the issue 'duplication'
            const newDuplicationCoupling = duplicationCoupling
                .map((duplicationCouples) => {
                    return duplicationCouples.filter((duplication) => {
                        const duplicationIssue = downloadSummary[duplication][summaryColumns.Issue];
                        if (typeof duplicationIssue === 'string') {
                            return downloadSummary[duplication][summaryColumns.Issue].includes(translationIssues.duplication);
                        } else {
                            return false;
                        }
                    });
                })
                .filter((duplicationCouples) => {
                    const isMoreThanOneDuplicte = duplicationCouples.length > 1;
                    if (!isMoreThanOneDuplicte && ambiguityTranslations.some((at) => at.summaryIndex === duplicationCouples[0])) {
                        downloadSummary[duplicationCouples[0]].Issue = translationIssues.verified;
                        ambiguityTranslations.find((a) => a.summaryIndex === duplicationCouples[0]).Issue = translationIssues.verified;
                    }
                    return isMoreThanOneDuplicte;
                });

            newDuplicationCoupling.forEach((duplicationCouples) => {
                if (!downloadSummary[duplicationCouples[0]][summaryColumns.Issue].includes(translationIssues.verified)) {
                    const summaryIndex = duplicationCouples[0];
                    const translationIndexes = ambiguityTranslations.find((at) => at.summaryIndex === summaryIndex)?.translationIndex;
                    overriddenSummaryIndexes.push(summaryIndex);
                    overriddenTranslationIndexes.push(translationIndexes);
                }
            });
            setOverriddenSummaryIndexes(overriddenSummaryIndexes);
            setOverriddenTranslationIndexes(overriddenTranslationIndexes);
            setDuplicationCoupling(newDuplicationCoupling);
            setAmbiguityTranslations(ambiguityTranslations);
        } else {
            setAmbiguityTranslations([]);
        }
    };

    const handleCsvParse = (file) => {
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const structuralErrors = [];

                // Check for syntax errors in the file
                if (results.errors.length && results.meta.aborted) {
                    setParsingError(['Failed parsing CSV file']);
                    setIsFileParsed(true);
                    return;
                }

                if (!results.data.length) {
                    setParsingError(['The file is empty.']);
                    setIsFileParsed(true);
                    return;
                }

                const columnNames = results.meta.fields.map((lang) => lang.trim().toLowerCase());

                if (columnNames.length < 3) {
                    setParsingError(['Please ensure that the file has a minimum of 3 columns.']);
                    setIsFileParsed(true);
                    return;
                }

                const duplicateColumnNames = new Set(
                    columnNames.filter(
                        (columnName, columnIndex, columnArray) => columnName && columnArray.indexOf(columnName) !== columnIndex
                    )
                );

                for (let i = 0; i < results.data.length; i++) {
                    const row = Object.values(results.data[i]);
                    for (let j = 0; j < row.length; j++) {
                        // @ts-ignore
                        const cell: string = row[j];

                        if (invalidCharacters.some((char) => cell.includes(char))) {
                            setParsingError([invalidCharacterErrorMessage]);
                            setIsFileParsed(true);
                            return;
                        }
                    }
                }

                const csvColumnNameMapper = (colIndex) => {
                    let csvColName = '';
                    let colIndexModified = colIndex;
                    while (colIndexModified > 0) {
                        const modulo = (colIndexModified - 1) % 26;
                        csvColName = `${String.fromCharCode(65 + modulo)}${csvColName}`;
                        colIndexModified = ~~((colIndexModified - modulo) / 26);
                    }
                    return csvColName;
                };

                const emptyColumnsIndexes = columnNames.reduce((acc, columnName, columnIndex) => {
                    if (!columnName) {
                        acc.push(csvColumnNameMapper(columnIndex + 1));
                    }
                    return acc;
                }, []);

                if (duplicateColumnNames.size) {
                    setParsingError([`Duplicate column names: ${[...duplicateColumnNames].join(', ')}`]);
                    setIsFileParsed(true);
                    return;
                }

                if (emptyColumnsIndexes.length) {
                    setParsingError([`Missing column names in column letters: ${emptyColumnsIndexes.join(', ')}`]);
                    setIsFileParsed(true);
                    return;
                }

                let occupiedColumnNames: any = new Set();
                const duplicationMap = {};
                const translationsObjectsList = [];
                const downloadSummary = [];
                const translationSummaryAssociation = [];

                for (let i = 0; i < results.data.length; i++) {
                    let originalPhraseObject = {
                        phrase: null,
                        language: null,
                        attributeId: null,
                    };
                    let deployableId;
                    const rowEntries = Object.entries(results.data[i]);
                    let rowTranslationExists = false;

                    for (let j = 0; j < rowEntries.length; j++) {
                        const [untrimmedColumnName, columnValue] = rowEntries[j];
                        const columnName = untrimmedColumnName.trim().toLowerCase();

                        if (!columnName || columnName === '__parsed_extra') {
                            continue;
                        } else if (j === 0 && columnName.toLowerCase().replace(/\s+/g, '') !== 'deployableid') {
                            setParsingError(['First column should be DeployableID']);
                            setIsFileParsed(true);
                            return;
                        } else if (j === 0) {
                            deployableId = columnValue;
                        } else if (columnValue) {
                            if (j === 1) {
                                occupiedColumnNames.add(columnName);
                                originalPhraseObject = {
                                    phrase: columnValue,
                                    language: columnName,
                                    attributeId: { manualTranslation: columnValue },
                                };
                            } else {
                                occupiedColumnNames.add(columnName);
                                rowTranslationExists = true;

                                const translationPhraseObject = {
                                    phrase: columnValue,
                                    language: columnName,
                                    attributeId: { manualTranslation: originalPhraseObject.phrase },
                                };

                                const doesOriginalPhraseExist = !!originalPhraseObject.phrase;

                                const translationSummary = {};
                                translationSummary[summaryColumns.Line] = i + 2;
                                translationSummary[summaryColumns.DeployableID] = deployableId;
                                translationSummary[summaryColumns.Original] = originalPhraseObject.phrase;
                                translationSummary[summaryColumns.Language] = columnName;
                                translationSummary[summaryColumns.Translation] = columnValue;
                                translationSummary[summaryColumns.Status] = !doesOriginalPhraseExist && translationStatus.error;
                                translationSummary[summaryColumns.Issue] = !doesOriginalPhraseExist && translationIssues.missingOrig;
                                translationSummary[summaryColumns.Action] = !doesOriginalPhraseExist && translationActions.skip;

                                downloadSummary.push(translationSummary);

                                if (doesOriginalPhraseExist) {
                                    const currSummaryIndex = downloadSummary.length - 1;
                                    translationSummaryAssociation.push(currSummaryIndex);

                                    checkDuplicatesAndAddToTranslations(
                                        currSummaryIndex,
                                        originalPhraseObject,
                                        translationPhraseObject,
                                        translationsObjectsList,
                                        translationSummaryAssociation,
                                        duplicationMap,
                                        deployableId,
                                        downloadSummary
                                    );

                                    checkCorruptionsAndAddToTranslations(
                                        translationPhraseObject,
                                        originalPhraseObject,
                                        translationsObjectsList
                                    );
                                }
                            }
                        }
                    }

                    if (!rowTranslationExists && originalPhraseObject.phrase) {
                        const translationSummary = {};
                        translationSummary[summaryColumns.Line] = i + 2;
                        translationSummary[summaryColumns.DeployableID] = deployableId;
                        translationSummary[summaryColumns.Original] = originalPhraseObject.phrase;
                        translationSummary[summaryColumns.Language] = undefined;
                        translationSummary[summaryColumns.Translation] = undefined;
                        translationSummary[summaryColumns.Status] = translationStatus.error;
                        translationSummary[summaryColumns.Issue] = translationIssues.missingTranslations;
                        translationSummary[summaryColumns.Action] = translationActions.skip;
                        downloadSummary.push(translationSummary);
                    }
                }

                const duplicationCoupling = [];
                coupleDuplications(duplicationCoupling, duplicationMap);

                if (!translationsObjectsList.length) {
                    setParsingError([`CSV does not contain any valid translation`]);
                    setIsFileParsed(true);
                    return;
                }

                if (translationsObjectsList.length > maxTranslations) {
                    setParsingError([maxTranslationsError]);
                    setIsFileParsed(true);
                    return;
                }

                occupiedColumnNames = [...occupiedColumnNames];

                setIsFileParsed(true);
                setBulkId(sha1.hex(currentSystemAppData.data.guid + new Date().getTime()));
                setStructuralErrors(structuralErrors);
                setParsedTranslationObjects(translationsObjectsList);
                setDownloadSummary(downloadSummary);
                setTranslationSummaryAssociation(translationSummaryAssociation);
                setDuplicationCoupling(duplicationCoupling);
                setOccupiedColumnNames(occupiedColumnNames);
            },
            error: (error) => {
                if (error.response) {
                    setServerError(error.response.data.message || error.response.data);
                } else if (error.message.indexOf('timeout') !== -1) {
                    setServerError(timeoutErrorMessage);
                } else {
                    setServerError(error.message);
                }
            },
        });
    };

    const renderTableRow = (row) => {
        const isApprove = overriddenSummaryIndexes.includes(row.summaryIndex);

        return (
            <TableRow key={`issue-${row[summaryColumns.Line]}-${row.summaryIndex}`}>
                <StickyTableCell component='th' scope='row'>
                    {' '}
                    {row[summaryColumns.Line]}{' '}
                </StickyTableCell>
                <StyledTableCell>{row[summaryColumns.DeployableID]}</StyledTableCell>
                <StyledTableCell>
                    <WMTooltip title={row[summaryColumns.Original]} variant='light'>
                        <div className={classes.phrase}>{row[summaryColumns.Original]}</div>
                    </WMTooltip>
                </StyledTableCell>
                <StyledTableCell>
                    <WMIconBackArrow className={classes.translationArrow} />
                    <WMTag
                        key={WMTagVariant.RoyalBlue}
                        className={classes.tag}
                        variant={WMTagVariant.RoyalBlue}
                        labelText={row[summaryColumns.Language]}
                        fitToText={true}
                        fullLabel={true}
                    />
                    <WMTooltip title={row[summaryColumns.Translation]} variant='light'>
                        <div className={classes.translation}>{row[summaryColumns.Translation]}</div>
                    </WMTooltip>
                </StyledTableCell>
                <StyledTableCell>
                    <div className={classes.issue}>{row[summaryColumns.Issue]}</div>
                    {row[summaryColumns.VerifiedTranslation] && (
                        <WMTooltip title={row[summaryColumns.VerifiedTranslation]} variant='light'>
                            <div className={classes.verifiedTranslation}>{row[summaryColumns.VerifiedTranslation]}</div>
                        </WMTooltip>
                    )}
                </StyledTableCell>
                <StyledTableCell>
                    <div className={classes.actionsWarpper}>
                        <button
                            className={isApprove ? classes.selected : classes.unselected}
                            disabled={isApprove}
                            onClick={() => {
                                onApproveAmbiguity(row.summaryIndex);
                            }}>
                            <WMIconCheck size={12} className={classes.approveIcon} />
                            Approve
                        </button>
                        <button
                            className={!isApprove ? classes.selected : classes.unselected}
                            disabled={!isApprove}
                            onClick={() => {
                                onSkipAmbiguity(row.summaryIndex);
                            }}>
                            <WMIconClose size={7} className={classes.skipIcon} />
                            Skip
                        </button>
                    </div>
                </StyledTableCell>
            </TableRow>
        );
    };

    const onApproveAmbiguity = (summaryIndex) => {
        const isDuplication = ambiguityTranslations
            .find((at) => at.summaryIndex === summaryIndex)
            [summaryColumns.Issue].includes(translationIssues.duplication);
        const translationIndex = ambiguityTranslations.find((at) => at.summaryIndex === summaryIndex).translationIndex;
        const newOverriddenSummaryIndexes = [...overriddenSummaryIndexes];
        const newOverriddenTranslationIndexes = [...overriddenTranslationIndexes];
        if (isDuplication) {
            const couples = duplicationCoupling.find((dc) => dc.includes(summaryIndex));
            let coupleIndex = -1;
            couples.forEach((couple) => {
                const temp = newOverriddenSummaryIndexes.indexOf(couple);
                if (temp !== -1) coupleIndex = temp;
            });
            if (coupleIndex !== -1) {
                newOverriddenSummaryIndexes.splice(coupleIndex, 1);
                newOverriddenTranslationIndexes.splice(coupleIndex, 1);
            }
        }
        newOverriddenSummaryIndexes.push(summaryIndex);
        newOverriddenTranslationIndexes.push(translationIndex);
        setOverriddenSummaryIndexes(newOverriddenSummaryIndexes);
        setOverriddenTranslationIndexes(newOverriddenTranslationIndexes);
    };

    const onSkipAmbiguity = (summaryIndex) => {
        const translationIndex = ambiguityTranslations.find((at) => at.summaryIndex === summaryIndex).translationIndex;
        let newOverriddenSummaryIndexes = [...overriddenSummaryIndexes];
        let newOverriddenTranslationIndexes = [...overriddenTranslationIndexes];
        newOverriddenSummaryIndexes = newOverriddenSummaryIndexes.filter((item) => item !== summaryIndex);
        newOverriddenTranslationIndexes = newOverriddenTranslationIndexes.filter((item) => item !== translationIndex);
        setOverriddenSummaryIndexes(newOverriddenSummaryIndexes);
        setOverriddenTranslationIndexes(newOverriddenTranslationIndexes);
    };

    const onOverrideDuplicate = (coupling, index) => {
        const newOverriddenSummaryIndexes = [...overriddenSummaryIndexes];
        const newOverriddenTranslationIndexes = [...overriddenTranslationIndexes];
        let couplingIndex = -1;
        newOverriddenSummaryIndexes.forEach((osi, index) => {
            if (coupling.includes(osi)) couplingIndex = index;
        });
        if (couplingIndex !== -1) {
            const translationIndex = ambiguityTranslations.find((at) => at.summaryIndex === index).translationIndex;
            newOverriddenSummaryIndexes[couplingIndex] = index;
            newOverriddenTranslationIndexes[couplingIndex] = translationIndex;
            setOverriddenSummaryIndexes(newOverriddenSummaryIndexes);
            setOverriddenTranslationIndexes(newOverriddenTranslationIndexes);
        }
    };

    const renderDuplicateRow = (row) => {
        let coupling = duplicationCoupling.filter((a) => a.includes(row.summaryIndex));
        coupling = coupling.length === 1 ? coupling[0] : null;

        return (
            coupling && (
                <>
                    <tr className={classes.duplicateRow}>
                        <td className={classes.duplicationTd} colSpan={6}>
                            {' '}
                            Different traslation for this phase was found, select only one translation
                        </td>
                    </tr>
                    {coupling.map((item, index) => {
                        const row = ambiguityTranslations.find((at) => at.summaryIndex === item);
                        const isSelected = overriddenSummaryIndexes.includes(item);
                        const isLast = coupling.length === index + 1;
                        const isVerified = row[summaryColumns.Issue].includes(translationIssues.verified);
                        const isNotOnlyDuplication = row[summaryColumns.Issue] !== translationIssues.duplication;

                        return (
                            <tr className={classes.duplicateRow}>
                                <td className={isLast ? classes.lastTd : classes.duplicationTd}>{row[summaryColumns.Line]}</td>
                                <td className={isLast ? classes.lastTd : classes.duplicationTd}>{row[summaryColumns.DeployableID]}</td>
                                <td className={isLast ? classes.lastTd : classes.duplicationTd}>
                                    <WMTooltip title={row[summaryColumns.Original]} variant='light'>
                                        <div className={classes.phrase}>{row[summaryColumns.Original]}</div>
                                    </WMTooltip>
                                </td>
                                <td className={isLast ? classes.lastTd : classes.duplicationTd}>
                                    <WMIconBackArrow className={classes.translationArrow} />
                                    <WMTag
                                        key={WMTagVariant.RoyalBlue}
                                        className={classes.tag}
                                        variant={WMTagVariant.RoyalBlue}
                                        labelText={row[summaryColumns.Language]}
                                        fitToText={true}
                                        fullLabel={true}
                                    />
                                    <WMTooltip title={row[summaryColumns.Translation]} variant='light'>
                                        <div className={classes.translation}>{row[summaryColumns.Translation]}</div>
                                    </WMTooltip>
                                </td>
                                <td className={isLast ? classes.lastTd : classes.duplicationTd}>
                                    <div className={classes.issue}>
                                        {row[summaryColumns.Issue]}
                                        {row[summaryColumns.VerifiedTranslation] && (
                                            <WMTooltip title={row[summaryColumns.VerifiedTranslation]} variant='light'>
                                                <div className={classes.verifiedTranslation}>{row[summaryColumns.VerifiedTranslation]}</div>
                                            </WMTooltip>
                                        )}
                                    </div>
                                </td>
                                <td className={isLast ? classes.lastTd : classes.duplicationTd}>
                                    <div className={classes.actionsWarpper}>
                                        {isNotOnlyDuplication ? (
                                            <>
                                                <button
                                                    className={isSelected ? classes.selected : classes.unselected}
                                                    disabled={isSelected}
                                                    onClick={() => {
                                                        onApproveAmbiguity(row.summaryIndex);
                                                    }}>
                                                    <WMIconCheck size={12} className={classes.approveIcon} />
                                                    {isVerified ? 'Approve' : 'Select'}
                                                </button>
                                                <button
                                                    className={!isSelected ? classes.selected : classes.unselected}
                                                    disabled={!isSelected}
                                                    onClick={() => {
                                                        onSkipAmbiguity(row.summaryIndex);
                                                    }}>
                                                    <WMIconClose size={7} className={classes.skipIcon} />
                                                    Skip
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                className={isSelected ? classes.selected : classes.unselected}
                                                onClick={() => onOverrideDuplicate(coupling, item)}
                                                disabled={isSelected}>
                                                {isSelected ? 'Selected' : 'Select'}
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </>
            )
        );
    };

    const getOnlyOneDuplication = () => {
        return [...ambiguityTranslations].filter((item) => {
            if (!item[summaryColumns.Issue].includes(translationIssues.duplication)) {
                return true;
            } else {
                return duplicationCoupling.some((dc) => dc[0] === item.summaryIndex);
            }
        });
    };

    const renderDuplicatesTable = () => {
        const indexedSummary = getOnlyOneDuplication();

        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headCells.map((header, index) => (
                                <StickyTableCell key={`${header.id}-${index}`}>
                                    <span>{header.label}</span>
                                </StickyTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {indexedSummary.map((row) => {
                            return row.Issue.includes(translationIssues.duplication) ? renderDuplicateRow(row) : renderTableRow(row);
                        })}
                    </TableBody>
                </Table>
            </div>
        );
    };

    const handleOverrideAmbiguities = async () => {
        setIsLoading(true);
        startProgress();
        for (let i = 0; i < downloadSummary.length; i++) {
            if (!overriddenSummaryIndexes.includes(i) && !downloadSummary[i][summaryColumns.Action]) {
                downloadSummary[i][summaryColumns.Action] = translationActions.skip;
            }
        }
        try {
            if (overriddenTranslationIndexes.length) {
                const startTime = performance.now();
                const data = await addManualAndAutomatonTranslationsBulk(
                    parsedTranslationObjects,
                    occupiedColumnNames,
                    overriddenTranslationIndexes
                );
                console.log(`Translations: ${parsedTranslationObjects.length}, Time: ${performance.now() - startTime}`);
                await processCsvResults(data?.lexiconResults, downloadSummary);
            } else {
                setAmbiguityTranslations([]);
            }
            setIsLoading(false);
            finishProgress();
        } catch (error) {
            finishProgress();
            if (error.response) {
                setServerError(error.response.data.message || error.response.data);
            } else if (error.message.indexOf('timeout') !== -1) {
                setServerError(timeoutErrorMessage);
            } else {
                setServerError(error.message);
            }
            setIsLoading(false);
        }
    };

    const renderSummaryContent = () => {
        return (
            <div className={classes.uploadModalContent}>
                <div className={classes.summaryContatier}>
                    <div className={classes.summaryItem}>
                        <div className={classes.summaryTitle}>
                            {' '}
                            {downloadSummary.filter((ds) => ds.Action === translationActions.skip).length}{' '}
                        </div>
                        Skipped
                    </div>
                    <div className={classes.summaryItem}>
                        <div className={classes.summaryTitle}>
                            {' '}
                            {downloadSummary.filter((ds) => ds.Action === translationActions.upload).length}{' '}
                        </div>
                        Uploaded
                    </div>
                    <div className={classes.summaryItem}>
                        <div className={classes.summaryTitle}> {occupiedColumnNames.length} </div>
                        Languages
                    </div>
                </div>
                Thank you for uploading your application translations. Please be advised that it may take up to 15 minutes for the updates
                to take effect.
            </div>
        );
    };

    const renderDownloadTeplateButton = (hideDownloadButton) => {
        if (!hideDownloadButton) {
            return (
                <WMButton
                    variant={WMButtonVariant.Primary}
                    onClick={() => {
                        onDownloadTemplate();
                        setOpenModal(false);
                        clearFile();
                    }}>
                    Download Template
                </WMButton>
            );
        }
    };

    const getUploadModalTitle = () => {
        if (parsingError.length) return structuralErrorTitle;
        if (!hasUploadBeenApproved) return 'Upload translations file';
        if (hasUploadBeenApproved && (!firstSendDone || isLoading)) return 'Loading data';
        if (hasUploadBeenApproved && firstSendDone) {
            if (structuralErrors.length) return structuralErrorTitle;
            if (serverError) return 'Server error';
            if (ambiguityTranslations.length) return 'The following translation ambiguities were found in the file';
            if (!structuralErrors.length && !ambiguityTranslations.length) return 'Upload complete';
        }
    };

    const getUploadModalTitleIcon = () => {
        if (parsingError.length) return <WMIconErrorTriangle color={'#eb3232'} size={24} />;
        if (!hasUploadBeenApproved) return <WMIconErrorTriangle color={'#6D81A6'} size={24} />;
        if (hasUploadBeenApproved && (!firstSendDone || isLoading)) return '';
        if (hasUploadBeenApproved && firstSendDone) {
            if (structuralErrors.length) return <WMIconErrorTriangle color={'#eb3232'} size={24} />;
            if (serverError) return <WMIconErrorTriangle color={'#eb3232'} size={24} />;
            if (ambiguityTranslations.length) return <WMIconWarning color={'#eb3232'} size={24} />;
            if (!structuralErrors.length && !ambiguityTranslations.length) return <WMIconSuccess color={'#41B7A6'} size={24} />;
        }
    };

    const renderModalContent = (hideDownloadButton) => {
        if (parsingError.length)
            return (
                <div className={classes.uploadModalContent}>
                    The translations cannot be uploaded because:
                    {parsingError.length === 1 ? (
                        <div>{parsingError[0]}</div>
                    ) : (
                        <ul>
                            {parsingError.map((error) => {
                                return <li>{error}</li>;
                            })}
                        </ul>
                    )}
                    <br />
                    {!hideDownloadButton && 'Feel free to download the template file for your reference.'}
                </div>
            );

        if (!hasUploadBeenApproved)
            return (
                <div className={classes.uploadModalContent}>
                    Are you sure you want to upload this translations file? <br />
                    The Phrases in the file will populate the platform translations dictionary. <br />
                </div>
            );

        if (hasUploadBeenApproved && (!firstSendDone || isLoading))
            return (
                <div className={classes.uploadModalContent}>
                    <WMLinearProgress />
                </div>
            );

        if (hasUploadBeenApproved && firstSendDone) {
            if (structuralErrors.length)
                return (
                    <div className={classes.uploadModalContent}>
                        The translations cannot be uploaded because:
                        {structuralErrors.length === 1 ? (
                            <div>{structuralErrors[0]}</div>
                        ) : (
                            <ul>
                                {structuralErrors.map((error) => {
                                    return <li>{error}</li>;
                                })}
                            </ul>
                        )}
                        <br />
                        Feel free to download the template file for your reference.
                    </div>
                );

            if (serverError) return <div className={classes.uploadModalContent}>{serverError}</div>;

            if (ambiguityTranslations.length) return renderDuplicatesTable();

            if (!structuralErrors.length && !ambiguityTranslations.length) return renderSummaryContent();
        }
    };

    const renderModalFooter = (hideDownloadButton) => {
        if (parsingError.length) return renderDownloadTeplateButton(hideDownloadButton);
        if (!hasUploadBeenApproved)
            return (
                <WMButton
                    variant={WMButtonVariant.Primary}
                    loading={isLoading}
                    onClick={() => {
                        setHasUploadBeenApproved(true);
                        if (!parsingError.length) {
                            handleCsvTransmission();
                        }
                    }}>
                    Confirm
                </WMButton>
            );

        if (hasUploadBeenApproved && (!firstSendDone || isLoading)) return;

        if (hasUploadBeenApproved && firstSendDone) {
            if (structuralErrors.length) return renderDownloadTeplateButton(hideDownloadButton);

            if (serverError) return;

            if (ambiguityTranslations.length)
                return (
                    <WMButton variant={WMButtonVariant.Primary} onClick={handleOverrideAmbiguities}>
                        Continue
                    </WMButton>
                );

            if (!structuralErrors.length && !ambiguityTranslations.length)
                return (
                    <WMButton variant={WMButtonVariant.Primary} onClick={onDownloadSummary}>
                        Download summary
                    </WMButton>
                );
        }
    };

    return (
        <div>
            <div className={classes.introduction}>
                <div className={classes.mainHeader}> Lexicon </div>
                <div className={classes.subTitle}>
                    With WalkMe Lexicon active, a builder can create WalkMe content on a site that displays their native language and
                    end-users will see the content if they browse the site in a different language.
                </div>
            </div>

            <div className={classes.downloadInfo}>
                <div className={classes.downloadHeader}>Upload a CSV to populate and update the platform translations.</div>
                <ul>
                    <li> Please start by downloading the CSV template below. </li>
                    <li> Replace the language codes in the top row with your languages (lower case). </li>
                    <li> The original content language should be first (Column B). </li>
                    <li> You may leave the first column (DeployableID) unpopulated. </li>
                </ul>
            </div>

            <Box className={classes.buttonWrapper}>
                <WMButton
                    className={classes.fileButton}
                    iconComponent={<WMIconTemplate size={16} />}
                    variant={WMButtonVariant.Secondary}
                    onClick={onDownloadTemplate}>
                    Download CSV Template
                </WMButton>
                {isTemplateDownloaded && <WMIconSuccess className={classes.isDownloadedButton} color={'#385FEB'} size={16}></WMIconSuccess>}
            </Box>

            <Box>
                <Dropzone onDrop={onDropFile} accept={'.csv'} open={true} />
            </Box>

            <WMButton
                className={classes.newFooter}
                variant={WMButtonVariant.Primary}
                disabled={!isFileParsed}
                onClick={() => setOpenModal(true)}>
                Upload
            </WMButton>

            <WMDialog
                ds2
                open={openModal}
                title={getUploadModalTitle()}
                hideCloseButton
                titleIcon={getUploadModalTitleIcon()}
                subtitle={
                    ambiguityTranslations.length && !isLoading && !serverError
                        ? `Please review the list below and select "Approve" or "Skip" for each.`
                        : ''
                }
                className={ambiguityTranslations.length && !isLoading && !serverError && classes.duplicatesModal}>
                {renderModalContent(errorsWithoutDownloadTemplate.some((error) => parsingError.includes(error)))}
                <div className={classes.footerModal}>
                    <WMButton className={classes.uploadModalCloseBtn} variant={WMButtonVariant.Text} onClick={() => setOpenModal(false)}>
                        Close
                    </WMButton>
                    {renderModalFooter(errorsWithoutDownloadTemplate.some((error) => parsingError.includes(error)))}
                </div>
            </WMDialog>
        </div>
    );
};

export default PagesHomeImportLexicon;
