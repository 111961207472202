import { AppData } from '@walkme-admin-center/libs/types';
import { RestoredSystemEnvironment } from '@walkme-admin-center/libs/analytics-api';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useRestoredEnvironment = () => {
    const restoredEnvironmentAppData: AppData<RestoredSystemEnvironment> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.restoredEnvironment
    );

    return { restoredEnvironmentAppData };
};

export default useRestoredEnvironment;
