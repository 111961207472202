import styled from 'styled-components';

export const StyledSelectContractContainer = styled.div`
    display: grid;
    grid-template-columns: 0.42fr 1fr;
    height: calc(100% - 80px);
    width: 100%;
    gap: 20px;
    flex: 1;
`;
