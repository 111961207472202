import { CssTextField } from '../../../../data-integration-form-style';
import { Field } from 'react-final-form';
import React, { useState } from 'react';
import ImageIcon from 'packages/libs/common/assets/icons/data-integrations/integrations-icons/landscape-image-icon.svg';
import { FieldLabel, IconContainer, SmallVideoContainer, TextFieldContainer, TextFieldDivider } from './credentials-step-style';
import { retriveCopyBotTokenVideo, retriveCopySigningSecretVideo } from '@walkme-admin-center/libs/common';

const CredentialsStep = () => {
    const [showSigningSecretWindow, setShowSigningSecretWindow] = useState(false);
    const [showBotTokenWindow, setShowBotTokenWindow] = useState(false);

    const handleMouseEnterSecret = () => {
        setShowSigningSecretWindow(true);
    };
    const handleMouseLeaveSecret = () => {
        setShowSigningSecretWindow(false);
    };

    const handleMouseEnterBotToken = () => {
        setShowBotTokenWindow(true);
    };
    const handleMouseLeaveBotToken = () => {
        setShowBotTokenWindow(false);
    };

    return (
        <>
            <TextFieldContainer>
                <FieldLabel>
                    Paste 'Signing Secret'
                    <IconContainer onMouseEnter={handleMouseEnterSecret} onMouseLeave={handleMouseLeaveSecret}>
                        <img src={ImageIcon} />
                    </IconContainer>
                    {showSigningSecretWindow && (
                        <SmallVideoContainer style={{ top: '22.5%', left: '30%' }} autoPlay={true} loop={true}>
                            <source src={retriveCopySigningSecretVideo()} type='video/mp4' />
                        </SmallVideoContainer>
                    )}
                </FieldLabel>

                <Field name={'settings.app_secret'}>
                    {({ input, meta }) => (
                        <CssTextField
                            variant='outlined'
                            placeholder={'Copy from Slack and paste here'}
                            fullWidth
                            error={!!meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            {...input}
                        />
                    )}
                </Field>
                <TextFieldDivider />
                <FieldLabel>
                    Pase 'Bot User OAuth Token'
                    <IconContainer onMouseEnter={handleMouseEnterBotToken} onMouseLeave={handleMouseLeaveBotToken}>
                        <img src={ImageIcon} />
                    </IconContainer>
                    {showBotTokenWindow && (
                        <SmallVideoContainer style={{ top: '35%', left: '35%' }} autoPlay={true} loop={true}>
                            <source src={retriveCopyBotTokenVideo()} type='video/mp4' />
                        </SmallVideoContainer>
                    )}
                </FieldLabel>
                <Field name={'settings.app_key'}>
                    {({ input, meta }) => (
                        <CssTextField
                            variant='outlined'
                            placeholder={'Copy from Slack and paste here'}
                            fullWidth
                            error={!!meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            {...input}
                        />
                    )}
                </Field>
            </TextFieldContainer>
        </>
    );
};

export default CredentialsStep;
