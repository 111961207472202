import React, { ReactNode } from 'react';
import { Header, HeaderContainer, HeaderText, SubHeaderText } from './form-header-style';
import styles from '../../common/style.module.scss';
import { shrinkText } from '../../common/services';
import { FormMode, IHeaderText } from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import styled from 'styled-components';
import { CloseIconThin } from '../../data-integrations-list/integration-card/icons/icons';
import { IconButton } from '@walkme/ui-core';

export interface IFormHeader {
    onClose: () => void;
    headerText?: IHeaderText;
    formMode: FormMode;
    step: number;
    goBack: () => void;
    actions?: ReactNode;
}

export const HeaderActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const FormHeader = ({ headerText, formMode, step, onClose, goBack, actions }: IFormHeader) => {
    return (
        <>
            <HeaderContainer subHeader={!!headerText?.subHeader}>
                <Header subHeader={!!headerText?.subHeader}>
                    <div className={'header-flex-row'}>
                        <div
                            className={`${styles.flexColumn} ${styles.spaceBetween} ${styles.start} ${styles.width100}`}
                            style={{ lineHeight: '28px' }}>
                            <div className={'header-inner-flex-row'}>
                                <HeaderText>{shrinkText(headerText.header, 45)}</HeaderText>
                                <HeaderActionsContainer>
                                    {actions}
                                    <IconButton style={{ width: '28px', height: '28px' }} variant='ghost' onClick={onClose}>
                                        <CloseIconThin />
                                    </IconButton>
                                </HeaderActionsContainer>
                            </div>
                            <SubHeaderText>{headerText.subHeader}</SubHeaderText>
                        </div>
                    </div>
                </Header>
            </HeaderContainer>
        </>
    );
};
