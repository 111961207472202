import React from 'react';
import { Saml, Okta, Adfs, Azuread, GSuite, Oidc, PingId } from './providers';

const providersInit = {
  saml: new Saml(),
  okta: new Okta(),
  'g-suite': new GSuite(),
  adfs: new Adfs(),
  azuread: new Azuread(),
  OIDC: new Oidc(),
  pingId: new PingId(),
};


export const getProviderInstance = (idpName: string) => {
  return providersInit[idpName];
};

