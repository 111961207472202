import { useSelector } from 'react-redux';
import { AiHubDashboardState } from '../AiHubDashboard.slice';

export const useAiHubDashboard = () => {
    const capViewTableData = useSelector(
        (state: { aiHubDashboardState: AiHubDashboardState }) => state.aiHubDashboardState.capViewTableData
    );
    const itemsUsageExpenseRowData = useSelector(
        (state: { aiHubDashboardState: AiHubDashboardState }) => state.aiHubDashboardState.itemsUsageExpenseRowData
    );

    return {
        capViewTableData,
        itemsUsageExpenseRowData,
    };
};

export default useAiHubDashboard;
