import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { insightsApi } from '../services/insights-api';
import { AddedDesktopAppResults } from '../types/added-desktop-apps-result';
import { DesktopTrackedApp, DesktopTrackedApps } from '../types/desktop-tracked-app';

export interface DesktopTrackedAppsState {
    currentDesktopTrackedApp: AppData<DesktopTrackedApp>;
    updatedDesktopTrackedApp: AppData<DesktopTrackedApp>;
    createdDesktopTrackedApp: AppData<DesktopTrackedApp>;
    desktopTrackedApps: AppData<DesktopTrackedApps>;
    activateDesktopTrackedApps: AppData<number>;
    deactivateDesktopTrackedApps: AppData<number>;
    addedDesktopTrackedAppsResults: AddedDesktopAppResults;
}

const initalNewDesktopTrackedAppState: DesktopTrackedApp = {
    systemName: '',
    winIdentifiers: [
        {
            id: 'local_id_win',
            processName: '',
            appTitle: '',
        },
    ],
    macIdentifiers: [
        {
            id: 'local_id_mac',
            appName: '',
            bundleId: '',
        },
    ],
};

const initalAddedDesktopTrackedAppsResults = {
    success: [],
    failed: [],
};

const initalDesktopTrackedAppState: DesktopTrackedApp = {
    systemName: '',
    winIdentifiers: [],
    macIdentifiers: [],
};

const initialDesktopTrackedAppsState: DesktopTrackedAppsState = {
    currentDesktopTrackedApp: {
        error: null,
        loading: false,
        data: initalNewDesktopTrackedAppState,
    },
    updatedDesktopTrackedApp: {
        error: null,
        loading: false,
        data: initalDesktopTrackedAppState,
    },
    createdDesktopTrackedApp: {
        error: null,
        loading: false,
        data: initalDesktopTrackedAppState,
    },
    desktopTrackedApps: {
        error: null,
        loading: true,
        data: null,
    },
    activateDesktopTrackedApps: {
        error: null,
        loading: false,
        data: 0,
    },
    deactivateDesktopTrackedApps: {
        error: null,
        loading: false,
        data: 0,
    },
    addedDesktopTrackedAppsResults: initalAddedDesktopTrackedAppsResults,
};

const desktopTrackedAppsSlice = createSlice({
    name: 'desktopTrackedAppsSlice',
    initialState: initialDesktopTrackedAppsState,
    reducers: {
        getDesktopTrackedAppsStart(state: DesktopTrackedAppsState) {
            state.desktopTrackedApps.error = null;
            state.desktopTrackedApps.loading = true;

            return state;
        },
        getDesktopTrackedAppsSuccess(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApps>) {
            state.desktopTrackedApps.data = action.payload;
            state.desktopTrackedApps.loading = false;
            state.desktopTrackedApps.error = null;

            return state;
        },
        getDesktopTrackedAppsFailed(state, action: PayloadAction<string>) {
            state.desktopTrackedApps.loading = false;
            state.desktopTrackedApps.error = action.payload;

            return state;
        },
        getCurrentDesktopTrackedAppStart(state: DesktopTrackedAppsState) {
            state.currentDesktopTrackedApp.error = null;
            state.currentDesktopTrackedApp.loading = true;

            return state;
        },
        getCurrentDesktopTrackedAppSuccess(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApp>) {
            state.currentDesktopTrackedApp.data = action.payload;
            state.currentDesktopTrackedApp.loading = false;
            state.currentDesktopTrackedApp.error = null;

            return state;
        },
        getCurrentDesktopTrackedAppFailed(state, action: PayloadAction<string>) {
            state.currentDesktopTrackedApp.loading = false;
            state.currentDesktopTrackedApp.error = action.payload;

            return state;
        },
        cleanCurrentDesktopTrackedApp(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApp>) {
            state.currentDesktopTrackedApp.data = initalNewDesktopTrackedAppState;
            state.currentDesktopTrackedApp.loading = false;
            state.currentDesktopTrackedApp.error = null;

            return state;
        },
        createDesktopTrackedAppStart(state: DesktopTrackedAppsState) {
            state.createdDesktopTrackedApp.error = null;
            state.createdDesktopTrackedApp.loading = true;

            return state;
        },
        createDesktopTrackedAppSuccess(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApp>) {
            state.createdDesktopTrackedApp.data = action.payload;
            state.createdDesktopTrackedApp.loading = false;
            state.createdDesktopTrackedApp.error = null;

            return state;
        },
        createDesktopTrackedAppFailed(state, action: PayloadAction<string>) {
            state.createdDesktopTrackedApp.loading = false;
            state.createdDesktopTrackedApp.error = action.payload;

            return state;
        },
        cleanCreatedDesktopTrackedApp(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApp>) {
            state.createdDesktopTrackedApp.data = initalDesktopTrackedAppState;
            state.createdDesktopTrackedApp.loading = false;
            state.createdDesktopTrackedApp.error = null;

            return state;
        },
        updateDesktopTrackedAppStart(state: DesktopTrackedAppsState) {
            state.updatedDesktopTrackedApp.error = null;
            state.updatedDesktopTrackedApp.loading = true;

            return state;
        },
        updateDesktopTrackedAppSuccess(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApp>) {
            state.updatedDesktopTrackedApp.data = action.payload;
            state.updatedDesktopTrackedApp.loading = false;
            state.updatedDesktopTrackedApp.error = null;

            return state;
        },
        updateDesktopTrackedAppFailed(state, action: PayloadAction<string>) {
            state.updatedDesktopTrackedApp.loading = false;
            state.updatedDesktopTrackedApp.error = action.payload;

            return state;
        },
        cleanUpdatedDesktopTrackedApp(state: DesktopTrackedAppsState, action: PayloadAction<DesktopTrackedApp>) {
            state.updatedDesktopTrackedApp.data = initalDesktopTrackedAppState;
            state.updatedDesktopTrackedApp.loading = false;
            state.updatedDesktopTrackedApp.error = null;

            return state;
        },
        activateDesktopTrackedAppsStart(state: DesktopTrackedAppsState) {
            state.activateDesktopTrackedApps.error = null;
            state.activateDesktopTrackedApps.loading = true;

            return state;
        },
        activateDesktopTrackedAppsSuccess(state: DesktopTrackedAppsState, action: PayloadAction<number>) {
            state.activateDesktopTrackedApps.data = action.payload;
            state.activateDesktopTrackedApps.loading = false;
            state.activateDesktopTrackedApps.error = null;

            return state;
        },
        activateDesktopTrackedAppsFailed(state, action: PayloadAction<string>) {
            state.activateDesktopTrackedApps.loading = false;
            state.activateDesktopTrackedApps.error = action.payload;

            return state;
        },
        cleanActivateDesktopTrackedApps(state: DesktopTrackedAppsState) {
            state.activateDesktopTrackedApps.data = null;
            state.activateDesktopTrackedApps.loading = false;
            state.activateDesktopTrackedApps.error = null;

            return state;
        },
        deactivateDesktopTrackedAppsStart(state: DesktopTrackedAppsState) {
            state.deactivateDesktopTrackedApps.error = null;
            state.deactivateDesktopTrackedApps.loading = true;

            return state;
        },
        deactivateDesktopTrackedAppsSuccess(state: DesktopTrackedAppsState, action: PayloadAction<number>) {
            state.deactivateDesktopTrackedApps.data = action.payload;
            state.deactivateDesktopTrackedApps.loading = false;
            state.deactivateDesktopTrackedApps.error = null;

            return state;
        },
        deactivateDesktopTrackedAppsFailed(state, action: PayloadAction<string>) {
            state.deactivateDesktopTrackedApps.loading = false;
            state.deactivateDesktopTrackedApps.error = action.payload;

            return state;
        },
        cleanDeactivatedDesktopTrackedApps(state: DesktopTrackedAppsState) {
            state.deactivateDesktopTrackedApps.data = null;
            state.deactivateDesktopTrackedApps.loading = false;
            state.deactivateDesktopTrackedApps.error = null;

            return state;
        },
        addedDesktopTrackedAppsResults(state: DesktopTrackedAppsState, action: PayloadAction<AddedDesktopAppResults>) {
            state.addedDesktopTrackedAppsResults = action.payload;

            return state;
        },
        cleanAddedDesktopTrackedAppsResults(state: DesktopTrackedAppsState) {
            state.addedDesktopTrackedAppsResults = initalAddedDesktopTrackedAppsResults;

            return state;
        },
    },
});

export { desktopTrackedAppsSlice };
type DesktopTrackedAppsStateType = ReturnType<typeof desktopTrackedAppsSlice.reducer>;

const {
    getDesktopTrackedAppsFailed,
    getDesktopTrackedAppsStart,
    getDesktopTrackedAppsSuccess,
    getCurrentDesktopTrackedAppFailed,
    getCurrentDesktopTrackedAppStart,
    getCurrentDesktopTrackedAppSuccess,
    createDesktopTrackedAppFailed,
    createDesktopTrackedAppStart,
    createDesktopTrackedAppSuccess,
    updateDesktopTrackedAppFailed,
    updateDesktopTrackedAppStart,
    updateDesktopTrackedAppSuccess,
    activateDesktopTrackedAppsFailed,
    activateDesktopTrackedAppsStart,
    activateDesktopTrackedAppsSuccess,
    deactivateDesktopTrackedAppsFailed,
    deactivateDesktopTrackedAppsStart,
    deactivateDesktopTrackedAppsSuccess,
} = desktopTrackedAppsSlice.actions;

type AppThunk = ThunkAction<void, DesktopTrackedAppsStateType, unknown, Action<string>>;

export const getDesktopTrackedApp = (msaId: number, systemId: number): AppThunk => async (dispatch) => {
    try {
        dispatch(getCurrentDesktopTrackedAppStart());

        const desktopTrackedApp = await insightsApi.getDesktopTrackedApp(msaId, systemId);

        dispatch(getCurrentDesktopTrackedAppSuccess(desktopTrackedApp));
    } catch (err) {
        dispatch(getCurrentDesktopTrackedAppFailed(err.message));

        return;
    }
};

export const getDesktopTrackedApps = (msaId: number): AppThunk => async (dispatch) => {
    try {
        dispatch(getDesktopTrackedAppsStart());

        const desktopTrackedApps = await insightsApi.getDesktopTrackedApps(msaId);

        dispatch(getDesktopTrackedAppsSuccess(desktopTrackedApps));
    } catch (err) {
        dispatch(getDesktopTrackedAppsFailed(err.message));

        return;
    }
};

export const createDesktopTrackedApp = (msaId: number, desktopTrackedApp: DesktopTrackedApp): AppThunk => async (dispatch) => {
    try {
        dispatch(createDesktopTrackedAppStart());

        const createdDesktopTrackedApp = await insightsApi.createDesktopTrackedApp(msaId, desktopTrackedApp);

        dispatch(createDesktopTrackedAppSuccess(createdDesktopTrackedApp));
    } catch (err) {
        if (err.request.status === 400 || err.request.status === 409) {
            dispatch(getDesktopTrackedApps(msaId));
        }

        dispatch(createDesktopTrackedAppFailed(err.message));

        return;
    }
};

export const updateDesktopTrackedApp = (msaId: number, systemId: number, desktopTrackedApp: DesktopTrackedApp): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(updateDesktopTrackedAppStart());

        const desktopTrackedApps = await insightsApi.updateDesktopTrackedApp(msaId, systemId, desktopTrackedApp);

        dispatch(updateDesktopTrackedAppSuccess(desktopTrackedApps));
    } catch (err) {
        if (err.request.status === 400 || err.request.status === 409) {
            dispatch(getDesktopTrackedApps(msaId));
        }

        dispatch(updateDesktopTrackedAppFailed(err.message));

        return;
    }
};

export const activateDesktopTrackedApps = (msaId: number, systemIds: number[]): AppThunk => async (dispatch) => {
    try {
        dispatch(activateDesktopTrackedAppsStart());

        await insightsApi.activateDesktopTrackedApps(msaId, systemIds);

        dispatch(getDesktopTrackedApps(msaId));
        dispatch(activateDesktopTrackedAppsSuccess(systemIds.length));
    } catch (err) {
        dispatch(activateDesktopTrackedAppsFailed(err.message));

        return;
    }
};

export const deactivateDesktopTrackedApps = (msaId: number, systemIds: number[]): AppThunk => async (dispatch) => {
    try {
        dispatch(deactivateDesktopTrackedAppsStart());

        await insightsApi.deactivateDesktopTrackedApps(msaId, systemIds);

        dispatch(getDesktopTrackedApps(msaId));
        dispatch(deactivateDesktopTrackedAppsSuccess(systemIds.length));
    } catch (err) {
        dispatch(deactivateDesktopTrackedAppsFailed(err.message));

        return;
    }
};
