import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SystemsState } from '@walkme-admin-center/libs/state-management-systems';
import {
    CssCheckBox,
    CssArrowIcon,
    CssIntegrationRow,
    CssIntegrationData,
    NameContainer,
    LastSyncContainer,
    IconContainer,
    SyncStatusContainer,
    CreatedConatiner,
    ActionContainer,
    SystemsContainer,
    SystemTypeContainer,
    SystemNameContainer,
    SystemStatusContainer,
    SystemTopRow,
    SystemCheckBoxContainer,
} from './integration-row-style';
import ArrowIcon from 'packages/libs/common/assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg';
import IntegrationCardActions from '../integration-card/integration-card-actions/integration-card-actions';
import { StatusIcon, StatusText } from '../integration-card/integration-card-styled';
import CountUp from 'react-countup';
import SystemsRow from './systems-row';
import { IAskMe } from 'packages/libs/state-mangment-data-integration/src/lib/types';

export interface IIntegrationTableRow {
    integration: IAskMe;
    editIntegration: (integration: IAskMe) => void;
    editSourceSettings: (integration: IAskMe) => void;
    editGuidedAnswers: (integration: IAskMe) => void;
    showScanSummary: (integration: IAskMe) => void;
    isChecked: boolean;
    updateCheckedList: (sourceGuid: string, checked: boolean) => void;
    openChat: (integration: IAskMe) => void;
    isChatOpen: boolean;
    collapseAll: boolean;
}

const IntegrationTableRow = ({
    integration,
    editIntegration,
    editSourceSettings,
    editGuidedAnswers,
    showScanSummary,
    isChecked,
    updateCheckedList,
    openChat,
    isChatOpen,
    collapseAll,
}: IIntegrationTableRow) => {
    const [isHover, setIsHover] = useState(false);
    const [open, setOpen] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [allSystemsSelected, setallSystemsSelected] = useState(false);
    const [checkedSystems, setCheckedSystems] = useState<Set<string>>(new Set());
    const { data: systems } = useSelector((state: { systemsState: SystemsState }) => state.systemsState.systems);

    const { jobStatus } = integration;

    const getSystems = useCallback(() => {
        return systems.filter((system) => {
            return integration.systems.some((sys) => sys.guid === system.guid);
        });
    }, [systems, integration.systems]);

    useEffect(() => {
        setCollapse(collapseAll);
    }, [collapseAll]);

    const getPercentage = (): number => {
        const scan = jobStatus.scan || 0;
        const scrape = jobStatus.scrape || 0;
        const scrape_failed = jobStatus.scrape_failed || 0;
        const crawl_failed = jobStatus.crawl_failed || 0;
        if (!scan) {
            return 0;
        } else if (scrape + scrape_failed + crawl_failed > scan) {
            return 100;
        } else {
            return Math.floor(((scrape + scrape_failed + crawl_failed) / scan) * 100);
        }
    };

    const shouldShowCheckbox = useCallback(() => {
        if (jobStatus.status === 'inProgress') {
            return false;
        }
        return isHover || isChecked;
    }, [isHover, isChecked, jobStatus]);

    const isExecuting = (): boolean => {
        return integration.isExecuting;
    };

    const handleAllSystemsSelected = () => {
        if (allSystemsSelected) {
            setCheckedSystems(new Set());
        } else {
            setCheckedSystems(new Set(integration.systems.map((system) => system.guid)));
        }
        setallSystemsSelected(!allSystemsSelected);
    };

    const updateChekcedSystems = (systemGuid: string, checked: boolean) => {
        const newSet = new Set(checkedSystems);
        if (checked) {
            newSet.add(systemGuid);
        } else {
            newSet.delete(systemGuid);
        }
        setCheckedSystems(newSet);
    };
    useEffect(() => {
        setallSystemsSelected(checkedSystems.size === integration.systems.length);
    }, [checkedSystems]);

    // useEffect(() => {
    //     setCheckedSystems()
    // }, [isChecked])

    return (
        <div style={{ display: 'felx', flexDirection: 'column', alignSelf: 'stretch' }}>
            <CssIntegrationRow isHover={isHover} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                <IconContainer>
                    <CssArrowIcon src={ArrowIcon} collapsed={collapse} onClick={() => setCollapse(!collapse)} />
                    <CssCheckBox
                        type='checkbox'
                        onChange={(e) => updateCheckedList(integration.sourceId, e.target.checked)}
                        checked={isChecked}
                    />
                </IconContainer>
                <CssIntegrationData>
                    <NameContainer>
                        {integration.name}
                        <div style={{ color: 'var(--typography-typography-tint-3, #ACB3C4)', fontWeight: 400 }}>
                            {integration.systems.length}
                        </div>
                    </NameContainer>
                    <LastSyncContainer> {integration.lastCrawl ? integration.lastCrawl : 'Not run yet'}</LastSyncContainer>
                    <SyncStatusContainer>
                        {isExecuting() ? (
                            <CountUp preserveValue={true} end={getPercentage()} suffix='%' />
                        ) : (
                            <>
                                <StatusIcon className={`${jobStatus.status}`} />
                                <StatusText>{integration.jobStatus.status}</StatusText>
                            </>
                        )}
                    </SyncStatusContainer>
                    <CreatedConatiner>{moment(integration.createdAt).format('DD.MM.YYYY')}</CreatedConatiner>
                </CssIntegrationData>

                {isHover && (
                    <ActionContainer>
                        <IntegrationCardActions
                            editIntegration={() => editIntegration(integration)}
                            editSourceSettings={() => editSourceSettings(integration)}
                            editGuidedAnswers={() => editGuidedAnswers(integration)}
                            showScanSummary={() => showScanSummary(integration)}
                            integration={integration}
                            isChatOpen={isChatOpen}
                        />
                    </ActionContainer>
                )}
            </CssIntegrationRow>
            {collapse && (
                <>
                    <SystemsContainer>
                        <SystemCheckBoxContainer>
                            <CssCheckBox
                                type='checkbox'
                                onChange={handleAllSystemsSelected}
                                checked={allSystemsSelected}
                                disabled={isChecked}
                            />
                        </SystemCheckBoxContainer>
                        <SystemTopRow>
                            <SystemTypeContainer>Type</SystemTypeContainer>
                            <SystemNameContainer>Name</SystemNameContainer>
                            <SystemStatusContainer>Approval Status</SystemStatusContainer>
                        </SystemTopRow>
                    </SystemsContainer>
                    {integration.systems.map((system) => {
                        return (
                            <SystemsRow
                                updateChekcedSystems={updateChekcedSystems}
                                system={system}
                                isSystemChecked={checkedSystems.has(system.guid)}
                                key={system.guid}
                                isContentSourceChecked={isChecked}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default IntegrationTableRow;
