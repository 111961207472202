export interface CustomError {
  showError: boolean,
  message: string,
  title?: string,
  onConfirmText?: string
}

export enum ERROR_CONSTS {
  OK= 'OK',
  ERROR_TITLE = 'Error',
};
