import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Pluralize from 'react-pluralize';
import { CommonSnackbar, DataTable, useTable } from '@walkme-admin-center/libs/ui-components';
import { Confirm, Dialog } from '@walkme-admin-center/libs/common';
import { RoleForm, RoleFormValues } from './role-form/role-form';
import { FormActions, TableRowData } from '@walkme-admin-center/libs/types';
import { Role, Permission } from 'wm-accounts-sdk';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteRole,
    editRole,
    saveRole,
    useLoggedInUser,
    usersSlice,
    UsersState,
    useUsers,
} from '@walkme-admin-center/libs/state-management-users';
import { Box, Typography } from '@material-ui/core';
import { countRoleUsers, getRolesRows } from '../utils/roles-rows';
import { WMSnackbar } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';
import { useDataTableLocale } from 'packages/pages/util/locale-utils';

export const CustomRolesTab = ({ loadingData, pageData }) => {
    const { t, rt } = useTranslation('general');

    const tableSettings = useTable();
    const { usersAppData } = useUsers();
    const users = usersAppData.data;
    const [showDialog, setShowDialog] = useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [formActions, setFormActions] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const savedRoleAppData = useSelector((state: { usersState: UsersState }) => state.usersState.savedRole);
    const deletedRoleAppData = useSelector((state: { usersState: UsersState }) => state.usersState.deletedRole);
    const currentSnackbarMessage = useSelector((state: { usersState: UsersState }) => state.usersState.notificationMessage);

    const dataTableLocale = useDataTableLocale();

    const dispatch = useDispatch();

    useEffect(() => {
        if (savedRoleAppData.data) {
            setShowDialog(false);
            dispatch(usersSlice.actions.saveRoleCleanup());
        }
    }, [savedRoleAppData]);

    useEffect(() => {
        if (deletedRoleAppData.data) {
            setShowDialog(false);
            dispatch(usersSlice.actions.deleteRoleCleanup());
        }
    }, [deletedRoleAppData]);

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(usersSlice.actions.cleanUpNotificationMessage());
        dispatch(usersSlice.actions.saveRoleCleanup());
        dispatch(usersSlice.actions.deleteRoleCleanup());
    };

    const customRolesHead = [...pageData.headCells, { id: 'deleteButton', label: '' }];

    const rolesCount = useMemo(() => countRoleUsers(users), [users]);

    const onConfirmDeleteRole = useCallback(
        (role: { id: number; displayName: string }) => {
            dispatch(deleteRole({ role }));
        },
        [dispatch]
    );

    const { accountFeatureEnabled } = useLoggedInUser();

    const customRolesRows = useMemo(
        () =>
            getRolesRows(
                t,
                rt,
                pageData.roles,
                rolesCount,
                pageData.actions,
                accountFeatureEnabled,
                true,
                deletedRoleAppData,
                onConfirmDeleteRole
            ),
        [pageData.roles, rolesCount, deletedRoleAppData, t, rt]
    );

    const convertValuesToPermissions = (permissionsValues: Record<string, string>, allowedPermisons: string[] = []): Permission[] => {
        const permissions: Permission[] = [];
        for (const itemKey in permissionsValues) {
            const value = permissionsValues[itemKey];
            if (value === 'Inherit' || value === 'None' || !allowedPermisons.includes(itemKey)) continue;
            permissions.push({
                actionKey: itemKey,
                permission: value,
                resourceId: null,
            });
        }
        return permissions;
    };

    const onConfirmCreateRole = useCallback(
        (values: RoleFormValues) => {
            const permissions: Permission[] = convertValuesToPermissions(values.permissions, values.allowedActions);
            dispatch(
                saveRole({
                    role: {
                        displayName: values.roleName,
                        permissions: permissions,
                    },
                })
            );
        },
        [dispatch]
    );

    const onConfirmEditRole = useCallback(
        (values: RoleFormValues) => {
            const permissions: Permission[] = convertValuesToPermissions(values.permissions, values.allowedActions);
            dispatch(
                editRole({
                    role: {
                        id: values.existingRole.id,
                        displayName: values.roleName,
                        permissions: permissions,
                    },
                })
            );
        },
        [dispatch]
    );

    const cancel = useCallback(() => {
        setShowDialog(false);
    }, []);

    const triggerRoleFormDialog = (actions: FormActions, title: string, role?: Role) => {
        return (
            <Dialog
                view='sideScreen'
                children={
                    <RoleForm
                        actions={actions}
                        actionsGraph={pageData.actions}
                        role={role}
                        title={title}
                        deletingRole={deletedRoleAppData.loading}
                        submittingRole={savedRoleAppData.loading}
                        showDrawer={showDialog}
                        onClose={cancel}
                    />
                }
                onClose={null}
                showDialog={showDialog}
            />
        );
    };

    const createNewRoleDialog = useCallback(() => {
        const actions = { onConfirm: onConfirmCreateRole, onCancel: cancel };
        setShowDialog(true);
        setFormTitle(t(`users-and-roles-tab.roles.custom-roles-tab.role-form.create-title`));
        setFormActions(actions);
        setSelectedRole(null);
    }, [cancel, onConfirmCreateRole]);

    const handleRoleSelection = useCallback(
        (id) => {
            const role = pageData.roles.find((role) => role.id === id);
            const actions: FormActions = { onConfirm: onConfirmEditRole, onCancel: cancel };
            if (role.accountId) actions.onDelete = onConfirmDeleteRole;
            setSelectedRole(role);
            setShowDialog(true);
            setFormTitle(
                role
                    ? t('users-and-roles-tab.roles.custom-roles-tab.role-form.view-title')
                    : t('users-and-roles-tab.roles.custom-roles-tab.role-form.create-title')
            );
            setFormActions(actions);
        },
        [cancel, onConfirmEditRole, onConfirmDeleteRole, pageData.roles]
    );

    return (
        <>
            {showDialog && triggerRoleFormDialog(formActions, formTitle, selectedRole)}
            <DataTable
                onToolbarButtonClick={createNewRoleDialog}
                onRowClick={handleRoleSelection}
                heads={customRolesHead}
                data={customRolesRows}
                buttonText={t('buttons.new-role')}
                title={<Pluralize singular={'Role'} count={loadingData ? 'N/A' : pageData.rolesRows && pageData.rolesRows.length} />}
                tableHeader={
                    <Box display='flex' flexDirection='row' flexGrow={1}>
                        <Typography style={{ fontFamily: 'Poppins', color: '#2F426C', fontSize: '18px', fontWeight: 600 }}>
                            {t(`users-and-roles-tab.roles.custom-roles-tab.title`)}
                        </Typography>
                    </Box>
                }
                hasToolbar
                hideSort={true}
                loading={
                    loadingData || (savedRoleAppData && savedRoleAppData.loading) || (deletedRoleAppData && deletedRoleAppData.loading)
                }
                {...tableSettings}
                searchPlaceholder={t('users-and-roles-tab.roles.grid.search-placeholder')}
                locale={dataTableLocale}
            />
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
                onClose={handleSnackBarErrorClose}
            />
        </>
    );
};

export default CustomRolesTab;
