import { System } from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { SystemsState } from '../redux/systems.slice';

export const useUpdatedSystem = () => {
    const updatedSystemAppData: AppData<System> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.updatedSystem
    );

    return {
        updatedSystemAppData,
    };
};

export default useUpdatedSystem;
