import { Button, Divider, IconButton, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export const CssSourceNameDivider = withStyles({
    root: {
        backgroundColor: '#EBEFF7',
        margin: '30px 0',
    },
})(Divider);

export const CssDomainDivider = withStyles({
    root: {
        backgroundColor: '#F5F6F8',
        margin: '4px 0 7px 0',
    },
})(Divider);

export const CssAddButton = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '29px',
        width: '98px',
        textTransform: 'none',
        color: '#385FEB',
        borderRadius: '40px',
        fontSize: '12px',
        padding: '6px 14px 6px 10px',
        borderColor: 'rgb(59,97,235, 0.2)',
        '&.query_params-btn': {
            width: '140px',
        },
        '&:hover': {
            borderColor: '#3B61EB',
            background: 'none',
        },
    },
})(Button);

export const CssDeleteRowButton = withStyles({
    root: {
        '&:hover': {
            background: 'none',
        },
    },
})(IconButton);

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    border: solid 1px #e4e9fc !important;
    width: 188px;
`;

export const StyledToggleButton = styled(ToggleButton)<{ value: string }>`
    width: ${(props) => (props.value === 'includeDomains' ? '93px' : '97px')};
    height: 30px;
    border: none !important;
    color: #637191 !important;
    font-weight: 400;
    text-transform: none;

    &:hover {
        background-color: inherit;
    }

    &.Mui-selected {
        color: #385feb !important;
        font-weight: 400;
        background-color: #e4e9fc !important;

        &:first-child {
            border-radius: 0px 4px 4px 0px;
        }

        &:last-child {
            border-radius: 4px 0px 0px 4px;
        }
    }
`;
