import React, { useEffect, useState, KeyboardEvent } from 'react';
import { WMIconDelete, WMIconClose, WMTextField } from '@walkme/wm-ui';
import Box from '@material-ui/core/Box/Box';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { useStyles } from './styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { useTranslation } from 'apps/home/src/localization';
import { validateUrlStructure, ValidationResult } from 'packages/pages/util/validation';

const DOMAIN_URL_PLACEHOLDER = 'http://example.com/*';
const REGEX_URL_PLACEHOLDER = 'example.com';

interface UrlProps {
    value: string;
    isRegex: boolean;
    isNew: boolean;
    disabled: boolean;
    tooltipMessage?: string;
    index?: number;
    handleSave?: (value: string, index: number) => void;
    handleDelete?: (index: number) => void;
    setAreUrlsValid?: (isValid: boolean, index: number) => void;
    enableDelete: boolean;
    shouldValidateValue: boolean;
    enableNewUrlClose?: boolean; // There are cases when we show the new url row control by default, and in that case we do not want to enable the close option
    onAddNewUrlClose?: () => void;
}

export const Url = ({
    index,
    value,
    isRegex,
    tooltipMessage,
    isNew,
    disabled,
    handleDelete,
    setAreUrlsValid,
    handleSave,
    enableDelete,
    shouldValidateValue,
    enableNewUrlClose,
    onAddNewUrlClose,
}: UrlProps) => {
    const classes = useStyles();
    const [currentValue, setCurrentValue] = useState<string>(value);
    const { t } = useTranslation('general');

    const urlValidationResult: ValidationResult = shouldValidateValue ? validateUrlStructure(currentValue, isRegex, t) : { isValid: true };
    const textFieldStatus = urlValidationResult.isValid ? 'default' : 'error';
    const placeholder = isRegex ? REGEX_URL_PLACEHOLDER : DOMAIN_URL_PLACEHOLDER;

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => setCurrentValue(event.target.value as string);

    setAreUrlsValid && setAreUrlsValid(urlValidationResult.isValid, index);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const saveIfValidAndNotDisabled = (): void => {
        if (!disabled && urlValidationResult.isValid) {
            handleSave(currentValue, index);
        }
    };

    const onKeyPress = (event: KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            saveIfValidAndNotDisabled();
        }
    };

    const commonTextFieldProps = {
        value: currentValue,
        className: classes.url,
        onChange: onValueChanged,
        helperText: urlValidationResult.errorMessage,
        placeholder: placeholder,
        onKeyPress: onKeyPress,
    };

    const existingUrlComponent = (
        <Tooltip title={tooltipMessage} placement='top'>
            <Box display='flex'>
                <WMTextField
                    {...commonTextFieldProps}
                    ds2
                    disabled={disabled}
                    status={textFieldStatus}
                    InputProps={{
                        endAdornment: !disabled && enableDelete && handleDelete && (
                            <InputAdornment position='end' className={classes.icon}>
                                <WMIconDelete onClick={() => handleDelete(index!)} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Tooltip>
    );

    const newUrlComponent = (
        <Box display='flex'>
            <WMTextField
                {...commonTextFieldProps}
                ds2
                autoFocus
                status={textFieldStatus}
                InputProps={{
                    endAdornment: !!enableNewUrlClose && onAddNewUrlClose && (
                        <InputAdornment position='end' className={classes.icon}>
                            <WMIconClose size={9} onClick={() => onAddNewUrlClose()} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );

    return <ClickAwayListener onClickAway={saveIfValidAndNotDisabled}>{isNew ? newUrlComponent : existingUrlComponent}</ClickAwayListener>;
};

export default Url;
