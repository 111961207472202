import { makeStyles } from '@material-ui/core';
import { styledScrollCss } from '../../common/styledScroll';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '182px', //150px of environment item + (16 * 2 px) padding
        overflowY: 'auto',
        ...styledScrollCss,
    },
    divider: {
        marginTop: '16px',
        marginBottom: '16px',
    },
}));

export { useStyles };
