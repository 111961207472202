import { TextField, Theme } from '@material-ui/core';
import { WMSelect } from '@walkme/wm-ui';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Field = styled.div`
    width: 100%;
    height: 2em;
    max-width: 28em;
`;

export const AutoCompleate = styled.div`
    width: 100%;
`;

export const FieldRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const EnabledColorWMSelect = styled(WMSelect)`
    width: 100%;
    height: 2em;
    max-width: 28em;
    .wmSelect__control {
        background-color: var(--ls-color-input-dropdown-bg-primary-default, #f0f3f9);
    }
`;

export const StyledSecletSystemsWMSelect = styled(WMSelect)`
    width: 100%;
    height: 2em;
    max-width: 28em;
`;

export const StyledLabel = styled.label`
    font-size: 12px;
    font-family: 'ProximaNova';
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
    color: var(--typography-typography-main-primary-main, #2f426c);
`;

export const CssTextField = styled(TextField)`
    width: 100%;
    height: 2em;
    max-width: 28em;

    & .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.Mui-disabled.MuiInputBase-formControl {
        background-color: var(--ls-color-input-dropdown-bg-primary-white-bg-disable, rgba(118, 118, 118, 0.09));
    }

    & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        background-color: var(--ls-color-input-dropdown-bg-primary-default, #f0f3f9);
    }

    & .MuiOutlinedInput-notchedOutline {
        border-width: 0 !important;
    }

    & .MuiInputBase-root,
    .MuiSelect-root,
    .MuiFormControl-root,
    .MuiFormControl-root {
        width: 100%;
    }

    & .MuiInputBase-input,
    .MuiOutlinedInput-input {
        padding: 0px;
        margin: 0.5em;
    }
`;
