import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppData } from '@walkme-admin-center/libs/types';
import { Dialog, retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import NewSystemForm from './new-system-form/new-system-form';
import {
    ActionTypeDetails,
    SubscriptionStatusActionType,
    SubscriptionStatusType,
    System,
    Systems,
    SystemsSettings,
    SystemStatusDetails,
    SystemTypeKey,
    WebhookResponseStatus,
} from 'wm-accounts-sdk';
import {
    saveSystem,
    ShouldShowAssociatedSystems,
    systemsSlice,
    SystemsState,
    updateSystemsStatus,
    useSystemsCreationStatus,
    useUpdatedSystem,
} from '@walkme-admin-center/libs/state-management-systems';
import {
    MenuAction,
    WMButtonVariant,
    WMChip,
    WMDataGrid,
    WMDataGridActionColumn,
    WMDialog,
    WMIconDelete,
    WMIconSettings,
    WMIconWarning,
    WMSnackbar,
    WMSnackbarVariant,
    WMDataGridActionDialog,
} from '@walkme/wm-ui';
import { useLoggedInUser, useUsers } from '@walkme-admin-center/libs/state-management-users';
import SystemDetails from './system_details/system_details';
import SystemStatusIndicator from './system-status-indicator';
import { Box, Typography } from '@material-ui/core';
import { regexesColumnValueGetter, WebSystemAdvancedSettings } from '@walkme-admin-center/pages/home/extension-pages';
import ChangeSystemType from './change_system_type/change_type';
import { DeployablesState, deployablesTypes, useDeployables } from 'packages/libs/state-management-deployables/src';
import { IconSelfHostedMenuItem, SelfHostedDialog, SelfHostedPackageDownload } from '@walkme-admin-center/self-hosting';
import useSystemsSelfHosted from 'packages/libs/state-management-systems/src/lib/hooks/use-systems-selfhosted';
import styled from 'styled-components';
import moment from 'moment';
import { DeployablesTagList } from '@walkme/deployables-tag-list';
import { getSystemsRegexes, useGetSystemsRegexes } from '@walkme-admin-center/libs/state-management-extensions';
import { GetSystemsRegexesResultItem, SystemsRegexes } from '@walkme-admin-center/libs/exposure-api';
import { useNavigate } from 'react-router-dom';
import { dateComparator } from '../../../../../libs/ui-components/src/lib/data-table/utils';
import {
    StyledDialog,
    StyledWMDataGrid,
    StyledGridTitle,
    StyledTypography,
    StyledAppIconContainer,
    StyledAppNameContainer,
    DeleteItemsButton,
    DeletionDialog,
    DeletionDialogWithPlatform,
    DialogSubTitle,
    DialogText,
    EnvChip,
    NewChip,
    MenuList,
} from './styles/systems-page.styles';
import { momentLocalesMapping, useTranslation } from 'apps/home/src/localization';
import { useDatagridLocale } from 'packages/pages/util/locale-utils';
import { useSystemAppsConfigs } from '@walkme-admin-center/libs/state-management-systems';
import { DeleteButton, MultipleSelectionFooter } from '@walkme-admin-center/libs/ui-components';
import { ColDef } from '@ag-grid-community/core';

const HttpStatus = {
    Forbidden: '403',
};

export const generateTextBox = (borderColor: string, backgroundColor: string, text: JSX.Element, icon?: JSX.Element) => {
    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 16px',
                marginBottom: '10px',
                backgroundColor,
                border: `1px solid ${borderColor}`,
                borderRadius: '4px',
            }}>
            {icon}
            {text}
        </Box>
    );
};

export interface SystemsPageProps {
    systems: Systems;
    systemsSettings: SystemsSettings;
    loadingAccountSystems: boolean;
}

export const AllSystemsPage = ({ pageData, loadingData }) => {
    const { t, rt, i18n } = useTranslation('general');
    const navigate = useNavigate();
    const { systemsDeployablesCountAppData } = useDeployables();
    //const [systemIdToOpen, setSystemIdToOpen] = useState(pageData.id && !isNaN(pageData.id) ? Number(pageData.id) : null);
    const { systemsCreationStatus } = useSystemsCreationStatus();
    const { systemsSelfHostedMap } = useSystemsSelfHosted();
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState('');
    const [openWarningSnackbar, setOpenWarningSnackbar] = useState('');
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [cleanupRowsSelection, setCleanupRowsSelection] = useState(false);
    const createdSystemAppData: AppData<System> = useSelector((state: { systemsState: SystemsState }) => state.systemsState.createdSystem);
    const updatedSystemNameAppData: AppData<System> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.updatedSystemName
    );
    const updateSystemsStatusAppData: AppData<SystemStatusDetails[]> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.updateSystemsStatus
    );
    const DeployablesTypesAppData = useSelector(
        (state: { deployablesState: DeployablesState }) => state.deployablesState.realDeployablesTypes
    );
    const {
        loggedInUserAppData: {
            data: {
                role: { displayName },
            },
        },
        userPermissions,
        accountFeatureEnabled,
    } = useLoggedInUser();
    const { getSystemsRegexesAppData } = useGetSystemsRegexes();

    const systemsWithEnvs = useMemo(() => {
        return (
            pageData.enabledSystems &&
            pageData.enabledSystems.map((system) => {
                return {
                    ...system,
                    deployables: systemsDeployablesCountAppData.data.find(
                        (systemsDeployablesCount) => systemsDeployablesCount.systemId == system.id
                    ),
                };
            })
        );
    }, [pageData.enabledSystems, systemsDeployablesCountAppData.data]);

    const datagridLocale = useDatagridLocale();

    const sortedSystems =
        systemsWithEnvs &&
        systemsWithEnvs.sort((a, b) => {
            return b.creationDate.localeCompare(a.creationDate);
        });

    const systemsRows = useMemo(() => {
        if (!sortedSystems) {
            return [];
        } else if (getSystemsRegexesAppData.data) {
            const mappingFromSystemIdToRegexes: Map<number, SystemsRegexes[]> = new Map(
                getSystemsRegexesAppData.data.map((resultItem: GetSystemsRegexesResultItem) => {
                    return [resultItem.systemId, resultItem.regexes];
                })
            );
            const systemsWithRegexes = sortedSystems.map((sortedSystem) => {
                return {
                    ...sortedSystem,
                    regexes: mappingFromSystemIdToRegexes.get(sortedSystem.id) ?? [],
                };
            });

            return systemsWithRegexes;
        }

        return sortedSystems;
    }, [sortedSystems, getSystemsRegexesAppData.data]);

    const [selectedSystem, setSelectedSystem] = useState(null);

    const [showDialog, setShowDialog] = useState(false);
    const [showAdvancedSettingsDialog, setShowAdvancedSettingsDialog] = useState(false);
    const [showSelfHostedSettingsDialog, setShowSelfHostedSettingsDialog] = useState(false);
    const [showChangeSystemTypeDialog, setShowChangeSystemTypeDialog] = useState(false);
    const [showAddMobileDialog, setShowAddMobileDialog] = useState(false);
    const [showDeleteSystemsDialog, setShowDeleteSystemsDialog] = useState(false);
    const [systemsToDelete, setSystemsToDelete] = useState([]);
    const dispatch = useDispatch();
    const { usersAppData } = useUsers();
    const { updatedSystemAppData } = useUpdatedSystem();
    const { systemAppsConfigData } = useSystemAppsConfigs();

    useEffect(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
    }, [i18n.language]);

    useEffect(() => {
        dispatch(deployablesTypes());
        dispatch(getSystemsRegexes());
    }, []);

    const selfHostedInAdminCenterEnabled = accountFeatureEnabled('selfHostedInAdminCenter');

    const [newSystemIds, setNewSystemIds] = useState<number[]>([]);

    const handleSnackBarSuccessClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSuccessSnackbar('');
    };

    const handleSnackBarWarningClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenWarningSnackbar('');
    };

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar('');
    };

    useEffect(() => {
        const systemIdToOpen = pageData.id && !isNaN(pageData.id) ? Number(pageData.id) : null;
        if (systemIdToOpen && pageData.enabledSystems && pageData.enabledSystems.length > 0) {
            const newSystem = pageData.enabledSystems.find((system) => system.id == systemIdToOpen);
            if (newSystem) {
                setSelectedSystem(newSystem);
                setShowDialog(true);
                //setSystemIdToOpen(null);
            }
        }
    }, [pageData.enabledSystems, pageData.id]);

    const canSystemBeConverted = (system: System) => {
        if (!system) {
            return false;
        }
        if (system?.settings?.name == 'unknown') {
            return true;
        }

        const systemWithAppliedSettings = systemAppsConfigData?.data?.find((sa) => sa.name === system.settings.name);
        if (!systemWithAppliedSettings) {
            return true;
        }

        const hasDeployables = systemsWithEnvs.some((i) => i.id === system.id && i.deployables);
        return !hasDeployables;
    };

    const actions: MenuAction<System>[] = [
        {
            label: t('systems-tab.systems-table.change-system-type'),
            icon: <img src={`/assets/icons/icon-Loading.svg`} width='20px' height='20px' />,
            onClick: (data) => openChangeSystemType(data),
            isVisible: (data) => canSystemBeConverted(data) && data.systemTypeKey === 'Web',
        },
        {
            label: t('systems-tab.systems-table.add-mobile-web'),
            icon: <img src={`/assets/icons/icon-add-mobile.svg`} width='20px' height='20px' />,
            onClick: (data) => openAddMobile(data),
            isVisible: (data) => data && !data.platforms.some((platform) => platform.platformTypeId === 3) && data.systemTypeKey === 'Web',
        },
        {
            label: t('systems-tab.systems-table.delete'),
            icon: <img src={`/assets/icons/icon-trash-bin.svg`} width='20px' height='20px' />,
            onClick: (data) => handleSystemsDeletionClick([data]),
            isVisible: () => true,
        },
    ];

    const MenuIcon: JSX.Element = <img src={`/assets/icons/menuIcon.svg`} width='20px' height='20px' />;

    const dateCreatedValueFormatter = (params) => {
        return moment(params.data.creationDate).format('MMM Do YYYY');
    };

    const systemStatusValueFormatter = (params) => {
        return params.data.isLimited ? t('systems-tab.systems-table.limited') : t('systems-tab.systems-table.unlimited');
    };

    const systemsUsers = useMemo(() => {
        const usersToSystemsMapping = {};
        for (const system of pageData.enabledSystems) {
            let numOfUsers = 0;
            for (const user in usersAppData.data) {
                for (const userSystem in usersAppData.data[user].systems) {
                    system.id == usersAppData.data[user].systems[userSystem].id ? numOfUsers++ : null;
                }
            }
            usersToSystemsMapping[system.id] = numOfUsers;
        }
        return usersToSystemsMapping;
    }, [pageData.enabledSystems, usersAppData]);

    useEffect(() => {
        if (updatedSystemAppData.data.id) {
            setOpenSuccessSnackbar(
                t('systems-tab.all-systems-page.success-message-change-type', {
                    newTypeDisplayName: updatedSystemAppData.data.settings.displayName,
                    displayName: updatedSystemAppData.data.displayName,
                })
            );
            dispatch(systemsSlice.actions.cleanupUpdatedSystem());
        } else if (updatedSystemAppData.error) {
            setOpenErrorSnackbar(updatedSystemAppData.error);
            dispatch(systemsSlice.actions.cleanupUpdatedSystem());
        }
    }, [updatedSystemAppData]);

    useEffect(() => {
        if (updatedSystemNameAppData.data?.id) {
            setOpenSuccessSnackbar(t('systems-tab.all-systems-page.success-message-change-name'));
            dispatch(systemsSlice.actions.cleanupUpdatedSystemName());
        } else if (updatedSystemNameAppData.error) {
            setOpenErrorSnackbar(updatedSystemNameAppData.error);
            dispatch(systemsSlice.actions.cleanupUpdatedSystemName());
        }
    }, [updatedSystemNameAppData]);

    function getActionColumn<T>(menuActions: MenuAction<T>[]) {
        return {
            colId: 'actions-column',
            pinned: 'right',
            lockPosition: 'right',
            suppressSizeToFit: true,
            width: 120,
            filter: false,
            cellStyle: (params) => {
                const visibleActions = menuActions?.filter((action) => action.isVisible(params.data));
                if (!visibleActions || visibleActions?.length === 0) {
                    return { visibility: 'hidden' };
                }
            },
            cellRenderer: WMDataGridActionColumn,
            cellRendererParams: {
                actions: menuActions,
                icon: MenuIcon,
                menuProps: {
                    classes: { list: MenuList },
                    align: 'right',
                },
            },
        };
    }

    const deployablesLabelsForTag = useCallback(
        (systemDeployables) => {
            if (systemDeployables) {
                let deployables = DeployablesTypesAppData.data.map((type) => {
                    return {
                        counter: 0,
                        name: type.value,
                        displayType: type.displayType,
                        id: type.type,
                    };
                });
                Object.entries(systemDeployables).forEach((SystemDeployable) => {
                    deployables.find((deployable) => deployable.name === SystemDeployable[0])
                        ? (deployables.find((deployable) => deployable.name === SystemDeployable[0]).counter +=
                              SystemDeployable[1] as number)
                        : (deployables.find((deployable) => deployable.displayType === 'SMART_WALKTHRU').counter +=
                              SystemDeployable[1] as number);
                });
                return deployables
                    .filter((label) => label.counter > 0)
                    .map((deployable) => {
                        return {
                            name: deployable.name,
                            displayType: deployable.displayType,
                            counter: deployable.counter,
                        };
                    });
            }
        },
        [DeployablesTypesAppData.data]
    );

    const getApplicationIcon = (params) => {
        return params.data?.settings ? (
            <img src={retrieveSystemIcon(params.data.settings)} />
        ) : (
            <>
                {params.data?.systemTypeKey == 'Web' ? (
                    <img src={`/assets/icons/custom_type.png`} />
                ) : params.data?.systemTypeKey == 'Mobile' ? (
                    <img src={retrieveSystemIcon(params.data)} />
                ) : (
                    <img src={`/assets/icons/Workstation.png`} />
                )}
                <span className={'system-type-key'}>
                    {params.data?.systemTypeKey == 'Web'
                        ? t('systems-tab.systems-table.custom-type')
                        : params.data?.systemTypeKey == 'Mobile'
                        ? params.data?.name
                        : t('systems-tab.systems-table.workstation')}
                </span>
            </>
        );
    };

    const getHideState = (colId) => {
        try {
            const savedColumnState = JSON.parse(localStorage.getItem('systemsColumnState'));
            const column: any = savedColumnState?.find((col) => col.colId === colId);
            return column?.hide;
        } catch (e) {
            console.error('Failed to parse JSON', e);
        }
    };

    const headCells: ColDef[] = useMemo(() => {
        return [
            {
                headerCheckboxSelection: true,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerName: t('systems-tab.systems-table.name'),
                colId: 'name',
                lockPosition: true,
                lockVisible: true,
                pinned: true,
                sortable: true,
                minWidth: 300,
                field: 'settings.displayName',
                comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
                    return nodeA?.data?.displayName?.localeCompare(nodeB?.data?.displayName);
                },
                cellRenderer: function (params) {
                    const checkbox = params.eGridCell.querySelector('div[ref="eCheckbox"] input');
                    const havePlatforms = params.data?.platforms?.length > 0;
                    if (checkbox && havePlatforms) {
                        checkbox.addEventListener('click', () => {
                            if (checkbox.checked) {
                                setOpenWarningSnackbar(
                                    t('systems-tab.all-systems-page.warning-message', {
                                        displayName: params.data?.displayName,
                                        systemTypeKey: params.data?.systemTypeKey,
                                    })
                                );
                            }
                        });
                    }
                    const systemData: System = params.data;
                    const createdSystemStatus = systemsCreationStatus[systemData.guid];

                    return (
                        <Box display='flex' flexDirection='row' alignItems='center' fontWeight={600}>
                            <StyledAppIconContainer>{getApplicationIcon(params)}</StyledAppIconContainer>

                            <StyledAppNameContainer>
                                <span>{params.data?.displayName}</span>
                                <StyledTypography variant={'T30'}>{rt(params.data?.settings?.displayName)}</StyledTypography>
                            </StyledAppNameContainer>

                            {newSystemIds.find((systemId) => systemId === systemData.id) && <NewChip label={t('common.new')} rounded />}
                            <SystemStatusIndicator system={systemData} createdSystemStatus={createdSystemStatus} />
                        </Box>
                    );
                },
            },
            {
                headerName: 'Custom Name',
                colId: 'custom-name',
                field: 'displayName',
                hide: true, // INFO: on purpose, the column's purpose is to enable search according to custom app name
            },
            ...pageData.renderSharedColumns(),
            {
                headerName: t('systems-tab.systems-table.usage'),
                colId: 'usage',
                minWidth: 200,
                sortable: true,
                field: 'usage',
                hide: getHideState('usage') ?? false,
                valueGetter: function (params) {
                    return params.data.usage ? params.data.usage?.charAt(0) + params.data.usage?.slice(1).toLowerCase() : 'N/A';
                },
            },
            {
                headerName: t('systems-tab.systems-table.purpose'),
                colId: 'purpose',
                minWidth: 200,
                sortable: true,
                field: 'purpose',
                hide: getHideState('purpose') ?? true,
                valueGetter: function (params) {
                    return params.data.purpose ? params.data.purpose?.charAt(0) + params.data.purpose?.slice(1).toLowerCase() : 'N/A';
                },
            },
            {
                headerName: t('systems-tab.systems-table.environment'),
                colId: 'environments',
                minWidth: 250,
                field: 'environments',
                hide: getHideState('environments') ?? false,
                cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
                getQuickFilterText: (params) => params.value.map((env) => env.name).toString(),
                cellRenderer: function (params) {
                    const visibleLabels = params.value ? params.value.slice(0, 2) : null;
                    return params.value ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {visibleLabels.map((env) => {
                                return <EnvChip key={env.id} label={env.name} />;
                            })}
                            {params.value.length - 2 > 0 && <EnvChip label={`+${params.value.length - 2}`} />}
                        </div>
                    ) : (
                        <div> N/A </div>
                    );
                },
            },
            {
                headerName: t('systems-tab.systems-table.users'),
                colId: 'users',
                minWidth: 200,
                sortable: true,
                hide: getHideState('users') ?? false,
                valueGetter: (params) => systemsUsers[params.data.id],
                comparator: (valueA, valueB) => valueA - valueB,
            },
            {
                colId: 'date-created',
                headerName: t('systems-tab.systems-table.date-created'),
                minWidth: 200,
                sortable: true,
                hide: getHideState('date-created') ?? false,
                valueGetter: dateCreatedValueFormatter,
                comparator: (valueA, valueB) => moment(valueA, 'MMM Do YYYY').unix() - moment(valueB, 'MMM Do YYYY').unix(),
            },
            {
                headerName: t('systems-tab.systems-table.type'),
                colId: 'type',
                minWidth: 200,
                sortable: false,
                hide: getHideState('type') ?? false,
                valueGetter: systemStatusValueFormatter,
            },
            {
                headerName: t('systems-tab.systems-table.deployables'),
                colId: 'deployables',
                minWidth: 200,
                field: 'deployables',
                hide: getHideState('deployables') ?? false,
                cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
                cellRenderer: function (params) {
                    return params.value && DeployablesTypesAppData.data.length > 0 ? (
                        <DeployablesTagList
                            maxTagsShown={2}
                            types={DeployablesTypesAppData.data}
                            dataLabels={deployablesLabelsForTag(params.value.deployables)}
                        />
                    ) : (
                        'N/A'
                    );
                },
            },
            {
                headerName: t('systems-tab.systems-table.user-identifier-method'),
                colId: 'user-identifier-method',
                minWidth: 250,
                hide: getHideState('user-identifier-method') ?? true,
                sortable: true,
                field: 'uniqueIdentifier.method',
            },
            {
                headerName: 'regexes',
                hide: true, // On purpose, we do not show them but filtering based on them
                minWidth: 200,
                sortable: false,
                field: 'regexes',
                valueGetter: regexesColumnValueGetter,
            },
            getActionColumn<System>(actions),
        ];
    }, [systemsCreationStatus, usersAppData.data, systemsWithEnvs, pageData.renderSharedColumns]);

    const deleteButton: DeleteButton = {
        onClick: (rows) => {
            handleSystemsDeletionClick(rows);
        },
        title: t('buttons.delete'),
    };

    const handleSystemsDeletionClick = (rowsToDelete) => {
        rowsToDelete.forEach((system) => {
            if (system.platforms.length > 0) {
                for (const platform of system.platforms) {
                    const alreadyInList = rowsToDelete.some((row) => row.id === platform.id);
                    const platformRow = pageData.enabledSystems.find((row) => row.id === platform.id);
                    if (!alreadyInList && platformRow) {
                        rowsToDelete.push(platformRow);
                    }
                }
            }
        });
        setSystemsToDelete(rowsToDelete);
        setShowDeleteSystemsDialog(true);
    };

    const handleDeletion = async () => {
        const systems = systemsToDelete.map((system) => ({
            systemId: system.id,
            systemGuid: system.guid,
        }));
        await deleteSystems(
            systems,
            SubscriptionStatusType.pendingForDelete,
            true,
            SubscriptionStatusActionType.self,
            ActionTypeDetails.admin
        );
    };

    useEffect(() => {
        setCleanupRowsSelection(false);
        if (updateSystemsStatusAppData.data.length > 0) {
            const message =
                systemsToDelete.length > 1
                    ? t('systems-tab.all-systems-page.success-message-systems-delete', { amount: systemsToDelete.length.toString() })
                    : t('systems-tab.all-systems-page.success-message-system-delete', { amount: systemsToDelete.length.toString() });
            setOpenSuccessSnackbar(message);
            dispatch(systemsSlice.actions.cleanupUpdateSystemsStatusStart());
            setCleanupRowsSelection(true);
            setShowDeleteSystemsDialog(false);
        } else if (updateSystemsStatusAppData.error) {
            setOpenErrorSnackbar(
                updateSystemsStatusAppData.error.includes(HttpStatus.Forbidden)
                    ? t('common.permission-error-text')
                    : updateSystemsStatusAppData.error
            );
            dispatch(systemsSlice.actions.cleanupUpdateSystemsStatusStart());
        }
    }, [updateSystemsStatusAppData]);

    const deleteSystems = useCallback(
        (systems, status, canBeRestored, actionType, actionTypeDetails) => {
            dispatch(updateSystemsStatus(systems, status, canBeRestored, actionType, actionTypeDetails));
        },
        [dispatch]
    );

    const addSystem = useCallback(
        (appType, appName, systemTypeKey, associatedSystem?) => {
            const systemName = appType ? appType : 'workstation';
            dispatch(saveSystem(systemName, appName, systemTypeKey, false, associatedSystem));
        },
        [dispatch]
    );

    const navToEditView = useCallback(
        (event) => {
            if (
                event.data.systemTypeKey === SystemTypeKey.DesktopMac ||
                event.data.systemTypeKey === SystemTypeKey.DesktopWindows ||
                event.data.systemTypeKey === SystemTypeKey.DesktopTrackedApps ||
                event.data.systemTypeKey === SystemTypeKey.DesktopElectron
            )
                return;
            if (event.colDef.colId !== 'actions-column') {
                const pathName = `/systems/all-systems/${event.data.id}/environments`;
                navigate({
                    pathname: pathName,
                });
            }
        },
        [history]
    );

    const openAdvancedSettings = useCallback((system) => {
        setSelectedSystem(system);
        setShowAdvancedSettingsDialog(true);
    }, []);

    const openSelfHosting = useCallback((system) => {
        setSelectedSystem(system);
        setShowSelfHostedSettingsDialog(true);
    }, []);

    const openChangeSystemType = useCallback((system) => {
        setSelectedSystem(system);
        setShowChangeSystemTypeDialog(true);
    }, []);

    const openAddMobile = useCallback((system) => {
        setSelectedSystem(system);
        setShowAddMobileDialog(true);
    }, []);

    const addMobileDialog = () => {
        const buttons = [
            {
                children: t('buttons.cancel'),
                variant: WMButtonVariant.Text,
                style: { marginRight: 32, color: '#c5cad6' },
                onClick: () => setShowAddMobileDialog(false),
            },
            {
                children: t('systems-tab.systems-table.add-mobile-web'),
                onClick: () =>
                    addSystem(selectedSystem.settings.name, selectedSystem.displayName, SystemTypeKey.MobileWeb, selectedSystem.id),
                loading: createdSystemAppData.loading,
                disabled: createdSystemAppData.loading,
            },
        ];

        return (
            <WMDialog
                ds2
                open={showAddMobileDialog}
                onClose={() => setShowAddMobileDialog(false)}
                title={'Add mobile web'}
                buttons={buttons}>
                <Box display='flex' flexDirection='row' style={{ margin: '16px 0' }}>
                    <img src={retrieveSystemIcon(selectedSystem.settings)} width='50px' height='50px' style={{ marginRight: '20px' }} />
                    <Box display='flex' flexDirection='column'>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#8D97AE',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}>
                            {selectedSystem.settings ? selectedSystem.settings.displayName : 'Default'}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                color: '#2F426C',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}>
                            {selectedSystem.displayName}
                        </Typography>
                    </Box>
                </Box>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        color: '#2F426C',
                        fontSize: '14px',
                        fontWeight: 400,
                        marginBottom: '32px',
                    }}>
                    {t('systems-tab.systems-table.add-mobile-web-text')}
                </Typography>
            </WMDialog>
        );
    };

    useEffect(() => {
        if (createdSystemAppData.data.id) {
            const createdSystemStatus = systemsCreationStatus[createdSystemAppData.data.guid];
            const statusOkToMoveToDrawer = (() => {
                if (createdSystemAppData.data.systemTypeKey !== 'Web') return true;
                if (!createdSystemStatus || !createdSystemStatus.processed) return false;
                const success = createdSystemStatus.successEventWebhookStatus || [];
                const falied = createdSystemStatus.failedEventWebhookStatus || [];
                const pending = createdSystemStatus.pendingEventWebhookStatus || [];
                const allStatuses = [...success, ...falied, ...pending];
                const nextCrateSystemPageAliasesArray = window.clientConfig.NX_CREATE_SYSTEM_NEXT_PAGE_ALIAS.split(',');
                const nextPageAlias = allStatuses.find((aliasStatus) => nextCrateSystemPageAliasesArray.includes(aliasStatus.alias));
                if (!nextPageAlias) return true;
                return nextPageAlias.statusDetails.status !== WebhookResponseStatus.pendingForPreviousAliasToFinish;
            })();
            if (!statusOkToMoveToDrawer) {
                return;
            }
            pageData.setShowCreateDialog(false);
            setShowAddMobileDialog(false);
            if (createdSystemAppData.data.systemTypeKey !== 'MobileWeb' || ShouldShowAssociatedSystems) {
                setSelectedSystem(createdSystemAppData.data);
                navigate({
                    pathname: `/systems/all-systems/${createdSystemAppData.data.id}/environments`,
                });
            }
            setNewSystemIds([...newSystemIds, createdSystemAppData.data.id]);
            setOpenSuccessSnackbar(
                t('systems-tab.all-systems-page.success-message-systems-created', { displayName: createdSystemAppData.data.displayName })
            );
            dispatch(systemsSlice.actions.cleanupCreatedSystem());
        } else if (createdSystemAppData.error) {
            setOpenErrorSnackbar(
                createdSystemAppData.error === 'Forbidden resource' ? t('common.permission-error-text') : createdSystemAppData.error
            );
            dispatch(systemsSlice.actions.cleanupCreatedSystem());
        }
    }, [systemsCreationStatus, createdSystemAppData, newSystemIds]);

    const triggerSystemDetailsDialog = useCallback(() => {
        return (
            <StyledDialog
                view='sideScreen'
                children={
                    <SystemDetails
                        systemStatusIndicator={
                            <SystemStatusIndicator
                                system={selectedSystem}
                                createdSystemStatus={systemsCreationStatus[selectedSystem.guid]}
                            />
                        }
                        isAdmin={userPermissions('Management', 'Write')}
                        system={selectedSystem}
                        onClose={() => setShowDialog(false)}
                        showDrawer={showDialog}
                    />
                }
                onClose={() => setShowDialog(false)}
                showDialog={showDialog}
            />
        );
    }, [selectedSystem, showDialog, systemsCreationStatus]);

    const triggerSystemCreateFormDialog = () => {
        return (
            <NewSystemForm
                open={pageData.showCreateDialog}
                onClose={() => pageData.setShowCreateDialog(false)}
                createSystem={addSystem}
                loading={createdSystemAppData.loading || (createdSystemAppData.data.id ? true : false)}
            />
        );
    };

    const triggerAdvancedSystemSettingsDialog = () => {
        return (
            <WebSystemAdvancedSettings
                open={showAdvancedSettingsDialog}
                onClose={() => setShowAdvancedSettingsDialog(false)}
                system={selectedSystem}
            />
        );
    };

    const triggerSelfHostedDialog = () => {
        return selfHostedInAdminCenterEnabled ? (
            <SelfHostedPackageDownload
                open={showSelfHostedSettingsDialog}
                onClose={() => setShowSelfHostedSettingsDialog(false)}
                system={selectedSystem}
                mobileWebPlatform={selectedSystem.platforms?.find((platform) => platform.platformName === 'MobileWeb')}
            />
        ) : (
            <SelfHostedDialog open={showSelfHostedSettingsDialog} onClose={() => setShowSelfHostedSettingsDialog(false)} />
        );
    };

    const triggerChangeSystemTypeDialog = () => {
        const availableSystems = pageData.systemsSettingsAppData.data.filter(
            (searchSystem) =>
                searchSystem.name != 'unknown' &&
                searchSystem.name != 'android' &&
                searchSystem.name != 'ios' &&
                searchSystem.showInAdminCenter
        );
        return (
            <ChangeSystemType
                open={showChangeSystemTypeDialog}
                onClose={() => setShowChangeSystemTypeDialog(false)}
                system={selectedSystem}
                availableSystems={availableSystems}
                hasDeployables={systemsWithEnvs.some((i) => i.id === selectedSystem.id && i.deployables)}
            />
        );
    };

    const onColumnVisible = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);

    const initialColumnState = {
        state: [
            {
                colId: 'isRestricted',
                sort: 'asc',
                sortIndex: '0',
            },
            {
                colId: 'date-created',
                sort: 'desc',
                sortIndex: '1',
            },
            {
                colId: 'users',
                sort: 'desc',
                sortIndex: '2',
            },
        ],
    };

    const onGridReady = useCallback((event) => {
        setGridApi(event.api);
        try {
            const savedColumnState = { state: JSON.parse(localStorage.getItem('systemsColumnState')) };
            if (savedColumnState) {
                event.api?.columnModel?.applyColumnState(savedColumnState);
            } else {
                event.columnApi.applyColumnState(initialColumnState);
            }
        } catch (e) {
            console.error('Failed to parse JSON', e);
        }
    }, []);

    const onSortChanged = useCallback((event) => {
        const columnState = event.api?.columnModel?.getColumnState();
        localStorage.setItem('systemsColumnState', JSON.stringify(columnState));
    }, []);

    const getRowId = useCallback((params) => params.data.id, []);

    const gridConfig = useMemo(() => {
        return {
            onCellClicked: (event) => navToEditView(event),
            getRowId,
            onColumnVisible: onColumnVisible,
            onFirstDataRendered: onColumnVisible,
            gridOptions: {
                onRowSelected: (params) => {
                    const newSelectedRows = params.api.getSelectedRows();
                    setSelectedRows(newSelectedRows);
                },
            },
            onGridReady,
            onSortChanged,
            getRowStyle: (params) => {
                if (params.data?.restrictedAccess) {
                    return { opacity: 0.5, pointerEvents: 'none' };
                }
            },
        };
    }, []);

    const triggerDeleteSystemsDialog = useCallback(() => {
        const systemsWithPlatforms = systemsToDelete.filter((system) => system.platforms && system.platforms.length > 0);
        const havePlatforms = !!(systemsWithPlatforms.length > 0);
        const totalUsers = systemsToDelete.map((system) => systemsUsers[system.id]).reduce((partialSum, number) => partialSum + number, 0);
        const buttons = [
            {
                iconComponent: <img src={`/assets/icons/icon-empty-info.svg`} width='17px' height='17px' />,
                children: t('systems-tab.all-systems-page.delete-system-form.learn-more-system-deletion'),
                variant: WMButtonVariant.Text,
                style: { marginRight: 'auto', color: '#385FEB', alignSelf: 'center' },
                onClick: () => window.open('https://support.walkme.com/knowledge-base/how-to-delete-a-system'),
            },
            {
                children: t('buttons.cancel'),
                variant: WMButtonVariant.Text,
                style: { marginRight: '30px', color: '#637191' },
                onClick: () => setShowDeleteSystemsDialog(false),
            },
            {
                children: t('buttons.delete'),
                onClick: () => handleDeletion(),
                component: DeleteItemsButton,
                loading: updateSystemsStatusAppData.loading,
            },
        ];
        const title =
            systemsToDelete.length === 1
                ? t('systems-tab.all-systems-page.delete-system-form.title')
                : t('systems-tab.all-systems-page.delete-system-form.title-plural', { amount: systemsToDelete.length.toString() });

        const DialogComponent = havePlatforms ? DeletionDialogWithPlatform : DeletionDialog;

        return (
            <DialogComponent
                ds2
                disableHeaderDivider={true}
                open={showDeleteSystemsDialog}
                onClose={() => setShowDeleteSystemsDialog(false)}
                title={title}
                buttons={buttons}>
                <DialogSubTitle>
                    {systemsToDelete.length === 1
                        ? t('systems-tab.all-systems-page.delete-system-form.dialog')
                        : t('systems-tab.all-systems-page.delete-system-form.dialog-plural')}
                </DialogSubTitle>
                <Box>
                    <DialogText>
                        {'\u2022'} {t('systems-tab.all-systems-page.delete-system-form.dialog-text-1', { totalUsers })}
                    </DialogText>
                    <DialogText>
                        {'\u2022'} {t('systems-tab.all-systems-page.delete-system-form.dialog-text-2')}{' '}
                    </DialogText>
                    <DialogText>
                        {'\u2022'} {t('systems-tab.all-systems-page.delete-system-form.dialog-text-3')}{' '}
                    </DialogText>
                    {havePlatforms
                        ? generateTextBox(
                              '#F7B500',
                              '#FEF8E8',
                              <Typography>
                                  {t('systems-tab.all-systems-page.delete-system-form.have-platfrom-text-1')}
                                  {
                                      <span style={{ fontWeight: 600 }}>
                                          {' '}
                                          {t('systems-tab.all-systems-page.delete-system-form.have-platfrom-text-2')}
                                      </span>
                                  }
                              </Typography>,
                              <img
                                  src={`/assets/icons/icon-error-triangle.svg`}
                                  width='20px'
                                  height='20px'
                                  style={{ paddingRight: '12px' }}
                              />
                          )
                        : null}
                    {generateTextBox(
                        '#3B61EB',
                        '#EDF1FD',
                        <Typography>
                            {t('systems-tab.all-systems-page.delete-system-form.text-box-1')}
                            {<span style={{ fontWeight: 600 }}> {t('systems-tab.all-systems-page.delete-system-form.text-box-days')}</span>}
                            {t('systems-tab.all-systems-page.delete-system-form.text-box-2')}
                        </Typography>,
                        <WMIconWarning color={'#6C89F0'} size={20} style={{ flexShrink: 0, paddingRight: '12px' }} />
                    )}
                </Box>
            </DialogComponent>
        );
    }, [showDeleteSystemsDialog, systemsToDelete, updateSystemsStatusAppData]);

    return (
        <>
            <WMSnackbar
                open={openSuccessSnackbar ? true : false}
                onClose={handleSnackBarSuccessClose}
                variant={WMSnackbarVariant.Success}
                message={openSuccessSnackbar}
            />

            <WMSnackbar
                open={openWarningSnackbar ? true : false}
                onClose={handleSnackBarWarningClose}
                variant={WMSnackbarVariant.Warning}
                message={openWarningSnackbar}
            />

            <WMSnackbar
                open={openErrorSnackbar ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={openErrorSnackbar}
            />

            {triggerDeleteSystemsDialog()}
            {showDialog && triggerSystemDetailsDialog()}
            {pageData.showCreateDialog && triggerSystemCreateFormDialog()}
            {showAdvancedSettingsDialog && triggerAdvancedSystemSettingsDialog()}
            {showSelfHostedSettingsDialog && triggerSelfHostedDialog()}
            {showChangeSystemTypeDialog && triggerChangeSystemTypeDialog()}
            {showAddMobileDialog && addMobileDialog()}

            <StyledWMDataGrid
                title={<StyledGridTitle> {t('systems-tab.all-systems-page.title')} </StyledGridTitle>}
                locale={datagridLocale}
                rows={systemsRows}
                columnDefs={headCells}
                hideExport
                entityName={t('systems-tab.systems-table.entity-name')}
                gridConfig={gridConfig}
                loadingData={(usersAppData.loading || systemsDeployablesCountAppData.loading, loadingData)}
                columnPickerOptions={{
                    persistColumnIds: [
                        'name',
                        'displayName',
                        'settings',
                        'systemTypeKey',
                        'regexes', // Defined here so it won't be visible in column picker even though it's not a visible column
                        'custom-name',
                    ],
                }}
                supressResetOnNewData={true}
                clearRowSelection={cleanupRowsSelection}
            />
            {selectedRows.length > 0 && (
                <MultipleSelectionFooter
                    onCancel={() => {
                        gridApi.deselectAll();
                        setSelectedRows([]);
                    }}
                    numSelectedRows={selectedRows.length}
                    entityName={t('common.system')}
                    getSelectedRows={() => selectedRows}
                    deleteButton={deleteButton}
                />
            )}
        </>
    );
};
