import { Button, styled } from '@material-ui/core';
import { PartialSystemData, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import { Tooltip } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import React, { createContext, useState } from 'react';
import { URLVerifierDialog } from './url-verifier-dialog';
import { EnvironmentURLQueryResult } from './values';
import { deepClone } from '@datadog/browser-core';

interface URLVerifierProps {
    systemData: PartialSystemData;
    selectedMatcher?: SystemMatcher;
    onOpenExtensionSettings: (environmentURLVerifierResult: EnvironmentURLQueryResult) => void;
}

const StyledButton = styled(Button)({
    textTransform: 'none',
    cursor: 'pointer',
});

interface IContext {
    onOpenExtensionSettings: (environmentURLVerifierResult: EnvironmentURLQueryResult) => void;
}

const OnOpenExtensionSettingsContext = createContext<IContext>({
    onOpenExtensionSettings: () => {},
});

const cloneSystemDataWithSelectedMatcher = (systemData: PartialSystemData, selectedMatcher?: SystemMatcher) => {
    const clonedSystemData = deepClone(systemData);
    const { matchers } = clonedSystemData;

    if (!selectedMatcher) {
        return clonedSystemData;
    }

    for (const envId of Object.keys(matchers)) {
        matchers[envId] = clonedSystemData.matchers[envId].map((matcher: SystemMatcher) => {
            if (matcher._id === selectedMatcher._id) {
                return selectedMatcher;
            }

            return matcher;
        });
    }

    return clonedSystemData;
};

const URLVerifier: React.FC<URLVerifierProps> = ({ systemData, selectedMatcher, onOpenExtensionSettings }) => {
    const { t } = useTranslation('general');
    const [openUrlVerifier, setOpenUrlVerifier] = useState(false);

    const clonedSystemData = cloneSystemDataWithSelectedMatcher(systemData, selectedMatcher);

    return (
        <OnOpenExtensionSettingsContext.Provider value={{ onOpenExtensionSettings }}>
            <Tooltip
                title={
                    <>
                        <div style={{ fontWeight: 600, marginBottom: '5px' }}>
                            {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.tooltip-title`)}
                        </div>
                        <div>{t(`systems-tab.all-systems-page.extension-page.open-url-verifier.tooltip-text`)}</div>
                    </>
                }>
                <StyledButton color='primary' onClick={() => setOpenUrlVerifier(true)}>
                    <img src={`/assets/icons/icon-url-verifier.svg`} style={{ marginRight: '5px' }} />
                    {t('systems-tab.all-systems-page.extension-page.open-url-verifier.button-text')}
                </StyledButton>
            </Tooltip>
            {openUrlVerifier ? <URLVerifierDialog onCloseDialog={() => setOpenUrlVerifier(false)} systemData={clonedSystemData} /> : null}
        </OnOpenExtensionSettingsContext.Provider>
    );
};

export { URLVerifier, OnOpenExtensionSettingsContext };
