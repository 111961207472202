import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState, getLoggedInUser } from '../redux/account.slice';
import get from 'lodash/get';

export const useLoggedInUser = () => {
    const loggedInUserAppData = useSelector((state: { accountState: AccountState }) => state.accountState.loggedInUser);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLoggedInUser());
    }, [dispatch]);

    const accountFeatureEnabled = useCallback<(feature: string) => boolean>(
        (feature) => {
            const accountFeatures = get(loggedInUserAppData, ['data', 'account', 'features', 'names']);
            return accountFeatures ? accountFeatures.split(/[\s,]+/).includes(feature) : false;
        },
        [loggedInUserAppData]
    );

    const userPermissions = useCallback<(actionKey: string, requiredPermission?: string) => boolean>(
        (actionKey, requiredPermission) => {
            const userPermissions = get(loggedInUserAppData, ['data', 'role', 'permissions']);
            if (!userPermissions) return false;
            const requestedActionKey = userPermissions.find((permission) => permission.actionKey === actionKey);
            if (requestedActionKey && !requiredPermission) return true;
            return requestedActionKey ? requestedActionKey.permission === requiredPermission : false;
        },
        [loggedInUserAppData]
    );

    return {
        loggedInUserAppData,
        accountFeatureEnabled,
        userPermissions,
    };
};

export default useLoggedInUser;
