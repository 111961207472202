import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { PageTitle } from '@walkme-admin-center/libs/shared-styles';
import { WMButton, WMIconPublish, WMTextField } from '@walkme/wm-ui';
import React, { ChangeEvent, useState } from 'react';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

/* eslint-disable-next-line */
export interface OldDashboardProps {}
export const OldDashboard = (props: OldDashboardProps) => {
    const { loggedInUserAppData } = useLoggedInUser();
    const [value, setValue] = useState('');
    const [sent, setSent] = useState(false);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const sendFeedback = (feedback) => {
        const userId = loggedInUserAppData.data.id;
        const userEmail = loggedInUserAppData.data.email;
        datadogLogs.logger.log(
            'Dashboard Feedback - ' + feedback,
            { userId: userId, userEmail: userEmail, logType: 'DashboardFeedback' },
            StatusType.info
        );
        setSent(true);
    };

    return (
        <>
            <PageTitle>Admin Dashboard</PageTitle>
            <Card>
                <CardContent style={{ padding: '32px', height: '60vh' }}>
                    {sent ? (
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            <img src={`/assets/icons/hug-dashboard.svg`} width='120px' height='120px' style={{ marginTop: '48px' }} />
                            <Typography
                                style={{
                                    fontFamily: 'Poppins',
                                    color: '#2F426C',
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    marginTop: '24px',
                                }}>
                                Thank you!
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Proxima nova',
                                    color: '#2F426C',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    marginTop: '8px',
                                }}>
                                We received your feedback.
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Proxima nova',
                                    color: '#2F426C',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    marginBottom: '32px',
                                }}>
                                Stay tuned to see your new Admin Dashboard coming soon.
                            </Typography>
                        </Box>
                    ) : (
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            <img src={`/assets/icons/admin-dashboard.svg`} width='120px' height='120px' style={{ marginTop: '48px' }} />
                            <Typography
                                style={{
                                    fontFamily: 'Poppins',
                                    color: '#2F426C',
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    marginTop: '24px',
                                }}>
                                New Admin Dashboard is coming soon!
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Proxima nova',
                                    color: '#2F426C',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    marginTop: '8px',
                                }}>
                                We’re excited to hear your feedback!
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Proxima nova',
                                    color: '#2F426C',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    marginBottom: '32px',
                                }}>
                                Share your thoughts on what would you like to see in the new Admin Dashboard.
                            </Typography>
                            <WMTextField
                                ds2
                                value={value}
                                onChange={onChange}
                                maxLength={250}
                                showCounter
                                multiline
                                style={{ width: '350px' }}
                                placeholder='Share your thoughts'
                            />
                            <WMButton
                                onClick={() => sendFeedback(value)}
                                iconComponent={<WMIconPublish />}
                                style={{ width: '350px', marginTop: '16px' }}>
                                Send
                            </WMButton>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default OldDashboard;
