import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { AppData } from '@walkme-admin-center/libs/types';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { idpApi } from '@walkme-admin-center/libs/idp-api';
import {
    CustomError,
    ERROR_CONSTS,
    AccountConfig,
    OneId,
    Provider,
    ProviderGroups,
    Providers,
    IdpSystems,
    IdpSystemsFormValue,
} from '../types';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import get from 'lodash/get';
import { CONSTS } from '../../../../../pages/home/sso-configuration/src/lib/common/consts';

export interface SsoConfigurationState {
    providers: AppData<Providers>;
    providersGroups: AppData<ProviderGroups>;
    accountConfig: AppData<AccountConfig>;
    oneId: AppData<OneId>;
    error: CustomError;
}

export const initialSsoConfigurationState: SsoConfigurationState = {
    providers: {
        data: [],
        error: null,
        loading: true,
    },
    providersGroups: {
        data: [],
        error: null,
        loading: true,
    },
    oneId: {
        data: {
            oneIdProvider: null,
        },
        error: null,
        loading: true,
    },
    accountConfig: {
        data: {
            providerId: null,
        },
        error: null,
        loading: true,
    },
    error: {
        showError: false,
        message: '',
        title: '',
    },
};

const ssoConfigurationSlice = createSlice({
    name: 'ssoConfigurationSlice',
    initialState: initialSsoConfigurationState,
    reducers: {
        startGetProviderGroups(state: SsoConfigurationState) {
            state.providersGroups.loading = true;
            return state;
        },
        getProviderGroupsSuccess(state: SsoConfigurationState, action: PayloadAction<ProviderGroups>) {
            state.providersGroups.loading = false;
            state.providersGroups.data = action.payload;
            return state;
        },
        startGetProviders(state: SsoConfigurationState) {
            state.providers.loading = true;
            return state;
        },
        getProvidersSuccess(state: SsoConfigurationState, action: PayloadAction<Providers>) {
            state.providers.loading = false;
            state.providers.data = action.payload;
            return state;
        },
        startGetAccountConfig(state: SsoConfigurationState) {
            state.accountConfig.loading = true;
            return state;
        },
        getAccountConfigSuccess(state: SsoConfigurationState, action: PayloadAction<AccountConfig>) {
            state.accountConfig.loading = false;
            state.oneId.loading = false;
            state.accountConfig.data = action.payload;
            state.oneId.data = { oneIdProvider: null };
            return state;
        },
        setOneIdSuccess(state: SsoConfigurationState, action: PayloadAction<AccountConfig>) {
            state.accountConfig.data = action.payload;
            return state;
        },
        turnOffOneIdSuccess(state: SsoConfigurationState, action: PayloadAction<AccountConfig>) {
            state.accountConfig.data = action.payload;
            return state;
        },
        updateProviderSuccess(state: SsoConfigurationState, action: PayloadAction<Provider>) {
            state.providers.data = state.providers.data.map((provider) => {
                if (provider.id === action.payload.id) {
                    return { ...provider, ...action.payload };
                }
                return provider;
            });
            return state;
        },
        assignSystemsToProviderSuccess(
            state: SsoConfigurationState,
            action: PayloadAction<{ activeSystems: IdpSystems; providerId: number }>
        ) {
            state.providers.data = state.providers.data.map((provider) => {
                if (provider.id === action.payload.providerId) {
                    return { ...provider, systems: action.payload.activeSystems };
                }
                return provider;
            });
            return state;
        },
        createProviderSuccess(state: SsoConfigurationState, action: PayloadAction<any>) {
            state.providers.data = [...state.providers.data, action.payload];
            return state;
        },
        showError(state: SsoConfigurationState, action: PayloadAction<CustomError>) {
            state.error = {
                showError: action.payload.showError,
                title: action.payload.title,
                message: action.payload.message,
                onConfirmText: action.payload.onConfirmText,
            };
            return state;
        },
    },
});

export { ssoConfigurationSlice };

const {
    getProviderGroupsSuccess,
    getProvidersSuccess,
    startGetProviderGroups,
    startGetProviders,
    startGetAccountConfig,
    getAccountConfigSuccess,
    setOneIdSuccess,
    turnOffOneIdSuccess,
    createProviderSuccess,
    updateProviderSuccess,
    assignSystemsToProviderSuccess,
    showError,
} = ssoConfigurationSlice.actions;

export type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

function prepareProvider(provider: Provider): Provider {
    if (provider.config && provider.config.scope) {
        provider.config.scope = provider.config.scope.split(' ').filter(Boolean).join(',');
    }
    if (provider.config.type === CONSTS.SAML) {
        provider.config.clientSecret = provider.config.clientSecret.replace(/\n/gm, ' ');
    }
    return provider;
}

function addTransactionId(): void {
    const wmTransactionId = localStorage.getItem('idp-Wm-Transaction-Id');
    if (!wmTransactionId) {
        localStorage.setItem('idp-Wm-Transaction-Id', uuidv4());
    }
}

export const initData =
    (isImpersonator: boolean): AppThunk =>
    async (dispatch) => {
        try {
            addTransactionId();
            await Promise.all([dispatch(getProviderGroups()), dispatch(getProviders(isImpersonator)), dispatch(getAccountConfig())]);
        } catch (err) {
            dispatch(
                setShowError({
                    showError: true,
                    message: 'Please try to reload the page or try again in a few seconds.',
                    title: 'Something Went Wrong',
                })
            );
        }
    };

export const getProviderGroups = (): AppThunk => async (dispatch) => {
    dispatch(startGetProviderGroups());
    const groups: ProviderGroups = await idpApi.getGroups();
    dispatch(getProviderGroupsSuccess(groups));
};

export const getAccountConfig = (): AppThunk => async (dispatch) => {
    dispatch(startGetAccountConfig());
    const accountConfig = await idpApi.getAccountConfig();
    dispatch(getAccountConfigSuccess(accountConfig));
};

export const setOneId =
    (data: OneId): AppThunk =>
    async (dispatch) => {
        await idpApi.setOneId(data);
        dispatch(setOneIdSuccess(data));
    };

export const turnOffOneId = (): AppThunk => async (dispatch) => {
    await idpApi.turnOffOneId();
    dispatch(turnOffOneIdSuccess({ oneIdProvider: null }));
};

export const getProviders =
    (isImpersonator: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(startGetProviders());
        const list: Providers = [];
        const providers: Providers = await idpApi.getProviders(isImpersonator);
        if (providers && providers.length) {
            providers.forEach((provider) => {
                list.push(prepareProvider(provider));
            });
        }
        dispatch(getProvidersSuccess(list));
    };

export const createProvider =
    (body): AppThunk =>
    async (dispatch) => {
        const response = await idpApi.saveProvider(body);
        const created = prepareProvider(response);
        created.systems = [];
        dispatch(createProviderSuccess(created));
        return created;
    };

export const updateProvider =
    (id: number, body): AppThunk =>
    async (dispatch) => {
        const response = await idpApi.updateProvider(id, body);
        const updated = prepareProvider(response);
        dispatch(updateProviderSuccess(updated));
        return updated;
    };

export const assignSystemToProvider =
    (providerId: number, systems: IdpSystemsFormValue, impersonator: boolean): AppThunk =>
    async (dispatch) => {
        const activeSystems = await idpApi.assignSystemsToProvider(providerId, systems, impersonator);
        dispatch(assignSystemsToProviderSuccess({ activeSystems, providerId }));
    };

export const setShowError =
    (error: CustomError): AppThunk =>
    async (dispatch) => {
        dispatch(
            showError({
                showError: error.showError,
                message: error.message,
                title: get(error, 'title', ERROR_CONSTS.ERROR_TITLE),
                onConfirmText: get(error, 'onConfirmText', ERROR_CONSTS.OK),
            })
        );
    };
