import { Button, IconButton, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const CssIconButton = withStyles({
    root: {
        width: '16px',
        height: '16px',
        padding: 0,
        '&.edit': {
            margin: '0 12px',
        },
        '&.execute': {
            marginRight: '12px',
        },
    },
})(IconButton);

export const DemoButton = styled(Button)`
    border-radius: 40px;
    border: solid 1px rgb(59, 97, 235, 0.2);
    height: 28px;
    padding: 6px 14px 6px 10px;
    background: none;
    font-size: 12px;
    color: #385feb;
    text-transform: none;
    box-shadow: none;

    & .MuiSvgIcon-root {
        font-size: 16px;
    }

    & .MuiButton-startIcon {
        margin: 0 7px 0 0;
    }

    &:hover {
        border: 1px solid #385feb;
        background: none;
        box-shadow: none;
    }
`;
