import React from 'react';
import get from 'lodash/get';
import { CONSTS } from '../../../common/consts';
import { idpApi } from '@walkme-admin-center/libs/idp-api';
import {
    AuthUrlResponse,
    Certificate,
    Fields,
    IdpSystems,
    IdpSystemsFormValue,
    Provider,
    ProviderFormValues,
    ProviderGroup,
    SubmitProvider,
} from '@walkme-admin-center/libs/state-management-sso-configuration';
import { FormApi } from 'final-form';
import { Typography } from '@material-ui/core';

export const openPdfInNewTab = (input: string) => {
    if (input.startsWith('http://') || input.startsWith('https://')) {
        window.open(input, '_blank');
    } else {
        const pureBase64Pdf = input.replace('data:application/pdf;base64,', '');
        const binaryString = window.atob(pureBase64Pdf);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    }
};
export class Base {
    private readonly name;
    private readonly setupGuide;
    private readonly schemaValidation;

    constructor(name: string, schemaValidation: any, setupGuide?) {
        this.setupGuide = setupGuide;
        this.name = name;
        this.schemaValidation = schemaValidation;
    }

    getProtocol(): string {
        return CONSTS.OAUTH;
    }

    getIdpInstructions = (): React.ReactElement => {
        const linkOriginal = (
            <Typography
                component='span'
                onClick={() => openPdfInNewTab(this.setupGuide)}
                style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>
                Set up your {this.name}
            </Typography>
        );
        return (
            <>
                <p style={{ color: '#2F426C8C' }}>
                    {linkOriginal} application according to the instructions <br /> and copy the application properties to the fields below.
                </p>
            </>
        );
    };

    getSchemaValidation = () => {
        return this.schemaValidation;
    };

    handleAuth = async (
        values: ProviderFormValues,
        provider: Provider,
        newCredentials: boolean,
        idpType: ProviderGroup,
        certificate: Certificate
    ): Promise<AuthUrlResponse> => {
        const providerId = get(provider, 'id', null);
        return this.getAuthParams(values, providerId, idpType, newCredentials);
    };

    getAuthParams = async (values: ProviderFormValues, providerId: number, idpType, newCredentials): Promise<AuthUrlResponse> => {
        let res: AuthUrlResponse;
        if (providerId && !newCredentials) {
            res = await idpApi.getAuthUrl(providerId);
        } else {
            res = await idpApi.getAuthUrlForCandidate(values, providerId, idpType.id);
        }
        return res;
    };

    getFields = async (provider: Provider, newCredentials: boolean, candidateId: number) => {
        if (provider && !newCredentials) {
            return idpApi.getProviderFields(provider.id);
        } else {
            return idpApi.getCandidateFields(candidateId);
        }
    };

    prepareFields = (values): Fields => {
        return values.fields;
    };

    prepareProviderBeforeSave = (form: FormApi, provider: Provider, candidateId: number): SubmitProvider => {
        const values = form.getState().values;
        const response = {
            candidateId,
            name: values.name,
            fields: this.prepareFields(values),
            userIdentifier: values.userIdentifier,
        };
        if (candidateId) {
            return response;
        } else {
            const config = {};
            const dirtyFields = Object.keys(form.getState().dirtyFields);
            Object.keys(values.config).forEach((key) => {
                if (dirtyFields.some((dirtyField) => dirtyField === `config.${key}`)) {
                    config[key] = values.config[key];
                }
            });
            return Object.keys(config).length === 0 ? response : { ...response, config };
        }
    };

    prepareSystems = (
        providerId: number,
        providerSystems: IdpSystems,
        assignSystemsForm: IdpSystemsFormValue = null
    ): IdpSystemsFormValue => {
        const result: IdpSystemsFormValue = [];
        providerSystems.forEach(({ guid, envs }) => {
            envs.forEach(({ id: wmEnv, enforceSso }) => {
                result.push({ wmEnv, guid, enforceSso, providerId });
            });
        });
        return assignSystemsForm ? result.concat(assignSystemsForm) : result;
    };
}

export default Base;
