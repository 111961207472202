import styled, { css } from 'styled-components';
import { WMButton, WMCheckbox, WMSelect, WMTooltip } from '@walkme/wm-ui';
import { Box, Link } from '@material-ui/core';

const StyledWMSelect = styled(WMSelect)`
    .wmSelect__control {
        background-color: #f4f6fb;
        min-height: 40px;

        &:hover:not(.wmSelect__control--is-focused) {
            border-color: #93a8f4;
        }

        &.wmSelect__control--is-focused {
            border-color: #385feb;
            background-color: #ffffff;
        }
    }
`;

const StyledWMCheckBox = styled(WMCheckbox)`
    &.WMCheckbox-withLabel {
        margin-right: 0;
        margin-left: 0;

        &.WMCheckbox-checked {
            color: #385feb;

            &.WMCheckbox-disabled {
                color: #c5cad6;
            }

            &:hover {
                color: #2b47ac;
            }
        }
    }
`;

const confirmButtonStyle = css`
    &.WMButton-wmButton {
        height: 40px;
        border-radius: 40px;
        background-color: #385feb;

        &:hover {
            background-color: #2b47ac;
        }

        &:active {
            background-color: #223786;
        }

        &.WMButton-disabled {
            color: #fff;
            background-color: #c5cad6;
            border-color: #c5cad6;
        }

        & .WMButton-icon {
            margin-right: 10px;
        }

        & svg {
            font-size: 16px;
        }
    }
`;

const styledScrollbar = css`
    scrollbar-gutter: stable;
    scrollbar-color: var(--wmGrayMedium);
    scrollbar-width: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;

        &-track {
            background-color: var(--wmWhite);
            border-radius: 4px;
        }

        &-thumb {
            background-color: var(--wmGrayMedium);
            border: 2px solid var(--wmWhite);
            border-radius: 4px;

            &:hover {
                background-color: var(--wmUltraLight);
            }
        }
    }
`;

const cancelButtonStyle = css`
    &.WMButton-wmButton {
        font-weight: 600;
        line-height: 20px;
        color: #637191;

        &:hover {
            color: var(--wmGrayUltraDark);
        }

        &:active {
            color: #233251;
        }

        &.WMButton-disabled {
            color: #c5cad6;
        }
    }
`;

const StyledCancelButton = styled(WMButton)`
    ${cancelButtonStyle}
`;

const StyledWMTooltip = styled(WMTooltip)`
    &.WMTooltip-wmTooltip {
        box-sizing: border-box;
        padding: 6px 12px;
        background-color: #233251;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        max-width: 312px;
    }

    & .WMTooltip-htmlWrapper {
        padding: 0;
    }

    & .WMTooltip-arrow {
        color: #233251;
    }
`;

const StyledAccordionBox = styled(Box)`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.open {
        max-height: 300px;
        overflow: auto;
        animation: hide-scroll 0.3s backwards;
    }

    @keyframes hide-scroll {
        from,
        to {
            overflow: hidden;
        }
    }
`;

const StyledLink = styled(Link).attrs((props) => ({
    underline: 'always',
    target: '_blank',
}))`
    color: #385feb;
    cursor: pointer;

    &:hover {
        color: #2b47ac;
    }

    &:active {
        color: #223786;
    }
`;

export {
    StyledWMSelect,
    StyledWMCheckBox,
    StyledCancelButton,
    StyledWMTooltip,
    StyledAccordionBox,
    StyledLink,
    confirmButtonStyle,
    cancelButtonStyle,
    styledScrollbar,
};
