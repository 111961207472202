import React, { useEffect, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { ActionsGraph } from '../../utils/create-actions-graph';
import { RoleFormValues } from '../../role-form/role-form';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Typography,
    Box,
} from '@material-ui/core';
import { WMTagList, WMTagProps, WMSelect, WMTag, WMTagVariant, WMDivider, WMTooltip, WMIconErrorTriangle } from '@walkme/wm-ui';
import { findPermissionTranslatedDisplayName, actionsColors } from '../../../utils/color-genrator';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { RTFunction, useTranslation } from 'apps/home/src/localization';
import { TFunction } from 'i18next';

const StyledWMIconArrowRight = <img src='/assets/icons/arrow-right.svg' style={{ width: '11px', height: '11px' }} />;
interface ActionNodeProps {
    actionsGraph: ActionsGraph;
    currentValues: RoleFormValues;
    category: string;
    expandAll: boolean;
    isPredefinedRole: boolean;
    onPermissionsChange: (permissions: Record<string, string>) => void;
    flatTree: ActionsGraph;
}

const Accordion = styled(MuiAccordion)`
    width: 100%;
    border: 1px solid #dde5f5;
    box-shadow: none;
    &.MuiAccordion-rounded {
        border-radius: 6px;
    }
    &.Mui-expanded {
        border-color: #6c89f0;
    }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
    background-color: #ffffff;
    flex-direction: row-reverse;
    border-radius: 6px;
    .MuiAccordionSummary-expandIcon {
        transform: rotate(0deg);
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(-90deg);
    }
    #communication-center {
        padding-right: 5px;
        height: 14px;
        margin-left: auto;
    }
    .info-icon {
        width: 14px;
        height: 14px;
    }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
    display: flex;
    flex-direction: column;
    padding: 2px;
`;

const StyledWMTagList = styled(WMTagList)`
    padding-left: 4%;
    padding-bottom: 20px;
    padding-top: 12px;
    flex-wrap: wrap;
    .WMTag-old.WMTag-fullLabel {
        max-width: 130px;
    }
`;

const StyledWMSelect = styled(WMSelect)`
    .wmSelect__menu {
        right: 0px;
        min-width: 210px;
        width: fit-content;
    }
`;

const StyledWMTooltip = styled(WMTooltip)`
    .WMTooltip-htmlWrapper {
        padding: 4px 8px 4px 8px;
    }
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: var(--Color-Semantic-Action-Content-Text-inversed-default, #92a7f4);
    display: flex;
    justify-content: flex-end;
`;

const ActionNode = ({
    actionsGraph,
    currentValues,
    category,
    expandAll,
    isPredefinedRole,
    onPermissionsChange,
    flatTree,
}: ActionNodeProps) => {
    const { t, rt } = useTranslation('general');

    const categoryName = rt(category);

    const [expanded, setExpanded] = useState<boolean>(expandAll);

    const isNewRole = !currentValues.existingRole;

    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll]);

    const { accountFeatureEnabled } = useLoggedInUser();

    const onChange = (permissionRow) => {
        if (permissionRow.value === 'None' && permissionRow.key === 'PublishLib') {
            // TO DO remove this first if bloack after publishAndUpdate migration
            currentValues.permissions['Publish'] = 'Write';
        }
        if (permissionRow.value === 'None') {
            delete currentValues.permissions[permissionRow.key];
            const dependentActions = flatTree.filter((action) => action.dependencyActionKey === permissionRow.key);
            dependentActions.forEach((action) => delete currentValues.permissions[action.key]);
        } else {
            currentValues.permissions[permissionRow.key] = permissionRow.value;
        }
        onPermissionsChange(currentValues.permissions);
    };

    const mapActionGraphToRows = (rt: RTFunction, action) => {
        if (!action || !action.actionPermissions) return;

        const sortOrder = {
            Read: 1,
            Modify: 2,
            Write: 3,
        };

        const sortedActionPermissions = [...action.actionPermissions].sort((a, b) => {
            return sortOrder[a.permission] - sortOrder[b.permission];
        });

        const actionPermissionsRows = [];
        actionPermissionsRows.push({
            label: rt('None'),
            value: 'None',
            key: action.key,
        });

        for (const actionPermission of sortedActionPermissions) {
            if (actionPermission.permissionDisplayName === 'Publish Content & WM Version') {
                continue; // TO DO remove this after PublishAndUpdate permission was removed from roles
            }

            actionPermissionsRows.push({
                label: rt(actionPermission.permissionDisplayName),
                value: actionPermission.permission,
                key: action.key,
            });
        }
        return actionPermissionsRows;
    };

    const findRowValue = (actionsRows) => {
        const actionRow = actionsRows.find((row) => row.value === currentValues.permissions[row.key]);
        if (currentValues.permissions[actionsRows[0].key] === 'PublishAndUpdate') {
            return actionsRows.find((row) => row.value === 'Write'); // TO DO remove this after PublishAndUpdate permission was removed from roles
        }
        return actionRow ? actionRow : actionsRows.find((row) => row.value === 'None');
    };

    const mapPermissionsToLabels = (actionsGraph) => {
        const actionsLabels: WMTagProps[] = [];

        for (const action of actionsGraph) {
            if (action.key === 'Theming' && !accountFeatureEnabled('showThemingPermission')) {
                continue;
            }

            const permissionTranslatedDisplayName = findPermissionTranslatedDisplayName(
                rt,
                action,
                isPredefinedRole ? currentValues.permissions[action.key] : action.permissions[action.permissions.length - 1]
            );
            if (!permissionTranslatedDisplayName) continue;
            const labelText = `${rt(action.displayName)}: ${permissionTranslatedDisplayName}`;
            actionsLabels.push({
                labelText,
                fullLabel: true,
                fitToText: true,
                variant: WMTagVariant[actionsColors[labelText]],
            });
        }
        return actionsLabels;
    };

    const disabled = !actionsGraph ? true : isNewRole ? false : mapPermissionsToLabels(actionsGraph).length === 0;

    const sortedActions = useMemo(
        () => actionsGraph.sort((a, b) => (a.displayPriority || Number.MAX_VALUE) - (b.displayPriority || Number.MAX_VALUE)),
        [actionsGraph]
    );

    const shouldDisableSelect = useCallback(
        (action) => {
            if (action.dependencyActionKey) {
                return (
                    !currentValues.permissions[action.dependencyActionKey] ||
                    currentValues.permissions[action.dependencyActionKey] === 'None'
                );
            }
            return false;
        },
        [currentValues]
    );

    const findDisplayName = (actionKey) => {
        const action = flatTree.find((action) => action.key === actionKey);
        if (action) {
            return action.displayName;
        }
    };

    const tooltipToHtml = (rt: RTFunction, tooltipText) => {
        const callForNotice = rt('Note:');
        const translatedTooltipText = rt(tooltipText);
        const [tooltip, note] = translatedTooltipText.split(callForNotice);
        return (
            <div>
                {tooltip}
                {note && (
                    <div style={{ display: 'flex' }}>
                        <WMIconErrorTriangle size={16} color={'#FFFFFF'} style={{ marginRight: '2px', flexShrink: 0 }} /> {note}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <Accordion expanded={disabled ? false : expanded} onClick={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={StyledWMIconArrowRight} id={categoryName} disabled={disabled}>
                    <img src={`/assets/icons/rolesFormIcons/${category}-Icon.svg`} style={{ padding: '0 5px 0 5px' }} />
                    <Typography>{categoryName}</Typography>
                    {category === 'Communication Center' && (
                        <Box id={'communication-center'}>
                            <StyledWMTooltip
                                arrow
                                title={
                                    <>
                                        <div>{t('users-and-roles-tab.roles.custom-roles-tab.communication-center.tooltip')}</div>
                                        <StyledLink
                                            href='https://support.walkme.com/knowledge-base/roles-and-permissions/#communication-center'
                                            target='_blank'>
                                            {t('users-and-roles-tab.roles.custom-roles-tab.communication-center.learn-more')}
                                        </StyledLink>
                                    </>
                                }>
                                <img src={`/assets/icons/icon-info.svg`} className={'info-icon'} />
                            </StyledWMTooltip>
                        </Box>
                    )}
                </AccordionSummary>
                <WMDivider style={{ margin: 'auto', width: '90%' }} />
                <AccordionDetails onClick={(e) => e.stopPropagation()}>
                    {isPredefinedRole ? (
                        <StyledWMTagList labels={mapPermissionsToLabels(sortedActions)} maxTagsShown={15} />
                    ) : (
                        <Box style={{ padding: '12px 20px 0px 20px' }}>
                            {sortedActions &&
                                sortedActions.map((action) => {
                                    if (action.key === 'Theming' && !accountFeatureEnabled('showThemingPermission')) {
                                        return null;
                                    }
                                    return (
                                        <Box
                                            key={rt(action.displayName)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingBottom: '20px',
                                                justifyContent: 'space-between',
                                            }}>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography>{rt(action.displayName)}</Typography>
                                                {action.tooltipText && (
                                                    <Box style={{ paddingLeft: '5px', height: '14px' }}>
                                                        <StyledWMTooltip title={tooltipToHtml(rt, action.tooltipText)} arrow>
                                                            <img
                                                                src={`/assets/icons/icon-info.svg`}
                                                                style={{ width: '14px', height: '14px' }}
                                                            />
                                                        </StyledWMTooltip>
                                                    </Box>
                                                )}
                                            </Box>
                                            <WMTooltip
                                                title={t(
                                                    'users-and-roles-tab.roles.custom-roles-tab.role-form.permissions-dependency-tooltip'
                                                ).replace('{PERMISSION_NAME_PLACEHOLDER}', rt(findDisplayName(action.dependencyActionKey)))}
                                                arrow
                                                condition={shouldDisableSelect(action)}>
                                                <Box style={{ right: '0px', width: '40%', minWidth: '145px' }}>
                                                    <StyledWMSelect
                                                        onChange={(selectedItem) => onChange(selectedItem)}
                                                        onClick={(e) => e.stopPropagation()}
                                                        value={findRowValue(mapActionGraphToRows(rt, action))}
                                                        options={mapActionGraphToRows(rt, action)}
                                                        maxMenuHeight={300}
                                                        isSearchable={false}
                                                        menuPortalTarget={null}
                                                        isDisabled={shouldDisableSelect(action)}
                                                    />
                                                </Box>
                                            </WMTooltip>
                                        </Box>
                                    );
                                })}
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export { ActionNode };
