import React, { useState } from 'react';
import BulkUsersMutation from './bulk-users-mutation';
import { Box, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Users } from 'wm-accounts-sdk';
import { WMIconDone, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { FullScreenDiv } from '../styles/styles';

export const BulkUsersFullScreen = () => {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const wideFormFields = searchParams.get('wideFormFields') && searchParams.get('wideFormFields') === 'true';
    const hideTitle = searchParams.get('hideTitle') && searchParams.get('hideTitle') === 'true';
    const noPadding = searchParams.get('noPadding') && searchParams.get('noPadding') === 'true';
    const showTableIfEmpty = searchParams.get('showTableIfEmpty') && searchParams.get('showTableIfEmpty') === 'true';
    const tableHeight = searchParams.get('tableHeight');
    const backgroundColor = searchParams.get('backgroundColor');
    const hideCancelButton = searchParams.get('hideCancelButton') && searchParams.get('hideCancelButton') === 'true';
    const [usersInvited, setUsersInvited] = useState('');

    const handleInvited = (successMessage: string, createdUsers: Users) => {
        setUsersInvited(successMessage);
        try {
            window.parent && window.parent.postMessage('InviteUsersEvent', '*');
        } catch (e) {
            console.log(`event error`, e);
        }
    };

    const handleCancel = () => {
        try {
            window.parent && window.parent.postMessage('CancelInviteUsersEvent', '*');
        } catch (e) {
            console.log(`event error`, e);
        }
    };

    const handleLoaded = () => {
        try {
            window.parent && window.parent.postMessage('LoadedInviteUsersEvent', '*');
        } catch (e) {
            console.log(`event error`, e);
        }
    };

    const handleSnackBarUserCreationClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setUsersInvited('');
    };

    return (
        <FullScreenDiv style={{ backgroundColor: backgroundColor || 'white' }}>
            <BulkUsersMutation
                showError={(error: string) => {}}
                fullScreen={true}
                hideCancelButton={hideCancelButton || false}
                tableStyles={tableHeight ? { minHeight: tableHeight, maxHeight: tableHeight } : {}}
                showTableIfEmpty={showTableIfEmpty || false}
                noPadding={noPadding || false}
                hideTitle={hideTitle ? true : false}
                wideFormFields={wideFormFields || false}
                onFinish={handleInvited}
                onCancel={handleCancel}
                onLoaded={handleLoaded}
                onRemoveEmail={() => {}}
            />
            <WMSnackbar
                open={usersInvited ? true : false}
                onClose={handleSnackBarUserCreationClose}
                variant={WMSnackbarVariant.Success}
                message={usersInvited}
            />
        </FullScreenDiv>
    );
};

export default BulkUsersFullScreen;
