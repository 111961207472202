export interface UpdatedSystemEnvironment {
    name: string;
    action: 'created' | 'renamed';
}

export interface DeletedSystemEnvironment {
    envId: number;
    action: 'deleted';
}

export interface RestoredSystemEnvironment {
    envId: number;
    action: 'restored';
}

export interface CreateSystemEnvironmentDto {
    envName: string;
    userId: number;
    isDev: boolean;
    systemId: number;
}

export interface DeleteSystemEnvironmentDto {
    envId: number;
    systemId: number;
}

export interface RestoreSystemEnvironmentDto {
    envId: number;
    systemId: number;
}

export interface UpdateSystemEnvironmentDto {
    isDev: boolean;
    userId: number;
    envId: number;
    envName: string;
    systemId: number;
}
