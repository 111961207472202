import Base from './base';
import { IdpSystemsFormValue, Provider, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import { oAuth2adfsSettingsSchema } from '../validation';
import { sharedFields } from '../shared-fields';

const adfsPdf = require('../../../common/pdfFiles/idp_adfs_instructions.pdf');

export class Adfs extends Base {
    constructor() {
        super('ADFS', oAuth2adfsSettingsSchema, adfsPdf);
    }

    getFormFields(): Array<any> {
        return [sharedFields.idpName, sharedFields.clientId, sharedFields.idpDomain, sharedFields.enforceSSOIframeFlow];
    }

    initForm(provider: Provider, assignSystemsForm: IdpSystemsFormValue): ProviderFormValues {
        return {
            config: {
                type: this.getProtocol(),
                domain: get(provider, 'config.domain', ''),
                clientId: get(provider, 'config.clientId', ''),
                enforceSSOIframeFlow: get(provider, 'config.enforceSSOIframeFlow', false),
            },
            name: get(provider, 'name', ''),
            fields: get(provider, 'fields', []),
            userIdentifier: get(provider, 'userIdentifier', ''),
            systems: this.prepareSystems(get(provider, 'id', null), get(provider, 'systems', []), assignSystemsForm),
        };
    }
}
