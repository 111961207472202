import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Environment } from 'wm-accounts-sdk';
import moment from 'moment/moment';
import { deleteEnvironment } from '@walkme-admin-center/libs/state-management-extensions';
import { AppThunkDispatch } from '@walkme-admin-center/libs/state-management-users';
import { SelfHostedPackageDownload } from '@walkme-admin-center/self-hosting';
import { MenuAction } from '@walkme/wm-ui';
import { Box } from '@material-ui/core';
import {
    renameEnvironment,
    systemsSlice,
    useSystem,
    useSystems,
    useUpdatedEnvironment,
} from '@walkme-admin-center/libs/state-management-systems';
import { validateEnvironment } from './validation/validate-environment';
import DeleteEnvironment from './components/delete-environment';
import EnvironmentSnippet from './components/environment-snippet';
import useSystemsSelfHosted from '../../../../../libs/state-management-systems/src/lib/hooks/use-systems-selfhosted';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping } from 'apps/home/src/localization';
import { useDatagridLocale } from 'packages/pages/util/locale-utils';
import {
    StyleEditableContent,
    StyledWMDataGrid,
    StyleWMDataGridActionColumn,
    DateText,
    TimeText,
    MultiLineMenuLabel,
} from './all-environments-page.styles';

export const AllEnvironmentsPage = ({ pageData, loadingData }) => {
    const { t, i18n } = useTranslation('general');
    const [showSelfHostedDialog, setShowSelfHostedDialog] = useState(false);
    const [showDeleteEnvironmentDialog, setShowDeleteEnvironmentDialog] = useState(false);
    const [showSnippetDialog, setShowSnippetDialog] = useState(false);
    const [updatedEnvironment, setUpdatedEnvironment] = useState<Environment>(null);
    const [nameChanged, setNameChanged] = useState(false);
    const {
        currentSystemAppData: { data: system },
    } = useSystem(pageData.systemId);
    const { systemsAppData } = useSystems();
    const dispatch = useDispatch();
    const { updatedEnvironmentAppData } = useUpdatedEnvironment();
    const { systemsSelfHostedMap } = useSystemsSelfHosted();

    const systemCreationDate = useMemo(
        () => systemsAppData.data.find((s) => s.id === pageData.systemId)?.creationDate,
        [pageData.systemId, systemsAppData.data]
    );

    const datagridLocale = useDatagridLocale();

    useEffect(() => {
        if (nameChanged && updatedEnvironment) {
            const errors = validateEnvironment(updatedEnvironment, pageData.environments);

            if (errors.length) {
                pageData.setOpenWarningSnackbar(errors.toString());
                setNameChanged(false);
            } else {
                handleRenameEnvironment(updatedEnvironment);
                setNameChanged(false);
            }
        }
    }, [nameChanged]);

    useEffect(() => {
        if (updatedEnvironmentAppData.data.action) {
            pageData.setOpenSuccessSnackbar(`"${updatedEnvironment.name}" saved successfully`);
            (dispatch as AppThunkDispatch)(systemsSlice.actions.cleanupUpdatedEnvironment());
        } else if (updatedEnvironmentAppData.error) {
            pageData.setOpenErrorSnackbar(updatedEnvironmentAppData.error);
            (dispatch as AppThunkDispatch)(systemsSlice.actions.cleanupUpdatedEnvironment());
        }
    }, [updatedEnvironmentAppData]);

    const dateCreatedValueFormatter = (date) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DateText>{moment(date).format('ll')}</DateText>
                <TimeText>{moment(date).format('LT')}</TimeText>
            </div>
        );
    };

    const environmentRows = useMemo(() => {
        if (!pageData.environments) {
            return [];
        }

        return pageData.environments.filter((env) => !env.isDeleted && ![parseInt(window.clientConfig.NX_PREVIEW_ENV_ID)].includes(env.id));
    }, [pageData.environments]);

    const handleDeleteEnvironment = useCallback(
        (envId: number) => {
            (dispatch as AppThunkDispatch)(
                deleteEnvironment({
                    systemId: pageData.systemId,
                    envId,
                })
            );
        },
        [dispatch]
    );

    const handleRenameEnvironment = useCallback(
        (env: Environment) => {
            (dispatch as AppThunkDispatch)(
                renameEnvironment({
                    isDev: env.isDev,
                    envId: env.id,
                    userId: env.userId,
                    envName: env.name,
                    systemId: pageData.systemId,
                })
            );
        },
        [dispatch]
    );

    const handleBlur = (environment: Environment, environmentName: string) => {
        if (environment.name.toLowerCase() !== environmentName) {
            setNameChanged(true);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.currentTarget.blur();
        }
    };

    const handleInput = (environment: Environment, environmentName: string) => {
        if (!environment.name || environment.name.toLowerCase() === environmentName.toLowerCase()) {
            setUpdatedEnvironment(null);
        } else {
            setUpdatedEnvironment({ ...environment, name: environmentName });
        }
    };

    const handleRenameClicked = (environment: Environment) => {
        const span = document.getElementById(`env-name-${environment.id}`);

        setImmediate(() => span.focus());
    };

    const triggerEnvironmentSnippetDialog = () => {
        return (
            <EnvironmentSnippet
                open={showSnippetDialog}
                onClose={() => setShowSnippetDialog(false)}
                generateSnippetCode={pageData.generateSnippetCode}
                environment={pageData.selectedEnvironment}
                systemId={pageData.systemId}
            />
        );
    };

    const triggerDeleteEnvironmentDialog = () => {
        return (
            <DeleteEnvironment
                open={showDeleteEnvironmentDialog}
                onClose={() => setShowDeleteEnvironmentDialog(false)}
                setOpenSuccessSnackbar={pageData.setOpenSuccessSnackbar}
                setOpenErrorSnackbar={pageData.setOpenErrorSnackbar}
                deleteEnvironment={handleDeleteEnvironment}
                envId={pageData.selectedEnvironment.id}
            />
        );
    };

    const triggerSelfHostedDialog = () => {
        return (
            <SelfHostedPackageDownload
                open={showSelfHostedDialog}
                onClose={() => setShowSelfHostedDialog(false)}
                system={system}
                env={{ value: pageData.selectedEnvironment.id, label: pageData.selectedEnvironment.name }}
                onPackageDownloaded={() => {
                    pageData.setOpenSuccessSnackbar('Self-hosted package downloaded');
                    setShowSelfHostedDialog(false);
                }}
                mobileWebPlatform={system.platforms?.find((platform) => platform.platformName === 'MobileWeb')}
            />
        );
    };

    const isSelfHostedVisible = useMemo(() => {
        return !!systemsSelfHostedMap[pageData.systemId];
    }, [pageData.systemId, systemsSelfHostedMap]);

    const actions: MenuAction<Environment>[] = [
        {
            onClick: (data) => {
                handleRenameClicked(data);
            },
            label: 'Rename',
            icon: <img src={`/assets/icons/icon-rename.svg`} width='20px' height='20px' />,
            isVisible: (data) => data.userId !== -1,
        },
        {
            onClick: (data) => {
                pageData.setSelectedEnvironment(data);
                setShowSnippetDialog(true);
            },
            label: 'Show snippet',
            icon: <img src={`/assets/icons/icon-snippet.svg`} width='20px' height='20px' />,
        },
        {
            onClick: (data) => {
                pageData.setSelectedEnvironment(data);
                setShowSelfHostedDialog(true);
            },
            label: (
                <MultiLineMenuLabel>
                    <span>Self Hosting</span>
                    <span>Download package & Edit settings</span>
                </MultiLineMenuLabel>
            ),
            icon: <img src={`/assets/icons/icon-self-hosted.svg`} width='20px' height='20px' />,
            isVisible: () => isSelfHostedVisible,
        },
        {
            onClick: (data) => {
                pageData.setSelectedEnvironment(data);
                setShowDeleteEnvironmentDialog(true);
            },
            label: <span style={{ color: '#EB3232' }}>Delete</span>,
            icon: <img src={`/assets/icons/icon-delete.svg`} width='20px' height='20px' />,
            isVisible: (data) => data.userId !== -1,
        },
    ];

    function getActionColumn<T>(menuActions: MenuAction<T>[]) {
        return {
            colId: 'actions-column',
            pinned: 'right' as boolean | 'left' | 'right',
            suppressSizeToFit: true,
            width: 90,
            sortable: true,
            filter: false,
            cellRenderer: StyleWMDataGridActionColumn,
            cellRendererParams: {
                actions: menuActions,
                icon: <img src={`/assets/icons/menuIcon.svg`} width='16px' height='16px' />,
                menuProps: {
                    classes: { list: 'all-environments-page-action-column-menu-list' },
                    align: 'right',
                },
            },
        };
    }

    const headCells = useMemo(() => {
        moment.locale(momentLocalesMapping[i18n.language]);

        return [
            {
                headerCheckboxSelection: false,
                checkboxSelection: false,
                headerCheckboxSelectionFilteredOnly: false,
                headerName: t('systems-tab.systems-table.name'),
                lockVisible: true,
                pinned: true,
                sortable: true,
                minWidth: 250,
                field: 'name',
                cellRenderer: function (params) {
                    return (
                        <Box fontWeight={600}>
                            <StyleEditableContent
                                className={params.data.userId !== -1 ? 'editable' : ''}
                                id={`env-name-${params.data.id}`}
                                contentEditable={params.data.userId !== -1}
                                onBlur={(event) => handleBlur(params.data, event.currentTarget.innerText)}
                                onInput={(event) => handleInput(params.data, event.currentTarget.innerText)}
                                onKeyPress={handleKeyPress}>
                                {params.value}
                            </StyleEditableContent>
                        </Box>
                    );
                },
            },
            {
                headerName: t('systems-tab.systems-table.date-created'),
                minWidth: 200,
                sortable: true,
                field: 'createdAt',
                flex: 1,
                cellStyle: { display: 'flex', alignItems: 'center' },
                cellRenderer: (params) => dateCreatedValueFormatter(params.data.userId !== -1 ? params.data.createdAt : systemCreationDate),
            },
            {
                headerName: t('systems-tab.systems-table.last-modified'),
                minWidth: 200,
                sortable: true,
                field: 'updatedAt',
                flex: 1,
                cellStyle: { display: 'flex', alignItems: 'center' },
                cellRenderer: (params) => dateCreatedValueFormatter(params.data.userId !== -1 ? params.data.updatedAt : systemCreationDate),
            },
            getActionColumn<Environment>(actions),
        ];
    }, [pageData.environments, systemCreationDate, i18n.language]);

    const onColumnVisible = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);

    const getRowId = useCallback((params) => params.data.id, []);

    const gridConfig = useMemo(() => {
        return {
            getRowId,
            onColumnVisible: onColumnVisible,
            onFirstDataRendered: onColumnVisible,
            suppressCellSelection: true,
            suppressKeyboardEvent: (params) => ['ArrowLeft', 'ArrowRight'].includes(params.event.key),
        };
    }, []);

    return (
        <>
            {showSnippetDialog && triggerEnvironmentSnippetDialog()}
            {showSelfHostedDialog && triggerSelfHostedDialog()}
            {showDeleteEnvironmentDialog && triggerDeleteEnvironmentDialog()}

            <StyledWMDataGrid
                rows={environmentRows}
                columnDefs={headCells}
                hideColumnPicker
                hideExport
                hideSearch
                title=''
                entityName={t('systems-tab.systems-table.environment')}
                gridConfig={gridConfig}
                loadingData={loadingData}
                columnPickerOptions={{ persistColumnIds: ['name', 'settings', 'environmentTypeKey'] }}
                locale={datagridLocale}
            />
        </>
    );
};
