import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';

import { WMButtonVariant } from '@walkme/wm-ui';
import cc from 'classnames';
import moment from 'moment';

import { useDispatch } from 'react-redux';

import styles from './MonthQuarterDatePicker.module.scss';
import { Button } from '../Button/Button';
import { YearPicker } from './YearPicker/YearPicker';
import { DateList } from './DateList/DateList';
import { Popover } from '@material-ui/core';
import useDateRangeOptions, {
    DateRangeOption,
    DateRangeTypeEnum,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/filters/hooks/useDateRangeOptions';
import useFilters from '../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/filters/hooks/useFilters';
import { setDateRangeFilter } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { IDateRange } from '../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/types';

const getSelectedDateRangeOption = (fromDate: string, toDate: string, dateRangeOptions: DateRangeOption[]) => {
    const res = dateRangeOptions.find(({ value }) => fromDate === value.fromDate && toDate === value.toDate)!;
    return res;
};

const getFirstLastAvailableYear = (ranges: DateRangeOption[]) => {
    const lastRange = ranges[ranges.length - 1];
    const firstRange = ranges[0];
    return {
        firstYear: +moment(firstRange.value.fromDate).format('YYYY'),
        lastYear: +moment(lastRange.value.fromDate).format('YYYY'),
    };
};

export interface MonthQuarterDatePickerProps {
    isDisabled?: boolean;
    confirmSelection?: boolean;
    availableDates: IDateRange[];
    hideMonth?: boolean;
    hideQuarter?: boolean;
}

const MonthPicker = ({ isDisabled, availableDates, hideMonth, hideQuarter, confirmSelection = false }: MonthQuarterDatePickerProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const filter = useFilters();
    const dispatch = useDispatch();
    const ranges = useDateRangeOptions(availableDates);

    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeOption>(
        getSelectedDateRangeOption(filter.fromDate, filter.toDate, ranges)
    );

    useEffect(() => {
        setSelectedDateRange(getSelectedDateRangeOption(filter.fromDate, filter.toDate, ranges));
    }, [availableDates, filter, ranges]);

    const [selectedYear, setSelectedYear] = useState(+moment(selectedDateRange?.value?.fromDate).format('YYYY'));

    const [selectedDateRangeType, setSelectedDateRangeType] = useState<DateRangeTypeEnum>(selectedDateRange?.value?.type);

    const dateRangeOptionsToShow = useMemo(
        () => ranges.filter((r) => r.value.type === selectedDateRangeType && moment(r.value.fromDate).year() === selectedYear),
        [ranges, selectedDateRangeType, selectedYear]
    );

    const applyChange = (dateRange: DateRangeOption) => {
        dispatch(
            setDateRangeFilter({
                fromDate: dateRange?.value.fromDate,
                toDate: dateRange?.value.toDate,
            })
        );
        setAnchorEl(null);
    };

    const onSelectDate = (dateRange: DateRangeOption) => {
        setSelectedDateRange(dateRange);
        if (!confirmSelection) {
            applyChange(dateRange);
        }
    };

    const close = () => {
        setSelectedDateRange(getSelectedDateRangeOption(filter.fromDate, filter.toDate, ranges));
        setAnchorEl(null);
    };

    const onOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
        setSelectedDateRangeType(selectedDateRange.value.type);
        setAnchorEl(e.currentTarget);
    };

    const isMonthRangesAvailable = useMemo(
        () => !hideMonth && ranges.some((r) => r.value.type === DateRangeTypeEnum.month),
        [hideMonth, ranges]
    );

    const isQuarterRangesAvailable = useMemo(
        () => !hideQuarter && ranges.some((r) => r.value.type === DateRangeTypeEnum.quarter),
        [hideQuarter, ranges]
    );

    const formattedSelectedDateRange = useMemo(() => {
        const date = moment(selectedDateRange.value.fromDate);
        const formattedMonthYear = date.format('MMM YYYY');
        const firstDayOfMonth = date.clone().startOf('month').format('MMM D');
        const lastDayOfMonth = date.clone().endOf('month').format('MMM D');
        return (
            <>
                <span style={{ fontWeight: 600 }}>{formattedMonthYear} </span>
                <span style={{ color: '#8D97AE' }}>
                    ({firstDayOfMonth} - {lastDayOfMonth})
                </span>
            </>
        );
    }, [selectedDateRange]);
    const isDateChanged =
        selectedDateRange && (selectedDateRange.value.fromDate !== filter.fromDate || selectedDateRange.value.toDate !== filter.toDate);

    return (
        <div className={styles.container}>
            <button
                data-aid='date-range-picker'
                disabled={isDisabled}
                className={cc(styles.button, { [styles.active]: !!anchorEl })}
                onClick={onOpen}>
                <img src={'assets/icons/data-integrations/calendar.svg'} width='20px' height='20px' />
                <span>{formattedSelectedDateRange}</span>
            </button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={close}
                data-aid='date-range-picker-popper'
                PaperProps={{ className: styles.popover }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <div className={styles.content}>
                    <YearPicker dateRangeOptions={ranges} selectedYear={selectedYear} onSelect={setSelectedYear} />
                    <DateList ranges={dateRangeOptionsToShow} selectedRange={selectedDateRange} onSelect={onSelectDate} />
                    {confirmSelection && (
                        <div className={styles.actions}>
                            <Button variant={WMButtonVariant.Text} onClick={close}>
                                Close
                            </Button>
                            <Button onClick={() => applyChange(selectedDateRange)} size='large' disabled={!isDateChanged}>
                                Apply
                            </Button>
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default MonthPicker;
