import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Chat } from '@walkme/chat-ui';
import { AskMeService } from '../../data-integration-form/services/AskMeService';
import { IconButton, withStyles } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { shrinkText } from '../../common/services';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { AiFeatureFlags } from '../../common/consts';

export const Container = styled.div`
    height: 703px;
    width: 410px;
    position: fixed;
    bottom: 17px;
    right: 0px;
    @media (max-height: 776px) {
        height: calc(100% - 56px - 17px);
    }
    & .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        & .sub-wrapper {
            position: relative;
            width: 398px;
            height: calc(100% - 36px);
            border-radius: 20px;
        }
    }
`;

export const MainFrame = styled.div`
    background-color: #f4f6fb;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    & header {
        position: relative;
        min-height: 146px;
        max-height: 146px;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        & .bubble-container {
            height: 100%;
            background-color: rgb(56, 95, 235);
        }
        & .source-name-container {
            position: absolute;
            top: 103px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            & .source-name {
                font-size: 20px;
                font-weight: 600;
                color: #fff;
                font-family: Poppins;
            }
        }
    }
    & .chatUi-wrapper {
        background: linear-gradient(180deg, rgba(200, 211, 249, 0.5) 0%, rgba(200, 211, 249, 0) 100%, #f4f6fb 100%);
        padding-bottom: 20px;
        flex: 1;
    }
`;

export const Bubble = styled.figure`
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 56.93%, hsla(0, 0%, 100%, 0.278) 86.53%);
    border-radius: 50%;
    position: absolute;
    transition: all 0.2s cubic-bezier(0.43, 0.39, 0.07, 0.86);
    z-index: 0;
    margin: 0 !important;

    &.left {
        left: -128px;
        top: -50px;
        height: 352px;
        transform: rotate(-180deg);
        width: 352px;
    }

    &.right {
        right: -330px;
        height: 480px;
        width: 480px;
        top: -255px;
    }
`;

export const CssIconButton = withStyles({
    root: {
        width: '28px',
        height: '28px',
        padding: 0,
        '& .MuiIconButton-label': {
            height: '16px',
            width: '16px',
        },
        '&:hover': {
            background: 'none',
        },
        '&.minimize': {
            position: 'absolute',
            right: '16px',
            top: '20px',
            '&:hover': {
                backgroundColor: 'hsla(0,0%,100%,.15)',
            },
        },
    },
})(IconButton);

export const WalkMeImage = styled.div`
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(47, 61, 83, 0.302);
    height: 64px;
    width: 64px;
    left: 50%;
    top: -16px;
    position: absolute;
    transform: translate(-50%);
    z-index: 1;
    & img {
        position: absolute;
        top: 20px;
        left: 17px;
    }
`;

const DisclaimerText = styled.div`
    padding: 0.5rem;
    background-color: rgb(253 240 204);
    text-align: center;
    font-size: x-small;
    color: rgba(0, 0, 80, 0.75);
    font-weight: 500;
`;

export const ChatWrapper = ({ systemGuid, sourceGuid, name, onClose, msaGuid }) => {
    const { accountFeatureEnabled } = useLoggedInUser();
    const [aiSdk, setAiSdk] = useState(null);
    const [chatId, setChatId] = useState(uuidv4());
    useEffect(() => {
        setAiSdk(
            new AskMeService({
                systemGuid,
                sourceGuid,
                msaGuid,
            })
        );
        return () => {
            setAiSdk(null);
        };
    }, []);

    const reset = () => {
        aiSdk.reset();
        setChatId(uuidv4());
    };

    return (
        <Container>
            <div className={'wrapper'}>
                <div className={'sub-wrapper'}>
                    <WalkMeImage>
                        <img src={'assets/icons/data-integrations/walkme-icon.svg'} />
                    </WalkMeImage>
                    <MainFrame>
                        <header>
                            <div className={'bubble-container'}>
                                <Bubble className={'left'} />
                                <Bubble className={'right'} />
                            </div>
                            <CssIconButton
                                className={'minimize'}
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                size={'small'}
                                onClick={onClose}>
                                <img width={16} height={16} alt={''} src={'assets/icons/data-integrations/minimize-small.svg'} />
                            </CssIconButton>
                            <div className={'source-name-container'}>
                                <span className={'source-name'}>{shrinkText(name, 25)}</span>
                            </div>
                        </header>
                        {!accountFeatureEnabled(AiFeatureFlags.ENABLE_ASK_ME) && (
                            <DisclaimerText>
                                Test your knowledge source's accuracy in this AI Answers demo.<br />
                                The AI Chat is not enabled for your account.
                            </DisclaimerText>
                        )}
                        <div className={'chatUi-wrapper'}>
                            <Chat
                                id={chatId}
                                onSubmitAction={(text, streamChunkUpdateCallback) => aiSdk.ask(text, streamChunkUpdateCallback)}
                                onFeedbackAction={async ({ message, score }) => {
                                    return await aiSdk.sendGeneralFeedBack(message, score, systemGuid);
                                }}
                                onMessageFeedbackAction={async ({ messageId, message, action }) => {
                                    return await aiSdk.sendQuestionFeedBack(messageId, message, action, systemGuid);
                                }}
                                inputProps={{
                                    autofocus: true,
                                    showNewChatButton: true,
                                    onNewChatClick: reset,
                                    hideInputFeedback: false,
                                    placeholder: 'Ask me anything...',
                                }}
                                onSourceClick={(url) => url?.length && window.open(url)}
                            />
                        </div>
                    </MainFrame>
                </div>
            </div>
        </Container>
    );
};

export default ChatWrapper;
