import React from 'react';
import { CloseButtonContainer, FormHeader } from './modal-style';
import {
    DeleteButton,
    ToastContainer,
    WarningFooter,
    WarningHeader,
    WarningModalContainer,
    WarningText,
    CancelButton,
} from './warning-modal-style';
import { CloseIcon } from '../../../../common/consts';
import { ModalToastBody } from '../integration-style';
import { FormTypes } from 'packages/libs/state-mangment-data-integration/src/lib/types';

interface WarningModalProps {
    onClose: () => void;
    handleWarningModel: (flag: boolean) => void;
    integrationType: FormTypes;
}

const WarningModal = ({ onClose, handleWarningModel, integrationType }: WarningModalProps) => {
    return (
        <WarningModalContainer>
            <WarningHeader>
                <FormHeader>Leave {integrationType} Integration Configuration?</FormHeader>
                <CloseButtonContainer onClick={() => handleWarningModel(false)}>
                    <img src={CloseIcon} alt='Close' />
                </CloseButtonContainer>
            </WarningHeader>
            <ToastContainer>
                <ModalToastBody>
                    <WarningText>
                        <div style={{ padding: '4px' }}>All unsaved data will be lost. This cannot be undone.</div>
                    </WarningText>
                </ModalToastBody>
            </ToastContainer>
            <WarningFooter>
                <CancelButton onClick={() => handleWarningModel(false)}>Cancel</CancelButton>
                <DeleteButton onClick={onClose}>Leave</DeleteButton>
            </WarningFooter>
        </WarningModalContainer>
    );
};

export default WarningModal;
