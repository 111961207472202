import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronTop, ChevronBottom } from '@walkme/ui-icons/large';

const CollapsibleGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
    min-height: 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;

        &-track {
            background-color: var(--wmWhite);
            border-radius: 4px;
        }

        &-thumb {
            background-color: var(--wmGrayMedium);
            border: 2px solid var(--wmWhite);
            border-radius: 4px;

            &:hover {
                background-color: var(--wmUltraLight);
            }
        }
    }
`;

const SingleCollapsibleContainer = styled.div`
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #dde5f5;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const SingleCollapsibleHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 14px 14px 30px;
    gap: 12px;
    height: 28px;
    background: #ffffff;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    cursor: pointer;
`;

const SingleCollapsibleContentContainer = styled.div`
    border-top: 1px solid #e4e9fc;
`;

interface CollapsibleItem {
    header: JSX.Element;
    content: JSX.Element;
    height?: string;
}

interface CollapsibleGroupProps {
    openIndex: number;
    handleItemClick: (index: number) => void;
    collapsibleItems: CollapsibleItem[];
}

interface SingleCollapsibleProps {
    item: CollapsibleItem;
    onClick: () => void;
    openState?: boolean;
}

const SingleCollapsible = ({ item, onClick, openState = false }: SingleCollapsibleProps) => {
    return (
        <SingleCollapsibleContainer style={{ flexShrink: !openState ? 0 : 1 }}>
            <SingleCollapsibleHeader
                onClick={() => {
                    onClick();
                }}>
                {item.header}
                <div style={{ display: 'flex' }}>{openState ? <ChevronTop /> : <ChevronBottom />}</div>
            </SingleCollapsibleHeader>
            {openState ? <SingleCollapsibleContentContainer>{item.content}</SingleCollapsibleContentContainer> : null}
        </SingleCollapsibleContainer>
    );
};

const CollapsibleGroup = ({ openIndex, handleItemClick, collapsibleItems }: CollapsibleGroupProps) => {
    return (
        <CollapsibleGroupContainer>
            {collapsibleItems.map((item, index) => (
                <SingleCollapsible key={index} item={item} onClick={() => handleItemClick(index)} openState={openIndex === index} />
            ))}
        </CollapsibleGroupContainer>
    );
};

export default CollapsibleGroup;
