import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    containerSelected: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 24px',
        height: '80px',
        background: '#FFFFFF',
        border: '1px solid #3B61EB',
        borderRadius: '8px',
    },
    containerDefault: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 24px',
        height: '80px',
        background: '#FFFFFF',
        border: '1px solid #DDE5F5',
        borderRadius: '8px',
    },
    loadingOptionsDetailsContainer: {
        paddingLeft: '12px',
        display: 'flex',
        flexDirection: 'column'
    },
    loadingOptionTitle: {
        fontFamily: 'ProximaNova',
        color: '#2F426C',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        fontStyle: 'normal'
    },
    loadingOptionSubtitle: {
        fontFamily: 'ProximaNova',
        color: '#8D97AE',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        fontStyle: 'normal'
    }
}));

export { useStyles };