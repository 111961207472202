import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & .idp-page-title {
      margin: 0 0 8px 0;
    }
  }
`;

export const CreateProviderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & .edit-one-id-icon {
    width: 20px;
    padding-right: 4px;
    filter: invert(41%) sepia(59%) saturate(5852%) hue-rotate(220deg) brightness(94%) contrast(95%);
  }
`;
