import { Box, Typography } from '@material-ui/core';
import { DialogActions, DialogContent, DialogTitle } from '@walkme/ui-core';
import { WMButton, WMButtonVariant, WMDivider } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';
import React, { useContext } from 'react';
import { CounterBadge } from './common';
import { OnOpenExtensionSettingsContext } from './url-verifier';
import { URLVerifierDialogContext } from './url-verifier-dialog';
import { EnvironmentURLQueryResult, URLMatchData } from './values';

interface EnvironmentDialogProps {
    selectedEnvironment: EnvironmentURLQueryResult;
    onCloseDialog: () => void;
}

const StyledWMIconArrowLeft = (onClick: () => void) => (
    <img
        onClick={onClick}
        src='/assets/icons/arrow-right.svg'
        style={{ width: '10px', height: '10px', cursor: 'pointer', transform: 'rotate(180deg)' }}
    />
);

const EnvironmentDialogContent: React.FC<EnvironmentDialogProps> = ({ selectedEnvironment, onCloseDialog: closeDialog }) => {
    if (!selectedEnvironment) {
        return null;
    }

    const { t } = useTranslation('general');
    const { setShowEnvironmentResult } = useContext(URLVerifierDialogContext);
    const { onOpenExtensionSettings } = useContext(OnOpenExtensionSettingsContext);
    const matchingRegexesOrDomainsCount = selectedEnvironment.urlMatchData.filter((domain) => domain.isMatching).length;
    const onOpenExtensionSettingsAndCloseDialog = () => {
        onOpenExtensionSettings(selectedEnvironment);
        closeDialog();
    };

    return (
        <>
            <DialogTitle>
                <EnvironmentNameTitle
                    environmentName={selectedEnvironment.envName}
                    configurationSet={selectedEnvironment.configurationSet}
                />
            </DialogTitle>
            <DialogContent>
                <Box display='flex' flexDirection='row' alignItems='center' marginBottom='8px'>
                    <Typography style={{ color: '#2F426C', fontSize: '14px', fontWeight: 600 }}>
                        {/* TODO: translate the title? */}
                        {selectedEnvironment.isRegex ? 'Regex' : `URL${matchingRegexesOrDomainsCount > 1 ? 's' : ''}`}
                    </Typography>
                    <CounterBadge count={matchingRegexesOrDomainsCount} />
                </Box>
                <DomainsList domains={selectedEnvironment.urlMatchData} />
            </DialogContent>
            <DialogActions>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <WMButton
                        variant={WMButtonVariant.Secondary}
                        onClick={() => setShowEnvironmentResult(false)}
                        style={{ marginRight: '20px', border: 'none' }}>
                        {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.dialog-back-button`)}
                    </WMButton>
                    <WMButton variant={WMButtonVariant.Primary} onClick={onOpenExtensionSettingsAndCloseDialog}>
                        {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.dialog-open-settings-button`)}
                    </WMButton>
                </Box>
            </DialogActions>
        </>
    );
};

const EnvironmentNameTitle: React.FC<{ environmentName: string; configurationSet?: number }> = ({ environmentName, configurationSet }) => {
    const { t } = useTranslation('general');
    const { setShowEnvironmentResult } = useContext(URLVerifierDialogContext);

    return (
        <Box display='flex' flexDirection='column' alignItems='start'>
            <Box display='flex' flexDirection='row' alignItems='center'>
                {StyledWMIconArrowLeft(() => setShowEnvironmentResult(false))}
                <WMDivider orientation='vertical' flexItem style={{ margin: '0 10px 0 10px' }} />
                <Typography style={{ color: '#2F426C', fontSize: '18px', fontWeight: 600 }}>{environmentName}</Typography>
            </Box>
            {configurationSet ? (
                <Typography style={{ fontFamily: 'Proxima nova', fontSize: '14px', fontWeight: 400, color: '#637191' }}>
                    {`${t(`systems-tab.all-systems-page.extension-page.open-url-verifier.configuration-set`)} ${configurationSet}`}
                </Typography>
            ) : null}
        </Box>
    );
};

const DomainsList: React.FC<{ domains: URLMatchData[] }> = ({ domains }) => {
    const isLastDomain = (index: number) => index === domains.length - 1;

    return (
        <Box display='flex' flexDirection='column' alignItems='start'>
            {domains.map((domainData, index) => (
                <React.Fragment key={`${domainData.selfDomainOrRegex}-${index}`}>
                    <Typography
                        style={{
                            fontFamily: 'Proxima nova',
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#2F426C',
                            backgroundColor: domainData.isMatching ? '#D8DFFB' : 'transparent',
                        }}>
                        {domainData.selfDomainOrRegex}
                    </Typography>
                    {!isLastDomain(index) ? <WMDivider flexItem style={{ width: '100%', height: '1px', margin: '6px 0 6px 0' }} /> : null}
                </React.Fragment>
            ))}
        </Box>
    );
};

export { EnvironmentDialogContent };
