import React, { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { SystemsState } from '@walkme-admin-center/libs/state-management-systems';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import {
    CssIntegrationCard,
    DataContainer,
    ExecuteContainer,
    FooterContainer,
    IntegrationName,
    LastExecute,
    SpinnerLoader,
    StatusContainer,
    StatusIcon,
    StatusText,
    StatusDismiss,
    StatusFont,
    SystemAvatar,
    SystemAvatarContainer,
    SystemContainer,
    TopRowContainer,
    CssIntegrationCardTopContainer,
} from './integration-card-styled';
import IntegrationCardActions from './integration-card-actions/integration-card-actions';
import CountUp from 'react-countup';
import { shrinkText } from '../../common/services';
import CustomToolTip from '../../../../../sso-configuration/src/lib/common/components/tool-tip/tool-tip';
import {
    IAskMe,
    JobType,
    RevertStatus,
    ScrapeStatus,
    Status,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import styled, { keyframes } from 'styled-components';
import useSmoothProgress from './use-smooth-progress';
import { sum } from 'lodash';
import { AiFeatureFlags, SECONDS_TO_PROCESS_MB } from '../../common/consts';
import useDelayedMemo from './use-delayed-state';
import {
    CheckmarkIcon,
    CloseIcon,
    DividerIcon,
    ExclamationIcon,
    GuidedAnswerIcon,
    PauseIcon,
    PlayIcon,
    StopIcon,
    SystemIconSmall,
    UniversalIconSmall,
} from './icons/icons';
import { DemoButton } from './integration-card-actions/integration-card-actions-styled';
import { Box, Fade, IconButton } from '@material-ui/core';
import { pauseScan, resumeScan, stopScan, useGlobalSettings } from '@walkme-admin-center/libs/state-mangment-data-integration';
import IdpDialog from 'packages/pages/home/sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import { Button } from '@walkme/ui-core';
import { useDismissedScanMsg, useDismissedRevertFailedScanMsg } from './use-dismissed-scan-msg';
import { Spacer, ZStack } from '../../common/style';
import { WarningIcon } from '../../data-integration-form/data-integrations-form-manager/warning-icon';
import TruncatedText from '../../common/components/truncated-text';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

const maxSystemBadges = 3;

const CardWebpageType = styled.div`
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    background: #f3ebfa;
    height: 1.5rem;
    padding: 0 0.5rem;
    align-items: center;
    gap: 0.25rem;
`;

const CardFileType = styled(CardWebpageType)`
    background: #fdf0cc;
`;

const CardGuidedAnswersType = styled(CardWebpageType)`
    background: var(--LS-Color-Tag-bg-primary-default, rgba(56, 95, 235, 0.14));
`;

const CardTypeText = styled.p`
    color: #2f426c;
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.5rem;
`;

const CardBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const RightHeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
`;

const FadeContainer = styled.div<{ height?: string | number }>`
    position: relative;
    height: ${(p) => p.height ?? 'auto'};
`;

const FadeElementContainer = styled.div<{ width?: string | number }>`
    position: absolute;
    width: ${(p) => p.width ?? '100%'};
`;

export interface IIntegrationCard {
    integration: IAskMe;
    editIntegration: (integration: IAskMe) => void;
    editSourceSettings: (integration: IAskMe) => void;
    editGuidedAnswers: (integration: IAskMe) => void;
    showScanSummary: (integration: IAskMe) => void;
    isChecked: boolean;
    isChatOpen: boolean;
    updateCheckedList: (sourceGuid: string, checked: boolean) => void;
    openChat: (integration: IAskMe) => void;
}

const FILE_SCAN_TIMEOUT_RATIO = 3;
const FILE_SCAN_TIMEOUT_MINUTES_MINIMUM = 10;

const isScanTimeout = (integration: IAskMe): boolean => {
    const { jobStatus } = integration;

    if (['inProgress', 'pending'].includes(jobStatus.status) && integration.files?.length && integration.jobStatus?.runningCrawl) {
        const totalBytes = sum(integration.files.map((file) => file?.metadata?.size ?? 0));
        const totalSeconds = SECONDS_TO_PROCESS_MB * Math.max(1, totalBytes / 1024 / 1024);
        const passedSeconds = (Date.now() - new Date(integration.jobStatus.runningCrawl).getTime()) / 1000;

        return passedSeconds > Math.max(FILE_SCAN_TIMEOUT_MINUTES_MINIMUM * 60, totalSeconds * FILE_SCAN_TIMEOUT_RATIO);
    }

    return false;
};

const isDateInLastNDays = (dateString: string, days: number): boolean => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= days;
};

const slideLoading = keyframes`
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
`;

const LoadingBackground = styled.div<{ paused?: boolean }>`
    display: flex;
    position: relative;
    height: 0.25rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.4375rem;
    background: ${(p) =>
        p.paused
            ? `var(--LS-Color-Action-Bg-On-light-Extra-disable, rgba(118, 118, 118, 0.09));`
            : `var(--LS-Color-Bg-layout-panel, #D7DFFB)`};
    align-items: center;
    align-self: stretch;
    transition: background-color 0.3s ease;
`;

const LoadingForeground = styled.div`
    height: 0.25rem;
    background: var(--LS-Color-Bg-accent, #385feb);
`;

const LoadingForegroundProgress = styled(LoadingForeground)<{ progress: number }>`
    transition: width 1s ease;
    width: ${(p) => `${p.progress}%`};
`;

const LoadingForegroundRepeating = styled(LoadingForeground)<{ paused?: boolean }>`
    width: 2.3125rem;
    padding: 0.625rem;
    position: absolute;
    animation: ${slideLoading} 2s linear infinite;
    animation-play-state: ${(p) => (p.paused ? 'paused' : 'running')};
    transition: background-color 0.3s ease;
    ${(p) => p.paused && `background: var(--LS-Color-Text-On-light-bg-white-disable, #767676)`};
`;

const LoadingHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 1.75006rem;
`;

const LoadingControlsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const LoadingText = styled.div<{ color?: string }>`
    color: ${(p) => p.color ?? `var(--LS-Color-Tag-content-secondary, #637191)`};
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    width: 1rem;
    white-space: nowrap;
`;

const textSwitch = keyframes`
    0%, 25% {
        opacity: 0;
    }
    35%, 65% {
        opacity: 1;
    }
    75%, 100% {
        opacity: 0;
    }
`;

const FlickeringText = styled.span<{ delay?: number }>`
    animation: ${textSwitch} 8s infinite;
    animation-delay: ${(props) => props.delay ?? 0}s;
    opacity: 0;
`;

const LoadingControlsButton = styled(IconButton)`
    border: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #e3e9fc);
    transition: background-color 0.3s ease, border 0.3s ease;
    padding: 0.25rem;
    width: 1.6rem;
    height: 1.6rem;

    svg [stroke] {
        stroke: #6d81a6;
        transition: stroke 0.3s ease;
    }
    &:hover {
        border: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #92a7f4);
        background: #f0f3f9;
        svg [stroke] {
            stroke: #385feb;
        }
    }
`;

const LoadingControlsDangerButton = styled(LoadingControlsButton)`
    &:hover {
        border: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #fcdede);
        background: #fef3f3;
        svg [stroke] {
            stroke: #e91616;
        }
    }
`;

const ExecutionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    align-self: stretch;
`;

const SourceNotificationActions = styled.div`
    display: flex;
    justify-content: right;
    flex: 1 0 0;
    gap: 0.375rem;
    align-items: center;
`;

const ExecuteProgress = ({ integration, percentage }: { integration: IAskMe; percentage?: number }) => {
    const smoothPercentage = useSmoothProgress(integration);
    const viewPercentage = percentage ?? smoothPercentage;

    return (
        <>
            <ExecutionContainer>
                <LoadingHeaderContainer>
                    <LoadingText>
                        <CountUp preserveValue={true} end={viewPercentage} suffix='%' />
                    </LoadingText>
                </LoadingHeaderContainer>
                <LoadingBackground>
                    <LoadingForegroundProgress progress={viewPercentage} />
                </LoadingBackground>
            </ExecutionContainer>
        </>
    );
};

const ExecuteLoading = ({ integration }: { integration: IAskMe }) => {
    const [confirmData, setConfirmData] = useState({
        showConfirm: false,
        content: null,
        confirm: null,
        title: '',
        buttonText: '',
        type: '',
    });

    const [pauseLoading, setPauseLoading] = useState(false);
    const [resumeLoading, setResumeLoading] = useState(false);
    const dispatch = useDispatch();

    const isInitializing = integration.jobStatus?.jobStatus === ScrapeStatus.INIT_SCAN;
    const isFinalizing = integration.jobStatus?.jobStatus === ScrapeStatus.PUBLISHING;
    const isPaused = integration.jobStatus?.jobStatus === ScrapeStatus.PAUSE;
    const controlsDisabled = pauseLoading || resumeLoading || isInitializing || isFinalizing;

    const pause = async (): Promise<void> => {
        setPauseLoading(true);
        try {
            await dispatch(pauseScan(integration.sourceId));
        } finally {
            setPauseLoading(false);
        }
    };

    const resume = async (): Promise<void> => {
        setResumeLoading(true);
        try {
            await dispatch(resumeScan(integration.sourceId));
        } finally {
            setResumeLoading(false);
        }
    };

    const stop = () => {
        setConfirmData({
            showConfirm: true,
            buttonText: 'Stop Scan',
            title: 'Stop Scan?',
            type: 'errorButton',
            content: `Are you sure you want to stop scanning '${shrinkText(integration.name, 45)}'?`,
            confirm: async (event) => {
                await dispatch(stopScan(integration.sourceId));
                setConfirmData({ ...confirmData, showConfirm: false });
            },
        });
    };

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={confirmData.showConfirm}
                handleClose={() => setConfirmData({ ...confirmData, showConfirm: false })}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={confirmData.type || 'defaultButton'}
                buttonText={confirmData.buttonText || 'I understand, continue'}
                cancelButtonText={'Cancel'}
            />
        );
    }, [confirmData]);

    return (
        <>
            {confirmData.showConfirm && triggerConfirm()}
            <ExecutionContainer>
                <LoadingHeaderContainer>
                    <LoadingText>
                        <ZStack>
                            <FlickeringText>
                                {isPaused
                                    ? `Scan Paused`
                                    : isInitializing
                                    ? `Initializing...`
                                    : isFinalizing
                                    ? `Finalizing...`
                                    : `Scan in progress...`}
                            </FlickeringText>
                            <FlickeringText delay={4}>Scanned: {integration?.jobStatus?.crawl || 0}</FlickeringText>
                        </ZStack>
                    </LoadingText>
                    <LoadingControlsContainer>
                        {isPaused ? (
                            <CustomToolTip title='Resume scan' placement={'top'} arrow={true}>
                                <LoadingControlsButton disabled={controlsDisabled} onClick={resume}>
                                    {resumeLoading ? <SpinnerLoader /> : <PlayIcon />}
                                </LoadingControlsButton>
                            </CustomToolTip>
                        ) : (
                            <CustomToolTip title='Pause scan' placement={'top'} arrow={true}>
                                <LoadingControlsButton disabled={controlsDisabled} onClick={pause}>
                                    {pauseLoading ? <SpinnerLoader /> : <PauseIcon />}
                                </LoadingControlsButton>
                            </CustomToolTip>
                        )}
                        <CustomToolTip title='Stop scan' placement={'top'} arrow={true}>
                            <LoadingControlsDangerButton disabled={controlsDisabled} onClick={stop}>
                                <StopIcon />
                            </LoadingControlsDangerButton>
                        </CustomToolTip>
                    </LoadingControlsContainer>
                </LoadingHeaderContainer>
                <LoadingBackground paused={isPaused}>
                    <LoadingForegroundRepeating paused={isPaused} />
                </LoadingBackground>
            </ExecutionContainer>
        </>
    );
};

const SubIconContainer = styled.div`
    display: flex;
    background: white;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(-35%, -35%);
    border-radius: 50%;
`;

const UniversalSystemIconSmall = () => {
    return (
        <>
            <SystemIconSmall />
            <SubIconContainer>
                <UniversalIconSmall />
            </SubIconContainer>
        </>
    );
};

const RevertMsgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 0;
    align-self: stretch;
    gap: 0.75rem;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    border-top: 1px solid var(--Color-Notifications-alerts-stroke-warning, #f7b500);
    background: var(--Warning-Warning-tint-7, #fef8e8);
    border-radius: 0rem 0rem 0.5rem 0.5rem;
`;

const RevertMsgText = styled.p`
    margin: 0;
    color: var(--Typography-Typography-MainPrimary-Main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

export const IntegrationCard = ({
    integration,
    editIntegration,
    editSourceSettings,
    editGuidedAnswers,
    showScanSummary,
    isChecked,
    updateCheckedList,
    openChat,
    isChatOpen,
}: IIntegrationCard) => {
    const { accountFeatureEnabled } = useLoggedInUser();
    const { isAdminMode } = useGlobalSettings();
    const [isHover, setIsHover] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const cardRef = useRef(null);

    const { data: systems } = useSelector((state: { systemsState: SystemsState }) => state.systemsState.systems);

    const { jobStatus } = integration;

    const showStopped = jobStatus?.jobStatus === ScrapeStatus.STOP;

    const currentStatus = useMemo(() => {
        return isScanTimeout(integration) ? ('failed' as Status) : jobStatus.status;
    }, [jobStatus, integration]);

    const [percentage, setPercentage] = useState<number | undefined>(undefined);

    const status = useDelayedMemo(currentStatus, 1000, (status) => {
        if (status === 'inProgress' && currentStatus === 'ready') {
            setPercentage(100);
            return true;
        }
        setPercentage(undefined);
        return false;
    });

    const isExecuting = useMemo(() => {
        return ['inProgress', 'pending'].includes(status) && !showStopped;
    }, [status]);

    const getSystems = useCallback(() => {
        return systems.filter((system) => {
            return integration.systems.some((sys) => sys.guid === system.guid);
        });
    }, [systems, integration.systems]);

    const handleMouseHover = (): void => {
        setIsHover(true);
    };

    const handleMouseLeave = (): void => {
        setIsHover(false);
    };

    const [dismissedScanMsg, setDismissedScanMsg] = useDismissedScanMsg(integration);
    const [dismissedScanRevertFailedMsg, setDismissedScanRevertFailedMsg] = useDismissedRevertFailedScanMsg(integration);

    const isDemoDisable = jobStatus.status !== Status.Ready || isChatOpen;

    const [scanMsgActionLoading, setScanMsgActionLoading] = useState(false);

    const openCredsSettings = async () => {
        setScanMsgActionLoading(true);
        try {
            await editSourceSettings(integration);
        } finally {
            setScanMsgActionLoading(false);
        }
    };

    const openScanSummary = async () => {
        setScanMsgActionLoading(true);
        try {
            await showScanSummary(integration);
        } finally {
            setScanMsgActionLoading(false);
        }
    };

    const showMissingCredsError = jobStatus.jobStatus === ScrapeStatus.MISSING_CREDS;
    const showScanCompleteMsg = integration.type === JobType.WEB && status === 'ready' && isDateInLastNDays(integration.lastCrawl, 31);
    const showScanMsg = !dismissedScanMsg && (showMissingCredsError || showScanCompleteMsg);
    const showScanRevertFailedMsg =
        !dismissedScanRevertFailedMsg &&
        isDateInLastNDays(integration.lastCrawl, 31) &&
        integration.jobStatus?.lastRevertStatus === RevertStatus.REVERT_FAILED;

    return (
        <>
            <CssIntegrationCard
                ref={cardRef}
                onMouseEnter={handleMouseHover}
                onMouseLeave={handleMouseLeave}
                isExecuting={isExecuting}
                selected={isSelected}
                warning={showScanRevertFailedMsg}>
                <CssIntegrationCardTopContainer>
                    <TopRowContainer>
                        <CardBadgeContainer>
                            {integration.type === JobType.WEB ? (
                                <CardWebpageType>
                                    <img
                                        src='/assets/icons/data-integrations/integrations-icons/webpage-card.svg'
                                        style={{ width: '1rem', height: '1rem' }}
                                    />
                                    <CardTypeText>Webpages</CardTypeText>
                                </CardWebpageType>
                            ) : (
                                <CardFileType>
                                    <img
                                        src='/assets/icons/data-integrations/integrations-icons/file-card.svg'
                                        style={{ width: '1rem', height: '1rem' }}
                                    />
                                    <CardTypeText>Files</CardTypeText>
                                    {!!integration.settings?.files?.length && (
                                        <CardTypeText>{integration.settings?.files?.length}</CardTypeText>
                                    )}
                                </CardFileType>
                            )}
                            {(isAdminMode || accountFeatureEnabled(AiFeatureFlags.ENABLE_ASK_ME)) && !!integration.guidedAnswersCount && (
                                <CardGuidedAnswersType>
                                    <GuidedAnswerIcon style={{ width: '1rem', height: '1rem' }} />
                                    <CardTypeText>{integration.guidedAnswersCount}</CardTypeText>
                                </CardGuidedAnswersType>
                            )}
                        </CardBadgeContainer>
                        <RightHeaderContainer>
                            <SystemContainer>
                                {integration.isOmni ? (
                                    <SystemAvatarContainer index={maxSystemBadges - 1}>
                                        <CustomToolTip placement={'top'} title='Universal System'>
                                            <SystemAvatar>
                                                <UniversalSystemIconSmall />
                                            </SystemAvatar>
                                        </CustomToolTip>
                                    </SystemAvatarContainer>
                                ) : (
                                    <>
                                        {getSystems()
                                            .slice(0, getSystems().length > maxSystemBadges ? maxSystemBadges - 1 : maxSystemBadges)
                                            .map((system, index) => {
                                                return (
                                                    <SystemAvatarContainer
                                                        key={index}
                                                        index={index + maxSystemBadges - Math.min(3, getSystems().length)}>
                                                        <CustomToolTip placement={'top'} title={system.displayName}>
                                                            <SystemAvatar>
                                                                <img src={retrieveSystemIcon(system.settings)} width='13px' height='13px' />
                                                            </SystemAvatar>
                                                        </CustomToolTip>
                                                    </SystemAvatarContainer>
                                                );
                                            })}
                                        {getSystems().length > maxSystemBadges && (
                                            <SystemAvatarContainer index={maxSystemBadges - 1}>
                                                <CustomToolTip
                                                    placement={'top'}
                                                    title={
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}>
                                                            {getSystems()
                                                                .slice(maxSystemBadges - 1, getSystems().length)
                                                                .map((x, index) => {
                                                                    return <span key={index}>{x.displayName}</span>;
                                                                })}
                                                        </div>
                                                    }>
                                                    <SystemAvatar>
                                                        <span
                                                            style={{
                                                                display: 'block',
                                                                fontSize: '0.625rem',
                                                                color: 'var(--LS-Color-Text-On-light-primary, #2F426C)',
                                                            }}>
                                                            +{getSystems().length - maxSystemBadges + 1}
                                                        </span>
                                                    </SystemAvatar>
                                                </CustomToolTip>
                                            </SystemAvatarContainer>
                                        )}
                                    </>
                                )}
                            </SystemContainer>
                            <IntegrationCardActions
                                editIntegration={() => editIntegration(integration)}
                                editSourceSettings={() => editSourceSettings(integration)}
                                editGuidedAnswers={() => editGuidedAnswers(integration)}
                                showScanSummary={() => showScanSummary(integration)}
                                integration={{ ...integration, jobStatus: { ...jobStatus, status } }}
                                isChatOpen={isChatOpen}
                                onMenuChange={setIsSelected}
                            />
                        </RightHeaderContainer>
                    </TopRowContainer>
                    <Box height='12px' />
                    <IntegrationName>
                        <TruncatedText text={integration.name} />
                    </IntegrationName>
                    <LastExecute>
                        {integration.lastCrawl && `Last Updated: ${moment(integration.lastCrawl).format('MMM DD, YYYY')}`}
                        <StopIcon style={{ opacity: 0 }} />
                    </LastExecute>
                </CssIntegrationCardTopContainer>
                <FooterContainer>
                    <ZStack alignItems='start'>
                        <Fade in={isExecuting}>
                            <ExecuteContainer>
                                {integration.type === JobType.WEB ? (
                                    <ExecuteLoading integration={integration} />
                                ) : (
                                    <ExecuteProgress integration={integration} percentage={percentage} />
                                )}
                            </ExecuteContainer>
                        </Fade>
                        <Fade in={!isExecuting && showScanRevertFailedMsg}>
                            <RevertMsgContainer>
                                <WarningIcon />
                                <RevertMsgText>Revert last scan operation failed</RevertMsgText>
                                <Button active variant='text' onClick={() => setDismissedScanRevertFailedMsg('true')}>
                                    <CloseIcon />
                                </Button>
                            </RevertMsgContainer>
                        </Fade>
                        <Fade in={!isExecuting && showScanMsg && !showScanRevertFailedMsg}>
                            <DataContainer>
                                <StatusContainer>
                                    {showMissingCredsError && <ExclamationIcon />}
                                    {showScanCompleteMsg && !showScanRevertFailedMsg && <CheckmarkIcon />}
                                    <StatusText>
                                        {showMissingCredsError && 'Scan failed'}
                                        {showScanCompleteMsg && 'Scan completed'}
                                    </StatusText>
                                </StatusContainer>
                                <SourceNotificationActions>
                                    {!scanMsgActionLoading && (
                                        <>
                                            <Button
                                                active
                                                variant='text'
                                                onClick={() => setDismissedScanMsg('true')}
                                                disabled={scanMsgActionLoading}>
                                                <StatusDismiss>Dismiss</StatusDismiss>
                                            </Button>
                                            <DividerIcon height='1rem' />
                                        </>
                                    )}
                                    <Button
                                        variant='text'
                                        onClick={() => {
                                            if (showMissingCredsError) {
                                                openCredsSettings();
                                            } else if (showScanCompleteMsg) {
                                                openScanSummary();
                                            }
                                        }}
                                        loading={scanMsgActionLoading}
                                        disabled={scanMsgActionLoading}>
                                        <StatusFont>
                                            {showMissingCredsError && 'Update Credentials'}
                                            {showScanCompleteMsg && 'View Summary'}
                                        </StatusFont>
                                    </Button>
                                </SourceNotificationActions>
                            </DataContainer>
                        </Fade>
                        <Fade in={!isExecuting && !showScanMsg && !showScanRevertFailedMsg}>
                            <DataContainer>
                                <StatusContainer className={`${status}`}>
                                    <StatusIcon className={`${status}`} />
                                    <StatusText>{showStopped ? 'cancelled' : status}</StatusText>
                                </StatusContainer>
                                <FadeContainer style={{ display: 'flex', justifyContent: 'right', flex: '1 0 0' }}>
                                    <StatusText>Created: {moment(integration.createdAt).format('MMM DD, YYYY')}</StatusText>
                                    <Fade in={isHover && !isChecked && !isDemoDisable}>
                                        <FadeElementContainer
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'right',
                                                backgroundColor: 'white',
                                                transform: 'translateY(-40%)',
                                            }}>
                                            <DemoButton
                                                onClick={() => openChat(integration)}
                                                startIcon={<img src={'assets/icons/data-integrations/play-icon-small.svg'} />}>
                                                <span>Run Demo</span>
                                            </DemoButton>
                                        </FadeElementContainer>
                                    </Fade>
                                </FadeContainer>
                            </DataContainer>
                        </Fade>
                    </ZStack>
                </FooterContainer>
            </CssIntegrationCard>
        </>
    );
};

export default IntegrationCard;
