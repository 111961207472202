import { Route, Routes } from 'react-router-dom';
import React, { useMemo } from 'react';
import { BulkUsersFullScreen } from '@walkme-admin-center/pages/home/users-roles';
import { LoadingPage } from '@walkme-admin-center/libs/common';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { SystemAutomatedActionsShell } from '@walkme-admin-center/pages/home/systems';

export const ComponentRoutes = () => {
    return (
        <Routes>
            <Route path='/users-full' element={<BulkUsersFullScreen />} />
            <Route path='/systems-requests' element={<SystemAutomatedActionsShell />} />
        </Routes>
    );
};

export default () => {
    const { loggedInUserAppData } = useLoggedInUser();
    if (!loggedInUserAppData.data.id) {
        return <LoadingPage />;
    }
    return <ComponentRoutes />;
};
