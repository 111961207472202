import React, { ReactNode } from 'react';

export class Base {
    public dispatch;
    public showSnackBar;
    public closeForm;
    public changeActiveStep;

    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        this.dispatch = dispatch;
        this.showSnackBar = showSnackBar;
        this.closeForm = closeForm;
        this.changeActiveStep = changeActiveStep;
    }

    getFormLabels(): Array<string> {
        return [];
    }

    getStepperLabels(): Array<string> {
        return this.getFormLabels();
    }

    getTabsLabels(): Array<string> {
        return this.getFormLabels();
    }

    getFormHeaderActions(source, formMode): ReactNode {
        return undefined;
    }
}
