import { retriveInstallAppVideo } from '@walkme-admin-center/libs/common';
import React from 'react';
import { StepContainer, TitleContainer, TitleText, SubtitleText, VideoStepNumber, SmallBoldText } from './slack-steps-styles';
import VideoContainer from './video-container';
const InstallApp = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>6.</VideoStepNumber>Install your app
                </TitleText>
                <SubtitleText>
                    Click <SmallBoldText>Install to Workspace</SmallBoldText> and choose a slack channel to post to. Then click{' '}
                    <SmallBoldText>Allow</SmallBoldText>
                </SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveInstallAppVideo()} />
        </StepContainer>
    );
};

export default InstallApp;
