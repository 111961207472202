import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { GetSystemDataResult, GetSystemsRegexesResultItem, SystemMatcher } from './types';

const WebSystemsRoute = '/extensions/webSystems';
const SystemMatchersRoute = '/extensions/systemMatchers';

class WebSystemsExposureApi {
    private readonly httpClient: WMhttpClient;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
    }

    async getSystemData(systemUserId: number): Promise<GetSystemDataResult> {
        const response = await this.httpClient.instance.post<GetSystemDataResult>(`${WebSystemsRoute}/getSystemData`, { systemUserId });

        return response.data;
    }

    async updateSystem(body: any): Promise<void> {
        await this.httpClient.instance.put<void>(`${WebSystemsRoute}/updateSystem`, body);
    }

    async addExistingSystemToExtension(body: any): Promise<void> {
        await this.httpClient.instance.post<void>(`${WebSystemsRoute}/addExistingSystem`, body);
    }

    async getSelfDomainsForSystem(body: any): Promise<string[]> {
        const response = await this.httpClient.instance.post<string[]>(`${WebSystemsRoute}/getSelfDomainsForSystem`, body);

        return response.data;
    }

    async getSystemsRegexes(): Promise<GetSystemsRegexesResultItem[]> {
        const response = await this.httpClient.instance.post<GetSystemsRegexesResultItem[]>(`${WebSystemsRoute}/getSystemsRegexes`);

        return response.data;
    }

    async getDefaultSystemMatcher(body: any): Promise<SystemMatcher> {
        const response = await this.httpClient.instance.post<SystemMatcher>(`${SystemMatchersRoute}/getDefaultSystemMatcher`, body);

        return response.data;
    }

    async linkSystemToExtension(body: any): Promise<GetSystemDataResult> {
        const response = await this.httpClient.instance.post<GetSystemDataResult>(`${WebSystemsRoute}/linkSystemToExtension`, body);

        return response.data;
    }

    async addSystemMatcher(body: any): Promise<GetSystemDataResult> {
        const response = await this.httpClient.instance.post<GetSystemDataResult>(`${SystemMatchersRoute}/addSystemMatcher`, body);

        return response.data;
    }

    async updateSystemMatcher(body: any): Promise<GetSystemDataResult> {
        const response = await this.httpClient.instance.post<GetSystemDataResult>(`${SystemMatchersRoute}/updateSystemMatcher`, body);

        return response.data;
    }
}

export const webSystemsExposureApi = new WebSystemsExposureApi(wmhttpClientSharedInstance);
