import {
    NoPartnersLabel,
    NoPartnersDiv,
    LoadingContainer,
    StyledPartnersWMDataGrid,
    EmailColumn,
    StatusColumn,
    ActionMenuColumn,
    InvitesTabContainer,
} from './styles/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useMemo, useState } from 'react';
import {
    WMDataGridWrapperProps,
    DataGridModules,
    WMTagList,
    WMTagVariant,
    WMTooltip,
    WMStatusDot,
    WMMenu,
    WMMenuItemShape,
    WMIconHelp,
} from '@walkme/wm-ui';
import { IconButton, Tooltip } from '@walkme/ui-core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColDef } from '@ag-grid-community/core';
import { InviteData, InviteStatus } from 'packages/libs/types/src/lib/partner';
import { Add, Delete, Envelop, Restore } from '@walkme/ui-icons';
import {
    cancelInvite,
    getInvites,
    resendInviteMail,
    validateInvite,
} from 'packages/libs/state-management-partners/src/lib/redux/partners.slice';
import { useDispatch } from 'react-redux';
import { InviteStatusMap } from './utils/partner-status';
import { AddPartner } from './add-partner';
import { LabelWithTooltip } from './components/LabelWithTooltip';
import { ColIds, partnerActionsMenuButtonId } from './partners.lib';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping, timeLocalesMapping } from 'apps/home/src/localization';
import moment from 'moment';

export const InvitesTab = ({ loadingData, pageData }) => {
    const { t, rt, i18n } = useTranslation('general');
    const invites: InviteData[] = pageData;
    const updatedGridModules = DataGridModules.concat([MasterDetailModule as any]);
    const [currentHoveredRow, setCurrentHoveredRow] = useState<number | null>(null);
    const [showAddPartnerScreen, setShowAddPartnerScreen] = useState(false);

    const [dateTimeLocale, setDateTimeLocale] = useState(timeLocalesMapping[i18n.language]);

    useEffect(() => {
        setDateTimeLocale(timeLocalesMapping[i18n.language]);
    }, [i18n.language]);

    const dispatch = useDispatch();

    const formatDate = (date: string): string => {
        return new Date(date).toLocaleDateString(dateTimeLocale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const headerActions = [
        <Tooltip title='Add a new partner'>
            <IconButton
                variant='ghost'
                onClick={() => {
                    setShowAddPartnerScreen(true);
                }}>
                <Add />
            </IconButton>
        </Tooltip>,
    ];

    function onValidateInvite(email: string) {
        validateInvite(dispatch, email);
    }

    function onCancelInvite(email: string): void {
        cancelInvite(dispatch, email);
    }

    function onResendInviteMail(email: string) {
        resendInviteMail(dispatch, email);
    }

    const handleSystemsTooltipMouseLeave = () => {
        setCurrentHoveredRow(null);
    };

    const getInviteStatus = (data: InviteData) => {
        return new Date(data.expiryDate) > new Date()
            ? InviteStatusMap.find((mappedStatus) => mappedStatus.id === InviteStatus.Pending)
            : InviteStatusMap.find((mappedStatus) => mappedStatus.id === InviteStatus.Expired);
    };

    const gridConfig: WMDataGridWrapperProps<any> = useMemo(
        () => ({
            suppressRowHoverHighlight: true,
            onCellMouseOver(event) {
                setCurrentHoveredRow(event.rowIndex);
            },
            onCellMouseOut(event) {
                if (
                    (event.event.target as HTMLImageElement).id !== partnerActionsMenuButtonId &&
                    event.colDef.colId !== ColIds.systemsColId
                ) {
                    setCurrentHoveredRow(null);
                }
            },
            masterDetail: true,
            detailRowHeight: 50,
            detailRowAutoHeight: true,
            modules: updatedGridModules,
            rowHeight: 60,
            class: 'main-table',
            defaultColDef: {
                flex: 1,
                headerClass: 'main-table',
                cellClass: 'main-table',
            },
        }),
        [invites]
    );

    const primaryTableColumns: ColDef[] = [
        {
            headerName: t('common.email'),
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                return (
                    <EmailColumn>
                        <LabelWithTooltip text={data.email} />
                    </EmailColumn>
                );
            },
        },
        {
            headerName: t('common.role'),
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                return <div>{rt(data.role.displayName)}</div>;
            },
        },
        {
            headerName: t('common.systems'),
            cellStyle: { display: 'flex' },
            colId: ColIds.systemsColId,
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                return (
                    <div>
                        <WMTagList
                            onMouseLeave={handleSystemsTooltipMouseLeave}
                            labels={data.assignedSystems.map((system) => ({
                                labelText: system.displayName,
                                fullLabel: true,
                                variant: WMTagVariant.Comet,
                            }))}
                            maxTagsShown={3}
                        />
                    </div>
                );
            },
        },
        {
            headerName: t('common.creation-date'),
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                return <div>{formatDate(data.createdDate)}</div>;
            },
        },
        {
            headerName: t('common.expiry-date'),
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                return <div>{formatDate(data.expiryDate)}</div>;
            },
        },
        {
            headerName: t('common.status'),
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                const mappedStatus = getInviteStatus(data);
                return (
                    <div>
                        <WMTooltip title={t(mappedStatus.info)}>
                            <div>
                                <StatusColumn>
                                    {<WMStatusDot variant={mappedStatus.color} />}
                                    <span className={'userRowStatus'}>{t(mappedStatus.display)}</span>
                                </StatusColumn>
                            </div>
                        </WMTooltip>
                    </div>
                );
            },
        },
        {
            minWidth: 40,
            maxWidth: 40,
            cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
            colId: ColIds.actionMenuColId,
            cellRenderer: (params) => {
                const data: InviteData = params.data;
                const getMenuItems = (data: InviteData): WMMenuItemShape[] => {
                    const mappedStatus = getInviteStatus(data);
                    switch (mappedStatus.id) {
                        case InviteStatus.Expired:
                            return [
                                {
                                    label: t('buttons.resend-invitation'),
                                    startIcon: <Restore />,
                                    onClick: () => onValidateInvite(data.email),
                                },
                                {
                                    label: t('buttons.cancel-invite'),
                                    startIcon: <Delete />,
                                    onClick: () => onCancelInvite(data.email),
                                },
                            ];
                        case InviteStatus.Pending:
                            return [
                                {
                                    label: t('buttons.resend-email'),
                                    startIcon: <Envelop />,
                                    onClick: () => onResendInviteMail(data.email),
                                },
                                {
                                    label: t('buttons.cancel-invite'),
                                    startIcon: <Delete />,
                                    onClick: () => onCancelInvite(data.email),
                                },
                            ];
                        default:
                            return [];
                    }
                };
                return (
                    <ActionMenuColumn>
                        {currentHoveredRow === params.rowIndex && (
                            <WMMenu
                                menuButtonIconComponent={
                                    <img alt={'more'} src={`/assets/icons/icon-moreVertical.svg`} id={partnerActionsMenuButtonId} />
                                }
                                menuItems={getMenuItems(data)}
                                autoFocus={false}
                            />
                        )}
                    </ActionMenuColumn>
                );
            },
        },
    ];

    return (
        <InvitesTabContainer>
            <div className={'contentDiv'}>
                {showAddPartnerScreen && (
                    <AddPartner
                        onCancel={() => setShowAddPartnerScreen(false)}
                        onFinish={() => {
                            setShowAddPartnerScreen(false);
                            dispatch(getInvites());
                        }}
                        showDialog={showAddPartnerScreen}
                    />
                )}
                {loadingData ? (
                    <LoadingContainer>
                        <CircularProgress size={50} thickness={4} />
                    </LoadingContainer>
                ) : (
                    <div className={'partnersContainer'}>
                        {invites?.length > 0 ? (
                            <StyledPartnersWMDataGrid
                                rows={invites}
                                columnDefs={primaryTableColumns}
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>{t('partners-tab.invites-sub-tab.invited-users')}</div>
                                        <WMTooltip title={t('partners-tab.invites-sub-tab.invited-users-tooltip')}>
                                            <div style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                                                <WMIconHelp size={15} color={'#6D81A6'} cursor={'pointer'} />
                                            </div>
                                        </WMTooltip>
                                    </div>
                                }
                                entityName={''}
                                gridConfig={gridConfig}
                                loadingData={loadingData}
                                headerActions={headerActions}
                                hideColumnPicker={true}
                                hideExport={true}
                            />
                        ) : (
                            <NoPartnersDiv>
                                <img alt={'no results'} src='/assets/No results.svg' width='250px' height='250px' />
                                <NoPartnersLabel>{t('partners-tab.invites-sub-tab.no-invite')}</NoPartnersLabel>
                            </NoPartnersDiv>
                        )}
                    </div>
                )}
            </div>
        </InvitesTabContainer>
    );
};
