import { CustomToolTip, CustomToolTipProps } from 'packages/pages/home/sso-configuration/src/lib/common/components/tool-tip/tool-tip';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const TruncText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%; // required for text overflow ellipsis to work
`;

const Wrapper = styled.div`
    width: 100%;
    & > * {
        display: inline !important;
    }
`;

interface TruncatedTextProps {
    text: string;
    tooltipProps?: Omit<CustomToolTipProps, 'title' | 'children'>;
}

const TruncatedText = ({ text, tooltipProps = {} }: TruncatedTextProps) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        const checkTruncation = () => {
            if (textRef.current) {
                setIsTruncated(textRef.current.offsetWidth < textRef.current.scrollWidth);
            }
        };

        setTimeout(checkTruncation);
        window.addEventListener('resize', checkTruncation);

        return () => {
            window.removeEventListener('resize', checkTruncation);
        };
    }, []);

    return (
        <Wrapper>
            <CustomToolTip title={text} disableHoverListener={!isTruncated} placement='top' arrow {...tooltipProps}>
                <TruncText ref={textRef}>{text}</TruncText>
            </CustomToolTip>
        </Wrapper>
    );
};

export default TruncatedText;
