import React from 'react';
import UrlSections from './url-sections';
import { useForm } from 'react-final-form';
import {
    Bold,
    ExplainRowContainer,
    ExplainSubtitle,
    ExplainText,
    ExplainTextContainer,
    ExplainTitle,
    MainContainer,
    SmallIconButton,
} from './styles';
import { Export } from '@walkme/ui-icons';
import { downloadScanSummaryReport } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { ScanSummaryType } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { useDispatch } from 'react-redux';
import { CustomToolTip } from 'packages/pages/home/sso-configuration/src/lib/common/components/tool-tip/tool-tip';
import { IconButton } from '@walkme/ui-core';

export const ScanUrlsStep = () => {
    const formApi = useForm();
    const {
        values: { urls, sourceGuid },
    } = formApi.getState();

    const dispatch = useDispatch();

    const sections = [
        {
            title: 'Success URLs',
            subtitle: 'Successfully scanned URLs',
            urls: urls?.success?.list,
            count: urls?.success?.count || 0,
            warningName: 'successfully scanned',
        },
        {
            title: 'Failed URLs',
            subtitle: 'URLs that failed to be scanned',
            urls: urls?.failed?.list,
            count: urls?.failed?.count || 0,
            warningName: 'unsuccessfully scanned',
        },
    ];

    return (
        <MainContainer>
            <ExplainText>View a list of all URLs integrated into your knowledge source and those that failed to scan.</ExplainText>
            <div />
            <ExplainRowContainer>
                <ExplainTextContainer>
                    <ExplainTitle>All Current URLs</ExplainTitle>
                    <ExplainSubtitle>
                        <Bold>Note:</Bold>&nbsp;Tabs show up to 10 URLs. Click the export icon to view the full list if needed.
                    </ExplainSubtitle>
                </ExplainTextContainer>
                <CustomToolTip placement={'top'} title='Export' arrow>
                    <IconButton
                        variant='ghost'
                        style={{ width: '24px', height: '24px' }}
                        onClick={() => dispatch(downloadScanSummaryReport(sourceGuid, ScanSummaryType.CURRENT_URLS))}>
                        <Export />
                    </IconButton>
                </CustomToolTip>
            </ExplainRowContainer>
            <UrlSections sections={sections} />
        </MainContainer>
    );
};

export default ScanUrlsStep;
