import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary } from '@material-ui/core';
import {
    IdpSystem,
    IdpSystems,
    IdpSystemsFormValue,
    OneId,
    AccountConfig,
    Provider as IProvider,
    Providers,
} from '@walkme-admin-center/libs/state-management-sso-configuration';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Provider } from './provider/provider';
import { StyledAccordion, StyledAccordionSummary } from './provider-list-style';
import { expandIcon, sortProviders } from '../common/helpers';
import NoResult from '../common/components/no-result/no-result';
import { SystemList } from './provider/systems-list/system-list';
import { Environments } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization';

export interface ProvidersListProps {
    providers: Providers;
    allSystems: IdpSystemsFormValue;
    editProvider: (provider: IProvider) => void;
    editAttributes: (provider: IProvider) => void;
    assignSystems: (provider: IProvider) => void;
    isImpersonator: boolean;
    systemsEnvs: Map<string, Environments>;
    oneId: OneId;
    accountConfig: AccountConfig;
}

export const ProvidersList = ({
    providers,
    editProvider,
    editAttributes,
    assignSystems,
    oneId,
    accountConfig,
    allSystems,
    isImpersonator,
    systemsEnvs,
}: ProvidersListProps) => {
    const [expanded, setExpanded] = useState(null);

    const { t } = useTranslation('general');

    const stringifyProviders = JSON.stringify(providers);

    useEffect(() => {
        setExpanded(null);
    }, [oneId, stringifyProviders]);

    const prepareSystems = (systems: IdpSystems): IdpSystems => {
        return systems.reduce((aggr: IdpSystems, system: IdpSystem) => {
            const foundSystem = allSystems.find((s) => s.guid === system.guid);
            if (!foundSystem) {
                return aggr;
            }
            aggr.push({
                ...system,
                id: foundSystem.id,
                typeKey: foundSystem.typeKey,
                displayName: foundSystem ? foundSystem.displayName : '',
            });
            return aggr;
        }, []);
    };

    const showExpandIcon = (provider: IProvider): React.ReactElement => {
        if (oneId.oneIdProvider) {
            return oneId.oneIdProvider === provider.id ? expandIcon : null;
        } else {
            return provider.systems.length ? expandIcon : null;
        }
    };

    const setCursor = (provider: IProvider): string => {
        const defaultCursorClass = 'default-cursor';
        if (oneId.oneIdProvider) {
            return oneId.oneIdProvider === provider.id ? '' : defaultCursorClass;
        } else {
            return provider.systems.length ? '' : defaultCursorClass;
        }
    };

    const handleChange =
        (provider: IProvider) =>
        (event: React.ChangeEvent, isExpanded: boolean): void => {
            if (oneId.oneIdProvider === provider.id) {
                setExpanded(isExpanded ? provider.id : false);
            } else {
                if (provider.systems.length > 0) {
                    setExpanded(isExpanded ? provider.id : false);
                }
            }
        };

    return (
        <>
            {providers.length > 0 ? (
                sortProviders(providers, oneId).map((provider) => {
                    return (
                        <StyledAccordion
                            classes={{ root: 'root', expanded: 'expanded', rounded: 'rounded', disabled: 'disabled' }}
                            key={provider.id}
                            disabled={oneId.oneIdProvider && oneId.oneIdProvider !== provider.id}
                            expanded={expanded === provider.id}
                            onChange={handleChange(provider)}>
                            <StyledAccordionSummary
                                classes={{ root: 'root', content: 'content' }}
                                className={setCursor(provider)}
                                expandIcon={showExpandIcon(provider)}>
                                <Provider
                                    defaultProvider={accountConfig.providerId === provider.id}
                                    provider={provider}
                                    editProvider={editProvider}
                                    editAttributes={editAttributes}
                                    assignSystems={assignSystems}
                                />
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <SystemList
                                    systemsEnvs={systemsEnvs}
                                    providerExpand={expanded}
                                    systems={prepareSystems(provider.systems)}
                                    isImpersonator={isImpersonator}
                                />
                            </AccordionDetails>
                        </StyledAccordion>
                    );
                })
            ) : (
                <NoResult
                    image={'/assets/icons/idp-no-result.png'}
                    paddingTop={'138px'}
                    title={t('idp-tab.table.list.no-result')}
                    content={''}
                    actionText={''}
                    onAction={null}
                />
            )}
        </>
    );
};
export default ProvidersList;
