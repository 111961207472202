import { Step } from '@material-ui/core';
import React from 'react';
import QontoStepIcon from '../stepper-icons/stepper-icons';
import { StyledQontoConnector, StyledStepLabel, StyledStepper } from './custom-stepper.styles';

export interface CustomStepperProps {
    activeStep: number;
    steps: Array<string>;
}

export const CustomStepper = ({ activeStep, steps }: CustomStepperProps) => {
    return (
        <StyledStepper classes={{ root: 'root' }} activeStep={activeStep} connector={<StyledQontoConnector />}>
            {steps.map((label: string) => {
                return (
                    <Step key={label}>
                        <StyledStepLabel
                            StepIconComponent={QontoStepIcon}
                            classes={{
                                labelContainer: 'labelContainer',
                            }}>
                            {label}
                        </StyledStepLabel>
                    </Step>
                );
            })}
        </StyledStepper>
    );
};

export default CustomStepper;
