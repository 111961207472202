import React from 'react';
import styled from 'styled-components';
import { CONSTS } from '../../../common/consts';
import { Provider } from '@walkme-admin-center/libs/state-management-sso-configuration';
import CustomToolTip from '../../../common/components/tool-tip/tool-tip';
import { useTranslation } from 'apps/home/src/localization';
import { StyledIconButton } from './provider-acitons.styles';

const ProviderActionsContainer = styled.div`
    display: none;
    width: 128px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-right: 12px;
`;

export interface ProviderActionsProps {
    provider: Provider;
    isDefault: boolean;
    openConfirm: (
        actionName: string,
        title: string,
        content: string | JSX.Element,
        buttonText: string,
        cancelButtonText: string,
        type?: string,
        disableConfirm?: boolean
    ) => void;
    openEditProvider: () => void;
    openAssignSystems: () => void;
    openEditAttributes: () => void;
}

const ProviderActions = (props: ProviderActionsProps) => {
    const { t } = useTranslation('general');

    const deleteDialogContent = (
        <span>
            To delete the identity provider,{' '}
            <a href={'https://support.walkme.com/submit-a-ticket'} target='_blank' onClick={(event) => event.stopPropagation()}>
                open a Support ticket.
            </a>
            <br />
            The identity provider must not be assigned to any systems to be deleted.
        </span>
    );

    const actions = [
        {
            id: 1,
            toolTip: props.isDefault ? t('idp-tab.table.tooltips.delete-default') : t('idp-tab.table.tooltips.delete'),
            disabled: props.isDefault,
            name: 'delete',
            element: <img src='assets/icons/icon-trash-bin.svg' alt='delete provider' />,
            action: () => {
                props.openConfirm(
                    CONSTS.DELETE_ACTION,
                    `Delete ${props.provider.name}?`,
                    deleteDialogContent,
                    'Manage system assignment',
                    'Close',
                    'defaultButton',
                    props.provider.fields.length <= 0
                );
            },
        },
        {
            id: 2,
            toolTip:
                props.provider.fields.length > 0 ? t('idp-tab.table.tooltips.assign-systems') : t('idp-tab.table.tooltips.cannot-assign'),
            name: 'assign systems',
            element: <img src='assets/icons/plus-icon.svg' alt='edit systems' />,
            action: () => {
                props.openAssignSystems();
            },
        },
        {
            id: 3,
            toolTip: t('idp-tab.table.tooltips.import-properties'),
            name: 'edit attributes',
            element: <img src='assets/icons/import_properties.svg' alt='import properties' />,
            action: () => {
                props.openEditAttributes();
            },
        },
        {
            id: 4,
            toolTip: props.provider.fields.length > 0 ? t('idp-tab.table.tooltips.edit') : t('idp-tab.table.tooltips.cannot-edit'),
            name: 'edit',
            element: <img src='assets/icons/icon-edit-small.svg' alt='edit provider' />,
            action: () => props.openEditProvider(),
        },
    ];

    const setActions = (action): JSX.Element => {
        return (
            <CustomToolTip title={action.toolTip} key={action.id}>
                <span>
                    <StyledIconButton
                        key={action.id}
                        style={{ opacity: action.disabled ? 0.5 : 1 }}
                        aria-label={action.name}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (!action.disabled) {
                                action.action();
                            }
                        }}
                        classes={{ root: 'root' }}>
                        {action.element}
                    </StyledIconButton>
                </span>
            </CustomToolTip>
        );
    };

    return (
        <ProviderActionsContainer className={'provider-actions'}>
            {actions.map((action) => {
                return setActions(action);
            })}
        </ProviderActionsContainer>
    );
};

export default ProviderActions;
