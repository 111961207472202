import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initData } from '../redux/sso-configuration.slice';

export const useInitData = (isImpersonator: boolean) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initData(isImpersonator));
    }, [dispatch]);
};

export default useInitData;
