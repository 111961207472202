import { useSelector } from 'react-redux';
import { DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useCreatedDesktopTrackedApp = () => {
    const createdDesktopTrackedAppData = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.createdDesktopTrackedApp
    );

    return {
        createdDesktopTrackedAppData
    };
};

export default useCreatedDesktopTrackedApp;
