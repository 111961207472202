import React, { useEffect, useState } from 'react';
import { StyledContractCard, StyledRadioButton, StyledRecommendedBadge, StyledWarningSign } from './contract-card.styles';
import { Tooltip } from '@walkme/ui-core';
import { Contract } from '@walkme-admin-center/libs/state-management-systems';
import { useHover } from '../../../../custom-hooks/useHover';
import { useTranslation } from 'apps/home/src/localization';

type ContractCardProps = {
    contract?: Contract;
    onClick: () => void;
    selected: boolean;
    isRecommended?: boolean;
    text?: string;
    systemsInUse?: number;
    numOfPurchasedSystems?: string | number;
    disabled?: boolean;
    exceededUsage?: boolean;
};

export const ContractCard = ({
    contract,
    onClick,
    selected,
    isRecommended,
    text,
    systemsInUse = 0,
    numOfPurchasedSystems = 'Unlimited Systems',
    disabled,
    exceededUsage = false,
}: ContractCardProps) => {
    const [ref, isHovered] = useHover();

    const { t, rt, i18n } = useTranslation('general');

    const [numOfSystems, setNumOfSystems] = useState<number | string>(numOfPurchasedSystems);
    useEffect(() => {
        if (typeof numOfPurchasedSystems !== 'number') {
            setNumOfSystems(rt(numOfPurchasedSystems));
        }
    }, [i18n.language]);

    const handleOnClick = () => {
        if (disabled) return;
        onClick();
    };

    const sideBadge = () => {
        if (disabled) {
            return null;
        }

        if (selected) {
            return <StyledRadioButton checked={true} />;
        } else if (isHovered) {
            return <StyledRadioButton checked={false} />;
        } else if (isRecommended) {
            return <StyledRecommendedBadge>Recommended</StyledRecommendedBadge>;
        } else {
            return null;
        }
    };

    return (
        <StyledContractCard
            onClick={handleOnClick}
            selected={selected}
            disabled={disabled}
            ref={ref as React.MutableRefObject<any>}
            key={contract?.sfId}
            shouldTitleMarginBottom={!text}>
            <div className='description'>
                <div className='title'>
                    <Tooltip title={text || contract.description || contract.type}>{text || contract.description || contract.type}</Tooltip>
                </div>
                <div className='misc'>{sideBadge()}</div>
            </div>
            {!text ? (
                <div className='info'>
                    <span>ID</span>{' '}
                    <Tooltip title={contract?.sfId}>
                        <span className='id'>{contract?.sfId}</span>
                    </Tooltip>
                    &#x2022;
                    <span className='systems'>
                        {systemsInUse} / {numOfSystems} {exceededUsage ? <StyledWarningSign /> : null}
                    </span>
                </div>
            ) : null}
        </StyledContractCard>
    );
};
