import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDesktopTrackedApp, DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useDesktopTrackedApp = (msaId: number, systemId?: number) => {
    const currentDesktopTrackedAppData = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.currentDesktopTrackedApp
    );

    const dispatch = useDispatch();

    if (systemId) {
        useEffect(() => {
            dispatch(getDesktopTrackedApp(msaId, systemId));
        }, [dispatch, systemId]);
    }

    return {
        currentDesktopTrackedAppData,
    };
};

export default useDesktopTrackedApp;
