import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { AppIdentifier } from './types/app-identifier';
import { Button } from '@walkme-admin-center/libs/ui-components';
import { createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

const StyledCardContent = styled(CardContent)`
    width: 50%;
    min-height: 250px;
    background-color: #FAFAFA;
    padding-left: 25px;
`;

const StyledDescription = styled(Typography)`
    color: ${styledTheme.colors.mainGray};
`;

const StyledTitleWrapper = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const StyledTitleContent = styled(Typography)`
    margin-left: 10px;
    font-size: 16px;
    display: inline-block;
`;

const StyledIdentifiersTitleWrapper = styled.div`
    margin-bottom: 10px;
`;

const StyledIdentifiersTitle = styled.span`
    width: 185px;
    display: inline-block;
`;

const StyledLink = styled(Link)`
    color: ${styledTheme.colors.main.inlineLinks};

    &:hover {
        text-decoration: none;
    }
`;

const StyledLinkContainer = styled(Typography)`
    margin-bottom: 30px;
`;

const Mandatory = styled.span`
    color: ${styledTheme.colors.errorColor};
`;

interface IdentifiersProps {
    os: string;
    icon: string;
    simpleIdentifierLabel: string;
    advancedIdentifierLabel: string;
    identifiers: AppIdentifier[];
    description: string;
    linkLabel: string;
    simpleIdentifierMandatory?: boolean;
    advancedIdentifierMandatory?: boolean;
    onAdd: () => void;
    onUpdate: (index: number, identifier: AppIdentifier) => void;
    onDelete: (index: number) => void;
    RowComponent: (any) => JSX.Element
}

const SUPPORT_LINK = 'https://support.walkme.com/knowledge-base/insights-with-desktop/';

const Identifiers = ({
    os,
    icon,
    simpleIdentifierLabel,
    advancedIdentifierLabel,
    identifiers,
    description,
    linkLabel,
    simpleIdentifierMandatory,
    advancedIdentifierMandatory,
    onAdd,
    onUpdate,
    onDelete,
    RowComponent
}: IdentifiersProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            addButton: {
                marginLeft: '0 !important'
            }
        })
    );

    const getMandatorySymbol = () => {
        return (
            <Mandatory>*</Mandatory>
        );
    }

    const classes = useStyles();
    const showTitles = identifiers.length > 0 && identifiers.some((identifier) => !identifier.delete);

    return (
        <StyledCardContent>
            <StyledTitleWrapper>
                <img src={`assets/icons/${icon}`} alt="" />
                <StyledTitleContent>
                    <b>Configure for {os}</b>
                </StyledTitleContent>
            </StyledTitleWrapper>

            <StyledDescription>
                {description}
            </StyledDescription>
            <StyledLinkContainer>
                <StyledLink href={SUPPORT_LINK} target="_blank">{linkLabel}</StyledLink>
            </StyledLinkContainer>

            {showTitles && (
                <StyledIdentifiersTitleWrapper>
                    <StyledIdentifiersTitle>
                        {simpleIdentifierLabel}
                        {
                            simpleIdentifierMandatory && getMandatorySymbol()
                        }
                    </StyledIdentifiersTitle>
                    <StyledIdentifiersTitle>
                        {advancedIdentifierLabel}
                        {
                            advancedIdentifierMandatory && getMandatorySymbol()
                        }
                    </StyledIdentifiersTitle>
                </StyledIdentifiersTitleWrapper>
            )}

            <div>
                {
                    identifiers.map((identifier, index) => {
                        if (identifier.delete) {
                            return <div key={identifier.id}></div>
                        }

                        return <RowComponent
                            key={identifier.id}
                            identifier={identifier}
                            index={index}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    })
                }
                <div>
                    <Button
                        customClass={`form change inline add-button ${classes.addButton}`}
                        onClick={(e) => onAdd()}
                    >
                        <AddIcon />
                    </Button>
                </div>
            </div>
        </StyledCardContent>
    );
};

export default Identifiers;
