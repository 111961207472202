import styled from 'styled-components';
import { Input, Toaster } from '@walkme/ui-core';
import { ErrorColor, FilledWarning, Info } from '@walkme/ui-icons';

export const AddedUsersContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 45vh;

    .added-users-list {
        padding-right: 16px;
        border-right: 1px solid var(--Typography-Typography-tint-8, #f5f6f8);
        height: 100%;
        overflow-y: auto;

        .label {
            margin-bottom: 8px;
            font-size: 12px;
            color: var(--Typography-Typography-Main, #2f426c);
        }

        .list {
            padding-top: 4px;
        }
    }

    .added-user-form {
        padding: 20px 0 0 16px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .split-row {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 24px;
        }
        .full-row {
            margin-bottom: 24px;
            position: relative;
            .label {
                display: flex;
                font-size: 12px;
                margin-bottom: 4px;
                color: var(--Typography-Typography-Main, #2f426c);
            }

            .space-between {
                justify-content: space-between;
            }
            .input {
                width: 100%;
            }

            .WMSelect-target {
                padding: 0 16px;
                width: 590px;
            }
        }
    }

    .WMSelect-popoutSelect {
        button {
            height: 40px;
        }
    }
`;

export const StyledAddedUserItem = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 16px;
    background-color: ${({ isSelected }) => (isSelected ? '#F5F7FE' : 'transparent')};
    cursor: pointer;
    margin-bottom: 4px;

    &:hover {
        box-shadow: ${({ isSelected }) =>
            !isSelected ? '0px -10px 15px 0px rgba(212, 223, 245, 0.20), 0px 10px 15px 0px rgba(212, 223, 245, 0.20)' : 'unset'};
    }

    .description {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--LS-Color-Text-primary, #2f426c);
        font-weight: 400;
    }

    .actions {
        display: flex;
        align-items: center;
    }
`;

export const DeleteIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;

    &:hover {
        background: var(--LS-Color-Action-Bg-On-light-Danger-ghost-hover, #fef3f3);

        svg {
            color: var(--LS-Color-Action-Content-On-light-Danger-ghost-hover, #af1111);
        }
    }
`;

export const StyledUserInput = styled(Input)`
    height: 32px;
`;

export const StyledToasterContainer = styled(Toaster)`
    margin-top: 20px;
    box-shadow: none;
    width: auto;
`;

export const StyledErrorSign = styled(ErrorColor)`
    margin-right: 6px;
`;

export const StyledWarningSign = styled(FilledWarning)`
    margin-right: 6px;
`;

export const StyledInfo = styled(Info)`
    cursor: pointer;
    padding: 2px;
    color: var(--Typography-Typography-Main, #2f426c);
`;

export const StyledSurveyRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--Typography-Typography-tint-2, #8d97ae);

    .description {
        .surveyLink {
            color: var(--Blue-Primary-Primary-Main, #3b61eb);
            font-weight: 600;
            text-decoration: none;
        }
    }
`;

export const StyledSurveyInfoIcon = styled(Info)`
    margin-right: 4px;
`;
