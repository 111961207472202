import React, { ReactNode } from 'react';
import { noop } from 'rxjs';
import { Tooltip } from '@material-ui/core';

import { StyledIconButton } from '@walkme-admin-center/libs/shared-styles';

interface IconButtonProps {
    title?: string;
    ariaLabel?: string;
    children?: ReactNode;
    onClick?: (event) => unknown;
}

export const IconButton = (props: IconButtonProps) => {
    const { title, ariaLabel, children, onClick = noop } = props;

    return (
        <Tooltip title={title}>
            <StyledIconButton aria-label={ariaLabel} onClick={onClick}>
                {children}
            </StyledIconButton>
        </Tooltip>
    );
};

export default IconButton;
