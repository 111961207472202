import styled from 'styled-components';

export const IdpIntegrationContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  & .idp-integration-form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  & .divider {
    margin: 28px 0;
  }`;

export const ToggleContainer = styled.div`
  & .toggle-content {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  & .toggle-helper {
    color: #8D97AE;
    font-size: 12px
  }
  `

export const FieldBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 35px;
  }
`;
