import React, { useEffect } from 'react';
import { getAuditLogs, AuditLogsState } from '@walkme-admin-center/libs/state-management-auditlog';
import { useDispatch, useSelector } from 'react-redux';
import { useSystems } from '../../../../../libs/state-management-systems/src/lib/hooks/use-systems';
import { GetAuditLogsDto } from 'wm-accounts-sdk';
import { AuditLogPage } from '@walkme/audit-log';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { auditEventProcessorApi } from '../../../../../libs/audit-event-processor-api/src';

export const AuditLogsView = () => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();

    const { systemsAppData } = useSystems();
    const { userPermissions, loggedInUserAppData } = useLoggedInUser();
    const loggedInUserId = loggedInUserAppData?.data?.id;

    const auditLogsAppData = useSelector((state: { auditLogState: AuditLogsState }) => {
        return state.auditLogState.auditLogs;
    });

    const sendAuditInternalEvent = async (action, actionType, loggedInUserId, additionalInfo) => {
        const body = {
            actionType: actionType,
            timestamp: new Date(),
            eventAdditionalInfo: {
                action: action,
                object: {
                    id: loggedInUserId,
                    type: 'User',
                },
                details: {
                    ...additionalInfo,
                },
            },
        };

        try {
            await auditEventProcessorApi.sendInternalEvent(body);
            console.log('Audit event sent successfully');
        } catch (error) {
            console.error('Failed to send audit event:', error);
        }
    };

    const onExport = (additionalInfo) => {
        const action = 'Export Audit Logs';
        const actionType = 'export_activity_log_report';
        sendAuditInternalEvent(action, actionType, loggedInUserId, additionalInfo);
    };

    const { allowedSystems, logs } = React.useMemo(() => {
        const systems = systemsAppData.data;
        const auditlogs = auditLogsAppData.data;

        const hasModifyPermissions = userPermissions('systemManagement', 'Modify');
        if (!hasModifyPermissions) {
            return { allowedSystems: systems, logs: auditlogs };
        }

        const allowedSystems = systems.filter((system) => !system.restrictedAccess);
        const allowedSystemsIds = allowedSystems.map((allowedSystem) => allowedSystem.id);

        const allowedLogs = auditlogs.filter(
            (auditLog) => !auditLog.additionalInfo.systemId || allowedSystemsIds.includes(auditLog.additionalInfo.systemId)
        );
        return { allowedSystems, logs: allowedLogs };
    }, [systemsAppData.data, loggedInUserAppData, auditLogsAppData.data]);

    useEffect(() => {
        document.title = t('activity-log-tab.document-title');
    }, []);

    return !allowedSystems.length ? (
        <div></div>
    ) : (
        <div style={{ height: '85vh' }}>
            <AuditLogPage
                systems={allowedSystems}
                logs={logs}
                loading={auditLogsAppData.loading}
                onChangeFilter={(getLogsDto: GetAuditLogsDto) => {
                    dispatch(getAuditLogs(getLogsDto));
                }}
                onExport={onExport}></AuditLogPage>
        </div>
    );
};

export default AuditLogsView;
