import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuditLogsState, getAuditLogs } from '../redux/auditlogs.slice';
import { User } from 'wm-accounts-sdk';

export interface AuditLogQueryParams {
    from?: number;
    to?: number;
    loggedInUserAppData?: User;
}

export const useAuditLogs = (params: AuditLogQueryParams) => {
    const auditLogsAppData = useSelector((state: { auditLogState: AuditLogsState }) => {
        return state.auditLogState.auditLogs;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAuditLogs(params));
    }, [dispatch]);

    return {
        auditLogsAppData,
    };
};

export default useAuditLogs;
