import { Accordion, AccordionSummary } from '@material-ui/core';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
    &.root {
        border: 1px solid #e1e9f5;
        margin-bottom: 8px;
        box-shadow: none;

        &:before {
            opacity: 0;
        }

        & .MuiCollapse-root {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top: 1px solid rgb(225, 233, 245);
        }

        & .MuiAccordionDetails-root {
            padding: 0;
            flex-direction: column;
        }
    }

    &.expanded {
        margin: 0 0 8px 0 !important;
    }

    &.rounded {
        border-radius: 4px !important;
    }

    &.disabled {
        background-color: #f0f4fa !important;

        & .provider-title {
            color: #2f426c;
        }
        & .provider-protocol-tag {
            color: #8d97ae;
        }
    }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    &.root {
        height: 64px;
        padding: 0 24px;

        &.default-cursor {
            cursor: default !important;
        }

        &:hover {
            & .provider-actions {
                display: flex;
            }

            & .provider-info {
                visibility: visible;
            }
        }

        .MuiCollapse-wrapperInner {
            .MuiAccordionDetails-root {
                padding: 0px 40px 0px 76px !important;
            }
        }
    }

    &.content {
        margin: 0 !important;
    }
`;
