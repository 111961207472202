import React from 'react';
import { Button } from '@walkme/ui-core';
import { getDefaultSystemMatcher, useGetDefaultSystemMatcher } from '@walkme-admin-center/libs/state-management-extensions';
import { System, User } from 'wm-accounts-sdk';
import { useDispatch } from 'react-redux';
import SYSTEM_NOT_CONNECTED_ICON from '../../../../../../../packages/libs/common/assets/icons/system-not-connected-to-extension.png';
import { SpecialContentContainer, SpecialContentMessageHeader, SpecialContentMessageSubHeader } from './shared-styled-components';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

interface SystemNotConnectedProps {
    canConnect: boolean;
    selectedSystem: System;
    user: User;
    defaultEnvId: number;
}

const SystemNotConnected = ({ canConnect, selectedSystem, user, defaultEnvId }: SystemNotConnectedProps) => {
    const { t } = useTranslation('general');
    const { getDefaultSystemMatcherAppData } = useGetDefaultSystemMatcher();
    const dispatch = useDispatch();

    const onGetDefaultMatcherClicked = async () =>
        dispatch(
            getDefaultSystemMatcher(selectedSystem.settings?.id!, selectedSystem.name, defaultEnvId, user.account.id, user.account.guid!)
        );

    const connectEnabledControl = (
        <>
            <SpecialContentMessageHeader>
                {t('systems-tab.all-systems-page.extension-page.system-not-linked-to-extension.can-connect-title')}
            </SpecialContentMessageHeader>
            <SpecialContentMessageSubHeader>
                {t('systems-tab.all-systems-page.extension-page.system-not-linked-to-extension.can-connect-description')}
            </SpecialContentMessageSubHeader>
            <Button
                onClick={onGetDefaultMatcherClicked}
                disabled={getDefaultSystemMatcherAppData.loading}
                loading={getDefaultSystemMatcherAppData.loading}>
                {t('systems-tab.all-systems-page.extension-page.configure-urls')}
            </Button>
        </>
    );

    const connectDisabledControl = (
        <>
            <SpecialContentMessageHeader>
                {t('systems-tab.all-systems-page.extension-page.system-not-linked-to-extension.cannot-connect-title')}
            </SpecialContentMessageHeader>
            <SpecialContentMessageSubHeader>
                {t('systems-tab.all-systems-page.extension-page.system-not-linked-to-extension.cannot-connect-description')}
            </SpecialContentMessageSubHeader>
        </>
    );

    return (
        <SpecialContentContainer>
            <img src={SYSTEM_NOT_CONNECTED_ICON} />
            {canConnect ? connectEnabledControl : connectDisabledControl}
        </SpecialContentContainer>
    );
};

export default SystemNotConnected;
