import React, { useCallback } from 'react';
import classNames from 'classnames';

import { FormActions as IFormActions } from '@walkme-admin-center/libs/types';
import { Button } from '@walkme-admin-center/libs/ui-components';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import { StyledActions } from './styles/styles';

export interface FormActionsProps {
    actions: IFormActions;
    submitting?: boolean;
    deleting?: boolean;
    pristine?: boolean;
    invalid?: boolean;
    className?: string;
    values?: unknown;
    next?: () => void;
    saveActionName?: string;
    status?: string;
    userId?: number;
}

export const FormActions = ({
    actions: { onCancel, onConfirm, onChange, onDelete, onResend },
    submitting = false,
    deleting = false,
    pristine = false,
    invalid = false,
    values,
    next,
    status,
    userId,
    className = 'actions',
    saveActionName = 'Save',
}: FormActionsProps) => {
    return (
        <StyledActions className={className}>
            <div className='actions-row'>
                <Button customClass='form cancel' type='button' onClick={() => onCancel(pristine)} data-testid='cancel-button'>
                    cancel
                </Button>
                {!!onDelete && (
                    <Button
                        customClass={deleting ? 'form' : 'form delete'}
                        disabled={deleting || submitting}
                        type='button'
                        loading={deleting}
                        onClick={() => {
                            onDelete(values);
                        }}
                        data-testid='delete-button'>
                        <span className='iconContainer'>
                            <DeleteIcon fontSize={'small'} />
                        </span>
                        Delete
                    </Button>
                )}

                {!!onResend && (
                    <Button type='button' customClass='form resend' onClick={() => onResend(values)} data-testid='resend-button'>
                        Resend Email
                    </Button>
                )}

                {!!onChange && (
                    <Button customClass='form change' type='button' onClick={onChange} data-testid='change-button'>
                        <span className='iconContainer'>
                            <SettingsIcon fontSize={'small'} />
                        </span>
                        Change System Type
                    </Button>
                )}
                <Button
                    customClass={classNames({ form: true }, { save: true }, { main: !(submitting || pristine || invalid) })}
                    disabled={submitting || pristine || invalid}
                    type='submit'
                    loading={submitting}
                    data-testid='confirm-button'
                    onClick={
                        next
                            ? next
                            : () => {
                                  onConfirm(values);
                              }
                    }>
                    {saveActionName}
                </Button>
            </div>
        </StyledActions>
    );
};

export default FormActions;
