import React, { useState, MouseEvent, useRef } from 'react';
import { Button, Checkbox, Menu, MenuItem, MenuItemText, DropdownChevronDisplayOptions } from '@walkme/ui-core';

import { Role, Roles } from 'wm-accounts-sdk';
import {
    StyledMenuItemText,
    StyledSelector,
    StyledDivider,
    StyledClear,
    StyledRolesSelectorDiv,
    StyledRoleButtonText,
    StyledRoleText,
    StyledTagLength,
    StyledMenuItem,
    StyledApplyButton,
} from './styles/role-filter.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface RoleFilterProps {
    selectedRoles: Roles;
    allRoles: Roles;
    setSelectedRoles: (roles: Roles) => void;
}
export function RoleFilter(props: RoleFilterProps) {
    const { t, rt } = useTranslation('general');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [finalFilterToApplay, setFinalFilterToApplay] = useState<Roles>(props.selectedRoles);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };
    const handleSelectAll = (e) => {
        e.target.checked ? setFinalFilterToApplay(props.allRoles) : setFinalFilterToApplay([]);
    };
    const handleApllyChanges = () => {
        props.setSelectedRoles(finalFilterToApplay);
        handleClose();
    };

    const handleChangeSelection = (e, selectedRole: Role) => {
        const newSelectedRoles = e.target.checked
            ? finalFilterToApplay.concat(selectedRole)
            : finalFilterToApplay.filter((role) => role.id !== selectedRole.id);
        setFinalFilterToApplay(newSelectedRoles);
    };

    const resetToAllRoles = (e) => {
        e.stopPropagation();
        props.setSelectedRoles(props.allRoles);
        setFinalFilterToApplay(props.allRoles);
    };

    const selectedRolesFilterHasChanges = props.selectedRoles.length > 0 && props.allRoles.length !== props.selectedRoles.length;

    const SelectorDisplayedValue = (
        <StyledRolesSelectorDiv>
            <StyledRoleButtonText>{t('common.roles')}</StyledRoleButtonText>
            {props.selectedRoles.length > 0 && props.allRoles.length !== props.selectedRoles.length && (
                <StyledRoleText>{rt(props.selectedRoles[0].displayName)}</StyledRoleText>
            )}
            {props.selectedRoles.length > 0 &&
                props.allRoles.length !== props.selectedRoles.length &&
                props.selectedRoles.length - 1 > 0 && <StyledTagLength>+{props.selectedRoles.length - 1}</StyledTagLength>}
            {selectedRolesFilterHasChanges ? <StyledClear onClick={(e) => resetToAllRoles(e)} /> : null}
        </StyledRolesSelectorDiv>
    );

    const chevronDisplayOption = selectedRolesFilterHasChanges
        ? DropdownChevronDisplayOptions.HIDE_CHEVRON_DOWN
        : DropdownChevronDisplayOptions.DEFAULT;

    return (
        <div>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
            />
            <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={250}>
                <StyledMenuItem key={-1}>
                    <Checkbox
                        onChange={(e) => handleSelectAll(e)}
                        checked={props.allRoles.length === finalFilterToApplay.length}
                        indeterminate={props.allRoles.length !== finalFilterToApplay.length}
                    />
                    <StyledMenuItemText>{t('systems-tab.all-systems-page.system-users-page.all-roles')}</StyledMenuItemText>
                </StyledMenuItem>
                <StyledDivider />
                {props.allRoles.map((role, index) => {
                    return (
                        <StyledMenuItem key={role.id}>
                            <Checkbox
                                onChange={(e) => handleChangeSelection(e, role)}
                                checked={!!finalFilterToApplay.find((selectedRole) => role.id === selectedRole.id)}
                            />
                            <StyledMenuItemText>{rt(role.displayName)}</StyledMenuItemText>
                        </StyledMenuItem>
                    );
                })}
                <StyledApplyButton variant='text' onClick={handleApllyChanges}>
                    {t('buttons.apply')}
                </StyledApplyButton>
            </Menu>
        </div>
    );
}
