import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessDomains, UsersState } from '../redux/users.slice';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

export const useBusinessDomains = (forceLoad = false) => {
    const { accountFeatureEnabled } = useLoggedInUser();
    const lobFeatureIsEnabled = accountFeatureEnabled('lobEnabled');
    const businessDomainsAppData = useSelector((state: { usersState: UsersState }) => state.usersState.businessDomains);
    const defaultBusinessDomain = { id: 0, description: 'No business domain', key: 'NONE', name: 'None', order: 0 };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBusinessDomains(forceLoad, lobFeatureIsEnabled));
    }, [dispatch]);

    return {
        businessDomains: [defaultBusinessDomain, ...businessDomainsAppData.data].sort((a, b) => a.order - b.order),
    };
};

export default useBusinessDomains;
