import styled from 'styled-components';
import { Divider, DropdownSelection } from '@walkme/ui-core';
import { Close as Clear, Info } from '@walkme/ui-icons';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 40px;
        width: 300px;
        margin: 0;
        outline: none !important;
    }
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const UuidSelctorDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const UuidButtonText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;

export const StyledClear = styled(Clear)`
    cursor: pointer;
`;

export const StyledIdpTooltip = styled.div`
    .learn-more {
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
        cursor: pointer;
        margin-top: 6px;
        padding: 5px;

        a {
            text-decoration: none !important;
            color: var(--Color-Local-Semantic-Action-Content-Text-inversed-default, #92a7f4);
        }
    }
`;

export const StyledUUIDNotifier = styled.div`
    display: flex;
    margin-top: 8px;

    .info-icon {
        padding: 2px 0px 1px 1px;
    }

    .description {
        margin-left: 8px;
        color: var(--Color-Local-Semantic-Text-secondary, #637191);
    }
`;
