export interface LexiconResults {
    readyState?: any;
    verifiedAmbiguity?: boolean;
    Issue?: string,
    shouldNotUpdateSummary?: boolean,
    Status?: LexiconResultsStatus;
    duplicationAmbiguity?: boolean;
}


export enum LexiconResultsStatus {
    ERROR = 'Error',
    AMBIGUITY = 'Ambiguity',
    SUCCESS = 'Success',
}

export interface AttributeId {
    manualTranslation: string;
}

export interface PhraseObject {
    phrase: string;
    language: string;
    attributeId: AttributeId;
}

export interface TranslationsListObject {
    originalPhraseObject: PhraseObject;
    translationPhraseObject: PhraseObject;
    deployableId: string;
    duplication?: boolean;
}
export interface AddManualAndAutomatonTranslationsBulkResults {
    lexiconResults?: LexiconResults[];
    lexiconStructuralErrors?: string[];
}