import React, { useCallback } from 'react';
import { DateFormatter, DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { MutableRefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTheme } from '@walkme/ui-styles';
import { Navigation } from './Navigation';
import { Button } from '@walkme/ui-core';
import { Popover } from '@material-ui/core';
import { useTranslation, dateFnsLocalesMapping, timeLocalesMapping } from 'apps/home/src/localization';
import { format } from 'date-fns';

const DatePickerContainer = styled.div`
    width: 280px;
    box-sizing: content-box;
    box-shadow: 0px 12px 16px rgba(47, 61, 83, 0.11);
    border-radius: 12px;
    padding: 16px 24px;
    font-family: ${(props) => getTheme(props).typography.F10};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    .rdp {
        margin: 0;
    }

    .rdp-head_cell {
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .rdp-button:hover:not([disabled]) {
        background-color: #f4f6fb;
        color: ${(props) => getTheme(props).palette.colors.primary.main};
    }

    .rdp-day_today:not(.rdp-day_outside) {
        font-weight: 400;
        color: ${(props) => getTheme(props).palette.colors.primary.main};
    }

    .rdp-button:focus:not([disabled]) {
        border: 0;
    }

    .rdp-day_selected:not([disabled]),
    .rdp-day_selected:focus:not([disabled]),
    .rdp-day_selected:active:not([disabled]),
    .rdp-day_selected:hover:not([disabled]) {
        background: ${(props) => getTheme(props).palette.colors.primary.main};
        color: #ffffff;
    }

    .rdp-day_outside {
        color: ${(props) => getTheme(props).palette.colors.typography.tint4};
    }
`;

const ButtonBlock = styled.div`
    display: flex;
    justify-content: center;
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ApplyButton = styled(Button)`
    display: flex !important;
    height: 48px !important;
    padding: 14px 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    width: 100% !important;
`;

export interface DatePickerProps {
    isOpen: boolean;
    onClose: () => void;
    selected?: DateRange;
    onSelect: (day?: Date | DateRange) => void;
    anchorRef: MutableRefObject<unknown>;
    className?: string;
    fromYear?: number;
}

const defaultFromYear = 2021;

export const DatePicker = ({ isOpen, onClose, anchorRef, selected, onSelect, className, fromYear }: DatePickerProps) => {
    const { t, i18n } = useTranslation('general');
    const [date, setDate] = useState(selected);
    const [dateLocale, setDateLocale] = useState(dateFnsLocalesMapping[i18n.language]);

    const formatMonthCaption: DateFormatter = (date) => {
        return format(date, 'MMMM', { locale: dateLocale });
    };

    useEffect(() => {
        setDateLocale(dateFnsLocalesMapping[i18n.language]);
    }, [i18n.language]);

    useEffect(() => {
        setDate(selected);
    }, [selected]);

    const onInnerSelect = (newValue: unknown) => {
        setDate(newValue as DateRange);
        console.log('date', newValue);
    };

    const onApply = () => {
        onSelect(date);
        onClose();
    };

    const onClear = () => {
        setDate(undefined);
        onSelect();
        onClose();
    };

    return (
        <Popover
            open={isOpen}
            onClose={onClose}
            anchorEl={anchorRef.current as Element}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            PaperProps={{ style: { marginTop: 4 } }}>
            <DatePickerContainer className={className}>
                <DayPicker
                    mode='range'
                    selected={date}
                    onSelect={onInnerSelect}
                    components={{
                        Caption: (props) => <Navigation {...props} fromYear={fromYear || defaultFromYear} />,
                    }}
                    showOutsideDays
                    locale={dateLocale}
                    formatters={{ formatMonthCaption }}
                />
                <ButtonBlock>
                    <ApplyButton variant='text' onClick={onApply}>
                        {t('buttons.apply')}
                    </ApplyButton>
                </ButtonBlock>
            </DatePickerContainer>
        </Popover>
    );
};
