import styled from 'styled-components';
import { Typography, Input, DialogContent, Button } from '@walkme/ui-core';

export const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
`;

export const StyledTypography = styled(Typography)`
    padding: 8px 0;
`;

export const StyledInput = styled(Input)`
    width: -webkit-fill-available;
    margin-top: 16px;

    & span {
        color: var(--Color-Semantic-Text-text-primary, #2f426c);
    }

    & textarea {
        resize: none;
    }
`;

export const StyledCheckboxDiv = styled.div`
    display: flex;
    gap: 8px;
    padding-top: 8px;
`;

export const StyledButton = styled(Button)`
    margin-right: 20px;
    color: var(--Typography-Typography-tint-1, #637191);
`;
