import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import DropZoneField from './dropZoneField';
import { CssTextField, FieldLabel, TextFieldContainer } from '../../../../data-integration-form-style';
import styles from '../../../../../common/style.module.scss';

const Separator = styled.div`
    width: 100%;
    height: 0.0625rem;
    background: var(--Color-Local-Semantic-El-divider, #f0f3f9);
    margin: 2rem 0;
    flex: none;
`;

const UploadFiles = () => {
    return (
        <div style={{ padding: '24px 32px', height: '100%', boxSizing: 'border-box' }} className={styles.flexColumn}>
            <TextFieldContainer>
                <FieldLabel>Knowledge source name</FieldLabel>
                <Field name={'name'}>
                    {({ input, meta }) => (
                        <CssTextField
                            variant='outlined'
                            placeholder={'E.g. HR Policies'}
                            fullWidth
                            error={!!meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            {...input}
                        />
                    )}
                </Field>
            </TextFieldContainer>
            <Separator />
            <Field name={'settings.files'} component={DropZoneField} />
        </div>
    );
};

export default UploadFiles;
