import { exposureApi } from '../../../../../../libs/exposure-api/src/lib/exposure-api';
import { LoadOption, PartialSystemData, SystemMatcher } from '../../../../../../libs/exposure-api/src/lib/new-web-systems-api/types';
import { EnvironmentURLQueryResult, URLMatchData } from './values';

const extractAllDomainsForConversion = (systemData: PartialSystemData): Set<string> => {
    let allDomains = new Set<string>();

    Object.values(systemData.matchers).forEach((matchersPerEnv: SystemMatcher[]) => {
        matchersPerEnv.forEach((systemMatcher: SystemMatcher) => {
            if (!systemMatcher.isRegex && systemMatcher.isActive) {
                systemMatcher.selfDomains?.forEach((domain) => allDomains.add(domain));
                systemMatcher.topDomains?.forEach((domain) => allDomains.add(domain));
            }
        });
    });

    return allDomains;
};

const getConvertedDomainsToRegexesMap = async (systemData: PartialSystemData): Promise<Map<string, string>> => {
    const allDomains = extractAllDomainsForConversion(systemData);

    if (allDomains.size === 0) {
        return new Map();
    }

    try {
        const domainToRegexArray = (await exposureApi.convertDomainsToRegexes([...allDomains])) || [];
        const domainToRegexMap = new Map(domainToRegexArray.map((item) => [item.domain, item.regex]));

        return domainToRegexMap;
    } catch (error) {
        // TODO: add logger here
        throw error;
    }
};

const isRegexMatcherMatchURL = (matcher: SystemMatcher, topUrl: string, frameUrl?: string): boolean => {
    // frameUrl will be exists only in case the url to match is iframe. In case it is not defined we need to validate the topUrl against the self regex.
    const urlToValidateSelfRegex = frameUrl || topUrl;
    const isMatchingTopRegex = !!matcher.topRegex ? testRegex(matcher.topRegex, topUrl) : true;
    const isMatchingSelfRegex = testRegex(matcher.selfRegex, urlToValidateSelfRegex);

    return isMatchingTopRegex && isMatchingSelfRegex;
};

const testRegex = (regex: string, url: string): boolean => {
    if (!regex) {
        return false;
    }

    const regexPattern = new RegExp(regex);
    return regexPattern.test(url);
};

const getSelfDomainsMatcherThatMatchURLs = (
    matcher: SystemMatcher,
    topUrl: string,
    frameUrl: string,
    convertedDomainsToRegexesMap: Map<string, string>
): string[] => {
    const urlToValidateSelfDomains = frameUrl || topUrl;

    const isMatchingTop =
        matcher.topDomains.length === 0
            ? true
            : matcher.topDomains.some((topDomain) => testRegex(convertedDomainsToRegexesMap.get(topDomain), topUrl));

    if (!isMatchingTop) {
        return [];
    }

    return matcher.selfDomains.filter((selfDomain) => testRegex(convertedDomainsToRegexesMap.get(selfDomain), urlToValidateSelfDomains));
};

const getSelfDomainsUrlMatchData = (matcher: SystemMatcher, selfDomainsMatchingURL: string[]): URLMatchData[] =>
    matcher.selfDomains.map((selfDomain) => ({
        selfDomainOrRegex: selfDomain,
        isMatching: selfDomainsMatchingURL.includes(selfDomain),
    }));

const getVerificationResults = async (
    systemData: PartialSystemData,
    topUrl: string,
    frameUrl?: string
): Promise<EnvironmentURLQueryResult[]> => {
    const environmentURLQueryMatchingResults: EnvironmentURLQueryResult[] = [];
    const convertedDomainsToRegexesMap = await getConvertedDomainsToRegexesMap(systemData);
    const isIframe = !!frameUrl;

    for (const envId in systemData.matchers) {
        const isEnvDev = systemData.environments[envId].isDev;

        if (isEnvDev) {
            continue;
        }

        const matchers = systemData.matchers[envId];
        for (let matcherIndex = 0; matcherIndex < matchers.length; matcherIndex++) {
            const matcher = matchers[matcherIndex];
            const environmentURLQueryMatchingResult: EnvironmentURLQueryResult = {
                envName: systemData.environments[envId].name,
                envValue: Number(envId),
                urlMatchData: [],
                configurationSet: matchers.length > 1 ? matcherIndex + 1 : undefined,
                matcherId: matcher._id,
                isRegex: matcher.isRegex,
            };

            if (!matcher.isActive || (isIframe && matcher.loadOptions !== LoadOption.TopAndCdIframes)) {
                continue;
            }

            if (matcher.isRegex) {
                if (isRegexMatcherMatchURL(matcher, topUrl, frameUrl)) {
                    environmentURLQueryMatchingResult.urlMatchData = [{ selfDomainOrRegex: matcher.selfRegex, isMatching: true }];
                    environmentURLQueryMatchingResults.push(environmentURLQueryMatchingResult);
                }
            } else {
                const selfDomainsMatchingURL = getSelfDomainsMatcherThatMatchURLs(matcher, topUrl, frameUrl, convertedDomainsToRegexesMap);

                if (selfDomainsMatchingURL.length) {
                    environmentURLQueryMatchingResult.urlMatchData = getSelfDomainsUrlMatchData(matcher, selfDomainsMatchingURL);
                    environmentURLQueryMatchingResults.push(environmentURLQueryMatchingResult);
                }
            }
        }
    }

    return environmentURLQueryMatchingResults;
};

export { getVerificationResults };
