import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme, OutlinedInput, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@walkme-admin-center/libs/ui-components';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import { AppIdentifier } from './types/app-identifier';
import IdentifierRowErrorMessage from './identifier-row-error-message';

interface WinIdentifierRowProps {
    identifier: AppIdentifier;
    index: number;
    onUpdate: (index: number, identifier: AppIdentifier) => void;
    onDelete: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    identifierWrapper: {
      marginBottom: '10px'
    },
    identifierInput: {
      height: '35px',
      backgroundColor: '#FFFF'
    },
    identifierAdvancedInput: {
      height: '35px',
      backgroundColor: '#FFFF',
      marginLeft: '10px'
    },
    inputError: {
      border: `1px solid ${styledTheme.colors.errorColor}`
    },
    deleteButton: {
      marginBottom: '3px !important'
    }
  })
);

const WinIdentifierRow = ({
    identifier,
    index,
    onUpdate,
    onDelete
}: WinIdentifierRowProps) => {
    const [ isChanged, setIsChange ] = useState<boolean>(false);
    const classes = useStyles();

    const onSimpleIdentifierChange = useCallback((event) => {
        const simpleIdentifier = event.target.value;

        setIsChange(true)
        onUpdate(index, { ...identifier, simpleIdentifier });
    }, [identifier, onUpdate])

    const onAdvancedIdentifierChange = useCallback((event) => {
        const advancedIdentifier = event.target.value;

        setIsChange(true)
        onUpdate(index, { ...identifier, advancedIdentifier });
    }, [identifier, onUpdate])

    return (
        <div className={classes.identifierWrapper}>
            <div>
                <OutlinedInput
                    type="text"
                    className={`inline ${classes.identifierInput} ${isChanged && identifier.simpleInvalid ? classes.inputError : ''}`}
                    defaultValue={identifier.simpleIdentifier}
                    onChange={onSimpleIdentifierChange}
                    placeholder="Process Name"
                />
                <OutlinedInput
                    type="text"
                    className={`inline ${classes.identifierAdvancedInput} ${isChanged && identifier.advancedInvalid ? classes.inputError : ''}`}
                    defaultValue={identifier.advancedIdentifier}
                    onChange={onAdvancedIdentifierChange}
                    placeholder="App Title"
                />
                <Button
                    customClass={`form change inline ${classes.deleteButton}`}
                    onClick={(e) => onDelete(index)}
                >
                    <DeleteIcon />
                </Button>
            </div>
            {isChanged && identifier.errorMessage && (
                <IdentifierRowErrorMessage errorMessage={identifier.errorMessage} />
            )}
        </div>
    );
};

export default WinIdentifierRow;
