import React from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
    padding: 0;
`;

export const StyledCheckedIcon = styled.span`
    //classes.icon
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid #d4dff5;
    input:hover ~ & {
        border-color: #6c89f0;
    }

    //checkedIcon
    width: 16px;
    height: 16px;
    border: none;
    background-color: #3b61eb;
    background-repeat: no-repeat;
    background-position: center;
    input:hover ~ & {
        background-color: #2c49b0;
    }

    input:disabled ~ & {
        background-color: #c5cad6;
    }
`;

export const StyledIcon = styled.span`
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid #d4dff5;

    input:hover ~ & {
        border-color: #6c89f0;
    }
`;

export const StyledIndeterminateIcon = styled.span`
    //checkedIcon
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid #d4dff5;

    input:hover ~ & {
        border-color: #6c89f0;
    }

    //classes.indeterminate

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: none;
    background-color: #3b61eb;
    input:hover ~ & {
        background-color: #2c49b0;
    }

    input:disabled ~ & {
        background-color: #c5cad6;
    }
`;

export const CustomCheckbox = (props) => {
    return (
        <StyledCheckbox
            disableRipple
            checkedIcon={<StyledCheckedIcon style={{ backgroundImage: 'url(assets/icons/check-white.svg)' }} />}
            indeterminateIcon={
                <StyledIndeterminateIcon>
                    <div
                        style={{
                            background: 'white',
                            width: '10px',
                            height: '2px',
                            borderRadius: '2px',
                        }}
                    />
                </StyledIndeterminateIcon>
            }
            icon={<StyledIcon />}
            {...props}
        />
    );
};

export default CustomCheckbox;
