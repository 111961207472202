import styled from 'styled-components';
import { EmptyResultsIcon } from '../../../assets/icons/empty-resutls';
import { Toaster } from '@walkme/ui-core';

export const StyledContractDetailsContainer = styled.div`
    padding: 24px 32px;
    background-color: #f0f3f9;
    display: flex;
    flex-flow: column;
    overflow: hidden;
`;

export const StyledDefaultContractDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .description {
        font-weight: 600;
        text-align: center;
        color: var(--Typography-Typography-Main, #2f426c);
    }
`;

export const StyledEmptyResultsIcon = styled(EmptyResultsIcon)`
    margin-bottom: 20px;
`;

export const StyledContractSystems = styled.div<{ numOfSystems: number; fadeEffect: boolean }>`
    overflow: auto;
    -webkit-mask-image: ${({ fadeEffect }) =>
        fadeEffect ? '-webkit-gradient(linear, left center, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))' : 'none'}};
    .title {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: rgb(47, 66, 108);
    }

    #contractSystems .MuiPaper-root.MuiAccordion-root {
        height: 100% !important;
        width: 100% !important;
    }

    #contractSystems .MuiPaper-root.WMDataGrid-wmDataGrid {
        width: 100% !important;
        height: ${(props) => Math.min(props.numOfSystems * 50 + 80, 300)}px !important;
    }

    #contractSystems .ag-row {
        display: flex;
        align-items: center;
    }

    #contractSystems .ag-cell {
        height: unset !important;
    }

    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;

export const StyledContractTable = styled.div`
    margin-top: 24px;
`;

export const StyledMaxSystemsOverflowMessage = styled(Toaster)`
    padding: 12px 18px;
    margin-top: 24px;
`;

export const StyledContractSystemsContent = styled.div``;

export const ReachedToBottomLine = styled.div`
    width: 100%;
    height: 1px;
    visibility: hidden;
`;
