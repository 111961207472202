import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { Environments } from 'wm-accounts-sdk';
import { CreateSystemEnvironmentDto, DeleteSystemEnvironmentDto, RestoreSystemEnvironmentDto, UpdateSystemEnvironmentDto } from './types';

class AnalyticsApi {
    private readonly httpClient: WMhttpClient;
    private readonly systemsEnvs: Record<string, Environments>;
    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
        this.systemsEnvs = {};
    }

    async createEnvironment(env: CreateSystemEnvironmentDto) {
        const result = await this.httpClient.instance.post<Environments>(`/analytics/Environments/AddPublishEnvironment`, env, {
            timeout: 120000,
        });

        if (result.data['error']) {
            throw new Error(result.data['error']);
        }
    }

    async deleteEnvironment(env: DeleteSystemEnvironmentDto) {
        const result = await this.httpClient.instance.post<Environments>(
            `/analytics/Environments/MarkEnvironmentAsDeleted`,
            { environmentId: env.envId, systemId: env.systemId },
            { timeout: 120000 }
        );

        if (result.data['error']) {
            throw new Error(result.data['error']);
        }
    }

    async restoreEnvironment(env: RestoreSystemEnvironmentDto) {
        const result = await this.httpClient.instance.post<Environments>(
            `/analytics/Environments/RestoreEnvironment`,
            { environmentId: env.envId, systemId: env.systemId },
            { timeout: 120000 }
        );

        if (result.data['error']) {
            throw new Error(result.data['error']);
        }
    }

    async updateEnvironment(env: UpdateSystemEnvironmentDto) {
        const result = await this.httpClient.instance.post<Environments>(`/analytics/Environments/UpdatePublishEnvironment`, env, {
            timeout: 120000,
        });

        if (result.data['error']) {
            throw new Error(result.data['error']);
        }
    }

    async getSystemEnvironments(systemId: number, force?: boolean, getDeleted?: boolean): Promise<Environments> {
        if (!force) {
            const cachedSystemEnvs = this.systemsEnvs[systemId];
            if (cachedSystemEnvs) return cachedSystemEnvs;
        }
        const response = await this.httpClient.instance.get<any>(`/analytics/Environments/GetSystemEnvironmentsData`, {
            params: { getDeleted, systemId, getInternalEnvironments: true },
            data: {
                systemId: systemId,
            },
        });

        if (response.data['error']) {
            console.error(response.data['error']);
            return [];
        }

        const systemEnvs: Environments = response.data.map((env) => {
            return {
                createdAt: env.CreatedAt,
                name: env.Name,
                id: env.Id,
                updatedAt: env.UpdatedAt,
                userId: env.UserId,
                path: env.Path,
                isDeleted: env.IsDeleted,
                isDev: env.IsDev,
            };
        });

        // todo: The filtering of dev environments should happen in the endpoint according to the backoffice status of the user
        const filteredSystemEnvs = (systemEnvs && systemEnvs.filter((env) => !env.isDev)) || [];
        this.systemsEnvs[systemId] = filteredSystemEnvs;

        return filteredSystemEnvs;
    }
}

export const analyticsApi = new AnalyticsApi(wmhttpClientSharedInstance);
