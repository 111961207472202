import styled from 'styled-components';

export const StyledSubMenu = styled.div<{ isOpen: boolean }>`
    border-radius: 5px;
    width: 100%;
    color: var(--Color-Local-Semantic-Text-primary, #637191);
    font-size: 14px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    background: ${({ isOpen }) => (isOpen ? 'var(--Primaries-Blue-P8, #F5F7FE)' : 'none')};
    padding-bottom: 8px;

    & .header:hover {
        background: var(--Primaries-Blue-P8, #f5f7fe);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 8px 10px;
        border-radius: 5px;
        cursor: pointer;

        .content {
            display: flex;
            gap: 10px;
            .title {
                line-height: 1.6;
            }
        }
    }
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
    padding: 10px 20px;
    cursor: pointer;
    color: ${({ isSelected }) => (isSelected ? 'var(--Color-Local-Semantic-Bg-accent, #385FEB)' : 'init')};
    border-radius: 5px;
    background: ${({ isSelected }) => (isSelected ? 'var(--Primaries-Blue-P6, #E3E9FC)' : 'init')};

    &:hover {
        background: var(--Primaries-Blue-P7, #edf1fd);
    }
`;
