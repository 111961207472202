import { Select } from '@material-ui/core';
import styled from 'styled-components';
import { IconButton } from '@walkme/ui-core';
import { Restore } from '@material-ui/icons';

export const StyledIconButton = styled(IconButton)`
    padding: 0;
    margin-left: 2px;
`;

export const StyledRestore = styled(Restore)`
    padding: 0;
    margin-left: 2px;
`;

export const StyledTypeSelect = styled(Select)`
    .root {
        width: 112px;
        padding: 7px 0;
    }

    .selectMenuItem {
        & .MuiMenu-paper {
            max-height: 250px;
            margin-top: 8px;
            border-radius: 10px;
            box-shadow: 0px 10px 25px #121b4e1c;
        }

        &.one-id-select .MuiMenu-paper {
            max-height: 144px;
        }
    }
`;
