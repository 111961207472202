import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WMSelect } from '@walkme/wm-ui';
import { ControlHeader } from '../../../../../../../extension-pages/src/lib/new-design/shared-styled-components';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import { getNotDevEnvironmentsFromSystemDataEnvironments } from '@walkme-admin-center/pages/home/extension-pages';
import useSystemExtensionData from '../../../../../../../../../libs/state-management-systems/src/lib/hooks/use-system-extension-data';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { Environment } from '../../../../../../../extension-pages/src/lib/types';
import { getMinimalEnvById } from '../../../../../../../extension-pages/src/lib/utils';
import { StyledDropDownContainer } from './environment-selector-control.styles';
import { CreateSystemSteps } from '../../../../../../../../../libs/state-management-systems/src/lib/types/createSystem';
import cloneDeep from 'lodash/cloneDeep';
import { UnsavedChangesDialog } from './unsaved-changes-dialog';
import { isEqual } from 'lodash';

export const EnvironmentSelectorControl = () => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const { systemExtensionData } = useSystemExtensionData(createdSystem?.id);
    const environmentOptions = getNotDevEnvironmentsFromSystemDataEnvironments(systemExtensionData?.systemData?.environments);
    const selectedEnvironment = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedEnvironment
    );
    const selectedEnvMatcher = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedEnvironmentMatcher
    );
    const stepErrors = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.stepErrors);
    const environmentMatchers = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.environmentMatchers
    );
    const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState<boolean>(false);
    const [newEnvironment, setNewEnvironment] = useState<Environment | null>(null);
    const [initialSelectedEnvMatcher, setInitialSelectedEnvMatcher] = useState(null);

    const setDefaultSelectedEnvironment = () => {
        if (!selectedEnvironment) {
            dispatch(createSystemSlice.actions.setSelectedEnvironment(getMinimalEnvById(environmentOptions)));
        }
    };

    const onSelectedEnvironmentChange = (newEnv: Environment) => {
        setNewEnvironment(newEnv);
        if (newEnv.value === selectedEnvironment.value) {
            return;
        }

        if (isEqual(initialSelectedEnvMatcher, selectedEnvMatcher)) {
            setInitialSelectedEnvMatcher(null);
            dispatch(createSystemSlice.actions.setSelectedEnvironment(newEnv));
            updateLoadingActiveStatus(false);
        } else {
            setUnsavedChangesDialogOpen(true);
        }
    };

    const updateLoadingActiveStatus = (status: boolean) => {
        let clonedEnvMatchers = cloneDeep(environmentMatchers);
        clonedEnvMatchers[selectedEnvironment?.value][0].isActive = status;
        dispatch(createSystemSlice.actions.setEnvironmentMatchers(clonedEnvMatchers));
    };

    const onUnsavedChangesCancel = () => {
        setUnsavedChangesDialogOpen(false);
    };

    const onUnsavedChangesConfirm = () => {
        setInitialSelectedEnvMatcher(null);
        dispatch(createSystemSlice.actions.setSelectedEnvironment(newEnvironment));
        setUnsavedChangesDialogOpen(false);
        updateLoadingActiveStatus(false);
        // INFO: reset environmentMatchers to initial value
        dispatch(createSystemSlice.actions.setEnvironmentMatchers(systemExtensionData?.systemData?.matchers));
    };

    const validateSelectedEnvMatcher = () => {
        const currentStepErrors = stepErrors[CreateSystemSteps.CONFIGURE_SYSTEM];
        const modifiedStepErrors = {
            ...stepErrors,
            [CreateSystemSteps.CONFIGURE_SYSTEM]: {
                ...currentStepErrors,
                selectedEnvMatcher: selectedEnvMatcher?.isActive
                    ? selectedEnvMatcher?.isRegex
                        ? selectedEnvMatcher?.selfRegex === ''
                        : selectedEnvMatcher?.selfDomains?.length === 0
                    : null,
            },
        };
        dispatch(createSystemSlice.actions.setStepErrors(modifiedStepErrors));
    };

    const saveInitialSelectedEnvMatcher = () => {
        if (!initialSelectedEnvMatcher && selectedEnvMatcher) {
            setInitialSelectedEnvMatcher(selectedEnvMatcher);
        }
    };

    useEffect(saveInitialSelectedEnvMatcher, [selectedEnvMatcher]);

    useEffect(setDefaultSelectedEnvironment, [systemExtensionData]);

    useEffect(validateSelectedEnvMatcher, [selectedEnvMatcher]);

    return (
        <>
            <ControlHeader>{t('systems-tab.all-systems-page.extension-page.select-env')}</ControlHeader>
            <StyledDropDownContainer>
                <WMSelect
                    value={environmentOptions.find((envOption) => envOption?.value === selectedEnvironment?.value)}
                    onChange={onSelectedEnvironmentChange}
                    isDisabled={false}
                    options={environmentOptions}
                />
            </StyledDropDownContainer>
            {unsavedChangesDialogOpen && (
                <UnsavedChangesDialog
                    isOpen={unsavedChangesDialogOpen}
                    onCancel={onUnsavedChangesCancel}
                    onConfirm={onUnsavedChangesConfirm}
                />
            )}
        </>
    );
};
