import React, { useCallback, useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FooterActions, FooterContainer, SmallerText } from './footer-style';
import { useAskMeForm } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { InfoBox } from '../../../common/components/info-box';
import IdpDialog from 'packages/pages/home/sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';

export interface IEditCrawlerFooter {
    onClose: () => void;
}

const RESCAN_FIELDS = [
    'settings.startingUrl',
    'settings.includeSubDomain',
    'settings.controlDomains',
    'settings.allowPath',
    'settings.denyPath',
    'settings.cssInclude',
    'settings.cssExclude',
    'settings.authType',
    'settings.controlCss',
    'settings.delay',
    'settings.controlDelay',
    'settings.auth',
    'settings.includeHash',
];

export const EditCrawlerFooter = ({ onClose }: IEditCrawlerFooter) => {
    const [loading, setLoading] = useState(false);
    const formApi = useForm();
    const { dirty, invalid, dirtyFields } = formApi.getState();

    const handleSubmit = async () => {
        if (
            Object.keys(dirtyFields).some((dirtyField) =>
                RESCAN_FIELDS.some((rescanField) => rescanField === dirtyField || dirtyField.startsWith(`${rescanField}.`))
            )
        ) {
            const submit = async (rescan = false) => {
                formApi.change('rescan', rescan);
                await formApi.submit();
            };

            setConfirmData({
                showConfirm: true,
                buttonText: 'Save and Rescan',
                secondaryButtonText: 'Save',
                title: 'Rescan content?',
                type: 'defaultButton',
                content: (
                    <>
                        With the updated information, you are now able to initiate a rescan of the source.
                        <br />
                        <br />
                        <SmallerText>
                            Note: Clicking Save and Rescan" will initiate a new rescan, even if one is already in progress.
                        </SmallerText>
                    </>
                ),
                confirm: () => submit(true),
                onSecondaryClick: () => submit(),
            });
        } else {
            setLoading(true);
            await formApi.submit();
            setLoading(false);
        }
    };

    const { askMeForm } = useAskMeForm();
    const disabled = !dirty || invalid || askMeForm.isFooterDisabled;

    const [confirmData, setConfirmData] = useState({
        showConfirm: false,
        content: null,
        confirm: null,
        title: '',
        buttonText: '',
        type: '',
        secondaryButtonText: '',
        onSecondaryClick: null,
    });

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={confirmData.showConfirm}
                handleClose={() => setConfirmData({ ...confirmData, showConfirm: false })}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={confirmData.type || 'defaultButton'}
                buttonText={confirmData.buttonText || 'I understand, continue'}
                cancelButtonText={'Cancel'}
                secondaryButtonText={confirmData.secondaryButtonText}
                onSecondaryClick={confirmData.onSecondaryClick}
            />
        );
    }, [confirmData]);

    return (
        <>
            {confirmData.showConfirm && triggerConfirm()}
            <FooterContainer>
                <div>
                    <Collapse in={!disabled}>
                        <InfoBox>
                            The knowledge source will be available in all system environments without requiring a settings publish.
                        </InfoBox>
                    </Collapse>
                </div>
                <FooterActions>
                    <WMButton onClick={() => onClose()} variant={WMButtonVariant.Text}>
                        Cancel
                    </WMButton>
                    <WMButton onClick={handleSubmit} disabled={disabled} loading={loading}>
                        Save Changes
                    </WMButton>
                </FooterActions>
            </FooterContainer>
        </>
    );
};

export default EditCrawlerFooter;
