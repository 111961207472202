import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { Extension } from './types';

enum ExtensionStatus {
    Exists,
    NotExists,
    ExistsNeedsMigration,
}

type UserExtensionData = {
    extension?: Extension;
    status: ExtensionStatus;
};

type DomainToRegexData = {
    domain: string;
    regex: string;
};

class ExposureApi {
    constructor(private httpClient: WMhttpClient) {}

    async getUserExtension(): Promise<Extension | undefined> {
        const response = await this.httpClient.instance.post<UserExtensionData>(`/extensions/userExtension/getUserExtension`);

        return response.data.extension;
    }

    async getUserExtensionStatus(): Promise<ExtensionStatus | undefined> {
        const response = await this.httpClient.instance.post<UserExtensionData>(`/extensions/userExtension/getUserExtension`);

        return response.data?.status;
    }

    async convertDomainsToRegexes(domains: string[]): Promise<DomainToRegexData[] | undefined> {
        const response = await this.httpClient.instance.post<DomainToRegexData[]>(`extensions/converter/convertDomainToRegex`, { domains });

        return response.data;
    }
}

const exposureApi = new ExposureApi(wmhttpClientSharedInstance);

export { exposureApi, DomainToRegexData };
