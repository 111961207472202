import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExtensionState, getUserExtension } from '../redux/extensions.slice';

export const useUserExtension = () => {
    const extensionData = useSelector((state: { extensionState: ExtensionState }) => state.extensionState.extension);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserExtension());
    }, [dispatch]);

    return {
        extensionData,
    };
};

export default useUserExtension;
