import styled from 'styled-components';
import { Button, Dialog } from '@walkme/ui-core';
import { Close } from '@walkme/ui-icons/large';

export const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        height: 75vh !important;
        width: 68vw !important;
        max-width: unset !important;
    }

    .MuiDialogContent-root {
        padding: 0 !important;
        overflow-y: hidden !important;
        display: flex !important;
        flex-flow: column !important;
    }
`;

export const StyledPrimaryActionButton = styled(Button)`
    margin-left: 20px !important;
`;

export const StyledSelectedAppContainer = styled.div`
    position: absolute;
    display: flex;
    left: 32px;
    color: #2f426c;
    align-items: center;

    .selectedAppIcon {
        margin-right: 8px;
    }
`;

export const StyledDialogTitle = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 32px 16px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
`;

export const CloseIcon = styled(Close)`
    cursor: pointer;
`;

export const StyledExternalLink = styled.a`
    text-decoration: none;
    gap: 10px;
    display: flex;
    position: absolute;
    left: 32px;

    .link-typography {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
    }
`;
