import styled from 'styled-components';

export const StyledAppListContainer = styled.div`
    padding: 16px 32px;
    overflow-y: auto;
    flex: 1;
`;

export const StyledAppList = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
`;

export const StyledLoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    flex: 1;
    padding: 16px 32px;
`;
