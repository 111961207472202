import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { AccountsSdk, SystemTypeKey, WmFetchPolicy } from 'wm-accounts-sdk';

export interface DesktopSystemsExistsState {
    desktopSystemsExists: AppData<boolean>;
}

const initialDesktopTrackedAppsState: DesktopSystemsExistsState = {
    desktopSystemsExists: {
        error: null,
        loading: true,
        data: null,
    },
};

const desktopSystemsExistsSlice = createSlice({
    name: 'desktopSystemsExistsSlice',
    initialState: initialDesktopTrackedAppsState,
    reducers: {
        getDesktopSystemsExistsStart(state: DesktopSystemsExistsState) {
            state.desktopSystemsExists.error = null;
            state.desktopSystemsExists.loading = true;

            return state;
        },
        getDesktopSystemsExistsSuccess(state: DesktopSystemsExistsState, action: PayloadAction<boolean>) {
            state.desktopSystemsExists.data = action.payload;
            state.desktopSystemsExists.loading = false;
            state.desktopSystemsExists.error = null;

            return state;
        },
        getDesktopSystemsExistsFailed(state, action: PayloadAction<string>) {
            state.desktopSystemsExists.loading = false;
            state.desktopSystemsExists.error = action.payload;

            return state;
        },
    },
});

export { desktopSystemsExistsSlice };
type DesktopSystemsExistsStateType = ReturnType<typeof desktopSystemsExistsSlice.reducer>;

const { getDesktopSystemsExistsStart, getDesktopSystemsExistsSuccess, getDesktopSystemsExistsFailed } = desktopSystemsExistsSlice.actions;

type AppThunk = ThunkAction<void, DesktopSystemsExistsStateType, unknown, Action<string>>;

export const getDesktopSystemsExists = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getDesktopSystemsExistsStart());

        const systems = await AccountsSdk.getInstance().getAccountSystems(WmFetchPolicy.network, [SystemTypeKey.Workstation], true);

        dispatch(getDesktopSystemsExistsSuccess(systems.length > 0));
    } catch (err) {
        dispatch(getDesktopSystemsExistsFailed(err.message));

        return;
    }
};
