import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { useLocation } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { StyledLink } from './styles/styles';

export interface NavBarProps {
    menuItems: Record<string, string>[];
}

export const NavBar = ({ menuItems }: NavBarProps) => {
    const location = useLocation();

    return (
        <List style={{ display: 'inline-flex' }}>
            {menuItems.map((item, i) => {
                return (
                    <ListItem
                        key={i}
                        className="listItem"
                        button
                        component={StyledLink}
                        to={item.route}
                        selected={location.pathname === item.route}
                    >
                        <ListItemText className="listItemText" primary={item.name} />
                    </ListItem>
                );
            })}
        </List>
    );
};
