import React, { useState } from 'react';
import { Field, useForm } from 'react-final-form';
import {
    CssSwitch,
    CssTextField,
    FieldLabel,
    FieldLabelLight,
    SwitchLabel,
    TextFieldContainer,
} from '../../../../data-integration-form-style';
import styles from '../../../../../common/style.module.scss';
import { CssSourceNameDivider, CssDomainDivider, StyledToggleButtonGroup, StyledToggleButton } from './domain-step-style';
import SubDomains from './sub-domains';
import { isValidPath } from '../../../../services/validation/validate';
import { IAskMeFormUrlPath } from '../../../../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { getToolTip } from '../../../../../common/services';
import { useGlobalSettings } from '@walkme-admin-center/libs/state-mangment-data-integration';

const tooltips = {
    AuditAllSubdomains: 'When enabled, the crawler audits all sub-links under the main domain.',
    IncludeHash: 'Crawls URLs with hash fragments (e.g., "example.com/page#section").',
    ControlURLsToAudit: 'By enabling this option, you can specify a list of specific URLs that the crawler will focus on.',
    ControlCssSelectorToAudit: 'Specify CSS selectors for targeted auditing.',
};

export const DomainStep = () => {
    const { isAdminMode } = useGlobalSettings();
    const [domainsToggle, setDomainsToggle] = useState('includeDomains');
    const formApi = useForm();

    const domainsToggleChange = (event, value: string): void => {
        if (value && value !== domainsToggle) {
            setDomainsToggle(value);
        }
    };

    const controlDomainsChange = (isChecked: boolean): void => {
        const returnValidArray = (arr: Array<IAskMeFormUrlPath>): Array<IAskMeFormUrlPath> => {
            if (!arr) {
                return [];
            }
            return arr.reduce((aggr, str) => {
                if (str && isValidPath(str)) {
                    aggr.push(str);
                    return aggr;
                }
                return aggr;
            }, []);
        };
        setDomainsToggle('includeDomains');
        formApi.change('settings.controlDomains', isChecked);
        if (!isChecked) {
            const { allowPath, denyPath } = formApi.getState().values.settings;
            formApi.change('settings.allowPath', returnValidArray(allowPath));
            formApi.change('settings.denyPath', returnValidArray(denyPath));
        }
    };

    return (
        <div style={{ padding: '24px 32px' }} className={styles.flexColumn}>
            <TextFieldContainer>
                <FieldLabel>Knowledge source name</FieldLabel>
                <Field name={'name'}>
                    {({ input, meta }) => (
                        <CssTextField
                            variant='outlined'
                            placeholder={'E.g. HR Policies'}
                            fullWidth
                            error={!!meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            {...input}
                        />
                    )}
                </Field>
            </TextFieldContainer>
            <CssSourceNameDivider />
            <TextFieldContainer className={styles.flexColumn}>
                <FieldLabel>Enter starting URL</FieldLabel>
                <Field name={'settings.startingUrl'}>
                    {({ input, meta }) => (
                        <CssTextField
                            variant='outlined'
                            placeholder={'e.g https://support.walkme.com'}
                            fullWidth
                            error={!!meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            disabled={!isAdminMode}
                            {...input}
                        />
                    )}
                </Field>
            </TextFieldContainer>
            <div className={styles.flexColumn} style={{ paddingTop: '16px' }}>
                <div className={styles.flexColumn}>
                    <Field name={'settings.includeSubDomain'} type={'checkbox'}>
                        {({ input, meta }) => (
                            <div className={`${styles.flexRow} ${styles.center}`} style={{ height: '24px', paddingBottom: '12px' }}>
                                <CssSwitch disableRipple disabled={!isAdminMode} {...input} />
                                <SwitchLabel className={`${input.checked ? 'active' : ''}`}>Audit all Subdomains</SwitchLabel>
                                {getToolTip(tooltips.AuditAllSubdomains)}
                            </div>
                        )}
                    </Field>
                    <CssDomainDivider />
                </div>
                <div className={styles.flexColumn}>
                    <Field name={'settings.includeHash'} type={'checkbox'}>
                        {({ input, meta }) => (
                            <div className={`${styles.flexRow} ${styles.center}`} style={{ height: '48px' }}>
                                <CssSwitch disableRipple disabled={!isAdminMode} {...input} />
                                <SwitchLabel className={`${input.checked ? 'active' : ''}`}>Include hash</SwitchLabel>
                                {getToolTip(tooltips.IncludeHash)}
                            </div>
                        )}
                    </Field>
                    <CssDomainDivider />
                </div>
                <div className={styles.flexColumn}>
                    <Field name={'settings.controlDomains'} type={'checkbox'}>
                        {({ input, meta }) => (
                            <div className={`${styles.flexColumn}`} style={{ padding: '12px 0' }}>
                                <div className={`${styles.flexRow} ${styles.center}`} style={{ height: '24px' }}>
                                    <CssSwitch
                                        disableRipple
                                        disabled={!isAdminMode}
                                        {...input}
                                        onChange={(event) => controlDomainsChange(event.target.checked)}
                                    />
                                    <SwitchLabel className={`${input.checked ? 'active' : ''}`}>Control URLs to audit</SwitchLabel>
                                    {getToolTip(tooltips.ControlURLsToAudit)}
                                </div>
                                {input.checked && (
                                    <div style={{ padding: '16px 0 0 36px' }} className={styles.flexColumn}>
                                        <div className={`${styles.flexRow} ${styles.center}`}>
                                            <StyledToggleButtonGroup exclusive value={domainsToggle} onChange={domainsToggleChange}>
                                                <StyledToggleButton disableRipple value='includeDomains'>
                                                    Include
                                                </StyledToggleButton>
                                                <StyledToggleButton disableRipple value='excludeDomain'>
                                                    Exclude
                                                </StyledToggleButton>
                                            </StyledToggleButtonGroup>
                                            <span style={{ paddingLeft: '8px', color: '#2F426C' }}>the following URL’s while running</span>
                                        </div>
                                        {domainsToggle === 'includeDomains' && (
                                            <SubDomains
                                                fieldName={'settings.allowPath'}
                                                placeHolder={'insert path to include'}
                                                disabled={!isAdminMode}
                                            />
                                        )}
                                        {domainsToggle === 'excludeDomain' && (
                                            <SubDomains
                                                fieldName={'settings.denyPath'}
                                                placeHolder={'insert path to exclude'}
                                                disabled={!isAdminMode}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </Field>
                    <CssDomainDivider />
                </div>
                <div className={styles.flexColumn}>
                    <Field name={'settings.controlCss'} type={'checkbox'}>
                        {({ input, meta }) => (
                            <div className={`${styles.flexColumn}`} style={{ padding: '12px 0' }}>
                                <div className={`${styles.flexRow} ${styles.center}`} style={{ height: '24px' }}>
                                    <CssSwitch disableRipple disabled={!isAdminMode} {...input} />
                                    <SwitchLabel className={`${input.checked ? 'active' : ''}`}>Control CSS selector to audit</SwitchLabel>
                                    {getToolTip(tooltips.ControlCssSelectorToAudit)}
                                </div>
                                {input.checked && (
                                    <div style={{ padding: '16px 0 0 0' }} className={styles.flexColumn}>
                                        <div style={{ height: '84px' }}>
                                            <FieldLabelLight>Include only the following HTML matching CSS Selector</FieldLabelLight>
                                            <Field name={'settings.cssInclude'}>
                                                {({ input, meta }) => (
                                                    <CssTextField
                                                        variant='outlined'
                                                        placeholder={'Insert CSS Selector to include'}
                                                        fullWidth
                                                        disabled={!isAdminMode}
                                                        {...input}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div style={{ height: '84px' }}>
                                            <FieldLabelLight>Exclude only the following HTML matching CSS Selector </FieldLabelLight>
                                            <Field name={'settings.cssExclude'}>
                                                {({ input, meta }) => (
                                                    <CssTextField
                                                        variant='outlined'
                                                        placeholder={'Insert CSS Selector to exclude'}
                                                        fullWidth
                                                        disabled={!isAdminMode}
                                                        {...input}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Field>
                    <CssDomainDivider />
                </div>
            </div>
        </div>
    );
};

export default DomainStep;
