import { Contract } from '@walkme-admin-center/libs/state-management-systems';
import { RTFunction } from 'apps/home/src/localization';
import { TFunction } from 'i18next';

export const getContractNumOfSystemsInUse = (contract: Contract, systems: any) => {
    return systems?.reduce((acc, system) => {
        if (system.contracts?.includes(contract?.sfId)) {
            acc++;
        }
        return acc;
    }, 0);
};

export const getContractNumOfPurchasedSystems = (contract: Contract, rt: RTFunction): string | number => {
    return Number.isNaN(Number(contract?.purchasedSystems)) ? rt(contract?.purchasedSystems) : Number(contract?.purchasedSystems);
};

export const isContractRecommended = (contract: Contract, systems: any, rt: RTFunction): boolean => {
    const numOfSystemsInUse = getContractNumOfSystemsInUse(contract, systems);
    const numOfPurchasedSystems = getContractNumOfPurchasedSystems(contract, rt);

    return typeof numOfPurchasedSystems === 'number'
        ? numOfSystemsInUse + 1 <= numOfPurchasedSystems
        : typeof numOfPurchasedSystems === 'string';
};
