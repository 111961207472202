import styled from 'styled-components';
import { DropdownSelection } from '@walkme/ui-core';
import { Close as Clear } from '@walkme/ui-icons';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 32px;
        margin: 0;
    }
`;

export const DateRange = styled.div`
    &.WMDateRange-explicitButtons {
        justify-content: center !important;
    }
`;

export const RoleButtonText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

export const DateRangeText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
`;

export const RolesSelectorDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledClear = styled(Clear)`
    cursor: pointer;
`;
