import styled from 'styled-components';

export const PageTitle = styled.h1`
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
`;

export const StyledPageTitle = styled.h1`
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0px;
`;

export default PageTitle;
