import styled from 'styled-components';
import { WMDataGrid, WMDataGridActionColumn } from '@walkme/wm-ui';

export const StyleEditableContent = styled('span')`
    padding: 5px;
    line-height: 20px;
    border: 1px solid transparent;

    &.editable:hover {
        border: 1px dashed #385feb;
        border-radius: 5px;
    }
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
    width: 100%;
    --ag-selected-row-background-color: #edf1fd;

    & .WMDataGridHeader-wmDataGridHeader {
        display: none !important;
    }

    & .ag-header {
        border-bottom-color: #e4e9fc;
    }

    & .ag-row {
        border-color: #e4e9fc;
    }

    & .WMButton-wmButton:hover {
        background-color: #3b61eb0d;
        color: #385feb;
    }

    & .WMDataGridActionColumn-open .WMButton-wmButton {
        background-color: #3b61eb0d;
        color: #2b47ac;
    }
`;

export const StyleWMDataGridActionColumn = styled(WMDataGridActionColumn)`
    & .MuiButtonBase-root {
        position: relative;
        bottom: 2px;
        width: 28px;
        height: 28px;
    }
`;

export const DateText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

export const TimeText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const MultiLineMenuLabel = styled.div`
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
        font-size: 12px;
        color: #8d97ae;
    }
`;
