import { AppData, NotificationMessage } from '@walkme-admin-center/libs/types';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { PermissionsErrorkey } from '@walkme-admin-center/libs/common';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import { ThunkAction } from 'redux-thunk';
import { getTranslator } from 'apps/home/src/localization/localizationBase';
import { systemAppService } from '../services/system-app.service';

const t = getTranslator('general');

export interface SystemAppsConfigsState {
    systemAppsConfigs: AppData<any>;
    notificationMessage: NotificationMessage;
}

export const initialState = {
    systemAppsConfigs: {
        data: [],
        error: null,
        loading: false,
    },
    notificationMessage: {
        text: '',
        variant: WMSnackbarVariant.Success,
        isOpen: false,
    },
};

const updateStateWithNotificationMessage = ({ state, text, variant, isOpen }) => {
    const message = text === 'Forbidden resource' ? t(PermissionsErrorkey) : text;
    state.notificationMessage.text = message;
    state.notificationMessage.variant = variant;
    state.notificationMessage.isOpen = isOpen;
};

const systemAppsConfigsSlice = createSlice({
    name: 'systemAppsConfigsSlice',
    initialState: initialState,
    reducers: {
        getSystemAppsConfigsStart: (state: SystemAppsConfigsState) => {
            state.systemAppsConfigs.loading = true;
            return state;
        },
        getSystemAppsConfigsSuccess: (state: SystemAppsConfigsState, action: PayloadAction<any>) => {
            state.systemAppsConfigs.data = action.payload;
            state.systemAppsConfigs.loading = false;
            return state;
        },
        getSystemAppsConfigsFailed: (state: SystemAppsConfigsState, action: PayloadAction<any>) => {
            updateStateWithNotificationMessage({ state, text: action.payload, variant: WMSnackbarVariant.Error, isOpen: true });
            state.systemAppsConfigs.loading = false;
            return state;
        },
        resetInitialState: (state: SystemAppsConfigsState) => {
            state.notificationMessage.isOpen = false;
            state.systemAppsConfigs.loading = false;
            state.systemAppsConfigs.data = [];
        },
    },
});

export { systemAppsConfigsSlice };

const { getSystemAppsConfigsStart, getSystemAppsConfigsSuccess, getSystemAppsConfigsFailed } = systemAppsConfigsSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk<ReturnType = void> = ThunkAction<ReturnType, rootReducerType, unknown, Action<string>>;

export const getSystemAppsConfigs = (): AppThunk => async (dispatch, getState) => {
    try {
        if (getState().systemAppsConfigsState.systemAppsConfigs.data.length > 0) {
            return;
        }
        dispatch(getSystemAppsConfigsStart());

        const response = await systemAppService.getSystemAppsConfigs();

        if (response) {
            dispatch(getSystemAppsConfigsSuccess(response));
        }
    } catch (error) {
        dispatch(getSystemAppsConfigsFailed(error.message));
    }
};
