import styled from 'styled-components';
import { MenuItem, TextField, Switch } from '@material-ui/core';
import { Switches, TextField as MUITextField } from 'mui-rff';
import IdpButton from './components/idp-button/idp-button';

export const StyledTabDescription = styled.span`
    color: #7680a3;

    .link {
        color: #3b61eb;
        cursor: pointer;
    }
`;

export const StyledCustomMenuItem = styled(MenuItem)`
    .root {
        &:hover {
            background-color: #f0f4fa !important;
        }

        &.Mui-selected {
            background-color: #f0f4fa;
        }
    }
`;

export const StyledButton = styled(IdpButton)`
    border-radius: 17px;
    height: 32px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 16px;
    max-width: 250px;
    box-shadow: none;
    border-color: #e1e9f5;
    align-self: start;
    background-color: #3b61eb;
    color: #ffffff;
    text-transform: none;
    &.footer-button {
        height: 40px;
        border-radius: 20px;
    }
    &.get-prop-button {
        margin-top: 16px;
        font-size: 12px;
    }
    &.Mui-disabled {
        background: #c5cad6;
        color: #ffffff;
    }
    &:hover {
        background-color: #2c49b0;
        box-shadow: none;
    }
`;

export const StyledTransparentButton = styled(IdpButton)`
    border-radius: 17px;
    background: none;
    height: 32px;
    padding: 0 16px;
    max-width: 250px;
    box-shadow: none;
    border: 1px solid #dfe5f2;
    color: #3b61eb;
    text-transform: none;
    &.d-metadata-Bt {
        height: 28px;
        width: 141px;
        font-size: 10px;
        font-weight: bold;
        padding: 0 14px;
    }
    &:hover {
        border-color: #3b61eb;
        background: none;
        box-shadow: none;
    }
`;

export const CancelButton = styled(IdpButton)`
    border-radius: 20px;
    height: 40px;
    width: 83px;
    margin-right: 12px;
    padding: 0 16px;
    background: none;
    color: #637191;
    text-transform: none;
    box-shadow: none;
    &:hover {
        background: none;
        box-shadow: none;
    }
`;

export const StyledInputLabel = styled.span`
    font-size: 14px;
    font-weight: 600;
    height: 17px;
    padding-bottom: 8px;
    &.flex-center {
        display: flex;
        align-items: center;
    }
`;

export const StepSectionHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    & .title {
        font-size: 20px;
        color: #2f426c;
        font-weight: 600;
    }
    & .title-helper {
        font-size: 14px;
        color: #8d97ae;
        padding-top: 8px;
    }
`;

export const HelperIcon = styled.div`
    height: 14px;
    width: 14px;
    background: #f0f4fa;
    border-radius: 50%;
    margin-left: 8px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
`;

export const SelectWrapper = styled.div`
    .MuiInputBase-root.MuiOutlinedInput-root {
        .MuiSelect-root {
            background: #f6f8fc;
        }
        .MuiOutlinedInput-notchedOutline {
            border-width: 0;
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
        .MuiSelect-root {
            background: #ffffff;
        }
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-focused):not(.Mui-disabled) {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #354b9b40;
        }
    }
`;

export const CustomTag = styled.div`
    height: 12px;
    display: flex;
    align-items: center;
    background: #e7edf9;
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 4px;
    margin-left: 12px;
    color: #637191;
`;

export const StyledCSSSearchField = styled(TextField)`
    border-radius: 16px;
    & .MuiInputBase-root {
        height: 36px;
        border-radius: 16px;
        & .MuiOutlinedInput-notchedOutline {
            border-width: 0;
        }
        &:hover:not(.Mui-focused):not(.Mui-disabled) {
            & .MuiOutlinedInput-notchedOutline {
                border-width: 1px;
                border-color: #354b9b40;
            }
        }
        &.Mui-focused {
            & .MuiOutlinedInput-notchedOutline {
                border-width: 1px;
            }
        }
    }
    &.list-search {
        margin-bottom: 24px;
        width: 280px;
        align-self: end;
        background: white;
    }
    &.fields-search {
        margin: 16px 0;
        width: 100%;
        background: #f6f8fc;
    }
    &.assign-search {
        width: 280px;
        background: #f6f8fc;
    }
`;

export const CustomSwitches = styled(Switches)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#3B61EB',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: 'width 2s',
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#C5CAD6',
        boxSizing: 'border-box',
    },
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#3B61EB',
            },
            '&.Mui-disabled': {
                opacity: 0.5,
                pointerEvents: 'auto',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: 'width 2s',
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#C5CAD6',
        boxSizing: 'border-box',
    },
}));

export const StyledTextField = styled(TextField)`
    height: 40px;
    width: 100%;

    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 12px 14px !important;
        background: #f6f8fc;
    }

    &.alias-text {
        height: 30px;
    }

    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }

        .MuiInputBase-input.MuiOutlinedInput-input {
            background: #ffffff;
        }
    }

    .Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #f44336;
        }

        .MuiInputBase-input.MuiOutlinedInput-input {
            background: #fef5f5;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-width: 0;
    }

    .MuiInputBase-root:hover:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled) {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #354b9b40;
        }
    }

    .MuiFormHelperText-root {
        margin: 0 6px;
    }
`;

export const StyledMUITextField = styled(MUITextField)`
    height: 40px;

    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 12px 14px;
        background: #f6f8fc;
    }

    &.alias-text {
        height: 30px;
    }

    & .Mui-focused {
        & .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }

        & .MuiInputBase-input,
        .MuiOutlinedInput-input {
            background: #ffffff;
        }
    }

    & .Mui-error {
        & .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }

        & .MuiInputBase-input,
        .MuiOutlinedInput-input {
            background: #fef5f5;
        }
    }

    & .MuiOutlinedInput-notchedOutline {
        border-width: 0;
    }

    & .MuiInputBase-root:hover:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled) {
        & .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #354b9b40;
        }
    }

    & .MuiFormHelperText-root {
        margin: 0 6px;
    }
`;

export const CodeFragment = styled.pre`
    padding: 5px;
    border-radius: 3px;
    font-size: 9px;
    background: #f4f4f4;
    color: #505050;
`;
