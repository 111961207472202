import React, { useState } from 'react';
import { Parser } from '@json2csv/plainjs';
import { useForm } from 'react-final-form';
import { FooterActionsSubContainer, ResetButtonContainer } from './footer-style';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { DemoIcon, LightBulbIcon, ShareIcon } from '../../../data-integrations-list/integration-card/icons/icons';
import { IGuidedAnswers } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { useAskMeForm } from '@walkme-admin-center/libs/state-mangment-data-integration';
import IdpDialog, { IdpDialogProps } from 'packages/pages/home/sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import styled from 'styled-components';
import { CustomToolTipHover } from '../../../common/components/custom-tool-tip';
import { CssCheckBox } from '../../../data-integrations-list/integration-row/integration-row-style';
import { CONSTS } from 'packages/pages/home/sso-configuration/src/lib/common/consts';

const DONT_SHOW_PUBLISH_DIALOG_KEY = 'dont-show-publish-dialog';

const DialogContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const DialogNoticeContainer = styled.div`
    display: flex;
    padding: 0.0625rem 0rem;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid var(--LS-Color-Notifications-alerts-stroke-tips, #afbff7);
    background: var(--LS-Color-Notifications-alerts-bg-info, #edf1fd);
`;

const DialogNoticeText = styled.p`
    margin: 0;
    flex: 1 0 0;
    color: var(--LS-Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.125rem;
`;

const DialogDontShowAgainContainer = styled.div`
    display: flex;
    align-items: center;
    gap: var(--ls-spacers-generation-sp-208, 0.5rem);
    align-self: stretch;
`;

const DialogDontShowAgainText = styled.p`
    margin: 0;
    display: flex;
    padding: var(--LS-Spacers-Generation-SP2, 0.125rem) 0rem;
    align-items: flex-start;
    gap: 0.625rem;
`;

const downloadCsv = (guidedAnswers: IGuidedAnswers, sourceName: string) => {
    const parser = new Parser({
        fields: [
            { label: 'Question', value: 'question' },
            { label: 'Answer', value: 'answer' },
        ],
    });
    const csvContent = parser.parse(guidedAnswers);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${sourceName} Q&A.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const GuidedAnswersFooter = ({ onClose, showSnackBar }) => {
    const [loading, setLoading] = useState(false);
    const formApi = useForm();
    const { dirty, invalid, values } = formApi.getState();
    const guidedAnswers = formApi.getState().values['guidedAnswers'];
    const { askMeForm } = useAskMeForm();
    const [dialogProps, setDialogProps] = useState<IdpDialogProps | undefined>();

    const handleSubmit = async () => {
        setLoading(true);
        await formApi.submit();
        setLoading(false);
    };

    const toggleDontShowPublishDialogAgain = (checked: boolean) => {
        if (checked) {
            localStorage.setItem(DONT_SHOW_PUBLISH_DIALOG_KEY, 'true');
        } else {
            localStorage.removeItem(DONT_SHOW_PUBLISH_DIALOG_KEY);
        }
    };

    return (
        <>
            {dialogProps && (
                <IdpDialog
                    open={dialogProps.open}
                    handleClose={dialogProps.handleClose}
                    confirm={dialogProps.confirm}
                    title={dialogProps.title}
                    content={dialogProps.content}
                    type={dialogProps.type ?? 'defaultButton'}
                    buttonText={dialogProps.buttonText ?? 'I understand, continue'}
                    cancelButtonText={dialogProps.cancelButtonText ?? 'Cancel'}
                />
            )}
            <FooterActionsSubContainer>
                <CustomToolTipHover placement='top' title='The Q&A list will be exported to a CSV document.' arrow>
                    <ResetButtonContainer
                        onClick={() => {
                            downloadCsv(
                                values['guidedAnswers'].filter((qa) => qa),
                                values['name']
                            );
                            showSnackBar({
                                open: true,
                                messageText: `CSV export completed`,
                                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
                            });
                        }}
                        disabled={askMeForm.isFooterDisabled || !guidedAnswers?.some((ga) => ga)}>
                        <ShareIcon />
                        <span>Export</span>
                    </ResetButtonContainer>
                </CustomToolTipHover>
            </FooterActionsSubContainer>
            <FooterActionsSubContainer>
                <WMButton
                    variant={WMButtonVariant.Text}
                    onClick={() =>
                        dirty
                            ? setDialogProps({
                                  open: true,
                                  handleClose: () => setDialogProps(undefined),
                                  confirm: () => {
                                      onClose();
                                      setDialogProps(undefined);
                                  },
                                  title: 'Discard changes?',
                                  content: "All unsaved changes will be lost. This can't be undone.",
                                  type: 'errorButton',
                                  buttonText: 'Discard',
                                  cancelButtonText: 'Cancel',
                              })
                            : onClose()
                    }>
                    Cancel
                </WMButton>
                <WMButton
                    onClick={() =>
                        localStorage.getItem(DONT_SHOW_PUBLISH_DIALOG_KEY)
                            ? handleSubmit()
                            : setDialogProps({
                                  open: true,
                                  handleClose: () => setDialogProps(undefined),
                                  confirm: () => {
                                      handleSubmit();
                                      setDialogProps(undefined);
                                  },
                                  title: 'Publish your custom Q&A?',
                                  content: (
                                      <DialogContentContainer>
                                          <div>The custom Q&A you've created will be published and available instantly to end-users.</div>
                                          <DialogNoticeContainer>
                                              <LightBulbIcon
                                                  style={{ width: '1.25rem', height: '1.25rem', fill: '#385FEB', stroke: '#385FEB' }}
                                              />
                                              <DialogNoticeText>
                                                  Click the Demo icon <DemoIcon /> on the source tile to test your Q&A.
                                              </DialogNoticeText>
                                          </DialogNoticeContainer>
                                          <DialogDontShowAgainContainer>
                                              <CssCheckBox
                                                  type='checkbox'
                                                  onChange={(event) => toggleDontShowPublishDialogAgain(event.target.checked)}
                                              />
                                              <DialogDontShowAgainText>Don't show me this message again</DialogDontShowAgainText>
                                          </DialogDontShowAgainContainer>
                                      </DialogContentContainer>
                                  ),
                                  type: 'defaultButton',
                                  buttonText: 'Save & Publish',
                                  cancelButtonText: 'Cancel',
                              })
                    }
                    disabled={!dirty || invalid || loading || askMeForm.isFooterDisabled}
                    loading={loading}
                    style={{ height: '2.75rem', borderRadius: '1.365rem' }}>
                    Save & Publish
                </WMButton>
            </FooterActionsSubContainer>
        </>
    );
};

export default GuidedAnswersFooter;
