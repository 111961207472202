import { Box, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { changeSystemType, createSystemSlice, useSystemApps, useUpdatedSystem } from '@walkme-admin-center/libs/state-management-systems';
import { WMButton, WMButtonVariant, WMDivider, WMRadio, WMSearchFilter } from '@walkme/wm-ui';
import {
    Dialog as WMDialog,
    DialogTitle as WMDialogTitle,
    DialogContent as WMDialogContent,
    DialogActions as WMDialogActions,
    Button,
    Spinner,
    RadioButton,
} from '@walkme/ui-core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountsSdk, SystemSettings } from 'wm-accounts-sdk';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import {
    StyledBox,
    StyledTypography,
    StyledLoadingSpinner,
    LoadingContainer,
    StyledDialogTitle,
    StyledDialog,
    StyledSystemAppItem,
    StyledContentContainer,
    StyledBodyContainer,
} from './change_type.styles';
import { defaultPaginationConfig } from '../../../../../../libs/state-management-systems/src/lib/redux/createSystem.lib';
import { useDebouncedCallback } from 'use-debounce';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface ChangeSystemTypeProps {
    open: boolean;
    onClose: () => void;
    system?: any;
    availableSystems: any;
    hasDeployables: boolean;
}

const ChangeSystemType = ({ open, onClose, system, availableSystems, hasDeployables }: ChangeSystemTypeProps) => {
    const { t } = useTranslation('general');
    const [query, setQuery] = useState('');
    const [appsCount, setAppsCount] = useState(200);
    const { updatedSystemAppData } = useUpdatedSystem();
    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [systemAppsContainer, setSystemAppsContainer] = useState<any>(null);
    const [isPaginationLoading, setIsPaginationLoading] = useState(false);
    const [systemsPage, setSystemsPage] = useState(2);
    const { systemAppsAppData, systemAppPagesInfo, selectedSystemApp, generatedPaginationFilterDto } = useSystemApps();
    const isInitialSystemAppsLoading = systemAppsAppData.loading;
    const systemApps: any = systemAppsAppData.data;
    const loaderRef = useRef(null);
    const [selectedSystemAppToChange, setSelectedSystemAppToChange] = useState(null);

    const fetchNextSystemApps = useCallback(async () => {
        if (systemsPage > systemAppPagesInfo?.totalPages || isPaginationLoading) return;
        try {
            setIsPaginationLoading(true);
            const pageSystemApps = await AccountsSdk.getInstance().getPaginatedSystemApps({
                ...defaultPaginationConfig,
                ...generatedPaginationFilterDto,
                page: systemsPage,
            });
            setSystemAppsContainer((currSystemApps) => [...currSystemApps, ...pageSystemApps?.page?.edges?.map((edge: any) => edge.node)]);
            setSystemsPage((curPage) => curPage + 1);
            setIsPaginationLoading(false);
        } catch (error) {
            console.error(error);
        }
    }, [systemsPage, isPaginationLoading, systemAppPagesInfo, generatedPaginationFilterDto]);

    const getInitialSystemApps = () => {
        setSystemAppsContainer(systemApps);
        setSystemsPage(2);
    };

    const changeType = (newSystemSettings: SystemSettings & { guid?: string }) => {
        if (hasDeployables) {
            setConfirmOpen(true);
        } else {
            dispatch(changeSystemType(newSystemSettings.guid, system.id, newSystemSettings.regexp));
        }
    };

    const handleConfirmClose = (confirmed: boolean) => {
        setConfirmOpen(false);
        if (confirmed) {
            dispatch(changeSystemType(selectedSystemAppToChange.guid, system.id, selectedSystemAppToChange.regexp));
        }
    };

    const handleSearchChange = useDebouncedCallback((value: string) => {
        dispatch(createSystemSlice.actions.setSearchFilterText(value));
    }, 1000);

    const setIntersectionObserver = () => {
        if (systemAppPagesInfo?.totalPages < 2) {
            getInitialSystemApps();
        } else {
            const observer = new IntersectionObserver((entries) => {
                const target = entries[0];

                if (target.isIntersecting) {
                    fetchNextSystemApps();
                }
            });

            if (loaderRef.current) {
                observer.observe(loaderRef.current);
            }

            return () => {
                if (loaderRef.current) {
                    observer.unobserve(loaderRef.current);
                }
            };
        }
    };

    const handleDialogClose = () => {
        dispatch(createSystemSlice.actions.resetCreateSystemState());
        onClose();
    };

    const loadMore = () => {
        setAppsCount(appsCount + 200);
    };

    useEffect(getInitialSystemApps, [systemApps]);
    useEffect(() => {
        if (updatedSystemAppData.data.id) {
            onClose();
        }
    }, [updatedSystemAppData]);
    useEffect(setIntersectionObserver, [fetchNextSystemApps, systemAppsContainer]);

    // @ts-ignore
    return (
        <>
            <StyledDialog onClose={handleDialogClose} isOpen={open}>
                <StyledDialogTitle>
                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                color: '#2F426C',
                                fontSize: '18px',
                                fontWeight: 600,
                            }}>
                            {t('systems-tab.systems-table.change-system-type')}
                        </Typography>
                    </Box>
                    <WMDivider style={{ marginTop: '16px' }} />
                </StyledDialogTitle>
                <WMDialogContent>
                    <StyledContentContainer>
                        <WMSearchFilter
                            ds2
                            value={query}
                            onSearch={(event) => handleSearchChange(event)}
                            style={{ marginBottom: '16px' }}
                            onPointerEnterCapture={() => null}
                            onPointerLeaveCapture={() => null}
                        />
                        <StyledBodyContainer>
                            {!systemApps || isInitialSystemAppsLoading ? (
                                <LoadingContainer>
                                    <CircularProgress size={50} thickness={4} />
                                </LoadingContainer>
                            ) : null}
                            {systemApps &&
                                !isInitialSystemAppsLoading &&
                                systemAppsContainer?.map((systemApp) => (
                                    <StyledSystemAppItem key={systemApp._id}>
                                        <RadioButton
                                            checked={selectedSystemAppToChange?._id === systemApp._id}
                                            onClick={(event) => setSelectedSystemAppToChange(systemApp)}
                                        />
                                        <Box
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            style={{ marginLeft: '10px' }}>
                                            <img src={retrieveSystemIcon(systemApp)} width='20px' height='20px' />
                                            <Typography
                                                style={{
                                                    fontFamily: 'Proxima Nova',
                                                    color: '#2F426C',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    marginLeft: '10px',
                                                }}>
                                                {systemApp.displayName}
                                            </Typography>
                                        </Box>
                                    </StyledSystemAppItem>
                                ))}
                            {systemApps && !isInitialSystemAppsLoading ? (
                                <StyledLoadingSpinner ref={loaderRef}>
                                    {isPaginationLoading ? <Spinner size={30} /> : null}
                                </StyledLoadingSpinner>
                            ) : null}
                        </StyledBodyContainer>
                    </StyledContentContainer>
                </WMDialogContent>
                <WMDialogActions>
                    <WMButton variant={WMButtonVariant.Text} disabled={updatedSystemAppData.loading} onClick={handleDialogClose}>
                        {t('buttons.cancel')}
                    </WMButton>
                    <WMButton
                        style={{ marginLeft: '20px' }}
                        loading={updatedSystemAppData.loading}
                        disabled={isInitialSystemAppsLoading || !selectedSystemAppToChange}
                        onClick={() => changeType(selectedSystemAppToChange)}>
                        {t('systems-tab.systems-table.change-system-type')}
                    </WMButton>
                </WMDialogActions>
            </StyledDialog>

            <WMDialog isOpen={confirmOpen} onClose={() => handleConfirmClose(false)}>
                <WMDialogTitle>
                    <StyledTypography>{t('systems-tab.systems-table.change-system-type-alert-title')}</StyledTypography>
                </WMDialogTitle>
                <DialogContent>
                    <StyledBox>
                        <Typography>{t('systems-tab.systems-table.change-system-type-alert')}</Typography>
                        <br />
                        <Typography>{t('systems-tab.systems-table.change-system-type-alert-question')}</Typography>
                    </StyledBox>
                </DialogContent>
                <DialogActions style={{ margin: '18px' }}>
                    <Button variant={'outlined'} onClick={() => handleConfirmClose(false)}>
                        {t('buttons.cancel')}
                    </Button>
                    <WMButton style={{ marginLeft: '20px' }} onClick={() => handleConfirmClose(true)}>
                        {t('buttons.yes')}
                    </WMButton>
                </DialogActions>
            </WMDialog>
        </>
    );
};

export default ChangeSystemType;
