import * as yup from 'yup';
import { validateIp } from '../validate';

yup.addMethod(yup.array, 'uniqueIp', function (key, message) {
    return this.test('unique', message, function (list) {
        if (!list) {
            return true;
        }
        const mapper = (x) => x;
        const set = [...new Set(list.map(mapper))];
        const isUnique = list.length === set.length;
        if (isUnique) {
            return true;
        }

        const idx = list.findIndex((l, i) => {
            return mapper(l) !== set[i];
        });
        return this.createError({
            path: `${key}[${idx}]`,
            message: message,
        });
    });
});

export const securitySchema = yup.object().shape({
    ipList: yup
        .array()
        .of(
            yup
                .string()
                .required()
                .test('Valid ip', 'Please enter valid ip', (value) => (value ? validateIp(value) : true))
        )
        // @ts-ignore
        .uniqueIp('ipList', 'Must be unique'),
});
