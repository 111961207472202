import React from 'react';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import { getErrorObject } from '../services/validation/validate';
import EditCrawlerFooter from '../data-integration-form-components/footers/edit-crawler-footer';
import {
    IAskMeForm,
    FormMode,
    IAskMe,
    IHeaderText,
    IntegrationChannelOptions,
    JobType,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { Crawler } from './crawler';
import UploadFiles from '../data-integration-form-components/file-form/steps/upload-files/upload-files';
import AssignSystemsTabs from '../data-integration-form-components/crawler-form/steps/assign-systems-step/assign-systems-tabs';
import { createAskMe, updateAskMe } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { CONSTS } from '../../../../../sso-configuration/src/lib/common/consts';
import CreateSourceFooter from '../data-integration-form-components/footers/create-source-footer';
import { uploadFileSchema } from '../services/validation/files/upload-file-step-validation';
import TabPanel from '../../common/components/custom-tab-panel';
import AssignSegmentsTab from '../data-integration-form-components/crawler-form/steps/assign-segments-step/assign-segments-step';

export class FileScan extends Crawler {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    prepareSettings(settings: any): any {
        const timestamp = Date.now();
        return {
            ...settings,
            timestamp,
        };
    }

    async save(values: any, integrations: any): Promise<void> {
        const settings = this.prepareSettings(values.settings);
        const slackSystems = this.prepareSlackChannels([], values.slackSystems, integrations.slack);
        const slackRules = this.prepareSlackRules(values.slackRules);
        const teamsSystems = this.prepareSlackChannels([], values.teamsSystems, integrations.teams);
        const teamsRules = this.prepareSlackRules(values.teamsRules);

        const sourceId = values.sourceId;
        const data = {
            ...values,
            sourceId,
            settings,
            slackSystems,
            slackRules,
            teamsSystems,
            teamsRules,
        };

        try {
            // await this.uploadFiles(values.settings.files, settings.timestamp, sourceId);
            await this.dispatch(createAskMe(data));
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `You’ve successfully added ${values.name}`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to create Content.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    async update(askMeIntegration: IAskMe, values: any, integrations: any): Promise<void> {
        const newValues = this.prepareSettings(values.settings);
        const systems = this.prepareSystems(askMeIntegration.systems, values.systems);
        const slackSystems = this.prepareSlackChannels(askMeIntegration.slackSystems, values.slackSystems, integrations.slack);
        const slackRules = this.prepareSlackRules(values.slackRules);
        const teamsSystems = this.prepareSlackChannels(askMeIntegration.teamsSystems, values.teamsSystems, integrations.teams);
        const teamsRules = this.prepareSlackRules(values.teamsRules);

        const sourceId = askMeIntegration.sourceId;
        const integrationsData = {
            sourceId,
            slackSystems,
            slackRules,
            teamsSystems,
            teamsRules,
        };

        try {
            await this.dispatch(
                updateAskMe(askMeIntegration.sourceId, {
                    ...values,
                    settings: newValues,
                    systems,
                    ...integrationsData,
                })
            );
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `You’ve successfully edited ${values.name}`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to update ${askMeIntegration.name}`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    getFormLabels(): Array<string> {
        return ['Source Files', 'Access', 'Segments'];
    }

    getTabsLabels(): Array<string> {
        return this.getFormLabels();
    }

    getFormHeader(integration: IAskMe = null, formMode): IHeaderText {
        switch (formMode) {
            case FormMode.STEPPER:
                return { header: 'Add Knowledge Sources', subHeader: null };
            case FormMode.TABS:
                return { header: `Edit ${integration.name}`, subHeader: null };
        }
    }

    getFormByStep(step: number, triggerModal: () => void): React.ReactNode {
        switch (step) {
            case 0:
                return <UploadFiles />;
            case 1:
                return <AssignSystemsTabs triggerModal={triggerModal} formMode={FormMode.STEPPER} />;
            case 2:
                return <AssignSegmentsTab triggerModal={triggerModal} formMode={FormMode.STEPPER} />;
        }
    }

    getFormByTab(step: number, instanceData: IAskMe | null, triggerModal: () => void): React.ReactNode {
        return (
            <>
                <TabPanel value={step} index={0}>
                    <UploadFiles />
                </TabPanel>
                <TabPanel value={step} index={1}>
                    <AssignSystemsTabs triggerModal={triggerModal} formMode={FormMode.TABS} />
                </TabPanel>
                <TabPanel value={step} index={2}>
                    <AssignSegmentsTab triggerModal={triggerModal} formMode={FormMode.TABS} />
                </TabPanel>
            </>
        );
    }

    getTabsFooter(): React.ReactNode {
        return <EditCrawlerFooter onClose={this.closeForm} />;
    }

    async validate(values: IAskMeForm, step: number) {
        let schema;
        if (step === 0) {
            schema = uploadFileSchema;
        } else {
            return null;
        }
        const providerSettingsErrors = await getErrorObject(schema, values);
        if (providerSettingsErrors) {
            return providerSettingsErrors;
        }
        return null;
    }

    initForm({ data: integration }: { data: IAskMe | any }): any {
        return {
            name: get(integration, 'name', ''),
            type: get(integration, 'type', JobType.FILE),
            sourceId: get(integration, 'sourceId', uuidv4()),
            settings: {
                files: get(integration, 'settings.files', []),
                slackChannelsConfig: get(integration, 'settings.slackChannelsConfig', IntegrationChannelOptions.DISABLED),
                teamsChannelsConfig: get(integration, 'settings.teamsChannelsConfig', IntegrationChannelOptions.DISABLED),
            },
            systems: get(integration, 'systems', []),
            slackSystems: get(integration, 'slackSystems', []),
            slackRules: this.getRules(get(integration, 'slackRules.rules', {})),
            teamsSystems: get(integration, 'teamsSystems', []),
            teamsRules: this.getRules(get(integration, 'teamsRules.rules', {})),
            isOmni: get(integration, 'isOmni', undefined) || undefined,
            isPrivate: get(integration, 'isPrivate', undefined),
        };
    }
}
