import { SystemsRegexes } from '@walkme-admin-center/libs/exposure-api';

const HTTP_HTTPS_PREFIX_REGEX = new RegExp(/https?:\/\//, 'i');

// Used in the systems-page as a solution for searching also by regex matching.
const regexesColumnValueGetter = (params: { api: { filterManager: { quickFilter: any } }; data: { regexes: any[] } }) => {
    const query = params.api.filterManager.quickFilter;
    const regexes = params.data?.regexes ?? [];
    const isQueryStartingAsUrl = HTTP_HTTPS_PREFIX_REGEX.test(query);
    const shouldCheckRegexMatching = isQueryStartingAsUrl || query?.includes('.');

    if (!shouldCheckRegexMatching) {
        return;
    }

    if (regexes) {
        const isQueryMatched = regexChecker(query, regexes, isQueryStartingAsUrl);
        if (isQueryMatched) {
            //In case there is match, we set the value of this column to be the query and as a result it will be listed in the table
            return query;
        }
    }
};

const regexChecker = (queryString: string, regexes: SystemsRegexes[], isQueryStartingAsUrl: boolean) => {
    let queryStringsToTest: string[] = [queryString];

    if (!isQueryStartingAsUrl) {
        // It means it has '.' as substring, we'd like to test it against 2 possibilities
        if (queryString.includes('/')) {
            queryStringsToTest = [`http://${queryString}`, `https://${queryString}`];
        } else {
            queryStringsToTest = [`http://${queryString}/`, `https://${queryString}/`];
        }
    } else {
        const startSearchFromIndex = 'https://'.length;
        if (!queryString.includes('/', startSearchFromIndex)) {
            // Starts with http:// or https:// but does not contain an additional /
            queryStringsToTest = [`${queryString}/`];
        }
    }

    for (let i = 0; i < regexes.length; i++) {
        const { isActive, selfRegex, topRegex } = regexes[i];

        if (isActive) {
            try {
                // Currently we are validating only active matchers\packages which will actually will be tested by the extension
                const selfRegExp = new RegExp(selfRegex, 'i');
                const topRegExp = new RegExp(topRegex, 'i');

                if (queryStringsToTest.some((queryString) => selfRegExp.test(queryString) && topRegExp.test(queryString))) {
                    return true;
                }
            } catch {
                /*Do nothing in that case*/
            }
        }
    }

    return false;
};

export { regexesColumnValueGetter };
