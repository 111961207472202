import { MenuItem, Select, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const CustomMenuItem = withStyles({
    root: {
        minHeight: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#F0F4FA !important',
        },
        '&.Mui-selected': {
            color: '#385FEB',
            backgroundColor: 'white',
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'white',
        },
    },
})(MenuItem);

export const SelectWrapper = styled.div`
    .MuiInputBase-root.MuiOutlinedInput-root {
        .MuiSelect-root {
            background: #f6f8fc;
        }
        .MuiOutlinedInput-notchedOutline {
            border-width: 0;
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
        .MuiSelect-root {
            background: #ffffff;
        }
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-focused):not(.Mui-disabled) {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #354b9b40;
        }
    }
`;

export const CssSelect = withStyles({
    root: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
})(Select);

export const StyledLink = styled(Link)`
    color: var(--blue-primary-primary-main, #385feb);
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

export const ZStack = styled.div<{ alignItems?: 'center' | 'start' | 'end' | 'stretch' }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: ${(props) => props.alignItems ?? 'center'};

    & > * {
        grid-area: 1 / 1;
    }
`;

export const Spacer = styled.div<{ height?: number }>`
    ${(p) =>
        p.height &&
        css`
            height: ${p.height}rem;
        `}
`;

export const StyledCustomMenuItem = styled(MenuItem)`
    .root {
        &:hover {
            background-color: #f0f4fa !important;
        }

        &.Mui-selected {
            background-color: #f0f4fa;
        }
    }
`;
