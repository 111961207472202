import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useUpdateSystemMatcher = () => {
    const updateSystemMatcherAppData: AppData<number | undefined> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.updateSystemMatcher
    );

    return { updateSystemMatcherAppData };
};

export default useUpdateSystemMatcher;
