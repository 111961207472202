import { Dialog, DialogActions, Select } from '@material-ui/core';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
    .MuiSelect-outlined.MuiSelect-outlined {
        padding: 12px 14px;
    }
`;

export const StyledDialog = styled(Dialog)`
    .container {
        & .MuiPaper-root {
            width: 500px;
            min-height: 260px;
            border-radius: 12px;
        }
    }
`;

export const StyledDialogActions = styled(DialogActions)`
    &.root {
        height: 88px;
        padding: 0 32px;
        display: flex;
        flex-direction: row;
    }
`;
