import React from 'react';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@walkme/ui-core';
import { StyledConfirmButton } from './unsaved-changes-dialog.styles';

export const UnsavedChangesDialog = ({ isOpen, onCancel, onConfirm }) => {
    const { t } = useTranslation('general');

    return (
        <Dialog isOpen={isOpen} onClose={onCancel}>
            <DialogTitle>{t('systems-tab.all-systems-page.extension-page.unsaved-changes-title')}</DialogTitle>
            <DialogContent>{t('systems-tab.all-systems-page.extension-page.unsaved-changes-subtitle')}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant='text'>
                    {t('buttons.cancel')}
                </Button>
                <StyledConfirmButton onClick={onConfirm}>{t('buttons.confirm')}</StyledConfirmButton>
            </DialogActions>
        </Dialog>
    );
};
