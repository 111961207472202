import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';


const useQontoStepIconStyles = makeStyles((theme) => createStyles ({

  root: {
    background: '#E1E9F5',
    color: '#6D81A6',
    display: 'flex',
    borderRadius: '50%',
    fontSize: '12px',
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: 'white',
    background: theme.palette.primary.main
  },
  completed: {
    background: '#F4F7FD',
    border: '1px solid #4164E3',
    height: 22,
    width: 22,
  },
}));


export const QontoStepIcon = (props) => {
  const classes = useQontoStepIconStyles();
  const { active, completed, icon } = props;

  return (
    <div className={`${classes.root} ${ active ? classes.active: ''} ${completed ? classes.completed: ''}`}>
      {completed ? <div><img src='assets/icons/check-primary.svg'/></div> : <div>{icon}</div>}
    </div>
  );
}
export default QontoStepIcon
