import React, { useEffect, useRef, useState } from 'react';
import CollapsibleGroup from './collapsible';
import { WMDivider, WMLoader, WMSelect } from '@walkme/wm-ui';
import { Button, Tooltip } from '@walkme/ui-core';
import { Environment } from '../types';
import { ExtensionStatus, GetSystemDataResult, PartialSystemData, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import cloneDeep from 'lodash/cloneDeep';
import {
    HeaderContainer,
    HeaderMainText,
    HeaderSubText,
    ContentContainer,
    CollapsibleContentDiv,
    CollapsibleHeader,
    FooterContainer,
    LoaderContainer,
    MainContainer,
    ControlHeader,
    DropDownContainer,
    MatcherSelectorContainer,
    ControlHeaderWithTooltipContainer,
    StyledTooltipLink,
    StyledTooltipText,
    SpecialContentMessageHeader,
    SpecialContentContainer,
    SpecialContentMessageSubHeader,
} from './shared-styled-components';
import {
    linkSystemToExtension,
    useLinkSystemToExtension,
    useWebSystem,
    webSystemsSlice,
    useGetDefaultSystemMatcher,
    addSystemMatcher,
    useAddSystemMatcher,
    updateSystemMatcher,
    useUpdateSystemMatcher,
} from '@walkme-admin-center/libs/state-management-extensions';
import { getEnvById, getMinimalEnvById } from '../utils';
import SystemNotConnected from './system-not-connected';
import { useDispatch } from 'react-redux';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import isEqual from 'lodash/isEqual';
import StatusNotification, { StatusNotificationProps } from './status-notifications';
import { useSystem } from '@walkme-admin-center/libs/state-management-systems';
import BasicConfigurations from './basic-configurations';
import AdvancedConfigurations from './advanced-configurations';
import MultiMatchersDialog from './multi-matchers-dialog';
import { SystemMatcherOption } from './types';
import { buildAddNewMatcherData, buildSystemMatcherDataForLinkingExistingSystem } from './system-matcher-builders';
import { getMatcherChanges } from './matcher-changes-provider';
import {
    getNotDevEnvironmentsFromSystemDataEnvironments,
    getSelectedEnvironmentMatchersOptions,
    getSuccessSaveDialogMessageBasedOnExtensionCacheTime,
    getTooltipIcon,
} from './utils';
import UnsavedChangesConfirmationDialog from './unsaved-changes-confirmation-dialog';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { URLVerifier } from '../url-verifier/url-verifier';
import Box from '@material-ui/core/Box';
import { EnvironmentURLQueryResult, URLMatchData } from '../url-verifier/values';

interface ExtensionProps {
    systemId: number;
}

const Extension = ({ systemId }: ExtensionProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();

    /* Redux hooks */
    const { webSystemAppData } = useWebSystem(systemId);
    const { currentSystemAppData } = useSystem(systemId);
    const { getDefaultSystemMatcherAppData } = useGetDefaultSystemMatcher();
    const { linkSystemToExtensionAppData } = useLinkSystemToExtension();
    const { addSystemMatcherAppData } = useAddSystemMatcher();
    const { updateSystemMatcherAppData } = useUpdateSystemMatcher();
    const { loggedInUserAppData } = useLoggedInUser();

    /* State vars */
    const [showUnsavedChangesConfirmation, setShowUnsavedChangesConfirmation] = useState(false);
    const [notificationSettings, setNotificationSettings] = useState<StatusNotificationProps>({
        isOpen: false,
        status: 'success',
        onConfirm: () => {},
    });
    const [isMultiMatchersMode, setIsMultiMatchersMode] = useState(false);
    const [isLinkToExtensionMode, setIsLinkToExtensionMode] = useState(false);
    const [systemData, setSystemData] = useState<PartialSystemData>();
    const [isAddingNewUrl, setIsAddingNewUrl] = useState(false);
    const environmentOptions = getNotDevEnvironmentsFromSystemDataEnvironments(systemData?.environments);
    const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | undefined>(getMinimalEnvById(environmentOptions));
    const selectedEnvironmentMatchersOptions: SystemMatcherOption[] = getSelectedEnvironmentMatchersOptions(
        selectedEnvironment,
        systemData
    );
    const [selectedMatcher, setSelectedMatcher] = useState<SystemMatcher | undefined>(
        selectedEnvironmentMatchersOptions.length > 0 ? selectedEnvironmentMatchersOptions[0].value : undefined
    );
    const [selectedMatcherIdURLVerifier, setSelectedMatcherIdURLVerifier] = useState<string | number>(undefined);
    const [urlVerifierMatchData, setUrlVerifierMatchData] = useState<URLMatchData[]>([]);
    const [isSystemConnectedToExtension, setIsSystemConnectedToExtension] = useState(false);
    const [canLinkSystemToExtension, setCanLinkSystemToExtension] = useState(false);
    const [areSelfUrlsValid, setAreSelfUrlsValid] = useState(true);
    const [areTopUrlsValid, setAreTopUrlsValid] = useState(true);
    const [isSelfRegexValid, setIsSelfRegexValid] = useState(true);
    const [isTopRegexValid, setIsTopRegexValid] = useState(true);
    const [openCollapsibleItemIndex, setOpenCollapsibleItemIndex] = useState<number | null>(0);

    /* Refs */
    const originalSelectedMatcher = useRef(selectedMatcher);
    const isUpdatePressed = useRef(false);
    const extensionCacheTimeInSeconds = useRef<number>(900);
    const newEnvironmentSelection = useRef<Environment | undefined>(undefined);
    const newMatcherSelection = useRef<SystemMatcher | undefined>(undefined);
    const onUnsavedChangesConfirm = useRef(undefined);
    const onUnsavedChangesCancel = useRef(undefined);

    const isSelectedMatcherChanged = !isEqual(selectedMatcher, originalSelectedMatcher.current);
    const isSaveInProgress = addSystemMatcherAppData.loading || updateSystemMatcherAppData.loading || linkSystemToExtensionAppData.loading;

    const onStatusNotificationConfirm = () => {
        setNotificationSettings({
            ...notificationSettings,
            isOpen: false,
        });
    };

    useEffect(() => {
        if (linkSystemToExtensionAppData.data !== undefined) {
            // TODO: If we linked the extension with not active matcher, dont show pop just regular success snackbar.
            setNotificationSettings({
                isOpen: true,
                extensionCacheTime: extensionCacheTimeInSeconds.current,
                status: 'success',
                onConfirm: onStatusNotificationConfirm,
                //TODO: do we want here different message?
                successDialogMessage: getSuccessSaveDialogMessageBasedOnExtensionCacheTime(extensionCacheTimeInSeconds.current, t),
            });
            dispatch(webSystemsSlice.actions.cleanupLinkSystemToExtension());
            setIsLinkToExtensionMode(false);
            setSelectedEnvironment(getMinimalEnvById(environmentOptions));
        } else if (linkSystemToExtensionAppData.error) {
            setNotificationSettings({
                isOpen: true,
                status: 'error',
                onConfirm: onStatusNotificationConfirm,
                snackBarMessage: 'Failed to connect, please try again or contact WalkMe Support.',
            });
            dispatch(webSystemsSlice.actions.cleanupLinkSystemToExtension());
        }
    }, [linkSystemToExtensionAppData]);

    const handleSaveSuccessNotification = () => {
        setNotificationSettings({
            isOpen: true,
            extensionCacheTime: extensionCacheTimeInSeconds.current,
            status: 'success',
            onConfirm: onStatusNotificationConfirm,
            successDialogMessage: getSuccessSaveDialogMessageBasedOnExtensionCacheTime(extensionCacheTimeInSeconds.current, t),
        });
    };

    const handleSaveFailureNotification = () => {
        setNotificationSettings({
            isOpen: true,
            status: 'error',
            onConfirm: onStatusNotificationConfirm,
        });
    };

    useEffect(() => {
        if (updateSystemMatcherAppData.data) {
            handleSaveSuccessNotification();
            dispatch(webSystemsSlice.actions.cleanupUpdateSystemMatcher());
        } else if (updateSystemMatcherAppData.error) {
            handleSaveFailureNotification();
            dispatch(webSystemsSlice.actions.cleanupUpdateSystemMatcher());
        }
    }, [updateSystemMatcherAppData]);

    useEffect(() => {
        if (addSystemMatcherAppData.data) {
            // TODO: If we linked the extension with not active matcher, dont show pop just regular success snackbar.
            handleSaveSuccessNotification();
            dispatch(webSystemsSlice.actions.cleanupAddSystemMatcher());
            setSelectedMatcher(addSystemMatcherAppData.data);
            originalSelectedMatcher.current = { ...addSystemMatcherAppData.data };
        } else if (addSystemMatcherAppData.error) {
            handleSaveFailureNotification();
            dispatch(webSystemsSlice.actions.cleanupAddSystemMatcher());
        }
    }, [addSystemMatcherAppData]);

    useEffect(() => {
        if (isLinkToExtensionMode) {
            return;
        }

        const isMultiMatchersPerEnvironment = selectedEnvironmentMatchersOptions.length > 1;
        setIsMultiMatchersMode(isMultiMatchersPerEnvironment);
        let newMatcher: SystemMatcher;

        if (selectedEnvironmentMatchersOptions.length > 0) {
            const urlVerifierResultMatcher = selectedMatcherIdURLVerifier
                ? selectedEnvironmentMatchersOptions.find(
                      (matcher) => matcher.value._id.toString() === selectedMatcherIdURLVerifier?.toString()
                  )
                : undefined;
            newMatcher = urlVerifierResultMatcher?.value || selectedEnvironmentMatchersOptions[0].value;
        }

        setSelectedMatcher(newMatcher);
        originalSelectedMatcher.current = { ...newMatcher };
        setSelectedMatcherIdURLVerifier(undefined);
    }, [selectedEnvironment]);

    useEffect(() => {
        const getSystemDataResult: GetSystemDataResult = webSystemAppData.data && webSystemAppData.data[systemId];

        if (getSystemDataResult) {
            const { extensionStatus, isSystemLinked, systemData, cacheTime } = getSystemDataResult;
            extensionCacheTimeInSeconds.current = cacheTime;
            setSystemData(systemData);
            const canLinkSystemToExtension = !isSystemLinked && extensionStatus === ExtensionStatus.Exists;

            if (!isUpdatePressed.current) {
                // We change the environment only if we didn't do any update yet
                const newEnvironmentOptions = getNotDevEnvironmentsFromSystemDataEnvironments(systemData?.environments);
                setSelectedEnvironment(getMinimalEnvById(newEnvironmentOptions));
            }

            setIsSystemConnectedToExtension(isSystemLinked);
            setCanLinkSystemToExtension(canLinkSystemToExtension);
        }
    }, [webSystemAppData.data]);

    const setSelectedMatcherWithDefaultAndActivate = (defaultMatcher: SystemMatcher): void => {
        const selectedMatcherClone: SystemMatcher = cloneDeep(defaultMatcher);
        selectedMatcherClone.isActive = true;
        setSelectedMatcher(selectedMatcherClone);
    };

    useEffect(() => {
        const defaultMatcherWithAppConfigOverride = getDefaultSystemMatcherAppData.data;

        if (defaultMatcherWithAppConfigOverride) {
            setIsLinkToExtensionMode(true);
            dispatch(webSystemsSlice.actions.cleanupGetDefaultMatcher());
            const minimalEnvironment = getMinimalEnvById(getNotDevEnvironmentsFromSystemDataEnvironments(systemData?.environments));
            setSelectedEnvironment(minimalEnvironment);
            setSelectedMatcherWithDefaultAndActivate(defaultMatcherWithAppConfigOverride);
        } else if (getDefaultSystemMatcherAppData.error) {
            setNotificationSettings({
                isOpen: true,
                status: 'error',
                onConfirm: onStatusNotificationConfirm,
                snackBarMessage: 'Failed to connect, please try again or contact WalkMe Support.',
            });
            dispatch(webSystemsSlice.actions.cleanupGetDefaultMatcher());
        }
    }, [getDefaultSystemMatcherAppData]);

    const onAddNewSelfUrlClosed = () => {
        setIsAddingNewUrl(false);
        setAreSelfUrlsValid(true);
    };

    const onAddNewTopUrlClosed = () => {
        setIsAddingNewUrl(false);
        setAreTopUrlsValid(true);
    };

    const onURLVerifierClosed = (environmentURLVerifierResult: EnvironmentURLQueryResult) => {
        setUrlVerifierMatchData(environmentURLVerifierResult.urlMatchData);
        setOpenCollapsibleItemIndex(0);

        if (environmentURLVerifierResult.matcherId !== selectedMatcher._id) {
            setSelectedMatcherIdURLVerifier(environmentURLVerifierResult.matcherId);
            const newEnv = getEnvById(environmentURLVerifierResult.envValue, environmentOptions);
            setSelectedEnvironment(newEnv);
        }
    };

    const handleCollapsibleItemClick = (index: number) => {
        const isSameIndex = openCollapsibleItemIndex === index;
        setOpenCollapsibleItemIndex(isSameIndex ? null : index);
    };

    const basicCollapsibleSection = (
        <CollapsibleContentDiv>
            <BasicConfigurations
                isSaveInProgress={isSaveInProgress}
                isAddingNewUrl={isAddingNewUrl}
                selectedMatcher={selectedMatcher}
                urlVerifierMatchData={urlVerifierMatchData}
                setIsSelfRegexValid={setIsSelfRegexValid}
                setAreSelfUrlsValid={setAreSelfUrlsValid}
                setSelectedMatcher={setSelectedMatcher}
                setIsAddingNewUrl={setIsAddingNewUrl}
                onAddNewSelfUrlClosed={onAddNewSelfUrlClosed}
            />
        </CollapsibleContentDiv>
    );

    const advancedCollapsibleSection = (
        <CollapsibleContentDiv>
            <AdvancedConfigurations
                selectedMatcher={selectedMatcher}
                isAddingNewUrl={isAddingNewUrl}
                isSaveInProgress={isSaveInProgress}
                setIsAddingNewUrl={setIsAddingNewUrl}
                setSelectedMatcher={setSelectedMatcher}
                setAreTopUrlsValid={setAreTopUrlsValid}
                setIsTopRegexValid={setIsTopRegexValid}
                onAddNewTopUrlClosed={onAddNewTopUrlClosed}
            />
        </CollapsibleContentDiv>
    );

    const loaderComponent = (
        <LoaderContainer>
            <WMLoader style={{ width: '30%' }} />
        </LoaderContainer>
    );

    const onLinkToExtensionClicked = async () => {
        const initialMatcherData = buildSystemMatcherDataForLinkingExistingSystem(selectedMatcher);

        dispatch(
            linkSystemToExtension(loggedInUserAppData.data.account!.id, systemId, currentSystemAppData.data.settings.id, initialMatcherData)
        );
    };

    const getLinkToExtensionButton = () => {
        const areUrlsNotValid = selectedMatcher && (!areSelfUrlsValid || !areTopUrlsValid);
        const areUrlsEmpty = selectedMatcher?.selfDomains.length === 0 || selectedMatcher?.topDomains.length === 0;

        return (
            <Button
                size='large'
                onClick={onLinkToExtensionClicked}
                disabled={isSaveInProgress || areUrlsNotValid || areUrlsEmpty}
                loading={isSaveInProgress}>
                Create Connection
            </Button>
        );
    };

    const handleUpdateMatcher = async () => {
        const changes = getMatcherChanges(originalSelectedMatcher.current, selectedMatcher);
        dispatch(updateSystemMatcher(loggedInUserAppData.data.account!.id, systemId, selectedMatcher._id, changes));
    };

    const handleAddNewMatcher = async () => {
        const addNewMatcherData = buildAddNewMatcherData(selectedMatcher);
        dispatch(addSystemMatcher(loggedInUserAppData.data.account!.id, systemId, addNewMatcherData));
    };

    const onSaveClicked = async () => {
        if (selectedMatcher.isDefault) {
            handleAddNewMatcher();
        } else {
            handleUpdateMatcher();
        }

        originalSelectedMatcher.current = { ...selectedMatcher };
        isUpdatePressed.current = true;
    };

    const getSaveButton = () => {
        const areRegexesDefined = selectedMatcher && selectedMatcher.selfRegex !== '' && selectedMatcher.topRegex !== '';
        const areMatchPatternsDefined =
            selectedMatcher && selectedMatcher.selfDomains?.length > 0 && selectedMatcher.topDomains?.length > 0;
        const areValuesDefined = selectedMatcher ? (selectedMatcher.isRegex ? areRegexesDefined : areMatchPatternsDefined) : true;

        const shouldDisableButton =
            (selectedMatcher && !selectedMatcher.isRegex && (!areSelfUrlsValid || !areTopUrlsValid)) ||
            (selectedMatcher && selectedMatcher.isRegex && (!isSelfRegexValid || !isTopRegexValid)) ||
            !areValuesDefined ||
            isSaveInProgress ||
            !isSelectedMatcherChanged ||
            isAddingNewUrl;

        return (
            <Button size='large' onClick={onSaveClicked} disabled={shouldDisableButton} loading={isSaveInProgress}>
                {t('buttons.save')}
            </Button>
        );
    };

    const getFooterButton = () => {
        if (selectedEnvironment) {
            if (isLinkToExtensionMode) {
                return getLinkToExtensionButton();
            } else if (isSystemConnectedToExtension) {
                return getSaveButton();
            }
        }

        return null;
    };

    const unsavedChangesDialog = (
        <UnsavedChangesConfirmationDialog
            isOpen={showUnsavedChangesConfirmation}
            onCancel={onUnsavedChangesCancel.current}
            onConfirm={onUnsavedChangesConfirm.current}
        />
    );

    const onSelectedEnvironmentChange = (newEnv: Environment) => {
        if (newEnv.value === selectedEnvironment.value) {
            return;
        }

        if (isSelectedMatcherChanged) {
            newEnvironmentSelection.current = newEnv;
            onUnsavedChangesCancel.current = () => {
                setShowUnsavedChangesConfirmation(false);
            };
            onUnsavedChangesConfirm.current = () => {
                setSelectedEnvironment(newEnvironmentSelection.current);
                setShowUnsavedChangesConfirmation(false);
                newEnvironmentSelection.current = undefined;
            };
            setShowUnsavedChangesConfirmation(true);
        } else {
            setSelectedEnvironment(newEnv);
        }
    };

    const onSelectedMatcherChange = (newMatcher: { value: SystemMatcher }) => {
        if (selectedMatcher && newMatcher.value._id.toString() === selectedMatcher._id.toString()) {
            return;
        }

        if (isSelectedMatcherChanged) {
            newMatcherSelection.current = newMatcher.value;
            onUnsavedChangesCancel.current = () => {
                setShowUnsavedChangesConfirmation(false);
            };
            onUnsavedChangesConfirm.current = () => {
                setSelectedMatcher(newMatcher.value);
                setShowUnsavedChangesConfirmation(false);
                newMatcherSelection.current = undefined;
                originalSelectedMatcher.current = newMatcher.value;
            };
            setShowUnsavedChangesConfirmation(true);
        } else {
            setSelectedMatcher(newMatcher.value);
            originalSelectedMatcher.current = newMatcher.value;
        }
    };

    const shouldDisableMatcherSelectorControls = isLinkToExtensionMode || isSaveInProgress || isAddingNewUrl;

    const getConfigurationSetSelectorControl = () => {
        const toolTipContent = (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StyledTooltipText>Switch between configuration sets to view additional environment configurations.</StyledTooltipText>
                <StyledTooltipLink
                    to={{ pathname: 'https://support.walkme.com/knowledge-base/extension-settings/#configuration-sets' }}
                    target='_blank'>
                    {' Learn more'}
                </StyledTooltipLink>
            </div>
        );

        return (
            <>
                <WMDivider orientation='vertical' flexItem></WMDivider>
                <ControlHeaderWithTooltipContainer>
                    <ControlHeader>Select configuration set</ControlHeader>
                    <Tooltip title={toolTipContent} arrow>
                        {getTooltipIcon()}
                    </Tooltip>
                </ControlHeaderWithTooltipContainer>
                <DropDownContainer>
                    <WMSelect
                        value={
                            selectedEnvironmentMatchersOptions.find(
                                (matcher) =>
                                    selectedMatcher?._id &&
                                    matcher.value._id &&
                                    matcher.value._id.toString() === selectedMatcher._id.toString()
                            ) || selectedEnvironmentMatchersOptions[0]
                        }
                        onChange={onSelectedMatcherChange}
                        isDisabled={shouldDisableMatcherSelectorControls}
                        options={selectedEnvironmentMatchersOptions}
                    />
                </DropDownContainer>
            </>
        );
    };

    const environmentSelectorControl = (
        <>
            <ControlHeader>{t('systems-tab.all-systems-page.extension-page.select-env')}</ControlHeader>
            <DropDownContainer>
                <WMSelect
                    value={environmentOptions.find((envOption) => envOption.value === selectedEnvironment.value)}
                    onChange={onSelectedEnvironmentChange}
                    isDisabled={shouldDisableMatcherSelectorControls}
                    options={environmentOptions}
                />
            </DropDownContainer>
        </>
    );

    const matcherSelectorControl = (
        <MatcherSelectorContainer>
            {unsavedChangesDialog}
            {environmentSelectorControl}
            {selectedEnvironmentMatchersOptions.length > 1 ? getConfigurationSetSelectorControl() : null}
        </MatcherSelectorContainer>
    );

    const content = (
        <>
            <StatusNotification {...notificationSettings}></StatusNotification>
            <MultiMatchersDialog isMultiMatchersMode={isMultiMatchersMode} setIsMultiMatchersMode={setIsMultiMatchersMode} />
            <ContentContainer>
                {matcherSelectorControl}
                <CollapsibleGroup
                    openIndex={openCollapsibleItemIndex}
                    handleItemClick={handleCollapsibleItemClick}
                    collapsibleItems={[
                        {
                            header: <CollapsibleHeader>{t('systems-tab.all-systems-page.extension-page.basic')}</CollapsibleHeader>,
                            content: basicCollapsibleSection,
                        },
                        {
                            header: <CollapsibleHeader>{t('systems-tab.all-systems-page.extension-page.advanced')}</CollapsibleHeader>,
                            content: advancedCollapsibleSection,
                        },
                    ]}
                />
            </ContentContainer>
            <FooterContainer>{getFooterButton()}</FooterContainer>
        </>
    );

    const getSystemNotConnectedComponent = () => (
        <ContentContainer>
            <SystemNotConnected
                canConnect={canLinkSystemToExtension}
                selectedSystem={currentSystemAppData.data}
                defaultEnvId={selectedEnvironment?.value ?? 0}
                user={loggedInUserAppData.data}
            />
        </ContentContainer>
    );

    const getErrorComponent = () => (
        <ContentContainer>
            <SpecialContentContainer>
                <img src='assets/icons/extension-get-system-data-error.png' alt='error' />
                <SpecialContentMessageHeader>{t('errors.extension-get-system-data-failure-title')}</SpecialContentMessageHeader>
                <SpecialContentMessageSubHeader>{t('errors.extension-get-system-data-failure-message')}</SpecialContentMessageSubHeader>
            </SpecialContentContainer>
        </ContentContainer>
    );

    const getContentComponent = () => {
        if (webSystemAppData.loading) {
            return loaderComponent;
        } else if (webSystemAppData.error) {
            return getErrorComponent();
        } else if (isSystemConnectedToExtension || isLinkToExtensionMode) {
            //TODO: move content component to new file
            return content;
        } else {
            return getSystemNotConnectedComponent();
        }
    };

    const showUrlVerifier = !webSystemAppData.loading && !webSystemAppData.error && (isSystemConnectedToExtension || isLinkToExtensionMode);

    return (
        <MainContainer>
            <HeaderContainer>
                <Box display='flex' flexDirection='column'>
                    <HeaderMainText>{t('systems-tab.all-systems-page.extension-page.title')}</HeaderMainText>
                    <HeaderSubText>{t('systems-tab.all-systems-page.extension-page.sub-title')}</HeaderSubText>
                </Box>
                {showUrlVerifier ? (
                    <URLVerifier systemData={systemData} selectedMatcher={selectedMatcher} onOpenExtensionSettings={onURLVerifierClosed} />
                ) : null}
            </HeaderContainer>
            {getContentComponent()}
        </MainContainer>
    );
};

export { Extension };
