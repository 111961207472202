enum TopDomainValidationMethod {
    All = 'all',
    Specific = 'specific',
}

interface TopDomainValidationMethodRadioValue {
    value: TopDomainValidationMethod;
    label: string;
}

const topDomainValidationMethodRadioValues: TopDomainValidationMethodRadioValue[] = [
    {
        value: TopDomainValidationMethod.All,
        label: 'On all domains',
    },
    {
        value: TopDomainValidationMethod.Specific,
        label: 'On specific domains',
    },
];

export { topDomainValidationMethodRadioValues, TopDomainValidationMethodRadioValue, TopDomainValidationMethod };
