import React from 'react';
import { UUID } from './uuid.lib';
import { StyledIdpTooltip } from './uuid-selector.styles';
import { Link } from 'react-router-dom';

export const getTooltipByUUID = (uuid: UUID, translator) => {
    const tooltips = {
        [UUID.IDP]: {
            disabled: (
                <StyledIdpTooltip>
                    {translator(`systems-tab.new-system-form.configure-step.uuid.idp.tooltip.text`)}
                    <div className='learn-more'>
                        <Link to={'/idp-integrations'} target='_blank'>
                            {translator(`systems-tab.new-system-form.configure-step.uuid.idp.tooltip.learn-more`)}
                        </Link>
                    </div>
                </StyledIdpTooltip>
            ),
        },
    };

    return tooltips[uuid];
};
