import Typography from '@material-ui/core/Typography/Typography';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { PartialSystemData, SystemMatcher, SystemMatchers } from '@walkme-admin-center/libs/exposure-api';
import React from 'react';
import { TopDomainValidationMethod } from './values';

const ALL_URLS_DOMAIN_MATCHER: string[] = ['http://*/*', 'https://*/*'];
const ALL_URLS_REGEX: string = '.*';

const getSettingsTitleComponent = (text: string) => {
    return (
        <Typography
            style={{
                fontFamily: 'ProximaNova',
                color: '#2F426C',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
            }}>
            {text}
        </Typography>
    );
};

//TODO(Ronen): add test for this
const getInitialTopDomainValidationMethodValue = (
    systemMatcher: SystemMatcher | undefined,
    isRegexMode: boolean
): TopDomainValidationMethod => {
    let initialTopDomainValidationMethodValue: TopDomainValidationMethod = TopDomainValidationMethod.All;

    if (!systemMatcher) {
        return initialTopDomainValidationMethodValue;
    }

    if (isRegexMode) {
        if (systemMatcher.topRegex !== ALL_URLS_REGEX) {
            initialTopDomainValidationMethodValue = TopDomainValidationMethod.Specific;
        }
    } else {
        if (!isEqual(systemMatcher.topDomains, ALL_URLS_DOMAIN_MATCHER)) {
            initialTopDomainValidationMethodValue = TopDomainValidationMethod.Specific;
        }
    }

    return initialTopDomainValidationMethodValue;
};

const DefaultEnvIds: number[] = [0, 3, 32, 95];
const isDefaultEnvironment = (envId: number): boolean => DefaultEnvIds.includes(envId);

const getSystemMatchersClone = (systemMatchers: SystemMatchers): SystemMatchers => cloneDeep(systemMatchers);

const getMinEnvironmentId = (systemData: PartialSystemData): number => {
    if (!systemData) {
        return undefined;
    }

    const environmentIds: number[] = Object.keys(systemData.environments).map((envId) => Number(envId));

    return Math.min(...environmentIds);
};

export {
    getSettingsTitleComponent,
    getInitialTopDomainValidationMethodValue,
    ALL_URLS_REGEX,
    ALL_URLS_DOMAIN_MATCHER,
    isDefaultEnvironment,
    getSystemMatchersClone,
    getMinEnvironmentId,
};
