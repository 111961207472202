import React, { useEffect, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { Box, Collapse } from '@material-ui/core';
import styled from 'styled-components';
import { useField, useForm } from 'react-final-form';
import useFileUpload from '../../../../services/validation/files/use-file-upload';
import { useAskMeFiles, useAskMeForm } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { useDispatch } from 'react-redux';
import { updateAskMeFormState } from 'packages/libs/state-mangment-data-integration/src/lib/redux/data-integration.slice';
import { ErrorCircle } from '../../../../data-integrations-form-manager/error-circle';
import { WarningIcon } from '../../../../data-integrations-form-manager/warning-icon';
import FormWarningBox from '../../../../data-integrations-form-manager/warning-box';
import { flatMap, isEqual, sortBy, uniq } from 'lodash';
import CustomToolTip from '../../../../../common/components/custom-tool-tip';
import { CancelIcon, ResetIcon, TrashIconSmall } from '../../../../../data-integrations-list/integration-card/icons/icons';
import { CssIconButton } from '../../../../../data-integrations-list/select-integrations-actions/select-integrations-actions';

const MAX_FILE_SIZE_MB = 15;
const MAX_FILE_SIZE_B = MAX_FILE_SIZE_MB * 1024 * 1024;

const DUPLICATE_FILENAME = 'duplicate-filename';

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex: 1 0 0;
`;

const DropZoneArea = styled.div<{ isDragActive: boolean; isError: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px dashed
        ${(props) =>
            props.isError
                ? 'var(--Color-Global-Semantic-On-light-danger-0, #E91616)'
                : 'var(--Color-Global-Semantic-On-light-primary-4, rgba(56, 95, 235, 0.20))'};
    background: ${(props) =>
        props.isDragActive ? 'var(--Color-Local-Semantic-Bg-layout-panel, #E3E9FC)' : 'var(--Color-Local-Semantic-Bg-primary, #F0F3F9)'};
    transition: all 0.3s ease;
`;

const DropZoneText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem var(--Spacers-Semantic-dialogs-padding, 2rem);
`;

const DropZoneError = styled.div`
    display: flex;
    overflow: hidden;
    padding: 0.5rem 1.25rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background: var(--Color-Global-Semantic-On-light-danger-2, #fcdede);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const DropZoneErrorMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    flex: 1 0 0;
`;

const DropZoneErrorText = styled.p`
    color: var(--Typography-Typography-MainPrimary-Main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    line-height: 0;
`;

const FileListTitle = styled.p`
    color: var(--Typography-Typography-Main, #2f426c);
    /* Headings/walk-me-h3 */
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; /* 155.556% */
    margin: 0;
`;

const FileEntry = styled.div<{ isError?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0.75rem 0;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => (props.isError ? '#FBD0D0' : '#EDF1FD')};
    background: ${(props) => (props.isError ? '#FDEAEA' : '#FFF')};
`;

const FileEntryDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const FileEntryActionsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const FileEntryProgressBarContainer = styled.div`
    display: flex;
    padding: 0.5rem 0.75rem 0.5625rem 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
`;

const FileEntryTitle = styled.div`
    color: #2f426c;
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
`;

const FileEntrySubtitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.375rem;
`;

const FileEntrySubtitle = styled.div`
    color: #8d97ae;
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const FileEntrySubtitleSuccess = styled(FileEntrySubtitle)`
    color: #31897d;
`;

const FileEntrySubtitleError = styled(FileEntrySubtitle)`
    color: #e91616;
`;

const FileEntryProgressBarBackground = styled.div`
    border-radius: 1.875rem;
    background: rgba(56, 95, 235, 0.05);
    width: 100%;
    height: 0.5rem;
    flex-shrink: 0;
    overflow: hidden;
`;

const FileEntryProgressBarForeground = styled.div<{ percentage?: number }>`
    background: #385feb;
    width: ${(props) => props.percentage ?? 0}%;
    height: 100%;
    transition: width 0.3s ease-in-out;
`;

const DropZoneTitle = styled.p`
    color: var(--Color-Local-Semantic-Text-primary, #2f426c);
    text-align: center;

    /* Headings/walk-me-h3 */
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; /* 155.556% */

    margin: 0.5rem 0;
`;

const DropZoneSubtitle = styled.p`
    color: var(--Color-Local-Semantic-Text-primary, #2f426c);
    text-align: center;

    /* Texts/t2/walk-me-t2 */
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
`;

const DropZoneNoteContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    line-height: 0;
`;

const DropZoneNoteText = styled.p`
    color: var(--Color-Global-Semantic-On-light-secondary-1, #637191);
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
`;

const UploadButton = styled(WMButton)`
    background-color: transparent;
    transition: border-color 0.3s ease;

    &:hover {
        border-color: #385feb;
    }
`;

const UPLOAD_ERROR_WARNING_SINGULAR = 'A file has failed to upload. If you proceed, it will not be processed.';
const UPLOAD_ERROR_WARNING_PLURAL = 'Some files have failed to upload. If you proceed, they will not be processed.';

const formatNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 10) / 10;
};

const FileEntryField = ({ file, removeFile, onFileStateChange }) => {
    const { uploadFile, cancelUpload, uploadProgress, success, failure, isUploading, askMeFile, secondsLeft, uploadCount } =
        useFileUpload();
    const {
        input: { value: sourceId },
    } = useField('sourceId');
    const {
        input: { value: files },
    } = useField('settings.files');

    const [isDeleteHover, setIsDeleteHover] = useState(false);
    const [isReuploadHover, setIsReuploadHover] = useState(false);

    useEffect(() => {
        if (file.rawFile && !file.isUploadSuccess && !file.isUploadError) {
            uploadFile(file.rawFile, sourceId);
        }
    }, []);

    useEffect(() => {
        if (uploadCount && file.rawFile && askMeFile) {
            onFileStateChange({ ...file, askMeFile });
        }
    }, [askMeFile, file.rawFile]);

    useEffect(() => {
        if (uploadCount) {
            onFileStateChange({ ...file, isUploading });
        }
    }, [isUploading]);

    useEffect(() => {
        if (uploadCount) {
            onFileStateChange({ ...file, isUploadError: failure });
        }
    }, [failure]);

    useEffect(() => {
        if (uploadCount) {
            onFileStateChange({ ...file, isUploadSuccess: success });
        }
    }, [success]);

    const removeFileEntry = () => {
        if (file.isUploading) {
            cancelUpload();
        }
        removeFile();
    };

    return (
        <FileEntry isError={failure}>
            <FileEntryDetails>
                <img src='/assets/icons/data-integrations/integrations-icons/upload-file.svg' style={{ width: '2rem', height: '2rem' }} />
                <Box display='flex' flexDirection='column' flex='1 0 0'>
                    <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                        <Box display='flex' flexDirection='column'>
                            <FileEntryTitle>{file.fileName}</FileEntryTitle>

                            <FileEntrySubtitleContainer>
                                <FileEntrySubtitle>{formatNumber((file.fileSize ?? 0) / (1024 * 1024))}MB</FileEntrySubtitle>
                                <img
                                    src='/assets/icons/data-integrations/integrations-icons/ellipse-small.svg'
                                    style={{ width: '0.25rem', height: '0.25rem' }}
                                />
                                {isUploading && <FileEntrySubtitle>{secondsLeft} sec left</FileEntrySubtitle>}
                                {!file.rawFile && (
                                    <FileEntrySubtitle>
                                        {file?.askMeFile?.createdAt
                                            ? new Date(file?.askMeFile?.createdAt).toLocaleDateString()
                                            : 'Uploaded'}
                                    </FileEntrySubtitle>
                                )}
                                {file.isUploadSuccess && <FileEntrySubtitleSuccess>Upload completed</FileEntrySubtitleSuccess>}
                                {file.isUploadError && <FileEntrySubtitleError>Upload failed</FileEntrySubtitleError>}
                            </FileEntrySubtitleContainer>
                        </Box>
                        <FileEntryActionsContainer>
                            {file.isUploadError && file.rawFile && (
                                <CustomToolTip placement={'top'} title={'Upload again'} arrow open={isReuploadHover}>
                                    <CssIconButton
                                        size={'small'}
                                        onClick={() => uploadFile(file.rawFile, sourceId)}
                                        strokecolor='#6D81A6'
                                        onMouseEnter={() => setIsReuploadHover(true)}
                                        onMouseLeave={() => setIsReuploadHover(false)}
                                        style={{ width: '1.5rem', height: '1.5rem' }}>
                                        <ResetIcon style={{ width: '1rem', height: '1rem' }} />
                                    </CssIconButton>
                                </CustomToolTip>
                            )}
                            <CustomToolTip
                                placement={'top'}
                                title={
                                    isUploading ? 'Cancel upload' : files.length > 1 ? 'Delete file' : 'Source must have at least one file'
                                }
                                arrow
                                open={isDeleteHover}>
                                <div onMouseEnter={() => setIsDeleteHover(true)} onMouseLeave={() => setIsDeleteHover(false)}>
                                    <CssIconButton
                                        size={'small'}
                                        onClick={removeFileEntry}
                                        disabled={!isUploading && files.length <= 1}
                                        strokecolor='#6D81A6'
                                        strokehovercolor={isUploading ? '#6D81A6' : '#E91616'}
                                        style={{ width: '1.5rem', height: '1.5rem' }}>
                                        {isUploading ? (
                                            <CancelIcon style={{ width: '1rem', height: '1rem' }} />
                                        ) : (
                                            <TrashIconSmall style={{ width: '1rem', height: '1rem' }} />
                                        )}
                                    </CssIconButton>
                                </div>
                            </CustomToolTip>
                        </FileEntryActionsContainer>
                    </Box>
                </Box>
            </FileEntryDetails>
            <Box minHeight='0.75rem'>
                <Collapse in={isUploading}>
                    <FileEntryProgressBarContainer>
                        <FileEntryProgressBarBackground>
                            <FileEntryProgressBarForeground percentage={uploadProgress} />
                        </FileEntryProgressBarBackground>
                    </FileEntryProgressBarContainer>
                </Collapse>
            </Box>
        </FileEntry>
    );
};

const DropZoneField = ({ input: { value, onChange, ...inputProps }, meta, ...props }) => {
    const formApi = useForm();
    const {
        input: { value: fileGuidsField },
    } = useField(inputProps.name);

    const { askMeFiles } = useAskMeFiles();

    const { askMeForm } = useAskMeForm();
    const [isDuplicateFilename, setIsDuplicateFilename] = useState(false);
    const [uploadErrorCount, setUploadErrorCount] = useState(0);

    const dispatch = useDispatch();

    const setFiles = (files) => dispatch(updateAskMeFormState({ files }));

    useEffect(() => {
        if (!askMeForm.files?.length) {
            const initialFilesData = fileGuidsField.map((guid: string) => {
                const askMeFile = askMeFiles[guid];
                return {
                    fileName: askMeFile.metadata.name,
                    fileSize: askMeFile.metadata.size,
                    askMeFile,
                };
            });
            setFiles(initialFilesData);
        }
    }, []);

    useEffect(() => {
        if (!askMeForm.files) {
            return;
        }

        const fileGuids = askMeForm.files
            .filter((file) => file?.askMeFile?.guid && (!file.rawFile || file.isUploadSuccess))
            .map((file) => file?.askMeFile?.guid);
        if (!isEqual(sortBy(fileGuidsField), sortBy(fileGuids))) {
            const initialFileGuidsField = formApi.getState().initialValues.settings.files;
            if (isEqual(fileGuids, initialFileGuidsField)) {
                formApi.change(inputProps.name, initialFileGuidsField);
            } else {
                formApi.change(inputProps.name, fileGuids);
            }
        }

        const isUploading = askMeForm.files.some((file) => file.isUploading);
        setUploadErrorCount(askMeForm.files.filter((file) => file.isUploadError)?.length);
        dispatch(updateAskMeFormState({ isFooterDisabled: isUploading }));
    }, [askMeForm.files]);

    const onDropFiles = (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
            const currentFilenames = new Set(askMeForm.files.map((file) => file.fileName));
            const uniqueAcceptedFiles = acceptedFiles.filter((file) => !currentFilenames.has(file.name));
            setIsDuplicateFilename(uniqueAcceptedFiles.length !== acceptedFiles.length);

            setFiles([
                ...askMeForm.files,
                ...uniqueAcceptedFiles.map((file) => ({
                    fileName: file.name,
                    fileSize: file.size,
                    rawFile: file,
                })),
            ]);
        }
    };

    const onFilesPropChange = (i, file) => {
        const newFiles = [...askMeForm.files];
        newFiles[i] = file;
        setFiles(newFiles);
    };

    const onRemoveFile = (index: number) => {
        setFiles(askMeForm.files.filter((_, i) => i !== index));
    };

    const supportedFileExtensions = ['.pdf', '.docx', '.txt'];

    const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: onDropFiles,
        multiple: true,
        accept: supportedFileExtensions,
        maxSize: MAX_FILE_SIZE_B,
    });

    const fileRejectionErrorCodes = uniq(flatMap(fileRejections, (fileRejection) => fileRejection.errors).map((error) => error.code));
    if (isDuplicateFilename) {
        fileRejectionErrorCodes.push(DUPLICATE_FILENAME);
    }
    const dragErrorMsg = !fileRejectionErrorCodes?.length
        ? ''
        : fileRejectionErrorCodes.every((code) => code === ErrorCode.FileInvalidType)
        ? "Can't upload. Use one of these formats: PDF, DOCX, PPTX, CSV."
        : fileRejectionErrorCodes.every((code) => code === ErrorCode.FileTooLarge)
        ? `Can't upload. File size exceeds ${MAX_FILE_SIZE_MB}MB.`
        : fileRejectionErrorCodes.every((code) => code === DUPLICATE_FILENAME)
        ? 'Another file with the same name already exists.'
        : 'Some files failed to upload due to format, size or name.';
    const isDragError = !!dragErrorMsg?.length;

    return (
        <FieldContainer>
            <DropZoneArea isDragActive={isDragActive} isError={isDragError} {...getRootProps()}>
                <input {...getInputProps()} multiple={true} />
                <Collapse in={isDragError}>
                    <DropZoneError>
                        <DropZoneErrorMessage>
                            <ErrorCircle />
                            <DropZoneErrorText>{dragErrorMsg}</DropZoneErrorText>
                        </DropZoneErrorMessage>
                    </DropZoneError>
                </Collapse>
                <Box height='1.5rem' />
                <DropZoneText>
                    <img
                        src='/assets/icons/data-integrations/integrations-icons/upload-file.svg'
                        style={{ width: '2rem', height: '2rem' }}
                    />
                    <DropZoneTitle>Drag and drop your files here</DropZoneTitle>
                    <DropZoneSubtitle>
                        Maximum size per file: {MAX_FILE_SIZE_MB}MB
                        <br />
                        Supported formats: PDF, TXT, DOCX
                    </DropZoneSubtitle>
                    <Box height='1.25rem' />
                    <UploadButton variant={WMButtonVariant.Secondary} onClick={open}>
                        Upload
                    </UploadButton>
                    <Box height='0.5rem' />
                    <DropZoneNoteContainer>
                        <img style={{ width: '0.75rem', height: '0.75rem' }} src='assets/icons/data-integrations/info-icon-small.svg' />
                        <DropZoneNoteText>Note: the file name will be visible to end users.</DropZoneNoteText>
                    </DropZoneNoteContainer>
                </DropZoneText>
            </DropZoneArea>
            <Collapse in={!!uploadErrorCount}>
                <FormWarningBox>
                    <WarningIcon />
                    <div>{uploadErrorCount === 1 ? UPLOAD_ERROR_WARNING_SINGULAR : UPLOAD_ERROR_WARNING_PLURAL}</div>
                </FormWarningBox>
            </Collapse>
            <Box display='flex' flexDirection='column' flex='1 0 0' style={{ gap: '0.5rem' }}>
                {!!askMeForm.files?.length && (
                    <>
                        <FileListTitle>Uploaded Files</FileListTitle>
                        <Box display='flex' flexDirection='column' style={{ gap: '0.5rem' }}>
                            {askMeForm.files.map((file, i) => (
                                <FileEntryField
                                    key={i}
                                    file={file}
                                    removeFile={() => onRemoveFile(i)}
                                    onFileStateChange={(file) => onFilesPropChange(i, file)}
                                />
                            ))}
                        </Box>
                    </>
                )}
            </Box>
        </FieldContainer>
    );
};

export default DropZoneField;
