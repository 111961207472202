import React, { useState, useRef, useEffect } from 'react';
import { UrlMatchingAccordion } from './url-matching-accordion';
import { StyledInput, StyledInputContainer, StyledInputLabel, StyledSpinner, StyledUrlMatchingContainer } from './url-matching.styles';
import { InputHeight, Typography } from '@walkme/ui-core';
import { useDebouncedCallback } from 'use-debounce';
import { CreateSystemState, handleAppUrl } from '@walkme-admin-center/libs/state-management-systems';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
interface URLMatchingProps {
    isOpen?: boolean;
}

export const UrlMatching = ({ isOpen = true }: URLMatchingProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [url, setUrl] = useState<string>('');
    const [loadingUrlIndex, setLoadingUrlIndex] = useState<number>(null);
    const appUrlsData = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.appUrls.data);
    const appUrlsLoading = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.appUrls.loading);
    const inputRef = useRef(null);
    const [isPrevFocused, setIsPrevFocused] = useState<boolean>(false);
    const [isUrlChanged, setIsUrlChanged] = useState<boolean>(false);
    const urlInputLabel = t('systems-tab.new-system-form.url-input-text');

    const onChange = (value: string, index: number) => {
        if (index === 0) {
            setUrl(value);
            handleUrlChange(value, index);
        }
    };

    const handleUrlChange = useDebouncedCallback((value, index) => {
        setLoadingUrlIndex(index);
        dispatch(handleAppUrl(value, index));
        setIsUrlChanged(false);
    }, 2000);

    const onBlur = (value, index) => {
        setIsPrevFocused(false);
        if (isUrlChanged) {
            handleUrlChange.cancel();
            setLoadingUrlIndex(index);
            dispatch(handleAppUrl(value, index));
            setIsUrlChanged(false);
        }
    };

    const handleUrlInputFocus = () => {
        if (!appUrlsLoading && isPrevFocused && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const setUrlOnInit = () => {
        const firstAppUrl = appUrlsData.find((appUrl) => appUrl.order === 0);
        if (firstAppUrl) {
            setUrl(firstAppUrl.url);
        }
    };

    useEffect(() => {
        setIsUrlChanged(true);
    }, [url]);

    useEffect(handleUrlInputFocus, [appUrlsLoading]);

    useEffect(setUrlOnInit, []);

    return (
        <StyledUrlMatchingContainer>
            <StyledInputLabel>
                <Typography variant={'T20-2'}>{urlInputLabel}</Typography>
            </StyledInputLabel>

            <StyledInputContainer>
                <StyledInput
                    ref={inputRef}
                    placeholder={t('systems-tab.new-system-form.domain-placeholder')}
                    height={InputHeight.large}
                    width={560}
                    suffixElem={appUrlsLoading && loadingUrlIndex === 0 && <StyledSpinner color={'#2F426C'}></StyledSpinner>}
                    value={url}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 0)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur(e.target.value, 0)}
                    onFocus={() => setIsPrevFocused(true)}
                    disabled={appUrlsLoading}
                />
            </StyledInputContainer>

            {appUrlsData.find((appUrl) => appUrl.order === 0 && appUrl.app !== null) && !appUrlsLoading && (
                <UrlMatchingAccordion app={appUrlsData[0]?.app} />
            )}
        </StyledUrlMatchingContainer>
    );
};
