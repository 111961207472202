export interface TableHeadCell {
    id: string;
    label: string;
}

export type Order = 'asc' | 'desc';

export interface TableCustomCell {
    value: string;
    displayValue: JSX.Element;
}

export interface TableRowData {
    [key: string]: number | string | TableCustomCell;
}
