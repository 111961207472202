import * as yup from 'yup';
import { AuthType, ScanFrequency } from '../../../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import isValidDomain from 'is-valid-domain';
import { validateDomainAndPath, validateXpath } from '../validate';

const bearerTokenSchema = yup.object().shape({
    token: yup.string().required('Required'),
});

const basicAuthSchema = yup.object().shape({
    username: yup.string().required('Required'),
    password: yup.string().required('Required'),
});

const oauth2Schema = yup.object().shape({
    accessTokenUrl: yup.string().required('Required'),
    clientId: yup.string().required('Required'),
    clientSecret: yup.string().required('Required'),
    scope: yup.string(),
});

const fromAuthenticationSchema = yup.object().shape({
    ssoDomain: yup
        .string()
        .test('Valid Application Domain', 'Please enter valid Application Domain', (value) => validateDomainAndPath(value))
        .max(255, 'Max 255 characters')
        .required('Required'),
    username: yup.string().required('Required').max(255, 'Max 255 characters'),
    password: yup.string().required('Required').max(255, 'Max 255 characters'),
    usernameXpath: yup
        .string()
        .required('Required')
        .max(255, 'Max 255 characters')
        .test('Valid XPath', 'Please enter valid XPath', (value) => validateXpath(value)),
    passwordXpath: yup
        .string()
        .required('Required')
        .max(255, 'Max 255 characters')
        .test('Valid XPath', 'Please enter valid XPath', (value) => validateXpath(value)),
    signInButtonXpath: yup
        .string()
        .required('Required')
        .max(255, 'Max 255 characters')
        .test('Valid XPath', 'Please enter valid XPath', (value) => validateXpath(value)),
    appEmbedLink: yup
        .string()
        .max(255, 'Max 255 characters')
        .test('Valid Application Domain', 'Please enter valid app embed link', (value) => validateDomainAndPath(value)),
    appEmbedLinkLoginButtonXpath: yup
        .string()
        .max(255, 'Max 255 characters')
        .test('Valid XPath', 'Please enter valid XPath', (value) => validateXpath(value)),
    skipLoadEvent: yup.boolean(),
});

const auditScanSchema = yup.object().shape({
    frequency: yup.string().required('Required'),
    dayOfWeek: yup.number().when('frequency', {
        is: ScanFrequency.WEEKLY,
        then: yup.number().required('Required'),
    }),
    dayOfMonth: yup.number().when('frequency', {
        is: ScanFrequency.MONTHLY,
        then: yup.number().required('Required'),
    }),
    customDate: yup.string().when('frequency', {
        is: ScanFrequency.CUSTOM,
        then: yup.string().required('Required'),
    }),
});

export const settingsSchema = yup.object().shape({
    settings: yup.object({
        authType: yup.string(),
        auth: yup
            .object({})
            .when('authType', {
                is: AuthType.BEARER_TOKEN,
                then: (schema) => schema.concat(bearerTokenSchema),
            })
            .when('authType', {
                is: AuthType.BASIC_AUTH,
                then: (schema) => schema.concat(basicAuthSchema),
            })
            .when('authType', {
                is: AuthType.OAUTH2,
                then: (schema) => schema.concat(oauth2Schema),
            })
            .when('authType', {
                is: AuthType.FORM_AUTHENTICATION,
                then: (schema) => schema.concat(fromAuthenticationSchema),
            }),
        auditScan: auditScanSchema,
    }),
});
