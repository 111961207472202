import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WMSwitch } from '@walkme/wm-ui';
import { useDispatch, useSelector } from 'react-redux';
import {
    accountIdpsSlice,
    AccountIdpsState,
    deleteAccountIdp,
    setSecurityFlags,
    useAccountIdps,
    useSecurityFlags,
    useUsers,
} from '@walkme-admin-center/libs/state-management-users';
import { User, WMAccountIdp, WMSAMLIdp } from 'wm-accounts-sdk';
import { DataTable, useTable } from '@walkme-admin-center/libs/ui-components';
import { Box } from '@material-ui/core';
import moment from 'moment';
import {
    WMBarChart,
    WMButton,
    WMButtonVariant,
    WMIconDelete,
    WMIconDone,
    WMSnackbar,
    WMSnackbarVariant,
    WMTooltipProps,
} from '@walkme/wm-ui';
import { IdpConfigStatus, IdpConfigStepsData, IdpRow } from './types/types';
import ExternalIdpWizard from './external-idp-wizard';
import { LabelMsgActive, LabelMsgIdpList, LabelMsgIdpListNumbers, NoPasswordDiv } from './styles/styles';
import SecurityCard from '../common/security-card';
import { PermissionsErrorkey } from '@walkme-admin-center/libs/common';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import NoPasswordWarningModal from './no-password-warning-modal';
import { momentLocalesMapping } from 'apps/home/src/localization';
import { useDataTableLocale } from 'packages/pages/util/locale-utils';

export interface ExternalIdentityProvidersProps {
    accountFeatureEnabled: (feature: string) => boolean;
}

export const ExternalIdpsList = (props: ExternalIdentityProvidersProps) => {
    const { t, i18n } = useTranslation('general');
    const childCompRef = useRef(null);
    const { accountIdpsAppData } = useAccountIdps();
    const { securityFlagsAppData } = useSecurityFlags();
    const tableSettings = useTable();
    const [showDialog, setShowDialog] = useState(false);
    const [showNoPasswordWarning, setShowNoPasswordWarning] = useState(false);
    const [deletedIdpId, setDeletedIdpId] = useState<string>(null);
    const [selectedIdp, setSelectedIdp] = useState<WMSAMLIdp>(null);
    const deletedIdpAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.deletedAccountIdp);
    const setSecurityFlagsAppData = useSelector(
        (state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.settingSecurityFlags
    );
    const getSecurityFlagsAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.securityFlags);

    const [buttonWidth, setButtonWidth] = useState(150);

    const dataTableLocale = useDataTableLocale();

    useEffect(() => {
        if (i18n.language === 'jp') {
            setButtonWidth(180);
        } else {
            setButtonWidth(150);
        }
    }, [i18n.language]);

    const { usersAppData } = useUsers();
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState('');

    const shouldShowDeleteButton = true;

    const tooltipProps = {
        title: t('security-tab.idps.remove-idp'),
        placement: 'top',
    } as WMTooltipProps;

    const formatError = (error: string) => {
        const forbiddenResourceError = error.includes('Forbidden resource');
        if (forbiddenResourceError) {
            return t(PermissionsErrorkey);
        }
        return error;
    };

    const getIdpStatus = (idp: WMAccountIdp, user: User[]): IdpConfigStatus => {
        if (idp.isOldIdpFlow) return IdpConfigStatus.activated;
        if (!(idp as WMSAMLIdp).idpCertificate) return IdpConfigStatus.idpCustomerConnection;
        if (user.length === 0) return IdpConfigStatus.assignUsers;
        return IdpConfigStatus.activated;
    };

    const getIdpRows = (idps: WMAccountIdp[], users: User[]): IdpRow[] => {
        if (idps) {
            return idps.map((idp: WMAccountIdp) => {
                const idpUsersData = users.filter((user) => user.attachedIdpId === idp.idpId);
                const creationDateFormatted = moment(idp.creationDate).format('MMM Do');
                const currentLocationStatus = getIdpStatus(idp, idpUsersData);

                const activated = currentLocationStatus / 4 == 1;

                const idpStatus = (
                    <Box display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='row'>
                            <LabelMsgIdpListNumbers>{currentLocationStatus}/4</LabelMsgIdpListNumbers>
                            <LabelMsgIdpList>{t(IdpConfigStepsData[currentLocationStatus].displayName)}</LabelMsgIdpList>
                        </Box>

                        <WMBarChart bars={[{ value: (currentLocationStatus / 4) * 100, legend: 'of users' }]} height={4} width={150} />
                    </Box>
                );

                const idpStatusActivated = (
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <WMIconDone color='var(--wmPrimary)' />
                        <LabelMsgActive>{t('common.activated')}</LabelMsgActive>
                    </Box>
                );

                return {
                    id: idp.idpId,
                    idpType: idp.isOldIdpFlow ? 'N/A' : idp.idpType,
                    idpName: idp.isOldIdpFlow ? t('security-tab.idps.legacy-sso') : idp.idpName,
                    idpUsers: idpUsersData.length,
                    creationDateFormatted: creationDateFormatted,
                    idpStatus: {
                        value: idp.idpStatus,
                        displayValue: activated ? idpStatusActivated : idpStatus,
                    },
                    removeButton: shouldShowDeleteButton
                        ? {
                              value: 'remove',
                              displayValue: (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <WMButton
                                          variant={WMButtonVariant.Secondary}
                                          isIconButton
                                          loading={deletedIdpAppData.loading && deletedIdpId === idp.idpId}
                                          disabled={deletedIdpAppData.loading && deletedIdpId === idp.idpId}
                                          iconComponent={<WMIconDelete color='var(--wmError)' />}
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              onConfirmDeleteIdp(idp);
                                          }}
                                          tooltipProps={tooltipProps}
                                      />
                                  </div>
                              ),
                          }
                        : null,
                };
            });
        }

        return [];
    };

    const activeIDPExists = (idps: WMAccountIdp[], users: User[]): boolean => {
        let exists = false;
        if (idps) {
            exists = idps.some((idp) => {
                const idpUsersData = users.filter((user) => user.attachedIdpId === idp.idpId);
                const currentLocationStatus = getIdpStatus(idp, idpUsersData);
                const activated = currentLocationStatus / 4 == 1;
                if (activated) {
                    return true;
                }
            });
        }
        return exists;
    };

    const IdpsRows = useMemo(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
        return getIdpRows(accountIdpsAppData.data, usersAppData.data);
    }, [accountIdpsAppData, usersAppData, deletedIdpAppData, t, i18n.language]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (deletedIdpAppData.data) {
            dispatch(accountIdpsSlice.actions.deletedAccountIdpCleanup());
            setDeletedIdpId(null);
            //length before deletion
            if (accountIdpsAppData.data.length <= 1) {
                childCompRef.current.expand(false);
            }
        } else if (deletedIdpAppData.error) {
            setOpenErrorSnackbar(deletedIdpAppData.error);
            setDeletedIdpId(null);
        }
    }, [deletedIdpAppData]);

    useEffect(() => {
        if (setSecurityFlagsAppData.error) {
            setOpenErrorSnackbar(setSecurityFlagsAppData.error);
        }
    }, [setSecurityFlagsAppData]);

    useEffect(() => {
        if (getSecurityFlagsAppData.error) {
            setOpenErrorSnackbar(getSecurityFlagsAppData.error);
        }
    }, [getSecurityFlagsAppData]);

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar('');
    };

    const cancel = useCallback(
        (pristine: boolean) => {
            if (accountIdpsAppData.data.length > 0) {
                childCompRef.current.expand(true);
            }
            setShowDialog(false);
        },
        [setShowDialog, childCompRef, accountIdpsAppData]
    );

    const onConfirmDeleteIdp = useCallback(
        (idpToDelete: WMAccountIdp) => {
            setDeletedIdpId(idpToDelete.idpId);
            dispatch(deleteAccountIdp(idpToDelete.idpId));
        },
        [dispatch, usersAppData, setDeletedIdpId]
    );

    const onNewUserNoPasswordChange = useCallback((event, checked: boolean) => {
        if (checked) {
            setShowNoPasswordWarning(true);
        } else {
            dispatch(setSecurityFlags(false));
        }
    }, []);

    const createNewIdpDialog = useCallback(() => {
        setSelectedIdp(null);
        setShowDialog(true);
    }, [cancel]);

    const handleIdpSelection = useCallback(
        (id) => {
            const accountIdp = accountIdpsAppData.data.find((idp) => idp.idpId === id) as WMSAMLIdp;
            if (accountIdp.isOldIdpFlow || accountIdp.isSystem) {
                // show popup idp not editable + upgrade
                return;
            }
            setSelectedIdp(accountIdp);
            setShowDialog(true);
        },
        [onConfirmDeleteIdp, accountIdpsAppData]
    );

    const headCells = useMemo(() => {
        const cells = [
            { id: 'idpName', label: t('security-tab.idps.column-names.name') },
            { id: 'idpType', label: t('security-tab.idps.column-names.type') },
            { id: 'creationDateFormatted', label: t('security-tab.idps.column-names.creation-date') },
            { id: 'idpUsers', label: t('security-tab.idps.column-names.users') },
            { id: 'idpStatus', label: t('security-tab.idps.column-names.status') },
        ];
        if (shouldShowDeleteButton) {
            cells.push({ id: 'removeButton', label: '' });
        }

        return cells;
    }, [t]);

    const idpListComponant = (
        <div style={{ paddingBottom: '40px' }}>
            {showNoPasswordWarning && (
                <NoPasswordWarningModal
                    onCancel={() => setShowNoPasswordWarning(false)}
                    onFinish={() => {
                        setShowNoPasswordWarning(false);
                        dispatch(setSecurityFlags(true));
                    }}
                    showDialog={showNoPasswordWarning}
                    t={t}
                />
            )}
            <DataTable
                onToolbarButtonClick={createNewIdpDialog}
                heads={headCells}
                data={IdpsRows}
                buttonText={t('security-tab.idps.add-idp-button')}
                buttonWidth={180}
                hasToolbar
                showFooter={false}
                showSearchInput={false}
                disableEmptyRows={true}
                loading={accountIdpsAppData.loading}
                onRowClick={handleIdpSelection}
                {...tableSettings}
                searchPlaceholder={t('data-grid.search-placeholder')}
                locale={dataTableLocale}
            />
            <WMSnackbar
                open={openErrorSnackbar ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={formatError(openErrorSnackbar)}
            />
            {props.accountFeatureEnabled('allowCreateUserWithoutPassword') && (
                <NoPasswordDiv>
                    <WMSwitch
                        disabled={!activeIDPExists(accountIdpsAppData.data, usersAppData.data)}
                        id='create-idp-users-without-password'
                        onChange={onNewUserNoPasswordChange}
                        checked={securityFlagsAppData.data?.idpUsersNoPassword}
                        label={t('security-tab.idps.create-users-no-password')}
                    />
                </NoPasswordDiv>
            )}
        </div>
    );
    return (
        <>
            {showDialog && (
                <ExternalIdpWizard
                    showError={(error: string) => setOpenErrorSnackbar(error)}
                    onCancel={() => cancel(true)}
                    onFinish={() => cancel(true)}
                    showDialog={showDialog}
                    initalSelectedIdp={selectedIdp}
                    initialStep={selectedIdp ? IdpConfigStatus.idpConfiguration : IdpConfigStatus.idpCreated}
                />
            )}
            <SecurityCard
                ref={childCompRef}
                name='sso'
                collapsableComponant={idpListComponant}
                handleSwitchClick={createNewIdpDialog}
                loading={accountIdpsAppData.loading}
                title={t('security-tab.idps.title')}
                subTitle={t('security-tab.idps.subtitle')}
                checked={accountIdpsAppData && accountIdpsAppData.data.length > 0}
                disabled={accountIdpsAppData && accountIdpsAppData.data.length > 0}
            />
        </>
    );
};

export default ExternalIdpsList;
