import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputSearch, useToaster, ToasterVariant, Tooltip } from '@walkme/ui-core';
import { Export, Filter, Plus } from '@walkme/ui-icons';
import { WMButtonVariant, WMDivider, WMIconDelete, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { UsersState, editMultipleUsers, getUsers, useRoles, useUsers, usersSlice } from '@walkme-admin-center/libs/state-management-users';
import moment from 'moment';
import SystemNewUsers from './system-new-users';
import { useDispatch, useSelector } from 'react-redux';
import { Roles, UsersBulkMutationResult } from 'wm-accounts-sdk';
import { RoleFilter } from './role-filter';
import { DateFilter } from './date-filter';
import { DateRange } from 'react-day-picker';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import {
    HeaderDiv,
    HeaderTitles,
    Filterdiv,
    PlusIconDiv,
    StyledWMAgGridWrapper,
    StyledUsersDiv,
    HeaderMainText,
    HeaderSubText,
    DateText,
    TimeText,
} from './system-users.styles';
import { FiltersSelected } from '../../../../../../libs/common/assets/icons/FiltersSelected';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { AppData } from '@walkme-admin-center/libs/types';
import { DeleteButton, ExportButton, MultipleSelectionFooter } from '@walkme-admin-center/libs/ui-components';

export interface SystemUsersTabProps {
    systemId: number;
    systemName: string;
}

export const SystemUsersTab = (props: SystemUsersTabProps) => {
    const { t, rt } = useTranslation('general');
    const { usersAppData } = useUsers();
    const { systemsAppData } = useSystems();
    const users = useMemo(() => {
        return usersAppData.data.filter((user) => user.systems.find((system) => system.id === props.systemId));
    }, [usersAppData]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [query, setQuery] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch();
    const { addToaster } = useToaster();
    const updatedSystemsAppData: AppData<UsersBulkMutationResult> = useSelector(
        (state: { usersState: UsersState }) => state.usersState.updateUsers
    );
    const exportUsersAppData: AppData<UsersBulkMutationResult> = useSelector(
        (state: { usersState: UsersState }) => state.usersState.exportUsers
    );

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const RemoveUsersFromSystem = useCallback(
        (detailedUSersToRemove, allUsers) => {
            const usersToUpdate = allUsers.filter((user) => detailedUSersToRemove.find((row) => user.id === row.id));
            const finalUsers = usersToUpdate.map((user) => {
                return {
                    userId: user.id,
                    systemIds: user.systems.filter((existingSystem) => existingSystem.id !== props.systemId).map((system) => system.id),
                };
            });
            dispatch(editMultipleUsers({ editUsers: finalUsers }));
        },
        [dispatch, props.systemId]
    );

    const AddUsersToSystem = useCallback(
        (detailedUSersToAdd, allUsers) => {
            const usersToUpdate = allUsers.filter((user) => detailedUSersToAdd.find((row) => user.id === row.id));
            const finalUsers = usersToUpdate.map((user) => {
                return {
                    userId: user.id,
                    systemIds: user.systems.map((system) => system.id).concat(props.systemId),
                };
            });
            dispatch(editMultipleUsers({ editUsers: finalUsers }));
        },
        [dispatch, props.systemId]
    );

    useEffect(() => {
        if (updatedSystemsAppData.data?.users.length > 0) {
            const hasUsersBeenRemoved = selectedRows.length > 0;
            const multipleUsers = updatedSystemsAppData.data.users.length > 1;
            const actionMessageKey = hasUsersBeenRemoved
                ? 'systems-tab.all-systems-page.system-users-page.success-message-remove-users'
                : 'systems-tab.all-systems-page.system-users-page.success-message-assign-users';
            const singleUserActionMessageKey = hasUsersBeenRemoved
                ? 'systems-tab.all-systems-page.system-users-page.success-message-remove-user'
                : 'systems-tab.all-systems-page.system-users-page.success-message-assign-user';

            const message = multipleUsers
                ? `${updatedSystemsAppData.data.users.length} ${t(actionMessageKey)}`
                : `${t(singleUserActionMessageKey)}`;
            addToaster({
                message: message,
                variant: ToasterVariant.Success,
                width: 'content',
                autoDismiss: true,
                distance: '60',
            });
            dispatch(getUsers());
            dispatch(usersSlice.actions.updateUsersCleanup());
            //setOpenDialog(false);
            gridApi?.deselectAll();
            setSelectedRows([]);
        }
    }, [updatedSystemsAppData.data]);

    const exportUsageStatus = (selectedUsers) => {
        try {
            dispatch(usersSlice.actions.exportUsersStart());
            const userssData = selectedUsers.map((user) => ({
                Name: user.firstName + ' ' + user.lastName,
                Email: user.email,
                Role: user.role?.displayName,
                'Last Login Date': moment(user.lastLoginDate).format('ll'),
            }));

            const csv = Papa.unparse(userssData, { escapeFormulae: true });
            const blob = new Blob([csv]);
            FileSaver.saveAs(blob, `${props.systemName}-users_${moment().format('L')}.csv`);
            dispatch(usersSlice.actions.exportUsersSuccess());
        } catch (e) {
            dispatch(usersSlice.actions.exportUsersFailed(e.message));
        }
    };

    const deleteButton: DeleteButton = {
        onClick: (selectedRows) => RemoveUsersFromSystem(selectedRows, usersAppData.data),
        title: t('systems-tab.all-systems-page.system-users-page.remove-users'),
        loading: updatedSystemsAppData.loading,
    };

    const exportButton: ExportButton = {
        icon: (
            <Tooltip title={t('systems-tab.all-systems-page.system-users-page.export-text')}>
                <Export style={{ marginTop: '4px' }} />
            </Tooltip>
        ),
        onClick: (selectedRows) => exportUsageStatus(selectedRows),
        loading: exportUsersAppData.loading,
    };

    const [openFilterSection, setOpenFilterSection] = useState(false);
    const { rolesAppData } = useRoles();
    const allRoles = rolesAppData.data;
    const [selectedRolesToFilter, setSelectedRolesToFilter] = useState(allRoles);
    const [selectedDateToFilter, setSelectedDateToFilter] = useState<DateRange>(null);
    const currentSnackbarMessage = useSelector((state: { usersState: UsersState }) => state.usersState.notificationMessage);
    const handleSnackBarClose = (event, reason) => {
        dispatch(usersSlice.actions.cleanUpNotificationMessage());
    };

    const processUsersFilters = () => {
        const newFilteredUsersByRoles =
            selectedRolesToFilter?.length === allRoles.length
                ? users
                : users.filter((user) => !!selectedRolesToFilter.find((selectedRole) => selectedRole.id === user.role.id));
        const newFilteredUsersByDate =
            selectedDateToFilter &&
            users.filter(
                (user) =>
                    selectedDateToFilter &&
                    new Date(user.lastLoginDate) > new Date(selectedDateToFilter.from) &&
                    new Date(user.lastLoginDate) < new Date(selectedDateToFilter.to)
            );
        const finalFilteredUsers = newFilteredUsersByDate
            ? newFilteredUsersByRoles.filter((user) => newFilteredUsersByDate?.includes(user))
            : newFilteredUsersByRoles;

        setFilteredUsers(finalFilteredUsers);
    };

    useEffect(() => {
        !openFilterSection && setSelectedRolesToFilter(allRoles);
        !openFilterSection && setSelectedDateToFilter(null);
    }, [allRoles, openFilterSection]);

    useEffect(processUsersFilters, [selectedRolesToFilter, users, selectedDateToFilter, allRoles]);

    const filtersArentDefault = selectedRolesToFilter.length !== allRoles.length || selectedDateToFilter !== null;

    return (
        <div style={{ height: '100%' }}>
            <HeaderDiv>
                <HeaderTitles>
                    <HeaderMainText>{t('systems-tab.all-systems-page.system-users-page.title')}</HeaderMainText>
                    <HeaderSubText>{t('systems-tab.all-systems-page.system-users-page.sub-title')}</HeaderSubText>
                </HeaderTitles>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <div style={{ width: '280px' }}>
                        <InputSearch
                            placeholder={t('systems-tab.all-systems-page.system-users-page.search-placeholder')}
                            onChange={(e) => setQuery(e)}
                        />
                    </div>
                    {openFilterSection ? (
                        <Tooltip title={t('systems-tab.all-systems-page.system-users-page.collapse-filters')}>
                            <PlusIconDiv style={{ cursor: 'pointer', position: 'relative' }} onClick={() => setOpenFilterSection(false)}>
                                {filtersArentDefault ? <FiltersSelected /> : <Filter />}
                            </PlusIconDiv>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('systems-tab.all-systems-page.system-users-page.open-filters')}>
                            <Filter color='#6d81a6' onClick={() => setOpenFilterSection(true)} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                    )}
                    <WMDivider orientation='vertical' flexItem />
                    <Tooltip title={t('systems-tab.all-systems-page.assign-users-form.title')}>
                        <PlusIconDiv style={{ cursor: 'pointer' }}>
                            <Plus onClick={() => setOpenDialog(true)} />
                        </PlusIconDiv>
                    </Tooltip>
                </div>
            </HeaderDiv>
            {openFilterSection && (
                <Filterdiv>
                    <RoleFilter selectedRoles={selectedRolesToFilter} allRoles={allRoles} setSelectedRoles={setSelectedRolesToFilter} />
                    <DateFilter filterDate={selectedDateToFilter} setSelectedFilterDate={setSelectedDateToFilter} />
                </Filterdiv>
            )}
            <StyledUsersDiv>
                <StyledWMAgGridWrapper
                    rowSelection='multiple'
                    quickFilterText={query}
                    onGridReady={onGridReady}
                    suppressRowClickSelection={true}
                    suppressCellFocus={true}
                    gridOptions={{
                        onRowSelected: (params) => {
                            const newSelectedRows = params.api.getSelectedRows();
                            setSelectedRows(newSelectedRows);
                        },
                    }}
                    columnDefs={[
                        {
                            field: '',
                            headerCheckboxSelection: true,
                            checkboxSelection: true,
                            width: 40,
                            cellStyle: { paddingLeft: '0', borderColor: 'transparent' },
                            headerClass: 'header-black',
                        },
                        {
                            headerName: t('common.name'),
                            field: 'firstName',
                            sortable: true,
                            flex: 1,
                            cellRenderer: function (params) {
                                return params.data.firstName + ' ' + params.data.lastName;
                            },
                            cellStyle: {
                                color: 'var(--typography-typography-main-primary-main, #2F426C)',
                                fontFamily: 'Proxima Nova',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '16px',
                                display: 'flex',
                                alignItems: 'center',
                            },
                        },
                        {
                            headerName: t('common.role'),
                            field: 'role',
                            flex: 1,
                            cellRenderer: function (params) {
                                return rt(params.value?.displayName || '');
                            },
                            cellStyle: {
                                color: 'var(--typography-typography-main-primary-main, #2F426C)',
                                fontFamily: 'Proxima Nova',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '16px',
                                display: 'flex',
                                alignItems: 'center',
                            },
                        },
                        {
                            headerName: t('common.last-login'),
                            field: 'lastLoginDate',
                            flex: 1,
                            cellStyle: { display: 'flex', alignItems: 'center' },
                            cellRenderer: function (params) {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <DateText>{moment(params.value).format('ll')}</DateText>
                                        <TimeText>{moment(params.value).format('LT')}</TimeText>
                                    </div>
                                );
                            },
                        },
                        { field: 'email', hide: true },
                    ]}
                    rowData={filteredUsers}
                />
                {selectedRows.length > 0 && (
                    <MultipleSelectionFooter
                        onCancel={() => {
                            gridApi.deselectAll();
                            setSelectedRows([]);
                        }}
                        numSelectedRows={selectedRows.length}
                        entityName={t('common.user')}
                        getSelectedRows={() => selectedRows}
                        deleteButton={deleteButton}
                        exportButton={exportButton}
                    />
                )}
            </StyledUsersDiv>
            <SystemNewUsers
                isDrawerOpen={openDialog}
                onClose={() => setOpenDialog(false)}
                selectedSystemId={props.systemId}
                selectedSystemName={props.systemName}
                users={usersAppData.data}
                AddUsersToSystem={AddUsersToSystem}
                loading={updatedSystemsAppData.loading}
            />
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
            />
        </div>
    );
};
