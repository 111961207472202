import { Route, Routes } from 'react-router-dom';
import React, { useMemo } from 'react';
import { ActivationScreen, OpenAppScreen } from '@walkme-admin-center/pages/home/signin';

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path='/signin/activate/:activationKey' element={<ActivationScreen />} />
            <Route path='/signin/open-app' element={<OpenAppScreen />} />
        </Routes>
    );
};

export default () => {
    return <PublicRoutes />;
};
