import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { CreateSystemSteps } from '../../../../../../../libs/state-management-systems/src/lib/types/createSystem';
import { SystemUsage } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';

export const getSystemStepper = (): [
    { status: CreateSystemSteps; displayName: string; buttonTextOnChange: string; buttonTextNoChange: string }[],
    ({ type, usage }: { type: any; usage: any }) => { type?: string; title?: string }
] => {
    const { t } = useTranslation('general');
    const CreateSystemStepperSteps = [
        {
            status: CreateSystemSteps.SELECT_APPLICATION,
            displayName: t('systems-tab.new-system-form.select-application'),
            buttonTextOnChange: t('buttons.next'),
            buttonTextNoChange: t('buttons.next'),
        },
        {
            status: CreateSystemSteps.SELECT_CONTRACT,
            displayName: t('systems-tab.new-system-form.select-contract'),
            buttonTextOnChange: t('buttons.next'),
            buttonTextNoChange: t('buttons.next'),
        },
        {
            status: CreateSystemSteps.CONFIGURE_SYSTEM,
            displayName: t('systems-tab.new-system-form.configure'),
            buttonTextOnChange: t('buttons.save-and-finish'),
            buttonTextNoChange: t('buttons.save-and-finish'),
        },
    ];

    const getMainDescription = ({ type, usage }) => {
        const descriptionObj: { type?: string; title?: string } = {};
        switch (type) {
            case 'Web':
                descriptionObj['type'] = t('systems-tab.systems-table.web');
                break;
            case 'Workstation':
                descriptionObj['type'] = t('systems-tab.systems-table.workstation');
                break;
            default:
                descriptionObj['type'] = t('systems-tab.systems-table.web');
        }

        switch (usage) {
            case SystemUsage.INTERNAL:
                descriptionObj['title'] = t('systems-tab.new-system-form.internal-description');
                break;
            case SystemUsage.EXTERNAL:
                descriptionObj['title'] = t('systems-tab.new-system-form.external-description');
                break;
            default:
                descriptionObj['title'] = t('systems-tab.new-system-form.internal-description');
                break;
        }
        return descriptionObj;
    };

    return [CreateSystemStepperSteps, getMainDescription];
};
