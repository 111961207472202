import React, { ChangeEvent, useEffect, useState, useRef, useMemo } from 'react';
import {
    AddSystemData,
    ExtensionStatus,
    GetSystemDataResult,
    PartialSystemData,
    SystemData,
    SystemMatchers,
} from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';
import {
    WMButton,
    WMButtonVariant,
    WMSelect,
    WMTextField,
    WMDivider,
    WMSnackbar,
    WMSnackbarVariant,
    WMLoader,
    WMSwitch,
} from '@walkme/wm-ui';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box/Box';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { baseTitleStyling, getIsMultipleMatchers, getTitleComponent, resetIsDefaultForMatchersIfNeeded } from '../utils';
import Urls from '../common/urls/urls';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { System } from 'wm-accounts-sdk';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import {
    updateWebSystem,
    useWebSystem,
    useUpdatedWebSystem,
    useCreatedEnvironment,
    createNewEnvironment,
    webSystemsSlice,
    addExistingSystemToExtension,
    useAddExistingSystemToExtension,
    getSelfDomainsForSystem,
    useGetSelfDomainsForSystem,
} from '@walkme-admin-center/libs/state-management-extensions';
import { getSystemMatchersClone } from '../web-systems-advanced-settings/utils';
import { getAllSelfUrlsOfEnvironment, shouldShowExtensionPartControls, validateNewEnvironmentValue } from './utils';
import { Environment, UpdateUserChangedValues } from '../types';
import Alert from '@material-ui/lab/Alert/Alert';
import isEqual from 'lodash/isEqual';
import {
    getMinimalEnvById,
    getNotDevEnvironmentsFromAnalyticsAPI,
    getNotDevEnvironmentsFromSystemData,
    getChangedValuesToSave,
} from '../utils';

import CHEVRON_LEFT from '../../../../../../libs/common/assets/icons/left-icon.svg';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

interface WebSystemEnvironmentsProps {
    selectedSystem: System;
}

const WebSystemEnvironments = ({ selectedSystem }: WebSystemEnvironmentsProps) => {
    const { t } = useTranslation('general');
    const classes = useStyles();
    const dispatch = useDispatch();
    const [addEnvironmentClicked, setAddEnvironmentClicked] = useState(false);
    const [newEnvValue, setNewEnvValue] = useState('');
    const [areUrlsValid, setAreUrlsValid] = useState(true);
    const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
    const [showSuccessSnackBar, setShowSuccessSnackBar] = useState(false);
    const snackBarMessage = useRef<string>('');
    const { userPermissions, loggedInUserAppData } = useLoggedInUser();
    const { webSystemAppData } = useWebSystem(selectedSystem.id);
    const { createdEnvironmentAppData } = useCreatedEnvironment();
    const { updatedWebSystemAppData } = useUpdatedWebSystem();
    const { addExistingSystemToExtensionAppData } = useAddExistingSystemToExtension();
    const { getSelfDomainsForSystemAppData } = useGetSelfDomainsForSystem();
    const [systemData, setSystemData] = useState<PartialSystemData>();
    const previousSaveSystemData: React.MutableRefObject<PartialSystemData> = useRef(systemData);
    const notDevEnvironmentsOptions = useRef(systemData ? getNotDevEnvironmentsFromSystemData(systemData) : []);
    const [currentSelectedEnv, setCurrentSelectedEnv] = useState<Environment>(getMinimalEnvById(notDevEnvironmentsOptions.current));
    const [isLinkToExtensionMode, setIsLinkToExtensionMode] = useState(false);
    const [isAddingNewUrl, setIsAddingNewUrl] = useState(false);
    const isUpdatePressed = useRef(false);

    const isChanged = !isEqual(systemData, previousSaveSystemData.current);
    const isMultipleMatchers = getIsMultipleMatchers(systemData, currentSelectedEnv?.value);
    const [isSystemConnectedToExtension, setIsSystemConnectedToExtension] = useState(false);
    const [canLinkSystemToExtension, setCanLinkSystemToExtension] = useState(false);
    const envMatchers = currentSelectedEnv && systemData ? systemData.matchers[currentSelectedEnv.value] : undefined;
    const isNewEnvNameDefinedAlready = notDevEnvironmentsOptions.current.some(
        (env: Environment) => env.label.toLowerCase() === newEnvValue.toLowerCase()
    );
    const newEnvValidationResult = validateNewEnvironmentValue(newEnvValue);
    const newEnvValueErrorMessage = isNewEnvNameDefinedAlready
        ? 'This environment already exists'
        : !newEnvValidationResult.isValid
        ? newEnvValidationResult.errorMessage
        : undefined;

    const isRegexMode = envMatchers && envMatchers.length > 0 && !!envMatchers[0].isRegex;
    const urls: string[] = systemData ? getAllSelfUrlsOfEnvironment(systemData.matchers, currentSelectedEnv?.value) : [];
    const isCurrentEnvMatcherActive = envMatchers ? envMatchers[0].isActive : false;
    const isPermitted = useMemo(
        () => userPermissions('systemManagement', 'Modify') || userPermissions('systemManagement', 'Write'),
        [loggedInUserAppData]
    );

    const DEFAULT_SUCCESS_MESSAGE = t('systems-tab.all-systems-page.assign-users-form.DEFAULT_SUCCESS_MESSAGE');
    const DEFAULT_FAILURE_MESSAGE = t('systems-tab.all-systems-page.assign-users-form.DEFAULT_FAILURE_MESSAGE');

    useEffect(() => {
        const getSystemDataResult: GetSystemDataResult = webSystemAppData.data && webSystemAppData.data[selectedSystem.id];
        if (getSystemDataResult) {
            const { extensionStatus, isSystemLinked, systemData } = getSystemDataResult;
            setSystemData(systemData);
            previousSaveSystemData.current = { ...systemData };
            notDevEnvironmentsOptions.current = getNotDevEnvironmentsFromSystemData(systemData);
            const isSystemConnectedToExtension =
                isSystemLinked && (extensionStatus === ExtensionStatus.Exists || extensionStatus === ExtensionStatus.ExistsNeedsMigration);
            const canLinkSystemToExtension = !isSystemLinked && extensionStatus === ExtensionStatus.Exists;
            if (!isUpdatePressed.current) {
                // We change the environment only if we didn't do any update yet
                setCurrentSelectedEnv(getMinimalEnvById(notDevEnvironmentsOptions.current));
            }
            setIsSystemConnectedToExtension(isSystemConnectedToExtension);
            setCanLinkSystemToExtension(canLinkSystemToExtension);
        }
    }, [webSystemAppData.data]);

    const getSystemEnvironmentsAndSetMinimalEnvId = async () => {
        notDevEnvironmentsOptions.current = await getNotDevEnvironmentsFromAnalyticsAPI(selectedSystem.id);
        setCurrentSelectedEnv(getMinimalEnvById(notDevEnvironmentsOptions.current));
    };

    useEffect(() => {
        if (updatedWebSystemAppData.data.systemId) {
            setShowSuccessSnackBar(true);
            snackBarMessage.current = DEFAULT_SUCCESS_MESSAGE;
            dispatch(webSystemsSlice.actions.cleanupUpdatedSystem());
        } else if (updatedWebSystemAppData.error) {
            setShowErrorSnackBar(true);
            snackBarMessage.current = DEFAULT_FAILURE_MESSAGE;
            dispatch(webSystemsSlice.actions.cleanupUpdatedSystem());
        }
    }, [updatedWebSystemAppData]);

    useEffect(() => {
        if (addExistingSystemToExtensionAppData.data.systemId) {
            setShowSuccessSnackBar(true);
            snackBarMessage.current = t('systems-tab.all-systems-page.assign-users-form.success-message-linked');
            dispatch(webSystemsSlice.actions.cleanupAddExistingSystemToExtension());
            setIsLinkToExtensionMode(false);
            setCurrentSelectedEnv(getMinimalEnvById(notDevEnvironmentsOptions.current));
        } else if (addExistingSystemToExtensionAppData.error) {
            setShowErrorSnackBar(true);
            snackBarMessage.current = t('systems-tab.all-systems-page.assign-users-form.error-message-failed-to-link');
            dispatch(webSystemsSlice.actions.cleanupAddExistingSystemToExtension());
        }
    }, [addExistingSystemToExtensionAppData]);

    useEffect(() => {
        if (createdEnvironmentAppData.data.action) {
            setShowSuccessSnackBar(true);
            setAddEnvironmentClicked(false);
            setNewEnvValue('');
            snackBarMessage.current = DEFAULT_SUCCESS_MESSAGE;
            dispatch(webSystemsSlice.actions.cleanupCreatedNewEnv());
            if (!systemData) {
                getSystemEnvironmentsAndSetMinimalEnvId();
            }
        } else if (createdEnvironmentAppData.error) {
            setShowErrorSnackBar(true);
            snackBarMessage.current = DEFAULT_FAILURE_MESSAGE;
            dispatch(webSystemsSlice.actions.cleanupCreatedNewEnv());
        }
    }, [createdEnvironmentAppData]);

    useEffect(() => {
        const selfDomains = getSelfDomainsForSystemAppData.data;

        if (selfDomains) {
            setIsLinkToExtensionMode(true);
            dispatch(webSystemsSlice.actions.cleanupGetSelfDomainsForSystem());
            const minimalEnv = getMinimalEnvById(getNotDevEnvironmentsFromSystemData(systemData));
            setCurrentSelectedEnv(minimalEnv);
            setSelfUrlsOfEnv(selfDomains, minimalEnv.value);
            updateCurrentMatchersIsActiveValue(true);
        } else if (getSelfDomainsForSystemAppData.error) {
            setShowErrorSnackBar(true);
            snackBarMessage.current = t('systems-tab.all-systems-page.assign-users-form.error-message-failed-to-link-2');
            dispatch(webSystemsSlice.actions.cleanupGetSelfDomainsForSystem());
        }
    }, [getSelfDomainsForSystemAppData]);

    const onAddEnvClicked = async () => {
        dispatch(
            createNewEnvironment({
                isDev: false,
                userId: selectedSystem.id,
                systemId: selectedSystem.id,
                envName: newEnvValue,
            })
        );
    };

    const onAddExistingSystemToExtensionClicked = async () => {
        const systemData: AddSystemData = {
            systemId: selectedSystem.id,
            systemEmail: selectedSystem.email,
            name: selectedSystem.name,
            accountId: loggedInUserAppData.data.account!.id,
            accountName: loggedInUserAppData.data.account!.name,
            appId: selectedSystem.settings.id,
            selfDomains: urls,
            isActive: envMatchers[0].isActive,
        };

        dispatch(addExistingSystemToExtension(systemData));
    };

    const onSaveClicked = async () => {
        resetIsDefaultForMatchersIfNeeded(systemData);

        const changedValuesForAuditLog: UpdateUserChangedValues = getChangedValuesToSave(
            previousSaveSystemData.current,
            systemData,
            selectedSystem
        );

        const systemDataToSave: SystemData = {
            systemTypeKey: selectedSystem.systemTypeKey,
            systemId: selectedSystem.id,
            systemEmail: selectedSystem.email,
            name: selectedSystem.name,
            accountId: loggedInUserAppData.data.account!.id,
            accountName: loggedInUserAppData.data.account!.name,
            appId: selectedSystem.settings.id,
            changedValuesForAuditLog,
            ...systemData,
        };

        previousSaveSystemData.current = {
            ...systemData,
        };
        dispatch(updateWebSystem(systemDataToSave));
        isUpdatePressed.current = true;
    };

    const onGetSelfDomainsClicked = async () =>
        dispatch(getSelfDomainsForSystem(selectedSystem.name, selectedSystem.id, selectedSystem.settings?.id));

    const setSelfUrlsOfEnv = (urls: string[], envId: number): void => {
        const matchersCopy: SystemMatchers = getSystemMatchersClone(systemData.matchers);

        if (isRegexMode) {
            // In case it's regex, we dont split it by valid parts, we just place it as a single text value in the array and we dont allow to add new rows, so just read the first value.
            matchersCopy[envId][0].selfRegex = urls.length === 1 ? urls[0] : '';
        } else {
            matchersCopy[envId][0].selfDomains = [...urls];
        }

        setSystemData({
            ...systemData,
            matchers: { ...matchersCopy },
        });
    };

    const environmentSelectControl = (
        <WMSelect
            isDisabled={isLinkToExtensionMode || isAddingNewUrl}
            value={currentSelectedEnv}
            onChange={(env: Environment) => setCurrentSelectedEnv(env)}
            options={notDevEnvironmentsOptions.current}
        />
    );

    const handleSnackBarErrorClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') return;
        setShowErrorSnackBar(false);
        snackBarMessage.current = '';
    };

    const handleSnackBarSuccessClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') return;
        setShowSuccessSnackBar(false);
        snackBarMessage.current = '';
    };

    const errorSnackBar = (
        <WMSnackbar
            open={showErrorSnackBar}
            onClose={handleSnackBarErrorClose}
            variant={WMSnackbarVariant.Error}
            message={snackBarMessage.current}
        />
    );

    const successSnackBar = (
        <WMSnackbar
            open={showSuccessSnackBar}
            onClose={handleSnackBarSuccessClose}
            variant={WMSnackbarVariant.Success}
            message={snackBarMessage.current}
        />
    );

    const addExistingSystemToExtensionButton = (
        <Box className={classes.bottomButtonsContainer}>
            <WMButton
                className={classes.saveButton}
                variant={WMButtonVariant.Primary}
                onClick={onAddExistingSystemToExtensionClicked}
                disabled={addExistingSystemToExtensionAppData.loading || !areUrlsValid || urls.length === 0}
                loading={addExistingSystemToExtensionAppData.loading}>
                Link to Extension
            </WMButton>
        </Box>
    );

    const getSaveButton = () => {
        const shouldDisableButton =
            !areUrlsValid ||
            updatedWebSystemAppData.loading ||
            !isChanged ||
            isAddingNewUrl ||
            (isCurrentEnvMatcherActive && urls.length === 0);

        return (
            <Box className={classes.bottomButtonsContainer}>
                <WMButton
                    className={classes.saveButton}
                    variant={WMButtonVariant.Primary}
                    onClick={onSaveClicked}
                    disabled={shouldDisableButton}
                    loading={updatedWebSystemAppData.loading}>
                    Save
                </WMButton>
            </Box>
        );
    };

    const systemNotConnectedToExtensionControl = (
        <Box className={classes.systemNotConnectedToExtensionContainer}>
            <Alert severity='warning' style={{ whiteSpace: 'pre-line' }}>
                {canLinkSystemToExtension
                    ? t('systems-tab.all-systems-page.assign-users-form.warning-message-config-url')
                    : t('systems-tab.all-systems-page.assign-users-form.warning-message-system-not-linked')}
            </Alert>
            {canLinkSystemToExtension ? (
                <WMButton
                    variant={WMButtonVariant.Text}
                    className={classes.linkToExtensionButton}
                    onClick={onGetSelfDomainsClicked}
                    disabled={getSelfDomainsForSystemAppData.loading}
                    loading={getSelfDomainsForSystemAppData.loading}>
                    Configure URLs
                </WMButton>
            ) : null}
        </Box>
    );

    const multipleMatchersAlertControl = isMultipleMatchers ? (
        <Alert severity='warning' className={classes.alert}>
            {t('systems-tab.all-systems-page.assign-users-form.warning-view-only')}
        </Alert>
    ) : null;

    const onAddNewUrlClosed = () => {
        setIsAddingNewUrl(false);
        setAreUrlsValid(true);
    };

    const getSystemConnectedToExtensionControl = () => {
        const shouldShowUrlsComponent = isCurrentEnvMatcherActive || !isSystemConnectedToExtension || isMultipleMatchers;
        const shouldDisableUrlsComponentParts =
            isMultipleMatchers || updatedWebSystemAppData.loading || addExistingSystemToExtensionAppData.loading;

        return shouldShowUrlsComponent ? (
            <>
                <Box className={classes.urls}>
                    <Urls
                        urls={urls}
                        shouldDisableParts={shouldDisableUrlsComponentParts}
                        isRegexMode={isRegexMode}
                        setAreUrlsValid={setAreUrlsValid}
                        setUrls={setSelfUrlsOfEnv}
                        envId={currentSelectedEnv?.value}
                        enableEmpty={false}
                        isMultipleMatchers={isMultipleMatchers}
                        onAddUrlClicked={() => setIsAddingNewUrl(true)}
                        onAddNewUrlClosed={onAddNewUrlClosed}
                        onAddNewUrlsSaved={() => setIsAddingNewUrl(false)}
                        showAddNewUrlComponentByDefault={false}
                    />
                </Box>
                {multipleMatchersAlertControl}
            </>
        ) : (
            <Alert severity='warning'>{t('systems-tab.all-systems-page.assign-users-form.warning-text-box')}</Alert>
        );
    };

    const getMainConfigurationControl = () => {
        if ((isSystemConnectedToExtension && currentSelectedEnv) || isLinkToExtensionMode) {
            return getSystemConnectedToExtensionControl();
        } else if (!isSystemConnectedToExtension && !isLinkToExtensionMode) {
            return systemNotConnectedToExtensionControl;
        }

        return null;
    };

    const updateCurrentMatchersIsActiveValue = (newState: boolean) => {
        setSystemData((currentSystemData) => {
            const matchersClone: SystemMatchers = getSystemMatchersClone(currentSystemData.matchers);
            matchersClone[currentSelectedEnv.value][0].isActive = newState;

            return {
                ...systemData,
                matchers: matchersClone,
            };
        });
    };

    const onIsActiveChange = (event: ChangeEvent<HTMLInputElement>): void => {
        updateCurrentMatchersIsActiveValue(!envMatchers[0].isActive);
    };

    const isActiveControl = (
        <Box display='flex'>
            <Box flexGrow={1}>
                <Typography style={{ ...baseTitleStyling }}>
                    {t('systems-tab.all-systems-page.assign-users-form.load-wm-from-extension')}
                </Typography>
            </Box>
            <Box>
                <WMSwitch
                    disabled={updatedWebSystemAppData.loading || isAddingNewUrl}
                    checked={isCurrentEnvMatcherActive}
                    onChange={onIsActiveChange}
                />
            </Box>
        </Box>
    );

    const getEnvironmentConfigurationControl = () => {
        const shouldDisableAddEnvironmentButton =
            addExistingSystemToExtensionAppData.loading || updatedWebSystemAppData.loading || isLinkToExtensionMode || !isPermitted;
        return (
            <>
                {getTitleComponent(t('systems-tab.all-systems-page.assign-users-form.environments'))}
                <Box display='flex'>
                    <Box sx={{ width: '60%' }}>{environmentSelectControl}</Box>
                    <Box
                        sx={{
                            marginLeft: 'auto',
                            alignSelf: 'center',
                        }}>
                        <WMButton
                            variant={WMButtonVariant.Text}
                            onClick={() => setAddEnvironmentClicked(true)}
                            disabled={shouldDisableAddEnvironmentButton || isAddingNewUrl}>
                            {t('buttons.add-environment')}
                        </WMButton>
                    </Box>
                </Box>
            </>
        );
    };

    const getActiveStateToggleControl = () => {
        const shouldShowActiveStateToggleComponent = !isMultipleMatchers && (isSystemConnectedToExtension || isLinkToExtensionMode);

        return shouldShowActiveStateToggleComponent ? (
            <>
                {isActiveControl}
                <WMDivider className={classes.divider} />
            </>
        ) : null;
    };

    const configurationControl = (
        <Box className={classes.mainControlContainer}>
            {getEnvironmentConfigurationControl()}
            {shouldShowExtensionPartControls(selectedSystem) ? (
                <>
                    <WMDivider className={classes.divider} />
                    {getActiveStateToggleControl()}
                    {getMainConfigurationControl()}
                </>
            ) : null}
        </Box>
    );

    const onCancelAddEnvironmentClick = () => {
        setNewEnvValue('');
        setAddEnvironmentClicked(false);
    };

    const addEnvironmentButton = (
        <Box className={classes.bottomButtonsContainer}>
            <WMButton
                variant={WMButtonVariant.Text}
                onClick={onCancelAddEnvironmentClick}
                className={classes.cancelButton}
                disabled={createdEnvironmentAppData.loading}>
                Cancel
            </WMButton>
            <WMButton
                variant={WMButtonVariant.Primary}
                onClick={onAddEnvClicked}
                className={classes.saveButton}
                disabled={newEnvValue === '' || createdEnvironmentAppData.loading || !!newEnvValueErrorMessage || !isPermitted}
                loading={createdEnvironmentAppData.loading}>
                {t('buttons.add-environment')}
            </WMButton>
        </Box>
    );

    const addNewEnvironmentControl = (
        <Box>
            <Box display='flex'>
                <Box display='flex' flexGrow={1}>
                    <img onClick={onCancelAddEnvironmentClick} alt='Back' src={CHEVRON_LEFT} style={{ cursor: 'pointer' }} />
                    <Typography className={classes.addEnvionmentLabel}>Add new environment</Typography>
                </Box>
            </Box>
            <WMTextField
                ds2
                autoFocus
                style={{ marginTop: '20px' }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setNewEnvValue(event.target.value)}
                placeholder='Enter new environment name'
                value={newEnvValue}
                helperText={newEnvValueErrorMessage}
                status={isNewEnvNameDefinedAlready || !newEnvValidationResult.isValid ? 'error' : 'default'}
            />
            <Alert severity='warning' className={classes.alert}>
                {t('systems-tab.all-systems-page.assign-users-form.warning-no-url')}
            </Alert>
        </Box>
    );

    const loaderComponent = (
        <div className={classes.loader}>
            <WMLoader
                style={{
                    width: '30%',
                    maxWidth: '300px',
                }}
            />
        </div>
    );

    const webSystemAndEnvironmentsConfigurationControl = (
        <div className={classes.mainContainer}>
            {errorSnackBar}
            {successSnackBar}
            {addEnvironmentClicked ? addNewEnvironmentControl : configurationControl}
        </div>
    );

    const getBottomButtons = () => {
        if (addEnvironmentClicked) {
            return addEnvironmentButton;
        } else if (currentSelectedEnv) {
            if (isLinkToExtensionMode) {
                return addExistingSystemToExtensionButton;
            } else if (isSystemConnectedToExtension) {
                return getSaveButton();
            }
        }

        return null;
    };

    return (
        <>
            {webSystemAppData.loading || getSelfDomainsForSystemAppData.loading
                ? loaderComponent
                : webSystemAndEnvironmentsConfigurationControl}
            {getBottomButtons()}
        </>
    );
};

export { WebSystemEnvironments };
