import { SystemsSettings } from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemsSettings, SystemsState } from '../redux/systems.slice';

export const useSystemsSettings = () => {
    const systemsSettingsAppData: AppData<SystemsSettings> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.systemsSettings
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSystemsSettings());
    }, [dispatch]);

    return {
        systemsSettingsAppData,
    };
};

export default useSystemsSettings;
