import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import React, { ReactNode } from 'react';
import ConfirmationDialog from './confirmation-dialog';

const LOCAL_STORAGE_LAST_CACHE_TIME_KEY = 'wm_last_cache_time';

const updateCacheTimeInLocalStorage = (cacheTime: number) => localStorage.setItem(LOCAL_STORAGE_LAST_CACHE_TIME_KEY, cacheTime.toString());

const DEFAULT_SUCCESS_MESSAGE = 'Changes saved successfully';
const DEFAULT_SNACKBAR_FAILURE_MESSAGE = 'Failed to save changes, please try again or contact WalkMe Support.';

interface StatusNotificationProps {
    isOpen: boolean;
    status: 'success' | 'error';
    onConfirm: () => void;
    snackBarMessage?: ReactNode;
    successDialogTitle?: ReactNode;
    successDialogMessage?: ReactNode;
    extensionCacheTime?: number;
}

const StatusNotification = ({
    isOpen,
    extensionCacheTime,
    status,
    onConfirm,
    snackBarMessage,
    successDialogTitle,
    successDialogMessage,
}: StatusNotificationProps) => {
    const lastSavedCacheTime = localStorage.getItem(LOCAL_STORAGE_LAST_CACHE_TIME_KEY);
    const shouldShowExtensionCacheTime =
        extensionCacheTime !== undefined && (lastSavedCacheTime === undefined || Number(lastSavedCacheTime) !== extensionCacheTime);
    const shouldShowConfirmationDialog = status === 'success' && shouldShowExtensionCacheTime;

    const onSuccessDialogClose = (doNotShowAgain: boolean) => {
        if (doNotShowAgain) {
            updateCacheTimeInLocalStorage(extensionCacheTime);
        }

        onConfirm();
    };

    const statusSnackbar = (
        <WMSnackbar
            open={isOpen}
            onClose={onConfirm}
            variant={status === 'success' ? WMSnackbarVariant.Success : WMSnackbarVariant.Error}
            message={snackBarMessage ?? status === 'success' ? DEFAULT_SUCCESS_MESSAGE : DEFAULT_SNACKBAR_FAILURE_MESSAGE}
        />
    );

    const successDialog = (
        <ConfirmationDialog
            isOpen={isOpen}
            title={successDialogTitle ?? DEFAULT_SUCCESS_MESSAGE}
            message={successDialogMessage}
            confirmButtonColor='primary'
            onConfirm={onSuccessDialogClose}
            confirmButtonText='Ok'
            showDoNotShowAgainCheckbox={true}
        />
    );

    return <>{shouldShowConfirmationDialog ? successDialog : statusSnackbar}</>;
};

export default StatusNotification;
export { StatusNotificationProps };
