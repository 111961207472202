import React from 'react';
import { retriveClickCreateVideo } from '@walkme-admin-center/libs/common';
import { StepContainer, TitleContainer, TitleText, BoldText, VideoStepNumber } from './slack-steps-styles';
import VideoContainer from './video-container';

const ClickCreate = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>5.</VideoStepNumber>Click<BoldText>Create</BoldText>
                </TitleText>
            </TitleContainer>
            <VideoContainer video={retriveClickCreateVideo()} />
        </StepContainer>
    );
};

export default ClickCreate;
