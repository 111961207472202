import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataIntegrationsHeader from './data-integrations-header/data-integrations-header';
import { DataIntegrationForm } from './data-integration-form/data-integration-form';
import DataIntegrationsListFilter from './data-integrations-list-filter/data-integrations-list-filter';
import { AiContentSourceTabs, CssDrawer, CssModal } from './data-integrations-page-style';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import {
    getStatus,
    useSettings,
    useAskMe,
    useInitData,
    useIntegrations,
    useGlobalSettings,
} from '@walkme-admin-center/libs/state-mangment-data-integration';
import DataIntegrationsList from './data-integrations-list/data-integrations-list';
import { useDispatch } from 'react-redux';
import NoResult from '../../../sso-configuration/src/lib/common/components/no-result/no-result';
import { CircularProgress } from '@walkme-admin-center/libs/ui-components';
import CustomSnackBar, { CommonSnackbarProps } from '../../../sso-configuration/src/lib/common/components/custom-snackbar/custom-snackbar';
import { FormMode, FormTypes, SourcesViewStyle, Status } from '../../../../../libs/state-mangment-data-integration/src/lib/types';
import { CssTab, MainSourcesTabs } from './data-integration-form/data-integration-form-style';
import TabPanel from './common/components/custom-tab-panel';
import { IntegrationFormModal } from './data-integration-form/data-integration-form-components/integrations-page/integrations-forms/integration-form-modal';
import IntegrationsList from './data-integration-form/data-integration-form-components/integrations-page/integrations-list';
import { Spacer } from './common/style';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { AiFeatureFlags, integrationsArray } from './common/consts';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';
import { ErrorMsg } from './common/components/info-box';
import { useHasUndismissedFailedScan } from './common/use-has-undismissed-failed-scan';

const MainContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const PageContainer = styled.div`
    display: flex;
    overflow: hidden;
    border-radius: 0.75rem;
    background: var(--Color-Semantic-Background-bg-secondary, #fff);
    flex-direction: column;
    align-items: stretch;
    flex: 1 0 0;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: var(--ls-spacers-generation-sp-4016, 1rem) var(--LS-Spacers-Semantic-table-padding, 1.75rem);
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
`;

const BodyContainer = styled.div`
    display: flex;
    padding: 0.5rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    flex: 1 0 0;
    overflow-y: auto;
`;

/* eslint-disable-next-line */
export interface DataIntegrationsPageProps {}

export const DataIntegrationsPage = (props: DataIntegrationsPageProps) => {
    const { isAdminMode } = useGlobalSettings();
    const { accountFeatureEnabled } = useLoggedInUser();
    const [instanceData, setInstanceData] = useState<any>(null);
    const [formMode, setFormMode] = useState<FormMode>(null);
    const [fromType, setFromType] = useState<FormTypes>(null);
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [integrationType, setIntegrationType] = useState<FormTypes | null>(null);
    const [view, setView] = useState<SourcesViewStyle>(SourcesViewStyle.CARDS);
    const [search, setSearch] = useState('');
    const tabs = [{ label: 'AI Knowledge Sources' }, { label: 'Integrations' }];
    const [activeStep, setActiveStep] = useState(0);
    const [checkedIntegrations, setCheckedIntegrations] = useState<Set<string>>(new Set());
    const [snackBar, setSnackBar] = useState<CommonSnackbarProps>({
        open: false,
        onClose: null,
        messageText: '',
        severity: '',
    });

    const dispatch = useDispatch();

    useInitData();

    const { systemsAppData } = useSystems();
    const { settingsData } = useSettings();
    const { askMeIntegrationsData } = useAskMe();
    const { integrationsData } = useIntegrations();
    const [hasUndismissedFailedScan, setHasUndismissedFailedScan] = useHasUndismissedFailedScan();

    const isRunningJob = askMeIntegrationsData.data.some((jobs) => jobs.jobStatus.status === Status.InProgress);

    useEffect(() => {
        // Dispatch fetchData action every 10 seconds
        const intervalId = setInterval(
            () => {
                if (!document.hidden) {
                    dispatch(getStatus());
                }
            },
            isRunningJob ? 5000 : 30000
        );
        return () => {
            // Clear the interval when the component unmounts
            clearInterval(intervalId);
        };
    }, [dispatch, isRunningJob]);

    const searchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearch(event.target.value);
    };

    const triggerForm = useCallback((formMode: FormMode, instanceData: any = null, formType: FormTypes) => {
        setFormMode(formMode);
        setInstanceData(instanceData);
        setFromType(formType);
        setShowForm(true);
    }, []);

    const isLoading = (): boolean => {
        return askMeIntegrationsData.loading || settingsData.loading || systemsAppData.loading || integrationsData.loading;
    };

    const closeForm = (fromForm: boolean = true) => {
        setShowForm(false);
    };

    const filterIntegrations = askMeIntegrationsData.data
        .filter((data) => {
            return data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

    const handleSnackBarClose = (): void => {
        showSnackBar({ open: false, messageText: '', severity: '', onClose: null });
    };

    const showSnackBar = (data: CommonSnackbarProps): void => {
        setSnackBar({ ...data, onClose: handleSnackBarClose });
    };

    const updateCheckedList = (sourceGuid: string, checked: boolean) => {
        const newSet = new Set(checkedIntegrations);
        if (checked) {
            newSet.add(sourceGuid);
        } else {
            newSet.delete(sourceGuid);
        }
        setCheckedIntegrations(newSet);
    };

    const selectAll = (sourceGuids: Array<string>) => {
        const newSet = new Set(sourceGuids);
        setCheckedIntegrations(newSet);
    };

    const clearSelected = () => {
        setCheckedIntegrations(new Set());
    };

    const getForm = useCallback(() => {
        return (
            <DataIntegrationForm
                formMode={formMode}
                onClose={closeForm}
                instanceData={instanceData}
                fromType={fromType}
                showSnackBar={showSnackBar}
                checkedIntegrations={checkedIntegrations}
                triggerModal={triggerModal}
            />
        );
    }, [formMode, instanceData, fromType, checkedIntegrations]);

    const triggerModal = (formType: FormTypes) => {
        setIntegrationType(formType);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const getModal = useCallback(() => {
        return (
            <div>
                <IntegrationFormModal closeModal={closeModal} formType={integrationType} showSnackBar={showSnackBar} />
            </div>
        );
    }, [integrationType]);

    return (
        <>
            {snackBar.open && <CustomSnackBar {...snackBar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />}
            {isLoading() ? (
                <CircularProgress size={60} />
            ) : (
                <MainContainer>
                    <CssDrawer anchor={'right'} open={showForm} onClose={null} children={getForm()} />
                    <CssModal open={showModal} onClose={closeModal} children={getModal()} BackdropProps={{ onClick: () => {} }} />
                    <DataIntegrationsHeader triggerForm={triggerForm} />
                    <PageContainer>
                        <HeaderContainer>
                            {(!!isAdminMode ||
                                (accountFeatureEnabled(AiFeatureFlags.ENABLE_ASK_ME) &&
                                    integrationsArray.some(
                                        (integration) => integration.isGa || accountFeatureEnabled(integration.featureFlag)
                                    ))) && (
                                <AiContentSourceTabs>
                                    <MainSourcesTabs value={activeStep} onChange={(event, index) => setActiveStep(index)}>
                                        {tabs.map((tab, index) => {
                                            return (
                                                <CssTab disableRipple key={index} label={tab.label} className={'root.DataIntegrationTab'} />
                                            );
                                        })}
                                    </MainSourcesTabs>
                                </AiContentSourceTabs>
                            )}
                            <div />
                            {activeStep === 0 && (
                                <DataIntegrationsListFilter search={search} searchChange={searchChange} setView={setView} />
                            )}
                        </HeaderContainer>
                        <BodyContainer>
                            <TabPanel value={activeStep} index={0}>
                                {askMeIntegrationsData.data.length ? (
                                    <>
                                        <Collapse in={hasUndismissedFailedScan}>
                                            <ErrorMsg onClose={setHasUndismissedFailedScan}>
                                                Something went wrong with one or more of the scans. Please review the issue on the relevant
                                                source tiles.
                                            </ErrorMsg>
                                            <Spacer height={1} />
                                        </Collapse>
                                        <DataIntegrationsList
                                            checkedIntegrations={checkedIntegrations}
                                            updateCheckedList={updateCheckedList}
                                            clearSelected={clearSelected}
                                            integrations={filterIntegrations}
                                            triggerForm={triggerForm}
                                            clearSearch={() => setSearch('')}
                                            selectAll={selectAll}
                                            view={view}
                                            showSnackBar={showSnackBar}
                                        />
                                    </>
                                ) : (
                                    <NoResult
                                        image={'/assets/icons/idp-no-result.png'}
                                        paddingTop={'138px'}
                                        title={'No knowledge source'}
                                        content={"You haven't added any knowledge source yet. Let's "}
                                        actionText={'add your first knowledge source'}
                                        onAction={() => triggerForm(FormMode.STEPPER, null, FormTypes.CRAWLER)}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel value={activeStep} index={1}>
                                <IntegrationsList
                                    triggerModal={triggerModal}
                                    integrations={integrationsData.data}
                                    showSnackBar={showSnackBar}
                                />
                            </TabPanel>
                        </BodyContainer>
                    </PageContainer>
                </MainContainer>
            )}
        </>
    );
};

export default DataIntegrationsPage;
