import { EnvironmentData, Environments, PartialSystemData, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import isEqual from 'lodash/isEqual';
import { Environment } from '../types';
import { ALL_URLS_REGEX, ALL_URLS_MATCH_PATTERN } from './consts';
import { SystemMatcherOption } from './types';
import { TopSitesMode } from './values';
import { ReactNode } from 'react';
import React from 'react';

const getSuccessSaveDialogMessageBasedOnExtensionCacheTime = (cacheTimeInSeconds: number, translator): ReactNode => {
    const finalMessage = [];
    const fullText = translator('systems-tab.all-systems-page.extension-page.time-for-changes-to-take-effect.text');
    const splitByTime = fullText.split('##');
    let timeText = '';

    if ([900, 3600, 86400].includes(cacheTimeInSeconds)) {
        timeText = translator(
            `systems-tab.all-systems-page.extension-page.time-for-changes-to-take-effect.time-text.${cacheTimeInSeconds}`
        );
    } else {
        timeText = `${Math.floor(cacheTimeInSeconds / 60)} ${translator(
            `systems-tab.all-systems-page.extension-page.time-for-changes-to-take-effect.time-text.default`
        )}`;
    }

    finalMessage.push(<span>{splitByTime[0]} </span>);
    finalMessage.push(<b>{timeText}</b>);
    finalMessage.push(<span> {splitByTime[1]} </span>);

    return <span>{finalMessage}</span>;
};

const getNotDevEnvironmentsFromSystemDataEnvironments = (environments?: Environments): Environment[] => {
    if (!environments) {
        return [];
    }

    return Object.entries(environments) //We don’t want to show environment which flagged as Dev
        .filter(([_, envData]) => !envData.isDev)
        .map(([envId, envData]) => {
            const currentEnvId = Number(envId);
            const environmentData: EnvironmentData = envData;
            const envName = environmentData.name;
            const path = environmentData.path;
            const currentEnvData: Environment = { value: currentEnvId, label: envName, path };

            return currentEnvData;
        });
};

const getSelectedEnvironmentMatchersOptions = (
    selectedEnvironment: Environment | undefined,
    systemData: PartialSystemData | undefined
): SystemMatcherOption[] => {
    if (selectedEnvironment && systemData && systemData.matchers[selectedEnvironment.value]) {
        const selectedEnvironmentMatchersOptions = systemData.matchers[selectedEnvironment.value].map((matcher, index) => {
            return {
                value: matcher,
                label: `Configuration set ${index + 1}`,
            };
        });

        return selectedEnvironmentMatchersOptions;
    } else {
        return [];
    }
};

const getInitialTopSitesMode = (systemMatcher: SystemMatcher | undefined, isRegexMode: boolean): TopSitesMode => {
    let initialTopSitesMode: TopSitesMode = TopSitesMode.All;

    if (!systemMatcher) {
        return initialTopSitesMode;
    }

    if (isRegexMode) {
        if (systemMatcher.topRegex !== ALL_URLS_REGEX) {
            initialTopSitesMode = TopSitesMode.Specific;
        }
    } else {
        if (!isEqual(systemMatcher.topDomains, ALL_URLS_MATCH_PATTERN)) {
            initialTopSitesMode = TopSitesMode.Specific;
        }
    }

    return initialTopSitesMode;
};

const getTooltipIcon = () => <img src={`/assets/icons/icon-info.svg`} style={{ height: '17px', width: '17px', display: 'flex' }} />;

export {
    getSuccessSaveDialogMessageBasedOnExtensionCacheTime,
    getNotDevEnvironmentsFromSystemDataEnvironments,
    getSelectedEnvironmentMatchersOptions,
    getInitialTopSitesMode,
    getTooltipIcon,
};
