import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Environment } from 'wm-accounts-sdk';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { WMButton, WMButtonVariant, WMMessageIconPosition, WMMessageVariant, WMTextField } from '@walkme/wm-ui';
import { useCreatedEnvironment, webSystemsSlice } from '@walkme-admin-center/libs/state-management-extensions';
import { AppThunkDispatch } from '@walkme-admin-center/libs/state-management-users';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { validateEnvironment } from '../validation/validate-environment';
import { StyledDialog, StyledSuccessMessage, StyledTextButton, StyledCodeBlock } from './new-environment-form.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface NewEnvironmentFormProps {
    open: boolean;
    onClose: () => void;
    environments: Environment[];
    createEnvironment: (environmentName: string) => void;
    generateSnippetCode: (environment: Environment) => string;
    setOpenErrorSnackbar: React.Dispatch<React.SetStateAction<string>>;
    setOpenWarningSnackbar: React.Dispatch<React.SetStateAction<string>>;
    systemId: number;
    loading: boolean;
}

const NewEnvironmentForm = ({
    open,
    onClose,
    environments,
    createEnvironment,
    generateSnippetCode,
    setOpenErrorSnackbar,
    setOpenWarningSnackbar,
    systemId,
    loading,
}: NewEnvironmentFormProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const { createdEnvironmentAppData } = useCreatedEnvironment();
    const [environmentName, setEnvironmentName] = useState('');
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [environmentLoading, setEnvironmentLoading] = useState(false);

    const environment = useMemo(() => environments.find((env) => env.name === environmentName), [environmentName, environments]);

    const onCreateEnvironment = () => {
        const errors = validateEnvironment({ name: environmentName }, environments);

        if (errors.length) {
            setOpenWarningSnackbar(errors.toString());
            return;
        }

        setEnvironmentLoading(true);
        createEnvironment(environmentName);
    };

    useEffect(() => {
        if (createdEnvironmentAppData.data.action) {
            setShowSuccessDialog(true);
            setEnvironmentLoading(false);
            (dispatch as AppThunkDispatch)(webSystemsSlice.actions.cleanupCreatedNewEnv());
        } else if (createdEnvironmentAppData.error) {
            setOpenErrorSnackbar(createdEnvironmentAppData.error);
            setEnvironmentLoading(false);
            (dispatch as AppThunkDispatch)(webSystemsSlice.actions.cleanupCreatedNewEnv());
        }
    }, [createdEnvironmentAppData]);

    return (
        <StyledDialog onClose={() => onClose()} open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            color: '#2F426C',
                            fontSize: '18px',
                            fontWeight: 600,
                        }}>
                        {showSuccessDialog
                            ? t('systems-tab.all-systems-page.environments-page.environment-created')
                            : t('systems-tab.all-systems-page.environments-page.create-environment')}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <img src='assets/icons/close.svg' alt='close' style={{ width: 9 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={'dialog-content'}>
                <Typography
                    style={{
                        fontFamily: 'Proxima nova',
                        color: '#2F426C',
                        fontSize: '16px',
                        fontWeight: 400,
                        marginBottom: '10px',
                    }}>
                    {t('systems-tab.all-systems-page.environments-page.env-name')}
                </Typography>
                {showSuccessDialog ? (
                    <StyledSuccessMessage
                        message={environmentName}
                        iconPosition={WMMessageIconPosition.End}
                        variant={WMMessageVariant.Success}
                    />
                ) : (
                    <WMTextField
                        value={environmentName}
                        placeholder={t('systems-tab.all-systems-page.environments-page.new-env-placeholder')}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setEnvironmentName(event.target.value)}
                        disabled={environmentLoading}
                    />
                )}
                {showSuccessDialog && environment && (
                    <>
                        <Box style={{ marginTop: 32 }}>
                            <div style={{ fontWeight: 'bold', color: '#2f426c' }}>
                                {t('systems-tab.all-systems-page.environments-page.snippet-deployment')}
                            </div>
                            {t('systems-tab.all-systems-page.environments-page.success-message-environment')}
                            <StyledCodeBlock language='htmlbars' style={tomorrowNightBlue} text={generateSnippetCode(environment)} />
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ margin: '32px' }}>
                <StyledTextButton
                    style={{ width: '80px', height: '40px', borderRadius: '40px', color: showSuccessDialog ? '#fff' : '#637191' }}
                    variant={showSuccessDialog ? WMButtonVariant.Primary : WMButtonVariant.Text}
                    disabled={environmentLoading}
                    onClick={() => onClose()}>
                    {showSuccessDialog ? t('buttons.close') : t('buttons.cancel')}
                </StyledTextButton>
                {!showSuccessDialog && (
                    <WMButton
                        disabled={!environmentName}
                        style={{ width: '80px', height: '40px', borderRadius: '40px' }}
                        variant={WMButtonVariant.Primary}
                        onClick={onCreateEnvironment}
                        loading={environmentLoading}>
                        {environmentLoading ? t('buttons.saving') : t('buttons.save')}
                    </WMButton>
                )}
            </DialogActions>
        </StyledDialog>
    );
};

export default NewEnvironmentForm;
