import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(duration);
dayjs.extend(relativeTime);

const formatTimeToDisplayText = (timeInSeconds: number): string => {
    const durationObj = dayjs.duration({ seconds: timeInSeconds });

    return durationObj.humanize();
};

export { formatTimeToDisplayText };
