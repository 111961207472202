import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { styledTheme } from '../ui-theme/ui-theme';

export const StyledButton = styled(MuiButton)`
    border: 0;
    border-radius: 2px;
    color: white;
    height: 35px;
    padding: 0 22px;
    line-height: 30px;
    text-transform: uppercase;

    &.link {
        color: ${styledTheme.colors.main.inlineLinks};
        background-color: transparent;
        box-shadow: none;
        text-transform: initial;
        padding: 0;
        min-width: 0;

        &:hover {
            background-color: transparent;
        }
    }

    &.link-bordered {
        color: ${styledTheme.colors.main.inlineLinks};
        border: 1px solid ${styledTheme.colors.main.inlineLinks};
        text-transform: uppercase;
        background-color: ${styledTheme.colors.main.mainBg};
        text-decoration: none;
        text-align: center;
        box-shadow: none;
        padding: 10px;
        height: 39px;
        width: 200px;
        border-radius: 4px;
        transition: 0.2s;

        &:hover {
            text-decoration: none;
            border: 1px solid ${styledTheme.colors.main.mainText};
        }
    }

    &.form {
        box-shadow: none;
        margin: 0 10px;

        &.resend {
            color: ${styledTheme.colors.buttons.specialTypes.cancel};
            background-color: ${styledTheme.colors.main.mainBg};
            border: 1px solid ${styledTheme.colors.buttons.form.border};
            line-height: 15px;
        }

        &.cancel {
            color: ${styledTheme.colors.buttons.specialTypes.cancel};
            background-color: ${styledTheme.colors.main.mainBg};
            border: 1px solid ${styledTheme.colors.buttons.form.border};
        }

        &.change {
            color: ${styledTheme.colors.buttons.specialTypes.cancel};
            background-color: ${styledTheme.colors.main.mainBg};
            border: 1px solid ${styledTheme.colors.buttons.form.border};

            &.inline {
                width: 40px;
                margin-left: 7px;
                margin-right: 0;
            }
        }

        &.delete {
            background-color: ${styledTheme.colors.main.mainBg};
            border: 1px solid ${styledTheme.colors.buttons.form.border};
            color: ${styledTheme.colors.buttons.specialTypes.warning};

            &:hover {
                border: 1px solid ${styledTheme.colors.buttons.specialTypes.warning};
            }
        }

        &.save {
            &:hover {
                border: none;
            }
        }

        &:hover {
            border: 1px solid ${styledTheme.colors.main.mainText};
        }
    }

    &.main {
        background-color: ${styledTheme.colors.buttons.mainBgColor};

        &:hover:not(.Mui-disabled) {
            background-color: ${styledTheme.colors.buttons.mainHoverBgColor};
        }

        &.Mui-disabled {
          background-color: ${styledTheme.colors.buttons.mainBgColor};
          color: white;
          opacity: 40%;
        }
    }

    &.main-grey:not(.Mui-disabled) {
        background-color: ${styledTheme.colors.buttons.mainBgGrey};

        &:hover {
            background-color: ${styledTheme.colors.buttons.mainHoverBgGrey};
        }
    }

    &.confirm {
        width: 50%;
        padding: 22px 0;
        box-shadow: none;

        &.yes {
            background-color: ${styledTheme.colors.buttons.danger};
        }
        &.no {
            color: ${styledTheme.colors.buttons.specialTypes.cancel};
            background-color: ${styledTheme.colors.main.mainBg};
            border: 1px solid ${styledTheme.colors.buttons.form.border};
            shadow-box: none;
        }
    }

    &.MuiButton-sizeLarge {
      height: 50px;
      padding: 0 30px;
    }

    &.MuiButton-sizeSmall {
      padding: 0 10px;
      height: auto;

    }

    .iconContainer {
        display: flex;
        flex-direction: column;
        margin-right: 4px;
    }

`;
