import React, { useEffect, useState } from 'react';
import { StyledCopyLink, StyledSnippet } from '../styles/styles';
import { Button } from '@walkme-admin-center/libs/ui-components';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface SnippetProps {
    valueToCopy: string;
}

export const Snippet = ({ valueToCopy }: SnippetProps) => {
    const [messageVisible, setMessageVisible] = useState(false);

    const onCopy = () => {
        setMessageVisible(true);
    };

    useEffect(() => {
        const timeToMessageVisible = setTimeout(() => {
            if (messageVisible) {
                setMessageVisible(false);
            }
        }, 2000);
        return () => clearTimeout(timeToMessageVisible);
    }, [messageVisible]);

    return (
        <>
            <StyledSnippet>{valueToCopy}</StyledSnippet>
            <CopyToClipboard text={valueToCopy.trim()} onCopy={onCopy}>
                <StyledCopyLink>
                    <Button customClass="link">
                        <FileCopyOutlinedIcon fontSize={'small'} />
                    </Button>
                    <Button customClass="link">Copy Javascript Snippet to Clipboard</Button>
                    {messageVisible && <span className="copied">Copied to Clipboard</span>}
                </StyledCopyLink>
            </CopyToClipboard>
        </>
    );
};
