export const headCells = [
    {
        id: 'CSVLine',
        label: 'CSV Line',
    },
    {
        id: 'deployableID',
        label: 'Deployable ID',
    },
    {
        id: 'originalPhrase',
        label: 'Original Phrase',
    },
    {
        id: 'translation',
        label: 'Translation',
    },
    {
        id: 'issue',
        label: 'Issue',
    },
    {
        id: 'action',
        label: 'Action',
    },
];

export const summaryColumns = {
    Line: 'Line in csv',
    DeployableID: 'DeployableID',
    Original: 'Original Phrase',
    Language: 'Language of phrase to upload',
    Translation: 'Phrase for Upload',
    Status: 'Status',
    Issue: 'Issue',
    Action: 'Action',
    VerifiedTranslation: 'VerifiedTranslation',
};

export const translationActions = { skip: 'Skipped', upload: 'Uploaded' };

export const translationStatus = { error: 'Error', ambiguity: 'Ambiguity', success: 'Success' };

export const translationIssues = {
    duplication: 'Duplication',
    verified: 'Existing Translation verified',
    corruption: 'Suspected Corrupt Characters',
    missingOrig: 'Original phrase is missing',
    missingTranslations: 'All translations columns are empty',
};

export const downloadTemplateLink = `${window.clientConfig.NX_CDN_URL}/deepui/lexicon/template.csv`;

export const downloadTemplateFileName = 'CSV Template.csv';

export const downloadSummaryFileName = 'Summary.csv';

export const serverErrorMessage = 'For some reason the operation could not be completed, please try again later';

export const summaryIndexes = 'summaryIndexes';

export const maxFileSize = 10000000;

export const structuralErrorTitle = 'Wrong file structure';

export const timeoutErrorMessage = 'The translations import is taking longer than expected. Please try again in 30 minutes.';

export const maxTranslations = 10000;

export const maxTranslationsError = `The maximum number of translations per file that can be uploaded is 10,000`;

export const invalidCharacters = ['�'];

export const invalidCharacterErrorMessage = 'Uploaded file contains invalid characters (�)';

export const heavyFileError = 'The file is too large (more than 10MB)';

export const errorsWithoutDownloadTemplate = [maxTranslationsError, invalidCharacterErrorMessage, heavyFileError];
