import React, { useEffect, useState } from 'react';
import UsersTab from './users-tab/users-tab';
import PendingUsersTab from './users-tab/pending-users-tab';
import { Route, Link, BrowserRouter as Router, Routes, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { PageWrapper } from '@walkme-admin-center/libs/common';
import { StyledPageTitle } from '@walkme-admin-center/libs/shared-styles';
import { WMPendingUserRequestStatus } from 'wm-accounts-sdk';
import {
    useLoggedInUser,
    useRoles,
    useUsers,
    usePendingUsers,
    useAccountIdps,
    useMfaSettings,
    usersSlice,
} from '@walkme-admin-center/libs/state-management-users';
import { useUserTabData } from './users-tab/users-tab.pure';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import { WMButton, WMIconWarning, WMIconClose, WMIconPlus, WMSnackbarVariant } from '@walkme/wm-ui';
import { Tab, Tabs, Tooltip, Box, Badge, Grid, IconButton } from '@material-ui/core';
import { StyledTitleDiv, StyledPageTitleLabel } from './users.styles';
import { useTranslation, RTFunction, momentLocalesMapping } from 'apps/home/src/localization';
import BulkUsersMutation from './users-tab/bulk-users-mutation';
import { useDispatch } from 'react-redux';
import { renderMultiRoutes } from 'apps/home/src/app/routes';
import { BannerGrid, BannerTitle, StyledBannerIcon, StyledReviewLink, StyledTabBoxes } from './styles/styles';

enum TabsPaths {
    MainPath = '/users',
    Users = '/users/manage',
    AccessRequests = '/users/access-requests',
}

export const Users = () => {
    const { t, rt, i18n } = useTranslation('general');

    const location = useLocation();
    const { id } = useParams<{ id }>();
    const isMainPath = location.pathname === TabsPaths.MainPath;
    const shouldAutoOpenNewUserPage = location.pathname === `${TabsPaths.Users}/new`;
    const [currentPath, setCurrentPath] = useState<string>(isMainPath ? TabsPaths.Users : location.pathname);
    const [removePendingBanner, setRemovePendingBanner] = useState(false);
    const { pendingUsersAppData } = usePendingUsers();
    const { accountIdpsAppData } = useAccountIdps(false);
    const { mfaSettingsAppData } = useMfaSettings(false);
    const { usersAppData } = useUsers();
    const { rolesAppData } = useRoles();
    const [showBulkUsersMutationScreen, setShowBulkUsersMutationScreen] = useState(shouldAutoOpenNewUserPage);
    const roles = rolesAppData.data;
    const { systemsAppData } = useSystems();
    const { loggedInUserAppData, accountFeatureEnabled } = useLoggedInUser();
    const pendingUsers = pendingUsersAppData.data.filter((item) => item.status === WMPendingUserRequestStatus.pending);
    const shouldShowPendingUsersBanner = currentPath !== TabsPaths.AccessRequests && !removePendingBanner && pendingUsers.length > 0;
    const users = usersAppData.data;
    const accountSystems = systemsAppData.data;
    const { notificationsMessagesKeys } = useUserTabData();
    const { UserCreatedMessage, UserCreatedWithVerificationMailMessage } = notificationsMessagesKeys;
    const dispatch = useDispatch();
    const shouldShowPassword = accountFeatureEnabled('avoidEmailVerfication');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = t('users-and-roles-tab.document-title-users');
    }, []);

    return (
        <div className='noPadding'>
            {showBulkUsersMutationScreen && (
                <BulkUsersMutation
                    showError={(error: string) => {}}
                    onCancel={() => setShowBulkUsersMutationScreen(false)}
                    onFinish={(successMessage: string) => {
                        setShowBulkUsersMutationScreen(false);
                        dispatch(
                            usersSlice.actions.setNotificationMessage({
                                text: shouldShowPassword ? t(UserCreatedMessage) : t(UserCreatedWithVerificationMailMessage),
                                variant: WMSnackbarVariant.Success,
                                isOpen: true,
                            })
                        );
                    }}
                    showDialog={showBulkUsersMutationScreen}
                />
            )}
            {shouldShowPendingUsersBanner && (
                <BannerGrid container direction='row' alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                            <Grid item>
                                <StyledBannerIcon size={24} color='#F7B500' />
                            </Grid>
                            <Grid item>
                                <BannerTitle
                                    dangerouslySetInnerHTML={{
                                        __html: t('users-and-roles-tab.users.access-requests-banner', { count: pendingUsers.length }),
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <span>
                                    <StyledReviewLink
                                        onClick={() => {
                                            setCurrentPath(TabsPaths.AccessRequests);
                                        }}
                                        to={TabsPaths.AccessRequests}>
                                        {t('common.review')}
                                    </StyledReviewLink>
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='flex-end' alignItems='baseline'>
                            <IconButton
                                onClick={() => {
                                    setRemovePendingBanner(true);
                                }}>
                                <StyledBannerIcon size={7} color='#2F426C' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </BannerGrid>
            )}
            <div>
                <StyledTitleDiv>
                    <StyledPageTitle>{t('users-and-roles-tab.users.title')}</StyledPageTitle>
                    <StyledPageTitleLabel>{t('users-and-roles-tab.users.sub-title')}</StyledPageTitleLabel>
                </StyledTitleDiv>
                <StyledTabBoxes>
                    <WMButton
                        onClick={() => setShowBulkUsersMutationScreen(true)}
                        style={{ marginLeft: 'auto' }}
                        iconComponent={<WMIconPlus />}>
                        {t('users-and-roles-tab.users.buttons.add-users')}
                    </WMButton>
                </StyledTabBoxes>
                <PageWrapper
                    page={UsersTab}
                    name='users'
                    container
                    pageData={{
                        accountIdpsAppData,
                        mfaSettingsAppData,
                        users,
                        systems: accountSystems,
                        roles,
                    }}
                    loadingData={rolesAppData.loading || loggedInUserAppData.loading || usersAppData.loading}
                />
            </div>
        </div>
    );
};

export default Users;
