import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

const { dialogs } = styledTheme;
const { layouts } = dialogs;
const { sideScreen } = layouts;

export const StyledContent = styled(DialogContent)`
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;
export const CloseDialog = styled.div`
    display: inline-flex;
    text-transform: uppercase;
    font-size: 12px;
    padding: 13px 15px 0;
    justify-content: flex-end;
    color: #aeaeae;
    cursor: pointer;
`;
export const StyledDialog = styled(MuiDialog)`
    & .dialog {
        background-color: ${dialogs.colors.mainDialogBg};
    }

    & .square {
        height: 450px;
        width: 520px;
    }

    & .default {
        padding: 0 24px 10px;
    }

    & .sideScreenContainer.right {
        justify-content: ${sideScreen.rightSide.justifyContent};
    }

    & .sideScreenContainer.left {
        justify-content: ${sideScreen.leftSide.justifyContent};
    }

    & .sideScreen {
        padding: 0;
        display: flex;
        width: ${sideScreen.width};
        min-height: ${sideScreen.minHeight};
        margin: 0;
        border-radius: 0;
      & .MuiDialogContent-root:first-child{
        padding-top: 0px;
      }
    }
`;
export const StyledTitle = styled(DialogTitle)`
    padding: 16px 32px 10px;

    &.default {
        h2 {
            font-size: 30px;
        }
    }
`;
