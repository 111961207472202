import styled from 'styled-components';

export const StyledPageGrid = styled.div`
    height: 100vh;
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;

    .logoGrid {
        margin-top: 100px;
    }

    .titleGrid {
        margin-top: 50px;

        .title {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 24px;
            color: #2f426c;
        }
    }

    .subTitleGrid {
        margin-top: 20px;
        text-align: center;

        .subTitle {
            font-family: 'ProximaNova';
            font-weight: 400;
            font-size: 16px;
            color: #2f426c;
        }
    }

    .buttonGrid {
        z-index: 20;
        margin-top: 20px;
    }

    .downloadGrid {
        z-index: 20;
        margin-top: 20px;

        .downloadText {
            font-family: 'ProximaNova';
            font-weight: 400;
            font-size: 16px;
            color: #2f426c;
        }

        .downloadTextColor {
            font-family: 'ProximaNova';
            font-weight: 400;
            font-size: 16px;
            color: #3b61eb;
            cursor: pointer;
        }
    }

    .imageGrid {
        position: relative;
    }
`;

export const StyledBackgroundImage = styled.img`
    bottom: 0;
    position: fixed;
    width: 100%;
`;
