import React, { useCallback, useState } from 'react';
import {
    assignSystemToProvider,
    createProvider,
    IdpEnvs,
    IdpSystemsFormValue,
    OneId,
    Provider,
    setOneId,
    setShowError,
    SubmitProvider,
    turnOffOneId,
    updateProvider,
    useError,
    useInitData,
    useOneId,
    useAccountConfig,
    useProviderGroups,
    useProviders,
} from '@walkme-admin-center/libs/state-management-sso-configuration';
import { CircularProgress } from '@walkme-admin-center/libs/ui-components';
import ProvidersList from './providers-list/providers-list';
import ProviderFormStepper from './provider-form-stepper/provider-form-stepper';
import { useDispatch } from 'react-redux';
import NoResult from './common/components/no-result/no-result';
import { InputAdornment } from '@material-ui/core';
import { CONSTS, StepperMode } from './common/consts';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import { Environments, SystemTypeKey } from 'wm-accounts-sdk';
import IdpDialog, { IdpDialogProps } from './common/components/idp-dialog/idp-dialog';
import SsoConfigurationHeader from './sso-configuration-header/sso-configuration-header';
import { Search } from '@material-ui/icons';
import { StyledCSSSearchField } from './common/style';
import OneIdDialog from './common/components/one-id-dialog/one-id-dialog';
import CustomSnackBar, { CommonSnackbarProps } from './common/components/custom-snackbar/custom-snackbar';
import { MainContainer, StyledDialog } from './sso-configuration-page-style';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'react-i18next';

export const SsoConfigurationPage = () => {
    const [showForm, setShowForm] = useState(false);
    const [search, setSearch] = useState('');
    const [provider, setProvider] = useState(null);
    const [stepperMode, setStepperMode] = useState(StepperMode.NEW);
    const [showOneIdDialog, setShowOneIdDialog] = useState(false);
    const [showDialog, setShowDialog] = useState<IdpDialogProps>({
        open: false,
        confirm: null,
        doActionOnCancel: null,
        title: '',
        content: null,
        type: 'defaultButton',
        buttonText: '',
        showCancel: false,
        handleClose: null,
        disableConfirm: false,
    });
    const [snackBar, setSnackBar] = useState<CommonSnackbarProps>({
        open: false,
        onClose: null,
        messageText: '',
        severity: '',
    });

    const dispatch = useDispatch();

    const {
        loggedInUserAppData: {
            data: { impersonator },
        },
    } = useLoggedInUser();
    useInitData(!!impersonator);
    const { providerGroupsData } = useProviderGroups();
    const { providersData } = useProviders();
    const { oneIdData } = useOneId();
    const { accountConfig } = useAccountConfig();
    const { systemsAppData } = useSystems([
        SystemTypeKey.Web,
        SystemTypeKey.Workstation,
        SystemTypeKey.DesktopMac,
        SystemTypeKey.DesktopWindows,
        SystemTypeKey.DesktopElectron,
    ]);
    const { error } = useError();

    const { t } = useTranslation('general');

    let systemsIncludePlatforms: IdpSystemsFormValue = [];
    const systemsEnvs = new Map<string, Environments>();
    systemsAppData.data.forEach((system) => {
        systemsIncludePlatforms.push({
            id: system.id,
            displayName: system.displayName,
            guid: system.guid,
            typeKey: system.systemTypeKey,
        });
        systemsEnvs.set(system.guid, system.environments);
        if (system.platforms && system.platforms.length) {
            systemsIncludePlatforms = systemsIncludePlatforms.concat(
                system.platforms
                    .filter((platform) => platform.platformTypeId === 3 || platform.platformTypeId === 6)
                    .map((platform) => {
                        systemsEnvs.set(platform.guid, system.environments);
                        return {
                            id: platform.id,
                            displayName: system.displayName,
                            guid: platform.guid,
                            typeKey: platform.platformName,
                        };
                    })
            );
        }
    });

    const getAssignSystems = (provider: Provider) => {
        const assignIdpSystemsForm: IdpSystemsFormValue = [];
        const assignIdpSystemsByEnv: IdpEnvs = [];
        let allProvider = providersData.data;
        if (provider) {
            allProvider = providersData.data.filter((p) => p.id !== provider.id);
        }
        allProvider.forEach(({ name, systems }) => {
            systems.forEach(({ guid, envs }) => {
                envs.forEach(({ id: wmEnv, enforceSso }) => {
                    assignIdpSystemsForm.push({ wmEnv, guid, enforceSso });
                    assignIdpSystemsByEnv.push({ id: wmEnv, enforceSso, systemGuid: guid, providerName: name });
                });
            });
        });
        return { assignIdpSystemsForm, assignIdpSystemsByEnv };
    };

    const handleSnackBarClose = (): void => {
        showSnackBar({ open: false, messageText: '', severity: '', onClose: null });
    };

    const showSnackBar = (data: CommonSnackbarProps): void => {
        setSnackBar({ ...data, onClose: handleSnackBarClose });
    };

    const showSnackBarError = (marginTop: string = null, message: string = null): void => {
        showSnackBar({
            ...snackBar,
            open: true,
            marginTop,
            messageText: message ? message : t('idp-tab.snackbar-error'),
            severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
        });
    };

    const closeErrorDialog = (): void => {
        dispatch(
            setShowError({
                showError: false,
                message: '',
                title: '',
                onConfirmText: '',
            })
        );
    };

    const closeDialog = (): void => {
        setShowDialog({ ...showDialog, open: false, doActionOnCancel: null });
    };

    const triggerErrorDialog = useCallback(() => {
        return (
            <IdpDialog
                open={error.showError}
                handleClose={closeErrorDialog}
                confirm={closeErrorDialog}
                title={error.title}
                content={error.message}
                type={'errorButton'}
                buttonText={'OK'}
                showCancel={false}
            />
        );
    }, [error.showError]);

    const openDialog = useCallback(() => {
        return (
            <IdpDialog
                open={showDialog.open}
                handleClose={closeDialog}
                confirm={showDialog.confirm}
                title={showDialog.title}
                content={showDialog.content}
                type={showDialog.type}
                buttonText={showDialog.buttonText}
                doActionOnCancel={showDialog.doActionOnCancel}
                showCancel={showDialog.showCancel}
            />
        );
    }, [showDialog, providersData]);

    const openProviderStepper = useCallback(() => {
        const { assignIdpSystemsByEnv, assignIdpSystemsForm } = getAssignSystems(provider);
        return (
            <StyledDialog
                children={
                    <ProviderFormStepper
                        showSnackBar={showSnackBar}
                        showSnackBarError={showSnackBarError}
                        stepperMode={stepperMode}
                        systemsEnvs={systemsEnvs}
                        onClose={cancel}
                        provider={provider}
                        providerGroups={providerGroupsData.data}
                        handleAssignSystems={handleAssignSystems}
                        handleSave={handleSave}
                        allSystems={systemsIncludePlatforms}
                        assignIdpSystemsForm={assignIdpSystemsForm}
                        assignIdpSystemsByEnv={assignIdpSystemsByEnv}
                        isImpersonator={!!impersonator}
                    />
                }
                fullWidth={true}
                scroll={'paper'}
                onClose={null}
                open={showForm}
                classes={{ paper: 'paper', container: 'container' }}
            />
        );
    }, [showForm, provider, providersData.data, impersonator, systemsEnvs]);

    const openOneIDDialog = useCallback(() => {
        return (
            <OneIdDialog
                open={showOneIdDialog}
                handleClose={() => setShowOneIdDialog(false)}
                providers={providersData.data.filter((provider) => provider.fields.length > 0)}
                oneId={oneIdData.data}
                onConfirm={openActivateOnIdDialog}
                deactivateOnId={openDeactivateOnIdDialog}
            />
        );
    }, [showOneIdDialog, providersData, oneIdData, showDialog]);

    const saveOneId = async (data: OneId): Promise<void> => {
        try {
            await dispatch(setOneId(data));
            closeDialog();
            showSnackBar({
                ...snackBar,
                open: true,
                messageText: t('idp-tab.oneId.activate.success'),
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (err) {
            showSnackBarError();
            throw err;
        }
    };

    const deactivateOneId = async (): Promise<void> => {
        try {
            await dispatch(turnOffOneId());
            closeDialog();
            showSnackBar({
                open: true,
                onClose: handleSnackBarClose,
                messageText: t('idp-tab.oneId.deactivate.success'),
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (err) {
            showSnackBarError();
            throw err;
        }
    };

    const openActivateOnIdDialog = (data): void => {
        setShowDialog({
            ...showDialog,
            open: true,
            showCancel: true,
            buttonText: t('idp-tab.oneId.activate.button'),
            title: t('idp-tab.oneId.activate.prompt.title'),
            content: t('idp-tab.oneId.activate.prompt.text'),
            doActionOnCancel: () => setShowOneIdDialog(true),
            confirm: () => saveOneId(data),
        });
    };

    const openDeactivateOnIdDialog = (): void => {
        setShowDialog({
            ...showDialog,
            open: true,
            showCancel: true,
            buttonText: t('idp-tab.oneId.deactivate.button'),
            title: t('idp-tab.oneId.deactivate.prompt.title'),
            content: t('idp-tab.oneId.deactivate.prompt.text'),
            doActionOnCancel: () => setShowOneIdDialog(true),
            confirm: () => deactivateOneId(),
        });
    };

    const handleAssignSystems = async (body: IdpSystemsFormValue): Promise<void> => {
        await dispatch(assignSystemToProvider(provider.id, body, !!impersonator));
    };

    const handleSave = async (body: SubmitProvider): Promise<void> => {
        let data;
        if (provider && provider.id) {
            data = await dispatch(updateProvider(provider.id, { ...body }));
        } else {
            data = await dispatch(createProvider(body));
        }
        setProvider(data);
    };

    const cancel = useCallback(() => {
        setShowForm(false);
    }, [setShowForm]);

    const createNewProvider = useCallback(() => {
        setStepperMode(StepperMode.NEW);
        setProvider(null);
        setShowForm(true);
    }, []);

    const editProvider = useCallback((provider: Provider) => {
        setStepperMode(StepperMode.EDIT);
        setProvider(provider);
        setShowForm(true);
    }, []);

    const editAttributes = useCallback((provider: Provider) => {
        setStepperMode(StepperMode.EDIT_ATTRIBUTES);
        setProvider(provider);
        setShowForm(true);
    }, []);
    const assignSystems = useCallback((provider: Provider) => {
        setStepperMode(StepperMode.ASSIGN_SYSTEMS);
        setProvider(provider);
        setShowForm(true);
    }, []);

    const searchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearch(event.target.value);
    };

    const filterProviders = providersData.data.filter((provider) => {
        return provider.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    const isLoading = (): boolean => {
        return providerGroupsData.loading || providersData.loading || oneIdData.loading || systemsAppData.loading;
    };

    return (
        <>
            {showDialog.open && openDialog()}
            {showForm && openProviderStepper()}
            {showOneIdDialog && openOneIDDialog()}
            {snackBar.open && <CustomSnackBar {...snackBar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />}
            {error && error.showError && triggerErrorDialog()}

            <SsoConfigurationHeader
                createNewProvider={createNewProvider}
                oneIdData={oneIdData}
                providerGroupsData={providerGroupsData}
                providersData={providersData}
                openOneIdDialog={() => setShowOneIdDialog(true)}
            />
            {isLoading() ? (
                <CircularProgress size={60} />
            ) : (
                <MainContainer>
                    {providersData.data.length && systemsIncludePlatforms.length ? (
                        <>
                            <StyledCSSSearchField
                                className={'list-search'}
                                value={search}
                                variant='outlined'
                                placeholder={t('idp-tab.search-placeholder')}
                                onChange={searchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}></StyledCSSSearchField>
                            <ProvidersList
                                providers={filterProviders}
                                editProvider={editProvider}
                                editAttributes={editAttributes}
                                assignSystems={assignSystems}
                                oneId={oneIdData.data}
                                accountConfig={accountConfig.data}
                                allSystems={systemsIncludePlatforms}
                                systemsEnvs={systemsEnvs}
                                isImpersonator={!!impersonator}
                            />
                        </>
                    ) : (
                        <NoResult
                            image={'/assets/icons/idp-no-result.png'}
                            paddingTop={'138px'}
                            title={t('idp-tab.no-idp.title')}
                            content={t('idp-tab.no-idp.description')}
                            actionText={t('idp-tab.no-idp.action-text')}
                            onAction={createNewProvider}
                        />
                    )}
                </MainContainer>
            )}
        </>
    );
};

export default SsoConfigurationPage;
