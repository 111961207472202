import Base, { openPdfInNewTab } from './base';
import { IdpSystemsFormValue, Provider, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import React from 'react';
import { oAuth2pingIDSettingsSchema } from '../validation';
import { sharedFields } from '../shared-fields';
import { Typography } from '@material-ui/core';

const pingIDForEnterprise = require('../../../common/pdfFiles/idp_ping_id_enterprise_instructions.pdf');
const pingFederateForEnterprise = require('../../../common/pdfFiles/idp_ping_federate_instructions.pdf');

export class PingId extends Base {
    constructor() {
        super('PingID', oAuth2pingIDSettingsSchema);
    }

    getFormFields(): Array<any> {
        return [
            sharedFields.idpName,
            sharedFields.clientId,
            sharedFields.clientSecret,
            sharedFields.idpDiscoveryUrl,
            sharedFields.enforceSSOIframeFlow,
        ];
    }

    initForm(provider: Provider, assignSystemsForm: IdpSystemsFormValue): ProviderFormValues {
        return {
            config: {
                type: this.getProtocol(),
                clientId: get(provider, 'config.clientId', ''),
                clientSecret: get(provider, 'config.clientSecret', ''),
                discoveryEndpoint: get(provider, 'config.discoveryEndpoint', ''),
                enforceSSOIframeFlow: get(provider, 'config.enforceSSOIframeFlow', false),
            },
            name: get(provider, 'name', ''),
            fields: get(provider, 'fields', []),
            userIdentifier: get(provider, 'userIdentifier', ''),
            systems: this.prepareSystems(get(provider, 'id', null), get(provider, 'systems', []), assignSystemsForm),
        };
    }

    getIdpInstructions = () => {
        const linkOriginal = (
            <Typography
                component='span'
                onClick={() => openPdfInNewTab(pingIDForEnterprise)}
                style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>
                PingID
            </Typography>
        );
        const linkExtra = (
            <Typography
                component='span'
                onClick={() => openPdfInNewTab(pingFederateForEnterprise)}
                style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>
                PingFederate
            </Typography>
        );
        return (
            <div style={{ color: '#2F426C8C' }}>
                <p>
                    Set up your{' '}
                    <>
                        {linkOriginal} or {linkExtra}
                    </>{' '}
                    application according to the instructions and copy the application properties to the fields below.
                </p>
            </div>
        );
    };
}
