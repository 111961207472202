import React from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

const StyledLink = styled(Link)`
    color: ${styledTheme.colors.main.inlineLinks};

    &:hover {
        text-decoration: none;
    }
`;

const GreyText = styled.span`
    color: #78868F;
`;

const TableFooterErrorMessage = () => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            errorMessage: {
                display: 'flex', 
                flex: 1,
                width: '440px',
                marginTop: '10px'
            },
            errorIcon: {
                color: styledTheme.colors.errorColor,
                marginRight: '5px'
            }
        })
    );

    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <ReportProblemOutlinedIcon className={`inline-icon ${classes.errorIcon}`} />
            <Typography>
                <GreyText>
                    <b>Something went wrong</b> Please try again later, or <StyledLink href="https://support.walkme.com/submit-a-ticket" target="_blank">Contact Support</StyledLink>
                </GreyText>
            </Typography>
        </div>
    );
};

export default TableFooterErrorMessage;