import { RTFunction } from 'apps/home/src/localization';
import { TFunction } from 'i18next';
import { Actions } from 'wm-accounts-sdk';

const predefinedPermissionsColors = {
    'Manage Content: Edit': 'RoyalBlue',
    'Manage Content: View Only': 'Sangria',
    'Manage Settings: Edit': 'MountainMeadow',
    'Manage Settings: View Only': 'Mauve',
    'Insights Access: Allowed': 'ForestGreen',
    'Manage Users: Edit': 'Mauve',
    'Customize Templates & CSS: Edit': 'RenoSand',
    'Manage Systems: Edit': 'Sangria',
    'Publish Content: All Environments': 'Aquamarine',
    'Publish Settings: All Environments': 'RedRibbon',
    'Publish Wm Version: All Environments': 'RoyalBlue',
};

export const hashString = (string) =>
    string
        .split('')
        .map((char) => char.charCodeAt(0))
        .reduce((a, b) => a + b, 0);

const tagColors = ['RoyalBlue', 'MountainMeadow', 'ForestGreen', 'RenoSand', 'Mauve', 'Sangria', 'Aquamarine', 'RedRibbon'];

export const findTranslatedPermissionDisplayName = (rt: RTFunction, action, permissionName) => {
    if (!action?.actionPermissions?.length) {
        return null;
    }

    const actionPermission = action.actionPermissions.find(
        (actionPermission) => actionPermission.permission != 'None' && actionPermission.permission === permissionName
    );
    if (!actionPermission?.permissionDisplayName) {
        return null;
    }

    // TO DO remove this after PublishAndUpdate permission was removed from roles
    if (actionPermission.permissionDisplayName === 'Publish Content & WM Version') {
        return rt('All Environments');
    }

    return rt(actionPermission.permissionDisplayName);
};

export const actionsColors = { ...predefinedPermissionsColors };

export const actionsToColorMapping = (actions: Actions) => {
    if (!actions) return;
    let colorIndex = 0;

    for (const action of actions) {
        for (const actionPermission of action.actionPermissions) {
            if (!actionPermission) continue;

            const label = `${action.displayName}: ${actionPermission.permissionDisplayName}`;
            const color = predefinedPermissionsColors[label] || tagColors[Math.floor(Math.random() * tagColors.length)];
            actionsColors[label] = color;
            colorIndex++;
        }
    }
};
