import { LinkSystemToExtensionData, AddNewMatcherData, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';

const buildSystemMatcherDataForLinkingExistingSystem = (systemMatcher: SystemMatcher): LinkSystemToExtensionData => {
    const initialMatcherData: LinkSystemToExtensionData = {
        envId: systemMatcher.envId,
        isActive: systemMatcher.isActive,
        selfDomains: systemMatcher.selfDomains,
        topDomains: systemMatcher.topDomains,
        preferSiteSnippet: systemMatcher.preferSiteSnippet,
        waitForSiteSnippetWithTimeout: systemMatcher.waitForSiteSnippetWithTimeout,
        loadOptions: systemMatcher.loadOptions,
    };

    return initialMatcherData;
};

const buildAddNewMatcherData = (systemMatcher: SystemMatcher): AddNewMatcherData => {
    const addNewMatcherData = buildSystemMatcherDataForLinkingExistingSystem(systemMatcher) as AddNewMatcherData;
    addNewMatcherData.isRegex = systemMatcher.isRegex;
    addNewMatcherData.selfRegex = systemMatcher.selfRegex;
    addNewMatcherData.topRegex = systemMatcher.topRegex;

    return addNewMatcherData;
};

export { buildSystemMatcherDataForLinkingExistingSystem, buildAddNewMatcherData };
