import { Box, CardActions, CardContent, RadioGroup, Typography } from '@material-ui/core';
import { updateImpersonationSettings, useImpersonationSettings, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { WMButton, WMConfirmationDialog, WMRadio, WMSelect } from '@walkme/wm-ui';
import { StyledSlider } from 'packages/pages/home/security-configuration/src/lib/session-lifetime/styles/styles';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import SecurityCard from '../common/security-card';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { UpdateMyAccountImpersonationRestrictionDTO, User } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping } from 'apps/home/src/localization/localization';

export const RestrictionStartPeriodMinutesArray = [-1, 1440, 4320, 10080, 43800, 87600];

export enum ImpersonationUsersOptions {
    all = 'all-users',
    specificUsers = 'specific-users',
}

export interface ImpersonationAllowedUserItem {
    label: string;
    value: User;
    id: number;
}

export interface ImpersonationStartInMinMark {
    timeInMinutes: number;
    value: number;
    label: string;
}

export const ImpersonationSettings = () => {
    const dispatch = useDispatch();
    const { usersAppData } = useUsers();
    const { impersonationSettingsAppData } = useImpersonationSettings();
    const [selectedImperosnationStartTimeInMinutes, setSelectedImperosnationStartTimeInMinutes] = useState<ImpersonationStartInMinMark>();
    const [openDialogWithNewImpersonationStatus, setOpenDialogWithNewImpersonationStatus] =
        useState<UpdateMyAccountImpersonationRestrictionDTO>(null);
    const [someDataChanged, setSomeDataChanged] = useState<boolean>(false);
    const [impersonationStartInMinMarks, setImpersonationStartInMinMarks] = useState<ImpersonationStartInMinMark[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<ImpersonationAllowedUserItem[]>([]);
    const [allUsers, setAllUsers] = useState<ImpersonationAllowedUserItem[]>([]);
    const [selectedOption, setSelectedOption] = useState<ImpersonationUsersOptions>(ImpersonationUsersOptions.all);

    const { t, i18n } = useTranslation('general');

    const isRestricted = !!impersonationSettingsAppData.data?.isRestricted;
    const currentTime = new Date().getTime();
    const restrictionStartDateMillis = impersonationSettingsAppData.data?.fullRestrictionStartDateMillis;
    const isAlreadyFullyRestricted =
        (restrictionStartDateMillis ? restrictionStartDateMillis < currentTime + 1000 * 60 : isRestricted) || false;

    const getClosestMarkInMinutes = () => {
        if (!impersonationSettingsAppData.data.fullRestrictionStartDateMillis || isAlreadyFullyRestricted) {
            return 0;
        }
        const marksInMinutes = [...RestrictionStartPeriodMinutesArray];
        marksInMinutes.shift();
        const closestMarkInMinutes = marksInMinutes.reduce((a, b) => {
            const aInMillis = moment().add(a, 'minutes').valueOf();
            const bInMillis = moment().add(b, 'minutes').valueOf();
            return Math.abs(bInMillis - impersonationSettingsAppData.data.fullRestrictionStartDateMillis) <
                Math.abs(aInMillis - impersonationSettingsAppData.data.fullRestrictionStartDateMillis)
                ? b
                : a;
        });
        return closestMarkInMinutes;
    };

    useEffect(() => {
        if (impersonationSettingsAppData.data && !impersonationSettingsAppData.loading) {
            const closestMarkInMinutes = getClosestMarkInMinutes();
            const startDate = impersonationSettingsAppData.data.fullRestrictionStartDateMillis
                ? moment(impersonationSettingsAppData.data.fullRestrictionStartDateMillis).calendar()
                : null;

            const impersonationStartInMinMarks: ImpersonationStartInMinMark[] = RestrictionStartPeriodMinutesArray.map(
                (optionInMin: number, index) => {
                    const displayValue = (() => {
                        if (optionInMin === -1) return t('security-tab.walkme-access.slider.always');
                        const fromNow = moment()
                            .to(moment(moment().add(optionInMin, 'minutes')))
                            .replace('in', 'for');
                        if (closestMarkInMinutes === optionInMin) {
                            return `${fromNow} (until ${startDate})`;
                        } else return fromNow;
                    })();

                    return {
                        label: displayValue,
                        timeInMinutes: optionInMin,
                        value: index,
                    };
                }
            );
            const closestMark = impersonationStartInMinMarks.find((mark) => mark.timeInMinutes === closestMarkInMinutes);
            setImpersonationStartInMinMarks(impersonationStartInMinMarks);
            setSelectedImperosnationStartTimeInMinutes(closestMark);
        }
    }, [impersonationSettingsAppData, i18n.language]);

    useEffect(() => {
        if (!impersonationSettingsAppData.loading && impersonationSettingsAppData.data && usersAppData.data) {
            const hasSpacificUsers =
                impersonationSettingsAppData.data &&
                impersonationSettingsAppData.data.allowedAccountUsers &&
                impersonationSettingsAppData.data.allowedAccountUsers.length > 0;
            const mappedUsers: ImpersonationAllowedUserItem[] =
                usersAppData && usersAppData.data
                    ? usersAppData.data.map((user) => {
                          return {
                              label: user.email,
                              value: user,
                              id: user.id,
                          };
                      })
                    : [];
            setAllUsers(mappedUsers);
            const selectedUsersData = hasSpacificUsers
                ? mappedUsers.filter((user) =>
                      impersonationSettingsAppData.data.allowedAccountUsers.find((allowdUserId) => allowdUserId === user.id)
                  )
                : [];
            setSelectedUsers(selectedUsersData);
            setSelectedOption(hasSpacificUsers ? ImpersonationUsersOptions.specificUsers : ImpersonationUsersOptions.all);
        }
    }, [impersonationSettingsAppData, usersAppData]);

    const onChangeUser = useCallback((newUsers: ImpersonationAllowedUserItem[]) => {
        setSelectedUsers(newUsers);
    }, []);

    const isDataChanged = () => {
        if (!impersonationSettingsAppData.data) {
            return false;
        }
        if (getAllowedAccountUsersToAdd().length > 0 || getAllowedAccountUsersToRemove().length > 0) {
            return true;
        }
        const hasSpacificUsers =
            impersonationSettingsAppData.data &&
            impersonationSettingsAppData.data.allowedAccountUsers &&
            impersonationSettingsAppData.data.allowedAccountUsers.length > 0;
        if (hasSpacificUsers && selectedOption === ImpersonationUsersOptions.all) {
            return true;
        }
        const closestMarkInMinutes = getClosestMarkInMinutes();
        const closestMark = impersonationStartInMinMarks.find((mark) => mark.timeInMinutes === closestMarkInMinutes);
        if (
            (selectedImperosnationStartTimeInMinutes && selectedImperosnationStartTimeInMinutes.timeInMinutes !== -1 && !closestMark) ||
            (selectedImperosnationStartTimeInMinutes &&
                closestMark &&
                closestMark.timeInMinutes !== selectedImperosnationStartTimeInMinutes.timeInMinutes)
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
        if (isDataChanged() !== someDataChanged) {
            setSomeDataChanged(isDataChanged);
        }
    }, [impersonationStartInMinMarks, selectedUsers, selectedOption, selectedImperosnationStartTimeInMinutes, i18n.language]);

    const getAllowedAccountUsersToAdd = (): number[] => {
        const allowedAccountUsers = impersonationSettingsAppData.data.allowedAccountUsers || [];
        const allowedAccountUsersIdsToAdd =
            selectedOption === ImpersonationUsersOptions.all
                ? []
                : selectedUsers
                      .filter((newSelectedUser) => !allowedAccountUsers.find((existingUserId) => existingUserId === newSelectedUser.id))
                      .map((newUser) => newUser.id);
        return allowedAccountUsersIdsToAdd;
    };
    // *AUDIT LOG PURPOSES* - this function is used to get the names of the users that are added to the allowed users list - *AUDIT LOG PURPOSES*
    const getAllowedAccountUsersEmailsToAdd = (): string[] => {
        const allowedAccountUsers = impersonationSettingsAppData.data.allowedAccountUsers || [];
        const allowedAccountUsersNamesToAdd =
            selectedOption === ImpersonationUsersOptions.all
                ? []
                : selectedUsers
                      .filter((newSelectedUser) => !allowedAccountUsers.find((existingUserId) => existingUserId === newSelectedUser.id))
                      .map((newUser) => newUser.value.email);
        return allowedAccountUsersNamesToAdd;
    };

    const getAllowedAccountUsersToRemove = (): number[] => {
        const allowedAccountUsers = impersonationSettingsAppData.data.allowedAccountUsers || [];
        const allowedAccountUsersIdsToRemove =
            selectedOption === ImpersonationUsersOptions.all
                ? allowedAccountUsers
                : allowedAccountUsers.filter((existingUserId) => !selectedUsers.find((user) => user.id === existingUserId));
        return allowedAccountUsersIdsToRemove;
    };

    // *AUDIT LOG PURPOSES* - this function is used to get the names of the users that are removed from the allowed users list - *AUDIT LOG PURPOSES*
    const getAllowedAccountUsersEmailsToRemove = (): string[] => {
        const allowedAccountUsersToRemove = getAllowedAccountUsersToRemove();
        return allUsers
            .filter((selectedUser) => allowedAccountUsersToRemove.includes(selectedUser.id))
            .map((selectedUser) => selectedUser.value.email);
    };

    const onSwitchToggle = (isImpersonationEnabled: boolean) => {
        if (isImpersonationEnabled) {
            dispatch(
                updateImpersonationSettings({
                    isFullRestricted: false,
                    restrictionStartPeriodMinutes: null,
                    allowedAccountUsersIdsToAdd: [],
                    allowedAccountUsersIdsToRemove: [],
                    // *AUDIT LOG PURPOSES*
                    allowedAccountUsersEmailsToAdd: [],
                    allowedAccountUsersEmailsToRemove: [],
                    allUsersAllowedOptionSelected: selectedOption === ImpersonationUsersOptions.all,
                    // *AUDIT LOG PURPOSES*
                })
            );
        } else {
            setOpenDialogWithNewImpersonationStatus({
                isFullRestricted: true,
                restrictionStartPeriodMinutes: null,
                allowedAccountUsersIdsToAdd: [],
                allowedAccountUsersIdsToRemove: [],
                // *AUDIT LOG PURPOSES*
                allUsersAllowedOptionSelected: false,
                // *AUDIT LOG PURPOSES*
            });
        }
    };

    const onClickSave = () => {
        if (!selectedImperosnationStartTimeInMinutes || selectedImperosnationStartTimeInMinutes.timeInMinutes === -1) {
            dispatch(
                updateImpersonationSettings({
                    isFullRestricted: false,
                    restrictionStartPeriodMinutes: null,
                    allowedAccountUsersIdsToAdd: getAllowedAccountUsersToAdd(),
                    allowedAccountUsersIdsToRemove: getAllowedAccountUsersToRemove(),
                    // *AUDIT LOG PURPOSES*
                    allUsersAllowedOptionSelected: selectedOption === ImpersonationUsersOptions.all,
                    allowedAccountUsersEmailsToAdd: getAllowedAccountUsersEmailsToAdd(),
                    allowedAccountUsersEmailsToRemove: getAllowedAccountUsersEmailsToRemove(),
                    // *AUDIT LOG PURPOSES*
                })
            );
        } else {
            setOpenDialogWithNewImpersonationStatus({
                isFullRestricted: true,
                restrictionStartPeriodMinutes: selectedImperosnationStartTimeInMinutes.timeInMinutes,
                allowedAccountUsersIdsToAdd: getAllowedAccountUsersToAdd(),
                allowedAccountUsersIdsToRemove: getAllowedAccountUsersToRemove(),
                // *AUDIT LOG PURPOSES*
                allUsersAllowedOptionSelected: selectedOption === ImpersonationUsersOptions.all,
                allowedAccountUsersEmailsToAdd: getAllowedAccountUsersEmailsToAdd(),
                allowedAccountUsersEmailsToRemove: getAllowedAccountUsersEmailsToRemove(),
                // *AUDIT LOG PURPOSES*
            });
        }
    };

    const setTime = (e, indexValue) => {
        setSelectedImperosnationStartTimeInMinutes(impersonationStartInMinMarks[indexValue]);
    };

    const impersonationConfigContent = (
        <CardContent style={{ paddingTop: '0px' }}>
            <WMConfirmationDialog
                ds2
                open={openDialogWithNewImpersonationStatus ? true : false}
                onCancel={() => {
                    setOpenDialogWithNewImpersonationStatus(null);
                }}
                onConfirm={() => {
                    dispatch(updateImpersonationSettings(openDialogWithNewImpersonationStatus));
                    setOpenDialogWithNewImpersonationStatus(null);
                }}
                title={'Confirmation'}
                confirmLabel='Confirm'
                cancelButtonProps={{
                    style: {
                        color: 'black',
                    },
                }}
                confirmButtonProps={{
                    style: {
                        fontFamily: 'ProximaNova',
                        boxShadow: 'none',
                        borderColor: '#E1E9F5',
                        backgroundColor: '#EB3232',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#CC1616',
                            opacity: '0.9',
                        },
                    },
                }}>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        color: '#2F426C',
                        fontSize: '12px',
                        marginTop: '32px',
                    }}>
                    Please note that by limiting WalkMe impersonation access you will prevent all customer-facing teams at WalkMe from
                    accessing your account information. This will hinder our ability to service you properly, which includes<br></br>
                    <br></br>
                    &#x2022; Business reviews with your CSM<br></br>
                    <br></br>
                    &#x2022; Support tickets handling and SLA commitments<br></br>
                    <br></br>
                    &#x2022; Onboarding and ongoing projects with your DAC and Services team<br></br>
                    <br></br>
                    Also note that this change will apply to all systems on account level and can only be enabled/disabled by an Admin.
                    <br />
                    In order for us to continue providing you our best support and service capabilities we recommend that you leave access
                    available to 'All Users'.
                    <br></br>
                    <br></br>
                    If for any reason you decide to restrict access to 'Specific Users', make sure to notify your CSM beforehand so we can
                    make sure to inform all relevant internal teams.
                    <br></br>
                </Typography>
            </WMConfirmationDialog>
            <Box padding='0px 24px 0px 24px'>
                <Box display='flex' flexDirection='column'>
                    <StyledSlider
                        value={selectedImperosnationStartTimeInMinutes ? selectedImperosnationStartTimeInMinutes.value : 0}
                        marks={impersonationStartInMinMarks}
                        valueLabelDisplay='off'
                        step={null}
                        min={0}
                        max={
                            impersonationStartInMinMarks.length > 0
                                ? impersonationStartInMinMarks[impersonationStartInMinMarks.length - 1].value
                                : 0
                        }
                        onChange={setTime}
                    />
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            color: '#2F426C',
                            fontSize: '12px',
                            marginTop: '32px',
                        }}>
                        {t('security-tab.walkme-access.impersonation.selectWhichUsers')}
                    </Typography>
                    <RadioGroup
                        value={selectedOption}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setSelectedOption(event.target.value as ImpersonationUsersOptions)
                        }
                        name='Users'
                        style={{ width: '300px', marginTop: '5px' }}>
                        <WMRadio
                            value={ImpersonationUsersOptions.all}
                            label={t('security-tab.walkme-access.impersonation.values.allUsers')}
                        />
                        <WMRadio
                            value={ImpersonationUsersOptions.specificUsers}
                            label={t('security-tab.walkme-access.impersonation.values.specificUsers')}
                        />
                    </RadioGroup>
                </Box>

                {selectedOption == ImpersonationUsersOptions.specificUsers && (
                    <Box display='flex' flexDirection='column'>
                        <div style={{ width: '350px', marginRight: '16px', marginTop: '16px' }}>
                            <WMSelect
                                options={allUsers}
                                value={selectedUsers}
                                onChange={onChangeUser}
                                isMulti
                                isPopout
                                groupValues
                                multiValueGroupLabel={t('security-tab.walkme-access.impersonation.select.multi-value-group-label')}
                                disablePortal
                                popoutSelectProps={{ menuPlacement: 'top' }}
                                placeholder={t('wm-select.placeholder')}
                            />
                        </div>
                    </Box>
                )}
            </Box>
            <CardActions
                disableSpacing
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <WMButton disabled={!someDataChanged} onClick={onClickSave} loading={impersonationSettingsAppData.loading}>
                    {t('security-tab.walkme-access.impersonation.save')}
                </WMButton>
            </CardActions>
        </CardContent>
    );

    return (
        <SecurityCard
            name='impersonation-config'
            marginTop={'32px'}
            collapsableComponant={impersonationConfigContent}
            handleSwitchClick={onSwitchToggle}
            title={t('security-tab.walkme-access.title')}
            subTitle={t('security-tab.walkme-access.subTitle')}
            checked={!isAlreadyFullyRestricted}
            error={impersonationSettingsAppData.error}
            loading={impersonationSettingsAppData.loading}
            disabled={!impersonationSettingsAppData.data || impersonationSettingsAppData.loading}
        />
    );
};

export default ImpersonationSettings;
