import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelfHostedState } from '../self-hosted-state';
import { selfHostedSlice, getEnvironments } from '../self-hosted.slice';

const useEnvironmentsBySystem = (systemId: number) => {
    const environments = useSelector((state: { selfHostedState: SelfHostedState }) => state.selfHostedState.environmentsBySystem);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(selfHostedSlice.actions.clearEnvironments());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getEnvironments({ systemId }));
    }, [dispatch]);

    return { environments: environments[systemId] };
};

export default useEnvironmentsBySystem;
