import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { ConfigurationErrorDetails } from '../../utils';
import { useStyles } from './styles';

import ConfigurationErrorImage from '../../../../../../../libs/common/assets/icons/configuration-error.png';

interface ConfigurationErrorProps {
    configurationErrorDetails: ConfigurationErrorDetails;
    containerClassName?: string;
    imageClassName?: string;
}

export const ConfigurationError = ({ configurationErrorDetails, containerClassName, imageClassName }: ConfigurationErrorProps) => {
    const classes = useStyles();

    const configurationErrorContainerClasses = `${classes.configurationErrorContainer} ${containerClassName || ''}`;

    return (
        <Box className={configurationErrorContainerClasses}>
            <img
                alt={configurationErrorDetails.title}
                src={configurationErrorDetails.image || ConfigurationErrorImage}
                className={imageClassName}
            />
            <Typography className={classes.configurationErrorTitle}>{configurationErrorDetails.title}</Typography>
            <Typography className={classes.configurationErrorMessage}>{configurationErrorDetails.message}</Typography>
        </Box>
    );
};

export default ConfigurationError;
