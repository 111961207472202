import styled from 'styled-components';
import { DropDownContainer } from '../../../../../../../extension-pages/src/lib/new-design/shared-styled-components';

export const StyledDropDownContainer = styled(DropDownContainer)`
    margin-top: 8px;

    .wmSelect__control {
        background: var(--color-semantic-input-dropdown-primary-bg-default, #f0f3f9);
        max-height: 40px;
        height: 40px;
    }
`;
