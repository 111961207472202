import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BigDeprecatedImage, SmallDeprecatedImage } from './ml-deprecated-images';
import { WMButton } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    max-width: 365px;
`;

const TextWrapper = styled(ColumnWrapper)`
    gap: 14px;
`;

const Title = styled.h1`
    font-size: 24px;
    line-height: 32px;
    color: #2f426c;
    font-weight: 600;
    margin: 0;
`;

const Description = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    color: #2f426c;
`;

const StyledButton = styled(WMButton)`
    height: 40px;
    border-radius: 20px;
`;

export const MultiLanguageDeprecatedPage: React.FC = () => {
    const { t } = useTranslation('general');

    useEffect(() => {
        document.title = t('ml-tab.document-title');
    }, []);

    return (
        <Wrapper>
            <ContentWrapper>
                <ColumnWrapper>
                    <SmallDeprecatedImage />
                    <TextWrapper>
                        <Title>{t('ml-tab.title')}</Title>
                        <Description>{t('ml-tab.description')}</Description>
                    </TextWrapper>
                    <a href={`${window.clientConfig.NX_APP_URL_CONSOLE_MAIN}/multi-language/language-list`}>
                        <StyledButton>{t('buttons.go-to-console')}</StyledButton>
                    </a>
                </ColumnWrapper>
                <BigDeprecatedImage />
            </ContentWrapper>
        </Wrapper>
    );
};
