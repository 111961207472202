import { WMSwitch } from '@walkme/wm-ui';
import { Toaster, ToasterVariant, Tooltip } from '@walkme/ui-core';
import React, { ChangeEvent, useState } from 'react';
import Regex from './regex';
import {
    ControlContainer,
    ControlHeader,
    ControlSubHeader,
    StyledWmDivider,
    ControlHeaderWithTooltipContainer,
    StyledLink,
    UrlsContainer,
    StyledTooltipText,
} from './shared-styled-components';
import URLMatchPatterns from './url-match-patterns';
import { SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmationDialog from './confirmation-dialog';
import { get, isEqual } from 'lodash';
import styled from 'styled-components';
import { getTooltipIcon } from './utils';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { URLMatchData } from '../url-verifier/values';

const StyledToaster = styled(Toaster)`
    border: unset;
    box-shadow: unset;
`;

interface BasicConfigurationsProps {
    isSaveInProgress: boolean;
    isAddingNewUrl: boolean;
    selectedMatcher: SystemMatcher | undefined;
    urlVerifierMatchData: URLMatchData[];
    setIsSelfRegexValid: React.Dispatch<React.SetStateAction<boolean>>;
    setAreSelfUrlsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedMatcher: React.Dispatch<React.SetStateAction<SystemMatcher>>;
    setIsAddingNewUrl: React.Dispatch<React.SetStateAction<boolean>>;
    onAddNewSelfUrlClosed: () => void;
}

const BasicConfigurations = ({
    isSaveInProgress,
    isAddingNewUrl,
    selectedMatcher,
    urlVerifierMatchData,
    setIsSelfRegexValid,
    setAreSelfUrlsValid,
    setSelectedMatcher,
    setIsAddingNewUrl,
    onAddNewSelfUrlClosed,
}: BasicConfigurationsProps) => {
    const { t } = useTranslation('general');
    const [showDeactivateWalkmeConfirmation, setShowDeactivateWalkmeConfirmation] = useState(false);

    const onIsActiveChange = (event?: ChangeEvent<HTMLInputElement>): void => {
        if (event && selectedMatcher.isActive) {
            setShowDeactivateWalkmeConfirmation(true);
            return;
        }

        const matcherClone = cloneDeep(selectedMatcher);
        matcherClone.isActive = !matcherClone.isActive;
        setSelectedMatcher(matcherClone);
    };

    const deactivateWalkmeDialog = (
        <ConfirmationDialog
            isOpen={showDeactivateWalkmeConfirmation}
            title={t('systems-tab.all-systems-page.extension-page.disable-title')}
            message={t('systems-tab.all-systems-page.extension-page.disable-message')}
            confirmButtonText={t('buttons.disable')}
            confirmButtonColor='danger'
            onCancel={() => setShowDeactivateWalkmeConfirmation(false)}
            onConfirm={() => {
                onIsActiveChange();
                setShowDeactivateWalkmeConfirmation(false);
            }}
        />
    );

    const activeStatusControl = (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ControlHeaderWithTooltipContainer style={{ flexGrow: 1 }}>
                    <ControlHeader>{t('systems-tab.all-systems-page.extension-page.basic-title')}</ControlHeader>
                    <Tooltip
                        title={
                            <StyledTooltipText>{t('systems-tab.all-systems-page.extension-page.basic-title-tooltip')}</StyledTooltipText>
                        }
                        arrow>
                        {getTooltipIcon()}
                    </Tooltip>
                </ControlHeaderWithTooltipContainer>
                <WMSwitch
                    disabled={isSaveInProgress || isAddingNewUrl}
                    checked={selectedMatcher ? selectedMatcher.isActive : false}
                    onChange={onIsActiveChange}
                />
            </div>
            <ControlSubHeader>{t('systems-tab.all-systems-page.extension-page.basic-subtitle')}</ControlSubHeader>
        </>
    );

    const disabledMatcherWarningControl = (
        <div style={{ marginTop: '14px' }}>
            <StyledToaster
                icon
                variant={ToasterVariant.Warning}
                message={t('systems-tab.all-systems-page.extension-page.warning-message')}
                width={'auto'}
            />
        </div>
    );

    const getSelfRegexControl = () => (
        <Regex
            setIsValid={setIsSelfRegexValid}
            value={selectedMatcher?.selfRegex || ''}
            setValue={(newValue: string) => {
                if (newValue !== selectedMatcher.selfRegex) {
                    const selectedMatcherClone = cloneDeep(selectedMatcher);
                    selectedMatcherClone.selfRegex = newValue;
                    setSelectedMatcher(selectedMatcherClone);
                }
            }}
            urlVerifierMatchData={urlVerifierMatchData}
        />
    );

    const getSelfDomainsControl = () => (
        <URLMatchPatterns
            urls={selectedMatcher?.selfDomains || []}
            setAreUrlsValid={setAreSelfUrlsValid}
            setUrls={(newUrls: string[]) => {
                if (!isEqual(newUrls, selectedMatcher.selfDomains)) {
                    const selectedMatcherClone = cloneDeep(selectedMatcher);
                    selectedMatcherClone.selfDomains = newUrls;
                    setSelectedMatcher(selectedMatcherClone);
                }
            }}
            shouldShowNewLine={false}
            onAddUrlClicked={() => setIsAddingNewUrl(true)}
            onAddNewUrlClosed={onAddNewSelfUrlClosed}
            onAddNewUrlSaved={() => setIsAddingNewUrl(false)}
            urlVerifierMatchData={urlVerifierMatchData}
        />
    );

    const regexMatcherUrlsControl = (
        <>
            <ControlHeader>{t('systems-tab.all-systems-page.extension-page.regex-title')}</ControlHeader>
            <ControlSubHeader style={{ marginBottom: '12px' }}>
                {t('systems-tab.all-systems-page.extension-page.regex-description')}
                <StyledLink
                    to={{ pathname: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions/Cheatsheet' }}
                    target='_blank'>
                    {' ' + t('buttons.learn-more')}
                </StyledLink>
            </ControlSubHeader>
            {getSelfRegexControl()}
        </>
    );

    const selfDomainsMatcherControl = (
        <>
            <ControlHeader>{t('systems-tab.all-systems-page.extension-page.env-urls')}</ControlHeader>
            <ControlSubHeader style={{ marginBottom: '12px' }}>
                {t('systems-tab.all-systems-page.extension-page.env-urls-description')}
                <StyledLink to={{ pathname: 'https://support.walkme.com/knowledge-base/url-match-pattern' }} target='_blank'>
                    {' ' + t('buttons.learn-more')}
                </StyledLink>
            </ControlSubHeader>
            {getSelfDomainsControl()}
        </>
    );

    return (
        <>
            {deactivateWalkmeDialog}
            <ControlContainer>
                {activeStatusControl}
                {!selectedMatcher?.isActive ? disabledMatcherWarningControl : null}
            </ControlContainer>
            <StyledWmDivider />
            {selectedMatcher ? (
                <UrlsContainer>{selectedMatcher.isRegex ? regexMatcherUrlsControl : selfDomainsMatcherControl}</UrlsContainer>
            ) : null}
        </>
    );
};

export default BasicConfigurations;
