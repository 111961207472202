import { DropdownOption } from '../../../../../pages/home/systems/src/lib/create-system/steps/optional-configure/uuid/uuid.lib';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { siteConfigApi } from '../../../../../pages/home/systems/src/lib/api/site-config-api';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import { ThunkAction } from 'redux-thunk';
import { expectedFormatOptions, UUIDOptions } from '../../../../../pages/home/systems/src/lib/uuid/uuid.lib';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { NotificationMessage } from '@walkme-admin-center/libs/types';

interface InitialUUIDValues {
    selectedUUID: DropdownOption;
    selectedUUIDVariableValue: string;
    selectedExpectedFormat: DropdownOption;
    regexpInputValue: string;
}

export interface UUIDState {
    selectedSystemId: number;
    initialUUIDValues: InitialUUIDValues;
    selectedUUID: DropdownOption;
    selectedUUIDVariableValue: string;
    selectedExpectedFormat: DropdownOption;
    regexpInputValue: string;
    siteConfiguration: any;
    idpSelectDialogIsOpen: boolean;
    uuidErrors: Record<string, boolean>;
    notificationMessage: NotificationMessage;
}
export const initialUUIDState: UUIDState = {
    selectedSystemId: null,
    initialUUIDValues: {
        selectedUUID: {
            label: 'none',
            value: 'none',
            id: 0,
        },
        selectedUUIDVariableValue: null,
        selectedExpectedFormat: {
            label: 'none',
            value: 'None',
            id: 1,
        },
        regexpInputValue: null,
    },
    selectedUUID: {
        label: 'none',
        value: 'none',
        id: 0,
    },
    selectedUUIDVariableValue: null,
    selectedExpectedFormat: {
        label: 'none',
        value: 'None',
        id: 1,
    },
    regexpInputValue: null,
    siteConfiguration: null,
    idpSelectDialogIsOpen: false,
    uuidErrors: {},
    notificationMessage: {
        text: '',
        variant: WMSnackbarVariant.Success,
        isOpen: false,
    },
};

const uuidSlice = createSlice({
    name: 'uuidSlice',
    initialState: initialUUIDState,
    reducers: {
        setSelectedSystemId(state: UUIDState, action: PayloadAction<number>) {
            state.selectedSystemId = action.payload;
            return state;
        },
        setInitialUUIDValues(state: UUIDState, action: PayloadAction<InitialUUIDValues>) {
            state.initialUUIDValues = action.payload;
            return state;
        },
        setSelectedUUID(state: UUIDState, action: PayloadAction<DropdownOption>) {
            state.selectedUUID = action.payload;
            return state;
        },
        setSelectedUUIDVariableValue(state: UUIDState, action: PayloadAction<string>) {
            state.selectedUUIDVariableValue = action.payload;
            return state;
        },
        setSelectedExpectedFormat(state: UUIDState, action: PayloadAction<DropdownOption>) {
            state.selectedExpectedFormat = action.payload;
            return state;
        },
        setRegexpInputValue(state: UUIDState, action: PayloadAction<string>) {
            state.regexpInputValue = action.payload;
            return state;
        },
        setSiteConfiguration(state: UUIDState, action: PayloadAction<any>) {
            state.siteConfiguration = action.payload;
            return state;
        },
        setIdpSelectDialogIsOpen(state: UUIDState, action: PayloadAction<boolean>) {
            state.idpSelectDialogIsOpen = action.payload;
            return state;
        },
        setNotificationMessage(state: UUIDState, action: PayloadAction<NotificationMessage>) {
            state.notificationMessage = action.payload;
            return state;
        },
        cleanUpNotificationMessage(state: UUIDState) {
            state.notificationMessage.isOpen = false;
            return state;
        },
        setUUIDErrors(state: UUIDState, action: PayloadAction<Record<string, boolean>>) {
            state.uuidErrors = action.payload;
            return state;
        },
        resetUUIDBody: (state: UUIDState) => {
            state.selectedUUIDVariableValue = null;
            state.selectedExpectedFormat = {
                label: 'none',
                value: 'None',
                id: 1,
            };
            state.regexpInputValue = null;
        },
        restartUUIDState(state: UUIDState) {
            state.selectedSystemId = null;
            state.selectedUUID = {
                label: 'none',
                value: 'none',
                id: 0,
            };
            state.selectedUUIDVariableValue = null;
            state.selectedExpectedFormat = {
                label: 'none',
                value: 'None',
                id: 1,
            };
            state.regexpInputValue = null;
            state.siteConfiguration = null;
            state.idpSelectDialogIsOpen = false;
        },
    },
});

export { uuidSlice };

const {
    setSiteConfiguration,
    setSelectedSystemId,
    setSelectedUUID,
    setSelectedUUIDVariableValue,
    setSelectedExpectedFormat,
    setRegexpInputValue,
    setInitialUUIDValues,
} = uuidSlice.actions;
type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk<ReturnType = void> = ThunkAction<ReturnType, rootReducerType, unknown, Action<string>>;

export const fetchExistingUserConfiguration =
    (forceLoad = false, systemId): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (!forceLoad && getState().uuidState.selectedUUID === systemId) {
                return;
            }
            const userSettings = await siteConfigApi.getUserConfiguration(systemId);
            const endUserSettings = userSettings?.SiteConfiguration?.EndUserSettings;
            const initialUUIDValuesToSave: InitialUUIDValues = {
                selectedUUID: {
                    label: 'none',
                    value: 'none',
                    id: 0,
                },
                selectedUUIDVariableValue: null,
                selectedExpectedFormat: {
                    label: 'none',
                    value: 'None',
                    id: 1,
                },
                regexpInputValue: null,
            };
            const currentUUID = UUIDOptions.find((option) => option.value === endUserSettings?.Method);
            const currentVariableValue = endUserSettings?.Parameters?.VarName;
            const currentExpectedFormat = expectedFormatOptions.find((format) => endUserSettings?.IdFormat?.Type === format.value);
            initialUUIDValuesToSave.selectedUUID = currentUUID;
            dispatch(setSelectedUUID(currentUUID));

            if (currentVariableValue) {
                initialUUIDValuesToSave.selectedUUIDVariableValue = currentVariableValue;
                dispatch(setSelectedUUIDVariableValue(currentVariableValue));
            }

            if (currentExpectedFormat) {
                initialUUIDValuesToSave.selectedExpectedFormat = currentExpectedFormat;
                dispatch(setSelectedExpectedFormat(currentExpectedFormat));

                if (currentExpectedFormat.value === 'Custom_Regexp') {
                    initialUUIDValuesToSave.regexpInputValue = endUserSettings?.IdFormat?.Regexp;
                    dispatch(setRegexpInputValue(endUserSettings?.IdFormat?.Regexp));
                }
            }
            dispatch(setSiteConfiguration(userSettings?.SiteConfiguration));
            dispatch(setSelectedSystemId(systemId));
            dispatch(setInitialUUIDValues(initialUUIDValuesToSave));
        } catch (err) {
            return;
        }
    };
