import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Root from './app/root';
import { Provider } from 'react-redux';
import store from '../src/redux/store';
import { WMAuthManager, WMAuthManagerStorageType, WMAuthManagerType } from 'wm-accounts-auth';
import { AccountsSdk, AuditLogSdk } from 'wm-accounts-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import initLocalizationData from './localization/localization';
import { QueryClient, QueryClientProvider } from 'react-query';

initLocalizationData();

datadogRum.init({
    applicationId: window.clientConfig.NX_DD_APP_ID,
    clientToken: window.clientConfig.NX_DD_CLIENT_TOKEN,
    site: window.clientConfig.NX_DD_SITE,
    service: 'admin-center',
    env: window.clientConfig.NX_WM_NODE_ENV,
    sampleRate: 100,
    trackInteractions: true,
    proxyHost: window.clientConfig.NX_DATADOG_PROXY,
});

datadogLogs.init({
    clientToken: window.clientConfig.NX_DD_CLIENT_TOKEN,
    site: window.clientConfig.NX_DD_SITE,
    forwardErrorsToLogs: true,
    service: 'admin-center',
    env: window.clientConfig.NX_WM_NODE_ENV,
    useCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    proxyHost: window.clientConfig.NX_DATADOG_PROXY,
});

WMAuthManager.createWithConfig({
    managerType: window.clientConfig.NX_AUTH_TYPE as WMAuthManagerType,
    apiUrl: window.clientConfig.NX_WM_API_URL,
    authorityUrl: window.clientConfig.NX_AUTH_URL,
    clientId: window.clientConfig.NX_IDENTITY_CLIENT_ID,
    responseType: window.clientConfig.NX_IDENTITY_RESPONSE_TYPE,
    scope: window.clientConfig.NX_IDENTITY_SCOPE,
    shouldShowDebugLogs: window.clientConfig.NX_WM_NODE_ENV === 'dev2',
    autoRedirectToLoginOnInvalidToken: true,
    supportIdleLogout: true,
});

AccountsSdk.createWithConfig({
    baseUrl: window.clientConfig.NX_WM_API_URL,
    accessTokenGetter: {
        getAuthorizationHeaderValue(): string {
            return WMAuthManager.getInstance().getAuthorizationHeaderValue();
        },
        getUserToken(): string {
            return WMAuthManager.getInstance().getUserToken();
        },
    },
});

AuditLogSdk.createWithConfig({
    baseUrl: window.clientConfig.NX_WM_API_URL,
    accessTokenGetter: {
        getAuthorizationHeaderValue(): string {
            return WMAuthManager.getInstance().getAuthorizationHeaderValue();
        },
        getUserToken(): string {
            return WMAuthManager.getInstance().getUserToken();
        },
    },
});

console.log('load main app');

const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Root />
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
