import { IconButton, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled.div`
    padding: 1.5rem 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    height: calc(100% - 2 * 1.5rem);
`;

export const ExplainText = styled.p`
    margin: 0;
    color: var(--Color-Text-On-light-secondary, #637191);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const ExplainTitle = styled.p`
    margin: 0;
    color: var(--Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const ExplainSubtitle = styled.p`
    margin: 0;
    color: var(--Color-Text-On-light-secondary, #637191);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const Bold = styled.span`
    font-weight: 700;
`;

export const ExplainTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    gap: 4px;
`;

export const ExplainRowContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    align-self: stretch;
`;

export const SmallIconButton = withStyles({
    root: {
        padding: '2px',
    },
})(IconButton);

export const EmptyMsgContainer = styled.div`
    padding: 1.5rem;
    margin: auto;
`;
