import moment from 'moment';
import { useMemo } from 'react';
import { IDateRange } from '../../types';

export enum DateRangeTypeEnum {
    month = 'month',
    quarter = 'quarter',
}
export interface DateRangeOption {
    label: string;
    value: {
        type: DateRangeTypeEnum;
        fromDate: string;
        toDate: string;
    };
}

export const getDateRangeType = (fromDate: string, toDate: string) =>
    moment(toDate).diff(fromDate, 'months') > 1 ? DateRangeTypeEnum.quarter : DateRangeTypeEnum.month;

export const useDateRangeOptions = (availableDates: IDateRange[]) => {
    return useMemo<DateRangeOption[]>(
        () =>
            availableDates.map(({ fromDate, toDate }: any) => {
                const type = getDateRangeType(fromDate, toDate);
                const momentFromDate = moment(fromDate);
                return {
                    label: momentFromDate.format('MMM'),
                    value: {
                        type,
                        fromDate,
                        toDate,
                    },
                };
            }),
        [availableDates]
    );
};

export default useDateRangeOptions;
