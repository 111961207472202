import styled from 'styled-components';
import { Divider, IconButton, withStyles } from '@material-ui/core';

export const HeaderContainer = styled.div<{ subHeader: boolean }>`
    height: ${(props) => (props.subHeader ? '92px' : '68px')};
    width: 100%;
`;

export const Header = styled.div<{ subHeader: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 24px ${(props) => (props.subHeader ? '28px' : '32px')} 0 32px;

    .header-flex-row {
        display: flex;
        flex-direction: row;

        .header-inner-flex-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
`;

export const HeaderText = styled.span`
    color: var(--LS-Color-Text-primary, #2f426c);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const SubHeaderText = styled.span`
    color: #637191;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const CssFormHeaderIcon = withStyles({
    sizeSmall: {
        padding: 0,
        fontSize: '1rem',
        '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
        },
    },
})(IconButton);

export const CssDivider = withStyles({
    root: {
        marginTop: '15px',
        backgroundColor: '#F5F6F8',
    },
})(Divider);
