import React, { useEffect, useMemo, useState } from 'react';
import IntegrationCard from './integration-card/integration-card';
import SelectIntegrationsActions from './select-integrations-actions/select-integrations-actions';
import { useDispatch } from 'react-redux';
import { deleteAskMe, getAskMeById, getGuidedAnswers, getUrls } from '@walkme-admin-center/libs/state-mangment-data-integration';
import NoResult from '../../../../sso-configuration/src/lib/common/components/no-result/no-result';
import {
    FormMode,
    FormTypes,
    IAskMe,
    IAsksMe,
    IScanSummary,
    JobType,
} from '../../../../../../libs/state-mangment-data-integration/src/lib/types';
import {
    AskMeCounter,
    DataIntegrationTableContainer,
    DataIntegrationsListContainer,
    TableTopRow,
    TableHeader,
    TableHeaderContent,
    HeaderLastSyncContainer,
    HeaderNameContainer,
    HeaderSyncStatusContainer,
    HeaderCreatedContainer,
} from './data-integrations-list-styled';
import ChatWrapper from './chat-wrapper/chat-wrapper';
import IntegrationTableRow from './integration-row/integration-table-row';
import { CssArrowIcon, CssCheckBox, CssIntegrationData, CssIntegrationRow, IconContainer } from './integration-row/integration-row-style';
import UpDownArrow from 'packages/libs/common/assets/icons/data-integrations/integrations-icons/up-down-arrow-icon.svg';
import { CONSTS } from 'packages/pages/home/sso-configuration/src/lib/common/consts';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

const jobTypeToFormType = {
    [JobType.WEB]: FormTypes.CRAWLER,
    [JobType.FILE]: FormTypes.FILE_SCAN,
    pdf: FormTypes.FILE_SCAN, // TODO: legacy, remove
};

export interface IDataIntegrationsList {
    integrations: IAsksMe;
    triggerForm: (formMode: FormMode, instanceData: any, formType: FormTypes) => void;
    clearSearch: () => void;
    updateCheckedList: (sourceGuid: string, checked: boolean) => void;
    clearSelected: () => void;
    checkedIntegrations: Set<string>;
    view: string;
    selectAll: (sourceGuids: Array<string>) => void;
    showSnackBar: (snackBar: any) => void;
}

export const DataIntegrationsList = ({
    integrations,
    triggerForm,
    clearSearch,
    updateCheckedList,
    clearSelected,
    checkedIntegrations,
    selectAll,
    view,
    showSnackBar,
}: IDataIntegrationsList) => {
    const [showChat, setShowChat] = useState(false);
    const [chatData, setChatData] = useState({
        sourceGuid: null,
        systemGuid: null,
        name: null,
    });
    const sortedIntegrations = useMemo(() => {
        return integrations.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    }, [integrations]);

    const dispatch = useDispatch();
    const [collapseAll, setCollapseAll] = useState(false);
    const [checkAll, setCheckAll] = useState(checkedIntegrations.size === integrations.length);

    const {
        loggedInUserAppData: {
            data: { account },
        },
    } = useLoggedInUser();

    const handleSelectAll = () => {
        if (checkAll) {
            clearSelected();
        } else {
            selectAll(integrations.map((integration) => integration.sourceId));
        }
        setCheckAll(!checkAll);
    };

    useEffect(() => {
        setCheckAll(checkedIntegrations.size === integrations.length);
    }, [checkedIntegrations]);

    const openChat = (integration: IAskMe) => {
        setShowChat(() => false);
        // closeChat();
        setChatData({
            systemGuid: 'f16',
            sourceGuid: integration.sourceId,
            name: integration.name,
        });
        setShowChat(true);
    };

    const closeChat = () => {
        setShowChat(false);
        setChatData({
            systemGuid: null,
            sourceGuid: null,
            name: null,
        });
    };

    const handleDelete = async () => {
        let result;
        try {
            await dispatch(deleteAskMe([...checkedIntegrations]));
            clearSelected();
        } catch (err) {}
    };

    const handleEditIntegration = async (formMode: FormMode, integration: IAskMe, formType: FormTypes) => {
        try {
            const data = await dispatch(getAskMeById(integration.sourceId));
            triggerForm(formMode, { ...integration, ...data }, formType);
        } catch {
            showSnackBar({
                open: true,
                messageText: `Could not get ${integration.name}'s data.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    };

    const handleEditGuidedAnswers = async (formMode: FormMode, integration: IAskMe, formType: FormTypes) => {
        try {
            const data = await dispatch(getGuidedAnswers(integration.sourceId));
            triggerForm(formMode, { ...integration, guidedAnswers: data }, formType);
        } catch {
            showSnackBar({
                open: true,
                messageText: `Could not get ${integration.name}'s data.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    };

    const handleShowScanSummary = async (integration: IAskMe) => {
        try {
            const data = (await dispatch(getUrls(integration.sourceId))) as unknown as IScanSummary;
            triggerForm(FormMode.TABS, { ...integration, scanSummary: data }, FormTypes.SCAN_SUMMARY);
        } catch {
            showSnackBar({
                open: true,
                messageText: `Could not get ${integration.name}'s data.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    };

    return (
        <>
            {sortedIntegrations.length ? (
                (view === 'cards' && (
                    <DataIntegrationsListContainer>
                        {sortedIntegrations.map((integration) => {
                            return (
                                <React.Fragment key={integration.sourceId}>
                                    <IntegrationCard
                                        integration={integration}
                                        editIntegration={(integration) =>
                                            handleEditIntegration(FormMode.TABS, integration, jobTypeToFormType[integration.type])
                                        }
                                        editSourceSettings={(integration) =>
                                            handleEditIntegration(FormMode.ONE_PAGE, integration, FormTypes.SOURCE_SETTINGS)
                                        }
                                        editGuidedAnswers={(integration) =>
                                            handleEditGuidedAnswers(FormMode.ONE_PAGE, integration, FormTypes.GUIDED_ANSWERS)
                                        }
                                        showScanSummary={(integration) => handleShowScanSummary(integration)}
                                        isChecked={checkedIntegrations.has(integration.sourceId)}
                                        updateCheckedList={updateCheckedList}
                                        openChat={openChat}
                                        isChatOpen={showChat}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </DataIntegrationsListContainer>
                )) ||
                (view === 'table' && (
                    <DataIntegrationTableContainer>
                        <TableTopRow>
                            <TableHeaderContent>
                                <TableHeader>AI Knowledge Sources</TableHeader>
                                <AskMeCounter>{integrations.length}</AskMeCounter>
                            </TableHeaderContent>
                        </TableTopRow>
                        <CssIntegrationRow isHover={null}>
                            <IconContainer>
                                <CssArrowIcon src={UpDownArrow} collapsed={collapseAll} onClick={() => setCollapseAll(!collapseAll)} />
                                <CssCheckBox type='checkbox' onChange={handleSelectAll} checked={checkAll} />
                            </IconContainer>
                            <CssIntegrationData>
                                <HeaderNameContainer>Name</HeaderNameContainer>
                                <HeaderLastSyncContainer>Last Synced</HeaderLastSyncContainer>
                                <HeaderSyncStatusContainer>Sync Status</HeaderSyncStatusContainer>
                                <HeaderCreatedContainer>Created</HeaderCreatedContainer>
                            </CssIntegrationData>
                        </CssIntegrationRow>
                        {sortedIntegrations.map((integration) => {
                            return (
                                <React.Fragment key={integration.sourceId}>
                                    <IntegrationTableRow
                                        integration={integration}
                                        editIntegration={(integration) => triggerForm(FormMode.TABS, integration, FormTypes.CRAWLER)}
                                        editSourceSettings={(integration) =>
                                            triggerForm(FormMode.ONE_PAGE, integration, FormTypes.SOURCE_SETTINGS)
                                        }
                                        editGuidedAnswers={(integration) =>
                                            handleEditGuidedAnswers(FormMode.ONE_PAGE, integration, FormTypes.GUIDED_ANSWERS)
                                        }
                                        showScanSummary={(integration) => handleShowScanSummary(integration)}
                                        isChecked={checkedIntegrations.has(integration.sourceId)}
                                        updateCheckedList={updateCheckedList}
                                        openChat={openChat}
                                        isChatOpen={showChat}
                                        collapseAll={collapseAll}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <div>Table Footer</div>
                    </DataIntegrationTableContainer>
                ))
            ) : (
                <NoResult
                    image={'/assets/icons/idp-no-result.png'}
                    paddingTop={'138px'}
                    title={'No knowledge source found'}
                    content={''}
                    actionText={'Clear Search'}
                    onAction={clearSearch}
                />
            )}

            {checkedIntegrations.size > 0 && (
                <SelectIntegrationsActions
                    handleDelete={handleDelete}
                    selected={checkedIntegrations.size}
                    clearSelected={clearSelected}
                    openAssignSystem={() => triggerForm(FormMode.ONE_PAGE, null, FormTypes.ASSIGN_SYSTEMS)}
                />
            )}
            {showChat && (
                <ChatWrapper
                    msaGuid={account.guid}
                    name={chatData.name}
                    systemGuid={chatData.systemGuid}
                    sourceGuid={chatData.sourceGuid}
                    onClose={closeChat}
                />
            )}
        </>
    );
};

export default DataIntegrationsList;
