import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Form as FormStyles } from '@walkme-admin-center/libs/shared-styles';
import { FormActions as IFormActions } from '@walkme-admin-center/libs/types';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import CloseIcon from '@material-ui/icons/Close';
import { CustomClose, StyledTitle } from '../../styles/styles';
import { Roles, Systems, User, ResetEmailResponse, WMAccountIdp, AccountsSdk } from 'wm-accounts-sdk';
import { TextField, withStyles } from '@material-ui/core';
import { WMButton, WMButtonVariant, WMSelect, WMSnackbar, WMSnackbarVariant, WMTooltip } from '@walkme/wm-ui';
import {
    isIdpConfigured,
    useBusinessDomains,
    useActions,
    useLoggedInUser,
    getUsers,
} from '@walkme-admin-center/libs/state-management-users';
import { getUsersStatusMap, UserStatus } from '../../utils/users-status';
import UsersFormActions from './user-form-actions';
import { mapRoleToRow } from '../../utils/roles-rows';
import { PermissionsErrorkey } from '@walkme-admin-center/libs/common';
import { hasBusinessDomainOptions } from '../bulk-users-mutation.lib';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import GenerateUserTempPassword from '../users-temp-password';
import { useDispatch } from 'react-redux';
import { CssTextField, EnabledColorWMSelect, FieldRow, StyledLabel, StyledSecletSystemsWMSelect } from './users-form.styles';

interface SelectRow {
    value: string | number | boolean;
    label: string | JSX.Element;
    isDisabled?: boolean;
}

interface UserFormProps {
    user?: User | null;
    actions: IFormActions;
    systems?: Systems;
    roles: Roles;
    title?: string;
    accountIdps: WMAccountIdp[];
    submittingUser: boolean;
    deletingUser: boolean;
    idpId: string;
    shouldShowPassword: boolean;
    onResetUserMfaOptionsClicked: (userId: number) => void;
    resettingMfaOptions: boolean;
    isMfaEnabled: boolean;
}

export interface UserFormValues {
    editingUser: User | null;
    email: string;
    ssoID: string;
    firstName: string;
    lastName: string;
    attachToIdpId: SelectRow;
    role: SelectRow;
    systems?: SelectRow[];
    status: string;
    password?: string;
    hasBusinessDomain?: SelectRow;
    businessDomainId?: SelectRow;
}

const onSubmit = async (values) => {};

const emailRegex = /[\w-]+@([\w-]+\.)+[\w-]+/;
const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

const getInitialValues = (
    user: User,
    accountIdpsWithEmptyState: SelectRow[],
    userSystemsRows: SelectRow[],
    allAvailableRolesRows: SelectRow[],
    userStatus,
    hasBusinessDomainAvailableRows: SelectRow[],
    businessDomainsAvailableRows: SelectRow[]
) => {
    const attachToIdpId = get(user, 'attachedIdpId') || 'password';
    const userRole = user.role ? user.role.id : null;
    const values: UserFormValues = {
        editingUser: user,
        email: get(user, 'email', ''),
        ssoID: get(user, 'idpNameId', ''),
        firstName: get(user, 'firstName', ''),
        lastName: get(user, 'lastName', ''),
        role: userRole && allAvailableRolesRows.find((row) => row.value === userRole),
        attachToIdpId: accountIdpsWithEmptyState.find((item) => item.value === attachToIdpId),
        systems: userSystemsRows,
        status: userStatus ? userStatus.display : get(user, 'status', ''),
        password: null,
        hasBusinessDomain: hasBusinessDomainAvailableRows.find((item) => item.value === get(user, 'hasBusinessDomain', false)),
        businessDomainId: businessDomainsAvailableRows.find((item) => item.value === defaultTo(get(user, 'businessDomainId'), 0)),
    };

    return values;
};

export const UserForm = ({
    user,
    actions,
    systems,
    roles,
    title,
    idpId,
    submittingUser,
    deletingUser,
    accountIdps,
    shouldShowPassword,
    onResetUserMfaOptionsClicked,
    resettingMfaOptions,
    isMfaEnabled,
}: UserFormProps) => {
    const { t, rt, i18n } = useTranslation('general');
    const [usersStatusMap, setUsersStatusMap] = useState<UserStatus[]>(getUsersStatusMap(t));

    const dispatch = useDispatch();
    const { businessDomains } = useBusinessDomains();
    const activeAccountIdps = accountIdps && accountIdps.length > 0 ? accountIdps.filter((idp) => isIdpConfigured(idp)) : [];
    const userAttachedIdp =
        user.attachedIdpId && accountIdps && accountIdps.length > 0 ? accountIdps.find((idp) => idp.idpId === user.attachedIdpId) : null;
    if (userAttachedIdp && !activeAccountIdps.find((idp) => idp.idpId === userAttachedIdp.idpId)) {
        // in case the user already choose not configured idp
        activeAccountIdps.push(userAttachedIdp);
    }

    const required = (value) => (value ? undefined : t('common.required'));
    const emailValidation = (value: string) => (emailRegex.test(value) ? undefined : t('errors.invalid-email'));

    const userRole = user ? user.role : null;
    const isUserRoleExists = roles.find((role) => userRole && role.id === userRole.id);

    const allAvailableRoles = isUserRoleExists || !userRole ? roles : [...roles, userRole];

    const userSystemsRows: SelectRow[] = user.systems
        ? user.systems.map((system) => ({
              value: system.id,
              label: system.displayName,
          }))
        : [];
    const accountSystemsRows: SelectRow[] = systems
        ? systems.map((system) => ({
              value: system.id,
              label: system.displayName,
              isDisabled: system.restrictedAccess,
          }))
        : [];
    const accountIdpsWithEmptyState: SelectRow[] = activeAccountIdps.map((idp) => ({
        value: idp.idpId,
        label: idp.isOldIdpFlow ? t('users-and-roles-tab.users.edit-user-form.legacy-sso') : idp.idpName,
    }));
    const businessDomainsAvailableRows: SelectRow[] = businessDomains.map((domain) => ({ value: domain.id, label: domain.name }));
    accountIdpsWithEmptyState.push({
        value: 'password',
        label: isMfaEnabled
            ? t('users-and-roles-tab.users.edit-user-form.login-mfa')
            : t('users-and-roles-tab.users.edit-user-form.login-password'),
    });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const selectRef = React.useRef(null);
    const [actionError, setActionError] = useState<string>(null);
    const [actionSuccess, setActionSuccess] = useState<string>(null);
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
    const [generatePasswordLoading, setGeneratePasswordLoading] = useState(false);
    const [newGeneratedPassword, setNewGeneratedPassword] = useState('');
    const [userStatus, setUserStatus] = useState(user && usersStatusMap.find((mappedStatus) => mappedStatus.id == user.status));
    const { actionsAppData } = useActions();
    const { accountFeatureEnabled } = useLoggedInUser();
    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);

    const handleSnackBarErrorClose = (event, reason) => {
        setActionError('');
        setActionSuccess('');
    };

    const onPasswordGenerated = (userStatusMap: UserStatus[], tempPassword: string) => {
        setNewGeneratedPassword(tempPassword);
        setUserStatus(userStatusMap.find((mappedStatus) => mappedStatus.id == 'ACTIVE'));
    };

    useEffect(() => {
        setUsersStatusMap(getUsersStatusMap(t));
    }, [i18n.language]);

    const resetUserPassword = useCallback(async () => {
        try {
            setActionError('');
            setResetPasswordLoading(true);
            const response = await AccountsSdk.getInstance().resetPassword(user.id);
            setResetPasswordLoading(false);
            setActionSuccess(t('users-and-roles-tab.users.edit-user-form.success-message-reset-password'));
        } catch (e) {
            const error = (e.response && e.response.data && e.response.data.message) || e.message || t('errors.unspecified-error');
            setActionError(error === 'Forbidden resource' ? t(PermissionsErrorkey) : error);
        }
    }, [usersStatusMap]);

    const generateUserPassword = useCallback(async () => {
        try {
            setActionError('');
            setGeneratePasswordLoading(true);
            const response = await AccountsSdk.getInstance().setTemporaryPassword(user.id);
            onPasswordGenerated(usersStatusMap, response.tempPassword);
            setGeneratePasswordLoading(false);
        } catch (e) {
            setGeneratePasswordLoading(false);
            const error = (e.response && e.response.data && e.response.data.message) || e.message || t('errors.unspecified-error');
            setActionError(error === 'Forbidden resource' ? t(PermissionsErrorkey) : error);
        }
    }, [usersStatusMap]);

    const activateUser = useCallback(async () => {
        try {
            setActionError('');
            setGeneratePasswordLoading(true);
            const response = await AccountsSdk.getInstance().activateUser(user.id);
            onPasswordGenerated(usersStatusMap, response.tempPassword);
            setGeneratePasswordLoading(false);
            dispatch(getUsers(true));
        } catch (e) {
            setGeneratePasswordLoading(false);
            const error = (e.response && e.response.data && e.response.data.message) || e.message || t('errors.unspecified-error');
            setActionError(error === 'Forbidden resource' ? t(PermissionsErrorkey) : error);
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectRef.current != null) {
            setAnchorEl(selectRef.current);
        }
    });

    const isBackoffice =
        userRole &&
        user.role.permissions &&
        user.role.permissions.some(
            (permissionData) =>
                permissionData.actionKey === 'Backoffice' && (permissionData.permission === 'Write' || permissionData.permission === 'Read')
        );

    const isSSO = (values: UserFormValues) => values.attachToIdpId && values.attachToIdpId.value !== 'password';

    const validate = useCallback((values: UserFormValues) => {
        const errors: Record<string, string> = {};
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!user && shouldShowPassword && !values.password) {
            errors.password = 'Required';
        }
        if (!values.firstName) {
            errors.firstName = 'Required';
        }
        if (!values.lastName) {
            errors.lastName = 'Required';
        }
        return errors;
    }, []);

    const [hasBusinessDomainTranslatedOptions, setHasBusinessDomainTranslatedOptions] = useState(
        hasBusinessDomainOptions.map((option) => ({ ...option, label: t(option.label) }))
    );

    useEffect(() => {
        setHasBusinessDomainTranslatedOptions(hasBusinessDomainOptions.map((option) => ({ ...option, label: t(option.label) })));
    }, [i18n.language]);

    return (
        <>
            <WMSnackbar
                open={actionError || actionSuccess ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={actionError ? WMSnackbarVariant.Error : WMSnackbarVariant.Success}
                message={actionError || actionSuccess}
            />
            <FormStyles>
                <Form
                    onSubmit={onSubmit}
                    initialValues={getInitialValues(
                        user,
                        accountIdpsWithEmptyState,
                        userSystemsRows,
                        allAvailableRoles.map((role) => mapRoleToRow(rt, role, anchorEl, actionsAppData.data)),
                        userStatus,
                        hasBusinessDomainTranslatedOptions,
                        businessDomainsAvailableRows
                    )}
                    validateOnBlur
                    keepDirtyOnReinitialize
                    validate={validate}
                    render={({ submitError, handleSubmit, form, submitting, pristine, invalid, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '15px' }}>
                                    {title && (
                                        <StyledTitle id='form-title' style={{ marginLeft: '32px' }}>
                                            {title}
                                        </StyledTitle>
                                    )}
                                    <CustomClose onClick={() => actions.onCancel(pristine)}>
                                        <CloseIcon fontSize={'small'} />
                                    </CustomClose>
                                </div>
                                <div className='rows'>
                                    <Field name='email' validate={composeValidators(required, emailValidation)} type='email'>
                                        {({ input, meta }) => (
                                            <FieldRow>
                                                <StyledLabel>{t('common.email')} *</StyledLabel>
                                                <CssTextField
                                                    {...input}
                                                    type='text'
                                                    required
                                                    disabled={user != null}
                                                    error={meta.error && meta.touched}
                                                    variant='outlined'
                                                    helperText={meta.error && meta.touched ? meta.error : null}
                                                    inputProps={{ 'data-testid': 'email-input' }}
                                                />
                                            </FieldRow>
                                        )}
                                    </Field>
                                    {accountIdpsWithEmptyState && accountIdpsWithEmptyState.length > 0 && (
                                        <Field name='attachToIdpId' data-testid='attachedIdp-input'>
                                            {({
                                                input: { name, value, onChange, ...restInput },
                                                meta,
                                                label,
                                                formControlProps,
                                                ...rest
                                            }) => (
                                                <FieldRow>
                                                    <StyledLabel>{t('common.login-method')}</StyledLabel>
                                                    <EnabledColorWMSelect
                                                        id='outlined-select-attachedIdp'
                                                        name={name}
                                                        value={value}
                                                        maxMenuHeight={180}
                                                        itemsName={name}
                                                        onChange={onChange}
                                                        options={accountIdpsWithEmptyState}
                                                        isDisabled={user.userAccountTag === 'partner'}
                                                    />
                                                </FieldRow>
                                            )}
                                        </Field>
                                    )}

                                    {isSSO(values) && (
                                        <Field name='ssoID'>
                                            {({ input, meta }) => (
                                                <FieldRow>
                                                    <StyledLabel>{t('common.sso-id')}</StyledLabel>
                                                    <CssTextField
                                                        {...input}
                                                        error={false}
                                                        type='text'
                                                        variant='outlined'
                                                        inputProps={{ 'data-testid': 'ssoID-input' }}
                                                    />
                                                </FieldRow>
                                            )}
                                        </Field>
                                    )}

                                    <Field name='firstName' validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <FieldRow>
                                                <StyledLabel>{t('common.first-name')} *</StyledLabel>
                                                <CssTextField
                                                    {...input}
                                                    type='text'
                                                    required
                                                    error={meta.error && meta.touched}
                                                    variant='outlined'
                                                    helperText={meta.error && meta.touched ? meta.error : null}
                                                    inputProps={{ 'data-testid': 'firstName-input' }}
                                                />
                                            </FieldRow>
                                        )}
                                    </Field>

                                    <Field name='lastName' validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <FieldRow>
                                                <StyledLabel>{t('common.last-name')} *</StyledLabel>
                                                <CssTextField
                                                    {...input}
                                                    type='text'
                                                    required
                                                    error={meta.error && meta.touched}
                                                    variant='outlined'
                                                    helperText={meta.error && meta.touched ? meta.error : null}
                                                    inputProps={{ 'data-testid': 'lastName-input' }}
                                                />
                                            </FieldRow>
                                        )}
                                    </Field>

                                    <Field name='role' data-testid='role-input'>
                                        {({ input: { name, value, onChange, ...restInput }, meta, label, formControlProps, ...rest }) => (
                                            <FieldRow>
                                                <StyledLabel>{t('common.role')}</StyledLabel>
                                                <WMTooltip
                                                    condition={isBackoffice}
                                                    title={'Role change for backoffice users is not allowed.'}>
                                                    <div style={{ width: '100%' }} ref={selectRef}>
                                                        <EnabledColorWMSelect
                                                            id='outlined-select-role'
                                                            isDisabled={isBackoffice}
                                                            onChange={onChange}
                                                            value={value}
                                                            options={allAvailableRoles.map((role) =>
                                                                mapRoleToRow(rt, role, anchorEl, actionsAppData.data)
                                                            )}
                                                            maxMenuHeight={300}
                                                            menuPortalTarget={null}
                                                            isSearchable={false}
                                                        />
                                                    </div>
                                                </WMTooltip>
                                            </FieldRow>
                                        )}
                                    </Field>

                                    <Field name='systems' data-testid='systems-input'>
                                        {({ input: { name, value, onChange, ...restInput }, meta, label, formControlProps, ...rest }) => (
                                            <FieldRow>
                                                <StyledLabel>{t('common.systems')}</StyledLabel>
                                                <StyledSecletSystemsWMSelect
                                                    id='outlined-select-system'
                                                    maxMenuHeight={180}
                                                    allowSelectAll
                                                    groupValues
                                                    isMulti
                                                    isPopout
                                                    itemsName='systems'
                                                    multiValueSelectedText='picked'
                                                    onChange={onChange}
                                                    value={value}
                                                    options={accountSystemsRows}
                                                    disablePortal={true}
                                                    isSearchable={true}
                                                />
                                            </FieldRow>
                                        )}
                                    </Field>

                                    {user && (
                                        <Field name='status'>
                                            {({ input, meta }) => (
                                                <FieldRow>
                                                    <StyledLabel>{t('common.status')}</StyledLabel>
                                                    <WMTooltip title={userStatus && userStatus.info}>
                                                        <CssTextField
                                                            {...input}
                                                            error={false}
                                                            type='text'
                                                            disabled={user != null}
                                                            variant='outlined'
                                                            inputProps={{ 'data-testid': 'ssoID-input' }}
                                                        />
                                                    </WMTooltip>
                                                </FieldRow>
                                            )}
                                        </Field>
                                    )}
                                    {user?.status !== 'PROVISIONED' &&
                                        !user.attachedIdpId &&
                                        (isFeatureEnabled('noEmailActivationRequired') && !isSSO(values) ? (
                                            <FieldRow>
                                                <WMTooltip
                                                    title={t('users-and-roles-tab.users.edit-user-form.generate-temp-password-tooltip')}>
                                                    <WMButton
                                                        variant={WMButtonVariant.Text}
                                                        loading={generatePasswordLoading}
                                                        disabled={generatePasswordLoading}
                                                        onClick={() => {
                                                            generateUserPassword();
                                                        }}>
                                                        {t('buttons.generate-temp-password')}
                                                    </WMButton>
                                                </WMTooltip>
                                            </FieldRow>
                                        ) : (
                                            <FieldRow>
                                                <WMTooltip
                                                    title={t('users-and-roles-tab.users.edit-user-form.force-password-reset-tooltip')}>
                                                    <WMButton
                                                        variant={WMButtonVariant.Text}
                                                        loading={resetPasswordLoading}
                                                        disabled={resetPasswordLoading || actionSuccess ? true : false}
                                                        onClick={() => {
                                                            resetUserPassword();
                                                        }}>
                                                        {t('users-and-roles-tab.users.edit-user-form.force-password-reset')}
                                                    </WMButton>
                                                </WMTooltip>
                                            </FieldRow>
                                        ))}
                                    {user && !user.attachedIdpId && isMfaEnabled && (
                                        <FieldRow>
                                            <WMButton
                                                variant={WMButtonVariant.Text}
                                                loading={resettingMfaOptions}
                                                disabled={resettingMfaOptions}
                                                onClick={() => {
                                                    onResetUserMfaOptionsClicked(user.id);
                                                }}>
                                                {t('users-and-roles-tab.users.edit-user-form.reset-mfa')}
                                            </WMButton>
                                        </FieldRow>
                                    )}
                                    {isFeatureEnabled('lobEnabled') ? (
                                        <>
                                            <Field name='hasBusinessDomain' data-testid='hasBusinessDomain-input'>
                                                {({
                                                    input: { name, value, onChange, ...restInput },
                                                    meta,
                                                    label,
                                                    formControlProps,
                                                    ...rest
                                                }) => (
                                                    <FieldRow>
                                                        <StyledLabel>{t('common.default-view')}</StyledLabel>
                                                        <EnabledColorWMSelect
                                                            id='outlined-select-hasBusinessDomain'
                                                            name={name}
                                                            value={value}
                                                            maxMenuHeight={180}
                                                            itemsName={name}
                                                            onChange={onChange}
                                                            options={hasBusinessDomainTranslatedOptions}
                                                        />
                                                    </FieldRow>
                                                )}
                                            </Field>

                                            <Field name='businessDomainId' data-testid='businessDomain-input'>
                                                {({
                                                    input: { name, value, onChange, ...restInput },
                                                    meta,
                                                    label,
                                                    formControlProps,
                                                    ...rest
                                                }) => {
                                                    return (
                                                        <FieldRow>
                                                            <StyledLabel>{t('common.business-domain')}</StyledLabel>
                                                            <EnabledColorWMSelect
                                                                id='outlined-select-businessDomain'
                                                                name={name}
                                                                value={value}
                                                                maxMenuHeight={180}
                                                                itemsName={name}
                                                                onChange={onChange}
                                                                options={businessDomainsAvailableRows}
                                                            />
                                                        </FieldRow>
                                                    );
                                                }}
                                            </Field>
                                        </>
                                    ) : null}
                                </div>
                                <GenerateUserTempPassword
                                    onClose={() => {
                                        setNewGeneratedPassword('');
                                    }}
                                    isOpen={!!newGeneratedPassword}
                                    generatedPassword={newGeneratedPassword}
                                    email={user.email}
                                />
                                <UsersFormActions
                                    values={values}
                                    className='sideForm'
                                    actions={actions}
                                    submitting={submittingUser}
                                    deleting={deletingUser}
                                    pristine={pristine}
                                    invalid={invalid}
                                    saveActionName={
                                        user
                                            ? t('buttons.save')
                                            : shouldShowPassword
                                            ? t('buttons.create')
                                            : t('buttons.send-activation-email')
                                    }
                                    onUserActivation={userStatus.id !== 'ACTIVE' && !isSSO(values) && activateUser}
                                    userActivationLoading={generatePasswordLoading}
                                />
                            </form>
                        );
                    }}
                />
            </FormStyles>
        </>
    );
};

export default UserForm;
