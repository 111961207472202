import React from 'react';
import cc from 'classnames';
import styles from './DateList.module.scss';
import { DateRangeOption } from '../../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/filters/hooks/useDateRangeOptions';

interface DateListProps {
    ranges: DateRangeOption[];
    selectedRange: DateRangeOption;
    onSelect: (range: DateRangeOption) => void;
}

export const DateList = ({ ranges, selectedRange, onSelect }: DateListProps) => {
    return (
        <div className={styles.container}>
            {ranges.map((range) => (
                <button
                    key={range.label}
                    className={cc(styles.item, {
                        [styles.selected]:
                            range.value.fromDate === selectedRange.value.fromDate && range.value.toDate === selectedRange.value.toDate,
                    })}
                    onClick={() => onSelect(range)}>
                    {range.label}
                </button>
            ))}
        </div>
    );
};
