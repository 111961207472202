import useClientConfig from '@walkme-admin-center/../apps/home/src/redux/hooks/useClientConfig';

export const AppToUrlMapping = {
    dynamicsUii: `${window.clientConfig.NX_APP_URL_CONSOLE_MAIN}/uii`,
    editorBrowser: window.clientConfig.NX_APP_URL_EDITOR,
    editorElectron: 'walkme-editor://',
    consoleMain: window.clientConfig.NX_APP_URL_CONSOLE_MAIN,
    onboarding: window.clientConfig.NX_APP_URL_CONSOLE_MAIN,
    discovery: `${window.clientConfig.NX_APP_URL_CONSOLE_MAIN}/discovery`,
};
