import React, { useRef, useState } from 'react';
import {
    BoldText,
    CssExpanded,
    ExpandButtonContainer,
    Image,
    ImageContainer,
    StepContainer,
    SubtitleText,
    TitleContainer,
    TitleText,
    VideoStepNumber,
} from '../../../slack-form/steps/manifest-step/slack-steps/slack-steps-styles';
import { retriveTeamsLoginImage } from '@walkme-admin-center/libs/common/lib/icons';
import MinMaxIcon from 'packages/libs/common/assets/icons/data-integrations/min-max-size-icon.svg';
import styled from 'styled-components';
import { CssModal } from 'packages/pages/home/data-integrations/src/lib/data-integrations-page-style';
import { CloseIcon } from 'packages/pages/home/data-integrations/src/lib/common/consts';

const LinkStyle = styled.a`
    &:visited {
        color: #385feb;
    }
`;

const LoginStep = () => {
    const imageRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    const expandVideo = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <CssModal
                open={expanded}
                onClose={expandVideo}
                BackdropProps={{ onClick: () => {} }}
                children={
                    <CssExpanded>
                        <ImageContainer>
                            <Image className='expanded' src={retriveTeamsLoginImage()} ref={imageRef} />
                            <ExpandButtonContainer className='close' onClick={expandVideo}>
                                <img src={CloseIcon} />
                            </ExpandButtonContainer>
                        </ImageContainer>
                    </CssExpanded>
                }
            />
            <StepContainer>
                <TitleContainer>
                    <TitleText>
                        <VideoStepNumber>1.</VideoStepNumber>Log in to your
                        <BoldText>Azure account</BoldText>
                    </TitleText>
                    <SubtitleText>
                        Navigate to{' '}
                        <LinkStyle href='https://portal.azure.com' target='_blank' rel='noreferrer'>
                            Azure Portal
                        </LinkStyle>{' '}
                        and login
                    </SubtitleText>
                </TitleContainer>
                <ImageContainer>
                    <Image src={retriveTeamsLoginImage()} ref={imageRef} />
                    <ExpandButtonContainer className='expand' onClick={expandVideo}>
                        <img src={MinMaxIcon} />
                    </ExpandButtonContainer>
                </ImageContainer>
            </StepContainer>
        </>
    );
};

export default LoginStep;
