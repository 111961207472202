import React, { useEffect } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { PageTitle } from '@walkme-admin-center/libs/shared-styles';
import { StyledP, VerticalContentContainer, ImageContainer, SideContainer, StyledLink, ActionsContainer } from '../styles/styles';
import { useUserExtension } from '@walkme-admin-center/libs/state-management-extensions';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const Installation = () => {
    const { t } = useTranslation('general');
    const { extensionData } = useUserExtension();

    const createExtensionUrl = () => {
        const extGuid = extensionData.data && extensionData.data.guid;
        const url = `${window.clientConfig.NX_INSTALLATION_EXT_URL}${extGuid}?allInOne=true`;
        return url;
    };

    useEffect(() => {
        document.title = t('installation-tab.document-title');
    }, []);

    return (
        <>
            <PageTitle>{t('installation-tab.title')}</PageTitle>

            <Card style={{ boxShadow: 'none' }}>
                <CardContent>
                    <VerticalContentContainer>
                        <SideContainer className='padded'>
                            <h2>{t('installation-tab.walkme-extension')}</h2>
                            <StyledP>{t('installation-tab.extension-desc')}</StyledP>
                            <ImageContainer>
                                <img src='assets/browser-xt.png' alt='WalkMe Extension' />
                            </ImageContainer>
                            <ActionsContainer>
                                <StyledLink href={createExtensionUrl()} target='_blank'>
                                    {t('installation-tab.open-wiz-button')}
                                </StyledLink>
                            </ActionsContainer>
                        </SideContainer>
                    </VerticalContentContainer>
                </CardContent>
            </Card>
        </>
    );
};

export default Installation;
