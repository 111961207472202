import React from 'react';

import styled from 'styled-components';
import Search from '../search/search';
import Button from '../button/button';
import AddIcon from '@material-ui/icons/Add';
import Toolbar from '@material-ui/core/Toolbar';

export interface TableToolbarProps {
    handleInputChange?: (s: string) => void;
    title?: JSX.Element;
    data?: unknown[];
    query?: string;
    buttonText?: string;
    buttonImage?: string;
    onButtonClick?: () => void;
    customButton?: JSX.Element;
}
const ToolBarTitle = styled.div`
    flex-grow: 1;
`;

const StyledToolbar = styled(Toolbar)`
    justify-content: flex-end;
    padding: 30px;
`;

export const TableToolbar = ({ handleInputChange, title, query, buttonText, customButton, onButtonClick }: TableToolbarProps) => {
    return (
        <StyledToolbar>
            <ToolBarTitle>{title}</ToolBarTitle>
            <Search value={query} handleChangeFunc={handleInputChange} placeholder='Search...' />
            {!customButton && (
                <Button onClick={onButtonClick} customClass='main'>
                    <span className='iconContainer'>
                        <AddIcon style={{ color: 'white' }} />
                    </span>
                    <span>{buttonText}</span>
                </Button>
            )}
            {customButton}
        </StyledToolbar>
    );
};

TableToolbar.defaultProps = {
    title: '',
    buttonText: '',
};

export default TableToolbar;
