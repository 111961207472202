import { retrivePasteManifestVideo } from '@walkme-admin-center/libs/common';
import React from 'react';
import {
    StepContainer,
    TitleContainer,
    TitleText,
    BoldText,
    SubtitleText,
    VideoStepNumber,
    StyledLink,
    SubtitleTextContainer,
} from './slack-steps-styles';
import { useForm } from 'react-final-form';
import { ManifestTemplate } from '../manifest-template';
import VideoContainer from './video-container';

const PasteManifest = ({}) => {
    const formApi = useForm();
    const handleCopy = () => {
        const { name, guid } = formApi.getState().values;
        const template = new ManifestTemplate(name, guid).getTemplate();
        navigator.clipboard.writeText(JSON.stringify(template));
    };

    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>4.</VideoStepNumber>Paste<BoldText>Manifest Code</BoldText>
                </TitleText>
                <SubtitleTextContainer>
                    <SubtitleText>
                        <StyledLink onClick={handleCopy}>Click here</StyledLink> to copy walkme’s manifest code, and paste it instead of the
                        default code in the manifest setup window
                    </SubtitleText>
                </SubtitleTextContainer>
            </TitleContainer>
            <VideoContainer video={retrivePasteManifestVideo()} />
        </StepContainer>
    );
};

export default PasteManifest;
