import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { Button, MenuItem, Toolbar } from '@material-ui/core';
const logo = 'assets/walkme_logo.svg';

const {
    colors: {
        main: { mainBg, mainText },
        apps: { menuitemColor },
    },
    sizes: { drawerWidth },
} = styledTheme;

export const StyledAppBar = styled(AppBar)`
    min-height: 56px;
    max-height: 56px;
    background-color: ${mainBg};
    color: ${mainText};
    box-shadow: none !important;
    width: 100%;
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

export const StyledLogoContainer = styled(Button)`
    background-color: ${styledTheme.colors.main.mainBg};
    color: ${styledTheme.colors.main.mainText};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: none;

    &:hover {
        background-color: ${styledTheme.colors.main.mainBg};
    }

    .logo {
        width: 158px;
        height: 47px;
        background: url(${logo}) right no-repeat;
        background-size: 85%;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    padding: 0;
    margin: 0 5px 0 0;
    cursor: initial;
`;

export const StyledToolbar = styled(Toolbar)`
    min-height: 56px;
    padding-left: 0px;
    padding-right: 0px;

    .app-item {
        font-family: 'Poppins';
        flex-grow: 1;

        .app-name {
            margin: 0 4px 0 5px;
            color: ${menuitemColor};
        }

        .app-icon {
            width: 24px;
            height: 24px;
        }
    }
`;

export const StyledLeftMenu = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    margin-left: 10px;

    .MuiListItem-button:hover {
        background-color: transparent;
    }
`;

export const StyledCenterMenu = styled.div`
    display: flex;
    justify-content: flex-center;
    flex-grow: 1;

    .MuiListItem-button:hover {
        background-color: transparent;
    }
`;

export const StyledRightMenu = styled.div`
    border-left: 1px solid #dee6fc;
    height: 56px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
`;
