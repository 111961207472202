import * as React from 'react';
import { DialogActions, DialogContent, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CancelButton, SelectWrapper, StyledButton, StyledCustomMenuItem, StyledInputLabel } from '../../style';
import styled from 'styled-components';
import { Button } from '@walkme-admin-center/libs/ui-components';
import { OneId, Providers } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { Form, Field } from 'react-final-form';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { useState } from 'react';
import { sortProviders } from '../../helpers';
import { StyledDialog, StyledDialogActions, StyledSelect } from './one-id-dialog.styles';

const DeactivateButton = styled(Button)`
    border-radius: 20px;
    height: 40px;
    border-color: #e1e9f5;
    color: #eb3232;
    text-transform: none;
    background-color: #ffffff;
    box-shadow: none;

    &:hover {
        background-color: #fff5f5;
        box-shadow: none;
    }
`;

export interface IdpDialogProps {
    open: boolean;
    handleClose: () => void;
    onConfirm: (data: OneId) => void;
    deactivateOnId: () => void;
    providers: Providers;
    oneId: OneId;
}

export const OneIdDialog = ({ open, handleClose, providers, oneId, onConfirm, deactivateOnId }: IdpDialogProps) => {
    const [inProgress, setInProgress] = useState(false);
    const onClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleClose();
        }
    };
    const validate = (values) => {
        if (!values.oneIdProvider) {
            return {
                oneIdProvider: 'Required',
            };
        } else {
            return null;
        }
    };
    const initOneId = () => {
        return {
            oneIdProvider: oneId.oneIdProvider,
        };
    };
    const deactivate = () => {
        handleClose();
        deactivateOnId();
    };
    const save = async (values) => {
        setInProgress(true);
        try {
            await onConfirm({ oneIdProvider: values.oneIdProvider });
            handleClose();
        } catch (err) {
            setInProgress(false);
        }
    };

    return (
        <StyledDialog classes={{ container: 'container' }} open={open} onClose={onClose} style={{ minWidth: '500px', minHeight: '250px' }}>
            <div style={{ padding: '0 32px' }}>
                <DialogContent
                    style={{
                        padding: '12px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span style={{ fontSize: '18px', fontWeight: 600 }}>{'Activate OneID'}</span>
                    <IconButton aria-label='close' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogContent>
                <Divider />
            </div>
            <Form
                onSubmit={save}
                initialValues={initOneId()}
                validate={validate}
                keepDirtyOnReinitialize
                render={({ values, handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent style={{ padding: '20px 32px' }}>
                                <Field name={'oneIdProvider'}>
                                    {({ input: { name, value, onChange, ...restInput } }) => (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <StyledInputLabel>Select your preferred IDP for OneID</StyledInputLabel>
                                            <SelectWrapper>
                                                <StyledSelect
                                                    style={{ width: '100%' }}
                                                    MenuProps={{
                                                        className: 'one-id-select',
                                                        PopoverClasses: {
                                                            root: 'selectMenuItem',
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (!selected) {
                                                            return (
                                                                <span style={{ color: '#2F426C', opacity: 0.5 }}>Select IDP Vendor</span>
                                                            );
                                                        }
                                                        return providers.find((p) => p.id === selected).name;
                                                    }}
                                                    name={name}
                                                    value={value}
                                                    onChange={onChange}
                                                    variant={'outlined'}
                                                    inputProps={{ name: 'oneId' }}>
                                                    {sortProviders(providers, oneId).map((provider) => {
                                                        return (
                                                            <StyledCustomMenuItem disableRipple key={provider.id} value={provider.id}>
                                                                {provider.name}
                                                            </StyledCustomMenuItem>
                                                        );
                                                    })}
                                                </StyledSelect>
                                            </SelectWrapper>
                                        </div>
                                    )}
                                </Field>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '35px' }}>
                                    <WarningOutlinedIcon style={{ color: '#F7B500' }} />
                                    <span style={{ paddingLeft: '8px' }}>
                                        OneID will make the selected IDP vendor the default vendor for all the Systems in this Account. This
                                        can be modified or turned off.
                                    </span>
                                </div>
                            </DialogContent>
                            <StyledDialogActions
                                style={{ justifyContent: oneId.oneIdProvider ? 'space-between' : 'end' }}
                                classes={{ root: 'root' }}>
                                {oneId.oneIdProvider ? (
                                    <DeactivateButton type={'text'} onClick={deactivate}>
                                        Deactivate OneID
                                    </DeactivateButton>
                                ) : null}
                                <div>
                                    <CancelButton type={'text'} onClick={handleClose}>
                                        Cancel
                                    </CancelButton>
                                    <StyledButton
                                        className={'footer-button'}
                                        loading={inProgress}
                                        disabled={!form.getState().valid || !form.getState().dirty}
                                        type='submit'>
                                        Activate OneID
                                    </StyledButton>
                                </div>
                            </StyledDialogActions>
                        </form>
                    );
                }}
            />
        </StyledDialog>
    );
};

export default OneIdDialog;
