import styled from 'styled-components';

export const StyledQontoStepIcon = styled.div`
    &.root {
        background: #e4e9fc;
        color: #8d97ae;
        display: flex;
        border-radius: 50%;
        font-size: 12px;
        height: 24px;
        width: 24px;
        justify-content: center;
        align-items: center;
    }

    &.active {
        color: white;
        background: #385feb;
    }

    &.completed {
        background: #e4e9fc;
        border: 1px solid #3b61eb;
        height: 22px;
        width: 22px;
    }

    &.iconContainer {
        line-height: 2px;
    }
`;
