import React, { useState, MouseEvent, useRef } from 'react';
import { DropdownChevronDisplayOptions } from '@walkme/ui-core';
import {
    StyledDivider,
    StyledSelector,
    StyledMenuItem,
    ExpectedFormatSelectorDiv,
    UuidButtonText,
} from './expected-format-selector.styles';
import { DropdownOption } from './uuid.lib';
import { StyledUUIDMenu, StyledUUIDMenuWrapper, StyledWMMenu } from './uuid.styles';
import { Check } from '@walkme/ui-icons/large';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface ExpectedFormatProps {
    selectedExpectedFormat: DropdownOption;
    formatOptions: DropdownOption[];
    setSelectedExpectedFormat: (selectedFormat: DropdownOption) => void;
}

export function ExpectedFormat({ selectedExpectedFormat, formatOptions, setSelectedExpectedFormat }: ExpectedFormatProps) {
    const { t } = useTranslation('general');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const handleChangeSelection = (selectedFormat: DropdownOption) => {
        setSelectedExpectedFormat(selectedFormat);
        handleClose();
    };

    const SelectorDisplayedValue = (
        <ExpectedFormatSelectorDiv>
            <UuidButtonText>
                {selectedExpectedFormat
                    ? t(`systems-tab.new-system-form.configure-step.uuid.expected-format.options.${selectedExpectedFormat.label}`)
                    : ''}
            </UuidButtonText>
        </ExpectedFormatSelectorDiv>
    );

    const chevronDisplayOption = DropdownChevronDisplayOptions.DEFAULT;

    return (
        <div>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
                label={t(`systems-tab.new-system-form.configure-step.uuid.expected-format.main-title`)}
            />
            <StyledWMMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={300}>
                <StyledDivider />
                <div>
                    {formatOptions?.map((formatOption: DropdownOption, index) => {
                        return (
                            <StyledMenuItem
                                id={formatOption.value}
                                onClick={() => handleChangeSelection(formatOption)}
                                isSelected={formatOption.value === selectedExpectedFormat?.value}>
                                <span>
                                    {t(`systems-tab.new-system-form.configure-step.uuid.expected-format.options.${formatOption.label}`)}
                                </span>
                                {formatOption.value === selectedExpectedFormat?.value ? <Check color='#395eea' /> : null}
                            </StyledMenuItem>
                        );
                    })}
                </div>
            </StyledWMMenu>
        </div>
    );
}
