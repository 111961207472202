import { AppData } from '@walkme-admin-center/libs/types';
import { getSystemAppsConfigs, SystemAppsConfigsState } from '@walkme-admin-center/libs/state-management-systems';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export interface SystemAppConfig {
    name: string;
    createdAt: string; // ISO 8601 date string
    appId: string;
}

export const useSystemAppsConfigs = () => {
    const systemAppsConfigData: AppData<SystemAppConfig[]> = useSelector(
        (state: { systemAppsConfigsState: SystemAppsConfigsState }) => state.systemAppsConfigsState.systemAppsConfigs
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (dispatch) {
            dispatch(getSystemAppsConfigs());
        }
    }, []);

    return {
        systemAppsConfigData,
    };
};

export default useSystemAppsConfigs;
