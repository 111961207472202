import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import UrlSections from './url-sections';
import { useForm } from 'react-final-form';
import {
    Bold,
    EmptyMsgContainer,
    ExplainRowContainer,
    ExplainSubtitle,
    ExplainText,
    ExplainTextContainer,
    ExplainTitle,
    MainContainer,
    SmallIconButton,
} from './styles';
import { Export } from '@walkme/ui-icons';
import styled, { css } from 'styled-components';
import { Spacer, ZStack } from '../../../../common/style';
import { Box, Fade } from '@material-ui/core';
import { StarsIcon } from '../../../../data-integrations-list/integration-card/icons/icons';
import { useDispatch } from 'react-redux';
import { downloadScanSummaryReport } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { ScanSummaryType } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { EmptyState, IconButton } from '@walkme/ui-core';
import { CustomToolTip } from 'packages/pages/home/sso-configuration/src/lib/common/components/tool-tip/tool-tip';

const AiSummaryTextContainer = styled.div`
    flex: 1 0 0;
    color: var(--Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0.5rem;
`;

const UrlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
`;

const TabLabelContainer = styled.span`
    display: inline-flex;
    align-items: center;
    gap: var(--spacers-buttons-s-7, 0.4375rem);
`;

const SummaryButtonGroup = styled.div`
    display: flex;
    border-radius: var(--corner-radus-generation-cr-102, 0.125rem);
    border: 1px solid var(--Color-El-On-light-divider, #f0f3f9);
    background: var(--Primaries-Sky-1, #f0f3f9);
    margin-bottom: 6px;
`;

const SummaryButton = styled.button<{ active?: boolean }>`
    cursor: pointer;
    margin: auto;
    align-self: stretch;
    transition: color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease, stroke 0.3s ease;
    border-radius: var(--corner-radus-generation-cr-102, 0.125rem);
    border: none;
    height: var(--size-generation-s-8040, 40px);
    flex: 1 0 0;

    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 0.5rem;

    ${(props) =>
        props.active &&
        css`
            color: #385feb;
            background-color: #ffffff;

            svg [stroke] {
                stroke: #385feb;
            }
        `}

    ${(props) =>
        !props.active &&
        css`
            color: #6d81a6;
            background-color: #f0f3f9;

            svg [stroke] {
                stroke: #6d81a6;
            }
        `}
`;

const SummaryTabs = ({ children, onChange }: { children: React.ReactNode[]; onChange?: (value: number) => any }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <SummaryButtonGroup>
            {children.map((child, i) => (
                <SummaryButton
                    active={activeTab === i}
                    onClick={() => {
                        setActiveTab(i);
                        onChange(i);
                    }}
                    key={i}>
                    {child}
                </SummaryButton>
            ))}
        </SummaryButtonGroup>
    );
};

export const ScanDiffStep = () => {
    const formApi = useForm();
    const {
        values: { urls, aiSummary, sourceGuid },
    } = formApi.getState();

    const [viewMode, setViewMode] = useState('summary');
    const dispatch = useDispatch();

    const sections = [
        {
            title: 'New Pages',
            subtitle: 'Newly added webpages scanned',
            urls: urls?.added?.list,
            count: urls?.added?.count || 0,
            warningName: 'added',
        },
        {
            title: 'Modified Pages',
            subtitle: 'Updated webpages rescanned',
            urls: urls?.modified?.list,
            count: urls?.modified?.count || 0,
            warningName: 'modified',
        },
        {
            title: 'Removed Pages',
            subtitle: 'Pages removed from website and knowledge source',
            urls: urls?.removed?.list,
            count: urls?.removed?.count || 0,
            warningName: 'removed',
        },
    ];

    const hasDiff = sections.some((section) => section.count);

    return (
        <MainContainer>
            <ExplainText>Compare your latest scan with the previous one, highlighting new, modified, and removed pages.</ExplainText>
            {hasDiff ? (
                <>
                    <SummaryTabs onChange={(i) => setViewMode(i === 0 ? 'summary' : 'ai-summary')}>
                        <span>Summary</span>
                        <TabLabelContainer>
                            <StarsIcon />
                            <span>AI Summary</span>
                        </TabLabelContainer>
                    </SummaryTabs>
                    <ZStack alignItems='start'>
                        <Fade in={viewMode === 'summary'}>
                            <UrlsContainer>
                                <ExplainRowContainer>
                                    <ExplainTextContainer>
                                        <ExplainTitle>Scan summary</ExplainTitle>
                                        <ExplainSubtitle>
                                            <Bold>Note:</Bold>&nbsp;Tabs show up to 10 URLs. Click the export icon to view the full list if
                                            needed.
                                        </ExplainSubtitle>
                                    </ExplainTextContainer>
                                    <CustomToolTip placement={'top'} title='Export'>
                                        <IconButton
                                            variant='ghost'
                                            style={{ width: '24px', height: '24px' }}
                                            onClick={() => dispatch(downloadScanSummaryReport(sourceGuid, ScanSummaryType.LAST_SCAN_DIFF))}>
                                            <Export />
                                        </IconButton>
                                    </CustomToolTip>
                                </ExplainRowContainer>
                                <UrlSections sections={sections} />
                            </UrlsContainer>
                        </Fade>
                        <Fade in={viewMode === 'ai-summary'}>
                            <div>
                                <AiSummaryTextContainer>
                                    <ReactMarkdown>{aiSummary}</ReactMarkdown>
                                </AiSummaryTextContainer>
                            </div>
                        </Fade>
                    </ZStack>
                </>
            ) : (
                <EmptyMsgContainer>
                    <EmptyState
                        variant='emptySearchResults'
                        size='medium'
                        heading='No summary available for this scan'
                        description='You can review the Current URLs tab for details on successful and failed URLs'
                    />
                </EmptyMsgContainer>
            )}
        </MainContainer>
    );
};

export default ScanDiffStep;
