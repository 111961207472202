import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Field, Form, useForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { CssTextField } from '../data-integration-form-style';
import { Box, Collapse, Fade } from '@material-ui/core';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { guidedAnswerSchema } from '../services/validation/files copy/guided-answers-step-validation';
import { getErrorObject } from '../services/validation/validate';
import { IGuidedAnswer } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { CustomToolTipHover } from '../../common/components/custom-tool-tip';
import { CloseIcon, EditIconSmall, PlusIcon, ResetIcon, TrashIconSmall } from '../../data-integrations-list/integration-card/icons/icons';
import { CssIconButton } from '../../data-integrations-list/select-integrations-actions/select-integrations-actions';
import { FormApi } from 'final-form';
import IdpDialog, { IdpDialogProps } from 'packages/pages/home/sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import { EmptyState } from '@walkme/ui-core';
import { ResetButtonContainer } from '../data-integration-form-components/footers/footer-style';
import { useDispatch } from 'react-redux';
import { updateAskMeFormState } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { FieldArray } from 'react-final-form-arrays';
import { isEqual } from 'lodash';
import { StyledLink } from '../../common/style';

const GUIDED_ANSWER_LENGTH = 5000;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    &.contained {
        width: 100%;
    }
`;

const GuidedAnswersSubtitleContainer = styled.div`
    display: flex;
    padding: 1.25rem 2rem;
    border-bottom: 1px solid var(--LS-Color-El-On-light-divider, #f0f3f9);
    background: var(--LS-Color-Text-On-dark-primary-inverted, #fff);
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
`;

const GuidedAnswersSubtitleText = styled.p`
    margin: 0;
    flex: 1 0 0;
    color: var(--LS-Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const GuidedAnswerAddButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4375rem;
`;

const GuidedAnswerAddButtonEmptyState = styled(GuidedAnswerAddButton)`
    svg path {
        stroke: #385feb;
    }
`;

const AddButton = styled(WMButton)`
    height: 2.75rem;
    border-radius: 1.365rem;
    min-width: 4.5rem;
    stroke: #385feb;

    svg path {
        transition: stroke 0.3s ease;
    }

    &:not(.Mui-disabled) {
        .MuiButton-label {
            color: var(--LS-Color-Action-Content-Text-primary-default, #385feb);
        }

        svg path {
            stroke: #385feb;
        }
    }

    &.Mui-disabled {
        background-color: #fff;
        border-color: #76767617;

        .MuiButton-label {
            color: var(--LS-Color-Action-Content-On-light-Secondary-disable, rgba(118, 118, 118, 0.75));
        }

        svg path {
            stroke: var(--LS-Color-Action-Content-On-light-Secondary-disable, rgba(118, 118, 118, 0.75));
        }
    }
`;

const GuidedAnswerListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
    flex-grow: 1;
    padding: 1.5rem 2rem 0rem 2rem;
    background: var(--LS-Color-Text-On-dark-primary-inverted, #fcfdfe);
    overflow-y: auto;
`;

const GuidedAnswerContainer = styled.div<{ changed?: boolean }>`
    display: flex;
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #edf1fd);
    background-color: ${(props) => (props.changed ? `var(--Primaries-Sky-2, #F4F6FB)` : `var(--Primaries-Sky-6, #FFF)`)};
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
        border: 1px solid var(--ls-color-input-dropdown-stroke-primary-hover, #92a7f4);
    }
`;

const GuidedAnswerFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
`;

const GuidedAnswerFormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const GuidedAnswerFormTitle = styled.p`
    margin: 0;
    flex: 1 0 0;
    color: var(--ls-color-input-dropdown-content-content-filled, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const GuidedAnswerFormHeaderCloseContainer = styled.div`
    display: flex;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
`;

const GuidedAnswerFormFieldLabel = styled.p`
    margin: 0;
    color: var(--LS-Color-Text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const GuidedAnswerFormFieldContainer = styled.div`
    display: flex;
    min-height: 5.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--ls-spacers-generation-sp-104, 0.25rem);
    align-self: stretch;
`;

const GuidedAnswerFormDiscardButton = styled(WMButton)`
    padding: 0 1rem;
`;

const GuidedAnswerFormFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const GuidedAnswerFormFooterActions = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const GuidedAnswerViewContainer = styled.div`
    display: flex;
    flex: 1 0 0;
    align-items: center;
    gap: 0.75rem;
`;

const GuidedAnswerViewTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.25rem;
    flex: 1 0 0;
`;

const GuidedAnswerViewTextEntryContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const GuidedAnswerViewTextEntryLabel = styled.p`
    margin: 0;
    color: var(--LS-Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
`;

const GuidedAnswerViewText = styled.p`
    margin: 0;
    flex: 1 1 auto;
    color: var(--LS-Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 0; // required for text overflow ellipsis to work
`;

const GuidedAnswerViewActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const Divider = styled.div`
    width: 0.0625rem;
    height: 2rem;
    background: var(--LS-Color-El-On-light-divider, #f0f3f9);
`;

const EmptyStateContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    inset: 0;
    top: -1px;
    background-color: #fff;

    & > * {
        max-width: 26.25rem;
    }
`;

interface IGuidedAnswerForm {
    onSubmit: (values: IGuidedAnswer) => void;
    onDiscard: () => void;
    onDelete?: () => void;
    initialValues: IGuidedAnswer;
    mode?: 'new' | 'edit';
}

const GuidedAnswerForm = ({ onSubmit, onDiscard, onDelete, initialValues, mode = 'new' }: IGuidedAnswerForm) => {
    const [dialogProps, setDialogProps] = useState<IdpDialogProps | undefined>();

    const submit = (values, form: FormApi) => {
        setDialogProps(undefined);
        onSubmit(values);
        form.restart();
    };

    const discard = (form: FormApi) => {
        setDialogProps(undefined);
        onDiscard();
        form.restart();
    };

    const discardWithDialog = (form: FormApi) => {
        if (form.getState().dirty) {
            setDialogProps({
                open: true,
                handleClose: () => setDialogProps(undefined),
                confirm: () => discard(form),
                title: 'Unsaved changes',
                content: 'Changes you made will not be saved and published',
                type: 'defaultButton',
                buttonText: 'Confirm',
                cancelButtonText: 'Cancel',
            });
        } else {
            discard(form);
        }
    };

    return (
        <>
            {dialogProps && (
                <IdpDialog
                    open={dialogProps.open}
                    handleClose={dialogProps.handleClose}
                    confirm={dialogProps.confirm}
                    title={dialogProps.title}
                    content={dialogProps.content}
                    type={dialogProps.type ?? 'defaultButton'}
                    buttonText={dialogProps.buttonText ?? 'I understand, continue'}
                    cancelButtonText={dialogProps.cancelButtonText ?? 'Cancel'}
                />
            )}
            <Form
                onSubmit={submit}
                initialValues={initialValues}
                validate={(values) => getErrorObject(guidedAnswerSchema, values)}
                mutators={{
                    ...arrayMutators,
                    setFieldTouched: (args, state) => {
                        const [name] = args;
                        const field = state.fields[name];
                        if (field) {
                            field.touched = true;
                        }
                    },
                }}
                render={({ form }) => {
                    const { dirty, invalid } = form.getState();
                    return (
                        <>
                            <GuidedAnswerFormContainer>
                                <GuidedAnswerFormHeader>
                                    <GuidedAnswerFormTitle>{mode === 'edit' ? 'Edit Q&A' : 'Add Q&A'}</GuidedAnswerFormTitle>
                                    {mode === 'edit' && (
                                        <GuidedAnswerFormHeaderCloseContainer>
                                            <CustomToolTipHover placement='top' title='Close' arrow>
                                                <CssIconButton
                                                    className={'edit'}
                                                    size={'small'}
                                                    onClick={() => discardWithDialog(form)}
                                                    strokecolor='#6D81A6'
                                                    strokehovercolor='#385FEB'>
                                                    <CloseIcon />
                                                </CssIconButton>
                                            </CustomToolTipHover>
                                        </GuidedAnswerFormHeaderCloseContainer>
                                    )}
                                </GuidedAnswerFormHeader>
                                <GuidedAnswerFormFieldContainer>
                                    <GuidedAnswerFormFieldLabel>Question</GuidedAnswerFormFieldLabel>
                                    <Field name={'question'}>
                                        {({ input, meta }) => (
                                            <CssTextField
                                                variant='outlined'
                                                placeholder='Type your question here'
                                                fullWidth
                                                multiline
                                                minRows={3}
                                                maxRows={10}
                                                inputProps={{ maxLength: GUIDED_ANSWER_LENGTH }}
                                                error={!!meta.error && meta.touched}
                                                helperText={
                                                    (meta.touched && meta.error) || `${input.value.length} / ${GUIDED_ANSWER_LENGTH}`
                                                }
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </GuidedAnswerFormFieldContainer>
                                <GuidedAnswerFormFieldContainer>
                                    <GuidedAnswerFormFieldLabel>Answer</GuidedAnswerFormFieldLabel>
                                    <Field name={'answer'}>
                                        {({ input, meta }) => (
                                            <CssTextField
                                                variant='outlined'
                                                placeholder='Type your answer here'
                                                fullWidth
                                                multiline
                                                minRows={3}
                                                maxRows={10}
                                                inputProps={{ maxLength: GUIDED_ANSWER_LENGTH }}
                                                error={!!meta.error && meta.touched}
                                                helperText={
                                                    (meta.touched && meta.error) || `${input.value.length} / ${GUIDED_ANSWER_LENGTH}`
                                                }
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </GuidedAnswerFormFieldContainer>
                                <GuidedAnswerFormFooter>
                                    <GuidedAnswerFormFooterActions>
                                        {dirty && (
                                            <ResetButtonContainer onClick={() => form.restart()}>
                                                <ResetIcon />
                                                <span>Reset changes</span>
                                            </ResetButtonContainer>
                                        )}
                                    </GuidedAnswerFormFooterActions>
                                    <GuidedAnswerFormFooterActions>
                                        {mode === 'new' && (
                                            <GuidedAnswerFormDiscardButton
                                                variant={WMButtonVariant.Text}
                                                onClick={() => discardWithDialog(form)}>
                                                Discard
                                            </GuidedAnswerFormDiscardButton>
                                        )}
                                        {mode === 'edit' && (
                                            <>
                                                <CustomToolTipHover placement='top' title='Remove Q&A' arrow>
                                                    <CssIconButton
                                                        size={'small'}
                                                        onClick={onDelete}
                                                        strokecolor='#6D81A6'
                                                        strokehovercolor='#E91616'>
                                                        <TrashIconSmall />
                                                    </CssIconButton>
                                                </CustomToolTipHover>
                                                <Divider />
                                                <Box width='0.5rem' />
                                            </>
                                        )}
                                        <WMButton
                                            variant={WMButtonVariant.Primary}
                                            onClick={() => form.submit()}
                                            disabled={!dirty || invalid}>
                                            {mode === 'edit' ? 'Update Changes' : 'Add'}
                                        </WMButton>
                                    </GuidedAnswerFormFooterActions>
                                </GuidedAnswerFormFooter>
                            </GuidedAnswerFormContainer>
                        </>
                    );
                }}
            />
        </>
    );
};

interface IGuidedAnswerView {
    guidedAnswer: IGuidedAnswer;
    onSubmit: (values: IGuidedAnswer) => void;
    onDelete?: () => void;
    onFormOpen?: () => void;
    onFormClose?: () => void;
    disabled?: boolean;
    initialValues?: IGuidedAnswer;
}

const GuidedAnswerView = ({
    guidedAnswer,
    onSubmit,
    onDelete,
    onFormOpen,
    onFormClose,
    disabled = false,
    initialValues = undefined,
}: IGuidedAnswerView) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (isEditMode) {
            onFormOpen?.();
        } else {
            onFormClose?.();
        }
    }, [isEditMode]);

    return (
        <GuidedAnswerContainer
            changed={!isEditMode && !isEqual(guidedAnswer, initialValues)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Fade in={isEditMode}>
                <div>
                    <Collapse in={isEditMode}>
                        <GuidedAnswerForm
                            initialValues={guidedAnswer}
                            onSubmit={(values) => {
                                onSubmit(values);
                                setIsEditMode(false);
                            }}
                            onDiscard={() => setIsEditMode(false)}
                            onDelete={onDelete}
                            mode='edit'
                        />
                    </Collapse>
                </div>
            </Fade>
            <Fade in={!isEditMode}>
                <Collapse in={!isEditMode}>
                    <GuidedAnswerViewContainer>
                        <GuidedAnswerViewTextContainer>
                            <GuidedAnswerViewTextEntryContainer>
                                <GuidedAnswerViewTextEntryLabel>Q:</GuidedAnswerViewTextEntryLabel>
                                <GuidedAnswerViewText>{guidedAnswer.question}</GuidedAnswerViewText>
                            </GuidedAnswerViewTextEntryContainer>
                            <GuidedAnswerViewTextEntryContainer>
                                <GuidedAnswerViewTextEntryLabel>A:</GuidedAnswerViewTextEntryLabel>
                                <GuidedAnswerViewText>{guidedAnswer.answer}</GuidedAnswerViewText>
                            </GuidedAnswerViewTextEntryContainer>
                        </GuidedAnswerViewTextContainer>
                        <Fade in={isHovered}>
                            <GuidedAnswerViewActionsContainer>
                                <CustomToolTipHover placement='top' title='Edit Q&A' arrow>
                                    <CssIconButton
                                        className={'edit'}
                                        size={'small'}
                                        onClick={() => setIsEditMode(true)}
                                        strokecolor='#6D81A6'
                                        strokehovercolor='#385FEB'
                                        disabled={disabled}>
                                        <EditIconSmall />
                                    </CssIconButton>
                                </CustomToolTipHover>
                                <CustomToolTipHover placement='top' title='Delete Q&A' arrow>
                                    <CssIconButton
                                        size={'small'}
                                        onClick={onDelete}
                                        strokecolor='#6D81A6'
                                        strokehovercolor='#E91616'
                                        disabled={disabled}>
                                        <TrashIconSmall />
                                    </CssIconButton>
                                </CustomToolTipHover>
                            </GuidedAnswerViewActionsContainer>
                        </Fade>
                    </GuidedAnswerViewContainer>
                </Collapse>
            </Fade>
        </GuidedAnswerContainer>
    );
};

export const GuidedAnswersStep = ({ viewFlag }) => {
    const formApi = useForm();
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [isEmptyStateCleared, setIsEmptyStateCleared] = useState(false);
    const [dialogProps, setDialogProps] = useState<IdpDialogProps | undefined>();
    const dispatch = useDispatch();
    const {
        initialValues,
        values: { guidedAnswers },
        touched,
    } = formApi.getState();
    const isEmptyState = !isEmptyStateCleared && !isAddingNew && !guidedAnswers?.length && !touched?.guidedAnswers;

    useEffect(() => {
        setIsFormDisabled(isAddingNew);
    }, [isAddingNew]);

    useEffect(() => {
        dispatch(updateAskMeFormState({ isFooterDisabled: isFormDisabled }));
    }, [isFormDisabled]);

    return (
        <>
            {dialogProps && (
                <IdpDialog
                    open={dialogProps.open}
                    handleClose={dialogProps.handleClose}
                    confirm={dialogProps.confirm}
                    title={dialogProps.title}
                    content={dialogProps.content}
                    type={dialogProps.type ?? 'defaultButton'}
                    buttonText={dialogProps.buttonText ?? 'I understand, continue'}
                    cancelButtonText={dialogProps.cancelButtonText ?? 'Cancel'}
                />
            )}
            <Container className={!viewFlag && 'contained'}>
                <FieldArray name={'guidedAnswers'} isEqual={isEqual}>
                    {({ fields }) => (
                        <>
                            <Fade in={!isEmptyState}>
                                <>
                                    <GuidedAnswersSubtitleContainer>
                                        <GuidedAnswersSubtitleText>
                                            Supplement AI Answers with custom Q&As to address conflicting or missing information from your
                                            knowledge sources.&nbsp;
                                            <StyledLink
                                                to={{ pathname: 'https://support.walkme.com/knowledge-base/ai-center/' }}
                                                target='_blank'>
                                                Learn more
                                            </StyledLink>
                                        </GuidedAnswersSubtitleText>
                                        <AddButton
                                            variant={WMButtonVariant.Secondary}
                                            onClick={() => setIsAddingNew(true)}
                                            disabled={isFormDisabled}>
                                            <GuidedAnswerAddButton>
                                                <PlusIcon style={{ width: '1.25rem', height: '1.25rem' }} />
                                                <span>Add Q&A</span>
                                            </GuidedAnswerAddButton>
                                        </AddButton>
                                    </GuidedAnswersSubtitleContainer>
                                    <GuidedAnswerListContainer>
                                        <div>
                                            <Collapse in={isAddingNew}>
                                                <GuidedAnswerContainer>
                                                    <GuidedAnswerForm
                                                        initialValues={{ question: '', answer: '' }}
                                                        onSubmit={(values) => {
                                                            fields.push(values);
                                                            setIsAddingNew(false);
                                                        }}
                                                        onDiscard={() => setIsAddingNew(false)}
                                                        mode='new'
                                                    />
                                                </GuidedAnswerContainer>
                                            </Collapse>
                                        </div>
                                        {fields.value
                                            ?.slice()
                                            .reverse()
                                            .map((guidedAnswer: IGuidedAnswer | undefined, i: number) => {
                                                const index = fields.value.length - i - 1;
                                                return (
                                                    guidedAnswer && (
                                                        <GuidedAnswerView
                                                            guidedAnswer={guidedAnswer}
                                                            onSubmit={(values) => {
                                                                fields.update(index, values);
                                                            }}
                                                            onDelete={() =>
                                                                setDialogProps({
                                                                    open: true,
                                                                    handleClose: () => setDialogProps(undefined),
                                                                    confirm: () => {
                                                                        if (initialValues?.guidedAnswers?.[index]) {
                                                                            fields.update(index, null);
                                                                        } else {
                                                                            fields.remove(index);
                                                                        }
                                                                        setDialogProps(undefined);
                                                                        setIsFormDisabled(false);
                                                                    },
                                                                    title: 'Delete Q&A',
                                                                    content: 'Are you sure you want to delete this Q&A?',
                                                                    type: 'errorButton',
                                                                    buttonText: 'Delete',
                                                                    cancelButtonText: 'Cancel',
                                                                })
                                                            }
                                                            onFormOpen={() => setIsFormDisabled(true)}
                                                            onFormClose={() => setIsFormDisabled(false)}
                                                            disabled={isFormDisabled}
                                                            initialValues={initialValues?.guidedAnswers?.[index]}
                                                            key={i}
                                                        />
                                                    )
                                                );
                                            })}
                                    </GuidedAnswerListContainer>
                                </>
                            </Fade>
                            <Fade in={isEmptyState}>
                                <EmptyStateContainer>
                                    <EmptyState
                                        variant='emptySearchResults'
                                        size='medium'
                                        heading='No Q&A samples yet'
                                        description='Supplement AI Answers with custom Q&As to address conflicting or missing information from your knowledge sources.'
                                        firstButton={{
                                            variant: 'outlined',
                                            onClick: () => {
                                                setIsAddingNew(true);
                                                setIsEmptyStateCleared(true);
                                            },
                                            children: (
                                                <GuidedAnswerAddButtonEmptyState>
                                                    <PlusIcon style={{ width: '1.25rem', height: '1.25rem' }} />
                                                    <span>Add Q&A</span>
                                                </GuidedAnswerAddButtonEmptyState>
                                            ),
                                        }}
                                    />
                                </EmptyStateContainer>
                            </Fade>
                        </>
                    )}
                </FieldArray>
            </Container>
        </>
    );
};

export default GuidedAnswersStep;
