import { IHeaderText, IIntegrationType } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import CustomStepper from 'packages/pages/home/data-integrations/src/lib/common/components/custom-stepper';
import React from 'react';
import { ModalHeader, HeaderStepper, FormHeader, SubHeader, IconContainer, IconBox, BoxText, CloseButtonContainer } from './modal-style';
import { CloseIcon } from '../../../../common/consts';

export interface IIntegratopnFormHeader {
    formHeader: IHeaderText;
    activeStep: number;
    steps: number;
    onClose: () => void;
    icons: IIntegrationType[];
}
const ArrowIcon = 'assets/icons/data-integrations/A-Icon-Arrow-Uneven.svg';

const IntegrationFormHeader = ({ formHeader, activeStep, steps, onClose, icons }: IIntegratopnFormHeader) => {
    return (
        <ModalHeader>
            <HeaderStepper>
                <FormHeader>{formHeader.header}</FormHeader>
                <CustomStepper activeStep={activeStep} steps={steps} />
                <CloseButtonContainer onClick={onClose}>
                    <img src={CloseIcon} alt='Close' />
                </CloseButtonContainer>
            </HeaderStepper>
            <SubHeader>
                <div className={'sub-header-bold'}>Follow the instructions</div>
                <IconContainer>
                    {icons.map((integration: IIntegrationType, index: number, arr: IIntegrationType[]) => (
                        <React.Fragment key={index}>
                            <IconBox>
                                <img src={integration.icon} alt={integration.name} style={{ width: '16px', height: '16px' }} />
                                <BoxText>{integration.name}</BoxText>
                            </IconBox>
                            {index !== arr.length - 1 && <img src={ArrowIcon} style={{ height: '17px' }} />}
                        </React.Fragment>
                    ))}
                </IconContainer>
            </SubHeader>
        </ModalHeader>
    );
};

export default IntegrationFormHeader;
