import React, { useEffect } from 'react';
import {
    StyledContentContainer,
    StyledDeploymentMethodBody,
    StyledDeploymentMethodCardContainer,
    StyledDeploymentMethodContainer,
    StyledDeploymentMethodsWrapper,
    StyledDeploymentMethodTitles,
    StyledSubTitle,
    StyledLearnMoreLink,
} from './select-deployment.styles';
import { DeploymentMethods, getDeploymentOptions } from './select-deployment.lib';
import { CreateSystemCard } from '../purpose-and-usage/create-system-card';
import { useTranslation } from '../../../../../../../../../apps/home/src/localization/localizationBase';
import { useDispatch, useSelector } from 'react-redux';
import { CreateSystemState, createSystemSlice } from '@walkme-admin-center/libs/state-management-systems';
import { CompleteSystemCreationDialog } from '../select-contract/complete-system-creation-dialog';
import { ExtensionMethod } from './extension-method/extension-method';
import { SnippetMethod } from './snippet-method/snippet-method';
import { webSystemsExposureApi } from '@walkme-admin-center/libs/exposure-api';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useWebSystem } from '@walkme-admin-center/libs/state-management-extensions';
import { getTextJsx } from '../optional-configure/uuid/uuid.lib';

export const SelectDeploymentStep = () => {
    const { loggedInUserAppData } = useLoggedInUser();
    const { webSystemAppData } = useWebSystem(0);
    const [deploymentMethods] = getDeploymentOptions();
    const selectedDeploymentMethod = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedDeploymentMethod
    );

    const selectedSystemApp = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSystemApp.data
    );

    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const dispatch = useDispatch();
    const { t } = useTranslation('general');

    const onCardClick = (value: DeploymentMethods) => {
        dispatch(createSystemSlice.actions.setSelectedDeploymentMethod(value));
    };

    const setExtensionAsDefaultSelected = (isDefault: boolean) => {
        dispatch(createSystemSlice.actions.setExtensionAsDefaultSelected(isDefault));
    };

    const wayWalkMeShouldBeLoadedText = getTextJsx(
        t('systems-tab.new-system-form.deployment.step-sub-title.text'),
        'systems-tab.new-system-form.deployment.step-sub-title.links',
        t
    );

    const deploymentMethodBody = () => {
        switch (selectedDeploymentMethod) {
            case DeploymentMethods.EXTENSION:
                return <ExtensionMethod />;
            case DeploymentMethods.SNIPPET:
                return <SnippetMethod />;
            default:
                return null;
        }
    };

    const checkIfExtensionIsDefaultChoice = () => {
        if (selectedDeploymentMethod === null && localStorage.getItem('extensionIsDefaultDeploymentMethod')) {
            dispatch(createSystemSlice.actions.setSelectedDeploymentMethod(DeploymentMethods.EXTENSION));
            setExtensionAsDefaultSelected(true);
        }
    };

    useEffect(checkIfExtensionIsDefaultChoice, []);

    return (
        <StyledContentContainer scroll={false}>
            <StyledDeploymentMethodContainer>
                <StyledDeploymentMethodsWrapper>
                    <StyledDeploymentMethodTitles>
                        <div className='main-title'>{t('systems-tab.new-system-form.deployment.step-title')}</div>
                        <StyledSubTitle>
                            <div className='sub-title'>{wayWalkMeShouldBeLoadedText}</div>
                        </StyledSubTitle>
                    </StyledDeploymentMethodTitles>
                    <StyledDeploymentMethodCardContainer>
                        {deploymentMethods.options.map((method) => {
                            return (
                                <div style={{ width: '50%' }}>
                                    <CreateSystemCard
                                        title={method.title}
                                        subTitle={method.subTitle}
                                        value={method.value}
                                        selected={method.value === selectedDeploymentMethod}
                                        onCardClick={() => onCardClick(method.value)}
                                    />
                                </div>
                            );
                        })}
                    </StyledDeploymentMethodCardContainer>
                    <StyledDeploymentMethodBody>{deploymentMethodBody()}</StyledDeploymentMethodBody>
                </StyledDeploymentMethodsWrapper>
            </StyledDeploymentMethodContainer>
        </StyledContentContainer>
    );
};
