import styled from 'styled-components';

export const DataIntegrationsListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
    gap: 24px;
    overflow: auto;
    padding-bottom: 1rem;
`;

export const DataIntegrationTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    background: #fff;
`;

export const TableHeaderContent = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacers-global-sp-208, 8px);
    align-self: stretch;
`;

export const TableTopRow = styled.div`
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px 12px 0px 0px;
    border-bottom: 1px solid var(--background-background-main, #ebeff7);
    background: var(--white-white-main, #fff);
`;

export const TableHeader = styled.div`
    color: var(--typography-typography-main-primary-main, #2f426c);
    /* Texts/t1/walk-me-t1-3-br0 */
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`;

export const AskMeCounter = styled.div`
    display: flex;
    padding: 0px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--blue-primary-primary-tint-6, #e4e9fc);
    color: var(--blue-primary-primary-tint-1, #6c89f0);
    /* Texts/t4/walk-me-t4-br0 */
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
`;

export const HeaderNameContainer = styled.div`
    display: flex;
    width: 364px;
    height: var(--size-global-s-8040, 40px);
    padding: var(--spacers-global-sp-208, 8px) var(--spacers-global-sp-3012, 12px) 7px 7px;
    align-items: center;
    gap: var(--spacers-global-sp-208, 8px);
    flex-shrink: 0;
    color: var(--typography-typography-tint-1, #637191);
    /* Texts/t3/walk-me-t3-br0 */
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const HeaderLastSyncContainer = styled.div`
    display: flex;
    width: 180px;
    height: 44px;
    padding: 0px 12px;
    align-items: center;
    color: var(--typography-typography-tint-1, #637191);

    /* Texts/t3/walk-me-t3-br0 */
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const HeaderSyncStatusContainer = styled.div`
    display: flex;
    width: 180px;
    height: var(--size-global-s-8040, 40px);
    padding: var(--spacers-global-sp-208, 8px) var(--spacers-global-sp-3012, 12px) 7px var(--spacers-global-sp-3012, 12px);
    align-items: center;
    gap: var(--spacers-global-sp-208, 8px);
    flex-shrink: 0;
    color: var(--typography-typography-tint-1, #637191);
    /* Texts/t3/walk-me-t3-br0 */
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const HeaderCreatedContainer = styled.div`
    display: flex;
    width: 180px;
    height: var(--size-global-s-8040, 40px);
    padding: var(--spacers-global-sp-208, 8px) var(--spacers-global-sp-3012, 12px) 7px var(--spacers-global-sp-3012, 12px);
    align-items: center;
    gap: var(--spacers-global-sp-208, 8px);
    color: var(--typography-typography-tint-1, #637191);
    /* Texts/t3/walk-me-t3-br0 */
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;
