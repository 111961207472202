import { ContractsConfig } from '@walkme/accounts-contracts-ui';

export const contractsAdminCenterConfig: ContractsConfig = {
    columns: {
        summaryColumns: {
            hideColumns: ['End-Users Usage', 'Acquired', 'Actions'],
        },
        detailColumns: {
            hideColumns: ['End-user Usage', 'User Unique Identifier', 'System Status', 'Contract Systems Overuse'],
        },
    },
    tabs: {
        hideTabs: ['Expired'],
    },
    suppressMaxEndUsersViolationError: true,
    showContactUsFooter: true,
};
