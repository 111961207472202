import { Dialog, DialogActions } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
    .container {
        & .MuiPaper-root {
            min-width: 500px;
            max-width: 500px;
            min-height: 100px;
            border-radius: 12px;
        }
    }
`;

export const StyledDialogActions = styled(DialogActions)`
    &.root {
        height: 40px;
        padding: 16px 32px 32px 32px;
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
`;
