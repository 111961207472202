import React, { useCallback, useEffect, useRef, useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { DesktopTrackedApps } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

const ENTER_KEY_CODE = 'Enter';

const StyledContainer = styled.div`
    margin-bottom: 20px;
`;

const StyledInput = styled.input`
    color: ${styledTheme.colors.main.mainTitle};
    font-size: 2em;
    margin: 10px 0 10px 0;
    background: none;
    border: none;
    max-width: 400px;
    ::placeholder {
        opacity: 0.5;
    }
`;

const StyledEditOutlinedIcon = styled(EditOutlinedIcon)`
    color: ${styledTheme.colors.main.mainTitle};
    vertical-align: sub;
    cursor: pointer;
`;

const StyledError = styled.div`
    display: flex;
    flex: 1;
    width: 430px;
    color: ${styledTheme.colors.errorColor};
`;

const StyledReportProblemOutlinedIcon = styled(ReportProblemOutlinedIcon)`
    color: '${styledTheme.colors.errorColor}'
`;

interface SystemNameProps {
    systemName: string;
    systemId: number;
    allDesktopTrackedApps: DesktopTrackedApps;
    onChanged: (value: string) => void;
    setSystemNameValid: (isValid: boolean) => void;
}

const SystemName = ({
    systemName,
    systemId,
    allDesktopTrackedApps,
    onChanged,
    setSystemNameValid
}: SystemNameProps) => {
    const [allSystemNames, setAllSystemNames] = useState<{}>({});
    const [errorMessage, setErrorMessage] = useState<string>('');
    const inputEl = useRef(null);

    useEffect(() => {
        if (!systemId) {
            inputEl.current.focus();
        }
    }, []);

    useEffect(() => {
        const systemNames = allDesktopTrackedApps.reduce((systemNamesObj, desktopTrackedApp) => {
            if (desktopTrackedApp.systemId != systemId) {
                systemNamesObj[desktopTrackedApp.systemName] = true;
            }

            return systemNamesObj;
        }, {});

        setAllSystemNames(systemNames);

    }, [allDesktopTrackedApps]);

    useEffect(() => {
        if (systemName) {
            validateSystemName(systemName);
        }
    }, [allSystemNames]);

    const onSystemNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const systemNameValue = event.target.value
        validateSystemName(systemNameValue);

        onChanged(systemNameValue);
    }, [allSystemNames]);

    const validateSystemName = (systemNameValue: string) => {
        let errorMessage = '';
        let isValid = true;

        if (!systemNameValue) {
            errorMessage = 'System name must be provided'
            isValid = false;
        }

        if (allSystemNames[systemNameValue]) {
            errorMessage = 'System name is already taken';
            isValid = false;
        }

        setSystemNameValid(isValid);
        setErrorMessage(errorMessage);
    };

    const onKeyPress = (event) => {
        if (event.key === ENTER_KEY_CODE) {
            event.preventDefault();
            event.currentTarget.blur();
        }
    };

    return (
        <StyledContainer>
            <div className="card-section">
              <label>
                <StyledEditOutlinedIcon fontSize='large' />
                <StyledInput
                    ref={inputEl}
                    value={systemName}
                    onChange={onSystemNameChange}
                    onKeyPress={onKeyPress}
                    type="text"
                    placeholder="Enter App Name..."
                />
              </label>
            </div>
            {errorMessage && (
                <StyledError>
                    <StyledReportProblemOutlinedIcon />
                    <Typography>
                        {errorMessage}
                    </Typography>
                </StyledError>
            )}
        </StyledContainer>
    );
};

export default SystemName;
