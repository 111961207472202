import { Button } from '@material-ui/core';
import CustomCheckbox from 'packages/pages/home/data-integrations/src/lib/common/components/custom-checkbox';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const enum FilterStatusOptions {
    ALL = 'All',
    APPROVED = 'Approved',
    DENIED = 'Denied',
}

const DialogContainer = styled.div`
    width: 194px;
    height: 140px;
    overflow: hidden;
`;

const DialogContent = styled.div`
    display: flex;
    height: 24px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    &:hover {
        background: #e7eaef;
    }
    &:focus-within {
        border: 1px solid #385feb;
        background: #f4f6fb;
    }
    &.first {
        margin-top: 12px;
    }
`;

const DialogFooter = styled.div`
    display: flex;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`;

const StyledButton = styled(Button)`
    color: #385feb;
    border-radius: 40px;
`;

export const StatusIcon = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    align-self: center;
    &.approved {
        background: #41b7a6;
    }
    &.denied {
        background: #f44336;
    }
`;

const FilterDialog = ({ filter, changeFilter }) => {
    const [approved, setApproved] = useState(filter === FilterStatusOptions.ALL || filter === FilterStatusOptions.APPROVED);
    const [denied, setDenied] = useState(filter === FilterStatusOptions.ALL || filter === FilterStatusOptions.DENIED);

    const statusClicked = (status: string) => {
        switch (status) {
            case 'approved':
                if (!approved) {
                    setApproved(true);
                } else {
                    if (!denied) {
                        setDenied(true);
                    }
                    setApproved(false);
                }
                break;
            case 'denied':
                if (!denied) {
                    setDenied(true);
                } else {
                    if (!approved) {
                        setApproved(true);
                    }
                    setDenied(false);
                }
                break;
        }
    };

    const confirmChanges = () => {
        if (approved && denied) {
            changeFilter(FilterStatusOptions.ALL);
        } else if (approved) {
            changeFilter(FilterStatusOptions.APPROVED);
        } else if (denied) {
            changeFilter(FilterStatusOptions.DENIED);
        } else {
            changeFilter(FilterStatusOptions.ALL);
        }
    };

    return (
        <DialogContainer>
            <DialogContent className='first'>
                <CustomCheckbox checked={approved} onClick={() => statusClicked('approved')} />
                <StatusIcon className='approved' />
                <label>Approved</label>
            </DialogContent>
            <DialogContent>
                <CustomCheckbox checked={denied} onClick={() => statusClicked('denied')} />
                <StatusIcon className='denied'></StatusIcon>
                <label>Denied</label>
            </DialogContent>
            <DialogFooter>
                <StyledButton onClick={confirmChanges}>Apply</StyledButton>
            </DialogFooter>
        </DialogContainer>
    );
};

export default FilterDialog;
