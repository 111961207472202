import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const MessageContainer = styled.div`
    color: #78868F;
    display: flex;
    flex: 1;
    margin-bottom: 15px;
`;

const TextContainer = styled(Typography)`
    margin-left: 5px;
    font-size: 16px;
`;

const NoticeMessage = ({ children }) => {
    return (
        <MessageContainer>
            <ReportProblemOutlinedIcon className="inline-icon" />
            <TextContainer>
              { children }
            </TextContainer>
        </MessageContainer>
    );
};

export default NoticeMessage;
