import React, { useState } from 'react';
import { WMButton, WMButtonVariant, WMChip } from '@walkme/wm-ui';
import Box from '@material-ui/core/Box/Box';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { getTitleComponent } from '../../utils';
import Url from '../url/url';
import { useStyles } from './styles';

interface UrlsProps {
    envId: number;
    urls: string[];
    isRegexMode: boolean;
    setAreUrlsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setUrls: (newUrls: string[], envId: number) => void;
    shouldDisableParts: boolean;
    disabledTooltipMessage?: string;
    enableEmpty: boolean;
    showAddNewUrlComponentByDefault: boolean;
    isMultipleMatchers: boolean;
    onAddUrlClicked?: () => void;
    onAddNewUrlClosed?: () => void;
    onAddNewUrlsSaved?: () => void;
}

let urlValidityArray: boolean[];

export const Urls = ({
    envId,
    urls,
    isRegexMode,
    setAreUrlsValid,
    setUrls,
    shouldDisableParts,
    disabledTooltipMessage,
    enableEmpty,
    showAddNewUrlComponentByDefault,
    isMultipleMatchers,
    onAddUrlClicked,
    onAddNewUrlClosed,
    onAddNewUrlsSaved,
}: UrlsProps) => {
    const classes = useStyles();
    const [addUrlClicked, setAddUrlClicked] = useState(false);

    urlValidityArray = Array(urls.length).fill(true);
    const tooltipMessage = shouldDisableParts ? disabledTooltipMessage || '' : '';

    const onAddUrlClick = () => {
        setAddUrlClicked(true);
        onAddUrlClicked && onAddUrlClicked();
    };
    const addUrlControl = (
        <Tooltip title={tooltipMessage} placement='top'>
            <Box>
                <WMButton variant={WMButtonVariant.Text} onClick={onAddUrlClick} disabled={addUrlClicked || shouldDisableParts}>
                    Add Url
                </WMButton>
            </Box>
        </Tooltip>
    );

    const getUrlsTopBarControl = () => {
        const title = isMultipleMatchers ? 'Regex and URLs' : isRegexMode ? 'Regex' : 'URLs';
        const shouldShowUrlsAmount = isMultipleMatchers || !isRegexMode;
        const shouldShowAddUrlButton = showAddNewUrlComponentByDefault ? false : !isRegexMode || urls.length === 0;

        return (
            <Box display='flex'>
                <Box className={classes.urlsTopBar}>
                    {getTitleComponent(title)}
                    {shouldShowUrlsAmount ? <WMChip label={urls.length} rounded /> : null}
                </Box>
                {shouldShowAddUrlButton ? addUrlControl : null}
            </Box>
        );
    };

    const handleDelete = (index: number) => {
        const newValues: string[] = [...urls];
        newValues.splice(index, 1);
        urlValidityArray[index] = true;
        setUrls(newValues, envId);

        const areAllUrlsValid = urlValidityArray.every((isValid) => isValid);
        if (areAllUrlsValid) {
            setAreUrlsValid(true);
        }
    };

    const handleSave = (value: string, index?: number): void => {
        const newValues: string[] = [...urls];
        if (index !== undefined) {
            newValues[index] = value;
        } else {
            newValues.push(value);
            setAddUrlClicked(false);
            onAddNewUrlsSaved && onAddNewUrlsSaved();
        }

        setUrls(newValues, envId);
    };

    const handleUrlValidityChange = (isValid: boolean, index?: number): void => {
        if (isValid) {
            const amountOfInvalidUrls = urlValidityArray.filter((isValid) => !isValid).length;
            if (
                (index === undefined && amountOfInvalidUrls === 0) ||
                (index !== undefined && amountOfInvalidUrls === 1 && urlValidityArray[index] === false)
            ) {
                setAreUrlsValid(true);
            }
        } else {
            const areAllUrlsValid = urlValidityArray.every((isValid) => isValid);
            if (areAllUrlsValid) {
                setAreUrlsValid(false);
            }
        }

        if (index !== undefined) {
            urlValidityArray[index] = isValid;
        }
    };

    const onAddNewUrlClose = () => {
        setAddUrlClicked(false);
        onAddNewUrlClosed && onAddNewUrlClosed();
    };

    const newUrlControl = (
        <Url
            enableDelete={false}
            disabled={shouldDisableParts}
            value=''
            isRegex={isRegexMode}
            isNew={true}
            handleSave={handleSave}
            shouldValidateValue={true}
            enableNewUrlClose={!showAddNewUrlComponentByDefault}
            onAddNewUrlClose={onAddNewUrlClose}
        />
    );

    const urlsComponent = (
        <Box className={classes.urlsContainer}>
            {urls.map((url: string, index: number) => (
                <Url
                    key={`${url}_${index}`}
                    disabled={shouldDisableParts}
                    tooltipMessage={tooltipMessage}
                    index={index}
                    value={url}
                    isNew={false}
                    isRegex={isRegexMode}
                    handleDelete={handleDelete}
                    handleSave={handleSave}
                    setAreUrlsValid={handleUrlValidityChange}
                    enableDelete={enableEmpty ? true : urls.length > 1}
                    shouldValidateValue={!isMultipleMatchers}
                />
            ))}
        </Box>
    );

    return (
        <Box className={classes.root}>
            {getUrlsTopBarControl()}
            {addUrlClicked || showAddNewUrlComponentByDefault ? newUrlControl : null}
            {urlsComponent}
        </Box>
    );
};

export default Urls;
