/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { WMhttpClient } from '@walkme-admin-center/libs/http-service';
import {
    IAskMe,
    IAskMeById,
    IAskMeFormSubmit,
    IAsksMe,
    ICreateAskmeResponse,
    IExecute,
    IExecutionsStatus,
    IIntegration,
    IIntegrationFormSubmit,
    IIntegrations,
    IScanSummary,
    IUpdateAskmeResponse,
    IWhiteList,
    ScanSummaryType,
} from '../../../state-mangment-data-integration/src/lib/types';
import useClientConfig from '@walkme-admin-center/../apps/home/src/redux/hooks/useClientConfig';
import { IAIDashBoardResponse, IAiSettings, IDateRange } from '../../../state-mangment-data-integration/src/lib/ai-center/types';

const downloadFile = async (requestPromise, filename) => {
    try {
        const response = await requestPromise;

        // Create URL from the streaming response
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));

        // Trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Download failed:', error);
    }
};

class AskMeApi {
    private readonly httpClient: WMhttpClient;
    private readonly path: string;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
        this.path = 'ic/integration-server/ic-task-hive';
    }

    async getAskMe(): Promise<IAsksMe> {
        const { data } = await this.httpClient.instance.get<IAsksMe>(`${this.path}/askme`);
        return data;
    }

    async getAskMeById(sourceGuid: string): Promise<IAskMeById> {
        const { data } = await this.httpClient.instance.get<IAskMeById>(`${this.path}/askme/${sourceGuid}`);
        return data;
    }

    async createAskMe(body: IAskMeFormSubmit): Promise<ICreateAskmeResponse> {
        const { data } = await this.httpClient.instance.post<ICreateAskmeResponse>(`${this.path}/askme`, body);
        return data;
    }

    async updateAskMe(sourceGuid: string, body: IAskMeFormSubmit): Promise<IUpdateAskmeResponse> {
        const { data } = await this.httpClient.instance.put<IUpdateAskmeResponse>(`${this.path}/askme/${sourceGuid}`, body);
        return data;
    }

    async deleteAskMe(body: object): Promise<void> {
        await this.httpClient.instance.delete(`${this.path}/askme`, { data: body });
    }

    async assignSystems(body: object): Promise<void> {
        const { data } = await this.httpClient.instance.post<void>(`${this.path}/askme/assign-systems`, body);
        return data;
    }

    async executeAskMe(sourceGuid: string): Promise<IExecute> {
        const { data } = await this.httpClient.instance.post<IExecute>(`${this.path}/askme/${sourceGuid}/execute`, {});
        return data;
    }

    async pauseScan(sourceGuid: string): Promise<IExecute> {
        const { data } = await this.httpClient.instance.post<IExecute>(`${this.path}/askme/${sourceGuid}/pause`, {});
        return data;
    }

    async resumeScan(sourceGuid: string): Promise<IExecute> {
        const { data } = await this.httpClient.instance.post<IExecute>(`${this.path}/askme/${sourceGuid}/resume`, {});
        return data;
    }

    async stopScan(sourceGuid: string): Promise<IExecute> {
        const { data } = await this.httpClient.instance.post<IExecute>(`${this.path}/askme/${sourceGuid}/stop`, {});
        return data;
    }

    async getStatus(): Promise<IExecutionsStatus> {
        const { data } = await this.httpClient.instance.get<IExecutionsStatus>(`${this.path}/askme/execute/execution-status`);
        return data;
    }

    async getUrls(sourceGuid: string): Promise<IScanSummary> {
        const { data } = await this.httpClient.instance.get<IScanSummary>(`${this.path}/askme/urls/${sourceGuid}`);
        return data;
    }

    async revertScan(sourceGuid: string): Promise<IExecute> {
        const { data } = await this.httpClient.instance.post<IExecute>(`${this.path}/askme/${sourceGuid}/revert`);
        return data;
    }

    downloadScanSummaryReport(sourceGuid: string, type: ScanSummaryType): Promise<void> {
        const filename = type === ScanSummaryType.LAST_SCAN_DIFF ? 'Scan Summary.csv' : 'Scan URLs.csv';
        return downloadFile(
            this.httpClient.instance.get(`${this.path}/askme/diff-report/${sourceGuid}`, {
                responseType: 'stream',
                params: { type },
            }),
            filename
        );
    }

    async getWhiteList(): Promise<IWhiteList> {
        const { data } = await this.httpClient.instance.get<any>(`${this.path}/askme/settings/whiteList`);
        return data;
    }

    async createWhiteList(ipList: string): Promise<IWhiteList> {
        const { data } = await this.httpClient.instance.post<IWhiteList>(`${this.path}/askme/settings/whiteList`, { ipList });
        return data;
    }

    async updateWhiteList(id: number, ipList: string): Promise<IWhiteList> {
        const { data } = await this.httpClient.instance.put<IWhiteList>(`${this.path}/askme/settings/whiteList/${id}`, { ipList });
        return data;
    }
    //////////// Slack API /////////////

    // Integrations //
    async getAllIntegrations(): Promise<IIntegrations> {
        const { data } = await this.httpClient.instance.get<IIntegrations>(`${this.path}/integration`);
        return data;
    }

    async createIntegration(body: IIntegrationFormSubmit): Promise<IIntegration> {
        const { data } = await this.httpClient.instance.post<IIntegration>(`${this.path}/integration`, body);
        return data;
    }

    async deleteIntegration(id: string): Promise<void> {
        await this.httpClient.instance.delete(`${this.path}/integration/${id}`);
    }

    async getData(dateRange: IDateRange): Promise<IAIDashBoardResponse> {
        const response = await this.httpClient.instance.post<IAIDashBoardResponse>(`${this.path}/ai/dashboard`, dateRange);
        return response.data;
    }

    async getSettings(currentDateRange: IDateRange): Promise<IAiSettings> {
        const response = await this.httpClient.instance.post<IAiSettings>(`${this.path}/ai/settings`, currentDateRange);
        return response.data;
    }

    async getSignedUrls(files: File[], sourceId: string): Promise<void> {
        const { data } = await this.httpClient.instance.post<void>(`${this.path}/askme/file-scan/prepareUrls`, {
            files: files.map((file) => ({ name: file.name, size: file.size })),
            sourceId,
        });
        return data;
    }
}

export const askMeApi = new AskMeApi(new WMhttpClient(window.clientConfig.NX_WM_API_URL));

// export const askMeApi = new AskMeApi(new WMhttpClient('http://localhost:3232'));
