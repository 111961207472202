import styled from 'styled-components';

export const StyledTitleContainer = styled.div`
    margin-bottom: 24px;
`;

export const StyledPageTitle = styled.h1`
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 0px;
`;
export const StyledPageTitleLabel = styled.label`
    font-family: 'ProximaNova';
    color: #7680a3;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
`;

export const StyledContractsPage = styled.div`
    .MuiAccordionSummary-content {
        button {
            margin: 10px;
        }
    }
`;
