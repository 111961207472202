import React from "react";

export function DialogImage() {
    return <img
        data-testid="dialog-image"
        src={`/assets/icons/admin-dashboard.svg`}
        width="120px"
        height="120px"
    />;
}
