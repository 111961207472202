import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { WMIconClose, WMTextField } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';
import { ValidationResult, getMatchPatternValidationResultNew } from 'packages/pages/util/validation';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { DeleteIcon } from '../../../../systems/src/lib/create-system/assets/icons/delete';
import ConfirmationDialog from './confirmation-dialog';
import styled from 'styled-components';

const URL_MATCH_PATTERN_PLACEHOLDER = 'http://example.com/*';

type TextFieldStatus = 'default' | 'error';

const UrlMatchContainerDiv = styled.div`
    display: flex;

    .marked {
        background-color: #d8dffb;
    }
`;

interface URLMatchPatternProps {
    value: string;
    index?: number;
    isMatchingURLVerifier?: boolean;
    handleSave: (value: string, index: number) => void;
    handleDelete?: (index: number) => void;
    setAreUrlsValid?: (isValid: boolean, index: number) => void;
    handleClose?: () => void;
}

const URLMatchPattern = ({
    index,
    value,
    isMatchingURLVerifier,
    handleDelete,
    setAreUrlsValid,
    handleSave,
    handleClose,
}: URLMatchPatternProps) => {
    const [currentValue, setCurrentValue] = useState<string>(value);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const { t } = useTranslation('general');

    const urlValidationResult: ValidationResult = getMatchPatternValidationResultNew(currentValue, t);
    const textFieldStatus: TextFieldStatus = urlValidationResult.isValid ? 'default' : 'error';

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => setCurrentValue(event.target.value as string);

    setAreUrlsValid && setAreUrlsValid(urlValidationResult.isValid, index);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const saveIfValid = (): void => {
        if (urlValidationResult.isValid) {
            handleSave(currentValue, index);
        }
    };

    const onKeyPress = (event: KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            saveIfValid();
        }
    };

    const commonTextFieldProps = {
        value: currentValue,
        onChange: onValueChanged,
        helperText: urlValidationResult.errorMessage,
        placeholder: URL_MATCH_PATTERN_PLACEHOLDER,
        onKeyPress: onKeyPress,
        status: textFieldStatus,
    };

    const deleteConfirmationControl = (
        <ConfirmationDialog
            isOpen={showDeleteConfirmation}
            title={`Delete "${currentValue}"?`}
            message='Are you sure you want to delete this URL?'
            confirmButtonText='Delete'
            confirmButtonColor='danger'
            onCancel={() => setShowDeleteConfirmation(false)}
            onConfirm={() => {
                handleDelete(index!);
                setShowDeleteConfirmation(false);
            }}
        />
    );

    const deleteUrlControl = (
        <InputAdornment position='end' style={{ cursor: 'pointer' }}>
            <DeleteIcon onClick={() => setShowDeleteConfirmation(true)} />
        </InputAdornment>
    );

    const closeUrlControl = (
        <InputAdornment position='end' style={{ cursor: 'pointer' }}>
            <WMIconClose size={9} onClick={() => handleClose()} />
        </InputAdornment>
    );

    const getURLControl = (endAdornment: JSX.Element | null) => () => {
        return (
            <UrlMatchContainerDiv>
                <WMTextField
                    {...commonTextFieldProps}
                    style={{ marginTop: '10px' }}
                    ds2
                    InputProps={{
                        endAdornment,
                        classes: {
                            input: isMatchingURLVerifier ? 'marked' : undefined,
                        },
                    }}
                />
            </UrlMatchContainerDiv>
        );
    };

    const existingUrlComponent = getURLControl(handleDelete ? deleteUrlControl : null);
    const newUrlComponent = getURLControl(handleClose ? closeUrlControl : null);

    return (
        <>
            {deleteConfirmationControl}
            <ClickAwayListener onClickAway={saveIfValid}>
                {index === undefined ? newUrlComponent() : existingUrlComponent()}
            </ClickAwayListener>
        </>
    );
};

export default URLMatchPattern;
