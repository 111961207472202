import styled from 'styled-components';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { Collapse, Drawer, DrawerProps, List } from '@material-ui/core';
import { Tooltip } from '@walkme/ui-core';

interface StyledDrawerProps extends DrawerProps {
    $isExpanded: boolean;
    $isDocked: boolean;
}

export const StyledListItemText = styled(ListItemText)`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

export const StyledListItem = styled(Link)`
    height: 45px;
`;

export const StyledCollapse = styled(Collapse)`
    .MuiCollapse-wrapper {
        width: 100%;
        .MuiCollapse-wrapperInner {
            display: flex;
        }
    }
`;

export const StyledDrawer = styled(Drawer)<StyledDrawerProps>`
    flex-shrink: 0;
    background-color: rgb(8, 24, 51);
    width: ${(props) => (props.$isExpanded || props.$isDocked ? '244px' : '64px')};

    .paper {
        width: ${(props) => (props.$isExpanded || props.$isDocked ? '244px' : '64px')};
        border: none;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 1ms;
        z-index: 1;
        position: fixed; /* Stay in place */
        top: 56px; /* Stay at the top */
        left: 0;
        height: 100%;
        /* display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px; */
        background: rgb(8, 24, 51);

        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
`;

export const StyledMenuList = styled(List)<StyledDrawerProps>`
    color: white;
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding: 15px 12px;
    align-items: center;
    /* padding: 16px 10px 12px;
    gap: 4px;  */
    position: relative;
    //width: ${(props) => (props.$isExpanded || props.$isDocked ? '220px' : '40px')};
    //width: calc(100% - 20px);
    &hover {
        color: #b6c9fc;
    }
    .minimize-listItem {
        color: white;
        justify-content: center;
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;
        height: 40px;
        &:hover {
            background-color: #162457;
        }
        svg {
            color: #92a1bc;
        }
    }
    .expanded-listItem {
        color: white;
        justify-content: space-between;
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;
        height: 40px;
        &:hover {
            background-color: #162457;
        }
        svg {
            color: #92a1bc;
        }
    }

    .list-selected {
        background-color: #385feb;
        color: white;
        svg {
            color: white;
        }
    }

    .expanded-listItem-opened {
        color: white;
        background-color: #162457;
        justify-content: space-between;
        border-radius: 8px 8px 0 0;
        padding: 8px 10px;
        gap: 12px;
        width: 100%;
        height: 40px;
        &:hover {
            background-color: #162457;
        }
        svg {
            color: #92a1bc;
        }
    }

    .minimize-listItem-opened {
        color: white;
        background-color: #385feb;
        justify-content: center;
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;
        height: 40px;
        &:hover {
            background-color: #162457;
        }
        svg {
            color: white;
        }
    }
    .minimize-listItem-list-selected {
        justify-content: center;
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;
        height: 40px;
        &:hover {
            background-color: #162457;
        }
        background-color: #385feb;
        color: white;
        svg {
            color: white;
        }
    }

    .listItem-activated {
        background-color: #385feb;
        color: white;
        &:hover {
            background-color: #385feb;
            opacity: 0.9;
        }
        svg {
            color: white;
        }
    }

    .listItemText {
        color: white;
    }

    .menuItem {
        color: white;
        &:hover {
            background-color: black;
        }
    }
`;
export const StyledChildrenMenuList = styled(List)<StyledDrawerProps>`
    gap: 4px;
    display: flex;
    flex-direction: column;
    //width: ${(props) => (props.$isExpanded || props.$isDocked ? '244px' : '64px')};
    width: 100%;
    &hover {
        color: #b6c9fc;
    }
    .minimize-listItem {
        color: white;
        justify-content: center;
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;
        width: 40px;
        height: 40px;
        &:hover {
            background-color: #162457;
        }
        svg {
            color: #92a1bc;
        }
    }
    .expanded-listItem {
        color: white;
        justify-content: center;
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;
        height: 40px;
        &:hover {
            background-color: #1b2d6c;
        }
        svg {
            color: #92a1bc;
        }
    }
    .listItem-activated {
        background-color: #385feb;
        color: white;
        &:hover {
            background-color: #385feb;
            opacity: 0.9;
        }
        svg {
            color: white;
        }
    }

    .listItemText {
        color: white;
    }

    .menuItem {
        color: white;
        &:hover {
            background-color: black;
        }
    }
`;

export const StyledDockButton = styled.button<{ showDockIcon: boolean }>`
    position: fixed;
    top: 95;
    left: ${(props: { showDockIcon: boolean }) => (props.showDockIcon ? '233px' : '65px')};
    opacity: ${(props: { showDockIcon: boolean }) => (props.showDockIcon ? 1 : 0)};
    transition: left 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    z-index: 2;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    padding: 0;
    margin: 0 !important;
    cursor: pointer;

    &:hover {
        stroke: #ffffff;
    }
`;

export const StyledTooltip = styled(Tooltip)`
    display: flex;
    padding: 10px 4px;
`;

export const StyledTooltipContent = styled.span`
    display: flex;
    width: 336px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const StyledTooltipTitle = styled.span`
    color: var(--Color-Text-On-dark-primary-inverted, #fff);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const StyledTooltipContentText = styled.span`
    color: var(--Color-Text-On-dark-primary-inverted, #fff);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    align-items: flex-start;
    align-self: stretch;
`;

export const IconContainer = styled.div`
    height: 17px;
`;
