import React, { useEffect, useState } from 'react';
import { SideMenu } from './side-menu';
import { ConfigureContent } from './configure-content';
import { StyledConfigureContainer, ConfigureContentWrapper } from './configure.styles';
import { ConfigureTabs } from './configure.lib';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState, getExtensionStatus } from '@walkme-admin-center/libs/state-management-systems';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

export const ConfigureStep = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.configurationActiveTab);
    const saveAndPublishDialogIsOpen = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.saveAndPublishDialogIsOpen
    );

    const setActiveTab = (tab: number) => {
        dispatch(createSystemSlice.actions.setConfigurationActiveTab(tab));
    };

    useEffect(() => {
        dispatch(getExtensionStatus());
    }, []);

    return (
        <StyledConfigureContainer>
            <SideMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            <ConfigureContentWrapper>
                <ConfigureContent activeTab={activeTab} />
            </ConfigureContentWrapper>
        </StyledConfigureContainer>
    );
};
