import styled from 'styled-components';

export const StyledAccessibilityContainer = styled.div`
    height: 100%;
    padding: 0 32px;
    background-color: white;

    .accessibilityToaster {
        width: unset !important;
        box-shadow: none;
    }
`;

export const StyledAccessibilityTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 24px 0 24px 0;

    .main-title {
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 24px;
        font-weight: 600;
    }

    .sub-title {
        font-size: 14px;

        .action-row {
            display: flex;
            justify-content: space-between;
        }

        .learn-more {
            color: var(--Color-Semantic-Text-secondary, #637191);
            font-weight: 200;
            margin-top: 4px;

            a {
                color: var(--Blue-Primary-Primary-Main, #385feb) !important;
                text-decoration: none;
            }
        }
    }

    .automatic-enable-row {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        font-weight: 400;
    }
`;

export const StyledAccessibilityToasterMessage = styled.div`
    a {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;
