import styled from 'styled-components';
import { Dialog } from '@walkme-admin-center/libs/common';
import { WMChip, WMDataGrid, WMDialog } from '@walkme/wm-ui';
import { Menu, MenuItem, Typography } from '@walkme/ui-core';

export const StyledDialog = styled(Dialog)`
    background-color: white;
    width: 486px;
    padding-top: 0px;
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
    .ag-row-selected::before {
        background: #edf1fd !important;
    }
    .ag-theme-wm-ui .ag-body-viewport,
    .ag-theme-wm-ui .ag-center-cols-viewport {
        cursor: pointer;
        scrollbar-color: unset !important;
        scrollbar-width: unset !important;
    }
    .WMDataGridHeader-wmDataGridHeader {
        align-items: center;
        padding: 20px 32px;
    }
    .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-last-left-pinned {
        line-height: normal !important;
    }

    /* Header styles */
    & .ag-sort-indicator-icon.ag-sort-order {
        display: none;
    }

    & .WMSearchFilter-wmSearchFilter.WMSearchFilter-medium {
        max-width: 350px !important;
        display: flex;
        align-items: center;
    }

    /* ActionColumn styles */
    .WMButton-wmButton:hover {
        background-color: #3b61eb0d;
        color: #385feb;
    }
    .WMDataGridActionColumn-open {
        & .WMButton-wmButton {
            background-color: #3b61eb0d;
            color: #2b47ac;
        }
    }
`;

export const StyledGridTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTypography = styled(Typography)`
    color: var(--Color-Semantic-Text-secondary, #637191);
`;

export const StyledAppIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .system-type-key {
        margin-left: 10px;
    }

    img {
        width: 30px;
        height: 30px;
    }
`;

export const StyledAppNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`;

export const AutoCreateErrorText = styled.div`
    font-family: 'proximaNova';
    color: #eb3232;
    font-size: 18px;
    text-align: center;
`;

export const AutoCreateText = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 18px;
    text-align: center;
`;

export const SystemRowName = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 14px;
`;

export const SystemRowStatus = styled.div`
    margin-left: 6px;
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const SystemRowIdentifierMethod = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const NewChip = styled(WMChip)`
    background-color: #edf1fd !important;
    color: #3b61eb !important;
    border: 1px solid #3b61eb;
    margin-left: 5px;
`;

export const Grid = styled.div`
    height: 100%;
    width: 100%;
    --ag-selected-row-background-color: #edf1fd;
`;

export const Chip = styled(WMChip)`
    background-color: #e4e9fc !important;
`;

export const EnvChip = styled(WMChip)`
    background-color: #e4e9fc !important;
    margin-right: 6px;
`;

export const BusinessSolutionChip = styled(WMChip)`
    background-color: #f8ecf0 !important;
    margin-right: 6px;
    width: 50px;
`;

export const TaskChip = styled(WMChip)`
    background-color: #fbf7ea !important;
    margin-right: 6px;
    width: 50px;
`;

export const DeleteItemsButton = styled.button`
    height: 40px !important;
    width: 82px;
    border-radius: 40px !important;
    background-color: #eb3232 !important;
    &:hover {
        background-color: #b02626 !important;
    }
    &:active {
        background-color: #942020 !important;
    }
`;

export const DeletionDialog = styled(WMDialog)`
    width: 577px !important;
    background-color: white;
`;

export const DeletionDialogWithPlatform = styled(DeletionDialog)``;

export const DialogSubTitle = styled.div`
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 14px;
    padding-bottom: 24px;
`;

export const DialogText = styled.div`
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding-bottom: 20px;
`;

export const MultiLineMenuLabel = styled.div`
    display: flex;
    flex-direction: column;
    & > :not(:first-child) {
        font-size: 12px;
        color: #8d97ae;
    }
`;

export const MenuList = styled.div`
    &.WMMenu-list {
        padding: 8px 0 !important;
    }
    & .WMMenu-item {
        max-width: 244px;
    }
`;
