import { KeyboardArrowDownRounded, Done } from '@material-ui/icons';
import {
    FormMode,
    IAskMeIntegrationSystem,
    IAskMeIntegrationSystemForm,
    IIntegration,
    IIntegrationChannel,
    IIntegrationRulesForm,
    RuleType,
} from 'packages/libs/state-mangment-data-integration/src/lib/types';
import CustomToolTip, { toolTipUseStyles } from 'packages/pages/home/sso-configuration/src/lib/common/components/tool-tip/tool-tip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, FieldMetaState, useForm } from 'react-final-form';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { CloseIcon, RuleTypeOptions } from '../../../../../../common/consts';
import RuleTip from './rule-tip';
import {
    RulesContainer,
    BootstrapInput as SelectBootstrapInput,
    RuleContainer,
    ApprovalRuleTitle,
    ApprovalRuleSubTitle,
    RuleContainerWrapper,
    TooltipContent,
} from '../integration-manager-style';
import RuleTypeTips from './rule-type-tip';
import { Dialog, IconButton, Tooltip, withStyles } from '@material-ui/core';
import ChannelWarningDialog from '../channel-warning-dialog';
import { AddRuleButton, ChipStyled, InfoIcon, RuleSelectStyled, RulesMenuItem, RuleTextContainer, RuleTextField } from './rules-style';
import { checkRule } from '../helper';

const PlusIcon = 'assets/icons/data-integrations/plus-icon-no-border.svg';

export const CssIconButton = withStyles({
    root: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        padding: 0,
        '&:hover': {
            background: 'rgba(59, 97, 235, 0.05)',
        },
    },
})(IconButton);

interface IntegrationRulesProps {
    formMode: FormMode;
    integration: IIntegration;
}

const IntegrationRules = ({ formMode, integration }: IntegrationRulesProps) => {
    const deleteRuleText = `Are you sure you want to delete this rule? If you delete this rule, some ${integration.type} channels will lose their access to the knowledge source. you can always grant them access, manually, through the AI knowledge source table.`;
    const editRuleText = `Are you sure you want to edit this rule? If you edit this rule, some ${integration.type} channels will lose their access to the knowledge source. you can always grant them access, manually, through the AI knowledge source table.`;

    const [ruleIndexAction, setRuleIndexAction] = useState({
        index: -1,
        action: null,
    });
    const [editRuleDataDialog, setEditRuleDataDialog] = useState({
        show: false,
        confirm: null,
    });
    const [deleteRuleDataDialog, setDeleteRuleDataDialog] = useState({
        show: false,
        confirm: null,
    });

    const [dontShowAgainEditRuleDialog, setDontShowAgainEditRuleDialog] = useState(false);
    const [dontShowAgainDeleteRuleDialog, setDontShowAgainDeleteRuleDialog] = useState(false);

    const formApi = useForm();
    const integrationRulesApi = useFieldArray(`${integration.type}Rules`);
    const integrationSystemsApi = useFieldArray(`${integration.type}Systems`);
    const rulesContainerRef = useRef(null);

    const getRulesCount = useCallback(() => {
        let count = 0;
        formApi.getState().values[`${integration.type}Rules`].forEach((rule) => {
            if (rule.values) {
                count += rule.values.length;
            }
        });
        return count;
    }, [formApi.getState().values[`${integration.type}Rules`]]);

    const showDeleteIcon = useCallback(() => {
        if (formApi.getState().values[`${integration.type}Rules`].length > 1) {
            return true;
        }
    }, [formApi.getState().values[`${integration.type}Rules`]]);

    const handleRuleChange = async (
        event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
        ruleTypeIndex: number,
        rowPath: string
    ) => {
        if (event.key === 'Enter' || event.key === 'Backspace') {
            const allRules = integrationRulesApi.fields.value;
            const rule = allRules[ruleTypeIndex];
            if (rule.ruleText && event.key === 'Enter') {
                const ruleValue = formApi.getFieldState(`${rowPath}.values`);
                const ruleText = formApi.getFieldState(`${rowPath}.ruleText`);
                ruleValue.change([...ruleValue.value, rule.ruleText]);
                ruleText.change('');
                setRuleIndexAction({
                    index: ruleTypeIndex,
                    action: 'add',
                });
                handleNewRuleChip(rule.ruleText, rule.ruleType);
            } else if (!rule.ruleText && event.key === 'Backspace' && rule.values?.length) {
                const deleteAction = () => {
                    handleDeleteRuleChip(rowPath, rule.values.length - 1);
                    setRuleIndexAction({
                        index: ruleTypeIndex,
                        action: 'delete',
                    });
                };
                if (formMode === FormMode.STEPPER || dontShowAgainDeleteRuleDialog) {
                    deleteAction();
                } else {
                    setDeleteRuleDataDialog({
                        show: true,
                        confirm: () => {
                            setDeleteRuleDataDialog({ ...deleteRuleDataDialog, show: false, confirm: null });
                            deleteAction();
                        },
                    });
                }
            }
        }
    };

    useEffect(() => {
        const allRules = rulesContainerRef.current.getElementsByClassName('rule-text-field');
        if (ruleIndexAction.action === 'add' && ruleIndexAction.index !== -1) {
            allRules[ruleIndexAction.index].scrollLeft = allRules[ruleIndexAction.index].scrollWidth;
        }
    }, [ruleIndexAction]);

    const getRuleTypes = (currentValue: RuleType) => {
        const rulesOptions: Array<{ id: number; displayName: string; value: RuleType }> = [];
        rulesOptions.push(RuleTypeOptions.find((option) => option.value === currentValue));
        const rules = integrationRulesApi.fields.value.map((rule) => rule.ruleType);
        RuleTypeOptions.forEach((ruleOption) => {
            if (!rules.includes(ruleOption.value)) {
                rulesOptions.push(ruleOption);
            }
        });
        return rulesOptions;
    };

    const addType = () => {
        if (integrationRulesApi.fields.value?.length < 4) {
            const rules = integrationRulesApi.fields.value.map((rule) => rule.ruleType);
            for (const ruleTypeOption of RuleTypeOptions) {
                if (!rules.includes(ruleTypeOption.value)) {
                    integrationRulesApi.fields.push({
                        ruleType: ruleTypeOption.value,
                        ruleText: '',
                        values: [],
                    });
                    break;
                }
            }
        }
    };

    const findApprovedManually = () => {
        const channelsApprovedManually = [];
        if (formApi.getState().values[`${integration.type}Systems`]) {
            formApi.getState().values[`${integration.type}Systems`].forEach((slackChannel: IAskMeIntegrationSystem) => {
                const channelName = integration.slackChannels.find(
                    (channel: IIntegrationChannel) => channel.channelIntegrationGuid === slackChannel.channelGuid
                )?.channelName;
                const isApproved = formApi.getState().values[`${integration.type}Rules`].some(({ ruleType, values }) => {
                    return values?.some((value: string) => {
                        return checkRule(channelName, value, ruleType);
                    });
                });
                if (!isApproved) {
                    channelsApprovedManually.push(slackChannel);
                }
            });
        }
        return channelsApprovedManually;
    };

    const handleRuleTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, ruleTypePath: string) => {
        const channelsApprovedManually = findApprovedManually();
        formApi.change(`${ruleTypePath}.ruleType`, event.target.value);
        let ChannelsGuids = [];
        if (integration.slackChannels.length) {
            const updatedRulesRows = formApi.getState().values[`${integration.type}Rules`];
            integration.slackChannels.forEach((channel) => {
                let isOneOfRulesApplied = false;
                updatedRulesRows.forEach(({ ruleType, values }) => {
                    values?.forEach((rule: string) => {
                        if (checkRule(channel.channelName, rule, ruleType)) {
                            isOneOfRulesApplied = true;
                        }
                    });
                });
                if (isOneOfRulesApplied) {
                    ChannelsGuids.push({ channelGuid: channel.channelIntegrationGuid });
                }
            });
        }
        formApi.change(`${integration.type}Systems`, [...ChannelsGuids, ...channelsApprovedManually]);
    };

    const handleDeleteRuleRow = (index: number) => {
        const channelsApprovedManually = findApprovedManually();
        integrationRulesApi.fields.remove(index);
        let ChannelsGuids = [];
        if (integration.slackChannels.length) {
            const updatedRulesRows = formApi.getState().values[`${integration.type}Rules`];
            integration.slackChannels.forEach((channel) => {
                let isOneOfRulesApplied = false;
                updatedRulesRows.forEach(({ ruleType, values }) => {
                    values?.forEach((rule: string) => {
                        if (checkRule(channel.channelName, rule, ruleType)) {
                            isOneOfRulesApplied = true;
                        }
                    });
                });
                if (isOneOfRulesApplied) {
                    ChannelsGuids.push({ channelGuid: channel.channelIntegrationGuid });
                }
            });
        }
        formApi.change(`${integration.type}Systems`, [...ChannelsGuids, ...channelsApprovedManually]);
    };

    const handleDeleteRuleChip = (rowPath: string, chipIndex: number) => {
        const channelsApprovedManually = findApprovedManually();
        const chipsArray = formApi.getFieldState(`${rowPath}.values`);
        chipsArray.change([...chipsArray.value.slice(0, chipIndex), ...chipsArray.value.slice(chipIndex + 1)]);
        const allUpdatedRules = formApi.getState().values[`${integration.type}Rules`];
        let ChannelsGuids = [];
        if (integration.slackChannels.length) {
            integration.slackChannels.forEach((channel) => {
                let isOneOfRulesApplied = false;
                allUpdatedRules.forEach(({ ruleType, values }) => {
                    values?.forEach((rule: string) => {
                        if (checkRule(channel.channelName, rule, ruleType)) {
                            isOneOfRulesApplied = true;
                        }
                    });
                });
                if (isOneOfRulesApplied) {
                    ChannelsGuids.push({ channelGuid: channel.channelIntegrationGuid });
                }
            });
        }
        formApi.change(`${integration.type}Systems`, [...ChannelsGuids, ...channelsApprovedManually]);
    };

    const handleNewRuleChip = (newRule: string, ruleType: RuleType) => {
        if (integration.slackChannels.length) {
            integration.slackChannels.forEach((channel) => {
                let isOneOfRulesApplied = false;
                if (checkRule(channel.channelName, newRule, ruleType)) {
                    isOneOfRulesApplied = true;
                }
                if (isOneOfRulesApplied) {
                    const index = formApi.getState().values[`${integration.type}Systems`]
                        ? formApi
                              .getState()
                              .values[`${integration.type}Systems`].findIndex(
                                  (slackChannel: IAskMeIntegrationSystem) => slackChannel.channelGuid === channel.channelIntegrationGuid
                              )
                        : -1;
                    if (index === -1) {
                        integrationSystemsApi.fields.push({ channelGuid: channel.channelIntegrationGuid });
                    }
                }
            });
        }
    };

    const showEditRuleDialog = useCallback(() => {
        const closeDialog = () => {
            setEditRuleDataDialog({ ...editRuleDataDialog, show: false, confirm: null });
        };
        return (
            <Dialog
                open={editRuleDataDialog.show}
                onClose={() => closeDialog()}
                children={
                    <ChannelWarningDialog
                        confirm={() => {
                            editRuleDataDialog.confirm();
                            closeDialog();
                        }}
                        cancel={() => closeDialog()}
                        setShowDialogAgain={(value) => setDontShowAgainEditRuleDialog(value)}
                        text={editRuleText}
                        header={'Edit Rule?'}
                        buttonText={'Continue'}
                    />
                }
            />
        );
    }, [editRuleDataDialog]);

    const showDeleteRuleDialog = useCallback(() => {
        const closeDialog = () => {
            setDeleteRuleDataDialog({ ...deleteRuleDataDialog, show: false, confirm: null });
        };
        return (
            <Dialog
                open={deleteRuleDataDialog.show}
                onClose={() => closeDialog()}
                children={
                    <ChannelWarningDialog
                        confirm={() => {
                            deleteRuleDataDialog.confirm();
                            closeDialog();
                        }}
                        cancel={() => closeDialog()}
                        setShowDialogAgain={(value) => setDontShowAgainDeleteRuleDialog(value)}
                        text={deleteRuleText}
                        header={'Delete Rule?'}
                        buttonText={'Delete'}
                    />
                }
            />
        );
    }, [deleteRuleDataDialog]);

    const tooltipClasses = toolTipUseStyles();
    return (
        <>
            {showEditRuleDialog()}
            {showDeleteRuleDialog()}
            <RulesContainer>
                <div className={'flex-row'}>
                    <div className={'flex-column'}>
                        <ApprovalRuleTitle>
                            <div>Automatic Approval Rules</div>
                            <CustomToolTip title={<RuleTip integrationType={integration.type} />} placement='right' arrow={true}>
                                <InfoIcon src='assets/icons/data-integrations/info-icon-small.svg' alt='Info Icon' />
                            </CustomToolTip>
                        </ApprovalRuleTitle>
                        <ApprovalRuleSubTitle>All channels that meet any of the rules will be approved automatically.</ApprovalRuleSubTitle>
                    </div>
                    <CustomToolTip title={'Add Rule'} placement='top-end' arrow={true}>
                        <AddRuleButton src={PlusIcon} onClick={addType} />
                    </CustomToolTip>
                </div>

                <FieldArray name={`${integration.type}Rules`}>
                    {({ fields: rowFields }) => (
                        <RuleContainerWrapper ref={rulesContainerRef}>
                            {rowFields.map((row: string, index: number) => (
                                <RuleContainer key={index}>
                                    <span className={'channel-name'}>Channel Name</span>
                                    <Field name={`${row}.ruleType`}>
                                        {({
                                            input: ruleTypeInput,
                                            meta,
                                        }: {
                                            input: { name: string; value: RuleType };
                                            meta: FieldMetaState<unknown>;
                                        }) => (
                                            <RuleSelectStyled
                                                {...ruleTypeInput}
                                                renderValue={(value) => {
                                                    const found = RuleTypeOptions.find((option) => option.value === value);
                                                    return found.displayName;
                                                }}
                                                MenuProps={{
                                                    classes: {
                                                        paper: 'selectRuleMenuItem',
                                                    },
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                                onChange={(event) => {
                                                    const ruleTypeValues = formApi.getFieldState(`${row}.values`);
                                                    if (ruleTypeValues && ruleTypeValues.value?.length) {
                                                        if (formMode === FormMode.STEPPER) {
                                                            handleRuleTypeChange(event, row);
                                                        } else if (!dontShowAgainEditRuleDialog) {
                                                            setEditRuleDataDialog({
                                                                show: true,
                                                                confirm: () => {
                                                                    handleRuleTypeChange(event, row);
                                                                },
                                                            });
                                                        } else {
                                                            handleRuleTypeChange(event, row);
                                                        }
                                                    } else {
                                                        formApi.change(`${row}.ruleType`, event.target.value);
                                                    }
                                                }}
                                                IconComponent={KeyboardArrowDownRounded}
                                                variant={'outlined'}
                                                input={<SelectBootstrapInput className='ruleSelect' />}>
                                                {getRuleTypes(ruleTypeInput.value).map((option, index) => {
                                                    return (
                                                        <RulesMenuItem
                                                            style={{ width: '300px' }}
                                                            autoFocus={false}
                                                            disableRipple
                                                            key={index}
                                                            value={option.value}>
                                                            <Tooltip
                                                                classes={{
                                                                    tooltip: tooltipClasses.tooltip,
                                                                    arrow: tooltipClasses.arrow,
                                                                }}
                                                                title={<RuleTypeTips ruleType={option.value} />}
                                                                placement='right-start'
                                                                arrow={true}>
                                                                <TooltipContent>
                                                                    <span>{option.displayName}</span>
                                                                    {option.value === ruleTypeInput.value && <Done fontSize={'medium'} />}
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </RulesMenuItem>
                                                    );
                                                })}
                                            </RuleSelectStyled>
                                        )}
                                    </Field>
                                    <Field name={`${row}.ruleText`}>
                                        {({
                                            input: ruleTextInput,
                                            meta,
                                        }: {
                                            input: { name: string; value: string };
                                            meta: FieldMetaState<unknown>;
                                        }) => (
                                            <RuleTextContainer className={`rule-text-field ${getRulesCount() >= 5 ? 'disabled' : ''}`}>
                                                <RuleTextField
                                                    {...ruleTextInput}
                                                    disabled={getRulesCount() >= 5}
                                                    onKeyDown={(event) => handleRuleChange(event, index, row)}
                                                    placeholder={'Type Here'}
                                                    startAdornment={
                                                        <FieldArray name={`${row}.values`}>
                                                            {({ fields: rulesChips }) => (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                    {rulesChips.map((chip, chipIndex) => (
                                                                        <Field name={chip} key={chipIndex}>
                                                                            {({
                                                                                input: chipInput,
                                                                                meta,
                                                                            }: {
                                                                                input: { name: string; value: string };
                                                                                meta: FieldMetaState<unknown>;
                                                                            }) => (
                                                                                <ChipStyled
                                                                                    className='chip'
                                                                                    key={chipIndex}
                                                                                    label={chipInput.value}
                                                                                    onDelete={() => {
                                                                                        if (
                                                                                            formMode === FormMode.STEPPER ||
                                                                                            dontShowAgainDeleteRuleDialog
                                                                                        ) {
                                                                                            handleDeleteRuleChip(row, chipIndex);
                                                                                        } else {
                                                                                            setDeleteRuleDataDialog({
                                                                                                show: true,
                                                                                                confirm: () => {
                                                                                                    handleDeleteRuleChip(row, chipIndex);
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    deleteIcon={
                                                                                        <img
                                                                                            src={CloseIcon}
                                                                                            style={{
                                                                                                height: '10px',
                                                                                                width: '10px',
                                                                                            }}
                                                                                            alt='Close'
                                                                                        />
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    }
                                                />
                                            </RuleTextContainer>
                                        )}
                                    </Field>
                                    {showDeleteIcon() && (
                                        <CustomToolTip title={'Delete'} placement='top' arrow={true}>
                                            <CssIconButton
                                                className='icon'
                                                style={{ marginLeft: '8px' }}
                                                disableRipple
                                                disableTouchRipple
                                                disableFocusRipple
                                                onClick={() => {
                                                    const ruleTypeValues = formApi.getFieldState(`${row}.values`);
                                                    if (ruleTypeValues && ruleTypeValues.value?.length) {
                                                        if (formMode === FormMode.STEPPER) {
                                                            handleDeleteRuleRow(index);
                                                        } else if (!dontShowAgainDeleteRuleDialog) {
                                                            setDeleteRuleDataDialog({
                                                                show: true,
                                                                confirm: () => {
                                                                    handleDeleteRuleRow(index);
                                                                },
                                                            });
                                                        } else {
                                                            handleDeleteRuleRow(index);
                                                        }
                                                    } else {
                                                        rowFields.remove(index);
                                                    }
                                                }}>
                                                <img src={'assets/icons/data-integrations/trash-icon-small.svg'} alt='Delete' />
                                            </CssIconButton>
                                        </CustomToolTip>
                                    )}
                                </RuleContainer>
                            ))}
                        </RuleContainerWrapper>
                    )}
                </FieldArray>
            </RulesContainer>
        </>
    );
};

export default IntegrationRules;
