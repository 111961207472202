export interface Field {
    dataType: string;
    name: string;
    value?: string | number | boolean | Array<string | number | null> | null;
    suggestDataType?: 'String' | 'Date' | 'Number' | 'Boolean' | 'Array';
    alias: string;
    disabledToChangeType?: boolean;
}

export interface FieldsFailedResponse {
    success: boolean;
    code: number;
    message: string;
    reason?: object;
    data?: object;
}

export declare type Fields = Field[];

export interface FieldOption {
    key: string;
    value: string;
    label: string;
}

export declare type FieldOptions = FieldOption[];
