import React from 'react';
import styled from 'styled-components';
import { PageTitle } from '@walkme-admin-center/libs/shared-styles';
import Tooltip from 'packages/libs/ui-components/src/lib/tooltip/Tooltip';

const InfoIcon = styled.img`
    margin-left: 5px;
    height: 15px;
    width: 15px;
`;

const StyledPageTitle = styled(PageTitle)`
    display: inline-block;
    margin-bottom: 20px;
`;

const DesktopTrackedAppTitle = () => {
    return (
        <>
            <StyledPageTitle>Desktop Apps</StyledPageTitle>
            <Tooltip 
                maxWidth={200}
                placement="right"
                title="Desktop Apps are only used for Tracking Usage data and not for building content"
            >
                <InfoIcon src="assets/icons/icon-info.svg" alt="" />
            </Tooltip>
        </>
    );
};

export default DesktopTrackedAppTitle;