import React from 'react';
import { IdpSystems, ProviderConfig } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { IconButton } from '@walkme/ui-core';
import { Copy } from '@walkme/ui-icons';
import { CONSTS } from '../../../common/consts';
import {
    Header,
    TooltipProviderAttribute,
    ProviderInfo,
    StyledProviderHeader,
    StyledProviderProtocolHeader,
    StyledDefaultIdpHeader,
    StyledChip,
} from './provider-header-style';
import CustomToolTip from '../../../common/components/tool-tip/tool-tip';
import { useTranslation } from 'apps/home/src/localization';
import { TFunction } from 'i18next';

export interface ProviderHeaderProps {
    name: string;
    config: ProviderConfig;
    providerSystems: IdpSystems;
    isDefault: boolean;
}

const TextWithCopy = ({ name, value, copy }: { name: string; value?: string; copy?: boolean }) => {
    return (
        <TooltipProviderAttribute>
            <span>
                {name}: {value}
            </span>
            {copy && (
                <IconButton
                    color='white'
                    size='small'
                    variant='ghost'
                    onClick={(event) => {
                        event.stopPropagation();
                        navigator.clipboard.writeText(value);
                    }}>
                    <Copy />
                </IconButton>
            )}
        </TooltipProviderAttribute>
    );
};

function getTooltipContent(config: ProviderConfig, t: TFunction): JSX.Element {
    const defaultPapi = window.clientConfig.NX_WM_PAPI.replace(/\/$/, '');
    const papi = config.papi ? `https://${config.papi}` : defaultPapi;

    if (config.type === CONSTS.SAML) {
        return (
            <>
                <TextWithCopy name={t('idp-tab.table.header.sp-entity-id')} value={config.entityId || defaultPapi} copy />
                <TextWithCopy name={t('idp-tab.table.header.sso-url')} value={`${papi}/ic/idp/p/saml/callback`} copy />
            </>
        );
    }

    return (
        <>
            <TextWithCopy name={t('idp-tab.table.header.oauth-flow')} value={t('idp-tab.table.header.auth-code')} />
            <TextWithCopy name={t('idp-tab.table.header.redirect-uri')} value={`${papi}/ic/idp/p/callback`} copy />
        </>
    );
}

const ProviderHeader = ({ name, config, providerSystems, isDefault }: ProviderHeaderProps) => {
    const { t } = useTranslation('general');

    return (
        <Header>
            <StyledProviderHeader>
                <div className={'providerTitle'}>{name}</div>
                {providerSystems.length ? (
                    <div className={'systemsCount'}>
                        ({providerSystems.length} {t('idp-tab.table.header.systems')})
                    </div>
                ) : null}
            </StyledProviderHeader>
            <StyledProviderProtocolHeader>
                <span className={`protocol provider-protocol-tag`}>{config.type === CONSTS.SAML ? 'SAML 2.0' : 'OAuth 2.0'}</span>
            </StyledProviderProtocolHeader>
            <StyledDefaultIdpHeader>
                {isDefault && (
                    <CustomToolTip
                        title={t('idp-tab.table.header.default-idp-tooltip.title')}
                        tooltipContent={<span>{t('idp-tab.table.header.default-idp-tooltip.text')}</span>}>
                        <StyledChip
                            classes={{ label: 'defaultIdpLabel' }}
                            label={t('idp-tab.table.header.default-idp')}
                            variant='outlined'
                            color={'primary'}
                        />
                    </CustomToolTip>
                )}
            </StyledDefaultIdpHeader>
            <ProviderInfo className='provider-info'>
                <CustomToolTip
                    title={t('idp-tab.table.header.settings')}
                    tooltipContent={getTooltipContent(config, t)}
                    maxWidth={600}
                    placement='top-start'
                    arrow>
                    <IconButton color='primary' size='small' variant='ghost' onClick={(e) => e.stopPropagation()}>
                        <img src='assets/icons/icon-info.svg' alt='info' />
                    </IconButton>
                </CustomToolTip>
            </ProviderInfo>
        </Header>
    );
};
export default ProviderHeader;
