import { WMStatusDotVariant } from '@walkme/wm-ui';
import { TFunction } from 'i18next';

export interface UserStatus {
    id: string;
    display: string;
    info: string;
    color: WMStatusDotVariant;
    numberdId: number;
}

const StatusMap: UserStatus[] = [
    {
        id: 'STAGED',
        display: 'Staged',
        info: 'New users created through the API and not activated yet',
        color: WMStatusDotVariant.Draft,
        numberdId: 0,
    },
    {
        id: 'PROVISIONED',
        display: 'Pending user activation',
        info: 'Admin manually activate the user account but the user has not confirmed their e-mail address yet',
        color: WMStatusDotVariant.Draft,
        numberdId: 1,
    },
    { id: 'ACTIVE', display: 'Active', info: 'Active', color: WMStatusDotVariant.Success, numberdId: 2 },
    {
        id: 'RECOVERY',
        display: 'Recovery',
        info: 'An Admin intiated force password reset. User will not be able to use or request to reset their old password.',
        color: WMStatusDotVariant.Draft,
        numberdId: 3,
    },
    {
        id: 'LOCKED_OUT',
        display: 'Locked Out',
        info: 'User exceeds the number of login attempts defined in the login policy',
        color: WMStatusDotVariant.Failure,
        numberdId: 4,
    },
    {
        id: 'PASSWORD_EXPIRED',
        display: 'Password Expired',
        info: 'User password is expired',
        color: WMStatusDotVariant.Draft,
        numberdId: 5,
    },
    {
        id: 'SUSPENDED',
        display: 'Suspended',
        info: 'User cannot access applications',
        color: WMStatusDotVariant.Failure,
        numberdId: 6,
    },
    { id: 'DEPROVISIONED', display: 'Deprovisioned', info: 'User deactivated', color: WMStatusDotVariant.Draft, numberdId: 7 },
];

export const getUsersStatusMap = (t: TFunction): UserStatus[] => {
    if (!t) {
        t = ((x: string) => x) as TFunction;
    }

    return StatusMap.map((status) => ({
        ...status,
        display: t(`common.users-status.${status.id}.display`),
        info: t(`common.users-status.${status.id}.info`),
    }));
};
