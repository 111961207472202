import { WMAccountApplication } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/applications-types';
import { Box, Button, IconButton } from '@material-ui/core';
import { StyledTitle } from '../../../../users-roles/src/lib/styles/styles';
import Divider from '@material-ui/core/Divider';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Form as FormStyles } from '../../../../../../libs/shared-styles/src/lib/form-styles/form-styles';
import { WMButton, WMButtonVariant, WMSwitch, WMTextField, WMTooltipProps } from '@walkme/wm-ui';
import styled from 'styled-components';
import moment from 'moment';
import IconCopy from '../../../../../../../packages/libs/common/assets/icons/Icon-copy.svg';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { WMselectWrapper } from '@walkme-admin-center/libs/common/lib/select/wmselect-wrapper';

const StyledHeaderDiv = styled.div`
    height: 32px;
    width: 420px;
    margin-left: 32px;
    margin-top: 32px;
`;

const StyledEditHeaderDiv = styled.div`
    height: 55px;
    width: 420px;
    margin-left: 32px;
`;

const StyledEditClientHeaderDiv = styled.div`
    height: 60px;
    width: 420px;
    margin-left: 32px;
`;

const PermissionDiv = styled.div`
    width: 420px;
    margin-top: 32px;
    margin-left: 32px;
`;

const Title = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    font-size: 14px;
    color: #2f426c;
    line-height: 1.5;
    display: block;
`;

const Label = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    color: #2f3d53;
    font-size: 14px;
    line-height: 1.5;
    display: block;
`;

const ClientTitle = styled('label')`
    padding: 4px 0 8px;
    font-family: 'ProximaNova';
    font-size: 14px;
    color: #2f426c;
    line-height: 1.5;
    display: block;
`;

const TitlePermissions = styled('label')`
    padding: 32px 0 16px;
    font-family: 'ProximaNova';
    font-weight: 600;
    font-size: 14px;
    color: #2f426c;
    line-height: 1.5;
    display: block;
`;

const StyledRevokeButton = styled(WMButton)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 90px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #e1e9f5;
    background-color: #eb3232;
    color: #ffffff;
    text-transform: none;
    &:hover {
        background-color: #cc1616;
        opacity: 0.9;
    }
`;

const StyledCancelButton = styled(Button)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 90px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #cedaeb;
    color: #6d81a6;
    text-transform: none;
    &:hover {
        border: 1px solid #c6c9ce;
        background-color: #f8f6f8;
    }
`;

const PermDiv = styled.div`
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #dde5f5;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`;

const StyledPermissionsButton = styled(Button)`
    border-radius: 20px;
    height: 32px;
    width: 140px;
    font-family: 'ProximaNova';
    font-size: 12px;
    box-shadow: none;
    border: 1px solid #dfe5f2;
    color: #3b61eb;
    text-transform: none;
    &:hover {
        border: 1px solid #c6c9ce;
        background-color: #f8f6f8;
    }
`;

const StyledCreateButton = styled(WMButton)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 82px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #e1e9f5;
    background-color: #3b61eb;
    color: #ffffff;
    text-transform: none;
    &:hover {
        background-color: #1f46d1;
        opacity: 0.9;
    }
`;

const mapScopesBySelected = (api) => {
    const selectedScopes = new Map();
    if (api) {
        for (const scope of api.scopes.values()) {
            const splitScope = scope.split(':');
            const scopeActions = selectedScopes.get(splitScope[0]) ? selectedScopes.get(splitScope[0]) : [];
            selectedScopes.set(splitScope[0], scopeActions.concat(splitScope[1]));
        }
    }
    return selectedScopes;
};

interface scopeValueObject {
    desc: string;
    actions: string[];
}

interface ApiKeysFormProps {
    scopes: any;
    selected: WMAccountApplication;
    formActions: any;
    selectedCreator: string;
    selectedLastUsed: string;
    updating: boolean;
    submitting: boolean;
    deleting: boolean;
    newApi: boolean;
    clientSecret: string;
}

export const ApiKeysForm = ({
    scopes,
    selected,
    formActions,
    selectedCreator,
    selectedLastUsed,
    updating,
    submitting,
    deleting,
    newApi,
    clientSecret,
}: ApiKeysFormProps) => {
    const { t, rt } = useTranslation('general');
    const title = !selected ? t('api-keys-tab.create-new-key') : t('api-keys-tab.key-details');
    const [appName, setAppName] = useState('');
    const [canEdit, setCanEdit] = useState(false);
    const [selectedScopes, setSelectedScopes] = useState(mapScopesBySelected(selected));

    const isChecked = useCallback(
        (apiName: string) => {
            return selectedScopes.has(apiName);
        },
        [selectedScopes]
    );

    const handleToggle = useCallback(
        (isChecked: boolean, apiName: string) => {
            isChecked ? selectedScopes.set(apiName, []) : selectedScopes.delete(apiName);
            setSelectedScopes(new Map(selectedScopes));
        },
        [selectedScopes]
    );

    const selectedValue = useCallback(
        (apiName: string) => {
            return selectedScopes.has(apiName)
                ? selectedScopes.get(apiName).map((action) => {
                      return {
                          label: translateAction(action),
                          value: action,
                          id: action,
                      };
                  })
                : [];
        },
        [selectedScopes]
    );

    const handleActionChange = useCallback(
        (apiName: string, actions) => {
            selectedScopes.set(
                apiName,
                actions.map((action) => action.value)
            );
            setSelectedScopes(new Map(selectedScopes));
        },
        [selectedScopes]
    );

    const copyTooltipProps = {
        title: 'Copy',
        placement: 'top',
    } as WMTooltipProps;

    const EditHeader = useMemo(() => {
        return (
            <div>
                <Box display='flex' flexDirection='column' style={newApi ? { height: '370px', marginTop: '24px' } : { marginTop: '24px' }}>
                    <Box display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <StyledEditHeaderDiv>
                                <Title>{t('api-keys-tab.key-name')}</Title>
                                <Label>{selected ? selected.appName : ''}</Label>
                            </StyledEditHeaderDiv>
                        </Box>
                        <Box display='flex' flexDirection='row' alignItems='center' style={{ marginTop: '32px' }}>
                            <StyledEditHeaderDiv>
                                <Title>{t('api-keys-tab.creator')}</Title>
                                <Label>{selectedCreator ? selectedCreator : ''}</Label>
                            </StyledEditHeaderDiv>
                            <StyledEditHeaderDiv>
                                <Title>{t('api-keys-tab.created')}</Title>
                                <Label>{moment(selected ? selected.creationDate : '').format('MMM Do YYYY')}</Label>
                            </StyledEditHeaderDiv>
                            <StyledEditHeaderDiv>
                                <Title>{t('api-keys-tab.last-used')}</Title>
                                <Label>{selectedLastUsed}</Label>
                            </StyledEditHeaderDiv>
                        </Box>
                        <Divider style={{ margin: '12px 32px 12px 32px', color: '#E1E9F5' }} />
                    </Box>
                    <StyledEditClientHeaderDiv>
                        <ClientTitle>{t('api-keys-tab.client-id')}</ClientTitle>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#e9effb' }}>
                            <WMTextField
                                onChange={function noRefCheck() {}}
                                value={selected ? selected.clientId : ''}
                                disabled
                                inputProps={{ fontfamiliy: 'proximaNova' }}
                            />
                            <div
                                style={{
                                    fontFamily: 'proximaNova',
                                    backgroundColor: '#e9effb',
                                    fontSize: '12px',
                                }}>
                                <WMButton
                                    iconComponent={<img src={IconCopy} />}
                                    style={{ marginRight: '8px' }}
                                    tooltipProps={copyTooltipProps}
                                    isIconButton
                                    variant={WMButtonVariant.Text}
                                    onClick={() => {
                                        navigator.clipboard.writeText(selected ? selected.clientId : '');
                                    }}
                                />
                            </div>
                        </div>
                    </StyledEditClientHeaderDiv>
                    {newApi && (
                        <StyledHeaderDiv>
                            <ClientTitle>Client Secret</ClientTitle>
                            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#e9effb' }}>
                                <WMTextField onChange={function noRefCheck() {}} disabled value={clientSecret} />
                                <div
                                    style={{
                                        fontFamily: 'proximaNova',
                                        backgroundColor: '#e9effb',
                                        fontSize: '12px',
                                    }}>
                                    <WMButton
                                        iconComponent={<img src={IconCopy} />}
                                        style={{ marginRight: '8px' }}
                                        tooltipProps={copyTooltipProps}
                                        isIconButton
                                        variant={WMButtonVariant.Text}
                                        onClick={() => {
                                            navigator.clipboard.writeText(clientSecret);
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ height: '12px' }}></div>
                            <WMTextField
                                onChange={function noRefCheck() {}}
                                value={t('api-keys-tab.copy-secret-warning')}
                                multiline
                                disabled
                                inputProps={{
                                    style: {
                                        fontFamily: 'proximaNova',
                                        backgroundColor: '#FCF2F3',
                                        color: '#D9443C',
                                        fontSize: '12px',
                                    },
                                }}
                            />
                        </StyledHeaderDiv>
                    )}
                </Box>
            </div>
        );
    }, [selectedScopes, selected]);

    const translateAction = useCallback(
        (action: string): string => {
            return t(`api-keys-tab.api-keys-scopes.actions.${action}`);
        },
        [t]
    );

    const NewHeader = useMemo(() => {
        return (
            <StyledHeaderDiv>
                <Title>{t('common.name')}</Title>
                <WMTextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAppName(event.target.value)}
                    inputProps={{ fontfamiliy: 'proximaNova' }}
                    placeholder={t('api-keys-tab.name-placeholder')}
                />
            </StyledHeaderDiv>
        );
    }, [selectedScopes, selected]);

    const permissions = useMemo(() => {
        return (
            <PermissionDiv>
                <TitlePermissions>{t('api-keys-tab.permissions')}</TitlePermissions>
                <PermDiv
                    style={
                        newApi
                            ? { maxHeight: '15vh', overflowY: 'auto' }
                            : !selected
                            ? { maxHeight: '50vh', overflowY: 'auto' }
                            : { maxHeight: '25vh', overflowY: 'auto' }
                    }>
                    {Object.entries(scopes).map((api: [string, scopeValueObject]) => {
                        return (
                            <Box display='flex' key={api[0]} alignItems='center' justifyContent='space-between'>
                                <Box display='flex' flexDirection='row' alignItems='center' style={{ margin: '10px 0px', width: '200px' }}>
                                    <WMSwitch
                                        checked={isChecked(api[0])}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            handleToggle(event.target.checked, api[0]);
                                        }}
                                        label={`${rt(api[1].desc)}`}
                                    />
                                </Box>
                                <Box display='flex' flexDirection='row' style={{ marginBottom: '8px' }}>
                                    <WMselectWrapper
                                        isMulti
                                        isDropdownSelect
                                        itemsName='Scopes'
                                        groupValues
                                        value={selectedValue(api[0])}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleActionChange(api[0], event)}
                                        isSearchable={false}
                                        dropdownSelectProps={{
                                            buttonProps: {
                                                disabled: !isChecked(api[0]),
                                            },
                                        }}
                                        options={api[1].actions.map((action) => {
                                            return {
                                                label: translateAction(action),
                                                value: action,
                                                id: action,
                                            };
                                        })}
                                        multiValueGroupLabel={t('wm-select.multi-value-group-label')}
                                        placeholder={t('wm-select.placeholder')}
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </PermDiv>
            </PermissionDiv>
        );
    }, [selectedScopes, newApi]);

    return (
        <FormStyles>
            <div style={{ position: 'sticky', top: '0', backgroundColor: 'white', paddingTop: '20px' }}>
                <Box display='flex'>
                    <Box display='flex' flexDirection='row' flexGrow={1} alignItems='center'>
                        <img
                            src='assets/icons/api-key-icon.svg'
                            alt='system added'
                            style={{ marginLeft: '36.5px', marginRight: '12.57px' }}
                        />
                        <StyledTitle id='form-title'>{title}</StyledTitle>
                    </Box>
                    <Box display='flex' flexDirection='row'>
                        <IconButton onClick={() => formActions.onClose()} style={{ marginRight: '32px' }}>
                            <img src='assets/icons/close.svg' alt='system added' style={{ height: '12px' }} />
                        </IconButton>
                    </Box>
                </Box>
                <Divider style={{ margin: '12px 32px 12px 32px', color: '#E1E9F5' }} />
            </div>
            {!selected ? NewHeader : EditHeader}
            {permissions}
            <StyledHeaderDiv style={{ position: 'fixed', bottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <StyledCancelButton
                    onClick={() => {
                        formActions.onClose();
                    }}>
                    {' '}
                    {t('buttons.cancel')}{' '}
                </StyledCancelButton>
                {selected && (
                    <StyledRevokeButton
                        loading={deleting}
                        disabled={updating || submitting || deleting}
                        onClick={() => {
                            formActions.onDelete(selected.appId);
                        }}>
                        {' '}
                        {t('buttons.revoke')}{' '}
                    </StyledRevokeButton>
                )}
                {selected && (
                    <StyledCreateButton
                        loading={updating}
                        disabled={updating || submitting || deleting}
                        onClick={() => {
                            formActions.onUpdate(selectedScopes, selected.appId);
                        }}>
                        {' '}
                        {t('buttons.update')}{' '}
                    </StyledCreateButton>
                )}
                {!selected && (
                    <StyledCreateButton
                        loading={submitting}
                        disabled={appName.length == 0 || updating || submitting || deleting}
                        onClick={() => {
                            formActions.onConfirm(selectedScopes, appName);
                        }}>
                        {' '}
                        {t('buttons.create')}{' '}
                    </StyledCreateButton>
                )}
            </StyledHeaderDiv>
        </FormStyles>
    );
};

export default ApiKeysForm;
