import React from 'react';
import { Checkbox, createStyles, Theme, withStyles } from '@material-ui/core';
import { WMCheckbox } from '@walkme/wm-ui';

const checkboxStyle = (theme: Theme) =>
    createStyles({
        root: {
            '&.medium': {
                padding: 4,
            },
            '&.small': {
                padding: 3,
            },
            '&:hover': {
                background: 'none !important',
            },
        },
    });

const checkedIconStyle = (theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '3px',
            background: '#385FEB',
            backgroundImage: 'url(assets/icons/data-integrations/checked-icon-medium.svg) !important',
            backgroundRepeat: 'no-repeat !important',
            backgroundPosition: 'center !important',
            'input:hover ~ &': {
                background: '#2B47AC',
            },
            '&.medium': {
                width: 16,
                height: 16,
            },
            '&.small': {
                width: 14,
                height: 14,
            },
        },
    });

const UnCheckedIconStyle = (theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '3px',
            background: '#ffffff',
            border: '1px solid #6D81A6',
            'input:hover ~ &': {
                borderColor: '#6C89F0',
            },
            '&.medium': {
                width: 14,
                height: 14,
            },
            '&.small': {
                width: 12,
                height: 12,
            },
        },
    });

export const CheckedIcon = ({ classes, size }) => {
    return <span className={`${classes.root} ${size}`} />;
};

export const UnCheckedIcon = ({ classes, size }) => {
    return <span className={`${classes.root} ${size}`} />;
};

export const CssCheckedIcon = withStyles(checkedIconStyle)(CheckedIcon);

export const CssUnCheckedIcon = withStyles(UnCheckedIconStyle)(UnCheckedIcon);

export const CssCheckBox = withStyles(checkboxStyle)(Checkbox);

export const CustomCheckbox = ({ size = 'medium', ...other }) => {
    return <WMCheckbox size={'medium'} disableRipple {...other} />;
};

export default CustomCheckbox;
