import styled from 'styled-components';
import Select from '@material-ui/core/Select/Select';

export const StyledSelect = styled(Select)`
    .MuiSelect-root {
        align-items: center;
        display: flex;
        height: 24px;
        padding: 3px 5px;
        background: white;
        max-width: 420px;
        min-width: 200px;
    }
    .MuiSelect-icon {
        background-color: inherit;
    }
`;
