import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import { WMIconLogo } from '@walkme-admin-center/libs/ui-components';
import { WMButton, WMButtonVariant, WMSnackbar, WMSnackbarVariant, WMTextField } from '@walkme/wm-ui';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { AccountsSdk, AuthenticatePolicyComplexity, AuthenticateStateToken, PasswordValidator } from 'wm-accounts-sdk';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation, useParams } from 'react-router-dom';
import { AppData } from '@walkme-admin-center/libs/types';
import { useDispatch } from 'react-redux';
import { WMAuthManager } from 'wm-accounts-auth';
import { StyledActivationScreenGrid, StyledImageGrid } from './activation-screen.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActivationScreenProps {}
interface ActivationScreenPropsFormValues {
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
}

export const ActivationScreen = (props: ActivationScreenProps) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const search = useLocation().search;
    const validRedirectUrl = (() => {
        try {
            const defaultAppToOpen = new URLSearchParams(search).get('defaultAppToOpen');
            return `${window.location.origin}/signin/open-app?${defaultAppToOpen ? `defaultAppToOpen=${defaultAppToOpen}` : ''}`;
        } catch (e) {
            return `${window.location.origin}/signin/open-app`;
        }
    })();
    const { activationKey } = useParams<{ activationKey }>();
    const activationKeyDecoded = decodeURI(activationKey);
    const [noPassword, setNoPassword] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<ActivationScreenPropsFormValues>({
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    });
    const [formErrorsValues, setFormErrosValues] = useState<ActivationScreenPropsFormValues>({
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    });
    const [authenticateStateToken, setAuthenticateStateToken] = useState<AppData<AuthenticateStateToken>>({
        loading: true,
        data: null,
        error: null,
    });
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState('');
    const [authRequestProcessing, setAuthRequestProcessing] = useState(false);
    const [signupProccessLoading, setSignupProccessLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState<AuthenticatePolicyComplexity>({
        minLength: 10,
        minLowerCase: 1,
        minUpperCase: 1,
        minNumber: 1,
        minSymbol: 1,
        excludeUsername: false,
    });

    useEffect(() => {
        if (authRequestProcessing) {
            console.error(`already processing request`);
            return;
        }
        setAuthRequestProcessing(true);
        setNoPassword(new URLSearchParams(search).get('activateWithoutPassword') === 'true');
        AccountsSdk.getInstance()
            .accountSignin.authenticateUser({ activationKey: activationKeyDecoded })
            .then((authenticationData: AuthenticateStateToken) => {
                if (authenticationData.sessionToken) {
                    WMAuthManager.getInstance().setCookieAndRedirect(authenticationData.sessionToken, validRedirectUrl);
                } else if (authenticationData.stateToken && authenticationData.status === 'PASSWORD_RESET') {
                    const firstName =
                        authenticationData.firstName && authenticationData.firstName !== 'N/A' ? authenticationData.firstName : '';
                    const lastName =
                        authenticationData.lastName && authenticationData.lastName !== 'N/A' ? authenticationData.lastName : '';
                    setFormValues((prevState) => {
                        const newMapping = Object.assign({}, prevState);
                        newMapping['firstName'] = firstName;
                        newMapping['lastName'] = lastName;
                        return newMapping;
                    });
                    setAuthenticateStateToken({
                        loading: false,
                        data: authenticationData,
                        error: null,
                    });
                    setPasswordRequirements(authenticationData.policyComplexity);
                }
            })
            .catch((e) => {
                const errorCode = e.response && e.response.data && e.response.data.errorCode;
                if (errorCode === 'UserAlreadyAcitvated') {
                    window.location.replace(validRedirectUrl);
                }
                const error = (e.response && e.response.data && e.response.data.message) || 'Unspecified error';
                setAuthenticateStateToken({
                    loading: false,
                    data: null,
                    error: error,
                });
            });
    }, []);

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar('');
    };

    const onSignUpHandle = useCallback(async () => {
        try {
            setSignupProccessLoading(true);
            const response = await AccountsSdk.getInstance().accountSignin.resetPasswordAndUpdateProfileWithStateToken({
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                password: formValues.password,
                stateToken: authenticateStateToken.data.stateToken,
            });
            if (response.sessionToken) {
                WMAuthManager.getInstance().setCookieAndRedirect(response.sessionToken, validRedirectUrl);
            } else {
                setSignupProccessLoading(false);
            }
        } catch (e) {
            setSignupProccessLoading(false);
            const errorMessage = (e.response && e.response.data && e.response.data.message) || 'Unspecified error';
            setOpenErrorSnackbar(errorMessage);
        }
    }, [dispatch, authenticateStateToken, formValues]);

    const updateFormField = useDebouncedCallback((value: string, fieldName: string) => {
        setFormValues((prevState) => {
            const newMapping = Object.assign({}, prevState);
            newMapping[fieldName] = value;
            return newMapping;
        });
    }, 200);

    const validateFormField = useDebouncedCallback((value: string, fieldName: string) => {
        setFormErrosValues((prevState) => {
            const newMapping = Object.assign({}, prevState);
            let passwordValidationError;
            switch (fieldName) {
                case 'firstName':
                case 'lastName':
                    newMapping[fieldName] = value ? '' : 'Field Required';
                    break;
                case 'password':
                case 'confirmPassword':
                    passwordValidationError = PasswordValidator.validatePasswordWithSettings(passwordRequirements, value);
                    newMapping[fieldName] = passwordValidationError.error || '';
                    if (!passwordValidationError.error && formValues.confirmPassword && formValues.password) {
                        const isPasswordEquals = formValues.confirmPassword === formValues.password;
                        const error = 'Password not match';
                        newMapping.confirmPassword = isPasswordEquals ? '' : error;
                        newMapping.password = isPasswordEquals ? '' : error;
                    }
                    break;
            }
            return newMapping;
        });
    }, 500);

    const isFormValidated = () => {
        const emptyValues: boolean = noPassword
            ? Object.keys(formValues).filter((key) => formValues[key] === '' && key !== 'password' && key !== 'confirmPassword').length > 0
            : Object.values(formValues).filter((item) => !item).length > 0;
        return signupProccessLoading || Object.values(formErrorsValues).filter((item) => item).length > 0 || emptyValues;
    };

    return (
        <StyledActivationScreenGrid spacing={0} container direction='row' alignItems='flex-start' alignContent='space-around'>
            <WMSnackbar
                open={openErrorSnackbar ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={openErrorSnackbar}
            />
            <Grid className={'formGrid'} item sm={7} xs={12}>
                <Grid container alignItems='center' direction='column'>
                    <Grid item xs={7}>
                        <WMIconLogo />
                        <Grid className={'formTitleGrid'}>
                            <span className={'formTitle'}>
                                Welcome!<br></br>Set up your WalkMe account
                            </span>
                        </Grid>
                        {authenticateStateToken.error && (
                            <Grid className={'loadingErrorGrid'}>
                                <span className={'loadingErrorLabel'}>{authenticateStateToken.error}</span>
                            </Grid>
                        )}
                        {authenticateStateToken.loading && (
                            <Grid className={'formFieldsGrid'} container direction='row' justifyContent='center' alignItems='flex-start'>
                                <Grid item xs={12}>
                                    <CircularProgress size={50} />
                                </Grid>
                            </Grid>
                        )}
                        {authenticateStateToken.data && (
                            <Grid>
                                <Grid
                                    className={'formFieldsGrid'}
                                    container
                                    direction='row'
                                    alignItems='flex-start'
                                    justifyContent='space-between'
                                    spacing={2}>
                                    <Grid item xs={6}>
                                        <span className={'userFieldLabel'}>First Name</span>
                                        <WMTextField
                                            error={formErrorsValues.firstName}
                                            autoFocus
                                            value={formValues.firstName}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                updateFormField(event.target.value, 'firstName');
                                                validateFormField(event.target.value, 'firstName');
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className={'userFieldLabel'}>Last Name</span>
                                        <WMTextField
                                            error={formErrorsValues.lastName}
                                            value={formValues.lastName}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                updateFormField(event.target.value, 'lastName');
                                                validateFormField(event.target.value, 'lastName');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {!noPassword && (
                                    <Grid className={'formFieldsGrid'}>
                                        <span className={'userFieldLabel'}>Password</span>
                                        <WMTextField
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                style: {
                                                    fontFamily: 'proximaNova',
                                                    backgroundColor: '#f0f4fa',
                                                    fontSize: '14px',
                                                },
                                                endAdornment: (
                                                    <InputAdornment position='end' style={{ backgroundColor: '#f0f4fa' }}>
                                                        <WMButton
                                                            aria-label='toggle password visibility'
                                                            iconComponent={showPassword ? <VisibilityOff /> : <Visibility />}
                                                            isIconButton
                                                            variant={WMButtonVariant.Text}
                                                            onClick={() => {
                                                                setShowPassword(!showPassword);
                                                            }}
                                                            onMouseDown={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={formErrorsValues.password}
                                            value={formValues.password}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                updateFormField(event.target.value, 'password');
                                                validateFormField(event.target.value, 'password');
                                            }}
                                        />
                                    </Grid>
                                )}
                                {!noPassword && (
                                    <Grid className={'formFieldsGrid'}>
                                        <span className={'userFieldLabel'}>Confirm Password</span>
                                        <WMTextField
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            InputProps={{
                                                style: {
                                                    fontFamily: 'proximaNova',
                                                    backgroundColor: '#f0f4fa',
                                                    fontSize: '14px',
                                                },
                                                endAdornment: (
                                                    <InputAdornment position='end' style={{ backgroundColor: '#f0f4fa' }}>
                                                        <WMButton
                                                            aria-label='toggle password visibility'
                                                            iconComponent={showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            isIconButton
                                                            variant={WMButtonVariant.Text}
                                                            onClick={() => {
                                                                setShowConfirmPassword(!showConfirmPassword);
                                                            }}
                                                            onMouseDown={(event) => {
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={formErrorsValues.confirmPassword}
                                            value={formValues.confirmPassword}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                updateFormField(event.target.value, 'confirmPassword');
                                                validateFormField(event.target.value, 'confirmPassword');
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid className={'formFieldsGrid'} item xs={12}>
                                    <WMButton
                                        fullWidth
                                        onClick={() => {
                                            onSignUpHandle();
                                        }}
                                        disabled={isFormValidated()}
                                        loading={signupProccessLoading}>
                                        Sign Up
                                    </WMButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <StyledImageGrid item sm={5} xs={false}>
                <div className={'imageGrid'}>
                    <span className={'imageTitleTag'}>
                        Welcome <br></br>to WalkMe
                    </span>
                    <img className={'imageTag'} src='assets/signin/signup-image.png' alt='Signup Image' />
                </div>
            </StyledImageGrid>
        </StyledActivationScreenGrid>
    );
};

export default ActivationScreen;
