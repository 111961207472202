import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { UsersState, useRoles, useActions } from '@walkme-admin-center/libs/state-management-users';
import { partnersSlice, editUser, PartnersState } from '@walkme-admin-center/libs/state-management-partners';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import {
    LoadingContainer,
    StyledWMSelect,
    StyledGrid,
    StyledContentElements,
    StyledCloseDialogImage,
    StyledTableBody,
    StyledWizardDialogButtons,
    StyledEditUserContainer,
} from './styles/styles';
import { PartnerUserData } from '@walkme-admin-center/libs/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mapRoleToRow } from './utils/roles-rows';
import { mapSystemToRow } from './utils/systems-rows';
import { extractMailInitials } from './utils/mail-initials';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface EditUserProps {
    userDataForEdit: PartnerUserData;
    onCancel?: () => void;
    onFinish: () => void;
    tableStyles?: any;
    titleText?: string;
    hideTitle?: boolean;
}

export const DefaultEditPartnerTitle = 'Edit Partner User';

export const EditUser = ({
    userDataForEdit,
    onCancel,
    onFinish,
    hideTitle = false,
    titleText = DefaultEditPartnerTitle,
    tableStyles = {},
}: EditUserProps) => {
    const dispatch = useDispatch();
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const createUsersAppData = useSelector((state: { usersState: UsersState }) => state.usersState.createUsers);
    const editUserAppData = useSelector((state: { partnersState: PartnersState }) => state.partnersState.editUser);
    const { rolesAppData } = useRoles();
    const { systemsAppData } = useSystems();
    const { actionsAppData } = useActions();
    const selectRef = useRef(null);
    const { rt } = useTranslation('general');

    const onSaveChanges = useCallback(async () => {
        editUser(dispatch, {
            subUser: {
                idpNameId: null,
                firstName: userDataForEdit.firstName,
                lastName: userDataForEdit.lastName,
                userId: userDataForEdit?.id,
                roleId: selectedRole && (selectedRole.value as number),
                systemIds: selectedSystems.map((systemRow) => systemRow.value as number),
                attachToIdpId: null,
                deatchFromIdp: null,
                hasBusinessDomain: false,
                businessDomainId: null,
            },
        });
    }, [selectedRole, selectedSystems]);

    useEffect(() => {
        if (selectRef.current != null) {
            setAnchorEl(selectRef.current);
        }
    });

    useEffect(() => {
        const assignedSystems = userDataForEdit.assignedSystems.map((system) => {
            return { value: system?.id, label: system?.displayName };
        });

        const value = selectedRole?.value === userDataForEdit?.role?.id && isEqual(assignedSystems, selectedSystems);
        setIsSaveDisabled(value);
    }, [selectedRole, selectedSystems]);

    // INFO: initializes systems and role with existing user data
    useEffect(() => {
        if (!rolesAppData.loading && !systemsAppData.loading) {
            setSelectedRole(
                mapRoleToRow(
                    rt,
                    rolesAppData.data.find((role) => role.id === userDataForEdit?.role?.id),
                    anchorEl,
                    actionsAppData.data
                )
            );
            const previouslySelectedSystems = userDataForEdit.assignedSystems.map((system) => mapSystemToRow(system));
            setSelectedSystems(previouslySelectedSystems);
        }
    }, [rolesAppData, systemsAppData, anchorEl, rt]);

    useEffect(() => {
        if (editUserAppData && !editUserAppData.loading && editUserAppData.data) {
            dispatch(partnersSlice.actions.editUserCleanup());
            onFinish();
        }
    }, [editUserAppData]);

    const contentElements = (
        <StyledContentElements>
            <Grid className={'titleGrid'} item xs={12}>
                <Grid
                    container
                    className={'titleContainer'}
                    direction='row'
                    alignItems='flex-start'
                    justifyContent='space-between'
                    id={'applying'}>
                    <Grid item>
                        <span hidden={hideTitle} className={'dialogTitle'}>
                            {titleText}
                        </span>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='flex-end' alignItems='baseline'>
                            <IconButton onClick={() => onCancel()}>
                                <StyledCloseDialogImage src='assets/icons/close.svg' alt='close' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'wizardDialogContent'} item xs={12}>
                <Grid container className={'wizardDialogContainer'}>
                    <Grid item xs={12}>
                        <StyledGrid>
                            {rolesAppData.loading || systemsAppData.loading ? (
                                <LoadingContainer>
                                    <CircularProgress size={50} thickness={4} />
                                </LoadingContainer>
                            ) : (
                                <TableContainer className={'editPartnerTable'} style={{ ...tableStyles }} component={Paper} elevation={0}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Role</TableCell>
                                                <TableCell>Systems</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <StyledTableBody>
                                            <TableRow key={userDataForEdit.email} hover classes={{ selected: 'tableRowSelected' }}>
                                                <TableCell width={'25%'}>
                                                    <div>
                                                        <div className={'emailDiv'}>
                                                            <div className={'userImage'}>
                                                                <span className={'userImageText'}>
                                                                    {extractMailInitials(userDataForEdit.email)}
                                                                </span>
                                                            </div>
                                                            <span className={'emailText'}>{userDataForEdit.email}</span>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell width='25%'>
                                                    <div ref={selectRef}>
                                                        <StyledWMSelect
                                                            onChange={(selectedItem) => setSelectedRole(selectedItem)}
                                                            value={selectedRole}
                                                            options={rolesAppData.data.map((role) =>
                                                                mapRoleToRow(rt, role, anchorEl, actionsAppData.data)
                                                            )}
                                                            maxMenuHeight={300}
                                                            menuPortalTarget={null}
                                                            isSearchable={false}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell width='20%'>
                                                    <StyledWMSelect
                                                        maxMenuHeight={300}
                                                        allowSelectAll
                                                        groupValues
                                                        isMulti
                                                        isPopout
                                                        disablePortal
                                                        itemsName='systems'
                                                        value={selectedSystems}
                                                        onChange={(selectedItems) => setSelectedSystems(selectedItems)}
                                                        options={systemsAppData.data.map((system) => mapSystemToRow(system))}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </StyledTableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </StyledGrid>
                    </Grid>
                </Grid>
            </Grid>
            <StyledWizardDialogButtons item xs={12}>
                <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                    <Grid item>
                        <WMButton variant={WMButtonVariant.Text} className={'wizardDialogSecondaryButton'} onClick={onCancel}>
                            Cancel
                        </WMButton>
                    </Grid>
                    <Grid item>
                        <WMButton
                            onClick={() => onSaveChanges()}
                            disabled={selectedSystems.length === 0 || isSaveDisabled || rolesAppData.loading || systemsAppData.loading}
                            loading={createUsersAppData.loading}>
                            Save
                        </WMButton>
                    </Grid>
                </Grid>
            </StyledWizardDialogButtons>
        </StyledContentElements>
    );

    return (
        <StyledEditUserContainer>
            <Dialog onClose={() => onCancel()} open={true} classes={{ paper: 'editPartnerModal' }} fullWidth={true} maxWidth={'lg'}>
                <Grid container direction='row' alignItems='stretch' justifyContent='flex-start'>
                    {contentElements}
                </Grid>
            </Dialog>
        </StyledEditUserContainer>
    );
};

export default EditUser;
