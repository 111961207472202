import React, { useEffect, useState } from 'react';
import { StyledAccessibilityContainer, StyledAccessibilityTitle, StyledAccessibilityToasterMessage } from './accessibility.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import { Checkbox, Toaster, ToasterVariant } from '@walkme/ui-core';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { WMSwitch } from '@walkme/wm-ui';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { siteConfigApi } from '../../../../api/site-config-api';

export const Accessibility = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const { loggedInUserAppData } = useLoggedInUser();
    const accessibilityDefaultMasterFeatureOnAccount = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.accessibilityDefaultMasterFeatureOnAccount
    );
    const accessibilityDefaultMasterFeatureIsOnByUser =
        accessibilityDefaultMasterFeatureOnAccount?.modifiedBy === loggedInUserAppData?.data?.email;
    const accessibilityFeatureIsOn = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.accessibilityFeatureIsOn
    );
    const automaticEnableAccessibilityFeature = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.automaticEnableAccessibilityFeature
    );

    const handleOnToggleChange = (isOn) => {
        dispatch(createSystemSlice.actions.setAccessibilityFeatureIsOn(isOn));
    };

    const handleOnAutomaticEnableChange = (isOn) => {
        dispatch(createSystemSlice.actions.setAutomaticEnableAccessibilityFeature(isOn));
    };

    const setAccessibilityDefaultMasterFeatureOnAccount = (accessibilityDefaultMasterFeatureOnAccount) => {
        dispatch(createSystemSlice.actions.setAccessibilityDefaultMasterFeatureOnAccount(accessibilityDefaultMasterFeatureOnAccount));
    };

    const getTextJsx = (fullText: string, linksPrefix: string) => {
        const elements = [];
        const numOfLinks = (fullText.match(/\$\$/g) || []).length;
        const splitByDollars = fullText.split('$$');
        splitByDollars.forEach((element, index) => {
            elements.push(<span>{element}</span>);
            if (index < numOfLinks) {
                const linkText = t(`${linksPrefix}.${index}.text`);
                const linkURL = t(`${linksPrefix}.${index}.url`);
                elements.push(
                    <a href={linkURL} target='_blank'>
                        {linkText}
                    </a>
                );
            }
        });
        return elements;
    };

    const getToasterMessage = () => {
        if (accessibilityDefaultMasterFeatureOnAccount && !accessibilityDefaultMasterFeatureIsOnByUser) {
            const fullText = t(`systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-enabled.text`);
            const linksPrefix = 'systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-enabled.links';
            const ToasterMessage = getTextJsx(fullText, linksPrefix);
            return <StyledAccessibilityToasterMessage>{ToasterMessage}</StyledAccessibilityToasterMessage>;
        }

        const fullText = t(`systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-not-enabled.text`);
        const linksPrefix = 'systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-not-enabled.links';
        const ToasterMessage = getTextJsx(fullText, linksPrefix);

        return <StyledAccessibilityToasterMessage>{ToasterMessage}</StyledAccessibilityToasterMessage>;
    };

    const getAccountDefaultMasterFeature = async () => {
        try {
            const masterFeaturesRes = await siteConfigApi.getAccountDefaultMasterFeatures({
                accountId: loggedInUserAppData?.data?.account?.id,
            });
            const accessibilityEnabledOnAccount = masterFeaturesRes?.data?.find(
                (featureItem) => featureItem.feature?.name?.toLowerCase() === 'accessibility'
            );
            setAccessibilityDefaultMasterFeatureOnAccount(accessibilityEnabledOnAccount);
            if (accessibilityEnabledOnAccount) {
                handleOnToggleChange(true);

                if (accessibilityEnabledOnAccount.modifiedBy === loggedInUserAppData?.data?.email) {
                    handleOnAutomaticEnableChange(true);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getAccountDefaultMasterFeature();
    }, []);

    return (
        <StyledAccessibilityContainer>
            <StyledAccessibilityTitle>
                <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.accessibility.main-title`)}</div>
                <div className='sub-title'>
                    <div className='action-row'>
                        <div>{t(`systems-tab.new-system-form.configure-step.accessibility.sub-title-black`)}</div>
                        <WMSwitch
                            checked={accessibilityFeatureIsOn}
                            disabled={accessibilityDefaultMasterFeatureOnAccount && !accessibilityDefaultMasterFeatureIsOnByUser}
                            onChange={(e) => handleOnToggleChange(e.target?.checked)}
                        />
                    </div>
                    <div className='learn-more'>
                        <span>{t(`systems-tab.new-system-form.configure-step.accessibility.sub-title-learn-more`)}</span>
                        <a href='https://support.walkme.com/knowledge-base/accessibility-in-walkme/' target='_blank'>
                            {t('buttons.learn-more')}
                        </a>
                    </div>
                </div>
                {accessibilityFeatureIsOn &&
                (!accessibilityDefaultMasterFeatureOnAccount ||
                    (accessibilityDefaultMasterFeatureOnAccount && accessibilityDefaultMasterFeatureIsOnByUser)) ? (
                    <div className='automatic-enable-row'>
                        <Checkbox
                            checked={automaticEnableAccessibilityFeature}
                            onChange={() => handleOnAutomaticEnableChange(!automaticEnableAccessibilityFeature)}
                        />
                        <div>{t(`systems-tab.new-system-form.configure-step.accessibility.automatic-enable`)}</div>
                    </div>
                ) : null}
            </StyledAccessibilityTitle>
            <Toaster className='accessibilityToaster' icon={true} variant={ToasterVariant.Info} message={getToasterMessage()} />
        </StyledAccessibilityContainer>
    );
};
