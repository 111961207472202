import React, { SVGProps } from 'react';

export const ExternalLinkIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 4.5C10 4.22386 9.77614 4 9.5 4H5C3.89543 4 3 4.89543 3 6V15C3 16.1046 3.89543 17 5 17H14C15.1046 17 16 16.1046 16 15V10.5C16 10.2239 15.7761 10 15.5 10C15.2239 10 15 10.2239 15 10.5V15C15 15.5523 14.5523 16 14 16H5C4.44772 16 4 15.5523 4 15V6C4 5.44772 4.44772 5 5 5H9.5C9.77614 5 10 4.77614 10 4.5Z'
            fill='#385FEB'
        />
        <path d='M12.5 7.5L16 4' stroke='#385FEB' strokeLinecap='round' strokeLinejoin='round' />
        <path
            d='M17.5 2H14.2071C13.7617 2 13.5386 2.53857 13.8536 2.85355L17.1464 6.14645C17.4614 6.46143 18 6.23835 18 5.79289V2.5C18 2.22386 17.7761 2 17.5 2Z'
            fill='#385FEB'
        />
    </svg>
);
