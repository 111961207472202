import styled from 'styled-components';
import { Button } from '@walkme/ui-core';

export const StyledBodyParagraph = styled.p`
    color: var(--Color-Local-Semantic-Text-secondary, #637191);
`;

export const StyledAcknowledge = styled.div`
    display: flex;
    gap: 8px;
    padding-top: 8px;
    color: var(--Color-Local-Semantic-Text-secondary, #637191);
    cursor: pointer;
`;

export const StyledPrimaryButton = styled(Button)`
    margin-left: 30px;
`;

export const StyledSecondaryButton = styled(Button)`
    color: var(--Color-Local-Semantic-Action-Content-Text-secondary-default, #637191);
`;
