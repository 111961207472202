import { Box, Typography } from '@material-ui/core';
import { PartialSystemData } from '@walkme-admin-center/libs/exposure-api';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@walkme/ui-core';
import { WMButton, WMButtonVariant, WMCircularProgress } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { ValidationResult } from 'packages/pages/util/validation';
import React, { createContext, useContext, useState } from 'react';
import { EnvironmentDialogContent } from './environment-dialog-content';
import { EnvironmentsResult } from './uri-verifier-environment-result';
import { URLVerifierDialogTabs } from './url-verifier-dialog-tabs';
import { getVerificationResults } from './utils';
import { DomainType, EnvironmentURLQueryResult } from './values';

interface URLVerifierDialogProps {
    onCloseDialog: () => void;
    systemData: PartialSystemData;
}

interface VerificationButtonProps {
    appUrl: string;
    iframeUrl: string;
    currentTab: DomainType;
    systemData: PartialSystemData;
}

const StyledWMServerError = <img src='/assets/icons/url-verifier-server-error.svg' style={{ width: '120px', height: '120px' }} />;

interface IContext {
    setSelectedEnvironment: (env: EnvironmentURLQueryResult) => void;
    setShowEnvironmentResult: (show: boolean) => void;
    isUrlFormatValid: boolean;
    setIsUrlFormatValid: (value: boolean) => void;
}

const URLVerifierDialogContext = createContext<IContext>({
    setSelectedEnvironment: () => {},
    setShowEnvironmentResult: () => {},
    isUrlFormatValid: true,
    setIsUrlFormatValid: () => {},
});

const URLVerifierDialog: React.FC<URLVerifierDialogProps> = ({ onCloseDialog, systemData }) => {
    const [showEnvironmentResult, setShowEnvironmentResult] = useState(false);
    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentURLQueryResult>(null);
    const [isUrlFormatValid, setIsUrlFormatValid] = React.useState(true);

    return (
        <Dialog isOpen={true} onClose={onCloseDialog}>
            <URLVerifierDialogContext.Provider
                value={{ setSelectedEnvironment, setShowEnvironmentResult, isUrlFormatValid, setIsUrlFormatValid }}>
                <div style={{ display: showEnvironmentResult ? 'block' : 'none' }}>
                    <EnvironmentDialogContent selectedEnvironment={selectedEnvironment} onCloseDialog={onCloseDialog} />
                </div>
                <div style={{ display: showEnvironmentResult ? 'none' : 'block' }}>
                    <URLVerifierDialogContent systemData={systemData} />
                </div>
            </URLVerifierDialogContext.Provider>
        </Dialog>
    );
};

const URLVerifierDialogContent: React.FC<{ systemData: PartialSystemData }> = ({ systemData }) => {
    const { t } = useTranslation('general');
    const [appUrl, setAppUrl] = useState('');
    const [iframeUrl, setIframeUrl] = useState('');
    const [currentTab, setCurrentTab] = useState<DomainType>(DomainType.App);

    return (
        <>
            <DialogTitle>
                <Box width='100%' display='flex' flexDirection='column' alignItems='start'>
                    <Typography style={{ color: '#2F426C', fontSize: '18px', fontWeight: 600 }}>
                        {t('systems-tab.all-systems-page.extension-page.open-url-verifier.dialog-title')}
                    </Typography>
                    <Typography style={{ color: '#637191', fontSize: '14px', fontWeight: 400 }}>
                        {t('systems-tab.all-systems-page.extension-page.open-url-verifier.dialog-subtitle')}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <URLVerifierDialogTabs
                    appUrl={appUrl}
                    setAppUrl={setAppUrl}
                    iframeUrl={iframeUrl}
                    setIframeUrl={setIframeUrl}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            </DialogContent>
            <DialogActions>
                <VerificationButton appUrl={appUrl} iframeUrl={iframeUrl} currentTab={currentTab} systemData={systemData} />
            </DialogActions>
        </>
    );
};

const VerificationButton: React.FC<VerificationButtonProps> = ({ appUrl, iframeUrl, currentTab, systemData }) => {
    const { t } = useTranslation('general');
    const { isUrlFormatValid } = useContext(URLVerifierDialogContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [verificationResults, setVerificationResults] = useState<EnvironmentURLQueryResult[]>([]);
    const [isServerError, setIsServerError] = useState(false);

    const handleVerify = async () => {
        setIsLoading(true);
        try {
            const verificationResults = await getVerificationResults(systemData, appUrl, iframeUrl);
            setVerificationResults(verificationResults);
            setIsServerError(false);
            setShowResults(true);
        } catch (error) {
            setIsServerError(true);
            setShowResults(false);
        }

        setIsLoading(false);
    };

    const isButtonDisabled = (currentTab === DomainType.Iframe && iframeUrl === '') || appUrl === '' || isLoading || !isUrlFormatValid;

    return (
        <Box width='100%' display='flex' flexDirection='column' alignItems='end'>
            <WMButton
                style={{ width: '80px', height: '40px', borderRadius: '40px' }}
                variant={WMButtonVariant.Primary}
                disabled={isButtonDisabled}
                onClick={handleVerify}>
                {t('systems-tab.all-systems-page.extension-page.open-url-verifier.verify-button')}
            </WMButton>
            {isLoading ? <Loading /> : null}
            {showResults ? <EnvironmentsResult verificationResults={verificationResults} /> : null}
            {isServerError ? <ServerError /> : null}
        </Box>
    );
};

const Loading = () => {
    const { t } = useTranslation('general');

    return (
        <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
            <WMCircularProgress />
            <Typography style={{ fontFamily: 'Proxima nova', fontSize: '12px', fontWeight: 600, marginTop: '10px' }}>
                {t('systems-tab.all-systems-page.extension-page.open-url-verifier.loading-results')}
            </Typography>
        </Box>
    );
};

const ServerError = () => {
    const { t } = useTranslation('general');

    return (
        <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
            {StyledWMServerError}
            <Typography style={{ fontFamily: 'Proxima nova', fontSize: '16px', fontWeight: 600 }}>
                {t('systems-tab.all-systems-page.extension-page.open-url-verifier.server-error-title')}
            </Typography>
            <Typography style={{ fontFamily: 'Proxima nova', fontSize: '14px', fontWeight: 400 }}>
                {t('systems-tab.all-systems-page.extension-page.open-url-verifier.server-error-subtitle')}
            </Typography>
        </Box>
    );
};

export { URLVerifierDialog, URLVerifierDialogContext };
