import { FormControl } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import React, { useState } from 'react';
import { Certificate, Provider, ProviderGroup } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { idpApi } from '@walkme-admin-center/libs/idp-api';
import { StyledButton, CustomSwitches } from '../../../common/style';
import { Accordion, AccordionSummary, AccordionDetails, StyledHelperText, StyledFormHelperText } from './encryption-settings.style';

export interface EncryptionSettingsProps {
    provider: Provider;
    certificate: Certificate;
    idpType: ProviderGroup;
    updateCertificate: (certificate: Certificate) => void;
}

export const EncryptionSettings = ({ provider, certificate, idpType, updateCertificate }: EncryptionSettingsProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isExpand, setIsExpand] = useState<boolean>(false);

    const encryptionFields = [
        {
            title: 'AuthnRequest',
            helper: 'Sign the SAML AuthnRequest',
            fieldName: 'config.signAuthRequest',
        },
        {
            title: 'Assertion Encryption',
            helper: 'Receive encrypted SAML authentication assertions',
            fieldName: 'config.encryptAssertion',
        },
    ];

    const isCertificateGenerated = (provider && provider.config.hasCertificate) || (certificate && certificate.certificateId);

    const downLoadCertificate = async (): Promise<void> => {
        try {
            const hasCertificate = provider && provider.config.hasCertificate;
            const certId = hasCertificate ? provider.id : certificate.certificateId;
            setLoading(true);
            await idpApi.downloadCertificate(hasCertificate, certId);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const generateCertificate = async (): Promise<void> => {
        try {
            setLoading(true);
            const res = await idpApi.generateCertificate(idpType.id);
            setLoading(false);
            updateCertificate(res);
        } catch (err) {
            setLoading(false);
        }
    };

    const accordionChange = (event: React.ChangeEvent, expand: boolean): void => {
        setIsExpand(expand);
    };

    return (
        <Accordion onChange={accordionChange}>
            <AccordionSummary
                expandIcon={isExpand ? <Remove fontSize={'small'} /> : <Add fontSize={'small'} />}
                aria-controls='panel1a-content'
                id='panel1a-header'>
                <span>Setup Encryption Settings</span>
            </AccordionSummary>

            <AccordionDetails>
                <StyledHelperText>
                    To setup the encryption of the integration please generate the certificate first, And then select the relevant settings
                    below.
                </StyledHelperText>
                <StyledButton
                    style={{ margin: '15px 0' }}
                    loading={loading}
                    customClass='main'
                    onClick={() => {
                        isCertificateGenerated ? downLoadCertificate() : generateCertificate();
                    }}>
                    {isCertificateGenerated ? 'Download Certificate' : 'Generate Certificate'}
                </StyledButton>
                {encryptionFields.map((encryptionField, index) => {
                    return (
                        <FormControl className={'toggle-field'} key={index}>
                            <div>
                                <span className={'title'} style={{ fontWeight: 600 }}>
                                    {encryptionField.title}
                                </span>
                                <StyledFormHelperText>{encryptionField.helper}</StyledFormHelperText>
                            </div>
                            <CustomSwitches
                                disabled={!isCertificateGenerated}
                                color={'primary'}
                                name={encryptionField.fieldName}
                                data={{ label: '', value: null }}
                            />
                        </FormControl>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
};
export default EncryptionSettings;
