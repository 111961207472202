import React from 'react';
import { StyledButton } from '@walkme-admin-center/libs/shared-styles';
import {ButtonProps as ButtonBaseProps, CircularProgress} from '@material-ui/core';

interface ButtonProps {
    customClass?: string;
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: unknown;
    disabled?: boolean;
    color?: unknown;
    type?: string;
    loading?: boolean;
}

const CustomButton = (props) => <StyledButton {...props} />;

export const Button = ({
    customClass,
    text,
    onClick,
    disabled,
    color,
    loading,
    children,
    type,
    ...others
}: ButtonBaseProps<undefined, ButtonProps>) => {
    return (
        <CustomButton
            disabled={disabled}
            variant="contained"
            onClick={onClick}
            color={color}
            className={customClass ? customClass : ''}
            type={type}
            {...others}
        >
            {loading && (
                <><CircularProgress size={14} color='inherit'/>&nbsp;</> // Size 14 works pretty well
            )}
            {children}
            {text}
        </CustomButton>
    );
};

export default Button;
