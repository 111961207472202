import React, { useState, MouseEvent, useRef } from 'react';
import { DropdownChevronDisplayOptions } from '@walkme/ui-core';
import { StyledDivider, StyledSelector, StyledMenuItem, ExpectedFormatSelectorDiv, UuidButtonText } from './select-environment.styles';
import { DropdownOption } from './uuid/uuid.lib';
import { StyledUUIDMenu, StyledUUIDMenuWrapper, StyledWMMenu } from './uuid/uuid.styles';
import { Check } from '@walkme/ui-icons/large';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface SelectEnvironmentProps {
    selectedEnvironment: DropdownOption;
    environmentOptions: DropdownOption[];
    setSelectedEnvironment: (selectedEnvironment: DropdownOption) => void;
}

export function SelectEnvironment({ selectedEnvironment, environmentOptions, setSelectedEnvironment }: SelectEnvironmentProps) {
    const { t } = useTranslation('general');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const handleChangeSelection = (selectedFormat: DropdownOption) => {
        setSelectedEnvironment(selectedFormat);
        handleClose();
    };

    const SelectorDisplayedValue = (
        <ExpectedFormatSelectorDiv>
            <UuidButtonText>{selectedEnvironment?.label}</UuidButtonText>
        </ExpectedFormatSelectorDiv>
    );

    const chevronDisplayOption = DropdownChevronDisplayOptions.DEFAULT;

    return (
        <div>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
            />
            <StyledWMMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={430}>
                <StyledDivider />
                <div>
                    {environmentOptions?.map((envOption: DropdownOption, index) => {
                        return (
                            <StyledMenuItem
                                id={envOption.value}
                                onClick={() => handleChangeSelection(envOption)}
                                isSelected={envOption.value === selectedEnvironment?.value}>
                                <span>{envOption.label}</span>
                                {envOption.value === selectedEnvironment?.value ? <Check color='#395eea' /> : null}
                            </StyledMenuItem>
                        );
                    })}
                </div>
            </StyledWMMenu>
        </div>
    );
}
