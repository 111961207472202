import React from 'react';
import CustomToolTip from '../../../../sso-configuration/src/lib/common/components/tool-tip/tool-tip';

export const shrinkText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength - 3) + '...';
    }
};

export const getToolTip = (toolTip: string, noPadding = false): React.ReactNode => {
    return (
        <CustomToolTip placement={'top-start'} title={toolTip}>
            <img style={{ paddingLeft: noPadding ? '0' : '8px' }} src={'assets/icons/data-integrations/info-icon-small.svg'} />
        </CustomToolTip>
    );
};
