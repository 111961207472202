import styled from 'styled-components';
import { Divider, DropdownSelection } from '@walkme/ui-core';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 40px;
        width: 430px;
        margin: 0;
        outline: none !important;
    }
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const MenuItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const MenuItemText = styled.div`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
`;

export const ApplyButton = styled.div`
    display: flex !important;
    height: 48px !important;
    padding: 14px 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    width: 100% !important;
`;

export const TagLength = styled.div`
    border-radius: 4px;
    padding: 4px 8px;
    background: var(--blue-primary-primary-tint-9, #fbfcff);
    color: var(--infographic-blue-blue-shade-1, #162a69);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const ExpectedFormatSelectorDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const UuidText = styled.div`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;

export const UuidButtonText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
    color: ${({ isSelected }) => (isSelected ? '#385FEB' : '#2F426C')};

    &:hover {
        background: var(--Primaries-Blue-P8, #f5f7fe);
    }
`;
