import { TableRowData, TableCustomCell } from '@walkme-admin-center/libs/types';

export interface IdpRow extends TableRowData {
    idpName: string;
    idpType: string;
    creationDateFormatted: string | null;
    idpUsers: number;
    idpStatus: TableCustomCell;
    removeButton?: TableCustomCell;
}

export enum IdpConfigStatus {
    idpCreated = 0,
    idpConfiguration = 1,
    idpCustomerConnection = 2,
    assignUsers = 3,
    activated = 4,
}

export interface IDpConfigSteps {
    status: IdpConfigStatus;
    displayName: string;
    buttonTextOnChange: string;
    buttonTextNoChange: string;
    hideInWizard?: boolean;
}

export const IdpConfigStepsData: IDpConfigSteps[] = [
    {
        status: IdpConfigStatus.idpCreated,
        displayName: 'security-tab.idps.setup.step-1.title',
        buttonTextOnChange: 'buttons.save-and-next',
        buttonTextNoChange: 'buttons.next',
    },
    {
        status: IdpConfigStatus.idpConfiguration,
        displayName: 'security-tab.idps.setup.step-2.short-title',
        buttonTextOnChange: 'buttons.next',
        buttonTextNoChange: 'buttons.next',
    },
    {
        status: IdpConfigStatus.idpCustomerConnection,
        displayName: 'security-tab.idps.setup.step-3.title',
        buttonTextOnChange: 'buttons.save-and-next',
        buttonTextNoChange: 'buttons.next',
    },
    {
        status: IdpConfigStatus.assignUsers,
        displayName: 'security-tab.idps.setup.step-4.short-title',
        buttonTextOnChange: 'buttons.save-and-finish',
        buttonTextNoChange: 'buttons.finish',
    },
    {
        status: IdpConfigStatus.activated,
        displayName: 'common.active',
        buttonTextOnChange: 'buttons.finish',
        buttonTextNoChange: 'buttons.finish',
        hideInWizard: true,
    },
];
