import React from 'react';
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

export const IconInfo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path fill={props.htmlColor || props.color  || '#78868F'}
            transform="translate(-2.5 -3)"
            d="M11.826087,3.1 C16.6453717,3.1 20.5521739,7.0068022 20.5521739,11.826087 C20.5521739,16.6453717 16.6453717,20.5521739 11.826087,20.5521739 C7.0068022,20.5521739 3.1,16.6453717 3.1,11.826087 C3.1,7.0068022 7.0068022,3.1 11.826087,3.1 Z M11.826087,4.9 C8.00091475,4.9 4.9,8.00091475 4.9,11.826087 C4.9,15.6512592 8.00091475,18.7521739 11.826087,18.7521739 C15.6512592,18.7521739 18.7521739,15.6512592 18.7521739,11.826087 C18.7521739,8.00091475 15.6512592,4.9 11.826087,4.9 Z M11.3043478,10.0326087 C11.9628123,10.0326087 12.5043954,10.5330377 12.5695211,11.1743199 L12.576087,11.3043478 L12.576087,13.9130435 C12.576087,14.0519723 12.6726218,14.1683541 12.8022733,14.1987708 L12.8695652,14.2065217 L13.3913043,14.2065217 C13.8055179,14.2065217 14.1413043,14.5423082 14.1413043,14.9565217 C14.1413043,15.3362175 13.8591505,15.6500127 13.4930749,15.6996751 L13.3913043,15.7065217 L12.8695652,15.7065217 C11.9285801,15.7065217 11.1568526,14.9818435 11.0820323,14.0601366 L11.076087,13.9130435 L11.076,11.532 L10.7826087,11.5326087 C10.4029129,11.5326087 10.0891177,11.2504548 10.0394553,10.8843793 L10.0326087,10.7826087 C10.0326087,10.4029129 10.3147626,10.0891177 10.6808381,10.0394553 L10.7826087,10.0326087 L11.3043478,10.0326087 Z M11.5652174,7.42391304 L11.6753628,7.42984469 C12.181838,7.48472708 12.576087,7.91371395 12.576087,8.43478261 C12.576087,8.99307045 12.1235052,9.44565217 11.5652174,9.44565217 C11.0069295,9.44565217 10.5543478,8.99307045 10.5543478,8.43478261 C10.5543478,7.91371395 10.9485968,7.48472708 11.455072,7.42984469 L11.5652174,7.42391304 Z" />
    </SvgIcon>
  );
}
