import React from 'react';
import {SvgIcon} from '@material-ui/core';
import styled from "styled-components";

const StyledContainer = styled.span`
    svg {
        width: 11px;
        height: 11px;
        top: 1px;
        position: relative;
        cursor: pointer;
    }
`;

export const LinkIcon = (props) => {
    return (
        <StyledContainer>
            <SvgIcon
                width={12}
                height={12}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g clipPath="url(#prefix__clip0_1_2)" fill="#385FEB">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 1.5a.5.5 0 00-.5-.5H2a2 2 0 00-2 2v7a2 2 0 002 2h7a2 2 0 002-2V6.5a.5.5 0 00-1 0V10a1 1 0 01-1 1H2a1 1 0 01-1-1V3a1 1 0 011-1h3.5a.5.5 0 00.5-.5z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.854 1.146a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708l4-4a.5.5 0 01.708 0z"
                    />
                    <path d="M11.5 0H8.207a.5.5 0 00-.353.854l3.292 3.292A.5.5 0 0012 3.793V.5a.5.5 0 00-.5-.5z"/>
                </g>
                <defs>
                    <clipPath id="prefix__clip0_1_2">
                        <path fill="#fff" d="M0 0h12v12H0z"/>
                    </clipPath>
                </defs>
            </SvgIcon>
        </StyledContainer>
    );
}
