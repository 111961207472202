import React, { useCallback, useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { Divider } from '@walkme/ui-core';
import { ChevronDown, ChevronUp } from '@walkme/ui-icons/large';
import {
    StyledListItemText,
    StyledListItem,
    StyledCollapse,
    StyledDrawer,
    StyledMenuList,
    StyledChildrenMenuList,
    StyledDockButton,
    StyledTooltipTitle,
    StyledTooltipContentText,
    StyledTooltipContent,
    StyledTooltip,
    IconContainer,
} from './side-menu.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { ErrorColor } from '@walkme/ui-icons';

interface DockButtonProps {
    isExpanded: boolean;
    isDocked: boolean;
    onDockClicked: () => void;
}

const DockIcon = ({ isExpanded, onDockClicked, isDocked }: DockButtonProps) => {
    return (
        <StyledDockButton showDockIcon={isExpanded || isDocked} onClick={onDockClicked}>
            {isDocked ? <img alt={'more'} src={`/assets/Collapse.svg`} /> : <img alt={'more'} src={`/assets/Expand.svg`} />}
        </StyledDockButton>
    );
};

export interface Tab {
    label: string;
    to: string;
    index?: number;
    labelNewTag?: boolean;
    icon?: JSX.Element;
    errorKey?: string;
    children?: Tab[];
    hidden?: boolean;
    divider?: boolean;
}

export interface SideMenuProps {
    tabs: Tab[];
    path: string;
}
export interface ChildrenMenuProps {
    tab: Tab;
    isRouteSelected: (val) => boolean;
    isOpened: boolean;
    setIsOpened: (val) => void;
}

export const SideMenu = ({ tabs, path }: SideMenuProps) => {
    const currentRoute = path;
    const { t } = useTranslation('general');

    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const [isDocked, setIsDocked] = useState<boolean>(true);
    //const [isOpened, setIsOpened] = useState<boolean>(false);

    // const ListItemClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //     setIsOpened(false);
    // },[]);

    const ChildrenMenuList = ({ tab, isRouteSelected, isOpened, setIsOpened }: ChildrenMenuProps) => {
        const isChildSelected = tab?.children?.some((child) => {
            return isRouteSelected(child.to);
        });
        return (
            <div
                style={{
                    background: (isExpanded || isDocked) && (isOpened || isChildSelected) ? '#162457' : '',
                    borderRadius: '8px',
                    width: '100% ',
                    padding: '2px 0',
                }}
                key={tab.to}>
                <ListItem
                    className={`${
                        isExpanded || isDocked
                            ? `expanded-listItem${isOpened || isChildSelected ? '-opened' : ''}`
                            : `minimize-listItem${isChildSelected ? '-opened' : ''}`
                    }`}
                    component={StyledListItem}
                    key={tab.to}
                    to={currentRoute}
                    onClick={(event) => setIsOpened(!isOpened)}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }} key={tab.to}>
                        {tab.icon ?? null}
                        {isExpanded || isDocked ? <StyledListItemText primary={tab.label} disableTypography /> : null}
                    </div>
                    {isExpanded || isDocked ? (
                        isOpened || isChildSelected ? (
                            <ChevronUp style={{ color: 'white' }} />
                        ) : (
                            <ChevronDown style={{ color: 'white' }} />
                        )
                    ) : null}
                </ListItem>
                {(isChildSelected || isOpened) && (isExpanded || isDocked) && (
                    <Divider style={{ margin: '0 12px 0 ', borderColor: '#1B2D6C' }} />
                )}
                <StyledCollapse
                    style={{ borderRadius: '0 0 8px 8px', display: 'flex' }}
                    in={(isChildSelected || isOpened) && (isExpanded || isDocked)}
                    timeout='auto'>
                    <StyledChildrenMenuList
                        $isExpanded={isExpanded}
                        $isDocked={isDocked}
                        disablePadding
                        style={{
                            // width: 'calc(100% - 16px)',
                            padding: '8px ',
                        }}>
                        {tab?.children?.map(MenuItem)}
                    </StyledChildrenMenuList>
                </StyledCollapse>

                {tab.divider && <Divider style={{ marginTop: '15px', borderColor: '#1B2D6C' }} />}
            </div>
        );
    };

    const MenuItem = ({ to, label, children, icon: Icon, errorKey, hidden, divider }: Tab) => {
        const [isOpened, setIsOpened] = useState<boolean>(false);
        const ListItemClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setIsOpened(false);
        }, []);

        const isRouteSelected = (to: string) => {
            return (
                to === currentRoute ||
                '/' + to === currentRoute ||
                (currentRoute !== '/' && to.includes(currentRoute)) ||
                currentRoute.includes(to) ||
                selectedMenuItem === to
            );
        };

        useEffect(() => {
            if (selectedMenuItem === to) {
                setIsOpened(true);
            } else {
                setIsOpened(false);
            }
        }, [selectedMenuItem]);

        if (hidden) {
            return null;
        }

        if (children) {
            return (
                <ChildrenMenuList
                    tab={{ to, label, children, icon: Icon, hidden, divider }}
                    isRouteSelected={isRouteSelected}
                    isOpened={isOpened}
                    setIsOpened={setIsOpened}
                />
            );
        }

        const isItemSelected = isRouteSelected(to);
        const handleMenuItemClick = () => {
            setSelectedMenuItem(to);
        };

        const ErrorIndicator = ({ errorKey }) => {
            return (
                <StyledTooltip
                    arrow
                    placement={'top-start'}
                    maxWidth={336}
                    title={
                        <StyledTooltipContent>
                            <StyledTooltipTitle>{t(`errors.${errorKey}.title`)}</StyledTooltipTitle>
                            <StyledTooltipContentText>{t(`errors.${errorKey}.message`)}</StyledTooltipContentText>
                        </StyledTooltipContent>
                    }>
                    <IconContainer className={'error-icon'}>
                        <ErrorColor />
                    </IconContainer>
                </StyledTooltip>
            );
        };

        return (
            <div style={{ width: '100%', padding: '2px 0' }} key={to}>
                <ListItem
                    className={`${isExpanded || isDocked ? 'expanded' : 'minimize'}-listItem${isItemSelected ? ' listItem-activated' : ''}`}
                    component={StyledListItem}
                    selected={isItemSelected}
                    onClick={(event) => handleMenuItemClick()}
                    to={`/${to}`}
                    key={to}>
                    {Icon ?? null}
                    {isExpanded || isDocked ? <StyledListItemText primary={label} disableTypography /> : null}
                    {errorKey && (isExpanded || isDocked) ? <ErrorIndicator errorKey={errorKey} /> : null}
                </ListItem>
                {divider && <Divider style={{ marginTop: '15px', borderColor: '#1B2D6C' }} />}
            </div>
        );
    };
    const renderMenu = () => {
        return (
            <StyledMenuList $isExpanded={isExpanded} $isDocked={isDocked} disablePadding>
                <DockIcon
                    onDockClicked={() => {
                        setIsDocked((prevState) => !prevState);
                        setIsExpanded((prevState) => !prevState);
                    }}
                    isExpanded={isExpanded}
                    isDocked={isDocked}
                />
                {tabs.map(MenuItem)}{' '}
            </StyledMenuList>
        );
    };

    const onMinimizedHover = () => {
        if (!isExpanded) {
            setIsExpanded(true);
        }
    };

    const onMinimizedHoverOut = () => {
        if (isExpanded) {
            setIsExpanded(false);
        }
    };

    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    return (
        <StyledDrawer
            id='sideMenu'
            variant='permanent'
            classes={{
                paper: 'paper',
            }}
            anchor='left'
            onMouseEnter={onMinimizedHover}
            onMouseLeave={onMinimizedHoverOut}
            $isExpanded={isExpanded}
            $isDocked={isDocked}>
            {renderMenu()}
        </StyledDrawer>
    );
};

export default SideMenu;
