import { TableCustomCell } from '..';

export type RoleRow = {
    role: TableCustomCell;
    users: TableCustomCell;
    permissions: TableCustomCell;
    deleteButton: TableCustomCell;
    accountId: number;
    roleId: number;
};
