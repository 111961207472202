import styled from 'styled-components';
import { InputBase, Select, withStyles } from '@material-ui/core';

export const StyledSelect = styled(Select)`
    & .MuiSelect-root {
        border: solid 1px #f6f8fc;
        background: #f6f8fc;
        border-radius: 4px !important;
        padding: 4px 40px 4px 12px !important;
        height: 32px;
        line-height: 32px;
    }

    .MuiSelect-icon {
        & .MuiSelect-iconOpen {
            padding-bottom: 2px;
        }

        &:not(.MuiSelect-iconOpen) {
            padding-top: 2px;
        }
    }
`;

export const BootstrapInput = withStyles((theme) => ({
    root: {
        '&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiSelect-root': {
            borderColor: '#354b9b40',
        },
    },
    focused: {
        '& .MuiSelect-root': {
            background: '#ffffff',
            borderColor: '#385FEB',
        },
    },
}))(InputBase);

export const RadioContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RadioContextContainer = styled.div`
    display: flex;
`;

export const RadioContextContainerWithSelect = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RadioLabel = styled.span`
    padding-left: 8px;
    color: var(--LS-Color-Text-On-light-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
`;

export const MainContainer = styled.div`
    padding: 1.25rem 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    border-top: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #e3e9fc);
`;

export const RadioSecondaryContainer = styled.div`
    padding-left: 1.75rem;
    display: flex;
    flex-direction: column;
`;

export const AuthDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding-top: 1rem;
`;

export const AccordionSummaryWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;

    &.flex {
        flex: 1 0 0;
    }

    .accordion-summary-wrapper-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;
