import styled from 'styled-components';
import { Button, Input } from '@walkme/ui-core';

export const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
    align-items: center;
`;

export const StyledCustomApp = styled.div`
    padding: 48px 12px 20px 12px;
    display: flex;
    flex-direction: column;
`;

export const StyledHeader = styled.div`
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
`;

export const StyledInputContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const StyledInputLabel = styled.div`
    display: flex;
    height: var(--size-global-s-8040, 40px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const StyledInput = styled(Input)`
    & input:focus {
        border-width: 1px;
        outline: none;
    }

    & input:disabled {
        background-color: var(--Primaries-Disable-Disable-tint-8, #f8f8f8);
        color: rgba(200, 200, 200, 1);
    }
`;

export const StyledCustomName = styled.div`
    margin-top: 32px;
`;

export const StyledBack = styled.div`
    display: flex;
    gap: 4px;
    margin-bottom: 8px;

    &:hover .chevron-left {
        color: rgb(43, 71, 172);
    }

    &:hover .back-to-app-gallery {
        color: rgb(43, 71, 172);
    }
`;

export const StyledButton = styled(Button)`
    color: var(--Primaries-Typography-Typography-Main, #2f426c);
    text-align: center;
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
