import React from 'react';
import { StyledQontoStepIcon } from './stepper-ocpms.styles';

export const QontoStepIcon = (props) => {
    const { active, completed, icon } = props;

    return (
        <StyledQontoStepIcon className={`root ${active ? 'active' : ''} ${completed ? 'completed' : ''} iconContainer`}>
            {completed ? (
                <div>
                    <img src='assets/icons/check-primary.svg' />
                </div>
            ) : (
                <div>{icon}</div>
            )}
        </StyledQontoStepIcon>
    );
};
export default QontoStepIcon;
