import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useGetSelfDomainsForSystem = () => {
    const getSelfDomainsForSystemAppData: AppData<string[]> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.getSelfDomainsForSystem
    );

    return { getSelfDomainsForSystemAppData };
};

export default useGetSelfDomainsForSystem;
