import { SystemsSettings } from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CreateSystemState,
    getSystemApps,
    SystemAppQueryFields,
    SystemAppSortByOptions,
    SystemAppSortFilters,
} from '@walkme-admin-center/libs/state-management-systems';
import { generatePaginationFilterDto } from '../redux/createSystem.lib';

export interface SystemAppsFilterDto {
    sortBy: SystemAppSortByOptions;
    sortOrder: 1 | -1;
    filterIn: SystemAppQueryFields;
    filterOut: SystemAppQueryFields;
}

export const useSystemApps = () => {
    const systemAppsAppData: AppData<SystemsSettings> = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.systemApps
    );

    const systemAppPagesInfo: any = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.systemAppPagesInfo.data
    );

    const selectedSortFilters: SystemAppSortFilters = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSortFilters.data
    );

    const selectedAppFilters: SystemAppQueryFields = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedAppFilters.data
    );

    const appSearchFilterText = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.appSearchFilterText.data
    );

    const selectedSystemApp = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSystemApp.data
    );

    const systemUsage = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.usage.data);

    const selectedFilters = {
        selectedSortFilters,
        selectedAppFilters,
        appSearchFilterText,
    };

    const generatedPaginationFilterDto = generatePaginationFilterDto({
        searchText: appSearchFilterText,
        appFilters: selectedAppFilters,
        sortFilters: selectedSortFilters,
        systemUsage,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (dispatch) {
            dispatch(
                getSystemApps({
                    forceLoad: true,
                    page: 1,
                })
            );
        }
    }, [selectedSortFilters, selectedAppFilters, appSearchFilterText, systemUsage]);

    return {
        systemAppsAppData,
        selectedFilters,
        systemAppPagesInfo,
        selectedSystemApp,
        generatedPaginationFilterDto,
    };
};

export default useSystemApps;
