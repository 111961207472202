export const languages = {
    english: {
        name: 'English',
        key: 'en',
    },
    japanese: {
        name: '日本語',
        key: 'jp',
    },
    french: {
        name: 'Français',
        key: 'fr',
    },
    gernman: {
        name: 'Deutsch',
        key: 'de',
    },
};
