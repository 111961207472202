import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvites, PartnersState } from '../redux/partners.slice';

export const useInvites = () => {
    const invitesAppData = useSelector((state: { partnersState: PartnersState }) => state.partnersState.invites);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInvites());
    }, [dispatch]);

    return {
        invitesAppData,
    };
};

export default useInvites;
