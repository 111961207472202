import { WMTextField } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';
import { ValidationResult, getRegexValidationResultNew } from 'packages/pages/util/validation';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { URLMatchData } from '../url-verifier/values';

const RegexContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    .marked {
        background-color: #d8dffb;
    }
`;

const REGEX_URL_PLACEHOLDER = 'example.com';

interface RegexProps {
    value: string;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setValue: (newValue: string) => void;
    urlVerifierMatchData?: URLMatchData[];
}

const Regex = ({ value, setIsValid, setValue, urlVerifierMatchData }: RegexProps) => {
    const [currentValue, setCurrentValue] = useState<string>(value);
    const { t } = useTranslation('general');

    const regexValidationResult: ValidationResult = getRegexValidationResultNew(currentValue, t);
    const textFieldStatus = regexValidationResult.isValid ? 'default' : 'error';
    setIsValid(regexValidationResult.isValid);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const saveIfValid = (): void => {
        if (regexValidationResult.isValid) {
            setValue(currentValue);
        }
    };

    const onKeyPress = (event: KeyboardEvent<any>) => {
        if (event.key === 'Enter') {
            saveIfValid();
        }
    };

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => setCurrentValue(event.target.value as string);
    const isMatchingURLVerifier = urlVerifierMatchData?.find((matchData) => matchData.selfDomainOrRegex === value)?.isMatching;

    return (
        <RegexContainerDiv>
            <WMTextField
                value={currentValue}
                onChange={onValueChanged}
                helperText={regexValidationResult.errorMessage}
                placeholder={REGEX_URL_PLACEHOLDER}
                onKeyPress={onKeyPress}
                status={textFieldStatus}
                style={{ marginTop: '10px' }}
                onBlur={saveIfValid}
                ds2
                autoFocus
                InputProps={{
                    classes: {
                        input: isMatchingURLVerifier ? 'marked' : undefined,
                    },
                }}
            />
        </RegexContainerDiv>
    );
};

export default Regex;
