import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDesktopTrackedApps, DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useDesktopTrackedApps = (msaId: number) => {
    const desktopTrackedAppsData = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.desktopTrackedApps
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDesktopTrackedApps(msaId));
    }, [dispatch]);

    return {
        desktopTrackedAppsData,
    };
};

export default useDesktopTrackedApps;
