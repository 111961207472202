import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    overflow-x: auto;
    height: calc(100% - 90px);
`;

export const StyledDialog = styled(Dialog)`
    .paper {
        border-radius: 12px;
        height: 100%;
        top: 28px;
        max-height: calc(100% - 152px);
        margin: 0 48px;
    }

    .container {
        & .MuiDialog-paper {
            overflow-y: hidden;
            max-width: 100%;
        }
    }
`;
