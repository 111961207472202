import React from 'react';

const VALID_URL_REGEX = /^((?:https?:\/\/)|\/)[^\/].+[^\/]$/i;

const urlFieldConfig = {
    validate: (value: string) => (VALID_URL_REGEX.test(value) ? undefined : 'The URL format is invalid'),
    placeholder: 'Enter URL',
    getTooltip: () => (
        <span>
            <div>The URL should start with "http://", "https://"</div>
            <div>or "/" (relative path) and should not end with "/"</div>
        </span>
    ),
};

export { urlFieldConfig };
