import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, UsersState } from '../redux/users.slice';

export const useUsers = (forceLoad = false) => {
    const usersAppData = useSelector((state: { usersState: UsersState }) => state.usersState.users);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers(forceLoad));
    }, [dispatch]);

    return {
        usersAppData,
    };
};

export default useUsers;
