import styled from 'styled-components';

export const StyledDataCollectionOption = styled.div<{ isLast: boolean }>`
    display: flex;
    margin-top: 12px;
    .radioBtn {
        padding-top: 2px;
    }

    .description {
        margin-left: 8px;
        border-bottom: ${(props) => (props.isLast ? 'none' : '1px solid #f1f3f9')};
        width: 90%;
        .main-title {
            color: #2f426c;
        }

        .bullets {
            margin: 12px 0 8px 0;
            .bullet {
                display: flex;
                margin-top: 4px;
                .check {
                }
                .text {
                    color: #637191;
                    margin-left: 8px;
                }
            }
        }
    }
`;

export const StyledDataCollectionOptionTitle = styled.div`
    .learn-more {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;
