import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { DeleteServiceApplicationDTO } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/applications-types';
import { cmtApi, DeployablesTypes, SystemDeployableCountItem } from '@walkme-admin-center/libs/cmt-api';
import rootReducer from 'apps/home/src/redux/rootReducer';

export interface DeployablesState {
    systemsDeployablesCount: AppData<SystemDeployableCountItem[]>;
    realDeployablesTypes: AppData<DeployablesTypes[]>;
}

export const initialDeployablesState: DeployablesState = {
    systemsDeployablesCount: {
        loading: false,
        error: null,
        data: [],
    },
    realDeployablesTypes: {
        loading: false,
        error: '',
        data: [],
    },
};

const deployablesSlice = createSlice({
    name: 'deployablesSlice',
    initialState: initialDeployablesState,
    reducers: {
        getSystemsDeployablesCountStart(state: DeployablesState) {
            state.systemsDeployablesCount.error = null;
            state.systemsDeployablesCount.loading = true;
            return state;
        },
        getSystemsDeployablesCountSuccess(state: DeployablesState, action: PayloadAction<SystemDeployableCountItem[]>) {
            state.systemsDeployablesCount.data = action.payload;
            state.systemsDeployablesCount.loading = false;
            state.systemsDeployablesCount.error = null;
            return state;
        },
        getSystemsDeployablesCountFailed(state, action: PayloadAction<string>) {
            state.systemsDeployablesCount.loading = false;
            state.systemsDeployablesCount.error = action.payload;
            return state;
        },
        getDeployablesTypeStart(state: DeployablesState) {
            state.realDeployablesTypes.error = null;
            state.realDeployablesTypes.loading = true;
            return state;
        },
        getDeployablesTypeSuccess(state: DeployablesState, action: PayloadAction<DeployablesTypes[]>) {
            state.realDeployablesTypes.data = action.payload;
            state.realDeployablesTypes.loading = false;
            state.realDeployablesTypes.error = null;
            return state;
        },
        getDeployablesTypeFailed(state, action: PayloadAction<string>) {
            state.realDeployablesTypes.loading = false;
            state.realDeployablesTypes.error = action.payload;
            return state;
        },
    },
});

export { deployablesSlice };
const {
    getSystemsDeployablesCountStart,
    getSystemsDeployablesCountSuccess,
    getSystemsDeployablesCountFailed,
    getDeployablesTypeFailed,
    getDeployablesTypeSuccess,
    getDeployablesTypeStart,
} = deployablesSlice.actions;
type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const getSystemsDeployablesCount = (forceLoad = false): AppThunk => async (dispatch, getState) => {
    try {
        const alreadyLoadedSystemsDeployablesCount = getState().deployablesState.systemsDeployablesCount;
        if (alreadyLoadedSystemsDeployablesCount.data.length > 0 && !forceLoad) {
            return;
        }
        dispatch(getSystemsDeployablesCountStart());
        const deployables: SystemDeployableCountItem[] = await cmtApi.getSystemsDeployablesCount();
        dispatch(getSystemsDeployablesCountSuccess(deployables || []));
    } catch (err) {
        dispatch(getSystemsDeployablesCountFailed(err));
        return;
    }
};

export const deployablesTypes = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(getDeployablesTypeStart());
        const deployables: DeployablesTypes[] = await cmtApi.deployablesTypes();
        dispatch(getDeployablesTypeSuccess(deployables));
    } catch (err) {
        dispatch(getDeployablesTypeFailed(err.message));
        return;
    }
};
