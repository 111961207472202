import styled from 'styled-components';

export const DataIntegrationsListContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
`;

export const IntegrationCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 274px;
    height: 158px;
    padding: 20px;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #e3e9fc);
`;

export const IntegrationCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .integration-title {
        gap: 12px;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: row;
    }
`;

export const IntegrationCardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    height: auto;
`;

export const IntegrationName = styled.span`
    color: #2f426c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const IntegrationLogoContainer = styled.div`
    display: flex;
    width: 36px;
    height: 36px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #f0f3f9;
`;

export const IntegrationInfo = styled.div`
    display: flex;
    padding: 2px 0px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    height: 80px;
`;

export const IntegrationCardFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    height: 28px;
    width: 100%;
`;

export const EnableButton = styled.div<{ enabled: boolean }>`
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;

    .enable-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    ${(props) =>
        props.enabled
            ? `
    border: 1px solid rgba(59, 97, 235, 0.20);
    padding: 6px 15px 6px 10px;
    background: #FFF;
    width: 65px;
    color: #3B61EB;
    align-items: center;
    &:hover {
    background-color: #F5F5F5;
    box-shadow: none;
    }
      `
            : `
    padding: 6px 14px;
    background: #385FEB;
    width: 40px;
    color: #ffffff;
    align-items: flex-start;
    &:hover {
    background-color: #2C49B0;
    box-shadow: none;
    }
    `}
`;

export const DeleteModalContainer = styled.div`
    z-index: 999;
    display: flex;
    width: 543px;
    height: 358px;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 85px;
    bottom: 0;
    margin: auto;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 36px 52px 0px rgba(47, 61, 83, 0.11);
`;

export const DeleteModalHeader = styled.div`
    display: flex;
    padding: 24px 28px 16px 32px;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px 12px 0px 0px;
    background: #fff;
`;
export const DeleteHeaderText = styled.div`
    color: #2f426c;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const DeleteWarningText = styled.div`
    height: 4px;
    padding: 24px 32px 12px 32px;
    align-self: stretch;
    color: #2f426c;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const DeleteModalToastContainer = styled.div`
    display: flex;
    padding: 10px 32px 0px 32px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #fff;
`;

export const DeleteToast = styled.div`
    display: flex;
    width: 445px;
    height: 42px;
    padding: 8px 16px;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #f7b500;
    background: #fef8e8;
    box-shadow: 0px 12px 24px 0px rgba(247, 181, 0, 0.07);
`;

export const ActionListContainer = styled.div`
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;

    .action-items-list {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
    }
`;

export const ActionItem = styled.li`
    padding: 8px 0px;
    align-self: stretch;
    color: #2f426c;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const ModalToastBody = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
`;
