import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const FooterStyledButton = styled(Button)`
    height: 40px;
    border-radius: 40px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 10px 20px;
    box-shadow: none;
    background-color: #385feb;
    color: #ffffff;
    text-transform: none;
    &.next-button {
        width: 80px;
    }
    &.Mui-disabled {
        background: #c5cad6;
        color: #ffffff;
    }
    &:hover:not(.Mui-disabled) {
        background-color: #2c49b0;
        box-shadow: none;
    }
`;

export const FooterCancelButton = styled(Button)`
    margin-right: 14px;
    border-radius: 40px;
    border: 0;
    height: 40px;
    width: 83px;
    padding: 10px 20px;
    background: none;
    color: #637191;
    text-transform: none;
    box-shadow: none;
    transition: color 250ms ease;
    &:hover {
        color: #385feb;
        background: none;
        box-shadow: none;
    }

    &.button-flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

export const ResetButtonContainer = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;

    & svg path {
        transition: stroke 0.3s ease;
    }

    &:not(.Mui-disabled) {
        .MuiButton-label {
            color: var(--LS-Color-Action-Content-Text-primary-default, #385feb);
        }

        svg path {
            stroke: #385feb;
        }
    }

    &.Mui-disabled {
        .MuiButton-label {
            color: #767676;
        }

        svg path {
            stroke: #767676;
        }
    }

    .MuiButton-label {
        align-items: center;
        gap: 0.5rem;
        font-family: 'Proxima Nova';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 0.5rem;
        text-transform: none;
    }
`;

export const FooterActionsSubContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const FooterActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
`;

export const SmallerText = styled.p`
    margin: 0;
    font-size: 12px;
    line-height: 16px;
`;
