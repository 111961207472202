import React, { useCallback } from 'react';
import { Credentials } from './credentials/credentials';
import { AppBar, TopBarItem } from '@walkme-admin-center/libs/ui-components';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import { Button, ButtonGroup, Container, Link, MenuItem } from '@material-ui/core';
import { ImpersonationIndication } from 'wm-accounts-auth';
import { AccountIndication } from '@walkme/account-indication';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

const StyledImpersonationIndication = styled.div`
    margin-right: 30px;
    display: flex;
    gap: 12px;
    flex-direction: row-reverse;
`;

const {
    colors: { appBarFirstTextColor, appBarSecondTextColor },
} = styledTheme;

const StyledLink = styled(Link)`
    margin: 0 20px 0 0;
    font-size: 14px;
    font-weight: 400;
    font-family: 'ProximaNova';
    cursor: pointer;
    text-transform: none;
`;

const RowContainer = styled(Container)`
    flex-direction: row;
    flex-grow: 4;
    justify-content: center;
`;

const CenterDiv = styled.div`
    margin: 0 auto;
    display: flex;
    border-radius: 4px;
    padding: 2px;
    align-items: center;
`;

const StyledImage = styled.img`
    margin-right: 5px;
    display: flex;
    align-items: center;
`;

const StyledIconText = styled.div`
    color: ${appBarFirstTextColor};
    margin-left: 5px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    font-family: 'Poppins';
`;

const StyledIconTextColor = styled.div`
    color: ${appBarSecondTextColor};
    margin-left: 5px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    font-family: 'Poppins';
`;

export const TopBar = () => {
    const { t } = useTranslation('general');
    const navigate = useNavigate();
    const { loggedInUserAppData } = useLoggedInUser();

    const dispatch = useDispatch();

    const onImpersonateClicked = useCallback(
        (stop: boolean) => {
            const { href } = window.location;
            const impersonationUrl = window.clientConfig.NX_IMPERSONATION_URL;
            window.location.replace(`${impersonationUrl}?action=${stop ? 'stop' : 'start'}&returnUrl=${href}`);
        },
        [loggedInUserAppData, dispatch]
    );

    const onSwitchAccountClick = () => {
        const { href } = window.location;
        const userPortalUrl = `${window.clientConfig.NX_USER_PORTAL_URL}/accounts?action=switch&returnUrl=${decodeURIComponent(href)}`;
        window.location.replace(userPortalUrl);
    };

    const logo = (
        <RowContainer>
            <CenterDiv>
                <StyledImage src='/assets/walkme_admin_logo.svg' />
                <StyledIconText>{t('top-bar.walkme')}</StyledIconText>
                <StyledIconTextColor>{t('top-bar.admin')}</StyledIconTextColor>
            </CenterDiv>
        </RowContainer>
    );
    const credentials = <Credentials loading={loggedInUserAppData.loading} user={loggedInUserAppData.data} />;
    const insightsUrl = window.clientConfig.NX_INSIGHTS_URL;
    const shareUrl = window.clientConfig.NX_SHARE_URL;

    const menuItems: TopBarItem[] = [
        {
            name: t('top-bar.insights'),
            onClick: () => window.open(insightsUrl, '_blank'),
        },
    ];

    const leftMenuItems = menuItems.map((item) => {
        return (
            <StyledLink variant='button' underline='none' color='textPrimary' onClick={item.onClick}>
                {item.name}
            </StyledLink>
        );
    });

    const centerMenuItems = [
        <StyledImpersonationIndication>
            {loggedInUserAppData.data && loggedInUserAppData.data.impersonator && (
                <ImpersonationIndication
                    disabled={false}
                    email={loggedInUserAppData.data && loggedInUserAppData.data.impersonator ? loggedInUserAppData.data.email : null}
                    onStop={() => onImpersonateClicked(true)}
                    onEdit={() => onImpersonateClicked(false)}
                />
            )}
            {loggedInUserAppData.data && loggedInUserAppData.data.userAccountTag === 'partner' && (
                <AccountIndication
                    disabled={!!(loggedInUserAppData.data && loggedInUserAppData.data.impersonator)}
                    partnerAccountName={
                        loggedInUserAppData.data && loggedInUserAppData.data.userAccountTag === 'partner'
                            ? loggedInUserAppData.data.account.name
                            : null
                    }
                    onSwitchAccount={() => onSwitchAccountClick()}
                />
            )}
        </StyledImpersonationIndication>,
    ];

    const rightMenuItems = [credentials];

    return (
        <AppBar
            rightMenuItems={rightMenuItems}
            leftMenuItems={leftMenuItems}
            centerMenuItems={centerMenuItems}
            logo={logo}
            apps={null}></AppBar>
    );
};

export default TopBar;
