import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

export const StyledActions = styled(DialogActions)`
    &.default {
        justify-content: center;
        flex-grow: 1;
        align-items: flex-end;
        padding: 0;
    }

    .actions-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &.sideForm {
        background-color: ${styledTheme.colors.main.mainBg};
        border-top: 1px solid ${styledTheme.dialogs.colors.separator};
        padding: 10px 30px;
        min-height: 75px;
        border-radius: 0;
        position: sticky;
        bottom: 0;

        .actions-row {
            display: flex;
        }
    }
`;
