import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import Tooltip from 'packages/libs/ui-components/src/lib/tooltip/Tooltip';

const StyledContainer = styled.div`
    display: flex;
`;

const StyledSystemName = styled.div`
    color: ${styledTheme.colors.main.inlineLinks};
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
    white-space: nowrap;
`;

interface SystemNameCellProps {
    systemName: string;
}

const SystemNameCell = ({ 
    systemName
}: SystemNameCellProps) => {

    return (
        <StyledContainer>
            <Tooltip 
                placement="bottom"
                title={systemName}
            >
                <StyledSystemName>{systemName}</StyledSystemName>
            </Tooltip>
        </StyledContainer>
    );
};

export default SystemNameCell;
