import React, { useState, MouseEvent, useRef } from 'react';
import { Button, Checkbox, Menu, MenuItem, MenuItemText, DropdownChevronDisplayOptions } from '@walkme/ui-core';
import {
    StyledSelector,
    StyledDivider,
    StyledClear,
    StyledMenuItem,
    StyledMenuItemText,
    StyledApplyButton,
    StyledTagLength,
    StyledRolesSelectorDiv,
    StyledRoleText,
    StyledRoleButtonText,
} from './business-domains.filter.styles';
import { BusinessDomain } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization';
export interface BusinessDomainsFilterProps {
    selectedBusinessDomains: BusinessDomain[];
    allBusinessDomains: BusinessDomain[];
    setSelectedBusinessDomains: (businessDomains: BusinessDomain[]) => void;
}

export function BusinessDomainsFilter(props: BusinessDomainsFilterProps) {
    const { t, rt } = useTranslation('general');

    const translateBD = (bd: BusinessDomain): BusinessDomain => {
        return { ...bd, name: rt(bd.name) };
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [finalFilterToApply, setFinalFilterToApply] = useState<BusinessDomain[]>(props.selectedBusinessDomains);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const handleSelectAll = (e) => {
        e.target.checked ? setFinalFilterToApply(props.allBusinessDomains) : setFinalFilterToApply([]);
    };

    const handleApllyChanges = () => {
        props.setSelectedBusinessDomains(finalFilterToApply);
        handleClose();
    };

    const handleChangeSelection = (e, selectedBusinessDomain: BusinessDomain) => {
        const newSelectedRoles = e.target.checked
            ? finalFilterToApply.concat(selectedBusinessDomain)
            : finalFilterToApply.filter((businessDomain) => businessDomain.id !== selectedBusinessDomain.id);
        setFinalFilterToApply(newSelectedRoles);
    };

    const resetToAllBusinessDomains = (e) => {
        e.stopPropagation();
        props.setSelectedBusinessDomains(props.allBusinessDomains);
        setFinalFilterToApply(props.allBusinessDomains);
    };

    const selectedBusinessDomainsFilterHasChanges =
        props.selectedBusinessDomains.length > 0 && props.allBusinessDomains.length !== props.selectedBusinessDomains.length;

    const SelectorDisplayedValue = (
        <StyledRolesSelectorDiv>
            <StyledRoleButtonText>{t('business-domains.placeholder')}</StyledRoleButtonText>
            {props.selectedBusinessDomains.length > 0 && props.allBusinessDomains.length !== props.selectedBusinessDomains.length && (
                <StyledRoleText>{props.selectedBusinessDomains[0].name}</StyledRoleText>
            )}
            {props.selectedBusinessDomains.length > 0 &&
                props.allBusinessDomains.length !== props.selectedBusinessDomains.length &&
                props.selectedBusinessDomains.length - 1 > 0 && (
                    <StyledTagLength>+{props.selectedBusinessDomains.length - 1}</StyledTagLength>
                )}
            {selectedBusinessDomainsFilterHasChanges ? <StyledClear onClick={(e) => resetToAllBusinessDomains(e)} /> : null}
        </StyledRolesSelectorDiv>
    );

    const chevronDisplayOption = selectedBusinessDomainsFilterHasChanges
        ? DropdownChevronDisplayOptions.HIDE_CHEVRON_DOWN
        : DropdownChevronDisplayOptions.DEFAULT;

    return (
        <div>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
            />
            <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={250}>
                <StyledMenuItem>
                    <Checkbox
                        onChange={(e) => handleSelectAll(e)}
                        checked={props.allBusinessDomains.length === finalFilterToApply.length}
                        indeterminate={props.allBusinessDomains.length !== finalFilterToApply.length}
                    />
                    <StyledMenuItemText>{t('business-domains.all-business-domains')}</StyledMenuItemText>
                </StyledMenuItem>
                <StyledDivider />
                {props.allBusinessDomains.map((businessDomain, index) => {
                    return (
                        <StyledMenuItem key={businessDomain.id}>
                            <Checkbox
                                onChange={(e) => handleChangeSelection(e, businessDomain)}
                                checked={
                                    !!finalFilterToApply.find((selectedBusinessDomain) => businessDomain.id === selectedBusinessDomain.id)
                                }
                            />
                            <StyledMenuItemText>{businessDomain.name}</StyledMenuItemText>
                        </StyledMenuItem>
                    );
                })}
                <StyledApplyButton variant='text' onClick={handleApllyChanges}>
                    Apply
                </StyledApplyButton>
            </Menu>
        </div>
    );
}
