import React from 'react';
import { WMButton, WMButtonProps, WMButtonVariant } from '@walkme/wm-ui';
import cc from 'classnames';

import styles from './Button.module.scss';

export interface ButtonProps extends WMButtonProps {
    size?: 'small' | 'medium' | 'large';
}

export const Button = ({ className, size = 'medium', ...props }: ButtonProps) => (
    <WMButton
        className={cc(
            className,
            styles[size],
            styles.button,
            props.variant !== WMButtonVariant.Text && !props.isIconButton && styles.disabledStyle
        )}
        {...props}
    />
);
