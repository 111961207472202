import * as React from 'react';
import { DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CancelButton, StyledButton } from '../../style';
import { useState } from 'react';
import styled from 'styled-components';
import { StyledDialog, StyledDialogActions } from './idp-dialog.styles';

const ConfirmButton = styled(StyledButton)`
    height: 2.75rem;
    border-radius: 1.365rem;
    min-width: 4.5rem;

    &.errorButton {
        background-color: #e91616 !important;
        &:hover {
            background-color: darkred !important;
        }
    }

    &.footer-button {
        height: 40px;
        border-radius: 20px;
    }
`;

const SecondaryButton = styled(ConfirmButton)`
    color: #385feb;
    background-color: white;
    border: 1px solid var(--Color-Action-Stroke-On-light-Secondary-default, #d7dffb);
    margin-right: 0.75rem !important;

    &:hover {
        background-color: white;
        border-color: #385feb;
    }
`;

const DialogBodyText = styled.p`
    margin: 0;
    color: var(--Color-Semantic-Text-text-secondary, #637191);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export interface IdpDialogProps {
    open: boolean;
    handleClose: (event?) => void;
    confirm: (event) => void;
    doActionOnCancel?: () => void;
    title: string;
    content: string | JSX.Element;
    type: string;
    buttonText: string;
    showCancel?: boolean;
    disableConfirm?: boolean;
    cancelButtonText?: string;
    onSecondaryClick?: (event) => void;
    secondaryButtonText?: string;
}

export const IdpDialog = ({
    open,
    showCancel = true,
    handleClose,
    title,
    confirm,
    content,
    disableConfirm = false,
    doActionOnCancel,
    type = 'defaultButton',
    buttonText,
    cancelButtonText,
    onSecondaryClick,
    secondaryButtonText,
}: IdpDialogProps) => {
    const [inProgress, setInProgress] = useState(false);
    const [secondaryInProgress, setSecondaryInProgress] = useState(false);
    const progress = inProgress || secondaryInProgress;

    const handleConfirm = async (event) => {
        setInProgress(true);
        try {
            await confirm(event);
        } catch (err) {
            setInProgress(false);
        }
    };
    const handleSecondaryClick = async (event) => {
        setSecondaryInProgress(true);
        try {
            await onSecondaryClick(event);
        } catch (err) {
            setSecondaryInProgress(false);
        }
    };
    const handleDoActionOnCancel = () => {
        handleClose();
        doActionOnCancel();
    };
    const onClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            handleClose();
        }
    };
    return (
        <StyledDialog
            onClick={(event) => event.stopPropagation()}
            classes={{ container: 'container' }}
            open={open}
            onClose={onClose}
            style={{ minWidth: '500px', minHeight: '250px' }}>
            <div style={{ padding: '0 24px 0 32px' }}>
                <DialogContent
                    style={{
                        padding: '25px 0px 18px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span style={{ fontSize: '18px', fontWeight: 600 }}>{title}</span>
                    <IconButton style={{ padding: 0 }} aria-label='close' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogContent>
            </div>
            <DialogContent style={{ padding: '0.5rem 32px' }}>
                <DialogBodyText>{content}</DialogBodyText>
            </DialogContent>
            <StyledDialogActions classes={{ root: 'root' }}>
                {showCancel && (
                    <CancelButton type={'text'} onClick={doActionOnCancel ? () => handleDoActionOnCancel() : handleClose}>
                        {cancelButtonText}
                    </CancelButton>
                )}
                {onSecondaryClick && (
                    <SecondaryButton
                        loading={secondaryInProgress}
                        disabled={progress || disableConfirm}
                        onClick={(event) => handleSecondaryClick(event)}
                        className={`footer-button`}>
                        {secondaryButtonText}
                    </SecondaryButton>
                )}
                <ConfirmButton
                    loading={inProgress}
                    disabled={progress || disableConfirm}
                    onClick={(event) => handleConfirm(event)}
                    className={`${type} footer-button`}>
                    {buttonText}
                </ConfirmButton>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default IdpDialog;
