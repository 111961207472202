import React, { useCallback, useState } from 'react';
import { Button, IconButton, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import IdpDialog from '../../../../../sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 0 16px 0 24px;
    bottom: 32px;
    width: 840px;
    height: 64px;
    background: #385feb;
    border-radius: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
`;

export const CssIconButton = withStyles({
    root: (props: { strokecolor?: string; strokehovercolor?: string }) => ({
        width: '32px',
        height: '32px',
        padding: 0,
        '& svg path': {
            stroke: props.strokecolor ?? 'inherit',
            transition: 'stroke 0.3s ease',
        },
        '&:hover svg path': {
            stroke: props.strokehovercolor ?? props.strokecolor ?? 'inherit',
        },
    }),
})(IconButton);

export const CssDivider = styled.div`
    width: 1px;
    height: 32px;
    background: rgba(255, 255, 255, 0.52);
`;

export const AssignSystemButton = styled(Button)`
    border-radius: 40px;
    height: 32px;
    width: 131px;
    padding: 6px 16px;
    background: white;
    color: #385feb;
    font-weight: 600;
    text-transform: none;
    box-shadow: none;
    &:hover {
        background: white;
    }
`;

export const SelectIntegrationsActions = ({ selected, clearSelected, openAssignSystem, handleDelete }) => {
    const [confirmData, setConfirmData] = useState({
        showConfirm: false,
        content: null,
        confirm: null,
        type: '',
        title: '',
    });

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={confirmData.showConfirm}
                handleClose={() => setConfirmData({ ...confirmData, showConfirm: false })}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={confirmData.type || 'defaultButton'}
                buttonText={'Delete'}
                cancelButtonText={'Cancel'}
            />
        );
    }, [confirmData]);

    const handleMultiDelete = async () => {
        setConfirmData({
            showConfirm: true,
            title: 'Delete',
            type: 'errorButton',
            content: `Are you sure you want to Delete?`,
            confirm: async (event) => {
                setConfirmData({ ...confirmData, showConfirm: false });
                await handleDelete(event);
            },
        });
    };

    return (
        <>
            {confirmData.showConfirm && triggerConfirm()}
            <Container>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 600, fontSize: '24px' }}>{selected}</span>
                    <span style={{ fontSize: '16px', paddingLeft: '8px' }}>Knowledge sources selected</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <CssIconButton onClick={handleMultiDelete}>
                        <img src={'assets/icons/data-integrations/trash-icon-medium-white.svg'} />
                    </CssIconButton>
                    <AssignSystemButton onClick={openAssignSystem}>Assign Systems</AssignSystemButton>
                    <CssDivider />
                    <CssIconButton onClick={clearSelected}>
                        <img src={'assets/icons/data-integrations/close-icon-medium-white.svg'} />
                    </CssIconButton>
                </div>
            </Container>
        </>
    );
};

export default SelectIntegrationsActions;
