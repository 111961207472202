import React from 'react';
import { WMButtonVariant, WMIconDelete } from '@walkme/wm-ui';
import { StyledWMDataGridActionDialog } from './MultipleSelectionFotter.styles';
import { Export } from '@walkme/ui-icons';

export interface MultipleSelectionFooterProps {
    onCancel: () => void;
    numSelectedRows: number;
    entityName: string;
    getSelectedRows: () => any[];
    deleteButton: DeleteButton;
    exportButton?: ExportButton;
}

export interface DeleteButton {
    onClick: (rows) => void;
    loading?: boolean;
    disabled?: boolean;
    title: string;
}

export interface ExportButton {
    onClick: (rows) => void;
    loading?: boolean;
    disabled?: boolean;
    icon?: any;
}

export const MultipleSelectionFooter = ({
    onCancel,
    numSelectedRows,
    entityName,
    getSelectedRows,
    deleteButton,
    exportButton = null,
}: MultipleSelectionFooterProps) => {
    const actions = [];
    if (exportButton) {
        actions.push({
            onClick: exportButton.onClick,
            loading: exportButton.loading ?? false,
            icon: exportButton.icon ?? <Export />,
            className: 'export-button',
        });
    }
    actions.push({
        onClick: deleteButton.onClick,
        loading: deleteButton.loading ?? false,
        disabled: deleteButton.disabled ?? false,
        icon: <WMIconDelete />,
        title: deleteButton.title,
        variant: WMButtonVariant.Secondary,
        className: 'delete-button',
    });
    return (
        <StyledWMDataGridActionDialog
            entityName={entityName}
            numOfSelectedRows={numSelectedRows}
            onCancel={onCancel}
            getSelectedRows={getSelectedRows}
            actions={actions}
        />
    );
};
