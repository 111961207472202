import React from 'react';
import cc from 'classnames';

import styles from './ai-button.module.scss';
import { Button } from '@walkme/ui-core';

const AiIcon = (props) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
            <path
                d='M14.5 11.75C10.7887 13 9.15476 14.5321 8.25 18C7.34524 14.5328 5.71131 13.0007 2 11.75C5.71131 10.5 7.34524 8.96876 8.25 5.5C9.15476 8.96876 10.7887 10.4992 14.5 11.75Z'
                fill='var(--ai-icon-bg, currentcolor)'
                stroke='var(--ai-icon-bg, currentcolor)'
                strokeWidth='0.833333'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18 4.5C16.5155 5 15.8619 5.61282 15.5 7C15.1381 5.61311 14.4845 5.00029 13 4.5C14.4845 4 15.1381 3.3875 15.5 2C15.8619 3.3875 16.5155 3.99968 18 4.5Z'
                fill='var(--ai-icon-bg, currentcolor)'
                stroke='var(--ai-icon-bg, currentcolor)'
                strokeWidth='0.444445'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient id='gradient_bg' x1='3.58952' y1='15.882' x2='7.57709' y2='9.06903' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.138363' stopColor='#385FEB' />
                    <stop offset='1' stopColor='#8351F5' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export interface AiButtonProps {
    children: React.ReactNode;
    isSecondary?: boolean;
    className?: string;
    disabled?: boolean;
    onClick: () => void;
    withIcon?: boolean;
    size?: 'small' | 'medium';
    selected?: boolean; // New prop for selected state
}

export const AiButton = ({
    children,
    onClick,
    className,
    isSecondary,
    disabled,
    withIcon = true,
    size = 'medium',
    selected,
}: AiButtonProps) => {
    return (
        <Button
            disabled={disabled}
            className={cc(styles.button, className, {
                [styles.secondary]: isSecondary,
                [styles.small]: size === 'small',
                [styles.selected]: selected,
            })}
            startIcon={withIcon && <AiIcon className={styles.icon} alt='AI Icon' />}
            onClick={onClick}>
            {isSecondary && <div className={styles.secondaryContainer} />}
            <span className={styles.children}>{children}</span>
        </Button>
    );
};
