import { AppData } from '@walkme-admin-center/libs/types';
import { DeletedSystemEnvironment } from '@walkme-admin-center/libs/analytics-api';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useDeletedEnvironment = () => {
    const deletedEnvironmentAppData: AppData<DeletedSystemEnvironment> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.deletedEnvironment
    );

    return { deletedEnvironmentAppData };
};

export default useDeletedEnvironment;
