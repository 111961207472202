import { useSelector } from 'react-redux';
import { SsoConfigurationState } from '../redux/sso-configuration.slice';

export const useProviderGroups= () => {
  const providerGroupsData = useSelector((state: { ssoConfigurationState: SsoConfigurationState }) => state.ssoConfigurationState.providersGroups);

  return {
    providerGroupsData
  };
};

export default useProviderGroups;
