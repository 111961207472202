import styled from 'styled-components';
import { Input, Spinner } from '@walkme/ui-core';

export const StyledUrlMatchingContainer = styled.div`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
`;

export const StyledInputContainer = styled.div`
    position: relative;
    display: inline-block;
    margin-top: 16px;
    display: flex;
`;

export const StyledAddUrlButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
`;

export const StyledInputLabel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const StyledInput = styled(Input)`
    & input:focus {
        border-width: 1px;
        outline: none;
    }

    & input:disabled {
        background-color: var(--Primaries-Disable-Disable-tint-8, #f8f8f8);
        color: rgba(200, 200, 200, 1);
    }
`;

export const StyledSpinner = styled(Spinner)`
    position: absolute;
    right: 20px;
`;
