import styled from 'styled-components';
import { Divider, DropdownSelection, Button, Checkbox, Menu, MenuItem, MenuItemText, DropdownChevronDisplayOptions } from '@walkme/ui-core';
import { Close as Clear } from '@walkme/ui-icons';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 32px;
        margin: 0;
    }
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const StyledMenuItem = styled(MenuItem)`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledMenuItemText = styled(MenuItemText)`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
`;

export const StyledApplyButton = styled(Button)`
    display: flex !important;
    height: 48px !important;
    padding: 14px 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    width: 100% !important;
`;

export const StyledTagLength = styled.span`
    border-radius: 4px;
    padding: 4px 8px;
    background: var(--blue-primary-primary-tint-9, #fbfcff);
    color: var(--infographic-blue-blue-shade-1, #162a69);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const StyledRolesSelectorDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-right: 8px;
`;

export const StyledRoleText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const StyledRoleButtonText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

export const StyledClear = styled(Clear)`
    cursor: pointer;
`;
