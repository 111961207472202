import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconExpand = (props: SvgIconProps) => {
    return (
        <SvgIcon width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M15 9L12 6M12 6L9 9M12 6L12.0001 18M15.0002 14.9999L12.0001 18M12.0001 18L9 14.9999'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </SvgIcon>
    );
};

export { IconExpand };
