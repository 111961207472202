import { useTranslation } from 'apps/home/src/localization/localizationBase';

export enum DeploymentMethods {
    EXTENSION = 'EXTENSION',
    SNIPPET = 'SNIPPET',
}

export const getDeploymentOptions = () => {
    const { t } = useTranslation('general');

    const deploymentMethods = {
        title: '',
        description: '',
        type: 'deployment',
        options: [
            {
                value: DeploymentMethods.EXTENSION,
                title: t('systems-tab.new-system-form.deployment.extension.main-title'),
                subTitle: t('systems-tab.new-system-form.deployment.extension.sub-title'),
                type: 'deployment',
            },
            {
                value: DeploymentMethods.SNIPPET,
                title: t('systems-tab.new-system-form.deployment.snippet.main-title'),
                subTitle: t('systems-tab.new-system-form.deployment.snippet.sub-title'),
                type: 'deployment',
            },
        ],
    };

    return [deploymentMethods];
};
