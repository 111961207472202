export interface FormValues {
    id: number | string;
}

export interface FormActions {
    onCancel: (pristine: boolean) => void;
    onChange?: () => void;
    // TODO: remove language argument, this is general logic and language is specific to multi-language here
    onConfirm: (values: unknown, language?: unknown) => void | Promise<void>;
    onDelete?: (values: unknown) => void | Promise<void>;
    onResend?: (values: unknown) => void | Promise<void>;
}
