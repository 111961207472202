import React, { useRef, useState } from 'react';
import URLMatchPattern from './url-match-pattern';
import { Button } from '@walkme/ui-core';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import {
    UrlTopBarControlContainer,
    UrlCounterContainer,
    UrlsTitle,
    StyledChip,
    MatchPatternsItemsContainerDiv,
    MatchPatternsContainerDiv,
    StyledControlSubHeader,
} from './url-match-patterns.styles';
import { StyledLink } from '../select-deployment.styles';

interface URLMatchPatternsProps {
    urls: string[];
    setAreUrlsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setUrls: (newUrls: string[]) => void;
    shouldShowNewLine: boolean;
    onAddUrlClicked: () => void;
    onAddNewUrlClosed: () => void;
    onAddNewUrlSaved: () => void;
}

const URLMatchPatterns = ({
    urls,
    setAreUrlsValid,
    setUrls,
    shouldShowNewLine,
    onAddUrlClicked,
    onAddNewUrlClosed,
    onAddNewUrlSaved,
}: URLMatchPatternsProps) => {
    const { t } = useTranslation('general');
    const [addUrlClicked, setAddUrlClicked] = useState(false);
    const urlMatchPattensValidityArray = useRef<boolean[]>(Array(urls.length).fill(true));

    const onAddUrlClick = () => {
        setAddUrlClicked(true);
        onAddUrlClicked();
    };

    const getUrlsTopBarControl = () => {
        const shouldShowAddUrlButton = !shouldShowNewLine;

        return (
            <UrlTopBarControlContainer>
                <UrlCounterContainer>
                    <UrlsTitle>{t('common.urls')}</UrlsTitle>
                    <StyledChip size='small' label={urls.length.toString()} />
                </UrlCounterContainer>
                {shouldShowAddUrlButton ? (
                    <Button variant='text' onClick={onAddUrlClick} disabled={addUrlClicked}>
                        {t('buttons.add-url')}
                    </Button>
                ) : null}
            </UrlTopBarControlContainer>
        );
    };

    const handleDelete = (index: number) => {
        const newUrls: string[] = [...urls];
        newUrls.splice(index, 1);
        setUrls(newUrls);

        const wasUrlValid = urlMatchPattensValidityArray.current[index];
        const newUrlValidityArray = [...urlMatchPattensValidityArray.current];
        newUrlValidityArray.splice(index, 1);

        if (!wasUrlValid) {
            const areAllUrlsValid = newUrlValidityArray.every((isValid) => isValid);
            if (areAllUrlsValid) {
                setAreUrlsValid(true);
            }
        }

        urlMatchPattensValidityArray.current = newUrlValidityArray;
    };

    const handleSave = (value: string, index?: number): void => {
        const newUrls: string[] = [...urls];
        if (index !== undefined) {
            newUrls[index] = value;
        } else {
            newUrls.push(value);
            setAddUrlClicked(false);
            onAddNewUrlSaved();
        }

        setUrls(newUrls);
    };

    const handleUrlValidityChange = (isValid: boolean, index?: number): void => {
        const areAllOtherUrlsValid = urlMatchPattensValidityArray.current.every(
            (isValid, urlMatchPattensValidityIndex) => isValid || index === urlMatchPattensValidityIndex
        );

        if (areAllOtherUrlsValid) {
            setAreUrlsValid(isValid);
        }

        if (index !== undefined) {
            const newUrlValidityArray = [...urlMatchPattensValidityArray.current];
            newUrlValidityArray[index] = isValid;
            urlMatchPattensValidityArray.current = newUrlValidityArray;
        }
    };

    const onAddNewUrlClose = () => {
        setAddUrlClicked(false);
        onAddNewUrlClosed();
    };

    const newUrlMatchPatternControl = (
        <URLMatchPattern value='' handleSave={handleSave} handleClose={!shouldShowNewLine ? onAddNewUrlClose : null} />
    );

    const urlMatchPatternsControl = (
        <MatchPatternsItemsContainerDiv>
            {urls.map((url: string, index: number) => (
                <URLMatchPattern
                    key={`${url}_${index}`}
                    index={index}
                    value={url}
                    handleDelete={urls.length > 1 ? handleDelete : null}
                    handleSave={handleSave}
                    setAreUrlsValid={handleUrlValidityChange}
                />
            ))}
        </MatchPatternsItemsContainerDiv>
    );

    return (
        <MatchPatternsContainerDiv>
            <StyledControlSubHeader>
                <div>
                    {t('systems-tab.all-systems-page.extension-page.env-urls-description')}
                    <StyledLink to={{ pathname: 'https://support.walkme.com/knowledge-base/url-match-pattern' }} target='_blank'>
                        {' ' + t('buttons.learn-more')}
                    </StyledLink>
                </div>
            </StyledControlSubHeader>
            {getUrlsTopBarControl()}
            {addUrlClicked || shouldShowNewLine ? newUrlMatchPatternControl : null}
            {urlMatchPatternsControl}
        </MatchPatternsContainerDiv>
    );
};

export default URLMatchPatterns;
