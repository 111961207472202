import styled from 'styled-components';
import { Divider, DropdownSelection, Menu } from '@walkme/ui-core';
import { Info } from '@walkme/ui-icons';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 40px;
        width: 300px;
        margin: 0;
        outline: none !important;
    }
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const StyledSelectorDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledButtonText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;

export const StyledInfoIcon = styled(Info)`
    color: var(--typography-typography-main-primary-main, #2f426c);
`;

export const StyledLanguageMenuWrapper = styled.div`
    .MuiMenu-list {
        padding: 0 !important;
    }
`;

export const StyledWMMenu = styled(Menu)`
    margin-top: 8px;

    .MuiMenu-list {
        padding: 0 !important;
    }
`;

export const StyledLanguageMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    .menu-window {
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;

        .menu-item {
            display: flex;
            justify-content: space-between;
            padding: 12px 16px;
            cursor: pointer;

            &:hover {
                background-color: #f0f3fa;
            }
        }
    }

    .description-window {
        background-color: #f0f3fa;
        padding: 20px;

        .title {
            font-size: 12px;
            margin-top: 12px;
            color: var(--Color-Semantic-Text-text-primary, #2f426c);
        }

        .description {
            margin: unset;
            font-size: 12px;
            white-space: pre-line;
            color: #697396;
        }
    }
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean; isDisabled?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    color: ${({ isSelected, isDisabled }) => (isDisabled ? '#989898' : isSelected ? '#2e62dc' : '#2f426c')};

    &:hover {
        background-color: ${({ isDisabled }) => (isDisabled ? '#f8f8f8' : '#f0f3fa')};
    }
`;

export const StyledLink = styled.a`
    text-decoration: none;
    color: var(--Blue-Primary-Primary-Main, #385feb) !important;
`;
