import { useSelector } from 'react-redux';
import { DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useAddedDesktopAppsResults = () => {
    const addedDesktopTrackedAppsResults = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.addedDesktopTrackedAppsResults
    );

    return {
        addedDesktopTrackedAppsResults
    };
};

export default useAddedDesktopAppsResults;
