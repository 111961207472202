import { askMeApi } from '@walkme-admin-center/libs/ask-me-api';
import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import moment from 'moment';
import {
    IAiSettings,
    ICapViewRowData,
    ICapViewRowsData,
    IDateRange,
    IItemsUsageExpenseRowData,
    IItemUsageExpenseResponse,
    IItemUsageExpenseRowData,
    ISystemCupResponse,
} from '../types';
import { Systems } from 'wm-accounts-sdk';
import { SystemsTypes } from '../types/enums';
import rootReducer from '../../../../../../../apps/home/src/redux/rootReducer';
import { AppData } from '@walkme-admin-center/libs/types';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { retrieveSlackIcon, retrieveTeamsIcons } from '../../../../../../pages/home/ai-usage/src/lib/service';

export interface AiHubDashboardState {
    settings: AppData<IAiSettings>;
    capViewTableData: AppData<ICapViewRowsData>;
    itemsUsageExpenseRowData: AppData<IItemsUsageExpenseRowData>;
}

export const getCurrnetMonthDateRange = (): IDateRange => {
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    return {
        fromDate: startOfMonth.format('YYYY-MM-DD'),
        toDate: endOfMonth.format('YYYY-MM-DD'),
    };
};

export const initialAiHubDashboardState: AiHubDashboardState = {
    capViewTableData: {
        data: [],
        error: null,
        loading: true,
    },
    itemsUsageExpenseRowData: {
        data: [],
        error: null,
        loading: true,
    },
    settings: {
        data: {
            msaTotalCap: null,
            totalAiCost: 0,
            systemsCap: {},
            usePercentage: null,
            integrations: [],
        },
        error: null,
        loading: true,
    },
};

const aiHubDashboardSlice = createSlice({
    name: 'aiHubDashboardSlice',
    initialState: initialAiHubDashboardState,
    reducers: {
        getDashBoardDataStart(state: AiHubDashboardState) {
            state.capViewTableData.loading = true;
            state.itemsUsageExpenseRowData.loading = true;
            state.capViewTableData.error = null;
            state.itemsUsageExpenseRowData.error = null;
            return state;
        },
        getDashBoardDataSuccess(
            state: AiHubDashboardState,
            action: PayloadAction<{
                systemCupData: ICapViewRowsData;
                itemsUsageExpenseData: IItemsUsageExpenseRowData;
            }>
        ) {
            state.capViewTableData.loading = false;
            state.itemsUsageExpenseRowData.loading = false;
            state.capViewTableData.error = null;
            state.itemsUsageExpenseRowData.error = null;
            state.capViewTableData.data = action.payload.systemCupData;
            state.itemsUsageExpenseRowData.data = action.payload.itemsUsageExpenseData;
            return state;
        },
        getDashBoardDataFailure(state: AiHubDashboardState) {
            state.itemsUsageExpenseRowData.loading = false;
            state.capViewTableData.loading = false;
            state.itemsUsageExpenseRowData.error = 'Failed to load data';
            state.capViewTableData.error = 'Failed to load data';
            return state;
        },
        getAiSettingsStart(state: AiHubDashboardState) {
            state.settings.loading = true;
            state.settings.error = null;
            return state;
        },
        getAiSettingsSuccess(state: AiHubDashboardState, action: PayloadAction<IAiSettings>) {
            state.settings.loading = false;
            state.settings.error = null;
            state.settings.data = action.payload;
            return state;
        },
        getAiSettingsFailure(state: AiHubDashboardState) {
            state.settings.loading = false;
            state.settings.error = 'Failed to load Ai settings';
            return state;
        },
    },
});

export { aiHubDashboardSlice };

const {
    getDashBoardDataStart,
    getDashBoardDataSuccess,
    getAiSettingsStart,
    getAiSettingsSuccess,
    getDashBoardDataFailure,
    getAiSettingsFailure,
} = aiHubDashboardSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

const prepareSystemCupData = (systemsCupAgg: ISystemCupResponse[], settings: IAiSettings, systems: Systems): ICapViewRowsData => {
    if (Object.keys(settings.systemsCap).length === 0) {
        return [];
    }
    const data: ICapViewRowsData = [];

    Object.entries(settings.systemsCap).forEach(([key, value]) => {
        const fount = systemsCupAgg.find((item) => item.systemGuid === key);
        if (fount) {
            data.push({
                ...fount,
                monthlyCap: value,
            });
        } else {
            data.push({
                systemGuid: key,
                monthlyCap: value,
                usageExpense: 0,
            });
        }
    });
    return data.map((row: ICapViewRowData): ICapViewRowData => {
        const systemFound = systems.find((system) => system.guid === row.systemGuid);
        if (systemFound) {
            return {
                ...row,
                systemName: systemFound.displayName,
                systemIconPath: retrieveSystemIcon(systemFound.settings),
                monthlyCap: settings.systemsCap[row.systemGuid],
            };
        }
        const integrationFound = settings.integrations.find((integration) => integration.system_guid === row.systemGuid);
        if (integrationFound) {
            return {
                ...row,
                systemName: integrationFound.name,
                systemIconPath: integrationFound.system_type === SystemsTypes.SLACK ? retrieveSlackIcon() : retrieveTeamsIcons(),
                monthlyCap: settings.systemsCap[row.systemGuid],
            };
        }
        return {
            ...row,
            systemName: row.systemGuid,
            systemIconPath: null,
            monthlyCap: settings.systemsCap[row.systemGuid],
        };
    });
};

const prepareItemsUsageExpenseData = (
    data: IItemUsageExpenseResponse[],
    settings: IAiSettings,
    systems: Systems
): IItemsUsageExpenseRowData => {
    return data.map((row: IItemUsageExpenseResponse): IItemUsageExpenseRowData => {
        const systemFound = systems.find((system) => system.guid === row.systemGuid);
        if (systemFound) {
            return {
                ...row,
                systemName: systemFound.displayName,
                systemIconPath: retrieveSystemIcon(systemFound.settings),
            };
        }
        const integrationFound = settings.integrations.find((integration) => integration.system_guid === row.systemGuid);
        if (integrationFound) {
            return {
                ...row,
                systemName: integrationFound.name,
                systemIconPath: integrationFound.system_type === SystemsTypes.SLACK ? retrieveSlackIcon() : retrieveTeamsIcons(),
            };
        }
        return {
            ...row,
            systemName: row.systemGuid,
            systemIconPath: null,
        };
    });
};

export const getDashboardData =
    (dateRange: IDateRange): AppThunk =>
    async (dispatch, getState) => {
        dispatch(getDashBoardDataStart());
        try {
            const response = await askMeApi.getData(dateRange);
            const systemsState = getState().systemsState.systems;
            const settingsState = getState().aiHubDashboardState.settings;
            dispatch(
                getDashBoardDataSuccess({
                    systemCupData: prepareSystemCupData(response.systemsCup, settingsState.data, systemsState.data),
                    itemsUsageExpenseData: prepareItemsUsageExpenseData(response.itemsUsageExpense, settingsState.data, systemsState.data),
                })
            );
        } catch (error) {
            dispatch(getDashBoardDataFailure());
        }
    };

export const getSettings = (): AppThunk => async (dispatch) => {
    dispatch(getAiSettingsStart());
    try {
        const currentDateRange = getCurrnetMonthDateRange();
        const response = await askMeApi.getSettings(currentDateRange);
        dispatch(getAiSettingsSuccess(response));
    } catch (error) {
        dispatch(getAiSettingsFailure());
    }
};

export const getSignedUrls =
    (files: File[], sourceId): AppThunk =>
    async (dispatch) => {
        try {
            const response = await askMeApi.getSignedUrls(files, sourceId);
            return response;
        } catch (error) {
            throw new Error('Failed to get signed urls');
        }
    };
