import { Environments, System } from 'wm-accounts-sdk';

export interface SystemPackageData {
    email: string;
    environments: Environments;
}

export interface SystemPackage {
    id: number;
    selfRegex: string;
    topRegex?: string;
    envId: number;
    isActive?: boolean;
    userId?: number; //systemId
    user?: SystemPackageData;
}

export interface Extension {
    guid: string;
}

export interface WebSystem extends System {
    packages: SystemPackage[];
    environments: Environments;
}
