import styled from 'styled-components';
import { FlexDivContainer } from '../../../../../../../extension-pages/src/lib/new-design/shared-styled-components';

export const StyledExtensionMethodContainer = styled.div`
    .extension-as-default {
        display: flex;
        margin: 14px 0;
        .description {
            margin-left: 8px;
            color: var(--Color-Semantic-Text-secondary, #637191);
        }
    }
`;

export const StyledActiveStatusContainer = styled.div<{ isActive: boolean }>`
    padding: 24px 0 14px 0;
    border-bottom: ${({ isActive }) => (isActive ? '1px solid #f5f6f8' : 'unset')};
`;

export const StyledActiveStatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledSelfDomainsMatcherControlContainer = styled.div`
    padding: 24px 0 14px 0;
`;

export const ControlHeaderWithTooltipContainer = styled(FlexDivContainer)`
    gap: 4px;
    align-items: center;
    flex-grow: 1;
`;
