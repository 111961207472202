import React from 'react';
import Pluralize from 'react-pluralize';
import { Dialog } from '@walkme-admin-center/libs/common';
import { createStyles, makeStyles, Grid, Link } from "@material-ui/core";
import { Button } from '@walkme-admin-center/libs/ui-components';
import DesktopApp from "./desktop-app";
import styled from 'styled-components';
import { IconError, IconCheck } from '@walkme-admin-center/libs/common';
import { DesktopAppTileType } from 'packages/libs/state-management-desktop-tracked-apps/src/lib/types/desktop-app-tile-type';

export const Footer = styled.div`
    padding: 30px;
    height: 50px;
    margin-top: auto;
`;

interface AddDesktopTrackedAppResultsProps {
  onClose: (e?: unknown) => void
  isOpen: boolean;
  success: DesktopAppTileType[];
  failed?: DesktopAppTileType[];
}

const useStyles = makeStyles(() => createStyles({
  gridList: {
    padding: '0 18px',
    maxHeight: '255px',
    overflow: 'auto',
    width: '100%',
    margin: '0'
  },
  button: {
    width: '100%'
  },
  dialog: {
    width: '520px',
    '& .MuiDialogTitle-root': {
      padding: '0 30px 10px'
    }
  },
  bodyText: {
    padding: '0 30px 20px'
  },
  svgIcon: {
    verticalAlign: 'top',
    paddingRight: '10px'
  },
  resultsSection: {
    paddingTop: '10px',
    paddingBottom: '10px'
  }
}));

const AddDesktopTrackedAppResults = ({ success, failed = [], isOpen, onClose }: AddDesktopTrackedAppResultsProps) => {
  const classes = useStyles();

  const successMessage = (
    <>
      <IconCheck className={classes.svgIcon}/>
      <Pluralize singular='app was' plural='apps were' count={success.length} /> added successfully.
    </>
  );
  const failedMessage = (
    <>
      <IconError className={classes.svgIcon}/>
      <Pluralize singular='app' plural='apps' count={failed.length} /> could not be added. Try again, or <Link href="https://support.walkme.com/submit-a-ticket" target="_blank">Contact Support</Link>.
    </>
  );

  const renderAppsResults = (apps, resMessage) => {
    if (!apps.length) {
      return null;
    }

    return (
      <div className={classes.resultsSection}>
        <span className={classes.bodyText}>
          {resMessage}
        </span>

        <Grid container spacing={2} className={classes.gridList}>
          {apps.map((app: DesktopAppTileType) => (
            <Grid item xs={6} key={app.id}>
              <DesktopApp desktopApp={app} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  return (
      <Dialog customClass={classes.dialog} showDialog={isOpen} onClose={onClose} title='Apps added to list'>
        <>
          {renderAppsResults(success, successMessage)}
          {renderAppsResults(failed, failedMessage)}
          <Footer>
            <Button customClass={`main ${classes.button}`} size='large' onClick={onClose}>Got it</Button>
          </Footer>
        </>
      </Dialog>
  )
};

export default AddDesktopTrackedAppResults;
