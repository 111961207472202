import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { siteConfigApi } from '../api/site-config-api';
import {
    ContentContainer,
    StyledSaveAndPublish,
    HeaderMainText,
    HeaderContainer,
    MainContainer,
    LoaderContainer,
} from './building-settings.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../../../../apps/home/src/localization';
import { WMLoader, WMSnackbarVariant } from '@walkme/wm-ui';
import { Button } from '@walkme/ui-core';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import {
    IMPERSONATION_PASSWORD_ERROR,
    ImpersonationPasswordError,
    impersonationSlice,
    ImpersonationState,
    impersonationThunks,
    systemsSlice,
} from '@walkme-admin-center/libs/state-management-systems';
import { ThemingEnablement } from './theming-enablement';
import { ScreenshotSettings } from './screenshot-settings';
import useSystem from '../../../../../../libs/state-management-systems/src/lib/hooks/use-system';
import { ImpersonatorDialog } from '../impersonator-dialog';

export enum ModeOptions {
    capture = 0,
    blur = 1,
    none = 2,
}

const loaderComponent = (
    <LoaderContainer>
        <WMLoader style={{ width: '30%' }} />
    </LoaderContainer>
);

const DISABLE_SCREENSHOT_SETTINGS_FF = 'disableScreenshotSettings';

export class PartialFailureError extends Error {}

export const BuildingSettings = ({ systemId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const [selectedMode, setSelectedMode] = useState();
    const [savingData, setSavingData] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [siteConfiguration, setSiteConfiguration] = useState({ Settings: {} });
    const [themingConfiguration, setThemingConfiguration] = useState(false);
    const [loading, setLoading] = useState(true);
    const { loggedInUserAppData, accountFeatureEnabled } = useLoggedInUser();
    const [isEnabled, setIsEnabled] = useState(false);
    const [isThemingEnabled, setIsThemingEnabled] = useState(false);
    const { currentSystemAppData } = useSystem(systemId);

    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;

    const saveTheming = async () => {
        await siteConfigApi.enableTheming({ systemId, isImpersonated });
        await loadThemingConfig();
    };

    const saveScreenshot = async (impPassword: string) => {
        let screenshotMode = ModeOptions.none;
        if (isBlur) {
            screenshotMode = ModeOptions.blur;
        } else if (isEnabled) {
            screenshotMode = ModeOptions.capture;
        }

        const settings = { ...siteConfiguration?.Settings, screenshotMode };
        const result = { ...siteConfiguration, Settings: settings };

        const saveSiteConfigurationPayload: any = {
            configurationString: JSON.stringify(result),
            systemId,
            isImpersonated,
            impPassword,
        };

        const saveResult = await siteConfigApi.saveSiteConfiguration(saveSiteConfigurationPayload);

        if (saveResult.data?.ErrorObject && saveResult.data?.ErrorMessage == IMPERSONATION_PASSWORD_ERROR) {
            throw new ImpersonationPasswordError();
        }

        await loadSiteConfig();
    };

    const didScreenShotConfigChange = (): boolean => {
        if (selectedMode == ModeOptions.none) {
            return isEnabled;
        } else if (selectedMode == ModeOptions.blur) {
            return !(isEnabled && isBlur);
        } else if (selectedMode == ModeOptions.capture) {
            return !(isEnabled && !isBlur);
        }
        return false;
    };

    const didThemingConfigChange = (): boolean => {
        return isThemingEnabled != themingConfiguration;
    };

    const saveBuildingSettings = async (impPassword: string) => {
        setSavingData(true);
        let screenShotChanged = didScreenShotConfigChange();
        let themingChanged = didThemingConfigChange();
        let screenShotSaved = false;
        let themingSaved = false;
        try {
            // One after the other, and not Promise.all because saving screenshots requires impersonation password, and we want it to fail first.
            if (screenShotChanged) {
                await saveScreenshot(impPassword);
                screenShotSaved = true;
            }
            if (themingChanged) {
                await saveTheming();
                themingSaved = true;
            }
        } catch (error) {
            if (screenShotChanged && themingChanged && !themingSaved) {
                throw new PartialFailureError(error);
            }
            throw error;
        } finally {
            setSavingData(false);
        }
    };

    const onSaveSucceeded = () => {
        dispatch(
            systemsSlice.actions.setNotificationMessage({
                text: t('systems-tab.new-system-form.configure-step.notifications.success'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            })
        );
    };

    const onSaveFailed = (message) => {
        dispatch(
            systemsSlice.actions.setNotificationMessage({
                text: message,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            })
        );
    };

    const handleSave = async (impPassword) => {
        try {
            await saveBuildingSettings(impPassword);
            onSaveSucceeded();
        } catch (error) {
            if (error instanceof ImpersonationPasswordError) {
                onSaveFailed(t('notifications.impersonated-save-fail'));
            } else if (error instanceof PartialFailureError) {
                onSaveFailed(t('systems-tab.new-system-form.configure-step.notifications.partiallyFail'));
            } else {
                onSaveFailed(t('systems-tab.new-system-form.configure-step.notifications.fail'));
            }
            console.error(error);
        }
    };

    const onSaveClicked = async () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(handleSave));
        } else {
            await handleSave(null);
        }
    };

    const hasConfigChanged = (): boolean => {
        return didScreenShotConfigChange() || didThemingConfigChange();
    };

    const handleThemingToggle = (event: ChangeEvent<HTMLInputElement>) => {
        setIsThemingEnabled(event.target?.checked);
    };

    const handleScreenShotToggle = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.checked) {
            setIsBlur(false);
        }
        setIsEnabled(event.target?.checked);
    };
    const loadSiteConfig = async () => {
        try {
            const result = await siteConfigApi.getUserConfiguration(systemId);
            const screenshotMode = result?.SiteConfiguration?.Settings?.screenshotMode;

            setSiteConfiguration(result?.SiteConfiguration);
            setSelectedMode(screenshotMode);
            if (screenshotMode == ModeOptions.blur || screenshotMode == ModeOptions.capture) {
                setIsEnabled(true);
            }

            if (screenshotMode == ModeOptions.blur) {
                setIsBlur(true);
            }
        } catch (e) {
            dispatch(
                systemsSlice.actions.setNotificationMessage({
                    text: t('errors.unspecified-error'),
                    variant: WMSnackbarVariant.Error,
                    isOpen: true,
                })
            );
        }
    };

    const loadThemingConfig = async () => {
        try {
            const result = await siteConfigApi.isThemingEnabled(systemId);
            setThemingConfiguration(result);
            setIsThemingEnabled(result);
        } catch (e) {
            dispatch(
                systemsSlice.actions.setNotificationMessage({
                    text: t('errors.unspecified-error'),
                    variant: WMSnackbarVariant.Error,
                    isOpen: true,
                })
            );
        }
    };

    const initialLoading = async () => {
        setLoading(true);
        await loadSiteConfig();
        await loadThemingConfig();
        setLoading(false);
    };

    const screenshotConfiguration = currentSystemAppData?.data?.featureFlags?.some((ff) => ff.flagName === DISABLE_SCREENSHOT_SETTINGS_FF);

    useEffect(() => {
        initialLoading();
    }, []);

    return loading ? (
        loaderComponent
    ) : (
        <MainContainer>
            <HeaderContainer>
                <HeaderMainText>{t(`systems-tab.new-system-form.configure-step.building-settings.main-title`)}</HeaderMainText>
            </HeaderContainer>
            <ContentContainer>
                <ScreenshotSettings
                    isEnabled={isEnabled}
                    handleToggle={handleScreenShotToggle}
                    isBlur={isBlur}
                    setIsBlur={setIsBlur}
                    screenshotConfiguration={screenshotConfiguration}
                />
                {accountFeatureEnabled('themingBetaEnablement') && (
                    <ThemingEnablement
                        isEnabled={isThemingEnabled}
                        handleToggle={handleThemingToggle}
                        themingConfiguration={themingConfiguration}
                    />
                )}
                <ImpersonatorDialog isLoading={savingData} />
            </ContentContainer>
            <StyledSaveAndPublish>
                <Button size='large' disabled={!hasConfigChanged() || savingData} loading={savingData} onClick={onSaveClicked}>
                    {t('buttons.save')}
                </Button>
            </StyledSaveAndPublish>
        </MainContainer>
    );
};
