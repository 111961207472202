import { StyledCSSSearchField, StyledInputLabel } from '../../../common/style';
import { InputAdornment } from '@material-ui/core';
import { Error, Search } from '@material-ui/icons';
import { FieldArray } from 'react-final-form-arrays';
import { CONSTS, TypesOptions, ArrayTypeOption } from '../../../common/consts';
import CustomToolTip from '../../../common/components/tool-tip/tool-tip';
import React, { useCallback, useEffect, useState } from 'react';
import NoResult from '../../../common/components/no-result/no-result';
import { Field, Fields, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import IdpDialog from '../../../common/components/idp-dialog/idp-dialog';
import { FieldsContainer, StyledPropertiesToImport } from './fields-section-style';
import FieldRow from './field-row/field-row';
import Base from '../../services/providers/base';
import { Saml } from '../../services/providers';

export interface FieldsSectionProps {
    copyFields: Fields;
    updateCopyFields: (fields: Fields) => void;
    fieldsLoad: boolean;
    values: ProviderFormValues;
    loadedFields: Fields;
    fields: Fields;
    providerInstance: Base;
}

export const FieldsSection = ({
    copyFields,
    updateCopyFields,
    fieldsLoad,
    values,
    loadedFields,
    fields,
    providerInstance,
}: FieldsSectionProps) => {
    const [search, setSearch] = useState('');
    const [isFieldTypeChange, setIsFieldTypeChange] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmData, setConfirmData] = useState({
        content: null,
        confirm: null,
        title: '',
    });

    useEffect(() => {
        if (fieldsLoad) {
            updateCopyFields(loadedFields);
        } else {
            updateCopyFields(fields);
        }
    }, [fieldsLoad, loadedFields, fields]);

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={showConfirm}
                handleClose={() => setShowConfirm(false)}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={'errorButton'}
                buttonText={'OK'}
            />
        );
    }, [showConfirm]);

    const searchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearch(event.target.value);
    };
    const updateFields = (aliasName: string, field: Field) => {
        copyFields = copyFields.map((copyField) => {
            if (copyField.name === field.name) {
                copyField.alias = aliasName;
            }
            return copyField;
        });
        updateCopyFields(copyFields);
    };
    const checkEqual = (initState, newState): boolean => {
        const formatPropsState = (item) => {
            return {
                name: item.name,
                dataType: item.dataType,
                ...(item.alias && item.alias.length && { alias: item.alias }),
            };
        };

        if (Array.isArray(initState) && Array.isArray(newState)) {
            return (
                JSON.stringify(initState.map(formatPropsState).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))) ===
                JSON.stringify(newState.map(formatPropsState).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)))
            );
        }
        return true;
    };

    const filterFields: Fields = copyFields.filter((field) => {
        if (field.alias) {
            return field.alias.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        }
        return field.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    const isChecked = (field: Field): boolean | null => {
        const checkedProperties = get(values, `fields`);
        if (checkedProperties) {
            return checkedProperties.some((property) => property.name === field.name);
        }
        return null;
    };

    const getProperty = (field: Field): number => {
        const checkedProps = get(values, `fields`);
        return checkedProps.findIndex((property) => property.name === field.name);
    };

    const getTypeMismatchMessage = (field: Field): JSX.Element => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>
                    We identified this field as a <span style={{ fontWeight: 'bold' }}>{field.suggestDataType}.</span>
                </span>
                <span>Please ensure you select the correct field type.</span>
            </div>
        );
    };

    const getArrayMessage = (field: Field): JSX.Element => {
        const delimiter = '#@#';
        const stringify = (v) => (typeof v === 'object' ? JSON.stringify(v) : v);

        let value = (field.value as Array<string | number>).map(stringify).join(delimiter);

        value = value.length > 80 ? `${value.substr(0, 77)}...` : value;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <span>Array values transformed into string with delimiter {delimiter}</span>
                <div>The result value will look like: {value ? `${delimiter}${value}${delimiter}` : 'null (the array is empty)'}</div>
            </div>
        );
    };

    const getWarning = (idpField: Field): JSX.Element => {
        const field = getField(idpField);

        const ready = fieldsLoad && field && isChecked(idpField);

        let message;
        if (ready && idpField.suggestDataType === 'Array') {
            message = getArrayMessage(idpField);
        } else if (ready && field.dataType !== idpField.suggestDataType) {
            message = getTypeMismatchMessage(idpField);
        }
        return (
            <>
                {message && (
                    <CustomToolTip title={message}>
                        <Error style={{ paddingRight: '8px', color: '#F7B500' }} />
                    </CustomToolTip>
                )}
            </>
        );
    };

    const getField = (field: Field): Field => {
        const checkedProperties = get(values, `fields`);
        if (checkedProperties) {
            return checkedProperties.find((property) => property.name === field.name);
        }
    };

    const updateFieldType = (fields, field: Field, fieldType: string): void => {
        field = { ...field, dataType: fieldType };
        const fieldIndex = copyFields.findIndex((property) => property.name === field.name);
        const tempFields = [...copyFields];
        tempFields[fieldIndex] = field;
        updateCopyFields(tempFields);
        fields.update(getProperty(field), field);
        setIsFieldTypeChange(true);
        setShowConfirm(false);
    };

    const fieldTypeChange = (fields, field: Field, fieldType: string): void => {
        if (isFieldTypeChange) {
            updateFieldType(fields, field, fieldType);
        } else {
            setConfirmData({
                content: (
                    <>
                        You are about to change the user attribute type.
                        <br />
                        Segmentation rules that rely on this attribute might be impacted by this change.
                        <br />
                        Read more on segmentation rules{' '}
                        <a
                            rel='noreferrer'
                            target='_blank'
                            href={
                                'https://support.walkme.com/knowledge-base/segmentation/#how-to-filter-insights-data-with-user-attributes'
                            }>
                            here
                        </a>
                        .
                    </>
                ),
                title: 'Change field type',
                confirm: () => updateFieldType(fields, field, fieldType),
            });
            setShowConfirm(true);
        }
    };

    return (
        <>
            {showConfirm && triggerConfirm()}
            <StyledPropertiesToImport>
                <StyledInputLabel>Properties to Import</StyledInputLabel>
                <span className={`helperText fontSmall`}>Choose the field that will be used to identify your users.</span>
            </StyledPropertiesToImport>
            <StyledCSSSearchField
                className={'fields-search'}
                value={search}
                variant='outlined'
                placeholder={'Search...'}
                onChange={searchChange}
                disabled={copyFields.length === 0}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <Search />
                        </InputAdornment>
                    ),
                }}></StyledCSSSearchField>
            <FieldArray name='fields' isEqual={checkEqual}>
                {({ fields }) => (
                    <FieldsContainer>
                        {filterFields && filterFields.length > 0 ? (
                            filterFields.map((field, index) => {
                                if (field && field.name === CONSTS.PROVIDER_GROUPS) return '';

                                let fieldOptions = TypesOptions;
                                if (!fieldsLoad || field.disabledToChangeType || providerInstance instanceof Saml) {
                                    fieldOptions = [...fieldOptions, ArrayTypeOption];
                                }
                                return (
                                    <FieldRow
                                        key={index}
                                        values={values}
                                        fields={fields}
                                        fieldsLoad={fieldsLoad}
                                        index={index}
                                        field={field}
                                        fieldTypeChange={fieldTypeChange}
                                        getProperty={getProperty}
                                        isChecked={isChecked}
                                        getWarning={getWarning}
                                        updateFields={updateFields}
                                        fieldOptions={fieldOptions}
                                    />
                                );
                            })
                        ) : (
                            <NoResult
                                image={'/assets/icons/idp-no-result.png'}
                                content={copyFields.length === 0 ? 'No properties found.' : `No properties match your search.`}
                                actionText={''}
                                onAction={null}
                                title={''}
                                paddingTop={'0'}
                            />
                        )}
                    </FieldsContainer>
                )}
            </FieldArray>
        </>
    );
};

export default FieldsSection;
