import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

const { buttons, messages, main } = styledTheme.colors;
const {
    outputs: { snippet },
} = messages;

export const Error = styled.span`
    color: ${main.mainText};
    background-color: ${messages.fail.bgColor};
    border-radius: 4px;
    padding: 8px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledSnippet = styled.div`
    border: 1px solid ${snippet.borderColor};
    background-color: ${snippet.bgColor};
    color: ${snippet.textColor};
    padding: 10px;
    margin: 10px 0;
    max-height: 86px;
    overflow: auto;
    font-size: 12px;
`;
export const StyledSnippetTitle = styled.div`
    font-size: 18px;
    margin: 20px 0;
    color: #78868f;

    &.sub {
        font-size: 14px;
        margin: 13px 0;
    }
`;

export const StyledFooter = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin: 0 0 15px 0;
`;

export const StyledCopyLink = styled.div`
    display: flex;

    span {
        margin-right: 5px;
    }

    & .copied {
        line-height: 33px;
        color: ${buttons.mainBgColor};
        margin-left: 5px;
    }
`;

export const StyledConfirmSystem = styled.div`
    display: flex;
    align-items: center;

    .icon {
        margin-right: 10px;
    }
`;

export const StyledSelect = styled(Select)`
    .MuiSelect-root {
        align-items: center;
        display: flex;
        height: 24px;
        padding: 3px 5px;
        background: white;
        width: 500px;
    }
`;

export const CustomClose = styled.div`
    display: inline-flex;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0;
    justify-content: flex-end;
    color: #aeaeae;
    cursor: pointer;
`;

export const StyledTitle = styled.div`
    padding: 5px 10px 10px;
    font-size: 23px;
`;

export const VerticalContentContainer = styled.div`
    display: flex;
`;

export const SideContainer = styled.div`
    display: flex;
    flex-direction: column;

    &.spaced {
        flex: 1;
        padding: 0 30px;
    }

    &.padded {
        padding: 0 30px;
    }
`;

export const ActionsContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90px;
    align-items: center;
`;

export const StyledLink = styled(Link)`
    color: ${styledTheme.colors.main.inlineLinks};
    border: 1px solid ${styledTheme.colors.main.inlineLinks};
    text-transform: uppercase;
    background-color: ${styledTheme.colors.main.mainBg};
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 200px;
    border-radius: 4px;
    transition: 0.2s;

    &:hover {
        text-decoration: none;
        border: 1px solid ${styledTheme.colors.main.mainText};
    }
`;

export const ImageContainer = styled.div`
    height: 100px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
`;

export const StyledP = styled.p`
    height: 180px;
`;

export const StyledContent = styled(CardContent)`
    padding: 10px 30px;
`;
export const StyledH2 = styled.h2`
    margin-bottom: 40px;
`;
