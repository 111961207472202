import i18n, { Namespace, TFunction, TOptions, i18n as i18nType } from 'i18next';
import { Trans, initReactI18next, useTranslation as _useTranslation, UseTranslationResponse } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useCallback } from 'react';

export const I18_CODE_KEY = 'i18nextLng';
export const DEFAULT_LANGUAGE_CODE = 'en';

let _reverseData = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initLocalization = (enData: any, jpnData: any, frData, deData, reverseData: any) => {
    i18n.use(initReactI18next)
        .use(LanguageDetector)
        .init({
            fallbackLng: DEFAULT_LANGUAGE_CODE,
            lng: localStorage?.getItem(I18_CODE_KEY) || DEFAULT_LANGUAGE_CODE,
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            detection: {
                order: ['localStorage', 'navigator'],
                caches: ['localStorage'],
            },
            resources: {
                en: {
                    ...enData,
                },
                jp: {
                    ...jpnData,
                },
                fr: {
                    ...frData,
                },
                de: {
                    ...deData,
                },
            },
        });

    _reverseData = reverseData;
};

export const setLocalizationLang = (langCode: string) => {
    i18n.changeLanguage(langCode);
    localStorage?.setItem(I18_CODE_KEY, langCode);
};

export type RTFunction = (value: string, options?: TOptions) => string;

const useReverseTranslation = (t: TFunction): RTFunction => {
    return useCallback(
        (value: string, options?: TOptions) => {
            const key = _reverseData[value];

            // if the value is not defined in the reverse translation map - return the original value.
            if (!key) {
                return value;
            }

            const translation = t(key, options);

            // if there is no translation for the key in the current language - return the original value.
            if (translation === key) {
                return value;
            }

            return translation;
        },
        [t]
    );
};

// Use the 'rt' function to translate a value from the db (db values should be added to the reverse translation map).
export type CombinedUseTranslationResponse<Ns extends Namespace, KPrefix> = [
    t: TFunction<Ns, KPrefix>,
    i18n: i18nType,
    ready: boolean,
    rt: RTFunction
] & {
    t: TFunction<Ns, KPrefix>;
    i18n: i18nType;
    ready: boolean;
    rt: RTFunction;
};

export const useTranslation = (namespace: string, keyPrefix: string = ''): CombinedUseTranslationResponse<string, string> => {
    const useTranslationResponse: UseTranslationResponse<string, string> = _useTranslation<string, string>(namespace, { keyPrefix });

    const { t, i18n, ready } = useTranslationResponse;

    const rt: RTFunction = useReverseTranslation(useTranslationResponse.t);

    const ret: any = [...useTranslationResponse, rt];
    ret.t = t;
    ret.i18n = i18n;
    ret.ready = ready;
    ret.rt = rt;

    return ret;
};

export const getTranslator = (namespace: string) => {
    return (key: string, values?: TOptions<Record<string, string>>) => i18n.t(`${namespace}:${key}`, values);
};

export default initLocalization;
