import React, { SVGProps } from 'react';

export const FiltersSelected = (props: SVGProps<SVGSVGElement>) => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M2.5 4.5H8.5H2.5Z' fill='white' />
        <path d='M2.5 4.5H8.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
        <circle cx='12.5' cy='4.5' r='2.5' fill='white' />
        <path d='M4.5 8.5H11.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.5 12.5H9.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);
