import { useSelector } from 'react-redux';
import { SystemsState } from '../redux/systems.slice';

export const useUpdatedEnvironment = () => {
  const updatedEnvironmentAppData = useSelector(
    (state: { systemsState: SystemsState }) => state.systemsState.updatedEnvironment
  );

  return {
    updatedEnvironmentAppData
  };
};

export default useUpdatedEnvironment;
