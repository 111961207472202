import React, { useEffect, useMemo } from 'react';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { getTabs } from '@walkme-admin-center/libs/common';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const EntryPointPage = () => {
    const { t } = useTranslation('general');
    const { loggedInUserAppData, accountFeatureEnabled, userPermissions } = useLoggedInUser();
    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);
    const isPermitted = useMemo(() => (actionKey, permission?) => userPermissions(actionKey, permission), [userPermissions]);
    const tabs = useMemo(() => getTabs(isFeatureEnabled, isPermitted, loggedInUserAppData, t), [isFeatureEnabled, isPermitted, t]);
    const navigate = useNavigate();

    const selectEntryPointPage = () => {
        const selectedPage = tabs?.filter((tab) => !tab.hidden)[0];
        if (selectedPage?.children?.[0]?.to) {
            navigate(selectedPage.children[0].to);
        } else if (selectedPage?.to) {
            navigate(selectedPage.to);
        }
    };

    useEffect(selectEntryPointPage, [userPermissions]);

    return <></>;
};

export default EntryPointPage;
