import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Grid, TableBody, Chip, TableContainer, Dialog } from '@material-ui/core';
import { WMDataGrid, WMSelect } from '@walkme/wm-ui';
import WMDialog from '@walkme/wm-ui/components/WMDialog';
import ChipInput from 'material-ui-chip-input';

export const StyledPartnersTabContainer = styled.div`
    padding: 0;

    .contentdiv: {
        height: 100%;
    }
`;

export const StyledPartnersContainer = styled.div`
    height: 75vh !important;
`;

export const StyledEditUserContainer = styled.div`
    .editPartnerModal {
        border-radius: 15px;
    }
`;

export const StyledGrid = styled(Grid)`
    height: 100%;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #dde5f5;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .editPartnerTable {
        cursor: pointer;
        margin-bottom: 2px;
        min-height: 35vh;
        max-height: 55vh;
    }

    .keywordChipsDiv {
        margin-bottom: 40px;
    }

    .keywordChipsAddTextDiv {
        margin-top: 3px;
        display: flex;
        justify-content: flex-start;

        .keywordChipsAddText {
            font-family: proximaNova;
            font-size: 14px;
            color: #2f426c;
        }
    }

    .usersTable {
        cursor: pointer;
        min-height: 55vh;
        max-height: 55vh;
        margin-bottom: 2px;

        &::-webkit-scrollbar: {
            -webkit-appearance: none;
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #dde5f5;
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }
`;

export const AddPartnerFooterText = styled.div`
    color: #2f426c;
    font-size: 13px;
`;

export const StyledPartnersWMDataGrid = styled(WMDataGrid)`
    .WMDataGridHeader-wmDataGridHeader {
        align-items: center;
    }
    .WMSearchFilter-clearButton {
        top: 50%;
    }
    .ag-theme-wm-ui .ag-details-row {
        padding: 0;
    }
    .ag-row-expandable {
        cursor: pointer !important;
    }
    .ag-row-non-expandable {
        cursor: default !important;
    }
    &.pined-right-action-column {
        .ag-pinned-right-header {
            border-left-color: transparent;
        }

        [col-id='actions-column'].ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
            border-left-color: transparent;
        }
    }
    .ag-theme-wm-ui:not(.active-filter) .ag-header-cell[col-id='actions-column'] {
        height: 100%;
    }
    .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
        visibility: hidden;
        padding: 0 !important;
    }
    .client-action {
        background-color: white !important;
        .row-buttons {
            display: none !important;
        }
    }
    .ag-row-hover .client-action .row-buttons {
        display: flex !important;
        flex-direction: row;
        //justify-content: space-between;
        gap: 10px;
    }
    .editor-action {
        background-color: white !important;
        .PartnerClients-buttons {
            display: none !important;
        }
    }
    .ag-row-hover .editor-action .PartnerClients-buttons {
        display: initial !important;
    }
    .ag-theme-wm-ui .ag-row {
        background-color: #f9fbfd;
    }
    .ag-theme-wm-ui .ag-header-row {
        background-color: #f9fbfd;
    }
    .main-table {
        background-color: white !important;
    }
    .inside-table {
        background-color: #f9fbfd !important;
    }
    .ag-cell-wrapper {
        height: 100%;
    }
    .ag-theme-wm-ui {
        .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
            border-left: solid 0px;
        }
    }
    .ag-pinned-right-cols-container {
        .ag-row-even .ag-row-no-focus .ag-row .ag-row-level-0 .ag-row-position-absolute .ag-row-first {
            background-color: #f9fbfd !important;
            border-left: 0px;
        }
    }
    .WMDataGridActionColumn-actionMenu {
        align-items: center;
    }
    .ag-theme-wm-ui.bottom-scroll-shadow .ag-body-viewport {
        padding-bottom: 0;
    }
    .ag-theme-wm-ui .ag-layout-auto-height .ag-center-cols-clipper,
    .ag-theme-wm-ui .ag-layout-auto-height .ag-center-cols-container,
    .ag-theme-wm-ui .ag-layout-print .ag-center-cols-clipper,
    .ag-theme-wm-ui .ag-layout-print .ag-center-cols-container {
        min-height: 0;
    }
`;

export const StyledTitleDiv = styled.div`
    margin-bottom: 24px;
`;

export const StyledPageTitleLabel = styled.label`
    font-family: 'ProximaNova';
    color: #7680a3;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
`;

export const StyledWMSelect = styled(WMSelect)`
    .wmSelect__single-value {
        width: 100%;
        .MuiTypography-root {
            padding: 0px !important;
        }
    }
    .wmSelect__option {
        padding: 0px;
    }
    .WMSelect-optionLabel {
        width: 100%;
    }
    .MuiButton-root:hover {
        background-color: white;
    }
    .MuiButtonBase-root {
        background-color: white;
    }
    .WMSelect-popoutSelect {
        .wmSelect__option {
            padding: 10px 16px;
        }
    }
`;

export const NoPartnersLabel = styled.label`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2f426c;
    margin-top: 32px;
    margin-bottom: 10px;
`;

export const NoPartnersDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: white;
    border-radius: 15px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
`;

export const StyledContentElements = styled.div`
    width: 100%;
    .titleGrid {
        margin: 20px 32px;

        .titleContainer {
            min-height: 21px;

            .dialogTitle {
                font-family: proximaNova;
                font-size: 18px;
                color: #2f426c;
                line-height: 1.5;
                font-weight: 600;
            }
        }
    }

    .wizardDialogContent {
        margin: 0 32px;
        min-height: 400px;

        .wizardDialogContainer {
            height: 200px;
        }
    }
`;

export const StyledCloseDialogImage = styled.img`
    height: 12px;
`;

export const StyledTableBody = styled(TableBody)`
    .tableRowSelected {
        background-color: #f5f7fe !important;
        &:hover {
            background-color: #f5f7fe !important;
        }
        .emailDiv {
            display: inline-flex;
            align-items: center;

            .userImage {
                display: flex;
                background-color: #415395;
                width: 24px;
                height: 24px;
                border-radius: 50%;

                .userImageText {
                    margin: auto;
                    color: #ffffff;
                    font-size: 10px;
                }
            }

            .emailText {
                margin-left: 10px;
                font-family: 'Proxima Nova', sans-serif;
                color: #2f426c;
                font-size: 14px;
            }
        }
    }
`;

export const StyledWizardDialogButtons = styled(Grid)`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -2px 0px #0000000a;
    padding: 15px 32px 22px 0;

    .wizardDialogSecondaryButton {
        margin-right: 22px;
    }
`;

export const EmailColumn = styled.div`
    display: flex;
    align-items: center;
    padding: 0 24px;
`;

export const StatusColumn = styled.div`
    display: flex;
    align-items: center;

    .userRowStatus {
        margin-left: 6px;
        font-family: proximaNova;
        color: #2f426c;
        font-size: 12px;
    }
`;

export const ActionMenuColumn = styled.div`
    position: relative;
    bottom: 5px;
`;

export const InvitesTabContainer = styled.div`
    padding: 0;

    .contentDiv {
        height: 100%;

        .partnersContainer {
            height: 75vh;
        }
    }
`;

export const AccountNameColumn = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;

    .accountRowChevron {
        min-width: 20px;
        position: relative;
        top: 3px;
    }

    .accountRowName {
        align-items: center;
        display: flex;
        justify-content: center;
        background: #eceffa;
        border-radius: 100px;
        min-width: 29px;
        width: 29px;
        height: 29px;
        margin-right: 9px;
        text-align: center;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
    }
`;

export const UserStatusColumn = styled.div`
    display: flex;
    align-items: center;

    .userRowStatus {
        margin-left: 6px;
        font-family: proximaNova;
        color: #2f426c;
        font-size: 12px;
    }
`;

export const StyledDeletionDialog = styled(WMDialog)`
    width: 577px !important;
    background-color: white;

    .DeletionDialogText {
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        margin: 0;
        padding-bottom: 20px;
    }

    .DeleteItemsButton {
        height: 40px !important;
        width: 82px;
        border-radius: 40px !important;
        background-color: #eb3232 !important;
        &:hover {
            background-color: #b02626 !important;
        }

        &:active {
            background-color: #942020 !important;
        }
    }
`;

export const StyledAddPartnerContentElements = styled.div`
    width: 100%;
    .titleGrid {
        margin: 20px 32px;

        .titleContainer {
            min-height: 21px;

            .dialogTitle {
                font-family: proximaNova;
                font-size: 18px;
                color: #2f426c;
                line-height: 1.5;
                font-weight: 600;
            }
        }
    }

    .wizardDialogContent {
        margin: 0 32px;
    }
`;

export const StyledChipInput = styled(ChipInput)`
    box-sizing: border-box;
    padding: 2px 4px;
    background-color: #f0f4fa; //var(--wmBackground);
    border-radius: 4px;
    border: 1px solid transparent;
    transition: border-color 0.3s ease-out;

    &:focus-within {
        border-color: #9da5c2; //var(--wmGrayDark);
    }

    .inputRoot {
        margin: 2px;
        margin-left: 6px;
        min-width: 35px;
        font-family: 'ProximaNova', sans-serif;
        color: #2f426c; //var(--wmGrayUltraDark)
        font-size: 14px;
        line-height: 1.5;
    }

    .input {
        height: 24px;
        padding: 0;
        letter-spacing: 0;
        box-sizing: border-box;

        &::placeholder {
            color: #6d81a6; //var(--wmBermudaGray);
            opacity: 1;
        }
    }

    .chipContainer {
        max-height: 90px;
        margin-bottom: 0;
    }
`;

export const DeleteIcon = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 0;
    color: #6d81a6;
    transition: 0.3s ease-out;

    &:hover {
        color: #2f426c; //var(--wmGrayUltraDark);
        background-color: #cedaeb; //var(--wmGrayUltraLight);
    }
`;

export const StyledChip = styled(Chip)`
    &.chip {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 14px;
        color: #2f426c;
        padding: 0 4px;
        margin: 3px;
        border-radius: 10px;
        background-color: #dde5f5;
        &:focus {
            background-color: #dde5f5;
        }
    }

    &.chipLabel {
        padding: 0;
        margin-left: 1px;
        margin-right: 4px;
    }
`;

export const StyledAddPartnerTableBody = styled(TableBody)`
    .tableRowSelected {
        background-color: #f5f7fe !important;
        &:hover {
            background-color: #f5f7fe !important;
        }
    }
`;

export const StyledEmailContainer = styled.div`
    .emailDiv {
        display: inline-flex;
        align-items: center;

        .userImage {
            display: flex;
            background-color: #415395;
            width: 24px;
            height: 24px;
            border-radius: 50%;

            .userImageText {
                margin: auto;
                color: #ffffff;
                font-size: 10px;
            }
        }

        .emailText {
            margin-left: 10px;
            font-family: 'Proxima Nova', sans-serif;
            color: #2f426c;
            font-size: 14px;
        }
    }
`;

export const StyledAddPartnerDialog = styled(Dialog)`
    .addPartnerModal {
        border-radius: 15px;
    }
`;
