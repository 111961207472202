import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeployablesState, getSystemsDeployablesCount } from '../redux/deployables.slice';

export const useDeployables = (forceLoad = false) => {
    const systemsDeployablesCountAppData = useSelector(
        (state: { deployablesState: DeployablesState }) => state.deployablesState.systemsDeployablesCount
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSystemsDeployablesCount(forceLoad));
    }, [dispatch]);

    return {
        systemsDeployablesCountAppData,
    };
};

export default useDeployables;
