import React from 'react';
import styled from 'styled-components';
import { WMCircularProgress } from '@walkme/wm-ui';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const CircularProgress = ({ size = 40 }) => {
    return (
        <Container>
            <WMCircularProgress />
        </Container>
    );
};
