import React from 'react';
import { Box } from '@material-ui/core';
import {
    StyledCreateSystemCardContainer,
    StyledCreateSystemCardIcon,
    StyledCreateSystemCardSubTitle,
    StyledCreateSystemCardTitle,
} from './purpose-and-usage.styles';
import {
    SystemPurpose as SystemPurposeOptions,
    SystemUsage as SystemUsageOptions,
} from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/systems-types';
import { DocumentIcon } from '../../assets/icons/document';
import { SearchCategoryIcon } from '../../assets/icons/search-category';
import { EmployeeCardIcon } from '../../assets/icons/employee-card';
import { BusinessDealIcon } from '../../assets/icons/business-deal';
import { DeploymentMethods } from '../select-deployment/select-deployment.lib';
import { CodeTagIcon } from '../../assets/icons/code-tag';
import { PuzzlePartIcon } from '../../assets/icons/puzzle-part';

const iconComponents = {
    [SystemPurposeOptions.COMMERCIAL]: <DocumentIcon />,
    [SystemPurposeOptions.TRIAL]: <SearchCategoryIcon />,
    [SystemUsageOptions.INTERNAL]: <EmployeeCardIcon />,
    [SystemUsageOptions.EXTERNAL]: <BusinessDealIcon />,
    [DeploymentMethods.EXTENSION]: <PuzzlePartIcon />,
    [DeploymentMethods.SNIPPET]: <CodeTagIcon />,
};

interface CardProps {
    title: string;
    subTitle: string;
    value: string;
    selected?: boolean;
    onCardClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const CreateSystemCard = ({ title, subTitle, value, selected = false, onCardClick }: CardProps) => {
    return (
        <StyledCreateSystemCardContainer selected={selected} onClick={onCardClick}>
            <StyledCreateSystemCardIcon>{iconComponents[value]}</StyledCreateSystemCardIcon>
            <Box display={'flex'} flexDirection={'column'} width={155}>
                <StyledCreateSystemCardTitle>{title}</StyledCreateSystemCardTitle>
                <StyledCreateSystemCardSubTitle>{subTitle}</StyledCreateSystemCardSubTitle>
            </Box>
        </StyledCreateSystemCardContainer>
    );
};
