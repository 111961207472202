import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export interface UnsupportedErrorPageProps {
    imgPath?: string;
    whyText: string;
    whatToDoText?: string;
}

const StyledTitle = styled(Typography)`
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const StyledText = styled(Typography)`
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const UnsupportedErrorPage = ({
    imgPath = '/assets/no_permission_img.svg',
    whyText,
    whatToDoText = 'Please contact support for help.',
}: UnsupportedErrorPageProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
            <img alt={'more'} src={imgPath} height='220px' width={'220px'} />
            <StyledTitle style={{ textAlign: 'center', marginTop: '16px' }}>{whyText}</StyledTitle>
            <StyledText style={{ textAlign: 'center', marginTop: '10px' }}>{whatToDoText}</StyledText>
        </div>
    );
};
