import { Switch, Tab, Tabs, TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

export const FieldLabel = styled.span`
    font-weight: 600;
    padding-bottom: 8px;
`;

export const FieldLabelLight = styled.span`
    font-weight: 400;
    font-size: 12px;
    color: #2f426c;
    padding-bottom: ${(props) =>
        // @ts-ignore
        props.noPadding ? '0px' : '4px'};
`;

export const FieldLabelLightSmall = styled(FieldLabelLight)`
    font-size: 0.75rem;
`;

export const FieldLabelDescription = styled.span`
    color: var(--color-input-dropdown-content-primary-default, #8d97ae);
    font-family: 'Proxima Nova';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 4px;
`;

export const CssTabs = withStyles({
    root: {
        height: '2.5rem',
        minHeight: 'auto',
        marginTop: '1.5rem',
        justifyContent: 'space-evenly',
        padding: '0 2rem',
        gap: '1rem',
    },
    scroller: {
        borderBottom: 'solid 1px #F5F6F8',
    },
    indicator: {
        height: 1,
    },
})(Tabs);

export const MainSourcesTabs = withStyles({
    root: {
        height: '2.5rem',
        minHeight: 'auto',
    },
    indicator: {
        height: 1,
    },
})(Tabs);

export const CssTab = withStyles({
    root: {
        flex: '1 0 0',
        maxWidth: 'unset',
        minHeight: 'inherit',
        padding: 0,
        minWidth: '140px',
        color: '#637191',
        textTransform: 'capitalize',
        fontFamily: 'Proxima Nova',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        '& .MuiTab-wrapper .DataIntegrationTab': {
            '&:hover': {
                color: '#385FEB',
            },
        },
    },
    selected: {
        color: '#385FEB',
    },
})(Tab);

export const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            caretColor: '#385FEB',
            borderRadius: '4px',
            background: '#F4F6FB',

            '&.MuiOutlinedInput-multiline': {
                padding: '0.5rem 0',
            },
        },
        '& .MuiOutlinedInput-input': {
            '&::placeholder': {
                color: '#8D97AE',
                fontWeight: 400,
                opacity: 1,
            },
            color: '#2F426C',
            borderRadius: 'inherit',
            padding: '8px 12px 8px 16px',
            height: '24px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderColor: '#F4F6FB',
        },
        '& .Mui-error.MuiInputBase-root': {
            background: '#FDEDED',
            caretColor: '#EB3232',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EB3232',
            },
            '&:hover': {
                background: '#FCE2E2',
            },
        },
        '& .Mui-focused': {
            background: '#FFFFFF !important',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#385FEB',
            },
        },
        '& .MuiInputBase-root:hover:not( .Mui-error):not( .Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: '#93A8F4',
        },
        '& .MuiFormHelperText-root': {
            margin: '4px 0 0 0',
            height: '16px',
            background: 'none',
            textAlign: 'end',
            '&.Mui-error': {
                color: '#B02626',
            },
        },
        '&.queryParams': {
            width: '260px',
            // '& .MuiOutlinedInput-root': {
            //     borderRadius: '0 4px 4px 0',
            // },
            '& .MuiOutlinedInput-input': {
                padding: '4px 8px 4px 12px',
                height: '24px',
            },
        },
        '&.subDomain': {
            width: '347px',
            '& .MuiOutlinedInput-root': {
                borderRadius: '0 4px 4px 0',
            },
            '& .MuiOutlinedInput-input': {
                padding: '4px 8px 4px 12px',
                height: '24px',
            },
            '&.readOnly': {
                width: '193px',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '4px 0 0 4px',
                    background: '#EBEFF7',
                },
                '& .Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                },
            },
        },
        '&.search': {
            flex: 1,
            '& .MuiOutlinedInput-root': {
                background: '#F4F6FB',
                borderRadius: '50px',
            },
            '& .MuiOutlinedInput-input': {
                height: '24px',
                padding: '4px 12px',
            },
            '&.main-page': {
                width: '258px',
                '& .MuiOutlinedInput-root': {
                    background: '#F0F3F9',
                },
            },
        },
    },
})(TextField);
export const CssSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&:hover': {
            background: 'none !important',
            '& + .MuiSwitch-track': {
                backgroundColor: '#3B61EB8C',
            },
        },
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#385FEB',
            },
            '&:hover': {
                '& + .MuiSwitch-track': {
                    backgroundColor: '#2B47AC',
                },
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        background: 'white',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: 'width 2s',
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#C5CAD6',
        boxSizing: 'border-box',
    },
}));
export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-listbox': {
        borderRadius: '0.75rem',
        background: 'var(--Color-Bg-secondary, #FFF)',
        boxShadow: '0px 16px 24px 0px rgba(47, 66, 108, 0.11)',
        '& .MuiAutocomplete-option': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)', // Custom hover background
            },
        },
    },
}));

export const TextFieldContainer = styled.span`
    height: 68px;
    display: flex;
    flex-direction: column;
`;

export const SwitchLabel = styled.span`
    &.active {
        font-weight: 500;
        color: #2f426c;
    }
    height: 20px;
    color: #637191;
    padding-left: 8px;
    font-weight: 400;
`;
export const FormContent = styled.div`
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;
export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 56px);
`;

export const FormFooter = styled.div`
    padding: 16px 32px 32px 32px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
