import { DataTableLocale } from '@walkme-admin-center/libs/ui-components';
import { useTranslation } from 'apps/home/src/localization';

export const useDatagridLocale = () => {
    const { t } = useTranslation('general');

    return {
        searchPlaceholder: t('data-grid.search'),
        noResultsTitle: t('data-grid.noResults.title'),
        noResultsText: t('data-grid.noResults.text'),
        noFilterResultsText: t('data-grid.noResults.filterText'),
        rowsPerPage: t('data-grid.footer.rowsPerPage'),
        showing: t('data-grid.footer.showing'),
        of: t('data-grid.footer.of'),
        columnsPickerTooltip: t('data-grid.columnPicker.columnsPickerTooltip'),
        addColumns: t('data-grid.columnPicker.addColumns'),
        allColumns: t('data-grid.columnPicker.allColumns'),
    };
};

export const useDataTableLocale = (): DataTableLocale => {
    const { t } = useTranslation('general');

    return {
        footerLocale: {
            showing: t('data-grid.footer.showing'),
            of: t('data-grid.footer.of'),
            rowsPerPage: t('data-grid.footer.rowsPerPage'),
        },
        emptySearchLocale: {
            title: t('data-grid.noResults.title'),
            textWithQuery: t('data-grid.noResults.text'),
        },
    };
};
