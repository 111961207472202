import { DataIntegrationState } from '../redux/data-integration.slice';
import { useSelector } from 'react-redux';

export const useAskMeFiles = () => {
    const askMeFiles = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.files.data;
    });

    return {
        askMeFiles,
    };
};

export default useAskMeFiles;
