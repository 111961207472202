import { PartnerAccountData, PartnerUserData, PartnerUserStatus } from '@walkme-admin-center/libs/types';
import { WMMenu, WMStatusDot, WMTagList, WMTagProps, WMTagVariant, WMTooltip } from '@walkme/wm-ui';
import React from 'react';
import { UserStatusMap } from './utils/partner-status';
import { Delete, Edit } from '@walkme/ui-icons';
import { deleteUser } from '@walkme-admin-center/libs/state-management-partners';
import { LabelWithTooltip } from './components/LabelWithTooltip';
import { UserStatusColumn } from './styles/styles';

export function getDetailCellRendererParams(onEditUser, dispatch, t, rt, dateTimeLocale) {
    function onDeleteUser(userId: number) {
        deleteUser(dispatch, userId);
    }

    const formatDate = (date: string): string => {
        return new Date(date).toLocaleDateString(dateTimeLocale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const result = {
        detailGridOptions: {
            suppressCellFocus: true,
            class: 'inside-table',
            detailRowAutoHeight: true,
            columnDefs: [
                {
                    field: t('common.email'),
                    minWidth: 250,
                    maxWidth: 250,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                        fontWeight: 600,
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <LabelWithTooltip text={data.email} />
                            </div>
                        );
                    },
                },
                {
                    field: t('common.name'),
                    minWidth: 250,
                    maxWidth: 250,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <span>
                                    {data.firstName} {data.lastName}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    field: t('common.role'),
                    minWidth: 150,
                    maxWidth: 150,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <span>{rt(data?.role?.displayName)}</span>
                            </div>
                        );
                    },
                },
                {
                    field: t('common.systems'),
                    minWidth: 250,
                    maxWidth: 250,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                        display: 'flex',
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        const systems = data.assignedSystems;
                        const systemsLabels: WMTagProps[] =
                            systems && systems.length > 0
                                ? systems.map((system) => ({
                                      labelText: system?.displayName,
                                      fullLabel: true,
                                      variant: WMTagVariant.Comet,
                                  }))
                                : [];
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <WMTagList labels={systemsLabels} maxTagsShown={3} />
                            </div>
                        );
                    },
                },
                {
                    field: t('common.last-login'),
                    minWidth: 200,
                    maxWidth: 200,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <span>{data.lastLogin ? formatDate(data.lastLogin) : 'N/A'}</span>
                            </div>
                        );
                    },
                },
                {
                    field: t('common.added-on'),
                    minWidth: 200,
                    maxWidth: 200,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <span>{formatDate(data.createdDate)}</span>
                            </div>
                        );
                    },
                },
                {
                    field: t('common.status'),
                    minWidth: 150,
                    maxWidth: 150,
                    cellStyle: {
                        color: '#2F426C',
                        fontSize: '14px',
                    },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;
                        const mappedStatus =
                            data.assignedSystems.length === 0
                                ? UserStatusMap.find((mappedStatus) => mappedStatus.id === PartnerUserStatus.NoSystems)
                                : UserStatusMap.find((mappedStatus) => mappedStatus.id === PartnerUserStatus.Active);
                        return (
                            <div>
                                <WMTooltip title={t(mappedStatus.info)}>
                                    <div>
                                        <UserStatusColumn>
                                            {<WMStatusDot variant={mappedStatus.color} />}
                                            <span className={'userRowStatus'}>{t(mappedStatus.display)}</span>
                                        </UserStatusColumn>
                                    </div>
                                </WMTooltip>
                            </div>
                        );
                    },
                },
                {
                    minWidth: 50,
                    cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
                    cellRenderer: (params) => {
                        const data: PartnerUserData = params.data;

                        return (
                            <WMMenu
                                menuButtonIconComponent={<img alt={'more'} src={`/assets/icons/icon-moreVertical.svg`} />}
                                menuButtonClassName={'menuButton'}
                                menuItems={[
                                    {
                                        label: t('buttons.edit-user'),
                                        startIcon: <Edit />,
                                        onClick: () => onEditUser(params.data),
                                    },
                                    {
                                        label: t('buttons.delete-user'),
                                        startIcon: <Delete />,
                                        onClick: () => onDeleteUser(data.id),
                                    },
                                ]}
                                autoFocus={false}
                            />
                        );
                    },
                },
            ],
            defaultColDef: {
                flex: 1,
                headerClass: 'inside-table',
                cellClass: 'inside-table',
            },
        },
        refreshStrategy: 'everything',
        getDetailRowData: (params) => {
            const data: PartnerAccountData = params.data;
            params.successCallback(data.partnerUsers);
        },
        dateTimeLocale,
    };

    return result;
}
