import { IAskMe } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import useLocalStorage from '../../data-integrations-header/use-local-storage';

export const useDismissedScanMsg = (source: IAskMe) => {
    return useLocalStorage(`scan-msg.${source.jobStatus?.executionId}`);
};

export const useDismissedRevertFailedScanMsg = (source: IAskMe) => {
    return useLocalStorage(`scan-revert-failed-msg.${source.jobStatus?.executionId}`);
};
