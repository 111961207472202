import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        configurationErrorTitle: {
            fontFfamily: 'Proxima Nova',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center'
        },
        configurationErrorMessage: {
            fontFfamily: 'Proxima Nova',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        configurationErrorContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
);

export { useStyles };