import { Snackbar } from '@material-ui/core';
import styled from 'styled-components';

export const StyledSnackBar = styled(Snackbar)`
    & .MuiAlert-root {
        color: #2f426c;
        box-shadow: none;
        padding: 0px 12px;
        font-size: 14px;
        align-items: center;
        &.MuiAlert-filledSuccess {
            background-color: #eef8f8;
            border: 1px solid #41b7a6;
        }

        &.MuiAlert-filledError {
            background-color: #fcf2f3;
            border: 1px solid #eb3232;
        }

        & .MuiAlert-message {
            white-space: pre-line;
        }

        & .MuiAlert-action {
            margin: 0;
            padding: 0;
            padding-left: 12px;

            & img:hover {
                cursor: pointer;
                filter: invert(52%) sepia(12%) saturate(1077%) hue-rotate(181deg) brightness(93%) contrast(86%);
            }
        }
    }
`;
