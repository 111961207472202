import React from 'react';
import { ChangeToCustom } from './change-to-custom';
import { AppListContainer } from './app-list-container/app-list-container';
import { useSelector } from 'react-redux';
import { CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { StyledSelectApplicationContainer } from './select-application.styles';
import { CustomApp } from './custom-app/custom-app';

export const SelectApplicationStep = () => {
    const isCustomMode = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.isCustomMode.data);

    return (
        <StyledSelectApplicationContainer>
            {isCustomMode ? <CustomApp /> : <AppListContainer />}
            <ChangeToCustom />
        </StyledSelectApplicationContainer>
    );
};
