import React from 'react';
import styled from 'styled-components';
import { StyledSubtext } from './no-result.styles';

const NoResultContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const NoResult = ({ title, content, paddingTop, actionText, onAction, image }) => {
    return (
        <NoResultContainer>
            <div style={{ width: '317px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img width={'247'} src={image} alt='' style={{ paddingTop: paddingTop }} />
                <span style={{ fontWeight: 600, paddingTop: '10px', fontSize: '16px' }}>{title}</span>
                <StyledSubtext style={{ textAlign: 'center' }}>
                    {content}
                    <span className={'link'} onClick={onAction}>
                        {actionText}
                    </span>
                </StyledSubtext>
            </div>
        </NoResultContainer>
    );
};

export default NoResult;
