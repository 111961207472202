import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const HeaderContainer = styled.div`
    display: flex;
    height: 80px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 99;
    width: 100%;
    border-bottom: 1px solid #f5f7fc;
    & .title {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;
export const Header = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: #2f426c;
    padding-left: 32px;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & .pipe {
    height: 24px;
    width: 1px;
    background: #CEDAEB
  },
  & .back-icon-color {
    color: #7680A3
  }
}
`;

export const StyledIconButton = styled(IconButton)`
    &:hover {
        background-color: transparent;
    }
`;
