import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

export const AntSwitch = withStyles(() => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        marginTop: '2px'
    },
    switchBase: {
        padding: 2,
        color: '#FFFF',
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#FFFF',
            '& + $track': {
                opacity: 1,
                backgroundColor: styledTheme.colors.mainBlue,
                borderColor: styledTheme.colors.mainBlue,
            }
        }
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none'
    },
    track: {
        border: `1px solid ${styledTheme.colors.mainBgGray}`,
        borderRadius: 8,
        opacity: 1,
        backgroundColor: styledTheme.colors.mainBgGray,
        height: '90%'
    },
    checked: {}
}))(Switch);