import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Error } from '@material-ui/icons';
import { CONSTS } from '../../consts';
import { StyledSnackBar } from './custom-snackbar.styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export interface CommonSnackbarProps {
    open: boolean;
    severity: string;
    messageText: string;
    onClose: () => void;
    marginTop?: string;
    autoHideDuration?: number;
    anchorOrigin?: { vertical: 'bottom' | 'top'; horizontal: 'center' | 'left' | 'right' };
}
export const CustomSnackBar = ({
    open,
    onClose,
    messageText,
    severity,
    marginTop = CONSTS.MARGIN_TOP_SNACKBAR,
    autoHideDuration = 7000,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
}: CommonSnackbarProps) => {
    const successIcon = (
        <img
            style={{
                width: '16px',
                height: '16px',
                filter: 'invert(60%) sepia(37%) saturate(626%) hue-rotate(122deg) brightness(99%) contrast(86%)',
            }}
            src='assets/icons/check.svg'
            alt='check'
        />
    );
    const closeIcon = <img src='assets/icons/x-snack-bar.svg' alt='cc' onClick={() => onClose()} />;
    const errorIcon = <Error fontSize={'small'} style={{ color: '#EB3232' }} />;

    const getIcon = () => {
        switch (severity) {
            case 'success':
                return successIcon;
            case 'error':
                return errorIcon;
        }
    };
    return (
        <StyledSnackBar
            classes={{ root: 'root' }}
            style={{ marginTop: marginTop }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={anchorOrigin}>
            <Alert icon={getIcon()} action={closeIcon} severity={severity}>
                {messageText}
            </Alert>
        </StyledSnackBar>
    );
};

export default CustomSnackBar;
