import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
    AccountsSdk,
    WMAccountIdp,
    SamlAccountIdpConfigurationDTO,
    WMSAMLIdp,
    AccountUsersToIdpExtraDataUserNameIdDTO,
} from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { getUsers } from './users.slice';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import { WMAccountSecurityFlags } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/security-types';

export interface AccountIdpsState {
    alreadyLoadedIdps: boolean;
    accountIdps: AppData<WMAccountIdp[]>;
    savedAccountIdp: AppData<WMAccountIdp>;
    createdIdpAppData: AppData<WMSAMLIdp>;
    updateAccountIdpUsers: AppData<boolean>;
    deletedAccountIdp: AppData<boolean>;
    socialSetting: AppData<any>;
    securityFlags: AppData<WMAccountSecurityFlags>;
    settingSecurityFlags: AppData<void>;
}

export const initialAccountIdpsState: AccountIdpsState = {
    alreadyLoadedIdps: false,
    settingSecurityFlags: {
        loading: false,
        error: null,
        data: null,
    },
    accountIdps: {
        loading: false,
        error: null,
        data: [],
    },
    securityFlags: {
        loading: false,
        error: null,
        data: null,
    },
    savedAccountIdp: {
        loading: false,
        error: null,
        data: null,
    },
    createdIdpAppData: {
        loading: false,
        error: null,
        data: null,
    },
    deletedAccountIdp: {
        loading: false,
        error: null,
        data: null,
    },
    updateAccountIdpUsers: {
        loading: false,
        error: null,
        data: null,
    },
    socialSetting: {
        loading: false,
        error: null,
        data: null,
    },
};

const errorFomatter = (e: any) => (e.response && e.response.data && e.response.data.message) || 'Unspecified error';

const accountIdpsSlice = createSlice({
    name: 'accountIdpsSlice',
    initialState: initialAccountIdpsState,
    reducers: {
        idpsLoaded(state: AccountIdpsState) {
            state.alreadyLoadedIdps = true;
            return state;
        },
        getAccountIdpsStart(state: AccountIdpsState) {
            state.accountIdps.error = null;
            state.accountIdps.loading = true;
            return state;
        },
        getAccountIdpsSuccess(state: AccountIdpsState, action: PayloadAction<WMAccountIdp[]>) {
            state.accountIdps.data = action.payload;
            state.accountIdps.loading = false;
            state.accountIdps.error = null;
            return state;
        },
        getAccountIdpsFailed(state, action: PayloadAction<string>) {
            state.accountIdps.loading = false;
            state.accountIdps.error = action.payload;
            return state;
        },
        saveAccountIdpStart(state: AccountIdpsState) {
            state.savedAccountIdp.error = null;
            state.savedAccountIdp.loading = true;
            return state;
        },
        saveAccountIdpSuccess(state: AccountIdpsState, action: PayloadAction<WMAccountIdp>) {
            state.savedAccountIdp.data = action.payload;
            state.savedAccountIdp.loading = false;
            state.savedAccountIdp.error = null;
            return state;
        },
        saveAccountIdpFailed(state, action: PayloadAction<string>) {
            state.savedAccountIdp.loading = false;
            state.savedAccountIdp.error = action.payload;
            return state;
        },
        getSecurityFlagsStart(state: AccountIdpsState) {
            state.securityFlags.error = null;
            state.securityFlags.loading = true;
            return state;
        },
        getSecurityFlagsSuccess(state: AccountIdpsState, action: PayloadAction<WMAccountSecurityFlags>) {
            state.securityFlags.data = action.payload;
            state.securityFlags.loading = false;
            state.securityFlags.error = null;
            return state;
        },
        getSecurityFlagsFailed(state: AccountIdpsState, action: PayloadAction<string>) {
            state.securityFlags.loading = false;
            state.securityFlags.error = action.payload;
            return state;
        },
        setSecurityFlagsStart(state: AccountIdpsState) {
            state.settingSecurityFlags.error = null;
            state.settingSecurityFlags.loading = true;
            return state;
        },
        setSecurityFlagsSuccess(state: AccountIdpsState) {
            state.settingSecurityFlags.loading = false;
            state.settingSecurityFlags.error = null;
            return state;
        },
        setSecurityFlagsFailed(state: AccountIdpsState, action: PayloadAction<string>) {
            state.settingSecurityFlags.loading = false;
            state.settingSecurityFlags.error = action.payload;
            return state;
        },
        savedAccountIdpCleanup(state: AccountIdpsState) {
            state.savedAccountIdp.data = null;
            state.savedAccountIdp.error = null;
            state.savedAccountIdp.loading = false;
            return state;
        },
        createAccountIdpStart(state: AccountIdpsState) {
            state.createdIdpAppData.error = null;
            state.createdIdpAppData.loading = true;
            return state;
        },
        createAccountIdpSuccess(state: AccountIdpsState, action: PayloadAction<WMSAMLIdp>) {
            state.createdIdpAppData.data = action.payload;
            state.createdIdpAppData.loading = false;
            state.createdIdpAppData.error = null;
            return state;
        },
        createAccountIdpFailed(state, action: PayloadAction<string>) {
            state.createdIdpAppData.loading = false;
            state.createdIdpAppData.error = action.payload;
            return state;
        },
        createdAccountIdpCleanup(state: AccountIdpsState) {
            state.createdIdpAppData.data = null;
            state.createdIdpAppData.error = null;
            state.createdIdpAppData.loading = false;
            return state;
        },
        deleteAccountIdpStart(state: AccountIdpsState) {
            state.deletedAccountIdp.error = null;
            state.deletedAccountIdp.loading = true;
            return state;
        },
        deleteAccountIdpSuccess(state: AccountIdpsState) {
            state.deletedAccountIdp.data = true;
            state.deletedAccountIdp.loading = false;
            state.deletedAccountIdp.error = null;
            return state;
        },
        deleteAccountIdpFailed(state, action: PayloadAction<string>) {
            state.deletedAccountIdp.loading = false;
            state.deletedAccountIdp.error = action.payload;
            return state;
        },
        deletedAccountIdpCleanup(state: AccountIdpsState) {
            state.deletedAccountIdp.data = null;
            state.deletedAccountIdp.error = null;
            state.deletedAccountIdp.loading = false;
            return state;
        },
        updateAccountUsersIdpStart(state: AccountIdpsState) {
            state.updateAccountIdpUsers.error = null;
            state.updateAccountIdpUsers.loading = true;
            return state;
        },
        updateAccountUsersIdpSuccess(state: AccountIdpsState, action: PayloadAction<boolean>) {
            state.updateAccountIdpUsers.data = action.payload;
            state.updateAccountIdpUsers.loading = false;
            state.updateAccountIdpUsers.error = null;
            return state;
        },
        updateAccountUsersIdpFailed(state, action: PayloadAction<string>) {
            state.updateAccountIdpUsers.loading = false;
            state.updateAccountIdpUsers.error = action.payload;
            return state;
        },
        updateAccountUsersIdpCleanup(state: AccountIdpsState) {
            state.updateAccountIdpUsers.data = null;
            state.updateAccountIdpUsers.error = null;
            state.updateAccountIdpUsers.loading = false;
            return state;
        },
        updateSocialLoginSettingsStart(state: AccountIdpsState) {
            state.socialSetting.error = null;
            state.socialSetting.loading = true;
            return state;
        },
        updateSocialLoginSettingsSuccess(state: AccountIdpsState, action: PayloadAction<any>) {
            state.socialSetting.data = action.payload;
            state.socialSetting.loading = false;
            state.socialSetting.error = null;
            return state;
        },
        updateSocialLoginSettingsFailed(state, action: PayloadAction<string>) {
            state.socialSetting.loading = false;
            state.socialSetting.error = action.payload;
            return state;
        },
        updateSocialLoginSettingsCleanup(state: AccountIdpsState) {
            state.socialSetting.data = null;
            state.socialSetting.error = null;
            state.socialSetting.loading = false;
            return state;
        },
    },
});

export { accountIdpsSlice };
const {
    idpsLoaded,
    getAccountIdpsStart,
    getAccountIdpsSuccess,
    getAccountIdpsFailed,
    saveAccountIdpStart,
    saveAccountIdpSuccess,
    saveAccountIdpFailed,
    createAccountIdpStart,
    createAccountIdpSuccess,
    createAccountIdpFailed,
    deleteAccountIdpStart,
    deleteAccountIdpSuccess,
    deleteAccountIdpFailed,
    updateAccountUsersIdpStart,
    updateAccountUsersIdpSuccess,
    updateAccountUsersIdpFailed,
    updateSocialLoginSettingsStart,
    updateSocialLoginSettingsSuccess,
    updateSocialLoginSettingsFailed,
    getSecurityFlagsStart,
    getSecurityFlagsSuccess,
    getSecurityFlagsFailed,
    setSecurityFlagsStart,
    setSecurityFlagsSuccess,
    setSecurityFlagsFailed,
} = accountIdpsSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const getAccountIdps =
    (forceLoad = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            const alreadyLoadedIdps = getState().accountIdpsState.alreadyLoadedIdps;
            if (alreadyLoadedIdps && !forceLoad) {
                return;
            }
            dispatch(getAccountIdpsStart());
            const accountIdps: WMAccountIdp[] = await AccountsSdk.getInstance().accountIdps.getAllIdps();
            dispatch(idpsLoaded());
            dispatch(getAccountIdpsSuccess(accountIdps));
        } catch (err) {
            dispatch(getAccountIdpsFailed(errorFomatter(err)));
            return;
        }
    };

export const getSecurityFlags = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getSecurityFlagsStart());
        const securityFlags: WMAccountSecurityFlags = await AccountsSdk.getInstance().accountsSecurity.getSecurityFlags();
        dispatch(getSecurityFlagsSuccess(securityFlags));
    } catch (err) {
        dispatch(getSecurityFlagsFailed(errorFomatter(err)));
        return;
    }
};

export const setSecurityFlags =
    (newValue: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSecurityFlagsStart());
            await AccountsSdk.getInstance().accountsSecurity.setSecurityFlags({ idpUsersNoPassword: newValue });
            dispatch(setSecurityFlagsSuccess());
            dispatch(getSecurityFlags());
        } catch (err) {
            dispatch(setSecurityFlagsFailed(errorFomatter(err)));
            dispatch(getSecurityFlags());
            return;
        }
    };

export const createAccountSamlIdp =
    (samlConfig: SamlAccountIdpConfigurationDTO): AppThunk =>
    async (dispatch) => {
        try {
            const promises = [];
            promises.push(dispatch(createAccountIdpStart()));
            const savedSamlIdp: WMSAMLIdp = await AccountsSdk.getInstance().accountIdps.createSamlIdp(samlConfig);
            promises.push(dispatch(createAccountIdpSuccess(savedSamlIdp)));
            promises.push(dispatch(getAccountIdps()));
            await Promise.all(promises);
        } catch (err) {
            dispatch(createAccountIdpFailed(errorFomatter(err)));
            return;
        }
    };

export const editAccountSamlIdp =
    (idpId: string, samlConfig: SamlAccountIdpConfigurationDTO): AppThunk =>
    async (dispatch) => {
        try {
            const promises = [];
            promises.push(dispatch(saveAccountIdpStart()));
            const savedSamlIdp: WMAccountIdp = await AccountsSdk.getInstance().accountIdps.updateSamlIdp(idpId, samlConfig);
            promises.push(dispatch(saveAccountIdpSuccess(savedSamlIdp)));
            promises.push(dispatch(getAccountIdps()));
            await Promise.all(promises);
        } catch (err) {
            dispatch(saveAccountIdpFailed(errorFomatter(err)));
            return;
        }
    };

export const deleteAccountIdp =
    (idpId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(deleteAccountIdpStart());
            const savedSamlIdp: WMAccountIdp = await AccountsSdk.getInstance().accountIdps.deleteIdp(idpId);
            dispatch(deleteAccountIdpSuccess());
            dispatch(getAccountIdps());
            dispatch(getUsers(true));
        } catch (err) {
            dispatch(deleteAccountIdpFailed(errorFomatter(err)));
            return;
        }
    };

export const attachAllAccountUsersToIdp =
    (idpId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateAccountUsersIdpStart());
            AccountsSdk.getInstance().accountIdps.attachAllAccountUsersToIdp(idpId);
            dispatch(updateAccountUsersIdpSuccess(true));
            dispatch(getAccountIdps());
        } catch (err) {
            dispatch(updateAccountUsersIdpFailed(errorFomatter(err)));
            return;
        }
    };

export const detachAllAccountUsersFromIdp =
    (idpId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateAccountUsersIdpStart());
            AccountsSdk.getInstance().accountIdps.detachAllAccountUsersFromIdp(idpId);
            dispatch(updateAccountUsersIdpSuccess(true));
            dispatch(getAccountIdps());
        } catch (err) {
            dispatch(updateAccountUsersIdpFailed(errorFomatter(err)));
            return;
        }
    };

export const updateAccountIdpUsers =
    (
        idpId: string,
        usersIdsToAttach: AccountUsersToIdpExtraDataUserNameIdDTO[],
        usersIdsToDetach: AccountUsersToIdpExtraDataUserNameIdDTO[],
        ssoIdIsEmail: boolean
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateAccountUsersIdpStart());
            await Promise.all([
                usersIdsToAttach.length > 0
                    ? AccountsSdk.getInstance().accountIdps.attachAccountUsersToIdp(idpId, { userIds: usersIdsToAttach, ssoIdIsEmail })
                    : Promise.resolve(null),
                usersIdsToDetach.length > 0
                    ? AccountsSdk.getInstance().accountIdps.detachAccountUsersFromIdp(idpId, { userIds: usersIdsToDetach })
                    : Promise.resolve(null),
            ]);
            dispatch(updateAccountUsersIdpSuccess(true));
            dispatch(getAccountIdps());
            dispatch(getUsers(true));
        } catch (err) {
            dispatch(updateAccountUsersIdpFailed(errorFomatter(err)));
            return;
        }
    };

export const updateSocialLoginSettings =
    (updateSocialLoginSettings): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateSocialLoginSettingsStart());
            const socialLoginSettings = await AccountsSdk.getInstance().accountsSocialSettings.updateSocialLoginSettings(
                updateSocialLoginSettings
            );
            dispatch(updateSocialLoginSettingsSuccess(socialLoginSettings));
            dispatch(getAccountIdps());
        } catch (err) {
            dispatch(updateSocialLoginSettingsFailed(errorFomatter(err)));
            return;
        }
    };

export const getSocialLoginSettings = (): AppThunk => async (dispatch) => {
    try {
        dispatch(updateSocialLoginSettingsStart());
        const socialLoginSettings = await AccountsSdk.getInstance().accountsSocialSettings.getSocialSettings();
        dispatch(updateSocialLoginSettingsSuccess(socialLoginSettings));
        dispatch(getAccountIdps());
    } catch (err) {
        dispatch(updateSocialLoginSettingsFailed(errorFomatter(err)));
        return;
    }
};
