import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState, getImpersonationSettings } from '../redux/account.slice';

export const useImpersonationSettings = () => {
    const impersonationSettingsAppData = useSelector((state: { accountState: AccountState }) => state.accountState.impersonationSettings);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getImpersonationSettings());
    }, [dispatch]);

    return { impersonationSettingsAppData };
};

export default useImpersonationSettings;
