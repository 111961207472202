import React, { useEffect, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { AppIdentifier } from './types/app-identifier';
import { DesktopMacIdentifier, DesktopTrackedApp, DesktopWinIdentifier } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { CONSTS } from './types/consts';
import styled from 'styled-components';
import Identifiers from './identifiers';
import WinIdentifierRow from './win-identifier-row';
import MacIdentifierRow from './mac-identifier-row';

interface FieldsValues {
    processNamesWithAppTitles: {};
    appNames: {};
    bundleIds: {};
};

interface IdentifierValidationResult {
    simpleInvalid: boolean;
    advancedInvalid: boolean;
    errorMessage: string;
};

interface CenterSectionProps {
    systemId?: number;
    winIdentifiers: DesktopWinIdentifier[];
    macIdentifiers: DesktopMacIdentifier[];
    onWinIdentifiersChange: (winIdentifiers: DesktopWinIdentifier[]) => void;
    onMacIdentifiersChange: (macIdentifiers: DesktopMacIdentifier[]) => void;
    allDesktopTrackedApps: DesktopTrackedApp[];
    setIdentifiersValid: (isValid: boolean) => void;
};

let localIdCounter = 0;

const StyledCardContent = styled(CardContent)`
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    background-color: #FAFAFA;
    height: fit-content;
`;

const winDescription = 'Apps on Windows can be defined by their Process Name and App Title';
const macDescription = 'Apps on Mac OS can be defined by their Application name or Bundle ID';
const winLinkLabel = 'How to get the Process Name on Windows';
const macLinkLabel = 'How to get Bundle ID or Application Name on Mac';

const CenterSection = ({
    systemId,
    winIdentifiers,
    macIdentifiers,
    onWinIdentifiersChange,
    onMacIdentifiersChange,
    allDesktopTrackedApps,
    setIdentifiersValid
}: CenterSectionProps) => {
    const [winIdentifiersData, setWinIdentifiersData] = useState<AppIdentifier[]>([]);
    const [macIdentifiersData, setMacIdentifiersData] = useState<AppIdentifier[]>([]);
    const [fieldsValues, setFieldsValues] = useState<FieldsValues>({
        processNamesWithAppTitles: {},
        appNames: {},
        bundleIds: {}
    });

    useEffect(() => {
        const initalFieldsValues = allDesktopTrackedApps.reduce((fieldsValuesObj, desktopTrackedApp) => {
            if (desktopTrackedApp.systemId !== systemId) {
                const currentWinFieldsValues = desktopTrackedApp.winIdentifiers.reduce((winFieldValues, identifier) => {
                    winFieldValues.processNamesWithAppTitles[identifier.processName + identifier.appTitle] = true;

                    return winFieldValues;
                }, { processNamesWithAppTitles: {} });

                const currentMacFieldsValues = desktopTrackedApp.macIdentifiers.reduce((macFieldValues, identifier) => {
                    if (identifier.appName) {
                        macFieldValues.appNames[identifier.appName] = true;
                    }

                    if (identifier.bundleId) {
                        macFieldValues.bundleIds[identifier.bundleId] = true;
                    }

                    return macFieldValues;
                }, { appNames: {}, bundleIds: {} });

                fieldsValuesObj.processNamesWithAppTitles = { ...fieldsValuesObj.processNamesWithAppTitles, ...currentWinFieldsValues.processNamesWithAppTitles };
                fieldsValuesObj.appNames = { ...fieldsValuesObj.appNames, ...currentMacFieldsValues.appNames };
                fieldsValuesObj.bundleIds = { ...fieldsValuesObj.bundleIds, ...currentMacFieldsValues.bundleIds };
            }

            return fieldsValuesObj;
        }, fieldsValues);

        setFieldsValues(initalFieldsValues);
    }, [systemId, allDesktopTrackedApps]);

    useEffect(() => {
        const newWinIdentifiersData : AppIdentifier[] = winIdentifiers.map((winIdentifier) => {
            const existing = winIdentifiersData.filter((winIdentifierData) => winIdentifierData.id === winIdentifier.id)[0];

            const winIdentifierData = {
                ...existing,
                id: winIdentifier.id,
                systemId: winIdentifier.systemId,
                simpleIdentifier: winIdentifier.processName,
                advancedIdentifier: winIdentifier.appTitle,
                delete: winIdentifier.delete,
            };

            return winIdentifierData;
        });

        setWinIdentifiersData(newWinIdentifiersData);
    }, [winIdentifiers]);

    useEffect(() => {
        const newMacIdentifiersData : AppIdentifier[] = macIdentifiers.map((macIdentifier) => {
            const existing = macIdentifiersData.filter((macIdentifierData) => macIdentifierData.id === macIdentifier.id)[0];

            const macIdentifierData = {
                ...existing,
                id: macIdentifier.id,
                systemId: macIdentifier.systemId,
                simpleIdentifier: macIdentifier.appName,
                advancedIdentifier: macIdentifier.bundleId,
                delete: macIdentifier.delete
            }

            return macIdentifierData;
        });

        setMacIdentifiersData(newMacIdentifiersData);
    }, [macIdentifiers]);

    useEffect(() => {
        const newWinIdentifiersData : AppIdentifier[] = winIdentifiers.map((winIdentifier) => {
            const existing = winIdentifiersData.filter((winIdentifierData) => winIdentifierData.id === winIdentifier.id)[0];

            const winIdentifierData = {
                ...existing,
                id: winIdentifier.id,
                systemId: winIdentifier.systemId,
                simpleIdentifier: winIdentifier.processName,
                advancedIdentifier: winIdentifier.appTitle,
                delete: winIdentifier.delete,
            };

            const result = validateWinFields(winIdentifierData);

            winIdentifierData.simpleInvalid = result.simpleInvalid;
            winIdentifierData.advancedInvalid = result.advancedInvalid;
            winIdentifierData.errorMessage = result.errorMessage;

            return winIdentifierData;
        });

        const newMacIdentifiersData : AppIdentifier[] = macIdentifiers.map((macIdentifier) => {
            const existing = macIdentifiersData.filter((macIdentifierData) => macIdentifierData.id === macIdentifier.id)[0];

            const macIdentifierData = {
                ...existing,
                id: macIdentifier.id,
                systemId: macIdentifier.systemId,
                simpleIdentifier: macIdentifier.appName,
                advancedIdentifier: macIdentifier.bundleId,
                delete: macIdentifier.delete
            }

            const result = validateMacFields(macIdentifierData);

            macIdentifierData.simpleInvalid = result.simpleInvalid;
            macIdentifierData.advancedInvalid = result.advancedInvalid;
            macIdentifierData.errorMessage = result.errorMessage;

            return macIdentifierData;
        });

        setWinIdentifiersData(newWinIdentifiersData);
        setMacIdentifiersData(newMacIdentifiersData);
        setIdentifiersValid(allIdentifiersValid(newMacIdentifiersData, newWinIdentifiersData));
    }, [allDesktopTrackedApps]);

    const mapToWinIdentifiers = (identifiers: AppIdentifier[]) : DesktopWinIdentifier[] => {
        return identifiers.map((identifier: AppIdentifier) => {
            return {
                id: identifier.id,
                systemId: identifier.systemId,
                processName: identifier.simpleIdentifier,
                appTitle: identifier.advancedIdentifier,
                delete: identifier.delete
            };
        });
    }

    const mapToMacIdentifiers = (identifiers: AppIdentifier[]) : DesktopMacIdentifier[] => {
        return identifiers.map((identifier: AppIdentifier) => {
            return {
                id: identifier.id,
                systemId: identifier.systemId,
                appName: identifier.simpleIdentifier,
                bundleId: identifier.advancedIdentifier,
                delete: identifier.delete
            };
        });
    }

    const onWinAdd = () => {
        const newAppIdentifier = getNewIdentifier();

        const updatedWinIdentifiersData = [
            ...winIdentifiersData,
            newAppIdentifier
        ];

        setWinIdentifiersData(updatedWinIdentifiersData);
        setIdentifiersValid(false);
    };

    const onWinUpdate = (index: number, identifier: AppIdentifier) => {
        const validationResult = validateWinFields(identifier);
        const updatedIdentifier : AppIdentifier = {
            ...identifier,
            ...validationResult
        };

        const updatedWinIdentifiersData = getUpdatedIdentifiers(index, updatedIdentifier, winIdentifiersData);

        setWinIdentifiersData(updatedWinIdentifiersData);

        if (!validationResult.simpleInvalid &&
            !validationResult.advancedInvalid &&
            allIdentifiersValid(updatedWinIdentifiersData, macIdentifiersData)) {
            setIdentifiersValid(true);
        } else {
            setIdentifiersValid(false);
        }

        onWinIdentifiersChange(mapToWinIdentifiers(updatedWinIdentifiersData));
    };

    const onWinDelete = (index: number) => {
        const updatedWinIdentifiersData = getUpdatedIdentifiersAfterDelete(index, winIdentifiersData);

        setWinIdentifiersData(updatedWinIdentifiersData);
        onWinIdentifiersChange(mapToWinIdentifiers(updatedWinIdentifiersData));
        setIdentifiersValid(allIdentifiersValid(updatedWinIdentifiersData, macIdentifiersData));
    };

    const onMacAdd = () => {
        const newAppIdentifier = getNewIdentifier();

        const updatedMacIdentifiersData = [
            ...macIdentifiersData,
            newAppIdentifier
        ];

        setMacIdentifiersData(updatedMacIdentifiersData);
        setIdentifiersValid(false);
    };

    const onMacUpdate = (index: number, identifier: AppIdentifier) => {
        const validationResult = validateMacFields(identifier);
        const updatedIdentifier : AppIdentifier = {
            ...identifier,
            ...validationResult
        };

        const updatedMacIdentifiersData = getUpdatedIdentifiers(index, updatedIdentifier, macIdentifiersData);

        setMacIdentifiersData(updatedMacIdentifiersData);

        if (!validationResult.simpleInvalid &&
            !validationResult.advancedInvalid &&
            allIdentifiersValid(winIdentifiersData, updatedMacIdentifiersData)) {
            setIdentifiersValid(true);
        } else {
            setIdentifiersValid(false);
        }

        onMacIdentifiersChange(mapToMacIdentifiers(updatedMacIdentifiersData));
    };

    const onMacDelete = (index: number) => {
        const updatedMacIdentifiersData = getUpdatedIdentifiersAfterDelete(index, macIdentifiersData);

        setMacIdentifiersData(updatedMacIdentifiersData);
        onMacIdentifiersChange(mapToMacIdentifiers(updatedMacIdentifiersData));
        setIdentifiersValid(allIdentifiersValid(winIdentifiersData, updatedMacIdentifiersData));
    };

    const getUpdatedIdentifiers = (index: number, updatedIdentifier: AppIdentifier, identifiersData: AppIdentifier[]) => {
        const updatedIdentifiersData = [
            ...identifiersData
        ];

        updatedIdentifiersData[index] = updatedIdentifier;

        return updatedIdentifiersData;
    }

    const getUpdatedIdentifiersAfterDelete = (index: number, identifiersData: AppIdentifier[]) => {
        let updatedIdentifiersData;

        if (!identifiersData[index].id.startsWith(CONSTS.LOCAL_ID)) {
            updatedIdentifiersData = [
                ...identifiersData
            ];

            updatedIdentifiersData[index].delete = true;
        } else {
            updatedIdentifiersData = identifiersData.filter((data, i) => i !== index);
        }

        return updatedIdentifiersData;
    }

    const getNewIdentifier = () : AppIdentifier => {
        localIdCounter++;

        return {
            id: `${CONSTS.LOCAL_ID}${localIdCounter}`,
            simpleIdentifier: '',
            advancedIdentifier: '',
            simpleInvalid: true,
            advancedInvalid: true
        };
    }

    const isWinCurrentIdentifiersUnique = (identifier: AppIdentifier) => {
        return winIdentifiersData.filter((winIdentifier) =>
            winIdentifier.simpleIdentifier === identifier.simpleIdentifier &&
            winIdentifier.advancedIdentifier === identifier.advancedIdentifier &&
            winIdentifier.id !== identifier.id
        ).length === 0;
    };

    const isMacAppNameUniqueInCurrentApp = (appName: string, id: string) => {
        return macIdentifiersData.filter((macIdentifier) =>
            macIdentifier.simpleIdentifier === appName &&
            macIdentifier.id !== id
        ).length === 0;
    };

    const isMacBundleIdUniqueInCurrentApp = (bundleId: string, id: string) => {
        return macIdentifiersData.filter((macIdentifier) =>
            macIdentifier.advancedIdentifier === bundleId &&
            macIdentifier.id !== id
        ).length === 0;
    };

    const validateWinFields = (identifier: AppIdentifier) : IdentifierValidationResult => {
        if (!identifier.simpleIdentifier) {
            return {
                simpleInvalid: true,
                advancedInvalid: false,
                errorMessage: 'Process Name must be provided'
            };
        }

        if (!isWinCurrentIdentifiersUnique(identifier) ||
            fieldsValues.processNamesWithAppTitles[identifier.simpleIdentifier + identifier.advancedIdentifier]) {
            return {
                simpleInvalid: true,
                advancedInvalid: true,
                errorMessage: 'Process Name and App Title should be unique accross all apps'
            };
        }

        return {
            simpleInvalid: false,
            advancedInvalid: false,
            errorMessage: ''
        };
    };

    const validateMacFields = (identifier: AppIdentifier) : IdentifierValidationResult => {
        if (!identifier.simpleIdentifier && !identifier.advancedIdentifier) {
            return {
                simpleInvalid: true,
                advancedInvalid: true,
                errorMessage: 'App Name or Bundle ID should be provided'
            };
        }

        if (identifier.simpleIdentifier && identifier.advancedIdentifier) {
            return {
                simpleInvalid: true,
                advancedInvalid: true,
                errorMessage: 'App Name or Bundle ID should be provided, not both'
            };
        }

        if (identifier.simpleIdentifier &&
            (
                !isMacAppNameUniqueInCurrentApp(identifier.simpleIdentifier, identifier.id) ||
                fieldsValues.appNames[identifier.simpleIdentifier]
            )) {
            return {
                simpleInvalid: true,
                advancedInvalid: false,
                errorMessage: 'App Name should be unique accross all apps'
            };
        }

        if (identifier.advancedIdentifier &&
            (
                !isMacBundleIdUniqueInCurrentApp(identifier.advancedIdentifier, identifier.id) ||
                fieldsValues.bundleIds[identifier.advancedIdentifier]
            )) {
            return {
                simpleInvalid: false,
                advancedInvalid: true,
                errorMessage: 'Bundle ID should be unique accross all apps'
            };
        }

        return {
            simpleInvalid: false,
            advancedInvalid: false,
            errorMessage: ''
        };
    };

    const allIdentifiersValid = (winIdentifiersData: AppIdentifier[], macIdentifiersData: AppIdentifier[]) => {
        return (
            (winIdentifiersData.length > 0 && !winIdentifiersData.every((identifier) => identifier.delete)) ||
            (macIdentifiersData.length > 0 && !macIdentifiersData.every((identifier) => identifier.delete))) &&
            winIdentifiersData.every((identifier) => identifier.delete || (!identifier.simpleInvalid && !identifier.advancedInvalid)) &&
            macIdentifiersData.every((identifier) => identifier.delete || (!identifier.simpleInvalid && !identifier.advancedInvalid));
    };

    return (
        <StyledCardContent>
            <Identifiers
                os="Windows"
                icon="logo-OS-windows.svg"
                simpleIdentifierLabel="Process Name"
                advancedIdentifierLabel="App Title"
                simpleIdentifierMandatory={true}
                identifiers={winIdentifiersData}
                description={winDescription}
                linkLabel={winLinkLabel}
                onAdd={onWinAdd}
                onUpdate={onWinUpdate}
                onDelete={onWinDelete}
                RowComponent={WinIdentifierRow}
            />
            <Divider orientation="vertical" flexItem />
            <Identifiers
                os="Mac OS"
                icon="logo-OS-mac.svg"
                simpleIdentifierLabel="Config Type"
                advancedIdentifierLabel="Config Value"
                advancedIdentifierMandatory={true}
                identifiers={macIdentifiersData}
                description={macDescription}
                linkLabel={macLinkLabel}
                onAdd={onMacAdd}
                onUpdate={onMacUpdate}
                onDelete={onMacDelete}
                RowComponent={MacIdentifierRow}
            />
        </StyledCardContent>
    );
};

export default CenterSection;
