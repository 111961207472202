import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconSelfHostedMenuItem = (props: SvgIconProps) => {
    return (
        <SvgIcon width={20} height={20} viewBox='0 0 20 20' style={{ fill: 'none' }} xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#clip0_1318_91965)'>
                <path
                    d='M2.5 5.50073C2.5 6.29638 3.29018 7.05944 4.6967 7.62205C6.10322 8.18466 8.01088 8.50073 10 8.50073C11.9891 8.50073 13.8968 8.18466 15.3033 7.62205C16.7098 7.05944 17.5 6.29638 17.5 5.50073C17.5 4.70508 16.7098 3.94202 15.3033 3.37941C13.8968 2.8168 11.9891 2.50073 10 2.50073C8.01088 2.50073 6.10322 2.8168 4.6967 3.37941C3.29018 3.94202 2.5 4.70508 2.5 5.50073V5.50073Z'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M2.5 5.50073V10.0001C2.5 11.6567 5.858 13.0001 10 13.0001C14.142 13.0001 17.5 11.6574 17.5 10.0001V5.50073'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M2.5 10V14.5C2.5 16.1567 5.858 17.5 10 17.5C14.142 17.5 17.5 16.1567 17.5 14.5V10'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_1318_91965'>
                    <rect width='16' height='16' fill='transparent' transform='translate(2 2)' />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export { IconSelfHostedMenuItem };
