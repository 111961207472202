import { setIn } from 'final-form';

export const getErrorObject = async (schema, values): Promise<object> => {
    if (typeof schema === 'function') schema = schema();
    try {
        await schema.validate(values, { abortEarly: false });
    } catch (e) {
        return e.inner.reduce((errors, error) => {
            return setIn(errors, error.path, error.message);
        }, {});
    }
};

export const validateXpath = (xpath) => {
    try {
        // Create a new DOMParser
        const parser = new DOMParser();

        // Create a new XML document
        const xmlDoc = parser.parseFromString('<xml></xml>', 'application/xml');

        // Use the evaluate method to check if the XPath expression is valid
        xmlDoc.evaluate(xpath, xmlDoc, null, XPathResult.ANY_TYPE, null);

        // If the evaluation is successful, the XPath is valid
        return true;
    } catch (error) {
        // If there is an error during evaluation, the XPath is not valid
        return false;
    }
};

export const validateDomainAndPath = (url) => {
    if (!url) return true;
    // Define the regular expression pattern
    const urlRegex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})(\/[^\/?\s]+)*(\/(?:[^\/?\s]+\/)?)?(\?[^\/?\s]*)?$/;

    // Test the URL against the pattern
    return urlRegex.test(url);
};

export const validateIp = (ip) => {
    // Define the regular expression pattern
    const ipAddressPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    // Test the URL against the pattern
    return ipAddressPattern.test(ip);
};

export const isValidPath = (path) => {
    try {
        new RegExp(path);
        return true; // If creating the RegExp object succeeds, it's a valid regex
    } catch (error) {
        return false; // If there's an error, it's not a valid regex
    }
};
