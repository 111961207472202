import React, { Dispatch } from 'react';

import { AssignSystems, Crawler, Settings, FileScan } from './index';
import { Slack } from './slack';
import { Teams } from './teams';
import { GuidedAnswers } from './guided-answers';
import { SourceSettings } from './source-settings';
import { ScanSummary } from './scan-summary';

const formInstanceInit = {
    Crawler: Crawler,
    Slack: Slack,
    Teams: Teams,
    AssignSystems: AssignSystems,
    Settings: Settings,
    FileScan: FileScan,
    GuidedAnswers,
    SourceSettings,
    ScanSummary,
};

export const getFormManager = (type: string, dispatch: Dispatch<any>, showSnackBar, closeForm, changeActiveStep) => {
    return new formInstanceInit[type](dispatch, showSnackBar, closeForm, changeActiveStep);
};
