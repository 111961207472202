import CustomCheckbox from '../../../../common/components/custom-checkbox/custom-checkbox';
import { shrinkText } from '../../../../common/helpers';
import CustomToolTip from '../../../../common/components/tool-tip/tool-tip';
import { InfoOutlined, Restore } from '@material-ui/icons';
import { SelectWrapper, StyledCustomMenuItem } from '../../../../common/style';
import React, { useState } from 'react';
import PropertyAlias from '../../fields-properties/fields-property-alias/fields-property-alias';
import { StyledIconButton, StyledRestore, StyledTypeSelect } from './field-row-style';
import { Field, FieldOptions, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';

export interface FieldRowProps {
    fields: any;
    values: ProviderFormValues;
    index: number;
    isChecked: (field: Field) => boolean | null;
    getWarning: (field: Field) => JSX.Element;
    getProperty: (field: Field) => number;
    fieldTypeChange: (fields: any, field: Field, value: string) => void;
    field: Field;
    fieldsLoad: boolean;
    updateFields: (aliasName: string, field: Field) => void;
    fieldOptions: FieldOptions;
}

export const FieldRow = ({
    fields,
    values,
    index,
    isChecked,
    getWarning,
    getProperty,
    fieldTypeChange,
    field,
    fieldsLoad,
    updateFields,
    fieldOptions,
}: FieldRowProps) => {
    const [editMode, setEditMode] = useState(false);

    const closeEditMode = (): void => {
        setEditMode(false);
    };

    const handleAliasChange = (name: string): void => {
        updateFields(name, field);
    };

    const getValueForToolTip = (value): string => {
        const str = (value || '').toString();
        if (str.length > 300) {
            return `${str.substr(0, 300)} ...`;
        }
        return str;
    };

    return (
        <div className={'field-item-block'} key={field.name}>
            <div className={'field-item-name-block'}>
                {!editMode ? (
                    <>
                        <CustomCheckbox
                            disabled={!fieldsLoad}
                            checked={isChecked(field)}
                            color={'primary'}
                            onChange={({ target: { checked } }) => {
                                if (checked) {
                                    fields.insert(index, field);
                                } else {
                                    fields.remove(getProperty(field));
                                    if (field.alias) {
                                        handleAliasChange(null);
                                    }
                                }
                            }}
                        />
                        {shrinkText(field.alias ? field.alias : field.name, 35)}
                        {fieldsLoad && (
                            <CustomToolTip title={getValueForToolTip(field.value) || '< null >'}>
                                <InfoOutlined fontSize={'medium'} color={'primary'} style={{ marginLeft: '4px' }} />
                            </CustomToolTip>
                        )}
                        {fieldsLoad && isChecked(field) && (
                            <CustomToolTip title={'Edit'}>
                                <StyledIconButton
                                    className={`${!field.alias ? 'edit-field-button' : ''}`}
                                    onClick={() => setEditMode(true)}>
                                    <img src='assets/icons/icon-edit-small.svg' alt='edit field name' />
                                </StyledIconButton>
                            </CustomToolTip>
                        )}
                        {isChecked(field) && field.alias && (
                            <CustomToolTip title={`Original name: '${field.name}'`}>
                                <StyledRestore fontSize={'medium'} />
                            </CustomToolTip>
                        )}
                    </>
                ) : (
                    <PropertyAlias
                        getProperty={getProperty}
                        handleAliasChange={handleAliasChange}
                        closeEditMode={closeEditMode}
                        fields={fields}
                        field={field}
                        values={values}
                    />
                )}
            </div>
            <div className={'field-select-type-block'}>
                {getWarning(field)}
                <SelectWrapper>
                    <StyledTypeSelect
                        MenuProps={{
                            PopoverClasses: {
                                root: 'selectMenuItem',
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                            getContentAnchorEl: null,
                        }}
                        disabled={!isChecked(field) || !fieldsLoad || field.disabledToChangeType}
                        variant={'outlined'}
                        value={field.dataType}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            fieldTypeChange(fields, field, event.target.value);
                        }}>
                        {fieldOptions.map((option) => {
                            return (
                                <StyledCustomMenuItem disableRipple key={option.key} value={option.value}>
                                    {option.label}
                                </StyledCustomMenuItem>
                            );
                        })}
                    </StyledTypeSelect>
                </SelectWrapper>
            </div>
        </div>
    );
};

export default FieldRow;
