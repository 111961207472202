import styled from 'styled-components';
import { Chip } from '@material-ui/core';
import { ControlSubHeader } from '../../../../../../../extension-pages/src/lib/new-design/shared-styled-components';

export const UrlsTitle = styled('span')`
    color: var(--typography-typography, #2f426c);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-right: 10px;
`;

export const MatchPatternsContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const MatchPatternsItemsContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const UrlTopBarControlContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
`;

export const UrlCounterContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

export const StyledChip = styled(Chip)`
    background: var(--Infographic-Blue-Blue-tint-4, #eceffa);
    color: var(--infographic-blue-blue-shade-1, #162a69);
`;

export const StyledControlSubHeader = styled(ControlSubHeader)`
    margin-bottom: 12px;
`;
