import { useSelector } from 'react-redux';
import { DataIntegrationState } from '../redux/data-integration.slice';

export const useGlobalSettings = () => {
    const globalSettingsData = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.globalSettings.data;
    });

    return globalSettingsData;
};

export default useGlobalSettings;
