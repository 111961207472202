import React, { useEffect, useMemo, useState } from 'react';
import { StyledSaveAndPublish, StyledUUIDContainer, StyledUUIDSelect, StyledUUIDTitle, StyledUUIDWrapper } from './uuid.styles';
import { UuidSelector } from './uuid-selector';
import { DropdownOption, UUIDOptions, UUID as UUIDEnum, getUUIDOptions } from './uuid.lib';
import { useSelector, useDispatch } from 'react-redux';
import {
    IMPERSONATION_PASSWORD_ERROR,
    ImpersonationPasswordError,
    impersonationSlice,
    ImpersonationState,
    impersonationThunks,
    systemsSlice,
    SystemsState,
    uuidSlice,
    UUIDState,
} from '@walkme-admin-center/libs/state-management-systems';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { UuidBody } from './uuid-body';
import { useLoggedInUser, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { IdpSelectionDialog } from './idp-selection-dialog';
import useExistingSystemSiteConfiguration from '../../../../../../libs/state-management-systems/src/lib/hooks/use-existing-system-site-configuration';
import { Button, Input } from '@walkme/ui-core';
import { SaveAndPublishDialog } from '../save-and-publish-dialog';
import { generateConfigurationString } from '../create-system/create-system-dialog/create-system-dialog.lib';
import { siteConfigApi } from '../api/site-config-api';
import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { getTextJsx } from '../create-system/steps/optional-configure/uuid/uuid.lib';
import { isEqual } from 'lodash';
import useGetProviders from '../../../../../../libs/state-management-systems/src/lib/hooks/use-get-providers';
import { ImpersonatorDialog } from '../impersonator-dialog';
import { el } from 'date-fns/locale';
import { AppData, NotificationMessage } from '@walkme-admin-center/libs/types';
import { System } from 'wm-accounts-sdk';

export const UUID = ({ systemId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const [savingData, setSavingData] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const initialUUIDValues = useSelector((state: { uuidState: UUIDState }) => state.uuidState.initialUUIDValues);
    const selectedUUID: DropdownOption = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedUUID);
    const selectedUUIDVariableValue = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedUUIDVariableValue);
    const selectedExpectedFormat = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedExpectedFormat);
    const regexpInputValue = useSelector((state: { uuidState: UUIDState }) => state.uuidState.regexpInputValue);
    const { siteConfiguration, fetchConfig } = useExistingSystemSiteConfiguration(systemId);
    const uuidErrors = useSelector((state: { uuidState: UUIDState }) => state.uuidState.uuidErrors);
    const { loggedInUserAppData } = useLoggedInUser();
    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;
    const { providers } = useGetProviders(isImpersonated);
    const idpOptionIsDisabled = !providers?.data || providers?.data?.length === 0;
    const currentUUIDValues = {
        selectedUUID,
        selectedUUIDVariableValue,
        selectedExpectedFormat,
        regexpInputValue,
    };

    const impersonationPassword = useSelector(
        (state: { impersonationState: ImpersonationState }) => state.impersonationState.currentPassword
    );

    const currentSnackbarMessage = useSelector((state: { uuidState: UUIDState }) => state.uuidState.notificationMessage);
    const currentSystemAppData: AppData<System> = useSelector((state: { systemsState: SystemsState }) => state.systemsState.currentSystem);

    const disabledUUIDs = [];
    if (idpOptionIsDisabled) {
        disabledUUIDs.push(UUIDEnum.IDP);
    }

    const setSelectedUUID = (UUID: DropdownOption) => {
        dispatch(uuidSlice.actions.setSelectedUUID(UUID));
        dispatch(uuidSlice.actions.resetUUIDBody());
    };

    const handleSnackBarClose = () => {
        dispatch(uuidSlice.actions.cleanUpNotificationMessage());
    };

    const resetUUIDSettings = () => {
        dispatch(uuidSlice.actions.restartUUIDState());
    };

    const noUUIDChange = isEqual(currentUUIDValues, initialUUIDValues);

    const saveIsDisabled = noUUIDChange || Object.keys(uuidErrors)?.some((key) => uuidErrors[key]);

    const saveUUID = async (impPassword: string) => {
        setSavingData(true);

        try {
            const modifiedEndUserSettings = generateConfigurationString({
                currentEndUserSettings: siteConfiguration?.EndUserSettings,
                uuidType: selectedUUID.value,
                variableParameter: selectedUUIDVariableValue,
                expectedFormat: selectedExpectedFormat.value,
                regexp: regexpInputValue,
            });

            const finalSiteConfig = { ...siteConfiguration, EndUserSettings: modifiedEndUserSettings };

            const saveSiteConfigurationPayload: any = {
                configurationString: JSON.stringify(finalSiteConfig),
                systemId,
                isImpersonated,
                impPassword,
                configurationData: {
                    uuidType: selectedUUID.label,
                    variableParameter: selectedUUIDVariableValue,
                    expectedFormat: selectedExpectedFormat.value,
                },
            };

            const saveConfRes = await siteConfigApi.saveSiteConfiguration(saveSiteConfigurationPayload);

            if (saveConfRes.data?.ErrorObject && saveConfRes.data?.ErrorMessage == IMPERSONATION_PASSWORD_ERROR) {
                throw new ImpersonationPasswordError();
            }
        } finally {
            setSavingData(false);
        }
    };

    const onSaveSucceeded = () => {
        dispatch(
            uuidSlice.actions.setNotificationMessage({
                text: t('systems-tab.new-system-form.configure-step.notifications.success'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            })
        );
        fetchConfig(true);
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };
    const onSaveFailed = (message: string) => {
        dispatch(uuidSlice.actions.setNotificationMessage({ text: message, variant: WMSnackbarVariant.Error, isOpen: true }));
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };
    const handleSave = async (impPassword: string) => {
        try {
            await saveUUID(impPassword);
            onSaveSucceeded();
        } catch (error) {
            if (error instanceof ImpersonationPasswordError) {
                onSaveFailed(t('notifications.impersonated-save-fail'));
            } else {
                onSaveFailed(t('systems-tab.new-system-form.configure-step.notifications.fail'));
            }
            console.error(error);
        }
    };
    const onSaveClicked = async () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(handleSave));
        } else {
            await handleSave(null);
        }
    };

    const onPublishFailed = (message: string) => {
        dispatch(uuidSlice.actions.setNotificationMessage({ text: message, variant: WMSnackbarVariant.Error, isOpen: true }));
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };

    const onPublishSucceeded = (message: string) => {
        dispatch(uuidSlice.actions.setNotificationMessage({ text: message, variant: WMSnackbarVariant.Success, isOpen: true }));
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };

    const handleSaveAndPublish = async () => {
        dispatch(systemsSlice.actions.setSaveAndPublishDialogIsOpen(true));
    };

    const onSaveAndPublishClicked = async () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(handleSaveAndPublish));
        } else {
            handleSaveAndPublish();
        }
    };

    useEffect(() => {
        return resetUUIDSettings;
    }, []);

    const learnMore = getTextJsx(
        t('systems-tab.new-system-form.configure-step.uuid.learn-more.text'),
        'systems-tab.new-system-form.configure-step.uuid.learn-more.links',
        t
    );

    return (
        <StyledUUIDWrapper>
            <StyledUUIDContainer>
                <StyledUUIDTitle>
                    <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.uuid.main-title`)}</div>
                    <div className='sub-title'>
                        <div>{t(`systems-tab.new-system-form.configure-step.uuid.sub-title`)}</div>
                        <div className='learn-more'>{learnMore}</div>
                    </div>
                </StyledUUIDTitle>
                <UuidSelector
                    selectedUUID={selectedUUID}
                    UUIDOptions={getUUIDOptions({ systemFeatureFlags: currentSystemAppData?.data?.featureFlags })}
                    setSelectedUUID={setSelectedUUID}
                    disabledUUIDs={disabledUUIDs}
                    systemId={systemId}
                />
                <UuidBody uuidType={selectedUUID?.value} systemId={systemId} />
                <IdpSelectionDialog />
                <ImpersonatorDialog isLoading={savingData} />
            </StyledUUIDContainer>
            <StyledSaveAndPublish>
                <Button disabled={saveIsDisabled || savingData} loading={savingData} onClick={onSaveClicked}>
                    {t('buttons.save')}
                </Button>
                <Button disabled={saveIsDisabled} onClick={onSaveAndPublishClicked} className='save-and-publish'>
                    {t('buttons.save-and-publish')}
                </Button>
            </StyledSaveAndPublish>
            <SaveAndPublishDialog
                settingsType='uuid'
                systemId={systemId}
                saveData={async () => saveUUID(impersonationPassword)}
                reloadData={() => fetchConfig(true)}
                onFailure={onPublishFailed}
                onSuccess={onPublishSucceeded}
            />
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
            />
        </StyledUUIDWrapper>
    );
};
