import React from 'react';
import ConfirmationDialog from './confirmation-dialog';
import { Typography } from '@material-ui/core';
import { StyledLink } from './shared-styled-components';

const LOCAL_STORAGE_MULTI_MATCHERS_DIALOG_SHOWN_KEY = 'wm_multi_matchers_dialog_shown';

interface MultiMatchersDialogProps {
    isMultiMatchersMode: boolean;
    setIsMultiMatchersMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiMatchersDialog = ({ isMultiMatchersMode, setIsMultiMatchersMode }: MultiMatchersDialogProps) => {
    const onMultiMatchersConfirm = (doNotShowAgain: boolean) => {
        if (doNotShowAgain) {
            localStorage.setItem(LOCAL_STORAGE_MULTI_MATCHERS_DIALOG_SHOWN_KEY, 'true');
        }

        setIsMultiMatchersMode(false);
    };

    const isDialogShownAndSelectedToNotShowAgain = localStorage.getItem(LOCAL_STORAGE_MULTI_MATCHERS_DIALOG_SHOWN_KEY) === 'true';

    const message = (
        <Typography>
            This environment configuration has additional settings for a customized experience. &nbsp;
            <StyledLink to={{ pathname: 'https://support.walkme.com/knowledge-base/extension-settings' }} target='_blank'>
                Learn more
            </StyledLink>
        </Typography>
    );

    return (
        <ConfirmationDialog
            isOpen={isMultiMatchersMode && !isDialogShownAndSelectedToNotShowAgain}
            title='Additional Configuration Available'
            message={message}
            confirmButtonColor='primary'
            onConfirm={onMultiMatchersConfirm}
            showDoNotShowAgainCheckbox={true}
        />
    );
};

export default MultiMatchersDialog;
