import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { AddManualAndAutomatonTranslationsBulkResults, TranslationsListObject } from './types';
import useClientConfig from '@walkme-admin-center/../apps/home/src/redux/hooks/useClientConfig';

class ImportLexiconApi {
    private readonly httpClient: WMhttpClient;
    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
    }

    async addManualAndAutomatonTranslationsBulk(
        systemId: number,
        bulkId: string,
        translationsList: TranslationsListObject[],
        columnNames: string[],
        overriddenIndexes?: number[][]
    ): Promise<AddManualAndAutomatonTranslationsBulkResults> {
        const { data } = await this.httpClient.instance.post<any>(
            `${window.clientConfig.NX_WM_API_URL}/deepui/api/lexicon-app/deepui/lx/ac/addManualAndAutomatonTranslationsBulk`,
            {
                bulkId,
                translationsList,
                columnNames,
                overriddenIndexes,
                ignoreMissingSourcePhrase: true,
                systemId,
            },
            {
                timeout: 150000,
            }
        );
        return data;
    }

    async isLexiconEnabled(userGuid: string): Promise<boolean> {
        const {
            data: { data },
        } = await this.httpClient.instance.post<any>(
            `${window.clientConfig.NX_WM_PAPI}/deepui/p/getUserSettings`,
            {},
            {
                headers: {
                    'x-wmdu-uguid': userGuid,
                },
            }
        );
        return data?.lexicon || false;
    }
}

export const importLexiconApi = new ImportLexiconApi(wmhttpClientSharedInstance);
