import styled from 'styled-components';
import { Dialog, List, StepLabel } from '@material-ui/core';
import { Button } from '@walkme-admin-center/libs/ui-components';
import { WMButton, WMTextField } from '@walkme/wm-ui';

export const LabelMsgIdpList = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    color: #303d53;
    font-size: 12px;
    line-height: 1.5;
    display: block;
    margin-left: 6px;
`;

export const NoPasswordDiv = styled.div`
    margin-top: 20px;
    margin-left: 20px;
`;

export const HeaderTitle = styled('label')`
    font-family: 'ProximaNova';
    font-size: 20px;
    color: #2f426c;
    line-height: 1.5;
    font-weight: 600;
    display: block;
`;

export const Content = styled('label')`
    font-family: 'ProximaNova';
    font-size: 14px;
    color: #8d97ae;
    line-height: 1.5;
    display: block;
`;

export const ContentTest = styled('label')`
    font-family: 'ProximaNova';
    font-size: 12px;
    color: #2f426c8c;
    line-height: 1.5;
    display: block;
    margin-bottom: 24px;
`;

export const ContentTestWarning = styled('label')`
    font-family: 'ProximaNova';
    font-size: 13px;
    color: #f44336;
    line-height: 1.5;
    display: block;
    margin-top: 24px;
`;

export const Label = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    color: #2f426c;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
`;

export const LabelDescription = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    color: #8d97ae;
    font-size: 14px;
    line-height: 1.5;
    display: block;
`;

export const LabelMsg = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    color: #2f426c;
    font-size: 14px;
    line-height: 1.5;
    display: block;
`;

export const StyledDiv = styled.div`
    width: 500px;
    margin-left: 20px;
    margin-bottom: 25px;
`;

export const StyledUsersDiv = styled.div`
    width: 500px;
    height: 200px;
    margin-left: 20px;
    margin-bottom: 30px;
`;

export const StyledList = styled(List)`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 200px;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #dde5f5;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`;

export const StyledCancelButton = styled(Button)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 90px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #cedaeb;
    color: #6d81a6;
    text-transform: none;
    &:hover {
        border: 1px solid #c6c9ce;
        background-color: #f8f6f8;
    }
`;

export const StyledStepperTitle = styled('label')`
    font-family: 'Poppins';
    font-size: 18px;
    color: #2f426c;
    line-height: 1.5;
    font-weight: 600;
    display: block;
    margin-left: 20px;
`;

export const StyledStepLabel = styled(StepLabel)`
    .MuiStepLabel-label {
        font-family: 'ProximaNova';
        color: #6d81a6;
        font-size: 14px;
    }
    .MuiStepLabel-label.MuiStepLabel-active {
        font-family: 'ProximaNova';
        color: #2f426c;
        font-size: 14px;
        font-weight: 600;
    }
`;

export const LabelMsgIdpListNumbers = styled('label')`
    padding: 0 0 8px;
    font-family: 'ProximaNova';
    color: #6d81a6;
    font-size: 12px;
    line-height: 1.5;
    display: block;
`;

export const LabelMsgActive = styled('label')`
    font-family: 'ProximaNova';
    color: #3b61eb;
    font-size: 12px;
    line-height: 1.5;
    margin-left: 8px;
`;

export const UserImage = styled.div`
    display: flex;
    background-color: #415395;
    margin: 7px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export const UserImageText = styled.p`
    margin: auto;
    color: #ffffff;
`;

export const NameIDTextField = styled(WMTextField)`
    .WMTextField-input {
        background-color: #ffffff;
        border: 1px solid #d4dff5;
        border-radius: 5px;
        color: #2f3d53;
        &:hover {
            border: 1px solid #8e949f;
            background-color: #ffffff;
            color: #2f3d53;
        }
        &:focus {
            background-color: #ffffff;
            border: 1px solid #3b61eb;
            color: #2f3d53;
        }
    }
`;

export const StyledXMLDownloadButton = styled(WMButton)`
    margin-left: 6px;
`;

export const StyledCollapsableListItemTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    .nameidsfield: {
        background-color: #ffffff !important;
        margin-left: 6px;
        border-radius: 5px;
        border-width: 1px;
        border-color: #d4dff5;
    }
`;

export const StyledExternalIdpDialog = styled(Dialog)`
    .wizardDialog {
        min-height: 90vh;
        max-height: 90vh;
        border-radius: 15px;
    }

    .wizardDialogContent {
        margin-left: 40px;
    }

    .wizardDialogButtons {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px -2px 0px #0000000a;
        padding-top: 15px;
        margin-bottom: 20px;
        margin-right: 20px;

        .wizardDialogSeconaryButton {
            margin-right: 22px;
        }
    }
`;
