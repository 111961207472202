import styled from 'styled-components';

export const FieldsContainer = styled.div`
    height: 280px;
    overflow-y: auto;
    padding: 0 10px;

    & .field-item-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & .field-item-name-block {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50px;
            & .edit-field-button {
                display: none;
            }
        }
        :hover {
            & .edit-field-button {
                display: inline-flex;
            }
        }
        & .field-select-type-block {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
`;

export const StyledPropertiesToImport = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 35px;

    &.alignItemsCenter {
        align-items: center;
    }

    .helperText {
        color: #2f426c8c;
        padding-top: 4px;

        &.fontSmall {
            font-size: 12px;
        }
    }
`;
