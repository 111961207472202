import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    RadioGroup,
    SnackbarCloseReason,
    Typography,
} from '@material-ui/core';
import {
    WMButton,
    WMButtonVariant,
    WMChip,
    WMConfirmationDialog,
    WMDivider,
    WMRadio,
    WMSnackbar,
    WMSnackbarVariant,
    WMSwitch,
    WMLoader,
} from '@walkme/wm-ui';
import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { System } from 'wm-accounts-sdk';
import { useStyles } from './styles';
import LoadingOptionCard from './loading_option_card/loading_option_card';
import {
    ExtensionStatus,
    GetSystemDataResult,
    InjectionMethod,
    LoadOption,
    PartialSystemData,
    SystemData,
    SystemMatcher,
    SystemMatchers,
} from '@walkme-admin-center/libs/exposure-api';
import Environments from './environments';
import {
    ALL_URLS_DOMAIN_MATCHER,
    ALL_URLS_REGEX,
    getInitialTopDomainValidationMethodValue,
    getMinEnvironmentId,
    getSettingsTitleComponent,
    getSystemMatchersClone,
} from './utils';
import { TopDomainValidationMethod, topDomainValidationMethodRadioValues } from './values';
import Urls from '../common/urls/urls';
import { resetIsDefaultForMatchersIfNeeded, getIsMultipleMatchers, getChangedValuesToSave } from '../utils';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import SiteSnippet from './site_snippet';
import ConfigurationError from '../common/configruration-error/configruration-error';
import { useWebSystem, useUpdatedWebSystem, updateWebSystem, webSystemsSlice } from '@walkme-admin-center/libs/state-management-extensions';
import CustomAlert from '../common/custom-alert';
import isEqual from 'lodash/isEqual';
import { getMinimalEnvById, getNotDevEnvironmentsFromSystemData } from '../utils';
import { Environment, UpdateUserChangedValues } from '../types';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';

import SNIPPET_LOADING_OPTION_SELECTED_ICON from '../../../../../../../packages/libs/common/assets/icons/system_load_option_snippet_selected.svg';
import SNIPPET_LOADING_OPTION_NOT_SELECTED_ICON from '../../../../../../../packages/libs/common/assets/icons/system_load_option_snippet_not_selected.svg';
import EXTENSION_LOADING_OPTION_SELECTED_ICON from '../../../../../../../packages/libs/common/assets/icons/system_load_option_extension_selected.svg';
import EXTENSION_LOADING_OPTION_NOT_SELECTED_ICON from '../../../../../../../packages/libs/common/assets/icons/system_load_option_extension_not_selected.svg';
import MULTIPLE_CONFIGS_ICON from '../../../../../../libs/common/assets/icons/extension_multiple_configurations.png';

interface WebSystemAdvancedSettingsProps {
    open: boolean;
    onClose: () => void;
    system: System;
}

const WebSystemAdvancedSettings = ({ open, onClose, system }: WebSystemAdvancedSettingsProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [areUrlsValid, setAreUrlsValid] = useState(true);
    const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
    const [showSuccessSnackBar, setShowSuccessSnackBar] = useState(false);
    const [deactivateEnvironmentConfirmationSelection, setDeactivateEnvironmentConfirmationSelection] = useState(false);
    const [environments, setEnvironments] = useState<Environment[]>([]);

    const { loggedInUserAppData } = useLoggedInUser();
    const { webSystemAppData } = useWebSystem(system.id);
    const { updatedWebSystemAppData } = useUpdatedWebSystem();
    const [systemData, setSystemData] = useState<PartialSystemData>();
    const [injectionMethod, setInjectionMethod] = useState<InjectionMethod>(InjectionMethod.Extension);
    const [isSystemConnectedToExtension, setIsSystemConnectedToExtension] = useState(false);
    const [canLinkSystemToExtension, setCanLinkSystemToExtension] = useState(false);
    const previousSaveSystemData = useRef(systemData);
    const [currentSelectedEnvId, setCurrentSelectedEnvId] = useState(getMinEnvironmentId(systemData));

    const isChanged = !isEqual(systemData, previousSaveSystemData.current);
    //We currently support only single matcher.
    const isSystemDataDefinedAndEnvSelected =
        currentSelectedEnvId !== undefined &&
        systemData?.matchers[currentSelectedEnvId] &&
        systemData?.matchers[currentSelectedEnvId].length > 0;
    const selectedEnvMatcher: SystemMatcher | undefined = isSystemDataDefinedAndEnvSelected
        ? systemData.matchers[currentSelectedEnvId][0]
        : undefined;
    const isCurrentEnvMatcherActive = selectedEnvMatcher?.isActive ?? false;
    const currentEnvMatcherPreferSiteSnippet = selectedEnvMatcher?.preferSiteSnippet ?? false;
    const currentEnvMatcherWaitForSiteSnippetWithTimeout = selectedEnvMatcher?.waitForSiteSnippetWithTimeout ?? false;
    const currentEnvIsSnippetDetectConfigValid = selectedEnvMatcher?.isSnippetDetectConfigValid ?? true;
    const currentLoadOption: LoadOption = selectedEnvMatcher?.loadOptions ?? LoadOption.OnlyTop;
    const shouldShowEnvironmentMissingSelfDomainUrlsError =
        selectedEnvMatcher &&
        selectedEnvMatcher.isDefault &&
        (selectedEnvMatcher.selfDomains?.length === 0 || selectedEnvMatcher.selfRegex === '');

    const isRegexMode = !!selectedEnvMatcher?.isRegex;
    const currentTopDomainValidationMethod: TopDomainValidationMethod = getInitialTopDomainValidationMethodValue(
        selectedEnvMatcher,
        isRegexMode
    );
    const isMultipleMatchers = getIsMultipleMatchers(systemData, currentSelectedEnvId);
    const topDomains: string[] =
        selectedEnvMatcher && selectedEnvMatcher.topDomains && selectedEnvMatcher.topDomains.length > 0
            ? selectedEnvMatcher.topDomains
            : [];
    const topRegexes: string[] = selectedEnvMatcher && selectedEnvMatcher.topRegex ? [selectedEnvMatcher.topRegex] : [];
    const urls: string[] = isRegexMode ? topRegexes : topDomains;
    const showAddNewUrlComponentByDefault = currentTopDomainValidationMethod === TopDomainValidationMethod.Specific && urls.length === 0;
    const [isAddingNewUrl, setIsAddingNewUrl] = useState(showAddNewUrlComponentByDefault);

    const canSaveUrls = areUrlsValid && (currentTopDomainValidationMethod === TopDomainValidationMethod.Specific ? urls.length > 0 : true);

    useEffect(() => {
        const getSystemDataResult: GetSystemDataResult = webSystemAppData.data && webSystemAppData.data[system.id];
        if (getSystemDataResult) {
            const { extensionStatus, isSystemLinked, systemData } = getSystemDataResult;
            const isSystemConnectedToExtension =
                isSystemLinked && (extensionStatus === ExtensionStatus.Exists || extensionStatus === ExtensionStatus.ExistsNeedsMigration);
            const canLinkSystemToExtension = !isSystemLinked && extensionStatus === ExtensionStatus.Exists;
            setSystemData(systemData);
            previousSaveSystemData.current = {
                ...systemData,
            };
            const envs = getNotDevEnvironmentsFromSystemData(systemData);
            setCurrentSelectedEnvId(getMinimalEnvById(envs).value);
            setEnvironments(envs);
            setIsSystemConnectedToExtension(isSystemConnectedToExtension);
            setCanLinkSystemToExtension(canLinkSystemToExtension);
        }
    }, [webSystemAppData.data]);

    useEffect(() => {
        if (updatedWebSystemAppData.data.systemId) {
            setShowSuccessSnackBar(true);
            dispatch(webSystemsSlice.actions.cleanupUpdatedSystem());
        } else if (updatedWebSystemAppData.error) {
            setShowErrorSnackBar(true);
            dispatch(webSystemsSlice.actions.cleanupUpdatedSystem());
        }
    }, [updatedWebSystemAppData]);

    const dialogTitle = (
        <DialogTitle>
            <Box className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitleText}>Advanced System Settings</Typography>
                <IconButton onClick={onClose}>
                    <img src='assets/icons/close.svg' alt='close' />
                </IconButton>
            </Box>
            <WMDivider className={classes.mainTitleDivider} />
        </DialogTitle>
    );

    const deactivateEnvironmentConfirmationControl = (
        <WMConfirmationDialog
            ds2
            open={deactivateEnvironmentConfirmationSelection}
            onCancel={() => {
                setDeactivateEnvironmentConfirmationSelection(false);
            }}
            onConfirm={() => {
                onIsActiveChange();
                setDeactivateEnvironmentConfirmationSelection(false);
            }}
            title='Disable extension content?'
            confirmLabel='Confirm'
            variant='danger'>
            <Typography className={classes.disableWalkmeConfirmation}>
                WalkMe extension will no longer inject content on this environment
            </Typography>
        </WMConfirmationDialog>
    );

    const getLoadingOptionControlSubTitle = () => {
        if (isSystemConnectedToExtension) {
            return 'A browser extension that loads and injects WalkMe content onto the end-user’s browser.';
        } else if (canLinkSystemToExtension) {
            return 'This system does not have an extension linked to it. Go to the environment configuration panel to configure URLs and link the extension.';
        } else {
            return 'To link this system, contact WalkMe Support.';
        }
    };

    const extensionLoadingOption = (
        <div
            className={
                isSystemConnectedToExtension
                    ? classes.loadingOptionContainer
                    : `${classes.loadingOptionContainer} ${classes.extensionDisabled}`
            }>
            <LoadingOptionCard
                title={isSystemConnectedToExtension ? 'Extension' : 'Extension not linked'}
                subTitle={getLoadingOptionControlSubTitle()}
                icon={
                    isSystemConnectedToExtension && injectionMethod === InjectionMethod.Extension
                        ? EXTENSION_LOADING_OPTION_SELECTED_ICON
                        : EXTENSION_LOADING_OPTION_NOT_SELECTED_ICON
                }
                isSelected={isSystemConnectedToExtension && injectionMethod === InjectionMethod.Extension}
                onClick={
                    isSystemConnectedToExtension && injectionMethod !== InjectionMethod.Extension
                        ? () => setInjectionMethod(InjectionMethod.Extension)
                        : () => {}
                }
            />
        </div>
    );

    const snippetViewOnlyLoadingOption = (
        <div className={classes.loadingOptionContainer}>
            <LoadingOptionCard
                title='Snippet (View only)'
                subTitle='A script placed in the customer’s site that loads and injects WalkMe content onto the end-user’s browser.'
                icon={
                    !isSystemConnectedToExtension || injectionMethod === InjectionMethod.SiteSnippet
                        ? SNIPPET_LOADING_OPTION_SELECTED_ICON
                        : SNIPPET_LOADING_OPTION_NOT_SELECTED_ICON
                }
                isSelected={!isSystemConnectedToExtension || injectionMethod === InjectionMethod.SiteSnippet}
                onClick={
                    injectionMethod !== InjectionMethod.SiteSnippet && !isAddingNewUrl
                        ? () => setInjectionMethod(InjectionMethod.SiteSnippet)
                        : () => {}
                }
            />
        </div>
    );

    const loadingOptionsControl = (
        <Box className={classes.loadingOptionsRootContainer}>
            <Typography className={classes.controlTitle}>Deployment methods options</Typography>
            <Box className={classes.loadingOptionsContainer}>
                {extensionLoadingOption}
                {snippetViewOnlyLoadingOption}
            </Box>
        </Box>
    );

    const onIsActiveChange = (event?: ChangeEvent<HTMLInputElement>): void => {
        if (event && systemData.matchers[currentSelectedEnvId][0].isActive) {
            setDeactivateEnvironmentConfirmationSelection(true);
            return;
        }

        const matchersClone: SystemMatchers = getSystemMatchersClone(systemData.matchers);
        matchersClone[currentSelectedEnvId][0].isActive = !matchersClone[currentSelectedEnvId][0].isActive;

        setSystemData({
            ...systemData,
            matchers: matchersClone,
        });
    };

    const onPreferSiteSnippetChange = (_: ChangeEvent<HTMLInputElement>): void => {
        const matchersClone: SystemMatchers = getSystemMatchersClone(systemData.matchers);
        matchersClone[currentSelectedEnvId][0].preferSiteSnippet = !matchersClone[currentSelectedEnvId][0].preferSiteSnippet;

        setSystemData({
            ...systemData,
            matchers: matchersClone,
        });
    };

    const onWaitForSiteSnippetWithTimeoutChange = (_: ChangeEvent<HTMLInputElement>): void => {
        const matchersClone: SystemMatchers = getSystemMatchersClone(systemData.matchers);
        matchersClone[currentSelectedEnvId][0].waitForSiteSnippetWithTimeout =
            !matchersClone[currentSelectedEnvId][0].waitForSiteSnippetWithTimeout;

        setSystemData({
            ...systemData,
            matchers: matchersClone,
        });
    };

    const onLoadOptionChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const matchersClone: SystemMatchers = getSystemMatchersClone(systemData.matchers);
        matchersClone[currentSelectedEnvId][0].loadOptions =
            currentLoadOption === LoadOption.OnlyTop ? LoadOption.TopAndCdIframes : LoadOption.OnlyTop;

        setSystemData({
            ...systemData,
            matchers: matchersClone,
        });
    };

    const onTopDomainValidationMethodChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const matchersClone: SystemMatchers = getSystemMatchersClone(systemData.matchers);
        const newValue: TopDomainValidationMethod = (event.target as HTMLInputElement).value as TopDomainValidationMethod;

        if (newValue === TopDomainValidationMethod.All) {
            if (isRegexMode) {
                matchersClone[currentSelectedEnvId][0].topRegex = ALL_URLS_REGEX;
            } else {
                matchersClone[currentSelectedEnvId][0].topDomains = [...ALL_URLS_DOMAIN_MATCHER];
            }
            setIsAddingNewUrl(false);
        } else {
            if (isRegexMode) {
                matchersClone[currentSelectedEnvId][0].topRegex = '';
            } else {
                matchersClone[currentSelectedEnvId][0].topDomains = [];
            }
            setIsAddingNewUrl(true);
        }

        setSystemData({
            ...systemData,
            matchers: matchersClone,
        });
        if (newValue === TopDomainValidationMethod.All) {
            //Resetting the urls validity, since error in urls is not relevant anymore, since we changed it.
            setAreUrlsValid(true);
        }
    };

    const getTopDomainValidationMethodRadioOption = (value: TopDomainValidationMethod, label: string) => (
        <FormControlLabel
            className={classes.radioOption}
            control={<WMRadio ds2 />}
            value={value}
            key={value}
            label={<Typography className={classes.radioOptionLabel}>{label}</Typography>}
        />
    );

    const setTopUrlsOfEnv = (urls: string[], envId: number): void => {
        const matchersCopy: SystemMatchers = getSystemMatchersClone(systemData.matchers);

        if (isRegexMode) {
            //In case it's regex, we dont split it. Just place it as a single value in the array.
            matchersCopy[envId][0].topRegex = urls[0];
        } else {
            matchersCopy[envId][0].topDomains = [...urls];
        }

        setSystemData({
            ...systemData,
            matchers: { ...matchersCopy },
        });
    };

    const loadOptionControl = (
        <Box display='flex'>
            <Box flexGrow={1}>{getSettingsTitleComponent('Load WalkMe on cross domain iframes')}</Box>
            <Box alignSelf='flex-end'>
                <WMSwitch
                    disabled={isAddingNewUrl}
                    checked={currentLoadOption === LoadOption.TopAndCdIframes}
                    onChange={onLoadOptionChange}
                />
            </Box>
        </Box>
    );

    const onAddNewUrlClosed = () => {
        setIsAddingNewUrl(false);
        setAreUrlsValid(true);
    };

    const topDomainValidationControl = (
        <Box display='flex' flexDirection='column'>
            {getSettingsTitleComponent('Top domain validation')}
            <Box display='flex' flexDirection='column'>
                <RadioGroup
                    value={currentTopDomainValidationMethod}
                    onChange={onTopDomainValidationMethodChange}
                    name='TopDomainValidationMethod'
                    className={classes.radioGroup}>
                    {topDomainValidationMethodRadioValues.map((topDomainValidationMethodRadioValue) =>
                        getTopDomainValidationMethodRadioOption(
                            topDomainValidationMethodRadioValue.value,
                            topDomainValidationMethodRadioValue.label
                        )
                    )}
                </RadioGroup>
                {currentTopDomainValidationMethod === TopDomainValidationMethod.Specific && (
                    <Box className={classes.urls}>
                        <Urls
                            urls={urls}
                            shouldDisableParts={false}
                            isRegexMode={isRegexMode}
                            setAreUrlsValid={setAreUrlsValid}
                            setUrls={setTopUrlsOfEnv}
                            envId={currentSelectedEnvId}
                            enableEmpty={false}
                            showAddNewUrlComponentByDefault={showAddNewUrlComponentByDefault}
                            isMultipleMatchers={isMultipleMatchers}
                            onAddUrlClicked={() => setIsAddingNewUrl(true)}
                            onAddNewUrlsSaved={() => setIsAddingNewUrl(false)}
                            onAddNewUrlClosed={onAddNewUrlClosed}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );

    const preferSiteSnipperControl = (
        <Box display='flex' paddingTop={'20px'}>
            <Box flexGrow={1}>{getSettingsTitleComponent('If the snippet doesn’t load, load the extension')}</Box>
            <Box alignSelf='flex-end'>
                <WMSwitch
                    disabled={isAddingNewUrl}
                    checked={currentEnvMatcherWaitForSiteSnippetWithTimeout}
                    onChange={onWaitForSiteSnippetWithTimeoutChange}
                />
            </Box>
        </Box>
    );

    const snippetDetectControl = (
        <Box display='flex' flexDirection='column'>
            {getSettingsTitleComponent('If you have an active WalkMe Extension and snippet:')}
            <Box display='flex' paddingTop={'20px'}>
                <Box flexGrow={1}>{getSettingsTitleComponent('Load the snippet first')}</Box>
                <Box alignSelf='flex-end'>
                    <WMSwitch disabled={isAddingNewUrl} checked={currentEnvMatcherPreferSiteSnippet} onChange={onPreferSiteSnippetChange} />
                </Box>
            </Box>
            {currentEnvMatcherPreferSiteSnippet ? preferSiteSnipperControl : null}
        </Box>
    );

    const notActiveWarningControl = (
        <Box paddingTop={'20px'}>
            <CustomAlert severity='error' message='When toggled off, WalkMe content will not load on this environment' />
        </Box>
    );

    const isActiveControl = (
        <Box display='flex' flexDirection='column'>
            <Box display='flex'>
                <Box flexGrow={1}>{getSettingsTitleComponent('Load WalkMe on this environment')}</Box>
                <Box alignSelf='flex-end'>
                    <WMSwitch disabled={isAddingNewUrl} checked={isCurrentEnvMatcherActive} onChange={onIsActiveChange} />
                </Box>
            </Box>
            {isCurrentEnvMatcherActive ? null : notActiveWarningControl}
        </Box>
    );

    const noSelfUrlsErrorControl = (
        <CustomAlert
            severity='error'
            message='Add at least one URL to edit this environment. To add a URL go to the environment configuration panel.'
        />
    );

    const environmentMatcherConfigurationControl = (
        <>
            {isActiveControl}
            {isCurrentEnvMatcherActive ? (
                <>
                    <WMDivider className={classes.settingsDivider} />
                    {currentEnvIsSnippetDetectConfigValid ? (
                        <>
                            {snippetDetectControl}
                            <WMDivider className={classes.settingsDivider} />
                        </>
                    ) : null}
                    {loadOptionControl}
                    <WMDivider className={classes.settingsDivider} />
                    {topDomainValidationControl}
                </>
            ) : null}
        </>
    );

    const envSettingsControl = (
        <Box className={classes.envSettingsContainer}>
            {shouldShowEnvironmentMissingSelfDomainUrlsError ? noSelfUrlsErrorControl : environmentMatcherConfigurationControl}
        </Box>
    );

    const siteSnippetControl = (
        <Box className={classes.envSettingsContainer}>
            <SiteSnippet
                envPath={currentSelectedEnvId ? environments.find((env: Environment) => env.value === currentSelectedEnvId).path : ''}
                systemGuid={system.guid}
            />
        </Box>
    );

    const multipleMatchersInfoControl = (
        <ConfigurationError
            configurationErrorDetails={{
                title: 'Configuration cannot be viewed',
                message:
                    'Due to multiple configurations in this environment, you are unable to make changes.\nIn order to combine the configurations or make changes, contact WalkMe Support.',
                image: MULTIPLE_CONFIGS_ICON,
            }}
            imageClassName={classes.multipleConfigImage}
        />
    );

    const getEnvConfigContentControl = () => {
        if (isMultipleMatchers) {
            return multipleMatchersInfoControl;
        } else if (!isSystemConnectedToExtension || injectionMethod === InjectionMethod.SiteSnippet) {
            return siteSnippetControl;
        } else {
            return envSettingsControl;
        }
    };

    const environmentsControl = (
        <Box className={classes.settingsContainer}>
            <Typography className={classes.controlTitle}>Environments</Typography>
            <Box className={classes.environmentsContainer}>
                <Environments
                    environments={environments}
                    systemData={systemData}
                    setCurrentEnvId={setCurrentSelectedEnvId}
                    selectedEnvId={currentSelectedEnvId}
                    shouldDisable={isAddingNewUrl}
                    toolTipDisableMessage={isAddingNewUrl ? 'Disabled while adding URL' : null}
                />
                <WMDivider orientation='vertical' flexItem className={classes.verticalDivider} />
                {getEnvConfigContentControl()}
            </Box>
        </Box>
    );

    const actualContent = (
        <Box className={classes.settingsContainer}>
            {deactivateEnvironmentConfirmationControl}
            {loadingOptionsControl}
            {environmentsControl}
        </Box>
    );

    const onSaveClicked = async () => {
        resetIsDefaultForMatchersIfNeeded(systemData);

        const changedValuesForAuditLog: UpdateUserChangedValues = getChangedValuesToSave(
            previousSaveSystemData.current,
            systemData,
            system
        );

        const systemDataToSave: SystemData = {
            systemTypeKey: system.systemTypeKey,
            systemId: system.id,
            systemEmail: system.email,
            name: system.name,
            accountId: loggedInUserAppData.data.account!.id,
            accountName: loggedInUserAppData.data.account!.name,
            appId: system.settings.id,
            changedValuesForAuditLog,
            ...systemData,
        };

        previousSaveSystemData.current = {
            ...systemData,
        };
        dispatch(updateWebSystem(systemDataToSave));
        setIsAddingNewUrl(false);
    };

    const saveButton = (
        <Box className={classes.saveButtonContainer}>
            <WMButton
                variant={WMButtonVariant.Primary}
                onClick={onSaveClicked}
                disabled={!canSaveUrls || updatedWebSystemAppData.loading || !isChanged || isAddingNewUrl}
                loading={updatedWebSystemAppData.loading}
                className={classes.saveButton}>
                Save
            </WMButton>
        </Box>
    );

    const dialogContent = (
        <DialogContent className={classes.dialogContentContainer}>
            <Box className={classes.generalSystemDetailsContainer}>
                <Box className={classes.systemDetails}>
                    <img src={retrieveSystemIcon(system.settings)} className={classes.systemDetailsIcon} />
                    <Box className={classes.systemNames}>
                        <Typography className={classes.genericSystemName}>
                            {system.settings ? system.settings.displayName : 'Default'}
                        </Typography>
                        <Typography className={classes.customSystemName}>{system.displayName}</Typography>
                    </Box>
                </Box>
                <Box alignSelf='flex-end'>
                    <WMChip label={system.systemTypeKey} rounded />
                </Box>
            </Box>
            <WMDivider
                style={{
                    marginTop: '16px',
                    marginBottom: '16px',
                }}
            />
            {actualContent}
        </DialogContent>
    );

    const handleSnackBarErrorClose = (_: React.SyntheticEvent<any>, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') return;
        setShowErrorSnackBar(false);
    };

    const handleSnackBarSuccessClose = (_: React.SyntheticEvent<any>, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') return;
        setShowSuccessSnackBar(false);
    };

    const errorSnackBar = (
        <WMSnackbar
            open={showErrorSnackBar}
            onClose={handleSnackBarErrorClose}
            variant={WMSnackbarVariant.Error}
            message={'Save failed. Please try again later or contact WalkMe Support.'}
        />
    );

    const successSnackBar = (
        <WMSnackbar
            open={showSuccessSnackBar}
            onClose={handleSnackBarSuccessClose}
            variant={WMSnackbarVariant.Success}
            message={'Changes saved'}
        />
    );

    const webSystemLoadingControl = (
        <div className={classes.loader}>
            <WMLoader
                style={{
                    width: '30%',
                    maxWidth: '300px',
                }}></WMLoader>
        </div>
    );

    const webSystemConfigurationLoadedComponent = (
        <>
            {errorSnackBar}
            {successSnackBar}
            {dialogTitle}
            {dialogContent}
            {isSystemConnectedToExtension ? <DialogActions>{saveButton}</DialogActions> : null}
        </>
    );

    return (
        <Dialog
            onClose={() => onClose()}
            open={open}
            classes={{
                paper: classes.dialog,
            }}
            fullWidth={true}
            maxWidth={'xl'}>
            {webSystemAppData.loading ? webSystemLoadingControl : webSystemConfigurationLoadedComponent}
        </Dialog>
    );
};

export { WebSystemAdvancedSettings };
