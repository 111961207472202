import styled from 'styled-components';
import React from 'react';
import { Paper } from '@material-ui/core';
import { ViewLogo } from './view-logo';
import { ViewStatus } from './view-status';
import { ViewEditButton } from './view-edit-button';

const StyledContainer = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const StyledButtonContainer = styled.div`
  margin: 0 20px 0 auto;
`

interface ViewContainerProps {
  cloudStorage: string;

  toggleEditDialog: () => void;

  editDisabled: boolean;
}

export const ViewContainer = (props: ViewContainerProps) => {
  return (
    <Paper>
      <StyledContainer>
        <ViewLogo cloudStorage={props.cloudStorage}/>
        <ViewStatus cloudStorage={props.cloudStorage} editDisabled={props.editDisabled}/>
        <StyledButtonContainer>
          <ViewEditButton toggleEditDialog={props.toggleEditDialog} editDisabled={props.editDisabled}/>
        </StyledButtonContainer>
      </StyledContainer>
    </Paper>
  );
}

export default ViewContainer;
