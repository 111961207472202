/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { ReactNode } from 'react';
import { Base } from './base';
import TabPanel from '../../common/components/custom-tab-panel';
import {
    IAskMeForm,
    IAskMe,
    IHeaderText,
    IIntegrations,
    IScanSummary,
    FormMode,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import ScanDiffStep from '../data-integration-form-components/scan-summary/steps/scan-diff-step';
import ScanUrlsStep from '../data-integration-form-components/scan-summary/steps/scan-urls-step';
import { RevertScan } from '../data-integration-form-components/scan-summary/header-actions/revert-scan';
import moment from 'moment';

type ISourceWithSummary = IAskMe & { scanSummary: IScanSummary };

interface IHandleSubmit {
    values: IAskMeForm;
    instanceData: IAskMe;
    integrations: IIntegrations;
}

export class ScanSummary extends Base {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    async handleSubmit({ values, instanceData, integrations }: IHandleSubmit): Promise<void> {
        return;
    }

    getFormLabels(): Array<string> {
        return ['Last Scan Summary', 'Current URLs'];
    }

    getFormHeader(integration: IAskMe = null, formMode): IHeaderText {
        return { header: `Scan Summary`, subHeader: `${integration.name} · ${moment(integration.lastCrawl).format('MMM DD, YYYY')}` };
    }

    getFormByTab(step: number, instanceData: IAskMe | null, triggerModal: () => void): React.ReactNode {
        return (
            <>
                <TabPanel value={step} index={0}>
                    <ScanDiffStep />
                </TabPanel>
                <TabPanel value={step} index={1}>
                    <ScanUrlsStep />
                </TabPanel>
            </>
        );
    }

    getTabsFooter(): React.ReactNode {
        return undefined;
    }

    getOnePageForm(): React.ReactNode {
        return <ScanUrlsStep />;
    }

    getOnePageFooter() {
        return undefined;
    }

    getFormHeaderActions(source: ISourceWithSummary, formMode: FormMode): JSX.Element {
        return <RevertScan sourceGuid={source.sourceId} onClick={this.closeForm} disabled={!source.scanSummary.canRevert} />;
    }

    async validate() {}

    initForm({ data: source }: { data: ISourceWithSummary }): any {
        return {
            sourceGuid: source.sourceId,
            urls: { ...(source?.scanSummary?.urls || {}) },
            aiSummary: source?.scanSummary?.aiSummary,
        };
    }
}
