import React, { SVGProps } from 'react';

export const APIKeysSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg width='21' height='21' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.1296 11.144C14.0625 11.1597 14.9635 10.8042 15.6343 10.1557C16.3052 9.50726 16.691 8.61889 16.707 7.68599C16.6908 6.75321 16.3049 5.86501 15.6341 5.21668C14.9633 4.56835 14.0624 4.21296 13.1296 4.22866C12.8269 4.22863 12.5254 4.26557 12.2316 4.33866C11.9588 3.78313 11.5347 3.31589 11.0081 2.99063C10.4815 2.66537 9.87387 2.49531 9.25497 2.49999C8.4475 2.48799 7.66471 2.77828 7.06031 3.31386C6.4559 3.84944 6.07354 4.59161 5.9883 5.39466C5.26268 5.45087 4.58417 5.77592 4.08562 6.30615C3.58708 6.83638 3.30441 7.53362 3.29297 8.26132C3.30665 9.03851 3.62824 9.77849 4.18711 10.3187C4.74598 10.859 5.49643 11.1553 6.27364 11.1427L13.1296 11.144Z'
            stroke='#92A1BC'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M15.4971 17.5V13.144' stroke='#92A1BC' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.625 13.144H16.367' stroke='#92A1BC' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.625 17.5H16.367' stroke='#92A1BC' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.39844 17.5V15.7573' stroke='#92A1BC' strokeLinecap='round' strokeLinejoin='round' />
        <path
            d='M9.40039 15.7573H10.7071C11.0536 15.7573 11.386 15.6196 11.631 15.3746C11.8761 15.1296 12.0137 14.7972 12.0137 14.4506C12.0137 14.1041 11.8761 13.7717 11.631 13.5267C11.386 13.2816 11.0536 13.144 10.7071 13.144H9.40039V15.7573Z'
            stroke='#92A1BC'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M3.2998 17.5L3.79114 14.2206C3.83885 13.9233 3.99095 13.6527 4.22015 13.4573C4.44935 13.262 4.74065 13.1547 5.0418 13.1547C5.34296 13.1547 5.63426 13.262 5.86346 13.4573C6.09266 13.6527 6.24476 13.9233 6.29247 14.2206L6.78447 17.5'
            stroke='#92A1BC'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M3.52832 15.9753H6.55565' stroke='#92A1BC' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);
