import React from 'react';
import { useForm } from 'react-final-form';
import { FormApi } from 'final-form';
import { StepperMode } from '../../common/consts';
import { CancelButton, StyledButton } from '../../common/style';

export interface StepperFooterProps {
  stopPolling: () => void;
  stepperMode: string;
  activeStep: number;
  handleNext: () => void;
  canUpdate: boolean;
  onClose: () => void;
  save: (formApi: FormApi) => void
  assignSystems: (values) => void;
  handleAuth: (values, formApi: FormApi) => void;
  inProgress: boolean;
  fieldsLoad: boolean;
}

export const StepperFooter = ({
                                stopPolling,
                                stepperMode,
                                activeStep,
                                handleNext,
                                canUpdate,
                                onClose,
                                save,
                                assignSystems,
                                handleAuth,
                                inProgress,
                                fieldsLoad,
                              }: StepperFooterProps) => {
  const form = useForm();

  const getFooterByMode = () => {
    switch (stepperMode) {
      case StepperMode.NEW:
        return (<>
            {activeStep === 0 && <StyledButton className={'footer-button'} loading={!canUpdate && inProgress} showPipe={!canUpdate && inProgress}
                                               disabled={(form.getState().invalid || (inProgress && canUpdate))}
                                               onClick={() => canUpdate ? handleNext() : !inProgress ? handleAuth(form.getState().values, form) : stopPolling()}> {canUpdate ? 'Next' : !inProgress ? 'Save & Next' : 'Discard'} </StyledButton>}
            {activeStep === 1 &&
            <StyledButton className={'footer-button'} disabled={(form.getState().invalid || inProgress)} loading={inProgress}
                          onClick={() => save(form)}> Save & Assign Systems </StyledButton>}
            {activeStep !== 2 ? null : (form.getFieldState('systems') && form.getFieldState('systems').dirty) ?
              <StyledButton className={'footer-button'} loading={inProgress} disabled={inProgress}
                            onClick={() => assignSystems(form.getState().values)}> Finish </StyledButton>
              : <StyledButton className={'footer-button'} onClick={() => onClose()}> Finish </StyledButton>
            }
          </>
        );
      case StepperMode.EDIT:
        return (<>
          {activeStep === 0 &&
          <>
            {!(canUpdate && fieldsLoad) ?
              <StyledButton className={'footer-button'} loading={inProgress}
                            disabled={form.getState().invalid || !form.getState().dirty || (inProgress && canUpdate)} showPipe={!canUpdate && inProgress}
                            onClick={() => canUpdate ? save(form) : !inProgress ? handleAuth(form.getState().values, form) : stopPolling()}> {canUpdate ? 'Save' : !inProgress ? 'Save & Next' : 'Discard'} </StyledButton>
              : <StyledButton className={'footer-button'}
                              disabled={form.getState().invalid || !form.getState().dirty}
                              onClick={() => handleNext()}>Next</StyledButton>}
          </>}
          {activeStep === 1 &&
          <StyledButton className={'footer-button'} loading={inProgress} disabled={(form.getState().invalid || inProgress)}
                        onClick={() => save(form)}> Save </StyledButton>}
        </>);
      case StepperMode.EDIT_ATTRIBUTES:
        return (
          <StyledButton className={'footer-button'} loading={inProgress && fieldsLoad}
                        disabled={(form.getState().invalid || (form.getState() ? !form.getState().dirty : true) || inProgress)}
                        onClick={() => save(form)}> Save </StyledButton>
        );
      case StepperMode.ASSIGN_SYSTEMS: {
        return (
          <StyledButton className={'footer-button'} loading={inProgress} disabled={(inProgress || !(form.getFieldState('systems') && form.getFieldState('systems').dirty))}
                        onClick={() => (form.getFieldState('systems') && form.getFieldState('systems').dirty)
                          ? assignSystems(form.getState().values)
                          : onClose()}> Save </StyledButton>);
      }
    }
  };

  return (
    <>
      <CancelButton type={'text'} onClick={() => onClose()}>Cancel</CancelButton>
      {getFooterByMode()}
    </>
  );
};

export default StepperFooter;
