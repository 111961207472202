export class ManifestTemplate {
    private readonly BOT_ID: string;
    constructor(BOT_ID: string) {
        this.BOT_ID = BOT_ID;
    }

    getTemplate() {
        return {
            $schema: 'https://developer.microsoft.com/en-us/json-schemas/teams/v1.15/MicrosoftTeams.schema.json',
            version: '1.1.0',
            manifestVersion: '1.15',
            id: `${this.BOT_ID}`,
            packageName: 'com.walkme.askme',
            name: {
                short: 'AskMeAi',
                full: 'Teams AskMe',
            },
            developer: {
                name: 'AskMe',
                mpnId: '',
                websiteUrl: 'https://www.walkme.com',
                privacyUrl: 'https://www.walkme.com/privacy',
                termsOfUseUrl: 'https://www.walkme.com/terms-and-conditions/',
            },
            description: {
                short: 'AskMe',
                full: 'AskMe bot for Teams',
            },
            icons: {
                outline: 'outline.png',
                color: 'color.png',
            },
            accentColor: '#FFFFFF',
            staticTabs: [
                {
                    entityId: 'conversations',
                    scopes: ['personal'],
                },
                {
                    entityId: 'about',
                    scopes: ['personal'],
                },
            ],
            bots: [
                {
                    botId: `${this.BOT_ID}`,
                    scopes: ['personal', 'team', 'groupChat'],
                    isNotificationOnly: false,
                    supportsCalling: false,
                    supportsVideo: false,
                    supportsFiles: false,
                },
            ],
            validDomains: [],
        };
    }
}
