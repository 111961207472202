import React from 'react';
import { IdpEnvs } from '@walkme-admin-center/libs/state-management-sso-configuration';
import Env from './env/env';
import { Environments } from 'wm-accounts-sdk';

export interface EnvsListProps {
    envs: IdpEnvs;
    allSystemEnvs: Environments;
}

export const EnvsList = ({ envs, allSystemEnvs }: EnvsListProps) => {
    const getName = (envId: number) => {
        const envData = allSystemEnvs.find((e) => e.id === envId);
        return envData ? envData.name : '';
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {allSystemEnvs.length &&
                envs.map((env, index) => {
                    return <Env key={`${env.id}_${index}`} enforce={env.enforceSso} name={getName(env.id)} />;
                })}
        </div>
    );
};

export default EnvsList;
