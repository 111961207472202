import styled from 'styled-components';

export const StyledEmptyStateSearch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 132px);
`;

export const StyledEmptyStateDescription = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 22.5px;
    font-family: 'ProximaNova';

    .emptyStateTitle {
        font-weight: bold;
    }

    .emptyStateDescription {
        color: #6d81a6;

        .clearFilterText {
            cursor: pointer;
            color: var(--Primaries-Blue-Primary-Primary-Main, #385feb);
        }
    }
`;
