import { WMhttpClient } from '@walkme-admin-center/libs/http-service';
import useClientConfig from '@walkme-admin-center/../apps/home/src/redux/hooks/useClientConfig';

export interface IBusinessDomain {
    id: number;
    key: string;
    name: string;
    description: string;
    workflowsTypesCount: number;
    order: number;
}

class DeepUiApi {
    private readonly httpClient: WMhttpClient;
    private readonly path: string;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
        this.path = '/deepui/api';
    }

    async getBusinessDomains(): Promise<IBusinessDomain[]> {
        const businessDomainsResponse = await this.httpClient.instance.get(`${this.path}/dap-orchestrator/api/businessDomains`);
        return businessDomainsResponse.data;
    }
}

export const deepUiApi = new DeepUiApi(new WMhttpClient(window.clientConfig.NX_WM_API_URL));
