import styled from 'styled-components';

export const ProviderContainer = styled.div`
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
`;
