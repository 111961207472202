import { AppData } from '@walkme-admin-center/libs/types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemsSelfHosted, SystemsState } from '../redux/systems.slice';
import { SystemSelfHosted } from '@walkme-admin-center/self-hosting';

export const useSystemsSelfHosted = () => {
    const systemsSelfHostedAppData: AppData<SystemSelfHosted[]> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.systemsSelfHosted
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSystemsSelfHosted());
    }, [dispatch]);

    const systemsSelfHostedMap = useMemo(() => {
        return Array.isArray(systemsSelfHostedAppData?.data)
            ? systemsSelfHostedAppData.data.reduce((acc, current) => {
                  acc[current.Id] = current.IsSelfHosted;
                  return acc;
              }, {})
            : [];
    }, [systemsSelfHostedAppData.data]);

    return {
        systemsSelfHostedAppData,
        systemsSelfHostedMap,
    };
};

export default useSystemsSelfHosted;
