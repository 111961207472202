import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { SideMenu, Tab } from '@walkme-admin-center/libs/ui-components';
import { ActivityBoard, Settings, Users, MultiLanguage, Apps, ClockTimer, TargetUser, Bag, Tv, Document } from '@walkme/ui-icons/large';
import { APIKeysSVG } from '../../../assets/icons/APIKeys';
import { ShieldSVG } from '../../../assets/icons/Shield';
import { PartnersSVG } from '../../../assets/icons/Partners';
import { AiCenterIcon } from '../../../assets/icons/data-integrations/ai-center-Icon';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import useGetProviders from '../../../../state-management-systems/src/lib/hooks/use-get-providers';
import { TargetUserWithErrorSVG } from '../../../assets/icons/TargetUserWithError';

export const getTabs = (isFeatureEnabled, isPermitted, loggedInUserAppData, t, hasNoProviders?): Tab[] => {
    return [
        {
            to: 'systems',
            label: t('side-bar.systems'),
            icon: <Apps />,
            hidden: !isPermitted('systemManagement'),
            index: 10,
        },
        {
            to: 'users-and-roles',
            label: t('side-bar.users-roles'),
            index: 2,
            icon: <Users />,
            hidden: !isPermitted('Management'),
            children: [
                {
                    to: 'users',
                    label: t('side-bar.users'),
                    index: 2,
                },
                {
                    to: 'roles',
                    label: t('side-bar.roles'),
                    index: 2,
                },
            ],
        },
        {
            to: 'partners',
            label: t('side-bar.partners'),
            hidden: !isFeatureEnabled('adminPartnersTab'),
            index: 4,
            icon: <PartnersSVG />,
        },
        {
            to: 'activity-log',
            icon: <ClockTimer />,
            label: t('side-bar.activity-log'),
            index: 13,
        },
        {
            to: 'account-api-keys',
            label: t('side-bar.api-keys'),
            hidden: !isPermitted('APIKeys'),
            icon: <APIKeysSVG />,
            index: 16,
        },
        {
            to: 'hosting',
            label: t('side-bar.hosting'),
            icon: <Tv />,
            index: 14,
            hidden: !isFeatureEnabled('multiCloudFileStorage'),
            children: [
                {
                    to: 'end-user-experience',
                    label: t('side-bar.end-user-experience'),
                    index: 15,
                    hidden: !isFeatureEnabled('multiCloudFileStorage'),
                },
                {
                    to: 'editor-and-apps',
                    label: t('side-bar.editor-and-apps'),
                    index: 16,
                    hidden: !isFeatureEnabled('multiCloudFileStorage'),
                },
                {
                    to: 'analytics',
                    label: t('side-bar.analytics'),
                    index: 17,
                    hidden: !isFeatureEnabled('multiCloudFileStorage'),
                },
            ],
        },
        {
            to: 'security',
            label: t('side-bar.security'),
            hidden: !isPermitted('Security'),
            icon: <ShieldSVG />,
            index: 18,
        },
        {
            label: t('side-bar.idp-integrations'),
            icon: hasNoProviders ? <TargetUserWithErrorSVG /> : <TargetUser />,
            errorKey: hasNoProviders ? 'no-idp-integrations' : null,
            hidden: !isPermitted('Management', 'Write'),
            to: 'idp-integrations',
            // hidden: !isPermitted('IDP'),
            index: 22,
        },
        {
            label: t('side-bar.ai-center'),
            icon: <AiCenterIcon />,
            to: 'ai-center',
            hidden: !isPermitted('Management', 'Write'),
            index: 23,
            children:
                isFeatureEnabled('enable_askMe') || isFeatureEnabled('enable_aiUsage')
                    ? [
                          {
                              label: t('side-bar.ai-useage'),
                              to: 'ai-usage',
                              hidden: !(isFeatureEnabled('enable_aiUsage') && isPermitted('Management', 'Write')),
                              index: 24,
                          },
                          {
                              label: t('side-bar.ai-settings'),
                              to: 'ai-settings',
                              hidden: !(isFeatureEnabled('enable_askMe') && isPermitted('Management', 'Write')),
                              index: 25,
                          },
                      ]
                    : null,
        },
        {
            to: 'multi-language',
            icon: <MultiLanguage />,
            label: t('side-bar.multi-language'),
            index: 3,
        },
        {
            to: 'contracts',
            label: t('side-bar.contracts'),
            icon: <Document />,
            index: 10,
        },
        {
            to: 'installation',
            label: t('side-bar.installation'),
            index: 1,
            icon: <Settings />,
            hidden: window.clientConfig.NX_SHOW_INSTALLATION_TAB !== 'true',
            divider: true,
        },
    ];
};

export const SideBar = () => {
    const { t } = useTranslation('general');
    const { pathname } = useLocation();
    const { loggedInUserAppData, accountFeatureEnabled, userPermissions } = useLoggedInUser();
    const isImpersonator = !!loggedInUserAppData?.data?.impersonator;
    const { providers } = useGetProviders(isImpersonator);

    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);
    const isPermitted = useMemo(() => (actionKey, permission?) => userPermissions(actionKey, permission), [userPermissions]);
    const hasNoProviders = useMemo(() => {
        return providers?.data?.length === 0;
    }, [providers]);
    const tabs = useMemo(
        () => getTabs(isFeatureEnabled, isPermitted, loggedInUserAppData, t, hasNoProviders),
        [isFeatureEnabled, isPermitted, t, providers]
    );

    return <SideMenu tabs={tabs} path={pathname} />;
};

export default SideBar;
