import Base from './base';
import { Fields, IdpSystemsFormValue, Provider, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import { oAuth2AzureSettingsSchema } from '../validation';
import { sharedFields } from '../shared-fields';
import { CONSTS } from '../../../common/consts';

const azurePdf = require('../../../common/pdfFiles/idp_azure_instructions.pdf');

export class Azuread extends Base {
    constructor() {
        super('Microsoft Entra ID', oAuth2AzureSettingsSchema, azurePdf);
    }

    getFormFields(): Array<any> {
        return [
            sharedFields.idpName,
            sharedFields.clientId,
            sharedFields.clientSecret,
            sharedFields.directoryId,
            sharedFields.domainHint,
            sharedFields.pullGroups,
            sharedFields.enforceSSOIframeFlow,
        ];
    }

    prepareFields = (values): Fields => {
        const fields = [...values.fields];
        let isExsits = false;
        if (values.config.pullGroups) {
            isExsits = fields.some((field) => field.name === CONSTS.PROVIDER_GROUPS);
            if (!isExsits) {
                fields.push({
                    name: CONSTS.PROVIDER_GROUPS,
                    dataType: 'String',
                });
            }
        }
        return fields;
    };

    initForm(provider: Provider, assignSystemsForm: IdpSystemsFormValue): ProviderFormValues {
        return {
            config: {
                type: this.getProtocol(),
                clientId: get(provider, 'config.clientId', ''),
                clientSecret: get(provider, 'config.clientSecret', ''),
                directoryId: get(provider, 'config.directoryId', ''),
                domainHint: get(provider, 'config.domainHint', ''),
                pullGroups: get(provider, 'config.pullGroups', false),
                enforceSSOIframeFlow: get(provider, 'config.enforceSSOIframeFlow', false),
            },
            name: get(provider, 'name', ''),
            fields: get(provider, 'fields', []).filter((field) => field.name !== CONSTS.PROVIDER_GROUPS),
            userIdentifier: get(provider, 'userIdentifier', ''),
            systems: this.prepareSystems(get(provider, 'id', null), get(provider, 'systems', []), assignSystemsForm),
        };
    }
}
