import { System } from 'wm-accounts-sdk';
import { Box, Typography } from '@material-ui/core';
import { WMChip } from '@walkme/wm-ui';
import React from 'react';
import { useStyles } from './system-info-style';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';

interface SystemInfoProps {
    system: System;
    className?: string;
    hasMobileWeb: boolean;
}

const SystemInfo = ({ system, className, hasMobileWeb }: SystemInfoProps) => {
    const classes = useStyles();
    const chips = hasMobileWeb ? [system.systemTypeKey, 'Mobile Web'] : [system.systemTypeKey];

    return (
        <Box className={`${classes.containerBox} ${className}`}>
            <Box className={classes.systemIconBox}>
                <img src={retrieveSystemIcon(system.settings)} />
            </Box>
            <Box className={classes.systemNamesBox}>
                <Typography className={classes.systemTypeName}>{system.settings ? system.settings.displayName : 'Default'}</Typography>
                <Typography className={classes.systemName}>{system.displayName}</Typography>
            </Box>
            <Box className={classes.platformTypeBox}>
                {chips.map((label) => (
                    <WMChip key={label} className={classes.platformTypeChip} label={label} />
                ))}
            </Box>
        </Box>
    );
};

export { SystemInfo };
