import React from 'react';
import { Step, Stepper } from '@material-ui/core';
import { getSystemStepper } from './create-system-stepper.lib';
import {
    StyledStepLabel,
    StyledStepperContainer,
    StyledSystemDescription,
    StyledAppIconContainer,
    StyledSystemDescriptionText,
} from './create-system-stepper.styles';
import { CreateSystemSteps } from '../../../../../../../libs/state-management-systems/src/lib/types/createSystem';
import { TvIcon } from '../assets/icons/tv';

type CreateSystemStepperProps = {
    activeStep: number;
    type: string;
    usage: string;
    selectedSystemApp: any;
};

export const CreateSystemStepper = ({ activeStep, type, usage, selectedSystemApp }: CreateSystemStepperProps) => {
    const [CreateSystemStepperSteps, getMainDescription] = getSystemStepper();
    const mainDescription = getMainDescription({ type, usage });
    const systemAppWasAlreadySelected = activeStep > CreateSystemSteps.SELECT_APPLICATION;

    return (
        <StyledStepperContainer>
            <StyledSystemDescription>
                {systemAppWasAlreadySelected && selectedSystemApp ? (
                    <StyledAppIconContainer>
                        <img src={selectedSystemApp.icons?.highRes} alt='' width={25} height={25} />
                    </StyledAppIconContainer>
                ) : (
                    <StyledAppIconContainer>
                        <TvIcon />
                    </StyledAppIconContainer>
                )}
                <StyledSystemDescriptionText>
                    <div className='systemType'>{systemAppWasAlreadySelected ? selectedSystemApp?.displayName : mainDescription.type}</div>
                    <div className='systemUsage'>{mainDescription.title}</div>
                </StyledSystemDescriptionText>
            </StyledSystemDescription>
            <Stepper activeStep={activeStep} id='styled-stepper'>
                {CreateSystemStepperSteps.map((stepData, index) => {
                    return (
                        <Step key={index}>
                            <StyledStepLabel>{stepData.displayName}</StyledStepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </StyledStepperContainer>
    );
};
