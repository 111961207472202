import { Step, StepConnector, StepLabel, Stepper, withStyles } from '@material-ui/core';
import React from 'react';
import QontoStepIcon from './stepper-icons';
import styled from 'styled-components';

const QontoConnector = withStyles({
    root: {
        flex: 'none',
        width: '20px',
        // height: 0,
        // borderTop: '1px solid #E6ECF8',
    },
    lineHorizontal: {
        borderTop: '1px solid #E6ECF8',
    },
})(StepConnector);

const StyledStepper = styled(Stepper)`
    height: 51px;
    border-bottom: solid 1px #f5f6f8;
    padding: 0 0 0 32px;

    .MuiStep-horizontal:first-child {
        padding-left: 0;
    }

    .MuiStepLabel-labelContainer {
        font-family: 'Proxima Nova';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.5rem;
        text-transform: capitalize;

        .MuiStepLabel-label {
            color: #637191;
            font-weight: 400;

            &.MuiStepLabel-active,
            &.MuiStepLabel-completed {
                color: #233251;
                font-weight: 400;
            }
        }
    }
`;

export const CustomStepper = ({ activeStep, steps }) => {
    return (
        <StyledStepper activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label: string) => {
                return (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                );
            })}
        </StyledStepper>
    );
};

export default CustomStepper;
