import { withStyles } from '@material-ui/core/styles';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    FormHelperText,
} from '@material-ui/core';
import styled from 'styled-components';

export const StyledHelperText = styled.span`
    color: #2f426c8c;
    padding-top: 10px;

    &.fontSmall {
        font-size: 12px;
    }
`;

export const StyledFormHelperText = styled(FormHelperText)`
    color: #2f426c8c;

    &.fontSmall {
        font-size: 12px;
    }
`;

export const Accordion = withStyles({
    root: {
        border: '1px solid #F6F8FC',
        '&:hover': {
            borderColor: '#354B9B40',
        },
        background: '#F6F8FC',
        borderRadius: '4px',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 42,
        maxHeight: 42,
        '&$expanded': {
            minHeight: 42,
            maxHeight: 42,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        '& .toggle-field': {
            height: '80px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
})(MuiAccordionDetails);
