import { useAskMe } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { Status } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import useLocalStorage from '../data-integrations-header/use-local-storage';

export const useHasUndismissedFailedScan: () => [boolean, () => void] = () => {
    const { askMeIntegrationsData } = useAskMe();
    const [lastDismissTimestamp, setLastDismissTimestamp] = useLocalStorage('general-error-dismiss-timestamp');

    if (!askMeIntegrationsData.data?.length) {
        return [false, () => {}];
    }

    const lastFailedSourceTimestamp = Math.max(
        ...askMeIntegrationsData.data
            .filter((source) => source.jobStatus.status === Status.Failed && source.lastCrawl)
            .map((source) => new Date(source.lastCrawl).getTime())
    );

    return [
        lastFailedSourceTimestamp > (Number(lastDismissTimestamp) || 0),
        () => setLastDismissTimestamp(new Date().getTime().toString()),
    ];
};
