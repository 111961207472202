import React, { useCallback, useEffect } from 'react';
import AiUsagePage from '../../ai-usage-page';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import useAiSettings from '../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/AiHubDashboard/hooks/useAiSettings';
import { WMCircularProgress, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import styledComponents from 'styled-components';
import { initialAiFilterState, setDateRangeFilter } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

export const Container = styledComponents.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const AiUsageWrapper = () => {
    const [showError, setShowError] = React.useState(false);

    const dispatch = useDispatch();
    const { systemsAppData } = useSystems();
    const { aiSettingsData } = useAiSettings();

    useEffect(() => {
        return () => {
            dispatch(
                setDateRangeFilter({
                    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
                    toDate: moment().endOf('month').format('YYYY-MM-DD'),
                })
            );
        };
    }, [dispatch]);

    const isLoading = useCallback(() => {
        return systemsAppData.loading || aiSettingsData.loading;
    }, [systemsAppData.loading, aiSettingsData.loading]);

    const isError = useCallback(() => {
        return systemsAppData.error || aiSettingsData.error;
    }, [systemsAppData.error, aiSettingsData.error]);

    return (
        <>
            {isLoading() ? (
                <Container>
                    <WMCircularProgress />
                </Container>
            ) : isError() ? (
                <WMSnackbar
                    style={{ marginTop: '60px' }}
                    open={true}
                    variant={WMSnackbarVariant.Error}
                    message={'Unexpected error occurred, please try again'}
                />
            ) : (
                <AiUsagePage />
            )}
        </>
    );
};

export default AiUsageWrapper;
