import React from 'react';
import styles from '../../../../../common/style.module.scss';
import { getToolTip } from '../../../../../common/services';
import WhiteListIp from './white-list-ip';
import styled from 'styled-components';
import { useForm } from 'react-final-form';

const tooltips = {
    ControlWhiteListIps: 'Control white list ips',
};

export const FieldHeader = styled.span`
    font-weight: 600;
    font-size: 16px;
    display: flex;
    padding-bottom: 12px;
    color: #2f426c;
`;

export const Security = ({}) => {
    const formApi = useForm();

    return (
        <div style={{ padding: '24px 32px' }} className={styles.flexColumn}>
            <div className={`${styles.flexColumn}`} style={{ padding: '12px 0' }}>
                <div className={`${styles.flexRow} ${styles.center}`} style={{ height: '24px' }}>
                    <FieldHeader>
                        White list Ip׳s
                        {getToolTip(tooltips.ControlWhiteListIps)}
                    </FieldHeader>
                </div>
                <div style={{ padding: '10px 0 0 0' }} className={styles.flexColumn}>
                    <WhiteListIp />
                </div>
            </div>
        </div>
    );
};

export default Security;
