import React, { SVGProps } from 'react';

export const DocumentIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
        <path
            d='M3.5 3C3.5 2.17157 4.17157 1.5 5 1.5H13.1716C13.5694 1.5 13.9509 1.65804 14.2322 1.93934L16.0607 3.76777L16.4142 3.41421L16.0607 3.76777C16.342 4.04907 16.5 4.4306 16.5 4.82843V17C16.5 17.8284 15.8284 18.5 15 18.5H5C4.17157 18.5 3.5 17.8284 3.5 17V3Z'
            stroke='#6D81A6'
        />
        <rect x='7.25' y='9.25' width='5.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
        <rect x='7.25' y='13.25' width='5.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
        <rect x='7.25' y='5.25' width='3.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
    </svg>
);
