import React from 'react';
import styled from 'styled-components';

const WarningIconContainer = styled.div`
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
`;

export const WarningIcon = () => {
    return (
        <WarningIconContainer>
            <img
                src='/assets/icons/data-integrations/integrations-icons/warning-triangle.svg'
                style={{ width: '1.25rem', height: '1.25rem', position: 'absolute' }}
            />
            <img
                src='/assets/icons/data-integrations/integrations-icons/exclamation-mark.svg'
                style={{ width: '1.25rem', height: '1.25rem', position: 'absolute' }}
            />
        </WarningIconContainer>
    );
};
