import { Route, Link, BrowserRouter as Router, Navigate, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { StyledPageTitle } from '@walkme-admin-center/libs/shared-styles';
import { StyledTitleDiv, StyledPageTitleLabel, StyledPartnersTabContainer } from './styles/styles';
import React, { useEffect, useState } from 'react';
import { WMSnackbar } from '@walkme/wm-ui';
import { PartnersState } from 'packages/libs/state-management-partners/src/lib/redux/partners.slice';
import { useDispatch, useSelector } from 'react-redux';
import { partnersSlice, useInvites, usePartners } from '@walkme-admin-center/libs/state-management-partners';
import { PageWrapper } from '@walkme-admin-center/libs/common';
import { PartnersTab } from './partners-tab';
import { InvitesTab } from './invites-tab';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { StyledBadgeSpan, StyledTabs, StyledTabsRow } from 'packages/pages/home/systems/src/lib/systems.styles';

const validTabNames = ['manage', 'invites'];

export const Partners = () => {
    const { t } = useTranslation('general');
    const { invitesAppData } = useInvites();
    const { partnersAppData } = usePartners();
    const currentSnackbarMessage = useSelector((state: { partnersState: PartnersState }) => state.partnersState.notificationMessage);
    const { tabName } = useParams<{ tabName: string }>();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(partnersSlice.actions.cleanUpNotificationMessage());
    };

    useEffect(() => {
        document.title = t('partners-tab.document-title');
    }, []);

    useEffect(() => {
        if (!validTabNames.includes(tabName)) {
            navigate('/partners/manage');
        }
    }, [tabName, navigate]);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (newValue: number) => {
        if (activeTab == newValue) return;
        setActiveTab(newValue);
        if (newValue === 0) {
            navigate({
                pathname: `/partners/manage`,
            });
        } else {
            navigate({
                pathname: `/partners/invites`,
            });
        }
    };

    return (
        <StyledPartnersTabContainer>
            <div className={'contentDiv'}>
                <StyledTitleDiv>
                    <StyledPageTitle>{t('partners-tab.title')}</StyledPageTitle>
                    <StyledPageTitleLabel>{t('partners-tab.description')}</StyledPageTitleLabel>
                </StyledTitleDiv>
                <WMSnackbar
                    open={currentSnackbarMessage.isOpen}
                    variant={currentSnackbarMessage.variant}
                    message={currentSnackbarMessage.text}
                    onClose={handleSnackBarClose}
                />
                <StyledTabsRow>
                    <StyledTabs
                        initialValue={activeTab}
                        onChange={(tabItem) => handleTabChange(Number(tabItem.value))}
                        items={[
                            {
                                content: <PartnersTab pageData={partnersAppData.data} loadingData={partnersAppData.loading} />,
                                label: t('partners-tab.partners-sub-tab.title'),
                                icon: <StyledBadgeSpan active={activeTab === 0}>{partnersAppData.data.length}</StyledBadgeSpan>,
                                iconPosition: 'end',
                                value: '0',
                            },
                            {
                                content: <InvitesTab pageData={invitesAppData.data} loadingData={invitesAppData.loading} />,
                                label: t('partners-tab.invites-sub-tab.title'),
                                icon: <StyledBadgeSpan active={activeTab === 1}>{invitesAppData.data.length}</StyledBadgeSpan>,
                                iconPosition: 'end',
                                value: '1',
                            },
                        ]}
                    />
                </StyledTabsRow>
            </div>
        </StyledPartnersTabContainer>
    );
};
