import React from 'react';
import { BoxSubHeader, StyledBox, BoxHeader } from './building-settings.styles';
import { useTranslation } from '../../../../../../../apps/home/src/localization';
import { WMSwitch } from '@walkme/wm-ui';

export const ThemingEnablement = ({ isEnabled, handleToggle, themingConfiguration }) => {
    const { t } = useTranslation('general');

    return (
        <StyledBox>
            <div className='top-area'>
                <div className='top-area-internal'>
                    <BoxHeader>
                        <div className='main-title'>
                            {t('systems-tab.new-system-form.configure-step.building-settings.theming-enablement.main-title')}
                        </div>
                    </BoxHeader>
                    <BoxSubHeader>
                        {t('systems-tab.new-system-form.configure-step.building-settings.theming-enablement.description')}
                    </BoxSubHeader>
                </div>
                <div className='center-area'>
                    <WMSwitch disabled={themingConfiguration} checked={isEnabled} onChange={handleToggle} />
                </div>
            </div>
        </StyledBox>
    );
};
