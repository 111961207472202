import React, { memo } from 'react';
import styled from 'styled-components';

import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

const { colors } = styledTheme;

const StyledCheckbox = styled(MuiCheckbox)`
    color: ${colors.mainGray};

    &.Mui-checked {
        color: ${colors.mainBlue};
    }
`;

export const Checkbox = memo(({ checkedIcon, ...rest }: CheckboxProps) => {
    return <StyledCheckbox checkedIcon={checkedIcon || <CheckBoxOutlined />} {...rest} />;
});

export default Checkbox;
