import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WMButtonVariant } from '@walkme/wm-ui';
import { StyledDeletionDialog } from './styles/styles';
import { PartnersState, deleteAccount } from 'packages/libs/state-management-partners/src/lib/redux/partners.slice';
import { Typography } from '@material-ui/core';

export interface DeleteAccountProps {
    accountIdToDelete: number;
    onCancel?: () => void;
    onFinish: () => void;
    showDialog?: boolean;
}

export const DeleteAccount = ({ accountIdToDelete, showDialog, onCancel, onFinish }: DeleteAccountProps) => {
    const dispatch = useDispatch();
    const [isFirstRender, setIsFirstRender] = useState(true);

    const deleteAccountData = useSelector((state: { partnersState: PartnersState }) => state.partnersState.deleteAccount);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else if (!deleteAccountData.loading) {
            onFinish();
        }
    }, [deleteAccountData]);

    const buttons = [
        {
            children: 'Cancel',
            onClick: () => onCancel(),
            variant: WMButtonVariant.Text,
            style: { marginRight: '30px', color: '#637191' },
        },
        {
            children: 'Delete',
            onClick: () => deleteAccount(dispatch, accountIdToDelete),
            className: 'DeleteItemsButton',
        },
    ];

    return (
        <div>
            <StyledDeletionDialog
                ds2
                disableHeaderDivider={true}
                open={showDialog}
                onClose={() => onCancel()}
                title={'Delete this account?'}
                buttons={buttons}>
                <Typography className={'DeletionDialogText'}>
                    All the associated users will be removed and will lose access to the systems they are assigned to. This action is
                    irreversible.
                </Typography>
            </StyledDeletionDialog>
        </div>
    );
};

export default DeleteAccount;
