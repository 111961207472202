import styled from 'styled-components';

export const StyledSubtext = styled.span`
    color: #7680a3;

    .link {
        color: #3b61eb;
        cursor: pointer;
    }
`;
