import React from 'react';
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

export const IconError = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets---Desktop-Systems" transform="translate(-1201.000000, -274.000000)" fill={props.htmlColor || props.color  || '#E43737'} fill-rule="nonzero">
          <g id="icon-error" transform="translate(1201.500000, 274.000000)">
            <path d="M11.9272727,3.11383995 C12.5974304,3.11383995 13.2146472,3.47802139 13.5416508,4.07006036 L13.5416508,4.07006036 L20.6296682,17.1556818 C20.9444967,17.7259143 20.9345492,18.4200657 20.6035098,18.9810425 C20.2724544,19.5420465 19.6695748,19.8863636 19.0181818,19.8863636 L19.0181818,19.8863636 L4.83636364,19.8863636 C4.18497061,19.8863636 3.58209102,19.5420465 3.25103563,18.9810425 C2.91999627,18.4200657 2.91004871,17.7259143 3.22198557,17.1609694 L3.22198557,17.1609694 L10.3158636,4.06463275 C10.6398983,3.47802139 11.257115,3.11383995 11.9272727,3.11383995 Z M11.9272727,4.61383995 C11.8031695,4.61383995 11.6888701,4.68128096 11.6318326,4.78448508 L11.6318326,4.78448508 L4.53803185,17.8806818 C4.47973026,17.9862804 4.4815724,18.114827 4.54287599,18.2187116 C4.60418921,18.3226125 4.71581379,18.3863636 4.83636364,18.3863636 L4.83636364,18.3863636 L19.0181818,18.3863636 C19.1387317,18.3863636 19.2503563,18.3226125 19.3116695,18.2187116 C19.3729731,18.114827 19.3748152,17.9862804 19.3136219,17.8753942 L19.3136219,17.8753942 L12.2256818,4.78991269 C12.1656754,4.68128096 12.051376,4.61383995 11.9272727,4.61383995 Z M11.9272727,15.1136364 C12.4754866,15.1136364 12.9229565,15.5449726 12.9488192,16.086782 C12.9494619,16.1033252 12.95,16.1197816 12.95,16.1363636 C12.95,16.6635445 12.5511264,17.0975635 12.0387101,17.1530897 L11.9272727,17.1590909 L11.9272727,17.1590909 C11.3624361,17.1590909 10.9045455,16.7012003 10.9045455,16.1363636 L10.9071728,16.08684 L10.9105467,16.0249262 C10.9660729,15.5125099 11.4000918,15.1136364 11.9272727,15.1136364 Z M11.9272727,8.02272727 C12.3069685,8.02272727 12.6207637,8.30488115 12.6704261,8.67095671 L12.6772727,8.77272727 L12.6772727,13.6818182 C12.6772727,14.0960317 12.3414863,14.4318182 11.9272727,14.4318182 C11.547577,14.4318182 11.2337818,14.1496643 11.1841193,13.7835887 L11.1772727,13.6818182 L11.1772727,8.77272727 C11.1772727,8.3585137 11.5130592,8.02272727 11.9272727,8.02272727 Z" id="Combined-Shape"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
