import styled from 'styled-components';
import { WMDataGrid } from '@walkme/wm-ui';
import { Button, Tag, Typography } from '@walkme/ui-core';

export const StyledWMDataGrid = styled(WMDataGrid)`
    &.MuiPaper-root,
    &.WMDataGrid-wmDataGrid,
    &.MuiPaper-elevation0,
    &.MuiPaper-rounded {
        width: unset !important;
        height: 100% !important;
        border-radius: 12px 12px 8px 8px;
    }

    margin: 0 32px;
    overflow: hidden !important;

    &::-webkit-scrollbar {
        display: none;
    }

    .ag-root-wrapper.ag-ltr.ag-layout-auto-height {
        overflow: hidden !important;
        height: 100% !important;
    }

    .ag-body-viewport.ag-row-animation.ag-layout-auto-height.ag-selectable {
        overflow: auto;
    }

    &.MuiPaper-root.WMDataGrid-wmDataGrid.MuiPaper-elevation0.MuiPaper-rounded .WMDataGridActionDialog-wmDataGridActionDialog {
        background: var(--Color-Semantic-Background-bg-accent, #385feb);
        border-radius: 8px;
        box-shadow: 0px 16px 24px 0px rgba(44, 73, 176, 0.25);

        .WMDataGridActionDialog-textContainer span {
            color: white;
        }
    }

    .WMDataGridHeader-wmDataGridHeader {
        background-color: var(--Primaries-Background-Background-tint-1, #f0f3f9);
        padding: 24px 0 12px 0;
    }

    .WMSearchFilter-wmSearchFilter.WMSearchFilter-medium {
        background-color: var(--White, #fff);
    }

    & .WMDataGridActionDialog-spacer {
        background: var(--Color-Semantic-Elements-el-divider-inverted, rgba(255, 255, 255, 0.15)) !important;
    }

    & .WMDataGridActionDialog-cancelButton {
        color: var(--Color-Semantic-Action-Primary-content-primary-default, #fff);
    }

    & .ag-header.ag-pivot-off {
        border-radius: 8px 8px 0 0;
    }

    & .ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height {
        background-color: var(--Primaries-Background-Background-tint-1, #f0f3f9);
        height: 100%;
    }

    & .ag-theme-wm-ui.bottom-scroll-shadow.hide-column-menu-tabs {
        height: 100% !important;
        overflow: hidden;
    }

    & .ag-body-viewport.ag-row-animation.ag-layout-auto-height.ag-selectable {
        background-color: #fff;
        scrollbar-width: unset !important;
        scrollbar-color: unset !important;
    }

    .ag-theme-wm-ui .ag-body-viewport,
    .ag-theme-wm-ui .ag-center-cols-viewport {
        cursor: pointer;
        scrollbar-color: unset !important;
        scrollbar-width: unset !important;
    }

    .ag-overlay .ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper .ag-react-container {
        height: calc(100% + 100px);
    }

    .WMDataGridActionDialog-wmDataGridActionDialog {
        min-width: unset;
        max-width: unset;
        width: 85%;
    }

    .disabled-checkbox {
         background-color: var(--Primaries-Typography-Typography-tint-8, #f5f6f8) !important;
    }

    /* Footer actions */

    .footer-assign-button {
        height: var(--size-global-s-6032, 32px) !important;
        min-width: 72px;
        padding: 0px 16px !important;
        background: var(--Color-Semantic-Action-Primary-content-primary-default, #fff) !important;
        border-radius: 40px !important;

        & .MuiButton-label {
            color: var(--Color-Semantic-Action-Primary-bg-primary-default, #385feb) !important;
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 8px;
        }

        & .WMDataGridActionDialog-actionIcon {
            margin-right: 0 !important;
        }
    }
`;

export const StyledTag = styled(Tag)`
    border-radius: 4px;
`;

export const StyledStatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const StatusDot = styled.span<{ assigned: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 4px;
    width: var(--size-global-s-58, 8px);
    height: var(--size-global-s-58, 8px);
    border-radius: 50%;
    background-color: ${(props) =>
        props.assigned ? 'var(--Success-Success-Main, #41B7A6)' : 'var(--Secondary-Secondary-shade-1, #A6ACB8)'};
`;

export const StyledButton = styled(Button)`
    &:disabled {
        border: 1px solid var(--Color-Semantic-Action-Secondary-stroke-secondary-disable, #d9d9d9);
        color: var(--Color-Semantic-Action-Secondary-content-secondary-disable, #767676);
        background-color: transparent;
    }
`;

export const CreatorTag = styled(Typography)`
    border-radius: 99px;
    border: 1px solid var(--Blue-Primary-Primary-Main, #3b61eb);
    background: var(--Blue-Primary-Primary-tint-7, #edf1fd);
    padding: 2px 6px;
    height: 20px;
    color: var(--Blue-Primary-Primary-Main, #3b61eb);
    display: flex;
    align-items: center;
`;

export const DataGridWrapper = styled.div`
    height: calc(100% - 12px);
    padding-bottom: 12px;
`;
