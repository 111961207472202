import { Box, Divider, Drawer, makeStyles, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';
import { WMButton } from '@walkme/wm-ui';

export const StyledTabBoxes = styled(Box)`
    margin-bottom: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const StyledTabContent = styled.div`
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const StyledTab = styled(Tab)`
    min-width: 140px;
    text-transform: none;
    font-family: Proxima Nova;
    font-weight: 400;
    font-size: 14px;
    color: #2f426c;
`;

export const StyledTabs = styled(Tabs)`
    .styled-indicator {
        backgroundcolor: #3b61eb;
    }
`;

export const StyledDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        width: 480px;
    }
    & .MuiBackdrop-root {
        background: rgba(47, 66, 108, 0.61);
    }
`;

export const DrawerHeaderContainer = styled.div`
    height: 130px;
    min-height: 130px;
`;

export const DrawerHeaderStyled = styled.div`
    margin: 24px 32px 16px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const StyledDivider = styled(Divider)`
    margin: 20px 32px 32px;
`;

export const DrawerContentContainer = styled.div`
    height: 100%;
    overflow: auto;
    flex: 1;
`;

export const DrawerContentStyled = styled.div`
    margin: 0px 32px 0 32px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const DrawerButtonsContainer = styled.div`
    height: 88px;
    min-height: 88px;
`;

export const DrawerButtonsStyled = styled.div`
    margin: 16px 32px 32px 32px;
    justify-content: flex-end;
    display: flex;
`;

export const StyledWMButton = styled(WMButton)`
    margin-left: 16px;
    width: 80px;
    height: 40px;
    border-radius: 40px;
`;
