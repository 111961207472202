import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationsState, getAccountServiceApps, getAllAvailableScopes } from '../redux/applications.slice';

export const useAccountServiceApps = () => {
    const accountServiceApps = useSelector((state: { applicationsSlice: ApplicationsState }) => state.applicationsSlice.accountServiceApps);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAccountServiceApps());
    }, [dispatch]);

    return {
        accountServiceApps,
    };
};

export default useAccountServiceApps;
