import React from 'react';
import { CustomSwitches, CodeFragment, StyledTextField, StyledMUITextField } from '../../common/style';

export const sharedFields = {
    idpName: {
        type: 'text',
        title: 'IDP Name',
        helper: 'Enter the name of your IDP',
        requireBeforeUpdate: false,
        name: 'name',
        component: <StyledTextField name='name' variant='outlined' placeholder={'Enter the IDP name'} />,
    },
    clientId: {
        type: 'text',
        title: 'Client ID',
        helper: 'The client id is a public identifier for apps',
        requireBeforeUpdate: true,
        name: 'config.clientId',
        component: <StyledTextField name='config.clientId' autoComplete='off' variant='outlined' placeholder={'Enter the Client ID'} />,
    },
    directoryId: {
        type: 'text',
        title: 'Directory ID (tenant)',
        helper: 'Enter tenant identifier that will be used to control who can sign into the application',
        requireBeforeUpdate: true,
        name: 'config.directoryId',
        component: <StyledTextField name='config.directoryId' variant='outlined' placeholder={'Enter the Directory ID'} />,
    },
    clientSecret: {
        type: 'text',
        title: 'Client Secret',
        helper: 'The client secret is a secret known only to the application and the authorization server',
        requireBeforeUpdate: true,
        name: 'config.clientSecret',
        component: (
            <StyledTextField
                name='config.clientSecret'
                autoComplete='new-password'
                variant='outlined'
                type={'password'}
                placeholder={'Enter the Client Secret'}
            />
        ),
    },
    idpDomain: {
        type: 'text',
        title: 'IDP Provider Domain',
        helper: 'Enter the domain of your organization',
        requireBeforeUpdate: true,
        name: 'config.domain',
        component: <StyledTextField name='config.domain' variant='outlined' placeholder={'your-company.idp-provider.com'} />,
    },
    domainHint: {
        type: 'text',
        title: 'Domain Hint (domain_hint)',
        helper: 'Domain hints can be used to accelerate the user to federated IdP sign-in page',
        requireBeforeUpdate: false,
        name: 'config.domainHint',
        component: (
            <StyledMUITextField
                name='config.domainHint'
                variant='outlined'
                placeholder={'Enter the domain hint'}
                fieldProps={{ allowNull: true, parse: (value) => (!value ? '' : value) }}
            />
        ),
    },
    idpDiscoveryUrl: {
        type: 'text',
        title: 'IDP Provider Discovery URL',
        helper: 'Enter the Discovery URL of your application',
        requireBeforeUpdate: true,
        name: 'config.discoveryEndpoint',
        component: (
            <StyledTextField
                name='config.discoveryEndpoint'
                variant='outlined'
                placeholder={'https://auth.idp-provider.com/oidc/2/.well-known/openid-configuration'}
            />
        ),
    },
    scope: {
        type: 'text',
        title: 'IDP Provider Scope',
        helper:
            'Add additional scopes for OpenID Connect authorization.' +
            '\nSeparate multiple values by commas (without spaces). Example: params,optional',
        requireBeforeUpdate: true,
        name: 'config.scope',
        component: (
            <StyledMUITextField
                name='config.scope'
                variant='outlined'
                placeholder={'Enter comma separated values (without spaces)'}
                fieldProps={{ allowNull: true, parse: (value) => (!value ? '' : value) }}
            />
        ),
    },
    cspDomains: {
        type: 'text',
        title: 'Content Security Policy',
        helper:
            'Content Security Policy (CSP) is an added layer of security that helps to detect and mitigate certain types of attacks, including Cross Site Scripting (XSS) and data injection attacks.' +
            '\nFor example: login.pingone.com,apps.pingone.com (without space after comma)' +
            '\nEnter domains for Content Security Policy. Authorisation endpoint domain added automatically.',
        requireBeforeUpdate: false,
        name: 'config.cspDomains',
        component: (
            <StyledMUITextField
                name='config.cspDomains'
                variant='outlined'
                placeholder={'Enter CSP domains, comma separated'}
                fieldProps={{ allowNull: true, parse: (value) => (!value ? '' : value) }}
            />
        ),
    },
    providerName: {
        type: 'text',
        title: 'Your IDP Provider',
        helper: 'Provider which you are using: G-suite, OneLogin, PingOne, Okta',
        requireBeforeUpdate: false,
        name: 'config.providerName',
        component: (
            <StyledMUITextField
                name='config.providerName'
                variant='outlined'
                placeholder={'Enter IDP provider'}
                fieldProps={{ allowNull: true, parse: (value) => (!value ? '' : value) }}
            />
        ),
    },
    samlCertificate: {
        type: 'text',
        title: 'X509 Signing Certificate (base64)',
        helper: (
            <React.Fragment>
                <p>The client Certificate is a secret known only to the application and the authorization server.</p>
                <p>The value should look like:</p>
                <CodeFragment>
                    -----BEGIN CERTIFICATE-----
                    <br />
                    xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    <br />
                    xxxxxxxxxxxxxxxx CERTIFICATE VALUE xxxxxxxxxxxxxxxx
                    <br />
                    xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    <br />
                    ...
                    <br />
                    -----END CERTIFICATE-----
                </CodeFragment>
            </React.Fragment>
        ),
        requireBeforeUpdate: true,
        name: 'config.clientSecret',
        component: (
            <StyledTextField
                name='config.clientSecret'
                autoComplete='new-password'
                variant='outlined'
                type={'password'}
                placeholder={'Enter the Certificate'}
            />
        ),
    },
    samlSingleSignOnURL: {
        type: 'text',
        title: 'Single Sign-On URL',
        helper: 'Enter the single sign-on URL',
        requireBeforeUpdate: true,
        name: 'config.domain',
        component: <StyledTextField name='config.domain' variant='outlined' placeholder={'your-company.idp-provider.com'} />,
    },
    signAuthRequest: {
        type: 'toggle',
        requireBeforeUpdate: true,
        name: 'config.signAuthRequest',
    },
    encryptAssertion: {
        type: 'toggle',
        requireBeforeUpdate: true,
        name: 'config.encryptAssertion',
    },
    pullGroups: {
        type: 'toggle',
        title: 'Microsoft Entra ID Groups',
        helper: 'Pull user groups information from provider',
        requireBeforeUpdate: true,
        name: 'config.pullGroups',
        component: <CustomSwitches disabled={false} color={'primary'} name='config.pullGroups' data={{ label: '', value: null }} />,
    },
    useIdTokenForProperties: {
        type: 'toggle',
        title: 'Use ID Token For Getting End-Users Properties',
        helper: 'Get properties from ID Token',
        requireBeforeUpdate: true,
        name: 'config.useIdTokenForProperties',
        component: (
            <CustomSwitches disabled={false} color={'primary'} name='config.useIdTokenForProperties' data={{ label: '', value: null }} />
        ),
    },
    enforceSSOIframeFlow: {
        type: 'toggle',
        title: 'Seamless login on hidden iframe',
        helper: 'Open SSO authorization URL in hidden iframe (to support PRT tokens for example)',
        requireBeforeUpdate: false,
        name: 'config.enforceSSOIframeFlow',
        component: (
            <CustomSwitches disabled={false} color={'primary'} name='config.enforceSSOIframeFlow' data={{ label: '', value: null }} />
        ),
    },
};
