import Link from '@material-ui/core/Link/Link';
import { LinkIcon } from '../icons/link.icon';
import React from 'react';

export function AnalyticsLink(props: { onClick: () => void }) {
    return (
        <Link
            onClick={props.onClick}
            href={`${window.clientConfig.NX_ANALYTICS_REDIRECT}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{
                fontFamily: 'Proxima nova',
                color: '#385FEB',
                fontSize: '14px',
                fontWeight: 400,
                marginTop: '20px',
                cursor: 'pointer',
            }}>
            Go to existing self-hosting page <LinkIcon />
        </Link>
    );
}
