import React from 'react';
import {
  Provider,
  ProviderGroups, Certificate, ProviderGroup,
} from '@walkme-admin-center/libs/state-management-sso-configuration';
import { Divider, FormGroup } from '@material-ui/core';
import EncryptionSettings from './encryption-settings/encryption-settings';
import { CONSTS } from '../../common/consts';
import { HelperIcon, StepSectionHeaderContainer, StyledInputLabel } from '../../common/style';
import CustomToolTip from '../../common/components/tool-tip/tool-tip';
import { FieldBlock, IdpIntegrationContentContainer, ToggleContainer } from './Idp-integration-step-style';
import ProviderFormProtocolAndType from './provider-form-protocol-and-type/provider-form-protocol-and-type';

export interface IdpIntegrationProps {
  provider?: Provider;
  providerGroups: ProviderGroups;
  idpType: ProviderGroup;
  providerInstance: any;
  certificate: Certificate;
  setCertificate: (value) => void;
  handleIdpTypeChange: (type) => void;
}

export const IdpIntegrationStep = ({
                               provider,
                               providerGroups,
                               idpType,
                               providerInstance,
                               handleIdpTypeChange,
                               certificate,
                               setCertificate,
                             }: IdpIntegrationProps) => {

  const allFields = providerInstance.getFormFields();

  const idpTypeChange = (type) => {
    handleIdpTypeChange(type);
  };

  const updateCertificate = (newCertificate: Certificate) => {
    setCertificate(newCertificate);
  };

  return (
    <>
      <StepSectionHeaderContainer>
        <span className={'title'}>IDP Integration</span>
        <span
          className={'title-helper'}>Select with which systems you want to perform the current IDP integration.</span>
      </StepSectionHeaderContainer>
      <IdpIntegrationContentContainer>
        <div style={{ flex: 1 }}>
          <ProviderFormProtocolAndType providerGroups={providerGroups} provider={provider} idpType={idpType}
                                       setIdpType={idpTypeChange} />
          {providerInstance.getIdpInstructions()}
          <Divider className={'divider'} component='div'/>
          <FormGroup>
            <div className={'idp-integration-form-group'}>
              {allFields.map((field, index) => {
                if (field.component) return (<React.Fragment key={index}>
                  <FieldBlock key={index}>
                    {field.type === 'text' && <>
                      <StyledInputLabel className={'flex-center'}>
                        {field.title}
                        <CustomToolTip title={field.helper}>
                          <HelperIcon>?</HelperIcon>
                        </CustomToolTip>
                      </StyledInputLabel>
                      {field.component}
                    </>}
                    {field.type === 'toggle' && <ToggleContainer>
                      <div className={'toggle-content'}>
                        <StyledInputLabel>
                          {field.title}
                        </StyledInputLabel>
                        {field.component}
                      </div>
                      <span className={'toggle-helper'}>{field.helper}</span>
                    </ToggleContainer>}
                  </FieldBlock>
                </React.Fragment>);
              })
              }
              {providerInstance.getProtocol() === CONSTS.SAML &&
              <EncryptionSettings provider={provider}
                                  certificate={certificate}
                                  idpType={idpType}
                                  updateCertificate={updateCertificate}
              />
              }
            </div>
          </FormGroup>
        </div>
      </IdpIntegrationContentContainer>
    </>
  );
};

export default IdpIntegrationStep;
