import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ManifestTemplate } from './manifest-template';

export const downloadZip = async (guid: string) => {
    const zip = new JSZip();
    try {
        const [colorImage, outlineImage] = await Promise.all([
            fetch(`/assets/icons/data-integrations/integration-images/color.png`).then((res) => res.blob()),
            fetch(`/assets/icons/data-integrations/integration-images/outline.png`).then((res) => res.blob()),
        ]);
        zip.file('color.png', colorImage);
        zip.file('outline.png', outlineImage);

        const manifest = new ManifestTemplate(guid).getTemplate();
        const manifestString = JSON.stringify(manifest, null, 2);
        zip.file('manifest.json', manifestString);

        const blob = await zip.generateAsync({ type: 'blob' });

        saveAs(blob, 'manifest.zip');
    } catch (error) {
        throw new Error('Failed to download zip');
    }
};
