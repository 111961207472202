import { useSelector } from 'react-redux';
import { SsoConfigurationState } from '../redux/sso-configuration.slice';

export const useProviders= () => {
  const providersData = useSelector((state: { ssoConfigurationState: SsoConfigurationState }) => state.ssoConfigurationState.providers);

  return {
    providersData,
  };
};

export default useProviders;
