import styled from 'styled-components';
import { Select, withStyles } from '@material-ui/core';

export const StyledEndUserIdentifierContainer = styled.div`
    display: flex;
    flex-direction: column;
    &.alignItemsCenter {
        align-items: center;
    }

    .helperText {
        color: #2f426c8c;

        &.fontSmall {
            font-size: 12px;
        }
    }
`;

export const CssSelect = withStyles({
    root: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
})(Select);

export const StyledSelect = styled(Select)`
    .root{
        padding:12px 0;
    }

    .selectMenuItem{
    & .MuiMenu-paper{
        max-height: 250px;
        margin-top: 8px;
        border-radius: 10px;
        box-shadow: 0px 10px 25px #121B4E1C;
    },
    &.one-id-select .MuiMenu-paper{
        max-height: 144px;
    },
    }
`;

export const StyledPlaceholder = styled.span`
    color: #2f426c;
    opacity: 0.5;
`;
