import { makeStyles } from '@material-ui/core';
import { styledScrollCss } from '../styledScroll';

const useStyles = makeStyles(() => ({
    urlsTopBar: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    urlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flex: 1,
        ...styledScrollCss,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        flex: 1,
    },
}));

export { useStyles };
