import React, { useState } from 'react';
import {
    ActionItem,
    ActionListContainer,
    DeleteHeaderText,
    DeleteModalContainer,
    DeleteModalHeader,
    DeleteModalToastContainer,
    DeleteToast,
    DeleteWarningText,
    ModalToastBody,
} from './integration-style';
import { CancelButton, DeleteButton, WarningFooter, WarningText } from './integrations-forms/warning-modal-style';
import { CloseButtonContainer } from './integrations-forms/modal-style';
import { CircularProgress } from '@material-ui/core';
import { deleteIntegration } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { useDispatch } from 'react-redux';
import { CONSTS } from 'packages/pages/home/sso-configuration/src/lib/common/consts';
import { CloseIcon } from '../../../common/consts';

const WarningIcon = 'assets/icons/data-integrations/small-warning-icon.svg';

interface DeleteWarningProps {
    integrationName: string;
    integrationGuid: string;
    closeWarningModal: () => void;
    showSnackBar: (snackBar: any) => void;
}

const DeleteWarning = ({ integrationName, integrationGuid, closeWarningModal, showSnackBar }: DeleteWarningProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseWarning = async () => {
        try {
            await dispatch(deleteIntegration(integrationGuid));
            closeWarningModal();
            showSnackBar({
                open: true,
                messageText: `${integrationName} Integration was deleted successfully.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
            setIsLoading(false);
        } catch (error) {
            showSnackBar({
                open: true,
                messageText: `Error deleteing ${integrationName} Integration.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    };

    const handleDelete = async () => {
        setIsLoading(true);
        await handleCloseWarning();
    };

    return (
        <DeleteModalContainer>
            <DeleteModalHeader>
                <DeleteHeaderText>Delete {integrationName} Integration ?</DeleteHeaderText>
                <CloseButtonContainer onClick={closeWarningModal}>
                    <img src={CloseIcon} alt='Close' style={{ height: '12px', width: '12px', padding: '4px' }} />
                </CloseButtonContainer>
            </DeleteModalHeader>
            <DeleteModalToastContainer>
                <DeleteToast>
                    <ModalToastBody>
                        <img src={WarningIcon} alt='Warning' />
                        <WarningText>
                            <div>Please Note!</div>
                            <div>Once the integration is deleted the below actions will occur</div>
                        </WarningText>
                    </ModalToastBody>
                </DeleteToast>
            </DeleteModalToastContainer>
            <DeleteWarningText>Deleting this integration will immediately:</DeleteWarningText>
            <ActionListContainer>
                <div style={{ height: '80px', width: '479px' }}>
                    <ul className={'action-items-list'}>
                        <ActionItem>Disconnect all {integrationName} channels from knowledge sources</ActionItem>
                        <ActionItem>Disconnect integration setup</ActionItem>
                    </ul>
                </div>
            </ActionListContainer>
            <WarningFooter>
                <CancelButton onClick={closeWarningModal}>Cancel</CancelButton>
                <DeleteButton onClick={handleDelete}>
                    {isLoading ? (
                        <div className={'warning-footer-delete-button'}>
                            <CircularProgress size={16} color='inherit' />
                        </div>
                    ) : (
                        <span>Delete</span>
                    )}
                </DeleteButton>
            </WarningFooter>
        </DeleteModalContainer>
    );
};

export default DeleteWarning;
