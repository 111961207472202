import styled from 'styled-components';
import { WMLoader } from '@walkme/wm-ui';

export const StyledAccessibilityWrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 9.25fr 0.75fr;
`;

export const StyledAccessibilityContainer = styled.div`
    padding: 0 24px;
    background-color: white;
    overflow-y: auto;

    .accessibilityToaster {
        width: unset !important;
        box-shadow: none;
    }
`;

export const StyledAccessibilityTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 32px 0 24px 0;

    .main-title {
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 24px;
        font-weight: 600;
    }

    .sub-title {
        font-size: 14px;

        .action-row {
            display: flex;
            justify-content: space-between;
        }

        .learn-more {
            color: var(--Color-Semantic-Text-secondary, #637191);
            font-weight: 200;
            margin-top: 4px;

            a {
                color: var(--Blue-Primary-Primary-Main, #385feb) !important;
                text-decoration: none;
            }
        }
    }

    .automatic-enable-row {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        font-weight: 400;
    }
`;

export const StyledAccessibilityToasterMessage = styled.div`
    a {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;

export const StyledSaveAndPublish = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 32px;

    .save-and-publish {
        margin-left: 8px;
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    background: white;
    border-radius: 12px;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const StyledLoader = styled(WMLoader)`
    width: 30%;
`;
