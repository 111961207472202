import styled from 'styled-components';
import { StepLabel, Stepper } from '@material-ui/core';

export const StyledStepperContainer = styled.div`
    border-top: 1px solid #ebeff7;
    border-bottom: 1px solid #ebeff7;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    #styled-stepper.MuiPaper-root {
        width: 700px !important;
        max-width: unset !important;
        height: unset !important;
        padding: 12px;
    }
`;

export const StyledStepLabel = styled(StepLabel)`
    .MuiStepLabel-label {
        font-family: 'ProximaNova';
        color: #6d81a6;
        font-size: 14px;
    }
    .MuiStepLabel-label.MuiStepLabel-active {
        font-family: 'ProximaNova';
        color: #2f426c;
        font-size: 14px;
        font-weight: 600;
    }
`;

export const StyledSystemDescription = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledSystemDescriptionText = styled.div`
    line-height: 22.5px;
    font-family: 'ProximaNova';
    .systemType {
        display: flex;
        font-weight: bold;
        color: var(--Typography-Typography, #2f426c);
    }

    .systemUsage {
        color: #6d81a6;
    }
`;

export const StyledAppIconContainer = styled.div`
    width: 40px;
    height: 40px;
    background-color: #e9f1f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 12px;
`;
