import styled from 'styled-components';

export const StyledSideMenu = styled.div`
    grid-column: 1;
    padding: 24px 32px 0 32px;
    border-bottom: 1px solid #ebeff7;
    border-right: 1px solid #ebeff7;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: flex-start;
`;

export const StyledTabName = styled.div<{ active: boolean; isOpen: boolean }>`
    padding: 10px 8px;
    background-color: ${(props) => (props.active && !props.isOpen ? 'var(--Blue-Primary-Primary-tint-7, #EDF1FD)' : '')};
    border-radius: ${(props) => (props.active ? '5px' : '')};
    cursor: pointer;

    .typography {
        color: ${(props) => (props.active && !props.isOpen ? '#385FEB' : '#637191')};
    }
`;
