import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initData } from '../redux/data-integration.slice';

export const useInitData = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initData());
    }, [dispatch]);
};

export default useInitData;
