import React, { useCallback, useState } from 'react';
import { Collapse, InputAdornment } from '@material-ui/core';
import CustomRadioButton from '../../../../../common/components/custom-radio-button';
import { Field, useForm } from 'react-final-form';
import styles from '../../../../../common/style.module.scss';
import { KeyboardArrowDownRounded, Done } from '@material-ui/icons';
import {
    CssSwitch,
    CssTextField,
    FieldLabelDescription,
    FieldLabelLight,
    FieldLabelLightSmall,
    SwitchLabel,
} from '../../../../data-integration-form-style';
import { AuthMethodOptions, SecondsOptions, WeeklyOptions, MonthlyOptions } from '../../../../../common/consts';
import { CustomMenuItem, Spacer } from '../../../../../common/style';
import { AuthType, ScanFrequency } from '../../../../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { useGlobalSettings } from '@walkme-admin-center/libs/state-mangment-data-integration';
import {
    AccordionStyled,
    AccordionSummaryStyled,
    AccordionHeaderStyled,
    SubHeader,
    AccordionDetailsColumn,
    StyledRadioGroup,
    AccordionSummaryContainerStyled,
} from '../assign-systems-step/access-window-style';
import { ErrorBox, InfoBox } from '../../../../../common/components/info-box';
import { DayPicker } from 'react-day-picker';
import { ExclamationIcon } from '../../../../../data-integrations-list/integration-card/icons/icons';
import {
    StyledSelect,
    AccordionSummaryWrapperStyled,
    RadioContextContainerWithSelect,
    RadioContextContainer,
    RadioContainer,
    RadioLabel,
    BootstrapInput,
    RadioSecondaryContainer,
    DetailsContainer,
    AuthDetailsContainer,
    MainContainer,
} from './settings-step-style';

const authTypeDescription = {
    [AuthType.NO_AUTH]: 'This is to crawl a public website',
    [AuthType.BASIC_AUTH]: 'Enter the username and password to access the website you want to crawl.',
    [AuthType.BEARER_TOKEN]: 'Enter the token to access the website you want to crawl.',
    [AuthType.OAUTH2]: 'Enter the access token URL, client ID, client secret, and scope to access the website you want to crawl.',
    [AuthType.FORM_AUTHENTICATION]: 'Enter the username and password to grant access to your knowledge source.',
};

export const SettingsStep = ({ isAuthError = false }: { isAuthError?: boolean }) => {
    const { isAdminMode } = useGlobalSettings();
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showClientSecretField, setShowClientSecretField] = useState(false);
    const [showFormAuthenticationPasswordField, setShowFormAuthenticationPasswordField] = useState(false);
    const [showTokenField, setShowTokenField] = useState(false);
    const [expanded, setExpanded] = useState(isAuthError ? 'authorization' : '');

    const formApi = useForm();
    const { auditScan, delay, controlDelay, authType } = formApi.getState().values.settings;

    const resetAll = (): void => {
        formApi.change('settings.auth', {});
        setShowPasswordField(false);
        setShowClientSecretField(false);
        setShowFormAuthenticationPasswordField(false);
        setShowTokenField(false);
    };

    const getAuthTypeDescription = useCallback(() => {
        return authTypeDescription[authType];
    }, [authType]);

    const showPasswordIcon = (field, setField): React.ReactNode => {
        return (
            <InputAdornment position='end'>
                {field ? (
                    <img
                        src={'assets/icons/data-integrations/show-password-icon-small.svg'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setField(false)}
                    />
                ) : (
                    <img
                        src={'assets/icons/data-integrations/hide-password-icon-small.svg'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setField(true)}
                    />
                )}
            </InputAdornment>
        );
    };

    const authTypeChange = (event) => {
        formApi.change('settings.authType', event.target.value);
        resetAll();
    };

    return (
        <MainContainer>
            <AccordionStyled
                expanded={expanded === 'frequency'}
                onChange={(event, isExpanded) => setExpanded(isExpanded ? 'frequency' : '')}
                bordercolor={expanded === 'frequency' ? '#385FEB' : '#E3E9FC'}>
                <AccordionSummaryStyled
                    expandIcon={
                        <img
                            src={'assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg'}
                            style={{ width: '20px', height: '20px' }}
                        />
                    }>
                    <AccordionSummaryContainerStyled>
                        <AccordionHeaderStyled>Audit Frequency Settings</AccordionHeaderStyled>
                        <SubHeader>Set the frequency for rescanning your website</SubHeader>
                    </AccordionSummaryContainerStyled>
                </AccordionSummaryStyled>
                <AccordionDetailsColumn>
                    <DetailsContainer>
                        <Field name={'settings.auditScan.frequency'} type={'text'}>
                            {({ input: radioInput, meta }) => (
                                <StyledRadioGroup defaultValue={ScanFrequency.NONE} {...radioInput}>
                                    <RadioContainer>
                                        <RadioContextContainerWithSelect>
                                            <RadioContextContainer>
                                                <CustomRadioButton value={ScanFrequency.NONE} />
                                                <RadioLabel>No scheduled rescan</RadioLabel>
                                            </RadioContextContainer>
                                        </RadioContextContainerWithSelect>
                                    </RadioContainer>
                                    <RadioContainer>
                                        <RadioContextContainerWithSelect>
                                            <RadioContextContainer>
                                                <CustomRadioButton value={ScanFrequency.WEEKLY} />
                                                <RadioLabel>Weekly</RadioLabel>
                                            </RadioContextContainer>
                                            <Collapse in={auditScan?.frequency === ScanFrequency.WEEKLY}>
                                                <Spacer height={0.5} />
                                                <RadioSecondaryContainer>
                                                    <FieldLabelLightSmall>Every</FieldLabelLightSmall>
                                                    <Field name={'settings.auditScan.dayOfWeek'} component='input'>
                                                        {({ input: selectInput, meta }) => (
                                                            <StyledSelect
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: 'selectMenuItem',
                                                                    },
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                }}
                                                                renderValue={(value) => {
                                                                    return WeeklyOptions.find((day) => day.value === value).displayName;
                                                                }}
                                                                IconComponent={KeyboardArrowDownRounded}
                                                                variant={'outlined'}
                                                                input={<BootstrapInput />}
                                                                {...selectInput}>
                                                                {WeeklyOptions.map((option, index) => {
                                                                    return (
                                                                        <CustomMenuItem
                                                                            autoFocus={false}
                                                                            disableRipple
                                                                            key={option.id}
                                                                            value={option.value}>
                                                                            {option.displayName}
                                                                            {option.value === auditScan?.dayOfWeek && (
                                                                                <Done fontSize={'medium'} />
                                                                            )}
                                                                        </CustomMenuItem>
                                                                    );
                                                                })}
                                                            </StyledSelect>
                                                        )}
                                                    </Field>
                                                </RadioSecondaryContainer>
                                            </Collapse>
                                        </RadioContextContainerWithSelect>
                                    </RadioContainer>
                                    <RadioContainer>
                                        <RadioContextContainerWithSelect>
                                            <RadioContextContainer>
                                                <CustomRadioButton value={ScanFrequency.MONTHLY} />
                                                <RadioLabel>Monthly</RadioLabel>
                                            </RadioContextContainer>
                                            <Collapse in={auditScan?.frequency === ScanFrequency.MONTHLY}>
                                                <Spacer height={0.5} />
                                                <RadioSecondaryContainer>
                                                    <FieldLabelLightSmall>Every</FieldLabelLightSmall>
                                                    <Field name={'settings.auditScan.dayOfMonth'} component='input'>
                                                        {({ input: selectInput, meta }) => (
                                                            <StyledSelect
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: 'selectMenuItem',
                                                                    },
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                }}
                                                                renderValue={(value) => {
                                                                    return MonthlyOptions.find((day) => day.value === value).displayName;
                                                                }}
                                                                IconComponent={KeyboardArrowDownRounded}
                                                                variant={'outlined'}
                                                                input={<BootstrapInput />}
                                                                {...selectInput}>
                                                                {MonthlyOptions.map((option, index) => {
                                                                    return (
                                                                        <CustomMenuItem
                                                                            autoFocus={false}
                                                                            disableRipple
                                                                            key={option.id}
                                                                            value={option.value}>
                                                                            {option.displayName}
                                                                            {option.value === auditScan?.dayOfMonth && (
                                                                                <Done fontSize={'medium'} />
                                                                            )}
                                                                        </CustomMenuItem>
                                                                    );
                                                                })}
                                                            </StyledSelect>
                                                        )}
                                                    </Field>
                                                    <Collapse
                                                        in={auditScan?.frequency === ScanFrequency.MONTHLY && auditScan.dayOfMonth > 30}>
                                                        <Spacer height={1} />
                                                        <InfoBox border={false}>
                                                            In months with fewer than 31 days, the rescan will take place on the final day
                                                            of the month.
                                                        </InfoBox>
                                                    </Collapse>
                                                </RadioSecondaryContainer>
                                            </Collapse>
                                        </RadioContextContainerWithSelect>
                                    </RadioContainer>
                                    <RadioContainer>
                                        <RadioContextContainerWithSelect>
                                            <RadioContextContainer>
                                                <CustomRadioButton value={ScanFrequency.CUSTOM} />
                                                <RadioLabel>On a chosen day</RadioLabel>
                                            </RadioContextContainer>
                                            <Collapse in={auditScan?.frequency === ScanFrequency.CUSTOM}>
                                                <Spacer height={0.5} />
                                                <RadioSecondaryContainer>
                                                    <FieldLabelLightSmall>Choose date</FieldLabelLightSmall>
                                                    <Field name={'settings.auditScan.customDate'} component='input'>
                                                        {({ input: selectInput, meta }) => (
                                                            <StyledSelect
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: 'datePickerSelectMenuItem',
                                                                    },
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                }}
                                                                renderValue={(value) => {
                                                                    return (
                                                                        selectInput.value &&
                                                                        new Date(selectInput.value).toLocaleDateString('en-US', {
                                                                            month: 'short',
                                                                            day: 'numeric',
                                                                            year: 'numeric',
                                                                        })
                                                                    );
                                                                }}
                                                                IconComponent={KeyboardArrowDownRounded}
                                                                variant={'outlined'}
                                                                input={<BootstrapInput />}
                                                                displayEmpty
                                                                {...selectInput}>
                                                                <DayPicker
                                                                    mode='single'
                                                                    selected={selectInput.value && new Date(selectInput.value)}
                                                                    disabled={(date) => {
                                                                        const today = new Date();
                                                                        today.setHours(0, 0, 0, 0);
                                                                        return date <= today;
                                                                    }}
                                                                    onSelect={(date) =>
                                                                        selectInput.onChange(
                                                                            date
                                                                                .toLocaleDateString('en-CA', {
                                                                                    year: 'numeric',
                                                                                    month: '2-digit',
                                                                                    day: '2-digit',
                                                                                })
                                                                                .split('/')
                                                                                .reverse()
                                                                                .join('-')
                                                                        )
                                                                    }
                                                                />
                                                            </StyledSelect>
                                                        )}
                                                    </Field>
                                                </RadioSecondaryContainer>
                                            </Collapse>
                                        </RadioContextContainerWithSelect>
                                    </RadioContainer>
                                </StyledRadioGroup>
                            )}
                        </Field>
                    </DetailsContainer>
                </AccordionDetailsColumn>
            </AccordionStyled>

            <AccordionStyled
                expanded={expanded === 'authorization'}
                onChange={(event, isExpanded) => setExpanded(isExpanded ? 'authorization' : '')}
                bordercolor={expanded === 'authorization' ? '#385FEB' : '#E3E9FC'}>
                <AccordionSummaryStyled
                    expandIcon={
                        <img
                            src={'assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg'}
                            style={{ width: '20px', height: '20px' }}
                        />
                    }>
                    <AccordionSummaryWrapperStyled className={'flex'}>
                        <div className={'accordion-summary-wrapper-row'}>
                            <AccordionHeaderStyled>Authorization Settings</AccordionHeaderStyled>
                            {isAuthError && <ExclamationIcon style={{ width: '1rem', height: '1rem' }} />}
                        </div>
                        <SubHeader>Enter your website login credentials for scanning access</SubHeader>
                    </AccordionSummaryWrapperStyled>
                </AccordionSummaryStyled>
                <AccordionDetailsColumn>
                    <DetailsContainer>
                        <FieldLabelLight>Authorization type</FieldLabelLight>
                        <Field name={'settings.authType'} component='input'>
                            {({ input: selectInput, meta }) => (
                                <>
                                    <StyledSelect
                                        {...selectInput}
                                        MenuProps={{
                                            classes: {
                                                paper: 'selectMenuItem',
                                            },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        renderValue={(value) => {
                                            const field = AuthMethodOptions.find((method) => method.value === value);
                                            return field.displayName;
                                        }}
                                        IconComponent={KeyboardArrowDownRounded}
                                        variant={'outlined'}
                                        input={<BootstrapInput />}
                                        onChange={authTypeChange}>
                                        {AuthMethodOptions.map((option, index) => {
                                            return (
                                                <CustomMenuItem autoFocus={false} disableRipple key={option.id} value={option.value}>
                                                    {option.displayName}
                                                    {option.value === authType && <Done fontSize={'medium'} />}
                                                </CustomMenuItem>
                                            );
                                        })}
                                    </StyledSelect>
                                    <FieldLabelDescription style={{ paddingTop: '2px' }}>
                                        {getAuthTypeDescription()}&nbsp;
                                        {authType === AuthType.FORM_AUTHENTICATION && (
                                            <a href='https://support.walkme.com/knowledge-base/ai-center/' target='_blank'>
                                                Learn more
                                            </a>
                                        )}
                                    </FieldLabelDescription>
                                </>
                            )}
                        </Field>
                        {isAuthError && <ErrorBox>We’ve identified an issue that requires the credentials to be updated.</ErrorBox>}
                        <AuthDetailsContainer>
                            {authType === AuthType.BEARER_TOKEN && (
                                <div className={styles.flexColumn}>
                                    <FieldLabelLight>Insert Token</FieldLabelLight>
                                    <Field name={'settings.auth.token'}>
                                        {({ input, meta }) => (
                                            <CssTextField
                                                variant='outlined'
                                                placeholder={'Paste your bearer token here'}
                                                fullWidth
                                                type={showTokenField ? 'text' : 'password'}
                                                autoComplete={showPasswordField ? 'off' : 'new-password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {showPasswordIcon(showTokenField, setShowTokenField)}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={!!meta.error && meta.touched}
                                                helperText={meta.touched && meta.error}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </div>
                            )}
                            {authType === AuthType.BASIC_AUTH && (
                                <>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Username</FieldLabelLight>
                                        <Field name={'settings.auth.username'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter username here'}
                                                    autoComplete='off'
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Password</FieldLabelLight>
                                        <Field name={'settings.auth.password'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    type={showPasswordField ? 'text' : 'password'}
                                                    autoComplete={showPasswordField ? 'off' : 'new-password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {showPasswordIcon(showPasswordField, setShowPasswordField)}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder={'Enter password here'}
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </>
                            )}
                            {authType === AuthType.OAUTH2 && (
                                <>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Insert Access Token URL</FieldLabelLight>
                                        <Field name={'settings.auth.accessTokenUrl'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Paste your token URL here'}
                                                    autoComplete='off'
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Insert Client Id</FieldLabelLight>
                                        <Field name={'settings.auth.clientId'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Paste your client ID here'}
                                                    autoComplete='off'
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Insert Client Secret</FieldLabelLight>
                                        <Field name={'settings.auth.clientSecret'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    type={showClientSecretField ? 'text' : 'password'}
                                                    autoComplete={showClientSecretField ? 'off' : 'new-password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {showPasswordIcon(showClientSecretField, setShowClientSecretField)}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder={'Paste your client secret here'}
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Insert Scope</FieldLabelLight>
                                        <Field name={'settings.auth.scope'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Paste your scope here'}
                                                    autoComplete='off'
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </>
                            )}
                            {authType === AuthType.FORM_AUTHENTICATION && (
                                <>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Login page URL</FieldLabelLight>
                                        <Field name={'settings.auth.ssoDomain'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter login page url here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Username</FieldLabelLight>
                                        <Field name={'settings.auth.username'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter username here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Password</FieldLabelLight>
                                        <Field name={'settings.auth.password'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    type={showFormAuthenticationPasswordField ? 'text' : 'password'}
                                                    autoComplete={showFormAuthenticationPasswordField ? 'off' : 'new-password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {showPasswordIcon(
                                                                    showFormAuthenticationPasswordField,
                                                                    setShowFormAuthenticationPasswordField
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder={'Enter password here'}
                                                    fullWidth
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Username field XPath</FieldLabelLight>
                                        <Field name={'settings.auth.usernameXpath'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter username XPath here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Password field XPath</FieldLabelLight>
                                        <Field name={'settings.auth.passwordXpath'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter password XPath here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Sign-In button XPath</FieldLabelLight>
                                        <Field name={'settings.auth.signInButtonXpath'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter Sign-In button XPath here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Application Embed Link</FieldLabelLight>
                                        <Field name={'settings.auth.appEmbedLink'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter Application Embed Link here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <FieldLabelLight>Application Embed Link Sign-In Button XPath</FieldLabelLight>
                                        <Field name={'settings.auth.appEmbedLinkLoginButtonXpath'}>
                                            {({ input, meta }) => (
                                                <CssTextField
                                                    variant='outlined'
                                                    placeholder={'Enter Application Embed Link Sign-In Button XPath here'}
                                                    fullWidth
                                                    autoComplete='off'
                                                    error={!!meta.error && meta.touched}
                                                    helperText={meta.touched && meta.error}
                                                    {...input}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <Field name={'settings.auth.skipLoadEvent'} type={'checkbox'}>
                                            {({ input, meta }) => (
                                                <div
                                                    className={`${styles.flexRow} ${styles.center}`}
                                                    style={{ height: '24px', paddingBottom: '12px' }}>
                                                    <CssSwitch disableRipple disabled={!isAdminMode} {...input} />
                                                    <SwitchLabel className={`${input.checked ? 'active' : ''}`}>
                                                        <div className={styles.flexColumn}>
                                                            <FieldLabelLight>Skip page reload</FieldLabelLight>
                                                            <FieldLabelDescription>
                                                                Turn on if login doesn't require a site redirect
                                                            </FieldLabelDescription>
                                                        </div>
                                                    </SwitchLabel>
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </>
                            )}
                        </AuthDetailsContainer>
                    </DetailsContainer>
                </AccordionDetailsColumn>
            </AccordionStyled>

            <AccordionStyled
                expanded={expanded === 'advanced'}
                onChange={(event, isExpanded) => setExpanded(isExpanded ? 'advanced' : '')}
                bordercolor={expanded === 'advanced' ? '#385FEB' : '#E3E9FC'}>
                <AccordionSummaryStyled
                    expandIcon={
                        <img
                            src={'assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg'}
                            style={{ width: '20px', height: '20px' }}
                        />
                    }>
                    <AccordionSummaryWrapperStyled>
                        <AccordionHeaderStyled>Advanced Settings</AccordionHeaderStyled>
                        <SubHeader>
                            Set the time intervals between page scans to ensure optimal performance, with the minimum delay usually
                            sufficient.&nbsp;
                            <a href='https://support.walkme.com/knowledge-base/ai-center/?ts=1740054555' target='_blank'>
                                Learn more
                            </a>
                        </SubHeader>
                    </AccordionSummaryWrapperStyled>
                </AccordionSummaryStyled>
                <AccordionDetailsColumn>
                    <DetailsContainer>
                        <Field name={'settings.controlDelay'} type={'checkbox'} value={controlDelay}>
                            {({ input: radioInput, meta }) => (
                                <StyledRadioGroup {...radioInput}>
                                    <RadioContainer>
                                        <RadioContextContainer>
                                            <CustomRadioButton value={'false'} />
                                            <RadioLabel>Minimum delay between pages</RadioLabel>
                                        </RadioContextContainer>
                                    </RadioContainer>
                                    <RadioContainer>
                                        <RadioContextContainerWithSelect>
                                            <RadioContextContainer>
                                                <CustomRadioButton value={'true'} />
                                                <RadioLabel>1 URL per</RadioLabel>
                                            </RadioContextContainer>
                                            <Collapse in={controlDelay === 'true'}>
                                                <Spacer height={0.5} />
                                                <RadioSecondaryContainer>
                                                    <Field name={'settings.delay'} component='input'>
                                                        {({ input: selectInput, meta }) => (
                                                            <StyledSelect
                                                                style={{ width: '7rem' }}
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: 'selectMenuItem',
                                                                    },
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                }}
                                                                renderValue={(value) => {
                                                                    const res = SecondsOptions.find((x) => x.value === value);
                                                                    return res.displayName;
                                                                }}
                                                                IconComponent={KeyboardArrowDownRounded}
                                                                variant={'outlined'}
                                                                input={<BootstrapInput />}
                                                                {...selectInput}>
                                                                {SecondsOptions.map((option, index) => {
                                                                    return (
                                                                        <CustomMenuItem
                                                                            autoFocus={false}
                                                                            disableRipple
                                                                            key={option.id}
                                                                            value={option.value}>
                                                                            {option.displayName}
                                                                            {option.value === delay && <Done fontSize={'medium'} />}
                                                                        </CustomMenuItem>
                                                                    );
                                                                })}
                                                            </StyledSelect>
                                                        )}
                                                    </Field>
                                                </RadioSecondaryContainer>
                                            </Collapse>
                                        </RadioContextContainerWithSelect>
                                    </RadioContainer>
                                </StyledRadioGroup>
                            )}
                        </Field>
                    </DetailsContainer>
                </AccordionDetailsColumn>
            </AccordionStyled>
        </MainContainer>
    );
};

export default SettingsStep;
