import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FooterCancelButton, FooterStyledButton } from '../../footers/footer-style';
import styled from 'styled-components';

const FooterContainer = styled.div`
    display: flex;
    width: 1008px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    .footer-container-flex-row {
        display: flex;
        flex-direction: row;
    }
`;

export interface ICreateSlackIntegraitonFooter {
    step: number;
    handleWarningModel: (flag: boolean) => void;
    changeActiveStep: (step: number) => void;
}

export const CreateSlackIntegrationFooter = ({ step, handleWarningModel, changeActiveStep }: ICreateSlackIntegraitonFooter) => {
    const [loading, setLoading] = useState(false);
    const formApi = useForm();

    const handleSubmit = async () => {
        setLoading(true);
        await formApi.submit();
        setLoading(false);
    };

    const nextStep = () => {
        changeActiveStep(step + 1);
    };

    const prevStep = () => {
        changeActiveStep(step - 1);
    };

    return (
        <FooterContainer>
            {step == 0 && (
                <>
                    <div>
                        <FooterCancelButton onClick={() => handleWarningModel(true)}>Cancel</FooterCancelButton>
                        <FooterStyledButton className={'next-button'} disabled={formApi.getState().invalid} onClick={nextStep}>
                            {' '}
                            Next{' '}
                        </FooterStyledButton>
                    </div>
                </>
            )}
            {step === 1 && (
                <>
                    <div className={'footer-container-flex-row'}>
                        <FooterCancelButton onClick={prevStep}>Back</FooterCancelButton>
                        <FooterStyledButton className={'next-button'} disabled={formApi.getState().invalid} onClick={nextStep}>
                            {' '}
                            Next{' '}
                        </FooterStyledButton>
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <div className={'footer-container-flex-row'}>
                        <FooterCancelButton onClick={prevStep}>Back</FooterCancelButton>
                        <FooterStyledButton onClick={handleSubmit} disabled={formApi.getState().invalid}>
                            {loading && (
                                <>
                                    <CircularProgress size={14} color='inherit' />
                                    &nbsp;
                                </>
                            )}
                            <span>Add Slack Integration</span>
                        </FooterStyledButton>
                    </div>
                </>
            )}
        </FooterContainer>
    );
};

export default CreateSlackIntegrationFooter;
