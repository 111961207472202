import React, { useEffect } from 'react';
import { RadioGroup } from '@material-ui/core';
import {
    FormMode,
    IAskMeIntegrationSystemForm,
    IIntegration,
    IIntegrationRulesForm,
    IntegrationChannelOptions,
} from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { Field, useForm } from 'react-final-form';
import { Container, IntegrationManagerConatiner } from './integration-manager-style';
import CustomRadioButton from '../../../../../common/components/custom-radio-button';
import { RadioContainer, RadioContextContainer, RadioLabel } from '../settings-step/settings-step-style';
import IntegrationRules from './rules/integration-rules';
import IntegrationChannels from './channels/integration-channels';

interface IntegrationTabProps {
    formMode: FormMode;
    integration: IIntegration;
}

const IntegrationTab = ({ formMode, integration }: IntegrationTabProps) => {
    const formApi = useForm();

    const integrationTypes = {
        slack: { name: 'Slack', type: 'channels' },
        teams: { name: 'Teams', type: 'teams' },
    };

    const integrationChannelConfig = formApi.getState().values.settings[`${integration?.type}ChannelsConfig`];

    const handleChange = (e) => {
        const integrationChannels = integration?.slackChannels;

        const newIntegrationChannelsConfigField = e.target.value;
        formApi.change(`settings.${integration?.type}ChannelsConfig`, newIntegrationChannelsConfigField);

        if (formMode === FormMode.STEPPER) {
            if (newIntegrationChannelsConfigField === IntegrationChannelOptions.ALL) {
                formApi.change(
                    `${integration?.type}Systems`,
                    integrationChannels.map((channel) => ({ channelGuid: channel.channelIntegrationGuid }))
                );
                formApi.change(`${integration?.type}Rules`, []);
            } else {
                formApi.change(`${integration?.type}Rules`, formApi.getState().initialValues[`${integration?.type}Rules`]);
                formApi.change(`${integration?.type}Systems`, formApi.getState().initialValues[`${integration?.type}Systems`]);
            }
        }

        if (formMode === FormMode.TABS) {
            if (newIntegrationChannelsConfigField === formApi.getState().initialValues[`${integration?.type}ChannelsConfig`]) {
                formApi.change(`${integration?.type}Rules`, formApi.getState().initialValues[`${integration?.type}Rules`]);
                formApi.change(`${integration?.type}Systems`, formApi.getState().initialValues[`${integration?.type}Systems`]);
            } else {
                let allChannels = [];
                let newRules = [];
                if (newIntegrationChannelsConfigField === IntegrationChannelOptions.ALL) {
                    allChannels = integrationChannels.map((channel) => ({ channelGuid: channel.channelIntegrationGuid }));
                } else if (newIntegrationChannelsConfigField === IntegrationChannelOptions.RULES) {
                    newRules = formApi.getState().initialValues[`${integration?.type}Rules`];
                    allChannels = formApi.getState().initialValues[`${integration?.type}Systems`];
                }
                formApi.change(`${integration?.type}Systems`, allChannels);
                formApi.change(`${integration?.type}Rules`, newRules);
            }
        }
    };

    return (
        <Container>
            <Field name={`settings.${integration?.type}ChannelsConfig`} type={'checkbox'} value={integrationChannelConfig}>
                {({ input: radioInput, meta }) => (
                    <RadioGroup {...radioInput} onChange={handleChange}>
                        <RadioContainer>
                            <RadioContextContainer>
                                <CustomRadioButton value={IntegrationChannelOptions.DISABLED} />
                                <RadioLabel className={`${integrationChannelConfig === IntegrationChannelOptions.DISABLED && 'active'}`}>
                                    {' '}
                                    Disable {integrationTypes[integration?.type]?.name} {integrationTypes[integration?.type]?.type}
                                </RadioLabel>
                            </RadioContextContainer>
                        </RadioContainer>
                        <RadioContainer>
                            <RadioContextContainer>
                                <CustomRadioButton value={IntegrationChannelOptions.ALL} />
                                <RadioLabel className={`${integrationChannelConfig === IntegrationChannelOptions.ALL && 'active'}`}>
                                    {' '}
                                    Approve Access to all {integrationTypes[integration?.type]?.type}
                                </RadioLabel>
                            </RadioContextContainer>
                        </RadioContainer>
                        <RadioContainer>
                            <RadioContextContainer>
                                <CustomRadioButton value={IntegrationChannelOptions.RULES} />
                                <RadioLabel className={`${integrationChannelConfig === IntegrationChannelOptions.RULES && 'active'}`}>
                                    {' '}
                                    Approve access to chosen {integrationTypes[integration?.type]?.type} only
                                </RadioLabel>
                            </RadioContextContainer>
                        </RadioContainer>
                    </RadioGroup>
                )}
            </Field>
            {integrationChannelConfig === IntegrationChannelOptions.RULES && (
                <IntegrationManagerConatiner>
                    <IntegrationRules formMode={formMode} integration={integration} />
                    <IntegrationChannels integration={integration} />
                </IntegrationManagerConatiner>
            )}
        </Container>
    );
};

export default IntegrationTab;
