import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import { FormApi } from 'final-form';
import { useForm } from 'react-final-form';
import { Fields, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { StepSectionHeaderContainer, StyledButton, StyledInputLabel } from '../../common/style';
import UserIdentifierSection from './user-identifier-section/user-identifier-section';
import FieldsSection from './fields-section/fields-section';
import { ImportFieldsContainer, StyledHelperText } from './idp-properties-step-style';
import Base from '../services/providers/base';

export interface FieldsBlockProps {
    fields: Fields;
    loadedFields: Fields;
    fieldsLoad: boolean;
    providerInstance: Base;
    values: ProviderFormValues;
    stopPolling: () => void;
    handleAuth: (values, formApi: FormApi) => void;
    inProgress: boolean;
}

export const IdpPropertiesStep = ({
    fields,
    providerInstance,
    loadedFields,
    fieldsLoad,
    values,
    stopPolling,
    handleAuth,
    inProgress,
}: FieldsBlockProps) => {
    const [copyFields, setCopyFields] = useState<Fields>([...fields]);

    const formApi = useForm();

    useEffect(() => {
        if (fieldsLoad) {
            setCopyFields(loadedFields);
        } else {
            setCopyFields(fields);
        }
    }, [fieldsLoad, loadedFields, fields]);

    const updateCopyFields = (copyFields: Fields) => {
        setCopyFields(copyFields);
    };

    return (
        <>
            <StepSectionHeaderContainer>
                <span className={'title'}>IDP Properties</span>
                <span className={'title-helper'}>Select the protocol for your IDP, fill out the form below, and get properties.</span>
            </StepSectionHeaderContainer>
            <ImportFieldsContainer>
                <StyledInputLabel>Properties to Import</StyledInputLabel>
                <StyledHelperText className={`fontSmall`}>
                    Choose which IDP Properties to import. WalkMe will synchronize the selected
                    <br />
                    fields only on the end user’s first login after the integration was set up.
                </StyledHelperText>
                <StyledButton
                    onClick={() => (inProgress ? stopPolling() : handleAuth(formApi.getState().values, formApi))}
                    showPipe={true}
                    loading={inProgress && !fieldsLoad}
                    disabled={fieldsLoad}
                    className={`get-prop-button ${inProgress ? 'in-progress' : ''}`}>
                    {inProgress && !fieldsLoad ? 'Discard' : 'Import Properties'}
                </StyledButton>
                <Divider style={{ margin: '32px 0' }} />
            </ImportFieldsContainer>

            <UserIdentifierSection values={values} copyFields={copyFields} fields={fields} fieldsLoad={fieldsLoad} />
            <FieldsSection
                fields={fields}
                fieldsLoad={fieldsLoad}
                copyFields={copyFields}
                providerInstance={providerInstance}
                updateCopyFields={updateCopyFields}
                loadedFields={loadedFields}
                values={values}
            />
        </>
    );
};

export default IdpPropertiesStep;
