import styled from 'styled-components';
import { RadioButton } from '@walkme/ui-core';
import { FilledWarning as Warning } from '@walkme/ui-icons';

export const StyledContractCard = styled.div<{ selected: boolean; disabled: boolean; shouldTitleMarginBottom: boolean }>`
    margin-bottom: 16px;
    padding: 16px 16px 16px 20px;
    border: 1px solid;
    border-color: ${(props) => (props.selected ? '#385feb' : 'rgba(56, 95, 235, 0.2)')};
    border-radius: 8px;
    background-color: ${(props) => (props.selected ? '#f7f9fc' : 'white')};
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};

    .description {
        display: flex;
        justify-content: space-between;
        .title {
            font-weight: bold;
            margin-bottom: ${({ shouldTitleMarginBottom }) => (shouldTitleMarginBottom ? '6px' : '0')};
            color: var(--Color-Semantic-Text-text-primary, #2f426c);
            max-width: 50%;

            span[class^='Tooltip'] {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
            }
        }

        .misc {
            display: flex;
            justify-content: flex-end;
            height: 25px;
        }
    }

    .info {
        display: flex;
        color: var(--Primaries-Typography-Typography-tint-1, #637191);

        .id {
            max-width: 200px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 4px;
        }

        .systems {
            display: flex;
            align-items: center;
            margin: 0 4px;
        }
    }
`;

export const StyledSideBadge = styled.div`
    height: 20px;
`;

export const StyledRadioButton = styled(RadioButton)``;

export const StyledRecommendedBadge = styled.div`
    background-color: #a8e2ff;
    color: #2f426c;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: bold;
    padding: 6px;
    max-height: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
`;

export const StyledWarningSign = styled(Warning)`
    margin-left: 6px;
`;
