import React, { SVGProps } from 'react';

export const TvIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 20 20' fill='none' {...props}>
        <rect x='1.5' y='3.5' width='17' height='11' rx='1.5' stroke='#6D81A6' />
        <rect
            x='6.24928'
            y='17.2507'
            width='7.5'
            height='0.5'
            rx='0.25'
            transform='rotate(0.164191 6.24928 17.2507)'
            stroke='#6D81A6'
            strokeWidth='0.5'
        />
    </svg>
);
