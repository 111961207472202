import React from 'react';
import { WMTextField } from '@walkme/wm-ui';
import { InputAdornment } from '@material-ui/core';
import { InfoIconWithTooltip } from './info-icon-with-tooltip';
import { useStyles } from './input-with-info-style';

interface UrlFieldProps {
    value: string;
    onChange: (event: any) => void;
    onBlur?: (event: any) => void;
    onFocus?: (event: any) => void;
    placeholder: string;
    tooltipText: string | React.ReactNode;
    isValid: boolean;
    isDisabled?: boolean;
    errorText: string;
    maxLength?: number;
}

const InputWithInfo = ({
    value,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    tooltipText,
    isValid,
    errorText,
    maxLength,
    isDisabled = false,
}: UrlFieldProps) => {
    const classes = useStyles();

    return (
        <WMTextField
            ds2
            size='large'
            placeholder={placeholder}
            status={isValid ? 'default' : 'error'}
            disabled={isDisabled}
            helperText={isValid ? '' : errorText}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            maxLength={maxLength}
            InputProps={{
                classes: {
                    root: classes.inputBase,
                    focused: classes.inputFocused,
                    error: classes.inputError,
                    disabled: classes.inputDisabled,
                },
                endAdornment: (
                    <InputAdornment position='end'>
                        <InfoIconWithTooltip tooltipTitle={tooltipText} />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export { InputWithInfo };
