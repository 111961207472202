import { ReportProblemOutlined, AutorenewOutlined } from '@material-ui/icons';
import { WMTooltip } from '@walkme/wm-ui';
import React from 'react';
import { System, SystemCreatedWebhooksStatus, WebhookResponseStatus } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization';

export interface SystemStatusIndicatorProps {
    system: System;
    createdSystemStatus: SystemCreatedWebhooksStatus;
}

const SystemStatusIndicator = (props: SystemStatusIndicatorProps) => {
    const { t } = useTranslation('general');

    const createdSystemStatus = props.createdSystemStatus;
    if (!createdSystemStatus) {
        return <></>;
    }
    const systemFailedAliases =
        createdSystemStatus.failedEventWebhookStatus.length > 0 &&
        createdSystemStatus.failedEventWebhookStatus.filter((status) => status.shouldExposeStatus);
    const systemPendingAliases =
        createdSystemStatus.pendingEventWebhookStatus.length > 0 &&
        createdSystemStatus.pendingEventWebhookStatus.filter((status) => status.shouldExposeStatus);

    if (systemFailedAliases) {
        const tempError = systemFailedAliases.find(
            (failedAlias) => failedAlias.statusDetails && failedAlias.statusDetails.status === WebhookResponseStatus.temporaryFailure
        );
        return (
            <WMTooltip
                condition={true}
                title={`${t('systems-tab.all-systems-page.system-status-indicator.failure-message')} (${
                    tempError
                        ? t('systems-tab.all-systems-page.system-status-indicator.trying-again')
                        : t('systems-tab.all-systems-page.system-status-indicator.contact-support')
                })`}>
                <ReportProblemOutlined style={{ fill: '#F7B500', marginLeft: '5px' }} />
            </WMTooltip>
        );
    }
    if (systemPendingAliases) {
        return (
            <WMTooltip condition={true} title={'System creation in progress'}>
                <AutorenewOutlined style={{ fill: '#5c7cf6', marginLeft: '5px' }} />
            </WMTooltip>
        );
    }
    return <></>;
};

export default SystemStatusIndicator;
