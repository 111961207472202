import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Route, Link, BrowserRouter as Router, Routes, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { PageWrapper } from '@walkme-admin-center/libs/common';
import { StyledPageTitle } from '@walkme-admin-center/libs/shared-styles';
import { RoleRow, TableHeadCell } from '@walkme-admin-center/libs/types';
import { useRoles, useActions } from '@walkme-admin-center/libs/state-management-users';
import { Tab, Tabs, Box } from '@material-ui/core';
import PredefinedRolesTab from './roles-tab/predefined-roles-tab';
import CustomRolesTab from './roles-tab/custom-roles-tab';
import { StyledPageTitleLabel } from './users.styles';
import { actionsToColorMapping } from './utils/color-genrator';
import { StyledTitleDiv } from './roles.styles';
import { useTranslation } from 'apps/home/src/localization';
import { StyledTabs, StyledTabsRow } from 'packages/pages/home/systems/src/lib/systems.styles';

const validTabNames = ['predefined-roles', 'custom-roles'];
export const Roles = () => {
    const { t, rt } = useTranslation('general');
    const { tabName } = useParams<{ tabName: string }>();
    const navigate = useNavigate();

    const { rolesAppData } = useRoles();
    const { actionsAppData } = useActions();

    const roles = rolesAppData.data;
    const actions = actionsAppData.data;

    const headCells: TableHeadCell[] = [
        { id: 'role', label: t('common.role') },
        { id: 'permissions', label: t('common.permissions') },
        { id: 'users', label: t('common.users') },
    ];

    const [customRoles, predefinedRoles] = roles.reduce(
        ([custom, predefined], role) => (role.accountId ? [[...custom, role], predefined] : [custom, [...predefined, role]]),
        [[], []]
    );

    useEffect(() => {
        actionsToColorMapping(actionsAppData.data);
    }, [actionsAppData.data]);

    useEffect(() => {
        document.title = t('users-and-roles-tab.document-title-roles');
    }, []);

    useEffect(() => {
        if (!validTabNames.includes(tabName)) {
            navigate('/roles/predefined-roles');
        }
    }, [tabName, navigate]);

    const [activeTab, setActiveTab] = useState(tabName === 'custom-roles' ? 1 : 0);

    const handleTabChange = (newValue: number) => {
        if (activeTab == newValue) return;
        setActiveTab(newValue);
        const isPredefinedTab = newValue === 0;
        if (isPredefinedTab) {
            navigate({
                pathname: `/roles/predefined-roles`,
            });
        } else {
            navigate({
                pathname: `/roles/custom-roles`,
            });
        }
    };

    return (
        <div className='noPadding'>
            <StyledTitleDiv>
                <StyledPageTitle>{t('users-and-roles-tab.roles.title')}</StyledPageTitle>
                <StyledPageTitleLabel>{t('users-and-roles-tab.roles.sub-title')}</StyledPageTitleLabel>
            </StyledTitleDiv>
            <StyledTabsRow>
                <StyledTabs
                    initialValue={activeTab}
                    onChange={(tabItem) => handleTabChange(Number(tabItem.value))}
                    items={[
                        {
                            content: (
                                <PredefinedRolesTab
                                    pageData={{ headCells, roles: predefinedRoles, actions }}
                                    loadingData={rolesAppData.loading}
                                />
                            ),
                            label: t('users-and-roles-tab.roles.predefined-roles-tab.title'),
                            value: '0',
                        },
                        {
                            content: (
                                <CustomRolesTab pageData={{ headCells, roles: customRoles, actions }} loadingData={rolesAppData.loading} />
                            ),
                            label: t('users-and-roles-tab.roles.custom-roles-tab.title'),
                            value: '1',
                        },
                    ]}
                />
            </StyledTabsRow>
        </div>
    );
};

export default Roles;
