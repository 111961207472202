import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Pluralize from 'react-pluralize';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataTable, useTable, FooterButtonsConfig } from '@walkme-admin-center/libs/ui-components';
import { RouteHeader, styledTheme } from '@walkme-admin-center/libs/shared-styles';

import {
    DesktopTrackedApps,
    DesktopAppStatuses,
    useDesktopTrackedApps,
    useActivateDesktopTrackedApps,
    useDeactivateDesktopTrackedApps,
    useAddedDesktopAppsResults,
} from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import DesktopIdentifiersTableDisplay from './desktop-identifier-table-display/desktop-identifier-table-display';
import DesktopAppStatusTableDisplay from './desktop-app-status-table-display/desktop-app-status-table-display';
import {
    activateDesktopTrackedApps,
    deactivateDesktopTrackedApps,
    desktopTrackedAppsSlice,
} from 'packages/libs/state-management-desktop-tracked-apps/src/lib/redux/desktop-tracked-apps.slice';
import TableFooterErrorMessage from './table-footer-error-message/table-footer-error-message';
import TableFooterSuccessMessage from './table-footer-success-message/table-footer-success-message';
import DesktopTrackedAppTitle from './desktop-tracked-app-title/desktop-tracked-app-title';
import { TableCustomCell, TableRowData } from '@walkme-admin-center/libs/types';
import NoDesktopTrackedApps from './no-desktop-tracked-apps/no-desktop-tracked-apps';
import { LoadingPage } from '@walkme-admin-center/libs/common';
import NoticeMessage from '@walkme-admin-center/libs/common/lib/notice-message/notice-message';
import SystemNameCell from './system-name-cell/system-name-cell';
import AddDesktopTrackedAppResults from './desktop-tracked-app-details/add-desktop-tracked-app-results';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { WMButton, WMTooltip } from '@walkme/wm-ui';
import { useDataTableLocale } from 'packages/pages/util/locale-utils';

const StyledButtonWrapper = styled.div`
    margin-left: 10px;
`;

export interface DesktopTrackedAppRow extends TableRowData {
    id: number;
    systemId: number;
    systemName: string;
    status: TableCustomCell;
    windowsProcessName: TableCustomCell;
    macAppName: TableCustomCell;
}

export interface DesktopTrackedAppsPageProps {
    desktopTrackedApps: DesktopTrackedApps;
    rows: DesktopTrackedAppRow[];
    loadingDesktopTrackedApps: boolean;
}

const tableConfig = {
    rowsPerPageOptions: [5],
};

const getIdentifiersText = (identifiers) => {
    return identifiers.length > 0 ? identifiers[0] : '';
};

const createRows = (desktopTrackedApps): TableRowData[] => {
    return desktopTrackedApps.map((desktopTrackedApp) => {
        const processNames = desktopTrackedApp.winIdentifiers
            .map((identifier) => identifier.processName)
            .filter((identifier) => identifier);
        const appNames = desktopTrackedApp.macIdentifiers.map((identifier) => identifier.appName).filter((identifier) => identifier);
        const bundleIds = desktopTrackedApp.macIdentifiers.map((identifier) => identifier.bundleId).filter((identifier) => identifier);

        const windowsProcessName = getIdentifiersText(processNames);
        const macAppName = getIdentifiersText(appNames);
        const macBundleId = getIdentifiersText(bundleIds);

        return {
            id: desktopTrackedApp.systemId,
            systemId: desktopTrackedApp.systemId,
            systemName: {
                value: desktopTrackedApp.systemName,
                displayValue: <SystemNameCell systemName={desktopTrackedApp.systemName} />,
            },
            status: {
                value: desktopTrackedApp.status,
                displayValue: <DesktopAppStatusTableDisplay status={desktopTrackedApp.status} />,
            },
            windowsProcessName: {
                value: windowsProcessName,
                displayValue: (
                    <DesktopIdentifiersTableDisplay
                        text={windowsProcessName}
                        identifiersCount={processNames.length}
                        fieldName='Process Name'
                    />
                ),
            },
            macAppName: {
                value: macAppName,
                displayValue: <DesktopIdentifiersTableDisplay text={macAppName} identifiersCount={appNames.length} fieldName='App Name' />,
            },
            macBundleId: {
                value: macBundleId,
                displayValue: (
                    <DesktopIdentifiersTableDisplay text={macBundleId} identifiersCount={bundleIds.length} fieldName='Bundle ID' />
                ),
            },
        };
    });
};

const ButtonTooltipText = ({ actionText }) => {
    return (
        <span>
            This will <b>{actionText}</b> data collection for selected app(s)
        </span>
    );
};

export const withData = (WrappedComponent) => {
    return (props) => {
        const { loggedInUserAppData } = useLoggedInUser();
        const { desktopTrackedAppsData } = useDesktopTrackedApps(loggedInUserAppData.data.account.id);

        return (
            <WrappedComponent
                {...props}
                rows={createRows(desktopTrackedAppsData.data || [])}
                loadingDesktopTrackedApps={desktopTrackedAppsData.loading}
                desktopTrackedApps={desktopTrackedAppsData.data}
            />
        );
    };
};

export const DesktopTrackedAppsPage = ({ rows, desktopTrackedApps, loadingDesktopTrackedApps }: DesktopTrackedAppsPageProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loggedInUserAppData } = useLoggedInUser();
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [hidePublishButton, setHidePublishButton] = useState(true);
    const [hideDeactivateButton, setHideDeactivateButton] = useState(true);
    const [showAddedDesktopAppsResult, setShowAddedDesktopAppsResult] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const [footerMessage, setFooterMessage] = useState<JSX.Element>(<TableFooterErrorMessage />);
    const [rowsSelectableDisabled, setRowsSelectableDisabled] = useState(false);
    const tableSettings = useTable();
    const activateDesktopTrackedAppsData = useActivateDesktopTrackedApps();
    const deactivateDesktopTrackedAppsData = useDeactivateDesktopTrackedApps();
    const addedDesktopAppsResults = useAddedDesktopAppsResults().addedDesktopTrackedAppsResults;
    const activateDesktopTrackedAppsStatus = activateDesktopTrackedAppsData.activateDesktopTrackedAppsData;
    const deactivateDesktopTrackedAppsStatus = deactivateDesktopTrackedAppsData.deactivateDesktopTrackedAppsData;
    const msaId = loggedInUserAppData.data.account.id;
    const dataTableLocale = useDataTableLocale();

    useEffect(() => {
        tableSettings.onSetOrderBy('');

        return () => {
            dispatch(desktopTrackedAppsSlice.actions.cleanActivateDesktopTrackedApps());
            dispatch(desktopTrackedAppsSlice.actions.cleanDeactivatedDesktopTrackedApps());
            setFooterMessage(null);
            setHidePublishButton(true);
            setHideDeactivateButton(true);
            setSelectedRows({});
        };
    }, []);

    useEffect(() => {
        setHidePublishButton(true);
        setHideDeactivateButton(true);
        setSelectedRows({});
    }, [rows]);

    useEffect(() => {
        let message = null;

        if (activateDesktopTrackedAppsStatus.data) {
            message = <TableFooterSuccessMessage text={'activated'} numOfApps={activateDesktopTrackedAppsStatus.data} />;
            setRowsSelectableDisabled(false);
        }

        if (activateDesktopTrackedAppsStatus.error) {
            message = <TableFooterErrorMessage />;
            setRowsSelectableDisabled(false);
        }

        setFooterMessage(message);
    }, [activateDesktopTrackedAppsStatus]);

    useEffect(() => {
        let message = null;

        if (deactivateDesktopTrackedAppsStatus.data) {
            message = <TableFooterSuccessMessage text={'deactivated'} numOfApps={deactivateDesktopTrackedAppsStatus.data} />;
            setRowsSelectableDisabled(false);
        }

        if (deactivateDesktopTrackedAppsStatus.error) {
            message = <TableFooterErrorMessage />;
            setRowsSelectableDisabled(false);
        }

        setFooterMessage(message);
    }, [deactivateDesktopTrackedAppsStatus]);

    useEffect(() => {
        const shouldShow =
            (addedDesktopAppsResults.success && addedDesktopAppsResults.success.length > 0) ||
            (addedDesktopAppsResults.failed && addedDesktopAppsResults.failed.length > 0);

        setShowAddedDesktopAppsResult(shouldShow);
    }, [addedDesktopAppsResults]);

    const headCells = [
        { id: 'systemName', label: 'App' },
        { id: 'status', label: 'Status' },
        { id: 'windowsProcessName', label: 'Windows Process Name' },
        { id: 'macAppName', label: 'Mac OS App Name' },
        { id: 'macBundleId', label: 'Mac OS Bundle ID' },
    ];
    const footerButtons: FooterButtonsConfig[] = [
        {
            text: allRowsSelected ? 'Deactivate All' : 'Deactivate',
            hidden: hideDeactivateButton,
            loading: deactivateDesktopTrackedAppsStatus.loading,
            disabled: activateDesktopTrackedAppsStatus.loading || deactivateDesktopTrackedAppsStatus.loading,
            customClass: activateDesktopTrackedAppsStatus.loading || deactivateDesktopTrackedAppsStatus.loading ? 'default' : 'main-grey',
            tooltipText: <ButtonTooltipText actionText='deactivate' />,
            tooltipWidth: 150,
            onClick: () => {
                dispatch(desktopTrackedAppsSlice.actions.cleanActivateDesktopTrackedApps());
                dispatch(desktopTrackedAppsSlice.actions.cleanDeactivatedDesktopTrackedApps());

                setRowsSelectableDisabled(true);

                const systemIds = getSystemIds();

                dispatch(deactivateDesktopTrackedApps(msaId, systemIds));
            },
        },
        {
            text: allRowsSelected ? 'Activate All' : 'Activate',
            hidden: hidePublishButton,
            loading: activateDesktopTrackedAppsStatus.loading,
            disabled: activateDesktopTrackedAppsStatus.loading || deactivateDesktopTrackedAppsStatus.loading,
            customClass: activateDesktopTrackedAppsStatus.loading || deactivateDesktopTrackedAppsStatus.loading ? 'default' : 'main',
            tooltipText: <ButtonTooltipText actionText='activate' />,
            tooltipWidth: 150,
            onClick: () => {
                dispatch(desktopTrackedAppsSlice.actions.cleanActivateDesktopTrackedApps());
                dispatch(desktopTrackedAppsSlice.actions.cleanDeactivatedDesktopTrackedApps());

                setRowsSelectableDisabled(true);

                const systemIds = getSystemIds();

                dispatch(activateDesktopTrackedApps(msaId, systemIds));
            },
        },
    ];

    const getSystemIds = () => {
        return Object.keys(selectedRows).map((systemId) => Number(systemId));
    };

    const handleAllRowsToggled = (allRowsSelected) => {
        let hidePublishButton;
        let hideDeactivateButton;
        const newSelectedRows = {};

        if (!allRowsSelected) {
            hidePublishButton = true;
            hideDeactivateButton = true;
        } else {
            desktopTrackedApps.forEach((desktopTrackedApp) => {
                hidePublishButton = hidePublishButton && desktopTrackedApp.status === DesktopAppStatuses.ACTIVATED;
                hideDeactivateButton = hideDeactivateButton && desktopTrackedApp.status !== DesktopAppStatuses.ACTIVATED;

                newSelectedRows[desktopTrackedApp.systemId] = desktopTrackedApp.status;
            });
        }

        setFooterMessage(null);
        setSelectedRows(newSelectedRows);
        setHidePublishButton(hidePublishButton);
        setHideDeactivateButton(hideDeactivateButton);
        setAllRowsSelected(allRowsSelected);
    };

    const handleRowToggled = (id, rowSelected) => {
        const selectedDesktopApp = desktopTrackedApps.filter((desktopTrackedApp) => desktopTrackedApp.systemId == id)[0];
        const newSelectedRows = {
            ...selectedRows,
        };
        let newHidePublishButton = true;
        let newHideDeactivateButton = true;

        if (rowSelected) {
            newSelectedRows[selectedDesktopApp.systemId] = selectedDesktopApp.status;
        } else {
            delete newSelectedRows[selectedDesktopApp.systemId];
        }

        Object.keys(newSelectedRows).forEach((systemId) => {
            newHidePublishButton = newHidePublishButton && newSelectedRows[systemId] === DesktopAppStatuses.ACTIVATED;
            newHideDeactivateButton = newHideDeactivateButton && newSelectedRows[systemId] !== DesktopAppStatuses.ACTIVATED;
        });

        setFooterMessage(null);
        setHidePublishButton(newHidePublishButton);
        setHideDeactivateButton(newHideDeactivateButton);
        setAllRowsSelected(false);
        setSelectedRows(newSelectedRows);
    };

    const onAddedAppsDialogClosed = () => {
        setShowAddedDesktopAppsResult(false);
        dispatch(desktopTrackedAppsSlice.actions.cleanAddedDesktopTrackedAppsResults());
    };

    const handleRowClicked = useCallback(
        (id: number) => {
            navigate({
                pathname: `/desktop-tracked-apps/app-details/${id}`,
            });
        },
        [history]
    );

    const createNewDesktopApp = useCallback(() => {
        navigate({
            pathname: '/desktop-tracked-apps/new',
        });
    }, [history]);

    const DesktopMessage = useMemo(() => {
        return footerMessage;
    }, [footerMessage]);

    const DesktopTableHeader = useMemo(() => {
        return footerButtons.map((button) => {
            return !button.hidden ? (
                <WMTooltip key={button.text} title={button.tooltipText || ''}>
                    <StyledButtonWrapper>
                        <WMButton loading={button.loading} onClick={button.onClick}>
                            {button.text}
                        </WMButton>
                    </StyledButtonWrapper>
                </WMTooltip>
            ) : (
                ''
            );
        });
    }, [footerButtons]);

    const renderContent = () => {
        if (rows.length === 0) {
            return <NoDesktopTrackedApps onClick={createNewDesktopApp} />;
        } else {
            return (
                <>
                    <NoticeMessage>
                        <b>Notice:</b> Settings changes only take effect after performing Publish to Production via the editor app
                    </NoticeMessage>
                    <DataTable
                        onToolbarButtonClick={createNewDesktopApp}
                        heads={headCells}
                        data={rows}
                        buttonText='Add Desktop App'
                        buttonWidth={175}
                        title={
                            <h3>
                                <Pluralize
                                    singular={'Available App'}
                                    count={loadingDesktopTrackedApps ? 'N/A' : desktopTrackedApps && desktopTrackedApps.length}
                                />
                            </h3>
                        }
                        hasToolbar
                        onRowClick={handleRowClicked}
                        rowsSelectable={true}
                        rowsSelectableDisabled={rowsSelectableDisabled}
                        onAllRowsToggled={handleAllRowsToggled}
                        onRowToggled={handleRowToggled}
                        showFooter={true}
                        tableHeader={
                            <Box display='flex' flexDirection='row' flexGrow={1}>
                                {DesktopTableHeader}
                                {DesktopMessage}
                            </Box>
                        }
                        footerButtons={footerButtons}
                        footerMessage={footerMessage}
                        {...tableSettings}
                        config={tableConfig}
                        rowsPerPage={5}
                        locale={dataTableLocale}
                    />
                    <AddDesktopTrackedAppResults
                        onClose={onAddedAppsDialogClosed}
                        success={addedDesktopAppsResults.success}
                        failed={addedDesktopAppsResults.failed}
                        isOpen={showAddedDesktopAppsResult}
                    />
                </>
            );
        }
    };

    if (loadingDesktopTrackedApps && !desktopTrackedApps) {
        return <LoadingPage />;
    }

    return (
        <>
            <DesktopTrackedAppTitle />
            {renderContent()}
        </>
    );
};

export default compose(withData)(DesktopTrackedAppsPage);
