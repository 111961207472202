/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { WMhttpClient } from '@walkme-admin-center/libs/http-service';
import useClientConfig from '@walkme-admin-center/../apps/home/src/redux/hooks/useClientConfig';
import { ITags } from '../../../state-mangment-data-integration/src/lib/types/types';

export interface IDeployableUser {
    userId: number;
    isImpersonated: boolean;
    systemId: number;
}

class DeployablesApi {
    private readonly httpClient: WMhttpClient;
    private readonly path: string;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
        this.path = 'deployables';
    }

    async getTags(user: IDeployableUser): Promise<ITags> {
        const { data } = await this.httpClient.instance.post<ITags>(`${this.path}/tag/getAll`, {
            deployableType: 10,
            deployableSubType: 10,
            environmentId: 0,
            isLight: true,
            filterByIds: null,
            ...user,
        });
        return data;
    }
}

export const deployablesApi = new DeployablesApi(new WMhttpClient(window.clientConfig.NX_WM_API_URL));
