import React from 'react';

import styled from 'styled-components';

export interface MessageProps {
    type: string;
    text: string;
}

export const StyledMessage = styled.div`
    padding: 10px;
    text-align: center;
    margin: 20px 0;

    &.warning {
        border: 1px solid #ffc30f;
        background-color: #fff8e5;
    }

    &.note {
        text-align: start;
    }
`;

export const Message = ({ type, text }: MessageProps) => {
    return <StyledMessage className={`${type}`}>{text}</StyledMessage>;
};

export default Message;
