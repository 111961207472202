import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'apps/home/src/localization';

export interface EnvProps {
    name: string;
    enforce: boolean;
}

export const Env = ({ name, enforce }: EnvProps) => {
    const { t } = useTranslation('general');

    return (
        <div
            style={{
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <span style={{ fontWeight: 600 }}>{name}</span>
            {enforce && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CheckCircleIcon style={{ fill: '#62DEA6' }} />
                    <span style={{ paddingLeft: '10px' }}>{t('idp-tab.table.system-list.enforce-sso')}</span>
                </div>
            )}
        </div>
    );
};

export default Env;
