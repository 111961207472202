import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

const {
    colors: {
        pages: {
            innerTabs: { backgroundColor },
        },
    },
    typography: { fontWeight },
} = styledTheme;

export const StyledLink = styled(Link)`
    margin-right: 10px;
    border-radius: 3px;
    width: auto;

    &:active {
        background-color: ${backgroundColor.focus};

        span {
            font-weight: ${fontWeight.heavy};
        }
    }

    &:hover {
        background-color: ${backgroundColor.focus};
    }

    &:focus {
        background-color: ${backgroundColor.focus};

        span {
            font-weight: ${fontWeight.heavy};
        }
    }
`;

export const StyledBadge = styled(Badge)`
    .MuiBadge-anchorOriginTopRightRectangle {
        top: 5px;
        right: 15px;
    }
`;
