import { useRef, useState } from 'react';
import axios from 'axios';
import { getSignedUrls } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { useDispatch } from 'react-redux';

const useFileUpload = () => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [askMeFile, setAskMeFile] = useState(undefined);
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [uploadCount, setUploadCount] = useState(0);
    const startTimeRef = useRef(0);
    const cancelTokenSourceRef = useRef(null);

    const dispatch: any = useDispatch();

    const uploadFile = async (file: File, sourceId: string) => {
        setIsUploading(true);
        setSuccess(false);
        setFailure(false);
        setUploadCount((value) => value + 1);

        try {
            startTimeRef.current = Date.now();
            const {
                urls: [signedUrl],
                jobFiles: [jobFile],
            } = await dispatch(getSignedUrls([file], sourceId));
            setAskMeFile(jobFile);
            cancelTokenSourceRef.current = axios.CancelToken.source();
            await axios.put(signedUrl, file, {
                cancelToken: cancelTokenSourceRef.current.token,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);

                    // Calculate seconds left
                    const uploadedBytes = progressEvent.loaded;
                    const totalBytes = progressEvent.total;
                    const elapsedTime = (Date.now() - startTimeRef.current) / 1000; // in seconds
                    const uploadSpeed = uploadedBytes / elapsedTime; // bytes per second
                    const remainingBytes = totalBytes - uploadedBytes;
                    const remainingTime = remainingBytes / uploadSpeed;

                    setSecondsLeft(Math.round(remainingTime));
                },
            });
            setSuccess(true);
        } catch (err) {
            setFailure(true);
        } finally {
            setIsUploading(false);
        }
    };

    const cancelUpload = () => {
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Upload canceled by user');
        }
    };

    return { uploadFile, cancelUpload, uploadProgress, success, failure, isUploading, askMeFile, secondsLeft, uploadCount };
};

export default useFileUpload;
