import React, { SVGProps } from 'react';

export const SortIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g id='Data Transfer Vertical'>
            <g id='Group 20947'>
                <g id='Group 20946'>
                    <path id='Vector' d='M4.47461 3V13' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                    <path
                        id='Vector_2'
                        d='M6.94849 10.6472L4.47424 13.0002L2 10.6472'
                        stroke='#6D81A6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </g>
                <g id='Group 20945'>
                    <path id='Vector_3' d='M11.5254 13V3' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                    <path
                        id='Vector_4'
                        d='M9.05127 5.35294L11.5255 3L13.9998 5.35294'
                        stroke='#6D81A6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </g>
            </g>
        </g>
    </svg>
);
