import styled from 'styled-components';
import { WMChip, WMDataGrid, WMDialog } from '@walkme/wm-ui';
import { Typography } from '@walkme/ui-core';

export const AutoCreateErrorText = styled.div`
    font-family: 'proximaNova';
    color: #eb3232;
    font-size: 18px;
    text-align: center;
`;

export const AutoCreateText = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 18px;
    text-align: center;
`;

export const SystemRowName = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 14px;
`;

export const SystemRowStatus = styled.div`
    margin-left: 6px;
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const SystemRowIdentifierMethod = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const NewChip = styled(WMChip)`
    background-color: #edf1fd !important;
    color: #3b61eb !important;
    border: 1px solid #3b61eb;
    margin-left: 5px;
`;

export const Chip = styled(WMChip)`
    background-color: #e4e9fc !important;
`;

export const EnvChip = styled(WMChip)`
    background-color: #e4e9fc !important;
    margin-right: 6px;
`;

export const BusinessSolutionChip = styled(WMChip)`
    background-color: #f8ecf0 !important;
    margin-right: 6px;
    width: 50px;
`;

export const TaskChip = styled(WMChip)`
    background-color: #fbf7ea !important;
    margin-right: 6px;
    width: 50px;
`;

export const RestoreItemsButton = styled.button`
    height: 40px !important;
    width: 88px;
    border-radius: 40px !important;
`;

export const RestorationDialog = styled(WMDialog)`
    width: 496px;
    background-color: white;
`;

export const DialogText = styled.div`
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding-bottom: 3.46vh;
`;

export const StyledGridTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const RestoreButton = styled.button`
    height: 32px !important;
    width: 100px;
    background-color: #3b61eb !important;
    border-radius: 40px !important;

    &:hover {
        background-color: #253d94 !important;
    }

    &:active {
        background-color: #2c49b0 !important;
    }

    .MuiButton-label {
        color: #ffffff;

        &:hover {
            color: #ffffff;
        }
    }
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
    .ag-row-selected::before {
        background: #edf1fd !important;
    }
    .ag-theme-wm-ui .ag-body-viewport,
    .ag-theme-wm-ui .ag-center-cols-viewport {
        cursor: pointer;
        scrollbar-color: unset !important;
        scrollbar-width: unset !important;
    }

    .WMDataGridHeader-wmDataGridHeader {
        align-items: center;
        padding: 20px 32px;
    }

    &.MuiPaper-root,
    &.WMDataGrid-wmDataGrid,
    &.MuiPaper-elevation0,
    &.MuiPaper-rounded {
        height: 100% !important;
    }

    .ag-cell {
        line-height: normal !important;
    }

    & .WMSearchFilter-wmSearchFilter.WMSearchFilter-medium {
        max-width: 350px !important;
        display: flex;
        align-items: center;
    }
`;

export const StyledTypography = styled(Typography)`
    color: var(--Color-Semantic-Text-secondary, #637191);
`;

export const StyledAppIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .system-type-key {
        margin-left: 10px;
    }

    img {
        width: 30px;
        height: 30px;
    }
`;

export const StyledAppNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`;
