import { InputBase, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const BootstrapInput = withStyles((theme) => ({
    root: {
        '&.Approved': {
            border: '1.5px solid #41B7A6',
            backgroundColor: '#41B7A6',
        },
        '&.Denied': {
            border: '1.5px solid #F37F7F',
            backgroundColor: '#F37F7F',
        },
        '&.Pending': {
            border: '1.5px solid #E6ECF8',
            backgroundColor: '#E6ECF8',
            color: '#C5CAD6',
        },
        '&.Mui-focused:not(.ruleSelect)': {
            border: '1.5px solid #385FEB',
        },
        '&:hover': {
            opacity: 0.8,
        },
        '&::before': {
            content: 'none',
        },
        '&.ruleSelect': {
            width: '120px',
            height: '32px',
            gap: '12px',
            borderRadius: '4px',
            background: '#F4F6FB',
        },
    },
}))(InputBase);

export const Container = styled.div`
    padding: 0px 24px 8px 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const IntegrationManagerConatiner = styled.div`
    height: 100%;
`;

export const RulesContainer = styled.div`
    display: flex;
    padding: 16px 0px 18px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .flex-column {
            display: flex;
            flex-direction: column;
        }
    }
`;

export const ApprovalRuleTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 2px 0 2px 0;
`;

export const ApprovalRuleSubTitle = styled.div`
    padding: 2px 0 4px 0;
    color: #8d97ae;
`;

export const RuleContainer = styled.div`
    display: flex;
    padding: 8px 12px 8px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #ebeff7;
    .icon {
        display: none;
        transition: opacity 0.5s ease-in-out;
    }
    &:hover {
        border: 1px solid #93a8f4;
        .icon {
            display: block;
        }
    }

    .channel-name {
        padding: 2px 0 2px 0;
        color: #2f426c;
    }
`;

export const RuleContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

export const TooltipContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
