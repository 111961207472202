import React, {ChangeEvent, useCallback} from 'react';
import { Card, Checkbox, createStyles, makeStyles } from "@material-ui/core";
import AppIcons from '../desktop-app-icons';
import styled from "styled-components";
import { IconCheck } from "@walkme-admin-center/libs/common";
import { DesktopAppTileType } from 'packages/libs/state-management-desktop-tracked-apps/src/lib/types/desktop-app-tile-type';

const CUSTOM_APP_ICON = 'logo-system-custom-system';

interface DesktopAppProps {
  desktopApp: DesktopAppTileType;
}

interface InteractiveDesktopAppProps {
  desktopApp: DesktopAppTileType;
  checked?: boolean;
  disabled?: boolean;
  onUpdate?: (app: DesktopAppTileType, isChecked: boolean) => void
}

const StyledCheckBox = styled(Checkbox)({
  marginLeft: '-15px'
});

const StyledDesktopApp = styled(Card)`
  height: 60px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: flex-start;

  img {
    padding-right: 10px;
    height: 30px;
  }
`;

const DesktopApp = ({desktopApp}: DesktopAppProps) => (
  <StyledDesktopApp variant='outlined'>
    <img src={AppIcons[desktopApp.icon] || AppIcons[CUSTOM_APP_ICON]} alt={desktopApp.systemName} />
    <b>{desktopApp.systemName}</b>
  </StyledDesktopApp>
);

const useStyles = makeStyles(() => createStyles({
  disabled: {
    backgroundColor: '#F7F7F7',
    borderColor: 'rgba(0, 0, 0, 0.12)'
  },
  checkIcon: {
    marginLeft: 'auto'
  }
}));

export const InteractiveDesktopApp = ({desktopApp, onUpdate, checked=false, disabled}: InteractiveDesktopAppProps) => {
  const classes = useStyles();
  const rootClassName = disabled ? classes.disabled : '';
  const onSelectionChanged = useCallback((e: ChangeEvent, isChecked: boolean) => onUpdate(desktopApp, isChecked), []);

  return (
    <StyledDesktopApp variant='outlined' className={rootClassName} data-testid='InteractiveDesktopApp'>
      {!disabled && <StyledCheckBox data-testid='StyledCheckBox' id={desktopApp.id} checked={checked} onChange={onSelectionChanged} />}
      <img src={AppIcons[desktopApp.icon] || CUSTOM_APP_ICON} alt={desktopApp.systemName}/>
      <b>{desktopApp.systemName}</b>
      {disabled && <IconCheck className={classes.checkIcon} htmlColor={'rgba(0, 0, 0, 0.12)'}/>}
    </StyledDesktopApp>
  );
};

export default DesktopApp;
