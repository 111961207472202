import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { Dialog } from '@walkme/ui-core';

export const StyledTypography = styled(Typography)`
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
`;

export const StyledBox = styled(Box)`
    padding-left: 8px;
    color: rgb(47, 66, 108);
`;

export const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        height: 50vh !important;
    }

    .MuiDialogContent-root {
        overflow-y: hidden !important;
        display: flex !important;
        flex-flow: column !important;
    }
`;

export const StyledLoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    flex: 1;
    padding: 16px 32px;
`;

export const StyledDialogTitle = styled.div`
    padding: 16px 24px;
`;

export const StyledSystemAppItem = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    margin: 10px 0 10px 5px;
`;

export const StyledContentContainer = styled.div`
    display: flex;
    flex-flow: column;
    overflow-y: auto;
`;

export const StyledBodyContainer = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow-y: auto;
`;
