import React, { ChangeEvent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { AuthContext } from 'wm-accounts-auth';
import { AccountsSdk, User } from 'wm-accounts-sdk';
import { AccountMenuItem, AccountsMenu } from '@walkme/accounts-menu';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@walkme/ui-core';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { StatusType, datadogLogs } from '@datadog/browser-logs';
import { Container, StyledDialog, StyledWMTextField } from './credentials.styles';
import { I18_CODE_KEY, useTranslation, setLocalizationLang, DEFAULT_LANGUAGE_CODE } from 'apps/home/src/localization/localizationBase';
import { languages } from 'apps/home/src/localization/languages';
import { Native } from '@walkme/ui-icons/large';
import _ from 'lodash';
export interface CredentialsProps {
    loading?: boolean;
    user?: User;
}

export const Credentials = (props: CredentialsProps) => {
    const { t } = useTranslation('general');
    const { loading, user } = props;
    const { signOut } = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const userData = (user && user.impersonator) || user;
    const [languageKey, setLanguageKey] = React.useState(localStorage?.getItem(I18_CODE_KEY) || DEFAULT_LANGUAGE_CODE);
    const { loggedInUserAppData, accountFeatureEnabled } = useLoggedInUser();
    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);

    const onChangeLanguage = (newLanguageKey: string) => {
        setLanguageKey(newLanguageKey);
        setLocalizationLang(newLanguageKey);
    };

    const onSignOut = () => {
        setOpen(false);
        signOut();
    };

    const onImpersonateClick = () => {
        setOpen(false);
        const { href } = window.location;
        const impersonationUrl = window.clientConfig.NX_IMPERSONATION_URL;
        window.location.replace(`${impersonationUrl}?action=start&returnUrl=${href}`);
    };

    const onSwitchAccountClick = () => {
        setOpen(false);
        const { href } = window.location;
        const userPortalUrl = `${window.clientConfig.NX_USER_PORTAL_URL}/accounts?action=switch&returnUrl=${decodeURIComponent(href)}`;
        window.location.replace(userPortalUrl);
    };

    const [value, setValue] = useState('');
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const languagesMap = _.keyBy(languages, 'key');

    const languageSubMenu = _.map(languagesMap, (language) => ({
        name: language.key,
        label: language.name,
        action: () => onChangeLanguage(language.key),
        isSelected: languageKey === language.key,
        icon: <img src={`/assets/flags/${language.key}.svg`} />,
    }));

    const createItems = useMemo(() => {
        const items: AccountMenuItem[] = [];

        if ((user && user.isBackoffice) || (user && user.impersonator)) {
            items.push({
                label: t('top-bar.impersonate'),
                action: onImpersonateClick,
                icon: <img alt={'more'} src={`/assets/Incogito.svg`} />,
            });
        }

        if (user && user.hasAssociatedAccounts) {
            items.push({
                label: t('top-bar.switch-account'),
                action: onSwitchAccountClick,
                icon: <img alt={'more'} src={`/assets/Reverse.svg`} />,
            });
        }

        items.push({
            label: languagesMap[languageKey].name,
            icon: languageKey ? <img src={`/assets/flags/${languageKey}.svg`} /> : <Native />,
            subMenu: languageSubMenu,
        });

        items.push({
            action: () => onSignOut(),
            label: t('top-bar.sign-out'),
            icon: <img alt={'more'} src={`/assets/SignOut.svg`} />,
        });

        return items;
    }, [user, userData, onSignOut]);

    if (loading) {
        return (
            <Container>
                <Skeleton variant='rect' width={200} height={20} style={{ marginRight: '10px' }} />
                <Skeleton variant='circle' width={40} height={40} />
            </Container>
        );
    }

    return (
        <>
            <AccountsMenu
                userMail={user.email}
                accountName={user.account.name}
                userFullName={user.firstName + ' ' + user.lastName}
                items={createItems}
            />
        </>
    );
};
