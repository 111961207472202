import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        height: '40px',
        width: '150px',
        borderRadius: '5px',
        padding: '8px 16px',
        alignItems: 'center',
    },
    selectedEnvContainer: {
        background: '#F4F6FB',
    },
    name: {
        paddingLeft: '10px',
    },
    cursorNotAllowed: {
        cursor: 'not-allowed',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}));

export { useStyles };
