import React from 'react';
import { Dialog } from '../../lib/dialog/dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@walkme-admin-center/libs/ui-components';
import styled from 'styled-components';

export interface ConfirmProps {
    closeConfirm: () => void;
    showConfirm: boolean;
    isLoadinData?: boolean;
    confirm: () => void;
    title?: string;
    view?: string;
    content?: string | JSX.Element;
}

const StyledContent = styled(DialogContent)`
    &.MuiDialogContent-root:first-child {
        padding-top: 0;
    }
`;

const StyledActions = styled(DialogActions)`
    justify-content: space-around;
    padding: 24px;
`;

export const Confirm = ({
    closeConfirm,
    showConfirm,
    confirm,
    title,
    view = 'default',
    content,
    isLoadinData = false,
}: ConfirmProps) => {
    return (
        <Dialog onClose={closeConfirm} showDialog={showConfirm} title={title} view={view}>
            <>
                <StyledContent>{content}</StyledContent>
                <StyledActions>
                    <Button
                        loading={isLoadinData}
                        disabled={isLoadinData}
                        onClick={confirm}
                        text="Yes"
                        color="primary"
                        customClass="confirm yes"
                    />
                    <Button onClick={closeConfirm} text="No" customClass="confirm no" />
                </StyledActions>
            </>
        </Dialog>
    );
};

export default Confirm;
