export enum PackageType {
    FULL = 0,
    CONTENT_ONLY = 4,
}

export type DownloadPackageDto = {
    systemId: number;
    userId: number;
    envId: number;
    packageType: PackageType;
};
