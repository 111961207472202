import { useState, useEffect } from 'react';

const useDelayedMemo = <T>(value: T, delayMs: number, condition: (currentValue: T) => boolean = () => true) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (condition(currentValue)) {
            timeout = setTimeout(() => setCurrentValue(value), delayMs);
        } else {
            setCurrentValue(value);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [value]);

    return currentValue;
};

export default useDelayedMemo;
