import { Typography } from '@material-ui/core';
import { PageTitle } from '@walkme-admin-center/libs/shared-styles';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ExternalIdpsList from '../external-idp/external-idps-list';
import ImpersonationSettings from '../impersonation-configuration/impersonation-settings';
import SocialLoginPage from '../social-login/social-login';
import MfaSettingsPage from '../mfa-settings/mfa-settings';
import SessionLifetime from '../session-lifetime/session-lifetime';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

const StyledTitleDiv = styled.div`
    margin-bottom: 24px;
`;

export const MainSecurityConfigurationPage = () => {
    const { t } = useTranslation('general');
    const { accountFeatureEnabled } = useLoggedInUser();
    const impRestrictionSettingsEnabled = accountFeatureEnabled('impRestrictionSettings');

    useEffect(() => {
        document.title = t('security-tab.document-title');
    }, []);

    return (
        <div>
            <StyledTitleDiv>
                <PageTitle>{t('security-tab.title')}</PageTitle>
                <Typography style={{ fontFamily: 'ProximaNova', color: '#7680A3', fontSize: '14px' }}>
                    {t('security-tab.description')}
                </Typography>
            </StyledTitleDiv>
            <ExternalIdpsList accountFeatureEnabled={accountFeatureEnabled} />
            <SocialLoginPage />
            <MfaSettingsPage />
            <SessionLifetime />
            {impRestrictionSettingsEnabled && <ImpersonationSettings />}
        </div>
    );
};

export default MainSecurityConfigurationPage;
