import React, { useState, MouseEvent, ChangeEvent, useRef } from 'react';
import { DatePicker } from './date-range-picker';
import { DateRange } from 'react-day-picker';
import moment from 'moment';
import { Close as Clear } from '@walkme/ui-icons';
import { RolesSelectorDiv, DateRangeText, RoleButtonText, StyledSelector, StyledClear } from './styles/date-filter.styles';
import { DropdownChevronDisplayOptions } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface DateFilterProps {
    filterDate: DateRange;
    setSelectedFilterDate: (newValue: DateRange) => void;
}

export function DateFilter(props: DateFilterProps) {
    const { t } = useTranslation('general');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const onDateRangeChange = (dateRange: DateRange) => {
        props.setSelectedFilterDate(dateRange);
        handleClose();
    };

    const resetDateFilter = (e) => {
        e.stopPropagation();
        props.setSelectedFilterDate(null);
    };

    const SelectorDisplayedValue = (
        <RolesSelectorDiv>
            <RoleButtonText>{t('common.last-login')}</RoleButtonText>
            {props.filterDate && (
                <>
                    <DateRangeText>
                        {moment(props.filterDate.from).format('ll')}-{moment(props.filterDate.to).format('ll')}
                    </DateRangeText>
                    <StyledClear onClick={(e) => resetDateFilter(e)} />
                </>
            )}
        </RolesSelectorDiv>
    );

    const chevronDisplayOption = props.filterDate ? DropdownChevronDisplayOptions.HIDE_CHEVRON_DOWN : DropdownChevronDisplayOptions.DEFAULT;

    return (
        <div>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
            />
            <DatePicker
                isOpen={Boolean(anchorEl)}
                onClose={handleClose}
                onSelect={onDateRangeChange}
                anchorRef={{
                    current: anchorEl,
                }}
            />
        </div>
    );
}
