import { Tooltip } from '@walkme/ui-core';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
    .tooltip {
        fontsize: 12px;
        background: #2f3d53;
        padding: 8px 12px;
    }

    .arrow {
        color: #2f3d53;
    }
`;
