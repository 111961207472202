import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { Box, MenuItem, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const Label = styled('label')`
    padding: 0 0 8px;
    line-height: 1.5;
    display: block;
    margin-right: 18px;
`;

const StyledButtonWrapper = styled.div`
    margin-left: 10px;
    display: inline-block;
`;

const StyledContainer = styled.span`
    float: right;
    margin-top: 7px;
`;

const StyledTableCell = styled(TableCell)`
    padding-left: 26px;
    padding-right: 26px;
`;

export interface FooterButtonsConfig {
    text: string;
    hidden?: boolean;
    customClass?: string;
    disabled?: boolean;
    loading?: boolean;
    tooltipText?: React.ReactNode;
    tooltipWidth?: number;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface FooterLocale {
    showing: string;
    of: string;
    rowsPerPage: string;
}

const defaultFooterLocale: FooterLocale = {
    showing: 'Showing',
    of: 'of',
    rowsPerPage: 'Rows per Page',
};

interface TableFooterProps {
    rowsPerPageOptions: Array<number>;
    rowsLength: number;
    rowsPerPage: number;
    currentPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    colsNumber: number;
    buttons: Array<FooterButtonsConfig>;
    message?: string | JSX.Element;
    serverSideRender?: boolean;
    jobsCount?: number;
    footerLocale?: FooterLocale;
}

export const Footer = ({
    rowsPerPageOptions,
    rowsLength,
    rowsPerPage,
    currentPage,
    onChangePage,
    onChangeRowsPerPage,
    colsNumber,
    buttons,
    message,
    serverSideRender,
    jobsCount,
    footerLocale,
}: TableFooterProps) => {
    const [rowsNumPerPage, setRowsNumPerPage] = React.useState(rowsPerPage);
    const firstRow = serverSideRender
        ? currentPage === 1
            ? currentPage
            : (currentPage - 1) * rowsNumPerPage + 1
        : rowsNumPerPage * currentPage + 1;
    const lastRow = serverSideRender
        ? rowsNumPerPage * currentPage > jobsCount
            ? jobsCount
            : rowsNumPerPage * currentPage
        : rowsNumPerPage * (currentPage + 1) > rowsLength
        ? rowsLength
        : rowsNumPerPage * (currentPage + 1);
    const pagesCount = rowsLength % rowsNumPerPage ? Math.floor(rowsLength / rowsNumPerPage) + 1 : rowsLength / rowsNumPerPage;

    const handleChange = (event) => {
        setRowsNumPerPage(event.target.value);
        onChangeRowsPerPage(event);
    };

    const locale = {
        ...defaultFooterLocale,
        ...(footerLocale || {}),
    };

    return (
        <TableFooter>
            <TableRow>
                <StyledTableCell colSpan={colsNumber}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <Label>
                            {locale.showing} {firstRow}-{lastRow} {locale.of} {rowsLength}
                        </Label>
                        <Pagination
                            size='small'
                            page={serverSideRender ? currentPage : undefined}
                            count={pagesCount}
                            color='primary'
                            onChange={onChangePage}
                        />
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                            <Label>{`${locale.rowsPerPage}: `}</Label>
                            <Select value={rowsNumPerPage} onChange={handleChange}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </StyledTableCell>
            </TableRow>
        </TableFooter>
    );
};

export default Footer;
