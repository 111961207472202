import styled from 'styled-components';
import { Button } from '@walkme/ui-core';

export const StyledCreateSystemText = styled.div`
    color: var(--Typography-Typography-MainPrimary-Main, #2f426c);
    font-weight: 400;
`;

export const StyledSecondaryButton = styled(Button)`
    color: var(--Typography-Typography-tint-1, #637191) !important;
`;

export const StyledPrimaryButton = styled(Button)`
    margin-left: 20px !important;
`;

export const StyledInputLabel = styled.div`
    display: flex;
    height: var(--size-global-s-8040, 40px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-top: 24px;
`;
