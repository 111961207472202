import React, { useCallback, useMemo } from 'react';
import cc from 'classcat';
import classes from '../ai-usage-page.module.scss';
import { CssWMDataGrid } from '../ai-usage-page';
import { ErrorColor } from '@walkme/ui-icons';
import { DataGridModules } from '@walkme/wm-ui';
import { Walkme } from '@walkme/ui-icons/large';
import { Systems } from 'wm-accounts-sdk';
import { ICapViewRowsData, IIntegrations } from '../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/types';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { SystemsTypes } from '../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/types/enums';
import { retrieveSlackIcon, retrieveTeamsIcons } from '../service';

const CapViewTable = ({
    loading,
    data,
    allSystems,
    integrations,
}: {
    loading: boolean;
    data: ICapViewRowsData;
    allSystems: Systems;
    integrations: IIntegrations;
}) => {
    const onGridReady = useCallback((event) => {
        const columnState = {
            state: [
                {
                    colId: 'usage_expense',
                    sort: 'desc',
                    sortIndex: '0',
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }, []);

    const onColumnVisible = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);

    const systemFilterCellRenderer = useCallback(
        (params) => {
            if (!params.value) return '(Blanks)';
            if (params.value === 'Select All') {
                return params.value;
            }
            let systemDisplayName = params.value;
            const defaultIcon = <Walkme />;
            let systemIcon;
            const systemFound = allSystems.find((system) => system.displayName === params.value);
            if (systemFound) {
                const icon = retrieveSystemIcon(systemFound.settings);
                systemDisplayName = systemFound.displayName;
                systemIcon = icon ? <img src={icon} width='20px' height='20px' /> : defaultIcon;
            } else {
                const integrationFound = integrations.find((integration) => integration.name === params.value);
                if (integrationFound) {
                    systemDisplayName = integrationFound.name;
                    systemIcon =
                        integrationFound.system_type === SystemsTypes.SLACK ? (
                            <img src={retrieveSlackIcon()} width='20px' height='20px' />
                        ) : integrationFound.system_type === SystemsTypes.TEAMS ? (
                            <img src={retrieveTeamsIcons()} width='20px' height='20px' />
                        ) : (
                            <Walkme />
                        );
                }
            }

            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                    {systemIcon}
                    <span
                        style={{
                            marginLeft: '5px',
                        }}>
                        {systemDisplayName}
                    </span>
                </div>
            );
        },
        [allSystems, integrations]
    );

    const columnDefs = useMemo(() => {
        return [
            {
                field: 'systemName',
                headerName: 'System Name',
                sortable: true,
                sortingOrder: ['desc', 'asc'] as ('asc' | 'desc')[],
                filter: 'agSetColumnFilter',
                cellRendererFramework: (params: any) => {
                    const { systemName, systemIconPath } = params.data;
                    return (
                        <div className={cc([classes.flexContainer, classes.row, classes.center])}>
                            {systemIconPath ? <img height={20} width={20} src={systemIconPath} /> : <Walkme />}
                            <span style={{ paddingLeft: '12px' }}>{systemName}</span>
                        </div>
                    );
                },
                filterParams: {
                    cellRenderer: systemFilterCellRenderer,
                },
            },
            {
                field: 'usageExpense',
                colId: 'usage_expense',
                headerName: 'Usage Expense',
                sortable: true,
                sortingOrder: ['desc', 'asc'] as ('asc' | 'desc')[],
                maxWidth: 307,
                cellRendererFramework: (params: any) => {
                    return <span>${params.value.toLocaleString()}</span>;
                },
            },
            {
                field: 'monthlyCap',
                headerName: 'Monthly Cap',
                maxWidth: 132,
                sortable: true,
                sortingOrder: ['desc', 'asc'] as ('asc' | 'desc')[],
                cellRendererFramework: (params: any) => {
                    if (params?.value) {
                        return <span>${params.value.toLocaleString()}</span>;
                    } else {
                        return <span>-</span>;
                    }
                },
            },
            {
                field: 'errorColumn',
                headerName: '',
                maxWidth: 56,
                minWidth: 56,
                cellRendererFramework: (params: any) => {
                    if (params.data.monthlyCap) {
                        if (params.data.usageExpense >= params.data.monthlyCap) {
                            return <ErrorColor />;
                        } else {
                            return <span></span>;
                        }
                    } else {
                        return <span></span>;
                    }
                },
            },
        ];
    }, [systemFilterCellRenderer]);

    const getGridConfig = useMemo(() => {
        return {
            modules: [...DataGridModules],
            animateRows: true,
            onGridReady,
            onColumnVisible: onColumnVisible,
            onFirstDataRendered: onColumnVisible,
        };
    }, [onGridReady, onColumnVisible]);

    return (
        <div style={{ marginTop: '20px', height: '576px' }}>
            <CssWMDataGrid
                loadingData={loading}
                gridConfig={getGridConfig}
                hideColumnPicker
                hideExport
                entityName={'Cap View'}
                columnDefs={columnDefs}
                title={
                    <div className={cc([classes.flexContainer, classes.column])}>
                        <span>Cap View</span>
                        <span className={classes.tableSubHeader}>Presenting information for all AI Features in your account</span>
                    </div>
                }
                rows={data}
            />
        </div>
    );
};

export default CapViewTable;
