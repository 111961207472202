import React, { SVGProps } from 'react';

export const EmployeeCardIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
        <path d='M13.5 8.5H15.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5 10.5H16.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
        <path
            d='M5.5 9C5.5 10.3807 6.61929 11.5 8 11.5C8.34905 11.5 8.68139 11.4285 8.98316 11.2993C9.87509 10.9174 10.5 10.0317 10.5 9C10.5 7.61929 9.38071 6.5 8 6.5C6.61929 6.5 5.5 7.61929 5.5 9Z'
            stroke='#6D81A6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <rect x='1.5' y='3.5' width='17' height='13' rx='1.5' stroke='#6D81A6' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.7715 14C11.5818 14 11.4086 13.8972 11.3013 13.7407C10.5806 12.6895 9.37082 12 8.00003 12C6.62924 12 5.4195 12.6895 4.69874 13.7407C4.59142 13.8972 4.41829 14 4.22852 14C3.84524 14 3.59215 13.6069 3.80053 13.2852C4.6914 11.91 6.23945 11 8.00003 11C9.76061 11 11.3087 11.91 12.1995 13.2852C12.4079 13.6069 12.1548 14 11.7715 14Z'
            fill='#6D81A6'
        />
    </svg>
);
