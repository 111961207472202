import styled from 'styled-components';
import { styledTheme } from '../ui-theme/ui-theme';

const {
    typography: { fontWeight },
    colors: { main },
} = styledTheme;

export const Main = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .mainPage {
        height: calc(100% - 56px);
        height: -webkit-calc(100% - 56px);
        height: -moz-calc(100% - 56px);
        display: flex;
        flex-direction: row;

        .mainContent {
            padding: 32px;
            flex-grow: 1;
            overflow: auto;
        }

        .mainContent > .noPadding {
            height: 100%;
        }
    }

    h1 {
        color: ${main.mainTitle};
        font-weight: ${fontWeight.medium};
    }

    h3 {
        color: ${main.mainTitle};
        font-weight: ${fontWeight.medium};
    }
`;

export default Main;
