import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SystemsAutomatedActions from './systems-automated-actions';

export const SystemAutomatedActionsShell = () => {
    const navigate = useNavigate();
    return (
        <div>
            <SystemsAutomatedActions
                showDialog={true}
                onFinish={(message) => {
                    navigate(`/systems`);
                }}
                onCancel={() => {
                    navigate(`/systems`);
                }}
            />
        </div>
    );
};

export default SystemAutomatedActionsShell;
