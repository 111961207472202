import React, { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppData } from '@walkme-admin-center/libs/types';
import {
    ActionTypeDetails,
    SubscriptionStatusActionType,
    SubscriptionStatusType,
    Systems,
    SystemsSettings,
    SystemStatusDetails,
} from 'wm-accounts-sdk';
import { WMButtonVariant, WMDialog, WMSnackbar, WMSnackbarVariant, WMTooltip } from '@walkme/wm-ui';
import { generateTextBox } from './systems-page';
import { systemsSlice, SystemsState, updateSystemsStatus } from '@walkme-admin-center/libs/state-management-systems';
import { useUsers } from '@walkme-admin-center/libs/state-management-users';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { dateComparator } from '../../../../../libs/ui-components/src/lib/data-table/utils';
import {
    StyledGridTitle,
    StyledWMDataGrid,
    StyledAppIconContainer,
    StyledAppNameContainer,
    StyledTypography,
    RestoreButton,
    DialogText,
    RestorationDialog,
    RestoreItemsButton,
} from './styles/deleted-systems-page.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { useDatagridLocale } from 'packages/pages/util/locale-utils';
import { momentLocalesMapping } from 'apps/home/src/localization';

export interface DeletedSystemsPageProps {
    systems: Systems;
    systemsSettings: SystemsSettings;
    loadingAccountSystems: boolean;
}

export const DeletedSystemsPage = ({ pageData, loadingData }) => {
    const { t, rt, i18n } = useTranslation('general');

    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState('');
    const [openWarningSnackbar, setOpenWarningSnackbar] = useState('');
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState('');
    const [cleanupRowsSelection, setCleanupRowsSelection] = useState(false);
    const updateSystemsStatusAppData: AppData<SystemStatusDetails[]> = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.updateSystemsStatus
    );

    const [showRestoreSystemsDialog, setShowRestoreSystemsDialog] = useState(false);
    const [systemsToRestore, setSystemsToRestore] = useState([]);
    const dispatch = useDispatch();
    const { usersAppData } = useUsers();

    useEffect(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
    }, [i18n.language]);

    const datagridLocale = useDatagridLocale();

    const handleSnackBarSuccessClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSuccessSnackbar('');
    };

    const handleSnackBarWarningClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenWarningSnackbar('');
    };

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar('');
    };

    function systemKeyCreator(params) {
        const systemObject = params.value;
        return systemObject ? systemObject.name : null;
    }

    function systemFilterCellRenderer(params) {
        if (!params.value) return '(Blanks)';
        if (params.value === 'Select All') {
            return params.value;
        }
        const system = pageData.systemsSettingsAppData.data.find((system) => system.name == params.value);
        const systemDisplayName = system ? system.displayName : '';

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <img src={retrieveSystemIcon(system)} width='20px' height='20px' />
                <span
                    style={{
                        marginLeft: '5px',
                    }}>
                    {systemDisplayName}
                </span>
            </div>
        );
    }

    const deletedInFormatter = (params) => {
        return moment(params.data.statusDetails.deletionAt).format('MMMM Do YYYY');
    };

    const getApplicationIcon = (params) => {
        return params.value ? (
            <img src={retrieveSystemIcon(params.value)} />
        ) : (
            <>
                {params.data.systemTypeKey == 'Web' ? (
                    <img src={`/assets/icons/custom_type.png`} />
                ) : params.data.systemTypeKey == 'Mobile' ? (
                    <img src={retrieveSystemIcon(params.data)} />
                ) : (
                    <img src={`/assets/icons/Workstation.png`} />
                )}
                <span className={'system-type-key'}>
                    {params.data.systemTypeKey == 'Web'
                        ? t('systems-tab.systems-table.custom-type')
                        : params.data.systemTypeKey == 'Mobile'
                        ? params.data.name
                        : t('systems-tab.systems-table.workstation')}
                </span>
            </>
        );
    };

    const headCells = useMemo(() => {
        return [
            {
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                headerName: t('systems-tab.systems-table.name'),
                lockVisible: true,
                lockPosition: true,
                sortable: true,
                minWidth: 300,
                field: 'settings',
                cellRenderer: function (params) {
                    const checkbox = params.eGridCell.querySelector('div[ref="eCheckbox"] input');
                    const checkboxWrapper = params.eGridCell.querySelector('div[ref="eWrapper"]');
                    const isPlatform = !!params.data.associatedSystem;

                    if (checkbox && checkboxWrapper) {
                        if (!isRowSelectable(params.node)) {
                            checkboxWrapper.style.backgroundColor = '#f1f1f2';
                            checkbox.disabled = false;
                            checkbox.addEventListener('click', () =>
                                setOpenWarningSnackbar(t('systems-tab.deleted-systems-page.cant-restore-message'))
                            );
                        }
                        if (isPlatform) {
                            const associatedSystemIsPendingForDelete = pageData.pendingForDeleteSystems.some(
                                (system) => system.id === params.data.associatedSystem.id
                            );
                            if (associatedSystemIsPendingForDelete) {
                                checkbox.addEventListener('click', () => {
                                    if (checkbox.checked) {
                                        setOpenWarningSnackbar(t('systems-tab.deleted-systems-page.warning-message-restore-platform'));
                                    }
                                });
                            }
                        }
                    }

                    return (
                        <Box display='flex' flexDirection='row' alignItems='center' fontWeight={600}>
                            <StyledAppIconContainer>{getApplicationIcon(params)}</StyledAppIconContainer>

                            <StyledAppNameContainer>
                                <span>{rt(params.data.displayName)}</span>
                                <StyledTypography variant={'T30'}>{params.value.displayName}</StyledTypography>
                            </StyledAppNameContainer>
                        </Box>
                    );
                },
            },
            ...pageData.renderSharedColumns(),
            {
                headerName: t('systems-tab.systems-table.deleted-by'),
                minWidth: 200,
                sortable: true,
                getQuickFilterText: (params) => usersAppData.data.find((user) => user.id === params.data.statusDetails.updatedBy),
                cellRenderer: function (params) {
                    const user = usersAppData.data.find((user) => user.id === params.data.statusDetails.updatedBy);
                    const text = (user && user.email) || 'WalkMe admin';
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                            <WMTooltip title={text} arrow>
                                <span>{text}</span>
                            </WMTooltip>
                            {!user && (
                                <div style={{ paddingLeft: '8px' }}>
                                    <WMTooltip title={t('systems-tab.deleted-systems-page.cant-restore-tooltip')} arrow>
                                        <img
                                            src='assets/icons/icon-info.svg'
                                            alt='infoIcon'
                                            style={{ height: '1em', width: '1em', transform: 'translateY(15%)' }}
                                        />
                                    </WMTooltip>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                headerName: t('systems-tab.systems-table.permanent-deletion-date'),
                minWidth: 200,
                sortable: true,
                valueGetter: deletedInFormatter,
                comparator: dateComparator('data.statusDetails.deletionAt'),
            },
        ];
    }, [usersAppData.data]);

    const footerActions = [
        {
            onClick: (rows) => {
                handleSystemsRestoreClick(rows);
            },
            icon: <img src={`/assets/icons/restore-icon.svg`} width='20px' height='20px' />,
            title: t('buttons.restore'),
            component: RestoreButton,
            id: 'test-restore-system-button',
        },
    ];

    const handleSystemsRestoreClick = (rowsToRestore) => {
        rowsToRestore.forEach((system) => {
            if (system.associatedSystem) {
                const alreadyInList = rowsToRestore.some((row) => row.id === system.associatedSystem.id);
                const associatedSystem = pageData.pendingForDeleteSystems.find((row) => row.id === system.associatedSystem.id);
                if (!alreadyInList && associatedSystem) {
                    rowsToRestore.push(associatedSystem);
                }
            }
        });
        setSystemsToRestore(rowsToRestore);
        setShowRestoreSystemsDialog(true);
    };

    const onColumnVisible = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);

    const getRowId = useCallback((params) => params.data.id, []);

    const isRowSelectable = useMemo(() => {
        return (params) => {
            return params.data.statusDetails.canBeRestored != false;
        };
    }, []);

    const onGridReady = useCallback((event) => {
        const columnState = {
            state: [
                {
                    colId: 'isRestricted',
                    sort: 'asc',
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }, []);

    const gridConfig = useMemo(() => {
        return {
            isRowSelectable: isRowSelectable,
            getRowId,
            onColumnVisible: onColumnVisible,
            onFirstDataRendered: onColumnVisible,
            suppressRowHoverHighlight: true,
            onGridReady,
            getRowStyle: (params) => {
                if (params.data?.restrictedAccess) {
                    return { opacity: 0.5, pointerEvents: 'none' };
                }
            },
        };
    }, []);

    const handleRestoring = async () => {
        const systems = systemsToRestore.map((system) => ({
            systemId: system.id,
            systemGuid: system.guid,
        }));
        await restoreSystems(systems, SubscriptionStatusType.enabled, true, SubscriptionStatusActionType.self, ActionTypeDetails.admin);
    };

    useEffect(() => {
        setCleanupRowsSelection(false);
        if (updateSystemsStatusAppData.data.length > 0) {
            const message =
                systemsToRestore.length > 1
                    ? t('systems-tab.deleted-systems-page.success-message-restore-plural', { amount: systemsToRestore.length.toString() })
                    : t('systems-tab.deleted-systems-page.success-message-restore-singal');
            setOpenSuccessSnackbar(message);
            dispatch(systemsSlice.actions.cleanupUpdateSystemsStatusStart());
            setCleanupRowsSelection(true);
            setShowRestoreSystemsDialog(false);
        } else if (updateSystemsStatusAppData.error) {
            setOpenErrorSnackbar(updateSystemsStatusAppData.error);
            dispatch(systemsSlice.actions.cleanupUpdateSystemsStatusStart());
        }
    }, [updateSystemsStatusAppData]);

    const restoreSystems = useCallback(
        (systems, status, canBeRestored, actionType, actionTypeDetails) => {
            dispatch(updateSystemsStatus(systems, status, canBeRestored, actionType, actionTypeDetails));
        },
        [dispatch]
    );

    const triggerRestoreSystemsDialog = useCallback(() => {
        const buttons = [
            {
                children: t('buttons.cancel'),
                variant: WMButtonVariant.Text,
                style: { marginRight: '1.6vw', color: '#637191' },
                id: 'test-cancel-system-button',
                onClick: () => setShowRestoreSystemsDialog(false),
            },
            {
                children: t('buttons.restore'),
                onClick: () => handleRestoring(),
                component: RestoreItemsButton,
                loading: updateSystemsStatusAppData.loading,
                id: 'test-restore-system-button-modal',
            },
        ];
        const title =
            systemsToRestore.length === 1
                ? t('systems-tab.deleted-systems-page.restore-system-form.title')
                : t('systems-tab.deleted-systems-page.restore-system-form.title-plural', { amount: systemsToRestore.length.toString() });
        return (
            <RestorationDialog
                ds2
                open={showRestoreSystemsDialog}
                onClose={() => setShowRestoreSystemsDialog(false)}
                title={title}
                buttons={buttons}
                disableHeaderDivider={true}>
                <DialogText>{t('systems-tab.deleted-systems-page.restore-system-form.dialog')}</DialogText>
                {generateTextBox(
                    '#F7B500',
                    '#FEF8E8',
                    <Typography>
                        {
                            <span style={{ fontWeight: 600 }}>
                                {t('systems-tab.deleted-systems-page.restore-system-form.text-box-1')}
                                <br />
                            </span>
                        }
                        {t('systems-tab.deleted-systems-page.restore-system-form.text-box-2')}
                    </Typography>,
                    <img src={`/assets/icons/icon-error-triangle.svg`} width='20px' height='20px' style={{ paddingRight: '12px' }} />
                )}
            </RestorationDialog>
        );
    }, [showRestoreSystemsDialog, systemsToRestore, updateSystemsStatusAppData]);

    return (
        <>
            <WMSnackbar
                open={openSuccessSnackbar ? true : false}
                onClose={handleSnackBarSuccessClose}
                variant={WMSnackbarVariant.Success}
                message={openSuccessSnackbar}
            />

            <WMSnackbar
                open={openWarningSnackbar ? true : false}
                onClose={handleSnackBarWarningClose}
                variant={WMSnackbarVariant.Warning}
                message={openWarningSnackbar}
            />

            <WMSnackbar
                open={openErrorSnackbar ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={openErrorSnackbar}
            />

            {triggerRestoreSystemsDialog()}
            <StyledWMDataGrid
                title={<StyledGridTitle>{t('systems-tab.deleted-systems-page.title')}</StyledGridTitle>}
                locale={datagridLocale}
                rows={pageData.pendingForDeleteSystems}
                columnDefs={headCells}
                hideExport
                entityName={t('systems-tab.systems-table.entity-name')}
                gridConfig={gridConfig}
                loadingData={usersAppData.loading || loadingData}
                columnPickerOptions={{ persistColumnIds: ['displayName', 'settings', 'systemTypeKey'] }}
                supressResetOnNewData={true}
                footerActions={footerActions}
                clearRowSelection={cleanupRowsSelection}
            />
        </>
    );
};
