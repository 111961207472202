export enum SelfHostedMethod {
    AUTO_DETECT = 'AutoDetect',
    PRE_DEFINED = 'PreDefined',
}

export type EnvironmentPackageSettings = {
    hostingMethod: SelfHostedMethod;
    deploymentUrl: string;
    mirrorExtensionUrl: boolean;
};

export type EnvironmentSettings = {
    deployment: {
        url: string;
        cacheUpdateTime: number;
        linkedToExtension: boolean;
        isUpdateEnabled: boolean;
        isActive: boolean;
    };
    package: EnvironmentPackageSettings;
    addGuidToPath?: boolean;
};
