import { CONSTS } from '../../../common/consts';
import React, { useState } from 'react';
import { Provider, ProviderGroup, ProviderGroups } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SelectWrapper, StyledCustomMenuItem, StyledInputLabel } from '../../../common/style';
import { useForm } from 'react-final-form';
import { StyledProviderForm, StyledSelect, StyledToggleButton } from './provider-form-protocol-and-type-style';

export interface ProviderFormProtocolAndTypeProps {
    provider?: Provider;
    providerGroups: ProviderGroups;
    idpType: ProviderGroup;
    setIdpType: (group: ProviderGroup) => void;
}

export const ProviderFormProtocolAndType = ({ provider, providerGroups, idpType, setIdpType }: ProviderFormProtocolAndTypeProps) => {
    const [protocol, setProtocol] = useState(idpType.name === CONSTS.SAML ? CONSTS.SAML : CONSTS.OAUTH);

    const protocolOptions = [
        { displayName: 'OAuth 2.0', value: CONSTS.OAUTH },
        {
            displayName: 'SAML 2.0',
            value: CONSTS.SAML,
        },
    ];

    const formApi = useForm();
    const idpTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const group: ProviderGroup = providerGroups.find((group) => group.name === event.target.value);
        formApi.setConfig('keepDirtyOnReinitialize', false);
        formApi.restart();
        formApi.setConfig('keepDirtyOnReinitialize', true);
        setIdpType(group);
    };

    const protocolChange = (event, value): void => {
        if (value) {
            if (value === CONSTS.SAML) {
                setIdpType(providerGroups.find((group) => group.name === CONSTS.SAML));
            } else {
                setIdpType({
                    name: 'okta',
                    displayName: 'Okta',
                    id: 1,
                });
            }
            formApi.setConfig('keepDirtyOnReinitialize', false);
            formApi.restart();
            formApi.setConfig('keepDirtyOnReinitialize', true);
            setProtocol(value);
        }
    };

    return (
        <StyledProviderForm>
            <StyledInputLabel>Select Protocol</StyledInputLabel>
            <ToggleButtonGroup value={protocol} exclusive onChange={protocolChange}>
                {protocolOptions.map((item, index) => {
                    return (
                        <StyledToggleButton
                            disabled={!!provider}
                            key={index}
                            classes={{
                                root: 'toggleButtonRoot',
                                selected: 'toggleButtonSelected',
                            }}
                            value={item.value}
                            aria-label={item.value}>
                            {item.displayName}
                        </StyledToggleButton>
                    );
                })}
            </ToggleButtonGroup>
            {protocol && protocol === CONSTS.OAUTH && (
                <div className={'typeContainer'}>
                    <StyledInputLabel>Select Vendor</StyledInputLabel>
                    <SelectWrapper>
                        <StyledSelect
                            MenuProps={{
                                PopoverClasses: {
                                    root: 'selectMenuItem',
                                },
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                            }}
                            disabled={!!provider}
                            variant={'outlined'}
                            value={idpType.name}
                            onChange={idpTypeChange}
                            inputProps={{ name: 'idpType' }}>
                            {providerGroups
                                .filter((group) => group.name !== CONSTS.SAML)
                                .map((group) => {
                                    return (
                                        <StyledCustomMenuItem disableRipple key={group.name} value={group.name}>
                                            {group.displayName}
                                        </StyledCustomMenuItem>
                                    );
                                })}
                        </StyledSelect>
                    </SelectWrapper>
                </div>
            )}
        </StyledProviderForm>
    );
};

export default ProviderFormProtocolAndType;
