import React, { useEffect, useMemo } from 'react';
import { StyledConfigureContent } from './configure-content.styles';
import { ConfigureTabs } from './configure.lib';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { AssignUsersTable } from './assign-users-table';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { UUID } from './uuid/uuid';
import { UUID as UUIDEnum, UUIDOptions } from './uuid/uuid.lib';
import { SelectDeploymentStep } from '../select-deployment/select-deployment';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { predefinedUUIDRecommendedValues } from './uuid/uuid.lib';
import { DataCollection } from './data-collection/data-collection';
import { Accessibility } from './accessibility/accessibility';
import { idpApi } from '@walkme-admin-center/libs/idp-api';

interface ConfigureContentProps {
    activeTab: number;
}

export const ConfigureContent = ({ activeTab }: ConfigureContentProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const selectedUUID = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedUUID);
    const { loggedInUserAppData } = useLoggedInUser();

    const setName = (name: string) => {
        dispatch(createSystemSlice.actions.setSelectedSystemNameConfigureStep(name));
    };

    const setDefaultUUIDSelection = async () => {
        try {
            // check if the account has a default IDP provider - first priority for a default UUID
            const accountGuid = loggedInUserAppData?.data?.account?.guid;
            const defaultIdpInfo = await idpApi.getAccountDefaultIdpProvider(accountGuid);
            if (defaultIdpInfo?.providerId) {
                const varOption = UUIDOptions.find((option) => option.value === UUIDEnum.IDP);
                dispatch(createSystemSlice.actions.setSelectedUUID(varOption));
                return;
            }
        } catch (e) {}

        const systemDefaultPredefinedUUID = predefinedUUIDRecommendedValues[createdSystem?.type]?.[0];

        if (systemDefaultPredefinedUUID) {
            switch (
                systemDefaultPredefinedUUID.method
                // NOTICE: we may need that in the near future
                // case UUIDEnum.SALESFORCE:
                //     const varOption = UUIDOptions.find((option) => option.value === UUIDEnum.SALESFORCE);
                //     dispatch(createSystemSlice.actions.setSelectedUUID(varOption));
                //     break;
                // case UUIDEnum.VARIABLE:
                //     const varOption = UUIDOptions.find((option) => option.value === UUIDEnum.VARIABLE);
                //     dispatch(createSystemSlice.actions.setSelectedUUID(varOption));
                //     break;
                // case UUIDEnum.JQUERY:
                //     const jqueryOption = UUIDOptions.find((option) => option.value === UUIDEnum.JQUERY);
                //     dispatch(createSystemSlice.actions.setSelectedUUID(jqueryOption));
                //     break;
                // case UUIDEnum.COOKIE:
                //     const cookieOption = UUIDOptions.find((option) => option.value === UUIDEnum.COOKIE);
                //     dispatch(createSystemSlice.actions.setSelectedUUID(cookieOption));
                //     break;
            ) {
            }
        }
    };

    useEffect(() => {
        // set default UUID only if the user didn't select one yet
        if (selectedUUID?.id === -1) {
            setDefaultUUIDSelection();
        }
    }, [loggedInUserAppData, selectedUUID]);

    useEffect(() => {
        if (createdSystem?.displayName) {
            setName(createdSystem.displayName);
        }
    }, [createdSystem?.displayName]);

    const AssignUsersContent = useMemo(() => {
        return <AssignUsersTable />;
    }, [activeTab === ConfigureTabs.ASSIGN_USERS, createdSystem]);

    let content;
    switch (activeTab) {
        case ConfigureTabs.DEPLOYMENT_METHOD:
            content = <SelectDeploymentStep />;
            break;

        case ConfigureTabs.UUID:
            content = <UUID />;
            break;
        case ConfigureTabs.DATA_COLLECTION_LEVEL:
            content = <DataCollection />;
            break;
        case ConfigureTabs.ASSIGN_USERS:
            content = AssignUsersContent;
            break;
        case ConfigureTabs.ACCESSIBILITY:
            content = <Accessibility />;
            break;
        default:
            content = <></>;
    }

    return <StyledConfigureContent activeTab={activeTab}>{content}</StyledConfigureContent>;
};
