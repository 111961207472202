import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const StyledIconButton = styled(IconButton)`
    color: inherit;
    font-size: 1.9rem;
    padding: 3px;

    .MuiSvgIcon-root {
        font-size: inherit;
    }
`;
