import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExistingUserConfiguration, UUIDState } from '@walkme-admin-center/libs/state-management-systems';

export const useExistingSystemSiteConfiguration = (systemId: number) => {
    const siteConfiguration = useSelector((state: { uuidState: UUIDState }) => state.uuidState.siteConfiguration);
    const dispatch = useDispatch();

    const fetchConfig = (forceLoad = false) => {
        if (systemId) {
            dispatch(fetchExistingUserConfiguration(forceLoad, systemId));
        }
    };

    useEffect(fetchConfig, [dispatch, systemId]);

    return {
        siteConfiguration,
        fetchConfig,
    };
};

export default useExistingSystemSiteConfiguration;
