import styled from 'styled-components';
import { Divider, DropdownSelection } from '@walkme/ui-core';
import { createStyles, makeStyles } from '@material-ui/core';

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 40px;
        width: 430px;
        margin: 0;
        outline: none !important;
    }
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
    color: ${({ isSelected }) => (isSelected ? '#385FEB' : '#2F426C')};

    &:hover {
        background: var(--Primaries-Blue-P8, #f5f7fe);
    }
`;
