import React, { useEffect, useState } from 'react';
import { StyledMenuItem, StyledSubMenu } from './sub-menu.styles';
import { ChevronDown, ChevronUp } from '@walkme/ui-icons/large';
import { useTranslation } from '../../../../../../../../../apps/home/src/localization/localizationBase';

export const SubMenu = ({ items, activeTab, isOpen, setIsOpen }) => {
    const { t } = useTranslation('general');

    const toggleIsOpen = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    const handleActiveTabChanged = () => {
        if (items.some((item) => item.id === activeTab)) {
            setIsOpen(true);
        }
    };

    useEffect(handleActiveTabChanged, [activeTab]);

    return (
        <StyledSubMenu isOpen={isOpen}>
            <div className='header' onClick={toggleIsOpen}>
                {t(`systems-tab.new-system-form.configure-step.side-menu.data-settings.title`)}
                {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
            {isOpen &&
                items?.map((item, index) => {
                    return (
                        <StyledMenuItem key={index} className='menuItem' onClick={item.onClick} isSelected={item.id === activeTab}>
                            {t(item.translationKey)}
                        </StyledMenuItem>
                    );
                })}
        </StyledSubMenu>
    );
};
