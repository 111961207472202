import React, { useCallback, useMemo, useState } from 'react';
import { compose } from '@reduxjs/toolkit';
import { PageTitle, RouteHeader } from '@walkme-admin-center/libs/shared-styles';
import { createStyles, makeStyles, Paper, Grid } from '@material-ui/core';
import {
    DesktopMacIdentifier,
    DesktopTrackedApp,
    desktopTrackedAppsSlice,
    DesktopWinIdentifier,
    insightsApi,
    PredefinedDesktopIdentifier,
    useDesktopTrackedApps,
    PredefinedDesktopTrackedApps,
} from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { Search, Button, EmptySearch } from '@walkme-admin-center/libs/ui-components';
import AddIcon from '@material-ui/icons/Add';
import { InteractiveDesktopApp } from './desktop-app';
import { PredefinedDesktopTrackedApp } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDesktopPredefinedApps } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { LoadingPage } from '@walkme-admin-center/libs/common';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { DesktopIdentifierOs } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { FormActions as IFormActions } from '@walkme-admin-center/libs/types';
import BottomSection from './bottom-section';

export const withData = (WrappedComponent) => () => {
    const { loggedInUserAppData } = useLoggedInUser();
    const { desktopTrackedAppsData } = useDesktopTrackedApps(loggedInUserAppData.data.account.id);
    const { desktopPredefinedAppsData } = useDesktopPredefinedApps();

    if (desktopPredefinedAppsData.loading || desktopTrackedAppsData.loading) {
        return <LoadingPage />;
    }

    return (
        <WrappedComponent
            predefinedApps={desktopPredefinedAppsData.data}
            appsData={desktopTrackedAppsData.data}
            msaId={loggedInUserAppData.data.account.id}
        />
    );
};

interface AddDesktopTrackedAppProps {
    appsData: DesktopTrackedApp[];
    msaId: number;
    predefinedApps: PredefinedDesktopTrackedApps;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        gridList: {
            padding: '0 18px',
            maxHeight: '410px',
            overflow: 'auto',
            paddingBottom: '20px',
            width: '100%',
            margin: '0',
        },
        left: {
            float: 'left',
            maxWidth: 'calc(100% - 220px)',
        },
        right: {
            float: 'right',
        },
        header: {
            padding: '30px',
            height: '40px',
        },
        footer: {
            borderTop: `1px solid ${styledTheme.dialogs.colors.separator}`,
        },
    })
);

type Identifiers = {
    winIdentifier: DesktopWinIdentifier;
    macIdentifier: DesktopMacIdentifier;
};

const getAppIdentifiersFromPredefinedApp = (predefinedDesktopIdentifier: PredefinedDesktopIdentifier[]) => {
    return predefinedDesktopIdentifier.reduce<Identifiers>((acc, identifier) => {
        if (identifier.os === DesktopIdentifierOs.WINDOWS) {
            acc.winIdentifier = {
                processName: identifier.simpleIdentifier || '',
                appTitle: identifier.advancedIdentifier || '',
            };
        }
        if (identifier.os === DesktopIdentifierOs.MAC) {
            acc.macIdentifier = {
                bundleId: identifier.advancedIdentifier || '',
                appName: identifier.simpleIdentifier || '',
            };
        }
        return acc;
    }, {} as Identifiers);
};

const getWinIdentifierString = (winIdentifier: DesktopWinIdentifier) =>
    `${winIdentifier.appTitle || ''}@@${winIdentifier.processName || ''}`;

export const AddDesktopTrackedApp = ({ predefinedApps, appsData, msaId }: AddDesktopTrackedAppProps) => {
    const navigate = useNavigate();
    const [checkedAppsStatus, updateCheckedAppsStatus] = useState({});
    const [addingAppsInProgress, setAddingAppsInProgress] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();

    const appsName = useMemo(() => appsData.map((app) => app.systemName), [appsData]);
    const macAppNames = useMemo(
        () => appsData.reduce<string[]>((acc, app) => [...acc, ...app.macIdentifiers.filter((i) => i.appName).map((i) => i.appName)], []),
        [appsData]
    );
    const macBundleIds = useMemo(
        () => appsData.reduce<string[]>((acc, app) => [...acc, ...app.macIdentifiers.filter((i) => i.bundleId).map((i) => i.bundleId)], []),
        [appsData]
    );
    const winIdentifierCombinations = useMemo(
        () => appsData.reduce<string[]>((acc, app) => [...acc, ...app.winIdentifiers.map(getWinIdentifierString)], []),
        [appsData]
    );

    const filteredApps = useMemo(() => {
        if (!searchValue) {
            return predefinedApps;
        }

        return predefinedApps.filter((app) => app.systemName.toLowerCase().includes(searchValue));
    }, [searchValue, predefinedApps]);
    const selectedApps = useMemo(() => predefinedApps.filter((app) => checkedAppsStatus[app.id]), [checkedAppsStatus, predefinedApps]);

    const navToAppDetails = useCallback(() => navigate('/desktop-tracked-apps/app-details/'), [history]);

    const navToListView = useCallback(() => navigate('/desktop-tracked-apps'), [history]);

    const onSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(`${e.target.value || ''}`.toLowerCase()), []);

    const onAppSelectionChanged = useCallback((app: PredefinedDesktopTrackedApp, isChecked: boolean) => {
        updateCheckedAppsStatus((values) => ({ ...values, [app.id]: isChecked }));
    }, []);

    const addSelectedApps = useCallback(async () => {
        setAddingAppsInProgress(true);
        const failed: DesktopTrackedApp[] = [];
        const success: DesktopTrackedApp[] = [];

        await Promise.all(
            selectedApps.map((app) => {
                const appIdentifiers = getAppIdentifiersFromPredefinedApp(app.predefinedDesktopIdentifiers);

                return insightsApi
                    .createDesktopTrackedApp(msaId, {
                        systemName: app.systemName,
                        macIdentifiers: appIdentifiers.macIdentifier ? [appIdentifiers.macIdentifier] : [],
                        winIdentifiers: appIdentifiers.winIdentifier ? [appIdentifiers.winIdentifier] : [],
                    })
                    .then(() => success.push(app))
                    .catch(() => failed.push(app));
            })
        );

        dispatch(desktopTrackedAppsSlice.actions.addedDesktopTrackedAppsResults({ success, failed }));
        navToListView();
        setAddingAppsInProgress(false);
    }, [selectedApps, msaId]);
    const actions = useMemo(
        (): IFormActions => ({ onConfirm: addSelectedApps, onCancel: navToListView }),
        [addSelectedApps, navToListView]
    );
    const classes = useStyles();

    const isPredefinedAppExist = (app: PredefinedDesktopTrackedApp): boolean => {
        const { winIdentifier, macIdentifier } = getAppIdentifiersFromPredefinedApp(app.predefinedDesktopIdentifiers);

        return (
            appsName.includes(app.systemName) ||
            (macIdentifier && macBundleIds.includes(macIdentifier.bundleId)) ||
            (macIdentifier && macAppNames.includes(macIdentifier.appName)) ||
            (winIdentifier && winIdentifierCombinations.includes(getWinIdentifierString(winIdentifier)))
        );
    };

    return (
        <>
            <PageTitle>Add Desktop App</PageTitle>
            <Paper>
                <div className={classes.header}>
                    <Search
                        className={classes.left}
                        width={'500px'}
                        placeholder='Search Apps...'
                        value={searchValue}
                        handleChangeFunc={onSearch}
                    />
                    <Button customClass={`main ${classes.right}`} startIcon={<AddIcon color='inherit' />} onClick={navToAppDetails}>
                        add custom app
                    </Button>
                </div>
                <Grid container spacing={2} className={classes.gridList}>
                    {filteredApps.length > 0 ? (
                        filteredApps.map((app: PredefinedDesktopTrackedApp) => (
                            <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={app.id}>
                                <InteractiveDesktopApp
                                    desktopApp={app}
                                    disabled={isPredefinedAppExist(app)}
                                    checked={checkedAppsStatus[app.id]}
                                    onUpdate={onAppSelectionChanged}
                                />
                            </Grid>
                        ))
                    ) : (
                        <EmptySearch />
                    )}
                </Grid>
                <BottomSection
                    className={classes.footer}
                    submitting={addingAppsInProgress}
                    saveActionName='add to list'
                    actions={actions}
                    pristine={false}
                    invalid={addingAppsInProgress || !selectedApps.length}
                />
            </Paper>
        </>
    );
};

export default compose(withData)(AddDesktopTrackedApp);
