import React from 'react';
import { WMButtonProps } from '@walkme/wm-ui';
import { alpha } from '@material-ui/core/styles';
import { StyledConfirmButton, StyledSvg } from './confirm-button-style';

const ConfirmButton = (props: WMButtonProps) => {
    return (
        <>
            <StyledSvg width={0} height={0}>
                <defs>
                    <linearGradient id={`${props.id}-loading-circle-gradient`} x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor='#ffffff' />
                        <stop offset='100%' stopColor={alpha('#ffffff', 0.4)} />
                    </linearGradient>
                </defs>
            </StyledSvg>
            <StyledConfirmButton {...props} />
        </>
    );
};

export { ConfirmButton };
