import { WMButton, WMDivider, WMSelect } from '@walkme/wm-ui';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { isIdpConfigured, useAccountIdps, useLoggedInUser, useRoles, useUsers } from '@walkme-admin-center/libs/state-management-users';
import moment from 'moment';
import styled from 'styled-components';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import { GetAuditLogsDto, SystemTypeKey } from 'wm-accounts-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { AuditLogsState, getAuditLogs } from '@walkme-admin-center/libs/state-management-auditlog';
import { getUsersStatusMap, UserStatus } from 'packages/pages/home/users-roles/src/lib/utils/users-status';
import { useNavigate } from 'react-router-dom';
import { Height } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'apps/home/src/localization/localization';

const StyledWMSelect = styled(WMSelect)`
    .WMButton-secondary:not(.WMButton-iconButton) {
        border-radius: 1rem;
        background: var(--primary-b-7, #edf1fd);
        display: flex;
        height: 1.5rem;
        padding: 0px 2px 0px 8px;
        align-items: center;
        color: var(--typography-typography, #2f426c);
        font-size: 0.875rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
    }
`;

export const StyledSpanGreetings = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 32px;
    color: #2f426c;
`;

export const StyledSpan = styled.span`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #637191;
`;

export const StyledDivCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    flex: 1 0 0;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`;
export const StyledMiddleDivCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    background: #ffffff;
    border-radius: 8px;
    flex: 1 0 0;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`;

export const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;
export const StyledDivChart = styled.div<{ gradient: string }>`
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: conic-gradient(${(props) => props.gradient});
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledDivHole = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    width: 60%;
    height: 100%;
`;

const Text = styled.span`
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #2f426c;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    justify-content: space-between;
    //padding: 0px 0px 1rem 0px;
    height: calc(100%-72px);
    height: -webkit-calc(100% - 72px);
    height: -moz-calc(100% - 72px);
`;

const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;
    padding: 0rem 1.5rem 1rem 1.5rem;
    height: calc(100% - 3rem - 32px);
    height: -webkit-calc(100% - 3rem - 32px);
    height: -moz-calc(100% - 3rem - 32px);
`;

const ScrollableContainer = styled.div`
    display: flex;
    height: 100%;
    padding: 0px 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    overflow: scroll;
`;

const ScrollableMiddleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding: 0rem 1.5rem 1rem 1.5rem;
    overflow: scroll;
    height: calc(100% - 4rem - 32px);
    height: -webkit-calc(100% - 4rem - 32px);
    height: -moz-calc(100% - 4rem - 32px);
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    background: #ffffff;
    border-radius: 0px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding: 1rem 1.5rem;
`;

const TextMiddleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    background: #ffffff;
    border-radius: 0px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding: 1rem 1.5rem;
    justify-content: space-between;
`;

const BarContainer = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background: ${(props) => (props.selected ? '#EBEFF7' : '#ffffff')};
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    justify-content: space-between;
    padding: 10px 1.5rem 10px 1rem;
    :hover {
        background: #f4f6fb;
        .linkIcon {
            opacity: 1;
        }
    }
    .linkIcon {
        opacity: 0;
    }
`;

const ColorDot = styled.span`
    width: 8px;
    height: 8px;
    background-color: ${({ color }) => color};
    display: inline-block;
    border-radius: 50%;
`;

const ChartContainer = styled.div`
    display: flex;
    height: 100%;
    padding: 0px 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
`;

const DivHole = styled.div`
    display: flex;
    flex-direction: column;
`;

const WalkmeProductDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 0.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const WalkmeProduct = styled.div`
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--background-background-tint-1, #f0f3f9);
    background: var(--white, #fff);
    :hover {
        box-shadow: 0px -10px 15px rgba(212, 223, 245, 0.2), 0px 10px 15px rgba(212, 223, 245, 0.2), inset 0px -1px 0px #f5f6f8;
    }
`;

const CombinedWalkmeProduct = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
    align-self: stretch;
`;

const OneActivity = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 1.25rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;

const ActivityFeedDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    background: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding: 1rem 1.5rem;
    height: calc(100% - 4rem - 32px);
    height: -webkit-calc(100% - 4rem - 32px);
    height: -moz-calc(100% - 4rem - 32px);
    overflow: scroll;
`;

const OneHealthDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background: #ffffff;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;

const AccountHealthDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding: 1rem 1.5rem;
    height: calc(100% - 4rem - 32px);
    height: -webkit-calc(100% - 4rem - 32px);
    height: -moz-calc(100% - 4rem - 32px);
    overflow: scroll;
`;

const HoleText = styled.span`
    text-align: center;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 28px;
    color: #2f426c;
`;

const UsersTextHole = styled.span`
    text-align: center;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #2f426c;
`;

const LinksText = styled.span`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #2f426c;
`;

const WalkmeProductText = styled.span`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2f426c;
`;
const ActivityText = styled.span`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2f426c;
`;

const StatusColor = styled.span<{ color: string }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 6px;
    height: 6px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
`;

/* eslint-disable-next-line */
export interface DashboardProps {}
export const Dashboard = (props: DashboardProps) => {
    const { loggedInUserAppData } = useLoggedInUser();
    const { rolesAppData } = useRoles();
    const { usersAppData } = useUsers();
    const { systemsAppData } = useSystems();
    const { accountIdpsAppData } = useAccountIdps();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auditLogsAppData = useSelector((state: { auditLogState: AuditLogsState }) => {
        return state.auditLogState.auditLogs;
    });

    const distributionOptions = [
        { label: 'Role', value: 'Role', id: 1 },
        { label: 'Status', value: 'Status', id: 2 },
        { label: 'Login Method', value: 'Login', id: 3 },
    ];

    const { t, rt, i18n } = useTranslation('general');
    const [usersStatusMap, setUsersStatusMap] = useState<UserStatus[]>(getUsersStatusMap(t));
    useEffect(() => {
        setUsersStatusMap(getUsersStatusMap(t));
    }, [i18n.language]);

    const [selectedDistribution, setSelectedDistribution] = useState(0);

    useEffect(() => {
        const getLogsDto: GetAuditLogsDto = {
            to: Date.now(),
            from: moment().subtract(90, 'days').valueOf(),
        };
        dispatch(getAuditLogs(getLogsDto));
    }, [dispatch]);

    function generateGreetings() {
        const currentHour = moment().format('HH');
        if (currentHour >= '04' && currentHour < '12') {
            return 'Good Morning,';
        } else if (currentHour >= '12' && currentHour < '17') {
            return 'Good Afternoon,';
        } else if (currentHour >= '17' && currentHour < '20') {
            return 'Good Evening,';
        } else if (currentHour >= '20' || currentHour < '04') {
            return 'Good Night,';
        } else {
            return 'Hello';
        }
    }

    const DashboardChart = (
        chartTitle,
        data: { id: number; legend: string; counter: number; color: string }[],
        holeTitle,
        colors,
        size,
        isAllBar: boolean,
        allTitle: string,
        options?: { label: string; id: number; value: string }[],
        selectedOptionId?: number
    ) => {
        const [selectedDataId, setSelectedDataId] = useState<number>(null);
        useEffect(() => {
            setSelectedDataId(null);
        }, []);
        let gradient = '';
        let selectedDataSize = size;
        if (!selectedDataId) {
            gradient = `${colors[0]} ${data[0].counter * 100}%`;
            let currentPercentage = data[0].counter * 100;
            for (let i = 1; i < data.length; i++) {
                if (data[i].counter > 0) {
                    gradient += `,${colors[i % colors.length]} ${currentPercentage}% ${currentPercentage + data[i].counter * 100}%`;
                    currentPercentage += data[i].counter * 100;
                }
            }
        } else {
            const colorIndex = data.findIndex((bar) => bar.id === selectedDataId);
            gradient = `${colors[colorIndex % colors.length]} ${data[colorIndex].counter * 100}%, #ECEFFA ${
                data[colorIndex].counter * 100
            }% 100%`;
            selectedDataSize = data[colorIndex].counter * size;
        }
        data.sort((dataObjectA, dataObjectB) => {
            return dataObjectB.counter - dataObjectA.counter;
        });
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    height: '100%',
                }}>
                <Container>
                    <TextContainer>
                        <Text>{chartTitle}</Text>
                        {options && (
                            <StyledWMSelect
                                isPopout
                                isSearchable={false}
                                options={options}
                                value={options[selectedOptionId]}
                                onChange={(newValue) => setSelectedDistribution(newValue.id - 1)}
                            />
                        )}
                    </TextContainer>
                    <FlexContainer>
                        <ScrollableContainer>
                            {isAllBar && (
                                <BarContainer selected={!selectedDataId} onClick={() => setSelectedDataId(null)}>
                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <span>
                                            {allTitle}
                                            {selectedOptionId === 1 ? 'es' : 's'}
                                        </span>
                                    </div>
                                    <span>100%</span>
                                </BarContainer>
                            )}
                            {data.map((bar) => {
                                return (
                                    <BarContainer selected={bar.id === selectedDataId} onClick={() => setSelectedDataId(bar.id)}>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                            <ColorDot color={bar.color} />
                                            <span>{bar.legend}</span>
                                        </div>
                                        <span>{(bar.counter * 100).toFixed(2)}%</span>
                                    </BarContainer>
                                );
                            })}
                        </ScrollableContainer>
                        <WMDivider orientation='vertical' flexItem />
                    </FlexContainer>
                </Container>
                <ChartContainer>
                    <StyledDivChart gradient={gradient}>
                        <StyledDivHole>
                            <DivHole>
                                <HoleText>{selectedDataSize}</HoleText>
                                <UsersTextHole>{holeTitle}</UsersTextHole>
                            </DivHole>
                        </StyledDivHole>
                    </StyledDivChart>
                </ChartContainer>
            </div>
        );
    };

    const UsersDistributionByRole = useCallback(() => {
        const users = usersAppData.data;
        const colors = ['#6B85D8', '#82CEDC', '#CD6B66', '#87DBAA', '#A368D7', '#C0DC73', '#CD6A8E', '#DFC162', '#A0ADC2'];
        const userSize = users.length;
        let overallCounter = 0;
        let colorCounter = 0;
        const bars = rolesAppData.data
            .map((role) => {
                const roleUsersCount = users.filter((user) => user.role.id === role.id)
                    ? users.filter((user) => user.role.id === role.id).length
                    : 0;
                overallCounter = overallCounter + roleUsersCount;
                return {
                    id: role.id,
                    legend: rt(role.displayName),
                    counter: roleUsersCount !== 0 ? roleUsersCount / userSize : 0,
                };
            })
            .filter((bar) => bar.id < 10);

        bars.push({
            id: -1,
            legend: 'Custom Role',
            counter: userSize - overallCounter !== 0 ? (userSize - overallCounter) / userSize : 0,
        });

        const barsWithColors = bars.map((bar) => {
            return { ...bar, color: colors[colorCounter++ % colors.length] };
        });
        return barsWithColors;
    }, [rolesAppData.data, usersAppData.data]);

    const UsersDistributionByStatus = useCallback(() => {
        const users = usersAppData.data;
        const colors = ['#6B85D8', '#82CEDC', '#CD6B66', '#87DBAA', '#A368D7', '#C0DC73', '#CD6A8E', '#DFC162', '#A0ADC2'];
        const userSize = users.length;
        let overallCounter = 0;
        let colorCounter = 0;
        const bars = usersStatusMap.map((status) => {
            const statusUsersCount = users.filter((user) => user.status === status.id)
                ? users.filter((user) => user.status === status.id).length
                : 0;
            overallCounter = overallCounter + statusUsersCount;
            return {
                id: status.numberdId,
                legend: status.display,
                counter: statusUsersCount !== 0 ? statusUsersCount / userSize : 0,
            };
        });

        const barsWithColors = bars.map((bar) => {
            return { ...bar, color: colors[colorCounter++ % colors.length] };
        });
        return barsWithColors;
    }, [usersAppData.data, usersStatusMap]);

    const UsersDistributionByLoginMethod = useCallback(() => {
        const users = usersAppData.data;
        const colors = ['#6B85D8', '#82CEDC', '#CD6B66', '#87DBAA', '#A368D7', '#C0DC73', '#CD6A8E', '#DFC162', '#A0ADC2'];
        const userSize = users.length;
        let overallCounter = 0;
        let colorCounter = 0;
        let methodCounter = 1;
        const activeAccountIdps =
            accountIdpsAppData.data && accountIdpsAppData.data.length > 0
                ? accountIdpsAppData.data.filter((idp) => isIdpConfigured(idp))
                : [];
        const accountIdpsWithEmptyState = activeAccountIdps.map((idp) => ({
            id: idp.idpId,
            label: idp.isOldIdpFlow ? 'WM Legacy SSO' : idp.idpName,
            numberdId: methodCounter++,
        }));
        accountIdpsWithEmptyState.push({
            id: '',
            label: 'Email+password',
            numberdId: -1,
        });
        const bars = accountIdpsWithEmptyState.map((loginMethod) => {
            const loginMethodUsersCount =
                loginMethod.numberdId === -1
                    ? users.filter((user) => !user.attachedIdpId || user.attachedIdpId === loginMethod.id).length ?? 0
                    : users.filter((user) => user.attachedIdpId === loginMethod.id).length ?? 0;
            overallCounter = overallCounter + loginMethodUsersCount;
            return {
                id: loginMethod.numberdId,
                legend: loginMethod.label,
                counter: loginMethodUsersCount !== 0 ? loginMethodUsersCount / userSize : 0,
            };
        });

        const barsWithColors = bars.map((bar) => {
            return { ...bar, color: colors[colorCounter++ % colors.length] };
        });
        return barsWithColors;
    }, [accountIdpsAppData.data, usersAppData.data]);

    const usersDistribution = useCallback(() => {
        const users = usersAppData.data;
        const colors = ['#6B85D8', '#82CEDC', '#CD6B66', '#87DBAA', '#A368D7', '#C0DC73', '#CD6A8E', '#DFC162', '#A0ADC2'];
        const barsWithColors =
            selectedDistribution === 0
                ? UsersDistributionByRole()
                : selectedDistribution === 1
                ? UsersDistributionByStatus()
                : UsersDistributionByLoginMethod();
        return DashboardChart(
            'Users distribution by',
            barsWithColors,
            'Users',
            colors,
            users.length,
            true,
            `All ${distributionOptions[selectedDistribution].label}`,
            distributionOptions,
            selectedDistribution
        );
    }, [
        UsersDistributionByLoginMethod,
        UsersDistributionByRole,
        UsersDistributionByStatus,
        distributionOptions,
        selectedDistribution,
        usersAppData.data,
    ]);

    const SystemDistributionByPlatform = useCallback(() => {
        const systems = systemsAppData.data;
        let platformSize = 0;
        systems.map((system) => (platformSize += system.platforms?.length));
        const size = systems.length + platformSize;
        const colors = ['#6B85D8', '#82CEDC', '#CD6B66', '#87DBAA', '#A368D7', '#C0DC73', '#CD6A8E', '#DFC162', '#A0ADC2'];
        let overallCounter = 0;
        let colorCounter = 0;
        let index = 1;
        const bars = Object.keys(SystemTypeKey)
            .map((type) => {
                const systemsPlatformsCount = systems.filter(
                    (system) =>
                        system.systemTypeKey === SystemTypeKey[type] ||
                        !!system.platforms?.find((platform) => platform.platformName === SystemTypeKey[type])
                )
                    ? systems.filter(
                          (system) =>
                              system.systemTypeKey === SystemTypeKey[type] ||
                              !!system.platforms?.find((platform) => platform.platformName === SystemTypeKey[type])
                      ).length
                    : 0;
                overallCounter = overallCounter + systemsPlatformsCount;
                return {
                    id: index++,
                    legend: type as string,
                    counter: systemsPlatformsCount !== 0 ? systemsPlatformsCount / size : 0,
                };
            })
            .filter((bar) => bar.id < 10);

        const barsWithColors = bars.map((bar) => {
            return { ...bar, color: colors[colorCounter++ % colors.length] };
        });
        return DashboardChart('Systems distribution by platform', barsWithColors, 'Systems', colors, size, true, 'All Platforms');
    }, [systemsAppData.data]);

    const AdminHotLinks = (
        <div style={{ width: '100%', height: '100%' }}>
            <TextMiddleContainer>
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}>
                    <img alt={'more'} src={`/assets/hotLinksIcon.svg`} />
                    <Text>Admin hot links</Text>
                </div>
            </TextMiddleContainer>
            <ScrollableMiddleContainer>
                <BarContainer
                    selected={null}
                    onClick={() => {
                        navigate({
                            pathname: `/systems/new`,
                        });
                    }}>
                    <LinksText>Create new system</LinksText>
                    <img alt={'more'} src={`/assets/externalLinkIcon.svg`} className='linkIcon' />
                </BarContainer>
                <BarContainer
                    selected={null}
                    onClick={() => {
                        navigate({
                            pathname: `/users/new`,
                        });
                    }}>
                    <LinksText>Add a user</LinksText>
                    <img alt={'more'} src={`/assets/externalLinkIcon.svg`} className='linkIcon' />
                </BarContainer>
                <BarContainer
                    selected={null}
                    onClick={() => {
                        navigate({
                            pathname: `/activity-log`,
                        });
                    }}>
                    <LinksText>View last changes in Activity log</LinksText>
                    <img alt={'more'} src={`/assets/externalLinkIcon.svg`} className='linkIcon' />
                </BarContainer>
            </ScrollableMiddleContainer>
        </div>
    );

    const OtherWalkmeProducts = (
        <div style={{ width: '100%', height: '100%' }}>
            <TextMiddleContainer>
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}>
                    <img alt={'more'} src={`/assets/walkmeProductsIcon.svg`} />
                    <Text>Other WalkMe products</Text>
                </div>
            </TextMiddleContainer>
            <ProductsContainer>
                <CombinedWalkmeProduct>
                    <WalkmeProduct>
                        <WalkmeProductDiv onClick={() => window.open(window.clientConfig.NX_APP_URL_EDITOR, '_blank')}>
                            <img alt={'more'} src={`/assets/editorIcon.svg`} />
                            <WalkmeProductText>WalkMe Editor</WalkmeProductText>
                        </WalkmeProductDiv>
                    </WalkmeProduct>
                    <WalkmeProduct>
                        <WalkmeProductDiv onClick={() => window.open(window.clientConfig.NX_APP_URL_CONSOLE_MAIN, '_blank')}>
                            <img alt={'more'} src={`/assets/consoleIcon.svg`} />
                            <WalkmeProductText>WalkMe Console</WalkmeProductText>
                        </WalkmeProductDiv>
                    </WalkmeProduct>
                </CombinedWalkmeProduct>
                <CombinedWalkmeProduct>
                    <WalkmeProduct>
                        <WalkmeProductDiv onClick={() => window.open(window.clientConfig.NX_INSIGHTS_URL, '_blank')}>
                            <img alt={'more'} src={`/assets/insightsIcon.svg`} />
                            <WalkmeProductText>WalkMe Insights</WalkmeProductText>
                        </WalkmeProductDiv>
                    </WalkmeProduct>
                    <WalkmeProduct>
                        <WalkmeProductDiv onClick={() => window.open('https://community.walkme.com/s/contactsupport', '_blank')}>
                            <img alt={'more'} src={`/assets/supportIcon.svg`} />
                            <WalkmeProductText>WalkMe support</WalkmeProductText>
                        </WalkmeProductDiv>
                    </WalkmeProduct>
                </CombinedWalkmeProduct>
            </ProductsContainer>
        </div>
    );

    const MinimizedActivityFeed = useCallback(() => {
        const minimizedLogs = auditLogsAppData.data.slice(0, 4);
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <TextMiddleContainer>
                    <div
                        style={{
                            display: 'flex',
                            gap: '0.75rem',
                            alignItems: 'center',
                        }}>
                        <img alt={'more'} src={`/assets/activityFeedIcon.svg`} />
                        <Text>Activity feed</Text>
                    </div>
                    <Text
                        style={{ color: '#3B61EB', fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                        onClick={() => {
                            navigate({
                                pathname: `/activity-log`,
                            });
                        }}>
                        Show More
                    </Text>
                </TextMiddleContainer>
                <ActivityFeedDiv>
                    {minimizedLogs.length > 0 ? (
                        minimizedLogs.map((log) => {
                            return (
                                <>
                                    <OneActivity>
                                        <ActivityText>
                                            {log.actionDisplayName} - {log.actionDescription}
                                        </ActivityText>
                                        <ActivityText>
                                            {moment(log.timestamp).format('ll')} | {moment(log.timestamp).format('LT')}
                                        </ActivityText>
                                    </OneActivity>
                                    {minimizedLogs[3] !== log && <WMDivider style={{ width: '100%', margin: '1rem' }} />}
                                </>
                            );
                        })
                    ) : (
                        <> Test</>
                    )}
                </ActivityFeedDiv>
            </div>
        );
    }, [auditLogsAppData.data, history]);

    const systemsWithoutUsers = useCallback(() => {
        const systems = systemsAppData.data;
        const users = usersAppData.data;
        const systemsUsers = systems.filter(
            (system) => !users.find((user) => !!user.systems.find((userSystem) => userSystem.id === system.id))
        );
        return systemsUsers.length > 0 ? (
            <OneHealthDiv>
                <StatusColor color={'#EB3232'}></StatusColor>
                <ActivityText>{systemsUsers.length} systems pending user assignment</ActivityText>
            </OneHealthDiv>
        ) : (
            <OneHealthDiv>
                <StatusColor color={'#41B7A6'}></StatusColor>
                <ActivityText>All systems assigned</ActivityText>
            </OneHealthDiv>
        );
    }, [systemsAppData.data, usersAppData.data]);

    const usersPendingActivation = useCallback(() => {
        const users = usersAppData.data;
        const provisonedUsers = users.filter((user) => user.status === 'PROVISIONED');
        return provisonedUsers.length > 0 ? (
            <OneHealthDiv>
                <StatusColor color={'#EB3232'}></StatusColor>
                <ActivityText>There are {provisonedUsers.length} users that have not activated their account</ActivityText>
            </OneHealthDiv>
        ) : (
            <OneHealthDiv>
                <StatusColor color={'#41B7A6'}></StatusColor>
                <ActivityText>All Users are active</ActivityText>
            </OneHealthDiv>
        );
    }, [usersAppData.data]);

    const usersForSSO = useCallback(() => {
        const idps = accountIdpsAppData.data;
        const users = usersAppData.data;
        const configuredIdps = idps.filter((idp) => !idp.configurationNotCompleted);
        if (configuredIdps.length > 0) {
            const assignedToSSOUsers = !!users.find((user) => user.attachedIdpId === '');
            return assignedToSSOUsers ? (
                <OneHealthDiv>
                    <StatusColor color={'#EB3232'}></StatusColor>
                    <ActivityText>Secure your account and assign all users to the company SSO</ActivityText>
                </OneHealthDiv>
            ) : (
                <OneHealthDiv />
            );
        }
        return <OneHealthDiv />;
    }, [accountIdpsAppData.data, usersAppData.data]);

    const AccountHelth = (
        <div style={{ width: '100%', height: '100%' }}>
            <TextMiddleContainer>
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}>
                    <img alt={'more'} src={`/assets/accountHealthIcon.svg`} />
                    <Text>Account health</Text>
                </div>
            </TextMiddleContainer>
            <AccountHealthDiv>
                {systemsWithoutUsers()}
                <WMDivider style={{ width: '100%', margin: '1rem' }} />
                {usersPendingActivation()}
                <WMDivider style={{ width: '100%', margin: '1rem' }} />
                {usersForSSO()}
            </AccountHealthDiv>
        </div>
    );

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    marginBottom: '26px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <StyledSpanGreetings>
                    {generateGreetings()} {loggedInUserAppData.data.firstName}!
                </StyledSpanGreetings>
                <StyledSpan>Get real-time analytics on your admin workspace</StyledSpan>
            </div>
            <Grid container spacing={3} style={{ overflow: 'scroll', height: '100%' }}>
                <Grid item xs={6} style={{ height: '33%' }}>
                    <StyledDivCard>{usersDistribution()}</StyledDivCard>
                </Grid>
                <Grid item xs={6} style={{ height: '33%' }}>
                    <StyledDivCard>{SystemDistributionByPlatform()}</StyledDivCard>
                </Grid>
                <Grid item xs={6} style={{ height: '33%' }}>
                    <StyledDivCard>{AdminHotLinks}</StyledDivCard>
                </Grid>
                <Grid item xs={6} style={{ height: '33%' }}>
                    <StyledDivCard>{OtherWalkmeProducts}</StyledDivCard>
                </Grid>
                <Grid item xs={6} style={{ height: '33%' }}>
                    <StyledDivCard>{MinimizedActivityFeed()}</StyledDivCard>
                </Grid>
                <Grid item xs={6} style={{ height: '33%' }}>
                    <StyledDivCard>{AccountHelth}</StyledDivCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
