import styled from 'styled-components';
import { Input } from '@walkme/ui-core';
import { ConfigureTabs } from './configure.lib';

export const StyledConfigureContent = styled.div<{ activeTab: number }>`
    grid-column: 2;
    display: flex;
    flex-direction: column;
    max-width: 48vw !important;
    height: 100%;
    background-color: ${(props) =>
        props.activeTab === ConfigureTabs.ASSIGN_USERS ? 'var(--Primaries-Background-Background-tint-1, #f0f3f9)' : 'white'};
    overflow: auto;
`;

export const Header = styled.div`
    padding: 24px 32px 12px 32px;
`;

export const Content = styled.div`
    padding: 0 32px;
    display: flex;
    align-content: center;
    flex-direction: column;
    height: 70%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
`;

export const StyledInput = styled(Input)`
    & input {
        background-color: #ffffff;
        color: #8d97ae;
    }

    & input:focus {
        border-width: 1px;
        outline: none;
        color: #2f426c;
    }
`;

export const Banner = styled.div`
    display: flex;
    height: var(--size-global-s-9048, 48px);
    padding: 0px 12px 0px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 4px;
    border: 1px solid var(--Primaries-Blue-Primary-Primary-tint-3, #afbff7);
    background: var(--Primaries-Blue-Primary-Primary-tint-7, #edf1fd);
`;
