import React, { useCallback, useMemo, useState } from 'react';
import Pluralize from 'react-pluralize';
import { DataTable, useTable } from '@walkme-admin-center/libs/ui-components';
import { Dialog } from '@walkme-admin-center/libs/common';
import { RoleForm } from './role-form/role-form';
import { FormActions } from '@walkme-admin-center/libs/types';
import { Role } from 'wm-accounts-sdk';
import { useSelector } from 'react-redux';
import { useLoggedInUser, UsersState, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { Box, Typography } from '@material-ui/core';
import { WMIconHelp } from '@walkme/wm-ui';
import PredefinedRolesInfoTable from './utils/predefined-roles-info-table';
import { countRoleUsers, getRolesRows } from '../utils/roles-rows';
import { useTranslation } from 'apps/home/src/localization';
import { useDataTableLocale } from 'packages/pages/util/locale-utils';

export const PredefinedRolesTab = ({ loadingData, pageData }) => {
    const { t, rt } = useTranslation('general');

    const tableSettings = useTable();
    const { usersAppData } = useUsers();

    const dataTableLocale = useDataTableLocale();

    const users = usersAppData.data;

    const [showDialog, setShowDialog] = useState(false);
    const [showInfoTable, setShowInfoTable] = useState(false);
    const [formActions, setFormActions] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const savedRoleAppData = useSelector((state: { usersState: UsersState }) => state.usersState.savedRole);
    const deletedRoleAppData = useSelector((state: { usersState: UsersState }) => state.usersState.deletedRole);

    const rolesCount = useMemo(() => countRoleUsers(users), [users]);
    const { accountFeatureEnabled } = useLoggedInUser();

    const predefinedRolesRows = useMemo(
        () => getRolesRows(t, rt, pageData.roles, rolesCount, pageData.actions, accountFeatureEnabled),
        [pageData.roles, rolesCount, t, rt]
    );

    const cancel = useCallback(() => {
        setShowDialog(false);
    }, []);

    const triggerRoleFormDialog = (actions: FormActions, role?: Role) => {
        return (
            <Dialog
                view='sideScreen'
                children={
                    <RoleForm
                        actions={actions}
                        actionsGraph={pageData.actions}
                        role={role}
                        title={rt(role.displayName)}
                        deletingRole={deletedRoleAppData.loading}
                        submittingRole={savedRoleAppData.loading}
                        showDrawer={showDialog}
                        onClose={cancel}
                    />
                }
                onClose={null}
                showDialog={showDialog}
            />
        );
    };

    const handleRoleSelection = useCallback(
        (id) => {
            const role = pageData.roles.find((role) => role.id === id);
            const actions: FormActions = { onConfirm: () => {}, onCancel: cancel };
            setSelectedRole(role);
            setShowDialog(true);
            setFormActions(actions);
        },
        [pageData.roles]
    );

    return (
        <>
            {showDialog && triggerRoleFormDialog(formActions, selectedRole)}
            <DataTable
                onRowClick={handleRoleSelection}
                heads={pageData.headCells}
                data={predefinedRolesRows}
                title={<Pluralize singular={'Role'} count={loadingData ? 'N/A' : predefinedRolesRows && predefinedRolesRows.length} />}
                tableHeader={
                    <Box display='flex' flexDirection='row' flexGrow={1} alignItems='center'>
                        <Typography style={{ fontFamily: 'Poppins', color: '#2F426C', fontSize: '18px', fontWeight: 600 }}>
                            {t('users-and-roles-tab.roles.predefined-roles-tab.title')}
                        </Typography>
                        <WMIconHelp
                            size={15}
                            style={{ paddingLeft: '5px' }}
                            color={'#6D81A6'}
                            onClick={() => setShowInfoTable(true)}
                            cursor={'pointer'}
                        />
                    </Box>
                }
                customToolbarButton={<div></div>}
                disableEmptyRows={true}
                showFooter={false}
                hideSort={true}
                loading={loadingData}
                {...tableSettings}
                searchPlaceholder={t('users-and-roles-tab.roles.grid.search-placeholder')}
                locale={dataTableLocale}
            />

            <PredefinedRolesInfoTable
                open={showInfoTable}
                onClose={() => setShowInfoTable(false)}
                roles={pageData.roles}
                rolesOrder={predefinedRolesRows.map((role) => role.roleId)}
            />
        </>
    );
};

export default PredefinedRolesTab;
