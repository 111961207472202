import { Base } from './base';
import {
    IDataIntegrationAppSettingsForm,
    IDataIntegrationAppSettings,
    IHeaderText,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import React from 'react';
import TabPanel from '../../common/components/custom-tab-panel';
import get from 'lodash/get';
import Security from '../data-integration-form-components/settings/steps/security/security';
import { getErrorObject } from '../services/validation/validate';
import { securitySchema } from '../services/validation/settings';
import SaveSettingsFooter from '../data-integration-form-components/footers/save-settings-footer';
import { createWhiteList, updateWhiteList } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { CONSTS } from '../../../../../sso-configuration/src/lib/common/consts';
import { IFormHeader } from '../form-header/form-header';

interface IHandleSubmit {
    values: IDataIntegrationAppSettingsForm;
    instanceData: IDataIntegrationAppSettings;
}

export class Settings extends Base {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    async handleSubmit({ values, instanceData }: IHandleSubmit): Promise<void> {
        const ips = values.ipList && values.ipList.length ? values.ipList.join(';') : null;
        if (!instanceData.whiteList?.id) {
            await this.createWhiteList(ips);
        } else {
            await this.updateWhiteList(instanceData.whiteList.id, ips);
        }
    }

    async updateWhiteList(id: number, ips: string) {
        try {
            await this.dispatch(updateWhiteList(id, ips));
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `You’ve successfully updated white list IPs`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to update white list IPs`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    async createWhiteList(ips: string) {
        try {
            await this.dispatch(createWhiteList(ips));
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `You’ve successfully created white list IPs`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to create white list IPs`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    getFormLabels(): Array<string> {
        return ['Security'];
    }

    getFormHeader(): IHeaderText {
        return { header: 'Settings', subHeader: null };
    }

    getFormByTab(step: number): React.ReactNode {
        return (
            <>
                <TabPanel value={step} index={0}>
                    <Security />
                </TabPanel>
            </>
        );
    }

    getTabsFooter(): React.ReactNode {
        return <SaveSettingsFooter onClose={this.closeForm} showSnackBar={this.showSnackBar} />;
    }

    async validate(values: IDataIntegrationAppSettingsForm) {
        let errors = await getErrorObject(securitySchema, values);
        if (errors) {
            return errors;
        }
        return null;
    }
    prepareIpList(ipString: string): Array<string> {
        if (!ipString) {
            return [];
        }
        return ipString.split(';');
    }
    initForm({ data: settings }: { data: IDataIntegrationAppSettings }): IDataIntegrationAppSettingsForm {
        return {
            ipList: this.prepareIpList(get(settings, 'whiteList.ipList', null)),
        };
    }
}
