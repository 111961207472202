import React from 'react';
import Pluralize from 'react-pluralize';
import styled from 'styled-components';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import Tooltip from 'packages/libs/ui-components/src/lib/tooltip/Tooltip';

const StyledAdditionalIdentifierCount = styled.div`
    color: ${styledTheme.colors.mainGray};
    display: inline-block;
`;

const StyledName = styled.div`
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
`;

const StyledContainer = styled.div`
    display: flex;
`;

interface DesktopIdentifiersTableDisplayProps {
    identifiersCount: number;
    text: string;
    fieldName: string;
};

const renderIdentifiersTableDisplay = ({
    identifiersCount,
    text,
    fieldName
} : DesktopIdentifiersTableDisplayProps) => {
    const additionalIdentifersCount = text && identifiersCount > 1 ? identifiersCount - 1 : 0;
    const tooltipNumberText = <Pluralize singular={`more ${fieldName}`} count={additionalIdentifersCount} />

    return (
        <StyledContainer>
            <Tooltip 
                placement="bottom"
                title={text}
            >
                <StyledName>{text}</StyledName>
            </Tooltip>
            <Tooltip 
                placement="bottom"
                title={tooltipNumberText}
            > 
                <StyledAdditionalIdentifierCount>
                    { additionalIdentifersCount ? `+${additionalIdentifersCount}` : '' }
                </StyledAdditionalIdentifierCount>
            </Tooltip>
        </StyledContainer>
    );
}

const DesktopIdentifiersTableDisplay = ({ text, identifiersCount, fieldName }: DesktopIdentifiersTableDisplayProps) => {
    if (identifiersCount > 0) {
        return renderIdentifiersTableDisplay({ text, identifiersCount, fieldName });
    } else {
        return <>---</>;
    }
};

export default DesktopIdentifiersTableDisplay;