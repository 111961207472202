import styled from 'styled-components';

export const Divider = styled.div`
    display: flex;
    margin: 0.25rem 0rem;
    flex-direction: column;
    justify-content: stretch;
    align-self: stretch;
    background: var(--Color-Bg-primary, #f0f3f9);
    height: 1px;
`;

export const SubsectionContainer = styled.div`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .flex-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }
`;

export const AccordionSummaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const TitleSubsectionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;

export const SectionIconContainer = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: var(--Color-Bg-primary, #f0f3f9);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    position: relative;
`;

export const SectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1 0 0;
`;

export const SectionTitle = styled.p`
    margin: 0;
    color: var(--Typography-Typography-Main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
`;

export const SectionSubtitle = styled(SectionTitle)`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
`;

export const SubsectionTitle = styled(SectionSubtitle)`
    font-weight: 600;
`;

export const SubIconContainer = styled.div`
    display: flex;
    width: 0.8125rem;
    height: 0.8125rem;
    background: var(--Color-Bg-primary, #f0f3f9);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-50%, -100%);
`;
