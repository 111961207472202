import React, { useEffect, useState } from 'react';
import {
    CssCheckBox,
    SystemCheckBoxContainer,
    SystemIconContainer,
    SystemNameContainer,
    SystemStatusContainer,
    SystemTypeContainer,
    SystemsContainer,
} from './integration-row-style';
import { retrieveSystemSvgIcon } from '@walkme-admin-center/libs/common';

const SystemsRow = ({ system, updateChekcedSystems, isSystemChecked, isContentSourceChecked }) => {
    return (
        <SystemsContainer>
            <SystemCheckBoxContainer>
                <CssCheckBox
                    type='checkbox'
                    onChange={(e) => updateChekcedSystems(system.guid, e.target.checked)}
                    checked={isSystemChecked}
                    disabled={isContentSourceChecked}
                />
            </SystemCheckBoxContainer>
            <SystemTypeContainer>
                <img src={retrieveSystemSvgIcon(system.type)} />
            </SystemTypeContainer>
            <SystemNameContainer style={{ fontWeight: 600, fontSize: '14px', color: '#2F426C' }}>{system.name}</SystemNameContainer>
            <SystemStatusContainer>???</SystemStatusContainer>
        </SystemsContainer>
    );
};

export default SystemsRow;
