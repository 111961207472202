import React from 'react';
import styled from 'styled-components';
import { CheckmarkIcon } from '../../icons/shared/checkmark.icon';

const StyleSelected = styled.div`
  color: #4164e3;
  display: flex;
  align-items: center;
`

const StyleCheckmarkIcon = styled(CheckmarkIcon)`
  width: 14px;
  padding-right: 5px;
`

interface SelectedHostProps {
  editDisabled: boolean;
}

export const SelectedHost = (props: SelectedHostProps) => {
  return (
    <StyleSelected className="selected-container">
      <StyleCheckmarkIcon /> Selected {props.editDisabled && '(default)'}
    </StyleSelected>
  )
}
