import { useSelector } from 'react-redux';
import { DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useDeactivateDesktopTrackedApps = () => {
    const deactivateDesktopTrackedAppsData = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.deactivateDesktopTrackedApps
    );

    return {
        deactivateDesktopTrackedAppsData
    };
};

export default useDeactivateDesktopTrackedApps;
