import { UpdateUserChangedValues } from '../../../../../pages/home/extension-pages/src/lib/types';

interface EnvironmentData {
    name: string;
    isDev: boolean;
    path: string;
}

interface Environments {
    [id: number]: EnvironmentData;
}

enum InjectionMethod {
    Extension = 'Extension',
    SiteSnippet = 'SiteSnippet',
}

enum LoadOption {
    OnlyTop = 1,
    TopAndCdIframes = 2,
}

interface SystemMatchers {
    [envId: number]: SystemMatcher[];
}

type SystemMatcher = BasicSystemMatcher & {
    topRegex: string;
    selfRegex: string;
    selfDomains?: string[];
    topDomains?: string[];
    isRegex?: boolean;
    isDefault?: boolean;
    isSnippetDetectConfigValid: boolean;
    _id?: string | number;
};

type AddSystemData = CommonSystemData & {
    selfDomains?: string[];
    isActive?: boolean;
};

type CommonSystemData = {
    accountId: number;
    accountName: string;
    systemEmail: string;
    name: string;
    systemId: number;
    appId: string;
};

type SystemData = PartialSystemData &
    CommonSystemData & {
        systemTypeKey: string;
        changedValuesForAuditLog?: UpdateUserChangedValues;
    };

interface PartialSystemData {
    injectionMethod: InjectionMethod;
    environments: Environments;
    matchers: SystemMatchers;
}

enum ExtensionStatus {
    Exists,
    NotExists,
    ExistsNeedsMigration,
}

interface GetSystemDataResult {
    extensionStatus: ExtensionStatus;
    isSystemLinked: boolean;
    systemData: PartialSystemData;
    cacheTime: number;
}

interface GetSystemResult {
    [systemId: number]: GetSystemDataResult;
}

type GetSystemsRegexesResultItem = {
    systemId: number;
    regexes: SystemsRegexes[];
};

type SystemsRegexes = {
    isActive: boolean;
    selfRegex: string;
    topRegex: string;
};

type LinkSystemToExtensionData = BasicSystemMatcher & {
    selfDomains: string[];
    topDomains: string[];
};

type MatcherChanges = {
    oldValues: Record<string, any>;
    newValues: Record<string, any>;
};

type AddNewMatcherData = BasicSystemMatcher & {
    isRegex?: boolean;
    selfRegex: string;
    topRegex: string;
    selfDomains?: string[];
    topDomains?: string[];
};

type BasicSystemMatcher = {
    envId: number;
    isActive: boolean;
    loadOptions: LoadOption;
    preferSiteSnippet: boolean;
    waitForSiteSnippetWithTimeout: boolean;
};

export {
    GetSystemDataResult,
    GetSystemResult,
    ExtensionStatus,
    PartialSystemData,
    SystemMatcher,
    SystemMatchers,
    LoadOption,
    InjectionMethod,
    EnvironmentData,
    Environments,
    SystemData,
    CommonSystemData,
    AddSystemData,
    GetSystemsRegexesResultItem,
    SystemsRegexes,
    LinkSystemToExtensionData,
    MatcherChanges,
    AddNewMatcherData,
};
