import { Drawer, withStyles, Modal } from '@material-ui/core';
import styled from 'styled-components';

export const CssDrawer = withStyles({
    root: {
        '& .MuiBackdrop-root': {
            top: '55px',
            background: 'rgba(47, 66, 108, 0.61) !important',
        },
    },
    paper: {
        width: '480px',
        top: '55px',
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
    },
})(Drawer);

export const CssModal = withStyles({
    root: {
        '& .MuiBackdrop-root': {
            top: '55px',
            background: 'rgba(47, 66, 108, 0.61) !important',
        },
    },
    paper: {
        width: '672px',
        top: '55px',
        backgroundColor: '#FFFFFF',
    },
})(Modal);

export const AiContentSourceTabs = styled.div`
    display: flex;
    width: 973.355px;
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;
