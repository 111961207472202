import styled from 'styled-components';
import { FlexDivContainer } from '../../../../extension-pages/src/lib/new-design/shared-styled-components';
import { Link } from 'react-router-dom';

export const StyledTooltipText = styled('span')`
    color: #ffffff;
    font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

export const MainContainer = styled(FlexDivContainer)`
    height: 100%;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

export const HeaderContainer = styled(FlexDivContainer)`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    flex-direction: column;
    padding: 32px 24px 12px 24px;
    align-self: stretch;
    flex: 1 0 0;
    background: var(--white, #fff);
    border-radius: 0px 12px 12px 0px;
    gap: 6px;
`;

export const HeaderMainText = styled('span')`
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const ContentContainer = styled(FlexDivContainer)`
    padding: 20px 24px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    gap: 12px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;

        &-track {
            background-color: var(--wmWhite);
            border-radius: 4px;
        }

        &-thumb {
            background-color: var(--wmGrayMedium);
            border: 2px solid var(--wmWhite);
            border-radius: 4px;

            &:hover {
                background-color: var(--wmUltraLight);
            }
        }
    }
`;

export const StyledSaveAndPublish = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px;
`;

export const BoxSubHeader = styled('span')`
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
export const StyledLink = styled(Link)`
    color: var(--blue-primary-primary-main, #385feb);
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

export const LoaderContainer = styled(FlexDivContainer)`
    background: white;
    border-radius: 12px;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
export const BoxHeader = styled('span')`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const StyledImg = styled('img')`
    height: 16px;
    width: 16px;
    display: flex;
`;

export const StyledBox = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 32px 14px 32px;
    border-radius: 8px;
    align-self: stretch;
    border: 1px solid rgb(221, 229, 245);
    color: var(--typography-typography-main-primary-main, #2f426c);

    .top-area {
        display: flex;
    }

    .top-area-internal {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 4px;
    }

    .main-title {
        display: flex;
        flex-direction: row;
    }

    .bottom-area {
        margin-top: 14px;
        display: flex;
        gap: 6px;
        font-weight: 400;

        span {
            display: flex;
        }
    }

    .center-area {
        display: flex;
        align-items: center;
    }
`;
