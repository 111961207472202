import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    StyledAppBar,
    StyledLogoContainer,
    StyledMenuItem,
    StyledToolbar,
    StyledRightMenu,
    StyledLeftMenu,
    StyledCenterMenu,
} from './styles/styles';

export interface TopBarItem {
    name: string;
    onClick: () => void;
}

interface AppBarProps {
    logo?: JSX.Element;
    rightMenuItems?: JSX.Element[];
    leftMenuItems?: JSX.Element[];
    centerMenuItems?: JSX.Element[];
    apps?: JSX.Element;
    children?: JSX.Element;
}

export const AppBar = ({ logo, leftMenuItems, rightMenuItems, centerMenuItems, apps, children }: AppBarProps): ReactElement => {
    const navigate = useNavigate();
    return (
        <StyledAppBar position='sticky'>
            <StyledToolbar>
                {logo && <StyledLogoContainer onClick={() => navigate('/')}>{logo}</StyledLogoContainer>}
                {apps && apps}
                {children}
                <StyledLeftMenu>
                    {leftMenuItems && leftMenuItems.map((menuItem, i) => <StyledMenuItem key={i}>{menuItem}</StyledMenuItem>)}
                </StyledLeftMenu>
                <StyledCenterMenu>
                    {centerMenuItems && centerMenuItems.map((menuItem, i) => <StyledMenuItem key={i}>{menuItem}</StyledMenuItem>)}
                </StyledCenterMenu>
                <StyledRightMenu>{rightMenuItems && rightMenuItems.map((menuItem, i) => <div key={i}>{menuItem}</div>)}</StyledRightMenu>
            </StyledToolbar>
        </StyledAppBar>
    );
};

export default AppBar;
