import { withStyles, MenuItem, Select, TableRow, TableCell } from '@material-ui/core';
import styled from 'styled-components';

export const MenuItemStyled = withStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '156px',
        height: '32px',
        color: '#FFF',
        '&:not(:first-child)': {
            marginTop: '4px',
        },
        '&:hover': {
            opacity: 0.8,
        },
        '&.Mui-selected': {
            border: '1.5px solid #385FEB',
        },
        '&.Approved': {
            backgroundColor: '#41B7A6',
            '&.Mui-selected:hover': {
                backgroundColor: '#41B7A6',
            },
        },
        '&.Denied': {
            backgroundColor: '#F37F7F',
            '&.Mui-selected:hover': {
                backgroundColor: '#F37F7F',
            },
        },
        '&.Pending': {
            backgroundColor: '#E6ECF8',
            color: '#C5CAD6',
            '&.Mui-selected:hover': {
                backgroundColor: '#E6ECF8',
            },
        },
    },
}))(MenuItem);

export const TableRowStyled = withStyles((theme) => ({
    root: {
        borderBottom: '1px solid #f5f6f8',
    },
}))(TableRow);

export const TableCellStyled = withStyles((theme) => ({
    root: {
        height: '15.5px',
        borderBottom: 'none',
        fontFamily: 'Proxima Nova',
        padding: '12px 0 12px 12px',
        lineHeight: 0,
        '&.MuiTableCell-head': {
            color: '#637191',
            fontWeight: 400,
            fontSize: '12px',
        },
        '&.MuiTableCell-body': {
            color: '#2F426C',
            fontWeight: 600,
            fontSize: '14px',
        },
        '&.channelName': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            height: '20px',
        },
        '&.channelStatus': {
            padding: 0,
            height: '20px',
            position: 'relative',
        },
    },
}))(TableCell);

export const TableCellContentStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .table-cell-flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

export const TableFooterStyled = styled.div`
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SelectStyled = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '156px',
        height: '18px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '6px 0px !important',
        color: '#FFF',
        '&:focus': {
            backgroundColor: 'inherit',
        },
    },
    icon: {
        content: 'none',
    },
}))(Select);

export const ChannelsListContainer = styled.div`
    display: flex;
    padding: 0px 24px 8px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #f5f7fe;

    .channels-list-header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
`;

export const ChannelsListHeader = styled.div`
    display: flex;
    padding: 16px 0px 12px 0px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;

    .channels-list-header-text-wrapper {
        gap: 4px;
        display: flex;
        flex-direction: row;
        align-self: center;

        .channels-list-header-text {
            font-size: 13px;
            font-weight: 600;
            color: #2f426c;
        }
    }
`;
export const RequestCounter = styled.div`
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #385feb;
    color: #fff;
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const ChannelsList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    position: relative;
`;

export const ChannelRow = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 0;
    border-bottom: 1px solid #f5f6f8;
    width: 100%;
    &hover {
        background: #d4dff533;
    }
    position: relative;
`;

export const ChannelColumn = styled.div`
    display: flex;
    width: 232px;
    height: 44px;
    flex-direction: column;
    justify-content: center;
`;

export const ColumnContent = styled.div`
    display: flex;
    height: 40px;
    padding: 8px 0px;
    align-items: center;
    gap: 8px;
`;

export const ContactSupport = styled.a`
    color: #385feb;
    text-decoration: none;
    cursor: pointer;

    &:visited {
        color: #385feb;
    }
`;

export const TextStyle = styled.div`
    color: #2f426c;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;

    &.bold-text {
        font-weight: 600;
    }
`;

export const NoChannelsStyled = styled.div`
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-self: center;
`;

export const RefreshChannelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
        background: rgba(56, 95, 235, 0.05);
    }
`;

export const RefreshIconContainer = styled.img`
    padding: 4px;
    &:hover {
        filter: #385feb;
    }
`;
export const SmallIconContainer = styled.img`
    cursor: pointer;
    padding: 4px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    &:hover {
        background: rgba(56, 95, 235, 0.05);
        filter: #385feb;
    }
`;

export const AutoApproveIconContainer = styled.img`
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 24px;
`;
