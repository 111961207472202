import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        warning: {
            color: 'rgb(102, 60, 0) !important',
            backgroundColor: 'rgb(255, 244, 229) !important',
        },
        error: {
            color: 'rgb(97, 26, 21) !important',
            backgroundColor: 'rgb(253, 236, 234) !important',
        },
        default: {
            color: 'black',
            backgroundColor: 'white',
        }
    })
);

export { useStyles };