import React, { useCallback } from 'react';
import { SelectWrapper, StyledCustomMenuItem, StyledInputLabel } from '../../../common/style';
import { Field } from 'react-final-form';
import { CONSTS } from '../../../common/consts';
import { Fields, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { StyledEndUserIdentifierContainer, StyledPlaceholder, StyledSelect } from './user-identifier-section.styles';

export interface UserIdentifierSectionProps {
    fieldsLoad: boolean;
    fields: Fields;
    copyFields: Fields;
    values: ProviderFormValues;
}

export const UserIdentifierSection = ({ fieldsLoad, fields, copyFields, values }: UserIdentifierSectionProps) => {
    const getUserIdentifierOption = useCallback(() => {
        if (!fieldsLoad) {
            const field = fields.find((field) => field.name === values.userIdentifier);
            const label = field && field.alias ? field.alias : values.userIdentifier;
            return [{ key: 1, value: values.userIdentifier, label }];
        } else {
            return fields
                .filter((field) => field.name !== CONSTS.PROVIDER_GROUPS)
                .map((field, index) => ({
                    key: index,
                    value: field.name,
                    label: field.alias ? field.alias : field.name,
                }));
        }
    }, [fieldsLoad, fields]);

    return (
        <Field name='userIdentifier'>
            {({ input, meta }) => (
                <StyledEndUserIdentifierContainer>
                    <StyledInputLabel>Choose End-User Identifier</StyledInputLabel>
                    <span className={`fontSmall`}>
                        The field from your IDP to identify users by.
                        <br />
                        Use a unique field such as “email” and make sure that the chosen property
                        <br />
                        exists for all the users in the organization.
                    </span>
                    <SelectWrapper>
                        <StyledSelect
                            style={{ marginTop: '16px', width: '100%' }}
                            displayEmpty
                            MenuProps={{
                                PopoverClasses: {
                                    root: 'selectMenuItem',
                                },
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                            }}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <StyledPlaceholder>Choose a unique end-user identifier</StyledPlaceholder>;
                                }
                                const foundSelectField = fields.find((field) => field.name === (selected as string));
                                return foundSelectField && foundSelectField.alias ? foundSelectField.alias : (selected as string);
                            }}
                            disabled={!fieldsLoad || fields.length === 0}
                            variant={'outlined'}
                            {...input}>
                            {getUserIdentifierOption().map((option) => {
                                return (
                                    <StyledCustomMenuItem disableRipple key={option.key} value={option.value}>
                                        {option.label}
                                    </StyledCustomMenuItem>
                                );
                            })}
                        </StyledSelect>
                    </SelectWrapper>
                </StyledEndUserIdentifierContainer>
            )}
        </Field>
    );
};

export default UserIdentifierSection;
