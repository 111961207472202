export interface DropdownOption {
    value: string;
    id: number;
    link?: string;
}

export enum Lang_Detector {
    OFF = 'off',
    HTML = 'html',
    AUTO = 'detect',
    URL = 'url',
    VAR = 'var',
    STORAGE = 'localstorage',
    COOKIE = 'cookie',
    JQUERY = 'jquery',
}

export const hasVariable: string[] = [
    Lang_Detector.URL,
    Lang_Detector.VAR,
    Lang_Detector.STORAGE,
    Lang_Detector.COOKIE,
    Lang_Detector.JQUERY,
];

export const DetectorOptions: DropdownOption[] = [
    { value: Lang_Detector.OFF, id: 1 },
    { value: Lang_Detector.HTML, id: 2, link: 'https://www.w3schools.com/tags/ref_language_codes.asp' },
    { value: Lang_Detector.AUTO, id: 3 },
    { value: Lang_Detector.URL, id: 4 },
    { value: Lang_Detector.VAR, id: 5 },
    { value: Lang_Detector.STORAGE, id: 6 },
    { value: Lang_Detector.COOKIE, id: 7 },
    { value: Lang_Detector.JQUERY, id: 8 },
];
