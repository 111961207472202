import React from 'react';
import { Typography, InputHeight } from '@walkme/ui-core';
import {
    StyledContainer,
    StyledCustomApp,
    StyledHeader,
    StyledInputLabel,
    StyledInput,
    StyledInputContainer,
    StyledCustomName,
    StyledBack,
    StyledButton,
} from './custom-app.styles';
import { ChevronLeftIcon } from '../../../assets/icons/chevron-left';
import { UrlMatching } from './url-matching';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { CustomAppFeedback } from './custom-app-feedback';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

const defaultCustomAppTitle = 'System for new application';

interface CustomAppProps {
    title?: string;
}

export const CustomApp = ({ title = defaultCustomAppTitle }: CustomAppProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const isCreateSystemFeedbackOpen = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateSystemFeedbackOpen.data
    );
    const isNeverShowFeedbackDialog = window.localStorage.getItem('neverShowFeedbackDialog');
    const isCreateCustomSystem = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateCustomSystem.data
    );
    const customAppName = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.customAppName.data);
    const appUrlsLoading = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.appUrls.loading);

    return (
        <StyledContainer>
            <StyledCustomApp>
                <StyledHeader>
                    <StyledBack
                        onClick={() => {
                            dispatch(createSystemSlice.actions.setCustomMode(false));
                        }}>
                        <ChevronLeftIcon className={'chevron-left'} />
                        <StyledButton
                            className={'back-to-app-gallery'}
                            variant={'text'}
                            onClick={() => {
                                dispatch(createSystemSlice.actions.setCustomMode(false));
                            }}>
                            {t('systems-tab.new-system-form.back-to-gallery')}
                        </StyledButton>
                    </StyledBack>
                    <Typography variant={'T10-3'}>{t('systems-tab.new-system-form.custom-type-title')}</Typography>
                </StyledHeader>

                <UrlMatching />

                <StyledCustomName>
                    <StyledInputLabel>
                        <Typography variant={'T20-2'}>{t('systems-tab.new-system-form.custom-type-input-text')}</Typography>
                    </StyledInputLabel>
                    <StyledInputContainer>
                        <StyledInput
                            placeholder={t('systems-tab.new-system-form.type-placeholder')}
                            height={InputHeight.large}
                            width={560}
                            value={customAppName}
                            onChange={(e) => dispatch(createSystemSlice.actions.setCustomAppName(e.target.value))}
                            disabled={appUrlsLoading || !isCreateCustomSystem}
                            maxLength={60}
                        />
                    </StyledInputContainer>
                </StyledCustomName>
            </StyledCustomApp>
            <CustomAppFeedback isOpen={isCreateSystemFeedbackOpen && !isNeverShowFeedbackDialog} />
        </StyledContainer>
    );
};
