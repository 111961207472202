import React, { Suspense } from 'react';
import { PageWrap } from '@walkme-admin-center/libs/types';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { UnsupportedErrorPage } from '../error-pages';
import LoadingPage from '../loading-page/loading-page';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const PermissionsErrorkey = 'notifications.permission-error-text';

export const PageWrapper = (props: PageWrap) => {
    const { t } = useTranslation('general');
    const CurrentPage: (props) => JSX.Element = props.page;

    const { loggedInUserAppData, userPermissions } = useLoggedInUser();

    const isEnterprise = true; // all accounts are enterprise type since 2021;
    const isAdminRole = (() => {
        if (!loggedInUserAppData.data.role || !loggedInUserAppData.data.role.permissions) {
            return false;
        }
        const hasManagmentPermissions = loggedInUserAppData.data.role.permissions.find(
            (permissionData) => permissionData.actionKey === 'Management' && permissionData.permission != 'None'
        );
        return hasManagmentPermissions ? true : false;
    })();
    const isLanguagePageAllow = (() => {
        const hasEditLanguagesPermissions = loggedInUserAppData.data.role.permissions.find(
            (permissionData) => permissionData.actionKey === 'EditLanguages' && permissionData.permission === 'Write'
        );
        return hasEditLanguagesPermissions ? true : false;
    })();
    const isBackoffice = loggedInUserAppData.data.isBackoffice;
    const isImpersonator = loggedInUserAppData.data.impersonator;
    const impersonatePageWithImpersonatorOrBackoffice = (isBackoffice || isImpersonator) && props.name === 'impersonate';
    const showEnterpriseUnavailableForPage =
        !impersonatePageWithImpersonatorOrBackoffice && !isEnterprise && !props.extraProps?.allowNotEnterprise;
    const showAdminUnavailableForPage =
        !impersonatePageWithImpersonatorOrBackoffice && !isBackoffice && !isAdminRole && !props.extraProps?.allowNotAdmin;
    const showLanguagePage = isLanguagePageAllow && props.extraProps?.isLanguageEditPermissionRequired;
    const showImpersonatorToNotAdminOrToAccountError = showAdminUnavailableForPage && isImpersonator;
    const accountFeatures = loggedInUserAppData.data.account ? loggedInUserAppData.data.account.features.names.split(' ') : [];
    const featuresForPage =
        props.extraProps && props.extraProps.allowIfFeaturesEnabled && (props.extraProps.allowIfFeaturesEnabled as string[]);
    const showFeaturesUnavailableForPage = featuresForPage && !featuresForPage.every((v) => accountFeatures.includes(v));
    const hiddenByCondition = props.extraProps && props.extraProps.hiddenByCondition;
    const conditionErrorText = (props.extraProps && (props.extraProps.conditionErrorText as string)) || 'A condition was not met';
    const isLoading = props.extraProps && props.extraProps.isLoading;
    const showUserisNotPermittedPage = props.requiredAction?.actionKey
        ? !userPermissions(props.requiredAction.actionKey, props.requiredAction?.permission)
        : false;

    if (showEnterpriseUnavailableForPage) {
        return <UnsupportedErrorPage whyText='Enterprise account type is required.' />;
    }

    if (showImpersonatorToNotAdminOrToAccountError) {
        return <UnsupportedErrorPage whyText={t('page-wrapper.impersonated-to-not-admin-text')} />;
    }

    if (showAdminUnavailableForPage) {
        return <UnsupportedErrorPage whyText={t('page-wrapper.not-admin-text')} whatToDoText={t('page-wrapper.what-to-do-text')} />;
    }

    if (props.extraProps?.isLanguageEditPermissionRequired && !showLanguagePage) {
        return <UnsupportedErrorPage whyText={t('page-wrapper.not-admin-text')} whatToDoText={t('page-wrapper.what-to-do-text')} />;
    }

    if (showUserisNotPermittedPage) {
        return <UnsupportedErrorPage whyText={t('page-wrapper.no-permission-text')} whatToDoText={t('page-wrapper.what-to-do-text')} />;
    }

    if (showFeaturesUnavailableForPage) {
        return <UnsupportedErrorPage whyText={t('page-wrapper.no-feature-text')} />;
    }

    if (isLoading) {
        return <LoadingPage />;
    }

    if (hiddenByCondition) {
        return <UnsupportedErrorPage whyText={conditionErrorText} />;
    }

    return (
        <Suspense fallback={<div>{t('common.loading')}...</div>}>
            <CurrentPage {...props} />
        </Suspense>
    );
};

export default PageWrapper;
