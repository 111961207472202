import React, { useCallback, useMemo, useState } from 'react';
import { ModalContent, ModalFooter, IntegrationModal } from './modal-style';
import { FormTypes, IIntegrationForm } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import IntegrationFormHeader from './integration-form-header';
import WarningModal from './warning-modal';
import { getFormManager } from '../../../data-integrations-form-manager/data-integrations-form-manager';
import { Modal } from '@material-ui/core';
import { CommonSnackbarProps } from 'packages/pages/home/sso-configuration/src/lib/common/components/custom-snackbar/custom-snackbar';

export interface IIntegrationFormModal {
    closeModal: () => void;
    showSnackBar: (data: CommonSnackbarProps) => void;
    formType: FormTypes;
}

export const IntegrationFormModal = ({ closeModal, formType, showSnackBar }: IIntegrationFormModal) => {
    const dispatch = useDispatch();

    const changeActiveStep = (step: number): void => {
        setActiveStep(step);
    };

    const [formManager] = useState(getFormManager(formType, dispatch, showSnackBar, closeModal, changeActiveStep));

    const initialValues = useMemo(() => formManager.initForm(null), []);

    const [activeStep, setActiveStep] = useState(0);
    const [openWarning, setOpenWarning] = useState(false);

    const handleSubmit = async (values: IIntegrationForm): Promise<void> => {
        await formManager.handleSubmit({ values });
    };

    const handleClose = () => {
        setOpenWarning(false);
        closeModal();
    };

    const validate = useCallback(
        async (values: any) => {
            return formManager.validate(values, activeStep);
        },
        [formManager, activeStep]
    );

    const handleWarningModel = (flag: boolean) => {
        setOpenWarning(flag);
    };

    const getWarningModal = () => {
        return (
            <div>
                <WarningModal onClose={handleClose} handleWarningModel={handleWarningModel} integrationType={formType} />
            </div>
        );
    };

    return (
        <div>
            <Modal
                open={openWarning}
                onClose={handleClose}
                children={getWarningModal()}
                BackdropProps={{ onClick: () => setOpenWarning(false) }}
            />
            <Form
                onSubmit={handleSubmit}
                validate={validate}
                initialValues={initialValues}
                render={() => {
                    return (
                        <IntegrationModal>
                            <IntegrationFormHeader
                                formHeader={formManager.getFormHeader()}
                                activeStep={activeStep}
                                steps={formManager.getFormLabels()}
                                onClose={() => setOpenWarning(true)}
                                icons={formManager.getFormIntegrationIcons()}
                            />
                            <ModalContent>{formManager.getFormByStep(activeStep)}</ModalContent>
                            <ModalFooter>{formManager.getStepperFooter(activeStep, handleWarningModel)}</ModalFooter>
                        </IntegrationModal>
                    );
                }}
            />
        </div>
    );
};
