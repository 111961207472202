import { WMDataGridWrapperProps, WMIconMinus, WMIconPlus } from '@walkme/wm-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UsersState, useUsers, useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { Box } from '@material-ui/core';
import { Typography } from '@walkme/ui-core';
import {
    StyledTag,
    StyledWMDataGrid,
    StatusDot,
    StyledStatusContainer,
    StyledButton,
    CreatorTag,
    DataGridWrapper,
} from './assign-users-table.styles';
import { UsersBulkMutationResult } from 'wm-accounts-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { assignUsers, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { LabelWithTooltip } from './label-with-tooltip';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { AppData } from '@walkme-admin-center/libs/types';
import { useDatagridLocale } from 'packages/pages/util/locale-utils';
import styled from 'styled-components';

export const FooterAssignButton = styled.button`
    height: var(--size-global-s-6032, 32px) !important;
    min-width: 72px;
    padding: 0px 16px !important;
    background: var(--Color-Semantic-Action-Primary-content-primary-default, #fff) !important;
    border-radius: 40px !important;

    & .MuiButton-label {
        color: var(--Color-Semantic-Action-Primary-bg-primary-default, #385feb) !important;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 8px;
    }

    & .WMDataGridActionDialog-actionIcon {
        margin-right: 0 !important;
    }
`;

export const AssignUsersTable = () => {
    const { t, rt } = useTranslation('general');
    const dispatch = useDispatch();
    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const assignUsersAppData = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.assignUsers); // todo - keep if helps with loading
    const updateUsersAppData: AppData<UsersBulkMutationResult> = useSelector(
        (state: { usersState: UsersState }) => state.usersState.updateUsers
    );
    const { usersAppData } = useUsers();
    const { loggedInUserAppData } = useLoggedInUser();
    const [gridApi, setGridApi] = useState(null);
    const [assignedUsersIds, setAssignedUsersIds] = useState([]); // todo - keep if helps with loading

    const datagridLocale = {
        ...useDatagridLocale(),
        searchPlaceholder: t('systems-tab.all-systems-page.assign-users-form.search-placeholder'),
    };

    const isUserAssigned = (userSystems) => {
        return userSystems.some((system) => system.id === createdSystem?.id);
    };

    const isSystemCreator = (user) => {
        if (loggedInUserAppData?.data) {
            return loggedInUserAppData.data?.id === user.id;
        }
        return false;
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const gridConfig: WMDataGridWrapperProps<any> = useMemo(() => {
        return {
            domLayout: 'autoHeight',
            onGridReady,
        };
    }, []);

    const headCells = useMemo(() => {
        return [
            {
                headerName: t('common.name'),
                field: 'firstName',
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                lockVisible: true,
                sortable: true,
                minWidth: 200,
                flex: 1,
                cellRenderer: (params) => {
                    const checkboxEl = params.eGridCell.querySelector('div[ref="eCheckbox"] input');
                    const checkboxWrapperEl = params.eGridCell.querySelector('div[ref="eWrapper"]');
                    if (checkboxEl && checkboxWrapperEl) {
                        const isAssigned = isUserAssigned(params.data.systems);
                        if (isAssigned) {
                            checkboxWrapperEl.classList.add('disabled-checkbox');
                            checkboxEl.disabled = true;
                        }
                    }

                    return (
                        <Box display='flex' flexDirection='row' alignItems='center' style={{ gap: '6px' }}>
                            <Typography variant={'T30-2'}>
                                {params.data.firstName} {params.data.lastName}
                            </Typography>
                            {isSystemCreator(params.data) && (
                                <CreatorTag variant={'T30'}>
                                    {t('systems-tab.all-systems-page.assign-users-form.creator-tag-you')}
                                </CreatorTag>
                            )}
                        </Box>
                    );
                },
            },
            {
                headerName: t('common.email'),
                field: 'email',
                flex: 1,
                cellRenderer: (params) => {
                    return <LabelWithTooltip text={params.data.email} />;
                },
            },
            {
                headerName: t('common.role'),
                minWidth: 170,
                flex: 1,
                cellStyle: { display: 'flex', alignItems: 'center' },
                cellRenderer: (params) => {
                    return <StyledTag label={rt(params.data?.role?.displayName)}></StyledTag>;
                },
            },
            {
                headerName: t('common.status'),
                minWidth: 150,
                flex: 1,
                cellStyle: { display: 'flex' },
                cellRenderer: (params) => {
                    const isAssigned = isUserAssigned(params.data.systems);
                    return (
                        <StyledStatusContainer>
                            <StatusDot assigned={isAssigned} />
                            <Typography variant={'T30'}>{isAssigned ? t('common.assigned') : t('common.not-assigned')}</Typography>
                        </StyledStatusContainer>
                    );
                },
            },
            {
                headerName: '',
                flex: 1,
                cellStyle: { display: 'flex', justifyContent: 'flex-end', padding: '0 28px 0 0' },
                cellRenderer: (params) => {
                    const isAssigned = isUserAssigned(params.data.systems);
                    const isLoading = assignUsersAppData.data.includes(params.data.id) && assignUsersAppData.loading; // todo - keep if helps with loading
                    const isCreator = isSystemCreator(params.data);

                    return (
                        <>
                            <StyledButton
                                variant='outlined'
                                color={isAssigned ? 'danger' : 'primary'}
                                startIcon={
                                    isLoading ? null : isAssigned && !isCreator ? <WMIconMinus size={12} /> : <WMIconPlus size={12} />
                                }
                                onClick={() =>
                                    isAssigned
                                        ? onUnassignUser(params.data.id, params.data.systems)
                                        : onAssignUser(params.data.id, params.data.systems)
                                }
                                loading={isLoading}
                                disabled={isCreator}>
                                {isAssigned && !isCreator ? t('buttons.unassign-user') : t('buttons.assign-user')}
                            </StyledButton>
                        </>
                    );
                },
            },
        ];
    }, [usersAppData.data, createdSystem, loggedInUserAppData, assignUsersAppData.data, assignedUsersIds]);

    const userRows = useMemo(() => {
        return [...usersAppData.data].sort((userA, userB) => {
            const userAAssigned = isUserAssigned(userA.systems);
            const userBAssigned = isUserAssigned(userB.systems);
            const userACreator = isSystemCreator(userA);
            const userBCreator = isSystemCreator(userB);

            if (userACreator) {
                return -1;
            } else if (userBCreator) {
                return 1;
            } else if (userAAssigned && !userBAssigned) {
                return -1;
            } else if (!userAAssigned && userBAssigned) {
                return 1;
            } else {
                return 0;
            }
        });
    }, [usersAppData.data]);

    const getFooterActions = (loading: boolean) => [
        {
            onClick: (users) => {
                const usersToEdit = users.map((user) => {
                    const systemIds = [...user.systems.map((system) => system?.id), createdSystem?.id];
                    return { userId: user.id, systemIds: systemIds };
                });
                dispatchEditUsers(usersToEdit);
            },
            icon: <></>,
            title: t('systems-tab.all-systems-page.assign-users-form.title'),
            className: 'footer-assign-button',
            loading: loading,
        },
    ];

    const onAssignUser = (userId, userSystems) => {
        const systemIds = [...userSystems.map((system) => system.id), createdSystem?.id];
        const userToEdit = [{ userId: userId, systemIds: systemIds }];
        dispatchEditUsers(userToEdit);
    };

    const onUnassignUser = (userId, userSystems) => {
        const systemIds = userSystems.map((system) => system.id).filter((id) => id !== createdSystem.id);
        const userToEdit = [{ userId: userId, systemIds: systemIds }];
        dispatchEditUsers(userToEdit);
    };

    const dispatchEditUsers = (usersToEdit) => {
        setAssignedUsersIds(usersToEdit[0].userId);
        dispatch(assignUsers({ editUsers: usersToEdit }));
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.deselectAll();
        }
    }, [usersAppData.data]);

    return (
        <DataGridWrapper>
            <StyledWMDataGrid
                title={<Typography variant={'T10-2'}>{t('systems-tab.all-systems-page.assign-users-form.title')}</Typography>}
                entityName='User'
                columnDefs={headCells}
                rows={userRows}
                gridConfig={gridConfig}
                loadingData={!createdSystem || usersAppData.loading || updateUsersAppData.loading}
                hideExport={true}
                hideColumnPicker={true}
                locale={datagridLocale}
                footerActions={getFooterActions(updateUsersAppData?.loading)}
                pagination={false}
                clearRowSelection={true}
            />
        </DataGridWrapper>
    );
};
