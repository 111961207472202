import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const StyledActivationScreenGrid = styled(Grid)`
    background-color: #f0f4fa;

    .formGrid {
        padding-top: 40px;
        min-height: 100vh;
        letter-spacing: 0;
        background-color: white;

        .formTitleGrid {
            margin-top: 50px;

            .formTitle {
                font-family: Poppins;
                font-weight: 600;
                font-size: 24px;
                color: #2f426c;
            }
        }

        .loadingErrorGrid {
            margin-top: 20px;

            .loadingErrorLabel {
                font-family: Poppins;
                font-weight: 600;
                font-size: 18px;
                color: #eb3232;
            }
        }

        .formFieldsGrid {
            margin-top: 30px;

            .userFieldLabel {
                font-family: proximaNova;
                font-size: 14px;
                color: #2f426c;
            }
        }
    }
`;

export const StyledImageGrid = styled(Grid)`
    .imageGrid {
        position: relative;

        .imageTitleTag {
            position: absolute;
            top: 90px;
            left: 40px;
            letter-spacing: 0;
            line-height: 1.2;
            font-family: Poppins;
            font-size: 45px;
            font-weight: 600;
            color: #2f426c;
            z-index: 2;
        }

        .imageTag {
            height: 100vh;
            transform: translateX(-20%) !important;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
`;
