import React, { SVGProps } from 'react';

export const TargetUserWithErrorSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <circle cx='10.5' cy='7.5' r='2' stroke='currentColor' />
        <path
            d='M14.5 14.5C14.5 12.2909 12.7091 10.5 10.5 10.5C8.29086 10.5 6.5 12.2909 6.5 14.5'
            stroke='currentColor'
            strokeLinecap='round'
        />
        <circle cx='10.5' cy='10.5' r='9' stroke='currentColor' />
        <path d='M10.5 2.5L10.5 0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M18.5 10.5L20.5 10.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.5 20.5L10.5 18.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.5 10.5L2.5 10.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <g transform='translate(14, 1)'>
            <rect x='1' y='1' width='5' height='5' rx='2.5' stroke='currentColor' />
            <circle cx='3.5' cy='3.5' r='2' fill='#EB3232' />
        </g>
    </svg>
);
