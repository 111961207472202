import React from 'react';
import { StyledLink, BoxSubHeader, StyledBox, StyledTooltipText, BoxHeader, StyledImg } from './building-settings.styles';
import { useTranslation } from '../../../../../../../apps/home/src/localization';
import { WMSwitch, WMDivider } from '@walkme/wm-ui';
import { Checkbox, Tooltip } from '@walkme/ui-core';

export const ScreenshotSettings = ({ isEnabled, handleToggle, isBlur, setIsBlur, screenshotConfiguration }) => {
    const { t } = useTranslation('general');

    const screenshotDisabledTooltipTitle = screenshotConfiguration ? (
        <StyledTooltipText>
            {t('systems-tab.new-system-form.configure-step.building-settings.screenshots-settings.screenshots-disabled')}
        </StyledTooltipText>
    ) : null;

    return (
        <StyledBox>
            <div className='top-area'>
                <div className='top-area-internal'>
                    <BoxHeader>
                        <div className='main-title'>
                            {t('systems-tab.new-system-form.configure-step.building-settings.screenshots-settings.main-title')}
                            <div className='center-area' style={{ paddingLeft: '6px' }}>
                                <Tooltip
                                    title={
                                        <StyledTooltipText>
                                            {t(
                                                'systems-tab.new-system-form.configure-step.building-settings.screenshots-settings.no-retroactive'
                                            )}
                                        </StyledTooltipText>
                                    }
                                    arrow>
                                    <StyledImg src={`/assets/icons/icon-info.svg`} />
                                </Tooltip>
                            </div>
                        </div>
                    </BoxHeader>
                    <BoxSubHeader>
                        {t('systems-tab.new-system-form.configure-step.building-settings.screenshots-settings.description')}
                        <StyledLink to={{ pathname: 'https://support.walkme.com/knowledge-base/screenshots/' }} target='_blank'>
                            {' ' + t('buttons.learn-more')}
                        </StyledLink>
                    </BoxSubHeader>
                </div>
                <div className='center-area'>
                    <Tooltip title={screenshotDisabledTooltipTitle} arrow>
                        <WMSwitch checked={isEnabled} onChange={handleToggle} disabled={screenshotConfiguration} />
                    </Tooltip>
                </div>
            </div>
            {isEnabled && (
                <>
                    <WMDivider style={{ marginTop: '14px' }} />
                    <div className='bottom-area'>
                        <Tooltip className={'screenshot-disabled-tooltip'} title={screenshotDisabledTooltipTitle} arrow>
                            <Checkbox checked={isBlur} onChange={() => setIsBlur(!isBlur)} disabled={screenshotConfiguration} />
                        </Tooltip>
                        <div>{t('systems-tab.new-system-form.configure-step.building-settings.screenshots-settings.blur')}</div>
                        <div className='center-area'>
                            <Tooltip
                                title={
                                    <StyledTooltipText>
                                        {t(
                                            'systems-tab.new-system-form.configure-step.building-settings.screenshots-settings.illegible-text'
                                        )}
                                    </StyledTooltipText>
                                }
                                arrow>
                                <StyledImg src={`/assets/icons/icon-info.svg`} />
                            </Tooltip>
                        </div>
                    </div>
                </>
            )}
        </StyledBox>
    );
};
