import React from 'react';

export const EditIconSmall = (props: any) => {
    return (
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M11.2111 3.44007C11.7969 2.85429 12.7467 2.85429 13.3325 3.44007C13.9182 4.02586 13.9182 4.97561 13.3325 5.56139L6.59524 12.2986C6.43057 12.4633 6.22983 12.5873 6.00892 12.661L3.16287 13.6097L4.11155 10.7636C4.18519 10.5427 4.30925 10.342 4.47392 10.1773L11.2111 3.44007Z'
                    stroke='#385FEB'
                />
            </g>
        </svg>
    );
};

export const TrashIconSmall = (props: any) => {
    return (
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M4.09998 3.50049H13.1V13.0005C13.1 13.8289 12.4284 14.5005 11.6 14.5005H5.59998C4.77155 14.5005 4.09998 13.8289 4.09998 13.0005V3.50049Z'
                    stroke='#6D81A6'
                />
                <path
                    id='Vector_3'
                    d='M6.09998 3.50049H11.1V3.00049C11.1 2.17206 10.4284 1.50049 9.59998 1.50049H7.59998C6.77155 1.50049 6.09998 2.17206 6.09998 3.00049V3.50049Z'
                    stroke='#6D81A6'
                />
                <path id='Vector_4' d='M2.09998 3.50049L15.1 3.50049L2.09998 3.50049Z' stroke='#6D81A6' strokeLinejoin='round' />
                <path id='Vector_5' d='M7.09998 11.5005L7.09998 6.50049' stroke='#6D81A6' strokeLinecap='round' />
                <path id='Vector_6' d='M10.1 11.5005L10.1 6.50049' stroke='#6D81A6' strokeLinecap='round' />
            </g>
        </svg>
    );
};

export const RescanIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none' {...props}>
            <path
                d='M7.19995 13.5005C4.16238 13.5005 1.69995 11.0381 1.69995 8.00049C1.69995 4.96292 4.16238 2.50049 7.19995 2.50049C10.2375 2.50049 12.7 4.96292 12.7 8.00049'
                stroke='#6D81A6'
                strokeLinecap='round'
            />
            <path
                d='M12.3464 10.6469L10.5535 8.85404C10.2385 8.53906 10.4616 8.00049 10.9071 8.00049L14.4928 8.00049C14.9383 8.00049 15.1614 8.53906 14.8464 8.85404L13.0535 10.6469C12.8582 10.8422 12.5417 10.8422 12.3464 10.6469Z'
                fill='#6D81A6'
            />
        </svg>
    );
};

export const CancelIcon = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path id='Vector_2' d='M15.5 4.5L4.5 15.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                <path id='Vector_3' d='M4.5 4.5L15.5 15.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
            </g>
        </svg>
    );
};

export const ResetIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path
                d='M9 13.501C12.0376 13.501 14.5 11.0385 14.5 8.00098C14.5 4.96341 12.0376 2.50098 9 2.50098C5.96243 2.50098 3.5 4.96341 3.5 8.00098'
                stroke='#385FEB'
                strokeLinecap='round'
            />
            <path
                d='M3.85355 10.6474L5.64645 8.85453C5.96143 8.53955 5.73835 8.00098 5.29289 8.00098L1.70711 8.00098C1.26165 8.00098 1.03857 8.53955 1.35355 8.85453L3.14645 10.6474C3.34171 10.8427 3.65829 10.8427 3.85355 10.6474Z'
                fill='#385FEB'
            />
        </svg>
    );
};

export const GuidedAnswerIcon = (props: any) => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector' clipPath='url(#clip0_3799_74044)'>
                <g id='Group'>
                    <path
                        id='Vector_2'
                        d='M12.8002 4.73567C12.5309 3.98904 12.1076 3.30738 11.5578 2.73488C11.008 2.16238 10.3441 1.71183 9.60895 1.41245C8.87385 1.11307 8.08403 0.971553 7.29071 0.997068C6.4974 1.02258 5.7183 1.21456 5.00396 1.56056C4.28961 1.90655 3.65596 2.39883 3.14411 3.00548C2.63226 3.61212 2.25364 4.31958 2.03279 5.08197C1.81195 5.84436 1.75382 6.64466 1.86217 7.43096C1.97052 8.21727 2.24293 8.97202 2.66174 9.64626L1 13.1378L4.49153 11.4761C5.13504 11.8765 5.85259 12.1432 6.60138 12.2603'
                        stroke='#2F426C'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path id='Vector_3' d='M4.73438 4.73438H9.09101' stroke='#2F426C' strokeLinecap='round' strokeLinejoin='round' />
                    <path id='Vector_4' d='M4.73438 7.53516H6.29032' stroke='#2F426C' strokeLinecap='round' strokeLinejoin='round' />
                </g>
                <g id='Group_2'>
                    <path
                        id='Vector_5'
                        d='M11.382 6.40235C12.077 6.40063 12.7577 6.59957 13.3424 6.9753C13.9271 7.35103 14.3909 7.88756 14.6781 8.52046C14.9653 9.15337 15.0637 9.85572 14.9615 10.5432C14.8592 11.2306 14.5607 11.874 14.1018 12.3959L14.5499 15.0037L12.1911 13.5473C11.9262 13.6121 11.6547 13.6456 11.382 13.6469C10.4213 13.6469 9.49999 13.2653 8.82069 12.5859C8.14139 11.9066 7.75977 10.9853 7.75977 10.0246C7.75977 9.06393 8.14139 8.1426 8.82069 7.46329C9.49999 6.78399 10.4213 6.40235 11.382 6.40235V6.40235Z'
                        stroke='#2F426C'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path id='Vector_6' d='M12.6711 10.0234H10.1816' stroke='#2F426C' strokeLinecap='round' strokeLinejoin='round' />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_3799_74044'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export const DemoIcon = (props: any) => {
    return (
        <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M4.69995 13.2604V2.7425C4.69995 2.35661 5.11856 2.11617 5.45189 2.31061L14.4673 7.56958C14.798 7.76252 14.798 8.24042 14.4673 8.43335L5.45189 13.6923C5.11856 13.8868 4.69995 13.6463 4.69995 13.2604Z'
                    stroke='#6D81A6'
                />
            </g>
        </svg>
    );
};

export const CloseIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path d='M11.5 4.5L4.5 11.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M4.5 4.5L11.5 11.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export const CloseIconThin = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.8536 4.85355C16.0488 4.65829 16.0488 4.34171 15.8536 4.14645C15.6583 3.95118 15.3417 3.95118 15.1464 4.14645L10 9.29289L4.85355 4.14645C4.65829 3.95118 4.34171 3.95118 4.14645 4.14645C3.95118 4.34171 3.95118 4.65829 4.14645 4.85355L9.29289 10L4.14645 15.1464C3.95118 15.3417 3.95118 15.6583 4.14645 15.8536C4.34171 16.0488 4.65829 16.0488 4.85355 15.8536L10 10.7071L15.1464 15.8536C15.3417 16.0488 15.6583 16.0488 15.8536 15.8536C16.0488 15.6583 16.0488 15.3417 15.8536 15.1464L10.7071 10L15.8536 4.85355Z'
                fill='#6D81A6'
            />
        </svg>
    );
};

export const PlusIcon = (props: any) => {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path id='Vector_2' d='M7 3.99805V10.0022' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                <path id='Vector_3' d='M3.99805 7L10.0022 7' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
            </g>
        </svg>
    );
};

export const ShareIcon = (props: any) => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M11.5 10.5V11.5C11.5 12.6046 10.6046 13.5 9.5 13.5H2.5C1.39543 13.5 0.5 12.6046 0.5 11.5V4.5C0.5 3.39543 1.39543 2.5 2.5 2.5H9.5C10.6046 2.5 11.5 3.39543 11.5 4.5V4.5'
                    stroke='#6D81A6'
                    strokeLinecap='round'
                />
                <path
                    id='Vector_3'
                    d='M5.5 10.5V9.5C5.5 8.39543 6.39543 7.5 7.5 7.5H15.5M15.5 7.5L13.5 5.5M15.5 7.5L13.5 9.5'
                    stroke='#6D81A6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    );
};

export const LightBulbIcon = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <g id='Vector_2'>
                    <mask id='path-1-inside-1_4215_76129' fill='white'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M12 14.2422C13.206 13.435 14 12.0602 14 10.5C14 8.01472 11.9853 6 9.5 6C7.01472 6 5 8.01472 5 10.5C5 12.0602 5.79401 13.435 7 14.2422V15C7 15.5523 7.44772 16 8 16H11C11.5523 16 12 15.5523 12 15V14.2422Z'
                        />
                    </mask>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12 14.2422C13.206 13.435 14 12.0602 14 10.5C14 8.01472 11.9853 6 9.5 6C7.01472 6 5 8.01472 5 10.5C5 12.0602 5.79401 13.435 7 14.2422V15C7 15.5523 7.44772 16 8 16H11C11.5523 16 12 15.5523 12 15V14.2422Z'
                        fill={props.style?.fill ?? '#6D81A6'}
                    />
                    <path
                        d='M12 14.2422L11.4437 13.4112C11.1664 13.5968 11 13.9085 11 14.2422H12ZM7 14.2422H8C8 13.9085 7.83356 13.5968 7.55626 13.4112L7 14.2422ZM13 10.5C13 11.7125 12.3842 12.7817 11.4437 13.4112L12.5563 15.0732C14.0277 14.0883 15 12.4079 15 10.5H13ZM9.5 7C11.433 7 13 8.567 13 10.5H15C15 7.46243 12.5376 5 9.5 5V7ZM6 10.5C6 8.567 7.567 7 9.5 7V5C6.46243 5 4 7.46243 4 10.5H6ZM7.55626 13.4112C6.61575 12.7817 6 11.7125 6 10.5H4C4 12.4079 4.97228 14.0883 6.44374 15.0732L7.55626 13.4112ZM8 15V14.2422H6V15H8ZM8 15H6C6 16.1046 6.89543 17 8 17V15ZM11 15H8V17H11V15ZM11 15V17C12.1046 17 13 16.1046 13 15H11ZM11 14.2422V15H13V14.2422H11Z'
                        fill={props.style?.fill ?? '#6D81A6'}
                        mask='url(#path-1-inside-1_4215_76129)'
                    />
                </g>
                <path id='Vector_3' d='M7.5 17.5H11.5' stroke='#6D81A6' strokeLinecap='round' />
                <path id='Vector_4' d='M17.5 10.5H16' stroke='#6D81A6' strokeLinecap='round' />
                <path id='Vector_5' d='M3 10.5H1.5' stroke='#6D81A6' strokeLinecap='round' />
                <path id='Vector_6' d='M4.5 5.5L3.5 4.5' stroke='#6D81A6' strokeLinecap='round' />
                <path id='Vector_7' d='M14.5 5.5L15.5 4.5' stroke='#6D81A6' strokeLinecap='round' />
                <path id='Vector_8' d='M9.5 4V2.5' stroke='#6D81A6' strokeLinecap='round' />
            </g>
        </svg>
    );
};

export const DotsIcon = (props: any) => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <circle id='Vector_2' cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 7 13)' fill='#6D81A6' />
                <circle id='Vector_3' cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 7 9)' fill='#6D81A6' />
                <circle id='Vector_4' cx='1' cy='1' r='1' transform='matrix(1 0 0 -1 7 5)' fill='#6D81A6' />
            </g>
        </svg>
    );
};

export const TrashIconBig = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M4.5 4.5H15.5V16C15.5 16.8284 14.8284 17.5 14 17.5H6C5.17157 17.5 4.5 16.8284 4.5 16V4.5Z'
                    stroke='#E91616'
                />
                <path
                    id='Vector_3'
                    d='M7.5 4.5H12.5V4C12.5 3.17157 11.8284 2.5 11 2.5H9C8.17157 2.5 7.5 3.17157 7.5 4V4.5Z'
                    stroke='#E91616'
                />
                <path id='Vector_4' d='M2.5 4.5L17.5 4.5' stroke='#E91616' strokeLinecap='round' strokeLinejoin='round' />
                <path id='Vector_5' d='M8.5 14.5L8.5 7.5' stroke='#E91616' strokeLinecap='round' />
                <path id='Vector_6' d='M11.5 14.5L11.5 7.5' stroke='#E91616' strokeLinecap='round' />
            </g>
        </svg>
    );
};

export const SettingsIcon = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M15.546 6.48177L15.3808 6.71473L15.4975 6.97536L16.0514 8.212L16.1542 8.4417L16.4 8.4959L18.5 8.959L18.5 11.0662L16.4372 11.36L16.1641 11.3988L16.0514 11.6506L15.4975 12.8872L15.3848 13.1389L15.5375 13.3685L16.7816 15.2394L15.256 16.765L13.4841 15.4393L13.2502 15.2643L12.9828 15.3822L11.7367 15.9318L11.49 16.0406L11.4454 16.3065L11.077 18.5L9.02044 18.5L8.55065 16.2855L8.49753 16.0351L8.26331 15.9318L7.01716 15.3822L6.74982 15.2643L6.51586 15.4393L4.74399 16.765L3.20548 15.2264L4.336 13.3496L4.47083 13.1257L4.36402 12.8872L3.81017 11.6506L3.71573 11.4397L3.49394 11.375L1.5 10.793L1.5 8.91089L3.58921 8.49815L3.84292 8.44803L3.94863 8.212L4.50248 6.97536L4.61921 6.71473L4.45402 6.48177L3.22507 4.74866L4.64314 3.30345L6.40546 4.44279L6.62188 4.5827L6.86139 4.48763L8.24601 3.93801L8.49169 3.84048L8.54945 3.58254L9.0158 1.5L11.0808 1.5L11.4461 3.56056L11.4927 3.82315L11.7367 3.93076L12.9828 4.48038L13.2372 4.59254L13.4667 4.43576L15.243 3.2223L16.7726 4.75195L15.546 6.48177Z'
                    stroke='#6D81A6'
                />
                <circle id='Vector_3' cx='10' cy='10' r='2.5' stroke='#6D81A6' />
            </g>
        </svg>
    );
};

export const ScanSummaryIcon = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M3.5 3C3.5 2.17157 4.17157 1.5 5 1.5H13.1716C13.5694 1.5 13.9509 1.65804 14.2322 1.93934L16.0607 3.76777L16.4142 3.41421L16.0607 3.76777C16.342 4.04907 16.5 4.4306 16.5 4.82843V17C16.5 17.8284 15.8284 18.5 15 18.5H5C4.17157 18.5 3.5 17.8284 3.5 17V3Z'
                    stroke='#6D81A6'
                />
                <rect id='Vector_3' x='7.25' y='9.25' width='5.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
                <rect id='Vector_4' x='7.25' y='13.25' width='5.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
                <rect id='Vector_5' x='7.25' y='5.25' width='3.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
            </g>
        </svg>
    );
};

export const RescanIconBig = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.99661 2.5C7.99661 2.22386 7.77276 2 7.49661 2H4C2.89543 2 2 2.89543 2 4V7.5C2 7.77614 2.22386 8 2.5 8C2.77614 8 3 7.77614 3 7.5V4C3 3.44772 3.44772 3 4 3H7.49661C7.77276 3 7.99661 2.77614 7.99661 2.5ZM7.99661 17.5C7.99661 17.2239 7.77276 17 7.49661 17H4C3.44772 17 3 16.5523 3 16V12.5C3 12.2239 2.77614 12 2.5 12C2.22386 12 2 12.2239 2 12.5V16C2 17.1046 2.89543 18 4 18H7.49661C7.77276 18 7.99661 17.7761 7.99661 17.5ZM12.5 18C12.2239 18 12 17.7761 12 17.5C12 17.2239 12.2239 17 12.5 17H16C16.5523 17 17 16.5523 17 16V12.5C17 12.2239 17.2239 12 17.5 12C17.7761 12 18 12.2239 18 12.5V16C18 17.1046 17.1046 18 16 18H12.5ZM12.5 3C12.2239 3 12 2.77614 12 2.5C12 2.22386 12.2239 2 12.5 2H16C17.1046 2 18 2.89543 18 4V7.5C18 7.77614 17.7761 8 17.5 8C17.2239 8 17 7.77614 17 7.5V4C17 3.44772 16.5523 3 16 3H12.5Z'
                    fill='#6D81A6'
                />
                <circle id='Vector_3' cx='10' cy='10' r='3.5' stroke='#6D81A6' />
            </g>
        </svg>
    );
};

export const GuidedAnswersIconBig = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector' clipPath='url(#clip0_4728_205374)'>
                <g id='Group'>
                    <path
                        id='Vector_2'
                        d='M16.1679 5.80682C15.8218 4.84761 15.278 3.97186 14.5717 3.23636C13.8654 2.50086 13.0124 1.92203 12.068 1.53741C11.1236 1.1528 10.1089 0.970981 9.08967 1.00376C8.07047 1.03654 7.06955 1.28318 6.15181 1.72769C5.23407 2.17219 4.42 2.80464 3.76241 3.58401C3.10482 4.36337 2.61839 5.27226 2.33467 6.25172C2.05094 7.23118 1.97626 8.25934 2.11546 9.26952C2.25467 10.2797 2.60464 11.2493 3.1427 12.1156L1.00781 16.6012L5.49348 14.4663C6.32021 14.9807 7.24207 15.3234 8.20407 15.4738'
                        stroke='#6D81A6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path id='Vector_3' d='M5.80469 5.80664H11.4018' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                    <path id='Vector_4' d='M5.80469 9.40527H7.80365' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                </g>
                <g id='Group_2'>
                    <path
                        id='Vector_5'
                        d='M14.345 7.94923C15.2379 7.94701 16.1124 8.2026 16.8636 8.68531C17.6148 9.16801 18.2107 9.85731 18.5796 10.6704C18.9486 11.4835 19.075 12.3858 18.9437 13.269C18.8123 14.1522 18.4288 14.9787 17.8392 15.6493L18.4149 18.9995L15.3844 17.1285C15.0442 17.2118 14.6953 17.2547 14.345 17.2564C13.1108 17.2564 11.9271 16.7661 11.0544 15.8934C10.1817 15.0207 9.69141 13.837 9.69141 12.6028C9.69141 11.3686 10.1817 10.185 11.0544 9.31224C11.9271 8.43952 13.1108 7.94923 14.345 7.94923V7.94923Z'
                        stroke='#6D81A6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path id='Vector_6' d='M16.0011 12.6025H12.8027' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_4728_205374'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export const EditIconBig = (props: any) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g id='Vector'>
                <path
                    id='Vector_2'
                    d='M13.9393 3.76777C14.5251 3.18198 15.4749 3.18198 16.0607 3.76777C16.6464 4.35355 16.6464 5.3033 16.0607 5.88909L7.659 14.2908C7.47874 14.471 7.25553 14.6023 7.01042 14.6724L4.4143 15.4141L5.15605 12.818C5.22608 12.5729 5.35742 12.3497 5.53768 12.1694L13.9393 3.76777Z'
                    stroke='#6D81A6'
                />
            </g>
        </svg>
    );
};

export const PlayIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
            <path
                d='M3.5 9.50896V2.49104C3.5 2.10514 3.91861 1.86471 4.25193 2.05915L10.2673 5.56811C10.5981 5.76105 10.5981 6.23895 10.2673 6.43189L4.25194 9.94085C3.91861 10.1353 3.5 9.89486 3.5 9.50896Z'
                stroke='#6D81A6'
            />
        </svg>
    );
};

export const PauseIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none' {...props}>
            <rect x='3.10059' y='1.5005' width='2' height='9' rx='0.5' stroke='#6D81A6' />
            <rect x='8.10059' y='1.5005' width='2' height='9' rx='0.5' stroke='#6D81A6' />
        </svg>
    );
};

export const StopIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none' {...props}>
            <mask id='path-1-inside-1_4799_190030' fill='white'>
                <rect x='2.10059' y='1.50098' width='9' height='9' rx='0.5' />
            </mask>
            <rect
                x='2.10059'
                y='1.50098'
                width='9'
                height='9'
                rx='0.5'
                stroke='#6D81A6'
                strokeWidth='2'
                mask='url(#path-1-inside-1_4799_190030)'
            />
        </svg>
    );
};

export const LoadingSpinnerIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
            <circle cx='6' cy='6' r='5' stroke='url(#paint0_angular_4799_69999)' strokeWidth='2' />
            <defs>
                <radialGradient
                    id='paint0_angular_4799_69999'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientUnits='userSpaceOnUse'
                    gradientTransform='translate(6 6) rotate(-90) scale(6)'>
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='1' stopColor='#3B61EB' />
                </radialGradient>
            </defs>
        </svg>
    );
};

export const DividerIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='2' height='20' viewBox='0 0 2 20' fill='none' {...props}>
            <path d='M1 0L0.999999 20' stroke='#E3E9FC' />
        </svg>
    );
};

export const ExclamationIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none' {...props}>
            <circle cx='5.90039' cy='5.50049' r='5.5' fill='#E91616' />
            <circle cx='0.5' cy='0.5' r='0.5' transform='matrix(1 0 0 -1 5.40039 9.00049)' fill='white' />
            <path d='M5.90039 2.50049V6.50049' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export const CheckmarkIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none' {...props}>
            <g clipPath='url(#clip0_5022_49603)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.40039 12.0005C9.7141 12.0005 12.4004 9.3142 12.4004 6.00049C12.4004 2.68678 9.7141 0.000488281 6.40039 0.000488281C3.08668 0.000488281 0.400391 2.68678 0.400391 6.00049C0.400391 9.3142 3.08668 12.0005 6.40039 12.0005ZM8.75394 5.35404C8.94921 5.15878 8.94921 4.8422 8.75394 4.64693C8.55868 4.45167 8.2421 4.45167 8.04684 4.64693L5.40039 7.29338L4.75394 6.64693C4.55868 6.45167 4.2421 6.45167 4.04684 6.64693C3.85158 6.8422 3.85158 7.15878 4.04684 7.35404L5.04684 8.35404C5.2421 8.5493 5.55868 8.5493 5.75394 8.35404L8.75394 5.35404Z'
                    fill='#41B7A6'
                />
            </g>
            <defs>
                <clipPath id='clip0_5022_49603'>
                    <rect width='12' height='12' fill='white' transform='translate(0.400391 0.000488281)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export const GlobeIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
            <circle cx='9.99736' cy='10.0008' r='7.60088' stroke='#6D81A6' />
            <path d='M17.1945 10H2.79297' stroke='#6D81A6' strokeLinecap='round' />
            <path
                d='M9.99326 2.79985C9.60751 2.79985 7.29297 6.40024 7.29297 10.0006C7.29297 13.601 9.60751 17.2014 9.99326 17.2014'
                stroke='#6D81A6'
                strokeLinecap='round'
            />
            <path
                d='M10.0067 2.79985C10.3925 2.79985 12.707 6.40024 12.707 10.0006C12.707 13.601 10.3925 17.2014 10.0067 17.2014'
                stroke='#6D81A6'
                strokeLinecap='round'
            />
        </svg>
    );
};

export const OpenUrlIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8 3.5C8 3.22386 7.77614 3 7.5 3H4C2.89543 3 2 3.89543 2 5V12C2 13.1046 2.89543 14 4 14H11C12.1046 14 13 13.1046 13 12V8.5C13 8.22386 12.7761 8 12.5 8C12.2239 8 12 8.22386 12 8.5V12C12 12.5523 11.5523 13 11 13H4C3.44772 13 3 12.5523 3 12V5C3 4.44772 3.44772 4 4 4H7.5C7.77614 4 8 3.77614 8 3.5Z'
                fill='#385FEB'
            />
            <path d='M8.5 7.5L12.5 3.5' stroke='#385FEB' strokeLinecap='round' strokeLinejoin='round' />
            <path
                d='M13.5 2H10.2071C9.76165 2 9.53857 2.53857 9.85355 2.85355L13.1464 6.14645C13.4614 6.46143 14 6.23835 14 5.79289V2.5C14 2.22386 13.7761 2 13.5 2Z'
                fill='#385FEB'
            />
        </svg>
    );
};

export const RevertIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
            <path
                d='M2.5 9.5C2.5 7.82845 3.02359 6.19889 3.99723 4.84018C4.97087 3.48147 6.34565 2.46186 7.92848 1.92456C9.51132 1.38726 11.2227 1.35925 12.8223 1.84448C14.4218 2.3297 15.8293 3.30378 16.8468 4.62991C17.8644 5.95604 18.441 7.56759 18.4957 9.23825C18.5504 10.9089 18.0804 12.5547 17.1518 13.9446C16.2231 15.3344 14.8824 16.3984 13.318 16.9872C11.7536 17.576 10.044 17.66 8.42945 17.2274'
                stroke='#6D81A6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M10.5 5.5V8V9.5L13.5 12.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
            <path
                d='M1.06752 9L3.93248 9C4.3564 9 4.58798 9.49443 4.31659 9.82009L2.88411 11.5391C2.68421 11.7789 2.31579 11.7789 2.11589 11.5391L0.68341 9.82009C0.412024 9.49443 0.643602 9 1.06752 9Z'
                fill='#6D81A6'
            />
        </svg>
    );
};

export const SystemIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
            <rect x='2.5' y='2.5' width='15' height='15' rx='1.5' stroke='#6D81A6' />
            <rect x='2.25' y='6.25' width='15.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
        </svg>
    );
};

export const SystemIconSmall = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
            <rect x='1.5' y='1.5' width='9' height='9' rx='1.5' stroke='#6D81A6' />
            <rect x='1.25' y='4.25' width='9.5' height='0.5' rx='0.25' stroke='#6D81A6' strokeWidth='0.5' />
        </svg>
    );
};

export const UniversalIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11' fill='none' {...props}>
            <circle cx='5.5' cy='5.5' r='5' stroke='#6D81A6' />
            <path d='M10.5 5.5H0.5' stroke='#6D81A6' strokeLinecap='round' />
            <path d='M5.5 0.5C4.9 0.5 3.5 3.45426 3.5 5.5C3.5 7.54574 4.9 10.5 5.5 10.5' stroke='#6D81A6' strokeLinecap='round' />
            <path d='M5.5 0.5C6.1 0.5 7.5 3.45426 7.5 5.5C7.5 7.54574 6.1 10.5 5.5 10.5' stroke='#6D81A6' strokeLinecap='round' />
        </svg>
    );
};

export const UniversalIconSmall = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none' {...props}>
            <path
                d='M6.72727 4H1.27273M7 4C7 5.65685 5.65685 7 4 7C2.34315 7 1 5.65685 1 4C1 2.34315 2.34315 1 4 1C5.65685 1 7 2.34315 7 4ZM4 1.27273C3.67273 1.27273 2.90909 2.88414 2.90909 4C2.90909 5.11586 3.67273 6.72727 4 6.72727C4.32727 6.72727 5.09091 5.11586 5.09091 4C5.09091 2.88414 4.32727 1.27273 4 1.27273Z'
                stroke='#6D81A6'
                strokeWidth='0.75'
                strokeLinecap='round'
            />
        </svg>
    );
};

export const StarsIcon = (props: any) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
            <path
                d='M6.5 3.5L6.80228 5.76263C7.04126 7.55148 8.44852 8.95874 10.2374 9.19772L12.5 9.5L10.2374 9.80228C8.44852 10.0413 7.04126 11.4485 6.80228 13.2374L6.5 15.5L6.19772 13.2374C5.95874 11.4485 4.55148 10.0413 2.76262 9.80228L0.5 9.5L2.76263 9.19772C4.55148 8.95874 5.95874 7.55148 6.19772 5.76262L6.5 3.5Z'
                stroke='#6D81A6'
                strokeLinejoin='round'
            />
            <path
                d='M12.5 1.5L12.5821 1.88237C12.7473 2.65171 13.3483 3.25268 14.1176 3.41789L14.5 3.5L14.1176 3.58211C13.3483 3.74732 12.7473 4.34829 12.5821 5.11763L12.5 5.5L12.4179 5.11763C12.2527 4.34829 11.6517 3.74732 10.8824 3.58211L10.5 3.5L10.8824 3.41789C11.6517 3.25269 12.2527 2.65171 12.4179 1.88237L12.5 1.5Z'
                stroke='#6D81A6'
                strokeLinejoin='round'
            />
        </svg>
    );
};
