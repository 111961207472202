import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SystemsState } from '@walkme-admin-center/libs/state-management-systems';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';
import { CssTextField } from '../../../../data-integration-form-style';
import { Collapse, InputAdornment } from '@material-ui/core';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import {
    StyledSearch,
    Container,
    CardContainer,
    FooterContainer,
    ContentContainer,
    SystemsContainer,
    SystemName,
    CardSelected,
    CardSelectedIcon,
    SelectIcon,
    FieldLabel,
} from './assign-systems-step-style';

export const AssignSystemsStep = ({ viewFlag }) => {
    const [search, setSearch] = useState('');
    const formApi = useForm();

    const systems = useSelector((state: { systemsState: SystemsState }) => state.systemsState.systems);

    const isChecked = (guid) => {
        const { systems } = formApi.getState().values;
        return systems?.some((sys) => sys.guid === guid);
    };

    const systemClicked = (checked, fields, system) => {
        if (!checked) {
            fields.push({ guid: system.guid, name: system.displayName });
        } else {
            const { systems } = formApi.getState().values;
            const index = systems.findIndex((sys) => sys.guid === system.guid);
            fields.remove(index);
        }
    };

    const searchChange = (event) => {
        setSearch(event.target.value);
    };

    const filterSystems = systems.data.filter((sys) => sys.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1);

    const isAllSelected = formApi.getState().values.systems?.length === systems.data.length;

    const handleSelectAll = () => {
        if (isAllSelected) {
            formApi.change('systems', []);
        } else {
            const newArr = systems.data.map((sys) => {
                return {
                    guid: sys.guid,
                    name: sys.displayName,
                };
            });
            formApi.change('systems', newArr);
        }
    };

    const [didMaximize, setDidMaximize] = useState(false);
    const isMinified = systems.data.length > 4 && !didMaximize;

    return (
        <Container className={!viewFlag && 'contained'} onClick={() => setDidMaximize(true)}>
            <StyledSearch viewFlag={viewFlag}>
                <CssTextField
                    className={'search'}
                    variant='outlined'
                    value={search}
                    onChange={searchChange}
                    placeholder={'Search..'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment style={{ width: '8px' }} position='start'>
                                <img src={'assets/icons/data-integrations/search-icon-medium.svg'} />
                            </InputAdornment>
                        ),
                    }}
                />
                <span
                    style={{ color: '#385FEB', fontSize: '12px', fontWeight: 600, cursor: 'pointer' }}
                    onClick={() => handleSelectAll()}>{`${isAllSelected ? 'Remove All' : 'Select All'}`}</span>
            </StyledSearch>
            <Collapse in={!isMinified} collapsedSize='9rem'>
                <FieldArray name={'systems'}>
                    {({ fields }) => (
                        <SystemsContainer viewFlag={viewFlag}>
                            {filterSystems.map((system) => {
                                const checked = isChecked(system.guid);
                                return (
                                    <CardContainer
                                        className={`${checked ? 'selected' : ''}`}
                                        key={system.id}
                                        onClick={() => systemClicked(checked, fields, system)}>
                                        {checked && (
                                            <>
                                                <CardSelected />
                                                <SelectIcon fontSize={'small'} />{' '}
                                            </>
                                        )}
                                        <ContentContainer>
                                            <img src={retrieveSystemIcon(system.settings)} width='20px' height='20px' />
                                        </ContentContainer>
                                        <SystemName className={`${checked ? 'selected' : ''}`}>{system.displayName}</SystemName>
                                    </CardContainer>
                                );
                            })}
                        </SystemsContainer>
                    )}
                </FieldArray>
            </Collapse>
            <Collapse in={isMinified}>
                <FooterContainer>
                    <WMButton variant={WMButtonVariant.Secondary} onClick={() => setDidMaximize(true)} style={{ border: 'unset' }}>
                        Show All Systems
                    </WMButton>
                </FooterContainer>
            </Collapse>
        </Container>
    );
};

export default AssignSystemsStep;
