import { PreferenceKey } from '../../consts';
import { useDispatch, useSelector } from 'react-redux';
import { SelfHostedState } from '../self-hosted-state';
import { useCallback, useEffect } from 'react';
import { getPreference, setPreference } from '../self-hosted.slice';

const usePreference = (key: PreferenceKey): [boolean, (value: boolean) => void] => {
    const value = useSelector((state: { selfHostedState: SelfHostedState }) => state.selfHostedState.preferences[key]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPreference(key));
    }, [dispatch, key]);

    const setValue = useCallback(
        (newValue: boolean) => {
            if (newValue !== value) {
                dispatch(setPreference(key, newValue));
            }
        },
        [dispatch, key]
    );

    return [value, setValue];
};

export { usePreference };
