import React, { useCallback, useState } from 'react';
import {
    CloseIcon,
    StyledDialog,
    StyledDialogTitle,
    StyledExternalLink,
    StyledPrimaryActionButton,
    StyledSelectedAppContainer,
} from './create-system-dialog.styles';
import { Button, DialogActions, DialogContent, Typography } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState, impersonationThunks } from '@walkme-admin-center/libs/state-management-systems';
import { CreateSystemSteps } from '../../../../../../../libs/state-management-systems/src/lib/types/createSystem';
import { CreateSystemStepper } from '../stepper/create-system-stepper';
import { SelectContractStep } from '../steps/select-contract/select-contract';
import { SelectApplicationStep } from '../steps/select-application/select-application';
import { PurposeAndUsageStep } from '../steps/purpose-and-usage/purpose-and-usage';
import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { SystemPurpose, SystemUsage } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { ConfigureStep } from '../steps/optional-configure/configure';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { ExternalLinkIcon } from '../assets/icons/external-link';
import { SystemCreationSaveAndPublishDialog } from '../steps/optional-configure/system-creation-save-and-publish-dialog';
import { ImpersonatorDialog } from '../../impersonator-dialog';

type CreateSystemDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const CreateSystemDialog = ({ isOpen, onClose }: CreateSystemDialogProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [primaryActionButtonIsLoading, setPrimaryActionButtonIsLoading] = useState<boolean>(false);
    const step: CreateSystemSteps = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.step.data);
    const type: string = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.type.data);
    const usage: SystemUsage = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.usage.data);
    const purpose: SystemPurpose = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.purpose.data);
    const selectedSystemApp = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSystemApp.data
    );
    const selectedContract = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedContract.data
    );
    const appUrlsData = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.appUrls.data);
    const isCustomMode = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.isCustomMode.data);
    const isCreateCustomSystem = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateCustomSystem.data
    );
    const customAppName = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.customAppName.data);
    const currentSnackbarMessage = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.notificationMessage
    );
    const isNeverShowFeedbackDialog = window.localStorage.getItem('neverShowFeedbackDialog');
    const { loggedInUserAppData } = useLoggedInUser();
    const accountEmail = loggedInUserAppData?.data?.account?.email;
    const userId = loggedInUserAppData?.data?.id;
    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;
    const isTestingMode = window.localStorage.getItem('testingNewSystemCreation');
    const isWalkMeAccount = accountEmail.split('@')[1] === 'walkme.com' && !isTestingMode;
    const isContractStepDisabled = purpose === SystemPurpose.TRIAL || isWalkMeAccount;
    const stepErrors = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.stepErrors);

    const saveAndPublishDialogIsOpen = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.saveAndPublishDialogIsOpen
    );

    const handleSnackBarClose = () => {
        dispatch(createSystemSlice.actions.cleanUpNotificationMessage());
    };
    const getDialogBodyComponent = () => {
        switch (step) {
            case CreateSystemSteps.PURPOSE_AND_USAGE:
                return <PurposeAndUsageStep />;
            case CreateSystemSteps.SELECT_APPLICATION:
                return <SelectApplicationStep />;
            case CreateSystemSteps.SELECT_CONTRACT:
                return <SelectContractStep />;
            case CreateSystemSteps.CONFIGURE_SYSTEM:
                return <ConfigureStep />;
            default:
                return <PurposeAndUsageStep />;
        }
    };

    type StepNavClickProps = { direction: 'next' | 'back' };
    const handleStepNavClick = ({ direction }: StepNavClickProps) => {
        dispatch(createSystemSlice.actions.setCreateSystemStep(direction === 'next' ? step + 1 : step - 1));
    };

    const handleOpenCompleteSystemCreationDialog = () => {
        dispatch(createSystemSlice.actions.setCompleteSystemCreationModalVisibility(true));
    };

    const openSaveAndPublishDialog = () => {
        dispatch(createSystemSlice.actions.setSaveAndPublishDialogIsOpen(true));
    };

    const onSaveAndPublishClicked = () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(openSaveAndPublishDialog));
        } else {
            openSaveAndPublishDialog();
        }
    };

    const getDialogActionButtonsProps = () => {
        const buttonProps = {
            secondaryActionButton: {
                text: t('buttons.cancel'),
                visible: true,
                onClick: onClose,
            },
            primaryActionButton: {
                text: t('buttons.next'),
                visible: true,
                onClick: () => handleStepNavClick({ direction: 'next' }),
                disabled: true,
                isLoading: false,
            },
        };

        switch (step) {
            case CreateSystemSteps.PURPOSE_AND_USAGE:
                buttonProps.secondaryActionButton.text = t('buttons.cancel');
                buttonProps.secondaryActionButton.onClick = onClose;
                buttonProps.primaryActionButton.disabled = !type || !usage;
                break;

            case CreateSystemSteps.SELECT_APPLICATION:
                buttonProps.secondaryActionButton.text = t('buttons.back');
                buttonProps.secondaryActionButton.onClick = () => {
                    handleStepNavClick({ direction: 'back' });
                    dispatch(createSystemSlice.actions.resetSelectApplicationStep());
                };
                buttonProps.primaryActionButton.onClick = () => {
                    if (isCustomMode) {
                        if (isCreateCustomSystem && !isNeverShowFeedbackDialog && appUrlsData[0]?.url?.length > 0) {
                            dispatch(createSystemSlice.actions.setCreateSystemFeedbackVisibility(true));
                        } else {
                            handleStepNavClick({ direction: 'next' });
                        }
                    } else {
                        handleStepNavClick({ direction: 'next' });
                    }
                };
                buttonProps.primaryActionButton.disabled =
                    (isCustomMode && ((isCreateCustomSystem && customAppName.length === 0) || appUrlsData[0]?.url?.length === 0)) ||
                    (!isCustomMode && !selectedSystemApp);
                break;

            case CreateSystemSteps.SELECT_CONTRACT:
                buttonProps.secondaryActionButton.text = t('buttons.back');
                buttonProps.secondaryActionButton.onClick = () => {
                    handleStepNavClick({ direction: 'back' });
                    dispatch(createSystemSlice.actions.resetContractsStep());
                };
                buttonProps.primaryActionButton.disabled = !isContractStepDisabled && selectedContract === null;
                buttonProps.primaryActionButton.onClick = handleOpenCompleteSystemCreationDialog;

                break;

            case CreateSystemSteps.CONFIGURE_SYSTEM:
                buttonProps.secondaryActionButton.visible = false;
                buttonProps.primaryActionButton.text = t('buttons.save-and-publish');
                buttonProps.primaryActionButton.disabled =
                    isStepHasError(CreateSystemSteps.CONFIGURE_SYSTEM) || primaryActionButtonIsLoading;
                buttonProps.primaryActionButton.onClick = onSaveAndPublishClicked;
                buttonProps.primaryActionButton.isLoading = primaryActionButtonIsLoading;

            default:
                break;
        }

        return buttonProps;
    };

    const isStepHasError = useCallback(
        (step: CreateSystemSteps) => {
            const currentStepErrors = stepErrors[step];

            return Object.values(currentStepErrors).some((error) => error);
        },
        [stepErrors]
    );

    const { secondaryActionButton, primaryActionButton } = getDialogActionButtonsProps();

    const onPublishSucceeded = (message: string) => {
        dispatch(
            createSystemSlice.actions.setNotificationMessage({
                text: message,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            })
        );
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };

    const onPublishFailed = (message: string) => {
        dispatch(
            createSystemSlice.actions.setNotificationMessage({
                text: message,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            })
        );
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };

    return (
        <>
            <StyledDialog isOpen={isOpen} onClose={onClose} preventClose={true}>
                <StyledDialogTitle>
                    <div>{t('systems-tab.create-system')}</div>
                    <CloseIcon onClick={onClose} />
                </StyledDialogTitle>
                <DialogContent>
                    {step >= 0 ? (
                        <CreateSystemStepper activeStep={step} type={type} usage={usage} selectedSystemApp={selectedSystemApp} />
                    ) : null}
                    {getDialogBodyComponent()}
                </DialogContent>
                <DialogActions>
                    {step == CreateSystemSteps.PURPOSE_AND_USAGE ? (
                        <StyledExternalLink href='https://support.walkme.com/knowledge-base/creating-a-system/' target='_blank'>
                            <Typography className={'link-typography'} variant={'T20-2'}>
                                {t('systems-tab.new-system-form.learn-more')}
                            </Typography>
                            <ExternalLinkIcon />
                        </StyledExternalLink>
                    ) : null}
                    {selectedSystemApp && step == CreateSystemSteps.SELECT_APPLICATION && !isCustomMode ? (
                        <StyledSelectedAppContainer>
                            <img
                                className='selectedAppIcon'
                                alt='selected app icon'
                                src={selectedSystemApp?.icons?.highRes}
                                width={25}
                                height={25}
                            />
                            <span>{selectedSystemApp?.displayName}</span>
                        </StyledSelectedAppContainer>
                    ) : null}
                    {secondaryActionButton.visible ? (
                        <Button size='large' variant='text' onClick={secondaryActionButton.onClick}>
                            {secondaryActionButton.text}
                        </Button>
                    ) : null}
                    <StyledPrimaryActionButton
                        size='large'
                        onClick={primaryActionButton.onClick}
                        disabled={primaryActionButton.disabled}
                        loading={primaryActionButton.isLoading}>
                        {primaryActionButton.text}
                    </StyledPrimaryActionButton>
                </DialogActions>
            </StyledDialog>
            {saveAndPublishDialogIsOpen ? (
                <SystemCreationSaveAndPublishDialog onSuccess={onPublishSucceeded} onFailure={onPublishFailed} />
            ) : null}
            <ImpersonatorDialog isLoading={primaryActionButtonIsLoading} />
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
            />
        </>
    );
};
