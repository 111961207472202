import React from 'react';
import { useStyles } from './styles';
import Environment from '../environment';
import { PartialSystemData, SystemMatcher } from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';
import Box from '@material-ui/core/Box/Box';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import WMDivider from '@walkme/wm-ui/components/WMDivider';
import { SimplifiedEnvironmentData } from '../types';
import { isDefaultEnvironment } from '../utils';
import { Environment as EnvironmentData } from '../..//types';

export interface EnvironmentsProps {
    systemData: PartialSystemData;
    selectedEnvId: number;
    environments: EnvironmentData[];
    setCurrentEnvId: React.Dispatch<React.SetStateAction<number>>;
    shouldDisable: boolean;
    toolTipDisableMessage?: string;
}

const Environments = ({
    systemData,
    selectedEnvId,
    setCurrentEnvId,
    environments,
    shouldDisable,
    toolTipDisableMessage,
}: EnvironmentsProps) => {
    const classes = useStyles();

    const defaultEnvironmentsData: SimplifiedEnvironmentData[] = [];
    const customEnvironmentsData: SimplifiedEnvironmentData[] = [];

    environments.forEach((envData: EnvironmentData) => {
        const envId = envData.value;

        const environmentData: SimplifiedEnvironmentData = {
            id: envId,
            name: envData.label,
            matchers: systemData && systemData.matchers[envId] ? [...systemData.matchers[envId]] : [],
        };

        if (isDefaultEnvironment(envId)) {
            defaultEnvironmentsData.push(environmentData);
        } else {
            customEnvironmentsData.push(environmentData);
        }
    });

    const getEnvironmentControls = (envData: SimplifiedEnvironmentData[]) => {
        return envData.map((envData: SimplifiedEnvironmentData) => {
            const isEnvActive = !!envData.matchers.find((matcher: SystemMatcher) => matcher.isActive);
            const envId = envData.id;

            return (
                <Environment
                    key={envId}
                    name={envData.name}
                    isActive={isEnvActive}
                    isSelected={selectedEnvId === envId}
                    onClick={() => setCurrentEnvId(envId)}
                    shouldDisable={shouldDisable}
                />
            );
        });
    };

    return (
        <Tooltip title={shouldDisable ? toolTipDisableMessage || '' : ''} placement='top'>
            <Box className={classes.container}>
                {getEnvironmentControls(defaultEnvironmentsData)}
                {customEnvironmentsData.length > 0 && (
                    <>
                        <WMDivider className={classes.divider} />
                        {getEnvironmentControls(customEnvironmentsData)}
                    </>
                )}
            </Box>
        </Tooltip>
    );
};

export default Environments;
