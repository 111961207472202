export const generateSnippet = (envPath: string, userGuid: string, cdnUrl: string) => {
    return `
    <script type="text/javascript">
    (function() {
      var walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = '${cdnUrl}/users/${userGuid}${envPath}/walkme_${userGuid}_https.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(walkme, s);
      window._walkmeConfig = {smartLoad:true};
    })();
    </script>
  `;
};
