import { useField } from 'react-final-form';
import { useEffect } from 'react';

interface useFieldMirroringParams {
    from: string;
    to: string;
    isActive: boolean;
}

const useFieldMirroring = ({ from, to, isActive }: useFieldMirroringParams) => {
    const {
        input: { value: fromValue },
    } = useField(from, { subscription: { value: true } });
    const {
        input: { onChange: toOnChange },
    } = useField(to, { subscription: {} });

    useEffect(() => {
        if (isActive) {
            toOnChange(fromValue);
        }
    }, [fromValue, toOnChange, isActive]);
};

export default useFieldMirroring;
