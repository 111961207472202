import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { useLocation } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { StyledLink, StyledBadge } from './styles/styles';

export interface BadgesNavBarMenuItem {
    name: string;
    route: string;
    withBadges?: boolean;
    badgesCount?: number;
}

export interface BadgesNavBarProps {
    menuItems: BadgesNavBarMenuItem[];
}

export const NavBarWithBadges = ({ menuItems }: BadgesNavBarProps) => {
    const location = useLocation();

    return (
        <List style={{ display: 'inline-flex' }}>
            {menuItems.map((item: BadgesNavBarMenuItem, i: number) => {
                if (item.withBadges) {
                    return (
                        <StyledBadge
                            key={i}
                            showZero
                            color="secondary"
                            badgeContent={item.badgesCount}>
                            <ListItem
                                className="listItem"
                                button
                                component={StyledLink}
                                to={item.route}
                                selected={location.pathname === item.route}
                            >
                                <ListItemText className="listItemText" primary={item.name} />
                            </ListItem>
                        </StyledBadge>
                    )
                } else {
                    return (
                        <ListItem
                            key={i}
                            className="listItem"
                            button
                            component={StyledLink}
                            to={item.route}
                            selected={location.pathname === item.route}
                        >
                            <ListItemText className="listItemText" primary={item.name} />
                        </ListItem>
                    );
                }
            })}
        </List>
    );
};
