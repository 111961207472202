import {Typography} from "@material-ui/core";
import React from "react";

export function MainTitle() {
    return <Typography
        style={{
            fontFamily: "Poppins",
            color: "#2F426C",
            fontSize: "20px",
            fontWeight: 600,
            marginTop: "24px",
        }}
    >
        A new self hosting experience is coming soon!
    </Typography>;
}
