import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { DesktopTrackedApp, DesktopTrackedApps, PredefinedDesktopTrackedApps } from '../types';

class InsightsApi {
    private readonly httpClient: WMhttpClient;
    private readonly INSIGHTS_ROUTE = 'insights-papi/desktopTrackedApps';

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
    }

    async getDesktopTrackedApps(msaId: number): Promise<DesktopTrackedApps> {
        const response = await this.httpClient.instance.get<DesktopTrackedApps>(`${this.INSIGHTS_ROUTE}/${msaId}`);

        return response.data || [];
    }

    async getDesktopTrackedApp(msaId: number, systemId: number): Promise<DesktopTrackedApp> {
        const response = await this.httpClient.instance.get<DesktopTrackedApp>(`${this.INSIGHTS_ROUTE}/${msaId}/${systemId}`);

        return response.data;
    }

    async getPredefinedDesktopTrackedApps(): Promise<PredefinedDesktopTrackedApps> {
        const response = await this.httpClient.instance.get<PredefinedDesktopTrackedApps>(`${this.INSIGHTS_ROUTE}/predefined`);

        return response.data || [];
    }

    async createDesktopTrackedApp(msaId: number, desktopTrackedApp: DesktopTrackedApp): Promise<DesktopTrackedApp> {
        const response = await this.httpClient.instance.post<DesktopTrackedApp>(`${this.INSIGHTS_ROUTE}/${msaId}`, { ...desktopTrackedApp });

        return response.data;
    }

    async updateDesktopTrackedApp(msaId: number, systemId: number, desktopTrackedApp: DesktopTrackedApp): Promise<DesktopTrackedApp> {
        const response = await this.httpClient.instance.post<DesktopTrackedApp>(`${this.INSIGHTS_ROUTE}/${msaId}/${systemId}`, { ...desktopTrackedApp });

        return response.data;
    }

    async activateDesktopTrackedApps(msaId: number, systemIds: number[]): Promise<any> {
        const response = await this.httpClient.instance.post(`${this.INSIGHTS_ROUTE}/${msaId}/activate`, { systemIds });

        return response.data;
    }

    async deactivateDesktopTrackedApps(msaId: number, systemIds: number[]): Promise<any> {
        const response = await this.httpClient.instance.post(`${this.INSIGHTS_ROUTE}/${msaId}/deactivate`, { systemIds });

        return response.data;
    }
}

export const insightsApi = new InsightsApi(wmhttpClientSharedInstance);
