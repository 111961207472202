import React, { useState } from 'react';
import {
    AccordionStyled,
    AccordionSummaryStyled,
    AccordionDetailsColumn,
} from '../../crawler-form/steps/assign-systems-step/access-window-style';
import { GlobeIcon, OpenUrlIcon } from '../../../../data-integrations-list/integration-card/icons/icons';
import { EmptyState } from '@walkme/ui-core';
import { EmptyMsgContainer } from './styles';
import {
    CardTitleContainerWrapper,
    CardTitleContainer,
    CardTitleText,
    CardSubtitleText,
    BadgeText,
    Badge,
    UrlText,
    UrlsContainer,
    UrlContainer,
} from './url-sections-style';

interface UrlSectionsProps {
    sections: Array<{ title: string; subtitle: string; urls: Array<string>; count: number; warningName: string }>;
}

export const UrlSections = ({ sections }: UrlSectionsProps) => {
    const [expanded, setExpanded] = useState('');

    return (
        <>
            {sections.map(({ title, subtitle, urls, count, warningName }, i) => (
                <AccordionStyled
                    key={i}
                    expanded={expanded === title}
                    onChange={(event, isExpanded) => setExpanded(isExpanded ? title : '')}
                    bordercolor={expanded === title ? '#385FEB' : '#E3E9FC'}>
                    <AccordionSummaryStyled
                        expandIcon={
                            <img
                                src={'assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg'}
                                style={{ width: '20px', height: '20px' }}
                            />
                        }>
                        <CardTitleContainerWrapper>
                            <CardTitleContainer>
                                <CardTitleText>{title}</CardTitleText>
                                <Badge>
                                    <BadgeText>{count}</BadgeText>
                                </Badge>
                            </CardTitleContainer>
                            <CardSubtitleText>{subtitle}</CardSubtitleText>
                        </CardTitleContainerWrapper>
                    </AccordionSummaryStyled>
                    <AccordionDetailsColumn>
                        {expanded === title && (count === 0 || !urls?.length) ? (
                            <EmptyMsgContainer>
                                <EmptyState variant='emptySearchResults' size='small' heading='Nothing to view here' />
                            </EmptyMsgContainer>
                        ) : (
                            <UrlsContainer>
                                {urls?.map((url, index) => (
                                    <UrlContainer key={index} onClick={() => window.open(url, '_blank')}>
                                        <GlobeIcon />
                                        <UrlText>{url}</UrlText>
                                        <div className='hover-only'>
                                            <OpenUrlIcon />
                                        </div>
                                    </UrlContainer>
                                ))}
                            </UrlsContainer>
                        )}
                    </AccordionDetailsColumn>
                </AccordionStyled>
            ))}
        </>
    );
};

export default UrlSections;
