import { Accordion, AccordionDetails, AccordionSummary, Button, RadioGroup, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const AccessWindowContainer = styled.div`
    display: flex;
    padding: 1.25rem 2rem;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
`;

export const SubWindowContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
`;

export const SubWindow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #ebeff7;
`;

export const WindowHeader = styled.div`
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #fff;
    border-radius: 8px;
    cursor: pointer;
`;

export const SubHeader = styled.div`
    padding: 2px 0px;
    color: #637191;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const SystemCounter = styled.div<{ collapsed: boolean }>`
    display: flex;
    padding: 0px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 4px;
    border-radius: 100px;
    background: ${(props) => (props.collapsed ? '#385FEB' : '#E4E9FC')};
    color: ${(props) => (props.collapsed ? '#FFF' : '#6C89F0')};
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const CollapsePanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    background: #fff;
    border-radius: 8px;
    width: 100%;
`;

export const EnableIntegrationButton = styled(Button)`
    border-radius: 40px;
    border: solid 1px rgb(59, 97, 235, 0.2);
    height: 40px;
    width: 83px;
    padding: 10px 20px;
    background: none;
    color: #385feb;
    text-transform: none;
    box-shadow: none;
    &:hover {
        border: 1px solid #385feb;
        background: none;
        box-shadow: none;
    }
`;

export const AccordionStyled = withStyles((theme) => ({
    root: (props: { bordercolor?: string }) => ({
        boxShadow: 'none',
        borderRadius: '8px !important',
        border: `1px solid ${props.bordercolor || '#ebeff7'}`,
        transition: `border-color 0.3s ease`,
        '&.Mui-expanded': {
            margin: 0,
        },
        '&::before': {
            content: 'none',
        },
        '&.Mui-disabled': {
            background: '#FFF',
        },
    }),
}))(Accordion);

export const AccordionSummaryStyled = withStyles((theme) => ({
    root: {
        height: 'auto',
        minHeight: '50px',
        padding: '16px 24px',
        '&.Mui-expanded': {
            minHeight: '50px',
        },
        '&.Mui-disabled': {
            opacity: 1,
            pointerEvents: 'auto',
        },
    },
    content: {
        margin: 0,
        '&.Mui-expanded': {
            margin: 0,
        },
    },
}))(AccordionSummary);

export const AccordionSummaryContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionDetailsStyled = withStyles((theme) => ({
    root: {
        padding: 0,
    },
}))(AccordionDetails);

export const AccordionDetailsColumn = withStyles((theme) => ({
    root: {
        flexDirection: 'column',
    },
}))(AccordionDetailsStyled);

export const AccordionHeaderStyled = styled.span`
    color: #2f426c;
    font-weight: 600;
    height: 24px;
`;

export const StyledRadioGroup = withStyles((theme) => ({
    root: {
        flexDirection: 'column',
        gap: '1.5rem',
    },
}))(RadioGroup);
