let Expr;
function createCache() {
    var keys = [];

    function cache(key, value) {
        // Use (key + " ") to avoid collision with native prototype properties (see Issue #157)
        if (keys.push(key + ' ') > Expr?.cacheLength) {
            // Only keep the most recent entries
            delete cache[keys.shift()];
        }
        return (cache[key + ' '] = value);
    }
    return cache;
}
let tokenCache = createCache();
const tokenize = (selector, parseOnly) => {
    var matched,
        match,
        tokens,
        type,
        soFar,
        groups,
        preFilters,
        cached = tokenCache[selector + ' '];

    const whitespace = '[\\x20\\t\\r\\n\\f]';
    const identifier = '(?:\\\\[\\da-fA-F]{1,6}' + whitespace + '?|\\\\[^\\r\\n\\f]|[\\w-]|[^\0-\\x7f])+';
    const attributes =
            '\\[' +
            whitespace +
            '*(' +
            identifier +
            ')(?:' +
            whitespace +
            // Operator (capture 2)
            '*([*^$|!~]?=)' +
            whitespace +
            // "Attribute values must be CSS identifiers [capture 5]
            // or strings [capture 3 or capture 4]"
            '*(?:\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)"|(' +
            identifier +
            '))|)' +
            whitespace +
            '*\\]',
        pseudos =
            ':(' +
            identifier +
            ')(?:\\((' +
            // To reduce the number of selectors needing tokenize in the preFilter, prefer arguments:
            // 1. quoted (capture 3; capture 4 or capture 5)
            '(\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)")|' +
            // 2. simple (capture 6)
            '((?:\\\\.|[^\\\\()[\\]]|' +
            attributes +
            ')*)|' +
            // 3. anything else (capture 2)
            '.*' +
            ')\\)|)';
    const rtrim = new RegExp('^' + whitespace + '+|((?:^|[^\\\\])(?:\\\\.)*)' + whitespace + '+$', 'g');
    const rcomma = new RegExp('^' + whitespace + '*,' + whitespace + '*');
    const rleadingCombinator = new RegExp('^' + whitespace + '*([>+~]|' + whitespace + ')' + whitespace + '*');
    const booleans =
        'checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|' + 'ismap|loop|multiple|open|readonly|required|scoped';
    const matchExpr = {
        ID: new RegExp('^#(' + identifier + ')'),
        CLASS: new RegExp('^\\.(' + identifier + ')'),
        TAG: new RegExp('^(' + identifier + '|[*])'),
        ATTR: new RegExp('^' + attributes),
        PSEUDO: new RegExp('^' + pseudos),
        CHILD: new RegExp(
            '^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(' +
                whitespace +
                '*(even|odd|(([+-]|)(\\d*)n|)' +
                whitespace +
                '*(?:([+-]|)' +
                whitespace +
                '*(\\d+)|))' +
                whitespace +
                '*\\)|)',
            'i'
        ),
        bool: new RegExp('^(?:' + booleans + ')$', 'i'),

        // For use in libraries implementing .is()
        // We use this for POS matching in `select`
        needsContext: new RegExp(
            '^' +
                whitespace +
                '*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(' +
                whitespace +
                '*((?:-\\d)?\\d*)' +
                whitespace +
                '*\\)|)(?=[^-]|$)',
            'i'
        ),
    };

    if (cached) {
        return parseOnly ? 0 : cached.slice(0);
    }

    soFar = selector;
    groups = [];
    preFilters = Expr?.preFilter;

    while (soFar) {
        // Comma and first run
        if (!matched || (match = rcomma.exec(soFar))) {
            if (match) {
                // Don't consume trailing commas as valid
                soFar = soFar.slice(match[0].length) || soFar;
            }
            groups.push((tokens = []));
        }

        matched = false;

        // Combinators
        if ((match = rleadingCombinator.exec(soFar))) {
            matched = match.shift();
            tokens.push({
                value: matched,

                // Cast descendant combinators to space
                type: match[0].replace(rtrim, ' '),
            });
            soFar = soFar.slice(matched.length);
        }

        // Filters
        for (type in Expr?.filter) {
            if ((match = matchExpr[type].exec(soFar)) && (!preFilters[type] || (match = preFilters[type](match)))) {
                matched = match.shift();
                tokens.push({
                    value: matched,
                    type: type,
                    matches: match,
                });
                soFar = soFar.slice(matched.length);
            }
        }

        if (!matched) {
            break;
        }
    }

    // Return the length of the invalid excess
    // if we're just parsing
    // Otherwise, throw an error or return tokens
    if (parseOnly) return soFar.length;

    if (soFar) throw new Error(`Syntax error, unrecognized expression: ${selector}`);

    return tokenCache(selector, groups).slice(0);
};

const balancedParenthesesJQueryValidator = (str) => {
    const parentheses = '[]()';
    const stack = [];
    let currChar;

    for (let i = 0; (currChar = str[i++]); ) {
        let bracePosition = parentheses.indexOf(currChar),
            braceType;
        //~ is truthy for any number but -1
        if (!~bracePosition) continue;

        braceType = bracePosition % 2 ? 'closed' : 'open';

        if (braceType === 'closed') {
            //If there is no open parenthese at all, return false OR
            //if the opening parenthese does not match ( they should be neighbours )
            if (!stack.length || parentheses.indexOf(stack.pop()) != bracePosition - 1) return false;
        } else {
            stack.push(currChar);
        }
    }

    //If anything is left on the stack <- not balanced
    return !stack.length;
};

const validateSelector = (selector: any) => {
    if (!selector) return false;
    else return balancedParenthesesJQueryValidator(selector);
};

export const validateJqueryFindElementObject = (obj) => {
    let returnValue;
    if (typeof obj.element === 'object') {
        returnValue = validateJqueryFindElementObject(obj.element);
    } else {
        returnValue = validateSelector(obj.element);
    }

    if (obj.context) {
        if (typeof obj.context == 'object') {
            returnValue &= validateJqueryFindElementObject(obj.context);
        } else {
            returnValue &= validateSelector(obj.context) as any;
        }
    }
    return returnValue;
};

export const getJqueryFindElementObject = (queryStr) => {
    if (!queryStr) return null;
    queryStr = queryStr?.trim();

    if (queryStr.indexOf('{') == 0) {
        try {
            return JSON.parse(queryStr);
        } catch (e) {
            //bad input
            return null;
        }
    } else {
        const obj: any = {};
        obj.element = queryStr;
        return obj;
    }
};
