import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActions, UsersState } from '../redux/users.slice';
import { AppData } from '@walkme-admin-center/libs/types';
import { Actions } from 'wm-accounts-sdk';

export const useActions = (): { actionsAppData: AppData<Actions> } => {
    const actionsAppData = useSelector((state: { usersState: UsersState }) => state.usersState.actions);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getActions());
    }, [dispatch]);

    return {
        actionsAppData,
    };
};

export default useActions;
