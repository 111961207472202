import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
    collapsedMaxHeight: string | number;
    expandedMaxHeight: string | number;
    baseTextColor: string;
}

const useStyles = makeStyles(() => ({
    codeBlock: {
        position: 'relative',
        '& pre': {
            boxSizing: 'border-box',
            borderRadius: '8px',
            transition: 'all 0.5s ease-in-out',
            scrollbarWidth: 'thin',
            scrollbarColor: (props: StyleProps) => `${props.baseTextColor}44 transparent`,
            scrollbarGutter: 'stable',
            '&::-webkit-scrollbar': {
                '-webkit-appearance': 'none',
                width: '10px',
                height: '10px',
            },
            '&::-webkit-scrollbar-track': {
                margin: '8px 16px',
            },
            '&::-webkit-scrollbar-thumb': {
                height: '40px',
                border: '4px solid transparent',
                borderRadius: '40px',
                boxShadow: (props: StyleProps) => `inset 0 0 2px 2px ${props.baseTextColor}44`,
            },
            '&::-webkit-scrollbar-corner': {
                '-webkit-appearance': 'none',
            },
        },
        '& code': {
            fontFamily: 'ProximaNova',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        '& .react-syntax-highlighter-line-number': {
            textAlign: 'center !important',
            paddingRight: '12px !important',
        },
    },
    'codeBlock--collapsed': {
        '& pre': {
            maxHeight: (props: StyleProps) => props.collapsedMaxHeight,
        },
    },
    'codeBlock--expanded': {
        '& pre': {
            maxHeight: (props: StyleProps) => props.expandedMaxHeight,
        },
    },
    wrapCodeLines: {
        '& pre': {
            paddingRight: '60px !important',
        },
        '& code': {
            whiteSpace: 'pre-wrap !important',
            overflowWrap: 'anywhere',
            '& > span': {
                display: 'block',
                marginLeft: 'calc(1.25em + 12px)',
                position: 'relative',
            },
        },
        '& .react-syntax-highlighter-line-number': {
            position: 'absolute',
            marginLeft: 'calc(-1.25em - 12px)',
        },
    },
    buttonsBox: {
        position: 'absolute',
        bottom: '8px',
        right: '18px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    button: {
        padding: 0,
        color: (props: StyleProps) => props.baseTextColor,
        '&.MuiIconButton-root:hover': {
            backgroundColor: 'rgba(59, 97, 235, 0.09)',
        },
        '& svg': {
            fontSize: '24px',
        },
    },
}));

const syntaxHighlighterCustomStyle = {
    padding: '8px 16px',
    margin: 0,
    overflow: 'auto',
};

export { useStyles, syntaxHighlighterCustomStyle };
