import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { StyledAccordionBox, StyledWMSelect } from '../common/styled-components';
import { Box } from '@material-ui/core';

const StyledForm = styled('form')`
    color: var(--wmGrayUltraDark);
    line-height: 20px;

    & .form-sectionTitle {
        font-weight: 600;
    }

    & .MuiTypography-body1 {
        line-height: 20px;
    }
`;

const StyledDeploymentMethodContainer = styled(Box)`
    & .deploymentMethod-name {
        margin-top: 20px;
    }

    & ${StyledWMSelect} {
        margin-top: 8px;
        margin-bottom: 21px;
    }

    & .deploymentMethod-txt {
        color: #637191;
        padding-right: 10px;
    }

    & > .MuiBox-root {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 10px;
    }
`;

const StyledAlert = styled(Alert)`
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: var(--wmGrayUltraDark);
    background-color: #fef8e8;
    border: 1px solid #f7b500;
    border-radius: 4px;
    margin-top: 12px;
    padding: 10px 35px 10px 16px;
    max-height: 70px;
    transition: all 0.3s ease-out;
    &.alert--hidden {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    & .MuiAlert-message {
        padding: 0;

        & span {
            display: inline-block;
        }
    }

    & .MuiAlert-action {
        position: absolute;
        top: 8px;
        right: 12px;

        & .MuiIconButton-root {
            color: #6d81a6;
            height: 20px;
            width: 20px;
        }
    }
`;

const StyledPackageLocationContainer = styled(Box)`
    margin-top: 33px;

    & .MuiFormGroup-root {
        gap: 16px;
        margin-top: 20px;
    }
`;

const StyledHostingMethodContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 12px;

    & .WMRadio-label {
        margin-left: 14px;
    }

    & .WMRadio-checked {
        color: #385feb;
    }
`;

const StyledDeploymentUrlContainer = styled(StyledAccordionBox)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 30px;
    margin-top: 10px;
`;

export {
    StyledForm,
    StyledDeploymentMethodContainer,
    StyledAlert,
    StyledPackageLocationContainer,
    StyledHostingMethodContainer,
    StyledDeploymentUrlContainer,
};
