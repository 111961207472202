import React from 'react';
import {
    StyledDataCollectionBody,
    StyledDataCollectionContainer,
    StyledDataCollectionTitle,
    StyledDataCollectionToasterMessage,
    StyledToasterContainer,
} from './data-collection.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import { DataCollectionOption } from './data-colleciton-option';
import { getDataCollectionOptions } from './data-collection.lib';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { Toaster, ToasterVariant } from '@walkme/ui-core';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { DataCollectionLevel } from '../../../../data-collection/data-collection.lib';

export const DataCollection = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const dataCollectionOptions = getDataCollectionOptions(t);
    const { loggedInUserAppData } = useLoggedInUser();
    const isImpersonator = !!loggedInUserAppData?.data?.impersonator;
    const selectedDataCollectionLevel = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedDataCollectionLevel
    );

    const onChangeDataCollectionLevel = (dataCollectionLevel) => {
        dispatch(createSystemSlice.actions.setSelectedDataCollectionLevel(dataCollectionLevel));
    };

    const getToasterText = () => {
        const elements = [];
        const fullText = t(`systems-tab.new-system-form.configure-step.data-collection-level.toaster.text`);
        const numOfLinks = (fullText.match(/\$\$/g) || []).length;
        const splitByDollars = fullText.split('$$');
        splitByDollars.forEach((element, index) => {
            elements.push(<span>{element}</span>);
            if (index < numOfLinks) {
                const linkText = t(`systems-tab.new-system-form.configure-step.data-collection-level.toaster.links.${index}.text`);
                const linkURL = t(`systems-tab.new-system-form.configure-step.data-collection-level.toaster.links.${index}.url`);
                elements.push(
                    <a href={linkURL} target='_blank'>
                        {linkText}
                    </a>
                );
            }
        });
        return elements;
    };

    const toasterMessage = <StyledDataCollectionToasterMessage>{getToasterText()}</StyledDataCollectionToasterMessage>;

    return (
        <StyledDataCollectionContainer>
            <StyledDataCollectionTitle>
                <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.data-collection-level.main-title`)}</div>
                <div className='sub-title'>
                    <div>{t(`systems-tab.new-system-form.configure-step.data-collection-level.sub-title`)}</div>
                </div>
            </StyledDataCollectionTitle>
            <StyledDataCollectionBody>
                {dataCollectionOptions.map((dcOption, dcOptionIndex) => (
                    <DataCollectionOption
                        id={dcOption.id}
                        title={dcOption.mainTitle}
                        bullets={dcOption.bullets}
                        isLast={dcOptionIndex === dataCollectionOptions?.length - 1}
                        isSelected={selectedDataCollectionLevel === dcOption.id}
                        isDisabled={isImpersonator && dcOption.id !== DataCollectionLevel.ENGAGEMENT}
                        onChange={onChangeDataCollectionLevel}
                    />
                ))}
            </StyledDataCollectionBody>
            <StyledToasterContainer
                className='dataCollectionToaster'
                icon={true}
                variant={ToasterVariant.Warning}
                message={toasterMessage}
            />
        </StyledDataCollectionContainer>
    );
};
