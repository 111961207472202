import React, { useState, MouseEvent, useRef } from 'react';
import { DropdownChevronDisplayOptions, Tooltip } from '@walkme/ui-core';
import { StyledSelector, StyledUUIDNotifier, StyledInfo, UuidButtonText, ExpectedFormatSelectorDiv } from './uuid-selector.styles';
import { DropdownOption, UUID, SalesforceSystems } from './uuid.lib';
import { StyledMenuItem, StyledUUIDMenu, StyledUUIDMenuWrapper, StyledWMMenu } from './uuid.styles';
import { Info } from '@walkme/ui-icons';
import { Check } from '@walkme/ui-icons/large';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { getTooltipByUUID } from './uuid-selector.lib';
import { useSystem, uuidSlice } from '@walkme-admin-center/libs/state-management-systems';
import { useDispatch } from 'react-redux';

export interface UUIdSelectorProps {
    selectedUUID: DropdownOption;
    UUIDOptions: DropdownOption[];
    setSelectedUUID: (UUIDOptions: DropdownOption) => void;
    disabledUUIDs: UUID[];
    systemId: number;
}

export function UuidSelector({ selectedUUID, UUIDOptions, setSelectedUUID, disabledUUIDs, systemId }: UUIdSelectorProps) {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentUUIDHovered, setCurrentUUIDHovered] = useState<string>(selectedUUID?.value);
    const currentUUIDHoveredRef = useRef<string>(null);
    const dropdownRef = useRef<any>(null);
    const { currentSystemAppData } = useSystem(systemId);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const handleChangeSelection = (selectedUUId: DropdownOption) => {
        if (disabledUUIDs.includes(selectedUUId?.value as UUID)) {
            return;
        }

        if (selectedUUId.value === UUID.IDP) {
            handleOpenIdpSelectionDialog();
            setCurrentUUIDHovered(selectedUUId?.value);
            handleClose();
            return;
        }
        setSelectedUUID(selectedUUId);
        setCurrentUUIDHovered(selectedUUId?.value);
        handleClose();
    };

    const handleItemHover = (e) => {
        if (e.target.id !== currentUUIDHoveredRef?.current) {
            setCurrentUUIDHovered(e.target.id);
        }
    };

    const handleOpenIdpSelectionDialog = () => {
        dispatch(uuidSlice.actions.setIdpSelectDialogIsOpen(true));
    };

    const SelectorDisplayedValue = (
        <ExpectedFormatSelectorDiv>
            <UuidButtonText>
                {selectedUUID ? t('systems-tab.new-system-form.configure-step.uuid.inputs.uuid-select-options.' + selectedUUID.label) : ''}
            </UuidButtonText>
        </ExpectedFormatSelectorDiv>
    );

    const chevronDisplayOption = DropdownChevronDisplayOptions.DEFAULT;

    const getUUIDTooltip = (uuid: UUID) => {
        const isUUIDDisabled = disabledUUIDs.includes(uuid);
        return getTooltipByUUID(uuid, t)?.[isUUIDDisabled ? 'disabled' : 'active'];
    };

    const filteredUUIDOptions = SalesforceSystems.includes(currentSystemAppData?.data?.type)
        ? UUIDOptions
        : UUIDOptions.filter((option) => option.value !== 'salesforce');

    return (
        <StyledUUIDMenuWrapper>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
                label={t(`systems-tab.new-system-form.configure-step.uuid.inputs.uuid-select-label`)}
            />
            <StyledWMMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={600}>
                <StyledUUIDMenu>
                    <div className='menu-window'>
                        {filteredUUIDOptions.map((UUIDOption: DropdownOption, index) => {
                            return (
                                <Tooltip title={getUUIDTooltip(UUIDOption.value as UUID)} placement={'top'} arrow={true}>
                                    <StyledMenuItem
                                        id={UUIDOption.value}
                                        isSelected={UUIDOption.value === selectedUUID?.value}
                                        isDisabled={disabledUUIDs.includes(UUIDOption.value as UUID)}
                                        onClick={() => handleChangeSelection(UUIDOption)}
                                        onMouseOver={handleItemHover}>
                                        <span id={UUIDOption.value} onMouseOver={handleItemHover}>
                                            {t(
                                                'systems-tab.new-system-form.configure-step.uuid.inputs.uuid-select-options.' +
                                                    UUIDOption.label
                                            )}
                                        </span>
                                        {selectedUUID?.value === UUIDOption.value ? <Check color='#395eea' /> : null}
                                    </StyledMenuItem>
                                </Tooltip>
                            );
                        })}
                    </div>
                    <div className='description-window'>
                        <Info />
                        <div className='title'>
                            <b>{t(`systems-tab.new-system-form.configure-step.uuid.${currentUUIDHovered}.description.title`)}</b>
                        </div>
                        <p className='description'>
                            {t(`systems-tab.new-system-form.configure-step.uuid.${currentUUIDHovered}.description.text`)}
                        </p>
                    </div>
                </StyledUUIDMenu>
            </StyledWMMenu>
            <StyledUUIDNotifier>
                <StyledInfo />
                <div className='description'>{t(`systems-tab.new-system-form.configure-step.uuid.inputs.fallback`)}</div>
            </StyledUUIDNotifier>
        </StyledUUIDMenuWrapper>
    );
}
