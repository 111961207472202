import Base, { openPdfInNewTab } from './base';
import {
    AuthUrlResponse,
    Provider,
    ProviderFormValues,
    Certificate,
    ProviderGroup,
    IdpSystemsFormValue,
} from '@walkme-admin-center/libs/state-management-sso-configuration';
import { samlSettingsSchema } from '../validation';
import get from 'lodash/get';
import { CONSTS } from '../../../common/consts';
import { sharedFields } from '../shared-fields';
import { idpApi } from '@walkme-admin-center/libs/idp-api';
import React from 'react';
import { StyledTransparentButton } from '../../../common/style';
import { Typography } from '@material-ui/core';

const SAMLPdf = require('../../../common/pdfFiles/idp_saml_instructions.pdf');

export class Saml extends Base {
    constructor() {
        super('Saml', samlSettingsSchema, SAMLPdf);
    }

    getFormFields = (): Array<any> => {
        return [
            sharedFields.idpName,
            sharedFields.samlCertificate,
            sharedFields.samlSingleSignOnURL,
            sharedFields.encryptAssertion,
            sharedFields.signAuthRequest,
        ];
    };

    initForm = (provider: Provider, assignSystemsForm: IdpSystemsFormValue): ProviderFormValues => {
        return {
            config: {
                type: this.getProtocol(),
                clientSecret: get(provider, 'config.clientSecret', ''),
                domain: get(provider, 'config.domain', ''),
                encryptAssertion: get(provider, 'config.encryptAssertion', false),
                signAuthRequest: get(provider, 'config.signAuthRequest', false),
            },
            name: get(provider, 'name', ''),
            fields: get(provider, 'fields', []),
            userIdentifier: get(provider, 'userIdentifier', ''),
            systems: this.prepareSystems(get(provider, 'id', null), get(provider, 'systems', []), assignSystemsForm),
        };
    };

    getProtocol = (): string => {
        return CONSTS.SAML;
    };

    downloadMetaData = async (): Promise<void> => {
        await idpApi.downloadSamlMetaData();
    };

    fixCertificateValue = (cert): string => {
        const pFrom = cert.indexOf('-----BEGIN CERTIFICATE-----') + '-----BEGIN CERTIFICATE-----'.length;
        const pTo = cert.indexOf('-----END CERTIFICATE-----');

        if (pTo === -1) {
            return cert;
        }

        const substring = cert.substring(pFrom, pTo);
        return `-----BEGIN CERTIFICATE-----${substring.replace(/\s/gm, '\n')}-----END CERTIFICATE-----`;
    };

    handleAuth = async (
        values: ProviderFormValues,
        provider: Provider,
        newCredentials: boolean,
        idpType: ProviderGroup,
        certificate: Certificate
    ): Promise<AuthUrlResponse> => {
        const tempValues: any = { ...values };
        const providerId = get(provider, 'id', null);
        tempValues.config.clientSecret = this.fixCertificateValue(values.config.clientSecret);
        tempValues.config.certificateId = certificate ? certificate.certificateId : null;
        tempValues.config.providerId = providerId;
        return this.getAuthParams(tempValues, providerId, idpType, newCredentials);
    };

    getIdpInstructions = (): React.ReactElement => {
        const linkOriginal = (
            <Typography
                component='span'
                onClick={() => openPdfInNewTab(SAMLPdf)}
                style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>
                Set up your SAML
            </Typography>
        );
        return (
            <>
                <p style={{ color: '#2F426C8C' }}>
                    {linkOriginal} application according to the instructions <br /> and copy the application properties to the fields below.
                </p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: '#2F426C8C', paddingBottom: '8px' }}>Download XML file:</span>
                    <StyledTransparentButton className={'d-metadata-Bt'} onClick={this.downloadMetaData}>
                        <span>Download metadata.xml</span>
                    </StyledTransparentButton>
                </div>
            </>
        );
    };
}
