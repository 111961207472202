import React from 'react';
import styled from 'styled-components';
import hosts from '../../hosts';

interface ViewLogoProps {
  cloudStorage: string;
}

export const ViewLogo = (props: ViewLogoProps) => {
  const host = hosts[props.cloudStorage] || {};

  if (!host.LogoIcon) {
    return null;
  }

  const StyledLogoIcon = styled(host.LogoIcon)`
    padding: 20px;
    width: 140px;
    height: auto;
  `;

  return <StyledLogoIcon />
}
