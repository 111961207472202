import { AwsLogoIcon } from '../icons/logos/aws-logo.icon'
import { Host } from './host';

class Aws extends Host {
  displayName = 'AWS';

  LogoIcon = AwsLogoIcon;
}

export default new Aws();
