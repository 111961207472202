import React from 'react';
import { ActionsGraph } from '../utils/create-actions-graph';
import { ActionNode } from './action-node/action-node';
import { RoleFormValues } from '../role-form/role-form';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
interface ControlledTreeViewProps {
    actionsGraph: ActionsGraph;
    currentValues: RoleFormValues;
    isPredefinedRole: boolean;
    onPermissionsChange: (permissions: Record<string, string>) => void;
}

const groupByCategory = (actions: ActionsGraph) => {
    const grouped = actions.reduce((group, action) => {
        const { category } = action;
        if (category != null) {
            group[category] = group[category] ?? [];
            group[category].push(action);
        }
        return group;
    }, {});
    return Object.keys(grouped)
        .sort()
        .reduce((obj, key) => {
            obj[key] = grouped[key];
            return obj;
        }, {});
};

const StyledBox = styled(Box)`
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #f5f5f5;
        border-radius: 10px;
    }
    ::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #8fa5f1;
        border-radius: 10px;
        &:hover {
            background-color: #728eed;
        }
    }
`;

export const ActionsTree = ({ actionsGraph, currentValues, isPredefinedRole, onPermissionsChange }: ControlledTreeViewProps) => {
    const { t } = useTranslation('general');
    const [expandAll, setExpandAll] = React.useState<boolean>(false);

    const groupedActions = groupByCategory(actionsGraph);

    const handleOpenAllClicked = () => {
        setExpandAll(!expandAll);
    };

    const renderTree = (groupedActions, currentValues: RoleFormValues) => {
        const flatTree = Object.values(groupedActions).flat();
        return Object.entries(groupedActions).map(([key, values], index) => (
            <Box style={{ padding: '6px' }} key={`${key}-${index}`}>
                <ActionNode
                    key={key}
                    category={key}
                    actionsGraph={values as ActionsGraph}
                    currentValues={currentValues}
                    expandAll={expandAll}
                    isPredefinedRole={isPredefinedRole}
                    onPermissionsChange={onPermissionsChange}
                    flatTree={flatTree as ActionsGraph}></ActionNode>
            </Box>
        ));
    };

    return (
        <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '34px 6px 8px 6px' }}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600 }}>
                        {t(`users-and-roles-tab.roles.custom-roles-tab.role-form.role-permissions-title`)}
                    </Typography>
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }}>
                        {t(`users-and-roles-tab.roles.custom-roles-tab.role-form.role-permissions-description`)}
                    </Typography>
                </Box>
                <Typography onClick={handleOpenAllClicked} style={{ color: '#3B61EB', cursor: 'pointer' }}>
                    {expandAll ? t('buttons.collapse-all') : t('buttons.expand-all')}
                </Typography>
            </Box>
            <StyledBox>{renderTree({ ...groupedActions }, currentValues)}</StyledBox>
        </Box>
    );
};
