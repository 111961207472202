export const ColIds = {
    email: 'email',
    name: 'name',
    role: 'role',
    systems: 'systems',
    loginMethod: 'loginMethod',
    lastLogin: 'lastLogin',
    status: 'status',
    actions: 'actions',
};

export const USERS_COLUMNS_STATE: string = 'usersColumnState';
