import React from 'react';
import { SnippetMethodContainer } from './snippet-method.styles';
import { useSelector } from 'react-redux';
import { CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import { StyledCodeBlock, StyledLink } from '../select-deployment.styles';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { generateSnippet } from '../../../../snippet';
import { EnvironmentSelectorControl } from '../utils/environment-selector-control';

export const SnippetMethod = () => {
    const { t } = useTranslation('general');
    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const selectedEnvironment = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedEnvironment
    );

    return (
        <SnippetMethodContainer>
            <EnvironmentSelectorControl />
            <div className='description'>
                <div>{t('systems-tab.new-system-form.deployment.snippet.copy-snippet')}</div>
                {/*INFO: hidden until deployment method finished*/}
                {/*<div>*/}
                {/*    {t('systems-tab.new-system-form.deployment.snippet.description.1')}*/}
                {/*    <StyledLink*/}
                {/*        to={{ pathname: 'https://support.walkme.com/knowledge-base/system-management/?ts=1720008269' }}*/}
                {/*        target='_blank'>*/}
                {/*        {' ' + t('buttons.learn-more')}*/}
                {/*    </StyledLink>*/}
                {/*</div>*/}
            </div>
            <div className='code-snippet'>
                <StyledCodeBlock
                    expandedAsDefault={true}
                    language='htmlbars'
                    style={tomorrowNightBlue}
                    text={generateSnippet(selectedEnvironment?.path, createdSystem?.guid.replace(/-/g, ''), window.clientConfig.NX_CDN_URL)}
                />
            </div>
        </SnippetMethodContainer>
    );
};
