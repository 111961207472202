import React, { useState } from 'react';
import { revertScan } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { useDispatch } from 'react-redux';
import { CssFormHeaderIcon } from '../../../form-header/form-header-style';
import { RevertIcon } from '../../../../data-integrations-list/integration-card/icons/icons';
import { CustomToolTipHover } from '../../../../common/components/custom-tool-tip';
import IdpDialog, { IdpDialogProps } from 'packages/pages/home/sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import { SmallerText } from '../../footers/footer-style';
import { CustomToolTip } from 'packages/pages/home/sso-configuration/src/lib/common/components/tool-tip/tool-tip';
import { IconButton } from '@walkme/ui-core';

export const RevertScan = ({ sourceGuid, onClick, disabled }: { sourceGuid: string; onClick?: () => {}; disabled?: boolean }) => {
    const dispatch = useDispatch();
    const [dialogProps, setDialogProps] = useState<IdpDialogProps | undefined>();

    const revert = () => {
        setDialogProps({
            open: true,
            handleClose: () => setDialogProps(undefined),
            confirm: async () => {
                await dispatch(revertScan(sourceGuid));
                onClick?.();
            },
            title: 'Revert to previous version?',
            content: (
                <>
                    Reverting restores the source to its previous version and deletes all rescan data. This action cannot be undone.
                    <br />
                    <br />
                    <SmallerText>Note: You still have the option to initiate another rescan.</SmallerText>
                </>
            ),
            type: 'errorButton',
            buttonText: 'Revert',
            cancelButtonText: 'Cancel',
        });
    };

    return (
        <>
            {dialogProps && (
                <IdpDialog
                    open={dialogProps.open}
                    handleClose={dialogProps.handleClose}
                    confirm={dialogProps.confirm}
                    title={dialogProps.title}
                    content={dialogProps.content}
                    type={dialogProps.type ?? 'defaultButton'}
                    buttonText={dialogProps.buttonText ?? 'I understand, continue'}
                    cancelButtonText={dialogProps.cancelButtonText ?? 'Cancel'}
                />
            )}
            <CustomToolTip
                placement={'top'}
                title={disabled ? 'Revert is not available for the current source' : 'Revert to previous version'}
                arrow>
                <IconButton style={{ width: '28px', height: '28px' }} variant='ghost' onClick={revert} disabled={disabled}>
                    <RevertIcon />
                </IconButton>
            </CustomToolTip>
        </>
    );
};
