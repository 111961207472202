import React, { useState } from 'react';
import { IdpSystems, Provider as IProvider } from '@walkme-admin-center/libs/state-management-sso-configuration';
import ProviderHeader from './provider-header/provider-header';
import ProviderActions from './provider-actions/provider-actions';
import { CONSTS } from '../../common/consts';
import IdpDialog, { IdpDialogProps } from '../../common/components/idp-dialog/idp-dialog';
import { ProviderContainer } from './provider-style';

export interface ProviderProps {
    provider: IProvider;
    defaultProvider: boolean;
    editProvider: (provider: IProvider) => void;
    editAttributes: (provider: IProvider) => void;
    assignSystems: (provider: IProvider) => void;
}

export const Provider = ({ provider, editProvider, editAttributes, assignSystems, defaultProvider }: ProviderProps) => {
    const [confirmDialog, setConfirmDialog] = useState<IdpDialogProps>({
        open: false,
        title: '',
        confirm: null,
        handleClose: null,
        content: '',
        type: '',
        buttonText: '',
        disableConfirm: false,
        cancelButtonText: 'Close',
    });

    const getConfirmFunctionByActionName = (name: string): void => {
        switch (name) {
            case CONSTS.DELETE_ACTION:
            case CONSTS.OPEN_ASSIGN_SYSTEMS:
                handleDelete();
                break;
        }
    };

    const openConfirm = (
        actionName: string,
        title: string,
        content: string,
        buttonText: string,
        cancelButtonText: string,
        type: string,
        disableConfirm = false
    ): void => {
        setConfirmDialog({
            open: true,
            title,
            type,
            buttonText,
            content,
            disableConfirm,
            cancelButtonText,
            confirm: (event) => {
                event.stopPropagation();
                setConfirmDialog({ ...confirmDialog, open: false });
                getConfirmFunctionByActionName(actionName);
            },
            handleClose: (event) => {
                event.stopPropagation();
                setConfirmDialog({ ...confirmDialog, open: false });
            },
        });
    };

    const handleDelete = (): void => {
        openAssignSystems();
    };

    const openEditProvider = (): void => {
        editProvider(provider);
    };

    const openEditAttributes = (): void => {
        editAttributes(provider);
    };

    const openAssignSystems = (): void => {
        assignSystems(provider);
    };

    const getProviderSystems = (): IdpSystems => {
        // if(oneId.oneIdProvider){
        //   return oneId.oneIdProvider === provider.id ? allSystems.map(system => {
        //     return {...system, enforceSso: true}
        //   }) : [];
        // } else {
        //   return provider.systems
        // }
        return provider.systems;
    };

    return (
        <>
            {confirmDialog.open && (
                <IdpDialog
                    open={confirmDialog.open}
                    handleClose={confirmDialog.handleClose}
                    title={confirmDialog.title}
                    content={confirmDialog.content}
                    type={confirmDialog.type}
                    buttonText={confirmDialog.buttonText}
                    cancelButtonText={confirmDialog.cancelButtonText}
                    confirm={confirmDialog.confirm}
                    disableConfirm={confirmDialog.disableConfirm}
                />
            )}
            <ProviderContainer>
                <ProviderHeader
                    name={provider.name}
                    config={provider.config}
                    providerSystems={getProviderSystems()}
                    isDefault={defaultProvider}
                />
                <ProviderActions
                    provider={provider}
                    isDefault={defaultProvider}
                    openConfirm={openConfirm}
                    openEditProvider={openEditProvider}
                    openEditAttributes={openEditAttributes}
                    openAssignSystems={openAssignSystems}
                />
            </ProviderContainer>
        </>
    );
};

export default Provider;
