const snippetCodeBlockStyle = {
    hljs: {
        backgroundColor: '#2F426C',
        color: '#FFFFFF',
    },
    'hljs-tag': {
        color: '#DFC162',
    },
    'hljs-function': {
        color: '#FFF',
    },
    'hljs-string': {
        color: '#5ACE8A',
    },
    'hljs-built_in': {
        color: '#de8995',
    },
    'hljs-keyword': {
        color: '#C29CE5',
    },
    'hljs-comment': {
        color: '#9CBDE5',
    },
    'hljs-literal': {
        color: '#F7B500',
    },
    'hljs-number': {
        color: '#F7B500',
    },
    'hljs-emphasis': {
        fontStyle: 'italic',
    },
    'hljs-strong': {
        fontWeight: 600,
    },
    linenumber: {
        color: '#637191',
    },
};

export { snippetCodeBlockStyle };
