import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'ProximaNova',
        color: '##F426C',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
    },
    snippet: {
        display: 'flex',
        maxWidth: 'calc(105vh - 64px - 182px - 65px)', //105vh width, 32*2px padding of content, 182px of enviornment container, 65 px divider (1px of divider + 32*2 px padding)
        marginBottom: '20px',
    },
}));

export { useStyles };