import React from 'react';
import { IconInfo } from '../../icons/icon-info';
import { useStyles } from './info-icon-with-tooltip-style';
import { StyledWMTooltip } from './styled-components';

interface InfoTooltipIconProps {
    tooltipTitle: string | React.ReactNode;
}

const InfoIconWithTooltip = ({ tooltipTitle }: InfoTooltipIconProps) => {
    const classes = useStyles();

    return (
        <StyledWMTooltip arrow title={tooltipTitle} placement='top'>
            <span className={classes.iconContainer}>
                <IconInfo className={classes.icon} />
            </span>
        </StyledWMTooltip>
    );
};

export { InfoIconWithTooltip };
