import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FooterStyledButton, FooterCancelButton } from './footer-style';

export const AssignSystemsFooter = ({ onClose }) => {
    const [loading, setLoading] = useState(false);
    const formApi = useForm();
    const { systems } = formApi.getState().values;

    const handleSubmit = async () => {
        setLoading(true);
        await formApi.submit();
        setLoading(false);
    };
    return (
        <>
            {systems && systems.length ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span
                        style={{
                            fontSize: '18px',
                            fontWeight: 600,
                            color: '#4164E3',
                            marginRight: '8px',
                        }}>
                        {systems.length}
                    </span>
                    <span style={{ color: '#2F426C' }}>Systems selected</span>
                </div>
            ) : (
                <span />
            )}
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FooterCancelButton onClick={() => onClose()}>Cancel</FooterCancelButton>
                    <FooterStyledButton onClick={handleSubmit} disabled={!systems?.length}>
                        {loading && (
                            <>
                                <CircularProgress size={14} color='inherit' />
                                &nbsp;
                            </>
                        )}
                        <span>Save Changes</span>
                    </FooterStyledButton>
                </div>
            </div>
        </>
    );
};

export default AssignSystemsFooter;
