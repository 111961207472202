import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '105vh',
        maxWidth: '105vh',
        borderRadius: 15,
    },
    paper: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center"
    },
    generalSystemDetailsContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        overflow: 'auto'
    },
    systemDetails: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    systemNames: {
        display: "flex",
        flexDirection: "column"
    },
    dialogTitleText: {
        fontFamily: 'Poppins',
        color: '#2F426C',
        fontSize: '18px',
        fontWeight: 600,
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mainTitleDivider: {
        marginTop: '16px'
    },
    genericSystemName: {
        fontFamily: 'ProximaNova',
        color: '#8D97AE',
        fontSize: '14px',
        fontWeight: 400,
    },
    customSystemName: {
        ontFamily: 'Poppins',
        color: '#2F426C',
        fontSize: '18px',
        fontWeight: 600,
    },
}));

export { useStyles };
