import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';

export interface SystemAppConfig {
    name: string;
    createdAt: string;
    appId: string;
    config: object;
    updatedAt: string;
}

export class SystemAppService {
    private readonly httpClient: WMhttpClient;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
    }

    async getSystemAppsConfigs(): Promise<SystemAppConfig[]> {
        const response = await this.httpClient.instance.get<SystemAppConfig[]>('/accounts-system-manager/systemAppsConfigs');
        return response?.data;
    }
}

export const systemAppService = new SystemAppService(wmhttpClientSharedInstance);
