import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface CommonSnackbarProps {
    open: boolean
    severity: string
    messageText: string
    onClose: (event, reason) => void
    autoHideDuration?: number
    anchorOrigin?: { vertical: "bottom"|"top", horizontal: "center" | "left" | "right" }
}

export const CommonSnackbar = ({open, onClose, messageText, severity, autoHideDuration = 3000, anchorOrigin = { vertical: "bottom", horizontal: "center"}} : CommonSnackbarProps) => {
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={anchorOrigin}>
            <Alert onClose={onClose} severity={severity}>
                {messageText}
            </Alert>
        </Snackbar>
    );
};
