import React, { useEffect, useState } from 'react';
import { RadioGroup, Box } from '@material-ui/core';
import { WMRadio } from '@walkme/wm-ui';
import {
    StyledContainer,
    StyledContent,
    StyledFooter,
    StyledFooterText,
    StyledHeader,
    StyledHeaderText,
    StyledRadioLabel,
    StyledRadioLabelContainer,
    StyledContentContainer,
} from './url-matching-accordion.styles';
import { Label, Typography } from '@walkme/ui-core';
import { FilledLightBulbIcon } from '../../../assets/icons/filled-light-bulb';
import { ChevronUpIcon } from '../../../assets/icons/chevron-up';
import { ChevronDownIcon } from '../../../assets/icons/chevron-down';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';

type AppMatch = {
    displayName: string;
    iconUrl: string;
    id: string;
    isCustom: boolean;
    showInAdminCenter: boolean;
    type: string;
};

interface URLMatchingAccordionProps {
    isOpen?: boolean;
    app: AppMatch;
}

export const UrlMatchingAccordion = ({ isOpen = false, app }: URLMatchingAccordionProps) => {
    const dispatch = useDispatch();
    const isCreateCustomSystem = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateCustomSystem.data
    );
    const [open, setOpen] = useState<boolean>(isOpen);
    const existingSystemId = 'existing-system';
    const footerText =
        'Existing applications are pre-configured with essential settings. You can create multiple systems for the same application type.';

    const onChange = (event) => {
        const radioButtonId = event.target.id;
        const isNew = radioButtonId !== existingSystemId;
        dispatch(createSystemSlice.actions.setIsCreateCustomSystem(isNew));
    };

    useEffect(() => {
        if (app?.displayName && app?.iconUrl) {
            setOpen(true);
        }
    }, [app]);

    return (
        <StyledContainer>
            <StyledHeader open={open}>
                <StyledHeaderText>
                    <Typography variant={'T20-2'}>Your URL matched the following application:</Typography>
                    <img src={app?.iconUrl} alt='' width={20} height={20} />
                    <Typography variant={'T20-2'}>{app?.displayName}</Typography>
                </StyledHeaderText>
                <div onClick={() => setOpen(!open)}>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
            </StyledHeader>
            <StyledContentContainer style={{ height: open ? 'auto' : '0' }} open={open}>
                <StyledContent>
                    <RadioGroup>
                        <Box mb={2}>
                            <WMRadio
                                ds2
                                checked={isCreateCustomSystem}
                                label={<StyledRadioLabel>Continue with creating a system for my own application</StyledRadioLabel>}
                                onChange={() => onChange(event)}
                            />
                        </Box>
                        <WMRadio
                            id={existingSystemId}
                            ds2
                            checked={!isCreateCustomSystem}
                            label={
                                <StyledRadioLabelContainer>
                                    <StyledRadioLabel>{`Create a system for ${app?.displayName} application`}</StyledRadioLabel>
                                    <Label label={'Recommended'} color={'blue'} />
                                </StyledRadioLabelContainer>
                            }
                            onChange={() => onChange(event)}
                        />
                    </RadioGroup>
                </StyledContent>
                <StyledFooter>
                    <FilledLightBulbIcon />
                    <StyledFooterText>
                        <Typography variant={'T30'}>{footerText}</Typography>
                    </StyledFooterText>
                </StyledFooter>
            </StyledContentContainer>
        </StyledContainer>
    );
};
