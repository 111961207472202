import React from 'react';
import { Base } from './base';
import AssignSystemsStep from '../data-integration-form-components/crawler-form/steps/assign-systems-step/assign-systems-step';
import { assignSystems } from '@walkme-admin-center/libs/state-mangment-data-integration';
import AssignSystemsFooter from '../data-integration-form-components/footers/assign-systems-footer';
import { CONSTS } from '../../../../../sso-configuration/src/lib/common/consts';
import { IAssignSystemsForm, IHeaderText } from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';

interface IHandleSubmit {
    values: IAssignSystemsForm;
    checkedIntegrations: Set<string>;
}

export class AssignSystems extends Base {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    async handleSubmit({ values, checkedIntegrations }: IHandleSubmit): Promise<void> {
        await this.assignSystems(checkedIntegrations, values);
    }

    async assignSystems(integrationsIds: Set<string>, values: IAssignSystemsForm): Promise<void> {
        try {
            await this.dispatch(assignSystems(values.systems, integrationsIds));
            this.closeForm();
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to assign systems`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    getFormHeader(): IHeaderText {
        return { header: 'Assign Systems', subHeader: 'Select systems to grant access to chosen knowledge sources' };
    }

    getOnePageForm(): React.ReactNode {
        return <AssignSystemsStep viewFlag={true} />;
    }

    getOnePageFooter() {
        return <AssignSystemsFooter onClose={this.closeForm} />;
    }

    async validate(values: IAssignSystemsForm, step: number) {
        return null;
    }

    initForm({}): IAssignSystemsForm {
        return {
            systems: [],
        };
    }
}
