import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
    AccountsSdk,
    System,
    Systems,
    SystemsSettings,
    UniqueIdentifier,
    WmFetchPolicy,
    SystemTypeKey,
    SystemStatusDetails,
    SubscriptionStatusType,
    SubscriptionStatusActionType,
    ActionTypeDetails,
    SystemCreatedWebhooksStatus,
} from 'wm-accounts-sdk';
import { exposureApi, SystemPackage, WebSystem } from '@walkme-admin-center/libs/exposure-api';
import { AppData, NotificationMessage } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import * as util from 'util';
import {
    analyticsApi,
    CreateSystemEnvironmentDto,
    UpdatedSystemEnvironment,
    UpdateSystemEnvironmentDto,
} from '../../../../analytics-api/src';
import { getUsers } from '@walkme-admin-center/libs/state-management-users';
import rootReducer from 'apps/home/src/redux/rootReducer';
import { SystemSelfHosted } from '@walkme-admin-center/self-hosting';
import { selfHostedApi } from '@walkme-admin-center/self-hosting';
import { WMAuthManager } from 'wm-accounts-auth';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { Provider, Providers, SsoConfigurationState } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { idpApi } from '@walkme-admin-center/libs/idp-api';
import { CONSTS } from '../../../../../pages/home/sso-configuration/src/lib/common/consts';
import { createSystemSlice } from './createSystem.slice';

export interface SystemsState {
    currentSystem: AppData<System>;
    updatedSystem: AppData<System>;
    updatedSystemName: AppData<System>;
    createdSystem: AppData<System>;
    updateSystemsStatus: AppData<SystemStatusDetails[]>;
    systems: AppData<Systems>;
    pendingForDeleteSystems: AppData<Systems>;
    systemsCreationStatus: Record<string, SystemCreatedWebhooksStatus>;
    systemsSettings: AppData<SystemsSettings>;
    updatedEnvironment: AppData<UpdatedSystemEnvironment>;
    systemsSelfHosted: AppData<SystemSelfHosted[]>;
    saveAndPublishDialogIsOpen: boolean;
    notificationMessage: NotificationMessage;
    providers: AppData<Providers>;
}

const initalSystemState: System = {
    displayName: '',
};

export const initalUpdatedSystemState: System = {
    id: null,
    guid: null,
    type: null,
    displayName: null,
};

export const initialUpdatedEnvironmentState: UpdatedSystemEnvironment = {
    name: '',
    action: undefined,
};

export const initialSystemsState: SystemsState = {
    systemsSelfHosted: {
        error: null,
        loading: false,
        data: [],
    },
    currentSystem: {
        error: null,
        loading: false,
        data: initalSystemState,
    },
    updatedSystem: {
        error: null,
        loading: false,
        data: initalUpdatedSystemState,
    },
    updatedSystemName: {
        error: null,
        loading: false,
        data: null,
    },
    createdSystem: {
        error: null,
        loading: false,
        data: initalUpdatedSystemState,
    },
    updateSystemsStatus: {
        error: null,
        loading: false,
        data: [],
    },
    systems: {
        data: [],
        error: null,
        loading: false,
    },
    pendingForDeleteSystems: {
        data: [],
        error: null,
        loading: false,
    },
    systemsSettings: {
        data: [],
        error: null,
        loading: false,
    },
    updatedEnvironment: {
        data: initialUpdatedEnvironmentState,
        error: null,
        loading: false,
    },
    systemsCreationStatus: {},
    saveAndPublishDialogIsOpen: false,
    notificationMessage: {
        text: '',
        variant: WMSnackbarVariant.Success,
        isOpen: false,
    },
    providers: {
        data: null,
        error: null,
        loading: true,
    },
};

const systemsSlice = createSlice({
    name: 'systemsSlice',
    initialState: initialSystemsState,
    reducers: {
        cleanupUpdatedSystemName: (state: SystemsState) => {
            state.updatedSystemName.data = null;
            state.updatedSystemName.error = null;
            state.updatedSystemName.loading = false;
            return state;
        },
        updateSystemNameStart: (state: SystemsState) => {
            state.updatedSystemName.error = null;
            state.updatedSystemName.loading = true;
            return state;
        },
        updateSystemNameSuccess(state: SystemsState, action: PayloadAction<System>) {
            state.updatedSystemName.data = action.payload;
            state.updatedSystemName.loading = false;
            state.updatedSystemName.error = null;
            return state;
        },
        updateSystemNameFailed(state, action: PayloadAction<string>) {
            state.updatedSystemName.error = action.payload;
            state.updatedSystemName.loading = false;
            return state;
        },
        cleanupUpdatedSystem: (state: SystemsState) => {
            state.updatedSystem.data = initalUpdatedSystemState;
            state.updatedSystem.error = null;
            state.updatedSystem.loading = false;
            return state;
        },
        updateSystemStart: (state: SystemsState) => {
            state.updatedSystem.error = null;
            state.updatedSystem.loading = true;
            return state;
        },
        updateSystemSuccess(state: SystemsState, action: PayloadAction<System>) {
            state.updatedSystem.data = action.payload;
            state.updatedSystem.loading = false;
            state.updatedSystem.error = null;
            return state;
        },
        updateSystemFailed(state, action: PayloadAction<string>) {
            state.updatedSystem.error = action.payload;
            state.updatedSystem.loading = false;
            return state;
        },
        cleanupCreatedSystem: (state: SystemsState) => {
            state.createdSystem.data = initalUpdatedSystemState;
            state.createdSystem.error = null;
            state.createdSystem.loading = false;
            return state;
        },
        createSystemStart: (state: SystemsState) => {
            state.createdSystem.error = null;
            state.createdSystem.loading = true;
            return state;
        },
        createSystemSuccess(state: SystemsState, action: PayloadAction<System>) {
            state.createdSystem.data = action.payload;
            state.createdSystem.loading = false;
            state.createdSystem.error = null;
            return state;
        },
        createSystemFailed(state, action: PayloadAction<string>) {
            state.createdSystem.error = action.payload;
            state.createdSystem.loading = false;
            return state;
        },
        updateSystemsStatusStart: (state: SystemsState) => {
            state.updateSystemsStatus.error = null;
            state.updateSystemsStatus.loading = true;
            state.updateSystemsStatus.data = [];
            return state;
        },
        updateSystemsStatusSuccess(state: SystemsState, action: PayloadAction<SystemStatusDetails[]>) {
            state.updateSystemsStatus.data = action.payload;
            state.updateSystemsStatus.loading = false;
            state.updateSystemsStatus.error = null;
            return state;
        },
        updateSystemsStatusFailed(state, action: PayloadAction<string>) {
            state.updateSystemsStatus.error = action.payload;
            state.updateSystemsStatus.loading = false;
            return state;
        },
        cleanupUpdateSystemsStatusStart: (state: SystemsState) => {
            state.updateSystemsStatus.data = [];
            state.updateSystemsStatus.error = null;
            state.updateSystemsStatus.loading = false;
            return state;
        },
        changeSystemTypeStart(state) {
            state.updatedSystem.error = null;
            state.updatedSystem.loading = true;
            return state;
        },
        changeSystemTypeSuccess(state: SystemsState, action: PayloadAction<System>) {
            state.updatedSystem.data = action.payload;
            state.updatedSystem.loading = false;
            state.updatedSystem.error = null;
            return state;
        },
        changeSystemTypeFailed(state, action: PayloadAction<string>) {
            state.updatedSystem.error = action.payload;
            state.updatedSystem.loading = false;
            return state;
        },
        systemsCreationStatusSuccess(state: SystemsState, action: PayloadAction<Record<string, SystemCreatedWebhooksStatus>>) {
            state.systemsCreationStatus = action.payload;
            return state;
        },
        getCurrentSystemStart(state: SystemsState) {
            state.currentSystem.loading = true;
            state.currentSystem.error = null;
            return state;
        },
        getCurrentSystemSuccess(state: SystemsState, action: PayloadAction<System>) {
            state.currentSystem.data = action.payload;
            state.currentSystem.loading = false;
            state.currentSystem.error = null;
            return state;
        },
        getCurrentSystemFailed(state, action: PayloadAction<string>) {
            state.currentSystem.error = action.payload;
            state.currentSystem.loading = false;
            return state;
        },
        cleanupCurrentSystem: (state: SystemsState) => {
            state.currentSystem.data = initalSystemState;
            state.currentSystem.error = null;
            state.currentSystem.loading = false;
            return state;
        },
        getSystemsStart(state: SystemsState) {
            state.systems.error = null;
            state.systems.loading = true;
            return state;
        },
        getSystemsSuccess(state: SystemsState, action: PayloadAction<Systems>) {
            state.systems.data = action.payload;
            state.systems.loading = false;
            state.systems.error = null;
            return state;
        },
        getSystemsFailed(state, action: PayloadAction<string>) {
            state.systems.loading = false;
            state.systems.error = action.payload;
            return state;
        },
        getPendingForDeleteSystemsStart(state: SystemsState) {
            state.pendingForDeleteSystems.error = null;
            state.pendingForDeleteSystems.loading = true;
            return state;
        },
        getPendingForDeleteSystemsSuccess(state: SystemsState, action: PayloadAction<Systems>) {
            state.pendingForDeleteSystems.data = action.payload;
            state.pendingForDeleteSystems.loading = false;
            state.pendingForDeleteSystems.error = null;
            return state;
        },
        getPendingForDeleteSystemsFailed(state, action: PayloadAction<string>) {
            state.pendingForDeleteSystems.loading = false;
            state.pendingForDeleteSystems.error = action.payload;
        },
        getSystemsSelfHostedStart(state: SystemsState) {
            state.systemsSelfHosted.error = null;
            state.systemsSelfHosted.loading = true;
            return state;
        },
        getSystemsSelfHostedSuccess(state: SystemsState, action: PayloadAction<any>) {
            state.systemsSelfHosted.data = action.payload;
            state.systemsSelfHosted.loading = false;
            state.systemsSelfHosted.error = null;
            return state;
        },
        getSystemsSelfHostedFailed(state, action: PayloadAction<string>) {
            state.systemsSelfHosted.loading = false;
            state.systemsSelfHosted.error = action.payload;
            return state;
        },
        getSystemsSettingsStart(state: SystemsState) {
            state.systemsSettings.error = null;
            state.systemsSettings.loading = true;
            return state;
        },
        getSystemsSettingsSuccess(state: SystemsState, action: PayloadAction<SystemsSettings>) {
            state.systemsSettings.data = action.payload;
            state.systemsSettings.loading = false;
            state.systemsSettings.error = null;
            return state;
        },
        getSystemsSettingsFailed(state, action: PayloadAction<string>) {
            state.systemsSettings.loading = false;
            state.systemsSettings.error = action.payload;
            return state;
        },
        createNewEnvStart(state: SystemsState) {
            state.updatedSystem.loading = true;
            state.updatedSystem.error = null;
            return state;
        },
        createNewEnvFailed(state, action: PayloadAction<string>) {
            state.updatedSystem.loading = false;
            state.updatedSystem.error = action.payload;
            return state;
        },
        createNewEnvSuccess(state, action: PayloadAction<UpdatedSystemEnvironment>) {
            state.updatedEnvironment.data = action.payload;
            state.updatedSystem.error = null;
            state.updatedSystem.loading = false;
            return state;
        },
        renameEnvStart(state: SystemsState) {
            state.updatedSystem.loading = true;
            state.updatedSystem.error = null;
            return state;
        },
        renameEnvFailed(state, action: PayloadAction<string>) {
            state.updatedSystem.loading = false;
            state.updatedSystem.error = action.payload;
            return state;
        },
        renameEnvSuccess(state, action: PayloadAction<UpdatedSystemEnvironment>) {
            state.updatedEnvironment.data = action.payload;
            state.updatedSystem.error = null;
            state.updatedSystem.loading = false;
            return state;
        },
        cleanupUpdatedEnvironment: (state: SystemsState) => {
            state.updatedEnvironment.data = initialUpdatedEnvironmentState;
            state.updatedSystem.error = null;
            state.updatedSystem.loading = false;
            return state;
        },
        setSaveAndPublishDialogIsOpen(state: SystemsState, action: PayloadAction<boolean>) {
            state.saveAndPublishDialogIsOpen = action.payload;
            return state;
        },
        setNotificationMessage(state: SystemsState, action: PayloadAction<NotificationMessage>) {
            state.notificationMessage = action.payload;
            return state;
        },
        cleanUpNotificationMessage(state: SystemsState) {
            state.notificationMessage.isOpen = false;
            return state;
        },
        setProviders(state: SystemsState, action: PayloadAction<Providers>) {
            state.providers.data = action.payload;
            return state;
        },
        startGetProviders(state: SystemsState) {
            state.providers.loading = true;
            return state;
        },
        getProvidersSuccess(state: SystemsState, action: PayloadAction<Providers>) {
            state.providers.loading = false;
            state.providers.data = action.payload;
            return state;
        },
    },
});

export { systemsSlice };
const {
    updateSystemSuccess,
    updateSystemFailed,
    updateSystemStart,
    cleanupUpdatedSystem,
    updateSystemNameSuccess,
    updateSystemNameFailed,
    updateSystemNameStart,
    cleanupUpdatedSystemName,
    createSystemSuccess,
    createSystemFailed,
    createSystemStart,
    updateSystemsStatusStart,
    updateSystemsStatusFailed,
    updateSystemsStatusSuccess,
    cleanupCreatedSystem,
    getCurrentSystemStart,
    getCurrentSystemSuccess,
    getCurrentSystemFailed,
    changeSystemTypeStart,
    changeSystemTypeSuccess,
    changeSystemTypeFailed,
    getSystemsFailed,
    getSystemsStart,
    getSystemsSuccess,
    getPendingForDeleteSystemsStart,
    getPendingForDeleteSystemsSuccess,
    getPendingForDeleteSystemsFailed,
    getSystemsSelfHostedStart,
    getSystemsSelfHostedSuccess,
    getSystemsSelfHostedFailed,
    getSystemsSettingsFailed,
    getSystemsSettingsStart,
    getSystemsSettingsSuccess,
    createNewEnvStart,
    createNewEnvSuccess,
    createNewEnvFailed,
    renameEnvFailed,
    renameEnvStart,
    renameEnvSuccess,
    systemsCreationStatusSuccess,
    startGetProviders,
    getProvidersSuccess,
    setProviders,
} = systemsSlice.actions;
type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const createSystem = async (
    systemType: string,
    name: string,
    systemTypeKey?: string,
    unassignUser?: boolean,
    associatedSystem?: number
): Promise<System> => {
    const updatedSystem: System = await AccountsSdk.getInstance().createSystem(
        systemType,
        name,
        systemTypeKey,
        unassignUser,
        associatedSystem
    );
    return updatedSystem;
};

export const getCreatedSystemsStatuses = (): AppThunk => async (dispatch, getState) => {
    try {
        const newSystemsCreationStatus = await AccountsSdk.getInstance().systemsManagement.getSystemsCreatedStatuses();
        dispatch(systemsCreationStatusSuccess(newSystemsCreationStatus));
    } catch (err) {
        return;
    }
};

export const ShouldShowAssociatedSystems = false; // This is legacy for a deprecated feature

function exponentialDelay(factor = 0) {
    const delay = Math.pow(2, factor) * 100;
    const randomSum = delay * 0.2 * Math.random();
    return delay + randomSum;
}

export const updateAndPollingCreatedSystemStatus =
    (systemId: number, systemGuid: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            let systemStatus = false;
            let retry = 1;
            while (!systemStatus) {
                const newSystemsCreationStatus = { ...getState().systemsState.systemsCreationStatus };
                const status = await AccountsSdk.getInstance().systemsManagement.getSystemCreatedStatus(systemId);
                newSystemsCreationStatus[systemGuid] = status;
                dispatch(systemsCreationStatusSuccess(newSystemsCreationStatus));
                systemStatus =
                    status.processed && status.pendingEventWebhookStatus.length === 0 && status.failedEventWebhookStatus.length === 0;
                const delay = exponentialDelay(retry);
                retry = retry + 1;
                !systemStatus && (await new Promise((resolve) => setTimeout(resolve, delay)));
            }
        } catch (err) {
            return;
        }
    };

export const saveSystem =
    (systemType: string, name: string, systemTypeKey?: string, unassignUser?: boolean, associatedSystem?: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(createSystemStart());
            const updatedSystem: System = await createSystem(systemType, name, systemTypeKey, unassignUser, associatedSystem);
            dispatch(createSystemSuccess(updatedSystem));
            dispatch(updateAndPollingCreatedSystemStatus(updatedSystem.id, updatedSystem.guid));
            dispatch(getSystems(true));
            dispatch(getUsers(true));
        } catch (err) {
            dispatch(createSystemFailed(err.message));
            return;
        }
    };

export const updateSystemsStatus =
    (
        systemsData: { systemId: number; systemGuid: string }[],
        statusType: SubscriptionStatusType,
        canBeRestored: boolean,
        actionType: SubscriptionStatusActionType,
        actionTypeDetails: ActionTypeDetails
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateSystemsStatusStart());
            const updatedSystem: SystemStatusDetails[] = await AccountsSdk.getInstance().systemsManagement.updateSystemsStatuses(
                systemsData,
                statusType,
                canBeRestored,
                actionType,
                actionTypeDetails
            );
            dispatch(updateSystemsStatusSuccess(updatedSystem));
            dispatch(getSystems(true));
        } catch (err) {
            dispatch(updateSystemsStatusFailed(err.message));
            return;
        }
    };

export const updateExistingSystem =
    (system: System, updatedPackages: SystemPackage[], uniqueIdentifier: UniqueIdentifier, updatedDisplayName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateSystemStart());
            const updatedSystem: System = await AccountsSdk.getInstance().updateSystem(system, updatedDisplayName);
            dispatch(updateSystemSuccess(updatedSystem));
            dispatch(getSystems());
        } catch (err) {
            dispatch(updateSystemFailed(err.message));
            return;
        }
    };

export const updateExistingSystemName =
    (system: System, updatedDisplayName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateSystemNameStart());
            const updatedSystem: System = await AccountsSdk.getInstance().updateSystem(system, updatedDisplayName);
            dispatch(updateSystemNameSuccess(updatedSystem));
            dispatch(getSystem(system.id));
            dispatch(getSystems());
        } catch (err) {
            dispatch(updateSystemNameFailed(err.message));
            return;
        }
    };

export const changeSystemType =
    (typeId: unknown, systemId: number, regexp: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(changeSystemTypeStart());
            const updatedSystem: System = await AccountsSdk.getInstance().updateSystemType(typeId, systemId);
            dispatch(changeSystemTypeSuccess(updatedSystem));
            dispatch(getSystem(systemId));
            dispatch(getSystems());
            dispatch(createSystemSlice.actions.resetCreateSystemState());
        } catch (err) {
            dispatch(changeSystemTypeFailed(`Failed to change system type. You may have that system type already`));
            return;
        }
    };

export const createNewEnvironment =
    (env: CreateSystemEnvironmentDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(createNewEnvStart());
            await analyticsApi.createEnvironment(env);
            dispatch(
                createNewEnvSuccess({
                    name: env.envName,
                    action: 'created',
                })
            );
            dispatch(getSystem(env.systemId, true));
        } catch (err) {
            dispatch(
                createNewEnvFailed(`Failed to create environment ${env.envName} for system ${env.userId}. Error: ${util.inspect(err)}`)
            );
            return;
        }
    };

export const renameEnvironment =
    (env: UpdateSystemEnvironmentDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(renameEnvStart());
            await analyticsApi.updateEnvironment(env);
            dispatch(
                renameEnvSuccess({
                    name: env.envName,
                    action: 'renamed',
                })
            );
            dispatch(getSystem(env.systemId, true));
        } catch (err) {
            dispatch(
                renameEnvFailed(
                    `Failed to rename environment ${env.envId} for system ${env.userId} to ${env.envName}. Error: ${util.inspect(err)}`
                )
            );
            return;
        }
    };

export const getSystem =
    (id: number, forceLoad?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(getCurrentSystemStart());
            const system: System = await AccountsSdk.getInstance().getAccountSystem(id);
            if (!system) {
                throw new Error('System Not found');
            }
            dispatch(getCurrentSystemSuccess(system));
        } catch (err) {
            dispatch(getCurrentSystemFailed(err.message));
            return;
        }
    };

export const getSystems =
    (forceLoad = false, filterByTypes?: SystemTypeKey[]): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(getSystemsStart());
            dispatch(getPendingForDeleteSystemsStart());
            const systems: Systems = await AccountsSdk.getInstance().getAccountSystems(
                forceLoad ? WmFetchPolicy.network : WmFetchPolicy.cache,
                filterByTypes,
                ShouldShowAssociatedSystems,
                false,
                false
            );
            const enabledSystems = systems.filter(
                (system) => !system.statusDetails || system.statusDetails.status === SubscriptionStatusType.enabled
            );
            const pendingForDeleteSystems = systems.filter(
                (system) => system.statusDetails && system.statusDetails.status === SubscriptionStatusType.pendingForDelete
            );
            dispatch(getSystemsSuccess(enabledSystems));
            dispatch(getPendingForDeleteSystemsSuccess(pendingForDeleteSystems));
        } catch (err) {
            dispatch(getSystemsFailed(err.message));
            dispatch(getPendingForDeleteSystemsFailed(err.message));
            return;
        }
    };

export const getSystemsSelfHosted = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getSystemsSelfHostedStart());

        const areSelfHosted: SystemSelfHosted[] = await selfHostedApi.getAreSystemSelfHosted();

        dispatch(getSystemsSelfHostedSuccess(areSelfHosted));
    } catch (err) {
        dispatch(getSystemsSelfHostedFailed(err.message));
        return;
    }
};

export const getSystemsSettings =
    (forceLoad = false): AppThunk =>
    async (dispatch, getState) => {
        try {
            const alreadyLoadedSystemsSettings = getState().systemsState.systemsSettings;
            if (alreadyLoadedSystemsSettings.data.length > 0 && !forceLoad) {
                return;
            }
            dispatch(getSystemsSettingsStart());
            const systemsSettings: SystemsSettings = await AccountsSdk.getInstance().getSystemsSettings();
            dispatch(getSystemsSettingsSuccess(systemsSettings));
        } catch (err) {
            dispatch(getSystemsSettingsFailed(err.message));
            return;
        }
    };

export const switchSystem =
    (systemId: number): AppThunk =>
    async (dispatch) => {
        try {
            await AccountsSdk.getInstance().setLastSystemId(systemId);
            await WMAuthManager.getInstance().forceRefreshToken(true);

            const userToken = WMAuthManager.getInstance().getUserToken();
        } catch (err) {
            dispatch(getSystemsSettingsFailed(err.message));
            return;
        }
    };

function prepareProvider(provider: Provider): Provider {
    if (provider?.config?.scope) {
        provider.config.scope = provider.config.scope.split(' ').filter(Boolean).join(',');
    }
    if (provider?.config?.type === CONSTS.SAML) {
        provider.config.clientSecret = provider.config.clientSecret?.replace(/\n/gm, ' ');
    }
    return provider;
}

export const getProviders =
    (isImpersonator: boolean): AppThunk =>
    async (dispatch, getState) => {
        if (getState().systemsState.providers.data) {
            return;
        }

        dispatch(startGetProviders());
        const list: Providers = [];

        try {
            const providers: Providers = await idpApi.getProviders(isImpersonator);
            if (providers && providers.length) {
                providers.forEach((provider) => {
                    list.push(prepareProvider(provider));
                });
            }
            dispatch(getProvidersSuccess(list));
        } catch (e) {
            console.error(e);
            dispatch(setProviders(null));
        }
    };
