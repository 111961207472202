import React, { ReactNode } from 'react';
import { StyledDataCollectionOptionTitle } from './data-collection-option.styles';

export enum DataCollectionLevel {
    ENGAGEMENT = 'engagement',
    DIGITAL = 'digital',
    EXPERIENCE_ANALYTICS = 'experience_analytics',
}

type DataCollectionOption = {
    id: string;
    mainTitle: ReactNode;
    bullets: string[];
};
export const getDataCollectionOptions = (translator): DataCollectionOption[] => [
    {
        id: DataCollectionLevel.ENGAGEMENT,
        mainTitle: (
            <StyledDataCollectionOptionTitle>
                <span>
                    {translator(`systems-tab.new-system-form.configure-step.data-collection-level.walkme-engagement-analytics.title`)}
                </span>{' '}
                <a
                    className='learn-more'
                    href={'https://support.walkme.com/knowledge-base/insights-enabling-collection-of-input-fields/'}
                    target='_blank'>
                    {translator(`buttons.learn-more`)}
                </a>
            </StyledDataCollectionOptionTitle>
        ),
        bullets: [
            translator(`systems-tab.new-system-form.configure-step.data-collection-level.walkme-engagement-analytics.on-all-events`),
            translator(
                `systems-tab.new-system-form.configure-step.data-collection-level.walkme-engagement-analytics.censorship-and-privacy`
            ),
        ],
    },
    {
        id: DataCollectionLevel.DIGITAL,
        mainTitle: (
            <StyledDataCollectionOptionTitle>
                <span>
                    {translator(`systems-tab.new-system-form.configure-step.data-collection-level.digital-experience-analytics.title`)}{' '}
                    <a className='learn-more' href={'https://support.walkme.com/knowledge-base/insights-dxa/'} target='_blank'>
                        {translator(`buttons.learn-more`)}
                    </a>
                </span>
            </StyledDataCollectionOptionTitle>
        ),
        bullets: [
            translator(
                `systems-tab.new-system-form.configure-step.data-collection-level.digital-experience-analytics.engagement-analytics`
            ),
            translator(
                `systems-tab.new-system-form.configure-step.data-collection-level.digital-experience-analytics.defined-website-analytics`
            ),
        ],
    },
    {
        id: DataCollectionLevel.EXPERIENCE_ANALYTICS,
        mainTitle: (
            <StyledDataCollectionOptionTitle>
                <span>
                    {translator(`systems-tab.new-system-form.configure-step.data-collection-level.experience-analytics.title`)}{' '}
                    <a
                        className='learn-more'
                        href={'https://support.walkme.com/knowledge-base/insights-level-3-session-playback-tracking/'}
                        target='_blank'>
                        {translator(`buttons.learn-more`)}
                    </a>
                </span>
            </StyledDataCollectionOptionTitle>
        ),
        bullets: [
            translator(`systems-tab.new-system-form.configure-step.data-collection-level.experience-analytics.engagement-analytics`),
            translator(`systems-tab.new-system-form.configure-step.data-collection-level.experience-analytics.full-website-analytics`),
            translator(`systems-tab.new-system-form.configure-step.data-collection-level.experience-analytics.video-like`),
        ],
    },
];

export enum DatCollectionFeature {
    INSIGHTS_USER_BEHAVIOR = 'Insights User Behavior',
    USER_BEHAVIOR_TRACKING = 'User Behavior Tracking',
    VISIONS = 'Visions',
    SESSION_PLAYBACK = 'Session Playback',
}

export const generateUserFeaturesToUpdateDataCollection = ({
    selectedDataCollectionLevel,
}: {
    selectedDataCollectionLevel: DataCollectionLevel;
}) => {
    const dataCollectionFeatures = {
        insightsUserBehavior: {
            featureName: DatCollectionFeature.INSIGHTS_USER_BEHAVIOR,
            isActivated: false,
        },
        userBehaviorTracking: {
            featureName: DatCollectionFeature.USER_BEHAVIOR_TRACKING,
            isActivated: false,
        },
        visions: {
            featureName: DatCollectionFeature.VISIONS,
            isActivated: false,
        },
        sessionPlayback: {
            featureName: DatCollectionFeature.SESSION_PLAYBACK,
            isActivated: false,
        },
    };

    switch (selectedDataCollectionLevel) {
        case DataCollectionLevel.DIGITAL:
            dataCollectionFeatures.insightsUserBehavior.isActivated = true;
            dataCollectionFeatures.userBehaviorTracking.isActivated = true;
            break;
        case DataCollectionLevel.EXPERIENCE_ANALYTICS:
            dataCollectionFeatures.userBehaviorTracking.isActivated = true;
            dataCollectionFeatures.visions.isActivated = true;
            dataCollectionFeatures.sessionPlayback.isActivated = true;
            break;
    }

    return Object.values(dataCollectionFeatures);
};
