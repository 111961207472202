export interface User {
  id: number,
  createdAt: string,
  email: string,
  deletedAt: string,
  migrated: boolean,
  multiProvider: boolean,
  role: string,
  status: string,
  updatedAt: string,
  userGuid: string,
  walkmeUserId: number
}
