import React, { useState } from 'react';
import { StyledTextField } from '../../../../common/style';
import { Close, Done, Restore } from '@material-ui/icons';
import CustomToolTip from '../../../../common/components/tool-tip/tool-tip';
import isString from 'lodash/isString';
import get from 'lodash/get';
import { Field, Fields, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { StyledFieldPropertiesContainer, StyledIconButton } from './fields-property-alias-style';

export interface PropertyAliasProps {
    closeEditMode: () => void;
    fields: any;
    field: Field;
    values: ProviderFormValues;
    handleAliasChange: (name: string) => void;
    getProperty: (field: Field) => number;
}

export const PropertyAlias = ({ closeEditMode, fields, field, values, handleAliasChange, getProperty }: PropertyAliasProps) => {
    const [alias, setAlias] = useState(field.alias ? field.alias : field.name);
    const [fieldError, setFieldError] = useState({
        show: false,
        message: null,
    });

    const fieldNameValue = field.alias ? field.alias : field.name;
    const handleChange = (event): void => {
        setAlias(event.target.value);
        validateField(event.target.value);
    };

    const handleRename = (): void => {
        if (!checkUnique(alias)) {
            setFieldError({ show: true, message: 'Property name should be unique' });
            return;
        }
        handleAliasChange(alias);
        fields.update(getProperty(field), { ...field, alias });
        closeEditMode();
    };

    const restoreName = (): void => {
        if (!checkUnique(field.name)) {
            setFieldError({ show: true, message: 'Property name should be unique' });
            return;
        }
        handleAliasChange(null);
        fields.update(getProperty(field), { ...field, alias: null });
        closeEditMode();
    };

    const handleEnter = (): void => {
        if (alias && !fieldError.show) {
            handleRename();
        }
    };

    const validateField = (value: string): null | string => {
        let errorMessage = null;

        const regExRules = [
            {
                re: /^.{256,}$/,
                message: 'Alias max length is 255',
            },
        ];

        for (const rule of regExRules) {
            if (rule.re.test(value)) {
                errorMessage = rule.message;
                break;
            }
        }

        if (errorMessage) {
            setFieldError({ show: true, message: errorMessage });
            return;
        }

        const newName = isString(value) ? value.trim() : value;

        if (newName === fieldNameValue) {
            return;
        }

        if (isString(newName) && !newName.length) {
            setFieldError({ show: true, message: 'Alias cannot be empty' });
            return;
        }
        const isUnique = checkUnique(value);
        if (!isUnique) {
            setFieldError({ show: true, message: 'Property name should be unique' });
            return;
        }
        setFieldError({ show: false, message: '' });
        return;
    };

    const checkUnique = (value: string): boolean => {
        const checkedProperties: Fields = get(values, `fields`);
        const withOutAlias = checkedProperties.filter((field) => !field.alias);
        const withAlias = checkedProperties.filter((field) => field.alias);
        if (withOutAlias.some((item) => item.name === value)) {
            return false;
        }
        return !withAlias.some((item) => item.alias === value);
    };

    return (
        <StyledFieldPropertiesContainer className={`alignItemsCenter`}>
            <StyledTextField
                className={'alias-text'}
                error={fieldError.show}
                helperText={fieldError.message}
                autoFocus
                name='alias'
                variant='outlined'
                value={alias}
                onChange={handleChange}
                inputProps={{ style: { height: '6px' } }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        handleEnter();
                    } else if (e.key === 'Escape') {
                        closeEditMode();
                    }
                }}
            />
            <CustomToolTip title={'Approve'}>
                <span>
                    <StyledIconButton
                        disabled={!alias || fieldError.show || alias === field.alias || alias === fieldNameValue}
                        onClick={() => handleRename()}>
                        <Done fontSize={'medium'} />
                    </StyledIconButton>
                </span>
            </CustomToolTip>
            <CustomToolTip title={'Cancel'}>
                <span>
                    <StyledIconButton onClick={() => closeEditMode()}>
                        <Close fontSize={'medium'} />
                    </StyledIconButton>
                </span>
            </CustomToolTip>
            {field.alias && (
                <CustomToolTip title={`Restore to: '${field.name}'`}>
                    <span>
                        <StyledIconButton
                            onClick={() => {
                                restoreName();
                            }}>
                            <Restore fontSize={'medium'} />
                        </StyledIconButton>
                    </span>
                </CustomToolTip>
            )}
        </StyledFieldPropertiesContainer>
    );
};

export default PropertyAlias;
