import React, { useCallback, useState } from 'react';
import { IdpEnv, IdpSystemFormValue } from '@walkme-admin-center/libs/state-management-sso-configuration';
import CustomCheckbox from '../../../../common/components/custom-checkbox/custom-checkbox';
import { shrinkText } from '../../../../common/helpers';
import CustomToolTip from '../../../../common/components/tool-tip/tool-tip';
import { CustomSwitch } from '../../../../common/style';
import { FormApi } from 'final-form';
import get from 'lodash/get';
import {
    EnforceSection,
    EnvName,
    EnvItemContainer,
    StyledEnforceSSO,
    StyledSystemEnvContainer,
    StyledEnforceSSOTitle,
} from './env-item-style';
import IdpDialog from '../../../../common/components/idp-dialog/idp-dialog';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { Environment } from 'wm-accounts-sdk';
import { SystemTypeKey } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/systems-types';

export interface EnvItemProps {
    isChecked: (system: IdpSystemFormValue, envId: number) => boolean | null;
    getSystemIndex: (system: IdpSystemFormValue, envId: number) => number;
    system: IdpSystemFormValue;
    systemEnv: Environment;
    assignTo: IdpEnv;
    fields: any;
    index: number;
    formApi: FormApi;
    providerId: number;
}

export const EnvItem = ({ isChecked, assignTo, getSystemIndex, system, systemEnv, fields, index, formApi, providerId }: EnvItemProps) => {
    const [confirmData, setConfirmData] = useState({
        showConfirm: false,
        content: null,
        confirm: null,
        title: '',
    });

    const isToggled = (): boolean | null => {
        const checkedSystems = get(formApi.getState().values, `systems`);
        if (checkedSystems) {
            const item = checkedSystems.find((s) => s.guid === system.guid && s.wmEnv === systemEnv.id);
            if (item) {
                return item.enforceSso;
            }
        }
        return false;
    };

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={confirmData.showConfirm}
                handleClose={() => setConfirmData({ ...confirmData, showConfirm: false })}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={'defaultButton'}
                buttonText={'I understand, continue'}
                cancelButtonText={'Cancel'}
            />
        );
    }, [confirmData]);

    const setEnforceSso = (checked: boolean): void => {
        const checkedSystemIndex = getSystemIndex(system, systemEnv.id);
        if (checkedSystemIndex >= 0) {
            fields.update(checkedSystemIndex, { ...system, wmEnv: systemEnv.id, enforceSso: checked, providerId });
        } else {
            if (checked) {
                fields.insert(index, { ...system, providerId, wmEnv: systemEnv.id, enforceSso: true });
            }
        }
    };

    const handleEnforceClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        const checked: boolean = event.target['checked'];
        if (checked) {
            setConfirmData({
                showConfirm: true,
                title: 'Enable Enforce SSO?',
                content: (
                    <StyledEnforceSSO>
                        <WarningOutlinedIcon style={{ color: '#F7B500' }} />
                        <span style={{ paddingLeft: '8px' }}>
                            With Enforce SSO enabled, your users will be redirected to this IDP provider if they are not authenticated.
                            <br />
                            Note this will affect ALL end users.
                            <br />
                            This change will be applied once you click "Finish".
                        </span>
                    </StyledEnforceSSO>
                ),
                confirm: () => {
                    setConfirmData({ ...confirmData, showConfirm: false });
                    setEnforceSso(checked);
                },
            });
        } else {
            setEnforceSso(checked);
        }
    };

    return (
        <>
            {confirmData.showConfirm && triggerConfirm()}
            <EnvItemContainer className={`${isChecked(system, systemEnv.id) ? 'env-checked' : ''}`}>
                <EnvName className={`alignItemsCenter`}>
                    <CustomToolTip disableHoverListener={!assignTo} title={assignTo ? `Assigned to ${assignTo.providerName} provider` : ''}>
                        <span>
                            <CustomCheckbox
                                disabled={!!assignTo}
                                style={{ padding: '0px' }}
                                size={'medium'}
                                checked={isChecked(system, systemEnv.id)}
                                color={'primary'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    const checked = event.target.checked;
                                    if (checked) {
                                        fields.insert(index, {
                                            ...system,
                                            providerId,
                                            wmEnv: systemEnv.id,
                                            enforceSso: system.typeKey === SystemTypeKey.Workstation,
                                        });
                                    } else {
                                        fields.remove(getSystemIndex(system, systemEnv.id));
                                    }
                                }}
                            />
                        </span>
                    </CustomToolTip>
                    <StyledSystemEnvContainer className={`alignItemsCenter name-container`}>
                        <div className={`inner-name-container alignItemsCenter`}>
                            <span className={'name'}>{shrinkText(systemEnv.name, 19)}</span>
                        </div>
                    </StyledSystemEnvContainer>
                </EnvName>
                <EnforceSection className={'enforce-section'}>
                    <span className={'title'}>Enforce SSO</span>
                    <CustomToolTip
                        title={
                            <StyledEnforceSSOTitle className={`alignItemsCenter`}>
                                <span>Users will be prompted to login</span>
                                <span>with IDP in case they login with</span>
                                <span>other credentials.</span>
                            </StyledEnforceSSOTitle>
                        }>
                        <span style={{ padding: '0 12px' }}>?</span>
                    </CustomToolTip>
                    <CustomSwitch
                        size='small'
                        disabled={!!assignTo || system.typeKey === SystemTypeKey.Workstation}
                        checked={isToggled()}
                        color='primary'
                        onClick={(event) => {
                            handleEnforceClick(event);
                        }}
                    />
                </EnforceSection>
            </EnvItemContainer>
        </>
    );
};

export default EnvItem;
