import React from 'react';
import styled, { css } from 'styled-components';
import { CloseIcon, ExclamationIcon } from '../../data-integrations-list/integration-card/icons/icons';
import { Button } from '@walkme/ui-core';

const InfoBoxContainer = styled.div<{ border?: boolean }>`
    display: flex;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-radius: 0.25rem;
    ${(p) =>
        p.border &&
        css`
            border: 1px solid var(--Primaries-Blue-3, #afbff7);
        `}
    background: var(--Primaries-Blue-7, #edf1fd);
`;

const InfoText = styled.p`
    flex: 1 0 0;
    color: var(--Color-Local-Semantic-Text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
`;

export const InfoBox = ({ children, border = true }) => {
    return (
        <InfoBoxContainer border={border}>
            <img src='/assets/icons/data-integrations/integrations-icons/info-circle.svg' style={{ width: '1.25rem', height: '1.25rem' }} />
            <InfoText>{children}</InfoText>
        </InfoBoxContainer>
    );
};

const ErrorBoxContainer = styled(InfoBoxContainer)`
    border: 1px solid var(--LS-Color-Notifications-alerts-stroke-error, #e91616);
    background: var(--LS-Color-Notifications-alerts-bg-error, #fdeaea);
`;

export const ErrorBox = ({ children }) => {
    return (
        <ErrorBoxContainer>
            <ExclamationIcon style={{ width: '1.25rem', height: '1.25rem' }} />
            <InfoText>{children}</InfoText>
        </ErrorBoxContainer>
    );
};

const ErrorMsgContainer = styled(InfoBoxContainer)`
    border: none;
    background: var(--LS-Color-Notifications-alerts-bg-error, #fdeaea);
    height: 3rem;
    padding: 0.25rem var(--spacers-generation-sp-3012, 0.75rem) 0.25rem 1rem;
    gap: 0.75rem;
`;

export const ErrorMsg = ({ children, onClose = undefined }) => {
    return (
        <ErrorMsgContainer>
            <ExclamationIcon style={{ width: '1.25rem', height: '1.25rem' }} />
            <InfoText>{children}</InfoText>
            {onClose && (
                <Button active variant='text' onClick={onClose}>
                    <CloseIcon />
                </Button>
            )}
        </ErrorMsgContainer>
    );
};
