import React, { useEffect, useState } from 'react';
import { updateMfaSettings, useMfaSettings } from '@walkme-admin-center/libs/state-management-users';
import { useDispatch } from 'react-redux';
import SecurityCard from '../common/security-card';
import { MfaSettingsStatus } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface MfaSettingsProps {}

export const MfaSettingsPage = (props: MfaSettingsProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const { mfaSettingsAppData } = useMfaSettings();

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (mfaSettingsAppData.data) {
            setChecked(mfaSettingsAppData.data.isEnabled);
        }
    }, [mfaSettingsAppData]);

    const toggleSwitch = (isChecked: boolean) => {
        const status = isChecked ? MfaSettingsStatus.enable : MfaSettingsStatus.disable;
        dispatch(updateMfaSettings({ status }));
    };

    return (
        <SecurityCard
            name='mfa-config'
            marginTop={'32px'}
            handleSwitchClick={toggleSwitch}
            loading={mfaSettingsAppData.loading}
            title={t('security-tab.mfa.title')}
            subTitle={t('security-tab.mfa.subtitle')}
            checked={checked}
            disabled={false}
            error={mfaSettingsAppData.error}
        />
    );
};

export default MfaSettingsPage;
