/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BootstrapInput } from '../integration-manager-style';
import { InputAdornment, Popover, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import {
    ChannelStatus,
    IAskMeForm,
    IAskMeIntegrationSystem,
    IIntegration,
    IIntegrationChannel,
    IIntegrationChannels,
} from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { CssTextField } from '../../../../../data-integration-form-style';
import { useForm } from 'react-final-form';
import { Pagination } from '@material-ui/lab';
import { shrinkText } from 'packages/pages/home/data-integrations/src/lib/common/services';
import CustomToolTip from 'packages/pages/home/sso-configuration/src/lib/common/components/tool-tip/tool-tip';
import {
    ChannelsListContainer,
    ChannelsListHeader,
    ContactSupport,
    MenuItemStyled,
    RequestCounter,
    SelectStyled,
    TableRowStyled,
    TableCellStyled,
    TextStyle,
    TableFooterStyled,
    RefreshChannelsContainer,
    RefreshIconContainer,
    SmallIconContainer,
    AutoApproveIconContainer,
    TableCellContentStyled,
    NoChannelsStyled,
} from './channels-style';
import { retrieveSlackIcon, retrieveTeamsIcon } from '@walkme-admin-center/libs/common';
import { useDispatch } from 'react-redux';
import { refreshIntegrations } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { checkRule } from '../helper';
import FilterDialog, { FilterStatusOptions } from './filter-channels';
import { InfoIcon } from '../rules/rules-style';
import ChannelsTip from './channels-tool-tip';

const PrivateChannelIcon = 'assets/icons/data-integrations/private-channel-icon.svg';
const PublicChannelIcon = 'assets/icons/data-integrations/public-channel-icon.svg';
const refreshIcon = 'assets/icons/data-integrations/integrations-icons/refresh-icon.svg';
const sortIcon = 'assets/icons/data-integrations/integrations-icons/sort-icon.svg';
const filterIcon = 'assets/icons/data-integrations/integrations-icons/filter-icon.svg';
const appliedFilterIcon = 'assets/icons/data-integrations/integrations-icons/applied-filter-icon.svg';
const autoApprove = 'assets/icons/data-integrations/integrations-icons/auto-approve-icon.svg';

const statusOptions = [
    { value: ChannelStatus.APPROVED, label: 'Approved' },
    { value: ChannelStatus.DENIED, label: 'Denied' },
];

interface IntegrationChannelsProps {
    integration: IIntegration;
}

const IntegrationChannels = ({ integration }: IntegrationChannelsProps) => {
    const dispatch = useDispatch();
    const formApi = useForm();
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const integrationSystemsApi = useFieldArray(`${integration.type}Systems`);
    const integrationRulesApi = useFieldArray(`${integration.type}Rules`);
    const integrationSystems = formApi.getState().values[`${integration.type}Systems`];
    const rowsNumPerPage = 5;

    const [showFilter, setShowFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState<FilterStatusOptions>(FilterStatusOptions.ALL);
    const [sortOrder, setSortOrder] = useState('');
    const [anchorFilter, setAnchorFilter] = useState(null);
    const [sortDirection, setSortDirection] = useState('');
    const [sortByNameDirection, setSortByNameDirection] = useState('');

    const approvedByRule = (channel: IIntegrationChannel) => {
        let isOneOfRulesApplied = false;
        integrationRulesApi.fields.value.forEach(({ ruleType, values }) => {
            values.forEach((rule: string) => {
                if (checkRule(channel.channelName, rule, ruleType)) {
                    isOneOfRulesApplied = true;
                }
            });
        });
        return isOneOfRulesApplied;
    };

    const applyStatusFilter = (filter: FilterStatusOptions) => {
        setStatusFilter(filter);
        setShowFilter(false);
    };

    const handleFilterClicked = (event) => {
        setShowFilter(!showFilter);
        setAnchorFilter(event.currentTarget);
    };

    const handleSortByStatusClicked = () => {
        setSortOrder('status');
        if (sortDirection === 'approved') {
            setSortDirection('denied');
        } else {
            setSortDirection('approved');
        }
    };

    const handleSortByNameClicked = () => {
        setSortOrder('name');
        if (sortByNameDirection === 'asc') {
            setSortByNameDirection('desc');
        } else {
            setSortByNameDirection('asc');
        }
    };

    const filterByStatus = (): IIntegrationChannels => {
        switch (statusFilter) {
            case FilterStatusOptions.APPROVED:
                return integration.slackChannels.filter(
                    (channel) =>
                        integrationSystems.some(
                            (system: IAskMeIntegrationSystem) => system.channelGuid === channel.channelIntegrationGuid
                        ) && channel.channelName.toLowerCase().includes(search.toLowerCase())
                );
            case FilterStatusOptions.DENIED:
                return integration.slackChannels.filter(
                    (channel) =>
                        !integrationSystems.some(
                            (system: IAskMeIntegrationSystem) => system.channelGuid === channel.channelIntegrationGuid
                        ) && channel.channelName.toLowerCase().includes(search.toLowerCase())
                );
            default:
                return integration.slackChannels.filter((channel) => channel.channelName.toLowerCase().includes(search.toLowerCase()));
        }
    };

    const sortByStatus = (channels: IIntegrationChannels) => {
        const approved = channels.filter((channel) =>
            integrationSystems.some((system: IAskMeIntegrationSystem) => system.channelGuid === channel.channelIntegrationGuid)
        );
        const denied = channels.filter(
            (channel) =>
                !integrationSystems.some((system: IAskMeIntegrationSystem) => system.channelGuid === channel.channelIntegrationGuid)
        );
        if (sortDirection === 'approved') {
            return [...approved, ...denied];
        } else if (sortDirection === 'denied') {
            return [...denied, ...approved];
        } else {
            return channels;
        }
    };

    const sortByName = (channels: IIntegrationChannels) => {
        if (sortByNameDirection === 'asc') {
            return [...channels].sort((a, b) => a.channelName.localeCompare(b.channelName));
        } else if (sortByNameDirection === 'desc') {
            return [...channels].sort((a, b) => b.channelName.localeCompare(a.channelName));
        } else {
            return [...channels];
        }
    };

    const filter = useCallback(() => {
        let filterSystemsByStatus = filterByStatus();
        switch (sortOrder) {
            case 'status':
                return sortByStatus(filterSystemsByStatus);
            case 'name':
                return sortByName(filterSystemsByStatus);
            default:
                return filterSystemsByStatus;
        }
    }, [search, statusFilter, sortDirection, sortByNameDirection, integrationSystems, integration.slackChannels]);

    const retrieveIntegrationIcon = (type: string) => {
        switch (type) {
            case 'slack':
                return retrieveSlackIcon();
            case 'teams':
                return retrieveTeamsIcon();
        }
    };

    const filterWithPagination = useCallback(
        (filter) => {
            const start = (currentPage - 1) * rowsNumPerPage;
            const end = start + rowsNumPerPage;
            return filter.slice(start, end);
        },
        [currentPage]
    );

    const getStatus = (channelGuid: string) => {
        const approved = formApi
            .getState()
            .values[`${integration.type}Systems`]?.some((channel: IAskMeIntegrationSystem) => channel.channelGuid === channelGuid);
        return approved ? ChannelStatus.APPROVED : ChannelStatus.DENIED;
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    const channelStatusChanged = (channel: IIntegrationChannel, event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value === ChannelStatus.APPROVED) {
            integrationSystemsApi.fields.push({
                channelGuid: channel.channelIntegrationGuid,
                channelName: channel.channelName,
            });
        } else {
            const index = formApi
                .getState()
                .values[`${integration.type}Systems`]?.findIndex(
                    (channelForm: IAskMeIntegrationSystem) => channelForm.channelGuid === channel.channelIntegrationGuid
                );
            integrationSystemsApi.fields.remove(index);
        }
    };

    const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const localChannelState = useRef(integration.slackChannels);

    useEffect(() => {
        const { slackChannels } = integration;
        const { values: currentValues } = formApi.getState();
        const newChannels = slackChannels.filter(
            ({ channelIntegrationGuid: id1 }) => !localChannelState.current.some(({ channelIntegrationGuid: id2 }) => id2 === id1)
        );

        if (newChannels.length) {
            const currentIntegrationSystems = integrationSystemsApi.fields.value;
            const newInitialChannels = newChannels.reduce((acc, channel) => {
                let isOneOfRulesApplied = false;
                formApi.getState().initialValues[`${integration.type}Rules`].forEach(({ ruleType, values }) => {
                    values.forEach((rule: string) => {
                        if (checkRule(channel.channelName, rule, ruleType)) {
                            isOneOfRulesApplied = true;
                        }
                    });
                });

                if (isOneOfRulesApplied) {
                    acc.push({ channelGuid: channel.channelIntegrationGuid });
                }
                return acc;
            }, []);
            const newInitIntegrationSystems = [...formApi.getState().initialValues[`${integration.type}Systems`], ...newInitialChannels];
            formApi.initialize({ ...formApi.getState().initialValues, [`${integration.type}Systems`]: newInitIntegrationSystems });

            const newCurrentApprovedChannels = newChannels.reduce((acc, channel) => {
                let isOneOfRulesApplied = false;

                integrationRulesApi.fields.value.forEach(({ ruleType, values }) => {
                    values.forEach((rule: string) => {
                        if (checkRule(channel.channelName, rule, ruleType)) {
                            isOneOfRulesApplied = true;
                        }
                    });
                });

                if (isOneOfRulesApplied) {
                    const found = integrationSystemsApi.fields.value.find(
                        (system) => system.channelGuid === channel.channelIntegrationGuid
                    );
                    if (!found) {
                        acc.push({ channelGuid: channel.channelIntegrationGuid });
                    }
                }
                return acc;
            }, []);

            Object.keys(currentValues).forEach((key: keyof IAskMeForm) => {
                if (key === `${integration.type}Systems`) {
                    formApi.change(`${integration.type}Systems`, [...newCurrentApprovedChannels, ...currentIntegrationSystems]);
                } else {
                    formApi.change(key, currentValues[key]);
                }
            });
            localChannelState.current = slackChannels;
        }
    }, [integration.slackChannels.length]);

    return (
        <ChannelsListContainer>
            <div className={'channels-list-header-wrapper'}>
                <ChannelsListHeader>
                    <div className={'channels-list-header-text-wrapper'}>
                        <div className={'channels-list-header-text '}>Identified Channels</div>
                        <CustomToolTip title={<ChannelsTip integrationType={integration.type} />} placement='right' arrow>
                            <InfoIcon
                                className={'icon-flex-row'}
                                src='assets/icons/data-integrations/info-icon-small.svg'
                                alt='Info Icon'
                            />
                        </CustomToolTip>
                        <RequestCounter>{integration.slackChannels.length || 0}</RequestCounter>
                    </div>
                    <CssTextField
                        className={'search'}
                        style={{ width: '280px' }}
                        value={search}
                        variant='outlined'
                        onChange={(event) => setSearch(event.target.value)}
                        placeholder={'Search..'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment style={{ width: '8px' }} position='start'>
                                    <img src={'assets/icons/data-integrations/search-icon-medium.svg'} alt='Search' />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomToolTip title='Refresh Channels' placement='top' arrow>
                        <RefreshChannelsContainer>
                            <RefreshIconContainer
                                src={refreshIcon}
                                alt='Refresh Channels'
                                onClick={() => dispatch(refreshIntegrations())}
                            />
                        </RefreshChannelsContainer>
                    </CustomToolTip>
                </ChannelsListHeader>
            </div>
            {integration.slackChannels.length > 0 ? (
                <FieldArray name={`${integration.type}Systems`}>
                    {({ fields }) =>
                        filterWithPagination(filter()).length ? (
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRowStyled>
                                                <TableCellStyled width={332} align='left' className='header'>
                                                    <TableCellContentStyled>
                                                        <div>Channel Name</div>
                                                        <SmallIconContainer src={sortIcon} alt='Sort' onClick={handleSortByNameClicked} />
                                                    </TableCellContentStyled>
                                                </TableCellStyled>
                                                <TableCellStyled width={180} align='right' className='header' style={{ gap: '24px' }}>
                                                    <TableCellContentStyled>
                                                        <div>Approval Status</div>
                                                        <div className={'table-cell-flex-row'}>
                                                            <SmallIconContainer
                                                                src={
                                                                    statusFilter === FilterStatusOptions.ALL
                                                                        ? filterIcon
                                                                        : appliedFilterIcon
                                                                }
                                                                alt='Filter'
                                                                onClick={handleFilterClicked}
                                                            />
                                                            <Popover
                                                                open={showFilter}
                                                                anchorEl={anchorFilter}
                                                                onClose={() => setShowFilter(false)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <FilterDialog filter={statusFilter} changeFilter={applyStatusFilter} />
                                                            </Popover>
                                                            <SmallIconContainer
                                                                src={sortIcon}
                                                                alt='Sort'
                                                                onClick={handleSortByStatusClicked}
                                                            />
                                                        </div>
                                                    </TableCellContentStyled>
                                                </TableCellStyled>
                                            </TableRowStyled>
                                        </TableHead>
                                        <TableBody>
                                            {filterWithPagination(filter()).map((channel: IIntegrationChannel, index: number) => {
                                                const channelStatus = getStatus(channel.channelIntegrationGuid);
                                                const approvedByRuleStatus = approvedByRule(channel);
                                                return (
                                                    <TableRowStyled key={index}>
                                                        <TableCellStyled className='channelName'>
                                                            <img src={retrieveIntegrationIcon(integration.type)} alt={integration.type} />
                                                            {channel.channelName !== 'Direct Message' && (
                                                                <img
                                                                    src={
                                                                        channel.isPrivate === true ? PrivateChannelIcon : PublicChannelIcon
                                                                    }
                                                                    alt={`${channel.isPrivate === true ? 'Private' : 'Public'}`}
                                                                />
                                                            )}
                                                            <CustomToolTip
                                                                title={channel.channelName}
                                                                placement='top'
                                                                disableHoverListener={channel.channelName.length <= 40}>
                                                                <div>{shrinkText(channel.channelName, 40)}</div>
                                                            </CustomToolTip>
                                                        </TableCellStyled>
                                                        <TableCellStyled width={180} className={'channelStatus'} align='center'>
                                                            {approvedByRuleStatus && channelStatus === ChannelStatus.APPROVED && (
                                                                <CustomToolTip
                                                                    title={
                                                                        <div>
                                                                            Approved automatically
                                                                            <br />
                                                                            due to rule compliance
                                                                        </div>
                                                                    }
                                                                    placement='top'
                                                                    arrow={true}>
                                                                    <AutoApproveIconContainer src={autoApprove} alt='Auto Approve' />
                                                                </CustomToolTip>
                                                            )}
                                                            <SelectStyled
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: 'selectChannelsMenuItem',
                                                                    },
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                }}
                                                                className={`${channelStatus}`}
                                                                value={channelStatus}
                                                                onChange={(event) => channelStatusChanged(channel, event)}
                                                                input={<BootstrapInput />}
                                                                IconComponent={'div'}>
                                                                {statusOptions.map((option, i) => (
                                                                    <MenuItemStyled
                                                                        className={`${option.value}`}
                                                                        value={option.value}
                                                                        key={i}>
                                                                        {option.label}
                                                                    </MenuItemStyled>
                                                                ))}
                                                            </SelectStyled>
                                                        </TableCellStyled>
                                                    </TableRowStyled>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableFooterStyled>
                                    <Pagination
                                        size='small'
                                        page={currentPage}
                                        count={Math.ceil(filter().length / rowsNumPerPage)}
                                        color='primary'
                                        onChange={onChangePage}
                                    />
                                </TableFooterStyled>
                            </>
                        ) : (
                            <NoChannelsStyled>
                                <img
                                    src='assets/icons/data-integrations/integrations-icons/system-icons/no-channels-found.svg'
                                    alt='No Channels Were Found'
                                />
                                <TextStyle>We couldn't find any channel requests</TextStyle>
                                <TextStyle style={{ fontWeight: 400 }}>
                                    <ContactSupport onClick={() => setSearch('')}>Clear your search</ContactSupport> and try somthing else
                                </TextStyle>
                            </NoChannelsStyled>
                        )
                    }
                </FieldArray>
            ) : (
                <NoChannelsStyled>
                    <img
                        src='assets/icons/data-integrations/integrations-icons/system-icons/no-channels-found.svg'
                        alt='No Channels Were Found'
                    />
                    <TextStyle className={'bold-text'}>There are no channels yet</TextStyle>
                    <TextStyle>
                        Once a {integration.type} channel will command <ContactSupport>AI Answers</ContactSupport> , it will appear here
                    </TextStyle>
                </NoChannelsStyled>
            )}
        </ChannelsListContainer>
    );
};

export default IntegrationChannels;
