import { Chip, Input, Select, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { StyledCustomMenuItem } from '../../../../../../common/style';

export const RuleTextContainer = styled.div`
    width: 245px;
    height: 32px;
    display: flex;
    overflow-x: auto;
    background: #f4f6fb;
    overflow-y: hidden;
    border-radius: 4px;
    border: 1px solid #ebeff7;
    &::-webkit-scrollbar {
        height: 1px;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #c8d3f9;
        width: 30px;
    }
    &:hover:not(.disabled) {
        border: 1px solid #385feb;
    }
    &:focus-within {
        border: 1px solid #385feb;
        background: white;
    }
`;

export const RuleTextField = withStyles({
    root: {
        width: 'inherit',
        '&::before': {
            border: 'none !important',
        },
        '&::after': {
            border: 'none !important',
        },
        '& .MuiInputBase-input': {
            width: '100%',
            minWidth: '100px',
            marginLeft: '12px',
        },
    },
})(Input);

export const CssSelect = withStyles({
    root: {
        border: 'solid 1px #f6f8fc',
        background: '#f6f8fc',
        borderRadius: '4px !important',
        padding: '4px 40px 4px 12px !important',
        height: '32px',
        lineHeight: '32px',
    },
    icon: {
        '&.MuiSelect-iconOpen': {
            paddingBottom: '2px',
        },
        '&:not(.MuiSelect-iconOpen)': {
            paddingTop: '2px',
        },
    },
})(Select);

export const RuleSelectStyled = withStyles((theme) => ({
    root: {
        padding: ' 0 0 0 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '156px',
        cursor: 'pointer',
    },
}))(CssSelect);

export const RulesMenuItem = withStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '120px',
        height: '36px',
        '&:hover': {
            backgroundColor: '#F0F4FA !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#F5F7FE',
            color: '#3B61EB',
        },
    },
})(StyledCustomMenuItem);

export const ChipStyled = withStyles((theme) => ({
    root: {
        height: '24px !important',
        color: '#2F426C',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        borderRadius: '4px',
        background: '#D8DFF4',
        padding: '4px 8px',
        marginLeft: '4px',
        '&:hover': {
            background: '#BFCBEE',
        },
    },
    label: {
        paddingRight: '4px',
        paddingLeft: '0',
    },
    deleteIcon: {
        paddingRight: '4px',
        paddingLeft: '4px',
        margin: 0,
    },
}))(Chip);

export const ToolTipHeader = styled.div`
    font-weight: 600;
    font-size: 14px;
    height: 24px;
    padding-top: 4px;
`;

export const ToolTipBody = styled.div`
    font-weight: 400;
    font-size: 14px;
    padding: 2px 0px;
`;

export const AddRuleButton = styled.img`
    width: 10px;
    height: 10px;
    padding: 6px;
    cursor: pointer;
    alignself: flex-start;
    border-radius: 50%;
    &:hover {
        background: #f5f7fe;
        transition: 0.3s ease;
        stroke: #3b61eb;
    }
`;

export const RuleTipContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    aslign-self: flex-start;
    gap: 4px;
    color: #fff;
    fontfamily: Proxima Nova;
    fontstyle: normal;
    lineheight: 20px;
`;

export const RuleBoldText = styled.a`
    font-weight: 600;
`;

export const InfoIcon = styled.img`
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;

    &.icon-flex-row {
        align-self: center;
        display: flex;
        flex-direction: row;
    }

    &:hover {
        filter: brightness(80%);
    }
`;

export const DialogContainer = styled.div`
    display: flex;
    width: 496px;
    flex-direction: column;
    align-items: flex-start;

    .check-box-wrapper {
        display: flex;
        flex-direction: row;
        align-self: stretch;
    }
`;

export const DialogHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 28px 16px 32px;
    align-self: stretch;

    .dialog-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const DialogBody = styled.div`
    display: flex;
    padding: 8px 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    color: #2f426c;
    font-style: normal;
    line-height: 20px;
`;

export const DialogFooter = styled.div`
    display: flex;
    padding: 16px 32px 32px 32px;
    align-self: stretch;
    justify-content: flex-end;
    border-radius: 0px 0px 12px 12px;
    background: #fff;

    .dialog-footer-container {
        gap: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
`;
