import React, { ReactNode } from 'react';
import { StyledDataCollectionOption } from './data-collection-option.styles';
import { Check } from '@walkme/ui-icons/large';
import { RadioButton } from '@walkme/ui-core';

type DataCollectionOptionProps = {
    id: string;
    title: string | ReactNode;
    bullets: string[];
    isLast: boolean;
    isSelected: boolean;
    isDisabled: boolean;
    onChange: React.Dispatch<React.SetStateAction<string>>;
};

export const DataCollectionOption = ({ id, title, bullets, isLast, isSelected, isDisabled, onChange }: DataCollectionOptionProps) => {
    return (
        <StyledDataCollectionOption isLast={isLast}>
            <div className='radioBtn'>
                <RadioButton checked={isSelected} onClick={() => onChange(id)} disabled={isDisabled} />
            </div>
            <div className='description'>
                <div className='main-title'>{title}</div>
                <div className='bullets'>
                    {bullets.map((bullet) => (
                        <div className='bullet'>
                            <div className='check'>
                                <Check color='#6c81a6' />
                            </div>
                            <div className='text'>{bullet}</div>
                        </div>
                    ))}
                </div>
            </div>
        </StyledDataCollectionOption>
    );
};
