import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const StyledContractListContainer = styled.div<{ disabled: boolean; fadeEffect: boolean }>`
    border-bottom: 1px solid #ebeff7;
    overflow-y: hidden;
    display: flex;
    flex-flow: column;
    filter: ${({ disabled }) => (disabled ? 'blur(3px)' : 'none')};
    user-select: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    .descriptionBlock {
        padding: 24px 32px 0 32px;
        margin-bottom: 20px;
        .title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 6px;
            color: var(--Typography-Typography-MainPrimary-Main, #2f426c);
        }
        .description {
            line-height: 1.5;
            color: var(--Primaries-Typography-Typography-tint-1, #637191);
            font-weight: 400;
        }
    }

    .contractsContainer {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow-y: hidden;

        .header {
            padding: 0 32px 0 32px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            cursor: pointer;
            color: var(--Primaries-Typography-Typography-tint-1, #637191);
        }

        .contracts {
            padding: 0 32px 24px 32px;
            position: relative;
            flex: 1;
            overflow-y: auto;
            -webkit-mask-image: ${({ fadeEffect }) =>
                fadeEffect ? '-webkit-gradient(linear, left center, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))' : 'none'}};

            &:hover {
                -webkit-mask-image: none !important;
            }
        }

        .determineLaterContainer {
            padding: 0 32px 0 32px;
        }
    }
`;

export const StyledLoadingSpinner = styled(CircularProgress)`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
