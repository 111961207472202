import { RuleType } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface RuleTypeTipsProps {
    ruleType: RuleType;
}

const ToolTipStyle = styled.div`
    maxwidth: 136px;
    padding: 6px 12px;
    color: #fff;
    fontweight: 400;
    lineheight: 20px;
`;

const RuleTypeTips = ({ ruleType }: RuleTypeTipsProps) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        switch (ruleType) {
            case 'is':
                setContent('Includes only names that exaxtly match the typed value(s)');
                break;
            case 'contains':
                setContent('Includes only names that contain the typed value(s)');
                break;
            case 'startsWith':
                setContent('Includes only names that start with the typed value(s)');
                break;
            case 'endsWith':
                setContent('Includes only names that end with the typed value(s)');
                break;
        }
    }, []);

    return <ToolTipStyle>{content}</ToolTipStyle>;
};

export default RuleTypeTips;
