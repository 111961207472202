import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

const StyledList = styled(List)`
    width: 200px;
    border: 1px solid #e9ecf2;
    border-radius: 2px;
    padding: 0;
`;

interface Item {
    name: string;
}

interface ListProps {
    items: Item[];
    selectedIndex: number;
    handleListItemClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, newSelectedIndex) => void;
}

export const SelectedListItem = ({ items, selectedIndex, handleListItemClick }: ListProps) => {
    return (
        <StyledList>
            {items.map((item, i) => {
                return (
                    <ListItem
                        button
                        selected={i === selectedIndex}
                        key={item.name}
                        onClick={(e) => handleListItemClick(e, i)}
                    >
                        <ListItemText primary={item.name} />
                    </ListItem>
                );
            })}
        </StyledList>
    );
};
