import { DataIntegrationState } from '../redux/data-integration.slice';
import { useSelector } from 'react-redux';

export const useAskMeForm = () => {
    const askMeForm = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.form.data;
    });

    return {
        askMeForm,
    };
};

export default useAskMeForm;
