import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputHeight, Toaster, ToasterVariant, Tooltip } from '@walkme/ui-core';
import {
    StyledExpectedFormatInputContainer,
    StyledExpectedFormatTitle,
    StyledInput,
    StyledInputLabel,
    StyledRegexpInputContainer,
    StyledUUIDBodyContainer,
    StyledVariableInputContainer,
} from './uuid-body.styles';
import { ExpectedFormat } from './expected-format-selector';
import { DropdownOption, expectedFormatOptions, getUUIDToaster, predefinedUUIDRecommendedValues, UUID } from './uuid.lib';
import { useDispatch, useSelector } from 'react-redux';
import { uuidSlice, useSystem, UUIDState } from '@walkme-admin-center/libs/state-management-systems';
import { DropdownBottom, DropdownTop, Info } from '@walkme/ui-icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { uuidVariableValidator } from '../create-system/steps/optional-configure/uuid/uuid.lib';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

export const UuidBody = ({ uuidType, systemId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const { accountFeatureEnabled } = useLoggedInUser();
    const [recommendedValuesWindowIsOpen, setRecommendedValuesWindowIsOpen] = useState(false);
    const variableInputRef = useRef(null);
    const regexpInputRef = useRef(null);
    const { currentSystemAppData } = useSystem(systemId);
    const selectedUUIDVariableValue = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedUUIDVariableValue);
    const selectedExpectedFormat = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedExpectedFormat);
    const regexpInputValue = useSelector((state: { uuidState: UUIDState }) => state.uuidState.regexpInputValue);
    const uuidErrors = useSelector((state: { uuidState: UUIDState }) => state.uuidState.uuidErrors);
    const inputLabel = t(`systems-tab.new-system-form.configure-step.uuid.${uuidType}.input-label`);
    const inputPlaceholder = t(`systems-tab.new-system-form.configure-step.uuid.variable-input.placeholder`);
    const shouldShowVariableInput = [UUID.VARIABLE, UUID.IDP, UUID.COOKIE, UUID.JQUERY].includes(uuidType);

    const recommendedValues = useMemo(() => {
        const systemPredefinedUUIDRecommendedValues = predefinedUUIDRecommendedValues[currentSystemAppData?.data?.type];
        return systemPredefinedUUIDRecommendedValues?.filter((value) => value.method === uuidType).map((value) => value.param);
    }, [uuidType, currentSystemAppData]);

    const setSelectedUUIDVariableValue = (uuidVariableValue: string) => {
        dispatch(uuidSlice.actions.setSelectedUUIDVariableValue(uuidVariableValue));
    };
    const setSelectedExpectedFormat = (formatOption: DropdownOption) => {
        dispatch(uuidSlice.actions.setSelectedExpectedFormat(formatOption));
    };

    const setRegexInputValue = (regex: string) => {
        dispatch(uuidSlice.actions.setRegexpInputValue(regex));
    };

    const handleCopyClicked = async (textToCopy: string) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            // NOTICE: we may need this in the future
            // dispatch(
            //     uuidSlice.actions.setNotificationMessage({
            //         text: t(`systems-tab.new-system-form.configure-step.copied-value`),
            //         variant: WMSnackbarVariant.Success,
            //         isOpen: true,
            //     })
            // );
            dispatch(uuidSlice.actions.setSelectedUUIDVariableValue(textToCopy));
            setRecommendedValuesWindowIsOpen(false);
        } catch (e) {
            console.error("couldn't copy text");
        }
    };

    const validateUUIDInputs = () => {
        const currentUUIDErrors = { ...uuidErrors };
        const modifiedUUIDErrors = {
            ...currentUUIDErrors,
            uuidVariableValue: !uuidVariableValidator(uuidType, selectedUUIDVariableValue, accountFeatureEnabled),
            regexpInputValue: selectedExpectedFormat?.value === 'Custom_Regexp' && !regexpInputValue,
        };
        dispatch(uuidSlice.actions.setUUIDErrors(modifiedUUIDErrors));
    };

    useEffect(validateUUIDInputs, [uuidType, selectedUUIDVariableValue, selectedExpectedFormat, regexpInputValue]);

    return (
        <StyledUUIDBodyContainer key={uuidType}>
            {shouldShowVariableInput ? (
                <>
                    <StyledInputLabel>{inputLabel}</StyledInputLabel>
                    <StyledVariableInputContainer>
                        <StyledInput
                            ref={variableInputRef}
                            placeholder={inputPlaceholder}
                            height={InputHeight.large}
                            width={300}
                            error={selectedUUIDVariableValue === '' ? t(`systems-tab.new-system-form.configure-step.errors.mandatory`) : ''}
                            value={selectedUUIDVariableValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedUUIDVariableValue(e.target.value)}
                        />
                        {/* --Disabled but kept for future optional use--  */}
                        {/*{recommendedValues?.length > 0 ? (*/}
                        {/*    <ClickAwayListener onClickAway={() => setRecommendedValuesWindowIsOpen(false)}>*/}
                        {/*        <StyledRecommendedValuesContainer>*/}
                        {/*            <StyledRecommendedValues isWindowOpen={recommendedValuesWindowIsOpen}>*/}
                        {/*                <StyledRecommendedValueHeader*/}
                        {/*                    isWindowOpen={recommendedValuesWindowIsOpen}*/}
                        {/*                    onClick={() => setRecommendedValuesWindowIsOpen((open) => !open)}>*/}
                        {/*                    <span>{t(`systems-tab.new-system-form.configure-step.uuid.recommended-values.label`)}</span>*/}
                        {/*                    {recommendedValuesWindowIsOpen ? <DropdownTop /> : <DropdownBottom />}*/}
                        {/*                </StyledRecommendedValueHeader>*/}
                        {/*                {recommendedValuesWindowIsOpen ? (*/}
                        {/*                    <StyledRecommendedValuesBody>*/}
                        {/*                        <div className='description'>*/}
                        {/*                            {t(`systems-tab.new-system-form.configure-step.uuid.recommended-values.description`)}*/}
                        {/*                        </div>*/}
                        {/*                        {recommendedValues.map((value) => (*/}
                        {/*                            <StyledWMTooltip arrow title={value} placement='top' enterDelay={500}>*/}
                        {/*                                <StyledRecommendedValue>*/}
                        {/*                                    <div className='text'>{value}</div>*/}
                        {/*                                    <StyledWMTooltip arrow title='Copy' placement='top' enterDelay={500}>*/}
                        {/*                                        <StyledCopyIcon onClick={() => handleCopyClicked(value)} />*/}
                        {/*                                    </StyledWMTooltip>*/}
                        {/*                                </StyledRecommendedValue>*/}
                        {/*                            </StyledWMTooltip>*/}
                        {/*                        ))}*/}
                        {/*                    </StyledRecommendedValuesBody>*/}
                        {/*                ) : null}*/}
                        {/*            </StyledRecommendedValues>*/}
                        {/*        </StyledRecommendedValuesContainer>*/}
                        {/*    </ClickAwayListener>*/}
                        {/*) : null}*/}
                    </StyledVariableInputContainer>
                    <div>
                        <StyledExpectedFormatTitle>
                            <div className='main-title'>
                                {t(`systems-tab.new-system-form.configure-step.uuid.expected-format.main-title`)}
                            </div>
                            <div className='description'>
                                <span className='description-text'>
                                    {t(`systems-tab.new-system-form.configure-step.uuid.expected-format.sub-title`)}
                                </span>
                                <Tooltip title={t(`systems-tab.new-system-form.configure-step.uuid.expected-format.tooltip`)}>
                                    <Info />
                                </Tooltip>
                            </div>
                        </StyledExpectedFormatTitle>
                        <StyledExpectedFormatInputContainer>
                            <ExpectedFormat
                                formatOptions={expectedFormatOptions}
                                selectedExpectedFormat={selectedExpectedFormat}
                                setSelectedExpectedFormat={setSelectedExpectedFormat}
                            />
                        </StyledExpectedFormatInputContainer>
                    </div>
                    {selectedExpectedFormat?.value === 'Custom_Regexp' ? (
                        <StyledRegexpInputContainer>
                            <StyledInput
                                ref={regexpInputRef}
                                placeholder={t(`systems-tab.new-system-form.configure-step.uuid.inputs.regex-placeholder`)}
                                height={InputHeight.large}
                                width={300}
                                error={regexpInputValue === '' ? t(`systems-tab.new-system-form.configure-step.errors.mandatory`) : ''}
                                value={regexpInputValue}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRegexInputValue(e.target.value)}
                                label={t(`systems-tab.new-system-form.configure-step.uuid.inputs.regex-label`)}
                            />
                        </StyledRegexpInputContainer>
                    ) : null}
                </>
            ) : null}

            {getUUIDToaster(uuidType as UUID, t, currentSystemAppData?.data?.type)}
        </StyledUUIDBodyContainer>
    );
};
