import { DialogActions, DialogContent } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDialogContent = styled(DialogContent)`
    padding: 0;
    & .stepper-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        & .stepper-content {
            display: flex;
            flex-direction: column;
            padding: 32px 104px;
            width: 100%;
        }
    }
`;

export const StyledDialogActions = styled(DialogActions)`
    height: 40px;
    box-shadow: 0px -2px 8px #0000000a;
    padding: 16px 32px 32px 0;
`;
