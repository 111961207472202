import { StepConnector, StepLabel, Stepper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledStepper = styled(Stepper)`
    justify-content: center;
    position: relative;
    padding: 28px 0;
    min-height: 24px;
`;

export const StyledStepLabel = styled(StepLabel)`
    .labelContainer {
        color: red;
        & .MuiStepLabel-label {
            font-size: 14px;
            color: #6d81a6;
        }

        & .MuiStepLabel-label.MuiStepLabel-active {
            color: #2f426c;
            font-weight: 600;
        }
    }
`;

export const StyledQontoConnector = styled(StepConnector)`
    flex: none;
    width: 24px;
    height: 0;
    border: 1px solid #b8c6e0;
`;
