import { retriveTeamsStepVideo } from '@walkme-admin-center/libs/common';
import React from 'react';
import {
    StepContainer,
    TitleContainer,
    TitleText,
    BoldText,
    SubtitleText,
    VideoStepNumber,
} from '../../../slack-form/steps/manifest-step/slack-steps/slack-steps-styles';
import VideoContainer from '../../../slack-form/steps/manifest-step/slack-steps/video-container';

const CreateBotSecret = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>6.</VideoStepNumber>Create <BoldText>Bot Secret</BoldText>
                </TitleText>
                <SubtitleText>
                    Click ‘Manage Password’ on Configarion page, and then create new secret - copy and save it for next step
                </SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveTeamsStepVideo(5)} />
        </StepContainer>
    );
};
export default CreateBotSecret;
