import { useSelector } from 'react-redux';
import { SsoConfigurationState } from '../redux/sso-configuration.slice';

export const useAccountConfig = () => {
    const accountConfig = useSelector(
        (state: { ssoConfigurationState: SsoConfigurationState }) => state.ssoConfigurationState.accountConfig
    );

    return {
        accountConfig,
    };
};

export default useAccountConfig;
