import styled from 'styled-components';
import { Divider, Menu, MenuItemText, MenuList } from '@walkme/ui-core';

export const StyledActionMenuLongList = styled(MenuList)`
    height: 150px;
`;

export const StyledTitle = styled(MenuItemText)`
    padding: 12px 16px;
    font-weight: 600;
`;

export const StyledDivider = styled(Divider)`
    margin: 0 16px;
`;

export const StyledMenu = styled(Menu)`
    & .MuiMenu-paper {
        margin-top: 8px !important;
    }
`;
