import { Action, PayloadAction, ThunkAction, createSlice } from '@reduxjs/toolkit';
import { AppData, PartnerAccountData, CreatePartnerDetailsDto, InviteData, NotificationMessage } from '@walkme-admin-center/libs/types';
import { wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { AccountsSdk, EditUserDto, SystemTypeKey, User } from 'wm-accounts-sdk';

//TODO: Rewrite this temporary store type
type AppThunk = ThunkAction<void, {}, unknown, Action<string>>;

export interface PartnersState {
    partners: AppData<PartnerAccountData[]>;
    invites: AppData<InviteData[]>;
    addPartners: AppData<boolean>;
    sendInvite: AppData<boolean>;
    deleteAccount: AppData;
    editUser: AppData<User>;
    deleteUser: AppData;
    resendInvite: AppData;
    validateInvite: AppData;
    cancelInvite: AppData;
    notificationMessage: NotificationMessage;
}

export const initialPartnersState: PartnersState = {
    partners: {
        loading: false,
        error: null,
        data: [],
    },
    invites: {
        loading: false,
        error: null,
        data: [],
    },
    addPartners: {
        loading: false,
        error: null,
        data: false,
    },
    notificationMessage: {
        text: '',
        variant: WMSnackbarVariant.Success,
        isOpen: false,
    },
    sendInvite: {
        loading: false,
        error: null,
        data: false,
    },
    deleteAccount: {
        loading: false,
        error: null,
        data: null,
    },
    editUser: {
        loading: false,
        error: null,
        data: null,
    },
    deleteUser: {
        loading: false,
        error: null,
        data: null,
    },
    resendInvite: {
        loading: false,
        error: null,
        data: null,
    },
    validateInvite: {
        loading: false,
        error: null,
        data: null,
    },
    cancelInvite: {
        loading: false,
        error: null,
        data: null,
    },
};

export const notificationsMessages = {
    InviteSuccess: 'Invite sent successfully',
    InvitesSuccess: 'All invites sent successfully',
    DeleteAccountSuccess: 'Account deleted successfully',
    DeleteUserSuccess: 'User deleted successfully',
    ResendInviteSuccess: 'Email sent successfully',
    ValidateInviteSuccess: 'Invitation validated and email sent successfully',
    CancelInviteSuccess: 'Invitation canceled successfully',
    EditUserSuccess: 'User edited successfully',
};

const updateStateWithNotificationMessage = ({ state, message, variant, isOpen }) => {
    state.notificationMessage.text = message;
    state.notificationMessage.variant = variant;
    state.notificationMessage.isOpen = isOpen;
};

const partnersSlice = createSlice({
    name: 'partnersSlice',
    initialState: initialPartnersState,
    reducers: {
        getPartnersSuccess(state: PartnersState, action: PayloadAction<PartnerAccountData[]>) {
            state.partners.data = action.payload;
            state.partners.loading = false;
            return state;
        },
        getPartnersStart: (state: PartnersState) => {
            state.partners.loading = true;
            return state;
        },
        getPartnersFailed(state: PartnersState, action: PayloadAction<string>) {
            state.partners.loading = false;
            state.partners.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        getInvitesSuccess(state: PartnersState, action: PayloadAction<InviteData[]>) {
            state.invites.data = action.payload;
            state.invites.loading = false;
            return state;
        },
        getInvitesStart: (state: PartnersState) => {
            state.invites.loading = true;
            return state;
        },
        getInvitesFailed(state: PartnersState, action: PayloadAction<string>) {
            state.invites.loading = false;
            state.invites.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        addPartnersStart: (state: PartnersState) => {
            state.addPartners.loading = true;
            state.addPartners.error = null;
            state.addPartners.data = false;
            return state;
        },
        addPartnersSuccess: (state: PartnersState, action: PayloadAction<string>) => {
            state.addPartners.loading = false;
            state.addPartners.data = true;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        addPartnersFailed: (state, action: PayloadAction<string>) => {
            state.addPartners.loading = false;
            state.addPartners.data = false;
            state.addPartners.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        addPartnersCleanup: (state) => {
            state.addPartners.loading = false;
            state.addPartners.error = null;
        },
        cleanUpNotificationMessage(state: PartnersState) {
            state.notificationMessage.isOpen = false;
            return state;
        },
        setNotificationMessage(state: PartnersState, action: PayloadAction<NotificationMessage>) {
            state.notificationMessage = action.payload;
            return state;
        },
        deleteAccountStart: (state: PartnersState) => {
            state.deleteAccount.loading = true;
            state.deleteAccount.error = null;
            return state;
        },
        deleteAccountSuccess: (state: PartnersState) => {
            state.deleteAccount.loading = false;
            updateStateWithNotificationMessage({
                state,
                message: notificationsMessages.DeleteAccountSuccess,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        deleteAccountFailed: (state, action: PayloadAction<string>) => {
            state.deleteAccount.loading = false;
            state.deleteAccount.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        deleteUserStart: (state: PartnersState) => {
            state.deleteUser.loading = true;
            state.deleteUser.error = null;
            state.deleteUser.data = false;
            return state;
        },
        deleteUserSuccess: (state: PartnersState) => {
            state.deleteUser.loading = false;
            state.deleteUser.data = true;
            updateStateWithNotificationMessage({
                state,
                message: notificationsMessages.DeleteUserSuccess,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        deleteUserFailed: (state, action: PayloadAction<string>) => {
            state.deleteUser.loading = false;
            state.deleteUser.data = false;
            state.deleteUser.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        editUserStart: (state: PartnersState) => {
            state.editUser.loading = true;
            state.editUser.error = null;
            state.editUser.data = null;
            return state;
        },
        editUserSuccess: (state: PartnersState, action: PayloadAction<User>) => {
            state.editUser.loading = false;
            state.editUser.data = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: notificationsMessages.EditUserSuccess,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        editUserFailed: (state, action: PayloadAction<string>) => {
            state.editUser.loading = false;
            state.editUser.data = null;
            state.editUser.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        editUserCleanup: (state) => {
            state.editUser.data = null;
        },
        validateInviteStart: (state: PartnersState) => {
            state.validateInvite.loading = true;
            state.validateInvite.error = null;
            state.validateInvite.data = false;
            return state;
        },
        validateInviteSuccess: (state: PartnersState) => {
            state.validateInvite.loading = false;
            state.validateInvite.data = true;
            updateStateWithNotificationMessage({
                state,
                message: notificationsMessages.ValidateInviteSuccess,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        validateInviteFailed: (state, action: PayloadAction<string>) => {
            state.validateInvite.loading = false;
            state.validateInvite.data = false;
            state.validateInvite.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        resendInviteStart: (state: PartnersState) => {
            state.resendInvite.loading = true;
            state.resendInvite.error = null;
            state.resendInvite.data = false;
            return state;
        },
        resendInviteSuccess: (state: PartnersState) => {
            state.resendInvite.loading = false;
            state.resendInvite.data = true;
            updateStateWithNotificationMessage({
                state,
                message: notificationsMessages.ResendInviteSuccess,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        resendInviteFailed: (state, action: PayloadAction<string>) => {
            state.resendInvite.loading = false;
            state.resendInvite.data = false;
            state.resendInvite.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        cancelInviteStart: (state: PartnersState) => {
            state.cancelInvite.loading = true;
            state.cancelInvite.error = null;
            state.cancelInvite.data = false;
            return state;
        },
        cancelInviteSuccess: (state: PartnersState) => {
            state.cancelInvite.loading = false;
            state.cancelInvite.data = true;
            updateStateWithNotificationMessage({
                state,
                message: notificationsMessages.CancelInviteSuccess,
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        cancelInviteFailed: (state, action: PayloadAction<string>) => {
            state.cancelInvite.loading = false;
            state.cancelInvite.data = false;
            state.cancelInvite.error = action.payload;
            updateStateWithNotificationMessage({
                state,
                message: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
    },
});

export { partnersSlice };
const {
    getPartnersFailed,
    getPartnersSuccess,
    getPartnersStart,
    getInvitesFailed,
    getInvitesSuccess,
    getInvitesStart,
    addPartnersStart,
    addPartnersFailed,
    addPartnersSuccess,
    deleteAccountStart,
    deleteAccountSuccess,
    deleteAccountFailed,
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailed,
    editUserStart,
    editUserSuccess,
    editUserFailed,
    validateInviteStart,
    validateInviteSuccess,
    validateInviteFailed,
    resendInviteStart,
    resendInviteSuccess,
    resendInviteFailed,
    cancelInviteStart,
    cancelInviteSuccess,
    cancelInviteFailed,
} = partnersSlice.actions;

export const addPartners = async (dispatch, newPartnersData: CreatePartnerDetailsDto[]) => {
    try {
        dispatch(addPartnersStart());
        const promises = newPartnersData.map(async (newPartnerData) => {
            const invitePartnerDto = {
                email: newPartnerData.email,
                role: newPartnerData.roleId,
                systems: newPartnerData.systemIds,
            };

            try {
                await wmhttpClientSharedInstance.instance.post('/accounts-partners/clients/account/partners/invite', invitePartnerDto);
                return { status: 'success', email: invitePartnerDto.email };
            } catch (error) {
                return { status: 'error', email: invitePartnerDto.email, error };
            }
        });

        let results = await Promise.all(promises);

        const failedRequestEmails = [];
        const successfulRequestEmails = [];

        results.forEach((result) => {
            result.status === 'error' ? failedRequestEmails.push(result.email) : successfulRequestEmails.push(result.email);
        });

        if (failedRequestEmails.length > 0) {
            const emails = failedRequestEmails.join(', ');
            const message = `The invites to the following partners have not been sent: ${emails}. Please try again. `;
            dispatch(addPartnersFailed(message));
        } else {
            const message = successfulRequestEmails.length > 1 ? notificationsMessages.InvitesSuccess : notificationsMessages.InviteSuccess;
            dispatch(addPartnersSuccess(message));
            dispatch(getInvites());
        }
    } catch (error) {
        dispatch(addPartnersFailed(error?.response?.data?.message));
    }
};

export const deleteAccount = async (dispatch, partnerAccountId: number) => {
    try {
        dispatch(deleteAccountStart());
        await wmhttpClientSharedInstance.instance.delete(`/accounts-partners/clients/account/partners/account/${partnerAccountId}`);
        dispatch(deleteAccountSuccess());
        dispatch(getPartners());
    } catch (error) {
        dispatch(deleteAccountFailed(error?.response?.data?.message));
    }
};

export const deleteUser = async (dispatch, partnerUserId: number) => {
    try {
        dispatch(deleteUserStart());
        await wmhttpClientSharedInstance.instance.delete(`/accounts-partners/clients/account/partners/user/${partnerUserId}`);
        dispatch(deleteUserSuccess());
        dispatch(getPartners());
    } catch (error) {
        dispatch(deleteUserFailed(error?.response?.data?.message));
    }
};

export const editUser = async (dispatch, editUser: EditUserDto) => {
    try {
        dispatch(editUserStart());
        const savedUser: User = await AccountsSdk.getInstance().updateUser(editUser);
        dispatch(editUserSuccess(savedUser));
        dispatch(getPartners());
    } catch (error) {
        dispatch(editUserFailed(error?.response?.data?.message));
    }
};

export const validateInvite = async (dispatch, email: string) => {
    try {
        dispatch(validateInviteStart());
        await wmhttpClientSharedInstance.instance.post('/accounts-partners/clients/account/partners/invite/validate', { email });
        dispatch(validateInviteSuccess());
        dispatch(getInvites());
    } catch (error) {
        dispatch(validateInviteFailed(error?.response?.data?.message));
    }
};

export const cancelInvite = async (dispatch, email: string) => {
    try {
        dispatch(cancelInviteStart());
        await wmhttpClientSharedInstance.instance.delete(`/accounts-partners/clients/account/partners/invite/${email}`);
        dispatch(cancelInviteSuccess());
        dispatch(getInvites());
    } catch (error) {
        dispatch(cancelInviteFailed(error?.response?.data?.message));
    }
};

export const resendInviteMail = async (dispatch, email: string) => {
    try {
        dispatch(resendInviteStart());
        await wmhttpClientSharedInstance.instance.post('/accounts-partners/clients/account/partners/invite/resend', { email });
        dispatch(resendInviteSuccess());
    } catch (error) {
        dispatch(resendInviteFailed(error?.response?.data?.message));
    }
};

export const getPartners = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getPartnersStart());
        const partners = await wmhttpClientSharedInstance.instance.get('/accounts-partners/clients/account/partners');
        dispatch(getPartnersSuccess(partners.data));
    } catch (err) {
        dispatch(getPartnersFailed(err.message));
        return;
    }
};

export const getInvites = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getInvitesStart());
        const invites = await wmhttpClientSharedInstance.instance.get('/accounts-partners/clients/account/partners/invites');
        dispatch(getInvitesSuccess(invites.data));
    } catch (err) {
        dispatch(getInvitesFailed(err.message));
        return;
    }
};
