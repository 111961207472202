const styledScrollCss = {
    '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--wmGrayLight)',
        border: '2px solid var(--wmWhite)',
        borderRadius: '4px',
    },

    '&::-webkit-scrollbar-track': {
        backgroundColor: 'var(--wmWhite)',
        borderRadius: '4px',
    },
};

export { styledScrollCss };
