import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BulkUsersMutation from './bulk-users-mutation';
import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { Users, WMPendingUserRequestItem, WMPendingUserRequestStatus } from 'wm-accounts-sdk';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { updatePendingUsersStatus } from '@walkme-admin-center/libs/state-management-users';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EmptySearch } from '@walkme-admin-center/libs/ui-components';
import { Box, Grid } from '@material-ui/core';
import { Divider, CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import {
    NoResultsDivider,
    NoResultsGrid,
    NoResultsImageGrid,
    NoResultsLoaderGrid,
    NoResultsTitle,
    NoResultsTitleGrid,
} from '../styles/styles';

export const PendingUsersTab = ({ loadingData, pageData }) => {
    const { t } = useTranslation('general');
    const search = useLocation().search;
    const redirectUrlParam = new URLSearchParams(search).get('redirectTo');
    const pendingUsersAppData = pageData as WMPendingUserRequestItem[];
    const pendingUsers = pendingUsersAppData.filter((item) => item.status === WMPendingUserRequestStatus.pending);
    const [openUserSuccessSnackbar, setOpenUserSuccessSnackbar] = useState('');
    const dispatch = useDispatch();

    const handleSnackBarUserCreationClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenUserSuccessSnackbar('');
    };

    const handleOnEmailRemoved = useCallback(
        (email: string) => {
            const pendingRequestToRemove = pendingUsers.find((pendingUser) => pendingUser.requestedUserEmail === email);
            if (pendingRequestToRemove) {
                dispatch(
                    updatePendingUsersStatus([
                        {
                            requestId: pendingRequestToRemove.requestId,
                            status: WMPendingUserRequestStatus.declined,
                        },
                    ])
                );
            }
        },
        [pendingUsers]
    );

    const handleOnFinish = useCallback(
        (successMessage: string, createdUsers: Users) => {
            setOpenUserSuccessSnackbar(successMessage);
            if (redirectUrlParam) {
                window.location.replace(redirectUrlParam);
            }
        },
        [redirectUrlParam]
    );

    return (
        <div>
            {pendingUsers.length > 0 ? (
                <BulkUsersMutation
                    showError={(error: string) => {}}
                    fullScreen={true}
                    hideCancelButton={true}
                    wideFormFields={true}
                    showOnlyAdvancedSettings={true}
                    onFinish={handleOnFinish}
                    onRemoveEmail={handleOnEmailRemoved}
                    disableUserInviteInput={true}
                    deleteMenuView={true}
                    suggestedUsers={pendingUsers}
                    checkboxView={true}
                    titleText={t('users-and-roles-tab.users.pending-users-tab.title')}
                    sendButtonText={t('buttons.save-and-approve')}
                />
            ) : (
                <NoResultsGrid container direction='column' alignItems='stretch' justifyContent='flex-start'>
                    <NoResultsTitleGrid item xs={12}>
                        <NoResultsTitle>{t('users-and-roles-tab.users.pending-users-tab.title')}</NoResultsTitle>
                        <NoResultsDivider />
                    </NoResultsTitleGrid>
                    {loadingData ? (
                        <NoResultsLoaderGrid item xs={12}>
                            <Box mb={4} display='flex' justifyContent='center'>
                                <CircularProgress size={50} thickness={4} />
                            </Box>
                        </NoResultsLoaderGrid>
                    ) : (
                        <NoResultsImageGrid item xs={12}>
                            <EmptySearch
                                title={t('users-and-roles-tab.users.pending-users-tab.empty-table-title')}
                                text={t('users-and-roles-tab.users.pending-users-tab.empty-table-text')}
                            />
                        </NoResultsImageGrid>
                    )}
                </NoResultsGrid>
            )}
            <WMSnackbar
                open={openUserSuccessSnackbar ? true : false}
                onClose={handleSnackBarUserCreationClose}
                variant={WMSnackbarVariant.Success}
                message={openUserSuccessSnackbar}
            />
        </div>
    );
};

export default PendingUsersTab;
