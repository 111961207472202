import { useSelector } from 'react-redux';
import { ProcessStatus, SelfHostedState } from '../self-hosted-state';

const useSaveSettingsStatus = () => {
    const status = useSelector((state: { selfHostedState: SelfHostedState }) => state.selfHostedState.settingsSaveStatus);

    const isInProgress = status === ProcessStatus.IN_PROGRESS;

    return { isInProgress, status };
};

export { useSaveSettingsStatus };
