import React from 'react';
import {
    BoldText,
    StepContainer,
    SubtitleText,
    TitleContainer,
    TitleText,
    VideoStepNumber,
} from '../../../slack-form/steps/manifest-step/slack-steps/slack-steps-styles';
import { retriveTeamsStepVideo } from '@walkme-admin-center/libs/common';
import VideoContainer from '../../../slack-form/steps/manifest-step/slack-steps/video-container';

const CreateAzureBot = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>2.</VideoStepNumber>Create new <BoldText>Azure Bot</BoldText>
                </TitleText>
                <SubtitleText>Go to ‘Create a resource’ and search ‘azure bot’</SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveTeamsStepVideo(1)} />
        </StepContainer>
    );
};

export default CreateAzureBot;
