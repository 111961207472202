import styled from 'styled-components';

export const CardTitleContainerWrapper = styled.div`
    display: flex;
    flex-direction: columm;
`;

export const CardTitleContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
`;

export const CardTitleText = styled.p`
    margin: 0;
    padding: var(--LS-Spacers-Generation-SP2, 0.125rem) 0rem;
    color: var(--Color-Semantic-Text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const CardSubtitleText = styled.p`
    margin: 0;
    padding-top: 0.125rem;
    color: var(--Color-Semantic-Text-secondary, #637191);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const Badge = styled.div`
    display: flex;
    padding: 0.375rem;
    border-radius: var(--ls-corner-radus-generation-cr-10099999, 6249.9375rem);
    background: var(--LS-Color-Action-Bg-Secondary-active, #f5f7fe);
    justify-content: center;
    align-items: center;
`;

export const BadgeText = styled.p`
    margin: 0;
    color: var(--LS-Color-Action-Content-Secondary-active, #385feb);
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.5rem;
`;

export const UrlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
    border-top: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #e3e9fc);
`;

export const UrlContainer = styled.div`
    display: flex;
    height: 3rem;
    background: var(--LS-Color-Text-On-dark-primary-inverted, #fff);
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    transition: color 0.3s ease;
    color: var(--LS-Color-Text-On-light-secondary, #637191);
    cursor: pointer;

    svg [stroke] {
        stroke: #637191;
        transition: stroke 0.3s ease;
    }

    .hover-only {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        color: var(--LS-Color-Bg-accent, #385feb);

        svg [stroke] {
            stroke: #385feb;
        }

        .hover-only {
            opacity: 1;
        }
    }

    & + & {
        border-top: 1px solid #f0f3f9;
    }
`;

export const UrlText = styled.p`
    flex: 1 1 auto;
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 0; // required for text overflow ellipsis to work
`;
