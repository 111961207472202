import React from 'react';
import { RuleBoldText, RuleTipContainer, ToolTipBody, ToolTipHeader } from './rules-style';

const RuleTip = ({ integrationType }) => {
    return (
        <RuleTipContainer>
            <ToolTipHeader>Wildcard Rules for {integrationType} Channels</ToolTipHeader>
            <ToolTipBody>
                Use the asterisk (*) to create a wildcard rule for Slack channel names. Determine the name you want to match and
                <RuleBoldText> use the asterisk (*) to represent any unknown characters.</RuleBoldText>
                This creates a flexible rule that can match multiple channel names. For example,<RuleBoldText>
                    {' '}
                    "marketing-*"
                </RuleBoldText>{' '}
                matches all channels starting with "marketing-".
            </ToolTipBody>
            <ToolTipBody>
                All channels that do not comply with the rules, will be automatically denied. No worries, you can always grant access
                manually in the Identified Channels table.
            </ToolTipBody>
        </RuleTipContainer>
    );
};

export default RuleTip;
