import { WMIconProps } from '@walkme/wm-ui';
import React from 'react';

export function WMIconLogo({ ...otherProps }: WMIconProps): JSX.Element {
    return (
        <svg
            {...otherProps}
            xmlns="http://www.w3.org/2000/svg"
            width="104.878"
            height="34.859"
            viewBox="0 0 104.878 34.859"
        >
            <g id="walkme_logo" transform="translate(0 0)">
                <g id="Integrating-Alert" transform="translate(-135 -301)">
                    <g id="Alert" transform="translate(53 281)">
                        <g id="LOGO-WALKME" transform="translate(82 20)">
                            <g id="Page-1">
                                <path
                                    id="Fill-1"
                                    d="M20.644,8.025v8.522A6.824,6.824,0,0,1,19.07,21.3a5.414,5.414,0,0,1-4.228,1.744,5.442,5.442,0,0,1-4.513-2.27,5.986,5.986,0,0,1-2.024,1.7,5.476,5.476,0,0,1-2.489.571A5.427,5.427,0,0,1,1.582,21.29,6.81,6.81,0,0,1,0,16.548V8.025H2.623v8.989A3.572,3.572,0,0,0,3.5,19.531a2.994,2.994,0,0,0,2.316.955,3.038,3.038,0,0,0,2.324-.962,3.527,3.527,0,0,0,.9-2.51V8.025h2.609v8.989a3.574,3.574,0,0,0,.877,2.517,3,3,0,0,0,2.316.955,3,3,0,0,0,2.3-.962,3.545,3.545,0,0,0,.892-2.51V8.025Z"
                                    fill="#231f20"
                                />
                                <path
                                    id="Fill-3"
                                    d="M33.464,22.771v-7.35a5.361,5.361,0,0,0-1.3-3.75,4.367,4.367,0,0,0-3.388-1.436,4.442,4.442,0,0,0-3.4,1.42,5.148,5.148,0,0,0-1.327,3.66,5.259,5.259,0,0,0,1.364,3.743,4.555,4.555,0,0,0,3.478,1.458,5.4,5.4,0,0,0,1.867-.331,7.029,7.029,0,0,0,1.822-1.022V22a7.478,7.478,0,0,1-1.9.759,8.639,8.639,0,0,1-2.091.248,6.953,6.953,0,0,1-2.084-.323,6.777,6.777,0,0,1-1.874-.909,7.369,7.369,0,0,1-2.429-2.743,7.935,7.935,0,0,1-.839-3.66A7.543,7.543,0,0,1,23.45,9.912a7.043,7.043,0,0,1,5.292-2.157A7.3,7.3,0,0,1,32.61,8.822a7.136,7.136,0,0,1,2.7,2.931,6.676,6.676,0,0,1,.622,1.841,17.531,17.531,0,0,1,.187,2.984v6.193Z"
                                    fill="#231f20"
                                />
                                <path id="Fill-5" d="M37.649,22.77h2.638V1.247H37.649Z" fill="#231f20" />
                                <path
                                    id="Fill-7"
                                    d="M42.074,22.771V1.247h2.638v12.13h1.169a3.3,3.3,0,0,0,1.837-.466,5.721,5.721,0,0,0,1.537-1.834l1.874-3.051H54.2l-2.159,3.5a10.26,10.26,0,0,1-1.124,1.563,3.888,3.888,0,0,1-1.049.872,4.155,4.155,0,0,1,2.676,1.5,5.338,5.338,0,0,1,.862,3.254v4.058H50.754V19.043a3.332,3.332,0,0,0-.7-2.337,2.906,2.906,0,0,0-2.211-.744H44.712v6.809Z"
                                    fill="#231f20"
                                />
                                <path
                                    id="Fill-8"
                                    d="M94.847,0H65.063A10.044,10.044,0,0,0,55.032,10.057v9.7A10.044,10.044,0,0,0,65.063,29.814h7.716l7.066,5.045,7.066-5.045h7.937a10.044,10.044,0,0,0,10.031-10.057v-9.7A10.044,10.044,0,0,0,94.847,0"
                                    fill="#3394d1"
                                />
                                <path
                                    id="Fill-9"
                                    d="M61.866,22.445V13.923a6.825,6.825,0,0,1,1.574-4.75A5.433,5.433,0,0,1,67.683,7.43a5.545,5.545,0,0,1,2.519.579A5.675,5.675,0,0,1,72.2,9.7,5.843,5.843,0,0,1,74.2,8a5.506,5.506,0,0,1,2.5-.571,5.409,5.409,0,0,1,4.22,1.751,6.811,6.811,0,0,1,1.582,4.742v8.522H79.9V13.427a3.509,3.509,0,0,0-.884-2.5,3.289,3.289,0,0,0-4.618,0,3.509,3.509,0,0,0-.885,2.5v9.018H70.906V13.427a3.474,3.474,0,0,0-.9-2.5,3.063,3.063,0,0,0-2.324-.947,3.009,3.009,0,0,0-2.316.947,3.528,3.528,0,0,0-.877,2.5v9.018Z"
                                    fill="#fff"
                                />
                                <path
                                    id="Fill-10"
                                    d="M98.044,15.922a8.555,8.555,0,0,1-2.549,4.99,6.915,6.915,0,0,1-4.858,1.834,7.2,7.2,0,0,1-1.926-.263,7.017,7.017,0,0,1-1.791-.774,7.826,7.826,0,0,1-3.156-9.575A7.87,7.87,0,0,1,85.39,9.639,7.259,7.259,0,0,1,90.668,7.4a7.018,7.018,0,0,1,4,1.18,8.45,8.45,0,0,1,2.856,3.435l-9.64,5.351-1.11-2,7.151-4a4.493,4.493,0,0,0-1.484-1.112,4.293,4.293,0,0,0-1.8-.376A4.347,4.347,0,0,0,87.3,11.36a5.214,5.214,0,0,0-1.357,3.66,5.2,5.2,0,0,0,1.364,3.652,4.528,4.528,0,0,0,6.469.248,5.764,5.764,0,0,0,1.581-3.4Z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
