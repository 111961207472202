import { EnvironmentSettings } from '../types/environment-settings';
import { DeploymentMethod, DeploymentMethodOption } from '../types/deployment-method';
import { useEffect, useMemo, useState } from 'react';

const extensionOption = { value: DeploymentMethod.EXTENSION, label: 'Extension' };
const snippetOption = { value: DeploymentMethod.SNIPPET, label: 'Snippet' };

const useDeploymentSelection = (settings: EnvironmentSettings) => {
    const { isActive, linkedToExtension } = settings?.deployment || {};
    const [currentDeploymentMethod, setCurrentDeploymentMethod] = useState({ value: null, label: '' });

    useEffect(() => {
        setCurrentDeploymentMethod(getDefaultDeploymentMethod(linkedToExtension, isActive));
    }, [isActive, linkedToExtension]);

    const availableDeploymentMethods = useMemo(() => getAvailableDeploymentMethods(linkedToExtension), [linkedToExtension]);

    return {
        currentMethod: currentDeploymentMethod,
        setCurrentMethod: setCurrentDeploymentMethod,
        availableMethods: availableDeploymentMethods,
    };
};

const getDefaultDeploymentMethod = (linkedToExtension: boolean, isExtensionActive: boolean): DeploymentMethodOption => {
    let result = snippetOption;

    if (linkedToExtension) {
        result = isExtensionActive ? extensionOption : snippetOption;
    }

    return result;
};

const getAvailableDeploymentMethods = (linkedToExtension: boolean): DeploymentMethodOption[] => {
    return linkedToExtension ? [snippetOption, extensionOption] : [snippetOption];
};

export default useDeploymentSelection;
