import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopPredefinedAppsState, getDesktopPredefinedApps } from '../redux';

export const useDesktopPredefinedApps = () => {
    const desktopPredefinedAppsData = useSelector(
        (state: { desktopPredefinedAppsState: DesktopPredefinedAppsState }) => state.desktopPredefinedAppsState.desktopPredefinedApps
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDesktopPredefinedApps());
    }, [dispatch]);

    return {
        desktopPredefinedAppsData,
    };
};

export default useDesktopPredefinedApps;
