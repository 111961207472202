import React, { useEffect, useMemo } from 'react';
import { retrieveAiWelcomeImage } from './service';
import styled from 'styled-components';
import { WMButton } from '@walkme/wm-ui';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { AiFeatureFlags } from '../../../data-integrations/src/lib/common/consts';
import AiCenterOnboarding from './components/ai-center-onboarding/ai-center-onboarding';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ImageContainer = styled.div`
    position: relative;
`;
export const GetAccessButton = styled(WMButton)`
    position: absolute;
    bottom: 48px;
    right: 340px;
    height: 40px;
    width: 143px;
    border-radius: 40px;
`;

const AiEmptyState = ({ navigate }) => {
    const { t } = useTranslation('general');
    const { accountFeatureEnabled } = useLoggedInUser();
    const isFeatureEnabled = useMemo(() => (feature) => accountFeatureEnabled(feature), [accountFeatureEnabled]);

    useEffect(() => {
        document.title = t('ai-center-tab.document-title');
    }, []);

    useEffect(() => {
        if (isFeatureEnabled(AiFeatureFlags.ENABLE_AI_USAGE)) {
            navigate('/ai-usage');
        } else if (isFeatureEnabled(AiFeatureFlags.ENABLE_ASK_ME) || isFeatureEnabled(AiFeatureFlags.AI_CENTER_ON_DEMAND)) {
            navigate('/ai-settings');
        }
    }, [isFeatureEnabled]);

    return (
        <div className='noPadding' style={{ display: 'flex' }}>
            <AiCenterOnboarding />
        </div>
    );
};

export default AiEmptyState;
