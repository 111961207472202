import { Box, Typography, styled } from '@material-ui/core';
import { Toaster, ToasterVariant } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization';
import React, { useContext } from 'react';
import { CounterBadge, StyledLink } from './common';
import { URLVerifierDialogContext } from './url-verifier-dialog';
import { EnvironmentURLQueryResult } from './values';

interface EnvironmentsResultProps {
    verificationResults: EnvironmentURLQueryResult[];
}

interface EnvironmentMatchResultProps {
    matchResult: EnvironmentURLQueryResult;
}

const StyledEnvironmentCard = styled('div')({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    borderRadius: '8px',
    border: '1px solid #E4E9FC',
    marginBottom: '8px',
    cursor: 'pointer',
    '&:hover': {
        border: '1px solid #92A7F4',
    },
});

const StyledWMIconArrowRight = <img src='/assets/icons/arrow-right.svg' style={{ width: '10px', height: '10px' }} />;

const StyledWMNoResults = <img src='/assets/icons/url-verifier-no-results.svg' style={{ width: '120px', height: '120px' }} />;

const EnvironmentsResult: React.FC<EnvironmentsResultProps> = ({ verificationResults }) => {
    const { t } = useTranslation('general');
    const resultCount = Object.keys(verificationResults).length;

    return (
        <>
            {resultCount > 1 ? <MultipleMatchesWarning /> : null}
            {resultCount > 0 ? (
                <Box width='100%' display='flex' flexDirection='column' alignItems='start'>
                    <Box width='100%' display='flex' flexDirection='row' alignItems='center' marginBottom='10px' marginTop='10px'>
                        <Typography style={{ fontFamily: 'Proxima nova', fontSize: '14px', fontWeight: 600, color: '#2F426C' }}>
                            {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.env-result-title`)}
                        </Typography>
                        <CounterBadge count={resultCount} />
                    </Box>
                    {verificationResults.map((result, index) => (
                        <EnvironmentMatchResult key={`${result.envName}-${index}`} matchResult={result} />
                    ))}
                </Box>
            ) : (
                <NoMatchFound />
            )}
        </>
    );
};

const MultipleMatchesWarning = () => {
    const { t } = useTranslation('general');

    return (
        <Box width='100%' margin='20px 0 8px 0'>
            <Toaster
                variant={ToasterVariant.Warning}
                width='auto'
                message={
                    <>
                        {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.multiple-matches-warning`)}
                        <StyledLink href='https://support.walkme.com/knowledge-base/extension-settings/#important-note' target='_blank'>
                            {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.learn-more`)}
                        </StyledLink>
                    </>
                }
            />
        </Box>
    );
};

const NoMatchFound = () => {
    const { t } = useTranslation('general');

    return (
        <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
            {StyledWMNoResults}
            <Typography style={{ fontFamily: 'Proxima nova', fontSize: '16px', fontWeight: 600 }}>
                {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.no-matches-warning-title`)}
            </Typography>
            <Typography style={{ fontFamily: 'Proxima nova', fontSize: '14px', fontWeight: 400 }}>
                {t(`systems-tab.all-systems-page.extension-page.open-url-verifier.no-matches-warning`)}
            </Typography>
        </Box>
    );
};

const EnvironmentMatchResult: React.FC<EnvironmentMatchResultProps> = ({ matchResult }) => {
    const { t } = useTranslation('general');
    const { setSelectedEnvironment, setShowEnvironmentResult } = useContext(URLVerifierDialogContext);
    const matchCount = matchResult.urlMatchData.filter((domain) => domain.isMatching).length;
    const onClick = () => {
        setSelectedEnvironment(matchResult);
        setShowEnvironmentResult(true);
    };

    return (
        <StyledEnvironmentCard onClick={onClick}>
            <Box display='flex' flexDirection='column' alignItems='start'>
                <Box display='flex' flexDirection='row'>
                    <Typography style={{ fontFamily: 'Proxima nova', fontSize: '14px', fontWeight: 600, color: '#2F426C' }}>
                        {matchResult.envName}
                    </Typography>
                    {!!matchResult.configurationSet ? (
                        <Typography
                            style={{
                                fontFamily: 'Proxima nova',
                                fontSize: '14px',
                                fontWeight: 400,
                                marginLeft: '5px',
                                color: '#2F426C',
                            }}>
                            {`(${t(`systems-tab.all-systems-page.extension-page.open-url-verifier.configuration-set`)} ${
                                matchResult.configurationSet
                            })`}
                        </Typography>
                    ) : null}
                </Box>
                <Typography style={{ fontFamily: 'Proxima nova', fontSize: '12px', fontWeight: 400, color: '#637191' }}>
                    {matchCount} match{matchCount > 1 ? 'es' : null} found
                </Typography>
            </Box>
            {StyledWMIconArrowRight}
        </StyledEnvironmentCard>
    );
};

export { EnvironmentsResult };
