import React, { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import { System } from 'wm-accounts-sdk';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { MenuItemText, Button } from '@walkme/ui-core';
import { WMButtonVariant, WMDivider, WMIconDownload, WMIconSettings, WMSnackbar, WMSnackbarVariant, WMIconArrowDown } from '@walkme/wm-ui';
import { Platform } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/systems-types';
import {
    StyledDialog,
    StyledEnvironmentSelectContainer,
    StyledInstructionsContainer,
    StyledSettingsButton,
    StyledTitleRow,
    StyledMenuItem,
    StyledMenu,
} from './self-hosted-package-download-style';
import { SystemInfo } from '../common/system-info';
import { downloadPackage, selfHostedSlice } from '../../redux/self-hosted.slice';
import { DownloadPackageDto, PackageType } from '../../types/download-package.dto';
import useDownloadPackage from '../../redux/hooks/use-download-package';
import { ProcessStatus } from '../../redux/self-hosted-state';
import useEnvironmentSelection from '../../hooks/useEnvironmentSelection';
import { SkippableConfirmationDialog } from '../common/skippable-confirmation-dialog';
import { StyledCancelButton, StyledWMSelect } from '../common/styled-components';
import { SelfHostedEnvSettings } from '../self-hosted-env-settings/sefl-hosted-env-settings';
import { usePreference } from '../../redux/hooks/use-preference';
import { PreferenceKey } from '../../consts';
import { PlatformOptions } from '../../types/platform-options.type';
import useEnvironmentsBySystem from '../../redux/hooks/use-environments-by-system';

interface SelfHostedPackageDownloadProps {
    open: boolean;
    onClose: () => void;
    system: System;
    env?: {
        value: number;
        label: string;
    };
    onPackageDownloaded?: () => void;
    mobileWebPlatform?: Platform;
}

const SelfHostedPackageDownload = ({
    open,
    onClose,
    system,
    env,
    onPackageDownloaded,
    mobileWebPlatform,
}: SelfHostedPackageDownloadProps) => {
    const { environmentOptions, selectedEnvOption, setSelectedEnvOption } = useEnvironmentSelection(system, env);
    const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
    const [showDownloadInstructions, setShowDownloadInstructions] = useState(false);
    const [showEnvSettings, setShowEnvSettings] = useState(false);
    const dispatch = useDispatch();
    const { isInProgress, status } = useDownloadPackage();
    const { environments: mobileWebEnvironments } = useEnvironmentsBySystem(mobileWebPlatform ? mobileWebPlatform.id : null);
    const [shouldSkipInstructions, setShouldSkipInstructions] = usePreference(PreferenceKey.SKIP_INSTRUCTIONS);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isEnvInMobileWeb, setIsEnvInMobileWeb] = useState(false);
    const [downloadMobileWeb, setDownloadMobileWeb] = useState(false);

    const isContentOnlyPackageEnabled = system.featureFlags.some((ff) => ff.flagName === 'selfHostedContentOnly');

    useEffect(() => {
        if (status === ProcessStatus.SUCCESS) {
            dispatch(selfHostedSlice.actions.clearDownloadPackageStatus());
            onPackageDownloaded && onPackageDownloaded();
        } else if (status === ProcessStatus.ERROR) {
            setShowErrorSnackBar(true);
            dispatch(selfHostedSlice.actions.clearDownloadPackageStatus());
        }
    }, [status, dispatch]);

    const callDownloadPackage = (systemId: number, envId: number) => {
        const packagePayload: DownloadPackageDto = {
            systemId: systemId,
            userId: systemId,
            envId: envId,
            packageType: isContentOnlyPackageEnabled ? PackageType.CONTENT_ONLY : PackageType.FULL,
        };

        dispatch(downloadPackage(packagePayload));
    };

    useEffect(() => {
        if (mobileWebPlatform && mobileWebEnvironments?.length > 0) {
            setIsEnvInMobileWeb(mobileWebEnvironments.some((environment) => environment.Name === env.label));
        }
    }, [system, mobileWebPlatform, mobileWebEnvironments]);

    const handleDownloadClickedWithMobile = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const downloadMobileWebPackage = () => {
        const mobileEnv = mobileWebEnvironments.find((environment) => environment.Name === env.label);
        callDownloadPackage(mobileWebPlatform.id, mobileEnv.Id);
    };

    const handleDownloadClicked = (isMobileWeb: boolean) => {
        handleCloseDownloadMenu();
        if (!shouldSkipInstructions) {
            if (isMobileWeb) {
                setDownloadMobileWeb(true);
            } else {
                setDownloadMobileWeb(false);
            }
            setShowDownloadInstructions(true);
        } else {
            if (isMobileWeb) {
                downloadMobileWebPackage();
            } else {
                callDownloadPackage(system.id, selectedEnvOption.value);
            }
        }
    };

    const handleInstructionsDownloadClicked = (skipInstructions: boolean) => {
        setShowDownloadInstructions(false);

        setShouldSkipInstructions(skipInstructions);

        if (downloadMobileWeb) {
            downloadMobileWebPackage();
        } else {
            callDownloadPackage(system.id, selectedEnvOption.value);
        }
        setDownloadMobileWeb(false);
    };

    const handleClose = () => {
        if (!isInProgress) {
            onClose();
        }
    };

    const handleSnackBarErrorClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') return;
        setShowErrorSnackBar(false);
    };

    const handleCloseDownloadMenu = () => {
        setAnchorEl(null);
    };

    return (
        <StyledDialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                <StyledTitleRow>
                    <Typography className='dialogTitle-titleTxt'>{`${env.label} self hosted package`}</Typography>
                    <IconButton className='dialogTitle-closeBtn' disabled={isInProgress} onClick={handleClose}>
                        <img src='assets/icons/close.svg' alt='close' />
                    </IconButton>
                </StyledTitleRow>
                <WMDivider />
                <SystemInfo className='dialogTitle-systemInfo' system={system} hasMobileWeb={isEnvInMobileWeb} />
                <WMDivider />
            </DialogTitle>
            <DialogActions>
                <StyledSettingsButton
                    variant={WMButtonVariant.Text}
                    iconComponent={<WMIconSettings />}
                    disabled={isInProgress}
                    onClick={() => setShowEnvSettings(true)}>
                    Settings
                </StyledSettingsButton>
                <Box>
                    <StyledCancelButton variant={WMButtonVariant.Text} disabled={isInProgress} onClick={handleClose}>
                        Cancel
                    </StyledCancelButton>
                    <Button
                        id='download-confirm-btn'
                        style={{ backgroundColor: Boolean(anchorEl) ? '#223786' : null }}
                        size='large'
                        loading={isInProgress}
                        variant='solid'
                        startIcon={<WMIconDownload />}
                        endIcon={isEnvInMobileWeb ? <WMIconArrowDown /> : null}
                        onClick={isEnvInMobileWeb ? handleDownloadClickedWithMobile : () => handleDownloadClicked(false)}>
                        {isInProgress ? 'Downloading' : 'Download'}
                    </Button>
                    <StyledMenu onClose={handleCloseDownloadMenu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
                        <StyledMenuItem onClick={() => handleDownloadClicked(false)} key={PlatformOptions.WEB.value}>
                            <MenuItemText>Web Package</MenuItemText>
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleDownloadClicked(true)} key={PlatformOptions.MOBILE_WEB.value}>
                            <MenuItemText>Mobile Web Package</MenuItemText>
                        </StyledMenuItem>
                    </StyledMenu>
                </Box>
            </DialogActions>

            <SkippableConfirmationDialog
                open={showDownloadInstructions}
                onClose={() => setShowDownloadInstructions(false)}
                onConfirm={handleInstructionsDownloadClicked}
                title='Instructions'
                confirmLabel='Download'
                confirmIcon={<WMIconDownload />}>
                <StyledInstructionsContainer>
                    <ol>
                        <li>Press "Download" to download the zip file</li>
                        <li>Unzip the downloaded file into the location where your WalkMe package is hosted</li>
                    </ol>
                </StyledInstructionsContainer>
            </SkippableConfirmationDialog>
            <SelfHostedEnvSettings
                open={showEnvSettings}
                onClose={() => setShowEnvSettings(false)}
                system={system}
                envId={selectedEnvOption.value}
                envName={selectedEnvOption.label}
                hasMobileWeb={isEnvInMobileWeb}
            />
            <WMSnackbar
                variant={WMSnackbarVariant.Error}
                open={showErrorSnackBar}
                onClose={handleSnackBarErrorClose}
                message='Download failed. Please try again later or contact WalkMe Support.'
            />
        </StyledDialog>
    );
};

export { SelfHostedPackageDownload };
