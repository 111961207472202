import axios, { AxiosInstance } from 'axios';
import { WMAuthManager } from 'wm-accounts-auth';

export interface IAccessTokenGetter {
    getUserToken: () => string;
    getAuthorizationHeaderValue: () => string;
}

export class WMhttpClient {
    baseUrl: string;
    tokenGetter: IAccessTokenGetter;
    readonly instance: AxiosInstance;
    constructor(baseUrl: string, accssTokenGetter?: IAccessTokenGetter) {
        // TODO WMAuthManager should be injected from the constructor
        // use default authManager if interface not provided
        this.tokenGetter = accssTokenGetter || {
            getUserToken(): string {
                return WMAuthManager.getInstance().getUserToken();
            },
            getAuthorizationHeaderValue(): string {
                return WMAuthManager.getInstance().getAuthorizationHeaderValue();
            },
        };
        this.baseUrl = baseUrl;
        this.instance = axios.create({
            baseURL: this.baseUrl,
        });

        // this.initializeResponseInterceptor();
        this.initializeRequestInterceptor();
    }

    private initializeRequestInterceptor() {
        this.instance.interceptors.request.use(
            async (config) => {
                const accessToken = this.tokenGetter.getUserToken();
                if (accessToken && !config.headers.authorization) {
                    config.headers['Authorization'] = this.tokenGetter.getAuthorizationHeaderValue();
                }
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    // private initializeResponseInterceptor = () => {
    //     this.instance.interceptors.response.use(this.handleResponse, this.handleError);
    // };

    // private handleResponse = ({ data }: AxiosResponse) => data;

    // handleError = (error: any) => Promise.reject(error);
}

export const wmhttpClientSharedInstance = new WMhttpClient(window.clientConfig.NX_WM_API_URL);
