import { User, Provider } from '../types';

export interface ProviderSystem {
    id: number;
    Provider: Provider;
    User: User;
    active: boolean;
    createdAt: string;
    endUserMappingId?: string;
    providerId: number;
    updatedAt: string;
    userId: number;
}

export declare type ProviderSystems = ProviderSystem[]
