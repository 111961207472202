export function useUserTabData() {
    const notificationsMessagesKeys = {
        UserCreatedWithVerificationMailMessage: 'notifications.user-created-with-mail',
        UserCreatedMessage: 'notifications.user-created',
        UserDeletedMessage: 'notifications.user-deleted',
        UsersDeletedMessage: 'notifications.users-deleted',
        EmailVerificationMessage: 'notifications.email-verification-sent',
        ResetMfaMessage: 'notifications.reset-mfa',
        PermissionsErrorText: 'notifications.permission-error-text',
    };

    return { notificationsMessagesKeys };
}
