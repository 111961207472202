import React, { SVGProps } from 'react';

export const PuzzlePartIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            id='Vector 405'
            d='M0.5 9V4C0.5 3.72386 0.723858 3.5 1 3.5H5.8C6.1866 3.5 6.5 3.1866 6.5 2.8V2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2V2.8C9.5 3.1866 9.8134 3.5 10.2 3.5H14C14.2761 3.5 14.5 3.72386 14.5 4V7.8C14.5 8.1866 14.8134 8.5 15.2 8.5H16C16.8284 8.5 17.5 9.17157 17.5 10C17.5 10.8284 16.8284 11.5 16 11.5H15.2C14.8134 11.5 14.5 11.8134 14.5 12.2V17C14.5 17.2761 14.2761 17.5 14 17.5H9C8.72386 17.5 8.5 17.2761 8.5 17V16C8.5 15.1716 7.82843 14.5 7 14.5C6.17157 14.5 5.5 15.1716 5.5 16V17C5.5 17.2761 5.27614 17.5 5 17.5H1C0.723858 17.5 0.5 17.2761 0.5 17V13C0.5 12.7239 0.723858 12.5 1 12.5H2C2.82843 12.5 3.5 11.8284 3.5 11C3.5 10.1716 2.82843 9.5 2 9.5H1C0.723858 9.5 0.5 9.27614 0.5 9Z'
            stroke='#6D81A6'
        />
    </svg>
);
