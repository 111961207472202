import styled from 'styled-components';
import { Dialog } from '@walkme-admin-center/libs/common';

export const DialogTitle = styled.span`
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    color: #2f426c;
`;

export const DialogInfo = styled.span`
    font-family: 'ProximaNova';
    color: #7680a3;
    font-size: 14px;
    font-weight: 400;
`;

export const RoleTitle = styled.span`
    font-family: 'ProximaNova';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2f426c;
`;

export const RoleInfo = styled.span`
    font-family: 'ProximaNova';
    font-weight: 400;
    color: #8d97ae;
    font-size: 12px;
    line-height: 16px;
`;

export const TableHeader = styled.span`
    font-family: 'ProximaNova';
    font-size: 12px;
    font-weight: 400;
`;

export const StyledDialog = styled(Dialog)`
    min-width: 45%;
    max-height: 100%;
`;
