import { PredefinedDesktopIdentifier } from "./predefined-desktop-identifier"

export interface PredefinedDesktopTrackedApp {
    id: string;
    systemName: string;
    icon: string;
    predefinedDesktopIdentifiers: Array<PredefinedDesktopIdentifier>
}

export type PredefinedDesktopTrackedApps = PredefinedDesktopTrackedApp[];
