import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        url: {
            marginBottom: '10px',
        },
        icon: {
            cursor: 'pointer',
        },
    })
);

export { useStyles };
