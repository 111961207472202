import styled from 'styled-components';
import { NoResultsIcon } from '../assets/icons/no-results';

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 45vh;
    margin-bottom: 2px;

    .empty-state-main-block {
        display: flex;
        flex: 0.9;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            padding: 14px 0;
        }

        .description-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Typography-Typography, #2f426c);
            width: 30%;
            text-align: center;

            .main-description {
                font-weight: 600;
                font-size: 20px;
            }

            .sub-description {
                margin-top: 2px;
                font-size: 16px;
            }
        }
    }
`;
