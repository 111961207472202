import { useSelector } from 'react-redux';
import { DataIntegrationState } from '../redux/data-integration.slice';

export const useAskMe = () => {
    const askMeIntegrationsData = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.askMeIntegrations;
    });

    return {
        askMeIntegrationsData,
    };
};

export default useAskMe;
