import { useSelector } from 'react-redux';
import { ProcessStatus, SelfHostedState } from '../self-hosted-state';

const useDownloadPackage = () => {
    const status = useSelector((state: { selfHostedState: SelfHostedState }) => state.selfHostedState.packageDownloadStatus);

    const isInProgress = status === ProcessStatus.IN_PROGRESS;

    return { isInProgress, status };
};

export default useDownloadPackage;
