import React from 'react';
import styled from 'styled-components';

const WarningIconContainer = styled.div`
    position: relative;
    width: 1rem;
    height: 1rem;
`;

export const ErrorCircle = () => {
    return (
        <WarningIconContainer>
            <img
                src='/assets/icons/data-integrations/integrations-icons/error-circle.svg'
                style={{ width: '1rem', height: '1rem', position: 'absolute' }}
            />
            <img
                src='/assets/icons/data-integrations/integrations-icons/exclamation-mark.svg'
                style={{ width: '1rem', height: '1rem', position: 'absolute' }}
            />
        </WarningIconContainer>
    );
};
