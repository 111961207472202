import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
export interface SystemDeployableCountItem {
    systemId: number;
    deployables: Record<string, number>;
}

export interface DeployablesTypes {
    displayType: string;
    subType: number;
    type: number;
    value: string;
    iconComponent: string;
}

class CmtApi {
    private readonly httpClient: WMhttpClient;
    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
    }

    async getSystemsDeployablesCount(): Promise<SystemDeployableCountItem[]> {
        try {
            const response = await this.httpClient.instance.post('walkme-os-api/graphql', {
                query: `
            {
              accountDeployablesCount {
                systemId
                deployables
              }
            }
        `,
            });
            return (response.data.data && response.data.data.accountDeployablesCount) || [];
        } catch (e) {
            const message = (e.response && e.response.data && e.response.data.message) || 'Unspecified error (Deployables)';
            throw new Error(message);
        }
    }

    async deployablesTypes(): Promise<DeployablesTypes[]> {
        try {
            const response = await this.httpClient.instance.post('walkme-os-api/graphql', {
                query: `
              {
                Deployable_Data_deployableTypes{
                            displayType
                            type
                            subType
                            value
                            iconComponent
                          }	 
              }
            `,
            });
            return response.data.data && response.data.data.Deployable_Data_deployableTypes;
        } catch (e) {
            const message = (e.response && e.response.data && e.response.data.message) || 'Unspecified error (Deployables)';
            throw new Error(message);
        }
    }
}

export const cmtApi = new CmtApi(wmhttpClientSharedInstance);
