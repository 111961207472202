import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledBox = styled(Box)`
    padding: 60px;
    display: flex;
    background-color: #ffffff;
    border-radius: 10px;
    justify-content: center;
`;

const StyledTitleTypography = styled(Typography)`
    color: #303d53;
    font-size: 14px;
    font-weight: 600;
    font-family: 'ProximaNova';
    text-align: center;
`;

const StyledSubTitleTypography = styled(Typography)`
    color: #303d53;
    font-size: 14px;
    font-family: 'ProximaNova';
    text-align: center;
`;

export interface EmptySearchProps {
    title?: string;
    text?: string;
    textWithQuery?: string;
    query?: string;
}

export const EmptySearch = ({
    title = 'No results found',
    text = 'We couldn`t find any results for your search',
    textWithQuery = 'We couldn`t find any results for "{SEARCH_PLACEHOLDER}"',
    query,
}: EmptySearchProps) => {
    let messageText = text;
    if (query) {
        messageText = textWithQuery.replace('{SEARCH_PLACEHOLDER}', query);
    }
    return (
        <StyledBox>
            <Box>
                <Box mb={4} display='flex' justifyContent='center'>
                    <img src='/assets/No results.svg' />
                </Box>
                <StyledTitleTypography>{title}</StyledTitleTypography>
                <StyledSubTitleTypography>{messageText}</StyledSubTitleTypography>
            </Box>
        </StyledBox>
    );
};

export default EmptySearch;
