import styled from 'styled-components';
import { WMButton, WMChip } from '@walkme/wm-ui';

export const StyledUsersDiv = styled.div`
    width: 420px;
    height: 100%;
    .header-black {
        padding-left: 1px !important;
    }
`;

export const StyledCancelButton = styled(WMButton)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 90px;
    font-family: 'ProximaNova';
    box-shadow: none;
    background-color: transparent;
    border-color: #cedaeb;
    color: #6d81a6;
    text-transform: none;
    &:hover {
        border: 1px solid #c6c9ce;
        background-color: #f8f6f8;
    }
`;

export const StyledRemoveUsersButton = styled(WMButton)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 90px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #e1e9f5;
    background-color: #eb3232;
    color: #ffffff;
    text-transform: none;
    &:hover {
        background-color: #cc1616;
        opacity: 0.9;
    }
`;

export const StyledCreateButton = styled(WMButton)`
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    width: 82px;
    font-family: 'ProximaNova';
    box-shadow: none;
    border-color: #e1e9f5;
    background-color: #3b61eb;
    color: #ffffff;
    text-transform: none;
    &:hover {
        background-color: #1f46d1;
        opacity: 0.9;
    }
`;

export { default as ChevronLeft } from '../../../../../../../packages/libs/common/assets/icons/left-icon.svg';

export const StyledWMChip = styled(WMChip)`
    background-color: #edf1fd !important;
    color: #3b61eb !important;
    border: 1px solid #3b61eb;
    margin-left: 5px;
`;
