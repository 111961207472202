import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { EnvironmentData, PartialSystemData, SystemMatcher } from '@walkme-admin-center/libs/exposure-api';
import { analyticsApi } from '@walkme-admin-center/libs/analytics-api';
import { Environment, UpdateUserChangedValues } from './types';
import minBy from 'lodash/minBy';
import { Environments, Environment as AccountsEnvironment, System } from 'wm-accounts-sdk';

interface ConfigurationErrorDetails {
    title: string;
    message: string;
    image?: any;
}

const baseTitleStyling = {
    fontFamily: 'ProximaNova',
    color: '#2F426C',
    fontSize: '14px',
    fontWeight: 600,
};

const getTitleComponent = (text: string) => {
    return (
        <Typography
            style={{
                ...baseTitleStyling,
                marginRight: '10px',
                marginBottom: '15px',
            }}>
            {text}
        </Typography>
    );
};

const resetIsDefaultForMatchersIfNeeded = (systemData: PartialSystemData): void => {
    Object.keys(systemData.matchers).forEach((currentEnvId: string) => {
        const envId = Number(currentEnvId);
        systemData.matchers[envId].forEach((systemMatcher: SystemMatcher) => {
            const isRegexModeAndHasValue = systemMatcher.isRegex && systemMatcher.selfRegex !== '';
            const isNotRegexModeAndHasValues = !systemMatcher.isRegex && systemMatcher.selfDomains && systemMatcher.selfDomains.length > 0;

            if (systemMatcher.isDefault && (isRegexModeAndHasValue || isNotRegexModeAndHasValues)) {
                systemMatcher.isDefault = false;
                systemMatcher.isActive = true;
            }
        });
    });
};

const getIsMultipleMatchers = (systemData: PartialSystemData, envId: number | undefined): boolean => {
    if (envId === undefined || !systemData?.matchers || !systemData.matchers[envId]) {
        return false;
    }

    return systemData.matchers[envId].length > 1;
};

const getNotDevEnvironmentsFromSystemData = (systemData: PartialSystemData): Environment[] => {
    if (!systemData) {
        return [];
    }

    return Object.entries(systemData.environments) //We don’t want to show environment which flagged as Dev
        .filter(([_, envData]) => !envData.isDev)
        .map(([envId, envData]) => {
            const currentEnvId = Number(envId);
            const environmentData: EnvironmentData = envData;
            const envName = environmentData.name;
            const path = environmentData.path;
            const currentEnvData: Environment = { value: currentEnvId, label: envName, path };

            return currentEnvData;
        });
};

const getNotDevEnvironmentsFromAnalyticsAPI = async (systemId: number): Promise<Environment[]> => {
    const envs: Environments = await analyticsApi.getSystemEnvironments(systemId, true);
    const convertedEnvs: Environment[] = envs.map((env: AccountsEnvironment) => {
        return { label: env.name, value: env.id, path: env.path };
    });

    return convertedEnvs;
};

const getMinimalEnvById = (environments: Environment[]): Environment | undefined => {
    if (!environments || environments.length === 0) {
        return undefined;
    }

    return minBy(environments, (env: Environment) => env.value);
};

const getEnvById = (envId: number, environments: Environment[]): Environment | undefined =>
    environments.find((env: Environment) => env.value === envId);

const getChangedValuesToSave = (
    previousSystemData: PartialSystemData,
    newSystemData: PartialSystemData,
    selectedSystem: System
): UpdateUserChangedValues => {
    const changedValues: UpdateUserChangedValues = { extensionAvailabilityForSystem: [] };
    const previousMatchers = previousSystemData.matchers;
    const newMatchers = newSystemData.matchers;

    Object.keys(previousMatchers).forEach((envId) => {
        // TODO:Currently we are not supporting managing of multi environments per system, this is the reason we access the 0 index. Need to change when multi env will be supported.
        const currentEnvMatcher = previousMatchers[envId][0];
        const newEnvMatcher = newMatchers[envId]?.[0];

        if (currentEnvMatcher?.isActive !== newEnvMatcher?.isActive) {
            const environmentName = newSystemData.environments[envId]?.name;
            changedValues.extensionAvailabilityForSystem.push({
                systemName: selectedSystem.name,
                systemDisplayName: selectedSystem.displayName,
                environmentName,
                isActive: newEnvMatcher?.isActive,
            });
        }
    });

    return changedValues;
};

export {
    getTitleComponent,
    resetIsDefaultForMatchersIfNeeded,
    getIsMultipleMatchers,
    getNotDevEnvironmentsFromSystemData,
    getNotDevEnvironmentsFromAnalyticsAPI,
    getMinimalEnvById,
    getEnvById,
    ConfigurationErrorDetails,
    baseTitleStyling,
    getChangedValuesToSave,
};
