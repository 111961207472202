import React from 'react';
import { StyledButton } from '@walkme-admin-center/libs/shared-styles';
import {ButtonProps as ButtonBaseProps, CircularProgress} from '@material-ui/core';
import styled from 'styled-components';

interface ButtonProps {
  customClass?: string;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: unknown;
  disabled?: boolean;
  showPipe?: boolean;
  color?: unknown;
  type?: string;
  loading?: boolean;
}

export const Pipe = styled.div`
  background: #6C89F0;
  width: 1px;
  height: 16px;
  margin: 0 12px
`
const CustomButton = (props) => <StyledButton {...props} />;

export const IdpButton = ({
                         customClass,
                         text,
                         onClick,
                         disabled,
                         color,
                         loading,
                         children,
                         type,
                         showPipe = false,
                         ...others
                       }: ButtonBaseProps<undefined, ButtonProps>) => {
  return (
    <CustomButton
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      color={color}
      className={customClass ? customClass : ''}
      type={type}
      {...others}
    >
      {loading && (
        <>
          <CircularProgress size={14} color='inherit'/>&nbsp;
          {showPipe && <Pipe/>}
        </> // Size 14 works pretty well
      )}
      {children}
      {text}
    </CustomButton>
  );
};

export default IdpButton;
