import React from 'react';
import { retriveTeamsStepVideo } from '@walkme-admin-center/libs/common';
import {
    StepContainer,
    TitleContainer,
    TitleText,
    BoldText,
    VideoStepNumber,
} from '../../../slack-form/steps/manifest-step/slack-steps/slack-steps-styles';
import VideoContainer from '../../../slack-form/steps/manifest-step/slack-steps/video-container';

const SetTeamsChannel = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>4.</VideoStepNumber>Set<BoldText>Teams Channel</BoldText>
                </TitleText>
            </TitleContainer>
            <VideoContainer video={retriveTeamsStepVideo(3)} />
        </StepContainer>
    );
};

export default SetTeamsChannel;
