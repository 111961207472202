import { TFunction } from 'i18next';
import { ValidationErrorType, ValidationResult } from './validation-result';

/* eslint-disable no-useless-escape*/
const PREFIX_REGEX = /^(http|https):\/\//;
const VALID_DOMAIN_REGEX = /^https?:\/\/([^.\s]+\.){2,}([^.\s])(.*)?$/;
const SCHEME_SEPARATOR = '://';
const HOST_WITH_WILDCARD_REGEX = /^(\*|(\*\.[^\/\*]+))$/;

const LOCALIZATION_KEYS_PREFIX = 'errors.url-validation';

const getErrorMessage = (errorType: string, t: TFunction): string => t(`${LOCALIZATION_KEYS_PREFIX}.${errorType}`);

const validateUrlStructure = (value: string, isRegex: boolean, t: TFunction): ValidationResult => {
    if (isRegex) {
        return getRegexValidationResult(value, t);
    } else {
        return getMatchPatternValidationResult(value, t);
    }
};

const getRegexValidationResult = (value: string, t: TFunction): ValidationResult => {
    let isValid: boolean = true;
    let errorMessage: string = '';
    let errorType: ValidationErrorType;

    try {
        new RegExp(value);

        if (value === '') {
            isValid = false;
            errorType = ValidationErrorType.FIELD_EMPTY;
            errorMessage = getErrorMessage(errorType, t);
        }
    } catch (e) {
        isValid = false;
        errorType = ValidationErrorType.INVALID_REGEX;
        errorMessage = getErrorMessage(errorType, t);
    }
    return { isValid, errorMessage, errorType };
};

const getUrlVerifierFormatValidationResult = (value: string, t: TFunction): ValidationResult => {
    if (!PREFIX_REGEX.test(value) || !VALID_DOMAIN_REGEX.test(value)) {
        const errorType = ValidationErrorType.INVALID_DOMAIN_AND_PROTOCOL;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    return { isValid: true };
};

const getMatchPatternValidationResult = (value: string, t: TFunction): ValidationResult => {
    if (!PREFIX_REGEX.test(value)) {
        const errorType = ValidationErrorType.MISSING_PROTOCOL_PREFIX;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    let urlParts: string[] = value.split(SCHEME_SEPARATOR);
    const urlPartWithoutScheme: string = urlParts[1];
    if (urlPartWithoutScheme === '') {
        const errorType = ValidationErrorType.EMPTY_HOST;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    urlParts = urlPartWithoutScheme.split('/');
    if (urlParts.length === 1) {
        const errorType = ValidationErrorType.EMPTY_PATH;
        return {
            isValid: false,
            errorMessage: getErrorMessage(errorType, t),
            errorType,
        };
    }

    const host: string = urlParts[0];
    const path: string = urlParts[1];

    if (host.indexOf('*') >= 0) {
        if (!HOST_WITH_WILDCARD_REGEX.test(host)) {
            const errorType = ValidationErrorType.HOST_FORMAT_INVALID;
            return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
        }
    }

    if (path === '') {
        const errorType = ValidationErrorType.EMPTY_PATH;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    return { isValid: true };
};

export { validateUrlStructure, getMatchPatternValidationResult, getRegexValidationResult, getUrlVerifierFormatValidationResult };
