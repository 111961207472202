import styled from 'styled-components';
import React from 'react';
import { Button } from '../shared/button';
import { PencilIcon } from '../../icons/shared/pencil.icon';

const StyledPencilIcon = styled(PencilIcon)`
  width: 14px;
  padding-right: 5px;
`

interface HostingEditButtonProps {
  toggleEditDialog: () => void;

  editDisabled: boolean;
}

export const ViewEditButton = (props: HostingEditButtonProps) => {
  const pencilColor = props.editDisabled ? '#cedaeb' : null;

  return (
    <Button onClick={props.toggleEditDialog} disabled={props.editDisabled}>
      <StyledPencilIcon htmlColor={pencilColor} /> Edit Hosting
    </Button>
  );
}
