import styled from 'styled-components';
export const StyledSystemHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .envsCount {
        padding-left: 6px;
        font-size: 12px;
        color: #8d97ae;
    }
`;
