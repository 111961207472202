import React, { useCallback, useMemo } from 'react';
import { FilledWarning, ErrorColor, InfoColor } from '@walkme/ui-icons';
import cc from 'classcat';
import styles from './AlertBanner.module.scss';
import { IAiSettings } from '../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/types';

const AlertBanner = ({ aiSettings }: { aiSettings: IAiSettings }) => {
    const getMessage = useCallback(() => {
        if (!aiSettings.msaTotalCap) {
            return (
                <>
                    <InfoColor />
                    <span className={cc([styles.bannerText])}>
                        For all the AI features in your account, you have spent ${aiSettings.totalAiCost.toLocaleString()}.
                    </span>
                </>
            );
        } else if (aiSettings.usePercentage >= 100) {
            return (
                <>
                    <ErrorColor />
                    <span className={cc([styles.bannerText])}>
                        You have spent ${aiSettings.msaTotalCap.toLocaleString()} on all AI features in your account, which is 100% of your
                        budget cap. Your limit will reset at the beginning of next month.
                    </span>
                </>
            );
        } else if (aiSettings.usePercentage >= 80) {
            return (
                <>
                    <FilledWarning />
                    <span className={cc([styles.bannerText])}>
                        You have spent {aiSettings.usePercentage.toLocaleString()}% of your ${aiSettings.msaTotalCap.toLocaleString()} limit
                        this month on your AI features.
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <InfoColor />
                    <span className={cc([styles.bannerText])}>
                        For all the AI features in your account, you have spent ${aiSettings.totalAiCost.toLocaleString()}, which is{' '}
                        {aiSettings.usePercentage}% of your total monthly budget cap of ${aiSettings.msaTotalCap.toLocaleString()}.
                    </span>
                </>
            );
        }
    }, [aiSettings]);

    const getClasses = useMemo(() => {
        if (!aiSettings.msaTotalCap) {
            return null;
        } else if (aiSettings.usePercentage >= 100) {
            return styles.error;
        } else if (aiSettings.usePercentage >= 80) {
            return styles.warning;
        } else {
            return null;
        }
    }, [aiSettings]);
    return <div className={cc([styles.alertBannerContainer, getClasses])}>{getMessage()}</div>;
};

export default AlertBanner;
