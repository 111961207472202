/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { WMhttpClient } from '@walkme-admin-center/libs/http-service';
import {
    IGuidedAnswersBulkUpdateData,
    IGuidedAnswers,
    IFeedbackPayload,
    IQuestionFeedbackPayload,
} from '../../../state-mangment-data-integration/src/lib/types';

class AskMeAppApi {
    private readonly httpClient: WMhttpClient;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
    }

    async getGuidedAnswers(sourceGuid: string): Promise<IGuidedAnswers> {
        const { data } = await this.httpClient.instance.get<any>(`guided-answers/${sourceGuid}`);
        return data;
    }

    async bulkUpdateGuidedAnswers(sourceGuid: string, data: IGuidedAnswersBulkUpdateData): Promise<{ guided_answers_count: number }> {
        const { data: response } = await this.httpClient.instance.put<any>(`guided-answers/${sourceGuid}`, data);
        return response;
    }

    async sendQuestionFeedBack(payload: IQuestionFeedbackPayload): Promise<void> {
        await this.httpClient.instance.post<IQuestionFeedbackPayload>(`feedback/question`, payload);
    }

    async sendFeedBack(payload: IFeedbackPayload): Promise<void> {
        await this.httpClient.instance.post<IFeedbackPayload>(`feedback/general`, payload);
    }
}

export const askMeAppApi = new AskMeAppApi(new WMhttpClient(`${window.clientConfig.NX_WM_PAPI}/deepui/api/askme`));

// export const askMeAppApi = new AskMeAppApi(new WMhttpClient('http://localhost:3434'));
