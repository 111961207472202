import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import cc from 'classcat';
import { Box, RadioGroup, Typography } from '@material-ui/core';
import { WMRadio } from '@walkme/wm-ui';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { SelfHostedMethod } from '../../types/environment-settings';
import { InfoIconWithTooltip } from '../common/info-icon-with-tooltip';
import { InputWithInfo } from '../common/input-with-info';
import { urlFieldConfig } from '../../utils/form-fields-config';
import { StyledWMCheckBox, StyledWMSelect } from '../common/styled-components';
import { CodeBlock } from '../common/code-block';
import { snippetCodeBlockStyle } from './snippet-code-block-style';
import { generateSelfHostedSnippet } from '../../utils/generate-self-hosted-snippet';
import {
    StyledAlert,
    StyledDeploymentMethodContainer,
    StyledDeploymentUrlContainer,
    StyledForm,
    StyledHostingMethodContainer,
    StyledPackageLocationContainer,
} from './env-settings-form-style';
import useFieldMirroring from '../../hooks/useFieldMirroring';
import { DeploymentMethod, DeploymentMethodOption } from '../../types/deployment-method';

interface EnvSettingsFormProps {
    formId: string;
    deploymentMethod: DeploymentMethodOption;
    allMethods: DeploymentMethodOption[];
    onDeploymentMethodChange: (newMethod: DeploymentMethodOption) => void;
    onSubmit: () => void;
    snippetUserGuid: string;
}

interface HostingMethodOptionProps {
    value: SelfHostedMethod;
    label: string;
    tooltip: string;
}

const SELF_HOSTED_URL_MAX_LENGTH = 200;

const HostingMethodOption = ({ value, label, tooltip }: HostingMethodOptionProps) => {
    return (
        <Field name='package.hostingMethod' value={value} type='radio'>
            {({ input: { checked, onChange } }) => (
                <StyledHostingMethodContainer>
                    <WMRadio ds2 value={value} label={label} checked={checked} onChange={onChange} />
                    <InfoIconWithTooltip tooltipTitle={tooltip} />
                </StyledHostingMethodContainer>
            )}
        </Field>
    );
};

const PreDefinedUrlInput = ({ isSnippet, canUpdateExtensionUrl }) => {
    const {
        input: { value: hostingMethod },
    } = useField('package.hostingMethod');
    const {
        input: { value: mirrorExtensionUrl, onChange: setMirrorExtensionUrl },
    } = useField('package.mirrorExtensionUrl');

    useFieldMirroring({
        from: 'deployment.url',
        to: 'package.deploymentUrl',
        isActive: mirrorExtensionUrl,
    });

    const validateIfPredefined = (value, allValues) => {
        return allValues?.package?.hostingMethod === SelfHostedMethod.PRE_DEFINED ? urlFieldConfig.validate(value) : undefined;
    };

    const handleMirrorExtensionUrlChanged = (event, checked: boolean) => {
        setMirrorExtensionUrl(checked);
    };

    return (
        <StyledDeploymentUrlContainer className={cc([{ open: hostingMethod === SelfHostedMethod.PRE_DEFINED }])}>
            <Field name='package.deploymentUrl' validate={validateIfPredefined}>
                {({ input: { value, onChange, onFocus, onBlur }, meta }) => (
                    <InputWithInfo
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        placeholder={urlFieldConfig.placeholder}
                        tooltipText={urlFieldConfig.getTooltip()}
                        isDisabled={isSnippet || mirrorExtensionUrl}
                        isValid={!(meta.error && meta.touched) || mirrorExtensionUrl}
                        errorText={meta.error}
                        maxLength={SELF_HOSTED_URL_MAX_LENGTH}
                    />
                )}
            </Field>
            {(isSnippet || canUpdateExtensionUrl) && (
                <span>
                    <StyledWMCheckBox
                        ds2
                        checked={isSnippet || mirrorExtensionUrl}
                        disabled={isSnippet}
                        onChange={handleMirrorExtensionUrlChanged}
                        label='Use deployment method url'
                    />
                </span>
            )}
        </StyledDeploymentUrlContainer>
    );
};

const DeploymentMethodWrapper = ({ fieldName, children, noAlert }) => {
    return (
        <>
            <Field name={fieldName} validate={urlFieldConfig.validate}>
                {({ input, meta }) => <Box>{children && children({ input, meta })}</Box>}
            </Field>
            {!noAlert && (
                <StyledAlert icon={false} severity='warning'>
                    To change the deployment method, go to system advanced settings
                </StyledAlert>
            )}
        </>
    );
};

const DeploymentMethodUrl = ({ value, onChange, onFocus, onBlur, meta }) => {
    return (
        <>
            <Typography className='deploymentMethod-txt'>Enter the URL where your WalkMe package will be hosted</Typography>
            <InputWithInfo
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={urlFieldConfig.placeholder}
                tooltipText={urlFieldConfig.getTooltip()}
                isValid={!(meta.error && meta.touched)}
                errorText={meta.error}
                maxLength={SELF_HOSTED_URL_MAX_LENGTH}
            />
        </>
    );
};

const ExtensionUrlInput = ({ canUpdate }) => {
    if (canUpdate) {
        return (
            <DeploymentMethodWrapper fieldName='deployment.url' noAlert={true}>
                {({ input: { value, onChange, onBlur, onFocus }, meta }) => (
                    <DeploymentMethodUrl value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} meta={meta} />
                )}
            </DeploymentMethodWrapper>
        );
    } else {
        return (
            <StyledAlert icon={false} severity='warning'>
                This extension has a custom configuration and the URLs cannot be edited. Contact WalkMe support for assistance.
            </StyledAlert>
        );
    }
};

const SnippetUrlInput = ({ snippetUserGuid, addGuidToPath }) => {
    return (
        <DeploymentMethodWrapper fieldName='package.deploymentUrl' noAlert={true}>
            {({ input: { value, onChange, onBlur, onFocus }, meta }) => (
                <>
                    <DeploymentMethodUrl value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} meta={meta} />
                    <Typography className='deploymentMethod-txt'>
                        Copy and paste the code below into the header HTML of every webpage where WalkMe should load.
                    </Typography>
                    <CodeBlock
                        text={generateSelfHostedSnippet(value, snippetUserGuid, addGuidToPath)}
                        language='htmlbars'
                        style={snippetCodeBlockStyle}
                        minifyOnCopy
                        wrap
                    />
                </>
            )}
        </DeploymentMethodWrapper>
    );
};

const EnvSettingsForm = ({
    formId,
    onSubmit,
    snippetUserGuid,
    deploymentMethod,
    allMethods,
    onDeploymentMethodChange,
}: EnvSettingsFormProps) => {
    const {
        input: { value: canUpdateExtensionUrl },
    } = useField('deployment.isUpdateEnabled', { subscription: { value: true } });
    const [localDeploymentMethod, setLocalDeploymentMethod] = useState(deploymentMethod);
    const { accountFeatureEnabled } = useLoggedInUser();

    useEffect(() => {
        setLocalDeploymentMethod(deploymentMethod);
    }, [deploymentMethod]);

    useEffect(() => {
        if (localDeploymentMethod === null) {
            setLocalDeploymentMethod(deploymentMethod);
        }
    }, [localDeploymentMethod]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit();
    };

    const handleDeploymentMethodChange = (newMethod) => {
        if (newMethod.value !== deploymentMethod.value) {
            setLocalDeploymentMethod(null);

            onDeploymentMethodChange(newMethod);
        }
    };

    return (
        <StyledForm id={formId} onSubmit={handleSubmit}>
            <StyledDeploymentMethodContainer>
                <Typography className='form-sectionTitle'>Deployment method</Typography>
                {allMethods.length > 1 && (
                    <StyledWMSelect value={localDeploymentMethod} options={allMethods} onChange={handleDeploymentMethodChange} />
                )}
                {allMethods.length === 1 && <Typography className='deploymentMethod-name'>{deploymentMethod.label}</Typography>}
                {deploymentMethod.value === DeploymentMethod.EXTENSION && <ExtensionUrlInput canUpdate={canUpdateExtensionUrl} />}
                {deploymentMethod.value === DeploymentMethod.SNIPPET && (
                    <SnippetUrlInput
                        snippetUserGuid={snippetUserGuid}
                        addGuidToPath={!accountFeatureEnabled('noSystemGuidInSelfHostedPath')}
                    />
                )}
            </StyledDeploymentMethodContainer>
            <StyledPackageLocationContainer>
                <Typography className='form-sectionTitle'>Package location</Typography>
                <RadioGroup>
                    <HostingMethodOption
                        value={SelfHostedMethod.AUTO_DETECT}
                        label='Auto-detect (Recommended)'
                        tooltip='Automatically detect where the initiating file is located.
                                         This method is recommended if you use the same downloaded files across custom,
                                         test and production environments'
                    />
                    <Box>
                        <HostingMethodOption
                            value={SelfHostedMethod.PRE_DEFINED}
                            label='Pre defined location'
                            tooltip='Define a static location within the self-hosted files.
                                         This method is recommended if you use separate files for test or production environments'
                        />
                        <PreDefinedUrlInput
                            isSnippet={deploymentMethod.value === DeploymentMethod.SNIPPET}
                            canUpdateExtensionUrl={canUpdateExtensionUrl}
                        />
                    </Box>
                </RadioGroup>
            </StyledPackageLocationContainer>
        </StyledForm>
    );
};

export { EnvSettingsForm };
