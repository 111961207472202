import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    iconContainer: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    icon: {
        color: '#6D81A6',
        '&.MuiSvgIcon-root': {
            fill: 'none',
            width: '15px',
            height: '15px',
        },
        '&:hover': {
            color: '#52617D',
        },
    },
}));

export { useStyles };
