import React from 'react';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: '100%', width: '100%' }}
            {...other}>
            {children}
        </div>
    );
};

export default TabPanel;
