import React, { useState } from 'react';
import { CreateSystemSteps } from '../../../../../../../../../libs/state-management-systems/src/lib/types/createSystem';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { Dialog, DialogTitle, DialogActions, Typography, Button, Checkbox } from '@walkme/ui-core';
import { StyledButton, StyledCheckboxDiv, StyledDialogContent, StyledInput, StyledTypography } from './custom-app-feedback.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const CustomAppFeedback = ({ isOpen }) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const step: CreateSystemSteps = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.step.data);
    const [feedback, setFeedback] = useState<string>('');
    const [neverShowChecked, setNeverShowChecked] = useState<boolean>(false);

    const onSkip = () => {
        dispatch(createSystemSlice.actions.setCreateSystemFeedbackVisibility(false));
        dispatch(createSystemSlice.actions.setCreateSystemStep(step + 1));
    };

    const onSubmit = () => {
        dispatch(createSystemSlice.actions.setFeedback(feedback));
        dispatch(createSystemSlice.actions.setCreateSystemFeedbackVisibility(false));
        dispatch(createSystemSlice.actions.setShouldSendFeedback(true));
        dispatch(createSystemSlice.actions.setCreateSystemStep(step + 1));

        if (neverShowChecked) {
            window.localStorage.setItem('neverShowFeedbackDialog', 'true');
        }
    };

    return (
        <Dialog isOpen={isOpen} onClose={onSkip}>
            <DialogTitle>{t('systems-tab.new-system-form.feedback-title')}</DialogTitle>
            <StyledDialogContent>
                <StyledTypography variant={'T20'}>{t('systems-tab.new-system-form.feedback-text')}</StyledTypography>
                <StyledInput
                    autoComplete='off'
                    multiline
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)} // add debounce ?
                    placeholder={t('systems-tab.new-system-form.feedback-placeholder')}
                    rows={4}
                    width={280}
                    maxlength={250}
                />
                <StyledCheckboxDiv>
                    <Checkbox checked={neverShowChecked} onChange={() => setNeverShowChecked(!neverShowChecked)} />
                    <Typography variant={'T20'}>{t('systems-tab.new-system-form.feedback-checkbox-text')}</Typography>
                </StyledCheckboxDiv>
            </StyledDialogContent>
            <DialogActions>
                <StyledButton size='large' variant='text' onClick={onSkip}>
                    {t('buttons.skip')}
                </StyledButton>
                <Button size='large' onClick={onSubmit}>
                    {t('buttons.submit-and-continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
