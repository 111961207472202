import { useSelector } from 'react-redux';
import { DataIntegrationState } from '../redux/data-integration.slice';

export const useSettings = () => {
    const settingsData = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.settings;
    });

    return {
        settingsData,
    };
};

export default useSettings;
