import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { PageTitle } from '@walkme-admin-center/libs/shared-styles';
import { OneId, ProviderGroups, Providers } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { AppData } from '@walkme-admin-center/libs/types';
import { StyledButton, StyledTabDescription, StyledTransparentButton } from '../common/style';
import { CreateProviderContainer, HeaderContainer } from './sso-configuration-header-style';
import { learnMoreUrl } from './sso-configuration-header-service';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface SsoConfigurationHeaderProps {
    providersData: AppData<Providers>;
    oneIdData: AppData<OneId>;
    providerGroupsData: AppData<ProviderGroups>;
    createNewProvider: () => void;
    openOneIdDialog: () => void;
}

export const SsoConfigurationHeader = ({
    providersData,
    oneIdData,
    providerGroupsData,
    createNewProvider,
    openOneIdDialog,
}: SsoConfigurationHeaderProps) => {
    const isLoading = (): boolean => {
        return !providerGroupsData.loading && !oneIdData.loading && !providersData.loading;
    };
    const { t } = useTranslation('general');

    useEffect(() => {
        document.title = t('idp-tab.document-title');
    }, []);

    return (
        <HeaderContainer>
            <div className={'main-header'}>
                <PageTitle className={'idp-page-title'}>{t('idp-tab.title')}</PageTitle>
                <CreateProviderContainer>
                    {isLoading() && (
                        <>
                            <StyledTransparentButton
                                disabled={providersData.data.length === 0 || true}
                                variant={'outlined'}
                                style={{ marginRight: '16px' }}
                                onClick={() => openOneIdDialog()}>
                                {oneIdData.data.oneIdProvider ? (
                                    <img className={'edit-one-id-icon'} src='assets/icons/icon-edit-small.svg' alt='edit provider' />
                                ) : null}
                                <span>
                                    {oneIdData.data.oneIdProvider ? t('idp-tab.oneId.manage.button') : t('idp-tab.oneId.activate.button')}
                                </span>
                            </StyledTransparentButton>
                            <StyledButton onClick={() => createNewProvider()}>
                                <span className='iconContainer'>
                                    <AddIcon />
                                </span>
                                <span>{t('idp-tab.add-idp')}</span>
                            </StyledButton>
                        </>
                    )}
                </CreateProviderContainer>
            </div>
            <StyledTabDescription>
                {t('idp-tab.description')}
                <span className={'link'} onClick={() => window.open(learnMoreUrl, '_blank')}>
                    {t('buttons.learn-more')}
                </span>
            </StyledTabDescription>
        </HeaderContainer>
    );
};

export default SsoConfigurationHeader;
