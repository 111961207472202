import React, { useState, useEffect, useRef } from 'react';
import {
    StyledAppsFilterContainer,
    StyledFilterInputs,
    StyledFilterSelections,
    StyledInputSearch,
    StyledFilterIcon,
    StyledSortIcon,
    StyledApplicationFoundText,
    StyledFilterIsOpenContainer,
    StyledSortContainer,
    StyledFilterIsClosedContainer,
} from '../select-application.styles';
import { useDispatch, useSelector } from 'react-redux';
import { CreateSystemState, createSystemSlice, useSystemApps } from '@walkme-admin-center/libs/state-management-systems';
import { WMDivider } from '@walkme/wm-ui';
import { FiltersSelected } from '../../../../../../../../../libs/common/assets/icons/FiltersSelected';
import { BusinessDomainsFilter } from './business-domains-filter';
import { BusinessDomain, useBusinessDomains } from '@walkme-admin-center/libs/state-management-users';
import { SortMenu } from './sort-filter';
import { useDebouncedCallback } from 'use-debounce';
import { Filter } from '@walkme/ui-icons';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const AppsFilter = () => {
    const { t, rt } = useTranslation('general');
    const [sortSelectionsAreVisible, setSortSelectionsAreVisible] = useState<boolean>(false);
    const dispatch = useDispatch();
    const selectedBusinessDomains: BusinessDomain[] = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedBusinessDomains
    );
    const filterSelectionsAreVisible: boolean = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.filterSelectionsAreVisible
    );
    const appSearchFilterText: string = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.appSearchFilterText.data
    );
    const selectedAppFilters = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedAppFilters.data
    );
    const allBusinessDomainsData = useBusinessDomains();
    const allBusinessDomains = allBusinessDomainsData?.businessDomains as BusinessDomain[];
    const systemApps = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.systemApps.data);
    const systemPagesInfo = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.systemAppPagesInfo.data
    );
    const systemAppsContainerLength = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.systemAppsContainerLength.data
    );
    const sortAnchorEl = useRef(null);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        dispatch(createSystemSlice.actions.setSearchFilterText(value));
    }, 1000);

    const filtersArentDefault = selectedBusinessDomains.length > 0 && allBusinessDomains.length !== selectedBusinessDomains.length;

    const translateBD = (bd: BusinessDomain): BusinessDomain => {
        return { ...bd, name: rt(bd.name) };
    };

    const handleChangeBusinessDomainFilter = () => {
        const newBusinessDomainsFilter = selectedBusinessDomains.map((businessDomain) => businessDomain.name);
        const updatedSelectedAppFilters = { ...selectedAppFilters };
        const updatedCategoryFilter = { ...selectedAppFilters?.category };
        if (newBusinessDomainsFilter?.length === 0 || newBusinessDomainsFilter?.length === allBusinessDomains.length) {
            delete updatedCategoryFilter.type;
        } else {
            updatedCategoryFilter.type = [
                ...newBusinessDomainsFilter,
                ...newBusinessDomainsFilter.map((businessDomain) => businessDomain.toLowerCase()),
            ];
        }
        if (Object.keys(updatedCategoryFilter).length === 0) {
            delete updatedSelectedAppFilters.category;
        } else {
            updatedSelectedAppFilters.category = updatedCategoryFilter;
        }

        dispatch(createSystemSlice.actions.setAppCategoryFilter(updatedSelectedAppFilters));
    };

    const handleToggleFilterSelections = (isVisible: boolean) => {
        dispatch(createSystemSlice.actions.setFilterSelectionsAreVisible(isVisible));
    };

    const handleResetFilters = () => {
        handleToggleFilterSelections(false);
        setSelectedBusinessDomains(allBusinessDomains);
    };

    const setSelectedBusinessDomains = (businessDomains: BusinessDomain[]) => {
        dispatch(createSystemSlice.actions.setSelectedBusinessDomains(businessDomains.map(translateBD)));
    };

    useEffect(handleChangeBusinessDomainFilter, [selectedBusinessDomains]);

    return (
        <>
            <StyledAppsFilterContainer>
                <StyledApplicationFoundText>
                    {t('systems-tab.new-system-form.showing-systems', {
                        countSystems: systemAppsContainerLength?.toString(),
                        totalSystems: systemPagesInfo?.totalItems,
                    })}
                </StyledApplicationFoundText>
                <StyledFilterInputs>
                    <StyledInputSearch
                        value={appSearchFilterText}
                        placeholder={t('systems-tab.new-system-form.search-placeholder')}
                        onChange={handleSearchChange}
                    />
                    {filterSelectionsAreVisible ? (
                        <StyledFilterIsOpenContainer onClick={() => handleToggleFilterSelections(false)}>
                            {filtersArentDefault ? <FiltersSelected /> : <Filter />}
                        </StyledFilterIsOpenContainer>
                    ) : (
                        <StyledFilterIsClosedContainer onClick={() => handleToggleFilterSelections(true)}>
                            <StyledFilterIcon color='#6d81a6' />
                        </StyledFilterIsClosedContainer>
                    )}
                    <WMDivider orientation='vertical' flexItem />
                    <StyledSortContainer ref={sortAnchorEl}>
                        <StyledSortIcon onClick={() => setSortSelectionsAreVisible(true)} />
                        <SortMenu
                            isOpen={sortSelectionsAreVisible}
                            onClose={() => setSortSelectionsAreVisible(false)}
                            anchor={sortAnchorEl}
                        />
                    </StyledSortContainer>
                </StyledFilterInputs>
            </StyledAppsFilterContainer>
            {filterSelectionsAreVisible ? (
                <StyledFilterSelections>
                    <BusinessDomainsFilter
                        selectedBusinessDomains={selectedBusinessDomains}
                        allBusinessDomains={allBusinessDomains.map(translateBD)}
                        setSelectedBusinessDomains={setSelectedBusinessDomains}
                    />
                    <div className='resetFiltersText' onClick={handleResetFilters}>
                        {t('common.reset-filters')}
                    </div>
                </StyledFilterSelections>
            ) : null}
        </>
    );
};
