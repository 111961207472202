import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { Environment } from 'wm-accounts-sdk';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CodeBlock } from '../../../../self-hosting/src/lib/components/common/code-block';

export interface EnvironmentSnippetProps {
    open: boolean;
    onClose: () => void;
    generateSnippetCode: (environment: Environment) => string;
    environment: Environment;
    systemId: number;
}

const StyledDialog = styled(Dialog)`
    .MuiDialog-container {
        background: #2f426c9c;
    }

    .MuiPaper-root {
        border-radius: 15px;
        overflow-y: hidden;
        box-shadow: 0px 36px 52px 0px #2f3d531c;
    }

    .dialog-content {
        overflow-y: visible;
        margin: 0 32px 0 32px;
        padding: 0;
    }

    .dialog-actions {
        margin: 32px;
    }
`;

const StyledCodeBlock = styled(CodeBlock)`
    border-radius: 8px;

    .react-syntax-highlighter-line-number {
        color: #637191 !important;
    }
`;

const StyledCloseButton = styled(WMButton)`
    width: 80px;
    height: 40px;
    border-radius: 40px;
`;

const EnvironmentSnippet = ({ open, onClose, generateSnippetCode, environment, systemId }: EnvironmentSnippetProps) => {
    const [environmentLoading, setEnvironmentLoading] = useState(false);

    return (
        <StyledDialog onClose={() => onClose()} open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            color: '#2F426C',
                            fontSize: '18px',
                            fontWeight: 600,
                        }}>
                        {`${environment.name} snippet`}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <img src='assets/icons/close.svg' alt='close' style={{ width: 9 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={'dialog-content'}>
                <Typography
                    style={{
                        fontFamily: 'Proxima nova',
                        color: '#2F426C',
                        fontSize: '16px',
                        fontWeight: 400,
                        marginBottom: '10px',
                    }}>
                    Snippet JavaScript
                </Typography>
                <StyledCodeBlock language='htmlbars' style={tomorrowNightBlue} text={generateSnippetCode(environment)} />
            </DialogContent>
            <DialogActions className={'dialog-actions'}>
                <StyledCloseButton variant={WMButtonVariant.Primary} disabled={environmentLoading} onClick={() => onClose()}>
                    Close
                </StyledCloseButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default EnvironmentSnippet;
