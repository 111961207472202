import { Grid, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const CssTextField = styled(TextField)`
    & .MuiInputBase-root,
    .MuiSelect-root,
    .MuiFormControl-root,
    .MuiFormControl-root {
        width: 100%;
    }
    & .MuiInputBase-input,
    .MuiOutlinedInput-input {
        padding: 0px;
        margin: 0.5em;
    }
`;

export const StyledGrid = styled(Grid)`
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #dde5f5;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .keywordChipsAddText {
        font-family: 'proximaNova';
        font-size: 14px;
        color: var(--Typography-Typography-Tint-2, #8d97ae);

        b {
            color: var(--LS-Color-Text-primary, #2f426c);
        }
    }
`;

export const StyledTitleWithTooltip = styled.div`
    display: flex;
    align-items: center;

    .titleTxt {
        margin: 0 5px !important;
    }
`;
