import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Dialog, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { WMButton, WMButtonVariant, WMSnackbar, WMSnackbarVariant, WMIconClose, WMCircularProgress, WMSelect } from '@walkme/wm-ui';
import {
    useUsers,
    UsersState,
    useRoles,
    isIdpConfigured,
    useAccountIdps,
    createMultipleUsers,
    usersSlice,
    useMfaSettings,
    useActions,
    useBusinessDomains,
    useLoggedInUser,
    useSecurityFlags,
} from '@walkme-admin-center/libs/state-management-users';
import { CreateUserDetailsDto, System, WMPendingUserRequestItem, Users } from 'wm-accounts-sdk';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import {
    FullScreenGrid,
    KeywordChipsAddTextDiv,
    KeywordChipsDiv,
    StyledChip,
    StyledChipInput,
    StyledDeleteIcon,
    StyledDialogTitle,
    StyledSimpleWMSelect,
    StyledTitleGrid,
    UserErrorLabel,
    WizardDialog,
    WizardDialogButtons,
    WizardDialogContent,
    WizardDialogContentNoPadding,
    WizardDialogOpen,
    WizardDialogPrimaryButton,
    WizardDialogSeconaryButton,
} from '../styles/styles';
import { CssTextField, StyledGrid, StyledTitleWithTooltip } from './bulk-users-mutation.styles';
import { hasBusinessDomainOptions } from './bulk-users-mutation.lib';
import { validateEmail, mailRegExp } from '../utils/mail';
import { Tooltip, Avatar, Input, InputVariant, ToasterVariant, Checkbox } from '@walkme/ui-core';
import { Delete, FilledWarning } from '@walkme/ui-icons';
import { ChevronBottom, Info } from '@walkme/ui-icons/large';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { TFunction } from 'i18next';
import { UsersEmptyState } from './user-form/users-empty-state';
import {
    AddedUsersContainer,
    DeleteIconContainer,
    StyledAddedUserItem,
    StyledErrorSign,
    StyledInfo,
    StyledSurveyInfoIcon,
    StyledSurveyRow,
    StyledToasterContainer,
    StyledWarningSign,
} from './user-form/added-users.styles';
import { StyledBadge, StyledNumberOfAddedUsersContainer } from './users-tab.styles';
import { InputHeight } from '@walkme/ui-core';
import { SelectInput } from './user-form/select-input';
import { mapRoleToRow } from '../utils/roles-rows';
import { getTooltipIcon } from '@walkme-admin-center/pages/home/extension-pages';
import { StyledTooltipText } from '../../../../extension-pages/src/lib/new-design/shared-styled-components';

import { NotificationMessage } from '@walkme-admin-center/libs/types';

export interface BulkUsersMutationProps {
    showError: (error: string) => void;
    onCancel?: () => void;
    onFinish: (successMessage: string, createdUsers: Users) => void;
    onRemoveEmail?: (email: string) => void;
    onLoaded?: () => void;
    showDialog?: boolean;
    noPadding?: boolean;
    hideCancelButton?: boolean;
    tableStyles?: any;
    showTableIfEmpty?: boolean;
    titleText?: string;
    hideTitle?: boolean;
    sendButtonText?: string;
    fullScreen?: boolean;
    showOnlyAdvancedSettings?: boolean;
    wideFormFields?: boolean;
    disableUserInviteInput?: boolean;
    checkboxView?: boolean;
    deleteMenuView?: boolean;
    suggestedUsers?: WMPendingUserRequestItem[];
}

export const BulkUsersMutation = ({
    showDialog,
    showError,
    onCancel,
    onFinish,
    onLoaded,
    onRemoveEmail,
    titleText,
    hideTitle = false,
    sendButtonText,
    fullScreen = false,
    disableUserInviteInput = false,
    showOnlyAdvancedSettings = false,
    wideFormFields = false,
    checkboxView = false,
    deleteMenuView = false,
    hideCancelButton = false,
    tableStyles = {},
    noPadding = false,
    showTableIfEmpty = false,
    suggestedUsers = [],
}: BulkUsersMutationProps) => {
    const { t, rt, i18n } = useTranslation('general');
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState([]);
    const [newEmailsChips, setNewEmailsChips] = useState([]);
    const [chipInputValue, setChipInputValue] = useState('');
    const [selectedUserEmail, setSelectedUserEmail] = useState<string>(null);
    const [newUsersData, setNewUsersData] = useState<CreateUserDetailsDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [showDenyAccessDialog, setShowDenyAccessDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [formErrorsAndWarnings, setFormErrorsAndWarnings] = useState({});
    const createUsersAppData = useSelector((state: { usersState: UsersState }) => state.usersState.createUsers);
    const currentSnackbarMessage = useSelector((state: { usersState: UsersState }) => state.usersState.notificationMessage);
    const { usersAppData } = useUsers();
    const { rolesAppData } = useRoles();
    const { systemsAppData } = useSystems();
    const { accountIdpsAppData } = useAccountIdps(false);
    const { actionsAppData } = useActions();
    const { businessDomains } = useBusinessDomains();
    const { accountFeatureEnabled } = useLoggedInUser();
    const { securityFlagsAppData } = useSecurityFlags();

    const [hasBusinessDomainTranslatedOptions, setHasBusinessDomainTranslatedOptions] = useState(
        hasBusinessDomainOptions.map((option) => ({ ...option, label: t(option.label) }))
    );

    const translatedSendButtonText = sendButtonText || t('buttons.send-invitation');
    const translatedTitleText = titleText || t('users-and-roles-tab.users.create-user-form.title');

    const translationFallback = (key: string, t: TFunction, fallbackValue: string) => {
        if (t(key) === key) {
            return fallbackValue;
        }
        return t(key);
    };

    const activeAccountIdps =
        accountIdpsAppData.data && accountIdpsAppData.data.length > 0 ? accountIdpsAppData.data.filter((idp) => isIdpConfigured(idp)) : [];
    const users = usersAppData.data;
    const selectRef = React.useRef(null);
    const fieldRef = React.useRef(null);
    const { mfaSettingsAppData } = useMfaSettings(false);
    const isMfaEnabled = mfaSettingsAppData.data && mfaSettingsAppData.data.isEnabled;
    const accountIdpsWithEmptyState = activeAccountIdps.map((idp) => ({
        value: idp.idpId,
        label: idp.isOldIdpFlow ? t('users-and-roles-tab.users.edit-user-form.legacy-sso') : idp.idpName,
    }));
    const passwordSSOOption = {
        value: 'password',
        label: isMfaEnabled
            ? t('users-and-roles-tab.users.edit-user-form.login-mfa')
            : t('users-and-roles-tab.users.edit-user-form.login-password'),
    };
    const showLOBContent = accountFeatureEnabled('lobEnabled');
    const selectedNewUserData = newUsersData?.find((user) => user.email === selectedUserEmail);

    accountIdpsWithEmptyState.push(passwordSSOOption);

    const setNotificationMessage = ({ text, variant, isOpen }: NotificationMessage) => {
        dispatch(usersSlice.actions.setNotificationMessage({ text, variant, isOpen }));
    };

    const extractNameFromEmail = (email) => {
        const beforeDomainSplittedByDot = email.split('@')[0].split('.');
        const firstName = capitalizeFirstLetter(beforeDomainSplittedByDot.length > 1 ? beforeDomainSplittedByDot[0] : email.split('@')[0]);
        const lastName = beforeDomainSplittedByDot.length > 1 ? capitalizeFirstLetter(beforeDomainSplittedByDot[1].split('+')[0]) : null;
        return {
            firstName,
            lastName,
        };
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(usersSlice.actions.cleanUpNotificationMessage());
    };
    const setNewUserDetails = (email: string, suggestedUserData?: WMPendingUserRequestItem): CreateUserDetailsDto => {
        const isIdpExists = activeAccountIdps && activeAccountIdps.length > 0;
        const ssoId = isIdpExists ? email : undefined;
        const suggestedSystemIds = (() => {
            if (!suggestedUserData || suggestedUserData.requestedUserSuggestedSystems.length === 0) {
                return null;
            }
            const filteredSystems = systemsAppData.data
                .filter((system) => {
                    const result = suggestedUserData.requestedUserSuggestedSystems.find(
                        (suggestedSystem) => suggestedSystem.type === system.type
                    );
                    return result;
                })
                .map((system) => system.id);
            return filteredSystems.length > 0 ? filteredSystems : null;
        })();
        const suggestedFirstName = suggestedUserData && suggestedUserData.requestedUserFirstName;
        const suggestedLastName = suggestedUserData && suggestedUserData.requestedUserLastName;
        const defaultAppToOpenAfterActivation = (suggestedUserData && suggestedUserData.defaultAppToOpenAfterActivation) || undefined;
        const extractedFirstAndLastName =
            suggestedFirstName && suggestedLastName
                ? { firstName: suggestedFirstName, lastName: suggestedLastName }
                : extractNameFromEmail(email);
        const newUser: CreateUserDetailsDto = {
            email: email,
            idpNameId: ssoId,
            roleId: 3,
            defaultAppToOpenAfterActivation,
            systemIds: suggestedSystemIds || systemsAppData.data.map((system) => system.id),
            firstName: extractedFirstAndLastName.firstName || 'N/A',
            lastName: extractedFirstAndLastName.lastName || '',
            attachToIdpId: isIdpExists ? activeAccountIdps[activeAccountIdps.length - 1].idpId : undefined,
            avoidAttachToDefaultIdp: !isIdpExists,
            activateWithoutPassword: isIdpExists && securityFlagsAppData.data?.idpUsersNoPassword,
        };
        return newUser;
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter' && (newEmailsChips.length > 0 || chipInputValue)) {
                addNewUsers();
            }

            if (event.key === ' ') {
                onBeforeAdd(chipInputValue, true);
            }
        },
        [newEmailsChips, chipInputValue, systemsAppData, accountIdpsAppData]
    );

    const handleNewUsersInitialErrorsAndWarnings = useCallback(
        (newUsers) => {
            const currentErrorsAndWarnings = { ...formErrorsAndWarnings };

            newUsers.forEach((newUser) => {
                currentErrorsAndWarnings[newUser.email] = {};
                Object.keys(newUser).forEach((key) => {
                    currentErrorsAndWarnings[newUser.email][key] = { error: null, warning: null };
                });
            });

            setFormErrorsAndWarnings(currentErrorsAndWarnings);
        },
        [formErrorsAndWarnings]
    );

    const addNewUsers = () => {
        if (newEmailsChips.length > 0 || chipInputValue) {
            const isInputExistsAndValid = chipInputValue && onBeforeAdd(chipInputValue, true);
            const newEmailBeforeAdd = isInputExistsAndValid ? chipInputValue : null;
            const newEmailsChipsToUpdate = newEmailBeforeAdd ? [...new Set([...newEmailsChips, newEmailBeforeAdd])] : newEmailsChips;
            const newUniqueResults: CreateUserDetailsDto[] = newEmailsChipsToUpdate.map((newEmailChip) => setNewUserDetails(newEmailChip));
            setNewUsersData([...newUsersData, ...newUniqueResults]);
            handleNewUsersInitialErrorsAndWarnings(newUniqueResults);

            if (isInputExistsAndValid) {
                setChipInputValue('');
            }
            setNewEmailsChips([]);
        }
    };

    const onMutateNewUsers = useCallback(() => {
        const filteredUsersData = checkboxView
            ? newUsersData.filter((userData) => selectedItems.find((email) => userData.email === email))
            : newUsersData;
        dispatch(
            createMultipleUsers({
                newUsers: filteredUsersData,
            })
        );
    }, [newUsersData, checkboxView, selectedItems]);

    const onAddChip = (newChip: string) => {
        setChipInputValue('');
        setNewEmailsChips([...newEmailsChips, newChip]);
    };

    const onDeleteChip = (removedChip: string) => {
        const newChips = newEmailsChips.filter((chip, chipIndex: number) => chip !== removedChip);
        setNewEmailsChips(newChips);
    };

    const onUpdateChipInput = (value: string) => {
        const chip = value && value.trim();
        setChipInputValue(chip);
    };

    const onBeforeAdd = (chip: string, showError = false): boolean => {
        if (!chip.trim()) return true;
        if (!validateEmail(chip)) {
            showError && setNotificationMessage({ text: t('errors.invalid-email'), variant: WMSnackbarVariant.Error, isOpen: true });
            return false;
        }
        if (
            users.find((user) => user.email === chip) ||
            newEmailsChips.find((email) => email === chip) ||
            newUsersData.find((user) => user.email === chip)
        ) {
            showError && setNotificationMessage({ text: t('errors.email-alredy-exists'), variant: WMSnackbarVariant.Error, isOpen: true });
            return false;
        }
        return true;
    };

    const mapSystemToRow = (system: System) => {
        if (!system) return;
        return {
            label: system.displayName,
            value: system.id,
        };
    };

    const getSelectedUser = () => {
        return newUsersData.find((user) => user.email === selectedUserEmail);
    };

    const onUserFieldsChanged = useCallback(
        (userEmailIdentifier: string, createUserUpdatedDetails: Partial<CreateUserDetailsDto>) => {
            const objectIndex = newUsersData.findIndex((userDetails) => userDetails.email === userEmailIdentifier);
            if (objectIndex === -1) return;
            const updatedNewUsersData: CreateUserDetailsDto[] = Object.assign([], newUsersData);
            const currentUserData: CreateUserDetailsDto = Object.assign({}, updatedNewUsersData[objectIndex]);
            updatedNewUsersData[objectIndex] = {
                ...currentUserData,
                ...createUserUpdatedDetails,
            };
            setNewUsersData(updatedNewUsersData);
        },
        [newUsersData]
    );

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = newUsersData.map((newUser) => newUser.email);
            setSelectedItems(newSelecteds);
            return;
        }
        setSelectedItems([]);
    };

    const handleClick = (event, email) => {
        const selectedIndex = selectedItems.indexOf(email);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, email);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
        }
        setSelectedItems(newSelected);
    };

    const handleRowClick = useCallback(
        (event, newUserData: CreateUserDetailsDto) => {
            setSelectedUserEmail(newUserData.email);
        },
        [selectedUserEmail]
    );

    const extractMailInitials = (email: string): string => {
        const splitEmail = email.toUpperCase().slice(0, email.indexOf('@')).split('.');
        return splitEmail.map((part, index) => (index < 2 ? part.charAt(0) : '')).join('');
    };

    const idpNameIdValidtorError = (userEmail: string, userIdpNameId: string): string => {
        if (!userIdpNameId) {
            return t('errors.sso-id-required');
        }
        const existingUserWithNameId = users.find((user) => user.idpNameId === userIdpNameId);
        const newUserWithNameId = newUsersData.find((user) => user.email !== userEmail && user.idpNameId === userIdpNameId);
        if (existingUserWithNameId) {
            return t('errors.sso-id-existing', { email: existingUserWithNameId.email });
        }
        if (newUserWithNameId) {
            return t('errors.sso-id-new', { email: newUserWithNameId.email });
        }
        return null;
    };

    const idpLoginMethodElement = (user: CreateUserDetailsDto, className?: string) => {
        return (
            <StyledSimpleWMSelect
                className={className}
                onChange={(selectedItem) => {
                    const isPasswrodOption = selectedItem.value === passwordSSOOption.value;
                    const createUserUpdatedDetails: Partial<CreateUserDetailsDto> = {
                        avoidAttachToDefaultIdp: isPasswrodOption,
                        attachToIdpId: isPasswrodOption ? null : selectedItem.value,
                    };
                    if (selectedItem.value === 'password') {
                        createUserUpdatedDetails['activateWithoutPassword'] = false;
                    }
                    onUserFieldsChanged(selectedUserEmail, createUserUpdatedDetails);
                }}
                value={
                    accountIdpsWithEmptyState.find((accountIdpWithEmptyState) => accountIdpWithEmptyState.value === user.attachToIdpId) ||
                    passwordSSOOption
                }
                options={accountIdpsWithEmptyState}
                maxMenuHeight={95}
                menuPortalTarget={null}
            />
        );
    };

    const idpNameIdElement = (user: CreateUserDetailsDto, className?: string) => {
        return (
            <CssTextField
                style={{ width: '100%' }}
                className={className}
                variant='outlined'
                type='text'
                error={idpNameIdValidtorError(user.email, user.idpNameId) ? true : false}
                helperText={idpNameIdValidtorError(user.email, user.idpNameId)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onUserFieldsChanged(selectedUserEmail, {
                        idpNameId: event.target.value,
                    });
                }}
                value={user.idpNameId}
            />
        );
    };

    const hasLOBElement = (user: CreateUserDetailsDto, className?: string) => {
        return (
            <StyledSimpleWMSelect
                className={className}
                maxMenuHeight={95}
                groupValues
                onChange={(selectedItem) => {
                    onUserFieldsChanged(selectedUserEmail, {
                        hasBusinessDomain: selectedItem.value,
                    });
                }}
                value={mapHasBusinessDomainToOption(user?.hasBusinessDomain)}
                options={hasBusinessDomainTranslatedOptions}
                menuPortalTarget={null}
            />
        );
    };

    const handleOnRemove = (newUserData: CreateUserDetailsDto) => {
        onRemoveEmail && onRemoveEmail(newUserData.email);
        const newSelected = selectedItems.filter((item) => item !== newUserData);
        setSelectedItems(newSelected);
        const newUsersDataUpdated = newUsersData.filter((newUser) => newUserData.email !== newUser.email);
        if (selectedUserEmail === newUserData.email && newUsersDataUpdated.length > 0) {
            setSelectedUserEmail(newUsersDataUpdated[0].email);
        }
        if (newUsersDataUpdated.length === 0) {
            setSelectedUserEmail(null);
        }
        setNewUsersData(newUsersDataUpdated);

        const currentFormErrorsAndWarnings = { ...formErrorsAndWarnings };
        if (currentFormErrorsAndWarnings[newUserData.email]) {
            delete currentFormErrorsAndWarnings[newUserData.email];
            setFormErrorsAndWarnings(currentFormErrorsAndWarnings);
        }
    };

    const returnErrorText = (newUserData: CreateUserDetailsDto) => {
        const errorForUser =
            createUsersAppData.data &&
            createUsersAppData.data.errors &&
            createUsersAppData.data.errors.length > 0 &&
            createUsersAppData.data.errors.find((error) => error.email === newUserData.email);
        if (!errorForUser) return '';
        return <UserErrorLabel>{errorForUser.message}</UserErrorLabel>;
    };

    const mapBusinessDomainIdToOption = (businessDomainId: number) => {
        const businessDomain = businessDomains.find((businessDomain) => businessDomain.id === businessDomainId);
        return {
            value: businessDomainId,
            label: businessDomain?.name,
        };
    };

    const mapHasBusinessDomainToOption = (hasBusinessDomain: boolean) => {
        return {
            value: hasBusinessDomain,
            label: hasBusinessDomain ? t('common.business') : t('common.standard'),
        };
    };

    const getShortNameDisplay = (email: string) => {
        return (
            email
                ?.split('@')[0]
                ?.split('.')
                ?.map((part) => part[0].toLocaleUpperCase())
                ?.join('')
                ?.substring(0, 3) || 'N/A'
        );
    };

    const shouldShowFormErrorToaster =
        newUsersData?.length > 0 &&
        Object.values(formErrorsAndWarnings).some((userErrorsAndWarnings) =>
            Object.values(userErrorsAndWarnings).some((errorAndWarning) => errorAndWarning.error)
        );

    const getSurveyText = () => {
        const elements = [];
        const fullText = t('users-and-roles-tab.users.create-user-form.survey.text');
        const linksPrefix = 'users-and-roles-tab.users.create-user-form.survey.links';
        const numOfLinks = (fullText.match(/\$\$/g) || []).length;
        const splitByDollars = fullText.split('$$');
        splitByDollars.forEach((element, index) => {
            elements.push(<span>{element}</span>);
            if (index < numOfLinks) {
                const linkText = t(`${linksPrefix}.${index}.text`);
                const linkURL = t(`${linksPrefix}.${index}.url`);
                elements.push(
                    <a href={linkURL} className='surveyLink' target='_blank'>
                        {linkText}
                    </a>
                );
            }
        });
        return elements;
    };

    const ContentGrid = noPadding ? WizardDialogContentNoPadding : WizardDialogContent;
    const contentElements = (
        <>
            <StyledTitleGrid item xs={12}>
                <Grid container style={{ minHeight: '21px' }} direction='row' alignItems='flex-start' justifyContent='space-between'>
                    <Grid item>
                        <StyledDialogTitle hidden={hideTitle}>{translatedTitleText}</StyledDialogTitle>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='flex-end' alignItems='baseline'>
                            {!showOnlyAdvancedSettings && newUsersData.length > 0 && (
                                <WMButton
                                    variant={WMButtonVariant.Text}
                                    onClick={() => {
                                        if (newUsersData.length > 0) {
                                            setSelectedUserEmail(newUsersData[0].email);
                                        }
                                    }}></WMButton>
                            )}
                            {!fullScreen && (
                                <IconButton onClick={() => onCancel()}>
                                    <img src='assets/icons/close.svg' alt='close' style={{ height: '12px' }} />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </StyledTitleGrid>
            <ContentGrid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <StyledGrid>
                            {!disableUserInviteInput && (
                                <KeywordChipsDiv>
                                    <StyledChipInput
                                        id='chip-input'
                                        onPaste={(event) => {
                                            setLoading(true);
                                            event.preventDefault();
                                            let newChips = [];
                                            newChips = event.clipboardData.getData('Text').match(mailRegExp);

                                            if (!newChips) {
                                                setNotificationMessage({
                                                    text: `${t('errors.invalid-email')} ${event.clipboardData.getData('Text')}`,
                                                    variant: WMSnackbarVariant.Error,
                                                    isOpen: true,
                                                });
                                                setLoading(false);
                                                return;
                                            }

                                            if (newChips.length === 1) {
                                                onBeforeAdd(newChips[0], true);
                                                onUpdateChipInput(newChips[0]);
                                            } else {
                                                const validChips = [];
                                                const invalidChips = [];
                                                for (const chip of newChips) {
                                                    const isValid = onBeforeAdd(chip, false);
                                                    if (isValid) validChips.push(chip);
                                                    else invalidChips.push(chip);
                                                }
                                                if (invalidChips.length > 0) {
                                                    setNotificationMessage({
                                                        text: t('users-and-roles-tab.users.create-user-form.invalid-emails', {
                                                            count: invalidChips.length,
                                                            emails: invalidChips.join(', '),
                                                        }),
                                                        variant: WMSnackbarVariant.Error,
                                                        isOpen: true,
                                                    });
                                                }
                                                const uniqueValidChips = [...new Set(validChips)];
                                                setNewEmailsChips([...newEmailsChips, ...uniqueValidChips]);
                                            }
                                            setLoading(false);
                                        }}
                                        inputValue={chipInputValue}
                                        classes={{
                                            inputRoot: 'styled-input-root',
                                            input: 'styled-input',
                                            chipContainer: 'styled-chip-container',
                                        }}
                                        fullWidth
                                        placeholder={t('users-and-roles-tab.users.create-user-form.email-placeholder')}
                                        value={newEmailsChips}
                                        onAdd={(chip) => onAddChip(chip)}
                                        onDelete={(chip, index) => onDeleteChip(chip)}
                                        onBeforeAdd={onBeforeAdd}
                                        onKeyDown={handleKeyDown}
                                        onUpdateInput={(event) => {
                                            onUpdateChipInput(event.target && event.target.value);
                                        }}
                                        chipRenderer={(args, key) => (
                                            <StyledChip
                                                deleteIcon={
                                                    <StyledDeleteIcon>
                                                        <WMIconClose size={9} />
                                                    </StyledDeleteIcon>
                                                }
                                                label={args.value}
                                                key={key}
                                                onDelete={args.handleDelete}
                                                disabled={args.isDisabled}
                                            />
                                        )}
                                        disableUnderline
                                        newChipKeys={['SPACE']}
                                        newChipKeyCodes={[32]}
                                        InputProps={{
                                            autoFocus: true,
                                            endAdornment: loading ? (
                                                <WMCircularProgress size={18} />
                                            ) : (
                                                <InputAdornment position='end'>
                                                    <WMButton
                                                        variant={WMButtonVariant.Text}
                                                        disabled={newEmailsChips.length === 0 && !chipInputValue}
                                                        style={{ padding: '10px' }}
                                                        onClick={() => addNewUsers()}>
                                                        {t('buttons.add')}
                                                    </WMButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <KeywordChipsAddTextDiv>
                                        <span
                                            className='keywordChipsAddText'
                                            dangerouslySetInnerHTML={{
                                                __html: t('users-and-roles-tab.users.create-user-form.new-user-tooltip'),
                                            }}></span>
                                    </KeywordChipsAddTextDiv>

                                    {shouldShowFormErrorToaster ? (
                                        <StyledToasterContainer
                                            icon={true}
                                            variant={ToasterVariant.Error}
                                            message={t(
                                                'users-and-roles-tab.users.create-user-form.errors-and-warnings.toaster.mandatory-fields'
                                            )}
                                        />
                                    ) : null}
                                </KeywordChipsDiv>
                            )}
                            {newUsersData.length === 0 ? (
                                <UsersEmptyState />
                            ) : (
                                <AddedUsersContainer>
                                    <div className='added-users-list'>
                                        <StyledNumberOfAddedUsersContainer>
                                            <div className='added-users-text'>
                                                {t('users-and-roles-tab.users.create-user-form.added-users')}
                                            </div>
                                            <StyledBadge badgeContent={newUsersData.length} size='medium' />
                                        </StyledNumberOfAddedUsersContainer>
                                        <div className='label'>{t('common.email')}</div>
                                        <div className='list'>
                                            {newUsersData.map((newUserData) => {
                                                const userErrorsAndWarnings = formErrorsAndWarnings[newUserData.email];
                                                const userHasError = Object.values(userErrorsAndWarnings).some(
                                                    (errorAndWarning: { error?: string; warning?: string }) => errorAndWarning.error
                                                );
                                                const userHasWarning = Object.values(userErrorsAndWarnings).some(
                                                    (errorAndWarning: { error?: string; warning?: string }) => errorAndWarning.warning
                                                );

                                                return (
                                                    <StyledAddedUserItem
                                                        isSelected={newUsersData.length === 1 || selectedUserEmail === newUserData.email}
                                                        onClick={() => setSelectedUserEmail(newUserData.email)}>
                                                        <div className='description'>
                                                            <Avatar text={getShortNameDisplay(newUserData.email)} color='pink' />
                                                            {newUserData.email}
                                                        </div>
                                                        <div className='actions'>
                                                            {userHasError && <StyledErrorSign />}
                                                            {!userHasError && userHasWarning && <StyledWarningSign />}
                                                            <Tooltip title={t('buttons.remove')} arrow>
                                                                <DeleteIconContainer>
                                                                    <Delete onClick={() => handleOnRemove(newUserData)} />
                                                                </DeleteIconContainer>
                                                            </Tooltip>
                                                        </div>
                                                    </StyledAddedUserItem>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='added-user-form'>
                                        <div className='split-row'>
                                            <Input
                                                value={newUsersData.find((user) => user.email === selectedNewUserData?.email)?.firstName}
                                                autoComplete='off'
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    onUserFieldsChanged(selectedUserEmail, {
                                                        firstName: event.target.value,
                                                    });
                                                }}
                                                variant={InputVariant.primary}
                                                width='auto'
                                                height={InputHeight.large}
                                                placeholder={t('users-and-roles-tab.users.create-user-form.first-name.placeholder')}
                                                label={t('users-and-roles-tab.users.create-user-form.first-name.label')}
                                                error={formErrorsAndWarnings[selectedUserEmail]?.firstName?.error}
                                                warning={formErrorsAndWarnings[selectedUserEmail]?.firstName?.warning}
                                            />
                                            <Input
                                                value={newUsersData.find((user) => user.email === selectedNewUserData?.email)?.lastName}
                                                autoComplete='off'
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    onUserFieldsChanged(selectedUserEmail, {
                                                        lastName: event.target.value,
                                                    });
                                                }}
                                                variant={InputVariant.primary}
                                                width='auto'
                                                height={InputHeight.large}
                                                placeholder={t('users-and-roles-tab.users.create-user-form.last-name.placeholder')}
                                                label={t('users-and-roles-tab.users.create-user-form.last-name.label')}
                                                error={formErrorsAndWarnings[selectedUserEmail]?.lastName?.error}
                                                warning={formErrorsAndWarnings[selectedUserEmail]?.lastName?.warning}
                                            />
                                        </div>
                                        <div className='full-row'>
                                            <div className='label'>{t('users-and-roles-tab.users.create-user-form.role.label')}</div>
                                            <div ref={selectRef}>
                                                <SelectInput
                                                    selectedOption={mapRoleToRow(
                                                        rt,
                                                        rolesAppData.data.find((role) => role.id === selectedNewUserData?.roleId),
                                                        anchorEl,
                                                        actionsAppData.data
                                                    )}
                                                    inputOptions={rolesAppData.data.map((role) =>
                                                        mapRoleToRow(rt, role, anchorEl, actionsAppData.data)
                                                    )}
                                                    setSelectedOption={(selectedItem) => {
                                                        onUserFieldsChanged(selectedNewUserData?.email, {
                                                            roleId: selectedItem.value,
                                                        });
                                                    }}
                                                    placeholder={t('users-and-roles-tab.users.create-user-form.role.placeholder')}
                                                />
                                            </div>
                                        </div>
                                        <div className='full-row select-input'>
                                            <div className='label'>
                                                {t('users-and-roles-tab.users.create-user-form.assigned-systems.label')}
                                            </div>
                                            <WMSelect
                                                maxMenuHeight={200}
                                                allowSelectAll
                                                groupValues
                                                isMulti
                                                isPopout
                                                disablePortal
                                                onChange={(selectedItems) => {
                                                    onUserFieldsChanged(selectedNewUserData?.email, {
                                                        systemIds: selectedItems.map((selectedItem) => selectedItem.value),
                                                    });
                                                }}
                                                value={systemsAppData.data
                                                    .filter((system) => {
                                                        return selectedNewUserData?.systemIds.find(
                                                            (userAssignedSystemId) => userAssignedSystemId === system.id
                                                        );
                                                    })
                                                    .map((system) => mapSystemToRow(system))}
                                                options={systemsAppData.data.map((system) => mapSystemToRow(system))}
                                                itemsName={t('common.systems-select.itemName')}
                                                multiValueGroupLabel={t('common.systems-select.multiValueGroupLabel')}
                                                allOptionsLabel={translationFallback(
                                                    'common.systems-select.allOptionsLabel',
                                                    t,
                                                    'All Systems'
                                                )}
                                                placeholder={t('common.systems-select.placeholder')}
                                                noOptionsMessage={() => t('common.systems-select.noOptionsMessage')}
                                                popoutSelectProps={{
                                                    searchPlaceholder: t('wm-select.search-placeholder'),
                                                    targetDropdownIcon: ChevronBottom,
                                                }}
                                                error={formErrorsAndWarnings[selectedUserEmail]?.systemIds?.error}
                                            />
                                        </div>
                                        <div className='full-row'>
                                            <div className='label'>
                                                {t('users-and-roles-tab.users.create-user-form.login-method.label')}
                                            </div>
                                            <SelectInput
                                                selectedOption={
                                                    accountIdpsWithEmptyState.find(
                                                        (accountIdpWithEmptyState) =>
                                                            accountIdpWithEmptyState.value === selectedNewUserData?.attachToIdpId
                                                    ) || passwordSSOOption
                                                }
                                                inputOptions={accountIdpsWithEmptyState}
                                                setSelectedOption={(selectedItem) => {
                                                    const isPasswrodOption = selectedItem.value === passwordSSOOption.value;
                                                    const createUserUpdatedDetails: Partial<CreateUserDetailsDto> = {
                                                        avoidAttachToDefaultIdp: isPasswrodOption,
                                                        attachToIdpId: isPasswrodOption ? null : selectedItem.value,
                                                    };
                                                    if (selectedItem.value === 'password') {
                                                        createUserUpdatedDetails['activateWithoutPassword'] = false;
                                                    }
                                                    onUserFieldsChanged(selectedUserEmail, createUserUpdatedDetails);
                                                }}
                                            />
                                        </div>
                                        {securityFlagsAppData?.data?.idpUsersNoPassword ? (
                                            <div className='full-row'>
                                                <StyledTitleWithTooltip>
                                                    <Checkbox
                                                        onChange={(selectedItem) => {
                                                            onUserFieldsChanged(selectedNewUserData.email, {
                                                                activateWithoutPassword: selectedItem.target.checked,
                                                            });
                                                        }}
                                                        checked={Boolean(getSelectedUser()?.activateWithoutPassword)}
                                                        disabled={!getSelectedUser()?.attachToIdpId}
                                                    />
                                                    <span className='titleTxt label'>
                                                        {t('users-and-roles-tab.users.create-user-form.no-password')}
                                                    </span>
                                                    <Tooltip
                                                        title={
                                                            <StyledTooltipText>
                                                                {t('users-and-roles-tab.users.create-user-form.no-password-tooltip')}
                                                            </StyledTooltipText>
                                                        }
                                                        arrow>
                                                        <StyledInfo />
                                                    </Tooltip>
                                                </StyledTitleWithTooltip>
                                            </div>
                                        ) : null}
                                        {getSelectedUser()?.attachToIdpId ? (
                                            <div className='full-row'>
                                                <div className='label space-between'>
                                                    <div>{t('users-and-roles-tab.users.create-user-form.sso-id.label')}</div>
                                                    <Tooltip title={t('users-and-roles-tab.users.create-user-form.sso-id.tooltip')}>
                                                        <StyledInfo />
                                                    </Tooltip>
                                                </div>
                                                <Input
                                                    value={getSelectedUser()?.idpNameId}
                                                    autoComplete='off'
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        onUserFieldsChanged(selectedUserEmail, {
                                                            idpNameId: event.target.value,
                                                        });
                                                    }}
                                                    variant={InputVariant.primary}
                                                    width='auto'
                                                    height={InputHeight.large}
                                                    placeholder={t('users-and-roles-tab.users.create-user-form.sso-id.placeholder')}
                                                    className='input'
                                                    error={formErrorsAndWarnings[selectedUserEmail]?.idpNameId?.error}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </AddedUsersContainer>
                            )}
                        </StyledGrid>
                    </Grid>
                </Grid>
            </ContentGrid>
            <WizardDialogButtons item xs={12}>
                {/*{newUsersData?.length > 0 ? (*/}
                {/*    <StyledSurveyRow>*/}
                {/*        <StyledSurveyInfoIcon />*/}
                {/*        <div className='description'>{getSurveyText()}</div>*/}
                {/*    </StyledSurveyRow>*/}
                {/*) : null}*/}
                <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                    {!hideCancelButton && (
                        <Grid item>
                            <WizardDialogSeconaryButton variant={WMButtonVariant.Text} onClick={onCancel}>
                                {t('buttons.cancel')}
                            </WizardDialogSeconaryButton>
                        </Grid>
                    )}
                    <Grid item>
                        <WizardDialogPrimaryButton
                            onClick={onMutateNewUsers}
                            disabled={
                                newUsersData.length === 0 ||
                                createUsersAppData.loading ||
                                (checkboxView && selectedItems.length === 0) ||
                                shouldShowFormErrorToaster
                            }
                            loading={createUsersAppData.loading}>
                            {translatedSendButtonText}
                        </WizardDialogPrimaryButton>
                    </Grid>
                </Grid>
            </WizardDialogButtons>
        </>
    );

    const handleFormErrorsAndWarnings = useCallback(() => {
        const currentFormErrorsAndWarnings = { ...formErrorsAndWarnings };
        newUsersData.forEach((userData) => {
            if (userData.firstName === '') {
                currentFormErrorsAndWarnings[userData.email].firstName = {
                    error: t('users-and-roles-tab.users.create-user-form.errors-and-warnings.firstName.required'),
                    warning: null,
                };
            } else if (userData.firstName.length < 3) {
                currentFormErrorsAndWarnings[userData.email].firstName = {
                    error: null,
                    warning: t('users-and-roles-tab.users.create-user-form.errors-and-warnings.firstName.length'),
                };
            } else {
                currentFormErrorsAndWarnings[userData.email].firstName = { error: null, warning: null };
            }

            if (userData.lastName === '') {
                currentFormErrorsAndWarnings[userData.email].lastName = {
                    error: t('users-and-roles-tab.users.create-user-form.errors-and-warnings.lastName.required'),
                    warning: null,
                };
            } else if (userData.lastName.length < 3) {
                currentFormErrorsAndWarnings[userData.email].lastName = {
                    error: null,
                    warning: t('users-and-roles-tab.users.create-user-form.errors-and-warnings.lastName.length'),
                };
            } else {
                currentFormErrorsAndWarnings[userData.email].lastName = { error: null, warning: null };
            }

            if (userData.systemIds?.length === 0) {
                currentFormErrorsAndWarnings[userData.email].systemIds = {
                    error: t('users-and-roles-tab.users.create-user-form.errors-and-warnings.assignedSystems.required'),
                    warning: null,
                };
            } else if (userData.systemIds?.length > 0) {
                currentFormErrorsAndWarnings[userData.email].systemIds = { error: null, warning: null };
            }

            if (userData.attachToIdpId) {
                currentFormErrorsAndWarnings[userData.email].idpNameId = {
                    error: idpNameIdValidtorError(userData.email, userData.idpNameId),
                    warning: null,
                };
            }
        });

        setFormErrorsAndWarnings(currentFormErrorsAndWarnings);
    }, [newUsersData]);

    useEffect(() => {
        const loadedData =
            !accountIdpsAppData.loading && rolesAppData.data.length > 0 && !systemsAppData.loading && usersAppData.data.length > 0;
        if (loadedData) {
            onLoaded && onLoaded();
        }
        if (loadedData && suggestedUsers && suggestedUsers.length > 0) {
            const newUniqueResults: CreateUserDetailsDto[] = suggestedUsers
                .map((suggestedUser) => setNewUserDetails(suggestedUser.requestedUserEmail, suggestedUser))
                .filter((newUserItem) => !usersAppData.data.find((existingUser) => existingUser.email === newUserItem.email));
            if (newUniqueResults.length > 0) {
                setNewUsersData(newUniqueResults);
                setSelectedUserEmail(newUniqueResults[0].email);
            }
        }
    }, [accountIdpsAppData, rolesAppData, systemsAppData, usersAppData]);

    useEffect(
        () => () => {
            dispatch(usersSlice.actions.createUsersCleanup());
        },
        []
    );

    useEffect(() => {
        if (createUsersAppData.data && createUsersAppData.data) {
            const numberOfUsersCreated = createUsersAppData.data.users.length;
            if (!createUsersAppData.data.errors || createUsersAppData.data.errors.length === 0) {
                setSelectedItems([]);
                setNewUsersData([]);
                setSelectedUserEmail('');
                onFinish(
                    `${numberOfUsersCreated > 1 ? `${numberOfUsersCreated} Users` : 'One user'} invited to your account`,
                    createUsersAppData.data.users
                );
                dispatch(usersSlice.actions.createUsersCleanup());
            } else {
                // check if some failed
                if (numberOfUsersCreated > 0) {
                    const newUsersDataUpdated = newUsersData.filter((newUser) =>
                        createUsersAppData.data.users.find((user) => user.email !== newUser.email)
                    );
                    setSelectedUserEmail(newUsersDataUpdated.length > 0 ? newUsersDataUpdated[0].email : null);
                    setNewUsersData(newUsersDataUpdated);
                    setNotificationMessage({
                        text: `${numberOfUsersCreated > 1 ? `${numberOfUsersCreated} Users` : 'One user'} invited to your account`,
                        variant: WMSnackbarVariant.Success,
                        isOpen: true,
                    });
                }
            }
        }
    }, [createUsersAppData]);

    useEffect(() => {
        if (selectRef.current != null) {
            setAnchorEl(selectRef.current);
        }
    });

    useEffect(() => {
        setHasBusinessDomainTranslatedOptions(hasBusinessDomainOptions.map((option) => ({ ...option, label: t(option.label) })));
    }, [i18n.language]);

    useEffect(() => {
        const firstUsersWereAdded = !selectedUserEmail && newUsersData.length >= 1;
        const lastSelectedUserWasRemoved = selectedUserEmail && !newUsersData.find((user) => user.email === selectedUserEmail);

        if (firstUsersWereAdded || lastSelectedUserWasRemoved) {
            setSelectedUserEmail(newUsersData[0]?.email);
        }

        handleFormErrorsAndWarnings();
    }, [newUsersData]);

    const DialogComponent = newUsersData.length > 0 ? WizardDialogOpen : WizardDialog;

    return (
        <div>
            {fullScreen ? (
                <FullScreenGrid container direction='row' alignItems='stretch' justifyContent='flex-start'>
                    {contentElements}
                </FullScreenGrid>
            ) : (
                <DialogComponent onClose={() => onCancel()} open={showDialog} fullWidth={true} maxWidth={'lg'}>
                    <Grid container direction='row' alignItems='stretch' justifyContent='flex-start'>
                        {contentElements}
                    </Grid>
                </DialogComponent>
            )}
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
            />
        </div>
    );
};

export default BulkUsersMutation;
