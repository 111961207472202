import styled from 'styled-components';
import { Accordion, AccordionSummary, TextField } from '@material-ui/core';

export const StyledAccordion = styled(Accordion)`
    &.root {
        &:before {
            opacity: 0;
        }

        box-shadow: none;
        & .MuiCollapse-root {
            background: #f9fbfe;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        & .MuiAccordionDetails-root {
            padding: 0px 40px 0px 76px;
        }

        & .system-title {
            font-weight: 600;
        }

        border-bottom: 1px solid rgb(225, 233, 245);
    }

    &.expanded {
        margin: 0 !important;
    }

    &.rounded {
        border-radius: 4px !important;
    }

    &.disabled {
        background-color: #f0f4fa !important;
        & .provider-title {
            color: #2f426c;
        }

        & .provider-protocol-tag {
            color: #8d97ae;
        }
    }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    &.root {
        min-height: 48px;
        height: 48px;
        padding: 0 24px 0 50px;

        &.default-cursor {
            cursor: default !important;
        }

        &.Mui-expanded {
            min-height: 48px;
            height: 48px;
        }
    }

    &.content {
        margin: 0 !important;
    }
`;

export const SearchContainer = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    margin-left: 24px;
`;
export const StyledTextField = styled(TextField)`
    & .MuiInputBase-input, .MuiOutlinedInput-input{
        padding: 12px 14px;
        background: #F6F8FC;
    }

    height: 40px;
    &.alias-text{
        height: 30px;
    }
    & .Mui-focused{
    & .MuiOutlinedInput-notchedOutline{
        border-width: 1px;
    }
    & .MuiInputBase-input, .MuiOutlinedInput-input{
        background: #FFFFFF;
    }
    }
    & .Mui-error{
    & .MuiOutlinedInput-notchedOutline{
        border-width: 1px;
    }
    & .MuiInputBase-input, .MuiOutlinedInput-input{
        background: #FEF5F5;
    }
    }
    & .MuiOutlinedInput-notchedOutline{
        border-width: 0;
    }

    & .MuiInputBase-root:hover:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled){
    & .MuiOutlinedInput-notchedOutline{
        border-width: 1px;
        border-color: #354B9B40;
    },
    }

    & .MuiFormHelperText-root{
        margin: 0 6px;
    }
`;
