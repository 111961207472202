import React from 'react';
import { Dialog } from '../shared/dialog';

interface EditorContainerProps {
  toggleEditDialog: () => void;

  showEditDialog: boolean;
}

export const EditContainer = (props: EditorContainerProps) => {
  const title = 'Change Hosting Provider';

  return (
    <Dialog title={title} onClose={props.toggleEditDialog} open={props.showEditDialog}>
      <div className="edit-content">
        To switch your end user experience hosting, please contact your Customer Success Manager.
      </div>
    </Dialog>
  );
}

export default EditContainer;
