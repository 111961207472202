import styled from 'styled-components';
import { TabContent } from '@walkme/ui-core';
import { SidebarDockSVG } from '../../../../../libs/common/assets/icons/SidebarDock';

export const SoonDiv = styled('div')`
    background: #ff91b6;
    border-radius: 2px 8px;
    width: 44px;
    height: 20px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2f426c;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledTabContent = styled(TabContent)`
    width: 100%;
    overflow-y: auto;
`;

export const SystemTypeDiv = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--infographic-blue-blue-tint-3, #d8dff4);
    color: var(--infographic-blue-blue-shade-1, #162a69);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const NavigationBar = styled.div`
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-right: 1px solid var(--blue-primary-primary-tint-6, #e4e9fc);
    background: var(--white, #fff);
    border-radius: 12px 0px 0px 12px;
`;

export const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--white, #fff);
    border-radius: 0px 12px 12px 0px;
    gap: 12px;
    width: 100%;
`;

export const NavigationTab = styled('div')`
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    width: 180px;
    :hover {
        background: #f4f6fb;
    }

    &.disabledTab {
        color: #c5cad6;
        pointer-events: none;
        justify-content: space-between;
    }

    &.selectedTab {
        background: #f4f6fb;
        color: #385feb;
    }
`;

export const SystemHeaderName = styled('div')<{ error: boolean }>`
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding: 6px 8px;
    border-radius: 2px;
    border: ${(props) => (props.error ? '1px solid var(--danger-danger-main, #eb3232)' : 'none')};
    color: ${(props) =>
        props.error ? 'var(--typography-typography-tint-4, #C5CAD6);' : 'var(--typography-typography-main-primary-main, #2f426c)'};
    :hover {
        border-radius: 2px;
        border: 1px dashed var(--blue-primary-primary-main, #385feb);
    }
`;

export const TabName = styled.span`
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const SystemsBackButton = styled.span`
    color: var(--typography-typography-tint-2, #8d97ae);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
`;

export const MiniTitle = styled.span`
    color: var(--blue-primary-primary-main, #385feb);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

export const StyledLexiconIcon = styled('div')`
    width: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledUsersIcon = styled.div`
    display: flex;
    padding: 2px;
`;

export const StyledLexiconTab = styled('div')`
    width: 95%;
    margin-left: 24px;
    margin-top: 20px;
`;

export const StyledAccessibilityIcon = styled('div')`
    width: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
`;

export const StyledSideMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    width: 200px;

    .header .content img {
        margin: 3px;
    }
`;

export const StyledSideMenuItem = styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${({ isSelected }) => (isSelected ? '#385FEB' : '#637191')};
    cursor: pointer;

    span[class^='Tooltip'] {
        display: flex;
    }
`;
