import { TFunction, TOptions } from 'i18next';
import enGeneral from './data/en/general.json';
import jpGeneral from './data/jp/general.json';
import frGeneral from './data/fr/general.json';
import deGeneral from './data/de/general.json';

import rolesReverseData from './reverse-data/roles.json';
import systemsReverseData from './reverse-data/systems.json';
import businessDomainsReverseData from './reverse-data/business-domains.json';
import apiKeysSCopes from './reverse-data/api-key-scopes.json';

import { enUS, ja, fr, de } from 'date-fns/locale';

const reverseData = {
    ...rolesReverseData,
    ...systemsReverseData,
    ...businessDomainsReverseData,
    ...apiKeysSCopes,
};

import { initLocalization, setLocalizationLang, useTranslation } from './localizationBase';

const initLocalizationData = () => {
    initLocalization(
        {
            general: enGeneral,
        },
        {
            general: jpGeneral,
        },
        {
            general: frGeneral,
        },
        {
            general: deGeneral,
        },
        reverseData
    );
};

export const momentLocalesMapping = {
    en: 'en',
    jp: 'ja',
    fr: 'fr',
    de: 'de',
};

export const muiLocalesMapping = {
    en: 'enUS',
    jp: 'jaJP',
    fr: 'frFR',
    de: 'deDE',
};

export const timeLocalesMapping = {
    en: 'en-US',
    jp: 'ja-JP',
    fr: 'fr-FR',
    de: 'de-DE',
};

export const dateFnsLocalesMapping: Record<string, Locale> = {
    en: enUS,
    jp: ja,
    fr: fr,
    de: de,
};

export default initLocalizationData;

export { useTranslation, setLocalizationLang };
