import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';
import { CommonSystemData } from '@walkme-admin-center/libs/exposure-api';

export const useAddExistingSystemToExtension = () => {
    const addExistingSystemToExtensionAppData: AppData<CommonSystemData> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.addExistingSystemToExtension
    );

    return { addExistingSystemToExtensionAppData };
};

export default useAddExistingSystemToExtension;
