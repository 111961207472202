import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@walkme-admin-center/libs/ui-components';
import { generateSnippet } from './generate-snippet';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography/Typography';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useStyles } from './styles';

interface SiteSnippetProps {
    envPath: string;
    systemGuid: string;
}

const SiteSnippet = ({ envPath, systemGuid }: SiteSnippetProps) => {
    const classes = useStyles();
    const [valueToCopy, setValueToCopy] = useState('');

    useEffect(() => {
        const systemGuidWithoutHyphens = systemGuid.replace(/-/g, '');
        setValueToCopy(generateSnippet(envPath, systemGuidWithoutHyphens, window.clientConfig.NX_CDN_URL).trim());
    }, [envPath, systemGuid]);

    return (
        <Box className={classes.container}>
            <Box className={classes.titleContainer}>
                <Box flexGrow={1}>
                    <Typography className={classes.title}>Java Script Snippet</Typography>
                </Box>
                <Box alignSelf='end'>
                    <CopyToClipboard text={valueToCopy}>
                        <Button customClass='link'>Copy Snippet</Button>
                    </CopyToClipboard>
                </Box>
            </Box>
            <Box className={classes.snippet}>
                <SyntaxHighlighter language='htmlbars' style={tomorrowNightBlue} showLineNumbers={true}>
                    {valueToCopy}
                </SyntaxHighlighter>
            </Box>
        </Box>
    );
};

export default SiteSnippet;
