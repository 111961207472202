import styled from 'styled-components';
import { styledTheme } from '../ui-theme/ui-theme';

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow-x: hidden;

    form {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;

        .editable-title {
            color: #77838d;
            font-size: 2em;
            margin: 10px 0 20px 0;
            background: none;
            border: none;
            width: 100%;
        }

        .fitted-form {
            flex-grow: 1;
        }

        .rows {
            flex-grow: 1;
            padding: 30px;
        }

        .inlineRows {
            display: flex;
        }

        .field-column {
            display: flex;
            flex-direction: column;
        }

        .card-section {
            display: flex;
            align-items: center;
        }

        .card-link {
            text-decoration: none;
            color: ${styledTheme.colors.main.inlineLinks};
            margin-left: 5px;
        }

        .card-main-icon {
            color: ${styledTheme.colors.main.mainTitle};
            margin: 0 0 10px 10px;
            cursor: pointer;
        }

        .inline-icon {
            margin-right: 5px;
            margin-bottom: 1px;
        }

        .card-inner-title {
            margin: 0px 0 0px;
            font-weight: 700;
        }

        .field-row {
            display: flex;
            flex-flow: row nowrap;
            line-height: 2em;
            margin: 5px 5px 20px 5px;
            .error {
                color: #800;
                margin-left: 13px;
            }

            &.inline {
                max-width: 350px;
            }

            & > label {
                color: #333;
                width: 230px;
                font-size: 1em;
                line-height: 32px;

                &.small {
                    font-size: 12px;
                    line-height: 27px;
                    width: 50%;
                }
            }

            & > input,
            & > select,
            & > CssTextField,
            & > CssAutoCompleate,
            & > textarea {
                flex: 1;
                padding: 3px 5px;
                font-size: 1em;

                border: 1px solid #ccc;
                border-radius: 3px;

                &.inline {
                    min-width: 250px;
                }
            }

            .custom-input {
                padding: 7px;
                min-width: 480px;
            }
        }
    }
`;

export default Form;
