import { EnvironmentSettings } from '../types/environment-settings';
import { PreferenceKey } from '../consts';

export enum ProcessStatus {
    IDLE,
    IN_PROGRESS,
    SUCCESS,
    ERROR,
}

export type EnvironmentsBySystemObject = {
    systemId: number;
};

export type SelfHostedState = {
    packageDownloadStatus: ProcessStatus;
    settingsByEnv: Record<number, EnvironmentSettings>;
    settingsLoadStatus: ProcessStatus;
    preferences: Record<PreferenceKey, boolean>;
    settingsSaveStatus: ProcessStatus;
    environmentsBySystem: EnvironmentsBySystemObject | {};
    environmentsLoadStatus: ProcessStatus;
};
