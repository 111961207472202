import { WMDivider } from '@walkme/wm-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FlexDivContainer = styled.div`
    display: flex;
`;

const HeaderContainer = styled(FlexDivContainer)`
    justify-content: space-between;
    padding: 32px 24px 12px 24px;
    align-self: stretch;
    flex: 1 0 0;
    background: var(--white, #fff);
    border-radius: 0px 12px 12px 0px;
    gap: 7px;
`;

const ContentContainer = styled(FlexDivContainer)`
    padding: 20px 24px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

const HeaderMainText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

const HeaderSubText = styled('span')`
    color: var(--typography-typography-tint-2, #8d97ae);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const CollapsibleHeader = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const CollapsibleContentDiv = styled(FlexDivContainer)`
    padding: 20px 24px 12px 24px;
    flex-direction: column;
`;

const ControlHeader = styled('span')`
    color: var(--typography-typography-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const ControlSubHeader = styled('span')`
    color: var(--typography-typography-tint-2, #8d97ae);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const ControlContainer = styled(FlexDivContainer)`
    flex-direction: column;
`;

const UrlsContainer = styled(FlexDivContainer)`
    flex-direction: column;
    max-width: 540px;
`;

const MatcherSelectorContainer = styled(FlexDivContainer)`
    align-items: center;
    gap: 24px;
    margin-bottom: 20px;
`;

const FooterContainer = styled.div`
    margin-left: auto;
    padding-bottom: 28px;
    padding-right: 24px;
    height: 30px;
    min-height: 30px;
`;

const LoaderContainer = styled(FlexDivContainer)`
    background: white;
    border-radius: 12px;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const MainContainer = styled(FlexDivContainer)`
    height: 100%;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

const StyledWmDivider = styled(WMDivider)`
    margin-bottom: 14px;
    margin-top: 14px;
`;

const TopSitesModeContainer = styled(FlexDivContainer)`
    flex-direction: column;
`;

const TopSitesModeOptionsContainer = styled(FlexDivContainer)`
    flex-direction: column;
    margin-top: 14px;
`;

const ControlHeaderWithTooltipContainer = styled(FlexDivContainer)`
    gap: 4px;
    align-items: center;
`;

const StyledLink = styled(Link)`
    color: var(--blue-primary-primary-main, #385feb);
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

const StyledTooltipLink = styled(Link)`
    align-self: flex-end;
    color: #92a7f4;
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

const StyledTooltipText = styled('span')`
    color: #ffffff;
    font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

const DropDownContainer = styled.div`
    width: 220px;
`;

const SpecialContentContainer = styled.div`
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #dde5f5;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const SpecialContentMessageHeader = styled('span')`
    color: var(--typography-typography-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

const SpecialContentMessageSubHeader = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    text-align: center;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-line;
`;

export {
    MainContainer,
    HeaderContainer,
    HeaderMainText,
    HeaderSubText,
    ContentContainer,
    CollapsibleContentDiv,
    CollapsibleHeader,
    ControlContainer,
    ControlHeader,
    ControlSubHeader,
    MatcherSelectorContainer,
    FooterContainer,
    LoaderContainer,
    StyledWmDivider,
    TopSitesModeContainer,
    TopSitesModeOptionsContainer,
    ControlHeaderWithTooltipContainer,
    StyledLink,
    StyledTooltipText,
    StyledTooltipLink,
    UrlsContainer,
    DropDownContainer,
    FlexDivContainer,
    SpecialContentContainer,
    SpecialContentMessageHeader,
    SpecialContentMessageSubHeader,
};
