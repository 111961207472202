import { useSelector } from 'react-redux';
import { ProcessStatus, SelfHostedState } from '../self-hosted-state';

const useLoadSettingsStatus = () => {
    const status = useSelector((state: { selfHostedState: SelfHostedState }) => state.selfHostedState.settingsLoadStatus);

    const isInProgress = status === ProcessStatus.IN_PROGRESS;

    return { isInProgress, status };
};

export { useLoadSettingsStatus };
