import styled from 'styled-components';
import { Button, Dialog, DialogContent, Input } from '@walkme/ui-core';

export const StyledDialog = styled(Dialog)`
    .MuiDialogContent-root {
        padding: 0 !important;
        overflow-y: hidden !important;
    }
`;

export const StyledContentContainer = styled.div`
    .header {
        padding: 12px 32px;
        border-top: 1px solid #ebeff7;
        display: flex;
        align-items: center;
        .title {
            font-size: 16px;
            font-weight: bold;
            color: var(--Color-Semantic-Text-text-primary, #2f426c);
        }
    }

    .inputContainer {
        border-top: 1px solid #ebeff7;
        padding: 12px 32px;
        .title {
            color: var(--Color-Semantic-Text-text-primary, #2f426c);
            font-size: 14px;
            font-weight: bold;
        }
    }
`;

export const StyledSecondaryActionButton = styled(Button)`
    color: var(--Typography-Typography-tint-1, #637191) !important;
`;

export const StyledPrimaryActionButton = styled(Button)`
    margin-left: 20px !important;
`;

export const StyledInputContainer = styled.div`
    position: relative;
    display: inline-block;
    margin-top: 12px;
    width: 100%;
`;

export const StyledInput = styled(Input)`
    & input:focus {
        border-width: 1px;
        outline: none;
    }

    & input:disabled {
        background-color: var(--Primaries-Disable-Disable-tint-8, #f8f8f8);
        color: rgba(200, 200, 200, 1);
    }
`;

export const StyledAppIconContainer = styled.div`
    width: 40px;
    height: 40px;
    background-color: #e9f1f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 12px;
`;
