import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppData, NotificationMessage } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import rootReducer from 'apps/home/src/redux/rootReducer';
import { CreateSystemSteps } from '../types/createSystem';
import { AccountsSdk, EditUsersDto, System, SystemPurpose, SystemTypeKey, SystemUsage } from 'wm-accounts-sdk';
import { getSystems, updateAndPollingCreatedSystemStatus } from './systems.slice';
import { BusinessDomain, editMultipleUsers, getUsers } from '@walkme-admin-center/libs/state-management-users';
import { FetchPaginatedSystemAppsDto } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { defaultPaginationConfig, generatePaginationFilterDto, keywordsWhichShouldReturnNoResults } from './createSystem.lib';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import {
    exposureApi,
    GetSystemDataResult,
    SystemMatcher,
    SystemMatchers,
    webSystemsExposureApi,
} from '@walkme-admin-center/libs/exposure-api';
import { PermissionsErrorkey } from '@walkme-admin-center/libs/common';
import { getTranslator } from 'apps/home/src/localization/localizationBase';
import { DeploymentMethods } from '../../../../../pages/home/systems/src/lib/create-system/steps/select-deployment/select-deployment.lib';
import { setEmptyTopUrlsWithDefaultForMatchPatternModeMatchers } from '../../../../state-management-extensions/src/lib/redux/utils';
import { ConfigureTabs } from '../../../../../pages/home/systems/src/lib/create-system/steps/optional-configure/configure.lib';
import { DropdownOption } from '../../../../../pages/home/systems/src/lib/create-system/steps/optional-configure/uuid/uuid.lib';
import { siteConfigApi } from '../../../../../pages/home/systems/src/lib/api/site-config-api';
import { DataCollectionLevel } from '../../../../../pages/home/systems/src/lib/create-system/steps/optional-configure/data-collection/data-collection.lib';
import { Environment } from '../../../../../pages/home/extension-pages/src/lib/types';

export enum SystemAppSupportedPlatformsOptions {
    WEB = 'web',
    MOBILE_WEB = 'mobile-web',
}
export type SystemAppSupportedPlatforms = SystemAppSupportedPlatformsOptions.WEB | SystemAppSupportedPlatformsOptions.MOBILE_WEB;
export enum SystemAppSortByOptions {
    POPULARITY = 'popularity',
    APP = 'app',
}
export type SystemAppSortByOption = SystemAppSortByOptions.POPULARITY | SystemAppSortByOptions.APP;
export enum SystemAppSortOrderOptions {
    ASC = 1,
    DESC = -1,
}
export type SystemAppSortOrder = SystemAppSortOrderOptions.ASC | SystemAppSortOrderOptions.DESC;
export type SystemAppSortFilters = { sortBy?: SystemAppSortByOptions; sortOrder?: SystemAppSortOrder };

export type SystemAppCategory = {
    id: string;
    type: string[];
    displayName: string;
};

export type SystemAppQueryFields = {
    app?: string;
    popularity?: number;
    showInAdminCenter?: boolean;
    status?: string;
    category?: SystemAppCategory;
    supportedPlatforms?: SystemAppSupportedPlatforms;
};
export type Contract = {
    name: string;
    sfId: string;
    type: string;
    isActive: boolean;
    expirationDate: Date;
    startDate: Date;
    accountId: number;
    endUsersMaxUsage: number;
    purchasedSystems: string;
    endUsersUsage: number;
    isViolateMaxEndUsers: boolean;
    isViolateNumSystems: boolean;
    description: string;
    relatedSystems?: any[];
};
export type ContractsData = {
    contracts: Contract[];
    systems: any[];
};

type AppMatch = {
    displayName: string;
    iconUrl: string;
    id: string;
    isCustom: boolean;
    showInAdminCenter: boolean;
    type: string;
};

type AppUrl = {
    order: number;
    url: string;
    app: AppMatch | null;
};

export type SystemAppPagesInfo = {
    endCursor: string;
    limit: number;
    nextPage: number;
    page: number;
    pagingCounter: number;
    prevPage: number;
    startCursor: string;
    totalItems: number;
    totalPages: number;
};

export type SaveSystemProps = {
    displayName?: string;
    systemAppId?: unknown;
    systemTypeKey?: string;
    unassignUser?: boolean;
    associatedSystem?: number;
    contractId?: string;
    purpose?: SystemPurpose;
    usage?: SystemUsage;
    sendFeedback?: boolean;
    movePage?: boolean;
    isLimited?: boolean;
    selfDomains?: string[];
    isActive?: boolean;
};
export interface CreateSystemState {
    isCreateSystemModalOpen: AppData<boolean>;
    isCreateWorkstationModalOpen: AppData<boolean>;
    isCustomMode: AppData<boolean>;
    appUrls: AppData<AppUrl[]>;
    isCreateCustomSystem: AppData<boolean>;
    customAppName: AppData<string>;
    isCreateSystemFeedbackOpen: AppData<boolean>;
    feedback: AppData<string>;
    isNeverShowFeedbackDialog: AppData<boolean>;
    type: AppData<SystemTypeKey.Web | SystemTypeKey.Workstation>;
    step: AppData<CreateSystemSteps>;
    appSearchFilterText: AppData<string>;
    selectedAppFilters: AppData<SystemAppQueryFields>;
    selectedSortFilters: AppData<SystemAppSortFilters>;
    systemApps: AppData<any>;
    selectedSystemApp: AppData<any>;
    purpose: AppData<SystemPurpose>;
    usage: AppData<SystemUsage>;
    contractsData: AppData<ContractsData>;
    selectedContract: AppData<Contract>;
    completeSystemCreationModalIsOpen: AppData<boolean>;
    assignUsers: AppData<string[]>;
    createdSystem: AppData<any>;
    systemAppsContainerLength: AppData<number>;
    systemAppPagesInfo: AppData<any>;
    notificationMessage: NotificationMessage;
    shouldSendFeedback: boolean;
    extensionStatus: AppData<number>;
    workstationAppId: string;
    filterSelectionsAreVisible: boolean;
    selectedBusinessDomains: BusinessDomain[];
    selectedDeploymentMethod: DeploymentMethods;
    systemExtensionData: AppData<GetSystemDataResult>;
    extensionAsDefaultSelected: boolean;
    extensionSelfDomains: string[];
    extensionSelfDomainsAreValid: boolean;
    environmentMatchers: SystemMatchers;
    selectedEnvironment: Environment | undefined;
    selectedEnvironmentMatcher: SystemMatcher;
    selectedSystemNameConfigureStep: string;
    stepErrors: Record<CreateSystemSteps, Record<string, boolean>>;
    configurationActiveTab: ConfigureTabs;
    selectedUUID: DropdownOption;
    selectedUUIDVariableValue: string;
    selectedExpectedFormat: DropdownOption;
    regexpInputValue: string;
    siteConfiguration: any;
    idpSelectDialogIsOpen: boolean;
    selectedDataCollectionLevel: DataCollectionLevel;
    accessibilityDefaultMasterFeatureOnAccount: any;
    accessibilityFeatureIsOn: boolean;
    automaticEnableAccessibilityFeature: boolean;
    saveAndPublishDialogIsOpen: boolean;
}

export const initialCreateSystemState: CreateSystemState = {
    isCreateSystemModalOpen: {
        data: false,
        error: null,
        loading: false,
    },
    isCreateWorkstationModalOpen: {
        data: false,
        error: null,
        loading: false,
    },
    purpose: {
        data: null,
        error: null,
        loading: false,
    },
    usage: {
        data: null,
        error: null,
        loading: false,
    },
    isCustomMode: {
        data: false,
        error: null,
        loading: false,
    },
    appUrls: {
        data: [],
        error: null,
        loading: false,
    },
    isCreateCustomSystem: {
        data: true,
        error: null,
        loading: false,
    },
    customAppName: {
        data: '',
        error: null,
        loading: false,
    },
    isCreateSystemFeedbackOpen: {
        data: false,
        error: null,
        loading: false,
    },
    feedback: {
        data: '',
        error: null,
        loading: false,
    },
    isNeverShowFeedbackDialog: {
        data: false,
        error: null,
        loading: false,
    },
    type: {
        data: null,
        error: null,
        loading: false,
    },
    step: {
        data: CreateSystemSteps.PURPOSE_AND_USAGE,
        error: null,
        loading: false,
    },
    appSearchFilterText: {
        data: '',
        error: null,
        loading: false,
    },
    selectedAppFilters: {
        data: {},
        error: null,
        loading: false,
    },
    systemApps: {
        data: null,
        error: null,
        loading: false,
    },
    selectedSystemApp: {
        data: null,
        error: null,
        loading: false,
    },
    selectedSortFilters: {
        data: { sortBy: SystemAppSortByOptions.POPULARITY, sortOrder: SystemAppSortOrderOptions.DESC },
        error: null,
        loading: false,
    },
    contractsData: {
        data: null,
        error: null,
        loading: false,
    },
    selectedContract: {
        data: null,
        error: null,
        loading: false,
    },
    assignUsers: {
        data: [],
        error: null,
        loading: false,
    },
    completeSystemCreationModalIsOpen: {
        data: false,
        error: null,
        loading: false,
    },
    createdSystem: {
        data: null,
        error: null,
        loading: false,
    },
    extensionStatus: {
        data: null,
        error: null,
        loading: false,
    },
    systemAppsContainerLength: {
        data: null,
        error: null,
        loading: false,
    },
    systemAppPagesInfo: {
        data: null,
        error: null,
        loading: false,
    },
    notificationMessage: {
        text: '',
        variant: WMSnackbarVariant.Success,
        isOpen: false,
    },
    shouldSendFeedback: false,
    workstationAppId: null,
    filterSelectionsAreVisible: false,
    selectedBusinessDomains: [],
    selectedDeploymentMethod: null,
    systemExtensionData: {
        data: null,
        error: null,
        loading: false,
    },
    extensionAsDefaultSelected: false,
    extensionSelfDomains: [],
    extensionSelfDomainsAreValid: true,
    environmentMatchers: null,
    selectedEnvironment: null,
    selectedEnvironmentMatcher: null,
    selectedSystemNameConfigureStep: '',
    stepErrors: {
        [CreateSystemSteps.PURPOSE_AND_USAGE]: {},
        [CreateSystemSteps.SELECT_APPLICATION]: {},
        [CreateSystemSteps.SELECT_CONTRACT]: {},
        [CreateSystemSteps.CONFIGURE_SYSTEM]: { deploymentMethod: false },
    },
    configurationActiveTab: ConfigureTabs.DEPLOYMENT_METHOD,
    selectedUUID: {
        label: 'none',
        value: 'none',
        id: -1,
    },
    selectedUUIDVariableValue: null,
    selectedExpectedFormat: {
        label: 'none',
        value: 'None',
        id: 1,
    },
    regexpInputValue: null,
    siteConfiguration: null,
    idpSelectDialogIsOpen: false,
    selectedDataCollectionLevel: DataCollectionLevel.ENGAGEMENT,
    accessibilityDefaultMasterFeatureOnAccount: null,
    accessibilityFeatureIsOn: false,
    automaticEnableAccessibilityFeature: false,
    saveAndPublishDialogIsOpen: false,
};

const t = getTranslator('general');

const updateStateWithNotificationMessage = ({ state, text, variant, isOpen }) => {
    const message = text === 'Forbidden resource' ? t(PermissionsErrorkey) : text;
    state.notificationMessage.text = message;
    state.notificationMessage.variant = variant;
    state.notificationMessage.isOpen = isOpen;
};

const createSystemSlice = createSlice({
    name: 'createSystemSlice',
    initialState: initialCreateSystemState,
    reducers: {
        setCreateSystemType: (state: CreateSystemState, action: PayloadAction<SystemTypeKey.Web | SystemTypeKey.Workstation>) => {
            state.type.data = action.payload;
            return state;
        },
        setCreateSystemModalVisibility: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.isCreateSystemModalOpen.data = action.payload;
            return state;
        },
        setCreateWorkstationModalVisibility: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.isCreateWorkstationModalOpen.data = action.payload;
            return state;
        },
        setCreateSystemStep: (state: CreateSystemState, action: PayloadAction<CreateSystemSteps>) => {
            state.step.data = action.payload;
            return state;
        },
        setCustomMode: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.isCustomMode.data = action.payload;
            return state;
        },
        updateAppUrl: (state: CreateSystemState, action: PayloadAction<{ updatedAppUrl: AppUrl; appUrlIndex: number }>) => {
            const { updatedAppUrl, appUrlIndex } = action.payload;
            state.appUrls.data = [...state.appUrls.data.slice(0, appUrlIndex), updatedAppUrl, ...state.appUrls.data.slice(appUrlIndex + 1)];
            return state;
        },
        resetAppUrl: (state: CreateSystemState) => {
            state.appUrls.data = [];
            return state;
        },
        getAppUrlStart: (state: CreateSystemState) => {
            state.appUrls.loading = true;
            return state;
        },
        getAppUrlSuccess: (state: CreateSystemState) => {
            state.appUrls.loading = false;
            state.appUrls.error = null;
            return state;
        },
        getAppUrlFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.appUrls.error = action.payload;
            state.appUrls.loading = false;
            return state;
        },
        setIsCreateCustomSystem: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.isCreateCustomSystem.data = action.payload;
            return state;
        },
        setCustomAppName: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.customAppName.data = action.payload;
            return state;
        },
        setCreateSystemFeedbackVisibility: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.isCreateSystemFeedbackOpen.data = action.payload;
            return state;
        },
        setFeedback: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.feedback.data = action.payload;
            return state;
        },
        setNeverShowFeedbackDialog: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.isNeverShowFeedbackDialog.data = action.payload;
            return state;
        },
        setCreateSystemPurpose: (state: CreateSystemState, action: PayloadAction<SystemPurpose>) => {
            state.purpose.data = action.payload;
            return state;
        },
        setCreateSystemUsage: (state: CreateSystemState, action: PayloadAction<SystemUsage>) => {
            state.usage.data = action.payload;
            return state;
        },
        setSearchFilterText: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.appSearchFilterText.data = action.payload;
            return state;
        },
        setAppCategoryFilter: (state: CreateSystemState, action: PayloadAction<SystemAppQueryFields>) => {
            state.selectedAppFilters.data = action.payload;
            return state;
        },
        getSystemAppsStart: (state: CreateSystemState) => {
            state.systemApps.loading = true;
            return state;
        },
        getSystemAppsSuccess: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.systemApps.data = action.payload;
            state.systemApps.loading = false;
            return state;
        },
        getSystemAppsFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            updateStateWithNotificationMessage({ state, text: action.payload, variant: WMSnackbarVariant.Error, isOpen: true });
            state.systemApps.loading = false;
            return state;
        },
        getSystemAppPageInfoSuccess: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.systemAppPagesInfo.data = action.payload;
            return state;
        },
        setSelectedSystemApp: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.selectedSystemApp.data = action.payload;
            return state;
        },
        setSelectedSortFilters: (state: CreateSystemState, action: PayloadAction<SystemAppSortFilters>) => {
            state.selectedSortFilters.data = action.payload;
            return state;
        },
        getContractsStart: (state: CreateSystemState) => {
            state.contractsData.loading = true;
            return state;
        },
        getContractsSuccess: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.contractsData.data = action.payload;
            state.contractsData.loading = false;
            return state;
        },
        getContractsFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            updateStateWithNotificationMessage({ state, text: action.payload, variant: WMSnackbarVariant.Error, isOpen: true });
            state.contractsData.loading = false;
            return state;
        },
        setSelectedContract: (state: CreateSystemState, action: PayloadAction<Contract>) => {
            state.selectedContract.data = action.payload;
            return state;
        },
        setCompleteSystemCreationModalVisibility: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.completeSystemCreationModalIsOpen.data = action.payload;
            return state;
        },
        setSystemAppsContainerLength: (state: CreateSystemState, action: PayloadAction<number>) => {
            state.systemAppsContainerLength.data = action.payload;
            return state;
        },
        createSystemStart: (state: CreateSystemState) => {
            state.createdSystem.loading = true;
            return state;
        },
        createSystemSuccess: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.createdSystem.data = action.payload;
            state.createdSystem.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: t('systems-tab.new-system-form.success-message-systems-created'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });
            return state;
        },
        createSystemFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            updateStateWithNotificationMessage({ state, text: action.payload, variant: WMSnackbarVariant.Error, isOpen: true });
            state.createdSystem.loading = false;
            return state;
        },
        cleanUpNotificationMessage(state: CreateSystemState) {
            state.notificationMessage.isOpen = false;
            return state;
        },
        setNotificationMessage(state: CreateSystemState, action: PayloadAction<NotificationMessage>) {
            state.notificationMessage = action.payload;
            return state;
        },
        setShouldSendFeedback(state: CreateSystemState, action: PayloadAction<boolean>) {
            state.shouldSendFeedback = action.payload;
            return state;
        },
        updateCreatedSystemNameStart: (state: CreateSystemState) => {
            state.createdSystem.loading = true;
            return state;
        },
        updateCreatedSystemNameSuccess: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.createdSystem.data = action.payload;
            state.createdSystem.loading = false;
            return state;
        },
        updateCreatedSystemNameFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.createdSystem.error = action.payload;
            state.createdSystem.loading = false;
            return state;
        },
        assignUsersStart: (state: CreateSystemState, action: PayloadAction<any>) => {
            state.assignUsers.data = action.payload;
            state.assignUsers.loading = true;
            return state;
        },
        assignUsersSuccess: (state: CreateSystemState) => {
            state.assignUsers.data = [];
            state.assignUsers.loading = false;
            return state;
        },
        assignUsersFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.assignUsers.data = [];
            state.assignUsers.loading = false;
            state.assignUsers.error = action.payload;
            return state;
        },
        getExtensionStatusStart: (state: CreateSystemState) => {
            state.extensionStatus.loading = true;
            return state;
        },
        getExtensionStatusSuccess: (state: CreateSystemState, action: PayloadAction<number>) => {
            state.extensionStatus.data = action.payload;
            return state;
        },
        getExtensionStatusFailed: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.extensionStatus.error = action.payload;
            state.extensionStatus.loading = false;
            return state;
        },
        setWorkstationAppId: (state: CreateSystemState, action: PayloadAction<string>) => {
            state.workstationAppId = action.payload;
            return state;
        },
        setFilterSelectionsAreVisible: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.filterSelectionsAreVisible = action.payload;
            return state;
        },
        setSelectedBusinessDomains: (state: CreateSystemState, action: PayloadAction<BusinessDomain[]>) => {
            state.selectedBusinessDomains = action.payload;
            return state;
        },
        setSelectedDeploymentMethod: (state: CreateSystemState, action: PayloadAction<DeploymentMethods>) => {
            state.selectedDeploymentMethod = action.payload;
            return state;
        },
        setExtensionSelfDomains: (state: CreateSystemState, action: PayloadAction<string[]>) => {
            state.extensionSelfDomains = action.payload;
            return state;
        },
        setExtensionSelfDomainsAreValid: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.extensionSelfDomainsAreValid = action.payload;
            return state;
        },
        setExtensionAsDefaultSelected: (state: CreateSystemState, action: PayloadAction<boolean>) => {
            state.extensionAsDefaultSelected = action.payload;
            return state;
        },
        getSystemExtensionStart(state: CreateSystemState) {
            state.systemExtensionData.error = null;
            state.systemExtensionData.loading = true;
            return state;
        },
        getSystemExtensionSuccess(state: CreateSystemState, action: PayloadAction<GetSystemDataResult>) {
            state.systemExtensionData.data = action.payload;
            state.systemExtensionData.loading = false;
            state.systemExtensionData.error = null;

            //update environmentMatchers
            state.environmentMatchers = action.payload.systemData?.matchers;
            return state;
        },
        getSystemExtensionFailed(state: CreateSystemState, action: PayloadAction<string>) {
            state.systemExtensionData.data = null;
            state.systemExtensionData.loading = false;
            state.systemExtensionData.error = action.payload;
            return state;
        },
        setEnvironmentMatchers(state: CreateSystemState, action: PayloadAction<SystemMatchers>) {
            state.environmentMatchers = action.payload;
            return state;
        },
        setSelectedEnvironmentMatcher(state: CreateSystemState, action: PayloadAction<SystemMatcher>) {
            state.selectedEnvironmentMatcher = action.payload;
            return state;
        },
        setSelectedEnvironment(state: CreateSystemState, action: PayloadAction<Environment | undefined>) {
            state.selectedEnvironment = action.payload;
            return state;
        },
        setSelectedSystemNameConfigureStep(state: CreateSystemState, action: PayloadAction<string>) {
            state.selectedSystemNameConfigureStep = action.payload;
            return state;
        },
        setStepErrors(state: CreateSystemState, action: PayloadAction<Record<CreateSystemSteps, Record<string, boolean>>>) {
            state.stepErrors = action.payload;
            return state;
        },
        setConfigurationActiveTab(state: CreateSystemState, action: PayloadAction<ConfigureTabs>) {
            state.configurationActiveTab = action.payload;
            return state;
        },
        setSelectedUUID(state: CreateSystemState, action: PayloadAction<DropdownOption>) {
            state.selectedUUID = action.payload;
            return state;
        },
        setSelectedUUIDVariableValue(state: CreateSystemState, action: PayloadAction<string>) {
            state.selectedUUIDVariableValue = action.payload;
            return state;
        },
        setSelectedExpectedFormat(state: CreateSystemState, action: PayloadAction<DropdownOption>) {
            state.selectedExpectedFormat = action.payload;
            return state;
        },
        setRegexpInputValue(state: CreateSystemState, action: PayloadAction<string>) {
            state.regexpInputValue = action.payload;
            return state;
        },
        setSiteConfiguration(state: CreateSystemState, action: PayloadAction<any>) {
            state.siteConfiguration = action.payload;
            return state;
        },
        setIdpSelectDialogIsOpen(state: CreateSystemState, action: PayloadAction<boolean>) {
            state.idpSelectDialogIsOpen = action.payload;
            return state;
        },
        setSelectedDataCollectionLevel(state: CreateSystemState, action: PayloadAction<DataCollectionLevel>) {
            state.selectedDataCollectionLevel = action.payload;
            return state;
        },
        setAccessibilityDefaultMasterFeatureOnAccount(state: CreateSystemState, action: PayloadAction<any>) {
            state.accessibilityDefaultMasterFeatureOnAccount = action.payload;
            return state;
        },
        setAccessibilityFeatureIsOn(state: CreateSystemState, action: PayloadAction<boolean>) {
            state.accessibilityFeatureIsOn = action.payload;
            return state;
        },
        setAutomaticEnableAccessibilityFeature(state: CreateSystemState, action: PayloadAction<boolean>) {
            state.automaticEnableAccessibilityFeature = action.payload;
            return state;
        },
        setSaveAndPublishDialogIsOpen(state: CreateSystemState, action: PayloadAction<boolean>) {
            state.saveAndPublishDialogIsOpen = action.payload;
            return state;
        },
        resetCreateSystemState: (state: CreateSystemState) => {
            state.isCreateSystemModalOpen.data = false;
            state.purpose.data = null;
            state.usage.data = null;
            state.step.data = CreateSystemSteps.PURPOSE_AND_USAGE;
            state.type.data = null;
            state.isCustomMode.data = false;
            state.isCreateCustomSystem.data = true;
            state.customAppName.data = '';
            state.appUrls.data = [];
            state.isCreateSystemFeedbackOpen.data = false;
            state.feedback.data = '';
            state.appSearchFilterText.data = '';
            state.selectedAppFilters.data = {};
            state.selectedSortFilters.data = { sortBy: SystemAppSortByOptions.POPULARITY, sortOrder: SystemAppSortOrderOptions.DESC };
            state.selectedSystemApp.data = null;
            state.selectedContract.data = null;
            state.completeSystemCreationModalIsOpen.data = false;
            state.systemAppsContainerLength.data = null;
            state.systemAppPagesInfo.data = null;
            state.notificationMessage.isOpen = false;
            state.shouldSendFeedback = false;
            state.selectedDeploymentMethod = null;
            state.extensionSelfDomains = [];
            state.systemExtensionData.data = null;
            state.systemExtensionData.loading = false;
            state.environmentMatchers = null;
            state.selectedEnvironment = null;
            state.selectedEnvironmentMatcher = null;
            state.createdSystem.data = null;
            state.createdSystem.error = null;
            state.createdSystem.loading = false;
            state.stepErrors = {
                [CreateSystemSteps.PURPOSE_AND_USAGE]: {},
                [CreateSystemSteps.SELECT_APPLICATION]: {},
                [CreateSystemSteps.SELECT_CONTRACT]: {},
                [CreateSystemSteps.CONFIGURE_SYSTEM]: { deploymentMethod: false },
            };
            state.siteConfiguration = null;
            state.idpSelectDialogIsOpen = false;
            state.selectedDataCollectionLevel = DataCollectionLevel.ENGAGEMENT;
            state.accessibilityFeatureIsOn = false;
            state.selectedUUID = {
                label: 'none',
                value: 'none',
                id: -1,
            };
            state.selectedUUIDVariableValue = null;
            state.selectedExpectedFormat = {
                label: 'none',
                value: 'None',
                id: 1,
            };
            state.regexpInputValue = null;
            state.saveAndPublishDialogIsOpen = false;
            state.configurationActiveTab = ConfigureTabs.DEPLOYMENT_METHOD;
            return state;
        },
        resetCreateWorkstationState: (state: CreateSystemState) => {
            state.isCreateWorkstationModalOpen.data = false;
            state.type.data = null;
            state.notificationMessage.isOpen = false;
        },
        resetSelectApplicationStep: (state: CreateSystemState) => {
            state.isCustomMode.data = false;
            state.isCreateCustomSystem.data = true;
            state.customAppName.data = '';
            state.appUrls.data = [];
            state.isCreateSystemFeedbackOpen.data = false;
            state.feedback.data = '';
            state.shouldSendFeedback = false;
            state.appSearchFilterText.data = '';
            state.selectedAppFilters.data = {};
            state.selectedSortFilters.data = { sortBy: SystemAppSortByOptions.POPULARITY, sortOrder: SystemAppSortOrderOptions.DESC };
            state.selectedSystemApp.data = null;
            state.systemAppsContainerLength.data = null;
            state.systemAppPagesInfo.data = null;
        },
        resetContractsStep: (state: CreateSystemState) => {
            state.selectedContract.data = null;
            state.completeSystemCreationModalIsOpen.data = false;
        },
        resetUUIDBody: (state: CreateSystemState) => {
            state.selectedUUIDVariableValue = null;
            state.selectedExpectedFormat = {
                label: 'none',
                value: 'None',
                id: 1,
            };
            state.regexpInputValue = null;
        },
    },
});

export { createSystemSlice };

const {
    getSystemAppsStart,
    getSystemAppsSuccess,
    getSystemAppsFailed,
    getAppUrlStart,
    getAppUrlSuccess,
    getAppUrlFailed,
    updateAppUrl,
    resetAppUrl,
    setIsCreateCustomSystem,
    setCustomAppName,
    getContractsStart,
    getContractsSuccess,
    getContractsFailed,
    createSystemStart,
    createSystemSuccess,
    createSystemFailed,
    getSystemAppPageInfoSuccess,
    updateCreatedSystemNameStart,
    updateCreatedSystemNameSuccess,
    updateCreatedSystemNameFailed,
    assignUsersStart,
    assignUsersSuccess,
    assignUsersFailed,
    getExtensionStatusStart,
    getExtensionStatusSuccess,
    getExtensionStatusFailed,
    getSystemExtensionStart,
    getSystemExtensionSuccess,
    getSystemExtensionFailed,
    setSiteConfiguration,
    setSelectedEnvironment,
    setSelectedEnvironmentMatcher,
} = createSystemSlice.actions;
type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk<ReturnType = void> = ThunkAction<ReturnType, rootReducerType, unknown, Action<string>>;

export type GetSystemAppsProps = {
    forceLoad: boolean;
    page?: number;
};

export const getSystemApps =
    ({ forceLoad = false, page = 1 }: GetSystemAppsProps): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (!forceLoad && getState().createSystemState.systemApps.data.length > 0) {
                return;
            }
            dispatch(getSystemAppsStart());
            const searchText = getState().createSystemState.appSearchFilterText.data;
            const appFilters = getState().createSystemState.selectedAppFilters.data;
            const sortFilters = getState().createSystemState.selectedSortFilters.data;
            const systemUsage = getState().createSystemState.usage.data;
            const isForcedEmptyState = keywordsWhichShouldReturnNoResults.includes(searchText.toLowerCase());
            let finalPageSystemApps = [];
            if (!isForcedEmptyState) {
                const generatedPaginationFilterDto = generatePaginationFilterDto({ searchText, appFilters, sortFilters, systemUsage });
                const paginationConfig: FetchPaginatedSystemAppsDto = {
                    ...defaultPaginationConfig,
                    page,
                    ...generatedPaginationFilterDto,
                };

                // check if search text is an url
                if (searchText.includes('.')) {
                    try {
                        const adjustedUrl = addHttpsToUrl(searchText);
                        const appMatch = await dispatch(getAppByUrl(adjustedUrl));
                        paginationConfig.searchText = appMatch?.type;
                    } catch (e) {
                        console.error(e);
                    }
                }

                const pageSystemApps = await AccountsSdk.getInstance().getPaginatedSystemApps(paginationConfig);
                finalPageSystemApps = pageSystemApps?.page?.edges?.map((edge) => edge.node);
                dispatch(getSystemAppPageInfoSuccess(pageSystemApps.page?.pageInfo));
            }
            dispatch(getSystemAppsSuccess(finalPageSystemApps));
        } catch (err) {
            dispatch(getSystemAppsFailed(err.message));
            return;
        }
    };

export const getContracts =
    (forceLoad = false, accountId): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (!forceLoad && getState().createSystemState.contractsData.data?.contracts?.length > 0) {
                return;
            }
            dispatch(getContractsStart());
            const contracts = await AccountsSdk.getInstance().contractsManagement.getContracts();
            dispatch(getContractsSuccess(contracts));
        } catch (err) {
            dispatch(getContractsFailed(err.message));
            return;
        }
    };

export const resetCreateSystemState = (): AppThunk => async (dispatch, getState) => {
    dispatch(createSystemSlice.actions.resetCreateSystemState());
};

export const getAppByUrl =
    (url: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        const response = await AccountsSdk.getInstance().getAppByUrl(url);
        return response[0]?.app;
    };

const addHttpsToUrl = (url: string) => {
    const regex = /^(https?:\/\/)/;
    if (!regex.test(url)) {
        url = 'https://' + url;
    }
    return url;
};

const handleUpdateAppUrl =
    (previousData, url: string, appMatch, order: number): AppThunk =>
    async (dispatch) => {
        const updatedAppUrl: AppUrl = { ...previousData, url: url, app: appMatch, order: order };
        dispatch(updateAppUrl({ updatedAppUrl, appUrlIndex: order }));
    };

export const handleAppUrl =
    (url: string, order: number): AppThunk =>
    async (dispatch, getState) => {
        let appUrlsData, appUrlIndex, isNameReset;
        try {
            dispatch(getAppUrlStart());
            appUrlsData = getState().createSystemState.appUrls.data;
            appUrlIndex = appUrlsData.findIndex((appUrl) => appUrl.order === order);

            isNameReset = appUrlsData[appUrlIndex]?.app?.displayName?.length > 0 || false;

            // check/adjust url
            let adjustedUrl: string;
            if (url.length > 0) {
                adjustedUrl = addHttpsToUrl(url);
            } else {
                if (appUrlIndex !== -1) {
                    dispatch(handleUpdateAppUrl(appUrlsData[appUrlIndex], url, null, order));
                }
                dispatch(getAppUrlFailed('Empty url'));
                dispatch(setIsCreateCustomSystem(true));
                return;
            }
            dispatch(handleUpdateAppUrl(appUrlsData[appUrlIndex], adjustedUrl, null, order));

            // get app match for url
            const appMatch = await dispatch(getAppByUrl(adjustedUrl));
            dispatch(handleUpdateAppUrl(appUrlsData[appUrlIndex], adjustedUrl, appMatch, order));
            dispatch(setCustomAppName(appMatch.displayName));
            dispatch(getAppUrlSuccess());
        } catch (err) {
            dispatch(getAppUrlFailed(err.message));
            dispatch(setIsCreateCustomSystem(true));
            if (isNameReset) {
                dispatch(setCustomAppName(''));
            }
        }
    };

export const saveNewSystem =
    ({
        displayName,
        systemAppId,
        systemTypeKey,
        unassignUser,
        associatedSystem,
        contractId,
        purpose,
        usage,
        sendFeedback = false,
        movePage = false,
        isLimited = false,
        isActive,
        selfDomains,
    }: SaveSystemProps): AppThunk =>
    async (dispatch, getState) => {
        const shouldSendFeedback = getState().createSystemState.shouldSendFeedback;
        const limitations = isLimited ? { IsLimited: 1 } : null;

        try {
            dispatch(createSystemStart());
            const updatedSystem: System = await AccountsSdk.getInstance().createSystem(
                displayName,
                systemAppId,
                systemTypeKey,
                unassignUser,
                associatedSystem,
                contractId,
                purpose,
                usage,
                limitations,
                isActive,
                selfDomains
            );
            dispatch(createSystemSuccess(updatedSystem));
            dispatch(updateAndPollingCreatedSystemStatus(updatedSystem.id, updatedSystem.guid));
            dispatch(getSystems(true));
            dispatch(getUsers(true));

            if (sendFeedback && shouldSendFeedback) {
                dispatch(sendCustomSystemFeedback());
            }

            const currentStep = getState().createSystemState.step.data;
            // create a delay so the user can see the success message
            setTimeout(() => {
                switch (systemTypeKey) {
                    case SystemTypeKey.Web:
                        dispatch(createSystemSlice.actions.setCompleteSystemCreationModalVisibility(false));
                        if (movePage) {
                            dispatch(createSystemSlice.actions.setCreateSystemStep(currentStep + 1));
                        }
                        break;

                    case SystemTypeKey.Workstation:
                        dispatch(createSystemSlice.actions.resetCreateWorkstationState());
                        break;
                }
            }, 750);
        } catch (err) {
            dispatch(createSystemFailed(err.message));
            return;
        }
    };

export const sendCustomSystemFeedback = (): AppThunk => async (dispatch, getState) => {
    const feedback = getState().createSystemState.feedback.data;
    try {
        await AccountsSdk.getInstance().subscrtiptionManagement.createNewCustomSystemFeedback({ feedback });
    } catch (err) {}
};
export const assignUsers =
    (editUsers: EditUsersDto): AppThunk =>
    async (dispatch, getState) => {
        try {
            const assignUserIds = editUsers.editUsers.map((user) => user.userId);
            const assignUsersData = getState().createSystemState.assignUsers.data;

            dispatch(assignUsersStart([...assignUserIds, ...assignUsersData]));
            dispatch(editMultipleUsers(editUsers));
            dispatch(assignUsersSuccess());
        } catch (err) {
            dispatch(assignUsersFailed(err.message));
            return;
        }
    };

export const getExtensionStatus = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getExtensionStatusStart());
        const status = await exposureApi.getUserExtensionStatus();
        dispatch(getExtensionStatusSuccess(status));
    } catch (err) {
        dispatch(getExtensionStatusFailed(err.message));
        return;
    }
};

export const getExtensionData =
    (systemUserId: number, forceLoad = false): AppThunk =>
    async (dispatch, getState) => {
        try {
            const alreadyLoadedWebSystem = getState().createSystemState.systemExtensionData;
            if (alreadyLoadedWebSystem.data && alreadyLoadedWebSystem.data[systemUserId] && !forceLoad) {
                return;
            }

            dispatch(getSystemExtensionStart());
            const getSystemDataResult: GetSystemDataResult = await webSystemsExposureApi.getSystemData(systemUserId);
            // EX2468 - to support saving changes in 'Basic' section when there is a problem with empty top domains
            setEmptyTopUrlsWithDefaultForMatchPatternModeMatchers(getSystemDataResult.systemData);
            dispatch(getSystemExtensionSuccess(getSystemDataResult));
        } catch (err) {
            dispatch(getSystemExtensionFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

export const fetchUserConfiguration =
    (forceLoad = false, systemId): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (!forceLoad && getState().createSystemState.siteConfiguration) {
                return;
            }
            const userSettings = await siteConfigApi.getUserConfiguration(systemId);
            dispatch(setSiteConfiguration(userSettings?.SiteConfiguration));
        } catch (err) {
            return;
        }
    };
