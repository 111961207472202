import { useDispatch, useSelector } from 'react-redux';
import { IAiSettings } from '../../types';
import { useEffect } from 'react';
import { AiHubDashboardState, getSettings } from '../AiHubDashboard.slice';
import { AppData } from '@walkme-admin-center/libs/types';

export const useAiSettings = () => {
    const aiSettingsData: AppData<IAiSettings> = useSelector(
        (state: { aiHubDashboardState: AiHubDashboardState }) => state.aiHubDashboardState.settings
    );

    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(getSettings());
    }, [dispatch]);

    return {
        aiSettingsData,
    };
};

export default useAiSettings;
