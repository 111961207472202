import styled from 'styled-components';

export const IntegrationModal = styled.div`
    display: flex;
    width: 911px;
    height: 790px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: #f7f9fc;
    box-shadow: 0px 36px 52px 0px rgba(47, 61, 83, 0.11);

    @media screen and (max-width: 768px) {
        width: 90%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px 12px 0px 0px;
    background: #fff;
    position: sticky;
    top: 0;
`;

export const FormHeader = styled.div`
    color: #2f426c;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const HeaderStepper = styled.div`
    display: flex;
    padding: 15px 32px 0px 32px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    border-bottom: solid 1px #f5f6f8;
`;

export const SubHeader = styled.div`
    display: flex;
    padding: 20px 32px 16px 32px;
    align-items: center;
    gap: 178px;
    align-self: stretch;
    flex: 1 0 0;
    justify-content: space-between;

    .sub-header-bold {
        font-size: 16px;
        font-weight: 600;
    }
`;

export const IconContainer = styled.div`
    width: 187px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

export const IconBox = styled.div`
    display: flex;
    padding: 4px 12px 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #eceffa;
`;

export const BoxText = styled.div`
    width: 100%;
    color: #162a69;
    text-align: center;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const ModalContent = styled.div`
    display: flex;
    padding: 16px 28px 28px 28px;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    align-items: center;
    overflow: auto;
`;

export const ModalFooter = styled.div`
    display: flex;
    padding: 16px 32px 32px 32px;
    align-items: flex-end;
    align-self: stretch;
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid #e4e9fc;
    background: #fff;
    position: sticky;
    margin-top: auto;
`;
export const CloseButtonContainer = styled.div`
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
