import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { PredefinedDesktopTrackedApps } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { insightsApi } from '../services/insights-api';

export interface DesktopPredefinedAppsState {
    desktopPredefinedApps: AppData<PredefinedDesktopTrackedApps>;
}

const initialDesktopTrackedAppsState: DesktopPredefinedAppsState = {
    desktopPredefinedApps: {
        error: null,
        loading: false,
        data: [],
    },
};

const desktopPredefinedAppsSlice = createSlice({
    name: 'desktopPredefinedAppsSlice',
    initialState: initialDesktopTrackedAppsState,
    reducers: {
        getDesktopPredefinedAppsStart(state: DesktopPredefinedAppsState) {
            state.desktopPredefinedApps.error = null;
            state.desktopPredefinedApps.loading = true;

            return state;
        },
        getDesktopPredefinedAppsSuccess(state: DesktopPredefinedAppsState, action: PayloadAction<PredefinedDesktopTrackedApps>) {
            state.desktopPredefinedApps.data = action.payload;
            state.desktopPredefinedApps.loading = false;
            state.desktopPredefinedApps.error = null;

            return state;
        },
        getDesktopPredefinedAppsFailed(state, action: PayloadAction<string>) {
            state.desktopPredefinedApps.loading = false;
            state.desktopPredefinedApps.error = action.payload;

            return state;
        },
    },
});

export { desktopPredefinedAppsSlice };
type DesktopPredefinedAppsStateType = ReturnType<typeof desktopPredefinedAppsSlice.reducer>;

const {
    getDesktopPredefinedAppsStart,
    getDesktopPredefinedAppsSuccess,
    getDesktopPredefinedAppsFailed,
} = desktopPredefinedAppsSlice.actions;

type AppThunk = ThunkAction<void, DesktopPredefinedAppsStateType, unknown, Action<string>>;

export const getDesktopPredefinedApps = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getDesktopPredefinedAppsStart());

        const desktopTrackedApps = await insightsApi.getPredefinedDesktopTrackedApps();

        dispatch(getDesktopPredefinedAppsSuccess(desktopTrackedApps));
    } catch (err) {
        dispatch(getDesktopPredefinedAppsFailed(err.message));

        return;
    }
};
