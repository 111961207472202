import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledAppListContainer, LoadingContainer, StyledAppList, StyledLoadingSpinner } from './app-list.styles';
import { CreateSystemState, SystemAppSortFilters, useSystemApps } from '@walkme-admin-center/libs/state-management-systems';
import { AppCard } from './app-card';
import { createSystemSlice } from '@walkme-admin-center/libs/state-management-systems';
import { EmptyStateSearch } from './empty-state-search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Spinner } from '@walkme/ui-core';
import { AccountsSdk } from 'wm-accounts-sdk';
import { defaultPaginationConfig } from '../../../../../../../../../libs/state-management-systems/src/lib/redux/createSystem.lib';

export const AppList = () => {
    const [systemAppsContainer, setSystemAppsContainer] = useState<any>(null);
    const [isPaginationLoading, setIsPaginationLoading] = useState(false);
    const [systemsPage, setSystemsPage] = useState(2);
    const dispatch = useDispatch();
    const { systemAppsAppData, systemAppPagesInfo, selectedSystemApp, generatedPaginationFilterDto } = useSystemApps();
    const isInitialSystemAppsLoading = systemAppsAppData.loading;
    const systemApps: any = systemAppsAppData.data;
    const loaderRef = useRef(null);
    const selectedSortFilters: SystemAppSortFilters = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSortFilters.data
    );

    const fetchNextSystemApps = useCallback(async () => {
        if (isPaginationLoading || systemsPage > systemAppPagesInfo?.totalPages) return;
        try {
            setIsPaginationLoading(true);
            const pageSystemApps = await AccountsSdk.getInstance().getPaginatedSystemApps({
                ...defaultPaginationConfig,
                ...generatedPaginationFilterDto,
                page: systemsPage,
            });
            setSystemAppsContainer((currSystemApps) => [...currSystemApps, ...pageSystemApps?.page?.edges?.map((edge: any) => edge.node)]);
            setSystemsPage((curPage) => curPage + 1);
            setIsPaginationLoading(false);
        } catch (error) {
            console.error(error);
        }
    }, [systemsPage, isPaginationLoading, systemAppPagesInfo]);

    const getInitialSystemApps = () => {
        setSystemAppsContainer(systemApps);
        setSystemsPage(2);
    };

    useEffect(getInitialSystemApps, [systemApps]);
    useEffect(() => {
        dispatch(createSystemSlice.actions.setSystemAppsContainerLength(systemAppsContainer?.length));
    }, [systemAppsContainer]);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchNextSystemApps();
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [fetchNextSystemApps, systemAppsContainer]);

    if (!systemApps || isInitialSystemAppsLoading)
        return (
            <LoadingContainer>
                <CircularProgress size={50} thickness={4} />
            </LoadingContainer>
        );

    return systemAppsContainer?.length === 0 ? (
        <EmptyStateSearch />
    ) : (
        <StyledAppListContainer>
            <StyledAppList>
                {systemAppsContainer?.map((app) => {
                    const onClick = () => {
                        dispatch(createSystemSlice.actions.setSelectedSystemApp(app));
                    };
                    return (
                        <AppCard
                            key={app._id + selectedSortFilters.sortBy}
                            displayName={app.displayName}
                            iconSrc={app.icons?.highRes}
                            onClick={onClick}
                            selected={selectedSystemApp?._id === app._id}
                        />
                    );
                })}
            </StyledAppList>
            <StyledLoadingSpinner ref={loaderRef}>{isPaginationLoading ? <Spinner size={30} /> : null}</StyledLoadingSpinner>
        </StyledAppListContainer>
    );
};
