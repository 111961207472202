import React from 'react';
import LoginStep from './slack-steps/login-step';
import styled from 'styled-components';
import YourAppsStep from './slack-steps/your-apps-step';
import CreateNewAppStep from './slack-steps/create-new-app-step';
import PasteManifest from './slack-steps/paste-manifest';
import ClickCreate from './slack-steps/click-create';
import InstallApp from './slack-steps/install-app';
import VerifyApp from './slack-steps/verify-app';

const ScrollContainer = styled.div`
    display: flex;
    width: 767px;
    padding: 32px 0px;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
`;

const Line = styled.div`
    border-left: 1.5px dashed #6c89f0;
    height: 32px;
`;

const ManifestStep = () => {
    return (
        <ScrollContainer>
            <LoginStep />
            <Line />
            <YourAppsStep />
            <Line />
            <CreateNewAppStep />
            <Line />
            <PasteManifest />
            <Line />
            <ClickCreate />
            <Line />
            <InstallApp />
            <Line />
            <VerifyApp />
        </ScrollContainer>
    );
};

export default ManifestStep;
