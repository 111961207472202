import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountIdpsState, getSecurityFlags } from '../redux/account-idps.slice';

export const useSecurityFlags = () => {
    const securityFlagsAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.securityFlags);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSecurityFlags());
    }, [dispatch]);

    return {
        securityFlagsAppData,
    };
};

export default useSecurityFlags;
