import React from 'react';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const StyledTitle = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E1E9F5;
  margin: 0 17px;
  padding: 15px 0;
`

const StyleCloseButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #a4aac2;
`

const StyledContent = styled(MuiDialogContent)`
  padding: 15px 17px;
`

const StyledActions = styled(MuiDialogActions)`
  padding: 25px 17px;
`

const StyledButton = styled(Button)`
  color: #ffffff;
  background-color: #3b61eb;
  border-radius: 20px;

  &:hover {
    background-color: #3b61eb;
    opacity: 0.5;
  }
`

interface DialogProps {
  title: string;

  onClose: () => void;

  open: boolean;

  children: React.ReactNode;
}

export const Dialog = (props: DialogProps) => {
  return (
    <MuiDialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open} maxWidth="xs">
      <StyledTitle>
        <Typography variant="h5">{props.title}</Typography>
        <StyleCloseButton onClick={props.onClose}>
          <CloseIcon />
        </StyleCloseButton>
      </StyledTitle>
      <StyledContent>
        {props.children}
      </StyledContent>
      <StyledActions>
        <StyledButton autoFocus onClick={props.onClose}>
          OK
        </StyledButton>
      </StyledActions>
    </MuiDialog>
  );
}
