import React from 'react';
import { IconButton, InputBase, MenuItem, Select } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@walkme/ui-icons/large';
import { getTheme } from '@walkme/ui-styles';
import { format } from 'date-fns';
import { CaptionProps, useNavigation } from 'react-day-picker';
import styled from 'styled-components';

const NavigationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 248px;
    margin: 0 16px 5px;
    border-bottom: 1px solid ${(props) => getTheme(props).palette.colors.typography.tint8};
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    .monthContainer {
        min-width: 118px;
        text-align: right;
    }
`;

const monthNumbers = Array.from(Array(12).keys());

const getYearsArray = (startYear: number) => {
    const endYear = new Date().getFullYear() + 3;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    return years;
};

export const Navigation = (props: CaptionProps & { fromYear: number }) => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();

    const years = getYearsArray(props.fromYear);

    const onMonthChange = ({ target: { value } }) => {
        goToMonth(new Date(props.displayMonth.setMonth(value as number)));
    };

    const onYearChange = ({ target: { value } }) => {
        goToMonth(new Date(props.displayMonth.setFullYear(value as number)));
    };

    return (
        <NavigationContainer>
            <div>
                <IconButton data-testid='datePickerPreviousMonth' onClick={() => previousMonth && goToMonth(previousMonth)}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <div className='monthContainer'>
                <Select
                    input={<InputBase />}
                    MenuProps={{ PopoverClasses: { root: '' } }}
                    value={props.displayMonth.getMonth()}
                    onChange={onMonthChange}>
                    {monthNumbers.map((monthNumnber) => (
                        <MenuItem value={monthNumnber} key={monthNumnber}>
                            {format(new Date(new Date().getFullYear(), monthNumnber, 1), 'LLLL')}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                <Select input={<InputBase />} value={props.displayMonth.getFullYear()} onChange={onYearChange}>
                    {years.map((year) => (
                        <MenuItem value={year} key={year}>
                            {format(new Date().setFullYear(year), 'yyyy')}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                <IconButton data-testid='datePickerNextMonth' onClick={() => nextMonth && goToMonth(nextMonth)}>
                    <ChevronRight />
                </IconButton>
            </div>
        </NavigationContainer>
    );
};
