import React from 'react';
import { Box, IconButton, Step, Stepper, Divider } from '@material-ui/core';
import { IdpConfigStepsData } from './types/types';
import { StyledStepperTitle, StyledStepLabel } from './styles/styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface StepperDialogProps {
    activeStep: number;
    handleBack: () => void;
    handleClose: () => void;
}

export const ExternalIdpStepper = ({ activeStep, handleBack, handleClose }: StepperDialogProps) => {
    const { t } = useTranslation('general');
    return (
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Box display='flex' flexDirection='row' alignItems='center'>
                <IconButton onClick={() => handleBack()}>
                    <img src='assets/icons/icon back arrow.svg' alt='back' style={{ height: '12px' }} />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <StyledStepperTitle>{t('security-tab.idps.setup.title')}</StyledStepperTitle>
            </Box>
            <Stepper activeStep={activeStep} style={{ width: '600px', padding: '12px' }}>
                {IdpConfigStepsData.map(
                    (stepData, index) =>
                        !stepData.hideInWizard && (
                            <Step key={index}>
                                <StyledStepLabel>{t(stepData.displayName)}</StyledStepLabel>
                            </Step>
                        )
                )}
            </Stepper>
            <IconButton onClick={() => handleClose()}>
                <img src='assets/icons/close.svg' alt='close' style={{ height: '12px' }} />
            </IconButton>
        </Box>
    );
};

export default ExternalIdpStepper;
