import { Box, ClickAwayListener, Divider, Drawer, Tab, Tabs, Typography } from '@material-ui/core';
import { updateExistingSystemName } from '@walkme-admin-center/libs/state-management-systems';
import { editMultipleUsers, usersSlice, UsersState, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { AppData } from '@walkme-admin-center/libs/types';
import { WMChip, WMIconEdit, WMSnackbar, WMSnackbarVariant, WMTextField } from '@walkme/wm-ui';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { System, UsersBulkMutationResult } from 'wm-accounts-sdk';
import UserSelection from './select_users';
import { WebSystemEnvironments } from '@walkme-admin-center/pages/home/extension-pages';
import { PagesHomeImportLexicon } from '@walkme-admin-center/pages/home/import-lexicon';
import { importLexiconApi } from '@walkme-admin-center/libs/import-lexicon-api';
import CloseIcon from '@material-ui/icons/Close';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import {
    StyledDrawer,
    DrawerHeaderContainer,
    DrawerHeaderStyled,
    StyledDivider,
    DrawerButtonsContainer,
    DrawerContentContainer,
    DrawerContentStyled,
    DrawerButtonsStyled,
    StyledWMButton,
    StyledTabBoxes,
    StyledTabContent,
    StyledTab,
    StyledTabs,
} from './system_details.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

interface SystemDetailsProps {
    isAdmin: boolean;
    system?: System;
    systemStatusIndicator: JSX.Element;
    onClose: () => void;
    showDrawer: boolean;
}
const DISABLE_LEXICON_IMPORT_FF = 'disableLexiconTranslationsImport';

export const SystemDetails = ({ system, systemStatusIndicator, onClose, showDrawer, isAdmin }: SystemDetailsProps) => {
    const { t } = useTranslation('general');
    const [activeTab, setActiveTab] = useState(0);
    const [updatedSystemName, setUpdatedSystemName] = useState(system.displayName);
    const [hover, setHover] = useState(false);
    const [clicked, setclicked] = useState(false);
    const [isLexiconEnabled, setIsLexiconEnabled] = useState(false);
    const dispatch = useDispatch();
    const { usersAppData } = useUsers();
    const users = usersAppData.data;
    const updatedSystemsAppData: AppData<UsersBulkMutationResult> = useSelector(
        (state: { usersState: UsersState }) => state.usersState.updateUsers
    );
    const currentSnackbarMessage = useSelector((state: { usersState: UsersState }) => state.usersState.notificationMessage);

    const handleSnackBarClose = (event, reason) => {
        dispatch(usersSlice.actions.cleanUpNotificationMessage());
    };

    useEffect(() => {
        if (updatedSystemsAppData.data) {
            const message =
                updatedSystemsAppData.data.users.length > 1
                    ? `${updatedSystemsAppData.data.users.length} ${t(
                          'systems-tab.all-systems-page.assign-users-form.success-message-update-users'
                      )}`
                    : `${updatedSystemsAppData.data.users[0].firstName} ${updatedSystemsAppData.data.users[0].lastName} ${t(
                          'systems-tab.all-systems-page.assign-users-form.success-message-update-user'
                      )}`;
            dispatch(usersSlice.actions.setNotificationMessage({ text: message, variant: WMSnackbarVariant.Success, isOpen: true }));
            dispatch(usersSlice.actions.updateUsersCleanup());
        }
    }, [dispatch, updatedSystemsAppData]);

    useEffect(() => {
        importLexiconApi
            .isLexiconEnabled(system.guid)
            .then((result) => {
                setIsLexiconEnabled(result);
            })
            .catch((error) => {
                setIsLexiconEnabled(false);
            });
    }, [system]);

    const RemoveUsersFromSystem = useCallback(
        (detailedUSersToRemove) => {
            const usersToUpdate = users.filter((user) => detailedUSersToRemove.find((row) => user.id === row.id));
            const finalUsers = usersToUpdate.map((user) => {
                return {
                    userId: user.id,
                    systemIds: user.systems.filter((existingSystem) => existingSystem.id !== system.id).map((system) => system.id),
                };
            });
            dispatch(editMultipleUsers({ editUsers: finalUsers }));
        },
        [dispatch, system.id, users]
    );

    const AddUsersToSystem = useCallback(
        (detailedUSersToAdd) => {
            const usersToUpdate = users.filter((user) => detailedUSersToAdd.find((row) => user.id === row.id));
            const finalUsers = usersToUpdate.map((user) => {
                return {
                    userId: user.id,
                    systemIds: user.systems.map((system) => system.id).concat(system.id),
                };
            });
            dispatch(editMultipleUsers({ editUsers: finalUsers }));
        },
        [dispatch, system.id, users]
    );

    const updateSystemCallback = useCallback(
        (updatedDisplayName: string) => {
            dispatch(updateExistingSystemName(system, updatedDisplayName));
        },
        [dispatch, system]
    );

    const handleClickAway = () => {
        hover && updatedSystemName != system.displayName && updateSystemCallback(updatedSystemName);
        setHover(false);
        setclicked(false);
    };

    const onChange = (event: ChangeEvent, newTabId: number): void => {
        setActiveTab(newTabId);
    };

    const usersTab = useCallback(() => {
        return (
            <UserSelection
                selectedSystem={system}
                RemoveUsersFromSystem={RemoveUsersFromSystem}
                AddUsersToSystem={AddUsersToSystem}
                users={users}
            />
        );
    }, [users, updatedSystemsAppData]);

    const tabsOptions = [
        {
            label: t('systems-tab.all-systems-page.assign-users-form.environments'),
            element: <WebSystemEnvironments selectedSystem={system} />,
            id: 1,
        },
        {
            label: t('systems-tab.systems-table.users'),
            element: usersTab(),
            id: 2,
        },
    ];

    const disableLexiconImport = system.featureFlags.some((ff) => ff.flagName === DISABLE_LEXICON_IMPORT_FF);

    if (isLexiconEnabled && isAdmin && !disableLexiconImport) {
        tabsOptions.push({
            label: t('systems-tab.all-systems-page.assign-users-form.lexicon'),
            element: <PagesHomeImportLexicon systemId={system.id} />,
            id: 3,
        });
    }

    const DrawerHeader = (onClose: () => void) => {
        return (
            <DrawerHeaderContainer>
                <DrawerHeaderStyled>
                    <Box display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='row' flexGrow={1}>
                            <img src={retrieveSystemIcon(system.settings)} width='40px' height='40px' style={{ marginRight: '20px' }} />
                            <Box display='flex' flexDirection='column'>
                                <Box display='flex' flexDirection='row'>
                                    <Typography
                                        style={{
                                            fontFamily: 'ProximaNova',
                                            color: '#8D97AE',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}>
                                        {system.settings ? system.settings.displayName : 'Default'}
                                    </Typography>
                                    {systemStatusIndicator}
                                </Box>
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Box
                                        display={'flex'}
                                        style={{ maxHeight: '18px' }}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => (clicked ? null : setHover(false))}>
                                        {hover && <WMIconEdit style={{ width: '12px', marginRight: '5px' }} />}
                                        <WMTextField
                                            value={updatedSystemName}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setUpdatedSystemName(event.target.value)}
                                            onClick={() => setclicked(true)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') handleClickAway();
                                            }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'ProximaNova',
                                                    color: '#2F426C',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    border: 0,
                                                    padding: 0,
                                                },
                                            }}
                                        />
                                    </Box>
                                </ClickAwayListener>
                            </Box>
                        </Box>
                        <WMChip label={system.systemTypeKey} rounded style={{ width: 'fit-content', marginTop: '14px' }} />
                    </Box>
                    <CloseIcon onClick={onClose} />
                </DrawerHeaderStyled>
                <StyledDivider />
            </DrawerHeaderContainer>
        );
    };

    const DrawerContent = () => {
        return (
            <DrawerContentContainer>
                <DrawerContentStyled>
                    <StyledTabBoxes>
                        <StyledTabs
                            variant='fullWidth'
                            value={activeTab}
                            classes={{
                                indicator: 'styled-indicator',
                            }}
                            onChange={onChange}>
                            {tabsOptions.map((tab) => {
                                return <StyledTab key={tab.id} label={tab.label} />;
                            })}
                        </StyledTabs>
                        <StyledTabContent>{tabsOptions[activeTab].element}</StyledTabContent>
                    </StyledTabBoxes>
                </DrawerContentStyled>
            </DrawerContentContainer>
        );
    };

    const DrawerButtons = () => {
        return (
            <DrawerButtonsContainer>
                <DrawerButtonsStyled></DrawerButtonsStyled>
            </DrawerButtonsContainer>
        );
    };

    return (
        <>
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
            />
            <StyledDrawer anchor='right' open={showDrawer} onClose={onClose}>
                {DrawerHeader(onClose)}
                {DrawerContent()}
                {DrawerButtons()}
            </StyledDrawer>
        </>
    );
};

export default SystemDetails;
