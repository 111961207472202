import { RuleType } from 'packages/libs/state-mangment-data-integration/src/lib/types';

export const checkRule = (channelName: string, newRule: string, ruleType: RuleType) => {
    switch (ruleType) {
        case RuleType.CONTAINS:
            return channelName.includes(newRule);
        case RuleType.START_WITH:
            return channelName.startsWith(newRule);
        case RuleType.END_WITH:
            return channelName.endsWith(newRule);
        case RuleType.IS:
            return channelName === newRule;
        default:
            return false;
    }
};
