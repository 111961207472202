import { TableCustomCell } from '..';
import { Role, WMClientSystem } from 'wm-accounts-sdk';

export interface PartnerRow {
    name: TableCustomCell;
    systems?: TableCustomCell;
    users: number;
    status: TableCustomCell;
}

// TODO: consider moving this and PartnerAccountData to the accounts-sdk
export interface PartnerUserData {
    id: number;
    role: Role;
    email: string;
    assignedSystems: WMClientSystem[];
    firstName: string;
    lastName: string;
    lastLogin: string;
    createdDate: string;
}

export interface PartnerAccountData {
    accountId: number;
    accountGuid: string;
    accountName: string;
    partnerUsers: PartnerUserData[];
    systems: WMClientSystem[];
}

export interface InviteData {
    role: Role;
    email: string;
    createdDate: string;
    expiryDate: string;
    assignedSystems: WMClientSystem[];
}

export interface CreatePartnerDetailsDto {
    email: string;
    roleId: number;
    systemIds: number[];
}

export enum PartnerAccountStatus {
    Active = 'Active',
    NoUsersOrSystems = 'NoUsersOrSystems',
}

export enum PartnerUserStatus {
    Active = 'Active',
    NoSystems = 'NoSystems',
}

export enum InviteStatus {
    Pending = 'Pending',
    Expired = 'Expired',
}
