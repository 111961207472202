import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconInfo = (props: SvgIconProps) => {
    return (
        <SvgIcon width={15} height={15} viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <circle cx='7.5' cy='7.5' r='6' stroke='currentColor' />
            <circle cx='0.5' cy='0.5' r='0.5' transform='matrix(1 0 0 -1 7 5)' fill='currentColor' />
            <path
                d='M8 10.5V10.5C7.72386 10.5 7.5 10.2761 7.5 10V7C7.5 6.72386 7.27614 6.5 7 6.5V6.5'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M6 6.5C6 6.22386 6.22386 6 6.5 6H7V7H6.5C6.22386 7 6 6.77614 6 6.5Z' fill='currentColor' />
            <path d='M9 10.5C9 10.7761 8.77614 11 8.5 11L8 11L8 10L8.5 10C8.77614 10 9 10.2239 9 10.5Z' fill='currentColor' />
        </SvgIcon>
    );
};

export { IconInfo };
