import { WMSelect } from '@walkme/wm-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from '../../../../../../apps/home/src/localization/localizationBase';

export const WMselectWrapper = ({ ...props }): ReactElement => {
    const { t } = useTranslation('general');
    return <WMSelect placeholder={t('wm-select.placeholder')} {...props} />;
};

export default WMselectWrapper;
