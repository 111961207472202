import { DataIntegrationState } from '../redux/data-integration.slice';
import { useSelector } from 'react-redux';
import { IntegrationType } from '../types';

export const useSlack = () => {
    const slackIntegrationsData = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.integrations.data.find((integration) => integration.type === IntegrationType.SLACK);
    });

    return {
        slackIntegrationsData,
    };
};

export default useSlack;
