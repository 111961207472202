import { AppData } from '@walkme-admin-center/libs/types';
import { UpdatedSystemEnvironment } from '@walkme-admin-center/libs/analytics-api';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useCreatedEnvironment = () => {
  const createdEnvironmentAppData: AppData<UpdatedSystemEnvironment> = useSelector(
    (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.createdEnvironment
  );

  return { createdEnvironmentAppData };
};

export default useCreatedEnvironment;