import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
    AccountsSdk,
    WMAccountServiceApplication,
    WMScope,
    UpdateServiceApplicationDTO,
    CreateServiceApplicationDTO,
    WMApplication,
    WMAccountApplication,
} from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { DeleteServiceApplicationDTO } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/applications-types';

export interface ApplicationsState {
    accountServiceApps: AppData<WMAccountServiceApplication[]>;
    availableScopes: AppData<WMScope[]>;
    updatedServiceApp: AppData<WMAccountApplication>;
    deleteServiceApp: AppData<boolean>;
    createdServiceApp: AppData<WMApplication>;
}

export const initialApplicationsState: ApplicationsState = {
    updatedServiceApp: {
        loading: false,
        error: null,
        data: null,
    },
    accountServiceApps: {
        loading: false,
        error: null,
        data: null,
    },
    availableScopes: {
        loading: false,
        error: null,
        data: null,
    },
    deleteServiceApp: {
        loading: false,
        error: null,
        data: null,
    },
    createdServiceApp: {
        loading: false,
        error: null,
        data: null,
    },
};

const applicationsSlice = createSlice({
    name: 'applicationsSlice',
    initialState: initialApplicationsState,
    reducers: {
        getScopesStart(state: ApplicationsState) {
            state.availableScopes.error = null;
            state.availableScopes.loading = true;
            return state;
        },
        getScopesSuccess(state: ApplicationsState, action: PayloadAction<WMScope[]>) {
            state.availableScopes.data = action.payload;
            state.availableScopes.loading = false;
            state.availableScopes.error = null;
            return state;
        },
        getScopesFailed(state, action: PayloadAction<string>) {
            state.availableScopes.loading = false;
            state.availableScopes.error = action.payload;
            return state;
        },
        getAccountServiceAppsStart(state: ApplicationsState) {
            state.accountServiceApps.error = null;
            state.accountServiceApps.loading = true;
            return state;
        },
        getAccountServiceAppsSuccess(state: ApplicationsState, action: PayloadAction<WMAccountServiceApplication[]>) {
            state.accountServiceApps.data = action.payload;
            state.accountServiceApps.loading = false;
            state.accountServiceApps.error = null;
            return state;
        },
        getAccountServiceAppsFailed(state, action: PayloadAction<string>) {
            state.accountServiceApps.loading = false;
            state.accountServiceApps.error = action.payload;
            return state;
        },
        createdAccountServiceAppStart(state: ApplicationsState) {
            state.createdServiceApp.data = null;
            state.createdServiceApp.error = null;
            state.createdServiceApp.loading = true;
            return state;
        },
        createdAccountServiceAppSuccess(state: ApplicationsState, action: PayloadAction<WMApplication>) {
            state.createdServiceApp.data = action.payload;
            state.createdServiceApp.loading = false;
            state.createdServiceApp.error = null;
            return state;
        },
        createdAccountServiceAppFailed(state, action: PayloadAction<string>) {
            state.createdServiceApp.loading = false;
            state.createdServiceApp.error = action.payload;
            return state;
        },
        updateAccountServiceAppStart(state: ApplicationsState) {
            state.updatedServiceApp.error = null;
            state.updatedServiceApp.loading = true;
            return state;
        },
        updateAccountServiceAppSuccess(state: ApplicationsState, action: PayloadAction<WMAccountApplication>) {
            state.updatedServiceApp.data = action.payload;
            state.updatedServiceApp.loading = false;
            state.updatedServiceApp.error = null;
            return state;
        },
        updateAccountServiceAppFailed(state, action: PayloadAction<string>) {
            state.updatedServiceApp.loading = false;
            state.updatedServiceApp.error = action.payload;
            return state;
        },
        deleteAccountServiceAppStart(state: ApplicationsState) {
            state.deleteServiceApp.error = null;
            state.deleteServiceApp.loading = true;
            return state;
        },
        deleteAccountServiceAppSuccess(state: ApplicationsState, action: PayloadAction<void>) {
            state.deleteServiceApp.data = true;
            state.deleteServiceApp.loading = false;
            state.deleteServiceApp.error = null;
            return state;
        },
        deleteAccountServiceAppFailed(state, action: PayloadAction<string>) {
            state.deleteServiceApp.loading = false;
            state.deleteServiceApp.error = action.payload;
            return state;
        },
        cleanupUpdatedAccountServiceApp: (state: ApplicationsState) => {
            state.updatedServiceApp.data = null;
            state.updatedServiceApp.error = null;
            state.updatedServiceApp.loading = false;
            return state;
        },
        cleanupCreatedAccountServiceApp: (state: ApplicationsState) => {
            state.createdServiceApp.data = null;
            state.createdServiceApp.error = null;
            state.createdServiceApp.loading = false;
            return state;
        },
        cleanupDeletedAccountServiceApp: (state: ApplicationsState) => {
            state.deleteServiceApp.data = null;
            state.deleteServiceApp.error = null;
            state.deleteServiceApp.loading = false;
            return state;
        },
    },
});

export { applicationsSlice };
const {
    getScopesFailed,
    getScopesSuccess,
    getScopesStart,
    getAccountServiceAppsStart,
    getAccountServiceAppsSuccess,
    getAccountServiceAppsFailed,
    createdAccountServiceAppStart,
    createdAccountServiceAppSuccess,
    createdAccountServiceAppFailed,
    updateAccountServiceAppStart,
    updateAccountServiceAppSuccess,
    updateAccountServiceAppFailed,
    deleteAccountServiceAppStart,
    deleteAccountServiceAppSuccess,
    deleteAccountServiceAppFailed,
    cleanupUpdatedAccountServiceApp,
    cleanupCreatedAccountServiceApp,
} = applicationsSlice.actions;
export type AccountStateType = ReturnType<typeof applicationsSlice.reducer>;
type AppThunk = ThunkAction<void, AccountStateType, unknown, Action<string>>;

export const getAllAvailableScopes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getScopesStart());
        const scopes: WMScope[] = await AccountsSdk.getInstance().applications.getAllScopes();
        dispatch(getScopesSuccess(scopes));
    } catch (err) {
        dispatch(getScopesFailed(errorFormatter(err)));
        return;
    }
};

export const getAccountServiceApps = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getAccountServiceAppsStart());
        const serviceApps: WMAccountServiceApplication[] = await AccountsSdk.getInstance().applications.getAccountServicesApps();
        dispatch(getAccountServiceAppsSuccess(serviceApps));
    } catch (err) {
        dispatch(getAccountServiceAppsFailed(errorFormatter(err)));
        return;
    }
};

export const updateAccountServiceApp = (updateServiceApp: UpdateServiceApplicationDTO): AppThunk => async (dispatch) => {
    try {
        dispatch(updateAccountServiceAppStart());
        const serviceApp: WMAccountApplication = await AccountsSdk.getInstance().applications.updateAccountServiceApp(updateServiceApp);
        dispatch(getAccountServiceApps());
        dispatch(updateAccountServiceAppSuccess(serviceApp));
    } catch (err) {
        dispatch(updateAccountServiceAppFailed(errorFormatter(err)));
        return;
    }
};

export const createAccountServiceApp = (createServiceApp: CreateServiceApplicationDTO): AppThunk => async (dispatch) => {
    try {
        dispatch(createdAccountServiceAppStart());
        const serviceApp: WMApplication = await AccountsSdk.getInstance().applications.createAccountServiceApp(createServiceApp);
        dispatch(getAccountServiceApps());
        dispatch(createdAccountServiceAppSuccess(serviceApp));
    } catch (err) {
        dispatch(createdAccountServiceAppFailed(errorFormatter(err)));
        return;
    }
};

export const deleteAccountServiceApp = (deleteServiceApp: DeleteServiceApplicationDTO): AppThunk => async (dispatch) => {
    try {
        dispatch(deleteAccountServiceAppStart());
        await AccountsSdk.getInstance().applications.deleteAccountServiceApp(deleteServiceApp);
        dispatch(getAccountServiceApps());
        dispatch(deleteAccountServiceAppSuccess());
    } catch (err) {
        dispatch(deleteAccountServiceAppFailed(errorFormatter(err)));
        return;
    }
};

const errorFormatter = (e: any) => {
    return (e.response && e.response.data && e.response.data.message) || 'Unspecified error';
};
