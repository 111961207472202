enum TopSitesMode {
    All = 'all',
    Specific = 'specific',
}

interface TopSitesModeRadioValue {
    value: TopSitesMode;
    label: string;
    translationKey: string;
}

const TOP_SITES_MODE_RADIO_VALUES: TopSitesModeRadioValue[] = [
    {
        value: TopSitesMode.All,
        label: 'On all sites',
        translationKey: 'on-all-sites',
    },
    {
        value: TopSitesMode.Specific,
        label: 'On specific sites',
        translationKey: 'on-specific-sites',
    },
];

export { TOP_SITES_MODE_RADIO_VALUES, TopSitesModeRadioValue, TopSitesMode };
