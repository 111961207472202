import { Badge, Link } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledBadge = styled(Badge)`
    margin-left: 20px;

    .customBadge {
        background-color: #e4e9fc;
        color: #6c89f0;
        font: 400 10px ProximaNova;
    }
`;

const CounterBadge: React.FC<{ count: number }> = ({ count }) => {
    return (
        <StyledBadge
            showZero
            classes={{
                badge: 'customBadge',
            }}
            badgeContent={count}
        />
    );
};

const StyledLink = styled(Link)`
    margin: 0 20px 0 0;
    font-size: 14px;
    font-weight: 400;
    font-family: ProximaNova;
    cursor: pointer;
    text-transform: none;
`;

export { CounterBadge, StyledLink };
