import React, { useEffect, useState } from 'react';
import {
    StyledDataCollectionBody,
    StyledDataCollectionContainer,
    StyledDataCollectionTitle,
    StyledDataCollectionToasterMessage,
    StyledDataCollectionWrapper,
    StyledSaveAndPublish,
    StyledToasterContainer,
    LoaderContainer,
    StyledLoader,
} from './data-collection.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { DataCollectionOption } from './data-colleciton-option';
import {
    decodeDataCollectionLevelFromUserFeature,
    generateUserFeaturesToUpdateDataCollection,
    getDataCollectionOptions,
} from './data-collection.lib';
import { Button, Toaster, ToasterVariant } from '@walkme/ui-core';
import { DataCollectionLevel } from './data-collection.lib';
import { siteConfigApi } from '../api/site-config-api';
import { SaveAndPublishDialog } from '../save-and-publish-dialog';
import {
    ImpersonationPasswordError,
    impersonationSlice,
    ImpersonationState,
    impersonationThunks,
    systemsSlice,
} from '@walkme-admin-center/libs/state-management-systems';
import { useDispatch, useSelector } from 'react-redux';
import { WMLoader, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { NotificationMessage } from '@walkme-admin-center/libs/types';
import { ImpersonatorDialog } from '../impersonator-dialog';
import { isIP } from 'node:net';

export const DataCollection = ({ systemId }) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const dataCollectionOptions = getDataCollectionOptions(t);
    const { loggedInUserAppData } = useLoggedInUser();
    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;
    const [defaultDataCollectionLevel, setInitDefaultDataCollectionLevel] = useState<DataCollectionLevel>(null);
    const [selectedDataCollectionLevel, setSelectedDataCollectionLevel] = useState<DataCollectionLevel>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [savingData, setSavingData] = useState(false);

    const impersonationPassword = useSelector(
        (state: { impersonationState: ImpersonationState }) => state.impersonationState.currentPassword
    );

    const defaultSnackbarMessage: NotificationMessage = { text: '', variant: WMSnackbarVariant.Success, isOpen: false };
    const [snackbarMessage, setSnackbarMessage] = useState(defaultSnackbarMessage);

    const handleSnackBarClose = () => {
        setSnackbarMessage(defaultSnackbarMessage);
    };

    const getToasterText = () => {
        const elements = [];
        const fullText = t(`systems-tab.new-system-form.configure-step.data-collection-level.toaster.text`);
        const numOfLinks = (fullText.match(/\$\$/g) || []).length;
        const splitByDollars = fullText.split('$$');
        splitByDollars.forEach((element, index) => {
            elements.push(<span>{element}</span>);
            if (index < numOfLinks) {
                const linkText = t(`systems-tab.new-system-form.configure-step.data-collection-level.toaster.links.${index}.text`);
                const linkURL = t(`systems-tab.new-system-form.configure-step.data-collection-level.toaster.links.${index}.url`);
                elements.push(
                    <a href={linkURL} target='_blank'>
                        {linkText}
                    </a>
                );
            }
        });
        return elements;
    };

    const toasterMessage = <StyledDataCollectionToasterMessage>{getToasterText()}</StyledDataCollectionToasterMessage>;

    const setDefaultDataCollectionLevel = async (shouldShowLoading = true) => {
        if (shouldShowLoading) {
            setIsLoading(true);
        }
        try {
            const userFeatureNamesRes = await siteConfigApi.getUserFeatureNames({ systemId });
            const dataCollectionLevel = decodeDataCollectionLevelFromUserFeature({
                userFeatures: userFeatureNamesRes?.data as DataCollectionLevel[],
            });
            setInitDefaultDataCollectionLevel(dataCollectionLevel);
            setSelectedDataCollectionLevel(dataCollectionLevel);
        } catch (e) {
            console.error(e);
        } finally {
            if (shouldShowLoading) {
                setIsLoading(false);
            }
        }
    };

    const saveDataCollectionLevel = async () => {
        const userFeaturesToUpdate = generateUserFeaturesToUpdateDataCollection({ selectedDataCollectionLevel });
        await siteConfigApi.updateUserFeatures({
            systemId,
            userId: loggedInUserAppData?.data?.id,
            updatedUserFeatures: userFeaturesToUpdate,
        });
    };

    const onSaveClicked = async () => {
        setSavingData(true);
        try {
            await saveDataCollectionLevel();
            onSaveSucceeded();
            setDefaultDataCollectionLevel(false);
        } catch (error) {
            if (error instanceof ImpersonationPasswordError) {
                onSaveFailed(t('notifications.impersonated-save-fail'));
            } else {
                onSaveFailed(t('systems-tab.new-system-form.configure-step.notifications.fail'));
            }
            console.error(error);
        } finally {
            setSavingData(false);
        }
    };
    const onSaveSucceeded = () => {
        dispatch(
            systemsSlice.actions.setNotificationMessage({
                text: t('systems-tab.new-system-form.configure-step.notifications.success'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            })
        );
        setDefaultDataCollectionLevel(false);
    };
    const onSaveFailed = (message: string) => {
        dispatch(
            systemsSlice.actions.setNotificationMessage({
                text: message,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            })
        );
    };

    const onSaveAndPublishClicked = () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(handleSaveAndPublish));
        } else {
            handleSaveAndPublish();
        }
    };
    const handleSaveAndPublish = async () => {
        dispatch(systemsSlice.actions.setSaveAndPublishDialogIsOpen(true));
    };

    const onPublishFailed = (message: string) => {
        setSnackbarMessage({ text: message, variant: WMSnackbarVariant.Error, isOpen: true });
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };

    const onPublishSucceeded = (message: string) => {
        setSnackbarMessage({ text: message, variant: WMSnackbarVariant.Success, isOpen: true });
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };

    useEffect(() => {
        setDefaultDataCollectionLevel();
    }, []);

    return isLoading ? (
        <LoaderContainer>
            <StyledLoader />
        </LoaderContainer>
    ) : (
        <StyledDataCollectionWrapper>
            <StyledDataCollectionContainer>
                <StyledDataCollectionTitle>
                    <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.data-collection-level.main-title`)}</div>
                    <div className='sub-title'>
                        <div>{t(`systems-tab.new-system-form.configure-step.data-collection-level.sub-title`)}</div>
                    </div>
                </StyledDataCollectionTitle>
                <StyledDataCollectionBody>
                    {dataCollectionOptions.map((dcOption, dcOptionIndex) => (
                        <DataCollectionOption
                            id={dcOption.id}
                            title={dcOption.mainTitle}
                            bullets={dcOption.bullets}
                            isLast={dcOptionIndex === dataCollectionOptions?.length - 1}
                            isSelected={selectedDataCollectionLevel === dcOption.id}
                            isDisabled={isImpersonated && dcOption.id !== DataCollectionLevel.ENGAGEMENT}
                            onChange={setSelectedDataCollectionLevel}
                        />
                    ))}
                </StyledDataCollectionBody>
                <StyledToasterContainer
                    className='dataCollectionToaster'
                    icon={true}
                    variant={ToasterVariant.Warning}
                    message={toasterMessage}
                />
            </StyledDataCollectionContainer>
            <StyledSaveAndPublish>
                <Button
                    disabled={defaultDataCollectionLevel === selectedDataCollectionLevel || savingData}
                    loading={savingData}
                    onClick={onSaveClicked}>
                    {t('buttons.save')}
                </Button>
                <Button
                    disabled={defaultDataCollectionLevel === selectedDataCollectionLevel}
                    onClick={onSaveAndPublishClicked}
                    className='save-and-publish'>
                    {t('buttons.save-and-publish')}
                </Button>
            </StyledSaveAndPublish>
            <ImpersonatorDialog isLoading={savingData} />
            <SaveAndPublishDialog
                settingsType='data_collection'
                systemId={systemId}
                selectedDataCollectionLevel={selectedDataCollectionLevel}
                saveData={saveDataCollectionLevel}
                reloadData={setDefaultDataCollectionLevel}
                onFailure={onPublishFailed}
                onSuccess={onPublishSucceeded}
            />
            <WMSnackbar
                open={snackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={snackbarMessage.variant}
                message={snackbarMessage.text}
            />
        </StyledDataCollectionWrapper>
    );
};
