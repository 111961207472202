import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ToasterVariant } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSystemSlice,
    IMPERSONATION_PASSWORD_ERROR_TYPE,
    ImpersonationPasswordError,
    impersonationSlice,
    ImpersonationState,
    systemsSlice,
    SystemsState,
    useSystem,
    uuidSlice,
    UUIDState,
} from '@walkme-admin-center/libs/state-management-systems';
import { DoneSVG as DoneCheck } from './create-system/steps/optional-configure/assets/DoneSVG';
import {
    StyledPrimaryButton,
    StyledPublishTitle,
    StyledSaveSection,
    StyledSaveSections,
    StyledSelectEnvironment,
    StyledToasterContainer,
} from './save-and-publish-dialog.styles';
import { SelectEnvironment } from './select-environment';
import { generateConfigurationString } from './create-system/create-system-dialog/create-system-dialog.lib';
import { siteConfigApi } from './api/site-config-api';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import useExistingSystemSiteConfiguration from '../../../../../libs/state-management-systems/src/lib/hooks/use-existing-system-site-configuration';
import { DataCollectionLevel, generateUserFeaturesToUpdateDataCollection } from './data-collection/data-collection.lib';
import { UUID } from './uuid/uuid.lib';
import { SystemPurpose } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { defaultEnvironmentSelection } from './create-system/steps/optional-configure/save-and-publish-dialog.lib';
import { analyticsApi } from '@walkme-admin-center/libs/analytics-api';
import { EnvironmentData } from '@walkme-admin-center/libs/exposure-api';
import { Environment } from '../../../extension-pages/src/lib/types';
import { on } from 'events';

export const SaveAndPublishDialog = ({
    settingsType,
    systemId,
    selectedDataCollectionLevel,
    accessibilityFeatureIsOn,
    defaultAutomaticAccessibilityOnAccount,
    automaticEnableAccessibilityFeature,
    accessibilityDefaultMasterFeatureOnAccount,
    reloadData,
    saveData,
    saveSection,
    onFailure = () => {},
    onSuccess = () => {},
}: {
    settingsType: 'uuid' | 'data_collection' | 'accessibility' | 'language-identification';
    systemId: number;
    selectedDataCollectionLevel?: DataCollectionLevel;
    accessibilityFeatureIsOn?: boolean;
    defaultAutomaticAccessibilityOnAccount?: boolean;
    automaticEnableAccessibilityFeature?: boolean;
    accessibilityDefaultMasterFeatureOnAccount?: any;
    reloadData?: () => void;
    saveData: () => void;
    saveSection?: () => JSX.Element;
    onFailure?: (message: string) => void;
    onSuccess?: (message: string) => void;
}) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [primaryActionButtonIsLoading, setPrimaryActionButtonIsLoading] = useState<boolean>(false);
    const [selectedEnvironmentToPublish, setSelectedEnvironmentToPublish] = useState(defaultEnvironmentSelection);
    const { loggedInUserAppData } = useLoggedInUser();
    const { currentSystemAppData } = useSystem(systemId);
    const saveAndPublishDialogIsOpen = useSelector(
        (state: { systemsState: SystemsState }) => state.systemsState.saveAndPublishDialogIsOpen
    );
    const [environmentOptions, setEnvironmentOptions] = useState([]);

    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;
    const impPassword = useSelector((state: { impersonationState: ImpersonationState }) => state.impersonationState.currentPassword);

    // UUID
    const selectedUUID = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedUUID);
    const selectedUUIDVariableValue = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedUUIDVariableValue);
    const selectedExpectedFormat = useSelector((state: { uuidState: UUIDState }) => state.uuidState.selectedExpectedFormat);
    const regexpInputValue = useSelector((state: { uuidState: UUIDState }) => state.uuidState.regexpInputValue);
    const { siteConfiguration } = useExistingSystemSiteConfiguration(systemId);

    // Accessibility
    const accessibilityDefaultMasterFeatureIsOnByUser =
        accessibilityDefaultMasterFeatureOnAccount?.modifiedBy === loggedInUserAppData?.data?.email;

    const closeDialog = () => {
        setPrimaryActionButtonIsLoading(false);
        dispatch(systemsSlice.actions.setSaveAndPublishDialogIsOpen(false));
        setSelectedEnvironmentToPublish(defaultEnvironmentSelection);
    };

    const handleOnClose = () => {
        closeDialog();
    };

    const mapEnvironmentToDropdownOption = (environment) => ({
        value: environment.value,
        label: environment.label,
        id: environment.value as number,
    });

    const publishToEnvironment = ({ systemId, envName, environmentId, userId }) => {
        return new Promise(async (resolve, reject) => {
            const publishResponse = await siteConfigApi.updateAndPublish({
                systemId,
                userId,
                isImpersonated,
                impPassword,
                params: { checkedItemsIds: '[]', skipSettings: false, envName, environmentId },
            });
            const trackingId = publishResponse?.data?.content?.id;
            if (!trackingId) {
                reject('No tracking id returned from publish request');
            }
            let publishStatusCheck = setInterval(async () => {
                try {
                    const statusResponse = await siteConfigApi.publishStatusCheck({ trackingId, systemId });

                    if (statusResponse?.data?.content?.status === 'done') {
                        clearInterval(publishStatusCheck);
                        resolve(true);
                    }
                    if (statusResponse?.data?.content?.status === 'failed') {
                        clearInterval(publishStatusCheck);

                        if (isImpersonationPasswordError(statusResponse?.data)) {
                            reject(t('notifications.impersonated-publish-fail'));
                        } else {
                            reject(t('notifications.publish-fail'));
                        }
                    }
                } catch (e) {
                    clearInterval(publishStatusCheck);
                    reject('Failed to check publish status');
                }
            }, 2000);
        });
    };

    const isImpersonationPasswordError = (publishStatus: any): boolean => {
        const tasks = (publishStatus?.content?.tasks as Array<any>) || [];
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            if (task.status === 'failed') {
                if (task.errors?.length && task.errors[0].errorType === IMPERSONATION_PASSWORD_ERROR_TYPE) {
                    return true;
                }
            }
        }
        return false;
    };

    interface PublishEnvResult {
        success: boolean;
        reason?: string;
    }
    const publishEnv = async (): Promise<PublishEnvResult> => {
        const envs = [];
        if (selectedEnvironmentToPublish?.value === 'prod_test') {
            envs.push(environmentOptions.find((env) => env.label === 'Production'));
            envs.push(environmentOptions.find((env) => env.label === 'Test'));
        } else {
            envs.push(selectedEnvironmentToPublish);
        }

        const publishPromises = envs.map((environment) =>
            publishToEnvironment({
                systemId,
                userId: loggedInUserAppData?.data?.id,
                envName: environment.label?.toLowerCase(),
                environmentId: environment.value,
            })
        );

        // @ts-ignore
        const publishPromisesResults = await Promise.allSettled(publishPromises);
        for (let result of publishPromisesResults) {
            if (result.status === 'rejected') {
                return {
                    success: false,
                    reason: result.reason,
                } as PublishEnvResult;
            }
        }

        return { success: true } as PublishEnvResult;
    };

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    const handleSaveAndFinishCreateSystemFlow = useCallback(async () => {
        setPrimaryActionButtonIsLoading(true);
        let shouldTriggerSecondPublish = false;

        try {
            if (saveData) {
                await saveData();
            }

            // data collection changes edge case
            if (settingsType === 'data_collection') {
                if (
                    selectedDataCollectionLevel === DataCollectionLevel.DIGITAL ||
                    selectedDataCollectionLevel === DataCollectionLevel.EXPERIENCE_ANALYTICS
                ) {
                    shouldTriggerSecondPublish = true;
                }
            }
        } catch (e) {
            if (e instanceof ImpersonationPasswordError) {
                onFailure(t('notifications.impersonated-save-fail'));
            } else {
                onFailure(t('systems-tab.new-system-form.configure-step.notifications.fail'));
                console.error(e);
            }

            closeDialog();
            return;
        }

        try {
            let publishResult = await publishEnv();

            // workaround for insights bug - require another publish if data collection changed
            if (publishResult.success && shouldTriggerSecondPublish) {
                await delay(5000);
                publishResult = await publishEnv();
            }

            if (publishResult.success) {
                if (typeof reloadData === 'function') {
                    await reloadData();
                }
                onSuccess(t('notifications.publish-success'));
            } else {
                onFailure(publishResult.reason);
            }
        } catch (e) {
            onFailure(t('notifications.publish-fail'));
        } finally {
            closeDialog();
        }
    }, [
        loggedInUserAppData,
        selectedUUID,
        selectedExpectedFormat,
        regexpInputValue,
        selectedUUIDVariableValue,
        siteConfiguration,
        selectedDataCollectionLevel,
        selectedEnvironmentToPublish,
        accessibilityFeatureIsOn,
        automaticEnableAccessibilityFeature,
        accessibilityDefaultMasterFeatureOnAccount,
    ]);

    const getEnvironmentOptions = () => {
        const environmentOptionsRes = [];
        if (currentSystemAppData?.data?.purpose !== SystemPurpose.TRIAL) {
            environmentOptionsRes.push({
                label: 'Production and Test',
                value: 'prod_test',
                id: 10,
            });
        }

        environmentOptionsRes.push(
            ...environmentOptions
                .map(mapEnvironmentToDropdownOption)
                .filter(
                    (env) =>
                        currentSystemAppData?.data?.purpose !== SystemPurpose.TRIAL ||
                        (currentSystemAppData?.data?.purpose === SystemPurpose.TRIAL && env.id !== 0)
                )
        );

        return environmentOptionsRes.reverse();
    };

    const getSaveSection = () => {
        switch (settingsType) {
            case 'accessibility':
                return getAccessibilitySaveSection();
            case 'data_collection':
                return getDataCollectionSaveSection();
            case 'uuid':
                return getUUIDSaveSection();
            case 'language-identification': {
                if (typeof saveSection === 'function') {
                    return saveSection();
                }
            }
        }
    };

    const getUUIDSaveSection = () => {
        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.uuid.main-title`)}</div>
                        <div className='sub-title'>{selectedUUID?.label}</div>
                    </div>
                </div>
                {selectedUUID?.value === UUID.NONE ? (
                    <StyledToasterContainer
                        icon={true}
                        variant={ToasterVariant.Info}
                        message={t(`systems-tab.new-system-form.configure-step.uuid.save-and-publish.toaster.recommendation`)}
                    />
                ) : null}
            </StyledSaveSection>
        );
    };

    const getDataCollectionSaveSection = () => {
        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.data-collection-level.main-title`)}</div>
                        <div className='sub-title'>
                            {t(`systems-tab.new-system-form.configure-step.save-and-publish.${selectedDataCollectionLevel as string}`)}
                        </div>
                    </div>
                </div>
            </StyledSaveSection>
        );
    };

    const getAccessibilitySaveSection = () => {
        const saveSectionText = [];

        if (accessibilityFeatureIsOn) {
            saveSectionText.push(<div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.enabled-on-system`)}</div>);
        } else {
            saveSectionText.push(
                <div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.disabled-on-system`)}</div>
            );
        }

        if (!defaultAutomaticAccessibilityOnAccount && automaticEnableAccessibilityFeature) {
            saveSectionText.push(
                <div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.enabled-on-account`)}</div>
            );
        }

        if (defaultAutomaticAccessibilityOnAccount && !automaticEnableAccessibilityFeature) {
            saveSectionText.push(
                <div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.disabled-on-account`)}</div>
            );
        }

        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.accessibility.main-title`)}</div>
                        <div className='sub-title'>{saveSectionText}</div>
                    </div>
                </div>
            </StyledSaveSection>
        );
    };

    const fetchSystemEnvironments = async () => {
        try {
            const systemEnvironments = await siteConfigApi.getEnvironments({
                systemId,
                isImpersonated,
            });
            const modifiedEnvs = systemEnvironments?.data
                .filter((envData) => !envData.IsDev)
                .map((envData) => {
                    const currentEnvId = envData.Id;
                    const envName = envData.Name;
                    const path = envData.Path;
                    const currentEnvData: Environment = { value: currentEnvId, label: envName, path };

                    return currentEnvData;
                });

            setEnvironmentOptions(modifiedEnvs);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchSystemEnvironments();
    }, []);

    return (
        <Dialog isOpen={saveAndPublishDialogIsOpen} onClose={closeDialog}>
            <DialogTitle>{t(`systems-tab.new-system-form.configure-step.save-and-publish.main-title`)}</DialogTitle>
            <DialogContent>
                <StyledPublishTitle>{t(`systems-tab.new-system-form.configure-step.save-and-publish.description`)}</StyledPublishTitle>
                <StyledSaveSections>{getSaveSection()}</StyledSaveSections>
                <StyledSelectEnvironment>
                    <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.save-and-publish.select-environment`)}</div>
                    <SelectEnvironment
                        selectedEnvironment={selectedEnvironmentToPublish}
                        setSelectedEnvironment={(environment) => setSelectedEnvironmentToPublish(environment)}
                        environmentOptions={getEnvironmentOptions()}
                    />
                </StyledSelectEnvironment>
            </DialogContent>
            <DialogActions>
                <Button size='large' variant='text' onClick={handleOnClose}>
                    {t('buttons.cancel')}
                </Button>
                <StyledPrimaryButton
                    size='large'
                    onClick={handleSaveAndFinishCreateSystemFlow}
                    disabled={selectedEnvironmentToPublish?.value === defaultEnvironmentSelection.value || primaryActionButtonIsLoading}
                    loading={primaryActionButtonIsLoading}>
                    {t('buttons.publish')}
                </StyledPrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
