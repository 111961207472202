import { createTheme } from '@material-ui/core/styles';

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#3B61EB',
        },
        secondary: {
            main: '#3B61EB',
            contrastText: '#fff',
        },
        text: {
            primary: '#333',
        },
    },

    typography: {
      fontFamily: [
        'ProximaNova',
      ].join(','),
        button: {
            fontSize: 14,
        },
    },
});

export default muiTheme;
