import React, { useEffect, useState } from 'react';
import { updateSocialLoginSettings, useSocialLogin } from '@walkme-admin-center/libs/state-management-users';
import { useDispatch } from 'react-redux';
import SecurityCard from '../common/security-card';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface SocialLoginProps {}

export const SocialLoginPage = (props: SocialLoginProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const { socialLoginAppData } = useSocialLogin();

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        if (socialLoginAppData.data) {
            setChecked(!socialLoginAppData.data.isDisabled);
        }
    }, [socialLoginAppData]);

    const toggleSwitch = (isChecked: boolean) => {
        const status = isChecked ? 'enable' : 'disable';
        dispatch(updateSocialLoginSettings({ status }));
    };

    return (
        <SecurityCard
            name='session-config'
            marginTop={'32px'}
            handleSwitchClick={toggleSwitch}
            loading={socialLoginAppData.loading}
            title={t('security-tab.social-login.title')}
            subTitle={t('security-tab.social-login.subtitle')}
            checked={checked}
            disabled={false}
            error={socialLoginAppData.error}
        />
    );
};

export default SocialLoginPage;
