import { WMButtonVariant, WMIconChevronRight } from '@walkme/wm-ui';
import moment from 'moment';
import React, { useMemo } from 'react';

import { Button } from '../../Button/Button';
import styles from './YearPicker.module.scss';
import { DateRangeOption } from '../../../../../../../../libs/state-mangment-data-integration/src/lib/ai-center/filters/hooks/useDateRangeOptions';
import { Typography } from '@material-ui/core';

interface YearPickerProps {
    selectedYear: number;
    dateRangeOptions: DateRangeOption[];
    onSelect: (year: number) => void;
}
export const YearPicker = ({ onSelect, dateRangeOptions, selectedYear }: YearPickerProps) => {
    const isNextYearDisabled = useMemo(
        () => !dateRangeOptions.some((range) => +moment(range.value.fromDate).format('YYYY') === selectedYear + 1),
        [dateRangeOptions, selectedYear]
    );

    const isPrevYearDisabled = useMemo(
        () => !dateRangeOptions.some((range) => +moment(range.value.fromDate).format('YYYY') === selectedYear - 1),
        [dateRangeOptions, selectedYear]
    );

    return (
        <div className={styles.container}>
            <Button
                onClick={() => onSelect(selectedYear - 1)}
                disabled={isPrevYearDisabled}
                className={styles.yearBtn}
                variant={WMButtonVariant.Tertiary}
                isIconButton
                iconComponent={<WMIconChevronRight className={styles.leftBtn} />}
            />
            <Typography variant='body2' className={styles.year}>
                {selectedYear}
            </Typography>
            <Button
                onClick={() => onSelect(selectedYear + 1)}
                disabled={isNextYearDisabled}
                className={styles.yearBtn}
                variant={WMButtonVariant.Tertiary}
                isIconButton
                iconComponent={<WMIconChevronRight />}
            />
        </div>
    );
};
