import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, RadioGroup } from '@material-ui/core';
import {
    WMButton,
    WMButtonVariant,
    WMCheckbox,
    WMCollapsibleListItem,
    WMIconExport,
    WMIconHelp,
    WMRadio,
    WMTextField,
    WMTooltipProps,
} from '@walkme/wm-ui';
import {
    accountIdpsSlice,
    AccountIdpsState,
    createAccountSamlIdp,
    DefaultIdpIssuer,
    DefaultIdpUrl,
    editAccountSamlIdp,
    getUsers,
    updateAccountIdpUsers,
    useLoggedInUser,
    useUsers,
} from '@walkme-admin-center/libs/state-management-users';
import {
    AccountUsersToIdpExtraDataUserNameIdDTO,
    SamlAccountIdpConfigurationDTO,
    User,
    WMSAMLIdp,
    WMSAMLIdpBindingType,
} from 'wm-accounts-sdk';
import { IdpConfigStatus, IdpConfigStepsData } from './types/types';
import ExternalIdpStepper from './external-idp-stepper';
import {
    Content,
    ContentTest,
    ContentTestWarning,
    HeaderTitle,
    Label,
    LabelDescription,
    LabelMsg,
    NameIDTextField,
    StyledCollapsableListItemTitle,
    StyledDiv,
    StyledExternalIdpDialog,
    StyledList,
    StyledUsersDiv,
    StyledXMLDownloadButton,
    UserImage,
    UserImageText,
} from './styles/styles';
import { useDebouncedCallback } from 'use-debounce';
import get from 'lodash/get';
import { parse } from 'fast-xml-parser';
import FileSaver from 'file-saver';

export interface ContentDialogProps {
    initialStep: number;
    initalSelectedIdp: WMSAMLIdp;
    showError: (error: string) => void;
    onCancel: () => void;
    onFinish: () => void;
    showDialog: boolean;
}

import IconCopy from '../../../../../../../packages/libs/common/assets/icons/Icon-copy.svg';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const ExternalIdpWizard = ({ initialStep, initalSelectedIdp, showDialog, showError, onCancel, onFinish }: ContentDialogProps) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [selfTestButtonPressed, setSelfTestButtonPressed] = useState(false);
    const [showSelfTestWarningText, setShowSelfTestWarningText] = useState(false);
    const [nextButtonPressed, setNextButtonPressed] = useState(false);

    const [issuerChanged, setIssuerChanged] = useState(false);
    const [certificateChanged, setCertificateChanged] = useState(false);
    const [idpUrlChanged, setIdpUrlChanged] = useState(false);
    const [userIdpNameIdChangesMapping, setUserIdpNameIdChangesMapping] = useState({});
    const [currentStep, setActiveStep] = useState(initialStep);
    const [selectedIdp, setSelectedIdp] = useState(initalSelectedIdp);
    const createdIdpAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.createdIdpAppData);
    const savedIdpAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.savedAccountIdp);
    const updateAccountIdpUsersData = useSelector(
        (state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.updateAccountIdpUsers
    );
    const { usersAppData } = useUsers();
    const users = usersAppData.data;
    const [updatedIdpData, setUpdatedIdpData] = useState<SamlAccountIdpConfigurationDTO>({
        idpName: selectedIdp && selectedIdp.idpName,
        idpUrl: selectedIdp && selectedIdp.idpUrl !== DefaultIdpUrl ? selectedIdp.idpUrl : '',
        certificate: selectedIdp && selectedIdp.idpCertificate,
        samlBinding: selectedIdp && selectedIdp.binding,
        issuer: selectedIdp && selectedIdp.issuer !== DefaultIdpIssuer ? selectedIdp.issuer : '',
    });

    const [idpMethodValue, setIdpMethodValue] = useState('URL');
    const [selectedUsers, setSelectedUsers] = useState(selectedIdp ? users.filter((user) => user.attachedIdpId === selectedIdp.idpId) : []);
    const [selectAll, setSelectAll] = useState(users.length == selectedUsers.length);

    const [stepChangesMapping, setStepChangesMapping] = useState({});
    const [ssoAutoNameIdChecked, setSsoAutoNameIdChecked] = useState(selectedIdp?.ssoIdIsEmail ?? true);
    const [query, setQuery] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);
    const { loggedInUserAppData } = useLoggedInUser();
    const [clickedCopy, setClickedCopy] = useState(false);

    const handleExportMetadataToFile = useCallback(() => {
        const blob = new Blob([selectedIdp.metadataXml]);
        FileSaver.saveAs(blob, `${updatedIdpData.idpName}_metadta.xml`);
    }, [updatedIdpData, selectedIdp]);

    useEffect(() => {
        if (usersAppData.data) {
            setFilteredUsers(usersAppData.data);
        }
    }, [usersAppData]);

    useEffect(() => {
        if (updateAccountIdpUsersData.data) {
            if (nextButtonPressed) {
                setNextButtonPressed(false);
                onFinish();
                dispatch(getUsers(true));
            } else if (selfTestButtonPressed) {
                setSelfTestButtonPressed(false);
                dispatch(accountIdpsSlice.actions.updateAccountUsersIdpCleanup());
                window.open(selectedIdp.acsUrl);
            }
        } else if (updateAccountIdpUsersData.error) {
            showError(updateAccountIdpUsersData.error);
        }
    }, [updateAccountIdpUsersData]);

    useEffect(() => {
        if (createdIdpAppData.data) {
            setSelectedIdp(createdIdpAppData.data);
            if (nextButtonPressed) {
                setNextButtonPressed(false);
                setActiveStep((prevActiveStep) => {
                    return prevActiveStep < 3 ? prevActiveStep + 1 : prevActiveStep;
                });
            }
        } else if (createdIdpAppData.error) {
            showError(createdIdpAppData.error);
        }
    }, [createdIdpAppData]);

    useEffect(
        () => () => {
            dispatch(accountIdpsSlice.actions.savedAccountIdpCleanup());
            dispatch(accountIdpsSlice.actions.createdAccountIdpCleanup());
            dispatch(accountIdpsSlice.actions.updateAccountUsersIdpCleanup());
        },
        []
    );

    useEffect(() => {
        if (savedIdpAppData.data) {
            if (nextButtonPressed) {
                setNextButtonPressed(false);
                setActiveStep((prevActiveStep) => {
                    return prevActiveStep < 3 ? prevActiveStep + 1 : prevActiveStep;
                });
            }
        } else if (savedIdpAppData.error) {
            showError(savedIdpAppData.error);
        }
    }, [savedIdpAppData]);

    const uploadMetadataFile = useCallback(
        (e) => {
            e.preventDefault();
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const jsonResult = parse(e.target.result.toString(), {
                        ignoreAttributes: false,
                        ignoreNameSpace: true,
                    });
                    const entityID = get(jsonResult, 'EntityDescriptor.@_entityID');
                    const certificate = get(jsonResult, 'EntityDescriptor.IDPSSODescriptor.KeyDescriptor.KeyInfo.X509Data.X509Certificate');
                    const singleSignOnService = get(jsonResult, 'EntityDescriptor.IDPSSODescriptor.SingleSignOnService');
                    const bindings = Array.isArray(singleSignOnService)
                        ? get(singleSignOnService[0], '@_Binding')
                        : get(singleSignOnService, '@_Binding');
                    const idpUrl = Array.isArray(singleSignOnService)
                        ? get(singleSignOnService[0], '@_Location')
                        : get(singleSignOnService, '@_Location');
                    const validCertificate = `-----BEGIN CERTIFICATE-----\n${certificate}\n-----END CERTIFICATE-----`;
                    const splittedBinding = bindings.split(':');
                    const validBinding = splittedBinding[splittedBinding.length - 1];
                    if (!entityID || !validCertificate || !idpUrl) {
                        return;
                    }
                    const newIdpConfig = Object.assign({}, updatedIdpData);
                    newIdpConfig.idpUrl = idpUrl;
                    newIdpConfig.samlBinding = validBinding || WMSAMLIdpBindingType.redirect;
                    newIdpConfig.certificate = validCertificate;
                    newIdpConfig.issuer = entityID;
                    setUpdatedIdpData(newIdpConfig);
                    stepDataChanged(IdpConfigStatus.idpCustomerConnection);
                } catch (e) {
                    console.log(`error uploading metadata`, e);
                }
            };
            reader.readAsText(e.target.files[0]);
        },
        [dispatch, updatedIdpData]
    );

    const selfTest = useCallback(() => {
        setShowSelfTestWarningText(true);
        const idpNameId =
            !ssoAutoNameIdChecked && userIdpNameIdChangesMapping[loggedInUserAppData.data.id]
                ? userIdpNameIdChangesMapping[loggedInUserAppData.data.id]
                : undefined;
        setSelfTestButtonPressed(true);
        dispatch(updateAccountIdpUsers(selectedIdp.idpId, [{ userId: loggedInUserAppData.data.id, idpNameId }], [], ssoAutoNameIdChecked));
        addUser(loggedInUserAppData.data);
    }, [dispatch, selectedIdp, selectedUsers, ssoAutoNameIdChecked]);

    const handleIdpNameIdChanges = useDebouncedCallback((user: User, newIdpNameId: string) => {
        setUserIdpNameIdChangesMapping((prevState) => {
            const newMapping = Object.assign({}, prevState);
            newMapping[user.id] = newIdpNameId;
            return newMapping;
        });
    }, 800);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            return prevActiveStep > 1 ? prevActiveStep - 1 : prevActiveStep;
        });
    };

    const onConfirmNewSamlIdp = useCallback(
        (samlAccountIdpConfigurationDTO: SamlAccountIdpConfigurationDTO) => {
            dispatch(createAccountSamlIdp(samlAccountIdpConfigurationDTO));
        },
        [dispatch]
    );

    const onUpdateSamlIdp = useCallback(
        (samlAccountIdpConfigurationDTO: SamlAccountIdpConfigurationDTO) => {
            dispatch(editAccountSamlIdp(selectedIdp.idpId, samlAccountIdpConfigurationDTO));
        },
        [dispatch, selectedIdp]
    );

    const onUpdateUsersSamlIdp = useCallback(() => {
        const selectedUsersToUpdate: AccountUsersToIdpExtraDataUserNameIdDTO[] = selectedUsers.map((user) => {
            const idpNameId =
                !ssoAutoNameIdChecked && userIdpNameIdChangesMapping[user.id] ? userIdpNameIdChangesMapping[user.id] : undefined;
            return {
                userId: user.id,
                idpNameId,
            };
        });
        const detachedUsersUsersToUpdate: AccountUsersToIdpExtraDataUserNameIdDTO[] = usersAppData.data
            .filter(
                (existingUser) =>
                    existingUser.attachedIdpId === selectedIdp.idpId &&
                    !selectedUsers.find((selectedUser) => existingUser.id === selectedUser.id)
            )
            .map((user) => {
                return {
                    userId: user.id,
                };
            });
        dispatch(updateAccountIdpUsers(selectedIdp.idpId, selectedUsersToUpdate, detachedUsersUsersToUpdate, ssoAutoNameIdChecked));
    }, [dispatch, selectedIdp, selectedUsers, userIdpNameIdChangesMapping, usersAppData.data, ssoAutoNameIdChecked]);

    const isStepValidated = (): boolean => {
        if (currentStep == IdpConfigStatus.idpCreated) {
            return updatedIdpData.idpName ? true : false;
        }
        if (currentStep == IdpConfigStatus.idpCustomerConnection) {
            return updatedIdpData.idpUrl && updatedIdpData.issuer && updatedIdpData.certificate ? true : false;
        }
        return true;
    };

    const handleNext = async (samlAccountIdpConfigurationDTO: SamlAccountIdpConfigurationDTO) => {
        const someChangesMade = stepChangesMapping[currentStep];
        if (!someChangesMade) {
            if (currentStep == IdpConfigStatus.assignUsers) {
                onFinish();
            }
            setNextButtonPressed(false);
            setActiveStep((prevActiveStep) => {
                return prevActiveStep < 3 ? prevActiveStep + 1 : prevActiveStep;
            });
            return;
        }
        setNextButtonPressed(true);
        if (currentStep == IdpConfigStatus.idpCreated) {
            onConfirmNewSamlIdp(samlAccountIdpConfigurationDTO);
        }
        if (currentStep == IdpConfigStatus.idpCustomerConnection) {
            onUpdateSamlIdp(samlAccountIdpConfigurationDTO);
        }
        if (currentStep == IdpConfigStatus.assignUsers) {
            onUpdateUsersSamlIdp();
        }
    };

    useEffect(() => {
        setFilteredUsers(users.filter((user) => user.email.includes(query)));
    }, [query]);

    const stepDataChanged = useDebouncedCallback((step: IdpConfigStatus) => {
        setStepChangesMapping((prevState) => {
            const newStepChangesMapping = Object.assign({}, prevState);
            newStepChangesMapping[step] = true;
            return newStepChangesMapping;
        });
    }, 500);

    const removeAllUsers = () => {
        setSelectedUsers([]);
        setSelectAll(false);
    };

    const removeUser = (userId: number) => {
        const newSelectedUsers = userId ? selectedUsers.filter((user) => user.id != userId) : [];
        setSelectedUsers(newSelectedUsers);
        setSelectAll(false);
    };

    const addAllUsers = () => {
        setSelectedUsers(users);
        setSelectAll(true);
    };

    const addUser = (user: User) => {
        if (selectedUsers.find((selectedUser) => selectedUser.id === user.id)) {
            return;
        }
        const newSelectedUsers = selectedUsers.concat(user);
        setSelectedUsers(newSelectedUsers);
        if (newSelectedUsers.length == users.length) setSelectAll(true);
    };

    const stepOne = () => {
        stepDataChanged(IdpConfigStatus.idpCreated);
        return (
            <Box>
                <StyledDiv>
                    <HeaderTitle>{t('security-tab.idps.setup.step-1.title')}</HeaderTitle>
                    <Content>{t('security-tab.idps.setup.step-1.subtitle')}</Content>
                </StyledDiv>
                <StyledDiv>
                    <Label>{t('security-tab.idps.setup.step-1.name')}</Label>
                    <WMTextField
                        autoFocus
                        maxLength={80}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newIdpConfig = Object.assign({}, updatedIdpData);
                            newIdpConfig.idpName = event.target.value;
                            newIdpConfig.idpUrl = updatedIdpData.idpUrl || DefaultIdpUrl;
                            newIdpConfig.samlBinding = updatedIdpData.samlBinding || WMSAMLIdpBindingType.redirect;
                            newIdpConfig.certificate = updatedIdpData.certificate;
                            newIdpConfig.issuer = updatedIdpData.issuer || DefaultIdpIssuer;
                            setUpdatedIdpData(newIdpConfig);
                        }}
                        placeholder={t('security-tab.idps.setup.step-1.name-placeholder')}
                        value={updatedIdpData.idpName || ''}
                    />
                </StyledDiv>
            </Box>
        );
    };

    const downloadMetadataTooltipProps = {
        title: t('common.download-file'),
        placement: 'top',
    } as WMTooltipProps;

    const copyTooltipProps = {
        title: clickedCopy ? t('common.copied') : t('common.copy'),
        placement: 'top',
    } as WMTooltipProps;

    const retrieveEntityId = (XMLData) => {
        const jsonResult = parse(XMLData, {
            ignoreAttributes: false,
            ignoreNameSpace: false,
        });
        const entityID = get(jsonResult, 'md:EntityDescriptor.@_entityID');
        return entityID;
    };

    const stepTwo = () => {
        return (
            <Box>
                <StyledDiv>
                    <HeaderTitle>{t('security-tab.idps.setup.step-2.title')}</HeaderTitle>
                    <Content>{t('security-tab.idps.setup.step-2.subtitle')}</Content>
                </StyledDiv>
                {selectedIdp && (
                    <StyledDiv>
                        <Label>{t('security-tab.idps.setup.step-2.idp-method')}</Label>
                        <LabelDescription>{t('security-tab.idps.setup.step-2.idp-method-desc')}</LabelDescription>
                        <RadioGroup
                            value={idpMethodValue}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setIdpMethodValue(event.target.value);
                            }}
                            name='idpMethodValue'>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <WMRadio value='URL' label={t('security-tab.idps.setup.step-2.integration-methods.url')} />
                                <WMRadio value='EntityId' label={t('security-tab.idps.setup.step-2.integration-methods.entry-id')} />
                                <WMRadio value='XMLMetadata' label={t('security-tab.idps.setup.step-2.integration-methods.xml')} />
                            </Box>
                        </RadioGroup>
                        <WMTextField
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setIdpMethodValue(event.target.value)}
                            value={
                                idpMethodValue == 'URL'
                                    ? selectedIdp.acsUrl
                                    : idpMethodValue == 'EntityId'
                                    ? retrieveEntityId(selectedIdp.metadataXml)
                                    : selectedIdp.metadataXml
                            }
                            disabled
                            InputProps={{
                                style: {
                                    fontFamily: 'proximaNova',
                                    backgroundColor: '#F6F8FC',
                                    fontSize: '12px',
                                },
                                endAdornment: (
                                    <InputAdornment position='end' style={{ backgroundColor: '#F6F8FC', pointerEvents: 'visible' }}>
                                        <WMButton
                                            iconComponent={<img src={IconCopy} />}
                                            tooltipProps={copyTooltipProps}
                                            isIconButton
                                            variant={WMButtonVariant.Text}
                                            onMouseLeave={() => setClickedCopy(false)}
                                            onClick={() => {
                                                setClickedCopy(true);
                                                navigator.clipboard.writeText(
                                                    idpMethodValue == 'URL'
                                                        ? selectedIdp.acsUrl
                                                        : idpMethodValue == 'EntityId'
                                                        ? retrieveEntityId(selectedIdp.metadataXml)
                                                        : selectedIdp.metadataXml
                                                );
                                            }}
                                        />
                                        {idpMethodValue === 'XMLMetadata' && (
                                            <StyledXMLDownloadButton
                                                iconComponent={<WMIconExport size={16} color='var(--wmGrayDark)' />}
                                                tooltipProps={downloadMetadataTooltipProps}
                                                isIconButton
                                                variant={WMButtonVariant.Text}
                                                onClick={() => {
                                                    handleExportMetadataToFile();
                                                }}
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: {
                                    fontFamily: 'proximaNova',
                                    backgroundColor: '#F6F8FC',
                                    color: '#35425F',
                                    fontSize: '12px',
                                },
                            }}
                        />
                    </StyledDiv>
                )}
                <StyledDiv>
                    <Label>{t('security-tab.idps.setup.step-2.setup-info')}</Label>
                    <LabelMsg>- {t('security-tab.idps.setup.sso-url')} </LabelMsg>
                    <LabelMsg>- {t('security-tab.idps.setup.idp-issuer')} </LabelMsg>
                    <LabelMsg>- {t('security-tab.idps.setup.public-certificate')} </LabelMsg>
                </StyledDiv>
            </Box>
        );
    };

    const stepThree = () => {
        return (
            <Box>
                <StyledDiv>
                    <HeaderTitle>{t('security-tab.idps.setup.step-3.title')}</HeaderTitle>
                    <Content>{t('security-tab.idps.setup.step-3.subtitle')}</Content>
                    <WMButton variant={WMButtonVariant.Text} component='label'>
                        {t('security-tab.idps.setup.step-3.uplodad-button')}
                        <input type='file' hidden accept='.xml' onChange={(e) => uploadMetadataFile(e)} />
                    </WMButton>
                </StyledDiv>
                <StyledDiv>
                    <Label>{t('security-tab.idps.setup.sso-url')}</Label>
                    <WMTextField
                        autoFocus
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newIdpConfig = Object.assign({}, updatedIdpData);
                            newIdpConfig.idpUrl = event.target.value;
                            setUpdatedIdpData(newIdpConfig);
                            stepDataChanged(IdpConfigStatus.idpCustomerConnection);
                            setIdpUrlChanged(true);
                        }}
                        placeholder={t('security-tab.idps.setup.step-3.sso-placeholder')}
                        error={idpUrlChanged && !updatedIdpData.idpUrl ? t('errors.empty-field') : ''}
                        value={
                            updatedIdpData && updatedIdpData.idpUrl && updatedIdpData.idpUrl !== DefaultIdpUrl ? updatedIdpData.idpUrl : ''
                        }
                    />
                </StyledDiv>
                <StyledDiv>
                    <Label>{t('security-tab.idps.setup.step-3.idp-label')}</Label>
                    <WMTextField
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newIdpConfig = Object.assign({}, updatedIdpData);
                            newIdpConfig.issuer = event.target.value;
                            setUpdatedIdpData(newIdpConfig);
                            stepDataChanged(IdpConfigStatus.idpCustomerConnection);
                            setIssuerChanged(true);
                        }}
                        placeholder={t('security-tab.idps.setup.step-3.issuer-placeholder')}
                        error={issuerChanged && !updatedIdpData.issuer ? t('errors.empty-field') : ''}
                        value={
                            updatedIdpData && updatedIdpData.issuer && updatedIdpData.issuer !== DefaultIdpIssuer
                                ? updatedIdpData.issuer
                                : ''
                        }
                    />
                </StyledDiv>
                <StyledDiv>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Label>{t('security-tab.idps.setup.public-certificate')}</Label>
                        <WMButton
                            style={{ padding: '0 0 8px 8px' }}
                            iconComponent={<WMIconHelp />}
                            tooltipProps={{
                                title: t('security-tab.idps.setup.step-3.certificate-tooltip'),
                                placement: 'top',
                            }}
                            isIconButton
                            variant={WMButtonVariant.Text}
                        />
                    </Box>
                    <WMTextField
                        inputProps={{
                            style: { minHeight: '80px', height: '80px' },
                        }}
                        multiline
                        rowsMax={4}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newIdpConfig = Object.assign({}, updatedIdpData);
                            newIdpConfig.certificate = event.target.value;
                            setUpdatedIdpData(newIdpConfig);
                            stepDataChanged(IdpConfigStatus.idpCustomerConnection);
                            setCertificateChanged(true);
                        }}
                        error={certificateChanged && !updatedIdpData.certificate ? 'This field cannot be empty' : ''}
                        value={updatedIdpData.certificate || ''}
                        placeholder={t('security-tab.idps.setup.step-3.certificate-placeholder')}
                    />
                </StyledDiv>
                <StyledDiv>
                    <Label>{t('security-tab.idps.setup.step-3.request-binding')}</Label>
                    <RadioGroup
                        value={updatedIdpData.samlBinding}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newIdpConfig = Object.assign({}, updatedIdpData);
                            newIdpConfig.samlBinding = event.target.value as WMSAMLIdpBindingType;
                            setUpdatedIdpData(newIdpConfig);
                            stepDataChanged(IdpConfigStatus.idpCustomerConnection);
                        }}
                        name='bindingValue'>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <WMRadio value={WMSAMLIdpBindingType.redirect} label={t('security-tab.idps.setup.step-3.http-redirect')} />
                            <WMRadio value={WMSAMLIdpBindingType.post} label={t('security-tab.idps.setup.step-3.http-post')} />
                        </Box>
                    </RadioGroup>
                </StyledDiv>
            </Box>
        );
    };

    const extractMailInitials = (email: string) => {
        const splitEmail = email.toUpperCase().slice(0, email.indexOf('@')).split('.');
        return splitEmail.map((part, index) => (index < 2 ? part.charAt(0) : '')).join('');
    };

    const stepFour = () => {
        return (
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <StyledDiv>
                    <StyledDiv>
                        <HeaderTitle>{t('security-tab.idps.setup.step-4.title')}</HeaderTitle>
                        <Content>{t('security-tab.idps.setup.step-4.subtitle')}</Content>
                    </StyledDiv>
                    <StyledDiv>
                        <Label>{t('security-tab.idps.setup.step-4.user-selection')}</Label>
                        <WMCheckbox
                            checked={ssoAutoNameIdChecked}
                            onChange={() => {
                                stepDataChanged(IdpConfigStatus.assignUsers);
                                setSsoAutoNameIdChecked(!ssoAutoNameIdChecked);
                            }}
                            label={t('security-tab.idps.setup.step-4.sso-checkbox')}
                        />
                    </StyledDiv>
                    <StyledUsersDiv>
                        <WMTextField
                            style={{ marginBottom: '20px' }}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
                            placeholder={t('common.search-placeholder')}
                            value={query}
                        />
                        <StyledList>
                            {filteredUsers && (
                                <WMCollapsibleListItem
                                    preventCollapse
                                    checkboxProps={{
                                        checked: selectAll,
                                        indeterminate: !selectAll,
                                        onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                            event.target.checked ? addAllUsers() : removeAllUsers();
                                            stepDataChanged(IdpConfigStatus.assignUsers);
                                        },
                                    }}
                                    title={selectAll ? t('common.unselect-all') : t('common.select-all')}
                                />
                            )}
                            {filteredUsers &&
                                filteredUsers.map((user) => {
                                    return (
                                        <WMCollapsibleListItem
                                            preventCollapse
                                            checkboxProps={{
                                                checked: selectedUsers.find((selectedUser) => selectedUser.id === user.id) ? true : false,
                                                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                                    event.target.checked ? addUser(user) : removeUser(user.id);
                                                    stepDataChanged(IdpConfigStatus.assignUsers);
                                                },
                                            }}
                                            key={user.id}
                                            prefixIcon={
                                                <UserImage>
                                                    <UserImageText>{extractMailInitials(user.email)}</UserImageText>
                                                </UserImage>
                                            }
                                            title={
                                                <StyledCollapsableListItemTitle>
                                                    <span>{user.email}</span>
                                                    {!ssoAutoNameIdChecked && (
                                                        <span className={'nameIdsField'}>
                                                            <NameIDTextField
                                                                onChange={(e) => {
                                                                    handleIdpNameIdChanges(user, e.target.value);
                                                                }}
                                                                value={user.idpNameId || user.email}
                                                            />
                                                        </span>
                                                    )}
                                                </StyledCollapsableListItemTitle>
                                            }
                                        />
                                    );
                                })}
                        </StyledList>
                    </StyledUsersDiv>
                </StyledDiv>
                <StyledDiv>
                    <Label>{t('security-tab.idps.setup.step-4.test-label')}</Label>
                    <ContentTest>{t('security-tab.idps.setup.step-4.test-text')}</ContentTest>
                    <WMButton
                        disabled={selfTestButtonPressed}
                        loading={selfTestButtonPressed}
                        onClick={() => {
                            selfTest();
                            stepDataChanged(IdpConfigStatus.assignUsers);
                        }}>
                        {t('security-tab.idps.setup.step-4.test-button')}
                    </WMButton>
                    {showSelfTestWarningText && <ContentTestWarning>{t('security-tab.idps.setup.step-4.test-warning')}</ContentTestWarning>}
                </StyledDiv>
            </Box>
        );
    };

    const stepperAction = [stepOne, stepTwo, stepThree, stepFour];
    return (
        <div>
            <StyledExternalIdpDialog
                onClose={() => onCancel()}
                classes={{ paper: 'wizardDialog' }}
                open={showDialog}
                fullWidth={true}
                maxWidth={'xl'}>
                <DialogTitle>
                    <ExternalIdpStepper activeStep={currentStep} handleClose={onCancel} handleBack={handleBack} />
                </DialogTitle>
                <DialogContent className={'wizardDialogContent'}>{stepperAction[currentStep]()}</DialogContent>
                <DialogActions className={'wizardDialogButtons'}>
                    <WMButton variant={WMButtonVariant.Text} className={'wizardDialogSeconaryButton'} onClick={onCancel}>
                        {t('buttons.cancel')}
                    </WMButton>
                    <WMButton
                        onClick={() => handleNext(updatedIdpData)}
                        disabled={
                            !isStepValidated() || savedIdpAppData.loading || createdIdpAppData.loading || updateAccountIdpUsersData.loading
                        }
                        loading={savedIdpAppData.loading || createdIdpAppData.loading || updateAccountIdpUsersData.loading}>
                        {stepChangesMapping[currentStep]
                            ? t(IdpConfigStepsData[currentStep].buttonTextOnChange)
                            : t(IdpConfigStepsData[currentStep].buttonTextNoChange)}
                    </WMButton>
                </DialogActions>
            </StyledExternalIdpDialog>
        </div>
    );
};

export default ExternalIdpWizard;
