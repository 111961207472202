import { GetSystemsRegexesResultItem } from '@walkme-admin-center/libs/exposure-api';
import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useGetSystemsRegexes = () => {
    const getSystemsRegexesAppData: AppData<GetSystemsRegexesResultItem[]> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.getSystemsRegexes
    );

    return { getSystemsRegexesAppData };
};

export default useGetSystemsRegexes;
