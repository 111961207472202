export function retriveYourAppsVideo() {
    return 'assets/icons/data-integrations/integrations-videos/your-apps-step.mov';
}
export function retriveCreateNewAppVideo() {
    return 'assets/icons/data-integrations/integrations-videos/create-new-app-step.mov';
}
export function retrivePasteManifestVideo() {
    return 'assets/icons/data-integrations/integrations-videos/paste-manifest-step.mov';
}
export function retriveClickCreateVideo() {
    return 'assets/icons/data-integrations/integrations-videos/click-create-step.mov';
}
export function retriveInstallAppVideo() {
    return 'assets/icons/data-integrations/integrations-videos/install-your-app-step.mov';
}
export function retriveVerifyAppVideo() {
    return 'assets/icons/data-integrations/integrations-videos/verify-your-app-step.mov';
}
export function retriveCopyBotTokenVideo() {
    return 'assets/icons/data-integrations/integrations-videos/bot-token.mov';
}
export function retriveCopySigningSecretVideo() {
    return 'assets/icons/data-integrations/integrations-videos/app-secret.mov';
}
