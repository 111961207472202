import styled from 'styled-components';

export const StyledUrlComponent = styled.div`
    display: flex;

    & .MuiFormControl-root.MuiTextField-root.WMTextField-wmTextField {
        margin-top: 10px;

        & .MuiInputBase-root.MuiInput-root.WMTextField-inputRoot.MuiInputBase-formControl.MuiInput-formControl {
            height: 40px;
        }
    }
`;
