import React, { useEffect, useRef, useState } from 'react';
import { CreateSystemCard } from './create-system-card';
import {
    StyledContentContainer,
    StyledPurposeAndUsageCardContainer,
    StyledPurposeAndUsageContainer,
    StyledPurposeAndUsageDescription,
    StyledPurposeAndUsageSection,
    StyledPurposeAndUsageTitle,
    StyledTrialMessage,
} from './purpose-and-usage.styles';
import { getSystemPurposeAndUsage, SectionTypes } from './purpose-and-usage.lib';
import { useDispatch, useSelector } from 'react-redux';
import { CreateSystemState, createSystemSlice } from '@walkme-admin-center/libs/state-management-systems';
import { SystemPurpose, SystemUsage } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { ToasterVariant } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const PurposeAndUsageStep = () => {
    const { t } = useTranslation('general');
    const purpose = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.purpose.data);
    const usage = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.usage.data);
    const dispatch = useDispatch();
    const SystemPurposeAndUsage = getSystemPurposeAndUsage();
    const outerRef = useRef(null);
    const innerRef = useRef(null);
    const [marginTop, setMarginTop] = useState<number | null>(null);

    const onCardClick = (value: SystemPurpose | SystemUsage, type: string) => {
        switch (type) {
            case SectionTypes.Purpose:
                dispatch(createSystemSlice.actions.setCreateSystemPurpose(value as SystemPurpose));
                break;
            case SectionTypes.Usage:
                dispatch(createSystemSlice.actions.setCreateSystemUsage(value as SystemUsage));
                break;
            default:
                break;
        }
    };

    const calculateMarginTop = () => {
        if (outerRef.current) {
            const marginTopValue = Math.max(
                0,
                (outerRef.current.getBoundingClientRect().height - innerRef.current.getBoundingClientRect().height) / 2
            );
            setMarginTop(marginTopValue);
        }
    };

    const handleResizeAndMarginTopCalculation = () => {
        calculateMarginTop();
        addEventListener('resize', calculateMarginTop);
        return () => {
            window.removeEventListener('resize', calculateMarginTop);
        };
    };

    useEffect(handleResizeAndMarginTopCalculation, []);

    return (
        <StyledContentContainer scroll={!!purpose} ref={outerRef}>
            <StyledPurposeAndUsageContainer marginTop={marginTop} ref={innerRef}>
                {SystemPurposeAndUsage.map((section) => {
                    return (
                        <StyledPurposeAndUsageSection hidden={!purpose && section.type !== 'purpose'}>
                            <StyledPurposeAndUsageTitle>{section.title}</StyledPurposeAndUsageTitle>
                            <StyledPurposeAndUsageDescription>{section.description}</StyledPurposeAndUsageDescription>
                            <StyledPurposeAndUsageCardContainer>
                                {section.options.map((option) => {
                                    {
                                        return (
                                            <CreateSystemCard
                                                title={option.title}
                                                subTitle={option.subTitle}
                                                value={option.value}
                                                selected={option.value === purpose || option.value === usage}
                                                onCardClick={() => onCardClick(option.value, option.type)}
                                            />
                                        );
                                    }
                                })}
                            </StyledPurposeAndUsageCardContainer>
                            {purpose === SystemPurpose.TRIAL && section.type === 'purpose' && (
                                <StyledTrialMessage
                                    icon
                                    variant={ToasterVariant.Info}
                                    message={t('systems-tab.new-system-form.purpose.tooltip')}
                                    width={'auto'}
                                />
                            )}
                        </StyledPurposeAndUsageSection>
                    );
                })}
            </StyledPurposeAndUsageContainer>
        </StyledContentContainer>
    );
};
