import React from 'react';
import {
    IHeaderText,
    IGuidedAnswersBulkUpdateData,
    IGuidedAnswers,
    IAskMe,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { User } from 'wm-accounts-sdk';
import EditCrawlerFooter from '../data-integration-form-components/footers/edit-crawler-footer';
import { Crawler } from './crawler';
import SettingsStep from '../data-integration-form-components/crawler-form/steps/settings-step/settings-step';

type ISourceWithGuidedAnswers = IAskMe & { guidedAnswers: IGuidedAnswers };

interface IHandleSubmit {
    instanceData: ISourceWithGuidedAnswers;
    values: ISourceWithGuidedAnswers;
    user: User;
}

export class SourceSettings extends Crawler {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    getFormHeader(integration: IAskMe): IHeaderText {
        return { header: `Source Settings`, subHeader: integration.name };
    }

    getOnePageForm(): React.ReactNode {
        return <SettingsStep isAuthError={this.isAuthError} />;
    }

    getOnePageFooter() {
        return <EditCrawlerFooter onClose={this.closeForm} />;
    }
}
