import React from 'react';
import { Base } from './base';
import { bulkUpdateGuidedAnswers } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { CONSTS } from '../../../../../sso-configuration/src/lib/common/consts';
import {
    IHeaderText,
    IGuidedAnswersBulkUpdateData,
    IGuidedAnswers,
    IAskMe,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { guidedAnswersSchema } from '../services/validation/files copy/guided-answers-step-validation';
import { getErrorObject } from '../services/validation/validate';
import { cloneDeep, differenceBy, differenceWith, isEqual, orderBy } from 'lodash';
import GuidedAnswersStep from '../guided-answers-form/guided-answers-step';
import GuidedAnswersFooter from '../data-integration-form-components/footers/guided-answers-footer';
import { User } from 'wm-accounts-sdk';
import { datadogLogs, StatusType } from '@datadog/browser-logs';

type ISourceWithGuidedAnswers = IAskMe & { guidedAnswers: IGuidedAnswers };

interface IHandleSubmit {
    instanceData: ISourceWithGuidedAnswers;
    values: ISourceWithGuidedAnswers;
    user: User;
}

export class GuidedAnswers extends Base {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    async handleSubmit({ instanceData, values, user }: IHandleSubmit): Promise<void> {
        await this.updateGuidedAnswers(instanceData, values.guidedAnswers, user);
    }

    async updateGuidedAnswers(source: ISourceWithGuidedAnswers, newValues: IGuidedAnswers, user: User): Promise<void> {
        try {
            const oldValues = source.guidedAnswers ?? [];
            const newValuesNoNulls = newValues.filter((ga) => ga);

            const data: IGuidedAnswersBulkUpdateData = {
                add: newValuesNoNulls.filter((ga) => !ga.guid),
                edit: differenceWith(
                    newValuesNoNulls.filter((ga) => ga.guid),
                    oldValues,
                    isEqual
                ),
                delete: differenceBy(oldValues, newValuesNoNulls, 'guid').map((ga) => ga.guid),
            };
            await this.dispatch(bulkUpdateGuidedAnswers(source.sourceId, data));

            datadogLogs.logger.log(
                'User updated guided answers for source',
                {
                    userId: user.id,
                    userEmail: user.email,
                    sourceGuid: source.sourceId,
                    data,
                    logType: 'AiaGuidedAnswersUpdate',
                },
                StatusType.info
            );

            this.closeForm();
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to update answers`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    getFormHeader(originalSource: ISourceWithGuidedAnswers): IHeaderText {
        return { header: `${originalSource.guidedAnswers?.length ? 'Manage' : 'Create'} Custom Q&A`, subHeader: null };
    }

    getOnePageForm(): React.ReactNode {
        return <GuidedAnswersStep viewFlag={true} />;
    }

    getOnePageFooter() {
        return <GuidedAnswersFooter onClose={this.closeForm} showSnackBar={this.showSnackBar} />;
    }

    async validate(values: IGuidedAnswers, step: number) {
        return await getErrorObject(guidedAnswersSchema, values);
    }

    initForm({ data: source }: { data?: ISourceWithGuidedAnswers }): ISourceWithGuidedAnswers {
        source.guidedAnswers = orderBy(source.guidedAnswers ?? [], ['created_at']);
        if (!source.guidedAnswers.length) {
            source.guidedAnswers = undefined;
        }
        return cloneDeep(source);
    }
}
