import React, { SVGProps } from 'react';

export const DeleteIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
        <path d='M3.5 3.5H12.5V13C12.5 13.8284 11.8284 14.5 11 14.5H5C4.17157 14.5 3.5 13.8284 3.5 13V3.5Z' stroke='#6D81A6' />
        <path d='M5.5 3.5H10.5V3C10.5 2.17157 9.82843 1.5 9 1.5H7C6.17157 1.5 5.5 2.17157 5.5 3V3.5Z' stroke='#6D81A6' />
        <path d='M1.5 3.5L14.5 3.5L1.5 3.5Z' stroke='#6D81A6' stroke-linejoin='round' />
        <path d='M6.5 11.5L6.5 6.5' stroke='#6D81A6' stroke-linecap='round' />
        <path d='M9.5 11.5L9.5 6.5' stroke='#6D81A6' stroke-linecap='round' />
    </svg>
);
