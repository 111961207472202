import { createSlice, PayloadAction, Action, ThunkDispatch } from '@reduxjs/toolkit';
import {
    AccountsSdk,
    Actions,
    CreateRoleDto,
    CreateUserDto,
    DeletedRole,
    DeletedUser,
    DeleteRoleDto,
    DeleteUserDto,
    EditRoleDto,
    EditUserDto,
    Role,
    Roles,
    User,
    Users,
    UserConfirmationEmailResponse,
    WmFetchPolicy,
    UsersBulkMutationResult,
    CreateUsersDto,
    WMPendingUserRequestItem,
    UpdatePendingUserRequestOfMyAccountDTO,
    EditUsersDto,
} from 'wm-accounts-sdk';
import { AppData, NotificationMessage } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { useUserTabData } from '../../../../../pages/home/users-roles/src/lib/users-tab/users-tab.pure';
import { deepUiApi } from '@walkme-admin-center/libs/deep-ui-api';
import { getTranslator } from 'apps/home/src/localization/localizationBase';
import { PermissionsErrorkey } from '@walkme-admin-center/libs/common';

const { notificationsMessagesKeys } = useUserTabData();

export interface BusinessDomain {
    id: number;
    key: string;
    name: string;
    description: string;
    workflowsTypesCount: number;
    order: number;
}
export interface UsersState {
    savedUser: AppData<User>;
    createUsers: AppData<UsersBulkMutationResult>;
    updateUsers: AppData<UsersBulkMutationResult>;
    deletedUser: AppData<DeletedUser>;
    deletedUsers: AppData<DeletedUser[]>;
    exportUsers: AppData<null>;
    users: AppData<Users>;
    pendingUsers: AppData<WMPendingUserRequestItem[]>;
    roles: AppData<Roles>;
    savedRole: AppData<Role>;
    deletedRole: AppData<DeletedRole>;
    actions: AppData<Actions>;
    sendEmailConfirmationStatus: AppData<UserConfirmationEmailResponse>;
    resetUserMfa: AppData<boolean>;
    notificationMessage: NotificationMessage;
    businessDomains: AppData<BusinessDomain[]>;
}

const initialRole = {
    id: null,
    displayName: null,
    description: null,
    shortDescription: null,
    permissions: [],
    accountId: null,
};

const initialUser: User = {
    id: null,
    email: null,
    firstName: null,
    lastLoginDate: null,
    lastName: null,
    idpNameId: null,
    isBackoffice: false,
    status: null,
    systems: [],
    role: initialRole,
};

export const initialUsersState: UsersState = {
    savedUser: {
        loading: false,
        error: null,
        data: initialUser,
    },
    createUsers: {
        loading: false,
        error: null,
        data: null,
    },
    updateUsers: {
        loading: false,
        error: null,
        data: null,
    },
    deletedUser: {
        loading: false,
        error: null,
        data: {
            firstName: null,
            lastName: null,
            accountId: null,
            id: null,
        },
    },
    deletedUsers: {
        loading: false,
        error: null,
        data: [
            {
                firstName: null,
                lastName: null,
                accountId: null,
                id: null,
            },
        ],
    },
    pendingUsers: {
        loading: false,
        error: null,
        data: [],
    },
    exportUsers: {
        loading: false,
        error: null,
        data: null,
    },
    users: {
        loading: false,
        error: null,
        data: [],
    },
    roles: {
        loading: false,
        error: null,
        data: [],
    },
    savedRole: {
        loading: false,
        error: null,
        data: initialRole,
    },
    deletedRole: {
        loading: false,
        error: null,
        data: {
            id: null,
        },
    },
    actions: {
        loading: false,
        error: null,
        data: [],
    },
    sendEmailConfirmationStatus: {
        loading: false,
        error: null,
        data: null,
    },
    resetUserMfa: {
        loading: false,
        error: null,
        data: null,
    },
    notificationMessage: {
        text: '',
        variant: WMSnackbarVariant.Success,
        isOpen: false,
    },
    businessDomains: {
        loading: false,
        error: null,
        data: [],
    },
};

const { UserDeletedMessage, UsersDeletedMessage } = notificationsMessagesKeys;

const t = getTranslator('general');

const updateStateWithNotificationMessage = ({ state, text, variant, isOpen }) => {
    const message = text === 'Forbidden resource' ? t(PermissionsErrorkey) : text;
    state.notificationMessage.text = message;
    state.notificationMessage.variant = variant;
    state.notificationMessage.isOpen = isOpen;
};

const usersSlice = createSlice({
    name: 'usersSlice',
    initialState: initialUsersState,
    reducers: {
        deleteUserStart: (state: UsersState) => {
            state.deletedUser.loading = true;
            return state;
        },
        deleteUserSuccess(state: UsersState, action: PayloadAction<DeletedUser>) {
            state.deletedUser.data = action.payload;
            state.deletedUser.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: t(UserDeletedMessage),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });

            return state;
        },
        deleteUserFailed(state, action: PayloadAction<string>) {
            state.deletedUser.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        deleteUserCleanup(state: UsersState) {
            state.deletedUser.data = null;
            state.deletedUser.loading = false;
            return state;
        },
        deleteUsersStart: (state: UsersState) => {
            state.deletedUsers.loading = true;
            state.deletedUsers.data = null;
            return state;
        },
        deleteUsersSuccess(state: UsersState, action: PayloadAction<DeletedUser[]>) {
            state.deletedUsers.data = action.payload;
            state.deletedUsers.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: t(UsersDeletedMessage, { numberDeletedUsers: action.payload.length.toString() }),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });

            return state;
        },
        deleteUsersFailed(state, action: PayloadAction<string>) {
            state.deletedUsers.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        deleteUsersCleanup(state: UsersState) {
            state.deletedUsers.data = null;
            state.deletedUsers.loading = false;
            return state;
        },
        createUsersStart: (state: UsersState) => {
            state.createUsers.loading = true;
            return state;
        },
        createUsersSuccess(state: UsersState, action: PayloadAction<UsersBulkMutationResult>) {
            state.createUsers.data = action.payload;
            state.createUsers.loading = false;
            return state;
        },
        createUsersFailed(state, action: PayloadAction<string>) {
            state.createUsers.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });

            return state;
        },
        createUsersCleanup(state: UsersState) {
            state.createUsers.data = null;
            state.createUsers.loading = false;
            return state;
        },
        updateUsersStart: (state: UsersState) => {
            state.updateUsers.loading = true;
            return state;
        },
        updateUsersSuccess(state: UsersState, action: PayloadAction<UsersBulkMutationResult>) {
            state.updateUsers.data = action.payload;
            state.updateUsers.loading = false;
            return state;
        },
        updateUsersFailed(state, action: PayloadAction<string>) {
            state.updateUsers.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        updateUsersCleanup(state: UsersState) {
            state.updateUsers.data = null;
            state.updateUsers.loading = false;
            return state;
        },
        saveUserStart: (state: UsersState) => {
            state.savedUser.loading = true;
            return state;
        },
        saveUserSuccess(state: UsersState, action: PayloadAction<User>) {
            state.savedUser.data = action.payload;
            state.savedUser.loading = false;
            return state;
        },
        saveUserFailed(state, action: PayloadAction<string>) {
            state.savedUser.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        saveUserCleanup(state: UsersState) {
            state.savedUser.data = null;
            state.savedUser.loading = false;
            return state;
        },
        getPendingUsersStart(state: UsersState) {
            state.pendingUsers.loading = true;
            return state;
        },
        getPendingUsersSuccess(state: UsersState, action: PayloadAction<WMPendingUserRequestItem[]>) {
            state.pendingUsers.data = action.payload;
            state.pendingUsers.loading = false;
            return state;
        },
        getPendingUsersFailed(state, action: PayloadAction<string>) {
            state.pendingUsers.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        getUsersStart(state: UsersState) {
            state.users.loading = true;
            return state;
        },
        getUsersSuccess(state: UsersState, action: PayloadAction<Users>) {
            state.users.data = action.payload;
            state.users.loading = false;
            return state;
        },
        getUsersFailed(state, action: PayloadAction<string>) {
            state.users.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        getRolesStart(state: UsersState) {
            state.roles.loading = true;
            return state;
        },
        getRolesSuccess(state: UsersState, action: PayloadAction<Roles>) {
            state.roles.data = action.payload;
            state.roles.loading = false;
            return state;
        },
        getRolesFailed(state, action: PayloadAction<string>) {
            state.roles.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        deleteRoleStart: (state: UsersState) => {
            state.deletedRole.loading = true;
            return state;
        },
        deleteRoleSuccess(state: UsersState, action: PayloadAction<DeletedRole>) {
            state.deletedRole.data = action.payload;
            state.deletedRole.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: t('notifications.role-deleted'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });

            return state;
        },
        deleteRoleFailed(state, action: PayloadAction<string>) {
            state.deletedRole.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });

            return state;
        },
        deleteRoleCleanup(state: UsersState) {
            state.deletedRole.data = null;
            state.deletedRole.loading = false;
            return state;
        },
        saveRoleStart: (state: UsersState) => {
            state.savedRole.loading = true;
            return state;
        },
        saveRoleSuccess(state: UsersState, action: PayloadAction<Role>) {
            state.savedRole.data = action.payload;
            state.savedRole.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: t('notifications.role-saved'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            });

            return state;
        },
        saveRoleFailed(state, action: PayloadAction<string>) {
            state.savedRole.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });

            return state;
        },
        saveRoleCleanup(state: UsersState) {
            state.savedRole.data = null;
            state.savedRole.loading = false;
            return state;
        },
        getActionsStart(state: UsersState) {
            state.actions.loading = true;
            return state;
        },
        getActionsSuccess(state: UsersState, action: PayloadAction<Actions>) {
            state.actions.data = action.payload;
            state.actions.loading = false;
            return state;
        },
        getActionsFailed(state, action: PayloadAction<string>) {
            state.actions.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        resendConfirmationMailStart(state: UsersState) {
            state.sendEmailConfirmationStatus.loading = true;
            return state;
        },
        resendConfirmationMailSuccess(state: UsersState, action: PayloadAction<UserConfirmationEmailResponse>) {
            state.sendEmailConfirmationStatus.data = action.payload;
            state.sendEmailConfirmationStatus.loading = false;
            return state;
        },
        resendConfirmationMailFailed(state, action: PayloadAction<string>) {
            state.sendEmailConfirmationStatus.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        resendConfirmationMailCleanup(state: UsersState) {
            state.sendEmailConfirmationStatus.data = null;
            state.sendEmailConfirmationStatus.loading = false;
            return state;
        },
        resetUserMfaStart(state: UsersState) {
            state.resetUserMfa.loading = true;
            return state;
        },
        resetUserMfaSuccess(state: UsersState, action: PayloadAction<boolean>) {
            state.resetUserMfa.data = action.payload;
            state.resetUserMfa.loading = false;
            return state;
        },
        resetUserMfaFailed(state, action: PayloadAction<string>) {
            state.resetUserMfa.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        resetUserMfaCleanup(state: UsersState) {
            state.resetUserMfa.data = null;
            state.resetUserMfa.loading = false;
            return state;
        },
        cleanUpNotificationMessage(state: UsersState) {
            state.notificationMessage.isOpen = false;
            return state;
        },
        setNotificationMessage(state: UsersState, action: PayloadAction<NotificationMessage>) {
            state.notificationMessage = action.payload;
            return state;
        },
        exportUsersStart(state: UsersState) {
            state.exportUsers.loading = true;
            return state;
        },
        exportUsersSuccess(state: UsersState) {
            state.exportUsers.loading = false;
            return state;
        },
        exportUsersFailed(state, action: PayloadAction<string>) {
            state.exportUsers.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
        getBusinessDomainsStart(state: UsersState) {
            state.businessDomains.loading = true;
            return state;
        },
        getBusinessDomainsSuccess(state: UsersState, action: PayloadAction<BusinessDomain[]>) {
            state.businessDomains.data = action.payload;
            state.businessDomains.loading = false;
            return state;
        },
        getBusinessDomainsFailed(state, action: PayloadAction<string>) {
            state.businessDomains.loading = false;
            updateStateWithNotificationMessage({
                state,
                text: action.payload,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return state;
        },
    },
});

export { usersSlice };
const {
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailed,
    deleteUsersStart,
    deleteUsersSuccess,
    deleteUsersFailed,
    createUsersStart,
    createUsersSuccess,
    createUsersFailed,
    createUsersCleanup,
    updateUsersStart,
    updateUsersSuccess,
    updateUsersFailed,
    updateUsersCleanup,
    saveUserStart,
    saveUserSuccess,
    saveUserFailed,
    saveUserCleanup,
    saveRoleStart,
    saveRoleSuccess,
    saveRoleFailed,
    saveRoleCleanup,
    deleteRoleStart,
    deleteRoleSuccess,
    deleteRoleFailed,
    deleteRoleCleanup,
    getPendingUsersFailed,
    getPendingUsersSuccess,
    getPendingUsersStart,
    getUsersFailed,
    getUsersSuccess,
    getUsersStart,
    getRolesStart,
    getRolesSuccess,
    getRolesFailed,
    getActionsStart,
    getActionsSuccess,
    getActionsFailed,
    resendConfirmationMailFailed,
    resendConfirmationMailSuccess,
    resendConfirmationMailStart,
    resetUserMfaStart,
    resetUserMfaSuccess,
    resetUserMfaFailed,
    resetUserMfaCleanup,
    cleanUpNotificationMessage,
    exportUsersStart,
    exportUsersSuccess,
    exportUsersFailed,
    getBusinessDomainsStart,
    getBusinessDomainsSuccess,
    getBusinessDomainsFailed,
} = usersSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<rootReducerType, unknown, Action<string>>;

export const createMultipleUsers =
    (createUsers: CreateUsersDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(createUsersStart());
            const usersBulkMutationResult: UsersBulkMutationResult = await AccountsSdk.getInstance().createMultipleUsers(createUsers);
            if (usersBulkMutationResult.errors.length > 0) {
                dispatch(createUsersFailed(t(`errors.failed-create-users`)));
                return;
            }
            dispatch(createUsersSuccess(usersBulkMutationResult));
            dispatch(getUsers(true));
            dispatch(getPendingUsers());
        } catch (err) {
            const errorMessage: string = err.message || t(`errors.failed-create-user`);
            dispatch(createUsersFailed(errorMessage));
            return;
        }
    };

export const saveUser =
    (createUser: CreateUserDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(saveUserStart());
            const savedUser: User = await AccountsSdk.getInstance().createUser(createUser);
            dispatch(saveUserSuccess(savedUser));
            dispatch(getUsers(true));
            dispatch(getPendingUsers());
        } catch (err) {
            const errorMessage: string = err.message || t(`errors.failed-create-user`);
            dispatch(saveUserFailed(errorMessage));
            return;
        }
    };

export const editUser =
    (editUser: EditUserDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(saveUserStart());
            const savedUser: User = await AccountsSdk.getInstance().updateUser(editUser);
            dispatch(saveUserSuccess(savedUser));
            dispatch(getUsers());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-edit-user');
            dispatch(saveUserFailed(errorMessage));
            return;
        }
    };

export const editMultipleUsers =
    (editUsers: EditUsersDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateUsersStart());
            const usersBulkMutationResult: UsersBulkMutationResult = await AccountsSdk.getInstance().updateMultipleUsers(editUsers);
            if (usersBulkMutationResult?.errors.length > 0) {
                const noPermissions = usersBulkMutationResult.errors.find((error) =>
                    error.message.toLowerCase().includes('not authorized')
                );
                const errorMessage: string = noPermissions ? 'Forbidden resource' : t('notifications.permission-error-text');
                dispatch(updateUsersFailed(errorMessage));
            } else {
                dispatch(updateUsersSuccess(usersBulkMutationResult));
            }
            dispatch(getUsers());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-edit-user');
            dispatch(updateUsersFailed(errorMessage));
            return;
        }
    };

export const deleteUser =
    (deleteUserDto: DeleteUserDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(deleteUserStart());
            const deleteUser: DeletedUser = await AccountsSdk.getInstance().deleteUser(deleteUserDto);
            dispatch(deleteUserSuccess(deleteUser));
            dispatch(getUsers());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-delete-user');
            dispatch(deleteUserFailed(errorMessage));
            return;
        }
    };

export const deleteUsers =
    (deleteUsersDto: DeleteUserDto[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(deleteUsersStart());
            const deletedUsersArray: DeletedUser[] = [];
            for (const userToDelete of deleteUsersDto) {
                const deleteUser: DeletedUser = await AccountsSdk.getInstance().deleteUser(userToDelete);
                deletedUsersArray.push(deleteUser);
            }
            dispatch(deleteUsersSuccess(deletedUsersArray));
            dispatch(getUsers());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-delete-users');
            dispatch(deleteUsersFailed(errorMessage));
            return;
        }
    };

export const saveRole =
    (createRole: CreateRoleDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(saveRoleStart());
            const savedRole: Role = await AccountsSdk.getInstance().createRole(createRole);
            dispatch(saveRoleSuccess(savedRole));
            dispatch(getRoles());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-create-role');
            dispatch(saveRoleFailed(errorMessage));
            return;
        }
    };

export const editRole =
    (editRole: EditRoleDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(saveRoleStart());
            const editedRole: Role = await AccountsSdk.getInstance().updateRole(editRole);
            dispatch(saveRoleSuccess(editedRole));
            dispatch(getRoles());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-edit-role');
            dispatch(saveRoleFailed(errorMessage));
            return;
        }
    };

export const deleteRole =
    (deleteRoleDto: DeleteRoleDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(deleteRoleStart());
            const deletedRole: DeletedRole = await AccountsSdk.getInstance().deleteRole(deleteRoleDto);
            dispatch(deleteRoleSuccess(deletedRole));
            dispatch(getRoles());
        } catch (err) {
            const errorMessage: string = err.message || t('notifications.failed-to-delete-role');
            dispatch(deleteRoleFailed(errorMessage));
            return;
        }
    };

export const getUsers =
    (forceLoad = false): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(getUsersStart());
            const users: Users = await AccountsSdk.getInstance().getUsers(forceLoad ? WmFetchPolicy.network : WmFetchPolicy.cache);
            dispatch(getUsersSuccess(users));
        } catch (err) {
            dispatch(getUsersFailed(err.message));
            return;
        }
    };

export const updatePendingUsersStatus =
    (updatePendingUsers: UpdatePendingUserRequestOfMyAccountDTO[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(getPendingUsersStart());
            const promises = updatePendingUsers.map((pendingUser) =>
                AccountsSdk.getInstance().pendingUsers.updateAccountPendingUser(pendingUser)
            );
            const updatedPendingUsersResults = await Promise.all(promises);
            const newPendingUsers = await AccountsSdk.getInstance().pendingUsers.getAllAccountPendingUsers();
            dispatch(getPendingUsersSuccess(newPendingUsers));
        } catch (err) {
            dispatch(getPendingUsersFailed(err.message));
            return;
        }
    };

export const getPendingUsers =
    (forceLoad = false): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(getPendingUsersStart());
            const pendingUsers = await AccountsSdk.getInstance().pendingUsers.getAllAccountPendingUsers();
            dispatch(getPendingUsersSuccess(pendingUsers));
        } catch (err) {
            dispatch(getPendingUsersFailed(err.message));
            return;
        }
    };

export const getRoles = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getRolesStart());
        const roles: Roles = await AccountsSdk.getInstance().getRoles();
        dispatch(getRolesSuccess(roles));
    } catch (err) {
        dispatch(getRolesFailed(err.message));
        return;
    }
};

export const getActions = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getActionsStart());
        const actions: Actions = await AccountsSdk.getInstance().getActions();
        dispatch(getActionsSuccess(actions));
    } catch (err) {
        dispatch(getActionsFailed(err.message));
        return;
    }
};

export const sendConfirmationMail =
    (userId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(resendConfirmationMailStart());
            const confirmationMail: UserConfirmationEmailResponse = await AccountsSdk.getInstance().resendConfirmationMail(userId);
            dispatch(resendConfirmationMailSuccess(confirmationMail));
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.message) || err.message || 'Unspecified error';
            dispatch(resendConfirmationMailFailed(errorMsg));
            return;
        }
    };

export const resetUserMfaSettings =
    (userId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(resetUserMfaStart());
            await new Promise((resolve) => setTimeout(resolve, 10000));
            await AccountsSdk.getInstance().accountMfaSettings.resetUserMfaOptions(userId);
            dispatch(resetUserMfaSuccess(true));
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.message) || err.message || 'Unspecified error';
            dispatch(resetUserMfaFailed(errorMsg));
            return;
        }
    };

export const getBusinessDomains =
    (forceLoad: boolean = false, throwError = true): AppThunk =>
    async (dispatch, getState) => {
        const currentBusinessDomains = getState().usersState.businessDomains.data;

        if (!forceLoad && currentBusinessDomains?.length > 0) return;

        try {
            dispatch(getBusinessDomainsStart());
            const businessDomains = await deepUiApi.getBusinessDomains();
            dispatch(getBusinessDomainsSuccess(businessDomains));
        } catch (err) {
            if (throwError) {
                dispatch(getBusinessDomainsFailed(err.message));
            }
            return;
        }
    };
