import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { createLogger } from 'redux-logger';

const shouldDisableDefaultMiddleware = () => {
    return window.clientConfig.NX_WM_NODE_ENV !== 'dev2' && window.clientConfig.NX_WM_NODE_ENV !== 'local';
};

const getMiddleware = (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware(
        shouldDisableDefaultMiddleware() && {
            serializableCheck: false,
            immutableCheck: false,
        }
    );

    if (window.clientConfig.NX_SHOW_LOGS === 'true') {
        defaultMiddleware.concat(createLogger());
    }

    return defaultMiddleware;
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getMiddleware(getDefaultMiddleware),
});

export default store;
