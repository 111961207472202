import * as React from 'react';
import { SVGProps } from 'react';
export const DoneSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <mask mask='alpha' id='mask0_4493_69314' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'>
            <g clipPath='url(#clip0_4493_69314)'>
                <circle cx='8' cy='8' r='7.5' stroke='#6D81A6' />
                <path d='M11 6L7 10L5 8' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
            </g>
        </mask>
        <g mask='url(#mask0_4493_69314)'>
            <rect width='16' height='16' fill='#41B7A6' />
        </g>
        <defs>
            <clipPath id='clip0_4493_69314'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
