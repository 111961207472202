import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { StyledSlider, StyledText, StyledBottomSection, StyledContainer } from './styles/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { updateSessionLifetime, useSessionLifetime } from '@walkme-admin-center/libs/state-management-users';
import { Box, Card, CardActions, CardContent } from '@material-ui/core';
import moment from 'moment';
import { WMButton } from '@walkme/wm-ui';
import SecurityCard from '../common/security-card';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping } from 'apps/home/src/localization/localization';

export interface SessionLifetimeProps {}

export interface SessionLifetimeMark {
    timeInMinutes: number;
    value: number;
    label: string;
}

export const SessionLifetime = (props: SessionLifetimeProps) => {
    const { sessionLifetimeAppData, sessionLifetimeOptionsAppData } = useSessionLifetime();
    const [marks, setMarks] = useState<SessionLifetimeMark[]>([]);
    const [updatedSessionLifeTime, setUpdatedSessionLifeTime] = useState<SessionLifetimeMark>(null);
    const { t, i18n } = useTranslation('general');

    useEffect(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
    }, [i18n.language]);

    useEffect(() => {
        const sessionMarks: SessionLifetimeMark[] = sessionLifetimeOptionsAppData.data
            ? sessionLifetimeOptionsAppData.data.map((optionInMin: number, index) => {
                  const displayValue = moment().to(moment(moment().add(optionInMin, 'minutes')));
                  return {
                      label: displayValue,
                      timeInMinutes: optionInMin,
                      value: index,
                  };
              })
            : [];
        setMarks(sessionMarks);
    }, [sessionLifetimeOptionsAppData, i18n.language]);

    function currentSession(): SessionLifetimeMark {
        const currenSessionLifetime =
            sessionLifetimeAppData.data && marks.find((mark) => mark.timeInMinutes === sessionLifetimeAppData.data);
        return currenSessionLifetime;
    }

    useEffect(() => {
        if (!sessionLifetimeAppData.loading && sessionLifetimeAppData.data) {
            setUpdatedSessionLifeTime(currentSession());
        }
    }, [sessionLifetimeAppData]);

    const dispatch = useDispatch();

    const setTime = useCallback(
        (e, indexValue) => {
            setUpdatedSessionLifeTime(marks[indexValue]);
        },
        [marks, setUpdatedSessionLifeTime]
    );

    const save = useCallback(() => {
        dispatch(updateSessionLifetime({ lifetime: updatedSessionLifeTime.timeInMinutes }));
    }, [dispatch, updatedSessionLifeTime]);

    const sessionLifeTimeContent = (
        <div>
            <Card style={{ boxShadow: 'none' }}>
                <CardContent>
                    <div>
                        {!sessionLifetimeAppData.data ? (
                            <StyledContainer>
                                <CircularProgress size={50} thickness={4} />
                            </StyledContainer>
                        ) : (
                            <Box padding='0px 24px 0px 24px' display={'flex'} justifyContent={'center'}>
                                <StyledSlider
                                    value={updatedSessionLifeTime ? updatedSessionLifeTime.value : 0}
                                    min={0}
                                    max={marks.length > 0 ? marks.length - 1 : 0}
                                    marks={marks}
                                    valueLabelDisplay='off'
                                    step={null}
                                    onChange={setTime}
                                />
                            </Box>
                        )}
                        <StyledBottomSection>
                            <StyledText>
                                <InfoOutlined fontSize={'small'} style={{ marginRight: '3px' }} />
                                {t('security-tab.session-timeout.best-practice-text')}
                            </StyledText>
                            <StyledText>
                                <InfoOutlined fontSize={'small'} style={{ marginRight: '3px' }} />
                                {t('security-tab.session-timeout.info-text')}
                            </StyledText>
                        </StyledBottomSection>
                    </div>
                    <CardActions
                        disableSpacing
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <WMButton
                            onClick={save}
                            disabled={
                                !updatedSessionLifeTime ||
                                sessionLifetimeAppData.loading ||
                                (updatedSessionLifeTime && currentSession() === updatedSessionLifeTime)
                            }
                            loading={sessionLifetimeAppData.loading}>
                            {t('buttons.save')}
                        </WMButton>
                    </CardActions>
                </CardContent>
            </Card>
        </div>
    );

    return (
        <SecurityCard
            name='session-timeout'
            marginTop={'32px'}
            collapsableComponant={sessionLifeTimeContent}
            handleSwitchClick={() => {}}
            loading={false}
            title={t('security-tab.session-timeout.title')}
            subTitle={t('security-tab.session-timeout.subtitle')}
            checked={true}
            disabled={true}
            error={sessionLifetimeAppData.error}
        />
    );
};

export default SessionLifetime;
