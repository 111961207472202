import { System } from 'wm-accounts-sdk';
import { useRef, useState } from 'react';
import { ProductionEnvId } from '../consts';

interface EnvOption {
    value: number;
    label: string;
}

const useEnvironmentSelection = (system: System, defaultEnv?: EnvOption) => {
    const environmentOptions = useRef(getEnvironmentOptions(system));

    const [selectedEnvOption, setSelectedEnvOption] = useState<EnvOption>(defaultEnv || getDefaultEnvOption(environmentOptions.current));

    return { environmentOptions, selectedEnvOption, setSelectedEnvOption };
};

const getEnvironmentOptions = (system: System): EnvOption[] => {
    if (!system || !system.environments) {
        return [];
    }

    return system.environments.map((env) => ({ value: env.id, label: env.name }));
};

const getDefaultEnvOption = (envOptions: EnvOption[]): EnvOption => {
    return envOptions?.find((envOption) => envOption.value === ProductionEnvId);
};

export default useEnvironmentSelection;
