import { useSelector } from 'react-redux';
import { DesktopTrackedAppsState } from '../redux/desktop-tracked-apps.slice';

export const useActivateDesktopTrackedApps = () => {
    const activateDesktopTrackedAppsData = useSelector(
        (state: { desktopTrackedAppsState: DesktopTrackedAppsState }) => state.desktopTrackedAppsState.activateDesktopTrackedApps
    );

    return {
        activateDesktopTrackedAppsData
    };
};

export default useActivateDesktopTrackedApps;
