import React, { useState, useEffect, useRef } from 'react';
import AssignSystemsStep from './assign-systems-step';
import { useGlobalSettings, useIntegrations, useSlack, useTeams } from '@walkme-admin-center/libs/state-mangment-data-integration';
import {
    AccessWindowContainer,
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
    EnableIntegrationButton,
    StyledRadioGroup,
    SystemCounter,
} from './access-window-style';
import { Field, useField, useForm } from 'react-final-form';
import {
    AuthType,
    FormMode,
    FormTypes,
    IAskMeForm,
    IIntegration,
} from '../../../../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { Spacer } from '../../../../../common/style';
import IntegrationTab from '../integration-manager/integration-manager';
import { AiFeatureFlags, integrationsArray } from '../../../../../common/consts';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import styled, { css } from 'styled-components';
import { SystemIcon, UniversalIcon } from '../../../../../data-integrations-list/integration-card/icons/icons';
import CustomRadioButton from '../../../../../common/components/custom-radio-button';
import { retrieveIntegrationsIcon } from '@walkme-admin-center/libs/common';
import { getToolTip } from '../../../../../common/services';
import { CssSwitch, SwitchLabel } from '../../../../data-integration-form-style';
import {
    SectionIconContainer,
    SectionTitleContainer,
    TitleSubsectionContainer,
    SectionTitle,
    SubsectionTitle,
    SectionSubtitle,
    SubIconContainer,
    SubsectionContainer,
    AccordionSummaryContainer,
    Divider,
} from './assign-systems-tabs-style';

const UniversalSystemIcon = () => {
    return (
        <SectionIconContainer>
            <SystemIcon />
            <SubIconContainer>
                <UniversalIcon style={{ width: '0.6875rem', height: '0.6875rem' }} />
            </SubIconContainer>
        </SectionIconContainer>
    );
};

const ChevronContainer = styled.div<{ isExpanded?: boolean }>`
    height: 1rem;
    transition: transform 0.3s ease-in-out;
    ${(props) =>
        props.isExpanded &&
        css`
            transform: rotate(180deg);
        `}
`;

interface AssignSystemsTabsProps {
    triggerModal: (formType: FormTypes, name: string) => void;
    formMode: FormMode;
}

const AssignSystemsTabs = ({ triggerModal, formMode }: AssignSystemsTabsProps) => {
    const { isAdminMode } = useGlobalSettings();
    const { accountFeatureEnabled } = useLoggedInUser();

    const { slackIntegrationsData } = useSlack();
    const { teamsIntegrationsData } = useTeams();
    const { integrationsData } = useIntegrations();

    const fromApi = useForm<IAskMeForm>();
    const [expanded, setExpanded] = useState('systems');
    const { systems, isOmni } = fromApi.getState().values;
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : null);
    };

    const {
        input: { value: authType },
    } = useField('settings.authType', { subscription: { value: true } });
    const {
        input: { value: isPrivate },
    } = useField('isPrivate', {
        subscription: { value: true },
        format: (value) => (value === '' ? undefined : value),
    });

    const previousSlackData = useRef<IIntegration | undefined>(slackIntegrationsData);
    const previousTeamsData = useRef<IIntegration | undefined>(teamsIntegrationsData);

    useEffect(() => {
        if (previousSlackData.current?.guid !== slackIntegrationsData?.guid) {
            setExpanded('slack');
        }
        previousSlackData.current = slackIntegrationsData;
    }, [slackIntegrationsData]);

    useEffect(() => {
        if (previousTeamsData.current?.guid !== teamsIntegrationsData?.guid) {
            setExpanded('teams');
        }
        previousTeamsData.current = teamsIntegrationsData;
    }, [teamsIntegrationsData]);

    const integrationExists = (type: string) => {
        return integrationsData.data.find((integration) => integration.type === type);
    };

    return (
        <AccessWindowContainer>
            <SectionTitleContainer>
                <SectionTitle>Locations</SectionTitle>
                <SectionSubtitle>Select where your knowledge source can be accessed</SectionSubtitle>
            </SectionTitleContainer>
            <Spacer height={1} />
            <AccordionStyled
                id={'system'}
                expanded={expanded === 'systems' && !isOmni}
                onChange={(event, isExpanded) => handleChange('systems')(event, isExpanded)}>
                <AccordionSummaryStyled>
                    <SubsectionContainer>
                        <Field name='isOmni' type='radio'>
                            {({ input, meta }) => (
                                <StyledRadioGroup
                                    {...input}
                                    defaultValue={0}
                                    value={isOmni ? 'true' : 'false'}
                                    onChange={(event, value) => input.onChange(value === 'true' ? true : undefined)}>
                                    <div className={'flex-column'}>
                                        <TitleSubsectionContainer onClick={() => input.onChange(true)}>
                                            <CustomRadioButton value={'true'} />
                                            <SectionIconContainer>
                                                <UniversalSystemIcon />
                                            </SectionIconContainer>
                                            <SectionTitleContainer>
                                                <SubsectionTitle>Universal Source</SubsectionTitle>
                                                <SectionSubtitle>
                                                    Accessible on all sites and locations when WalkMe is loaded
                                                </SectionSubtitle>
                                            </SectionTitleContainer>
                                        </TitleSubsectionContainer>
                                        <Divider />
                                        <TitleSubsectionContainer onClick={() => input.onChange(undefined)}>
                                            <CustomRadioButton value={'false'} />
                                            <SectionIconContainer>
                                                <SystemIcon />
                                            </SectionIconContainer>
                                            <SectionTitleContainer>
                                                <div className={'flex-row'}>
                                                    <SubsectionTitle>Location-Specific</SubsectionTitle>
                                                    {!isOmni && (
                                                        <SystemCounter collapsed={true}>
                                                            {systems && systems.length ? systems.length : 0} Selected
                                                        </SystemCounter>
                                                    )}
                                                </div>
                                                <SectionSubtitle>Select which locations can access this source</SectionSubtitle>
                                            </SectionTitleContainer>
                                            <ChevronContainer isExpanded={expanded === 'systems' && !isOmni}>
                                                <img src='assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg' />
                                            </ChevronContainer>
                                        </TitleSubsectionContainer>
                                    </div>
                                </StyledRadioGroup>
                            )}
                        </Field>
                    </SubsectionContainer>
                </AccordionSummaryStyled>
                <AccordionDetailsStyled>
                    <AssignSystemsStep viewFlag={null} />
                </AccordionDetailsStyled>
            </AccordionStyled>
            <Spacer height={1.25} />
            {accountFeatureEnabled(AiFeatureFlags.ENABLE_ASK_ME) && (
                <>
                    <SectionTitleContainer>
                        <SectionTitle>Integrations</SectionTitle>
                        <SectionSubtitle>Integrate Walkme knowledge source with your apps</SectionSubtitle>
                    </SectionTitleContainer>
                    <Spacer height={1} />
                    {integrationsArray.map((integration, i) => {
                        const userIntegration = integrationExists(integration.type);
                        return (
                            (!!isAdminMode || integration.isGa || accountFeatureEnabled(integration.featureFlag)) && (
                                <AccordionStyled
                                    key={i}
                                    disabled={!!!userIntegration}
                                    id={integration.type}
                                    expanded={expanded === integration.type}
                                    onChange={(event, isExpanded) => !!userIntegration && handleChange(integration.type)(event, isExpanded)}
                                    style={{ marginBottom: '0.75rem' }}>
                                    <AccordionSummaryStyled
                                        style={{ cursor: userIntegration ? 'pointer' : 'default' }}
                                        expandIcon={
                                            !!userIntegration ? (
                                                <img src={'assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg'} />
                                            ) : null
                                        }>
                                        <AccordionSummaryContainer>
                                            <TitleSubsectionContainer>
                                                <SectionIconContainer>
                                                    <img
                                                        src={retrieveIntegrationsIcon(integration.type)}
                                                        alt={integration.name}
                                                        style={{ height: '1.25rem', width: '1.25rem' }}
                                                    />
                                                </SectionIconContainer>
                                                <SectionTitleContainer>
                                                    <SubsectionTitle>{integration.name}</SubsectionTitle>
                                                    <SectionSubtitle>
                                                        {!!!userIntegration ? integration.preSetupInfo : integration.info}
                                                    </SectionSubtitle>
                                                </SectionTitleContainer>
                                            </TitleSubsectionContainer>
                                            {!!!userIntegration && (
                                                <EnableIntegrationButton
                                                    onClick={() =>
                                                        triggerModal(FormTypes[integration.type.toUpperCase()], integration.name)
                                                    }>
                                                    Enable
                                                </EnableIntegrationButton>
                                            )}
                                        </AccordionSummaryContainer>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        {userIntegration && <IntegrationTab formMode={formMode} integration={userIntegration} />}
                                    </AccordionDetailsStyled>
                                </AccordionStyled>
                            )
                        );
                    })}
                </>
            )}
            <Spacer height={1.25} />
            <SectionTitleContainer>
                <SectionTitle>Advanced</SectionTitle>
                <SectionSubtitle>Configure advanced access options</SectionSubtitle>
            </SectionTitleContainer>
            <Spacer height={1} />
            <Field name={'isPrivate'} type={'checkbox'}>
                {({ input, meta }) => (
                    <div style={{ height: '48px', display: 'flex', alignItems: 'center' }}>
                        <CssSwitch
                            disableRipple
                            {...input}
                            checked={isPrivate != null ? !isPrivate : authType === AuthType.NO_AUTH || !authType?.length}
                            onChange={() => input.onChange(!isPrivate)}
                        />
                        <SwitchLabel className={`${input.checked ? 'active' : ''}`}>Public Access</SwitchLabel>
                        {getToolTip('Enable to allow all your WalkMe users to access this source. Disable to restrict to VPN users.')}
                    </div>
                )}
            </Field>
        </AccessWindowContainer>
    );
};

export default AssignSystemsTabs;
