import { useDispatch, useSelector } from 'react-redux';
import { SelfHostedState } from '../self-hosted-state';
import { useCallback, useEffect } from 'react';
import { EnvSettingsParams, getSelfHostedSettings, selfHostedSlice } from '../self-hosted.slice';
import { EnvironmentSettings } from '../../types/environment-settings';

const useSelfHostedSettings = (params: EnvSettingsParams, isActive: boolean) => {
    const settingsByEnv = useSelector((state: { selfHostedState: SelfHostedState }) => state.selfHostedState.settingsByEnv);

    const dispatch = useDispatch();

    const getEnvironmentSettings = useCallback(
        (envId): EnvironmentSettings => {
            return settingsByEnv[envId];
        },
        [settingsByEnv]
    );

    useEffect(() => {
        dispatch(selfHostedSlice.actions.clearEnvSettings());
    }, [dispatch, params.systemId]);

    useEffect(() => {
        if (isActive) {
            dispatch(getSelfHostedSettings(params));
        }
    }, [dispatch, isActive, params]);

    return { getEnvironmentSettings };
};

export default useSelfHostedSettings;
