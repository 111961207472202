import React, { SVGProps } from 'react';

export const WalkmeIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
        <mask id='path-1-inside-1_7540_5424' fill='white'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5 3C2.23858 3 0 5.23858 0 8V11C0 13.7614 2.23858 16 5 16H7.5L10 18L12.5 16H15C17.7614 16 20 13.7614 20 11V8C20 5.23858 17.7614 3 15 3H5Z'
            />
        </mask>
        <path
            d='M7.5 16L8.12469 15.2191L7.85078 15H7.5V16ZM10 18L9.37531 18.7809L10 19.2806L10.6247 18.7809L10 18ZM12.5 16V15H12.1492L11.8753 15.2191L12.5 16ZM1 8C1 5.79086 2.79086 4 5 4V2C1.68629 2 -1 4.68629 -1 8H1ZM1 11V8H-1V11H1ZM5 15C2.79086 15 1 13.2091 1 11H-1C-1 14.3137 1.68629 17 5 17V15ZM7.5 15H5V17H7.5V15ZM10.6247 17.2191L8.12469 15.2191L6.87531 16.7809L9.37531 18.7809L10.6247 17.2191ZM11.8753 15.2191L9.37531 17.2191L10.6247 18.7809L13.1247 16.7809L11.8753 15.2191ZM15 15H12.5V17H15V15ZM19 11C19 13.2091 17.2091 15 15 15V17C18.3137 17 21 14.3137 21 11H19ZM19 8V11H21V8H19ZM15 4C17.2091 4 19 5.79086 19 8H21C21 4.68629 18.3137 2 15 2V4ZM5 4H15V2H5V4Z'
            fill='#6D81A6'
            mask='url(#path-1-inside-1_7540_5424)'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.1993 9.88387C16.1105 10.5272 15.8055 11.1205 15.3397 11.5697C14.8961 11.9856 14.3027 12.2075 13.6982 12.1908C13.4764 12.1908 13.2601 12.1631 13.0494 12.1021C12.8386 12.0411 12.6335 11.9579 12.4449 11.8415C12.0567 11.6085 11.7406 11.2814 11.5188 10.8821C11.297 10.4883 11.1861 10.0391 11.1916 9.58995C11.1916 9.25167 11.2526 8.91894 11.3802 8.60284C11.5077 8.28674 11.6907 8.00391 11.9292 7.75991C12.1621 7.52145 12.4338 7.32735 12.7444 7.19426C13.0494 7.06671 13.3821 7.00016 13.7148 7.00016C14.1918 6.99461 14.6631 7.13325 15.0624 7.39944C15.4783 7.69336 15.8111 8.09264 16.0274 8.55847L12.7776 10.3663L12.4061 9.68977L14.8184 8.33665C14.6798 8.17583 14.5079 8.04828 14.3193 7.95955C14.1252 7.87636 13.92 7.832 13.7093 7.83754C13.4986 7.83754 13.2878 7.87636 13.0937 7.96509C12.8996 8.05382 12.7277 8.18137 12.5835 8.34219C12.2785 8.68048 12.1122 9.12412 12.1288 9.57886C12.1177 10.0336 12.2841 10.4772 12.5891 10.8155C12.7277 10.9764 12.8996 11.1039 13.0937 11.1926C13.2878 11.2814 13.4986 11.3257 13.7093 11.3202C13.9034 11.3202 14.103 11.2925 14.2805 11.2204C14.4635 11.1483 14.6299 11.0374 14.7685 10.8987C15.068 10.5882 15.2565 10.1778 15.3009 9.74523L16.2048 9.88387H16.1993Z'
            fill='#6D81A6'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.00376 12V9.20717C3.97048 8.62488 4.15903 8.05368 4.53613 7.60449C4.71914 7.40485 4.94096 7.24957 5.18497 7.14975C5.43452 7.04993 5.70071 7.00002 5.9669 7.01111C6.26081 7.01111 6.55473 7.07766 6.81537 7.20521C7.08156 7.3383 7.30893 7.53794 7.48639 7.7764C7.66385 7.53794 7.89676 7.3383 8.16295 7.19966C8.4236 7.07211 8.71197 7.00556 9.00588 7.00556C9.27207 6.99447 9.53826 7.03884 9.78227 7.1442C10.0263 7.24402 10.2481 7.3993 10.4311 7.59894C10.8027 8.04814 10.9968 8.61933 10.9635 9.20162V11.9945H10.0817V9.03525C10.0984 8.7247 9.993 8.42524 9.78781 8.19232C9.68799 8.08696 9.56599 8.00377 9.43289 7.94832C9.2998 7.89286 9.15562 7.86513 9.01143 7.87068C8.86724 7.86513 8.72306 7.89286 8.58442 7.94832C8.45132 8.00377 8.32932 8.08696 8.2295 8.19232C8.02431 8.42524 7.91895 8.7247 7.93558 9.03525V11.9945H7.05383V9.03525C7.06493 8.7247 6.95956 8.41969 6.74883 8.19232C6.64901 8.08696 6.527 8.00377 6.39391 7.94832C6.26081 7.89286 6.11108 7.86513 5.9669 7.87068C5.82271 7.86513 5.67853 7.88731 5.53989 7.94277C5.40679 7.99823 5.28479 8.08141 5.18497 8.18678C4.97978 8.41969 4.87442 8.71915 4.89105 9.02971V11.9889H4.0093L4.00376 12Z'
            fill='#6D81A6'
        />
    </svg>
);
