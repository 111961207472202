import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { siteConfigApi } from '../api/site-config-api';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../../../../apps/home/src/localization';
import { WMLoader, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { Button, InputHeight, ToasterVariant } from '@walkme/ui-core';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import {
    IMPERSONATION_PASSWORD_ERROR,
    ImpersonationPasswordError,
    impersonationSlice,
    ImpersonationState,
    impersonationThunks,
    systemsSlice,
} from '@walkme-admin-center/libs/state-management-systems';
import {
    MainContainer,
    ContentContainer,
    HeaderMainText,
    HeaderContainer,
    LoaderContainer,
    StyledSaveAndPublish,
    StyledInput,
    StyledInputLabel,
    StyledBodyContainer,
    StyledVariableInputContainer,
    StyledToasterContainer,
    StyledToasterMessage,
} from './language-identification.styles';
import { LanguageDetectorSelector } from './language-detector-selector';
import { DropdownOption, hasVariable, Lang_Detector, DetectorOptions } from './consts';
import { SaveAndPublishDialog } from '../save-and-publish-dialog';
import { StyledSaveSection } from '../save-and-publish-dialog.styles';
import { DoneSVG as DoneCheck } from '../create-system/steps/optional-configure/assets/DoneSVG';
import { ImpersonatorDialog } from '../impersonator-dialog';
import { NotificationMessage } from '@walkme-admin-center/libs/types';
import { set } from 'lodash';

const WRONG_MASTER_PASSWORD_ERROR = 'WrongMasterPassword';

interface SiteConfigPayLoad {
    configurationString: string;
    systemId: any;
    isImpersonated: boolean;
    impPassword?: string;
}

const loaderComponent = (
    <LoaderContainer>
        <WMLoader style={{ width: '30%' }} />
    </LoaderContainer>
);

const getUnsupportedConfigToaster = (text) => {
    const ToasterMessage = (
        <StyledToasterMessage>
            <div>{text}</div>
        </StyledToasterMessage>
    );
    return <StyledToasterContainer icon={true} variant={ToasterVariant.Info} message={ToasterMessage} width='90%' />;
};

const MULTI_LANG_FEATURE = 'HTML Lang Attribute Multi-Language Auto-Toggle';
export const LanguageIdentification = ({ systemId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const [languageSettings, setLanguageSettings] = useState();
    const [method, setMethod] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(undefined);
    const [variableValue, setVariableValue] = useState(undefined);
    const [multiLangFeatureEnabled, setMultiLangFeatureEnabled] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [saveAndPublishData, setSaveAndPublishData] = useState(false);
    const [siteConfiguration, setSiteConfiguration] = useState({ Custom: { setWalkMeLanguage: {} } });
    const [loading, setLoading] = useState(true);
    const { loggedInUserAppData } = useLoggedInUser();
    const [selectedDetector, setSelectedDetector] = useState(null);
    const [isPublishing, setIsPublishing] = useState(false);

    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;

    const impersonationPassword = useSelector(
        (state: { impersonationState: ImpersonationState }) => state.impersonationState.currentPassword
    );

    const inputPlaceholder = t(`systems-tab.new-system-form.configure-step.uuid.variable-input.placeholder`);

    const isDetectorSupported = (): boolean => {
        return selectedDetector?.value != undefined && DetectorOptions.some((option) => option.value == selectedDetector.value);
    };

    const defaultSnackbarMessage: NotificationMessage = { text: '', variant: WMSnackbarVariant.Success, isOpen: false };
    const [snackbarMessage, setSnackbarMessage] = useState(defaultSnackbarMessage);

    const handleSnackBarClose = () => {
        setSnackbarMessage(defaultSnackbarMessage);
    };

    const updateSelectedMethod = (selectedOption: DropdownOption) => {
        setSelectedDetector(selectedOption);
        setMethod(selectedOption.value);
        setDefaultLanguage(undefined);
        setVariableValue(undefined);
    };

    const languageDetectionConfigChanged = (): boolean => {
        if (!languageSettings) {
            return method != Lang_Detector.OFF;
        }

        // @ts-ignore
        if (!languageSettings?.method && languageSettings?.detect) {
            if (method != Lang_Detector.AUTO) {
                return true;
            }
        }
        // @ts-ignore
        else if (languageSettings?.method != method) {
            return true;
        }

        // @ts-ignore
        if (languageSettings?.default != defaultLanguage) {
            return true;
        }

        // @ts-ignore
        if (languageSettings?.key != variableValue) {
            return true;
        }

        return false;
    };

    const languageDetectionConfigValid = (): boolean => {
        if (method == Lang_Detector.OFF) {
            return true;
        } else {
            if (!defaultLanguage || defaultLanguage.trim().length == 0) {
                return false;
            }
            if (hasVariable.includes(method) && (!variableValue || variableValue.trim().length == 0)) {
                return false;
            }
        }
        return true;
    };

    const isSaveDisabled = (): boolean => {
        return !languageDetectionConfigChanged() || !languageDetectionConfigValid() || savingData || saveAndPublishData;
    };

    const loadConfiguration = async () => {
        try {
            const result = await siteConfigApi.getUserConfiguration(systemId);
            const languageSettings = result?.SiteConfiguration?.Custom?.setWalkMeLanguage;

            setSiteConfiguration(result?.SiteConfiguration);
            setLanguageSettings(languageSettings);

            let selectedMethod;
            if (!languageSettings) {
                selectedMethod = Lang_Detector.OFF;
            } else {
                selectedMethod = languageSettings?.method;
                setDefaultLanguage(languageSettings?.default);
                setVariableValue(languageSettings?.key);

                if (!languageSettings?.method && languageSettings?.detect) {
                    selectedMethod = Lang_Detector.AUTO;
                }
            }
            setMethod(selectedMethod);
            setSelectedDetector(DetectorOptions.find((option) => option.value == selectedMethod));

            const userFeatures = await siteConfigApi.getUserFeatureNames(systemId);
            const data = userFeatures?.data || [];
            const isFeatureEnable = data.includes(MULTI_LANG_FEATURE);
            setMultiLangFeatureEnabled(isFeatureEnable);
        } catch (e) {
            dispatch(
                systemsSlice.actions.setNotificationMessage({
                    text: t('errors.unspecified-error'),
                    variant: WMSnackbarVariant.Error,
                    isOpen: true,
                })
            );
        }
    };

    const saveLanguageDetectionSettings = async (impPassword?: string) => {
        let customToSave = {};
        const Custom = siteConfiguration.Custom;
        let setWalkMeLanguage = {};
        let activateFeature = false;
        if (Custom?.setWalkMeLanguage) {
            delete Custom.setWalkMeLanguage;
        }

        if (method == Lang_Detector.OFF) {
            customToSave = Custom;
        } else {
            setWalkMeLanguage['method'] = method;
            setWalkMeLanguage['default'] = defaultLanguage;
            if (hasVariable.includes(method)) {
                setWalkMeLanguage['key'] = variableValue;
            } else if (method == Lang_Detector.AUTO) {
                setWalkMeLanguage['detect'] = {
                    searchTextSelector: 'body *:visible',
                    maxStringDetectionLength: 200,
                };
            }
            customToSave = { ...Custom, setWalkMeLanguage };
            activateFeature = true;
        }

        const result = { ...siteConfiguration, Custom: customToSave };

        const payload: SiteConfigPayLoad = {
            configurationString: JSON.stringify(result),
            systemId,
            isImpersonated,
            impPassword,
        };

        const response = await siteConfigApi.saveSiteConfiguration(payload);
        if (response.data?.ErrorObject && response.data?.ErrorMessage == IMPERSONATION_PASSWORD_ERROR) {
            throw new ImpersonationPasswordError();
        }

        if (activateFeature != multiLangFeatureEnabled) {
            await siteConfigApi.updateUserFeatures({
                systemId: systemId,
                updatedUserFeatures: [{ featureName: MULTI_LANG_FEATURE, isActivated: activateFeature }],
            });
        }

        await loadConfiguration();
    };

    const onSaveSucceeded = () => {
        dispatch(
            systemsSlice.actions.setNotificationMessage({
                text: t('systems-tab.new-system-form.configure-step.notifications.success'),
                variant: WMSnackbarVariant.Success,
                isOpen: true,
            })
        );
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };

    const onSaveFailed = (message: string) => {
        dispatch(
            systemsSlice.actions.setNotificationMessage({
                text: message,
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            })
        );
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };

    const handleSave = async (impersonationPassword) => {
        try {
            setSavingData(true);

            await saveLanguageDetectionSettings(impersonationPassword);
            onSaveSucceeded();
        } catch (error) {
            if (error instanceof ImpersonationPasswordError) {
                onSaveFailed(t('notifications.impersonated-save-fail'));
            } else {
                onSaveFailed(t('systems-tab.new-system-form.configure-step.notifications.fail'));
            }
            console.error(error);
        } finally {
            setSavingData(false);
        }
    };

    const onSaveClicked = async () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(handleSave));
        } else {
            await handleSave(null);
        }
    };

    const onPublishFailed = (message: string) => {
        setSnackbarMessage({ text: message, variant: WMSnackbarVariant.Error, isOpen: true });
        setSaveAndPublishData(false);
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };

    const onPublishSucceeded = (message: string) => {
        setSnackbarMessage({ text: message, variant: WMSnackbarVariant.Success, isOpen: true });
        setSaveAndPublishData(false);
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };

    const openSaveAndPublishDialog = () => {
        setSaveAndPublishData(true);
        dispatch(systemsSlice.actions.setSaveAndPublishDialogIsOpen(true));
    };

    const onSaveAndPublishClicked = async () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(openSaveAndPublishDialog));
        } else {
            openSaveAndPublishDialog();
        }
    };

    const initialLoading = async () => {
        setLoading(true);
        await loadConfiguration();
        setLoading(false);
    };

    useEffect(() => {
        initialLoading();
    }, []);

    const getPublishSaveSection = (): JSX.Element => {
        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>
                            {t(`systems-tab.new-system-form.configure-step.multi-language.language-identification.main-title`)}
                        </div>
                        <div className='sub-title'>
                            {t(
                                `systems-tab.new-system-form.configure-step.multi-language.language-identification.selector.${selectedDetector.value}.label`
                            )}
                        </div>
                    </div>
                </div>
            </StyledSaveSection>
        );
    };

    return loading ? (
        loaderComponent
    ) : (
        <MainContainer>
            <HeaderContainer>
                <HeaderMainText>
                    {t(`systems-tab.new-system-form.configure-step.multi-language.language-identification.main-title`)}
                    <div className='sub-title'>
                        <div>{t(`systems-tab.new-system-form.configure-step.multi-language.language-identification.sub-title`)}</div>
                    </div>
                </HeaderMainText>
            </HeaderContainer>
            {isDetectorSupported() ? (
                <>
                    <ContentContainer>
                        <LanguageDetectorSelector
                            title={t(`systems-tab.new-system-form.configure-step.multi-language.language-identification.selector-title`)}
                            translationPrefix='systems-tab.new-system-form.configure-step.multi-language.language-identification.selector'
                            selectedDetector={selectedDetector}
                            detectorOptions={DetectorOptions}
                            setSelectedDetector={updateSelectedMethod}
                        />
                        <StyledBodyContainer>
                            {hasVariable.includes(selectedDetector.value) && (
                                <>
                                    <StyledInputLabel>
                                        {t(
                                            `systems-tab.new-system-form.configure-step.multi-language.language-identification.selector.${selectedDetector.value}.variable`
                                        )}
                                    </StyledInputLabel>
                                    <StyledVariableInputContainer>
                                        <StyledInput
                                            placeholder={inputPlaceholder}
                                            height={InputHeight.large}
                                            width={300}
                                            error={
                                                variableValue === '' ? t(`systems-tab.new-system-form.configure-step.errors.mandatory`) : ''
                                            }
                                            value={variableValue}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVariableValue(e.target.value)}
                                        />
                                    </StyledVariableInputContainer>
                                </>
                            )}
                            {selectedDetector.value != Lang_Detector.OFF && (
                                <>
                                    <StyledInputLabel>
                                        {t(
                                            `systems-tab.new-system-form.configure-step.multi-language.language-identification.default-language`
                                        )}
                                    </StyledInputLabel>
                                    <StyledVariableInputContainer>
                                        <StyledInput
                                            height={InputHeight.large}
                                            placeholder={inputPlaceholder}
                                            width={300}
                                            error={
                                                defaultLanguage === ''
                                                    ? t(`systems-tab.new-system-form.configure-step.errors.mandatory`)
                                                    : ''
                                            }
                                            value={defaultLanguage}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDefaultLanguage(e.target.value)}
                                        />
                                    </StyledVariableInputContainer>
                                </>
                            )}
                        </StyledBodyContainer>
                    </ContentContainer>
                    <StyledSaveAndPublish>
                        <Button size='large' disabled={isSaveDisabled()} loading={savingData} onClick={onSaveClicked}>
                            {t('buttons.save')}
                        </Button>
                        <Button
                            size='large'
                            disabled={isSaveDisabled()}
                            loading={saveAndPublishData}
                            onClick={onSaveAndPublishClicked}
                            className='save-and-publish'>
                            {t('buttons.save-and-publish')}
                        </Button>
                    </StyledSaveAndPublish>
                    <SaveAndPublishDialog
                        settingsType='language-identification'
                        systemId={systemId}
                        saveSection={getPublishSaveSection}
                        saveData={async () => saveLanguageDetectionSettings(impersonationPassword)}
                        reloadData={loadConfiguration}
                        onFailure={onPublishFailed}
                        onSuccess={onPublishSucceeded}
                    />
                </>
            ) : (
                <ContentContainer>
                    {getUnsupportedConfigToaster(
                        t(`systems-tab.new-system-form.configure-step.multi-language.language-identification.unsupported-config`)
                    )}
                </ContentContainer>
            )}
            <ImpersonatorDialog isLoading={savingData} />
            <WMSnackbar
                open={snackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={snackbarMessage.variant}
                message={snackbarMessage.text}
            />
        </MainContainer>
    );
};
