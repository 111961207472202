import styled from 'styled-components';
import { Dialog, DialogContent } from '@walkme/ui-core';
import { WMTextField } from '@walkme/wm-ui';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 28px;
`;

export const Email = styled.div`
    margin-right: 10px;
    font-family: 'ProximaNova';
`;

export const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        height: 330px !important;
    }

    .MuiDialogContent-root {
        overflow-y: hidden !important;
    }
`;

export const StyledWMTextField = styled(WMTextField)`
    .WMTextField-multiline {
        height: 115px !important;
        .WMTextField-input {
            resize: none;
            height: 100px !important;
        }
    }
`;
