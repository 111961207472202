import { AppData } from '@walkme-admin-center/libs/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviders, getSystems, SystemsState } from '../redux/systems.slice';
import { Providers } from '@walkme-admin-center/libs/state-management-sso-configuration';

export const useGetProviders = (isImpersonator: boolean) => {
    const providers: AppData<Providers> = useSelector((state: { systemsState: SystemsState }) => state.systemsState.providers);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProviders(isImpersonator));
    }, [dispatch]);

    return {
        providers,
    };
};

export default useGetProviders;
