import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingUsers, UsersState } from '../redux/users.slice';

export const usePendingUsers = (forceLoad = false) => {
    const pendingUsersAppData = useSelector((state: { usersState: UsersState }) => state.usersState.pendingUsers);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPendingUsers(forceLoad));
    }, [dispatch]);

    return {
        pendingUsersAppData,
    };
};

export default usePendingUsers;
