import { Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions, Checkbox } from '@walkme/ui-core';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
    .MuiDialog-container {
        background: #2f426c9c;
    }
    .MuiDialog-paper {
        box-shadow: 0px 36px 52px 0px #2f3d531c;
    }
`;

const StyledCheckBox = styled(Checkbox)`
    flex-grow: 1;
`;

const StyledCancelButton = styled(Button)`
    color: var(--typography-typography-tint-1, #637191);
    text-align: center;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

interface ConfirmationDialogProps {
    isOpen: boolean;
    title: ReactNode;
    message: ReactNode;
    confirmButtonColor: 'danger' | 'primary' | 'white';
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: (doNotShowAgain?: boolean) => void;
    onCancel?: () => void;
    showDoNotShowAgainCheckbox?: boolean;
}

const ConfirmationDialog = ({
    isOpen,
    title,
    message,
    confirmButtonText,
    confirmButtonColor,
    cancelButtonText,
    onConfirm,
    onCancel,
    showDoNotShowAgainCheckbox,
}: ConfirmationDialogProps) => {
    const [doNotShowAgainValue, setDoNotShowAgainValue] = useState<boolean>(false);

    const onConfirmInner = (event: any) => {
        onConfirm(doNotShowAgainValue);
    };

    const doNotShowAgainCheckbox = (
        <StyledCheckBox
            label="Don't show again"
            checked={doNotShowAgainValue}
            onChange={() => setDoNotShowAgainValue(!doNotShowAgainValue)}
        />
    );

    const cancelButton = (
        <StyledCancelButton onClick={onCancel} variant='ghost'>
            {cancelButtonText ?? 'Cancel'}
        </StyledCancelButton>
    );

    const confirmButton = (
        <Button onClick={onConfirmInner} variant='solid' color={confirmButtonColor}>
            {confirmButtonText ?? 'Confirm'}
        </Button>
    );

    return (
        <StyledDialog isOpen={isOpen} onClose={onCancel} preventClose={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {showDoNotShowAgainCheckbox ? doNotShowAgainCheckbox : null}
                {onCancel ? cancelButton : null}
                {confirmButton}
            </DialogActions>
        </StyledDialog>
    );
};

export default ConfirmationDialog;
