import styled from 'styled-components';
import { Chip } from '@material-ui/core';

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    min-width: 300px;
    max-width: 550px;
    flex-grow: 1;
`;

export const ProviderInfo = styled.div`
    visibility: hidden;
`;

export const TooltipProviderAttribute = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
`;

export const StyledProviderHeader = styled.div`
    font-size: 16px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 350px;

    .providerTitle {
        font-weight: 600;
        text-overflow: 'ellipsis';
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 1;
    }

    .systemsCount {
        padding: 0 5px;
        font-size: 12px;
        color: #8d97ae;
        white-space: nowrap;
    }
`;

export const StyledProviderProtocolHeader = styled.div`
    width: 60px;
    flex-shrink: 0;

    .protocol {
        font-size: 12px;
        height: 15px;
        color: #8d97ae;
    }
`;

export const StyledDefaultIdpHeader = styled.div`
    flex-shrink: 0;
`;

export const StyledChip = styled(Chip)`
    font-size: 10px;
    height: 32px;
    border: none;
    background: #f0f4fa;

    .defaultIdpLabel {
        padding: 9px 12px;
    }
`;
