import styled from 'styled-components';

export const EnvName = styled.div`
    display: flex;
    flex-direction: row;

    &.alignItemsCenter {
        align-items: center;
    }

    & .name-container {
        padding-left: 20px;
        & .name {
            font-weight: 600;
        }
    }
`;

export const EnforceSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    & .title {
        color: #6d81a6;
    }
`;
export const EnvItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    &:not(:first-child) {
        border-top: 1px solid #e1e9f5;
    }
    &:hover:not(.env-checked) {
        box-shadow: 0px 2px 8px #dde5f5;
    }
    &.env-checked {
        background-color: #f5f7fe;
    }
`;

export const StyledEnforceSSO = styled.div`
    display: flex;
    flex-direction: row;

    &.alignItemsCenter {
        align-items: center;
    }
`;

export const StyledSystemEnvContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 500px;

    &.alignItemsCenter {
        align-items: center;
    }

    &.name-container {
        padding-left: 20px;

        .inner-name-container {
            display: flex;
            flex-direction: row;
            flex: 48%;

            &.alignItemsCenter {
                align-items: center;
            }
        }

        & .name {
            font-weight: 600;
        }
    }
`;

export const StyledEnforceSSOTitle = styled.div`
    display: flex;
    flex-direction: row;

    &.alignItemsCenter {
        align-items: center;
    }
`;
