import React from 'react';
import { Typography } from '@walkme/ui-core';
import { useCallback } from 'react';

import styles from './ai-center-onboarding.module.scss';
import { AiButton } from '../ai-button/ai-button';

const BgTextElement = ({ text, Icon }: { text: string; Icon: string }) => {
    return (
        <div className={styles.bgTextElement}>
            <div className={styles.icon}>
                <img src={Icon} alt={text} />
            </div>
            <Typography variant='T10'>{text}</Typography>
        </div>
    );
};

const AiCenterOnboarding = () => {
    const onSignUpClicked = useCallback(() => window.open('https://www.walkme.com/ai/walkme-x-demo/?q_id=620320', '_blank'), []);

    return (
        <div className={styles.contentContainer}>
            <div className={styles.bgElements}>
                <div className={styles.textContainerTop}>
                    <BgTextElement Icon='assets/icons/onboarding/ai-technology-spark-icon.svg' text='Connect to company knowledge' />
                    <BgTextElement Icon='assets/icons/onboarding/ai-file-spark-icon.svg' text='Customize access with user segments' />
                </div>
                <div className={styles.textContainerBottom}>
                    <BgTextElement Icon='assets/icons/onboarding/ai-edit-spark-icon.svg' text='Drive text-to-action' />
                    <BgTextElement Icon='assets/icons/onboarding/ai-generate-spark-icon.svg' text='Schedule regular knowledge updates' />
                </div>
                <img
                    src='assets/icons/onboarding/top-right-el.svg'
                    style={{ position: 'absolute', top: 0, right: 0 }}
                    alt='Top Right Element'
                />
                <img
                    src='assets/icons/onboarding/top-right-2-el.svg'
                    style={{ position: 'absolute', top: '36px', right: '287px' }}
                    alt='Top Right Stars Element'
                />
                <img
                    src='assets/icons/onboarding/top-left-el.svg'
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    alt='Top Left Element'
                />
                <img
                    src='assets/icons/onboarding/bottom-left-el.svg'
                    style={{ position: 'absolute', bottom: 0, left: 0 }}
                    alt='Bottom Left Element'
                />
            </div>
            <div className={styles.content}>
                <div className={styles.titleContainer}>
                    <Typography variant='H10' className={styles.title}>
                        Boost knowledge discovery with WalkMeˣ
                    </Typography>
                    <Typography variant='T10' className={styles.description}>
                        Transform the way users search for information and take action. With WalkMe’s on-demand AI assistance, you can
                        connect company knowledge to a conversational search experience that follows users everywhere they work.
                    </Typography>
                    <AiButton onClick={onSignUpClicked} className={styles.action}>
                        Explore on-demand AI
                    </AiButton>
                </div>
                <div className={styles.videoContainer}>
                    <img
                        src='assets/icons/onboarding/ai-center-onboarding.gif'
                        alt='Onboarding Video'
                        style={{ height: '50vh', borderRadius: '10px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AiCenterOnboarding;
