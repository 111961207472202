import React from 'react';
import styled from 'styled-components';
import { SelectedHost } from '../shared/selected-host';
import hosts from '../../hosts';

const StyledContainer  = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`

interface ViewStatusProps {
  cloudStorage: string;

  editDisabled: boolean;
}

export const ViewStatus = (props: ViewStatusProps) => {
  const host = hosts[props.cloudStorage] || {};

  return (
    <StyledContainer>
      <StyledTitle className="view-status-title">
        WalkMe Cloud on {host.displayName}
      </StyledTitle>
      <SelectedHost editDisabled={props.editDisabled}/>
    </StyledContainer>
  )
}
