import { createSlice } from '@reduxjs/toolkit';
import { Order } from '@walkme-admin-center/libs/types';

export type TableState = {
    rowsPerPage: number;
    currentPage: number;
    currentOrder: Order;
    currentOrderBy: string;
    selectedItemId: number;
};

export const initialTableState: TableState = {
    rowsPerPage: 10,
    currentPage: 0,
    currentOrder: 'asc',
    currentOrderBy: 'id',
    selectedItemId: null,
};

const tableSlice = createSlice({
    name: 'tableSlice',
    initialState: initialTableState,
    reducers: {
        rowsPerPageChanged: (state, action) => {
            state.rowsPerPage = action.payload;
            return state;
        },
        pageChanged: (state, action) => {
            state.currentPage = action.payload;
            return state;
        },
        orderChanged: (state, action) => {
            state.currentOrder = action.payload;
            return state;
        },
        orderByChanged: (state, action) => {
            state.currentOrderBy = action.payload;
            return state;
        },
        itemSelected: (state, action) => {
            state.selectedItemId = action.payload;
            return state;
        },
    },
});

export { tableSlice };
