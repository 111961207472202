import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { exposureApi, Extension } from '@walkme-admin-center/libs/exposure-api';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
export interface ExtensionState {
    extension: AppData<Extension | undefined>;
}

export const initialExtensionState: ExtensionState = {
    extension: {
        error: null,
        loading: false,
        data: null,
    },
};

const extensionsSlice = createSlice({
    name: 'extensionsSlice',
    initialState: initialExtensionState,
    reducers: {
        getUserExtensionStart(state: ExtensionState) {
            state.extension.loading = true;
            state.extension.error = null;
            return state;
        },
        getUserExtensionSuccess(state: ExtensionState, action: PayloadAction<Extension | undefined>) {
            state.extension.data = action.payload;
            state.extension.loading = false;
            state.extension.error = null;
            return state;
        },
        getUserExtensionFailed(state, action: PayloadAction<string>) {
            state.extension.error = action.payload;
            state.extension.loading = false;
            return state;
        },
    },
});

export { extensionsSlice };
const { getUserExtensionStart, getUserExtensionSuccess, getUserExtensionFailed } = extensionsSlice.actions;
export type ExtensionsStateType = ReturnType<typeof extensionsSlice.reducer>;
type AppThunk = ThunkAction<void, ExtensionsStateType, unknown, Action<string>>;

export const getUserExtension = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getUserExtensionStart());
        let extension: Extension | undefined = await exposureApi.getUserExtension();
        dispatch(getUserExtensionSuccess(extension));
    } catch (err) {
        dispatch(getUserExtensionFailed(err.message));
        return;
    }
};
