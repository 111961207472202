import React, { useEffect, useRef, useState } from 'react';
import { WMTooltip } from '@walkme/wm-ui';
import styled from 'styled-components';

interface LabelWithTooltipProps {
    text: string;
}

const Container = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LabelWithTooltip = ({ text }: LabelWithTooltipProps) => {
    const labelRef = useRef(null);
    const containerRef = useRef(null);
    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);

    useEffect(() => {
        const labelWidth = labelRef.current?.offsetWidth;
        const containerWidth = containerRef.current?.offsetWidth;
        if (labelWidth > containerWidth) {
            setIsTooltipDisplayed(true);
        }
    }, [text]);

    return (
        <Container ref={containerRef}>
            <WMTooltip title={text} disableHoverListener={!isTooltipDisplayed}>
                <label ref={labelRef}>{text}</label>
            </WMTooltip>
        </Container>
    );
};
