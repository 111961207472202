import React, { MouseEvent, useState } from 'react';
import { CreateIntegrationsContainer, HeaderContainer, PageTitle } from './data-integrations-header-style';
import AddIcon from '@material-ui/icons/Add';
import { TEXT } from '../common/consts';
import {
    FormMode,
    FormTypes,
    IAskMe,
    IDataIntegrationAppSettings,
} from '../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { Box, IconButton, MenuItem, withStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { setGlobalSettings, useGlobalSettings, useSettings } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { WMButton, WMIconChevronDown, WMMenu, WMSwitch } from '@walkme/wm-ui';
import { getToolTip } from '../common/services';
import useLocalStorage from './use-local-storage';
import { useDispatch } from 'react-redux';

export interface IDataIntegrationsHeader {
    triggerForm: (formMode: FormMode, instanceData: IAskMe | IDataIntegrationAppSettings, formType: FormTypes) => void;
}

export const CssIconButton = withStyles({
    root: {
        padding: 0,
        '&:hover': {
            background: 'none',
        },
    },
})(IconButton);

const AddSourceMenu = withStyles({
    paper: {
        borderRadius: '.75rem',
    },
})(WMMenu);

export const AddContentSourceMenu = ({ triggerForm }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { isAdminMode } = useGlobalSettings();

    const urlDisabledTooltip =
        "This option isn't available right now and is being performed by the WalkMe team. Please reach out to your CSM for more details";

    return (
        <div>
            <WMButton onClick={handleOpen} style={{ padding: '0.5rem' }}>
                <Box display='flex' alignItems='center' style={{ gap: '.5em' }}>
                    <Box />
                    <AddIcon />
                    <Box>Add Knowledge Source</Box>
                    <WMIconChevronDown size={22} />
                </Box>
            </WMButton>
            <AddSourceMenu
                ds2
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    style: {
                        width: anchorEl?.clientWidth,
                    },
                }}>
                {/* <Box width='17.7rem'></Box> */}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        triggerForm(FormMode.STEPPER, null, FormTypes.FILE_SCAN);
                    }}>
                    Upload Files
                </MenuItem>
                {!!isAdminMode ? (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            triggerForm(FormMode.STEPPER, null, FormTypes.CRAWLER);
                        }}>
                        Connect Webpages
                    </MenuItem>
                ) : (
                    <MenuItem disabled={true}>
                        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                            <span>Connect Webpages</span>
                            <span style={{ pointerEvents: 'all', lineHeight: 0.1 }}>{getToolTip(urlDisabledTooltip)}</span>
                        </Box>
                    </MenuItem>
                )}
            </AddSourceMenu>
        </div>
    );
};

const AdminModeSwitch = () => {
    const { isAdminMode } = useGlobalSettings();
    const [showAdminMode] = useLocalStorage('aia-is-admin');
    const dispatch = useDispatch();

    return (
        !!showAdminMode && (
            <WMSwitch
                checked={isAdminMode}
                onChange={() => dispatch(setGlobalSettings({ isAdminMode: !isAdminMode }))}
                label='Admin Mode'
            />
        )
    );
};

export const DataIntegrationsHeader = ({ triggerForm }: IDataIntegrationsHeader) => {
    const { settingsData } = useSettings();
    const { isAdminMode } = useGlobalSettings();

    return (
        <HeaderContainer>
            <div className={'main-header'}>
                <Box display='flex' flexDirection='column'>
                    <PageTitle className={'page-title'}>{TEXT.PAGE_TITLE}</PageTitle>
                    <span className={'page-title-sub-text'}>{TEXT.PAGE_DESCRIPTION}</span>
                </Box>
                <CreateIntegrationsContainer>
                    <AddContentSourceMenu triggerForm={triggerForm} />
                    {!!isAdminMode && (
                        <CssIconButton
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            onClick={() => triggerForm(FormMode.TABS, settingsData.data, FormTypes.SETTINGS)}>
                            <SettingsIcon fontSize={'medium'} />
                        </CssIconButton>
                    )}
                    <AdminModeSwitch />
                </CreateIntegrationsContainer>
            </div>
        </HeaderContainer>
    );
};

export default DataIntegrationsHeader;
