import React, { useState } from 'react';
import CustomMultipleSelect from '../common/components/custome-multiple-select';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { CssTextField } from '../data-integration-form/data-integration-form-style';

export interface IDataIntegrationsListFilterProps {
    search: string;
    searchChange: (any) => void;
    setView: (any) => void;
}

const systemOptions = [
    {
        id: 1,
        displayName: 'test1',
        value: 'test1',
    },
    {
        id: 2,
        displayName: 'test2',
        value: 'test2',
    },
    {
        id: 3,
        displayName: 'test3',
        value: 'test3',
    },
];

const statusOptions = [
    {
        id: 1,
        displayName: 'Published',
        value: 'p',
    },
    {
        id: 2,
        displayName: 'Published & Modified',
        value: 'm',
    },
    {
        id: 3,
        displayName: 'Darft',
        value: 'd',
    },
];

export const DataIntegrationsListFilter = ({ search, searchChange, setView }: IDataIntegrationsListFilterProps) => {
    const [systemFilter, setSystemFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);

    const handleSystemApply = (systems) => {
        setSystemFilter(systems);
    };
    const handleStatusApply = (statuses) => {
        setStatusFilter(statuses);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/*<CustomMultipleSelect*/}
                {/*    value={systemFilter}*/}
                {/*    options={systemOptions}*/}
                {/*    placeholder={'All systems'}*/}
                {/*    title={'Systems'}*/}
                {/*    handleApply={handleSystemApply}*/}
                {/*/>*/}
                {/*<CustomMultipleSelect*/}
                {/*    value={statusFilter}*/}
                {/*    options={statusOptions}*/}
                {/*    placeholder={'All statuses'}*/}
                {/*    title={'Status'}*/}
                {/*    handleApply={handleStatusApply}*/}
                {/*/>*/}
            </div>
            <div>
                <CssTextField
                    className={'search main-page'}
                    variant='outlined'
                    value={search}
                    onChange={searchChange}
                    placeholder={'Search..'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment style={{ width: '8px' }} position='start'>
                                <img src={'assets/icons/data-integrations/search-icon-medium.svg'} />
                            </InputAdornment>
                        ),
                    }}
                />
                {/* <button onClick={() => setView('cards')}>Cards</button>
                <button onClick={() => setView('table')}>Table</button> */}
            </div>
        </div>
    );
};

export default DataIntegrationsListFilter;
