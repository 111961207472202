import { DesktopAppStatuses } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import React from 'react';
import styled from 'styled-components';

export const StyledPublishdStatus = styled.span`
    color: #44BC67;
`;

export const StyledDeactivatedStatus = styled.span`
    color: #8A969D;
`;

export const StyledPendingPublishStatus = styled.span`
    color: #3B9DD7;
`;

export const StyledBullet = styled.span`
    font-size: 15px;
`;

interface DesktopAppStatusTableDisplayProps {
    status: string;
};

const getPublishStatusForDisplay = (status) => {
    return (
        <>
            &#9679;{` ${status}`}
        </>
    );
}

const DesktopAppStatusTableDisplay = ({ status }: DesktopAppStatusTableDisplayProps) => {
    let component;

    switch(status) {
        case DesktopAppStatuses.DEACTIVATED: {
            component = <StyledDeactivatedStatus>{getPublishStatusForDisplay(status)}</StyledDeactivatedStatus>;

            break;
        }
        case DesktopAppStatuses.ACTIVATED: {
            component = <StyledPublishdStatus>{getPublishStatusForDisplay(status)}</StyledPublishdStatus>;

            break;
        }
        default: {
            component = <StyledDeactivatedStatus>{getPublishStatusForDisplay(status)}</StyledDeactivatedStatus>;
        }
    }

    return (
        <>
            {component}
        </>
    );
};

export default DesktopAppStatusTableDisplay;