import styled from 'styled-components';
import { WMButton, WMDataGrid, WMTagList } from '@walkme/wm-ui';
import { Badge } from '@walkme/ui-core';
import checkedIcon from './assets/icons/checked.svg';

export const StyledButton = styled(WMButton)`
    margin-left: 10px;
`;

export const MenuButton = styled.button`
    width: 32px !important;
    height: 32px !important;
`;

export const Grid = styled.div`
    height: 90%;
    width: 100%;
    --ag-selected-row-background-color: #edf1fd;
`;

export const EnvChip = styled.div`
    background-color: #e4e9fc !important;
    margin-right: 6px;
`;

export const UserRowStatus = styled.span`
    margin-left: 6px;
    font-family: proximaNova;
    color: var(--color, #2f426c);
    font-size: 12px;
`;

export const UserRowEmail = styled.span`
    font-family: proximaNova;
    color: var(--color, #2f426c);
    font-size: 14px;
`;

export const WmErrorColor = styled.div`
    color: var(--wmError);
`;

export const StyledNumberOfAddedUsersContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 0 12px 0;

    .added-users-text {
        font-size: 18px;
        font-weight: 600;
        color: var(--Color-Semantic-Text-text-primary, #2f426c);
    }

    div[class*='StyledBadge'] {
        position: unset;
    }
`;

export const StyledWmTagList = styled(WMTagList)`
    .MuiChip-label {
        padding: 4px 8px !important;
        font-size: 14px;
        font-weight: 400;
    }
    .WMTag-old.WMTag-wmTag {
        border-radius: 5px;
    }
    .WMTag-black {
        background-color: var(--wmProfoundlyLight);
        color: var(--infographic-blue-blue-shade-1, #162a69);
    }
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
    .ag-theme-wm-ui .ag-body-viewport,
    .ag-theme-wm-ui .ag-center-cols-viewport {
        cursor: pointer;
        scrollbar-color: unset !important;
        scrollbar-width: unset !important;
    }
    .WMDataGridHeader-wmDataGridHeader {
        align-items: center;
        padding: 20px 32px;
    }
    .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-last-left-pinned {
        line-height: normal !important;
    }
    .ag-root .ag-cell {
        cursor: default !important;
    }
    .ag-theme-wm-ui .ag-checkbox-input-wrapper.ag-checked {
        &:after {
            background: url(${checkedIcon});
        }
    }

    & .WMSearchFilter-wmSearchFilter.WMSearchFilter-medium {
        max-width: 350px !important;
        display: flex;
        align-items: center;
    }

    /* ActionColumn styles */
    & .WMButton-wmButton:hover {
        background-color: #3b61eb0d;
        color: #385feb;
    }
    &.WMDataGridActionColumn-open {
        & .WMButton-wmButton {
            background-color: #3b61eb0d;
            color: #2b47ac;
        }
    }
`;

export const StyledStatusDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledGridTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledBadge = styled(Badge)`
    background-color: #eff0f9;
    color: var(--LS-Color-Action-Content-On-light-Secondary-active, #385feb);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 8px;
`;
