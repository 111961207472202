import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

const StyledButton = styled(MuiButton)`
  color: #4164e3;
  border: 1px solid #cedaeb;
  border-radius: 20px;
  text-transform: none;
  letter-spacing: normal;
  line-height: inherit;
  padding: 5px 15px;
`

export const Button = StyledButton;

