import React, { ReactNode, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { StyledCancelButton, StyledWMCheckBox } from './styled-components';
import { WMButtonVariant } from '@walkme/wm-ui';
import { useStyles } from './skippable-confirmation-dialog-style';
import { ConfirmButton } from './confirm-button';

interface SkippableConfirmationDialogProps {
    className?: string;
    open: boolean;
    onClose: () => void;
    onConfirm: (shouldSkip: boolean) => void;
    title: string;
    confirmLabel: string;
    confirmIcon?: ReactNode;
    children?: ReactNode;
}

const SkippableConfirmationDialog = ({
    className,
    open,
    onClose,
    onConfirm,
    title,
    confirmLabel,
    confirmIcon,
    children,
}: SkippableConfirmationDialogProps) => {
    const classes = useStyles();
    const [shouldSkip, setShouldSkip] = useState(false);

    const handleSkipChange = (event, checked: boolean) => {
        setShouldSkip(checked);
    };

    const handleDialogClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const handleConfirmClicked = () => {
        onConfirm(shouldSkip);
    };

    return (
        <Dialog
            className={className}
            open={open}
            onClose={handleDialogClose}
            classes={{ paper: classes.dialog }}
            fullWidth={true}
            BackdropProps={{ className: 'WMDialog-backdrop' }}>
            <DialogTitle className={classes.dialogTitleContainer}>
                <Box className={classes.dialogTitleBox}>
                    <Typography className={classes.dialogTitleText}>{title}</Typography>
                    <IconButton className={classes.dialogCloseBtn} onClick={onClose}>
                        <img src='assets/icons/close.svg' alt='close' />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContentContainer}>{children}</DialogContent>
            <DialogActions className={classes.dialogButtonsContainer}>
                <StyledWMCheckBox ds2 checked={shouldSkip} onChange={handleSkipChange} label={`Don't show me this message again`} />
                <Box className={classes.rightButtonsContainer}>
                    <StyledCancelButton variant={WMButtonVariant.Text} onClick={onClose}>
                        Cancel
                    </StyledCancelButton>
                    <ConfirmButton
                        id='instructions-download-btn'
                        variant={WMButtonVariant.Primary}
                        iconComponent={confirmIcon}
                        onClick={handleConfirmClicked}>
                        {confirmLabel}
                    </ConfirmButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export { SkippableConfirmationDialog };
