import { DataIntegrationState } from '../redux/data-integration.slice';
import { useSelector } from 'react-redux';
import { IntegrationType } from '../types';

export const useTeams = () => {
    const teamsIntegrationsData = useSelector((state: { dataIntegrationsState: DataIntegrationState }) => {
        return state.dataIntegrationsState.integrations.data.find((integration) => integration.type === IntegrationType.TEAMS);
    });

    return {
        teamsIntegrationsData,
    };
};

export default useTeams;
