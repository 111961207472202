import React, { ReactNode } from 'react';
import { Toaster, ToasterVariant } from '@walkme/ui-core';
import { StyledToasterMessage } from './uuid.styles';
import { StyledToasterContainer } from './uuid-body.styles';
import { FeatureFlag } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/features-types';

export interface DropdownOption {
    label: string;
    value: string;
    id: number;
}

// the labels are now keys for the localization values
export const UUIDOptions: DropdownOption[] = [
    { label: 'none', value: 'none', id: 1 },
    { label: 'segment.com', value: 'segment.com', id: 2 },
    { label: 'idp', value: 'idp', id: 3 },
    { label: 'var', value: 'var', id: 4 },
    { label: 'jquery', value: 'jquery', id: 5 },
    { label: 'cookie', value: 'cookie', id: 6 },
    { label: 'salesforce', value: 'salesforce', id: 7 },
    { label: 'walkme', value: 'walkme', id: 8 },
];

type GetUUIDOptions = {
    systemFeatureFlags: FeatureFlag[];
    accountFeatureFlags?: FeatureFlag[];
};
export const getUUIDOptions = ({ systemFeatureFlags, accountFeatureFlags }: GetUUIDOptions): DropdownOption[] => {
    let UUIDOptionsRes = [...UUIDOptions];

    if (systemFeatureFlags && !systemFeatureFlags?.some((featureFlag) => featureFlag.flagName.toLowerCase() === 'segment.com')) {
        UUIDOptionsRes = UUIDOptionsRes.filter((option) => option.value !== 'segment.com');
    }

    return UUIDOptionsRes;
};

export enum UUID {
    IDP = 'idp',
    VARIABLE = 'var',
    JQUERY = 'jquery',
    COOKIE = 'cookie',
    SALESFORCE = 'salesforce',
    WALKME = 'walkme',
    NONE = 'none',
    SEGMENTCOM = 'segment.com',
}

export const expectedFormatOptions = [
    { value: 'None', id: 1, label: 'none' },
    { value: 'Email', id: 2, label: 'email' },
    { value: 'GUID', id: 3, label: 'guid' },
    { value: 'Integer', id: 4, label: 'integer' },
    { value: 'Custom_Regexp', id: 4, label: 'custom-regex' },
];

export const regexPatternByFormat = {
    Email: '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    GUID: '({){0,1}[0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12}(}){0,1}$|[^]?([0-9a-fA-F]){8}(-([0-9a-fA-F]){4}){3}-([0-9a-fA-F]){12}?$',
    Integer: '^[+-]?[0-9]+$',
};

export const predefinedUUIDRecommendedValues = {
    adpworkforcenow: [{ method: 'jquery', param: '#mastheadGlobalOptions' }],
    appian: [{ method: 'jquery', param: 'span#HEADER_USER_MENU_POPUP_text' }],
    confluence: [
        { method: 'var', param: 'userName' },
        { method: 'var', param: 'userEmail' },
    ],
    jira: [{ method: 'var', param: 'JIRA.Users.LoggedInUser.userName()' }],
    beqom: [
        { method: 'var', param: 'globalUserInfo.userName' },
        { method: 'var', param: 'globalUserInfo.userId' },
    ],
    bullhornstaffing: [
        { method: 'var', param: 'NOVO_SESSION.UlSession.identity.username' },
        { method: 'var', param: 'NOVO_SESSION.UlSession.identity.userId' },
    ],
    calabrio: [
        { method: 'var', param: 'UserContext.userName' },
        { method: 'var', param: 'UserContext.userId' },
    ],
    chromeriver: [
        { method: 'var', param: 'localStorage.apc_user_id' },
        { method: 'jquery', param: '.navbar-icon-container.navbar-user-button.has-text:visible' },
    ],
    clari: [{ method: 'var', param: 'Raven.j.user.email' }],
    clarizen: [
        { method: 'var', param: 'username' },
        { method: 'var', param: 'userSettings.userinformation.userId' },
    ],
    coupa: [{ method: 'jquery', param: '#my_account' }],
    docupace: [{ method: 'jquery', param: '.user-name span' }],
    greenhouse: [
        { method: 'var', param: 'DATACORAL_USER_ID' },
        { method: 'var', param: 'Solvvy.customization.api.options.userId' },
    ],
    kronos: [{ method: 'jquery', param: 'span.krn-user-info__username span' }],
    dynamics: [
        { method: 'var', param: 'USER_NAME' },
        { method: 'var', param: 'wmDynamicsId' },
    ],
    office: [
        { method: 'jquery', param: '#user-displayname' },
        { method: 'var', param: 'msal.account.userName' },
    ],
    microsoftpowerplatform: [{ method: 'var', param: 'O365ShellContext.BootHeaderState.userEmail' }],
    sharepoint: [
        { method: 'var', param: '_spPageContextInfo.userEmail' },
        { method: 'var', param: '_spPageContextInfo.userId' },
    ],
    netsuite: [{ method: 'jquery', param: '#ns-header-menu-userrole .ns-role span:first' }],
    oraclepeoplesoft: [
        { method: 'var', param: 'personName' },
        { method: 'var', param: 'personId' },
    ],
    taleo: [{ method: 'jquery', param: '.fa-arpo-profile + span.userName' }],
    outreach: [{ method: 'var', param: 'wm_user_id' }],
    planisware: [{ method: 'var', param: 'applet.m_hiddenFields.USER' }],
    pmweb: [{ method: 'var', param: '#ctl00_lblFullName' }],
    priority: [{ method: 'jquery', param: '.loginName:visible' }],
    salesforceclassic: [
        { method: 'var', param: 'UserContext.userName' },
        { method: 'var', param: 'UserContext.userId' },
    ],
    salesforcemarketingcloudglobal: [{ method: 'salesforce' }],
    lightning: [{ method: 'salesforce' }, { method: 'var', param: 'UserContext.userName' }, { method: 'var', param: 'UserContext.userId' }],
    ariba: [{ method: 'var', param: 'wm_uid' }],
    concur: [{ method: 'var', param: 'headerProps.fullName' }],
    sapfieldglass: [{ method: 'jquery', param: '#LinkedUser' }],
    'saps/4hana': [{ method: 'var', param: 'sap-ushell-config.services.Container.adapter.config.id' }],
    successfactors: [
        { method: 'var', param: 'pageHeaderJsonData.userInfo.id' },
        { method: 'var', param: 'pageHeaderJsonData.userInfo.fullName' },
    ],
    servicenow: [
        { method: 'var', param: 'window.NOW.user.name' },
        { method: 'var', param: 'window.NOW.user_id' },
    ],
    veeva: [
        { method: 'var', param: 'USER.name' },
        { method: 'var', param: 'USER.id' },
    ],
    windchill: [{ method: 'var', param: 'PTC.navigation.GLOBAL_USER' }],
    workday: [{ method: 'var', param: 'walkMeWorkday.user' }],
    workfront: [
        { method: 'var', param: 'appNode.__reactContainere$hnv4c4md5u.child.pendingProps.user.username' },
        { method: 'var', param: 'options.objID' },
    ],
    yardi: [{ method: 'jquery', param: 'input#yAppUserName' }],
    zendesk: [{ method: 'var', param: 'Zendesk.currentUser.data.email' }],
};

export const SalesforceSystems = ['salesforceclassic', 'lightning'];

export const isSalesforceSystem = (type: string) => {
    return SalesforceSystems.includes(type);
};

const getTextJsx = (fullText: string, linksPrefix: string, translator) => {
    const elements = [];
    const numOfLinks = (fullText.match(/\$\$/g) || []).length;
    const splitByDollars = fullText.split('$$');
    splitByDollars.forEach((element, index) => {
        elements.push(<span>{element}</span>);
        if (index < numOfLinks) {
            const linkText = translator(`${linksPrefix}.${index}.text`);
            const linkURL = translator(`${linksPrefix}.${index}.url`);
            elements.push(
                <a href={linkURL} target='_blank'>
                    {linkText}
                </a>
            );
        }
    });
    return elements;
};

export const getUUIDToaster = (uuid: UUID, translator, systemType) => {
    let toasterTitle = translator(`systems-tab.new-system-form.configure-step.uuid.${uuid}.toaster.main-title`);
    let toasterDescription: ReactNode = '';
    if (uuid === UUID.NONE && isSalesforceSystem(systemType)) {
        toasterDescription = translator(
            `systems-tab.new-system-form.configure-step.uuid.${uuid}.toaster.description.predefined.salesforce`
        );
    } else {
        toasterDescription = getTextJsx(
            translator(`systems-tab.new-system-form.configure-step.uuid.${uuid}.toaster.description.text`),
            `systems-tab.new-system-form.configure-step.uuid.${uuid}.toaster.description.links`,
            translator
        );
    }

    const ToasterMessage = (
        <StyledToasterMessage>
            <div>
                <b>{toasterTitle}</b>
            </div>
            <div>{toasterDescription}</div>
        </StyledToasterMessage>
    );

    switch (uuid) {
        case UUID.IDP:
        case UUID.VARIABLE:
            return <StyledToasterContainer icon={true} variant={ToasterVariant.Insight} message={ToasterMessage} width='90%' />;
        case UUID.JQUERY:
        case UUID.COOKIE:
        case UUID.SALESFORCE:
        case UUID.WALKME:
        case UUID.SEGMENTCOM:
        case UUID.NONE:
            return <StyledToasterContainer icon={true} variant={ToasterVariant.Info} message={ToasterMessage} width='90%' />;
    }
};
