import { Box, CardContent, Typography, Card, Collapse } from '@material-ui/core';
import { WMButton, WMButtonVariant, WMCircularProgress, WMSnackbar, WMSwitch, WMSnackbarVariant, WMIconArrowDown } from '@walkme/wm-ui';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PermissionsErrorkey } from '@walkme-admin-center/libs/common';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface SecurityCardProps {
    handleSwitchClick: (isChecked: boolean) => void;
    name: string;
    loading: boolean;
    error?: string;
    title: string;
    subTitle: string;
    checked: boolean;
    disabled: boolean;
    marginTop?: string;
    collapsableComponant?: JSX.Element;
}

export const SecurityCard = forwardRef((securityCardProps: SecurityCardProps, ref) => {
    const { t } = useTranslation('general');
    useImperativeHandle(ref, () => ({
        expand(isExpanded: boolean) {
            setExpanded(isExpanded);
        },
    }));
    const [userChanged, setUserChanged] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(securityCardProps.error || '');

    const formatError = (error: string) => {
        const forbiddenResourceError = error.includes('Forbidden resource');
        if (forbiddenResourceError) {
            return t(PermissionsErrorkey);
        }
        return error;
    };

    const handleChange = (event) => {
        const isChecked = event.target.checked;
        setUserChanged(true);
        securityCardProps.handleSwitchClick(isChecked);
    };
    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar('');
    };

    useEffect(() => {
        if (userChanged && securityCardProps.collapsableComponant) {
            setExpanded(securityCardProps.checked);
        }
    }, [securityCardProps]);

    useEffect(() => {
        setOpenErrorSnackbar(securityCardProps.error || '');
    }, [securityCardProps.error]);

    return (
        <Card style={{ marginTop: securityCardProps.marginTop }}>
            <CardContent style={{ padding: '32px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'top'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (securityCardProps.checked && securityCardProps.collapsableComponant) {
                                setExpanded(!expanded);
                            }
                        }}>
                        <WMButton
                            style={{
                                marginRight: '5px',
                                visibility: !securityCardProps.checked || !securityCardProps.collapsableComponant ? 'hidden' : 'visible',
                            }}
                            isIconButton
                            variant={WMButtonVariant.Text}
                            iconComponent={
                                <WMIconArrowDown
                                    color={'#2F426C'}
                                    style={{
                                        transition: 'all 0.5s ease',
                                        transform: `rotate(${expanded ? '0.5turn' : 0})`,
                                    }}
                                    size={24}
                                />
                            }
                        />
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins',
                                    color: '#2F426C',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}>
                                {securityCardProps.title}
                            </Typography>
                            <Typography style={{ fontFamily: 'Poppins', color: '#2F426C', fontSize: '12px' }}>
                                {securityCardProps.subTitle}
                            </Typography>
                        </Box>
                    </Box>
                    {securityCardProps.loading ? (
                        <WMCircularProgress />
                    ) : (
                        <WMSwitch
                            id='security-item-toggle'
                            onChange={handleChange}
                            checked={securityCardProps.checked}
                            disabled={securityCardProps.disabled}
                        />
                    )}
                </div>
            </CardContent>
            {securityCardProps.collapsableComponant && (
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    {securityCardProps.collapsableComponant}
                </Collapse>
            )}
            <WMSnackbar
                open={openErrorSnackbar ? true : false}
                onClose={handleSnackBarErrorClose}
                variant={WMSnackbarVariant.Error}
                message={formatError(openErrorSnackbar)}
            />
        </Card>
    );
});

export default SecurityCard;
