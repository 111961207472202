import styled from 'styled-components';
import { WMButton, WMDataGrid } from '@walkme/wm-ui';

export const StyledButton = styled(WMButton)`
    height: 28px;
    width: 28px;
    padding: 0;
    background-color: #fff;
    & .WMButton-icon {
        margin: 0;
        & svg {
            font-size: 12px;
        }
    }
    &:hover {
        background-color: #3b61eb0d;
    }
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
    height: 60vh !important;
    width: 100%;
    --ag-selected-row-background-color: #edf1fd;

    .ag-cell-wrapper {
        height: 100%;
    }

    & .WMDataGridHeader-wmDataGridHeader {
        display: none !important;
    }

    & .ag-header {
        border-bottom-color: #e4e9fc;
    }

    & .ag-row {
        border-color: #e4e9fc;
    }

    & .ag-center-cols-container {
        width: 100% !important;
    }
`;

export const DateText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

export const TimeText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
