import { ContractsConfig } from '@walkme/accounts-contracts-ui';
import { Contract } from '@walkme-admin-center/libs/state-management-systems';

export const getContractSystemsTableConfig = (contract: Contract): ContractsConfig => ({
    accordion: { expanded: contract?.relatedSystems?.length > 0 ? true : undefined, disableToggle: true },
    columns: {
        summaryColumns: {
            hideColumns: ['End-Users Usage', 'Mapped Apps', 'Actions'],
        },
        detailColumns: {
            hideColumns: ['End-user Usage', 'User Unique Identifier', 'System Status', 'Contract Systems Overuse', 'Actions'],
        },
    },
});
