import styled from 'styled-components';
import { StyledPageTitle } from '@walkme-admin-center/libs/shared-styles';
import { StyledPageTitleLabel } from '../../../users-roles/src/lib/users.styles';
import { Tabs } from '@walkme/ui-core';
import { Box } from '@material-ui/core';
import { WMButton } from '@walkme/wm-ui';

export const StyledRootDiv = styled.div`
    height: 100%;
`;

export const StyledContainerDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledTitleDiv = styled.div`
    padding: 32px 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledTitleText = styled(StyledPageTitle)`
    margin-bottom: 0;
    padding-bottom: 3px;
    font-size: 18px;
`;

export const StyleSubtitleText = styled(StyledPageTitleLabel)`
    font-size: 12px;
    color: #8d97ae;
`;

export const StyledTabs = styled(Tabs)`
    & .MuiTab-root {
        padding: 0 0 16px 0;
        min-width: 100px;
    }

    & .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;

        & span {
            background: #385feb;
            height: 1px;
            width: 85%;
            margin-right: 15px;
        }
    }
`;

export const StyledTab = styled.span`
    position: relative;

    img {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 5px;
        right: -18px;
    }
`;

export const StyledSearchAndAddBox = styled(Box)`
    position: absolute;
    left: 300px;
    top: -7px;
    display: flex;
    gap: 12px;
    align-items: center;
    z-index: 1;
    margin: 0 12px 16px 0;

    & div:has(div[role='searchbox']) {
        width: 280px;
    }

    & .WMDivider-wmDivider {
        height: 24px;
        background-color: #eceef2;
    }
`;

export const StyledButton = styled(WMButton)`
    height: 32px;
    width: 32px;
    padding: 0;
    & .WMButton-icon {
        margin: 0;
        & svg {
            font-size: 12px;
        }
    }
`;

export const StyledTabsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;

    & .MuiTabs-scroller.MuiTabs-fixed {
        margin: 0 20px;
    }
`;
