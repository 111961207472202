import { Tooltip } from '@material-ui/core';
import React, { cloneElement, useState } from 'react';
import styled from 'styled-components';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)`
    & .MuiTooltip-tooltip {
        font-size: 12px;
        background: #2f3d53;
        padding: 8px 12px;
    }

    & .MuiTooltip-arrow {
        color: #2f3d53;
    }
`;

export interface CustomToolTipProps {
    title: string | JSX.Element;
    disableHoverListener?: boolean;
    children: any;
    placement?: 'top' | 'bottom' | 'top-start' | 'right' | 'right-start' | 'top-end' | 'left';
    arrow?: boolean;
    open?: boolean;
}

export const CustomToolTip = ({
    title,
    disableHoverListener = false,
    children,
    placement = 'top',
    arrow = false,
    open = false,
}: CustomToolTipProps) => {
    return (
        <StyledTooltip
            title={title}
            disableHoverListener={disableHoverListener}
            children={children}
            placement={placement}
            arrow={arrow}
            open={open}
        />
    );
};

export const CustomToolTipHover = ({ children, ...props }: CustomToolTipProps) => {
    const [open, setOpen] = useState(false);

    const handleMouseEnter = () => {
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    const handleClick = (event: MouseEvent) => {
        handleMouseLeave();
        if (children?.props?.onClick) {
            children.props.onClick(event);
        }
    };

    return (
        <CustomToolTip
            {...props}
            open={open}
            children={cloneElement(children, {
                onMouseEnter: handleMouseEnter,
                onMouseLeave: handleMouseLeave,
                onClick: handleClick,
            })}
        />
    );
};

export default CustomToolTip;
