import { makeStyles } from '@material-ui/core';
import { styledScrollCss } from '../common/styledScroll';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '105vh',
        maxWidth: '105vh',
        borderRadius: 15,
        height: '100%',
    },
    dialogContentContainer: {
        margin: '0 32px 32px 32px',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    generalSystemDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    systemDetails: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    systemNames: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogTitleText: {
        fontFamily: 'Poppins',
        color: '#2F426C',
        fontSize: '18px',
        fontWeight: 600,
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mainTitleDivider: {
        marginTop: '16px',
    },
    genericSystemName: {
        fontFamily: 'ProximaNova',
        color: '#8D97AE',
        fontSize: '14px',
        fontWeight: 400,
    },
    customSystemName: {
        ontFamily: 'Poppins',
        color: '#2F426C',
        fontSize: '18px',
        fontWeight: 600,
    },
    controlTitle: {
        fontFamily: 'ProximaNova',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    settingsContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    loadingOptionsRootContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    loadingOptionsContainer: {
        gap: '12px',
        display: 'flex',
        paddingTop: '24px',
        paddingBottom: '24px',
    },
    loadingOptionContainer: {
        flex: '1 1 0px',
    },
    environmentsContainer: {
        display: 'flex',
        height: '100%',
        paddingTop: '24px',
        overflow: 'hidden',
    },
    verticalDivider: {
        marginLeft: '32px',
        marginRight: '32px',
    },
    settingsDivider: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    envSettingsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        ...styledScrollCss,
    },
    radioOption: {
        marginTop: '10px',
    },
    radioOptionLabel: {
        fontFamily: 'ProximaNova',
        color: '#2F426C',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        marginLeft: '8px',
    },
    radioGroup: {
        marginLeft: '14px',
    },
    saveButtonContainer: {
        marginRight: '24px',
        marginBottom: '24px',
    },
    saveButton: {
        borderRadius: '20px !important',
        height: '40px !important',
        marginLeft: '12px',
        width: '90px',
        fontFamily: 'ProximaNova',
        boxShadow: 'none',
    },
    urls: {
        maxWidth: '40vh',
        marginLeft: '20px',
        marginTop: '10px',
    },
    configurationErrorContainer: {
        height: 'calc(80vh - 215px)',
    },
    disableWalkmeConfirmation: {
        fontFamily: 'ProximaNova',
        color: '#2F426C',
        fontSize: '16px',
        fontWeight: 400,
    },
    multipleConfigImage: {
        width: '70%',
        height: '70%',
    },
    extensionDisabled: {
        cursor: 'not-allowed',
    },
    loader: {
        display: 'flex',
        minHeight: '80vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    systemDetailsIcon: {
        width: '50px',
        height: '50px',
        marginRight: '20px',
    },
}));

export { useStyles };
