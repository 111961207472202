import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WMAccountIdp, WMIdpType, WMSAMLIdp } from 'wm-accounts-sdk';
import { AccountIdpsState, getAccountIdps } from '../redux/account-idps.slice';

export const DefaultIdpUrl = 'https://example.com';
export const DefaultIdpIssuer = 'example';

export const isIdpConfigured = (idp: WMAccountIdp): boolean => {
    if (idp.idpType !== WMIdpType.saml) return true;
    const samlIdp = idp as WMSAMLIdp;
    return samlIdp.idpCertificate && samlIdp.acsUrl !== DefaultIdpUrl && samlIdp.issuer !== DefaultIdpIssuer;
};

export const useAccountIdps = (forceLoad = true) => {
    const accountIdpsAppData = useSelector((state: { accountIdpsState: AccountIdpsState }) => state.accountIdpsState.accountIdps);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAccountIdps(forceLoad));
    }, [dispatch]);

    return {
        accountIdpsAppData,
    };
};

export default useAccountIdps;
