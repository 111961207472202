import { AppData } from '@walkme-admin-center/libs/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetSystemDataResult, GetSystemResult } from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';
import { CreateSystemState, getExtensionData } from '@walkme-admin-center/libs/state-management-systems';

export const useSystemExtensionData = (systemUserId: number, forceLoad = false) => {
    const systemExtensionData: AppData<GetSystemDataResult> = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.systemExtensionData
    );

    const dispatch = useDispatch();
    useEffect(() => {
        if (!systemExtensionData?.data) {
            dispatch(getExtensionData(systemUserId, forceLoad));
        }
    }, [dispatch]);

    return { systemExtensionData: systemExtensionData?.data };
};

export default useSystemExtensionData;
