import React, { SVGProps } from 'react';

export const BusinessDealIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
        <path d='M16.5 12.5L12.5 13.5' stroke='#6D81A6' strokeLinecap='round' strokeLinejoin='round' />
        <path
            d='M3.5 12.5H5.4987L8.5 16.5C8.86576 16.5005 13 13 13.4074 12.5C13.5 12 11 9.5 11 9.5'
            stroke='#6D81A6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M8.525 6.64974C8.2254 6.43654 7.86769 6.32022 7.5 6.31641C7.27732 6.31594 7.05663 6.35838 6.85 6.44141L3.5 7.5'
            stroke='#6D81A6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.5 8.5L9.41617 9.61672C9.09908 9.72759 8.75193 9.71548 8.44334 9.58279C8.13475 9.45009 7.88716 9.20646 7.7495 8.90005C7.60119 8.58083 7.5826 8.2165 7.69763 7.88383C7.81267 7.55116 8.05235 7.27614 8.36617 7.11672L11.1662 5.70839C11.4397 5.56923 11.7426 5.49778 12.0495 5.50005C12.2767 5.50158 12.502 5.54101 12.7162 5.61672L16.5 7.5'
            stroke='#6D81A6'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M19.5 6.5H17.5C16.9477 6.5 16.5 6.94772 16.5 7.5V12.5C16.5 13.0523 16.9477 13.5 17.5 13.5H19.5'
            stroke='#6D81A6'
            strokeLinecap='round'
        />
        <path
            d='M0.5 13.5H2.5C3.05228 13.5 3.5 13.0523 3.5 12.5L3.5 7.5C3.5 6.94772 3.05228 6.5 2.5 6.5H0.5'
            stroke='#6D81A6'
            strokeLinecap='round'
        />
    </svg>
);
