export class ManifestTemplate {
    private readonly appName: string;
    private readonly integrationGuid: string;
    constructor(appName: string, integrationGuid: string) {
        this.appName = appName;
        this.integrationGuid = integrationGuid;
    }

    getTemplate() {
        return {
            display_information: {
                name: this.appName,
                description: 'Receive app mentions and respond instantly',
                background_color: '#11359e',
            },
            features: {
                app_home: {
                    home_tab_enabled: false,
                    messages_tab_enabled: true,
                    messages_tab_read_only_enabled: false,
                },
                bot_user: {
                    display_name: this.appName,
                    always_online: true,
                },
                slash_commands: [
                    {
                        command: '/askme',
                        url: `${window.clientConfig.NX_WM_API_URL}/deepui/api/askme-slack/commands?integrationGuid=${this.integrationGuid}`,
                        description: 'Ask Whatever you want',
                        should_escape: false,
                    },
                ],
            },
            oauth_config: {
                scopes: {
                    bot: [
                        'app_mentions:read',
                        'channels:history',
                        'channels:read',
                        'chat:write',
                        'commands',
                        'im:history',
                        'incoming-webhook',
                        'mpim:history',
                        'groups:history',
                    ],
                },
            },
            settings: {
                event_subscriptions: {
                    request_url: `${window.clientConfig.NX_WM_API_URL}/deepui/api/askme-slack/events?integrationGuid=${this.integrationGuid}`,
                    bot_events: ['app_mention', 'message.im', 'message.mpim', 'member_joined_channel'],
                },
                interactivity: {
                    is_enabled: true,
                    request_url: `${window.clientConfig.NX_WM_API_URL}/deepui/api/askme-slack/feedback?integrationGuid=${this.integrationGuid}`,
                },
                org_deploy_enabled: false,
                socket_mode_enabled: false,
                token_rotation_enabled: false,
            },
        };
    }
}
