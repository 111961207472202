import React, { useEffect, useMemo, useState } from 'react';
import { System } from 'wm-accounts-sdk';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { WMButtonVariant, WMDivider, WMLoader, WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { IconChevronLeft } from '../../icons/icon-chevron-left';
import { SystemInfo } from '../common/system-info';
import {
    StyledDialog,
    StyledLoaderContainer,
    StyledSkippableConfirmationDialog,
    StyledTitleTopRow,
    StyledWMConfirmationDialog,
    StyledTitleRowNewShell,
} from './self-hosted-env-settings-style';
import { StyledCancelButton, StyledLink, StyledWMCheckBox, StyledWMSelect } from '../common/styled-components';
import { EnvSettingsForm } from './env-settings-form';
import { Form, useFormState } from 'react-final-form';
import { EnvironmentSettings } from '../../types/environment-settings';
import useSelfHostedSettings from '../../redux/hooks/use-self-hosted-settings';
import { IconInfo } from '../../icons/icon-info';
import { PreferenceKey } from '../../consts';
import { generateSelfHostedSnippet } from '../../utils/generate-self-hosted-snippet';
import { usePreference } from '../../redux/hooks/use-preference';
import { CodeBlock } from '../common/code-block';
import { snippetCodeBlockStyle } from './snippet-code-block-style';
import { useDispatch } from 'react-redux';
import { saveSelfHostedEnvSettings, selfHostedSlice } from '../../redux/self-hosted.slice';
import { useSaveSettingsStatus } from '../../redux/hooks/use-save-settings-status';
import { ProcessStatus } from '../../redux/self-hosted-state';
import { ConfirmButton } from '../common/confirm-button';
import { useLoadSettingsStatus } from '../../redux/hooks/use-load-settings-status';
import { useLoggedInUser } from '../../../../../../../libs/state-management-users/src/lib/hooks/use-loggedin-user';
import { formatTimeToDisplayText } from '../../utils/format-time-to-display-text';
import useDeploymentSelection from '../../hooks/use-deployment-selection';
import { DeploymentMethod, DeploymentMethodOption } from '../../types/deployment-method';
import { PlatformOption, PlatformOptions } from '../../types/platform-options.type';
import useEnvironmentsBySystem from '../../redux/hooks/use-environments-by-system';

interface SelfHostedEnvSettingsProps {
    open: boolean;
    onClose: () => void;
    system: System;
    envId: number;
    envName: string;
    hasMobileWeb?: boolean;
}

interface DialogProps extends SelfHostedEnvSettingsProps {
    inProgress: boolean;
    onSubmit: () => void;
    onReset: () => void;
    hasMobileWeb?: boolean;
    onPlatformChange: (newPlatform: PlatformOption) => PlatformOption;
    platformOptions: PlatformOption[];
    platform: PlatformOption;
}

const ExtensionSaveConfirmationDialog = ({ open, onCancel, onConfirm, cacheUpdateTime }) => {
    const [extensionPublishConfirm, setExtensionPublishConfirm] = useState(false);

    const handleExtensionPublishConfirm = (event, checked) => {
        setExtensionPublishConfirm(checked);
    };

    return (
        <StyledWMConfirmationDialog
            open={open}
            variant='default'
            titleIcon={<IconInfo />}
            title='Save and publish changes'
            confirmLabel='Save and Publish'
            confirmButtonProps={{ disabled: !extensionPublishConfirm }}
            onCancel={onCancel}
            onConfirm={() => onConfirm()}
            $closeIconSize='11px'>
            The changes you made to the extension URL path will be published immediately and take effect in{' '}
            {formatTimeToDisplayText(cacheUpdateTime)}.
            <StyledWMCheckBox
                ds2
                onChange={handleExtensionPublishConfirm}
                label='I understand that for WalkMe content to play, I need to deploy the WalkMe package to the new URL'
            />
        </StyledWMConfirmationDialog>
    );
};

const InnerDialog = ({
    open,
    onClose,
    system,
    envName,
    inProgress,
    onSubmit,
    onReset,
    hasMobileWeb,
    onPlatformChange,
    platformOptions,
    platform,
}: DialogProps) => {
    const { pristine, invalid, values, initialValues } = useFormState();
    const [showDiscardMessage, setShowDiscardMessage] = useState({ show: false, reason: null });
    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
    const [shouldSkipConfirmation, setShouldSkipConfirmation] = usePreference(PreferenceKey.SKIP_SNIPPET_CHANGE_CONFIRM);
    const { isInProgress: isLoadInProgress } = useLoadSettingsStatus();
    const { currentMethod, setCurrentMethod, availableMethods } = useDeploymentSelection(initialValues as EnvironmentSettings);
    const [tmpDeploymentMethod, setTmpDeploymentMethod] = useState(null);

    const handleClose = (event, reason?: string) => {
        if (inProgress || reason === 'backdropClick') return;

        if (!pristine) {
            setShowDiscardMessage({
                show: true,
                reason: 'close',
            });
        } else {
            closeAndResetForm();
        }
    };

    const handleDiscardCanceled = () => {
        setShowDiscardMessage({
            show: false,
            reason: null,
        });
    };

    const handleSubmit = () => {
        const shouldShowExtSaveConfirmation =
            currentMethod.value === DeploymentMethod.EXTENSION && values.deployment.url !== initialValues.deployment.url;
        const shouldShowSnippetChangeConfirmation =
            currentMethod.value === DeploymentMethod.SNIPPET &&
            !shouldSkipConfirmation &&
            values.package.deploymentUrl !== initialValues.package.deploymentUrl;

        if (shouldShowExtSaveConfirmation || shouldShowSnippetChangeConfirmation) {
            setShowSaveConfirmation(true);
        } else {
            onSubmit();
        }
    };

    const handleSaveCanceled = () => {
        setShowSaveConfirmation(false);
    };

    const handleSaveConfirmed = (shouldSkipSnippetConfirmation?: boolean) => {
        if (shouldSkipSnippetConfirmation !== undefined) {
            setShouldSkipConfirmation(shouldSkipSnippetConfirmation);
        }

        setShowSaveConfirmation(false);
        onSubmit();
    };

    const handleDeploymentMethodChanged = (newMethodOption: DeploymentMethodOption) => {
        if (newMethodOption.value === currentMethod.value) {
            return;
        }

        if (!pristine) {
            setTmpDeploymentMethod(newMethodOption);
            setShowDiscardMessage({
                show: true,
                reason: 'deploymentMethod',
            });
        } else {
            changeDeploymentMethod(newMethodOption);
        }
    };

    const handleDiscardConfirmation = () => {
        if (showDiscardMessage.reason === 'close') {
            closeAndResetForm();
        } else {
            changeDeploymentMethod(tmpDeploymentMethod);
        }

        setShowDiscardMessage({
            show: false,
            reason: null,
        });
    };

    const closeAndResetForm = () => {
        onClose();
        onReset();
    };

    const changeDeploymentMethod = (newMethodOption: DeploymentMethodOption) => {
        onReset();
        setCurrentMethod(newMethodOption);
    };

    return (
        <StyledDialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                <StyledTitleRowNewShell>
                    <Typography className='dialogTitle-titleTxt'>{envName} self hosting settings</Typography>
                    <IconButton className='dialogTitle-closeBtn' onClick={handleClose} disabled={inProgress}>
                        <img src='assets/icons/close.svg' alt='close' />
                    </IconButton>
                </StyledTitleRowNewShell>
            </DialogTitle>
            <DialogContent>
                {hasMobileWeb && (
                    <>
                        <Typography>Choose Platform</Typography>
                        <StyledWMSelect value={platform} options={platformOptions} onChange={onPlatformChange} />
                    </>
                )}
                <EnvSettingsForm
                    formId='envSettings'
                    deploymentMethod={currentMethod}
                    allMethods={availableMethods}
                    onDeploymentMethodChange={handleDeploymentMethodChanged}
                    onSubmit={handleSubmit}
                    snippetUserGuid={system.guid}
                />
            </DialogContent>
            <DialogActions>
                <StyledCancelButton variant={WMButtonVariant.Text} onClick={handleClose} disabled={inProgress}>
                    Cancel
                </StyledCancelButton>
                <ConfirmButton
                    id='settings-save-btn'
                    variant={WMButtonVariant.Primary}
                    type='submit'
                    form='envSettings'
                    disabled={pristine || invalid || inProgress}>
                    Save changes
                </ConfirmButton>
            </DialogActions>
            <StyledWMConfirmationDialog
                open={showDiscardMessage.show}
                variant='danger'
                title='Unsaved changes'
                confirmLabel='Discard Changes'
                onCancel={handleDiscardCanceled}
                onConfirm={handleDiscardConfirmation}>
                Are you sure you want to {showDiscardMessage.reason === 'close' ? 'close' : 'switch method'} without saving? Once you click
                discard all changes will be lost forever
            </StyledWMConfirmationDialog>
            <ExtensionSaveConfirmationDialog
                open={currentMethod.value === DeploymentMethod.EXTENSION && showSaveConfirmation}
                onCancel={handleSaveCanceled}
                onConfirm={() => handleSaveConfirmed()}
                cacheUpdateTime={initialValues?.deployment?.cacheUpdateTime}
            />
            <StyledSkippableConfirmationDialog
                open={currentMethod.value === DeploymentMethod.SNIPPET && showSaveConfirmation}
                onClose={handleSaveCanceled}
                onConfirm={handleSaveConfirmed}
                title='Save changes'
                confirmLabel='Save changes'>
                <Box>
                    <Typography>
                        Copy the snippet code below and paste it on the designated location on your website. &nbsp;
                        <StyledLink href='https://support.walkme.com/knowledge-base/self-hosting/' target='_blank'>
                            Learn more
                        </StyledLink>
                    </Typography>
                    <CodeBlock
                        text={generateSelfHostedSnippet(values?.package?.deploymentUrl, system.guid)}
                        language='htmlbars'
                        style={snippetCodeBlockStyle}
                        minifyOnCopy
                        wrap
                    />
                </Box>
            </StyledSkippableConfirmationDialog>
            {isLoadInProgress && (
                <StyledLoaderContainer>
                    <WMLoader />
                </StyledLoaderContainer>
            )}
        </StyledDialog>
    );
};

const SelfHostedEnvSettings = (props: SelfHostedEnvSettingsProps) => {
    const { envName, envId, onClose, system, open, hasMobileWeb } = props;
    const [platform, setPlatform] = useState(PlatformOptions.WEB);
    const mobileWebSystem = system.platforms.find((platform) => platform.platformName === 'MobileWeb');
    const { environments: mobileWebEnvironments } = useEnvironmentsBySystem(hasMobileWeb ? mobileWebSystem.id : null);
    const [systemInfo, setSystemInfo] = useState(!hasMobileWeb || platform === PlatformOptions.WEB ? system : mobileWebSystem);
    const [environment, setEnvironment] = useState(envId);

    const platformOptions: PlatformOption[] = [PlatformOptions.WEB, PlatformOptions.MOBILE_WEB];

    const handlePlatformChange = (newPlatform: PlatformOption) => {
        setPlatform(newPlatform);
        if (hasMobileWeb) {
            setSystemInfo(newPlatform === PlatformOptions.WEB ? system : mobileWebSystem);
            setEnvironment(
                newPlatform === PlatformOptions.WEB ? envId : mobileWebEnvironments.find((environment) => environment.Name === envName).Id
            );
        }
        return platformOptions[newPlatform.value];
    };

    const { loggedInUserAppData } = useLoggedInUser();
    const settingsParams = useMemo(
        () => ({
            accountId: loggedInUserAppData.data.account.id,
            systemId: systemInfo.id,
            systemGuid: systemInfo.guid,
            envId: environment,
        }),
        [loggedInUserAppData.data.account.id, systemInfo, environment]
    );

    const { getEnvironmentSettings } = useSelfHostedSettings(settingsParams, open);
    const { isInProgress, status } = useSaveSettingsStatus();
    const { accountFeatureEnabled } = useLoggedInUser();
    const dispatch = useDispatch();
    const [snackbarProps, setSnackbarProps] = useState<{ show: boolean; variant?: WMSnackbarVariant; message?: string }>({ show: false });
    const [settingsByEnv, setSettingsByEnv] = useState(getEnvironmentSettings(envId));

    useEffect(() => {
        setSettingsByEnv(getEnvironmentSettings(environment));
    }, [getEnvironmentSettings, settingsParams, environment]);

    useEffect(() => {
        if (status === ProcessStatus.SUCCESS) {
            setSnackbarProps({
                show: true,
                variant: WMSnackbarVariant.Success,
                message: 'Self-host settings saved',
            });
            onClose();
            dispatch(selfHostedSlice.actions.clearSaveSettingsStatus());
        } else if (status === ProcessStatus.ERROR) {
            setSnackbarProps({
                show: true,
                variant: WMSnackbarVariant.Error,
                message: 'Save failed. Please try again later or contact WalkMe Support.',
            });
            dispatch(selfHostedSlice.actions.clearSaveSettingsStatus());
        }
    }, [status]);

    const handleSettingsSave = (newEnvironmentSettings: EnvironmentSettings) => {
        dispatch(
            saveSelfHostedEnvSettings(settingsParams, {
                ...newEnvironmentSettings,
                addGuidToPath: !accountFeatureEnabled('noSystemGuidInSelfHostedPath'),
            })
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarProps({ show: false });
    };

    return (
        <>
            {open && (
                <Form onSubmit={handleSettingsSave} initialValues={settingsByEnv}>
                    {({ handleSubmit, form }) => (
                        <InnerDialog
                            {...props}
                            inProgress={isInProgress}
                            onSubmit={handleSubmit}
                            onReset={form.reset}
                            onPlatformChange={handlePlatformChange}
                            platformOptions={platformOptions}
                            platform={platform}
                        />
                    )}
                </Form>
            )}
            <WMSnackbar
                variant={snackbarProps.variant}
                open={snackbarProps.show}
                onClose={handleSnackbarClose}
                message={snackbarProps.message}
            />
        </>
    );
};

export { SelfHostedEnvSettings };
