import React, { useEffect, useState } from 'react';
import {
    StyledDialog,
    StyledContentContainer,
    StyledPrimaryActionButton,
    StyledInput,
    StyledInputContainer,
    StyledSecondaryActionButton,
    StyledAppIconContainer,
} from './create-workstation-dialog.styles';
import { Button, DialogActions, DialogContent, DialogTitle, InputHeight } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState, saveNewSystem } from '@walkme-admin-center/libs/state-management-systems';
import { AccountsSdk, SystemTypeKey } from 'wm-accounts-sdk';
import { WMSnackbar } from '@walkme/wm-ui';
import { WalkmeIcon } from '../assets/icons/walkme';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

type CreateWorkstationDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const CreateWorkstationDialog = ({ isOpen, onClose }: CreateWorkstationDialogProps) => {
    const { t } = useTranslation('general');
    const [workstationName, setWorkstationName] = useState<string>('');
    const dispatch = useDispatch();
    const workstationAppId: string = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.workstationAppId
    );
    const isCreatingSystem: boolean = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.loading
    );
    const currentSnackbarMessage = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.notificationMessage
    );

    const handleSystemNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkstationName(event.target.value);
    };

    const handleCreateWorkstation = () => {
        dispatch(
            saveNewSystem({
                displayName: workstationName,
                systemAppId: workstationAppId,
                systemTypeKey: SystemTypeKey.Workstation,
                unassignUser: false,
            })
        );
    };

    const handleSnackBarClose = () => {
        dispatch(createSystemSlice.actions.cleanUpNotificationMessage());
    };

    const fetchWorkstationAppInfo = async () => {
        try {
            const workstationAppInfo = await AccountsSdk.getInstance().getPaginatedSystemApps({
                filterIn: { app: 'workstation' },
            });
            dispatch(createSystemSlice.actions.setWorkstationAppId(workstationAppInfo?.page?.edges?.[0]?.node?.guid));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!workstationAppId) {
            fetchWorkstationAppInfo();
        }
    }, [workstationAppId]);

    return (
        <>
            <StyledDialog isOpen={isOpen} onClose={onClose}>
                <DialogTitle>{t('systems-tab.create-system')}</DialogTitle>
                <DialogContent>
                    <StyledContentContainer>
                        <div className='header'>
                            <StyledAppIconContainer>
                                <WalkmeIcon />
                            </StyledAppIconContainer>
                            <div className='title'>{t('systems-tab.systems-table.workstation')}</div>
                        </div>
                        <div>
                            <div className='inputContainer'>
                                <div className='title'>{t('systems-tab.new-system-form.provide-system-name')}</div>
                                <StyledInputContainer>
                                    <StyledInput
                                        height={InputHeight.large}
                                        value={workstationName}
                                        width='100%'
                                        onChange={handleSystemNameChange}
                                        maxLength={60}
                                    />
                                </StyledInputContainer>
                            </div>
                        </div>
                    </StyledContentContainer>
                </DialogContent>
                <DialogActions>
                    <StyledSecondaryActionButton size='large' variant='text' onClick={onClose}>
                        {t('buttons.cancel')}
                    </StyledSecondaryActionButton>
                    <StyledPrimaryActionButton
                        size='large'
                        onClick={handleCreateWorkstation}
                        loading={isCreatingSystem}
                        disabled={isCreatingSystem}>
                        {t('buttons.create-system')}
                    </StyledPrimaryActionButton>
                </DialogActions>
            </StyledDialog>
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                onClose={handleSnackBarClose}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
            />
        </>
    );
};
