import React from 'react';
import { WMSwitch } from '@walkme/wm-ui';
import { useDispatch, useSelector } from 'react-redux';
import { StyledChangeToCustomContainer } from './select-application.styles';
import { CreateSystemState, useSystemApps } from '@walkme-admin-center/libs/state-management-systems';
import { createSystemSlice } from '@walkme-admin-center/libs/state-management-systems';
import { Divider } from '@walkme/ui-core';
import { LightBulbIcon } from '../../assets/icons/light-bulb';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const ChangeToCustom = () => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const isCustomMode = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.isCustomMode.data);
    const { systemAppsAppData } = useSystemApps();

    const handleChangeCustomMode = (e) => {
        dispatch(createSystemSlice.actions.setCustomMode(e.target?.checked));
    };

    return (
        <StyledChangeToCustomContainer isEmptySearch={systemAppsAppData?.data?.length === 0}>
            <LightBulbIcon />
            <span className='changeToCustomText'>{t('systems-tab.new-system-form.change-to-custom-text')}</span>
            <WMSwitch checked={isCustomMode} onChange={handleChangeCustomMode} />
        </StyledChangeToCustomContainer>
    );
};
