import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            marginTop: '20px',
            marginBottom: '20px',
        },
        url: {
            marginBottom: '10px',
        },
        mainContainer: {
            marginTop: '20px',
            height: '100%',
            overflow: 'hidden',
        },
        urls: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            flex: 1,
        },
        mainControlContainer: {
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        },
        addEnvionmentLabel: {
            fontFamily: 'ProximaNova',
            color: '#2F426C',
            fontSize: '14px',
            fontWeight: 600,
            marginLeft: '10px',
        },
        addEnvironmentButton: {
            marginLeft: '15px',
            alignSelf: 'center',
        },
        bottomButtonsContainer: {
            position: 'fixed',
            bottom: '20px',
            right: '20px',
        },
        identificationContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        error: {
            margin: '20vh 15vh 20vh 15vh',
        },
        alert: {
            marginTop: '20px',
            whiteSpace: 'pre-line',
        },
        saveButton: {
            borderRadius: '20px !important',
            height: '40px !important',
            marginLeft: '12px',
            width: '90px',
            fontFamily: 'ProximaNova',
            boxShadow: 'none',
        },
        cancelButton: {
            alignSelf: 'center',
            borderRadius: '20px !important',
            height: '40px !important',
            marginLeft: '12px',
            width: '90px',
            fontFamily: 'ProximaNova',
            boxShadow: 'none',
            backgroundColor: 'transparent !important',
            borderColor: '#cedaeb ',
            color: '#6d81a6 !important',
            '&:hover': {
                border: '1px solid #c6c9ce',
                backgroundColor: '#f8f6f8',
            },
        },
        loader: {
            background: 'white',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '530px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        linkToExtensionButton: {
            margin: '12px',
            alignSelf: 'center',
        },
        systemNotConnectedToExtensionContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
    })
);

export { useStyles };
