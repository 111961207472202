import React, { useCallback, useState, MouseEvent } from 'react';
import { Fade } from '@material-ui/core';
import { deleteAskMe, executeAskMe, useGlobalSettings } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { useDispatch } from 'react-redux';
import { CssIconButton } from '../../select-integrations-actions/select-integrations-actions';
import { Status, IAskMe, JobType } from '../../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import IdpDialog from '../../../../../../sso-configuration/src/lib/common/components/idp-dialog/idp-dialog';
import { shrinkText } from '../../../common/services';
import styled from 'styled-components';
import { DotsIcon, EditIconBig, GuidedAnswersIconBig, RescanIconBig, ScanSummaryIcon, SettingsIcon, TrashIconBig } from '../icons/icons';
import { Button, Menu, MenuItem, MenuItemStartIcon, MenuItemText } from '@walkme/ui-core';
import { ZStack } from '../../../common/style';
import { AiFeatureFlags } from '../../../common/consts';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

const ActionsDividerContainer = styled.div`
    padding: 0 0.25rem;
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
`;

const ActionsDividerIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='2' height='20' viewBox='0 0 2 20' fill='none'>
            <path d='M1 0L0.999999 20' stroke='#EDF1FD' />
        </svg>
    );
};

const MenuItemMembers = styled.span<{ color?: string }>`
    ${(p) =>
        p.color &&
        `
        color: ${p.color} !important;
        svg: {
            path: {
                stroke: ${p.color} !important;
            }
        }
    `}
`;

export interface IIntegrationCardActions {
    editIntegration: () => void;
    editSourceSettings: () => void;
    editGuidedAnswers: () => void;
    showScanSummary: () => void;
    integration: IAskMe;
    isChatOpen: boolean;
    onMenuChange?: (open: boolean) => void;
}

export const IntegrationCardActions = ({
    editIntegration,
    editSourceSettings,
    editGuidedAnswers,
    showScanSummary,
    integration,
    isChatOpen,
    onMenuChange,
}: IIntegrationCardActions) => {
    const { accountFeatureEnabled } = useLoggedInUser();
    const { isAdminMode } = useGlobalSettings();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onMenuChange?.(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onMenuChange?.(false);
    };

    const [confirmData, setConfirmData] = useState({
        showConfirm: false,
        content: null,
        confirm: null,
        title: '',
        buttonText: '',
        type: '',
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { jobStatus } = integration || null;

    const handleDelete = () => {
        setConfirmData({
            showConfirm: true,
            buttonText: 'Delete',
            title: 'Delete',
            type: 'errorButton',
            content: `Are you sure you want to Delete '${shrinkText(integration.name, 45)}'?`,
            confirm: async (event) => {
                try {
                    await dispatch(deleteAskMe([integration.sourceId]));
                } finally {
                    setConfirmData({ ...confirmData, showConfirm: false });
                }
            },
        });
    };

    const execute = () => {
        setConfirmData({
            showConfirm: true,
            buttonText: 'Rescan',
            title: 'Rescan content?',
            type: 'defaultButton',
            content: `Are you sure you want to initiate a rescan of the source's content?`,
            confirm: async (event) => {
                try {
                    await dispatch(executeAskMe(integration.sourceId));
                } finally {
                    setConfirmData({ ...confirmData, showConfirm: false });
                }
            },
        });
    };

    const triggerConfirm = useCallback(() => {
        return (
            <IdpDialog
                open={confirmData.showConfirm}
                handleClose={() => setConfirmData({ ...confirmData, showConfirm: false })}
                confirm={confirmData.confirm}
                title={confirmData.title}
                content={confirmData.content}
                type={confirmData.type || 'defaultButton'}
                buttonText={confirmData.buttonText || 'I understand, continue'}
                cancelButtonText={'Cancel'}
            />
        );
    }, [confirmData]);

    const menuItems = [
        {
            label: 'Edit Source',
            action: editIntegration,
            icon: <EditIconBig />,
        },
        {
            label: 'Add or Edit Q&A',
            action: editGuidedAnswers,
            icon: <GuidedAnswersIconBig />,
            hide: !isAdminMode && !accountFeatureEnabled(AiFeatureFlags.ENABLE_ASK_ME),
        },
        {
            label: 'Re-Scan Source',
            action: execute,
            icon: <RescanIconBig />,
            hide: integration.type !== JobType.WEB || jobStatus.status === Status.InProgress,
        },
        {
            label: 'Last Scan Summary',
            action: showScanSummary,
            icon: <ScanSummaryIcon />,
            hide: integration.type !== JobType.WEB || jobStatus.status === Status.InProgress || jobStatus.status !== Status.Ready,
        },
        {
            label: 'Source Settings',
            action: editSourceSettings,
            icon: <SettingsIcon />,
            hide: integration.type !== JobType.WEB,
        },
        {
            label: <span style={{ color: '#E91616' }}>Delete Source</span>,
            action: handleDelete,
            icon: <TrashIconBig />,
        },
    ];

    return (
        <>
            {confirmData.showConfirm && triggerConfirm()}
            <ZStack>
                <Fade in={loading}>
                    <Button variant='text' loading disabled />
                </Fade>
                <Fade in={!loading}>
                    <CssIconButton
                        size={'small'}
                        onClick={handleOpen}
                        strokecolor='#6D81A6'
                        strokehovercolor='#6D81A6'
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        disabled={loading || isChatOpen}>
                        <DotsIcon style={{ width: '1rem', height: '1rem' }} />
                    </CssIconButton>
                </Fade>
            </ZStack>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                {menuItems.map((item, i) => {
                    return (
                        !item.hide && (
                            <MenuItem
                                key={i}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    setLoading(true);
                                    try {
                                        await item.action();
                                    } finally {
                                        setLoading(false);
                                    }
                                }}>
                                <MenuItemStartIcon>{item.icon}</MenuItemStartIcon>
                                <MenuItemText>{item.label}</MenuItemText>
                            </MenuItem>
                        )
                    );
                })}
            </Menu>
        </>
    );
};

export default IntegrationCardActions;
