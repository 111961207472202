import React from 'react';
import { EmptyStateContainer } from './users-empty-state.styles';
import { NoResultsIcon } from '../assets/icons/no-results';
import { StyledBadge, StyledNumberOfAddedUsersContainer } from '../users-tab.styles';
import { useTranslation } from '../../../../../../../../apps/home/src/localization';

export const UsersEmptyState = () => {
    const { t, rt, i18n } = useTranslation('general');

    return (
        <EmptyStateContainer>
            <StyledNumberOfAddedUsersContainer>
                <div className='added-users-text'>{t('users-and-roles-tab.users.create-user-form.added-users')}</div>
                <StyledBadge badgeContent={0} size='medium' />
            </StyledNumberOfAddedUsersContainer>
            <div className='empty-state-main-block'>
                <NoResultsIcon />
                <div className='description-block'>
                    <div className='main-description'>{t('users-and-roles-tab.users.create-user-form.empty-state.main-description')}</div>
                    <div className='sub-description'>{t('users-and-roles-tab.users.create-user-form.empty-state.sub-description')}</div>
                </div>
            </div>
        </EmptyStateContainer>
    );
};
