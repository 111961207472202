import styled from 'styled-components';
import { WMAgGridWrapper } from '@walkme/wm-ui';

export const HeaderDiv = styled('div')`
    display: flex;
    padding: 32px 24px 12px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    flex: 1 0 0;
    background: var(--white, #fff);
    box-shadow: 0px -1px 0px 0px #f5f6f8 inset;
    border-radius: 0px 12px 12px 0px;
`;

export const HeaderTitles = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 7px;
`;
export const Filterdiv = styled('div')`
    display: flex;
    height: 32px;
    padding: 12px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: var(--White, #fff);
    box-shadow: 0px -1px 0px 0px #f5f6f8 inset;
`;

export const PlusIconDiv = styled('div')`
    border-radius: 100px;
    background: var(--blue-primary-primary-main, #385feb);
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
`;

export const StyledWMAgGridWrapper = styled(WMAgGridWrapper)`
    .ag-row-selected::before {
        background: var(--white, #fff) !important;
    }
    .ag-row-selected .ag-row-hover {
        background: var(--white, #fff) !important;
        box-shadow: 0px -1px 0px 0px #f5f6f8 inset;
    }
    .ag-center-cols-viewport {
        overflow-x: hidden;
    }
`;

export const StyledUsersDiv = styled.div`
    height: calc(100% - 163px);
    padding: 20px 24px 12px 24px;
    .header-black {
        padding-left: 0px !important;
    }
`;

export const HeaderMainText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const HeaderSubText = styled('span')`
    color: var(--typography-typography-tint-2, #8d97ae);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
export const DateText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;
export const TimeText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
