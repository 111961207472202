import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Input, InputHeight } from '@walkme/ui-core';
import { StyledAcknowledge, StyledBodyParagraph, StyledPrimaryButton, StyledSecondaryButton } from './uuid/idp-selection-dialog.styles';
import { useTranslation } from '../../../../../../apps/home/src/localization';
import { useDispatch, useSelector } from 'react-redux';
import { impersonationSlice, ImpersonationState, impersonationThunks } from '@walkme-admin-center/libs/state-management-systems';

interface ImpersonatorDialogProps {
    handleClose?: () => void;
    handleConfirm?: (masterPassword: string) => void;
    isLoading?: boolean;
}

export const ImpersonatorDialog = ({ handleConfirm, handleClose, isLoading }: ImpersonatorDialogProps) => {
    const { t } = useTranslation('general');
    const [masterPassword, setMasterPassword] = useState('');
    const dispatch = useDispatch();
    const isOpen = useSelector((state: { impersonationState: ImpersonationState }) => state.impersonationState.isDialogOpen);

    const handleMasterPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMasterPassword(e.target.value);
    };

    useEffect(() => {
        if (!isOpen) {
            setMasterPassword('');
        }
    }, [isOpen]);

    const onConfirmClicked = async () => {
        dispatch(impersonationThunks.impersonationDialogConfirmed(masterPassword));
        if (handleConfirm) {
            handleConfirm(masterPassword);
        }
    };

    const onCloseClicked = () => {
        dispatch(impersonationThunks.impersonationDialogCanceled());
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <Dialog isOpen={isOpen} onClose={onCloseClicked}>
            <DialogTitle>{t(`systems-tab.new-system-form.configure-step.uuid.impersonation.main-title`)}</DialogTitle>
            <DialogContent>
                <StyledBodyParagraph>{t(`systems-tab.new-system-form.configure-step.uuid.impersonation.description`)}</StyledBodyParagraph>
                <Input
                    height={InputHeight.large}
                    width='100%'
                    placeholder={t(`systems-tab.new-system-form.configure-step.uuid.impersonation.placeholder`)}
                    value={masterPassword}
                    onChange={handleMasterPasswordChange}
                />
            </DialogContent>
            <DialogActions>
                <StyledSecondaryButton size='large' variant='text' onClick={onCloseClicked}>
                    {t('buttons.cancel')}
                </StyledSecondaryButton>
                <StyledPrimaryButton
                    size='large'
                    disabled={masterPassword === ''}
                    onClick={async () => await onConfirmClicked()}
                    loading={isLoading}>
                    {t('buttons.confirm')}
                </StyledPrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
