import React from 'react';
import styled from 'styled-components';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Add, Delete } from '@material-ui/icons';
import { CssAddButton, CssDeleteRowButton } from '../../../crawler-form/steps/domain-step/domain-step-style';
import { CssTextField } from '../../../../data-integration-form-style';

export const WhiteListRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 16px;
`;

export const WhiteListIps = ({}) => {
    const checkEqual = (initState = [], newState = []): boolean => {
        if (typeof initState !== typeof newState) {
            return false;
        }
        if (Array.isArray(initState) && Array.isArray(newState)) {
            return (
                JSON.stringify([...initState].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))) ===
                JSON.stringify([...newState].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0)))
            );
        }
        return true;
    };
    return (
        <FieldArray name={'ipList'} isEqual={checkEqual}>
            {({ fields }) => (
                <>
                    <div>
                        {fields.map((row, index) => (
                            <WhiteListRow key={index}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '360px',
                                    }}>
                                    <Field name={row}>
                                        {({ input, meta }) => (
                                            <CssTextField
                                                className={'subDomain'}
                                                placeholder={'insert ip'}
                                                fullWidth
                                                variant='outlined'
                                                error={!!meta.error}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <CssDeleteRowButton
                                    aria-label='delete'
                                    size='small'
                                    disableRipple
                                    disableFocusRipple
                                    disableTouchRipple
                                    onClick={() => {
                                        fields.remove(index);
                                    }}>
                                    <Delete style={{ color: '#92A1BC' }} fontSize='inherit' />
                                </CssDeleteRowButton>
                            </WhiteListRow>
                        ))}
                    </div>
                    <CssAddButton disableRipple variant='outlined' onClick={() => fields.push('')} disabled={fields.length >= 30}>
                        <Add fontSize={'small'} />
                        <span style={{ paddingLeft: '6px', display: 'flex', lineHeight: '16px' }}>Add IP</span>
                    </CssAddButton>
                </>
            )}
        </FieldArray>
    );
};

export default WhiteListIps;
