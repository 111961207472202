import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContractsData, CreateSystemState, getContracts } from '@walkme-admin-center/libs/state-management-systems';
import { AppData } from '@walkme-admin-center/libs/types';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

export const useContracts = () => {
    const { loggedInUserAppData } = useLoggedInUser();
    const accountData = loggedInUserAppData?.data?.account;
    const contractsData: AppData<ContractsData> = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.contractsData
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContracts(false, accountData?.id));
    }, [dispatch]);

    return {
        contracts: contractsData?.data?.contracts,
        systems: contractsData?.data?.systems,
    };
};
