import React, { useState } from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { StyledAcknowledge, StyledBodyParagraph, StyledPrimaryButton, StyledSecondaryButton } from './idp-selection-dialog.styles';
import { useDispatch, useSelector } from 'react-redux';
import { uuidSlice, UUIDState } from '@walkme-admin-center/libs/state-management-systems';
import { UUID, UUIDOptions } from './uuid.lib';

export const IdpSelectionDialog = () => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [isConfirmed, setIsConfirmed] = useState(false);
    const idpSelectDialogIsOpen = useSelector((state: { uuidState: UUIDState }) => state.uuidState.idpSelectDialogIsOpen);

    const handleOnClose = () => {
        dispatch(uuidSlice.actions.setIdpSelectDialogIsOpen(false));
    };

    const handleOnConfirm = () => {
        const idpOption = UUIDOptions.find((option) => option.value === UUID.IDP);
        dispatch(uuidSlice.actions.setSelectedUUID(idpOption));
        dispatch(uuidSlice.actions.setIdpSelectDialogIsOpen(false));
    };

    return (
        <Dialog isOpen={idpSelectDialogIsOpen} onClose={handleOnClose}>
            <DialogTitle>{t(`systems-tab.new-system-form.configure-step.uuid.idp-selection.main-title`)}</DialogTitle>
            <DialogContent>
                <StyledBodyParagraph>{t(`systems-tab.new-system-form.configure-step.uuid.idp-selection.description`)}</StyledBodyParagraph>
                <StyledAcknowledge onClick={() => setIsConfirmed((confirmed) => !confirmed)}>
                    <Checkbox checked={isConfirmed} />
                    <div>{t(`systems-tab.new-system-form.configure-step.uuid.idp-selection.checkbox`)}</div>
                </StyledAcknowledge>
            </DialogContent>
            <DialogActions>
                <StyledSecondaryButton size='large' variant='text' onClick={handleOnClose}>
                    {t('buttons.cancel')}
                </StyledSecondaryButton>
                <StyledPrimaryButton size='large' disabled={!isConfirmed} onClick={handleOnConfirm}>
                    {t('buttons.confirm')}
                </StyledPrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
