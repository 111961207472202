import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import { Input } from '@walkme/ui-core';
import { WMButton, WMIconClose, WMSelect } from '@walkme/wm-ui';
import { Box, Chip, Dialog, Divider, Grid } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { Link } from 'react-router-dom';

export const SelectToolbar = styled.div`
    padding: 6px 16px;
    display: flex;
`;

export const StyledSelect = styled(Select)`
    .MuiSelect-root {
        align-items: center;
        display: flex;
        height: 24px;
        padding: 3px 5px;
        background: white;
        max-width: 420px;
        min-width: 200px;
    }
`;

export const BottomFormLinks = styled.div`
    margin-top: 60px;
`;

export const CustomClose = styled.div`
    display: inline-flex;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0 15px;
    justify-content: flex-end;
    color: #aeaeae;
    cursor: pointer;
`;

export const StyledTitle = styled.div`
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 600;
    color: #2f3d53;
`;

export const StyledTable = styled.table`
    padding: 33px 32px 10px;
    font-size: 13px;
    color: #1b2432;
`;

export const StyledTableKey = styled.td`
    height: 24px;
`;

export const StyledTableValue = styled.td`
    font-weight: 600;
`;

export const StyledSimpleWMSelect = styled(WMSelect)`
    .wmSelect__menu {
        min-width: max-content;
    }
`;

export const StyledTitleGrid = styled(Grid)`
    margin-top: 20px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 15px;
`;

export const FullScreenGrid = styled(Grid)`
    background-color: white;
    border-radius: 15px;
`;

export const UserFieldsDiv = styled.div`
    padding-right: 20px;
    padding-left: 20px;
`;

export const UserFieldItemDiv = styled.div`
    margin-bottom: 10px;
    display: grid;
`;

export const UserFieldLabel = styled.div`
    font-family: 'proximaNova';
    font-size: 12px;
    color: #2f426c;
`;

export const UserFieldLabelWithToolTip = styled.div`
    font-family: 'proximaNova';
    font-size: 12px;
    color: #2f426c;
    margin-right: 5px;
`;

export const UserParamSelect = styled.div`
    margin-top: 7px;
    width: 100%;
    height: 2em;
    max-width: 28em;
`;

export const UserParamTextField = styled.div`
    width: 100%;
    height: 2em;
    max-width: 28em;
`;

export const CategoryTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const WizardDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 15px;
    }
`;

export const WizardDialogOpen = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 15px;
    }
`;

export const WizardDialogContentNoPadding = styled(Grid)`
    margin-left: 0px;
    margin-right: 0px;
`;

export const WizardDialogContent = styled(Grid)`
    margin-left: 32px;
    margin-right: 32px;
`;

export const NameIdsField = styled.div`
    background-color: #ffffff !important;
    margin-left: 6px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #d4dff5;
`;

export const WizardDialogButtons = styled(Grid)`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -2px 0px #0000000a;
    padding-top: 15px;
    padding-bottom: 22px;
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
`;

export const WizardDialogPrimaryButton = styled(WMButton)`
    height: 40px !important;
    border-radius: 99999px !important;
`;

export const WizardDialogSeconaryButton = styled(WMButton)`
    margin-right: 22px;
    color: #637191 !important;
`;

export const StyledDialogTitle = styled.span`
    font-family: 'proximaNova';
    font-size: 20px;
    color: #2f426c;
    line-height: 1.5;
    font-weight: 600;
`;

export const KeywordChipsDiv = styled.div`
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f6f8;
`;

export const KeywordChipsAddTextDiv = styled.div`
    margin-top: 3px;
    display: flex;
    justify-content: flex-start;
`;

export const KeywordChipsAddText = styled.div`
    font-family: 'proximaNova';
    font-size: 14px;
    color: #2f426c;
`;

export const DialogAdvancedSettingsTitleAndCloseBox = styled.div`
    align-items: center;
`;

export const UsersTable = styled.div`
    cursor: pointer;
    min-height: 45vh;
    max-height: 45vh;
    margin-bottom: 2px;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #dde5f5;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`;

export const StyledChip = styled(Chip)`
    font-family: var(--wmFontSecondary);
    font-size: 14px;
    color: var(--wmGrayUltraDark);
    padding: 0 4px;
    margin: 10px 5px;
    border-radius: 4px;
    background-color: var(--Infographic-Blue-Blue-tint-4, #eceffa);
    &:focus {
        background-color: var(--Infographic-Blue-Blue-tint-4, #eceffa);
    }
    .MuiChip-label {
        padding: 0;
        margin-left: 1px;
        margin-right: 4px;
    }
`;

export const StyledChipInput = styled(ChipInput)`
    box-sizing: border-box;
    padding: 2px 4px;
    background-color: var(--wmBackground) !important;
    border-radius: 4px;
    border: 1px solid transparent !important;
    transition: border-color 0.3s ease-out;
    &:focus-within {
        border-color: var(--ls-color-input-dropdown-primary-stroke-active, #385feb) !important;
        background-color: white !important;
    }

    .styled-input {
        height: 24px;
        padding: 0;
        letter-spacing: 0;
        box-sizing: border-box;
        &::placeholder {
            color: var(--wmBermudaGray) !important;
            opacity: 1;
        }
    }

    .styled-input-root {
        margin: 2px;
        margin-left: 6px;
        min-width: 35px;
        font-family: var(--wmFontSecondary);
        color: var(--wmGrayUltraDark) !important;
        font-size: 14px;
        line-height: 1.5;
    }

    .styled-chip-container {
        min-height: 40px;
        max-height: 90px;
        overflow: auto;
        margin-bottom: -2px;
        margin: 0px 5px;
        line-height: normal;
        padding-bottom: 1px;
    }
`;

export const StyledDeleteIcon = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 0;
    color: var(--wmBermudaGray);
    transition: 0.3s ease-out;
    &:hover {
        color: var(--wmGrayUltraDark);
        background-color: var(--wmGrayUltraLight);
    }
`;

export const UserImage = styled.div`
    display: flex;
    background-color: #415395;
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export const UserImageText = styled.div`
    margin: auto;
    color: #ffffff;
    font-size: 10px;
`;

export const EmailText = styled.div`
    margin-left: 10px;
    font-family: var(--wmFontSecondary);
    color: #2f426c;
    font-size: 14px;
`;

export const EmailDiv = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const UserErrorLabel = styled.p`
    color: var(--wmError);
    margin-left: 45px;
`;

export const TableRowSelected = styled.div`
    background-color: #f5f7fe !important;
    &:hover {
        background-color: #f5f7fe !important;
    }
`;

export const StyledLabel = styled.div`
    color: var(--wmError);
`;

export const ContentDiv = styled.div`
    padding: 36px;
`;

export const StyledReviewLink = styled(Link)`
    font-family: 'proximaNova';
    margin-right: 6px;
    font-size: 13px;
    color: #5c7cf5;
    line-height: 1.5;
    text-decoration: underline;
`;

export const StyledBannerIcon = styled(WMIconClose)`
    margin-right: 12px;
`;

export const BannerGrid = styled(Grid)`
    background-color: #fff8e5;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 10px;
`;

export const BannerTitle = styled.span`
    font-family: 'proximaNova';
    margin-right: 6px;
    font-size: 13px;
    color: #2f3d53;
    line-height: 1.5;
`;

export const BadgeDiv = styled.div`
    display: flex;
    align-items: center;
    &:hover {
        & .makeStyles-tab-33 {
            color: #385feb;
        }
        & .MuiBadge-badge {
            background-color: #385feb;
            color: #ffffff;
        }
    }
`;

export const ActiveCustomBadge = styled.div`
    background-color: #3b61eb;
    color: #ffffff;
    line-height: 0px;
`;

export const CustomBadge = styled.div`
    background-color: #e4e9fc;
    color: #2f426c;
    line-height: 0px;
`;

export const CustomDot = styled.div`
    transform: translate(12px, -10px);
`;

export const StyledTabBoxes = styled(Box)`
    margin-bottom: 1vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .MuiTabs-flexContainer {
        display: flex;
        align-items: center;
    }
`;

export const Tab = styled.div`
    font-family: 'proximaNova';
    color: #637191;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    padding: 2px;
    text-transform: none;
`;

export const ActiveTab = styled.div`
    font-family: 'proximaNova';
    color: #3b61eb;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 400;
    padding: 2px;
    text-transform: none;
`;

export const Indicator = styled.div`
    background-color: #3b61eb;
`;

export const RoleRowName = styled.div`
    font-family: 'ProximaNova';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2f426c;
`;

export const RoleDescription = styled.div`
    font-family: 'ProximaNova';
    font-weight: 400;
    color: #8d97ae;
    font-size: 12px;
    line-height: 16px;
`;

export const RoleRowUsersCounter = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const UserRowLastLogin = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const UserRowEmail = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 14px;
`;

export const UserRowStatus = styled.div`
    margin-left: 6px;
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const UserRowName = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
    display: inline-block;
    max-width: 150px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserRowRole = styled.div`
    font-family: 'proximaNova';
    color: #2f426c;
    font-size: 12px;
`;

export const NoResultsGrid = styled(Grid)`
    background-color: white;
    margin-top: 24px;
    border-radius: 15px;
`;

export const NoResultsTitleGrid = styled(Grid)`
    margin-left: 24px;
    margin-top: 14px;
`;

export const NoResultsLoaderGrid = styled(Grid)`
    padding-top: 180px;
    padding-bottom: 180px;
`;

export const NoResultsImageGrid = styled(Grid)`
    padding: 30px;
`;

export const NoResultsDivider = styled(Divider)`
    margin-top: 14px;
`;

export const NoResultsTitle = styled.span`
    font-family: 'proximaNova';
    font-size: 18px;
    color: #2f426c;
    line-height: 1.5;
    font-weight: 600;
`;

export const FullScreenDiv = styled.div`
    background-color: white;
    height: 100%;
`;

export const InvitedText = styled.div`
    font-family: 'proximaNova';
    font-size: 20px;
    color: #2f426c;
    line-height: 1.5;
    font-weight: 600;
`;

export const StyledUserDisplay = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-left: 8px;
    }
`;

export const StyledInput = styled(Input)`
    & input {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
    }
`;
