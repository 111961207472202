import styled from 'styled-components';
import { Accordion, AccordionSummary, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const SystemContainer = styled.div`
    height: 16px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e1e9f5;
    border-radius: 4px;
    padding: 20px;
    background-color: initial;

    & .enforce-section {
        display: none;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
    }

    &.system-checked .enforce-section {
        display: flex;
    }

    &.system-checked {
        background-color: #f5f7fe;
    }

    &:hover:not(.system-checked) {
        border: 1px solid white;
        box-shadow: 0px 2px 8px #dde5f5;

        & .enforce-section {
            display: flex;
        }
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

export const EditSystemsWarning = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    width: 657px;
    height: 40px;
    background: #fef8e8;
    & .pipe {
        width: 4px;
        height: 100%;
        background: #f7b500;
    }
    & .MuiSvgIcon-root {
        color: #f7b500;
        padding-left: 8px;
    }
`;

export const SystemCounter = styled.span`
    align-self: end;
    font-weight: 600;
`;

export const SelectAllButton = styled(Button)`
    text-transform: none;
    width: 115px;
    &:hover {
        background-color: transparent;
    }
`;

export const StyledAccordion = styled(Accordion)`
    &.root {
        &:before {
            opacity: 0;
        }

        border: 1px solid #e1e9f5;
        margin-bottom: 8px;
        box-shadow: none;

        & .MuiCollapse-root {
            // background: '#F9FBFE';
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top: 1px solid rgb(225, 233, 245);
        }

        & .MuiAccordionDetails-root {
            padding: 0;
        }
    }

    &.rounded {
        border-radius: 4px !important;
    }

    &.expanded {
        margin: 0 0 8px 0 !important;
    }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    &.root {
        height: 16px;
        min-height: inherit;
        padding: 20px;

        &.Mui-expanded {
            min-height: inherit;
        }

        &.default-cursor {
            cursor: default !important;
        }
    }

    &.content {
        align-items: center;
        margin: 0;

        &.Mui-expanded {
            margin: 0;
        }
    }
`;

export const StyledSystemAssignmentContainer = styled.div`
    display: flex;
    flex-direction: column;

    &.alignItemsCenter {
        align-items: center;
    }
`;

export const StyledSystemCounterContainer = styled.div`
    display: flex;
    flex-direction: column;

    &.alignItemsCenter {
        align-items: center;
    }

    height: 50px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 0.75rem;
`;
