import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';
import { SystemData } from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';

export const useUpdatedWebSystem = () => {
    const updatedWebSystemAppData: AppData<SystemData> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.updatedWebSystem
    );

    return { updatedWebSystemAppData };
};

export default useUpdatedWebSystem;