import React, { useState } from 'react';
import { CssTextField } from '../../../../data-integration-form-style';
import styles from '../../../../../common/style.module.scss';
import { Field } from 'react-final-form';
import styled from 'styled-components';

const InfoIcon = 'assets/icons/data-integrations/info-icon-small-uneven.svg';

const TextFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
`;

const FieldLabel = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    color: #2f426c;
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export const ContentToast = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: 8px 12px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #385feb;
    background: #edf1fd;
    box-shadow: 0px 12px 24px 0px rgba(59, 97, 235, 0.07);
`;
const AppNameStep = () => {
    return (
        <>
            <ContentToast>
                <img src={InfoIcon} />
                <div>
                    For a successful integration we recommend an <b>IT</b> professional or <b>Admin</b> to perform the following steps
                </div>
            </ContentToast>
            <TextFieldContainer>
                <FieldLabel>Slack integration name</FieldLabel>
                <Field name={'name'}>
                    {({ input, meta }) => (
                        <CssTextField
                            variant='outlined'
                            placeholder={"e.g 'WalkMe Slack Integration'"}
                            fullWidth
                            error={!!meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            {...input}
                        />
                    )}
                </Field>
            </TextFieldContainer>
        </>
    );
};

export default AppNameStep;
