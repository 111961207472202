import { IAskMe } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { useMemo } from 'react';

const useSmoothProgress = (integration: IAskMe) => {
    const { jobStatus } = integration;

    return useMemo(() => {
        const scan = jobStatus.scan || 0;
        const scrape = jobStatus.scrape || 0;
        const scrape_failed = jobStatus.scrape_failed || 0;
        const crawl_failed = jobStatus.crawl_failed || 0;

        if (!scan) {
            return 0;
        } else if (scrape + scrape_failed + crawl_failed > scan) {
            return 100;
        } else {
            return Math.floor(((scrape + scrape_failed + crawl_failed) / scan) * 100);
        }
    }, [jobStatus]);
};

export default useSmoothProgress;
