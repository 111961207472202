import { Environment } from 'wm-accounts-sdk';

export const validateEnvironment = (environment: Partial<Environment>, environments: Environment[]): string[] => {
    const environmentNames = environments.map((environment) => environment.name.toLowerCase());
    const errors = [];

    if (environment.name === '') {
        errors.push(`Environment name must not be empty`);
    }

    if (environmentNames.includes(environment.name.toLowerCase())) {
        errors.push(`Environment "${environment.name}" already exists`);
    }

    if (!/^[a-zA-Z0-9]+$/.test(environment.name)) {
        errors.push(`Environment name must not include special characters`);
    }

    return errors;
};
