import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';

class CloudStorageApi {
    constructor(private httpClient: WMhttpClient) {}

    async getUserCloudStorage(): Promise<string> {
        const { data } = await this.httpClient.instance.get<string>(`editor/Recorder/GetUserCloudStorage`);

        return data;
    }
}

export const cloudStorageApi = new CloudStorageApi(wmhttpClientSharedInstance);
