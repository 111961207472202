import { AzureLogoIcon } from '../icons/logos/azure-logo.icon'
import { Host } from './host';

class Aws extends Host {
  displayName = 'Azure';

  LogoIcon = AzureLogoIcon;
}

export default new Aws();
