import styled from 'styled-components';

export const StyledSubMenu = styled.div<{ isOpen: boolean }>`
    border-radius: 5px;
    width: 100%;
    color: var(--Color-Local-Semantic-Text-primary, #637191);
    font-size: 14px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    & .header:hover {
        background: var(--Primaries-Blue-P8, #f5f7fe);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 8px;
        cursor: pointer;
        color: rgb(99, 113, 145);
        color: ${({ isOpen }) => (isOpen ? '#385FEB' : 'init')};
        background: ${({ isOpen }) => (isOpen ? '#E3E9FC' : 'init')};
    }
`;

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
    margin-top: 12px;
    padding: 10px 20px;
    cursor: pointer;
    color: ${({ isSelected }) => (isSelected ? '#385FEB' : 'init')};
    border-radius: 5px;
    background: ${({ isSelected }) => (isSelected ? '#E3E9FC' : 'init')};

    &:hover {
        background: var(--Primaries-Blue-P7, #edf1fd);
    }
`;
