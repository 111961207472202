import styled from 'styled-components';
import { DropdownSelection, Input, Toaster } from '@walkme/ui-core';
import { WMTooltip } from '@walkme/wm-ui';

const FlexDivContainer = styled.div`
    display: flex;
`;
export const MainContainer = styled(FlexDivContainer)`
    height: 100%;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

export const HeaderContainer = styled(FlexDivContainer)`
    flex-direction: column;
    padding: 32px 24px 12px 24px;
    align-self: stretch;
    flex: 1 0 0;
    background: var(--white, #fff);
    border-radius: 0px 12px 12px 0px;
    gap: 6px;
`;

export const HeaderMainText = styled('span')`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    .sub-title {
        overflow: hidden;
        color: var(--Color-Local-Semantic-Text-secondary, #637191);
        text-overflow: ellipsis;
        /* Texts/t3/walk-me-t3 */
        font-family: 'Proxima Nova';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
`;

export const ContentContainer = styled(FlexDivContainer)`
    padding: 16px 24px 20px 24px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    gap: 16px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;

        &-track {
            background-color: var(--wmWhite);
            border-radius: 4px;
        }

        &-thumb {
            background-color: var(--wmGrayMedium);
            border: 2px solid var(--wmWhite);
            border-radius: 4px;

            &:hover {
                background-color: var(--wmUltraLight);
            }
        }
    }
`;

export const StyledSaveAndPublish = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 32px;

    .save-and-publish {
        margin-left: 8px;
    }
`;

export const LoaderContainer = styled(FlexDivContainer)`
    background: white;
    border-radius: 12px;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const StyledSelector = styled(DropdownSelection)`
    overflow: hidden;
    button {
        height: 40px;
        width: 300px;
        margin: 0;
        outline: none !important;
    }
`;

export const StyledBodyContainer = styled.div`
    width: 100%;
`;

export const StyledInput = styled(Input)`
    & input {
        height: 40px;
    }

    & input:focus {
        border-width: 1px;
        outline: none;
    }

    & input:disabled {
        background-color: var(--Primaries-Disable-Disable-tint-8, #f8f8f8);
        color: rgba(200, 200, 200, 1);
    }
`;

export const StyledVariableInputContainer = styled.div`
    display: flex;
    padding-bottom: 16px;
`;

export const StyledInputLabel = styled.div`
    line-height: 16px;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 12px;
    color: rgb(47, 66, 108);
    padding-bottom: 8px;
`;

export const StyledWMTooltip = styled(WMTooltip)`
    &.WMTooltip-wmTooltip {
        box-sizing: border-box;
        padding: 6px 12px;
        background-color: #233251;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        max-width: 312px;
    }

    & .WMTooltip-htmlWrapper {
        padding: 0;
    }

    & .WMTooltip-arrow {
        color: #233251;
    }
`;

export const StyledToasterContainer = styled(Toaster)`
    margin-bottom: 20px;
    box-shadow: none;
`;

export const StyledToasterMessage = styled.div`
    a {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;
