import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AuthUrlResponse,
    Certificate,
    IdpSystemsFormValue,
    Fields,
    Provider,
    ProviderFormValues,
    ProviderGroup,
    ProviderGroups,
    IdpEnvs,
} from '@walkme-admin-center/libs/state-management-sso-configuration';
import { FormHeader } from './form-header/form-header';
import IdpIntegrationStep from './Idp-integration-step/Idp-integration-step';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { getProviderInstance } from './services/provider-service';
import { getErrorObject } from './services/validation';
import { uniqBy } from 'lodash';
import get from 'lodash/get';
import { IdpPropertiesStep } from './idp-properties-step/idp-properties-step';
import { FormApi } from 'final-form';
import { CONSTS, StepperMode } from '../common/consts';
import StepperFooter from './form-footer/form-footer';
import SystemAssignmentStep from './system-assignment-step/system-assignment-step';
import CustomStepper from '../common/components/custom-stepper/custom-stepper';
import { StyledDialogActions, StyledDialogContent } from './provider-form-stepper-style';
import { Environments } from 'wm-accounts-sdk';

export interface ProviderFormStepperProps {
    provider?: Provider;
    isImpersonator: boolean;
    assignIdpSystemsForm: IdpSystemsFormValue;
    assignIdpSystemsByEnv: IdpEnvs;
    providerGroups: ProviderGroups;
    allSystems: IdpSystemsFormValue;
    systemsEnvs: Map<string, Environments>;
    stepperMode: string;
    onClose: () => void;
    showSnackBarError: (marginTop?, message?) => void;
    handleSave: (body) => void;
    handleAssignSystems: (body: IdpSystemsFormValue) => void;
    showSnackBar: (data) => void;
}

export const ProviderFormStepper = ({
    onClose,
    provider,
    providerGroups,
    handleSave,
    systemsEnvs,
    handleAssignSystems,
    stepperMode,
    allSystems,
    assignIdpSystemsForm,
    assignIdpSystemsByEnv,
    showSnackBar,
    showSnackBarError,
    isImpersonator,
}: ProviderFormStepperProps) => {
    const [activeStep, setActiveStep] = useState<number>(
        stepperMode === StepperMode.NEW ? 0 : stepperMode === StepperMode.EDIT ? 0 : stepperMode === StepperMode.EDIT_ATTRIBUTES ? 1 : 2
    );
    const [idpType, setIdpType] = useState<ProviderGroup>(
        provider
            ? providerGroups.find((group) => group.id === provider.providerGroupId)
            : {
                  name: 'okta',
                  displayName: 'Okta',
                  id: 1,
              }
    );
    const [certificate, setCertificate] = useState<Certificate>(null);
    const [providerInstance, setProviderInstance] = useState(getProviderInstance(idpType.name));
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [fieldsLoad, setFieldsLoad] = useState<boolean>(false);
    const [loadedFields, setLoadedFields] = useState([]);
    const [isGetFieldsWithNewCredentials, setIsGetFieldsWithNewCredentials] = useState(false);
    const [newCredentials, setNewCredentials] = useState(false);
    const [lastStateAfterGetField, setLastStateAfterGetField] = useState(provider ? provider : {});
    const [timer, setTimer] = useState(null);
    const [canUpdate, setCanUpdate] = useState<boolean>(false);
    const [candidateId, setCandidateId] = useState<number>(null);

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, [timer]);

    const requireFieldsBeforeUpdate = providerInstance
        .getFormFields()
        .filter((field) => field.requireBeforeUpdate)
        .map((x) => x.name);

    // -----   stepper functions start ----
    const getSteps = (): Array<string> => {
        switch (stepperMode) {
            case StepperMode.NEW:
                return ['IDP Integration', 'IDP Properties', 'Assign Systems'];
            case StepperMode.EDIT:
            case StepperMode.ASSIGN_SYSTEMS:
            case StepperMode.EDIT_ATTRIBUTES:
                return [];
        }
    };

    const handleNext = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (stepIndex: number, values): React.ReactElement => {
        switch (stepIndex) {
            case 0:
                return (
                    <IdpIntegrationStep
                        providerInstance={providerInstance}
                        handleIdpTypeChange={handleIdpTypeChange}
                        certificate={certificate}
                        setCertificate={updateCertificate}
                        idpType={idpType}
                        provider={provider}
                        providerGroups={providerGroups}
                    />
                );
            case 1:
                return (
                    <IdpPropertiesStep
                        fields={fieldsLoad ? loadedFields : provider.fields.filter((field) => field.name !== CONSTS.PROVIDER_GROUPS)}
                        providerInstance={providerInstance}
                        loadedFields={loadedFields}
                        fieldsLoad={fieldsLoad}
                        values={values}
                        stopPolling={stopPolling}
                        inProgress={inProgress}
                        handleAuth={handleAuth}
                    />
                );
            case 2:
                return (
                    <SystemAssignmentStep
                        provider={provider}
                        assignIdpSystemsByEnv={assignIdpSystemsByEnv}
                        allSystems={allSystems}
                        stepperMode={stepperMode}
                        systemsEnvs={systemsEnvs}
                        isImpersonator={isImpersonator}
                    />
                );
        }
    };

    // -----   stepper functions end ----

    // ----- attributes function  start ----

    const stopPolling = (): void => {
        setInProgress(false);
        if (timer) {
            clearTimeout(timer);
        }
    };

    const getFields = async (formApi: FormApi, canUpdate: boolean, candidateId: number, counter = 0): Promise<Fields> => {
        try {
            const fields = await providerInstance.getFields(provider, newCredentials || isGetFieldsWithNewCredentials, candidateId);
            if (!canUpdate) {
                setIsGetFieldsWithNewCredentials(true);
            }
            checkFields(formApi, fields);
            setLoadedFields(fields);
            setFieldsLoad(true);
            setLastStateAfterGetField({ ...formApi.getState().values });
            if (stepperMode !== StepperMode.EDIT_ATTRIBUTES) {
                handleNext();
            }
            setInProgress(false);
            return fields;
        } catch (err) {
            if (counter > 30) {
                stopPolling();
                showSnackBarError(CONSTS.MARGIN_TOP_SNACKBAR_STEPPER);
            } else {
                const tmr = setTimeout(() => getFields(formApi, canUpdate, candidateId, counter + 1), 2000);
                setTimer(tmr);
            }
        }
    };

    const checkFields = (formApi: FormApi, fields: Fields): void => {
        if (!provider) {
            formApi.change('userIdentifier', '');
            formApi.change('fields', []);
        } else {
            const userIdentifier = formApi.getState().values.userIdentifier;
            if (!fields.some((field) => field.name === userIdentifier)) {
                formApi.change('userIdentifier', '');
            }
            const arr = [];
            provider.fields
                .filter((field) => field.name !== CONSTS.PROVIDER_GROUPS)
                .forEach((item) => {
                    if (fields.some((field) => field.name === item.name)) {
                        arr.push(item);
                    }
                });
            formApi.change('fields', arr);
        }
    };

    const handleAuth = async (values: ProviderFormValues, formApi: FormApi): Promise<void> => {
        try {
            setInProgress(true);
            const newAuth: AuthUrlResponse = await providerInstance.handleAuth(
                values,
                provider,
                newCredentials || isGetFieldsWithNewCredentials,
                idpType,
                certificate
            );
            if (newAuth.error) {
                setInProgress(false);
                showSnackBarError(CONSTS.MARGIN_TOP_SNACKBAR_STEPPER, newAuth.error);
                return;
            }
            window.open(newAuth.authUrl);
            setCandidateId(newAuth.candidateId);
            await getFields(formApi, canUpdate, newAuth.candidateId);
        } catch (err) {
            setInProgress(false);
            showSnackBarError(CONSTS.MARGIN_TOP_SNACKBAR_STEPPER);
        }
    };

    // ----- attributes function  end ----

    // -----   stepper provider settings function start ----
    const updateCertificate = (value: Certificate): void => {
        setCertificate(value);
    };

    const handleIdpTypeChange = (type: ProviderGroup): void => {
        setIdpType(type);
        setProviderInstance(getProviderInstance(type.name));
    };

    const validate = useCallback(
        async (values: ProviderFormValues) => {
            const schema = providerInstance.getSchemaValidation();
            let providerSettingsErrors = await getErrorObject(schema, values);
            if (activeStep === 0) {
                let flag = true;
                for (const requiredField of requireFieldsBeforeUpdate) {
                    const isEqual = get(values, requiredField) === get(lastStateAfterGetField, requiredField);
                    if (!isEqual) {
                        flag = false;
                        break;
                    }
                }
                setCanUpdate(flag);
                setNewCredentials(!flag);
            }
            if (activeStep === 1) {
                if (fieldsLoad) {
                    if (!values.userIdentifier) {
                        providerSettingsErrors = {
                            ...providerSettingsErrors,
                            userIdentifier: 'User identifier is required',
                        };
                    }
                    if (values.fields && !values.fields.length) {
                        providerSettingsErrors = {
                            ...providerSettingsErrors,
                            fields: 'At least one property is required',
                        };
                    }
                }
            }
            if (providerSettingsErrors) {
                return providerSettingsErrors;
            }
            return null;
        },
        [providerInstance, activeStep, fieldsLoad, lastStateAfterGetField, requireFieldsBeforeUpdate]
    );

    const assignSystems = async (values: ProviderFormValues): Promise<void> => {
        try {
            setInProgress(true);
            const systems = (values.systems || []).filter((system) => system.providerId);
            const providerSystemsPreviousState = providerInstance.prepareSystems(provider.id, provider.systems);
            let newState: IdpSystemsFormValue = providerSystemsPreviousState
                .filter(
                    (previousState) =>
                        !systems.some(
                            (currentState) => currentState.guid === previousState.guid && currentState.wmEnv === previousState.wmEnv
                        )
                )
                .map((system) => {
                    return { active: false, guid: system.guid, enforceSso: system.enforceSso, wmEnv: system.wmEnv };
                });

            newState = newState.concat(
                systems
                    .filter((currentStateSystem) => {
                        const found = providerSystemsPreviousState.find(
                            (system) => system.guid === currentStateSystem.guid && system.wmEnv === currentStateSystem.wmEnv
                        );
                        return !found ? true : currentStateSystem.enforceSso !== found.enforceSso;
                    })
                    .map((system) => {
                        return { active: true, guid: system.guid, enforceSso: system.enforceSso, wmEnv: system.wmEnv };
                    })
            );

            await handleAssignSystems(newState);
            setInProgress(false);
            onClose();
            const getSnackBarMessage = (systems, providerName) => {
                const endSyntax = `${systems.length === 1 ? 'has' : 'have'}  been assigned to ${providerName}`;
                if (!systems.length) {
                    return `No systems ${endSyntax}`;
                }
                const tempSystems = systems.map((system) => {
                    if (system.name) return system;
                    const foundSystem = allSystems.find((sys) => sys.guid === system.guid);
                    return { ...system, name: foundSystem ? foundSystem.displayName : '' };
                });
                return tempSystems.length <= 2
                    ? `${tempSystems
                          .map((s) => {
                              return s.name;
                          })
                          .join(', ')} ${endSyntax}`
                    : `${tempSystems
                          .slice(0, 2)
                          .map((s) => {
                              return s.name;
                          })
                          .join(', ')} & ${tempSystems.length - 2} systems ${endSyntax}`;
            };
            showSnackBar({
                open: true,
                messageText: getSnackBarMessage(uniqBy(systems, 'guid'), values.name),
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (err) {
            setInProgress(false);
            showSnackBarError(CONSTS.MARGIN_TOP_SNACKBAR_STEPPER);
        }
    };

    const save = async (form: FormApi): Promise<void> => {
        const body = providerInstance.prepareProviderBeforeSave(form, provider, candidateId);
        try {
            setInProgress(true);
            await handleSave(body);
            showSnackBar({
                marginTop: stepperMode === StepperMode.NEW ? CONSTS.MARGIN_TOP_SNACKBAR_STEPPER : CONSTS.MARGIN_TOP_SNACKBAR,
                open: true,
                messageText: `${form.getState().values.name} IDP has been successfully ${
                    stepperMode === StepperMode.NEW ? 'added' : 'updated'
                }`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
            if (stepperMode === StepperMode.NEW) {
                setFieldsLoad(false);
                setNewCredentials(false);
                setIsGetFieldsWithNewCredentials(false);
                setCertificate(null);
                setCandidateId(null);
                setInProgress(false);
                handleNext();
            } else {
                onClose();
            }
        } catch (err) {
            setInProgress(false);
            showSnackBarError(CONSTS.MARGIN_TOP_SNACKBAR_STEPPER, get(err, 'response.data.message', null));
        }
    };

    const initialValues = useMemo(() => providerInstance.initForm(provider, assignIdpSystemsForm), [providerInstance]);

    // -----   stepper provider settings function end ----
    return (
        <>
            <FormHeader
                onClose={onClose}
                providerName={provider ? provider.name : null}
                activeStep={activeStep}
                stepperMode={stepperMode}
                handleBack={handleBack}
            />
            <CustomStepper activeStep={activeStep} steps={getSteps()} />
            <Form
                onSubmit={save}
                initialValues={initialValues}
                keepDirtyOnReinitialize
                validate={validate}
                mutators={{
                    ...arrayMutators,
                }}
                render={({ values }) => {
                    return (
                        <>
                            <StyledDialogContent>
                                <div className={'stepper-box'}>
                                    <div
                                        className={'stepper-content'}
                                        style={{
                                            maxWidth: activeStep !== 2 ? '560px' : '100%',
                                        }}>
                                        {getStepContent(activeStep, values)}
                                    </div>
                                </div>
                            </StyledDialogContent>
                            <StyledDialogActions>
                                <StepperFooter
                                    activeStep={activeStep}
                                    stepperMode={stepperMode}
                                    onClose={onClose}
                                    canUpdate={canUpdate}
                                    handleAuth={handleAuth}
                                    handleNext={handleNext}
                                    inProgress={inProgress}
                                    stopPolling={stopPolling}
                                    fieldsLoad={fieldsLoad}
                                    assignSystems={assignSystems}
                                    save={save}
                                />
                            </StyledDialogActions>
                        </>
                    );
                }}
            />
        </>
    );
};
export default ProviderFormStepper;
