import React, { useEffect, useState } from 'react';
import {
    StyledAccessibilityContainer,
    StyledAccessibilityTitle,
    StyledAccessibilityToasterMessage,
    StyledAccessibilityWrapper,
    StyledSaveAndPublish,
    LoaderContainer,
    StyledLoader,
} from './accessibility.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { Button, Checkbox, Toaster, ToasterVariant } from '@walkme/ui-core';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { WMLoader, WMSnackbar, WMSnackbarVariant, WMSwitch } from '@walkme/wm-ui';
import { ImpersonationState, systemsSlice, impersonationThunks } from '@walkme-admin-center/libs/state-management-systems';
import { SaveAndPublishDialog } from '../save-and-publish-dialog';
import { siteConfigApi } from '../api/site-config-api';
import { generateConfigurationString } from '../create-system/create-system-dialog/create-system-dialog.lib';
import { ImpersonatorDialog } from '../impersonator-dialog';
import { is } from 'date-fns/locale';
import { NotificationMessage } from '@walkme-admin-center/libs/types';

export const Accessibility = ({ systemId }) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [savingData, setSavingData] = useState(false);
    const { loggedInUserAppData } = useLoggedInUser();
    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;
    const [accessibilityDefaultMasterFeatureOnAccount, setAccessibilityDefaultMasterFeatureOnAccount] = useState(null);
    const [defaultAccessibilityFeature, setDefaultInitAccessibilityFeature] = useState(false);
    const [defaultAutomaticAccessibilityOnAccount, setDefaultAutomaticAccessibilityOnAccount] = useState(false);
    const [accessibilityFeatureIsOn, setAccessibilityFeatureIsOn] = useState(false);
    const [automaticEnableAccessibilityFeature, setAutomaticEnableAccessibilityFeature] = useState(false);
    const accessibilityDefaultMasterFeatureIsOnByUser =
        accessibilityDefaultMasterFeatureOnAccount?.modifiedBy === loggedInUserAppData?.data?.email;
    const [isLoading, setIsLoading] = useState(true);

    const impersonationPassword = useSelector(
        (state: { impersonationState: ImpersonationState }) => state.impersonationState.currentPassword
    );

    const defaultSnackbarMessage: NotificationMessage = { text: '', variant: WMSnackbarVariant.Success, isOpen: false };
    const [snackbarMessage, setSnackbarMessage] = useState(defaultSnackbarMessage);

    const saveIsDisabled =
        accessibilityFeatureIsOn === defaultAccessibilityFeature &&
        automaticEnableAccessibilityFeature === defaultAutomaticAccessibilityOnAccount;

    const onPublishFailed = (message: string) => {
        setSnackbarMessage({ text: message, variant: WMSnackbarVariant.Error, isOpen: true });
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordFailed());
        }
    };

    const onPublishSucceeded = (message: string) => {
        setSnackbarMessage({ text: message, variant: WMSnackbarVariant.Success, isOpen: true });
        if (isImpersonated) {
            dispatch(impersonationThunks.actionWithPasswordSucceeded());
        }
    };

    const openSaveAndPublishDialog = () => {
        dispatch(systemsSlice.actions.setSaveAndPublishDialogIsOpen(true));
    };

    const onSaveAndPublishClicked = () => {
        if (isImpersonated) {
            dispatch(impersonationThunks.startActionWithPassword(openSaveAndPublishDialog));
        } else {
            openSaveAndPublishDialog();
        }
    };

    const onSnackbarClose = () => {
        setSnackbarMessage(defaultSnackbarMessage);
    };

    const getTextJsx = (fullText: string, linksPrefix: string) => {
        const elements = [];
        const numOfLinks = (fullText.match(/\$\$/g) || []).length;
        const splitByDollars = fullText.split('$$');
        splitByDollars.forEach((element, index) => {
            elements.push(<span>{element}</span>);
            if (index < numOfLinks) {
                const linkText = t(`${linksPrefix}.${index}.text`);
                const linkURL = t(`${linksPrefix}.${index}.url`);
                elements.push(
                    <a href={linkURL} target='_blank'>
                        {linkText}
                    </a>
                );
            }
        });
        return elements;
    };

    const getToasterMessage = () => {
        if (accessibilityDefaultMasterFeatureOnAccount && !accessibilityDefaultMasterFeatureIsOnByUser) {
            const fullText = t(`systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-enabled.text`);
            const linksPrefix = 'systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-enabled.links';
            const ToasterMessage = getTextJsx(fullText, linksPrefix);
            return <StyledAccessibilityToasterMessage>{ToasterMessage}</StyledAccessibilityToasterMessage>;
        }

        const fullText = t(`systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-not-enabled.text`);
        const linksPrefix = 'systems-tab.new-system-form.configure-step.accessibility.toaster.accessibility-not-enabled.links';
        const ToasterMessage = getTextJsx(fullText, linksPrefix);

        return <StyledAccessibilityToasterMessage>{ToasterMessage}</StyledAccessibilityToasterMessage>;
    };

    const setDefaultAccessibilityFeature = async (shouldShowLoading = true) => {
        if (shouldShowLoading) {
            setIsLoading(true);
        }

        try {
            const userFeatureNamesRes = await siteConfigApi.getUserFeatureNames({ systemId });
            const accessibilityFeatureIsOn =
                userFeatureNamesRes?.data?.includes('accessibility') || userFeatureNamesRes?.data?.includes('Accessibility');
            setDefaultInitAccessibilityFeature(accessibilityFeatureIsOn);
            setAccessibilityFeatureIsOn(accessibilityFeatureIsOn);
        } catch (e) {
            console.error(e);
        } finally {
            if (shouldShowLoading) {
                setIsLoading(false);
            }
        }
    };

    const getAccountDefaultMasterFeature = async () => {
        try {
            const masterFeaturesRes = await siteConfigApi.getAccountDefaultMasterFeatures({
                accountId: loggedInUserAppData?.data?.account?.id,
            });
            const accessibilityEnabledOnAccount = masterFeaturesRes?.data?.find(
                (featureItem) => featureItem.feature?.name?.toLowerCase() === 'accessibility'
            );
            setAccessibilityDefaultMasterFeatureOnAccount(accessibilityEnabledOnAccount);
            if (accessibilityEnabledOnAccount) {
                setAccessibilityFeatureIsOn(true);

                if (accessibilityEnabledOnAccount.modifiedBy === loggedInUserAppData?.data?.email) {
                    setAutomaticEnableAccessibilityFeature(true);
                    setDefaultAutomaticAccessibilityOnAccount(true);
                }
            } else {
                setDefaultAutomaticAccessibilityOnAccount(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const saveAccessiblity = async () => {
        // user turned off accessibility on this system , although it's enabled on account as default
        if (accessibilityDefaultMasterFeatureOnAccount && !accessibilityFeatureIsOn) {
            await siteConfigApi.updateUserFeatures({
                systemId,
                userId: loggedInUserAppData?.data?.id,
                updatedUserFeatures: [
                    { featureName: 'Accessibility', isActivated: false },
                    { featureName: 'accessibility', isActivated: false },
                ],
            });
        }

        await siteConfigApi.updateUserFeatures({
            systemId,
            userId: loggedInUserAppData?.data?.id,
            updatedUserFeatures: [
                { featureName: 'Accessibility', isActivated: accessibilityFeatureIsOn },
                { featureName: 'accessibility', isActivated: accessibilityFeatureIsOn },
            ],
        });

        if (!accessibilityDefaultMasterFeatureOnAccount && automaticEnableAccessibilityFeature) {
            await siteConfigApi.updateAccountDefaultMasterFeatures({
                accountId: loggedInUserAppData?.data?.account?.id,
                masterFeatureName: 'accessibility',
                modifiedBy: loggedInUserAppData?.data?.email,
                platform: 'Web',
            });
        } else if (
            accessibilityDefaultMasterFeatureOnAccount &&
            accessibilityDefaultMasterFeatureIsOnByUser &&
            !automaticEnableAccessibilityFeature
        ) {
            await siteConfigApi.removeAccountDefaultMasterFeature({
                accountId: loggedInUserAppData?.data?.account?.id,
                masterFeatureName: 'accessibility',
                platform: 'Web',
            });
        }
    };

    const handleOnlySave = async () => {
        setSavingData(true);
        try {
            await saveAccessiblity();
            dispatch(
                systemsSlice.actions.setNotificationMessage({
                    text: t('systems-tab.new-system-form.configure-step.notifications.success'),
                    variant: WMSnackbarVariant.Success,
                    isOpen: true,
                })
            );
            triggerAccessibilityDataReload(false);
        } catch (e) {
            dispatch(
                systemsSlice.actions.setNotificationMessage({
                    text: t('systems-tab.new-system-form.configure-step.notifications.fail'),
                    variant: WMSnackbarVariant.Error,
                    isOpen: true,
                })
            );
            console.error(e);
        } finally {
            setSavingData(false);
        }
    };

    const triggerAccessibilityDataReload = (shouldShowLoading = true) => {
        setDefaultAccessibilityFeature(shouldShowLoading);
        getAccountDefaultMasterFeature();
    };

    useEffect(() => {
        getAccountDefaultMasterFeature();
    }, []);

    useEffect(() => {
        setDefaultAccessibilityFeature();
    }, []);

    return isLoading ? (
        <LoaderContainer>
            <StyledLoader />
        </LoaderContainer>
    ) : (
        <StyledAccessibilityWrapper>
            <StyledAccessibilityContainer>
                <StyledAccessibilityTitle>
                    <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.accessibility.main-title`)}</div>
                    <div className='sub-title'>
                        <div className='action-row'>
                            <div>{t(`systems-tab.new-system-form.configure-step.accessibility.sub-title-black`)}</div>
                            <WMSwitch
                                checked={accessibilityFeatureIsOn}
                                disabled={accessibilityDefaultMasterFeatureOnAccount && !accessibilityDefaultMasterFeatureIsOnByUser}
                                onChange={(e) => setAccessibilityFeatureIsOn(e.target?.checked)}
                            />
                        </div>
                        <div className='learn-more'>
                            <span>{t(`systems-tab.new-system-form.configure-step.accessibility.sub-title-learn-more`)}</span>
                            <a href='https://support.walkme.com/knowledge-base/accessibility-in-walkme/' target='_blank'>
                                {t('buttons.learn-more')}
                            </a>
                        </div>
                    </div>
                    {accessibilityFeatureIsOn &&
                    (!accessibilityDefaultMasterFeatureOnAccount ||
                        (accessibilityDefaultMasterFeatureOnAccount && accessibilityDefaultMasterFeatureIsOnByUser)) ? (
                        <div className='automatic-enable-row'>
                            <Checkbox
                                checked={automaticEnableAccessibilityFeature}
                                onChange={() => setAutomaticEnableAccessibilityFeature((isEnabled) => !isEnabled)}
                            />
                            <div>{t(`systems-tab.new-system-form.configure-step.accessibility.automatic-enable`)}</div>
                        </div>
                    ) : null}
                </StyledAccessibilityTitle>
                <ImpersonatorDialog isLoading={savingData} />
                <Toaster className='accessibilityToaster' icon={true} variant={ToasterVariant.Info} message={getToasterMessage()} />
            </StyledAccessibilityContainer>
            <StyledSaveAndPublish>
                <Button disabled={saveIsDisabled || savingData} loading={savingData} onClick={handleOnlySave}>
                    {t('buttons.save')}
                </Button>
                <Button disabled={saveIsDisabled} onClick={onSaveAndPublishClicked} className='save-and-publish'>
                    {t('buttons.save-and-publish')}
                </Button>
            </StyledSaveAndPublish>
            <SaveAndPublishDialog
                settingsType='accessibility'
                systemId={systemId}
                accessibilityFeatureIsOn={accessibilityFeatureIsOn}
                defaultAutomaticAccessibilityOnAccount={defaultAutomaticAccessibilityOnAccount}
                automaticEnableAccessibilityFeature={automaticEnableAccessibilityFeature}
                accessibilityDefaultMasterFeatureOnAccount={accessibilityDefaultMasterFeatureOnAccount}
                reloadData={triggerAccessibilityDataReload}
                saveData={saveAccessiblity}
                onFailure={onPublishFailed}
                onSuccess={onPublishSucceeded}
            />
            <WMSnackbar
                open={snackbarMessage.isOpen}
                onClose={onSnackbarClose}
                variant={snackbarMessage.variant}
                message={snackbarMessage.text}
            />
        </StyledAccessibilityWrapper>
    );
};
