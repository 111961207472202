import { AccountType, SystemPurpose } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';

type ShouldDisplayContractsParams = {
    purpose: SystemPurpose;
    accountTypes: AccountType[];
    isTestingMode: boolean;
};
export const shouldDisplayContracts = ({ purpose, accountTypes, isTestingMode }: ShouldDisplayContractsParams) => {
    if (purpose === SystemPurpose.TRIAL) return false;

    return (
        isTestingMode ||
        accountTypes?.length === 0 ||
        accountTypes?.some((accountType: AccountType) =>
            [AccountType.PAID, AccountType.LIMITED, AccountType.CUSTOMER, AccountType.PARTNER].includes(accountType)
        )
    );
};
