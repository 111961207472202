import * as yup from 'yup';

export const guidedAnswerSchema = yup.object().shape({
    question: yup.string().trim().required('Question is a required field'),
    answer: yup.string().trim().required('Answer is a required field'),
});

export const guidedAnswersSchema = yup.object().shape({
    guidedAnswers: yup.array().of(guidedAnswerSchema.nullable()).ensure(),
});
