const generateSrc = (deploymentUrl: string, userGuid: string, addGuidToPath: boolean) => {
    return addGuidToPath ? `'${deploymentUrl}/${userGuid}/walkme_${userGuid}_https.js'` : `'${deploymentUrl}/walkme_${userGuid}_https.js'`;
};

const generateSelfHostedSnippet = (deploymentUrl: string, userGuid: string, addGuidToPath = true) => {
    return `<script type="text/javascript">
(function() {
    var walkme = document.createElement('script');
    walkme.type = 'text/javascript';
    walkme.async = true;
    walkme.src = ${generateSrc(deploymentUrl, userGuid, addGuidToPath)};
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(walkme, s);
    window._walkmeConfig = {smartLoad: true};
})();
</script>`;
};

export { generateSelfHostedSnippet };
