import { WMStatusDotVariant } from '@walkme/wm-ui';
import { InviteStatus, PartnerAccountStatus, PartnerUserStatus } from 'packages/libs/types/src/lib/partner';

export const AccountStatusMap = [
    {
        id: PartnerAccountStatus.Active,
        display: 'partners-tab.status.account.active',
        info: 'partners-tab.status.account.active-info',
        color: WMStatusDotVariant.Success,
        numberdId: 0,
    },
    {
        id: PartnerAccountStatus.NoUsersOrSystems,
        display: 'partners-tab.status.account.no-user',
        info: 'partners-tab.status.account.no-user-info',
        color: WMStatusDotVariant.Failure,
        numberdId: 1,
    },
];

export const UserStatusMap = [
    {
        id: PartnerUserStatus.Active,
        display: 'partners-tab.status.user.active',
        info: 'partners-tab.status.user.active-info',
        color: WMStatusDotVariant.Success,
        numberdId: 0,
    },
    {
        id: PartnerUserStatus.NoSystems,
        display: 'partners-tab.status.user.no-systems',
        info: 'partners-tab.status.user.no-systems-info',
        color: WMStatusDotVariant.Draft,
        numberdId: 1,
    },
];

export const InviteStatusMap = [
    {
        id: InviteStatus.Pending,
        display: 'partners-tab.status.invite.pending',
        info: 'partners-tab.status.invite.pending-info',
        color: WMStatusDotVariant.Success,
        numberdId: 0,
    },
    {
        id: InviteStatus.Expired,
        display: 'partners-tab.status.invite.expired',
        info: 'partners-tab.status.invite.expired-info',
        color: WMStatusDotVariant.Failure,
        numberdId: 1,
    },
];
