import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageTitle } from '@walkme-admin-center/libs/shared-styles';
import {
    useScopes,
    useAccountServiceApps,
    createAccountServiceApp,
    updateAccountServiceApp,
    ApplicationsState,
    applicationsSlice,
    deleteAccountServiceApp,
} from '@walkme-admin-center/libs/state-management-applications';
import { CommonSnackbar, DataTable } from '@walkme-admin-center/libs/ui-components';
import styled from 'styled-components';
import { Box, Button, Link, ListItemText, Typography } from '@material-ui/core';
import { System, User, WMScope } from 'wm-accounts-sdk';
import { Dialog, PermissionsErrorkey } from '@walkme-admin-center/libs/common';
import { TableRowData } from '@walkme-admin-center/libs/types';
import moment from 'moment';
import {
    WMAccountApplication,
    WMAccountServiceApplication,
    WMApplication,
} from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/applications-types';
import { usersSlice, UsersState, useUsers } from '@walkme-admin-center/libs/state-management-users';
import { useDispatch, useSelector } from 'react-redux';
import ApiKeysForm from './apiKeysForm/api-keys-form';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping } from 'apps/home/src/localization';
import { useDataTableLocale } from 'packages/pages/util/locale-utils';

export interface ApiKeysPageProps {}

const StyledButton = styled(Button)`
    border-radius: 17px;
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: 170px;
    box-shadow: none;
    font-family: 'ProximaNova';
    border-color: #e1e9f5;
    background-color: #3b61eb;
    color: #ffffff;
    text-transform: none;
    &:hover {
        background-color: #1f46d1;
        opacity: 0.9;
    }
`;

const StyledDialog = styled(Dialog)`
    background-color: white;
    width: 486px;
    padding-top: 0px;
`;

const StyledPageTitleLabel = styled('label')`
    font-family: 'ProximaNova';
    color: #7680a3;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;
`;

const StyledTitleDiv = styled.div`
    margin-bottom: 24px;
`;

interface ApiRow extends TableRowData {
    creator: string;
    name: string;
    created: string;
    clientId: string;
    lastUsed: string | null;
}

const getApiRows = (apis: WMAccountServiceApplication[], users: User[]): ApiRow[] => {
    if (apis) {
        return apis.map((item) => {
            const { appName, appId, clientId, creationDate, assignedUser, lastUsageDateMillis } = item;

            const creation = moment(creationDate).format('MMM Do YYYY');
            const lastUsed = lastUsageDateMillis != 0 ? moment(lastUsageDateMillis).format('MMM Do YYYY') : '';

            const creator = users.find((user) => user.id == assignedUser);

            return {
                creator: creator ? creator.email : 'WalkMe Backoffice',
                name: appName,
                created: creation,
                clientId: clientId,
                lastUsed: lastUsed,
                id: clientId,
            };
        });
    }

    return [];
};

export const ApiKeysPage = (props: ApiKeysPageProps) => {
    const { t, i18n } = useTranslation('general');
    const availableScopesAppData = useScopes();
    const { accountServiceApps } = useAccountServiceApps();
    const updatedServiceAppData = useSelector(
        (state: { applicationsSlice: ApplicationsState }) => state.applicationsSlice.updatedServiceApp
    );
    const createdServiceAppData = useSelector(
        (state: { applicationsSlice: ApplicationsState }) => state.applicationsSlice.createdServiceApp
    );
    const deleteServiceAppData = useSelector((state: { applicationsSlice: ApplicationsState }) => state.applicationsSlice.deleteServiceApp);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    const errorMessage = useMemo(() => {
        const errorMessages = [updatedServiceAppData.error, createdServiceAppData.error, deleteServiceAppData.error];

        const forbiddenResourceError = errorMessages.find((error) => error && error.includes('Forbidden resource'));
        if (forbiddenResourceError) {
            return t(PermissionsErrorkey);
        }

        const nonEmptyError = errorMessages.find((error) => error);
        return nonEmptyError || null;
    }, [updatedServiceAppData.error, createdServiceAppData.error, deleteServiceAppData.error]);

    const Apis = useMemo(() => {
        const mappedScopes = {};
        const scopes = availableScopesAppData.actionsAppData.data ? availableScopesAppData.actionsAppData.data : [];
        for (const scope of scopes) {
            const scopeKey = scope.name.split(':')[0];
            const scopeAction = scope.name.split(':')[1];
            if (mappedScopes[scopeKey]) {
                mappedScopes[scopeKey].actions.push(scopeAction);
            } else {
                mappedScopes[scopeKey] = { desc: scope.description, actions: [scopeAction] };
            }
        }
        return mappedScopes;
    }, [availableScopesAppData]);

    const dataTableLocale = useDataTableLocale();

    const { usersAppData } = useUsers();
    const users = usersAppData.data;

    const ApisRows = useMemo(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
        return getApiRows(accountServiceApps.data, users);
    }, [accountServiceApps, i18n.language]);

    const [showDialog, setShowDialog] = useState(false);
    const [selectedApi, setSelectedApi] = useState<WMAccountApplication>(null);
    const [newAppName, setNewAppName] = useState('');
    const [creator, setCreator] = useState('');
    const [lastUsed, setLastUsed] = useState('');
    const [newApi, setNewApi] = useState(false);
    const [newSecret, setNewSecret] = useState('');

    const dispatch = useDispatch();

    const close = useCallback(() => {
        setShowDialog(false);
        setOpenErrorSnackbar(false);
    }, []);

    useEffect(() => {
        document.title = t('api-keys-tab.document-title');
    }, []);

    const handleCreateApi = useCallback(
        (selectedScopes, newAppName) => {
            const newScopesString = [];
            selectedScopes.forEach((value, key) => {
                value.forEach((item) => {
                    newScopesString.push(key.concat(':', item));
                });
            });
            const data = {
                appName: newAppName,
                scopes: newScopesString,
            };
            dispatch(createAccountServiceApp(data));
        },
        [dispatch, newAppName]
    );

    const handleUpdateApi = useCallback(
        (scopesSelected, appID) => {
            const updatedScopesString = [];
            scopesSelected.forEach((value, key) => {
                value.forEach((item) => {
                    updatedScopesString.push(key.concat(':', item));
                });
            });
            const data = {
                appId: appID,
                scopes: updatedScopesString,
            };
            dispatch(updateAccountServiceApp(data));
        },
        [dispatch]
    );

    const handleDeleteApi = useCallback(
        (appID) => {
            const data = {
                appId: appID,
            };
            dispatch(deleteAccountServiceApp(data));
        },
        [dispatch]
    );

    useEffect(() => {
        if (createdServiceAppData.data) {
            const api = accountServiceApps.data.find((api) => api.clientId === createdServiceAppData.data.clientId);
            setSelectedApi(api);
            setNewApi(true);
            setNewSecret(createdServiceAppData.data.clientSecret);
            const row = ApisRows.find((row) => row.clientId === createdServiceAppData.data.clientId);
            setCreator(row ? row.creator : '');
        } else if (createdServiceAppData.error) {
            setOpenErrorSnackbar(true);
        }
    }, [createdServiceAppData, accountServiceApps, ApisRows]);

    useEffect(() => {
        dispatch(applicationsSlice.actions.cleanupCreatedAccountServiceApp());
        dispatch(applicationsSlice.actions.cleanupUpdatedAccountServiceApp());
        dispatch(applicationsSlice.actions.cleanupDeletedAccountServiceApp());
        close();
    }, []);

    useEffect(() => {
        if (updatedServiceAppData.data) {
            dispatch(applicationsSlice.actions.cleanupUpdatedAccountServiceApp());
            dispatch(applicationsSlice.actions.cleanupCreatedAccountServiceApp());
            close();
        } else if (updatedServiceAppData.error) {
            setOpenErrorSnackbar(true);
        }
    }, [updatedServiceAppData]);

    useEffect(() => {
        if (deleteServiceAppData.data) {
            dispatch(applicationsSlice.actions.cleanupDeletedAccountServiceApp());
            close();
        } else if (deleteServiceAppData.error) {
            setOpenErrorSnackbar(true);
        }
    }, [deleteServiceAppData]);

    const formActions = { onClose: close, onConfirm: handleCreateApi, onUpdate: handleUpdateApi, onDelete: handleDeleteApi };

    const handleSnackBarErrorClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenErrorSnackbar(false);
    };

    const triggerApiKeyDialog = useMemo(() => {
        return (
            <StyledDialog
                view='sideScreen'
                children={
                    <ApiKeysForm
                        selected={selectedApi}
                        selectedCreator={creator}
                        selectedLastUsed={lastUsed}
                        scopes={Apis}
                        formActions={formActions}
                        updating={updatedServiceAppData.loading}
                        deleting={deleteServiceAppData.loading}
                        submitting={createdServiceAppData.loading}
                        newApi={newApi}
                        clientSecret={newSecret}
                    />
                }
                onClose={() => setShowDialog(false)}
                showDialog={showDialog}
            />
        );
    }, [showDialog, selectedApi, updatedServiceAppData, createdServiceAppData, deleteServiceAppData]);

    const createNewKeyDialog = () => {
        setSelectedApi(null);
        setNewApi(false);
        setShowDialog(true);
    };

    const createEditKeyDialog = (id) => {
        const api = accountServiceApps.data.find((api) => api.clientId === id);
        setSelectedApi(api);
        setCreator(ApisRows.find((row) => row.clientId === id).creator);
        setLastUsed(ApisRows.find((row) => row.clientId === id).lastUsed);
        setNewApi(false);
        setShowDialog(true);
    };

    const headCells = useMemo(() => {
        const cells = [
            { id: 'name', label: t('common.name') },
            { id: 'creator', label: t('api-keys-tab.creator') },
            { id: 'created', label: t('api-keys-tab.created') },
            { id: 'lastUsed', label: t('api-keys-tab.last-used') },
            { id: 'clientId', label: t('api-keys-tab.client-id') },
        ];
        return cells;
    }, []);

    return (
        <div>
            <StyledTitleDiv>
                <PageTitle>{t('api-keys-tab.title')}</PageTitle>
                <Typography style={{ fontFamily: 'ProximaNova', color: '#7680A3', fontSize: '14px' }}>
                    {t('api-keys-tab.description')}
                    <Link
                        href='https://developer.walkme.com/reference#getting-started-with-your-api'
                        target='_blank'
                        underline='hover'
                        style={{ marginLeft: '5px' }}>
                        {t('buttons.here')}
                    </Link>
                </Typography>
            </StyledTitleDiv>
            {showDialog && triggerApiKeyDialog}
            <DataTable
                buttonText={t('api-keys-tab.create-new-key')}
                heads={headCells}
                data={ApisRows}
                loading={accountServiceApps.loading}
                onToolbarButtonClick={createNewKeyDialog}
                customToolbarButton={
                    <StyledButton onClick={createNewKeyDialog} variant='contained'>
                        {t('api-keys-tab.create-new-key')}
                    </StyledButton>
                }
                onRowClick={createEditKeyDialog}
                searchPlaceholder={t('data-grid.search-placeholder')}
                locale={dataTableLocale}
            />
            <CommonSnackbar open={openErrorSnackbar} onClose={handleSnackBarErrorClose} severity='error' messageText={errorMessage} />
        </div>
    );
};

export default ApiKeysPage;
