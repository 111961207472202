export function retriveTeamsStepVideo(index: number) {
    return `assets/icons/data-integrations/integrations-videos/teams-integration-step${index}.mov`;
}

export function retriveTeamsCopyBotSecretVideo() {
    return 'assets/icons/data-integrations/integrations-videos/teams-copy-bot-secret.mov';
}

export function retriveTeamsCopyBotIdVideo() {
    return 'assets/icons/data-integrations/integrations-videos/teams-copy-bot-id.mov';
}
