import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Delete, Add } from '@material-ui/icons';
import styled from 'styled-components';
import { CssTextField } from '../../../../data-integration-form-style';
import { CssAddButton, CssDeleteRowButton } from './domain-step-style';

export const DomainsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
`;

export const DomainRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
`;

export interface ISubDomains {
    fieldName: string;
    placeHolder: string;
    disabled?: boolean;
}

export const SubDomains = ({ fieldName, placeHolder, disabled }: ISubDomains) => {
    const formApi = useForm();

    return (
        <FieldArray name={fieldName}>
            {({ fields }) => (
                <>
                    <DomainsContainer>
                        {fields.map((path, index) => (
                            <DomainRow key={index}>
                                <div>
                                    <CssTextField
                                        className={'subDomain readOnly'}
                                        variant='outlined'
                                        disabled
                                        InputProps={{
                                            value: formApi.getState().values.settings.startingUrl || '',
                                        }}
                                    />
                                    <Field name={`${path}`}>
                                        {({ input, meta }) => (
                                            <CssTextField
                                                className={'subDomain'}
                                                placeholder={placeHolder}
                                                variant='outlined'
                                                error={!!meta.error}
                                                disabled={disabled}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <CssDeleteRowButton
                                    aria-label='delete'
                                    size='small'
                                    disableRipple
                                    disableFocusRipple
                                    disableTouchRipple
                                    onClick={() => {
                                        fields.remove(index);
                                    }}
                                    disabled={disabled}>
                                    <Delete style={{ color: '#92A1BC' }} fontSize='inherit' />
                                </CssDeleteRowButton>
                            </DomainRow>
                        ))}
                    </DomainsContainer>
                    <CssAddButton
                        disableRipple
                        variant='outlined'
                        onClick={() => fields.push('')}
                        disabled={disabled || fields.length >= 10}>
                        <Add fontSize={'small'} />
                        <span style={{ paddingLeft: '6px', display: 'flex', lineHeight: '16px' }}>add path</span>
                    </CssAddButton>
                </>
            )}
        </FieldArray>
    );
};

export default SubDomains;
