import styled from 'styled-components';
import { Menu, Tabs } from '@walkme/ui-core';
import { WMButton } from '@walkme/wm-ui';

export const StyledTitleDiv = styled.div`
    margin-bottom: 24px;
`;
export const TryBetaDiv = styled.div`
    height: 40px;
    justify-content: center;
    align-items: center;
    background: var(--extra-extra-4, #e4c7ff);
    margin: -32px -32px 16px;
    display: flex;
    gap: 12px;
`;
export const ButtonBeta = styled.div`
    display: flex;
    padding: 2px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--infographic-violet-violet-shade-1, #421669);
    cursor: pointer;
`;
export const StyledTabs = styled(Tabs)`
    height: calc(100% - 84px);
    [role*='tabpanel'] {
        margin-top: 24px;
        height: calc(100% - 60px);
    }
`;
export const StyledBadgeSpan = styled.span<{ active: boolean }>`
    border-radius: 100px;
    background: ${(props) => (props.active ? 'var(--blue-primary-primary-main, #385FEB)' : 'var(--blue-primary-primary-tint-5, #d8dffb)')};
    color: ${(props) => (props.active ? 'white' : 'var(--blue-primary-primary-main, #385FEB)')};
    display: flex;
    padding: 0px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

export const StyledTabsRow = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
`;

export const StyledCreateSystemButton = styled(WMButton)`
    height: 32px;
    width: 140px;
    position: absolute;
    right: 32px;
`;

export const StyledCreateSystemMenu = styled.div`
    top: 32px;
    right: 32px;
`;

export const StyledMenu = styled(Menu)`
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
        width: 190px;
        margin-top: 8px !important;
    }

    .menu-item-gap {
        gap: 8px;
    }
`;

export const MenuTitle = styled.div`
    padding: 16px;
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 8px; /* 57.143% */
`;
