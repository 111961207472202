import styled from 'styled-components';
import { CodeBlock } from '../../../../../../self-hosting/src/lib/components/common/code-block';
import { FlexDivContainer } from '../../../../../../extension-pages/src/lib/new-design/shared-styled-components';
import { Toaster } from '@walkme/ui-core';
import { Link } from 'react-router-dom';

export const StyledContentContainer = styled.div<{ scroll: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
    background-color: white;
    overflow-y: scroll;
    flex-wrap: wrap;
    padding: 0 32px 32px 32px;
    overflow-x: auto;
    align-items: flex-start;
    border-bottom: 1px solid #ebeff7;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledDeploymentMethodContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
`;

export const StyledDeploymentMethodsWrapper = styled.div`
    width: 100%;
`;

export const StyledDeploymentMethodCardContainer = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;
`;

export const StyledDeploymentMethodBody = styled.div`
    margin-top: 24px;
`;

export const StyledDeploymentMethodTitles = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 24px 0 14px 0;

    .main-title {
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 24px;
        font-weight: 600;
    }

    .sub-title {
        font-size: 14px;
    }
`;

export const StyledSubTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    .sub-title {
        a {
            color: var(--Blue-Primary-Primary-Main, #385feb) !important;
            text-decoration: none;
        }
    }
`;

export const StyledLearnMoreLink = styled.a`
    color: var(--Color-Generation-On-light-primary-0, #385feb);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
`;

export const SnippetMethod = styled.div`
    margin-top: 20px;

    .description {
        color: var(--Typography-Typography-tint-1, #637191);
    }

    .code-snippet {
        font-family: 'Proxima Nova';
        font-weight: 600;
        border-radius: var(--corner-radus-global-cr-308, 8px);
        background: var(--Typography-Typography-MainPrimary-Main, #2f426c);
        padding: 8px 16px;
        margin-top: 20px;
    }
`;

export const StyledCodeBlock = styled(CodeBlock)`
    border-radius: 8px;

    .react-syntax-highlighter-line-number {
        color: #637191 !important;
    }
`;

export const ExtensionMethod = styled.div`
    .extension-as-default {
        display: flex;
        margin: 14px 0;
        .description {
            margin-left: 8px;
            color: var(--Color-Semantic-Text-secondary, #637191);
        }
    }
`;

const ControlHeaderWithTooltipContainer = styled(FlexDivContainer)`
    gap: 4px;
    align-items: center;
    flex-grow: 1;
`;

const ControlHeader = styled('span')`
    color: var(--typography-typography-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const StyledEnvironmentUrlsNote = styled(Toaster)`
    margin: 12px 0;

    .environment-urls-note-link {
        margin-left: 4px;
    }
`;

export const StyledLoadFromExtensionWarning = styled(Toaster)`
    margin: 12px 0;
    box-shadow: none;
`;

export const StyledLink = styled(Link)<{ underline?: boolean }>`
    color: var(--blue-primary-primary-main, #385feb);
    cursor: pointer;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
    }
`;
