import { EnvironmentsBySystemObject, ProcessStatus, SelfHostedState } from './self-hosted-state';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import rootReducer from 'apps/home/src/redux/rootReducer';
import { ThunkAction } from 'redux-thunk';
import { DownloadPackageDto } from '../types/download-package.dto';
import { selfHostedApi } from '../api/self-hosted-api';
import { PreferenceKey } from '../consts';
import { EnvironmentSettings } from '../types/environment-settings';
import { SaveEnvironmentSettingsDto } from '../types/save-environment-settings.dto';
import { SaveExtensionPathDto } from '../types/save-extension-path.dto';
import { siteConfigApi } from '../../../../systems/src/lib/api/site-config-api';

type EnvSettingsParams = {
    accountId: number;
    systemId: number;
    systemGuid: string;
    envId: number;
};

const initialSelfHostedState: SelfHostedState = {
    packageDownloadStatus: ProcessStatus.IDLE,
    settingsByEnv: {},
    settingsLoadStatus: ProcessStatus.IDLE,
    preferences: {
        [PreferenceKey.SKIP_INSTRUCTIONS]: false,
        [PreferenceKey.SKIP_SNIPPET_CHANGE_CONFIRM]: false,
    },
    settingsSaveStatus: ProcessStatus.IDLE,
    environmentsBySystem: {},
    environmentsLoadStatus: ProcessStatus.IDLE,
};

const selfHostedSlice = createSlice({
    name: 'selfHostedSlice',
    initialState: initialSelfHostedState,
    reducers: {
        downloadPackageStart: (state: SelfHostedState) => {
            state.packageDownloadStatus = ProcessStatus.IN_PROGRESS;
            return state;
        },
        downloadPackageSuccess: (state: SelfHostedState) => {
            state.packageDownloadStatus = ProcessStatus.SUCCESS;
            return state;
        },
        downloadPackageError: (state: SelfHostedState) => {
            state.packageDownloadStatus = ProcessStatus.ERROR;
            return state;
        },
        clearDownloadPackageStatus: (state: SelfHostedState) => {
            state.packageDownloadStatus = ProcessStatus.IDLE;
            return state;
        },
        loadSettingsStart: (state: SelfHostedState) => {
            state.settingsLoadStatus = ProcessStatus.IN_PROGRESS;
            return state;
        },
        loadSettingsSuccess: (state: SelfHostedState, action: PayloadAction<{ envId: number; envSettings: EnvironmentSettings }>) => {
            const { envId, envSettings } = action.payload;
            state.settingsByEnv = {
                ...state.settingsByEnv,
                [envId]: envSettings,
            };
            state.settingsLoadStatus = ProcessStatus.SUCCESS;
            return state;
        },
        loadSettingsError: (state: SelfHostedState) => {
            state.settingsLoadStatus = ProcessStatus.ERROR;
            return state;
        },
        updatePreference: (state: SelfHostedState, action: PayloadAction<{ preference: PreferenceKey; value: boolean }>) => {
            const { preference, value } = action.payload;
            state.preferences = {
                ...state.preferences,
                [preference]: value,
            };
            return state;
        },
        saveSettingsStart: (state: SelfHostedState) => {
            state.settingsSaveStatus = ProcessStatus.IN_PROGRESS;
            return state;
        },
        saveSettingsSuccess: (state: SelfHostedState, action: PayloadAction<{ envId: number; settings: EnvironmentSettings }>) => {
            const { envId, settings } = action.payload;
            state.settingsByEnv = {
                ...state.settingsByEnv,
                [envId]: settings,
            };
            state.settingsSaveStatus = ProcessStatus.SUCCESS;
            return state;
        },
        saveSettingsError: (state: SelfHostedState) => {
            state.settingsSaveStatus = ProcessStatus.ERROR;
            return state;
        },
        clearSaveSettingsStatus: (state: SelfHostedState) => {
            state.settingsSaveStatus = ProcessStatus.IDLE;
            return state;
        },
        clearEnvSettings: (state: SelfHostedState) => {
            state.settingsByEnv = {};
            return state;
        },
        clearEnvironments: (state: SelfHostedState) => {
            state.environmentsBySystem = {};
            return state;
        },
        loadEnvironmentsStart: (state: SelfHostedState) => {
            state.environmentsLoadStatus = ProcessStatus.IN_PROGRESS;
            return state;
        },
        loadEnvironmentsSuccess: (
            state: SelfHostedState,
            action: PayloadAction<{ systemId: number; systemEnvs: EnvironmentsBySystemObject }>
        ) => {
            const { systemId, systemEnvs } = action.payload;
            state.environmentsBySystem = {
                ...state.environmentsBySystem,
                [systemId]: systemEnvs,
            };
            state.environmentsLoadStatus = ProcessStatus.SUCCESS;
            return state;
        },
        loadEnvironmentsError: (state: SelfHostedState) => {
            state.environmentsLoadStatus = ProcessStatus.ERROR;
            return state;
        },
    },
});

const {
    downloadPackageStart,
    downloadPackageSuccess,
    downloadPackageError,
    loadSettingsStart,
    loadSettingsSuccess,
    loadSettingsError,
    updatePreference,
    saveSettingsStart,
    saveSettingsSuccess,
    saveSettingsError,
    loadEnvironmentsStart,
    loadEnvironmentsSuccess,
    loadEnvironmentsError,
} = selfHostedSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

const downloadPackage =
    (payload: DownloadPackageDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(downloadPackageStart());

            await selfHostedApi.downloadPackage(payload);

            dispatch(downloadPackageSuccess());
        } catch (err) {
            dispatch(downloadPackageError());
        }
    };

const getPreference =
    (preferenceKey: PreferenceKey): AppThunk =>
    async (dispatch) => {
        const preferenceValue = localStorage.getItem(preferenceKey);

        if (preferenceValue !== null) {
            const payload = {
                preference: preferenceKey,
                value: preferenceValue === 'true',
            };
            dispatch(updatePreference(payload));
        }
    };

const setPreference =
    (preferenceKey: PreferenceKey, preferenceValue: boolean): AppThunk =>
    async (dispatch) => {
        localStorage.setItem(preferenceKey, preferenceValue.toString());

        const payload = {
            preference: preferenceKey,
            value: preferenceValue,
        };
        dispatch(updatePreference(payload));
    };

const getEnvironments =
    ({ systemId }): AppThunk =>
    async (dispatch, getState) => {
        try {
            const currentEnvironments = getState().selfHostedState.environmentsBySystem[systemId];
            if (currentEnvironments) {
                return;
            }

            dispatch(loadEnvironmentsStart());

            const environments = await siteConfigApi.getEnvironments({
                systemId: systemId,
                isImpersonated: false,
            });

            dispatch(
                loadEnvironmentsSuccess({
                    systemId,
                    systemEnvs: environments.data,
                })
            );
        } catch (e) {
            dispatch(loadEnvironmentsError());
        }
    };

const getSelfHostedSettings =
    ({ systemId, envId, accountId, systemGuid }: EnvSettingsParams, forceLoad: boolean = false): AppThunk =>
    async (dispatch, getState) => {
        try {
            const currentEnvSettings = getState().selfHostedState.settingsByEnv[envId];
            if (!forceLoad && currentEnvSettings) {
                return;
            }

            dispatch(loadSettingsStart());

            const getSettingsPromise = selfHostedApi.getSettings(systemId);
            const getExposureSettingsPromise = selfHostedApi.getExposureSettings({ accountId, systemId, envId });

            const [{ EnvironmentData }, exposureSettings] = await Promise.all([getSettingsPromise, getExposureSettingsPromise]);

            const extensionUrlDisplayExtractor = new RegExp(`^(.*)/${systemGuid}$`);
            const extensionUrlDisplay = exposureSettings.selfHostedPath?.replace(extensionUrlDisplayExtractor, '$1');

            const settings: EnvironmentSettings = {
                deployment: {
                    url: extensionUrlDisplay,
                    cacheUpdateTime: exposureSettings.cacheUpdateTime,
                    isUpdateEnabled: exposureSettings.canUpdate,
                    linkedToExtension: exposureSettings.isSystemLinkedToTheExtension,
                    isActive: exposureSettings.isActive,
                },
                package: {
                    hostingMethod: EnvironmentData[envId].SelfHostedMethod,
                    deploymentUrl: EnvironmentData[envId].SelfHostedUrl,
                    mirrorExtensionUrl: false,
                },
            };

            dispatch(
                loadSettingsSuccess({
                    envId,
                    envSettings: settings,
                })
            );
        } catch (err) {
            dispatch(loadSettingsError());
        }
    };

const getSelfHostedPath = (url: string, systemGuid: string, addGuidToPath = true): string => {
    return addGuidToPath ? `${url}/${systemGuid}` : `${url}`;
};

const saveSelfHostedEnvSettings =
    ({ systemId, envId, accountId, systemGuid }: EnvSettingsParams, settings: EnvironmentSettings): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(saveSettingsStart());

            const { package: newPackageSettings, deployment: newExtensionSettings, addGuidToPath } = settings;
            const { package: currentPackageSettings, deployment: currentExtensionSettings } =
                getState().selfHostedState.settingsByEnv[envId];

            let savePromises = [];

            if (
                newPackageSettings.hostingMethod !== currentPackageSettings.hostingMethod ||
                newPackageSettings.deploymentUrl !== currentPackageSettings.deploymentUrl
            ) {
                const payload: SaveEnvironmentSettingsDto = {
                    systemId,
                    env: envId,
                    selfHostedMethod: newPackageSettings.hostingMethod,
                    deploymentUrl: newPackageSettings.deploymentUrl,
                };

                savePromises.push(selfHostedApi.saveEnvironmentSettings(payload));
            }

            if (newExtensionSettings.url !== currentExtensionSettings.url && currentExtensionSettings.isUpdateEnabled) {
                const payload: SaveExtensionPathDto = {
                    accountId,
                    systemId,
                    envId,
                    selfHostedPath: getSelfHostedPath(newExtensionSettings.url, systemGuid, addGuidToPath),
                };

                savePromises.push(selfHostedApi.saveExtensionPath(payload));
            }

            await Promise.all(savePromises);

            dispatch(saveSettingsSuccess({ envId, settings }));
        } catch (err) {
            dispatch(saveSettingsError());
        }
    };

export {
    EnvSettingsParams,
    initialSelfHostedState,
    selfHostedSlice,
    downloadPackage,
    getSelfHostedSettings,
    getPreference,
    setPreference,
    saveSelfHostedEnvSettings,
    getEnvironments,
};
