import React, { SVGProps } from 'react';

export const LightBulbIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
        <g clipPath='url(#clip0_12988_4718)'>
            <circle cx='7.5' cy='8.41797' r='7' stroke='#6D81A6' />
            <circle cx='0.5' cy='0.5' r='0.5' transform='matrix(1 0 0 -1 7 4.91797)' fill='#6D81A6' />
            <path
                d='M9 12.418H8.5C7.94772 12.418 7.5 11.9703 7.5 11.418V8.41797C7.5 7.86568 7.05228 7.41797 6.5 7.41797H6'
                stroke='#6D81A6'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_12988_4718'>
                <rect width='16' height='16' fill='white' transform='translate(0 0.917969)' />
            </clipPath>
        </defs>
    </svg>
);
