import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    inputBase: {
        '&.WMTextField-inputRoot': {
            paddingLeft: '16px',
            paddingRight: '13px',
            lineHeight: '20px',
            '&:hover': {
                borderColor: '#93A8F4',
            },
        },
    },
    inputFocused: {
        '&.WMTextField-inputRoot.WMTextField-focused': {
            borderColor: '#385FEB',
        },
    },
    inputError: {
        '&.WMTextField-inputRoot.WMTextField-error': {
            borderColor: '#EB3232',
            '&.WMTextField-focused': {
                borderColor: '#EB3232',
            },
        },
    },
    inputDisabled: {
        '&.WMTextField-inputRoot.WMTextField-disabled': {
            color: '#ACB3C4',
        },
        '& .MuiSvgIcon-root': {
            color: '#D6DCE6',
        },
    },
}));

export { useStyles };
