import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    & .main-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & .page-title {
            margin: 0;
        }
        & .page-title-sub-text {
            color: #7680a3;
        }
    }
`;

export const CreateIntegrationsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
`;

export const PageTitle = styled.h1`
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
`;
