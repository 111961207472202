import React, { useState } from 'react';
import {
    StepContainer,
    TitleContainer,
    TitleText,
    BoldText,
    SubtitleText,
    VideoStepNumber,
    StyledLink,
} from '../../../slack-form/steps/manifest-step/slack-steps/slack-steps-styles';
import VideoContainer from '../../../slack-form/steps/manifest-step/slack-steps/video-container';
import { retriveTeamsStepVideo } from '@walkme-admin-center/libs/common';
import CustomToolTip from '../../../../../common/components/custom-tool-tip';

const SetEndpoint = ({ guid }) => {
    const [copyClicked, setCopyClicked] = useState(false);
    const handleCopy = () => {
        setCopyClicked(true);
        navigator.clipboard.writeText(`${window.clientConfig.NX_WM_PAPI}/deepui/api/askme-teams/api/messages/${guid}`);
        setTimeout(() => {
            setCopyClicked(false);
        }, 2000);
    };

    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>5.</VideoStepNumber>Set<BoldText>Endpoint</BoldText>
                </TitleText>
                <SubtitleText>
                    <CustomToolTip title={'Copied!'} arrow disableHoverListener placement='left' open={copyClicked}>
                        <StyledLink onClick={handleCopy}>Click here</StyledLink>
                    </CustomToolTip>{' '}
                    to copy endpoint url, and paste it in 'Configuration' tab
                </SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveTeamsStepVideo(4)} />
        </StepContainer>
    );
};

export default SetEndpoint;
