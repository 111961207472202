import { TFunction } from 'i18next';
import { getMatchPatternValidationResult, getRegexValidationResult } from './url-validator';
import { ValidationErrorType, ValidationResult } from './validation-result';

/* eslint-disable no-useless-escape*/
const invalidPipeRegex: RegExp = /^\||(\|)\1+|\|$/;

const LOCALIZATION_KEYS_PREFIX = 'errors.regex-validation';

const getErrorMessage = (errorType: string, t: TFunction): string => t(`${LOCALIZATION_KEYS_PREFIX}.${errorType}` as string);

const getRegexValidationResultNew = (value: string, t: TFunction): ValidationResult => {
    if (value.startsWith(' ') || value.endsWith(' ')) {
        const errorType = ValidationErrorType.LEADING_OR_TRAILING_SPACE;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    if (invalidPipeRegex.test(value)) {
        const errorType = ValidationErrorType.LEADING_OR_DOUBLE_PIPES;
        return {
            isValid: false,
            errorMessage: getErrorMessage(errorType, t),
            errorType,
        };
    }

    return getRegexValidationResult(value, t);
};

const getMatchPatternValidationResultNew = (value: string, t: TFunction): ValidationResult => {
    if (value.endsWith(' ')) {
        const errorType = ValidationErrorType.URL_TRAILING_SPACE;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    if (value.includes('|')) {
        const errorType = ValidationErrorType.URL_CONTAINS_PIPE;
        return { isValid: false, errorMessage: getErrorMessage(errorType, t), errorType };
    }

    return getMatchPatternValidationResult(value, t);
};

export { getMatchPatternValidationResultNew, getRegexValidationResultNew };
