import React, { useCallback, useState } from 'react';
import {
    IntegrationCard,
    DataIntegrationsListContainer,
    IntegrationCardFooter,
    IntegrationInfo,
    IntegrationLogoContainer,
    IntegrationName,
    IntegrationCardContent,
    EnableButton,
} from './integration-style';
import { retrieveIntegrationsIcon } from '@walkme-admin-center/libs/common';
import { FormTypes, IIntegration } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import DeleteWarning from './delete-warning';
import { Modal } from '@material-ui/core';
import { integrationsArray } from '../../../common/consts';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useGlobalSettings } from '@walkme-admin-center/libs/state-mangment-data-integration';

const CheckedIcon = 'assets/icons/data-integrations/checkedIcon.svg';

interface IntegrationsListProps {
    triggerModal: (type: FormTypes, name: string) => void;
    integrations: IIntegration[];
    showSnackBar: (data: any) => void;
}

const IntegrationsList = ({ triggerModal, integrations, showSnackBar }: IntegrationsListProps) => {
    const { isAdminMode } = useGlobalSettings();
    const { accountFeatureEnabled } = useLoggedInUser();
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [chosenType, setChosenType] = useState('');
    const [chosenName, setchosenName] = useState('');

    const handleButtonClick = (type: string, name: string) => {
        const formType = FormTypes[type.toUpperCase()];
        const integration = integrations.find((integration) => integration.type === type);
        integration ? handleDeleteIntegration(formType, name) : triggerModal(formType, name);
    };

    const handleDeleteIntegration = (type: string, name: string) => {
        setChosenType(type);
        setchosenName(name);
        setOpenWarningModal(true);
    };

    const closeWarningModal = () => {
        setOpenWarningModal(false);
    };

    const getDeleteModal = useCallback(() => {
        const integrationGuid = integrations.find((integration) => integration.type === chosenType.toLowerCase())?.guid;
        return (
            <div>
                <DeleteWarning
                    integrationName={chosenName}
                    integrationGuid={integrationGuid}
                    closeWarningModal={closeWarningModal}
                    showSnackBar={showSnackBar}
                />
            </div>
        );
    }, [chosenType, chosenName]);

    const integrationExists = (type: string) => {
        return integrations.find((integration) => integration.type === type);
    };

    return (
        <>
            <Modal
                open={openWarningModal}
                onClose={() => setOpenWarningModal(false)}
                BackdropProps={{ onClick: () => setOpenWarningModal(false) }}
                children={getDeleteModal()}
            />
            <DataIntegrationsListContainer>
                {integrationsArray.map((integration, i) => {
                    const userIntegration = integrationExists(integration.type);
                    return (
                        (isAdminMode || integration.isGa || accountFeatureEnabled(integration.featureFlag)) && (
                            <IntegrationCard key={i}>
                                <IntegrationCardContent>
                                    <div className={'integration-title'}>
                                        <IntegrationLogoContainer>
                                            <img
                                                src={retrieveIntegrationsIcon(integration.type)}
                                                alt={integration.name}
                                                style={{ height: '28px', width: '28px' }}
                                            />
                                        </IntegrationLogoContainer>
                                        <IntegrationName>{integration.name}</IntegrationName>
                                    </div>
                                    <IntegrationInfo>
                                        <div style={{ color: '#637191' }}>
                                            Enable this {integration.name} Integration to use AI Answers within your {integration.name}{' '}
                                            channels.
                                        </div>
                                    </IntegrationInfo>
                                </IntegrationCardContent>
                                <IntegrationCardFooter>
                                    <EnableButton
                                        onClick={() => handleButtonClick(integration.type, integration.name)}
                                        enabled={!!userIntegration}>
                                        {userIntegration ? (
                                            <div className={'enable-button'} style={{ gap: '7px', height: '16px' }}>
                                                <img src={CheckedIcon} />
                                                <div>Enabled</div>
                                            </div>
                                        ) : (
                                            <div className={'enable-button'} style={{ height: '16px' }}>
                                                Enable
                                            </div>
                                        )}{' '}
                                    </EnableButton>
                                </IntegrationCardFooter>
                            </IntegrationCard>
                        )
                    );
                })}
            </DataIntegrationsListContainer>
        </>
    );
};

export default IntegrationsList;
