import React from 'react';
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { WMIconCheck } from '@walkme/wm-ui';
import { useTranslation } from 'apps/home/src/localization';
import { DialogTitle, DialogInfo, RoleTitle, RoleInfo, TableHeader, StyledDialog } from './predefined-roles-info-table.styles';

const StyledWMIconCheck = <WMIconCheck color='var(--wmPrimary)' />;

const StickyTableCell = withStyles((theme) => ({
    head: {
        left: 0,
        position: 'sticky',
        zIndex: theme.zIndex.appBar + 2,
        background: '#fff',
    },
    body: {
        padding: '8px',
        left: 0,
        position: 'sticky',
        background: '#fff',
        zIndex: theme.zIndex.appBar + 1,
    },
}))(TableCell);

const StyledTableCell = styled(TableCell)`
    align: center;
    user-select: none;
`;

const StyledTableContainer = styled(TableContainer)`
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #f5f5f5;
        border-radius: 10px;
    }
    ::-webkit-scrollbar {
        height: 6px;
        background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #8fa5f1;
        border-radius: 10px;
        &:hover {
            background-color: #728eed;
        }
    }
`;

const rolesInfoheadCells = [
    { title: 'Roles' },
    { title: 'Admin Center' },
    { title: 'Edit Content' },
    { title: 'View Content' },
    { title: 'Publish Content to all Environments' },
    { title: 'Publish Settings to all Environments' },
    { title: 'Publish Content to all Environments except Production' },
    { title: 'Insights' },
    { title: 'Languages' },
];

const findPermission = (rolePermissions, key, permissionAction) => {
    const action = rolePermissions.find((e) => e.actionKey === key);
    if (action && action.permission === permissionAction) {
        return StyledWMIconCheck;
    }
};

export const PredefinedRolesInfoTable = ({ open, onClose, roles, rolesOrder }) => {
    const { t, rt } = useTranslation('general');
    const getData = (roles) => {
        roles.sort((a, b) => rolesOrder.indexOf(a.id) - rolesOrder.indexOf(b.id));
        const rows = roles.length
            ? roles.map((role) => ({
                  roleName: (
                      <Box display='flex' flexDirection='column' justifyContent='space-between' style={{ width: '250px' }}>
                          <RoleTitle>{rt(role.displayName)}</RoleTitle>
                          <RoleInfo dangerouslySetInnerHTML={{ __html: rt(role.shortDescription) }} />
                      </Box>
                  ),
                  adminCenter: findPermission(role.permissions, 'Management', 'Write'),
                  editContent: findPermission(role.permissions, 'WorkSpace', 'Write'),
                  viewContent:
                      findPermission(role.permissions, 'WorkSpace', 'Write') || findPermission(role.permissions, 'WorkSpace', 'Read'),
                  publishContent:
                      findPermission(role.permissions, 'Publish', 'Write') ||
                      findPermission(role.permissions, 'Publish', 'PublishAndUpdate'),
                  publishSettings: findPermission(role.permissions, 'PublishSettings', 'Write'),
                  publishExcludeProd: findPermission(role.permissions, 'PublishToAllEnvs', 'Read'),
                  insights: findPermission(role.permissions, 'Analytics', 'Write'),
                  languages: findPermission(role.permissions, 'EditLanguages', 'Write'),
              }))
            : [];
        return rows;
    };

    const data = getData(roles);
    return (
        <StyledDialog onClose={onClose} showDialog={open}>
            <Grid container item xs={12}>
                <Grid container alignItems='flex-start' direction='row' justifyContent='space-between'>
                    <Grid item>
                        <DialogTitle>{t('users-and-roles-tab.roles.title')}</DialogTitle>
                    </Grid>
                    <Grid container justifyContent='flex-end' alignItems='baseline'>
                        {
                            <IconButton onClick={onClose}>
                                <img src='assets/icons/close.svg' alt='close' style={{ height: '12px' }} />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                <DialogInfo>{t('users-and-roles-tab.roles.predefined-roles-tab.predefined-roles-info-tooltip')}</DialogInfo>
                <Grid item xs={12}>
                    <StyledTableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {rolesInfoheadCells.map((header, index) =>
                                        header.title === 'Roles' ? (
                                            <StickyTableCell key={`${header.title}-${index}`}>
                                                <TableHeader>{header.title}</TableHeader>
                                            </StickyTableCell>
                                        ) : (
                                            <TableCell key={`${header.title}-${index}`}>
                                                <Box
                                                    style={{
                                                        display: 'inline-block',
                                                        minWidth: '60px',
                                                    }}>
                                                    <TableHeader>{header.title}</TableHeader>
                                                </Box>
                                            </TableCell>
                                        )
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={`${row.roleName}-${index}`}>
                                        <StickyTableCell component='th' scope='row'>
                                            {row.roleName}
                                        </StickyTableCell>
                                        <StyledTableCell>{row.adminCenter}</StyledTableCell>
                                        <StyledTableCell>{row.editContent}</StyledTableCell>
                                        <StyledTableCell>{row.viewContent}</StyledTableCell>
                                        <StyledTableCell>{row.publishContent}</StyledTableCell>
                                        <StyledTableCell>{row.publishSettings}</StyledTableCell>
                                        <StyledTableCell>{row.publishExcludeProd}</StyledTableCell>
                                        <StyledTableCell>{row.insights}</StyledTableCell>
                                        <StyledTableCell>{row.languages}</StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </Grid>
            </Grid>
        </StyledDialog>
    );
};

export default PredefinedRolesInfoTable;
