import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import { Checkbox } from '../checkbox/checkbox';
import { TableHeadCell, Order } from '@walkme-admin-center/libs/types';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
    padding: 0 26px;
    cursor: pointer;
    font-family: 'ProximaNova';
`;

interface TableHeadProps {
    onRequestSort?: (event: React.MouseEvent<unknown>, property: unknown) => void;
    order?: Order;
    orderBy?: string;
    rowCount?: number;
    heads?: TableHeadCell[];
    rowsSelectable?: boolean;
    allRowsSelected?: boolean;
    rowsSelectableDisabled?: boolean;
    indeterminateSelectedRows?: boolean;
    hideSort?: boolean;
    onAllRowsToggled?: () => void;
}

export const Head = (props: TableHeadProps) => {
    const {
        order,
        orderBy,
        onRequestSort,
        heads,
        rowsSelectable,
        allRowsSelected,
        rowsSelectableDisabled,
        indeterminateSelectedRows,
        onAllRowsToggled,
        hideSort,
    } = props;
    const createSortHandler = (property: unknown) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow style={{ height: '40px' }}>
                {rowsSelectable && (
                    <TableCell align="left">
                        <Checkbox
                            onChange={onAllRowsToggled}
                            checked={allRowsSelected}
                            indeterminate={indeterminateSelectedRows}
                            disabled={rowsSelectableDisabled}
                        />
                    </TableCell>
                )}
                {heads &&
                    heads.map((headCell) => {
                        return (
                            <StyledTableCell
                                key={headCell.id}
                                align="left"
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={hideSort ? null : createSortHandler(headCell.id)}
                                    style={{ fontFamily: 'ProximaNova', fontSize: '12px', color: '#637191' }}
                                    hideSortIcon={hideSort}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span style={{ display: 'none' }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </StyledTableCell>
                        );
                    })}
            </TableRow>
        </TableHead>
    );
};

export default Head;
