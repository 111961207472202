import React, { useState, MouseEvent, useRef } from 'react';
import { DropdownChevronDisplayOptions } from '@walkme/ui-core';
import {
    StyledSelector,
    StyledMenuItem,
    StyledLanguageMenu,
    StyledLanguageMenuWrapper,
    StyledWMMenu,
    StyledSelectorDiv,
    StyledButtonText,
    StyledInfoIcon,
} from './language-detector-selector.styles';
import { DropdownOption, DetectorOptions } from './consts';
import { Check } from '@walkme/ui-icons/large';
import { useTranslation } from '../../../../../../../apps/home/src/localization';

export interface DetectorSelectorProps {
    title: string;
    translationPrefix: string;
    selectedDetector: DropdownOption;
    detectorOptions: DropdownOption[];
    setSelectedDetector: (detector: DropdownOption) => void;
}

export function LanguageDetectorSelector({
    title,
    translationPrefix,
    selectedDetector,
    detectorOptions,
    setSelectedDetector,
}: DetectorSelectorProps) {
    const { t } = useTranslation('general');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [detectorInContext, setDetectorInContext] = useState(selectedDetector);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const handleChangeSelection = (selection: DropdownOption) => {
        setSelectedDetector(selection);
        setDetectorInContext(selection);
        handleClose();
    };

    const handleItemHover = (e) => {
        const hoveredValue = e.target.id;
        if (hoveredValue !== detectorInContext.value) {
            const hoveredElement = DetectorOptions.find((element) => element.value == hoveredValue);
            if (hoveredElement) {
                setDetectorInContext(hoveredElement);
            }
        }
    };

    const SelectorDisplayedValue = (
        <StyledSelectorDiv>
            <StyledButtonText>{selectedDetector ? t(`${translationPrefix}.${selectedDetector.value}.label`) : ''}</StyledButtonText>
        </StyledSelectorDiv>
    );

    const chevronDisplayOption = DropdownChevronDisplayOptions.DEFAULT;

    return (
        <StyledLanguageMenuWrapper>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
                label={title}
            />
            <StyledWMMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={600}>
                <StyledLanguageMenu>
                    <div className='menu-window'>
                        {detectorOptions.map((selection: DropdownOption, index) => {
                            return (
                                <StyledMenuItem
                                    id={selection.value}
                                    isSelected={selection.value === selectedDetector?.value}
                                    onClick={() => handleChangeSelection(selection)}
                                    onMouseOver={handleItemHover}>
                                    <span onMouseOver={handleItemHover} id={selection.value}>
                                        {t(`${translationPrefix}.${selection.value}.label`)}
                                    </span>
                                    {selectedDetector?.value === selection.value ? <Check color='#395eea' /> : null}
                                </StyledMenuItem>
                            );
                        })}
                    </div>
                    <div className='description-window'>
                        <StyledInfoIcon />
                        <div className='title'>
                            <b>{t(`${translationPrefix}.${detectorInContext.value}.label`)}</b>
                        </div>
                        <p className='description'>
                            {t(`${translationPrefix}.${detectorInContext.value}.description`)}
                            {detectorInContext.link && (
                                <a href={detectorInContext.link} target='_blank'>
                                    {t(`${translationPrefix}.${detectorInContext.value}.link`)}
                                </a>
                            )}
                        </p>
                    </div>
                </StyledLanguageMenu>
            </StyledWMMenu>
        </StyledLanguageMenuWrapper>
    );
}
