import React from 'react';
import { muiTheme } from '@walkme-admin-center/libs/shared-styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { AuthProvider } from 'wm-accounts-auth';
import App from './app';

export const Root = () => {
    return (
        <AuthProvider>
            <MuiThemeProvider theme={muiTheme}>
                <App />
            </MuiThemeProvider>
        </AuthProvider>
    );
};

export default Root;
