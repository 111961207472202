import styled from 'styled-components';

export const CssIntegrationRow = styled.div<{ isHover: boolean }>`
    display: flex;
    height: var(--size-global-s-9048, 48px);
    padding: var(--spacers-global-sp-2, 2px) 24px var(--spacers-global-sp-2, 2px) var(--spacers-global-sp-3012, 12px);
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
    background: var(--primaries-white-white-main, #fff);
    /* Table/Default */
    box-shadow: ${(props) => ` 0px ${props.isHover ? '-2px' : '-1px'} 0px 0px rgba(245, 246, 248, 1)`} inset;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CssCheckBox = styled.input`
    width: 14px;
    height: 14px;
    padding: 3px;
    border-radius: 3px;
    border: 1px solid var(--icons-icon-main, #6d81a6);
    &:indeterminate {
        background-color: #e4e9fc;
        color: #385feb;
    }
`;

export const CssArrowIcon = styled.img<{ collapsed: boolean }>`
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 2px;
    transform: ${(props) => (props.collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
    ${(props) =>
        props.collapsed &&
        `
    border-radius: 50%;
    background: rgba(59, 97, 235, 0.05);
  `}
    &:hover {
        opacity: 0.9;
    }
`;

export const CssIntegrationData = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 0;
`;

export const NameContainer = styled.div`
    display: flex;
    width: 364px;
    height: var(--size-global-s-8040, 40px);
    padding: var(--spacers-global-sp-208, 8px) var(--spacers-global-sp-3012, 12px) 7px 7px;
    align-items: center;
    gap: var(--spacers-global-sp-208, 8px);
    flex-shrink: 0;
    color: var(--typography-typography-main, #2f426c);
    /* Texts/t2/walk-me-t2-2-br0 */
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

export const LastSyncContainer = styled.div`
    display: flex;
    width: 180px;
    height: 44px;
    padding: 0px 12px;
    align-items: center;
    color: var(--typography-typography-main, #2f426c);
    /* Texts/t2/walk-me-t2-br0 */
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export const SyncStatusContainer = styled.div`
    display: flex;
    width: 180px;
    height: var(--size-global-s-8040, 40px);
    padding: var(--spacers-global-sp-208, 8px) var(--spacers-global-sp-3012, 12px) 7px var(--spacers-global-sp-3012, 12px);
    align-items: center;
    gap: var(--spacers-global-sp-208, 4px);
    flex-shrink: 0;
    color: var(--typography-typography-main-primary-main, #2f426c);
    /* Texts/t2/walk-me-t2-br0-x.h. */
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 8px; /* 57.143% */
`;

export const CreatedConatiner = styled.div`
    display: flex;
    width: 180px;
    height: var(--size-global-s-8040, 40px);
    padding: var(--spacers-global-sp-208, 8px) var(--spacers-global-sp-3012, 12px) 7px var(--spacers-global-sp-3012, 12px);
    align-items: center;
    gap: var(--spacers-global-sp-208, 8px);
    color: var(--typography-typography-main, #2f426c);
    /* Texts/t3/walk-me-t3-br0 */
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const ActionContainer = styled.div`
    display: flex;
    height: var(--size-global-s-9048, 48px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

export const SystemsContainer = styled.div`
    display: flex;
    height: var(--size-global-s-8040, 40px);
    padding: 0px 24px 0px 72px;
    margin-right: 4px;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
    border-bottom: 1px solid var(--blue-primary-primary-tint-6, #e4e9fc);
    background: var(--background-background-tint-4, #f9fbfd);
`;

export const SystemRowContainer = styled.div`
    display: flex;
    height: var(--size-global-s-8040, 40px);
    align-self: stretch;
    padding: 0px 24px 0px 72px;
    align-items: center;
    border-bottom: 1px solid var(--blue-primary-primary-tint-6, #e4e9fc);
    background: var(--background-background-tint-4, #f9fbfd);
`;

export const SystemTopRow = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 0;
    color: var(--typography-typography-tint-1, #637191);

    /* Texts/t3/walk-me-t3-br0 */
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;
export const SystemTypeContainer = styled.div`
    display: flex;
    width: 100px;
    height: 40px;
    padding: 12px 7px 0px;
    align-items: center;
    gap: 8px;
`;

export const SystemNameContainer = styled.div`
    display: flex;
    width: 232px;
    align-items: flex-start;
`;

export const SystemStatusContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const SystemCheckBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 0px 4px;
`;
export const SystemIconContainer = styled.div`
    display: flex;
    width: 100px;
    min-width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;
