import React from 'react';
import { StyledUUIDContainer, StyledUUIDTitle } from './uuid.styles';
import { UuidSelector } from './uuid-selector';
import { DropdownOption, UUIDOptions, UUID as UUIDEnum } from './uuid.lib';
import { useSelector, useDispatch } from 'react-redux';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import { UuidBody } from './uuid-body';
import { IdpSelectionDialog } from './idp-selection-dialog';
import { getTextJsx } from './uuid.lib';
import useGetProviders from '../../../../../../../../../libs/state-management-systems/src/lib/hooks/use-get-providers';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

export const UUID = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const selectedUUID = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedUUID);
    const { loggedInUserAppData } = useLoggedInUser();
    const isImpersonator = !!loggedInUserAppData?.data?.impersonator;
    const { providers } = useGetProviders(isImpersonator);
    const idpOptionIsDisabled = !providers?.data || providers?.data?.length === 0;
    const disabledUUIDs = [];
    if (idpOptionIsDisabled) {
        disabledUUIDs.push(UUIDEnum.IDP);
    }

    const setSelectedUUID = (UUID: DropdownOption) => {
        dispatch(createSystemSlice.actions.setSelectedUUID(UUID));
        dispatch(createSystemSlice.actions.resetUUIDBody());
    };

    const learnMore = getTextJsx(
        t('systems-tab.new-system-form.configure-step.uuid.learn-more.text'),
        'systems-tab.new-system-form.configure-step.uuid.learn-more.links',
        t
    );

    return (
        <StyledUUIDContainer>
            <StyledUUIDTitle>
                <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.uuid.main-title`)}</div>
                <div className='sub-title'>
                    <div>{t(`systems-tab.new-system-form.configure-step.uuid.sub-title`)}</div>
                    <div className='learn-more'>{learnMore}</div>
                </div>
            </StyledUUIDTitle>
            <UuidSelector
                selectedUUID={selectedUUID}
                UUIDOptions={UUIDOptions}
                setSelectedUUID={setSelectedUUID}
                disabledUUIDs={disabledUUIDs}
            />
            <UuidBody uuidType={selectedUUID.value} />
            <IdpSelectionDialog />
        </StyledUUIDContainer>
    );
};
