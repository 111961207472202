import React from 'react';
import { StyledSelectContractContainer } from './select-contract.styles';
import { ContractList } from './contract-list/contract-list';
import { ContractDetails } from './contract-details/contract-details';
import { CompleteSystemCreationDialog } from './complete-system-creation-dialog';

export const SelectContractStep = () => {
    return (
        <StyledSelectContractContainer>
            <ContractList />
            <ContractDetails />
            <CompleteSystemCreationDialog />
        </StyledSelectContractContainer>
    );
};
