import React from 'react';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme, Typography, Card, Grid } from '@material-ui/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Button } from '@walkme-admin-center/libs/ui-components';

const StyledCard = styled(Card)`
    padding: 0 30px;
    box-sizing: border-box;
    height: 250px;
`;

const StyledGridContainer = styled(Grid)`
    min-height: 250px;
`;

const StyledGridItem = styled(Grid)`
    text-align: center;
`;

const StyledText = styled(Typography)`
    color: #5E5E5E;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
`;

const useStyles = makeStyles(() => createStyles({
    button: {
        width: '200px',
        marginTop: '20px'
    }
}));

interface NoDesktopTrackedAppsProps {
    onClick: () => void;
};

const NoDesktopTrackedApps = ({ onClick }: NoDesktopTrackedAppsProps) => {
    const classes = useStyles();

    return (
        <StyledCard>
            <StyledGridContainer
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                spacing={0}
                style={{ minHeight: '250px' }}
            >
                <StyledGridItem item xs={4} >
                    <div>
                        <img src="assets/icons/icon-app-window-add.svg" alt="" />
                    </div>
                    <StyledText>
                        No Desktop Apps Added yet
                    </StyledText>
                    <Button customClass={`main ${classes.button}`} size='large' onClick={onClick}>
                        + Add desktop app
                    </Button>
                </StyledGridItem>
            </StyledGridContainer>
        </StyledCard>
    );
};

export default NoDesktopTrackedApps;
