import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTooltip } from './tool-tip.styles';

export const toolTipUseStyles = makeStyles({
    tooltip: {
        fontSize: '12px',
        background: '#2F3D53',
        padding: '8px 12px',
    },
    arrow: {
        color: '#2F3D53',
    },
});

export interface CustomToolTipProps {
    title: string | JSX.Element;
    disableHoverListener?: boolean;
    children: any;
    placement?: 'top' | 'bottom' | 'top-start' | 'right' | 'right-start' | 'top-end';
    arrow?: boolean;
    tooltipContent?: JSX.Element;
    maxWidth?: number;
}

export const CustomToolTip = ({
    title,
    disableHoverListener = false,
    children,
    placement = 'top',
    arrow = false,
    tooltipContent,
    maxWidth,
}: CustomToolTipProps) => {
    return (
        <StyledTooltip
            classes={{ tooltip: 'tooltip', arrow: 'arrow' }}
            title={title}
            disableHoverListener={disableHoverListener}
            children={children}
            placement={placement}
            arrow={arrow}
            tooltipContent={tooltipContent}
            maxWidth={maxWidth}
        />
    );
};
export default CustomToolTip;
