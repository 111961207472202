import { Box, Typography } from '@material-ui/core';
import { RoleRow } from '@walkme-admin-center/libs/types';
import {
    WMIconDelete,
    WMButton,
    WMButtonVariant,
    WMIconEdit,
    WMTagList,
    WMTagProps,
    WMTooltipProps,
    WMTagVariant,
    WMTooltip,
} from '@walkme/wm-ui';
import React from 'react';
import styled from 'styled-components';
import { Role, User, Actions, Action } from 'wm-accounts-sdk';
import { actionsColors, findTranslatedPermissionDisplayName } from './color-generator';
import { ActionsGraph } from './create-actions-graph';
import { RTFunction } from 'apps/home/src/localization';

const StyledWmTagList = styled(WMTagList)`
    .MuiChip-label {
        padding: 4px 8px !important;
        font-size: 12px;
        font-weight: 450;
    }
    .WMTag-old.WMTag-fullLabel {
        max-width: 130px;
    }
`;

const StyledWMIconDelete = styled(WMIconDelete)`
    color: var(--wmGrayDark);

    &:hover {
        color: var(--wmError);
    }
`;

const StyledDeleteButton = styled(WMButton)`
    opacity: 0;
    margin-left: auto;
`;

const StyledEditButton = styled.div`
    height: 48px;
    display: flex;
    justify-content: end;
    align-items: center;
    opacity: 0;
`;

const deleteTooltipProps = {
    title: 'Delete Role',
    placement: 'top',
} as WMTooltipProps;

export const getRolesRows = (
    rt: RTFunction,
    roles: Role[],
    rolesCount: Record<number, number>,
    actions: ActionsGraph,
    classes,
    isEditable?: boolean,
    deletedRoleAppData?,
    onConfirmDeleteRole?
): RoleRow[] => {
    if (!roles || roles.length === 0) return [];
    const rolesRows = roles.map((item) => {
        const { displayName, id, permissions, accountId, description } = item;
        const usersNum = rolesCount[id] || 0;
        const actionsLabels: WMTagProps[] = [];

        const translatedDisplayName = rt(displayName);
        const translatedDescription = rt(description);

        for (const permission of permissions) {
            const permissionTranslatedDisplayName = findTranslatedPermissionDisplayName(rt, permission, permission.permission); // returns translated value
            if (!permissionTranslatedDisplayName) continue;

            const actionName = actions.find((action) => action.key === permission.actionKey)?.displayName;
            const actionTranslatedName = actionName ? rt(actionName) : actionName;

            const labelText = `${actionTranslatedName}: ${permissionTranslatedDisplayName}`;
            actionsLabels.push({
                labelText,
                fullLabel: true,
                fitToText: true,
                variant: WMTagVariant[actionsColors[labelText]],
            });
        }

        return {
            roleId: id,
            accountId,
            role: {
                value: translatedDisplayName,
                displayValue: (
                    <Box style={{ display: 'flex', flexDirection: 'column', margin: '18px 0px' }}>
                        <span className={classes.roleRowName}>{translatedDisplayName}</span>
                        {translatedDescription ? <span className={classes.roleDescription}>{translatedDescription}</span> : null}
                    </Box>
                ),
            },
            permissions: {
                value: actionsLabels.join(','),
                count: actionsLabels.length,
                displayValue: (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <StyledWmTagList labels={actionsLabels} maxTagsShown={4} />
                        {isEditable ? (
                            <StyledEditButton className={'editButton'}>
                                <WMIconEdit color='var(--wmGrayDark)' size={16} />
                            </StyledEditButton>
                        ) : null}
                    </Box>
                ),
            },
            users: {
                value: String(usersNum),
                displayValue: <span className={classes.roleRowUsersCounter}>{usersNum}</span>,
            },
            deleteButton: isEditable && {
                value: 'deleteButton',
                displayValue: (
                    <StyledDeleteButton
                        className={'deleteButton'}
                        variant={WMButtonVariant.Text}
                        isIconButton
                        loading={deletedRoleAppData && deletedRoleAppData.loading}
                        disabled={deletedRoleAppData && deletedRoleAppData.loading}
                        iconComponent={<StyledWMIconDelete />}
                        onClick={(e) => {
                            e.stopPropagation();
                            onConfirmDeleteRole({ id, translatedDisplayName });
                        }}
                        tooltipProps={deleteTooltipProps}
                    />
                ),
            },
        };
    });

    return rolesRows.sort((a, b) => b.permissions.count - a.permissions.count);
};

export const countRoleUsers = (users: User[]): Record<number, number> => {
    const map: Record<number, number> = {};

    for (const user of users) {
        if (user.role) {
            map[user.role.id] ? map[user.role.id]++ : (map[user.role.id] = 1);
        }
    }

    return map;
};

export const StyledWMTooltip = styled(WMTooltip)`
    width: 100%;
    background-color: #fbfcff;
    .WMTooltip-htmlWrapper {
        width: 270px;
    }
`;

const mapPermissionsToLabels = (rt: RTFunction, permissions, actions: Actions) => {
    const actionsLabels: WMTagProps[] = [];

    for (const permission of permissions) {
        const translatedPermissionDisplayName = findTranslatedPermissionDisplayName(rt, permission, permission.permission); // returns translated value
        if (!translatedPermissionDisplayName) continue;
        const actionName = actions.find((action) => action.key === permission.actionKey)?.displayName;
        const labelText = `${rt(actionName)}: ${translatedPermissionDisplayName}`;
        actionsLabels.push({
            labelText,
            fullLabel: true,
            fitToText: true,
            variant: WMTagVariant[actionsColors[labelText]],
        });
    }

    return actionsLabels;
};

export const roleInfo = (rt: RTFunction, role: Role, actions: Actions) => (
    <Box style={{ width: '270px' }}>
        <img src='assets/icons/icon-info.svg' alt='infoIcon' style={{ height: '13px' }} />
        <Typography style={{ color: '#637191', marginTop: '13', fontWeight: 600 }}>{rt(role.displayName)}</Typography>
        <Typography style={{ color: '#8D97AE' }} dangerouslySetInnerHTML={{ __html: rt(role.shortDescription) }} />
        <Typography style={{ color: '#637191', marginTop: '20px', fontWeight: 600, paddingLeft: '3px' }}>Permissions included:</Typography>
        {!role.permissions.every((action) => action?.actionPermissions?.length === 0) ? (
            <StyledWmTagList
                labels={mapPermissionsToLabels(rt, role.permissions, actions)}
                maxTagsShown={15}
                style={{ flexWrap: 'wrap' }}
            />
        ) : (
            <Typography style={{ color: '#8D97AE', paddingLeft: '3px' }}>All permissions are disabled</Typography>
        )}
    </Box>
);

export const mapRoleToRow = (rt: RTFunction, role: Role, anchorEl, actions: Actions) => {
    if (!role) return;
    return {
        label: (
            <StyledWMTooltip
                variant={'light'}
                title={roleInfo(rt, role, actions)}
                placement={'right-start'}
                PopperProps={{ anchorEl: anchorEl }}>
                <Typography style={{ padding: '10px 16px' }}>{rt(role.displayName)}</Typography>
            </StyledWMTooltip>
        ),
        value: role.id,
    };
};
