import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ToasterVariant } from '@walkme/ui-core';
import { useTranslation } from '../../../../../../../../../apps/home/src/localization/localizationBase';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSystemSlice,
    CreateSystemState,
    IMPERSONATION_PASSWORD_ERROR,
    IMPERSONATION_PASSWORD_ERROR_TYPE,
    ImpersonationPasswordError,
    ImpersonationState,
} from '@walkme-admin-center/libs/state-management-systems';
import { DoneSVG as DoneCheck } from './assets/DoneSVG';
import {
    StyledDataSettingsAndAccessibilityContainer,
    StyledExtensionContainer,
    StyledPrimaryButton,
    StyledSaveSection,
    StyledSaveSections,
    StyledSelectEnvironment,
    StyledToasterContainer,
} from './save-and-publish-dialog.styles';
import { SelectEnvironment } from './select-environment';
import useSystemExtensionData from '../../../../../../../../libs/state-management-systems/src/lib/hooks/use-system-extension-data';
import { DeploymentMethods } from '../select-deployment/select-deployment.lib';
import {
    generateConfigurationString,
    handleSaveAndFinishDeploymentMethod,
    handleSaveDefaultDeploymentMethod,
} from '../../create-system-dialog/create-system-dialog.lib';
import { siteConfigApi } from '../../../api/site-config-api';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { DataCollectionLevel, generateUserFeaturesToUpdateDataCollection } from './data-collection/data-collection.lib';
import { SystemPurpose } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { UUID } from './uuid/uuid.lib';
import { defaultEnvironmentSelection } from './save-and-publish-dialog.lib';
import { analyticsApi } from '@walkme-admin-center/libs/analytics-api';
import { EnvironmentData, GetSystemDataResult } from '@walkme-admin-center/libs/exposure-api';
import { Environment } from '../../../../../../extension-pages/src/lib/types';
import { useWebSystem } from '@walkme-admin-center/libs/state-management-extensions';
import { getSuccessSaveDialogMessageBasedOnExtensionCacheTime } from '@walkme-admin-center/pages/home/extension-pages';
import { NotificationMessage } from '@walkme-admin-center/libs/types';

export const SystemCreationSaveAndPublishDialog = ({
    onSuccess = () => {},
    onFailure = () => {},
}: {
    onSuccess: (message: string) => void;
    onFailure: (messsage: string) => void;
}) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const [primaryActionButtonIsLoading, setPrimaryActionButtonIsLoading] = useState<boolean>(false);
    const [selectedEnvironmentToPublish, setSelectedEnvironmentToPublish] = useState(defaultEnvironmentSelection);

    const { loggedInUserAppData } = useLoggedInUser();
    const isImpersonated = !!loggedInUserAppData?.data?.impersonator;
    const impPassword = useSelector((state: { impersonationState: ImpersonationState }) => state.impersonationState.currentPassword);

    const saveAndPublishDialogIsOpen = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.saveAndPublishDialogIsOpen
    );
    const selectedUUID = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedUUID);
    const selectedDataCollectionLevel = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedDataCollectionLevel
    );

    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const { systemExtensionData } = useSystemExtensionData(createdSystem?.id);
    const [environmentOptions, setEnvironmentOptions] = useState([]);
    const selectedExpectedFormat = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedExpectedFormat
    );
    const regexpInputValue = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.regexpInputValue);
    const accessibilityFeatureIsOn = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.accessibilityFeatureIsOn
    );
    const accessibilityDefaultMasterFeatureOnAccount = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.accessibilityDefaultMasterFeatureOnAccount
    );
    const accessibilityDefaultMasterFeatureIsOnByUser =
        accessibilityDefaultMasterFeatureOnAccount?.modifiedBy === loggedInUserAppData?.data?.email;
    const automaticEnableAccessibilityFeature = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.automaticEnableAccessibilityFeature
    );
    const selectedUUIDVariableValue = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedUUIDVariableValue
    );
    const selectedDeploymentMethod = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedDeploymentMethod
    );
    const extensionAsDefaultSelected = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.extensionAsDefaultSelected
    );
    const extensionIsDefaultDeploymentMethod = localStorage.getItem('extensionIsDefaultDeploymentMethod');
    const originalMatchers = systemExtensionData?.systemData?.matchers;

    const environmentMatchers = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.environmentMatchers
    );

    const { webSystemAppData } = useWebSystem(createdSystem?.id);
    const [extensionCacheTimeInSeconds, setExtensionCacheTimeInSeconds] = useState<number>(900);
    const setNotificationMessage = ({ text, variant, isOpen }: NotificationMessage) => {
        dispatch(createSystemSlice.actions.setNotificationMessage({ text, variant, isOpen }));
    };

    const mapEnvironmentToDropdownOption = (environment) => ({
        value: environment.value,
        label: environment.label,
        id: environment.value as number,
    });

    const publishToEnvironment = ({ systemId, envName, environmentId, userId }) => {
        return new Promise(async (resolve, reject) => {
            const publishResponse = await siteConfigApi.updateAndPublish({
                systemId: createdSystem?.id,
                userId,
                isImpersonated,
                impPassword,
                params: { checkedItemsIds: '[]', envName, environmentId },
            });
            const trackingId = publishResponse?.data?.content?.id;
            if (!trackingId) {
                reject('No tracking id returned from publish request');
            }
            let publishStatusCheck = setInterval(async () => {
                try {
                    const statusResponse = await siteConfigApi.publishStatusCheck({ trackingId, systemId: createdSystem?.id });

                    if (statusResponse?.data?.content?.status === 'done') {
                        clearInterval(publishStatusCheck);
                        resolve('success');
                    }

                    if (statusResponse?.data?.content?.status === 'failed') {
                        clearInterval(publishStatusCheck);

                        if (isImpersonationPasswordError(statusResponse?.data)) {
                            reject(t('notifications.impersonated-publish-fail'));
                        } else {
                            reject(t('notifications.publish-fail'));
                        }
                    }
                } catch (e) {
                    clearInterval(publishStatusCheck);
                    reject('Failed to check publish status');
                }
            }, 2000);
        });
    };

    const isImpersonationPasswordError = (publishStatus: any): boolean => {
        const tasks = (publishStatus?.content?.tasks as Array<any>) || [];
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            if (task.status === 'failed') {
                if (task.errors?.length && task.errors[0].errorType === IMPERSONATION_PASSWORD_ERROR_TYPE) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleOnClose = () => {
        dispatch(createSystemSlice.actions.setSaveAndPublishDialogIsOpen(false));
        setPrimaryActionButtonIsLoading(false);
    };

    const handleOnFinishPublish = () => {
        dispatch(createSystemSlice.actions.resetCreateSystemState());
        setPrimaryActionButtonIsLoading(false);
    };

    interface PublishEnvResult {
        success: boolean;
        reason?: string;
    }

    const publishEnv = async (): Promise<PublishEnvResult> => {
        // const publishPromises = [];

        const envs = [];
        if (selectedEnvironmentToPublish?.value === 'prod_test') {
            envs.push(environmentOptions.find((env) => env.label === 'Production'));
            envs.push(environmentOptions.find((env) => env.label === 'Test'));
        } else {
            envs.push(selectedEnvironmentToPublish);
        }

        const publishPromises = envs.map((environment) =>
            publishToEnvironment({
                systemId: createdSystem?.id,
                userId: loggedInUserAppData?.data?.id,
                envName: environment.label?.toLowerCase(),
                environmentId: environment.value,
            })
        );

        // @ts-ignore
        const publishPromisesResults = await Promise.allSettled(publishPromises);
        for (let result of publishPromisesResults) {
            if (result.status === 'rejected') {
                return {
                    success: false,
                    reason: result.reason,
                } as PublishEnvResult;
            }
        }

        return { success: true } as PublishEnvResult;
    };

    const handleSaveAndFinishCreateSystemFlow = useCallback(async () => {
        setPrimaryActionButtonIsLoading(true);

        try {
            // save deployment method changes
            if (selectedDeploymentMethod === DeploymentMethods.EXTENSION) {
                handleSaveDefaultDeploymentMethod({ extensionAsDefaultSelected, extensionIsDefaultDeploymentMethod });

                await handleSaveAndFinishDeploymentMethod({
                    originalMatchers,
                    environmentMatchers,
                    accountId: loggedInUserAppData?.data?.account!.id,
                    systemId: createdSystem?.id,
                });
            }

            // save uuid changes

            const siteConfigurationRes = await siteConfigApi.getUserConfiguration(createdSystem?.id);
            const siteConfiguration = siteConfigurationRes?.SiteConfiguration;

            const modifiedEndUserSettings = generateConfigurationString({
                currentEndUserSettings: siteConfiguration?.EndUserSettings,
                uuidType: selectedUUID.value,
                variableParameter: selectedUUIDVariableValue,
                expectedFormat: selectedExpectedFormat.value,
                regexp: regexpInputValue,
            });

            const finalSiteConfig = { ...siteConfiguration, EndUserSettings: modifiedEndUserSettings };

            const saveSiteConfigRes = await siteConfigApi.saveSiteConfiguration({
                configurationString: JSON.stringify(finalSiteConfig),
                systemId: createdSystem?.id,
                configurationData: {
                    uuidType: selectedUUID.label,
                    variableParameter: selectedUUIDVariableValue,
                    expectedFormat: selectedExpectedFormat.value,
                },
                isImpersonated,
                impPassword,
            });

            if (saveSiteConfigRes?.data?.ErrorObject && saveSiteConfigRes.data.ErrorMessage == IMPERSONATION_PASSWORD_ERROR) {
                throw new ImpersonationPasswordError();
            }
            if (!((saveSiteConfigRes?.data?.Success as string) === 'Settings saved')) {
                throw new Error("Couldn't save site configuration");
            }

            // save data collection changes

            const userFeaturesToUpdate = generateUserFeaturesToUpdateDataCollection({ selectedDataCollectionLevel });
            await siteConfigApi.updateUserFeatures({
                systemId: createdSystem?.id,
                updatedUserFeatures: userFeaturesToUpdate,
                userId: loggedInUserAppData?.data?.id,
            });

            // save accessibility changes

            // user turned off accessibility on this system , although it's enabled on account as default
            if (accessibilityDefaultMasterFeatureOnAccount && !accessibilityFeatureIsOn) {
                await siteConfigApi.updateUserFeatures({
                    systemId: createdSystem?.id,
                    systemName: createdSystem?.displayName,
                    userId: loggedInUserAppData?.data?.id,
                    updatedUserFeatures: [
                        { featureName: 'Accessibility', isActivated: false },
                        { featureName: 'accessibility', isActivated: false },
                    ],
                });
            }

            if (accessibilityFeatureIsOn) {
                await siteConfigApi.updateUserFeatures({
                    systemId: createdSystem?.id,
                    systemName: createdSystem?.displayName,
                    userId: loggedInUserAppData?.data?.id,
                    updatedUserFeatures: [
                        { featureName: 'Accessibility', isActivated: true },
                        { featureName: 'accessibility', isActivated: true },
                    ],
                });

                // user checked automatic accessibility in future
                if (!accessibilityDefaultMasterFeatureOnAccount && automaticEnableAccessibilityFeature) {
                    await siteConfigApi.updateAccountDefaultMasterFeatures({
                        accountId: loggedInUserAppData?.data?.account?.id,
                        masterFeatureName: 'accessibility',
                        modifiedBy: loggedInUserAppData?.data?.email,
                        platform: 'Web',
                    });

                    // user turned off automatic accessibility in future
                } else if (
                    accessibilityDefaultMasterFeatureOnAccount &&
                    accessibilityDefaultMasterFeatureIsOnByUser &&
                    !automaticEnableAccessibilityFeature
                ) {
                    await siteConfigApi.removeAccountDefaultMasterFeature({
                        accountId: loggedInUserAppData?.data?.account?.id,
                        masterFeatureName: 'accessibility',
                        platform: 'Web',
                    });
                }
            }
        } catch (e) {
            console.error(e);
            setPrimaryActionButtonIsLoading(false);

            if (e instanceof ImpersonationPasswordError) {
                onFailure(t('notifications.impersonated-save-fail'));
            } else {
                onFailure(t('systems-tab.new-system-form.configure-step.notifications.fail'));
            }
            handleOnClose();

            return;
        }

        try {
            //handle publish
            let publishResult = await publishEnv();

            // workaround for insights bug - require another publish if data collection changed
            const shouldTriggerSecondPublish =
                selectedDataCollectionLevel === DataCollectionLevel.DIGITAL ||
                selectedDataCollectionLevel === DataCollectionLevel.EXPERIENCE_ANALYTICS;

            const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

            if (publishResult.success && shouldTriggerSecondPublish) {
                await delay(5000);
                publishResult = await publishEnv();
            }

            if (publishResult.success) {
                onSuccess(t('systems-tab.new-system-form.configure-step.notifications.success'));
                await delay(1500);
                handleOnFinishPublish();
            } else {
                onFailure(publishResult.reason);
            }
        } catch (e) {
            onFailure(t('systems-tab.new-system-form.configure-step.notifications.fail'));
        } finally {
            handleOnClose();
        }
    }, [
        createdSystem,
        loggedInUserAppData,
        selectedUUID,
        selectedExpectedFormat,
        regexpInputValue,
        selectedUUIDVariableValue,
        selectedDataCollectionLevel,
        selectedEnvironmentToPublish,
        selectedDeploymentMethod,
        extensionAsDefaultSelected,
        extensionIsDefaultDeploymentMethod,
        originalMatchers,
        environmentMatchers,
        accessibilityDefaultMasterFeatureOnAccount,
        automaticEnableAccessibilityFeature,
        accessibilityFeatureIsOn,
    ]);

    const getEnvironmentOptions = () => {
        const environmentOptionsRes = [];
        if (createdSystem?.purpose !== SystemPurpose.TRIAL) {
            environmentOptionsRes.push({
                label: 'Production and Test',
                value: 'prod_test',
                id: 10,
            });
        }

        environmentOptionsRes.push(
            ...environmentOptions
                .map(mapEnvironmentToDropdownOption)
                .filter(
                    (env) =>
                        createdSystem?.purpose !== SystemPurpose.TRIAL || (createdSystem?.purpose === SystemPurpose.TRIAL && env.id !== 0)
                )
        );

        return environmentOptionsRes.reverse();
    };

    const getUUIDSaveSection = () => {
        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.uuid.main-title`)}</div>
                        <div className='sub-title'>{selectedUUID?.label}</div>
                    </div>
                </div>
                {selectedUUID?.value === UUID.NONE ? (
                    <StyledToasterContainer
                        icon={true}
                        variant={ToasterVariant.Info}
                        message={t(`systems-tab.new-system-form.configure-step.uuid.save-and-publish.toaster.recommendation`)}
                        className='uuid'
                    />
                ) : null}
            </StyledSaveSection>
        );
    };

    const getDataCollectionSaveSection = () => {
        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.data-collection-level.main-title`)}</div>
                        <div className='sub-title'>
                            {t(`systems-tab.new-system-form.configure-step.save-and-publish.${selectedDataCollectionLevel as string}`)}
                        </div>
                    </div>
                </div>
            </StyledSaveSection>
        );
    };

    const getAccessibilitySaveSection = () => {
        const saveSectionText = [];

        if (!accessibilityFeatureIsOn) {
            saveSectionText.push(
                <div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.disabled-on-system`)}</div>
            );
        } else {
            saveSectionText.push(<div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.enabled-on-system`)}</div>);

            // user checked automatic accessibility in future
            if (!accessibilityDefaultMasterFeatureOnAccount && automaticEnableAccessibilityFeature) {
                saveSectionText.push(
                    <div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.enabled-on-account`)}</div>
                );
            }

            if (accessibilityDefaultMasterFeatureOnAccount && !automaticEnableAccessibilityFeature) {
                saveSectionText.push(
                    <div>{t(`systems-tab.new-system-form.configure-step.accessibility.save-section.disabled-on-account`)}</div>
                );
            }
        }

        return (
            <StyledSaveSection>
                <div className='description-content'>
                    <div className='check'>
                        <DoneCheck />
                    </div>
                    <div className='text'>
                        <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.accessibility.main-title`)}</div>
                        <div className='sub-title'>{saveSectionText}</div>
                    </div>
                </div>
            </StyledSaveSection>
        );
    };

    const fetchSystemEnvironments = async () => {
        try {
            const systemEnvironments = await siteConfigApi.getEnvironments({
                systemId: createdSystem?.id,
                isImpersonated,
            });
            const modifiedEnvs = systemEnvironments?.data
                .filter((envData) => !envData.IsDev)
                .map((envData) => {
                    const currentEnvId = envData.Id;
                    const envName = envData.Name;
                    const path = envData.Path;
                    const currentEnvData: Environment = { value: currentEnvId, label: envName, path };

                    return currentEnvData;
                });

            setEnvironmentOptions(modifiedEnvs);
        } catch (e) {
            console.error(e);
        }
    };

    const setSystemExtensionCacheTime = () => {
        const getSystemDataResult: GetSystemDataResult = webSystemAppData?.data?.[createdSystem?.id];

        if (getSystemDataResult) {
            const { cacheTime } = getSystemDataResult;
            setExtensionCacheTimeInSeconds(cacheTime);
        }
    };

    useEffect(() => {
        fetchSystemEnvironments();
    }, [createdSystem?.id]);

    useEffect(setSystemExtensionCacheTime, [createdSystem?.id, webSystemAppData.data]);

    return (
        <Dialog isOpen={saveAndPublishDialogIsOpen} onClose={handleOnClose}>
            <DialogTitle>{t(`systems-tab.new-system-form.configure-step.save-and-publish.main-title`)}</DialogTitle>
            <DialogContent>
                {selectedDeploymentMethod === DeploymentMethods.EXTENSION ? (
                    <StyledExtensionContainer>
                        <div className='main-title'>{t(`systems-tab.new-system-form.deployment.extension.main-title`)}</div>
                        <div className='sub-title'>
                            {t(`systems-tab.new-system-form.configure-step.save-and-publish.extension-description`)}
                        </div>
                        <StyledToasterContainer
                            icon={true}
                            variant={ToasterVariant.Info}
                            message={getSuccessSaveDialogMessageBasedOnExtensionCacheTime(extensionCacheTimeInSeconds, t) as ReactElement}
                        />
                    </StyledExtensionContainer>
                ) : null}
                <StyledDataSettingsAndAccessibilityContainer>
                    <div className='description'>{t(`systems-tab.new-system-form.configure-step.save-and-publish.description`)}</div>
                    <StyledSaveSections>
                        <div className='uuid'>{getUUIDSaveSection()}</div>
                        <div className='data-collection-accessibility'>
                            {getDataCollectionSaveSection()}
                            {getAccessibilitySaveSection()}
                        </div>
                    </StyledSaveSections>
                </StyledDataSettingsAndAccessibilityContainer>
                <StyledSelectEnvironment>
                    <div className='main-title'>{t(`systems-tab.new-system-form.configure-step.save-and-publish.select-environment`)}</div>
                    <SelectEnvironment
                        selectedEnvironment={selectedEnvironmentToPublish}
                        setSelectedEnvironment={(environment) => setSelectedEnvironmentToPublish(environment)}
                        environmentOptions={getEnvironmentOptions()}
                    />
                </StyledSelectEnvironment>
            </DialogContent>
            <DialogActions>
                <Button size='large' variant='text' onClick={handleOnClose}>
                    {t('buttons.cancel')}
                </Button>
                <StyledPrimaryButton
                    size='large'
                    onClick={handleSaveAndFinishCreateSystemFlow}
                    disabled={selectedEnvironmentToPublish?.value === defaultEnvironmentSelection.value || primaryActionButtonIsLoading}
                    loading={primaryActionButtonIsLoading}>
                    {t('buttons.publish')}
                </StyledPrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
