import React, { MouseEvent } from 'react';
import { MenuItem, MenuItemText } from '@walkme/ui-core';
import { StyledActionMenuLongList, StyledDivider, StyledMenu, StyledTitle } from './sort-filter.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSystemSlice,
    CreateSystemState,
    SystemAppSortByOptions,
    SystemAppSortFilters,
    SystemAppSortOrderOptions,
} from '@walkme-admin-center/libs/state-management-systems';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const SortMenu = ({ isOpen, onClose, anchor }) => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const selectedSortFilters: SystemAppSortFilters = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSortFilters.data
    );

    const selected = (() => {
        if (selectedSortFilters.sortBy) {
            switch (selectedSortFilters.sortBy) {
                case SystemAppSortByOptions.POPULARITY:
                    return SystemAppSortByOptions.POPULARITY;
                case SystemAppSortByOptions.APP:
                    if (selectedSortFilters.sortOrder === SystemAppSortOrderOptions.ASC) return 'app_asc';

                    return 'app_des';
            }
        }
        return SystemAppSortByOptions.POPULARITY;
    })();
    const handleClose = () => {
        onClose();
    };
    const items = [
        {
            label: t('systems-tab.new-system-form.popularity'),
            value: 'popularity',
        },
        {
            label: t('systems-tab.new-system-form.by-name'),
            value: 'app_asc',
        },
        {
            label: t('systems-tab.new-system-form.by-name-reverse'),
            value: 'app_des',
        },
    ];

    const handleClick = (e: MouseEvent, value: string) => {
        const newSortFilters: SystemAppSortFilters = {};
        switch (value) {
            case SystemAppSortByOptions.POPULARITY:
                newSortFilters['sortBy'] = SystemAppSortByOptions.POPULARITY;
                newSortFilters['sortOrder'] = SystemAppSortOrderOptions.DESC;
                break;
            case 'app_asc':
                newSortFilters['sortBy'] = SystemAppSortByOptions.APP;
                newSortFilters['sortOrder'] = SystemAppSortOrderOptions.ASC;
                break;
            case 'app_des':
                newSortFilters['sortBy'] = SystemAppSortByOptions.APP;
                newSortFilters['sortOrder'] = SystemAppSortOrderOptions.DESC;
                break;
        }
        dispatch(createSystemSlice.actions.setSelectedSortFilters(newSortFilters));
        handleClose();
    };
    return (
        <StyledMenu
            open={isOpen}
            onClose={handleClose}
            anchorEl={anchor.current}
            width={250}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <StyledTitle>Sort by</StyledTitle>
            <StyledDivider />
            <StyledActionMenuLongList>
                {items.map((item) => {
                    const isSelected = selected === item.value;
                    return (
                        <MenuItem key={item.value} onClick={(e) => handleClick(e, item.value)} selected={isSelected} checkMark>
                            <MenuItemText selected={isSelected}>{item.label}</MenuItemText>
                        </MenuItem>
                    );
                })}
            </StyledActionMenuLongList>
        </StyledMenu>
    );
};
