import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopSystemsExistsState, getDesktopSystemsExists } from '../redux/desktop-systems-exists.slice';

export const useDesktopSystemsExists = () => {
    const desktopSystemsExists = useSelector(
        (state: { desktopSystemsExistsState: DesktopSystemsExistsState }) => state.desktopSystemsExistsState.desktopSystemsExists
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDesktopSystemsExists());
    }, [dispatch]);

    return {
        desktopSystemsExists,
    };
};

export default useDesktopSystemsExists;
