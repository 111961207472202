import { SystemSettings } from 'wm-accounts-sdk';

export function retrieveSystemIcon(systemApp: SystemSettings) {
    return systemApp ? systemApp.icons?.highRes || `/assets/icons/icon-${systemApp.name}.png` : null;
}

export function retrieveSystemSvgIcon(systemType: string) {
    return `assets/icons/data-integrations/integrations-icons/system-icons/${
        systemType === 'default' ? 'walkme' : systemType
    }-icon-small.svg`;
}
