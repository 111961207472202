import React, { SVGProps } from 'react';

export const AiCenterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#AiCenter_svg__a)' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'>
            <path d='M14.5 10.5c-4.157 1.4-5.987 3.116-7 7-1.013-3.883-2.843-5.6-7-7 4.157-1.4 5.987-3.115 7-7 1.013 3.885 2.843 5.6 7 7ZM18 4.5c-2.345.787-2.928 1.317-3.5 3.5-.572-2.183-1.156-2.713-3.5-3.5 2.344-.787 2.928-1.317 3.5-3.5.572 2.183 1.155 2.712 3.5 3.5ZM18 15.5c-1.735.57-2.078.918-2.5 2.5-.423-1.582-.765-1.93-2.5-2.5 1.735-.57 2.077-.918 2.5-2.5.422 1.582.765 1.93 2.5 2.5Z'></path>
        </g>
        <defs>
            <clipPath id='AiCenter_svg__a'>
                <path d='M0 0h20v20H0z'></path>
            </clipPath>
        </defs>
    </svg>
);
