import { SubMenu } from './sub-menu';

export enum ConfigureTabs {
    DEPLOYMENT_METHOD = 0,
    UUID = 1,
    DATA_COLLECTION_LEVEL = 2,
    ACCESSIBILITY = 3,
    ASSIGN_USERS = 4,
}

export const ConfigureTabNames = ({ setActiveTab }) => {
    const configureTabNames: any = [
        {
            id: ConfigureTabs.DEPLOYMENT_METHOD,
            displayName: 'Deployment Method',
            translationKey: 'systems-tab.new-system-form.deployment.step-title',
        },
        {
            component: SubMenu,
            items: [
                {
                    id: ConfigureTabs.UUID,
                    displayName: 'Unique user settings (UUID)',
                    translationKey: 'systems-tab.new-system-form.configure-step.uuid.main-title',
                    onClick: () => setActiveTab(ConfigureTabs.UUID),
                },
                {
                    id: ConfigureTabs.DATA_COLLECTION_LEVEL,
                    displayName: 'Data collection level',
                    translationKey: 'systems-tab.new-system-form.configure-step.data-collection-level.side-menu-title',
                    onClick: () => setActiveTab(ConfigureTabs.DATA_COLLECTION_LEVEL),
                },
            ],
        },
        {
            id: ConfigureTabs.ACCESSIBILITY,
            displayName: 'Accessibility',
            translationKey: 'systems-tab.new-system-form.configure-step.accessibility.side-menu-title',
        },
        {
            id: ConfigureTabs.ASSIGN_USERS,
            displayName: 'Assign Users',
            translationKey: 'systems-tab.all-systems-page.assign-users-form.title',
        },
    ];

    return configureTabNames;
};
