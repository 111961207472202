import React from 'react';
import { Environments } from 'wm-accounts-sdk';
import { analyticsApi } from '@walkme-admin-center/libs/analytics-api';
import { OneId, Provider, Providers } from '@walkme-admin-center/libs/state-management-sso-configuration';
import CustomToolTip from './components/tool-tip/tool-tip';
import { previewEnv, successEnv, SystemsType, SystemTypeInfo } from './consts';
import { CustomTag } from './style';
import { TFunction } from 'i18next';

export const expandIcon = <img src='assets/icons/arrow-down.svg' alt='expand provider' style={{ width: '24px', height: '24px' }} />;

export const shrinkText = (text: string, size: number, padding = '0 0 0 8px') => {
    return (
        <CustomToolTip title={text ? text.toString() : 'UNKNOWN'} disableHoverListener={text.length < size}>
            <div style={{ padding }}>{text.length <= size ? text : `${text.substring(0, size - 3)}...`}</div>
        </CustomToolTip>
    );
};

export const sortProviders = (providerList: Providers, oneId: OneId): Providers => {
    const providers = [...providerList];
    providers.sort((firstElement: Provider, secondElement: Provider) => {
        if (firstElement.systems.length > secondElement.systems.length) {
            return -1;
        } else if (firstElement.systems.length < secondElement.systems.length) {
            return 1;
        } else {
            return firstElement.name.localeCompare(secondElement.name);
        }
    });
    return providers;
};

export const getSystemEnvText = (isTest: boolean, t: TFunction): React.ReactElement => {
    return <span>{isTest ? t('idp-tab.envs.test') : t('idp-tab.envs.others')}</span>;
};

export const getSystemTag = (systemType: string, t: TFunction) => {
    const getTooltip = (systemInfo: SystemTypeInfo): string => {
        if (!t) {
            return systemInfo.tooltip;
        }
        const result = t(systemInfo.localeKey);
        if (result === systemInfo.localeKey) {
            return systemInfo.tooltip;
        }
        return result;
    };

    const systemInfo: SystemTypeInfo = Object.values(SystemsType).find((system) => system.key === systemType) || SystemsType.WEB;

    let systemTooltip = getTooltip(systemInfo);

    return <CustomTag>{systemTooltip}</CustomTag>;
};

export const getSystemEnvs = async (id: number, force: boolean, isImpersonate = false, getDeleted = false): Promise<Environments> => {
    const envs = await analyticsApi.getSystemEnvironments(id, force, getDeleted);
    const newEnvs = [...envs, previewEnv];
    if (isImpersonate) {
        newEnvs.push(successEnv);
    }
    return newEnvs;
};

export const getSystemEnvsWithOutAjax = (envs: Environments = [], isImpersonate = false): Environments => {
    const newEnvs = [...envs, previewEnv];
    if (isImpersonate) {
        newEnvs.push(successEnv);
    }
    return newEnvs;
};
