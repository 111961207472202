import React, { useState } from 'react';
import { StyledSideMenu, StyledTabName } from './side-menu.styles';
import { ConfigureTabNames } from './configure.lib';
import { Typography } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

interface SideMenuProps {
    activeTab: number;
    setActiveTab: (tab: number) => void;
}

export const SideMenu = ({ activeTab, setActiveTab }: SideMenuProps) => {
    const { t } = useTranslation('general');
    const [isTabComponentOpen, setIsTabComponentOpen] = useState(false);

    const onClick = (tab) => {
        setActiveTab(tab.id);
        setIsTabComponentOpen(false);
    };

    return (
        <StyledSideMenu>
            {ConfigureTabNames({ setActiveTab }).map((tab) => {
                const TabComponent = tab.component;

                return TabComponent ? (
                    <TabComponent items={tab.items} activeTab={activeTab} isOpen={isTabComponentOpen} setIsOpen={setIsTabComponentOpen} />
                ) : (
                    <StyledTabName active={activeTab === tab.id} isOpen={isTabComponentOpen} onClick={() => onClick(tab)}>
                        <Typography variant={'T20'} className={'typography'}>
                            {t(tab.translationKey)}
                        </Typography>
                    </StyledTabName>
                );
            })}
        </StyledSideMenu>
    );
};
