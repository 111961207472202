import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const WarningModalContainer = styled.div`
    z-index: 999;
    display: flex;
    width: 543px;
    height: 220px;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 85px;
    bottom: 0;
    margin: auto;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 36px 52px 0px rgba(47, 61, 83, 0.11);
`;

export const ToastContainer = styled.div`
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

export const WarningHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
    padding: 24px 28px 16px 32px;
    align-self: stretch;
    gap: 16px;
    border-radius: 12px 12px 0px 0px;
`;

export const WarningText = styled.div`
    color: #2f426c;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const WarningFooter = styled.div`
    display: flex;
    margin-top: 16px;
    padding: 16px 32px 32px 32px;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    border-radius: 0px 0px 12px 12px;
    gap: 10px;
`;

export const DeleteButton = styled.div`
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 40px;
    padding: 10px 20px;
    background: #eb3232;
    color: #fff;
    text-align: center;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;

    .warning-footer-delete-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
    }
`;

export const CancelButton = styled(Button)`
    display: flex;
    width: 84px;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding: 10px 20px;
    border-radius: 40px;
    text-align: center;
    color: #637191;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    text-transform: none;
`;
