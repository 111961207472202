import { BrowserRouter as Router } from 'react-router-dom';
import {
    NoPartnersLabel,
    NoPartnersDiv,
    LoadingContainer,
    StyledPartnersWMDataGrid,
    AccountNameColumn,
    UserStatusColumn,
    ActionMenuColumn,
    StyledPartnersTabContainer,
    StyledPartnersContainer,
} from './styles/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WMDataGridWrapperProps, DataGridModules, WMTagList, WMTagVariant, WMTooltip, WMStatusDot, WMMenu } from '@walkme/wm-ui';
import { IconButton, Tooltip, Button } from '@walkme/ui-core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColDef } from '@ag-grid-community/core';
import { getDetailCellRendererParams } from './drawer';
import { PartnerAccountData, PartnerAccountStatus, PartnerUserData } from 'packages/libs/types/src/lib/partner';
import { Add, Delete, ChevronRight } from '@walkme/ui-icons';
import { getPartners } from 'packages/libs/state-management-partners/src/lib/redux/partners.slice';
import { useDispatch } from 'react-redux';
import { AccountStatusMap } from './utils/partner-status';
import { AddPartner } from './add-partner';
import { ChevronDown } from '@walkme/ui-icons/large';
import DeleteAccount from './delete-account';
import EditUser from './edit-user';
import { LabelWithTooltip } from './components/LabelWithTooltip';
import { ColIds, partnerActionsMenuButtonId } from './partners.lib';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { useDatagridLocale } from 'packages/pages/util/locale-utils';
import { timeLocalesMapping } from 'apps/home/src/localization';

export const PartnersTab = ({ loadingData, pageData }) => {
    const { t, rt, i18n } = useTranslation('general');
    const partners: PartnerAccountData[] = pageData;
    const updatedGridModules = DataGridModules.concat([MasterDetailModule as any]);
    const [currentHoveredRow, setCurrentHoveredRow] = useState<number | null>(null);
    const [showAddPartnerScreen, setShowAddPartnerScreen] = useState(false);
    const [showDeleteAccountScreen, setShowDeleteAccountScreen] = useState(false);
    const [showEditUserScreen, setShowEditUserScreen] = useState(false);
    const [accountIdToDelete, setAccountIdToDelete] = useState<number | null>(null);
    const [userDataForEdit, setUserDataForEdit] = useState<PartnerUserData | null>(null);

    const [dateTimeLocale, setDateTimeLocale] = useState(timeLocalesMapping[i18n.language]);

    const datagridLocale = useDatagridLocale();

    const onCancel = useCallback(() => setShowEditUserScreen(false), []);

    const dispatch = useDispatch();

    const handleSelection = useCallback((event) => {
        const data: PartnerAccountData = event.data;
        const rowContainsUsers: boolean = data.partnerUsers.length > 0;
        const isClickOnActionsColumn: boolean = event.colDef.colId === ColIds.actionMenuColId;
        if (!isClickOnActionsColumn && rowContainsUsers) {
            event.node.setExpanded(!event.node.expanded);
            event.api.redrawRows({ rowNodes: [event.node] });
        }
    }, []);

    const headerActions = [
        <Tooltip title={t('partners-tab.partners-sub-tab.add-new-partner')}>
            <IconButton
                variant='ghost'
                onClick={() => {
                    setShowAddPartnerScreen(true);
                }}>
                <Add />
            </IconButton>
        </Tooltip>,
    ];

    const onEditUser = (userData: PartnerUserData) => {
        setUserDataForEdit(userData);
        setShowEditUserScreen(true);
    };

    const handleSystemsTooltipMouseLeave = () => {
        setCurrentHoveredRow(null);
    };

    useEffect(() => {
        setDateTimeLocale(timeLocalesMapping[i18n.language]);
    }, [i18n.language]);

    const detailCellRendererParams = useMemo(() => {
        const result = getDetailCellRendererParams(onEditUser, dispatch, t, rt, dateTimeLocale);
        return result;
    }, [dateTimeLocale]);

    const gridConfig: WMDataGridWrapperProps<any> = useMemo(() => {
        const config = {
            getRowClass: (params) => {
                const data: PartnerAccountData = params.data;
                return data.partnerUsers.length === 0 ? 'ag-row-non-expandable' : 'ag-row-expandable';
            },
            onCellMouseOver(event) {
                setCurrentHoveredRow(event.rowIndex);
            },
            onCellMouseOut(event) {
                if (
                    (event.event.target as HTMLImageElement).id !== partnerActionsMenuButtonId &&
                    event.colDef.colId !== ColIds.systemsColId
                ) {
                    setCurrentHoveredRow(null);
                }
            },
            masterDetail: true,
            detailCellRendererParams,
            detailRowHeight: 50,
            onCellClicked: handleSelection,
            detailRowAutoHeight: true,
            modules: updatedGridModules,
            rowHeight: 60,
            class: 'main-table',
            defaultColDef: {
                flex: 1,
                headerClass: 'main-table',
                cellClass: 'main-table',
            },
            dateTimeLocale: detailCellRendererParams.dateTimeLocale,
        };
        return config;
    }, [partners, detailCellRendererParams]);

    const primaryTableColumns: ColDef[] = [
        {
            field: 'accountName',
            headerName: t('partners-tab.partners-sub-tab.partner-account-name'),
            lockPosition: true,
            cellRenderer: (params) => {
                const data: PartnerAccountData = params.data;
                const name: string = data.accountName;
                const currentRowIndx = params.rowIndex;
                const isCurrentRowExpanded = params.api?.rowRenderer?.allRowCtrls[currentRowIndx]?.rowNode?.expanded;

                return (
                    <AccountNameColumn>
                        <div className={'accountRowChevron'}>
                            {data.partnerUsers.length === 0 ? <div /> : isCurrentRowExpanded ? <ChevronDown /> : <ChevronRight />}
                        </div>
                        <div className={'accountRowName'}>
                            <span>{name.charAt(0)}</span>
                        </div>
                        <LabelWithTooltip text={name} />
                    </AccountNameColumn>
                );
            },
        },
        {
            headerName: t('common.systems'),
            cellStyle: { display: 'flex' },
            colId: ColIds.systemsColId,
            cellRenderer: (params) => {
                const data: PartnerAccountData = params.data;
                return (
                    <div>
                        <WMTagList
                            onMouseLeave={handleSystemsTooltipMouseLeave}
                            labels={data.systems.map((system) => ({
                                labelText: system.displayName,
                                fullLabel: true,
                                variant: WMTagVariant.Comet,
                            }))}
                            maxTagsShown={3}
                        />
                    </div>
                );
            },
        },
        { field: 'partnerUsers.length', headerName: t('common.users') },
        {
            headerName: t('common.status'),
            cellRenderer: (params) => {
                const data: PartnerAccountData = params.data;
                const mappedStatus =
                    data.partnerUsers.length == 0 || data.systems.length === 0
                        ? AccountStatusMap.find((mappedStatus) => mappedStatus.id === PartnerAccountStatus.NoUsersOrSystems)
                        : AccountStatusMap.find((mappedStatus) => mappedStatus.id === PartnerAccountStatus.Active);
                return (
                    <div>
                        <WMTooltip title={t(mappedStatus.info)}>
                            <div>
                                <UserStatusColumn>
                                    {<WMStatusDot variant={mappedStatus.color} />}
                                    <span className={'userRowStatus'}>{t(mappedStatus.display)}</span>
                                </UserStatusColumn>
                            </div>
                        </WMTooltip>
                    </div>
                );
            },
        },
        {
            minWidth: 40,
            maxWidth: 40,
            cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
            colId: ColIds.actionMenuColId,
            pinned: 'right',
            cellRenderer: (params) => {
                return (
                    <ActionMenuColumn className={'userRowActionMenuContainer'}>
                        {currentHoveredRow === params.rowIndex && (
                            <WMMenu
                                menuButtonIconComponent={
                                    <img alt={'more'} src={`/assets/icons/icon-moreVertical.svg`} id={partnerActionsMenuButtonId} />
                                }
                                menuItems={[
                                    {
                                        label: t('buttons.delete-account'),
                                        onClick: () => {
                                            setAccountIdToDelete(params.data.accountId);
                                            setShowDeleteAccountScreen(true);
                                            setCurrentHoveredRow(null);
                                        },
                                        startIcon: <Delete />,
                                    },
                                ]}
                                autoFocus={false}
                            />
                        )}
                    </ActionMenuColumn>
                );
            },
        },
    ];

    return (
        <StyledPartnersTabContainer>
            <div className={'contentDiv'}>
                {showAddPartnerScreen && (
                    <AddPartner
                        onCancel={() => setShowAddPartnerScreen(false)}
                        onFinish={() => {
                            setShowAddPartnerScreen(false);
                            dispatch(getPartners());
                        }}
                        showDialog={showAddPartnerScreen}
                    />
                )}
                {showDeleteAccountScreen && (
                    <DeleteAccount
                        accountIdToDelete={accountIdToDelete}
                        onCancel={() => setShowDeleteAccountScreen(false)}
                        onFinish={() => {
                            setShowDeleteAccountScreen(false);
                            dispatch(getPartners());
                        }}
                        showDialog={showDeleteAccountScreen}
                    />
                )}
                {showEditUserScreen && (
                    <EditUser
                        userDataForEdit={userDataForEdit}
                        onCancel={onCancel}
                        onFinish={() => {
                            setShowEditUserScreen(false);
                            dispatch(getPartners());
                        }}
                    />
                )}
                {loadingData ? (
                    <LoadingContainer>
                        <CircularProgress size={50} thickness={4} />
                    </LoadingContainer>
                ) : (
                    <StyledPartnersContainer>
                        {partners?.length > 0 ? (
                            <StyledPartnersWMDataGrid
                                rows={partners}
                                columnDefs={primaryTableColumns}
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>{t('partners-tab.partners-sub-tab.title')}</div>
                                }
                                entityName={''}
                                gridConfig={gridConfig}
                                loadingData={loadingData}
                                headerActions={headerActions}
                                hideColumnPicker={true}
                                hideExport={true}
                                locale={datagridLocale}
                            />
                        ) : (
                            <NoPartnersDiv>
                                <img alt={'no results'} src='/assets/No results.svg' width='250px' height='250px' />
                                <NoPartnersLabel>{t('partners-tab.partners-sub-tab.no-partners-text')}</NoPartnersLabel>
                                <Button onClick={() => setShowAddPartnerScreen(true)} variant='solid' color='primary'>
                                    {t('buttons.add-partners')}
                                </Button>
                            </NoPartnersDiv>
                        )}
                    </StyledPartnersContainer>
                )}
            </div>
        </StyledPartnersTabContainer>
    );
};
