import React, { useEffect } from 'react';
import { StyledAppCard, StyledAppIconContainer } from './app-card.styles';
import { Tooltip } from '@walkme/ui-core';

export const AppCard = ({ displayName, iconSrc, onClick, selected, key }) => {
    const modifiedDisplayName = displayName.length > 15 ? displayName.substring(0, 15) + '...' : displayName;
    return (
        <StyledAppCard onClick={onClick} selected={selected} key={key}>
            <StyledAppIconContainer>
                <img src={iconSrc} alt={displayName} width={25} height={25} />
            </StyledAppIconContainer>
            <Tooltip title={displayName.length <= 15 ? '' : displayName} placement='top'>
                <span className='displayName'>{modifiedDisplayName}</span>
            </Tooltip>
        </StyledAppCard>
    );
};
