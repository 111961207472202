import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Input, InputHeight, Typography } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState, saveNewSystem, SaveSystemProps } from '@walkme-admin-center/libs/state-management-systems';
import { CreateSystemSteps } from '../../../../../../../../libs/state-management-systems/src/lib/types/createSystem';
import { SystemPurpose, SystemUsage } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { SystemTypeKey } from 'wm-accounts-sdk';
import {
    StyledCreateSystemText,
    StyledInputLabel,
    StyledPrimaryButton,
    StyledSecondaryButton,
} from './complete-system-creation-dialog.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { useContracts } from '../../../../../../../../libs/state-management-systems/src/lib/hooks/use-contracts';
import { getContractNumOfPurchasedSystems, getContractNumOfSystemsInUse } from './contract-list/contract-list.lib';
import { useLoggedInUser, useUsers } from '@walkme-admin-center/libs/state-management-users';

export const CompleteSystemCreationDialog = () => {
    const { t, rt } = useTranslation('general');
    const dispatch = useDispatch();
    const nameInputRef = useRef(null);
    const { systems } = useContracts();
    const { usersAppData } = useUsers();
    const { loggedInUserAppData } = useLoggedInUser();
    const dialogIsOpen: boolean = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.completeSystemCreationModalIsOpen.data
    );
    const step: CreateSystemSteps = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.step.data);
    const selectedSystemApp = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSystemApp.data
    );

    const isCustomMode = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.isCustomMode.data);
    const isCreateCustomSystem = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateCustomSystem.data
    );
    const appUrlsData = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.appUrls.data);
    const customAppName = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.customAppName.data);

    const selectedContract = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedContract.data
    );
    const purpose = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.purpose.data) as SystemPurpose;
    const usage = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.usage.data) as SystemUsage;
    const isCreatingSystem = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem?.loading
    );
    const selectedDeploymentMethod = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedDeploymentMethod
    );

    const extensionSelfDomains = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.extensionSelfDomains
    );

    const extensionAsDefaultSelected = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.extensionAsDefaultSelected
    );

    const [finalSystemName, setFinalSystemName] = useState<string>('');

    const handleOnClose = () => {
        dispatch(createSystemSlice.actions.setCompleteSystemCreationModalVisibility(false));
    };

    type StepNavClickProps = { direction: 'next' | 'back' };

    const handleStepNavClick = ({ direction }: StepNavClickProps) => {
        dispatch(createSystemSlice.actions.setCreateSystemStep(direction === 'next' ? step + 1 : step - 1));
    };

    const handleCreateSystem = async () => {
        try {
            const numOfSystemsInUse = getContractNumOfSystemsInUse(selectedContract, systems);
            const numOfPurchasedSystems = getContractNumOfPurchasedSystems(selectedContract, rt);
            const systemIsLimited =
                purpose === SystemPurpose.TRIAL ||
                (typeof numOfPurchasedSystems === 'number' && numOfSystemsInUse + 1 > numOfPurchasedSystems);

            const customGuid = '00000000-0000-0000-0000-000000000000';
            let displayName = finalSystemName;
            let systemAppId = isCustomMode
                ? isCreateCustomSystem
                    ? customGuid
                    : appUrlsData.find((item) => item.order === 0)?.app?.id
                : selectedSystemApp?.guid;

            const newSystem: SaveSystemProps = {
                displayName,
                systemAppId,
                systemTypeKey: SystemTypeKey.Web,
                unassignUser: false,
                contractId: selectedContract?.sfId,
                purpose,
                usage,
                movePage: true,
                sendFeedback: true,
                isLimited: systemIsLimited,
            };

            dispatch(saveNewSystem(newSystem));
        } catch (e) {
            console.error(e);
        }
    };

    const handleGenerateDefaultFinalSystemName = () => {
        const currentSystemName = isCustomMode ? customAppName : selectedSystemApp?.displayName;
        let numOfSystemsWithDefaultDisplayName = 0;
        let foundSystemNameWithNumOfSystems = false;
        let nameToSave = currentSystemName;

        while (!foundSystemNameWithNumOfSystems) {
            const loggedUserSystems = usersAppData?.data?.find((user) => user.id === loggedInUserAppData?.data?.id)?.systems;

            if (loggedUserSystems?.find((system) => system.displayName === nameToSave)) {
                numOfSystemsWithDefaultDisplayName++;
                nameToSave =
                    numOfSystemsWithDefaultDisplayName > 0
                        ? `${currentSystemName} (${numOfSystemsWithDefaultDisplayName + 1})`
                        : currentSystemName;
            } else {
                foundSystemNameWithNumOfSystems = true;
            }
        }

        setFinalSystemName(
            numOfSystemsWithDefaultDisplayName > 0 ? `${currentSystemName} (${numOfSystemsWithDefaultDisplayName + 1})` : currentSystemName
        );
    };

    const focusOnNewNameInput = () => {
        setTimeout(() => nameInputRef.current?.focus(), 100);
    };

    useEffect(handleGenerateDefaultFinalSystemName, [customAppName]);

    useEffect(focusOnNewNameInput, [dialogIsOpen]);

    return (
        <Dialog isOpen={dialogIsOpen} onClose={handleOnClose}>
            <DialogTitle>{t('systems-tab.create-system')}</DialogTitle>
            <DialogContent>
                <StyledCreateSystemText>{t('systems-tab.new-system-form.create-system-description')}</StyledCreateSystemText>
                <StyledInputLabel>
                    <Typography variant={'T20-2'}>{t('systems-tab.new-system-form.system-name')}</Typography>
                </StyledInputLabel>
                <Input
                    ref={nameInputRef}
                    placeholder={t('systems-tab.all-systems-page.assign-users-form.type-new-name')}
                    height={InputHeight.large}
                    width='100%'
                    value={finalSystemName}
                    onChange={(event) => setFinalSystemName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <StyledSecondaryButton size='large' variant='text' onClick={handleOnClose} disabled={isCreatingSystem}>
                    {t('buttons.cancel')}
                </StyledSecondaryButton>
                <StyledPrimaryButton
                    size='large'
                    onClick={handleCreateSystem}
                    loading={isCreatingSystem}
                    disabled={finalSystemName?.length === 0}>
                    {t('buttons.create-system')}
                </StyledPrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
