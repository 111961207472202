import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatedSystemsStatuses, SystemsState } from '../redux/systems.slice';

export const useSystemsCreationStatus = () => {
    const systemsCreationStatus = useSelector((state: { systemsState: SystemsState }) => state.systemsState.systemsCreationStatus);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCreatedSystemsStatuses());
    }, [dispatch]);

    return {
        systemsCreationStatus,
    };
};

export default useSystemsCreationStatus;
