import styled from 'styled-components';

export const SnippetMethodContainer = styled.div`
    margin-top: 20px;

    .description {
        color: var(--Typography-Typography-tint-1, #637191);
        margin-bottom: 8px;
        margin-top: 24px;
    }

    .code-snippet {
        font-family: 'Proxima Nova';
        font-weight: 600;
        border-radius: var(--corner-radus-global-cr-308, 8px);
        background: var(--Typography-Typography-MainPrimary-Main, #2f426c);
        padding: 8px 16px;
    }
`;
