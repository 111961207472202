import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FooterStyledButton, FooterCancelButton } from './footer-style';
import { useAskMe } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { IDataIntegrationAppSettingsForm } from '../../../../../../../../libs/state-mangment-data-integration/src/lib/types';

export const SaveSettingsFooter = ({ onClose, showSnackBar }) => {
    const [loading, setLoading] = useState(false);
    const formApi = useForm<IDataIntegrationAppSettingsForm>();
    const { invalid, dirty, values } = formApi.getState();

    const {
        askMeIntegrationsData: { data },
    } = useAskMe();

    const validateBeforeSubmit = async () => {
        if (values.ipList && values.ipList.length) {
            await handleSubmit();
        } else {
            const privateAskMe = data.find((source) => source.privacyLevel === true);
            if (privateAskMe) {
                showSnackBar({
                    open: true,
                    messageText: `You must have at least one IP for '${privateAskMe.name}' source`,
                    severity: 'error',
                });
            } else {
                await handleSubmit();
            }
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        await formApi.submit();
        setLoading(false);
    };
    return (
        <>
            <span></span>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FooterCancelButton onClick={() => onClose()}>Cancel</FooterCancelButton>
                <FooterStyledButton onClick={() => validateBeforeSubmit()} disabled={invalid || !dirty}>
                    {loading && (
                        <>
                            <CircularProgress size={14} color='inherit' />
                            &nbsp;
                        </>
                    )}
                    <span>Save</span>
                </FooterStyledButton>
            </div>
        </>
    );
};

export default SaveSettingsFooter;
