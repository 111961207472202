import Box from '@material-ui/core/Box/Box';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { useStyles } from './styles';

export interface LoadingOptionCardProps {
    icon: any;
    title: string;
    subTitle: string;
    isSelected: boolean;
    onClick: () => void;
};

const LoadingOptionCard = ({ icon, title, subTitle, isSelected, onClick }: LoadingOptionCardProps) => {
    const classes = useStyles();

    return (
        <Paper className={isSelected ? classes.containerSelected : classes.containerDefault} onClick={onClick}>
            <img alt={title} src={icon} />
            <Box className={classes.loadingOptionsDetailsContainer}>
                <Typography className={classes.loadingOptionTitle}>
                    {title}
                </Typography>
                <Typography className={classes.loadingOptionSubtitle}>
                    {subTitle}
                </Typography>
            </Box>
        </Paper >
    );
};

export default LoadingOptionCard;