import { AudienceType, FetchPaginatedSystemAppsDto, SystemUsage } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import {
    SystemAppQueryFields,
    SystemAppSortByOptions,
    SystemAppSortFilters,
    SystemAppSortOrderOptions,
} from '@walkme-admin-center/libs/state-management-systems';

export const defaultPaginationConfig: FetchPaginatedSystemAppsDto = {
    limit: 50,
    filterOut: { app: 'unknown' },
    filterIn: { status: 'Active', showInAdminCenter: true },
};

export type GeneratePaginationFilterDto = {
    searchText: string;
    appFilters: SystemAppQueryFields;
    sortFilters: SystemAppSortFilters;
    systemUsage?: SystemUsage;
};
export const generatePaginationFilterDto = ({ searchText, appFilters, sortFilters, systemUsage }: GeneratePaginationFilterDto) => {
    const paginationFilterDto: FetchPaginatedSystemAppsDto = {};

    if (searchText) {
        paginationFilterDto.searchText = searchText;
    }
    if (appFilters) {
        paginationFilterDto.filterIn = { ...defaultPaginationConfig.filterIn, ...appFilters, ...paginationFilterDto.filterIn };
    }
    if (sortFilters) {
        paginationFilterDto.sortBy = sortFilters.sortBy || SystemAppSortByOptions.POPULARITY;
        paginationFilterDto.sortOrder = sortFilters.sortOrder || SystemAppSortOrderOptions.DESC;
    }
    if (systemUsage) {
        switch (systemUsage) {
            case SystemUsage.EXTERNAL:
                paginationFilterDto.filterOut = { ...defaultPaginationConfig.filterOut, audienceType: AudienceType.INTERNAL };
                break;
            case SystemUsage.INTERNAL:
                paginationFilterDto.filterOut = { ...defaultPaginationConfig.filterOut, audienceType: AudienceType.EXTERNAL };
                break;
        }
    }

    return paginationFilterDto;
};

export const keywordsWhichShouldReturnNoResults = ['custom'];
