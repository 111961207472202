import React from 'react';
import { RuleTipContainer, ToolTipHeader, ToolTipBody } from '../rules/rules-style';

const ChannelsTip = ({ integrationType }) => {
    const toUpperCase = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <RuleTipContainer>
            <ToolTipHeader>Identified Channels</ToolTipHeader>
            <ToolTipBody>
                A list of all the {toUpperCase(integrationType)} channels
                <br /> the "AI Answers" bot was added to
            </ToolTipBody>
        </RuleTipContainer>
    );
};

export default ChannelsTip;
