import React, { useRef, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useStyles, syntaxHighlighterCustomStyle } from './code-block-style';
import { Box, IconButton } from '@material-ui/core';
import { IconCopy } from '../../icons/icon-copy';
import { IconExpand } from '../../icons/icon-expand';
import { StyledWMTooltip } from './styled-components';
import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { IconClose } from '../../icons/icon-close';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import cc from 'classcat';

interface CodeBlockProps {
    text: string;
    language: string;
    style: Record<string, any>;
    maxHeight?: string | number;
    maxHeightExpanded?: string | number;
    minifyOnCopy?: boolean;
    wrap?: boolean;
    expandedAsDefault?: boolean;
}

const CodeBlock = ({
    language,
    style,
    text,
    maxHeight = '76px',
    maxHeightExpanded = '300px',
    minifyOnCopy,
    wrap,
    expandedAsDefault,
}: CodeBlockProps) => {
    const styleProps = useRef({
        collapsedMaxHeight: maxHeight,
        expandedMaxHeight: maxHeightExpanded,
        baseTextColor: style?.hljs?.color || '#000000',
    });
    const classes = useStyles(styleProps.current);
    const [isExpanded, setIsExpanded] = useState(expandedAsDefault || false);
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

    const handleExpandToggled = () => {
        setIsExpanded((wasExpanded) => !wasExpanded);
    };

    const handleCopyClicked = async () => {
        const textToCopy = minifyOnCopy
            ? text
                  .replace(/[\r\n\t\v\f]+/g, '')
                  .replace(/ +/g, ' ')
                  .trim()
            : text;

        await navigator.clipboard.writeText(textToCopy);

        setIsCopiedToClipboard(true);
    };

    const handleCopySnackBarClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason): void => {
        if (reason === 'clickaway') return;
        setIsCopiedToClipboard(false);
    };

    return (
        <Box
            className={cc([
                classes.codeBlock,
                {
                    [classes['codeBlock--expanded']]: isExpanded,
                    [classes['codeBlock--collapsed']]: !isExpanded,
                    [classes.wrapCodeLines]: wrap,
                },
            ])}>
            <SyntaxHighlighter language={language} style={style} customStyle={syntaxHighlighterCustomStyle} showLineNumbers wrapLines>
                {text}
            </SyntaxHighlighter>
            <Box className={classes.buttonsBox}>
                <StyledWMTooltip arrow title={isExpanded ? 'Close' : 'Expand view'} placement='top' enterDelay={500}>
                    <IconButton className={classes.button} onClick={handleExpandToggled}>
                        {isExpanded ? <IconClose /> : <IconExpand />}
                    </IconButton>
                </StyledWMTooltip>
                <StyledWMTooltip arrow title='Copy' placement='top' enterDelay={500}>
                    <IconButton className={classes.button} onClick={handleCopyClicked}>
                        <IconCopy />
                    </IconButton>
                </StyledWMTooltip>
            </Box>
            <WMSnackbar
                variant={WMSnackbarVariant.Success}
                open={isCopiedToClipboard}
                onClose={handleCopySnackBarClose}
                message='Copied to clipboard'
            />
        </Box>
    );
};

export { CodeBlock };
