/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import { Base } from './base';
import get from 'lodash/get';
import { domainSchema, settingsSchema } from '../services/validation/crawler';
import DomainStep from '../data-integration-form-components/crawler-form/steps/domain-step/domain-step';
import { getErrorObject } from '../services/validation/validate';
import SettingsStep from '../data-integration-form-components/crawler-form/steps/settings-step/settings-step';
import { createAskMe, executeAskMe, stopScan, updateAskMe } from '@walkme-admin-center/libs/state-mangment-data-integration';
import TabPanel from '../../common/components/custom-tab-panel';
import EditCrawlerFooter from '../data-integration-form-components/footers/edit-crawler-footer';
import { CONSTS } from '../../../../../sso-configuration/src/lib/common/consts';
import {
    IAskMeForm,
    FormMode,
    ScanFrequency,
    IAskMe,
    IAskMeSystems,
    AuthType,
    RuleType,
    IIntegration,
    IAskMeIntegrationSystems,
    IHeaderText,
    ChannelStatus,
    IntegrationChannelOptions,
    IIntegrationRules,
    IAskMeSettingsForm,
    IAskMeSettings,
    IAskMeIntegrationSystemForm,
    IIntegrations,
    JobType,
    IAuditScan,
    ScrapeStatus,
} from '../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import AssignSystemsTabs from '../data-integration-form-components/crawler-form/steps/assign-systems-step/assign-systems-tabs';
import { isEqual } from 'lodash';
import AssignSegmentsTab from '../data-integration-form-components/crawler-form/steps/assign-segments-step/assign-segments-step';
import CreateSourceFooter from '../data-integration-form-components/footers/create-source-footer';
import { setLocalStorage } from '../../data-integrations-header/use-local-storage';

interface IHandleSubmit {
    values: IAskMeForm;
    instanceData: IAskMe;
    integrations: IIntegrations;
}

export class Crawler extends Base {
    isAuthError = false;

    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    prepareSettings(settings: IAskMeSettingsForm): IAskMeSettings {
        const controlDelay = settings.controlDelay === 'true';
        let controlDomains = settings.controlDomains;
        if ((!settings.allowPath || !settings.allowPath.length) && (!settings.denyPath || !settings.denyPath.length) && controlDomains) {
            controlDomains = false;
        }
        let auditScan: IAuditScan;
        switch (settings.auditScan?.frequency) {
            case ScanFrequency.WEEKLY:
                auditScan = { frequency: ScanFrequency.WEEKLY, dayOfWeek: settings.auditScan.dayOfWeek };
                break;
            case ScanFrequency.MONTHLY:
                auditScan = { frequency: ScanFrequency.MONTHLY, dayOfMonth: settings.auditScan.dayOfMonth };
                break;
            case ScanFrequency.CUSTOM:
                auditScan = { frequency: ScanFrequency.CUSTOM, customDate: settings.auditScan.customDate };
                break;
        }
        return {
            ...settings,
            auditScan,
            controlDomains,
            controlDelay,
        };
    }

    prepareSystems(oldState: IAskMeSystems = [], newState: IAskMeSystems = []): IAskMeSystems {
        const removeAssignSystems = oldState
            .filter((oldSys) => {
                return !newState.some((newSys) => oldSys.guid === newSys.guid);
            })
            .map((s) => {
                return { ...s, isActive: false };
            });

        const addSystems = newState
            .filter((newSys) => {
                return !oldState.some((oldSys) => newSys.guid === oldSys.guid && isEqual(newSys.segment_ids, oldSys.segment_ids));
            })
            .map((s) => {
                return { ...s, isActive: true };
            });

        return addSystems.concat(removeAssignSystems);
    }

    prepareSlackChannels(
        oldState: IAskMeIntegrationSystems = [],
        newState: IAskMeIntegrationSystemForm[] = [],
        integration: IIntegration = null
    ): IAskMeSystems {
        const getChannelName = (channelGuid: string) => {
            return integration.slackChannels?.find((channel) => channel.channelIntegrationGuid === channelGuid).channelName;
        };
        if (integration) {
            const removeChannels = oldState
                .filter((oldChannel) => !newState.some((newChannel) => newChannel.channelGuid === oldChannel.channelGuid))
                .map((oldChannel) => ({
                    guid: oldChannel.channelGuid,
                    isActive: false,
                    name: getChannelName(oldChannel.channelGuid),
                    type: integration.type,
                }));

            const addChannels = newState
                .filter((newChannel) => {
                    const found = oldState.find((oldChannel) => oldChannel.channelGuid === newChannel.channelGuid);
                    return !found || found.status === ChannelStatus.DENIED;
                })
                .map((newChannel) => ({
                    guid: newChannel.channelGuid,
                    isActive: true,
                    name: getChannelName(newChannel.channelGuid),
                    type: integration.type,
                }));

            return addChannels.concat(removeChannels);
        }
        return [];
    }
    prepareSlackRules(rules: Array<any>): IIntegrationRules {
        if (!rules || rules.length === 0) {
            return {};
        }
        return rules.reduce((acc, rule) => {
            if (rule.values && rule.values.length > 0) {
                acc[rule.ruleType] = rule.values;
                return acc;
            }
            return acc;
        }, {});
    }

    async handleSubmit({ values, instanceData, integrations }: IHandleSubmit): Promise<void> {
        if (values.isOmni) {
            values.systems = [];
        }
        if (!instanceData) {
            await this.save(values, integrations);
        } else {
            await this.update(instanceData, values, integrations);
        }
    }

    async save(values: IAskMeForm, integrations: any): Promise<void> {
        const settings = this.prepareSettings(values.settings);
        const slackSystems = this.prepareSlackChannels([], values.slackSystems, integrations.slack);
        const slackRules = this.prepareSlackRules(values.slackRules);
        const teamsSystems = this.prepareSlackChannels([], values.teamsSystems, integrations.teams);
        const teamsRules = this.prepareSlackRules(values.teamsRules);
        const data = {
            ...values,
            settings,
            slackSystems,
            slackRules,
            teamsSystems,
            teamsRules,
        };

        try {
            await this.dispatch(createAskMe(data));
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `You’ve successfully added ${values.name}`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to create Content.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    async update(askMeIntegration: IAskMe, values: IAskMeForm, integrations: any): Promise<void> {
        const rescan = values.rescan;
        delete values.rescan;
        const newValues = this.prepareSettings(values.settings);
        const systems = this.prepareSystems(askMeIntegration.systems, values.systems);
        const slackSystems = this.prepareSlackChannels(askMeIntegration.slackSystems, values.slackSystems, integrations.slack);
        const slackRules = this.prepareSlackRules(values.slackRules);
        const teamsSystems = this.prepareSlackChannels(askMeIntegration.teamsSystems, values.teamsSystems, integrations.teams);
        const teamsRules = this.prepareSlackRules(values.teamsRules);
        const integrationsData = {
            slackSystems,
            slackRules,
            teamsSystems,
            teamsRules,
        };
        try {
            await this.dispatch(updateAskMe(askMeIntegration.sourceId, { ...values, settings: newValues, systems, ...integrationsData }));
            if (!isEqual(askMeIntegration.settings?.auth, newValues.auth)) {
                setLocalStorage(`scan-msg.${askMeIntegration.jobStatus?.executionId}`, 'true');
            }
            if (rescan) {
                if (askMeIntegration.isExecuting) {
                    await this.dispatch(stopScan(askMeIntegration.sourceId));
                }
                await this.dispatch(executeAskMe(askMeIntegration.sourceId));
            }
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `You’ve successfully edited ${values.name}`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong, failed to update ${askMeIntegration.name}`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    getFormLabels(): Array<string> {
        return ['Domain', 'Settings', 'Access', 'Segments'];
    }

    getTabsLabels(): Array<string> {
        return ['Domain', 'Access', 'Segments'];
    }

    getFormHeader(integration: IAskMe = null, formMode): IHeaderText {
        switch (formMode) {
            case FormMode.STEPPER:
                return { header: 'Add Data Source', subHeader: null };
            case FormMode.TABS:
                return { header: `Edit ${integration.name}`, subHeader: null };
        }
    }

    getFormByStep(step: number, triggerModal: () => void): React.ReactNode {
        switch (step) {
            case 0:
                return <DomainStep />;
            case 1:
                return <SettingsStep />;
            case 2:
                return <AssignSystemsTabs triggerModal={triggerModal} formMode={FormMode.STEPPER} />;
            case 3:
                return <AssignSegmentsTab triggerModal={triggerModal} formMode={FormMode.STEPPER} />;
        }
    }

    getFormByTab(step: number, instanceData: IAskMe | null, triggerModal: () => void): React.ReactNode {
        return (
            <>
                <TabPanel value={step} index={0}>
                    <DomainStep />
                </TabPanel>
                <TabPanel value={step} index={1}>
                    <AssignSystemsTabs triggerModal={triggerModal} formMode={FormMode.TABS} />
                </TabPanel>
                <TabPanel value={step} index={2}>
                    <AssignSegmentsTab triggerModal={triggerModal} formMode={FormMode.TABS} />
                </TabPanel>
            </>
        );
    }

    getStepperFooter(step: number): React.ReactNode {
        const formLabels = this.getStepperLabels();
        return (
            <CreateSourceFooter
                step={step}
                stepsCount={formLabels.length}
                onClose={this.closeForm}
                changeActiveStep={this.changeActiveStep}
                showSystems={formLabels[step] == 'Select Systems'}
            />
        );
    }

    getTabsFooter(): React.ReactNode {
        return <EditCrawlerFooter onClose={this.closeForm} />;
    }

    async validate(values: IAskMeForm, step: number, formMode: FormMode) {
        let schema;
        if (formMode === FormMode.ONE_PAGE || (formMode === FormMode.STEPPER && step === 1)) {
            schema = settingsSchema;
        } else if (step === 0) {
            schema = domainSchema;
        } else {
            return null;
        }
        const providerSettingsErrors = await getErrorObject(schema, values);
        if (providerSettingsErrors) {
            return providerSettingsErrors;
        }
        return null;
    }

    getRules(integrationRules: IIntegrationRules) {
        if (!integrationRules || Object.keys(integrationRules).length === 0) {
            return [{ ruleType: RuleType.START_WITH, values: [], ruleText: '' }];
        }
        const rules = [];
        for (const ruleType in integrationRules) {
            rules.push({ ruleType: ruleType, values: [...integrationRules[ruleType]], ruleText: '' });
        }
        return rules;
    }

    initForm({ data: integration }: { data: IAskMe }): IAskMeForm {
        if (integration?.jobStatus?.jobStatus === ScrapeStatus.MISSING_CREDS) {
            this.isAuthError = true;
        }
        return {
            name: get(integration, 'name', ''),
            type: get(integration, 'type', JobType.WEB),
            settings: {
                startingUrl: get(integration, 'settings.startingUrl', ''),
                includeSubDomain: get(integration, 'settings.includeSubDomain', false),
                controlDomains: get(integration, 'settings.controlDomains', false),
                allowPath: get(integration, 'settings.allowPath', []),
                denyPath: get(integration, 'settings.denyPath', []),
                cssInclude: get(integration, 'settings.cssInclude', ''),
                cssExclude: get(integration, 'settings.cssExclude', ''),
                authType: get(integration, 'settings.authType', AuthType.NO_AUTH),
                controlCss: get(integration, 'settings.controlCss', false),
                delay: get(integration, 'settings.delay', 2),
                controlDelay: `${get(integration, 'settings.controlDelay', false)}`,
                auditScan: get(integration, 'settings.auditScan', { frequency: ScanFrequency.NONE }),
                auth: get(integration, 'settings.auth', {}),
                includeHash: get(integration, 'settings.includeHash', false),
                slackChannelsConfig: get(integration, 'settings.slackChannelsConfig', IntegrationChannelOptions.DISABLED),
                teamsChannelsConfig: get(integration, 'settings.teamsChannelsConfig', IntegrationChannelOptions.DISABLED),
            },
            systems: get(integration, 'systems', []),
            slackSystems: get(integration, 'slackSystems', []),
            slackRules: this.getRules(get(integration, 'slackRules.rules', {})),
            teamsSystems: get(integration, 'teamsSystems', []),
            teamsRules: this.getRules(get(integration, 'teamsRules.rules', {})),
            isOmni: get(integration, 'isOmni', undefined) || undefined,
            isPrivate: get(integration, 'isPrivate', undefined),
        };
    }
}
