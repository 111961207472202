import { useSelector } from 'react-redux';
import { SsoConfigurationState } from '../redux/sso-configuration.slice';

export const useOneId= () => {
  const oneIdData = useSelector((state: { ssoConfigurationState: SsoConfigurationState }) => state.ssoConfigurationState.oneId);

  return {
    oneIdData
  };
};

export default useOneId;
