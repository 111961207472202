import { SystemMatcher, SystemMatchers } from '@walkme-admin-center/libs/exposure-api';
import { ValidationResult } from 'packages/pages/util/validation';
import { System } from 'wm-accounts-sdk';

const ENVIRONMENT_NAME_REGEX = /^[a-zA-Z0-9]*$/;
const ENVIRONMENT_NAME_MAX_LENGTH = 100;

const getAllSelfUrlsOfEnvironment = (systemMatchers: SystemMatchers, envId: number | undefined): string[] => {
    const urls: string[] = [];

    if (envId === undefined || !systemMatchers[envId]) {
        return urls;
    }

    systemMatchers[envId].forEach((systemMatcher: SystemMatcher) => {
        const isRegexMode = !!systemMatcher.isRegex;

        if (!isRegexMode && systemMatcher.selfDomains) {
            urls.push(...systemMatcher.selfDomains);
        } else if (isRegexMode && systemMatcher.selfRegex) {
            urls.push(systemMatcher.selfRegex);
        }
    });

    return urls;
};

const validateNewEnvironmentValue = (value: string): ValidationResult => {
    if (value.length > ENVIRONMENT_NAME_MAX_LENGTH) {
        return { isValid: false, errorMessage: 'Environment name cannot exceed 100 characters' };
    } else if (!ENVIRONMENT_NAME_REGEX.test(value)) {
        return { isValid: false, errorMessage: 'Environment name cannot contain special characters' };
    }

    return { isValid: true };
};

const shouldShowExtensionPartControls = (system: System) => system.systemTypeKey === 'Web';

export { getAllSelfUrlsOfEnvironment, validateNewEnvironmentValue, shouldShowExtensionPartControls };
