import Alert, { Color } from "@material-ui/lab/Alert/Alert";
import React from "react";
import { useStyles } from "./styles";

interface CustomAlertProps {
    message: string;
    severity: Color;
};

export const CustomAlert = ({ message, severity }: CustomAlertProps) => {
    const classes = useStyles();

    return (
        <Alert severity={severity} className={classes[severity] || classes.default}>{message}</Alert>
    );
};

export default CustomAlert;