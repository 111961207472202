import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Confirm, Dialog } from '@walkme-admin-center/libs/common';
import { UserForm, UserFormValues } from './user-form/user-form';
import { AppData, FormActions } from '@walkme-admin-center/libs/types';
import { DeleteUserDto, MfaSettings, User, WMAccountIdp } from 'wm-accounts-sdk';
import { Delete, Edit } from '@walkme/ui-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteUser,
    editUser,
    saveUser,
    sendConfirmationMail,
    useLoggedInUser,
    usersSlice,
    UsersState,
    resetUserMfaSettings,
    deleteUsers,
} from '@walkme-admin-center/libs/state-management-users';
import {
    WMSnackbar,
    WMSnackbarVariant,
    WMTooltip,
    WMStatusDot,
    WMDataGridWrapperProps,
    WMTagProps,
    WMTagVariant,
    WMDataGridActionColumn,
} from '@walkme/wm-ui';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import moment from 'moment';
import {
    StyledGridTitle,
    StyledWMDataGrid,
    StyledStatusDiv,
    StyledWmTagList,
    UserRowEmail,
    UserRowStatus,
    WmErrorColor,
} from './users-tab.styles';
import { useUserTabData } from './users-tab.pure';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { momentLocalesMapping } from 'apps/home/src/localization';
import { getUsersStatusMap } from '../utils/users-status';
import { StyledUserDisplay } from '../styles/styles';
import { PartnerUserIndication } from './users-tab.utils';
import { ColDef } from '@ag-grid-community/core';
import { dateComparator } from 'packages/libs/ui-components/src/lib/data-table/utils';
import { useDatagridLocale } from 'packages/pages/util/locale-utils';
import { ColIds, USERS_COLUMNS_STATE } from './users-tab.lib';
import { DeleteButton, ExportButton, MultipleSelectionFooter } from '@walkme-admin-center/libs/ui-components';
import { auditEventProcessorApi } from '../../../../../../libs/audit-event-processor-api/src';

export const UsersTab = ({ loadingData, pageData }) => {
    const { notificationsMessagesKeys } = useUserTabData();
    const { t, rt, i18n } = useTranslation('general');
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [formTitle, setFormTitle] = useState('');
    const [formActions, setFormActions] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const savedUserAppData = useSelector((state: { usersState: UsersState }) => state.usersState.savedUser);
    const deletedUserAppData = useSelector((state: { usersState: UsersState }) => state.usersState.deletedUser);
    const deletedUsersAppData = useSelector((state: { usersState: UsersState }) => state.usersState.deletedUsers);
    const confirmationEmailStatus = useSelector((state: { usersState: UsersState }) => state.usersState.sendEmailConfirmationStatus);
    const mfaSettingsAppData: AppData<MfaSettings> = pageData.mfaSettingsAppData;
    const resetMfaStatus = useSelector((state: { usersState: UsersState }) => state.usersState.resetUserMfa);
    const { loggedInUserAppData, accountFeatureEnabled } = useLoggedInUser();
    const loggedInUserIdpId = loggedInUserAppData.data && loggedInUserAppData.data.attachedIdpId;
    const loggedInUserId = loggedInUserAppData?.data?.id;
    const shouldShowPassword = accountFeatureEnabled('avoidEmailVerfication');
    const accountIdpsAppData: AppData<WMAccountIdp[]> = pageData.accountIdpsAppData;
    const currentSnackbarMessage = useSelector((state: { usersState: UsersState }) => state.usersState.notificationMessage);
    const dispatch = useDispatch();
    const { UserCreatedMessage, UserCreatedWithVerificationMailMessage, EmailVerificationMessage, ResetMfaMessage } =
        notificationsMessagesKeys;
    const closeAllDialogs = () => {
        setShowDialog(false);
        setShowConfirmDialog(false);
    };

    const MenuIcon: JSX.Element = <img src={`/assets/icons/menuIcon.svg`} width='20px' height='20px' />;

    const onGridReady = useCallback((event) => {
        setGridApi(event.api);

        try {
            const savedColumnState = JSON.parse(localStorage.getItem(USERS_COLUMNS_STATE));
            if (savedColumnState) {
                event.api?.columnModel?.applyColumnState(savedColumnState);
            }
        } catch (e) {
            console.error('Failed to parse JSON when getting users column state from local storage for onGridReady', e);
        }
    }, []);

    const datagridLocale = useDatagridLocale();

    useEffect(() => {
        moment.locale(momentLocalesMapping[i18n.language]);
    }, [i18n.language]);

    const getHideState = (colId) => {
        try {
            const savedColumnState = JSON.parse(localStorage.getItem(USERS_COLUMNS_STATE));
            const column: any = savedColumnState?.find((col) => col.colId === colId);
            return column?.hide;
        } catch (e) {
            console.error(
                `Failed to parse JSON when getting users column state from local storage for getHideState, column id {${colId}}`,
                e
            );
        }
    };

    const headCells: ColDef[] = useMemo(() => {
        return [
            {
                headerName: t('common.email'),
                colId: ColIds.email,
                lockPosition: true,
                minWidth: 300,
                sortable: true,
                hide: getHideState(ColIds.email) ?? false,
                checkboxSelection: true,
                valueGetter: function (params) {
                    return params.data.email;
                },
                cellRenderer: function (params) {
                    return (
                        <StyledUserDisplay>
                            <UserRowEmail>{params.data.email}</UserRowEmail>
                            {params.data.userAccountTag === 'partner' ? <PartnerUserIndication /> : null}
                        </StyledUserDisplay>
                    );
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
                    return nodeA?.data?.email?.localeCompare(nodeB?.data?.email);
                },
            },
            {
                headerName: t('common.name'),
                colId: ColIds.name,
                minWidth: 150,
                hide: getHideState(ColIds.name) ?? true,
                sortable: true,
                valueGetter: function (params) {
                    return `${params.data.firstName} ${params.data.lastName}`;
                },
            },
            {
                headerName: t('common.role'),
                colId: ColIds.role,
                minWidth: 150,
                hide: getHideState(ColIds.role) ?? false,
                sortable: true,
                valueGetter: function (params) {
                    return params.data.role?.displayName;
                },
            },
            {
                headerName: t('users-and-roles-tab.users.table.headers.assigned-systems'),
                colId: ColIds.systems,
                hide: getHideState(ColIds.systems) ?? false,
                minWidth: 300,
                cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
                valueGetter: function (params) {
                    return params.data.systems?.map((system) => system.displayName).toString() || t('common.not-applicable');
                },
                cellRenderer: function (params) {
                    const systemsLabels: WMTagProps[] =
                        params.data.systems && params.data.systems.length > 0
                            ? params.data.systems.map((system) => ({
                                  labelText: system.displayName,
                                  fullLabel: true,
                                  variant: WMTagVariant.RoyalBlue,
                              }))
                            : [];
                    return params.data.systems ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <StyledWmTagList labels={systemsLabels} maxTagsShown={3} />
                        </div>
                    ) : (
                        <div> {t('common.not-applicable')} </div>
                    );
                },
            },
            {
                headerName: t('common.login-method'),
                colId: ColIds.loginMethod,
                minWidth: 150,
                hide: getHideState(ColIds.loginMethod) ?? false,
                sortable: true,
                valueGetter: function (params) {
                    const selectedIdp =
                        params.data.attachedIdpId &&
                        accountIdpsAppData.data &&
                        accountIdpsAppData.data.find((accountIdp) => accountIdp.idpId === params.data.attachedIdpId);
                    if (selectedIdp) {
                        return selectedIdp.isOldIdpFlow ? 'WM Legacy SSO' : selectedIdp.idpName;
                    }
                    return mfaSettingsAppData.data && mfaSettingsAppData.data.isEnabled ? 'Password + MFA' : 'Password';
                },
            },
            {
                headerName: t('common.last-login'),
                colId: ColIds.lastLogin,
                minWidth: 200,
                hide: getHideState(ColIds.lastLogin) ?? false,
                sortable: true,
                comparator: dateComparator('data.lastLoginDate'),
                valueGetter: function (params) {
                    const timestamp = params.data.lastLoginDate ? new Date(params.data.lastLoginDate).getTime() : 0;
                    const time = timestamp && moment(timestamp).format('MMM Do YYYY H:mm');
                    return time || t('common.not-applicable');
                },
            },
            {
                headerName: t('common.status'),
                colId: ColIds.status,
                minWidth: 200,
                hide: getHideState(ColIds.status) ?? false,
                sortable: true,
                comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
                    return nodeA?.data?.status?.localeCompare(nodeB?.data?.status);
                },
                valueGetter: function (params) {
                    const usersStatusMap = getUsersStatusMap(t);
                    const mappedStatus = usersStatusMap.find((mappedStatus) => mappedStatus.id == params.data.status);

                    return mappedStatus ? mappedStatus.display : t('common.not-applicable');
                },
                cellRenderer: function (params) {
                    const usersStatusMap = getUsersStatusMap(t);
                    const mappedStatus = usersStatusMap.find((mappedStatus) => mappedStatus.id == params.data.status);
                    return (
                        <WMTooltip title={mappedStatus.info}>
                            <StyledStatusDiv>
                                {mappedStatus && <WMStatusDot variant={mappedStatus.color} />}
                                <UserRowStatus>{mappedStatus ? mappedStatus.display : t('common.not-applicable')}</UserRowStatus>
                            </StyledStatusDiv>
                        </WMTooltip>
                    );
                },
            },
            {
                colId: ColIds.actions,
                lockPosition: 'right',
                pinned: 'right',
                suppressSizeToFit: true,
                width: 120,
                filter: false,
                cellRenderer: WMDataGridActionColumn,
                cellRendererParams: {
                    actions: [
                        {
                            label: t('buttons.edit-user'),
                            icon: <Edit />,
                            onClick: (params) => handleUserSelection(params),
                        },
                        {
                            label: <WmErrorColor>{t('buttons.delete-user')}</WmErrorColor>,
                            icon: <Delete color={'var(--wmError)'} />,
                            onClick: (params) => handleUserDeletionClick(params),
                        },
                    ],
                    icon: MenuIcon,
                },
            },
        ];
    }, [accountIdpsAppData, mfaSettingsAppData]);

    useEffect(() => {
        if (savedUserAppData.data && savedUserAppData.data.id) {
            const snackBarMessageTxt = !!selectedUser
                ? t('users-and-roles-tab.users.success-message-edit-user')
                : shouldShowPassword
                ? t(UserCreatedMessage)
                : t(UserCreatedWithVerificationMailMessage);
            dispatch(
                usersSlice.actions.setNotificationMessage({
                    text: snackBarMessageTxt,
                    variant: WMSnackbarVariant.Success,
                    isOpen: true,
                })
            );
            setShowDialog(false);
            setShowConfirmDialog(false);
            dispatch(usersSlice.actions.saveUserCleanup());
        }
    }, [savedUserAppData, selectedUser]);

    useEffect(() => {
        if (deletedUserAppData.data) {
            setShowDialog(false);
            setShowConfirmDialog(false);
            dispatch(usersSlice.actions.deleteUserCleanup());
        }
    }, [deletedUserAppData]);

    useEffect(() => {
        if (deletedUsersAppData.data) {
            dispatch(usersSlice.actions.deleteUsersCleanup());
        }
    }, [deletedUsersAppData]);

    useEffect(() => {
        if (confirmationEmailStatus.data) {
            setShowDialog(false);
            dispatch(
                usersSlice.actions.setNotificationMessage({
                    text: t(EmailVerificationMessage),
                    variant: WMSnackbarVariant.Success,
                    isOpen: true,
                })
            );
            dispatch(usersSlice.actions.resendConfirmationMailCleanup());
        }
    }, [confirmationEmailStatus]);

    useEffect(() => {
        if (resetMfaStatus.data) {
            dispatch(
                usersSlice.actions.setNotificationMessage({ text: t(ResetMfaMessage), variant: WMSnackbarVariant.Success, isOpen: true })
            );
            dispatch(usersSlice.actions.resetUserMfaCleanup());
        }
    }, [resetMfaStatus]);

    const onResetUserMfaOptionsClicked = useCallback(
        (userId: number) => {
            dispatch(resetUserMfaSettings(userId));
        },
        [dispatch]
    );

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(usersSlice.actions.cleanUpNotificationMessage());
    };

    const onConfirmNewUser = useCallback(
        (values: UserFormValues) => {
            if (values.editingUser) {
                dispatch(
                    editUser({
                        subUser: {
                            idpNameId: values.ssoID,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            userId: values.editingUser.id,
                            roleId: values.role && (values.role.value as number),
                            systemIds: values.systems.map((systemRow) => systemRow.value as number),
                            attachToIdpId:
                                values.attachToIdpId && values.attachToIdpId.value !== 'password'
                                    ? (values.attachToIdpId.value as string)
                                    : null,
                            deatchFromIdp: values.attachToIdpId && values.attachToIdpId.value === 'password',
                            hasBusinessDomain: values.hasBusinessDomain?.value as boolean,
                            businessDomainId: values.businessDomainId?.value as number,
                        },
                    })
                );
            } else {
                dispatch(
                    saveUser({
                        subUser: {
                            idpNameId: values.ssoID,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            password: values.password,
                            email: values.email,
                            roleId: values.role && (values.role.value as number),
                            systemIds: values.systems.map((systemRow) => systemRow.value as number),
                            attachToIdpId:
                                values.attachToIdpId && values.attachToIdpId.value !== 'password'
                                    ? (values.attachToIdpId.value as string)
                                    : null,
                            avoidAttachToDefaultIdp: values.attachToIdpId && values.attachToIdpId.value === 'password',
                        },
                    })
                );
            }
        },
        [dispatch]
    );

    const cancel = useCallback(
        (pristine: boolean) => {
            //cancel
            if (!pristine) {
                setShowConfirmDialog(true);
            } else setShowDialog(false);
        },
        [setShowDialog, setShowConfirmDialog]
    );

    const onResendMail = useCallback(
        (values: UserFormValues) => {
            dispatch(sendConfirmationMail(values.editingUser.id));
        },
        [dispatch]
    );

    const onConfirmDeleteUser = useCallback(
        (values: UserFormValues) => {
            dispatch(
                deleteUser({
                    subUser: {
                        subUserId: values.editingUser.id,
                    },
                })
            );
        },
        [dispatch]
    );

    const triggerUserFormDialog = useCallback(
        (actions: FormActions, title: string, user?: User) => {
            return (
                <Dialog
                    view='sideScreen'
                    children={
                        <UserForm
                            user={user}
                            actions={actions}
                            roles={pageData.roles}
                            systems={pageData.systems}
                            title={title}
                            idpId={user ? user.attachedIdpId : loggedInUserIdpId}
                            deletingUser={deletedUserAppData.loading}
                            submittingUser={savedUserAppData.loading}
                            resettingMfaOptions={resetMfaStatus.loading}
                            onResetUserMfaOptionsClicked={onResetUserMfaOptionsClicked}
                            isMfaEnabled={mfaSettingsAppData.data && mfaSettingsAppData.data.isEnabled}
                            shouldShowPassword={shouldShowPassword}
                            accountIdps={accountIdpsAppData.data}
                        />
                    }
                    onClose={null}
                    showDialog={showDialog}
                />
            );
        },
        [showDialog, pageData.roles, pageData.systems, savedUserAppData, deletedUserAppData, resetMfaStatus, mfaSettingsAppData]
    );

    const handleUserSelection = useCallback(
        (user) => {
            const actions: FormActions = { onConfirm: onConfirmNewUser, onCancel: cancel };
            if (user.id) actions.onDelete = onConfirmDeleteUser;
            if (user.status == 'PROVISIONED') actions.onResend = onResendMail;
            setSelectedUser(user);
            setFormTitle(t('users-and-roles-tab.users.edit-user-form.title'));
            setFormActions(actions);
            setShowDialog(true);
        },
        [cancel, onConfirmDeleteUser, pageData, onConfirmNewUser, onResendMail]
    );

    const handleExportUsersToCsv = (userRows) => {
        const dataToExport = userRows.map((user) => {
            const timestamp = user.lastLoginDate ? new Date(user.lastLoginDate).getTime() : 0;
            const time = timestamp && moment(timestamp).format('MMM Do YYYY H:mm');
            return {
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                role: rt(user.role.displayName),
                system: user.systems.map((system) => system.displayName).toString(),
                lastLogin: time || t('common.not-applicable'),
                status: user.status,
            };
        });

        const csv = Papa.unparse(dataToExport, { escapeFormulae: true });
        const blob = new Blob([csv]);
        FileSaver.saveAs(blob, `users_list.csv`);
    };

    const handleUserDeletionClick = (rowToDelete) => {
        const deleteUsersDto: DeleteUserDto = {
            subUser: {
                subUserId: rowToDelete.id,
            },
        };
        dispatch(deleteUser(deleteUsersDto));
    };

    const handleUsersDeletionClick = (rowsToDelete) => {
        const deleteUsersDto: DeleteUserDto[] = rowsToDelete.map((rowToDelete) => ({
            subUser: {
                subUserId: rowToDelete.id,
            },
        }));
        dispatch(deleteUsers(deleteUsersDto));
    };

    const deleteButton: DeleteButton = {
        onClick: (rows) => {
            handleUsersDeletionClick(rows);
        },
        loading: deletedUsersAppData.loading,
        disabled: deletedUsersAppData.loading,
        title: t('users-and-roles-tab.users.buttons.delete-users'),
    };

    const exportButton: ExportButton = {
        onClick: (rows) => {
            handleExportUsersToCsv(rows);
        },
    };

    const getRowId = useCallback((params) => params.data.id, []);

    const onColumnVisible = useCallback((event) => {
        if (event.source === 'api') {
            const columnState = event.api?.columnModel?.getColumnState();
            localStorage.setItem(USERS_COLUMNS_STATE, JSON.stringify(columnState));
        }

        event.api.sizeColumnsToFit();
    }, []);

    const gridConfig: WMDataGridWrapperProps<any> = useMemo(() => {
        return {
            getRowId,
            onColumnVisible: onColumnVisible,
            gridOptions: {
                onRowSelected: (params) => {
                    const newSelectedRows = params.api.getSelectedRows();
                    setSelectedRows(newSelectedRows);
                },
            },
            onGridReady,
        };
    }, []);

    const sendAuditInternalEvent = async (action, actionType) => {
        const body = {
            actionType: actionType,
            timestamp: new Date(),
            eventAdditionalInfo: {
                action: action,
                object: {
                    id: loggedInUserId,
                    type: 'User',
                },
            },
        };

        try {
            await auditEventProcessorApi.sendInternalEvent(body);
        } catch (error) {
            console.error('Failed to send audit event:', error);
        }
    };

    const addLoggingToExportUsersAction = () => {
        if (gridApi) {
            const originalExportDataAsCsv = gridApi.exportDataAsCsv;
            const originalExportDataAsExcel = gridApi.exportDataAsExcel;
            const action = 'Export Users report';
            const actionType = 'export_users';

            gridApi.exportDataAsCsv = (...args) => {
                originalExportDataAsCsv.apply(gridApi, args);
                sendAuditInternalEvent(action, actionType);
            };

            gridApi.exportDataAsExcel = (...args) => {
                originalExportDataAsExcel.apply(gridApi, args);
                sendAuditInternalEvent(action, actionType);
            };
        }
    };

    useEffect(() => {
        addLoggingToExportUsersAction();
    }, [gridApi, loggedInUserId]);

    return (
        <div style={{ height: '73vh' }}>
            {showDialog && triggerUserFormDialog(formActions, formTitle, selectedUser)}
            {showConfirmDialog && (
                <Confirm
                    confirm={closeAllDialogs}
                    closeConfirm={() => setShowConfirmDialog(false)}
                    showConfirm={showConfirmDialog}
                    title={t('users-and-roles-tab.users.unsaved-changes-title')}
                    content={t('users-and-roles-tab.users.unsaved-changes-text')}
                />
            )}
            <StyledWMDataGrid
                title={<StyledGridTitle>{t('users-and-roles-tab.users.title')}</StyledGridTitle>}
                locale={datagridLocale}
                rows={pageData.users}
                columnDefs={headCells}
                entityName={t('common.user')}
                gridConfig={gridConfig}
                loadingData={loadingData}
                supressResetOnNewData={true}
                columnPickerOptions={{
                    persistColumnIds: [
                        `${ColIds.email}`,
                        `${ColIds.actions}`, // Defined here so it won't be visible in column picker even though it's not a visible column
                    ],
                }}
            />
            {selectedRows.length > 0 && (
                <MultipleSelectionFooter
                    onCancel={() => {
                        gridApi.deselectAll();
                        setSelectedRows([]);
                    }}
                    numSelectedRows={selectedRows.length}
                    entityName={t('common.user')}
                    getSelectedRows={() => selectedRows}
                    exportButton={exportButton}
                    deleteButton={deleteButton}
                />
            )}
            <WMSnackbar
                open={currentSnackbarMessage.isOpen}
                variant={currentSnackbarMessage.variant}
                message={currentSnackbarMessage.text}
                onClose={handleSnackBarClose}
            />
        </div>
    );
};

export default UsersTab;
