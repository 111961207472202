import styled from 'styled-components';
import { Box, Dialog, DialogContent } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
    border-radius: 15px;
    overflow-y: hidden;
`;

export const StyledPaper = styled(DialogContent)`
    overflow-y: visible;
    margin: 0 32px;
    padding: 0;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

export const StyledCardsContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledCategoryContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const StyledCardsPlatformItems = styled.div`
    height: 280px;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    gap: 25px;
    align-items: center;
    justify-content: center;
`;

export const StyledPlatformItem = styled(Box)<{ selected: boolean }>`
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    border: 1px solid #dde5f5;
    border-radius: 10px;
    flex-direction: column;
    height: 100%;
    max-width: 440px;
    cursor: pointer;

    ${({ selected }) =>
        selected &&
        `
        border: 1px #3b61eb solid;
    `}

    &:hover {
        box-shadow: 0px 4px 12px rgba(47, 61, 83, 0.11);
    }
`;

export const StyledDisabled = styled(Box)`
    border: 1px solid #dde5f5;
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    flex-direction: column;
    height: 100%;
    max-width: 440px;
    cursor: not-allowed;
`;

export const StyledPlatform = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledPlatformWeb = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

export const StyledCardsSystemItems = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    align-content: flex-start;
    height: 64px;
`;

export const StyledSystemItem = styled(Box)<{ selected: boolean }>`
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    border: 1px solid #dde5f5;
    border-radius: 10px;
    flex-direction: column;
    height: 100%;
    width: 210px;
    max-width: 210px;
    cursor: pointer;

    ${({ selected }) =>
        selected &&
        `
        border: 1px #3B61EB solid;
    `}

    &:hover {
        box-shadow: 0px 4px 12px rgba(47, 61, 83, 0.11);
    }
`;
export const StyledSystemContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 21vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--wmGrayLight);
        border: 2px solid var(--wmWhite);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--wmWhite);
        border-radius: 4px;
    }
`;

export const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledRootChip = styled.div`
    background-color: #41b7a6 !important;
    color: #ffffff !important;
    margin-top: 5px;
`;
