import React, { useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme, OutlinedInput, Typography, Select, MenuItem, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@walkme-admin-center/libs/ui-components';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import { AppIdentifier } from './types/app-identifier';
import styled from 'styled-components';
import IdentifierRowErrorMessage from './identifier-row-error-message';

enum MacIdentifierType {
    APP_NAME = 'appName',
    BUNDLE_ID = 'bundleId'
};

interface MacIdentifierRowProps {
    identifier: AppIdentifier;
    index: number;
    onUpdate: (index: number, identifier: AppIdentifier) => void;
    onDelete: (index: number) => void;
}

export const StyledSelect = styled(Select)`
    .MuiSelect-root {
        align-items: center;
        display: flex;
        padding: 3px 0 3px 14px;
        background: white;
        width: 162px;
    }
`;

const identifiersKeys = [
    {
        key: MacIdentifierType.BUNDLE_ID,
        value: 'Bundle ID'
    },
    {
        key: MacIdentifierType.APP_NAME,
        value: 'Application Name'
    }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    identifierWrapper: {
      marginBottom: '10px'
    },
    identifierInput: {
      height: '35px',
      backgroundColor: '#FFFF',
      marginLeft: '10px'
    },
    inputError: {
      border: `1px solid ${styledTheme.colors.errorColor}`
    },
    deleteButton: {
      marginBottom: '3px !important'
    }
  })
);

const MacIdentifierRow = ({
    identifier,
    index,
    onUpdate,
    onDelete
}: MacIdentifierRowProps) => {
    const [ isChanged, setIsChange ] = useState<boolean>(false);
    const [configType, setConfigType] = useState<MacIdentifierType>(identifier.simpleIdentifier ? MacIdentifierType.APP_NAME : MacIdentifierType.BUNDLE_ID);
    const configValue = useMemo(() => identifier.simpleIdentifier || identifier.advancedIdentifier, [identifier]);

    const classes = useStyles();

    const onConfigTypeChange = (event) => {
        const newConfigType = event.target.value;
        const newIdentifier = {...identifier};

        newIdentifier.simpleIdentifier = '';
        newIdentifier.advancedIdentifier = '';

        setIsChange(true);
        setConfigType(newConfigType);
        onUpdate(index, newIdentifier);
    };

    const onConfigValueChange = (event) => {
        const text = event.target.value;
        const newIdentifier = {...identifier};

        if (configType == MacIdentifierType.APP_NAME) {
            newIdentifier.simpleIdentifier = text;
            newIdentifier.advancedIdentifier = '';
        } else {
            newIdentifier.advancedIdentifier = text;
            newIdentifier.simpleIdentifier = '';
        }

        setIsChange(true);
        onUpdate(index, newIdentifier);
    };

  const displayInvalidClass = isChanged && (identifier.simpleInvalid || identifier.advancedInvalid);
  return (
        <div className={classes.identifierWrapper}>
            <div>
                <StyledSelect
                    name={'identifiers-styled-select'}
                    input={<OutlinedInput name="identifiers" id="identifiers" className="inline" />}
                    onChange={onConfigTypeChange}
                    value={configType}
                    MenuProps={{
                        variant: 'menu',
                        getContentAnchorEl: null,
                    }}
                    displayEmpty
                    className="inline"
                    data-testid="identifiers-select"
                >
                    {identifiersKeys.map((identifier, i) => {
                        return (
                            <MenuItem key={i} value={identifier.key}>
                                <ListItemText primary={identifier.value} />
                            </MenuItem>
                        );
                    })}
                </StyledSelect>
                <OutlinedInput
                    type="text"
                    className={`inline ${classes.identifierInput} ${displayInvalidClass ? classes.inputError : ''}`}
                    value={configValue}
                    onChange={onConfigValueChange}
                    placeholder="Enter Value"
                />
                <Button
                    customClass={`form change inline ${classes.deleteButton}`}
                    onClick={(e) => onDelete(index)}
                >
                    <DeleteIcon />
                </Button>
            </div>
            {isChanged && identifier.errorMessage && (
                <IdentifierRowErrorMessage errorMessage={identifier.errorMessage} />
            )}
        </div>
    );
};

export default MacIdentifierRow;
