import styled from 'styled-components';
import { Toaster } from '@walkme/ui-core';

export const StyledDataCollectionContainer = styled.div`
    height: 100%;
    padding: 0 32px;
    background-color: white;

    .dataCollectionToaster {
        width: unset !important;
    }
`;

export const StyledDataCollectionTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 24px 0 8px 0;

    .main-title {
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 24px;
        font-weight: 600;
    }
`;

export const StyledDataCollectionBody = styled.div`
    margin-bottom: 20px;
`;

export const StyledToasterContainer = styled(Toaster)`
    margin-bottom: 20px;
`;
export const StyledDataCollectionToasterMessage = styled.div`
    a {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;
