import React from 'react';
import { createStyles, makeStyles, Theme, Tooltip as MUITooltip, TooltipProps as MUITooltipProps } from '@material-ui/core';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';

interface TooltipProps extends MUITooltipProps {
    maxWidth?: number;
}

// INFO: IMPORTED ONLY IS DESKTOP-TRACKED-APPS (NOT USED)
const Tooltip = ({ title, placement, children, maxWidth }: TooltipProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            tooltipContent: {
                maxWidth: maxWidth ? `${maxWidth}px` : undefined,
                backgroundColor: '#FFFF',
                color: styledTheme.colors.mainGray,
                fontSize: '14px',
                border: '1px solid #D9D9D9',
                boxSizing: 'border-box',
                borderRadius: '3px',
                boxShadow: '0 2px 2px 0 rgba(10,35,51,0.2)',
            },
            tooltipArrow: {
                color: '#FFFF',
                '&::before': {
                    backgroundColor: '#FFFF',
                    border: '1px solid #D9D9D9',
                    boxSizing: 'border-box',
                    borderRadius: '3px',
                    boxShadow: '0 2px 2px 0 rgba(10,35,51,0.2)',
                },
            },
        })
    );

    const classes = useStyles();

    return (
        <>
            <MUITooltip
                classes={{ tooltip: classes.tooltipContent, arrow: classes.tooltipArrow }}
                arrow={true}
                placement={placement}
                title={title}>
                {children}
            </MUITooltip>
        </>
    );
};

export default Tooltip;
