import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import rootReducer from 'apps/home/src/redux/rootReducer';
import {
    CommonSystemData,
    GetSystemDataResult,
    SystemData,
    GetSystemResult,
    AddSystemData,
    GetSystemsRegexesResultItem,
    SystemMatcher,
    LinkSystemToExtensionData,
    MatcherChanges,
    AddNewMatcherData,
} from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';
import { webSystemsExposureApi } from '@walkme-admin-center/libs/exposure-api';
import {
    analyticsApi,
    CreateSystemEnvironmentDto,
    DeletedSystemEnvironment,
    DeleteSystemEnvironmentDto,
    RestoredSystemEnvironment,
    RestoreSystemEnvironmentDto,
    UpdatedSystemEnvironment,
} from '@walkme-admin-center/libs/analytics-api';
import { setEmptyTopUrlsWithDefaultForMatchPatternModeMatchers } from './utils';

interface WebSystemsState {
    updatedWebSystem: AppData<SystemData>;
    webSystem: AppData<GetSystemResult>;
    createdEnvironment: AppData<UpdatedSystemEnvironment>;
    deletedEnvironment: AppData<DeletedSystemEnvironment>;
    restoredEnvironment: AppData<RestoredSystemEnvironment>;
    addExistingSystemToExtension: AppData<CommonSystemData>;
    getSelfDomainsForSystem: AppData<string[]>;
    getSystemsRegexes: AppData<GetSystemsRegexesResultItem[]>;
    getDefaultSystemMatcher: AppData<SystemMatcher>;
    linkSystemToExtension: AppData<number | undefined>;
    addSystemMatcher: AppData<SystemMatcher | undefined>;
    updateSystemMatcher: AppData<number | undefined>;
}

const initialAddExistingSystemToExtensionState: CommonSystemData = {
    accountName: null,
    accountId: null,
    systemEmail: null,
    name: null,
    systemId: null,
    appId: null,
};

const initialUpdatedSystemState: SystemData = {
    ...initialAddExistingSystemToExtensionState,
    systemTypeKey: null,
    injectionMethod: null,
    environments: null,
    matchers: null,
};

const initialCreatedEnvironmentState: UpdatedSystemEnvironment = {
    name: '',
    action: null,
};

const initialDeletedEnvironmentState: DeletedSystemEnvironment = {
    envId: null,
    action: null,
};

const initialRestoredEnvironmentState: RestoredSystemEnvironment = {
    envId: null,
    action: null,
};

const initialSystemsState: WebSystemsState = {
    updatedWebSystem: {
        data: initialUpdatedSystemState,
        error: null,
        loading: false,
    },
    webSystem: {
        data: {},
        error: null,
        loading: false,
    },
    createdEnvironment: {
        data: initialCreatedEnvironmentState,
        error: null,
        loading: false,
    },
    deletedEnvironment: {
        data: initialDeletedEnvironmentState,
        error: null,
        loading: false,
    },
    restoredEnvironment: {
        data: initialRestoredEnvironmentState,
        error: null,
        loading: false,
    },
    addExistingSystemToExtension: {
        data: initialAddExistingSystemToExtensionState,
        error: null,
        loading: false,
    },
    getSelfDomainsForSystem: {
        data: null,
        error: null,
        loading: false,
    },
    getSystemsRegexes: {
        data: null,
        error: null,
        loading: false,
    },
    getDefaultSystemMatcher: {
        data: null,
        error: null,
        loading: false,
    },
    linkSystemToExtension: {
        data: undefined,
        error: null,
        loading: false,
    },
    addSystemMatcher: {
        data: undefined,
        error: null,
        loading: false,
    },
    updateSystemMatcher: {
        data: undefined,
        error: null,
        loading: false,
    },
};

const webSystemsSlice = createSlice({
    name: 'webSystemsSlice',
    initialState: initialSystemsState,
    reducers: {
        updateSystemStart: (state: WebSystemsState) => {
            state.updatedWebSystem.error = null;
            state.updatedWebSystem.loading = true;
            return state;
        },
        updateSystemSuccess(state: WebSystemsState, action: PayloadAction<SystemData>) {
            state.updatedWebSystem.data = action.payload;
            state.updatedWebSystem.loading = false;
            state.updatedWebSystem.error = null;
            return state;
        },
        updateSystemFailed(state, action: PayloadAction<string>) {
            state.updatedWebSystem.error = action.payload;
            state.updatedWebSystem.loading = false;
            return state;
        },
        cleanupUpdatedSystem: (state: WebSystemsState) => {
            state.updatedWebSystem.data = initialUpdatedSystemState;
            state.updatedWebSystem.error = null;
            state.updatedWebSystem.loading = false;
            return state;
        },
        getSystemStart(state: WebSystemsState) {
            state.webSystem.error = null;
            state.webSystem.loading = true;
            return state;
        },
        getSystemSuccess(state: WebSystemsState, action: PayloadAction<GetSystemResult>) {
            state.webSystem.data = action.payload;
            state.webSystem.loading = false;
            state.webSystem.error = null;
            return state;
        },
        getSystemFailed(state, action: PayloadAction<string>) {
            state.webSystem.data = null;
            state.webSystem.loading = false;
            state.webSystem.error = action.payload;
            return state;
        },
        deleteEnvStart(state: WebSystemsState) {
            state.deletedEnvironment.loading = true;
            state.deletedEnvironment.error = null;
            return state;
        },
        deleteEnvFailed(state, action: PayloadAction<string>) {
            state.deletedEnvironment.loading = false;
            state.deletedEnvironment.error = action.payload;
            return state;
        },
        deleteEnvSuccess(state, action: PayloadAction<DeletedSystemEnvironment>) {
            state.deletedEnvironment.data = action.payload;
            state.deletedEnvironment.error = null;
            state.deletedEnvironment.loading = false;
            return state;
        },
        createNewEnvStart(state: WebSystemsState) {
            state.createdEnvironment.loading = true;
            state.createdEnvironment.error = null;
            return state;
        },
        createNewEnvFailed(state, action: PayloadAction<string>) {
            state.createdEnvironment.loading = false;
            state.createdEnvironment.error = action.payload;
            return state;
        },
        createNewEnvSuccess(state, action: PayloadAction<UpdatedSystemEnvironment>) {
            state.createdEnvironment.data = action.payload;
            state.createdEnvironment.error = null;
            state.createdEnvironment.loading = false;
            return state;
        },
        restoreEnvStart(state: WebSystemsState) {
            state.restoredEnvironment.loading = true;
            state.restoredEnvironment.error = null;
            return state;
        },
        restoreEnvFailed(state, action: PayloadAction<string>) {
            state.restoredEnvironment.loading = false;
            state.restoredEnvironment.error = action.payload;
            return state;
        },
        restoreEnvSuccess(state, action: PayloadAction<RestoredSystemEnvironment>) {
            state.restoredEnvironment.data = action.payload;
            state.restoredEnvironment.error = null;
            state.restoredEnvironment.loading = false;
            return state;
        },
        cleanupCreatedNewEnv: (state: WebSystemsState) => {
            state.createdEnvironment.data = initialCreatedEnvironmentState;
            state.createdEnvironment.error = null;
            state.createdEnvironment.loading = false;
            return state;
        },
        cleanupDeletedEnv: (state: WebSystemsState) => {
            state.deletedEnvironment.data = initialDeletedEnvironmentState;
            state.deletedEnvironment.error = null;
            state.deletedEnvironment.loading = false;
            return state;
        },
        cleanupRestoredEnv: (state: WebSystemsState) => {
            state.restoredEnvironment.data = initialRestoredEnvironmentState;
            state.restoredEnvironment.error = null;
            state.restoredEnvironment.loading = false;
            return state;
        },
        addExistingSystemToExtensionStart: (state: WebSystemsState) => {
            state.addExistingSystemToExtension.error = null;
            state.addExistingSystemToExtension.loading = true;
            return state;
        },
        addExistingSystemToExtensionSuccess(state: WebSystemsState, action: PayloadAction<AddSystemData>) {
            state.addExistingSystemToExtension.data = action.payload;
            state.addExistingSystemToExtension.loading = false;
            state.addExistingSystemToExtension.error = null;
            return state;
        },
        addExistingSystemToExtensionFailed(state, action: PayloadAction<string>) {
            state.addExistingSystemToExtension.error = action.payload;
            state.addExistingSystemToExtension.loading = false;
            return state;
        },
        cleanupAddExistingSystemToExtension: (state: WebSystemsState) => {
            state.addExistingSystemToExtension.data = initialAddExistingSystemToExtensionState;
            state.addExistingSystemToExtension.error = null;
            state.addExistingSystemToExtension.loading = false;
            return state;
        },
        getSelfDomainsForSystemStart: (state: WebSystemsState) => {
            state.getSelfDomainsForSystem.error = null;
            state.getSelfDomainsForSystem.loading = true;
            return state;
        },
        getSelfDomainsForSystemSuccess(state: WebSystemsState, action: PayloadAction<string[]>) {
            state.getSelfDomainsForSystem.data = action.payload;
            state.getSelfDomainsForSystem.loading = false;
            state.getSelfDomainsForSystem.error = null;
            return state;
        },
        getSelfDomainsForSystemFailed(state, action: PayloadAction<string>) {
            state.getSelfDomainsForSystem.error = action.payload;
            state.getSelfDomainsForSystem.loading = false;
            return state;
        },
        cleanupGetSelfDomainsForSystem: (state: WebSystemsState) => {
            state.getSelfDomainsForSystem.data = null;
            state.getSelfDomainsForSystem.error = null;
            state.getSelfDomainsForSystem.loading = false;
            return state;
        },
        getSystemsRegexesStart: (state: WebSystemsState) => {
            state.getSystemsRegexes.error = null;
            state.getSystemsRegexes.loading = true;
            return state;
        },
        getSystemsRegexesSuccess(state: WebSystemsState, action: PayloadAction<GetSystemsRegexesResultItem[]>) {
            state.getSystemsRegexes.data = action.payload;
            state.getSystemsRegexes.loading = false;
            state.getSystemsRegexes.error = null;
            return state;
        },
        getSystemsRegexesFailed(state, action: PayloadAction<string>) {
            state.getSystemsRegexes.error = action.payload;
            state.getSystemsRegexes.loading = false;
            return state;
        },
        getDefaultSystemMatcherStart: (state: WebSystemsState) => {
            state.getDefaultSystemMatcher.error = null;
            state.getDefaultSystemMatcher.loading = true;
            return state;
        },
        getDefaultSystemMatcherSuccess(state: WebSystemsState, action: PayloadAction<SystemMatcher>) {
            state.getDefaultSystemMatcher.data = action.payload;
            state.getDefaultSystemMatcher.loading = false;
            state.getDefaultSystemMatcher.error = null;
            return state;
        },
        getDefaultSystemMatcherFailed(state, action: PayloadAction<string>) {
            state.getDefaultSystemMatcher.error = action.payload;
            state.getDefaultSystemMatcher.loading = false;
            return state;
        },
        cleanupGetDefaultMatcher: (state: WebSystemsState) => {
            state.getDefaultSystemMatcher.data = null;
            state.getDefaultSystemMatcher.error = null;
            state.getDefaultSystemMatcher.loading = false;
            return state;
        },
        linkSystemToExtensionStart: (state: WebSystemsState) => {
            state.linkSystemToExtension.error = null;
            state.linkSystemToExtension.loading = true;
            return state;
        },
        linkSystemToExtensionSuccess(state: WebSystemsState, action: PayloadAction<number>) {
            state.linkSystemToExtension.data = action.payload;
            state.linkSystemToExtension.loading = false;
            state.linkSystemToExtension.error = null;
            return state;
        },
        linkSystemToExtensionFailed(state, action: PayloadAction<string>) {
            state.linkSystemToExtension.error = action.payload;
            state.linkSystemToExtension.loading = false;
            return state;
        },
        cleanupLinkSystemToExtension: (state: WebSystemsState) => {
            state.linkSystemToExtension.data = undefined;
            state.linkSystemToExtension.error = null;
            state.linkSystemToExtension.loading = false;
            return state;
        },
        addSystemMatcherStart: (state: WebSystemsState) => {
            state.addSystemMatcher.error = null;
            state.addSystemMatcher.loading = true;
            return state;
        },
        addSystemMatcherSuccess(state: WebSystemsState, action: PayloadAction<SystemMatcher>) {
            state.addSystemMatcher.data = action.payload;
            state.addSystemMatcher.loading = false;
            state.addSystemMatcher.error = null;
            return state;
        },
        addSystemMatcherFailed(state, action: PayloadAction<string>) {
            state.addSystemMatcher.error = action.payload;
            state.addSystemMatcher.loading = false;
            return state;
        },
        cleanupAddSystemMatcher: (state: WebSystemsState) => {
            state.addSystemMatcher.data = undefined;
            state.addSystemMatcher.error = null;
            state.addSystemMatcher.loading = false;
            return state;
        },
        updateSystemMatcherStart: (state: WebSystemsState) => {
            state.updateSystemMatcher.error = null;
            state.updateSystemMatcher.loading = true;
            return state;
        },
        updateSystemMatcherSuccess(state: WebSystemsState, action: PayloadAction<number>) {
            state.updateSystemMatcher.data = action.payload;
            state.updateSystemMatcher.loading = false;
            state.updateSystemMatcher.error = null;
            return state;
        },
        updateSystemMatcherFailed(state, action: PayloadAction<string>) {
            state.updateSystemMatcher.error = action.payload;
            state.updateSystemMatcher.loading = false;
            return state;
        },
        cleanupUpdateSystemMatcher: (state: WebSystemsState) => {
            state.updateSystemMatcher.data = undefined;
            state.updateSystemMatcher.error = null;
            state.updateSystemMatcher.loading = false;
            return state;
        },
    },
});

const {
    updateSystemSuccess,
    updateSystemFailed,
    updateSystemStart,
    getSystemFailed,
    getSystemStart,
    getSystemSuccess,
    createNewEnvFailed,
    createNewEnvStart,
    createNewEnvSuccess,
    deleteEnvFailed,
    deleteEnvStart,
    deleteEnvSuccess,
    restoreEnvFailed,
    restoreEnvStart,
    restoreEnvSuccess,
    addExistingSystemToExtensionStart,
    addExistingSystemToExtensionSuccess,
    addExistingSystemToExtensionFailed,
    getSelfDomainsForSystemStart,
    getSelfDomainsForSystemSuccess,
    getSelfDomainsForSystemFailed,
    getSystemsRegexesFailed,
    getSystemsRegexesSuccess,
    getSystemsRegexesStart,
    getDefaultSystemMatcherStart,
    getDefaultSystemMatcherSuccess,
    getDefaultSystemMatcherFailed,
    linkSystemToExtensionStart,
    linkSystemToExtensionSuccess,
    linkSystemToExtensionFailed,
    cleanupLinkSystemToExtension,
    addSystemMatcherStart,
    addSystemMatcherSuccess,
    addSystemMatcherFailed,
    cleanupAddSystemMatcher,
    updateSystemMatcherStart,
    updateSystemMatcherSuccess,
    updateSystemMatcherFailed,
    cleanupUpdateSystemMatcher,
} = webSystemsSlice.actions;

type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

const getWebSystem =
    (systemUserId: number, forceLoad = false): AppThunk =>
    async (dispatch, getState) => {
        try {
            const alreadyLoadedWebSystem = getState().webSystemsState.webSystem;
            if (alreadyLoadedWebSystem.data && alreadyLoadedWebSystem.data[systemUserId] && !forceLoad) {
                return;
            }

            dispatch(getSystemStart());
            const getSystemDataResult: GetSystemDataResult = await webSystemsExposureApi.getSystemData(systemUserId);
            // EX2468 - to support saving changes in 'Basic' section when there is a problem with empty top domains
            setEmptyTopUrlsWithDefaultForMatchPatternModeMatchers(getSystemDataResult.systemData);
            dispatch(getSystemSuccess({ [systemUserId]: getSystemDataResult }));
        } catch (err) {
            dispatch(getSystemFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const updateWebSystem =
    (systemData: SystemData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateSystemStart());
            await webSystemsExposureApi.updateSystem({ systemData });
            dispatch(updateSystemSuccess(systemData));
            dispatch(getWebSystem(systemData.systemId, true));
            dispatch(getSystemsRegexes());
        } catch (err) {
            dispatch(updateSystemFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const getSelfDomainsForSystem =
    (appName: string, systemUserId: number, appId?: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(getSelfDomainsForSystemStart());
            const result = await webSystemsExposureApi.getSelfDomainsForSystem({ appName, systemUserId, appId });
            dispatch(getSelfDomainsForSystemSuccess(result));
        } catch (err) {
            dispatch(getSelfDomainsForSystemFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const createNewEnvironment =
    (env: CreateSystemEnvironmentDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(createNewEnvStart());
            await analyticsApi.createEnvironment(env);
            dispatch(
                createNewEnvSuccess({
                    name: env.envName,
                    action: 'created',
                })
            );
            dispatch(getWebSystem(env.systemId, true));
        } catch (err) {
            dispatch(createNewEnvFailed(err.message));
            return;
        }
    };

const deleteEnvironment =
    (env: DeleteSystemEnvironmentDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(deleteEnvStart());
            await analyticsApi.deleteEnvironment(env);
            dispatch(
                deleteEnvSuccess({
                    envId: env.envId,
                    action: 'deleted',
                })
            );
            dispatch(getWebSystem(env.systemId, true));
        } catch (err) {
            dispatch(deleteEnvFailed(err.message));
            return;
        }
    };

const restoreEnvironment =
    (env: RestoreSystemEnvironmentDto): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(restoreEnvStart());
            await analyticsApi.restoreEnvironment(env);
            dispatch(
                restoreEnvSuccess({
                    envId: env.envId,
                    action: 'restored',
                })
            );
            dispatch(getWebSystem(env.systemId, true));
        } catch (err) {
            dispatch(restoreEnvFailed(err.message));
            return;
        }
    };

const addExistingSystemToExtension =
    (systemData: AddSystemData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addExistingSystemToExtensionStart());
            await webSystemsExposureApi.addExistingSystemToExtension({ systemData });
            dispatch(addExistingSystemToExtensionSuccess(systemData));
            dispatch(getWebSystem(systemData.systemId, true));
            dispatch(getSystemsRegexes());
        } catch (err) {
            dispatch(addExistingSystemToExtensionFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const getSystemsRegexes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getSystemsRegexesStart());
        const result = await webSystemsExposureApi.getSystemsRegexes();
        dispatch(getSystemsRegexesSuccess(result));
    } catch (err) {
        dispatch(getSystemsRegexesFailed(err.response?.data?.ErrMsg || err.message));
        return;
    }
};

const getDefaultSystemMatcher =
    (appId: string, appName: string, envId: number, accountId: number, accountGuid: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(getDefaultSystemMatcherStart());
            const result = await webSystemsExposureApi.getDefaultSystemMatcher({
                appId,
                appName,
                envId,
                accountId,
                accountGuid,
            });
            dispatch(getDefaultSystemMatcherSuccess(result));
        } catch (err) {
            dispatch(getDefaultSystemMatcherFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const linkSystemToExtension =
    (accountId: number, systemId: number, appId: string, value: LinkSystemToExtensionData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(linkSystemToExtensionStart());
            const getSystemDataResult = await webSystemsExposureApi.linkSystemToExtension({ accountId, systemId, appId, value });
            dispatch(linkSystemToExtensionSuccess(systemId));
            dispatch(getSystemSuccess({ [systemId]: getSystemDataResult }));
            dispatch(getSystemsRegexes());
        } catch (err) {
            dispatch(linkSystemToExtensionFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const addSystemMatcher =
    (accountId: number, systemId: number, value: AddNewMatcherData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addSystemMatcherStart());
            const getSystemDataResult = await webSystemsExposureApi.addSystemMatcher({ accountId, systemId, value });
            const newAddedMatcher = getSystemDataResult.systemData.matchers[value.envId][0];
            dispatch(addSystemMatcherSuccess(newAddedMatcher));
            dispatch(getSystemSuccess({ [systemId]: getSystemDataResult }));
            dispatch(getSystemsRegexes());
        } catch (err) {
            dispatch(addSystemMatcherFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

const updateSystemMatcher =
    (accountId: number, systemId: number, matcherId: string | number, { oldValues, newValues }: MatcherChanges): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateSystemMatcherStart());
            const getSystemDataResult = await webSystemsExposureApi.updateSystemMatcher({
                accountId,
                systemId,
                matcherId,
                oldValues,
                newValues,
            });
            dispatch(updateSystemMatcherSuccess(systemId));
            dispatch(getSystemSuccess({ [systemId]: getSystemDataResult }));
            dispatch(getSystemsRegexes());
        } catch (err) {
            dispatch(updateSystemMatcherFailed(err.response?.data?.ErrMsg || err.message));
            return;
        }
    };

export {
    WebSystemsState,
    webSystemsSlice,
    getWebSystem,
    updateWebSystem,
    createNewEnvironment,
    deleteEnvironment,
    restoreEnvironment,
    addExistingSystemToExtension,
    getSelfDomainsForSystem,
    getSystemsRegexes,
    getDefaultSystemMatcher,
    linkSystemToExtension,
    addSystemMatcher,
    updateSystemMatcher,
};
