import * as yup from 'yup';

export const appCredentialsSchema = yup.object().shape({
    settings: yup.object({
        app_key: yup
            .string()
            .matches(
                /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                'Please make sure you copy the Bot Id'
            )
            .required('Required'),
        app_secret: yup.string().required('Required'),
    }),
});
