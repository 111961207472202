import { Box, Typography } from '@material-ui/core';
import { useLoggedInUser, UsersState } from '@walkme-admin-center/libs/state-management-users';
import { WMTextField, WMButton, WMButtonVariant, WMChip, WMAgGridWrapper, WMSearchFilter } from '@walkme/wm-ui';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { System, User, Users, UsersBulkMutationResult } from 'wm-accounts-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { AppData } from '@walkme-admin-center/libs/types';
import {
    StyledUsersDiv,
    StyledCancelButton,
    StyledRemoveUsersButton,
    StyledCreateButton,
    ChevronLeft,
    StyledWMChip,
} from './select_users.styles';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

interface UserSelectionProps {
    selectedSystem?: System;
    RemoveUsersFromSystem?: (detailedUSersToRemove) => void;
    AddUsersToSystem?: (detailedUSersToAdd) => void;
    users?: Users;
}
import { Tooltip } from '@walkme/ui-core';
export const UserSelection = ({ selectedSystem, RemoveUsersFromSystem, AddUsersToSystem, users }: UserSelectionProps) => {
    const { t, rt } = useTranslation('general');
    const [query, setQuery] = useState('');
    const { loggedInUserAppData } = useLoggedInUser();
    const updatedSystemsAppData: AppData<UsersBulkMutationResult> = useSelector(
        (state: { usersState: UsersState }) => state.usersState.updateUsers
    );
    const selectedUsers = () => {
        const filteredUsers = selectedSystem ? users.filter((user) => user.systems.find((system) => system.id === selectedSystem.id)) : [];
        return filteredUsers.map((user: User) => {
            return {
                name: user.firstName + ' ' + user.lastName,
                email: user.email,
                Role: user.role ? rt(user.role.displayName) : '',
                id: user.id,
            };
        });
    };
    const [gridApi, setGridApi] = useState(null);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const usersToAdd = () => {
        const filteredUsers = selectedSystem ? users.filter((user) => !user.systems.find((system) => system.id === selectedSystem.id)) : [];
        return filteredUsers.map((user: User) => {
            return {
                name: user.firstName + '' + user.lastName,
                email: user.email,
                Role: user.role ? rt(user.role.displayName) : '',
                id: user.id,
            };
        });
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const [addUsersButton, setAddUsersButton] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        gridApi ? gridApi.deselectAll() : null;
        setSelectedRows([]);
    }, [addUsersButton]);

    useEffect(() => {
        if (!updatedSystemsAppData.error) {
            if (updatedSystemsAppData.data) {
                setSelectedRows([]);
            }
        }
    }, [updatedSystemsAppData]);

    return (
        <div style={{ marginTop: '20px', height: '100%' }}>
            {!addUsersButton && (
                <Box style={{ height: '100%' }}>
                    <Box display='flex' flexDirection='row'>
                        <Box display='flex' flexDirection='row' flexGrow={1}>
                            <Typography
                                style={{
                                    fontFamily: 'ProximaNova',
                                    color: '#2F426C',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    marginRight: '10px',
                                }}>
                                {t('systems-tab.all-systems-page.assign-users-form.title')}
                            </Typography>
                            <WMChip label={selectedUsers().length} rounded />
                        </Box>
                        <Box>
                            <WMButton variant={WMButtonVariant.Text} onClick={() => setAddUsersButton(true)}>
                                {t('buttons.assign-users')}
                            </WMButton>
                        </Box>
                    </Box>
                    <WMSearchFilter
                        ds2
                        style={{ marginTop: '20px' }}
                        value={query}
                        placeholder={t('common.search-placeholder')}
                        onSearch={(query) => setQuery(query)}
                        onPointerEnterCapture={() => null}
                        onPointerLeaveCapture={() => null}
                    />
                    <Box style={{ marginTop: '20px', height: 'calc(100% - 100px)' }}>
                        <Box style={{ height: '100%' }}>
                            <StyledUsersDiv>
                                <WMAgGridWrapper
                                    onGridReady={onGridReady}
                                    rowSelection='multiple'
                                    quickFilterText={query}
                                    gridOptions={{
                                        onRowSelected: (params) => {
                                            const newSelectedRows = params.api.getSelectedRows();
                                            setSelectedRows(newSelectedRows);
                                        },
                                    }}
                                    columnDefs={[
                                        {
                                            field: '',
                                            headerCheckboxSelection: true,
                                            checkboxSelection: true,
                                            width: 40,
                                            cellStyle: { paddingLeft: '0', borderColor: 'transparent' },
                                            headerClass: 'header-black',
                                        },
                                        {
                                            headerName: t('systems-tab.all-systems-page.assign-users-form.name'),
                                            sort: 'desc',
                                            width: 200,
                                            getQuickFilterText: (params) => params.data.email + params.data.name,
                                            cellRenderer: function (params) {
                                                return (
                                                    <Box display='flex' flexDirection='column' style={{ marginTop: '6px' }}>
                                                        <Box display='flex' flexDirection='row' alignItems='center'>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: 'ProximaNova',
                                                                    color: '#2F426C',
                                                                    fontSize: '14px',
                                                                    fontWeight: 600,
                                                                }}>
                                                                {params.data.name}
                                                            </Typography>
                                                            {params.data.id == loggedInUserAppData.data.id && (
                                                                <StyledWMChip label='You' rounded />
                                                            )}
                                                        </Box>
                                                        <Tooltip title={params.data.email}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: 'ProximaNova',
                                                                    color: '#637191',
                                                                    fontSize: '12px',
                                                                    fontWeight: 400,
                                                                }}>
                                                                {params.data.email}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                            },
                                        },
                                        {
                                            field: t('common.role'),
                                            width: 170,
                                            filter: true,
                                        },
                                    ]}
                                    rowData={selectedUsers()}
                                />
                            </StyledUsersDiv>
                        </Box>
                    </Box>
                </Box>
            )}
            {addUsersButton && (
                <Box style={{ height: '100%' }}>
                    <Box display='flex' flexDirection='row'>
                        <Box display='flex' flexDirection='row' flexGrow={1}>
                            <img onClick={() => setAddUsersButton(false)} alt='collapse' src={ChevronLeft} style={{ cursor: 'pointer' }} />
                            <Typography
                                style={{
                                    fontFamily: 'ProximaNova',
                                    color: '#2F426C',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    marginLeft: '10px',
                                }}>
                                {t('systems-tab.all-systems-page.assign-users-form.add-new-users')}
                            </Typography>
                        </Box>
                    </Box>
                    <WMSearchFilter
                        ds2
                        style={{ marginTop: '20px' }}
                        value={query}
                        placeholder={t('common.search-placeholder')}
                        onSearch={(query) => setQuery(query)}
                        onPointerEnterCapture={() => null}
                        onPointerLeaveCapture={() => null}
                    />
                    <Box style={{ marginTop: '20px', height: '100%' }}>
                        <Box style={{ height: '100%' }}>
                            <StyledUsersDiv>
                                <WMAgGridWrapper
                                    onGridReady={onGridReady}
                                    rowSelection='multiple'
                                    quickFilterText={query}
                                    gridOptions={{
                                        onRowSelected: (params) => {
                                            const newSelectedRows = params.api.getSelectedRows();
                                            setSelectedRows(newSelectedRows);
                                        },
                                    }}
                                    columnDefs={[
                                        {
                                            field: '',
                                            headerCheckboxSelection: true,
                                            checkboxSelection: true,
                                            width: 40,
                                            cellStyle: { paddingLeft: '0', borderColor: 'transparent' },
                                            headerClass: 'header-black',
                                        },
                                        {
                                            headerName: t('systems-tab.all-systems-page.assign-users-form.name'),
                                            sort: 'desc',
                                            width: 200,
                                            getQuickFilterText: (params) => params.data.email + params.data.name,
                                            cellRenderer: function (params) {
                                                return (
                                                    <Box display='flex' flexDirection='column' style={{ marginTop: '12px' }}>
                                                        <Box display='flex' flexDirection='row' alignItems='center'>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: 'ProximaNova',
                                                                    color: '#2F426C',
                                                                    fontSize: '14px',
                                                                    fontWeight: 600,
                                                                }}>
                                                                {params.data.name}
                                                            </Typography>
                                                            {params.data.id == loggedInUserAppData.data.id && (
                                                                <StyledWMChip label='You' rounded />
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            style={{
                                                                fontFamily: 'ProximaNova',
                                                                color: '#637191',
                                                                fontSize: '12px',
                                                                fontWeight: 400,
                                                            }}>
                                                            {params.data.email}
                                                        </Typography>
                                                    </Box>
                                                );
                                            },
                                        },
                                        {
                                            field: t('common.role'),
                                            width: 170,
                                            filter: true,
                                        },
                                    ]}
                                    rowData={usersToAdd()}
                                />
                            </StyledUsersDiv>
                        </Box>
                    </Box>
                </Box>
            )}

            {selectedRows.length > 0 && (
                <div style={{ position: 'fixed', bottom: '20px', display: 'flex', width: '440px' }}>
                    <Box display='flex' flexDirection='row' flexGrow={1} alignItems='center'>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#4164E3',
                                fontSize: '20px',
                                fontWeight: 600,
                                marginRight: '5px',
                            }}>
                            {selectedRows.length}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#2F426C',
                                fontSize: '16px',
                                fontWeight: 400,
                            }}>
                            {selectedRows.length === 1
                                ? t('systems-tab.all-systems-page.assign-users-form.user-selected')
                                : t('systems-tab.all-systems-page.assign-users-form.users-selected')}
                        </Typography>
                    </Box>
                    <Box>
                        <StyledCancelButton
                            disabled={updatedSystemsAppData.loading}
                            onClick={() => {
                                gridApi ? gridApi.deselectAll() : null;
                            }}>
                            Cancel
                        </StyledCancelButton>
                        {addUsersButton ? (
                            <StyledCreateButton
                                loading={updatedSystemsAppData.loading}
                                disabled={updatedSystemsAppData.loading}
                                onClick={() => {
                                    AddUsersToSystem(selectedRows);
                                }}>
                                {t('buttons.assign')}
                            </StyledCreateButton>
                        ) : (
                            <StyledRemoveUsersButton
                                loading={updatedSystemsAppData.loading}
                                disabled={updatedSystemsAppData.loading}
                                onClick={() => {
                                    RemoveUsersFromSystem(selectedRows);
                                }}>
                                {t('buttons.remove')}
                            </StyledRemoveUsersButton>
                        )}
                    </Box>
                </div>
            )}
        </div>
    );
};

export default UserSelection;
