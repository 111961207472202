import React from 'react';
import { AppsFilter } from '../apps-filter/apps-filter';
import { AppList } from './app-list';
import { StyledAppSelection } from './app-list-container.styles';

export const AppListContainer = () => {
    return (
        <StyledAppSelection>
            <AppsFilter />
            <AppList />
        </StyledAppSelection>
    );
};
