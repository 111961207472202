import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';

class AuditEventProcessorApi {
    private readonly httpClient: WMhttpClient;
    private readonly path: string;

    constructor(httpClient: WMhttpClient) {
        this.httpClient = httpClient;
        this.path = 'accounts-audit-event-processor';
    }

    async sendInternalEvent(body): Promise<any> {
        const { data } = await this.httpClient.instance.post<any>(`${this.path}/events/internal`, body);
        return data;
    }
}

export const auditEventProcessorApi = new AuditEventProcessorApi(wmhttpClientSharedInstance);
