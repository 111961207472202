import React, { useState, MouseEvent, useRef } from 'react';
import { DropdownChevronDisplayOptions } from '@walkme/ui-core';
import { StyledDivider, StyledSelector, StyledMenuItem, StyledMenu, OptionSelectorDiv, UuidButtonText } from './select-input.styles';
import { Check } from '@walkme/ui-icons/large';
import { useTranslation } from '../../../../../../../../apps/home/src/localization';

export interface SelectInputProps {
    selectedOption: SelectInputOption;
    inputOptions: SelectInputOption[];
    setSelectedOption: (selectedOption: SelectInputOption) => void;
    placeholder?: string;
    error?: string;
}

export interface SelectInputOption {
    value: any;
    label: string | React.ReactElement;
}

export function SelectInput({ selectedOption, inputOptions, setSelectedOption, placeholder, error }: SelectInputProps) {
    const { t } = useTranslation('general');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dropdownRef = useRef<any>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => dropdownRef?.current.blur(), 100);
    };

    const handleChangeSelection = (selectedOption) => {
        setSelectedOption(selectedOption);
        handleClose();
    };

    const SelectorDisplayedValue = (
        <OptionSelectorDiv>
            <UuidButtonText>{selectedOption?.label || placeholder}</UuidButtonText>
        </OptionSelectorDiv>
    );

    const chevronDisplayOption = DropdownChevronDisplayOptions.DEFAULT;

    return (
        <div>
            <StyledSelector
                handleOpen={handleOpen}
                displayValue={SelectorDisplayedValue}
                anchorEl={anchorEl}
                ref={dropdownRef}
                chevronDisplayOption={chevronDisplayOption}
                error={error}
            />
            <StyledMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} width={590}>
                <StyledDivider />
                <div>
                    {inputOptions?.map((inputOption, index) => {
                        return (
                            <StyledMenuItem
                                id={inputOption.value}
                                onClick={() => handleChangeSelection(inputOption)}
                                isSelected={inputOption.value === selectedOption?.value}>
                                <span>{inputOption.label}</span>
                                {inputOption.value === selectedOption?.value ? <Check color='#395eea' /> : null}
                            </StyledMenuItem>
                        );
                    })}
                </div>
            </StyledMenu>
        </div>
    );
}
