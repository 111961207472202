import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { StyledPageTitle } from '@walkme-admin-center/libs/shared-styles';
import { StyledPageTitleLabel } from '../../../users-roles/src/lib/users.styles';
import { AllSystemsPage } from './systems-page';
import { DeletedSystemsPage } from './deleted-systems-page';
import { WMChip, WMIconPlus } from '@walkme/wm-ui';
import {
    CreateSystemState,
    ShouldShowAssociatedSystems,
    useSystems,
    useSystemsSettings,
} from '@walkme-admin-center/libs/state-management-systems';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { SystemPage } from './new-system-page';
import { Star } from '@walkme/ui-icons';
import {
    ButtonBeta,
    MenuTitle,
    StyledBadgeSpan,
    StyledCreateSystemButton,
    StyledCreateSystemMenu,
    StyledMenu,
    StyledTabs,
    StyledTabsRow,
    StyledTitleDiv,
    TryBetaDiv,
} from './systems.styles';
import { AccountsSdk, SystemTypeKey } from 'wm-accounts-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { Menu, MenuItem, MenuItemStartIcon, MenuItemText } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice } from '@walkme-admin-center/libs/state-management-systems';
import { CreateSystemDialog } from './create-system/create-system-dialog/create-system-dialog';
import { resetCreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { CreateWorkstationDialog } from './create-system/create-workstation-dialog/create-workstation-dialog';
import { TvIcon } from './create-system/assets/icons/tv';
import { WalkmeIcon } from './create-system/assets/icons/walkme';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { Box } from '@material-ui/core';
import { EnvChip } from './styles/systems-page.styles';

enum TabsPaths {
    MainPath = '/systems',
    AllSystems = '/systems/all-systems',
    DeletedSystems = '/systems/deleted-systems',
}

export const Systems = () => {
    const { t, i18n } = useTranslation('general');
    const { tabName, id, systemTabName } = useParams<{ tabName; id; systemTabName }>();
    const navigate = useNavigate();

    const shouldAutoOpenCreateSystem = id === 'new';
    const [showCreateDialog, setShowCreateDialog] = useState(shouldAutoOpenCreateSystem);
    const [showCreateSystemMenu, setShowCreateSystemMenu] = useState(false);
    const anchorEl = useRef(null);

    const { pendingForDeleteSystemsAppData, systemsAppData } = useSystems();
    const { systemsSettingsAppData } = useSystemsSettings();
    const { loggedInUserAppData, userPermissions, accountFeatureEnabled } = useLoggedInUser();
    const dispatch = useDispatch();
    const createSystemType = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.type);
    const isCreateSystemModalOpen = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateSystemModalOpen.data
    );
    const isCreateWorkstationModalOpen = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.isCreateWorkstationModalOpen.data
    );

    const calculatePlatformMinWidth = (language) => {
        return language === 'jp' ? 350 : 200;
    };

    const [platformColumnMinWidth, setPlatformColumnMinWidth] = useState(calculatePlatformMinWidth(i18n.language));

    useEffect(() => {
        setPlatformColumnMinWidth(calculatePlatformMinWidth(i18n.language));
    }, [i18n.language]);

    const createSystemMenuItems = [
        {
            label: t('systems-tab.systems-table.web'),
            value: 'Web',
            startIcon: <TvIcon />,
        },
        {
            label: t('systems-tab.systems-table.workstation'),
            value: 'Workstation',
            startIcon: <WalkmeIcon />,
        },
    ];
    const newCreateSystemFlowEnabled = accountFeatureEnabled('newCreateSystemFlow');

    const checkPermissionForSystems = () => {
        const isPermitted = userPermissions('systemManagement');

        if (!isPermitted) {
            navigate('/');
        }
    };

    const initTabHandle = () => {
        document.title = t('systems-tab.document-title');
        if (!isNaN(tabName))
            navigate({
                pathname: `/systems/all-systems/${tabName}`,
            });
    };

    useEffect(checkPermissionForSystems, []);
    useEffect(initTabHandle, [tabName]);

    const pendingForDeleteSystems = pendingForDeleteSystemsAppData.data;
    const enabledSystems = systemsAppData.data;

    const renderSharedColumns = () => [
        {
            headerName: t('systems-tab.systems-table.platform'),
            minWidth: platformColumnMinWidth,
            filter: true,
            sortable: true,
            field: 'systemTypeKey',
            cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
            cellRenderer: function (params) {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {params.value.map((platform) => (
                            <EnvChip label={platform} />
                        ))}
                    </div>
                );
            },
            valueGetter: function (params) {
                const showMobileWebChip =
                    !ShouldShowAssociatedSystems &&
                    params.data.platforms &&
                    params.data.platforms.find((platform) => platform.platformTypeId === 3);
                const systemTypeKey = t(`systems-tab.systems-table.${params.data.systemTypeKey.toLowerCase()}`);
                const mobileSystemTypeKey = t('systems-tab.systems-table.mobileweb');
                return showMobileWebChip ? [systemTypeKey, mobileSystemTypeKey] : [systemTypeKey];
            },
        },
    ];

    const [activeTab, setActiveTab] = useState(tabName === 'deleted-systems' ? 1 : 0);

    const handleTabChange = (newValue: number) => {
        if (activeTab == newValue) return;
        setActiveTab(newValue);
        const isDeleteTab = newValue === 1;
        if (isDeleteTab) {
            navigate({
                pathname: `/systems/deleted-systems`,
            });
        } else {
            navigate({
                pathname: `/systems/all-systems`,
            });
        }
    };

    const handleCreateSystemTypeSelected = (e, value) => {
        dispatch(createSystemSlice.actions.setCreateSystemType(value as SystemTypeKey.Web | SystemTypeKey.Workstation));
        setShowCreateSystemMenu(false);
        switch (value) {
            case SystemTypeKey.Web:
                dispatch(createSystemSlice.actions.setCreateSystemModalVisibility(true));
                break;
            case SystemTypeKey.Workstation:
                dispatch(createSystemSlice.actions.setCreateWorkstationModalVisibility(true));
                break;
        }
    };

    const handleCloseCreateSystemDialog = () => {
        dispatch(createSystemSlice.actions.resetCreateSystemState());
    };

    const handleCloseCreateWorkstationDialog = () => {
        dispatch(createSystemSlice.actions.resetCreateWorkstationState());
    };

    const handleCreateSystemClick = () => {
        if (newCreateSystemFlowEnabled) {
            setShowCreateSystemMenu(true);
        } else {
            setShowCreateDialog(true);
        }
    };

    return id ? (
        <SystemPage />
    ) : (
        <div className='noPadding'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTitleDiv>
                    <StyledPageTitle>{t('systems-tab.page-title')}</StyledPageTitle>
                    <StyledPageTitleLabel>{t('systems-tab.page-title-label')}</StyledPageTitleLabel>
                </StyledTitleDiv>
            </div>
            <StyledTabsRow>
                <StyledTabs
                    initialValue={activeTab}
                    onChange={(tabItem) => handleTabChange(Number(tabItem.value))}
                    items={[
                        {
                            content: (
                                <AllSystemsPage
                                    pageData={{
                                        setShowCreateDialog,
                                        showCreateDialog,
                                        enabledSystems,
                                        renderSharedColumns,
                                        systemsSettingsAppData,
                                        id,
                                    }}
                                    loadingData={systemsAppData.loading}
                                />
                            ),
                            label: t('systems-tab.all-systems-page.title'),
                            icon: <StyledBadgeSpan active={activeTab === 0}>{enabledSystems.length}</StyledBadgeSpan>,
                            iconPosition: 'end',
                            value: '0',
                        },
                        {
                            content: (
                                <DeletedSystemsPage
                                    pageData={{
                                        pendingForDeleteSystems,
                                        renderSharedColumns,
                                        systemsSettingsAppData,
                                    }}
                                    loadingData={systemsAppData.loading}
                                />
                            ),
                            label: t('systems-tab.deleted-systems-page.title'),
                            icon: <StyledBadgeSpan active={activeTab === 1}>{pendingForDeleteSystems.length}</StyledBadgeSpan>,
                            iconPosition: 'end',
                            value: '1',
                        },
                    ]}
                />
                <StyledCreateSystemButton ref={anchorEl} onClick={handleCreateSystemClick} iconComponent={<WMIconPlus />}>
                    {t('systems-tab.create-system')}
                </StyledCreateSystemButton>
                <StyledCreateSystemMenu>
                    <StyledMenu
                        open={showCreateSystemMenu}
                        onClose={() => setShowCreateSystemMenu(false)}
                        anchorEl={anchorEl.current}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                        <MenuTitle>{t('systems-tab.choose-platform')}</MenuTitle>
                        {createSystemMenuItems.map((item) => {
                            return (
                                <MenuItem
                                    key={item.value}
                                    onClick={(e) => handleCreateSystemTypeSelected(e, item.value)}
                                    className={'menu-item-gap'}>
                                    {item.startIcon}
                                    <MenuItemText>{item.label}</MenuItemText>
                                </MenuItem>
                            );
                        })}
                    </StyledMenu>
                </StyledCreateSystemMenu>
            </StyledTabsRow>
            {isCreateSystemModalOpen ? <CreateSystemDialog isOpen={true} onClose={handleCloseCreateSystemDialog} /> : null}
            {isCreateWorkstationModalOpen ? <CreateWorkstationDialog isOpen={true} onClose={handleCloseCreateWorkstationDialog} /> : null}
        </div>
    );
};
