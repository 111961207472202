import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AccordionHeaderStyled, AccordionStyled, AccordionSummaryStyled, SubHeader } from '../assign-systems-step/access-window-style';
import { useForm } from 'react-final-form';
import { FormMode, FormTypes, IAskMeForm } from '../../../../../../../../../../libs/state-mangment-data-integration/src/lib/types';
import { useSelector } from 'react-redux';
import { SystemsState } from '@walkme-admin-center/libs/state-management-systems';
import styled from 'styled-components';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { Box, Collapse, RadioGroup } from '@material-ui/core';
import CustomRadioButton from '../../../../../common/components/custom-radio-button';
import { CssCheckBox } from '../../../../../data-integrations-list/integration-row/integration-row-style';
import useGetTags from './use-get-tags';
import { getToolTip } from '../../../../../common/services';
import { InfoBox } from '../../../../../common/components/info-box';

const PageContainer = styled.div`
    display: flex;
    padding: 1.5rem 2rem;
    background: var(--LS-Color-Bg-secondary, #fff);
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    flex: 1 0 0;
    align-self: stretch;
`;

const SystemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
    align-self: stretch;
`;

const SystemIconContainer = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    background: var(--Background-Background-tint-1, #f0f3f9);
    justify-content: center;
    align-items: center;
`;

const SystemTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1 0 0;
`;

const SystemBodyContainer = styled.div`
    display: flex;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid var(--LS-Color-El-divider, #f0f3f9);
    background: var(--Primaries-Sky-4, #f9fbfd);
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

const SystemSegmentsOptionContainer = styled.div<{ isSelected?: boolean }>`
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    border: 1px solid
        ${(props) =>
            props.isSelected
                ? 'var(--ls-color-input-dropdown-primary-stroke-active, #385FEB)'
                : 'var(--ls-color-input-dropdown-primary-stroke-default, #EDF1FD)'};
    background: var(--LS-Color-Action-Pallete-White-0, #fff);
    align-self: stretch;
`;

const SystemSegmentsOptionHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1.5rem;
    align-self: stretch;
    cursor: pointer;
`;

const SystemSegmentsOptionTitleContainer = styled.div<{ isSelected?: boolean }>`
    display: flex;
    gap: var(--ls-spacers-generation-sp-3012, 0.75rem);
    align-self: stretch;
    align-items: center;
`;

const SystemSegmentsOptionText = styled.p`
    color: var(--LS-Color-Text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
`;

const SystemSegmentsEntriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem;
`;

const SystemSegmentEntryContainer = styled.div`
    display: flex;
    align-items: center;
    gap: var(--ls-spacers-generation-sp-3012, 0.75rem);
    align-self: stretch;
`;

const SystemSegmentEntryTextContainer = styled.div`
    display: flex;
    padding: var(--LS-Spacers-Generation-SP2, 0.125rem) 0rem;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
`;

const SystemSegmentEntryText = styled.p`
    color: var(--LS-Color-Text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
`;

const Separator = styled.div`
    align-self: stretch;
    height: 1px;
    background: var(--Typography-Typography-tint-8, #f5f6f8);
`;

const SegmentsEmptyStateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const SegmentsEmptyStateText = styled.p`
    color: var(--LS-Color-Text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    margin: 0;
    text-align: center;
`;

const SystemsEmptyStateText = styled.p`
    color: var(--LS-Color-Text-primary, #5c6374);
    font-family: 'Proxima Nova';
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    text-align: center;
    margin-top: 8rem;
`;

const ALL_SEGMENTS_TOOLTIP = 'Current segments & all future segments that will be added.';
const SPECIFIC_SEGMENTS_TOOLTIP =
    'If additional segments will be added to the system via the editor, they must be manually selected here in order to be applied to the knowledge source.';

const AssignSegmentsSystem = ({ system, systemDetails, update, setSegments }) => {
    const { data: segments, isLoading, error } = useGetTags(systemDetails.id);
    const allSegmentsCheckboxRef = useRef(null);

    const changeSegmentsValue = (segment_ids) => {
        update(segment_ids);
    };

    const toggleSegment = (segmentId) => {
        if (system.segment_ids?.includes(segmentId)) {
            update(system.segment_ids?.filter((id) => id !== segmentId));
        } else {
            update([...system.segment_ids, segmentId]);
        }
    };

    const toggleAllSegments = () => {
        if (system.segment_ids?.length === segments.length) {
            update([]);
        } else {
            update(segments.map((segment) => segment.Id));
        }
    };

    useEffect(() => {
        if (allSegmentsCheckboxRef.current) {
            allSegmentsCheckboxRef.current.indeterminate = 0 < system.segment_ids?.length && system.segment_ids?.length < segments.length;
        }
    }, [system.segment_ids]);

    useEffect(() => {
        if (!isLoading && !error) {
            setSegments(segments);
        }
    }, [segments, isLoading, error]);

    return (
        <AccordionStyled>
            <AccordionSummaryStyled
                expandIcon={<img src={'assets/icons/data-integrations/integrations-icons/down-arrow-icon.svg'} />}
                style={{ height: 'unset', padding: '0.75rem 1rem' }}>
                <SystemTitleContainer>
                    <SystemIconContainer>
                        <img src={retrieveSystemIcon(systemDetails.settings)} width='20px' height='20px' />
                    </SystemIconContainer>
                    <AccordionHeaderStyled>{systemDetails.displayName}</AccordionHeaderStyled>
                </SystemTitleContainer>
            </AccordionSummaryStyled>
            <RadioGroup value={system.segment_ids ? 1 : 0}>
                <SystemBodyContainer>
                    {segments?.length ? (
                        <>
                            <SystemSegmentsOptionContainer isSelected={!system.segment_ids}>
                                <SystemSegmentsOptionHeaderContainer onClick={() => changeSegmentsValue(undefined)}>
                                    <SystemSegmentsOptionTitleContainer>
                                        <CustomRadioButton value={0} />
                                        <SystemSegmentsOptionText>
                                            Knowledge source will be available to all system segments
                                        </SystemSegmentsOptionText>
                                        <span style={{ pointerEvents: 'all', lineHeight: 0.1 }}>
                                            {getToolTip(ALL_SEGMENTS_TOOLTIP, true)}
                                        </span>
                                    </SystemSegmentsOptionTitleContainer>
                                </SystemSegmentsOptionHeaderContainer>
                            </SystemSegmentsOptionContainer>
                            <SystemSegmentsOptionContainer isSelected={system.segment_ids}>
                                <SystemSegmentsOptionHeaderContainer onClick={() => changeSegmentsValue(system.segment_ids ?? [])}>
                                    <SystemSegmentsOptionTitleContainer>
                                        <CustomRadioButton value={1} />
                                        <SystemSegmentsOptionText>
                                            Knowledge source will only be available to specific system segments
                                        </SystemSegmentsOptionText>
                                        <span style={{ pointerEvents: 'all', lineHeight: 0.1 }}>
                                            {getToolTip(SPECIFIC_SEGMENTS_TOOLTIP, true)}
                                        </span>
                                    </SystemSegmentsOptionTitleContainer>
                                </SystemSegmentsOptionHeaderContainer>
                                <Collapse in={!!system?.segment_ids}>
                                    <Separator />
                                    <SystemSegmentsEntriesContainer>
                                        <SystemSegmentEntryContainer>
                                            <CssCheckBox
                                                type='checkbox'
                                                onChange={toggleAllSegments}
                                                checked={system.segment_ids?.length === segments?.length}
                                                ref={allSegmentsCheckboxRef}
                                            />
                                            <SystemSegmentEntryTextContainer>
                                                <SystemSegmentEntryText>All Segments</SystemSegmentEntryText>
                                            </SystemSegmentEntryTextContainer>
                                        </SystemSegmentEntryContainer>
                                        <Separator />
                                        {segments.map((segment) => (
                                            <SystemSegmentEntryContainer key={segment.Id}>
                                                <CssCheckBox
                                                    type='checkbox'
                                                    onChange={() => toggleSegment(segment.Id)}
                                                    checked={system.segment_ids?.includes(segment.Id)}
                                                />
                                                <SystemSegmentEntryTextContainer>
                                                    <SystemSegmentEntryText>{segment.Name}</SystemSegmentEntryText>
                                                </SystemSegmentEntryTextContainer>
                                            </SystemSegmentEntryContainer>
                                        ))}
                                    </SystemSegmentsEntriesContainer>
                                </Collapse>
                            </SystemSegmentsOptionContainer>
                        </>
                    ) : (
                        <SegmentsEmptyStateContainer>
                            <SegmentsEmptyStateText>No segments exist for {systemDetails.displayName}</SegmentsEmptyStateText>
                        </SegmentsEmptyStateContainer>
                    )}
                </SystemBodyContainer>
            </RadioGroup>
        </AccordionStyled>
    );
};

interface AssignSegmentsTabProps {
    triggerModal: (formType: FormTypes, name: string) => void;
    formMode: FormMode;
}

const AssignSegmentsTab = ({ triggerModal, formMode }: AssignSegmentsTabProps) => {
    const allSystems = useSelector((state: { systemsState: SystemsState }) => state.systemsState.systems);
    const formApi = useForm<IAskMeForm>();
    const { systems, isOmni } = formApi.getState().values;
    const [systemsSegments, setSystemsSegments] = useState({});
    const anySystemMissingSegments = useMemo(
        () => systems?.some((system) => systemsSegments[system.guid]?.length === 0),
        [systems, systemsSegments]
    );

    return (
        <PageContainer>
            <div>
                <AccordionHeaderStyled>Manage Segments</AccordionHeaderStyled>
                <SubHeader>Select the user segment per location that will have access to your knowledge source</SubHeader>
            </div>
            <Collapse in={anySystemMissingSegments || isOmni}>
                <Box height='1.25rem' />
                <InfoBox>
                    {isOmni
                        ? 'User segments are not applicable as you selected "Universal Source", allowing unrestricted access'
                        : 'We found systems with no pre-existing segments. Please note that any newly added segments will be applied automatically.'}
                </InfoBox>
            </Collapse>
            <Box height='1.25rem' />
            {!isOmni && (
                <SystemsContainer>
                    {systems?.length ? (
                        systems.map((system: any, i: number) => {
                            const systemDetails = allSystems.data.find((sys) => sys.guid === system.guid);
                            return (
                                !!systemDetails && (
                                    <AssignSegmentsSystem
                                        key={system.guid}
                                        system={system}
                                        systemDetails={systemDetails}
                                        update={(segment_ids) => {
                                            formApi.change(
                                                `systems`,
                                                systems.map((sys, idx) => (i === idx ? { ...sys, segment_ids } : sys))
                                            );
                                        }}
                                        setSegments={(segments) => setSystemsSegments((value) => ({ ...value, [system.guid]: segments }))}
                                    />
                                )
                            );
                        })
                    ) : (
                        <SystemsEmptyStateText>Source has no assigned systems.</SystemsEmptyStateText>
                    )}
                </SystemsContainer>
            )}
        </PageContainer>
    );
};

export default AssignSegmentsTab;
