import React, { useEffect, useRef } from 'react';
import { StyledContractListContainer, StyledLoadingSpinner } from './contract-list.styles';
import { useContracts } from '../../../../../../../../../libs/state-management-systems/src/lib/hooks/use-contracts';
import { ContractCard } from './contract-card';
import { Contract, createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { useDispatch, useSelector } from 'react-redux';
import { getContractNumOfPurchasedSystems, getContractNumOfSystemsInUse, isContractRecommended } from './contract-list.lib';
import { SystemPurpose, SystemUsage } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { ChevronUpIcon } from '../../../assets/icons/chevron-up';
import { ChevronDownIcon } from '../../../assets/icons/chevron-down';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { shouldDisplayContracts } from '../select-contract.lib';

export const ContractList = () => {
    const { t, rt } = useTranslation('general');
    const [contractsListIsOpen, setContractsListIsOpen] = React.useState<boolean>(true);
    const [fadeEffectIsVisible, setFadeEffectIsVisible] = React.useState<boolean>(true);
    const lastContractRef = useRef(null);
    const { contracts, systems } = useContracts();
    const selectedContract: Contract = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedContract.data
    );
    const usage: SystemUsage = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.usage.data);
    const purpose: SystemPurpose = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.purpose.data);
    const dispatch = useDispatch();
    const isTestingMode = window.localStorage.getItem('testingNewSystemCreation');
    const { loggedInUserAppData } = useLoggedInUser();
    const accountTypes = loggedInUserAppData?.data?.account?.types;
    const isDisabledStep = !shouldDisplayContracts({ purpose, accountTypes, isTestingMode: isTestingMode === 'true' });
    const handleOnSelectContract = (contract: Contract) => {
        dispatch(createSystemSlice.actions.setSelectedContract(contract));
    };

    const handleToggleContractsList = () => {
        setContractsListIsOpen((currVisibility) => !currVisibility);
    };

    const contractsFilterFunc = (contract: Contract) => {
        if (!contract.isActive) {
            return false;
        }

        switch (usage) {
            case SystemUsage.INTERNAL:
                return contract.type === 'WalkMe for Employees' || contract.type === 'WalkMe Core Offering';
            case SystemUsage.EXTERNAL:
                return contract.type === 'WalkMe for Customers';

            default:
                return true;
        }
    };

    const contractsSortFunc = (contractA: Contract, contractB: Contract) => {
        const contractAIsRecommended = isContractRecommended(contractA, systems, t);
        const contractBIsRecommended = isContractRecommended(contractB, systems, t);

        if (contractAIsRecommended && !contractBIsRecommended) return -1;
        if (!contractAIsRecommended && contractBIsRecommended) return 1;
        return 0;
    };

    const filteredContracts = contracts ? contracts.filter(contractsFilterFunc) : [];
    const sortedContracts = [...filteredContracts].sort(contractsSortFunc);

    const shouldDisplayRecommendedBadge = !sortedContracts.every((contract) => {
        const exceededUsage = !isContractRecommended(contract, systems, t);

        return !exceededUsage;
    });

    const applyFadeEffectObserver = () => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                setFadeEffectIsVisible(false);
            } else {
                setFadeEffectIsVisible(true);
            }
        });

        if (lastContractRef.current) {
            observer.observe(lastContractRef.current);
        }

        return () => {
            if (lastContractRef.current) {
                observer.unobserve(lastContractRef.current);
            }
        };
    };

    const handleOnlyOneContract = () => {
        if (sortedContracts.length === 1 && selectedContract === null) {
            handleOnSelectContract(sortedContracts[0]);
        }
    };

    useEffect(applyFadeEffectObserver, [sortedContracts]);

    useEffect(handleOnlyOneContract, [sortedContracts, selectedContract]);

    return (
        <StyledContractListContainer disabled={isDisabledStep} fadeEffect={fadeEffectIsVisible}>
            <div className='descriptionBlock'>
                <div className='title'>{t('systems-tab.new-system-form.select-contract')}</div>
                <div className='description'>{t('systems-tab.new-system-form.contract-list-description')}</div>
            </div>
            <div className='contractsContainer'>
                {!isDisabledStep && sortedContracts?.length > 0 ? (
                    <>
                        <div className='header' onClick={handleToggleContractsList}>
                            <span>{t('systems-tab.new-system-form.available-contracts')}</span>
                            {contractsListIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </div>
                        {contractsListIsOpen ? (
                            <div className='contracts'>
                                {!contracts && <StyledLoadingSpinner size={25} thickness={3} />}
                                {sortedContracts.map((contract, contractIndex) => {
                                    const numOfSystemsInUse = getContractNumOfSystemsInUse(contract, systems);
                                    const numOfPurchasedSystems = getContractNumOfPurchasedSystems(contract, rt);
                                    const exceededUsage = !isContractRecommended(contract, systems, t);

                                    return (
                                        <div ref={contractIndex === sortedContracts.length - 1 ? lastContractRef : null}>
                                            <ContractCard
                                                contract={contract}
                                                onClick={() => handleOnSelectContract(contract)}
                                                selected={selectedContract?.sfId === contract.sfId}
                                                isRecommended={shouldDisplayRecommendedBadge && !exceededUsage}
                                                systemsInUse={numOfSystemsInUse}
                                                numOfPurchasedSystems={numOfPurchasedSystems}
                                                disabled={isDisabledStep}
                                                exceededUsage={exceededUsage}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                ) : null}
                <div className='determineLaterContainer'>
                    <ContractCard
                        text={t('systems-tab.new-system-form.determine-later')}
                        onClick={() => handleOnSelectContract(undefined)}
                        selected={selectedContract === undefined}
                        disabled={isDisabledStep}
                    />
                </div>
            </div>
        </StyledContractListContainer>
    );
};
