import { DesktopMacIdentifier } from "./desktop-mac-identifier";
import { DesktopWinIdentifier } from "./desktop-win-identifier";

export interface DesktopTrackedApp {
    systemId?: number;
    msaId?: number;
    systemName: string;
    status?: string;
    winIdentifiers?: Array<DesktopWinIdentifier>;
    macIdentifiers?: Array<DesktopMacIdentifier>;
}

export type DesktopTrackedApps = DesktopTrackedApp[];