import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { WMButton, WMButtonVariant } from '@walkme/wm-ui';
import { useDeletedEnvironment, webSystemsSlice } from '@walkme-admin-center/libs/state-management-extensions';
import { AppThunkDispatch } from '@walkme-admin-center/libs/state-management-users';

export interface DeleteEnvironmentProps {
    open: boolean;
    onClose: () => void;
    deleteEnvironment: (envId: number) => void;
    setOpenSuccessSnackbar: React.Dispatch<React.SetStateAction<string>>;
    setOpenErrorSnackbar: React.Dispatch<React.SetStateAction<string>>;
    envId: number;
}

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 15px;
        overflow-y: hidden;
        box-shadow: 0px 36px 52px 0px #2f3d531c;
    }

    .MuiDialog-container {
        background: #2f426c9c;
    }

    .dialog-content {
        overflow-y: visible;
        margin: 0 32px 0 32px;
        padding: 0;
    }

    .dialog-actions {
        margin: 32px;
    }
`;

const StyledTextButton = styled(WMButton)`
    color: #637191;
    width: 80px;
    height: 40px;
    border-radius: 40px;

    &:hover {
        color: #637191;
        font-weight: bold;
    }
`;

const StyledDeleteButton = styled(WMButton)`
    background-color: var(--wmError);
    width: 80px;
    height: 40px;
    border-radius: 40px;

    &:hover {
        background-color: var(--wmErrorDark);
    }
`;

const DeleteEnvironment = ({
    open,
    onClose,
    deleteEnvironment,
    envId,
    setOpenSuccessSnackbar,
    setOpenErrorSnackbar,
}: DeleteEnvironmentProps) => {
    const dispatch = useDispatch();
    const [environmentLoading, setEnvironmentLoading] = useState(false);
    const { deletedEnvironmentAppData } = useDeletedEnvironment();

    const onDeleteEnvironment = () => {
        setEnvironmentLoading(true);
        deleteEnvironment(envId);
    };

    useEffect(() => {
        if (deletedEnvironmentAppData.data.action) {
            onClose();
            setOpenSuccessSnackbar('Environment successfully deleted');
            setEnvironmentLoading(false);
            (dispatch as AppThunkDispatch)(webSystemsSlice.actions.cleanupDeletedEnv());
        } else if (deletedEnvironmentAppData.error) {
            setOpenErrorSnackbar(deletedEnvironmentAppData.error);
            setEnvironmentLoading(false);
            (dispatch as AppThunkDispatch)(webSystemsSlice.actions.cleanupDeletedEnv());
        }
    }, [deletedEnvironmentAppData]);

    return (
        <StyledDialog onClose={() => onClose()} open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            color: '#2F426C',
                            fontSize: '18px',
                            fontWeight: 600,
                        }}>
                        Delete environment?
                    </Typography>
                    <IconButton onClick={onClose}>
                        <img src='assets/icons/close.svg' alt='close' style={{ width: 9 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={'dialog-content'}>
                <Typography
                    style={{
                        fontFamily: 'Proxima nova',
                        color: '#2F426C',
                        fontSize: '16px',
                        fontWeight: 400,
                        marginBottom: '10px',
                    }}>
                    WalkMe content will no longer be visible once the environment is deleted. You can restore deleted environments at any
                    time from the Deleted Environment tab.
                </Typography>
            </DialogContent>
            <DialogActions className={'dialog-actions'}>
                <StyledTextButton variant={WMButtonVariant.Text} disabled={environmentLoading} onClick={() => onClose()}>
                    Cancel
                </StyledTextButton>
                <StyledDeleteButton variant={WMButtonVariant.Primary} onClick={onDeleteEnvironment} loading={environmentLoading}>
                    Delete
                </StyledDeleteButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default DeleteEnvironment;
