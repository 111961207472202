export default  {
  "logo-OS-mac": `${require('./logo-OS-mac.svg')}`,
  "logo-OS-windows": `${require('./logo-OS-windows.svg')}`,
  "logo-system-adobe-acrobat": `${require('./logo-system-adobe-acrobat.svg')}`,
  "logo-system-adobe-reader": `${require('./logo-system-adobe-reader.svg')}`,
  "logo-system-datagrip": `${require('./logo-system-datagrip.svg')}`,
  "logo-system-drive-backup-sync": `${require('./logo-system-drive-backup-sync.svg')}`,
  "logo-system-drive-filestream": `${require('./logo-system-drive-filestream.svg')}`,
  "logo-system-edge": `${require('./logo-system-edge.svg')}`,
  "logo-system-excel": `${require('./logo-system-excel.svg')}`,
  "logo-system-firefox": `${require('./logo-system-firefox.svg')}`,
  "logo-system-forticlient": `${require('./logo-system-forticlient.svg')}`,
  "logo-system-google-chrome": `${require('./logo-system-google-chrome.svg')}`,
  "logo-system-internet-explorer": `${require('./logo-system-internet-explorer.svg')}`,
  "logo-system-jetbrains": `${require('./logo-system-jetbrains.svg')}`,
  "logo-system-ms-teams": `${require('./logo-system-ms-teams.svg')}`,
  "logo-system-ms-word": `${require('./logo-system-ms-word.svg')}`,
  "logo-system-numbers": `${require('./logo-system-numbers.svg')}`,
  "logo-system-one-note": `${require('./logo-system-one-note.svg')}`,
  "logo-system-outlook": `${require('./logo-system-outlook.svg')}`,
  "logo-system-photoshop": `${require('./logo-system-photoshop.svg')}`,
  "logo-system-placeholder": `${require('./logo-system-placeholder.svg')}`,
  "logo-system-powerpoint": `${require('./logo-system-powerpoint.svg')}`,
  "logo-system-skype": `${require('./logo-system-skype.svg')}`,
  "logo-system-slack": `${require('./logo-system-slack.svg')}`,
  "logo-system-snagit": `${require('./logo-system-snagit.svg')}`,
  "logo-system-spotify": `${require('./logo-system-spotify.svg')}`,
  "logo-system-teamviewer": `${require('./logo-system-teamviewer.svg')}`,
  "logo-system-walkme": `${require('./logo-system-walkme.svg')}`,
  "logo-system-whatsapp": `${require('./logo-system-whatsapp.svg')}`,
  "logo-system-wireshark": `${require('./logo-system-wireshark.svg')}`,
  "logo-system-zoom": `${require('./logo-system-zoom.svg')}`,
  "logo-system-custom-system": `${require('./logo-system-custom-system.svg')}`
};
