import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Base } from './base';
import {
    FormMode,
    IHeaderText,
    IIntegrationType,
    IIntegration,
    IIntegrationForm,
    IIntegrationFormSubmit,
} from 'packages/libs/state-mangment-data-integration/src/lib/types';
import get from 'lodash/get';
import { getErrorObject } from '../services/validation/validate';
import { appNameSchema, appCredentialsSchema } from '../services/validation/slack';
import { CONSTS } from '../../../../../sso-configuration/src/lib/common/consts';
import { createIntegration } from '@walkme-admin-center/libs/state-mangment-data-integration';
import AppNameStep from '../data-integration-form-components/slack-form/steps/app-name-step/app-name-step';
import ManifestStep from '../data-integration-form-components/slack-form/steps/manifest-step/manifest-step';
import CredentialsStep from '../data-integration-form-components/slack-form/steps/credentials-step/credentials-step';
import CreateSlackIntegrationFooter from '../data-integration-form-components/slack-form/footers/create-slack-integration-footer';
import SlackIcon from 'packages/libs/common/assets/icons/data-integrations/integrations-icons/system-icons/slack-icon-small.svg';
import WalkMeIcon from 'packages/libs/common/assets/icons/data-integrations/WalkMe_Short.svg';

export interface IHandleSlackSubmit {
    values: IIntegrationFormSubmit;
    formMode: FormMode;
    integration: IIntegration;
    activeStep: number;
}

export class Slack extends Base {
    constructor(dispatch, showSnackBar, closeForm, changeActiveStep) {
        super(dispatch, showSnackBar, closeForm, changeActiveStep);
    }

    async handleSubmit({ values }: IHandleSlackSubmit): Promise<void> {
        await this.save(values);
    }

    async save(values: IIntegrationFormSubmit): Promise<void> {
        try {
            await this.dispatch(createIntegration(values));
            this.closeForm();
            this.showSnackBar({
                open: true,
                messageText: `Slack integration created successfully.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_SUCCESS,
            });
        } catch (error) {
            this.showSnackBar({
                open: true,
                messageText: `Something went wrong.`,
                severity: CONSTS.SNACK_BAR_SEVERITY_ERROR,
            });
        }
    }

    getFormLabels(): Array<string> {
        return ['App Name', 'App Manifest', 'App Credentials'];
    }

    getFormIntegrationIcons(): Array<IIntegrationType> {
        return [
            { icon: SlackIcon, name: 'Slack' },
            { icon: WalkMeIcon, name: 'WalkMe' },
        ];
    }

    getFormHeader(): IHeaderText {
        return { header: 'Integrate Slack', subHeader: null };
    }

    getFormByStep(step: number): React.ReactNode {
        switch (step) {
            case 0:
                return <AppNameStep />;
            case 1:
                return <ManifestStep />;
            case 2:
                return <CredentialsStep />;
        }
    }

    getStepperFooter(step: number, handleWarningModel: (flag: boolean) => void): React.ReactNode {
        return (
            <CreateSlackIntegrationFooter step={step} handleWarningModel={handleWarningModel} changeActiveStep={this.changeActiveStep} />
        );
    }

    async validate(values: IIntegrationForm, step: number) {
        let schema;
        if (step === 0) {
            schema = appNameSchema;
        } else if (step === 1) {
            return null;
        } else if (step === 2) {
            schema = appCredentialsSchema;
        }
        let providerSettingsErrors = await getErrorObject(schema, values);
        if (providerSettingsErrors) {
            return providerSettingsErrors;
        }
        return null;
    }

    initForm(integration: IIntegration) {
        return {
            guid: get(integration, 'guid', uuidv4()),
            name: get(integration, 'name', ''),
            type: 'slack',
            settings: {
                app_key: get(integration, 'settings.app_key', ''),
                app_secret: get(integration, 'settings.app_secret', ''),
            },
        };
    }
}
