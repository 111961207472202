import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
    AccountsSdk,
    User,
    UpdateAccountSessionLifetime,
    AccountImpersonationRestrictedData,
    MfaSettings,
    UpdateMfaSettings,
} from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { ThunkAction } from 'redux-thunk';
import { UpdateMyAccountImpersonationRestrictionDTO } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/impersonation-settings-types';
import rootReducer from '../../../../../../apps/home/src/redux/rootReducer';

export interface AccountState {
    loggedInUser: AppData<User>;
    sessionLifetime: AppData<number>;
    impersonationSettings: AppData<AccountImpersonationRestrictedData>;
    mfaSettings: AppData<MfaSettings>;
    sessionLifetimeOptions: AppData<number[]>;
}

export const initialUser: User = {
    id: null,
    email: null,
    firstName: null,
    lastLoginDate: null,
    lastName: null,
    idpNameId: null,
    isBackoffice: false,
    status: null,
    account: {
        id: null,
        email: '',
        types: null,
    },
};

export const initialAccountState: AccountState = {
    loggedInUser: {
        loading: false,
        error: null,
        data: initialUser,
    },
    sessionLifetime: {
        loading: false,
        error: null,
        data: null,
    },
    impersonationSettings: {
        loading: false,
        error: null,
        data: null,
    },
    mfaSettings: {
        loading: false,
        error: null,
        data: null,
    },
    sessionLifetimeOptions: {
        loading: false,
        error: null,
        data: null,
    },
};

const accountSlice = createSlice({
    name: 'accountSlice',
    initialState: initialAccountState,
    reducers: {
        getLoggedInUserStart(state: AccountState) {
            state.loggedInUser.error = null;
            state.loggedInUser.loading = true;
            return state;
        },
        getLoggedInUserSuccess(state: AccountState, action: PayloadAction<User>) {
            state.loggedInUser.data = action.payload;
            state.loggedInUser.loading = false;
            state.loggedInUser.error = null;
            return state;
        },
        getLoggedInUserFailed(state, action: PayloadAction<string>) {
            state.loggedInUser.loading = false;
            state.loggedInUser.error = action.payload;
            return state;
        },
        getSessionLifetimeOptionsSuccess(state: AccountState, action: PayloadAction<number[]>) {
            state.sessionLifetimeOptions.data = action.payload;
            state.sessionLifetime.loading = false;
            state.sessionLifetime.error = null;
            return state;
        },
        getAccountSessionLifetimeStart(state: AccountState) {
            state.sessionLifetime.error = null;
            state.sessionLifetime.loading = true;
            return state;
        },
        getAccountSessionLifetimeSuccess(state: AccountState, action: PayloadAction<number>) {
            state.sessionLifetime.data = action.payload;
            state.sessionLifetime.loading = false;
            state.sessionLifetime.error = null;
            return state;
        },
        getAccountSessionLifetimeFailed(state, action: PayloadAction<string>) {
            state.sessionLifetime.loading = false;
            state.sessionLifetime.error = action.payload;
            return state;
        },
        getAccountImpersonationSettingsStart(state: AccountState) {
            state.impersonationSettings.error = null;
            state.impersonationSettings.loading = true;
            return state;
        },
        getAccountImpersonationSettingsSuccess(state: AccountState, action: PayloadAction<AccountImpersonationRestrictedData>) {
            state.impersonationSettings.data = action.payload;
            state.impersonationSettings.loading = false;
            state.impersonationSettings.error = null;
            return state;
        },
        getAccountImpersonationSettingsFailed(state, action: PayloadAction<string>) {
            state.impersonationSettings.loading = false;
            state.impersonationSettings.error = action.payload;
            return state;
        },
        getAccountMfaSettingsStart(state: AccountState) {
            state.mfaSettings.error = null;
            state.mfaSettings.loading = true;
            return state;
        },
        getAccountMfaSettingsSuccess(state: AccountState, action: PayloadAction<MfaSettings>) {
            state.mfaSettings.data = action.payload;
            state.mfaSettings.loading = false;
            state.mfaSettings.error = null;
            return state;
        },
        getAccountMfaSettingsFailed(state, action: PayloadAction<string>) {
            state.mfaSettings.loading = false;
            state.mfaSettings.error = action.payload;
            return state;
        },
    },
});

export { accountSlice };
const {
    getLoggedInUserFailed,
    getLoggedInUserSuccess,
    getLoggedInUserStart,
    getSessionLifetimeOptionsSuccess,
    getAccountSessionLifetimeStart,
    getAccountSessionLifetimeSuccess,
    getAccountSessionLifetimeFailed,
    getAccountImpersonationSettingsStart,
    getAccountImpersonationSettingsSuccess,
    getAccountImpersonationSettingsFailed,
    getAccountMfaSettingsStart,
    getAccountMfaSettingsSuccess,
    getAccountMfaSettingsFailed,
} = accountSlice.actions;
type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

function errorFormatter(err) {
    const errMessage = (err.response && err.response.data && err.response.data.message) || err.message || 'Unspecified error';
    return errMessage;
}

export const getLoggedInUser = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getLoggedInUserStart());
        const user: User = await AccountsSdk.getInstance().getLoggedInUserData();
        dispatch(getLoggedInUserSuccess(user));
    } catch (err) {
        dispatch(getLoggedInUserFailed(err.message));
        return;
    }
};

export const getSessionLifetime = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getAccountSessionLifetimeStart());
        const [sessionLifetimeOptions, sessionLifetime] = await Promise.all([
            AccountsSdk.getInstance().getAccountSessionLifetimeOptions(),
            AccountsSdk.getInstance().getAccountSessionLifetime(),
        ]);
        dispatch(getSessionLifetimeOptionsSuccess(sessionLifetimeOptions));
        dispatch(getAccountSessionLifetimeSuccess(sessionLifetime));
    } catch (err) {
        dispatch(getAccountSessionLifetimeFailed(errorFormatter(err)));
        return;
    }
};

export const updateSessionLifetime = (newSessionLifeTime: UpdateAccountSessionLifetime): AppThunk => async (dispatch) => {
    try {
        dispatch(getAccountSessionLifetimeStart());
        const sessionLifetime: number = await AccountsSdk.getInstance().updateAccountSessionLifetime(newSessionLifeTime);
        dispatch(getAccountSessionLifetimeSuccess(sessionLifetime));
    } catch (err) {
        dispatch(getAccountSessionLifetimeFailed(errorFormatter(err)));
        return;
    }
};

export const getImpersonationSettings = (): AppThunk => async (dispatch) => {
    try {
        dispatch(getAccountImpersonationSettingsStart());
        const impersonationSettings = await AccountsSdk.getInstance().impersonationSettings.getImpersonationRestrictionSettings();
        dispatch(getAccountImpersonationSettingsSuccess(impersonationSettings));
    } catch (err) {
        dispatch(getAccountImpersonationSettingsFailed(errorFormatter(err)));
        return;
    }
};

export const updateImpersonationSettings = (impersonationConfig: UpdateMyAccountImpersonationRestrictionDTO): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(getAccountImpersonationSettingsStart());
        const impersonationSettings = await AccountsSdk.getInstance().impersonationSettings.updateImpersonationRestrictionSettings(
            impersonationConfig
        );
        dispatch(getAccountImpersonationSettingsSuccess(impersonationSettings));
    } catch (err) {
        dispatch(getAccountImpersonationSettingsFailed(errorFormatter(err)));
        return;
    }
};

export const getMfaSettings = (forceLoad = true): AppThunk => async (dispatch, getState) => {
    try {
        const alreadyLoadedMfa = getState().accountState.mfaSettings;
        if (alreadyLoadedMfa.data && !forceLoad) {
            return;
        }
        dispatch(getAccountMfaSettingsStart());
        const mfaSettings = await AccountsSdk.getInstance().accountMfaSettings.getMfaSettings();
        dispatch(getAccountMfaSettingsSuccess(mfaSettings));
    } catch (err) {
        dispatch(getAccountMfaSettingsFailed(errorFormatter(err)));
        return;
    }
};

export const updateMfaSettings = (mfaConfig: UpdateMfaSettings): AppThunk => async (dispatch) => {
    try {
        dispatch(getAccountMfaSettingsStart());
        const mfaSettings = await AccountsSdk.getInstance().accountMfaSettings.updateMfaSettings(mfaConfig);
        dispatch(getAccountMfaSettingsSuccess(mfaSettings));
    } catch (err) {
        dispatch(getAccountMfaSettingsFailed(errorFormatter(err)));
        return;
    }
};
