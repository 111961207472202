import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
    &.root {
        width: 24px;
        height: 24px;
        margin: 0 4px;
    }
`;
