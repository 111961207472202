import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import { Form as FormStyles, RouteHeader } from '@walkme-admin-center/libs/shared-styles';
import { LoadingPage } from '@walkme-admin-center/libs/common';
import {
    DesktopTrackedApp,
    useDesktopTrackedApp,
    useDesktopTrackedApps,
    DesktopMacIdentifier,
    DesktopWinIdentifier,
    useUpdatedDesktopTrackedApp,
    useCreatedDesktopTrackedApp,
} from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import SystemName from './system-name';
import CenterSection from './center-section';
import BottomSection from './bottom-section';
import { useDispatch } from 'react-redux';
import {
    createDesktopTrackedApp,
    updateDesktopTrackedApp,
    desktopTrackedAppsSlice,
} from 'packages/libs/state-management-desktop-tracked-apps/src/lib/redux/desktop-tracked-apps.slice';
import { CONSTS } from './types/consts';

const DesktopTrackedAppsDetails = () => {
    const { id } = useParams<{ id }>();
    const systemId = id ? Number(id) : null;
    const { loggedInUserAppData } = useLoggedInUser();
    const msaId = loggedInUserAppData.data.account.id;
    const { desktopTrackedAppsData } = useDesktopTrackedApps(msaId);
    const allDesktopTrackedApps = desktopTrackedAppsData.data || [];
    const navigate = useNavigate();
    const [systemName, setSystemName] = useState<string>('');
    const [identifiersValid, setIdentifiersValid] = useState<boolean>(true);
    const [systemNameValid, setSystemNameValid] = useState<boolean>(true);
    const [winIdentifiers, setWinIdentifiers] = useState<DesktopWinIdentifier[]>([]);
    const [macIdentifiers, setMacIdentifiers] = useState<DesktopMacIdentifier[]>([]);
    const currentDesktopTrackedAppData = useDesktopTrackedApp(msaId, systemId).currentDesktopTrackedAppData;
    const updatedDesktopTrackedAppData = useUpdatedDesktopTrackedApp();
    const createdDesktopTrackedAppData = useCreatedDesktopTrackedApp();
    const updatedDesktopTrackedApp = updatedDesktopTrackedAppData.updatedDesktopTrackedAppData;
    const createdDesktopTrackedApp = createdDesktopTrackedAppData.createdDesktopTrackedAppData;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(desktopTrackedAppsSlice.actions.cleanCurrentDesktopTrackedApp());
            dispatch(desktopTrackedAppsSlice.actions.cleanCreatedDesktopTrackedApp());
            dispatch(desktopTrackedAppsSlice.actions.cleanUpdatedDesktopTrackedApp());
        };
    }, [dispatch]);

    useEffect(() => {
        let currentDesktopTrackedApp = currentDesktopTrackedAppData.data;
        const newIdentifiersValid =
            !!systemId && (currentDesktopTrackedApp.winIdentifiers.length > 0 || currentDesktopTrackedApp.macIdentifiers.length > 0);

        setSystemName(currentDesktopTrackedApp.systemName);
        setWinIdentifiers([...currentDesktopTrackedApp.winIdentifiers]);
        setMacIdentifiers([...currentDesktopTrackedApp.macIdentifiers]);
        setIdentifiersValid(newIdentifiersValid);
        setSystemNameValid(!!currentDesktopTrackedApp.systemName);
    }, [currentDesktopTrackedAppData]);

    useEffect(() => {
        if (createdDesktopTrackedApp.data.systemId) {
            const addedDesktopAppsResults = {
                success: [
                    {
                        id: `${createdDesktopTrackedApp.data.systemId}`,
                        systemName: createdDesktopTrackedApp.data.systemName,
                    },
                ],
            };

            dispatch(desktopTrackedAppsSlice.actions.addedDesktopTrackedAppsResults(addedDesktopAppsResults));
            navToListView();
        }
    }, [createdDesktopTrackedApp]);

    useEffect(() => {
        if (updatedDesktopTrackedApp.data.systemId === systemId) {
            navToListView();
        }
    }, [updatedDesktopTrackedApp]);

    const navToListView = useCallback(() => {
        navigate('/desktop-tracked-apps');
    }, [history]);

    const actions = {
        onCancel: navToListView,
        onConfirm: () => null,
    };

    const onSystemNameChanged = (value: string) => {
        setSystemName(value);
    };

    const onWinIdentifiersChange = (winIdentifiers: DesktopWinIdentifier[]) => {
        setWinIdentifiers(winIdentifiers);
    };

    const onMacIdentifiersChange = (macIdentifiers: DesktopMacIdentifier[]) => {
        setMacIdentifiers(macIdentifiers);
    };

    const onSubmit = () => {
        const desktopTrackedAppToSave: DesktopTrackedApp = {
            systemName,
            winIdentifiers: removeLocalIds(winIdentifiers),
            macIdentifiers: removeLocalIds(macIdentifiers),
        };

        if (systemId) {
            dispatch(updateDesktopTrackedApp(msaId, systemId, desktopTrackedAppToSave));
        } else {
            dispatch(createDesktopTrackedApp(msaId, desktopTrackedAppToSave));
        }
    };

    const removeLocalIds = (identifiers: any[]) => {
        const identifiersWithoutLocalIds = identifiers.map((identifier: any) => {
            let id;

            if (!identifier.id.startsWith(CONSTS.LOCAL_ID)) {
                id = identifier.id;
            }

            return {
                ...identifier,
                id,
            };
        });

        return identifiersWithoutLocalIds;
    };

    const renderContent = () => {
        return (
            <>
                <FormStyles>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{}}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <SystemName
                                    systemId={systemId}
                                    systemName={systemName}
                                    allDesktopTrackedApps={allDesktopTrackedApps}
                                    onChanged={onSystemNameChanged}
                                    setSystemNameValid={setSystemNameValid}
                                />

                                <Card>
                                    <CenterSection
                                        systemId={systemId}
                                        winIdentifiers={winIdentifiers}
                                        macIdentifiers={macIdentifiers}
                                        onWinIdentifiersChange={onWinIdentifiersChange}
                                        onMacIdentifiersChange={onMacIdentifiersChange}
                                        allDesktopTrackedApps={allDesktopTrackedApps}
                                        setIdentifiersValid={setIdentifiersValid}
                                    />

                                    <Divider />

                                    <BottomSection
                                        submitting={updatedDesktopTrackedApp.loading || createdDesktopTrackedApp.loading}
                                        actions={actions}
                                        pristine={false}
                                        saveActionName='save configuration'
                                        invalid={!identifiersValid || !systemNameValid}
                                    />
                                </Card>
                            </form>
                        )}
                    />
                </FormStyles>
            </>
        );
    };

    let content;

    if (currentDesktopTrackedAppData.loading) {
        content = <LoadingPage />;
    } else if (desktopTrackedAppsData.error || currentDesktopTrackedAppData.error) {
        content = <div>Something went wrong! Please try again later.</div>;
    } else {
        content = renderContent();
    }

    return <>{content}</>;
};

export default DesktopTrackedAppsDetails;
