import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography/Typography';
import WMStatusDot, { WMStatusDotVariant } from '@walkme/wm-ui/components/WMStatusDot';
import React from 'react';
import { useStyles } from './styles';

export interface EnvironmentProps {
    name: string;
    isActive: boolean;
    isSelected: boolean;
    onClick: () => void;
    shouldDisable: boolean;
}

const Environment = ({ name, isActive, isSelected, onClick, shouldDisable }: EnvironmentProps) => {
    const classes = useStyles();
    const baseContainerClassName = isSelected ? `${classes.container} ${classes.selectedEnvContainer}` : classes.container;
    const containerClassName = `${baseContainerClassName} ${shouldDisable ? classes.cursorNotAllowed : classes.cursorPointer}`;

    return (
        <Box className={containerClassName} onClick={shouldDisable ? () => {} : onClick}>
            <WMStatusDot
                tooltipMessage={isActive ? 'Active' : 'Not Active'}
                variant={isActive ? WMStatusDotVariant.Success : WMStatusDotVariant.Failure}
            />
            <Typography className={classes.name}>{name}</Typography>
        </Box>
    );
};

export default Environment;
