import React, { SVGProps } from 'react';

export const ShieldSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3406_15696)'>
            <path
                d='M1.50002 2.61535V7.69135C1.50004 9.29577 1.98623 10.8625 2.89453 12.1851C3.80282 13.5077 5.09057 14.524 6.58802 15.1L7.28202 15.3667C7.74419 15.5444 8.25586 15.5444 8.71802 15.3667L9.41202 15.1C10.9095 14.524 12.1972 13.5077 13.1055 12.1851C14.0138 10.8625 14.5 9.29577 14.5 7.69135V2.61535C14.5013 2.42433 14.4474 2.23702 14.3447 2.07594C14.2421 1.91485 14.095 1.78686 13.9214 1.70735C12.0546 0.892538 10.0368 0.481119 8.00002 0.500012C5.96326 0.481119 3.94546 0.892538 2.07869 1.70735C1.90501 1.78686 1.758 1.91485 1.65534 2.07594C1.55268 2.23702 1.49874 2.42433 1.50002 2.61535Z'
                stroke='#92A1BC'
                strokeWidth='1.125'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_3406_15696'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
