import { withStyles } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import styled from 'styled-components';

export const SelectIcon = withStyles({
    root: {
        color: 'white',
        position: 'absolute',
        right: '3px',
        top: '4px',
        width: '8px',
        height: '8px',
    },
})(Done);

export const FieldLabel = styled.span`
    font-weight: 600;
    font-size: 16px;
    color: #2f426c;
`;

export const Container = styled.div`
    padding: 0px 24px;
    display: flex;
    flex-direction: column;

    &.contained {
        width: 100%;
    }
`;

export const SystemsContainer = styled.div<{ viewFlag: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-height: ${(props) => (props.viewFlag ? '710px' : '100%')};
    overflow: ${(props) => (props.viewFlag ? 'auto' : 'hidden')};
    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: #c8d3f9;
        height: 64px;
    }
`;

export const CardContainer = styled.div`
    width: 42%;
    height: 32px;
    background: #ffffff;
    border: solid 1px #edf1fd;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;
    transition: border 0.3s ease, box-shadow 0.3s ease;

    &.selected {
        border-color: #3b61eb !important;
        background: #f5f7fe;
    }

    &:hover {
        cursor: pointer;
        border: 1px solid var(--Primary-Primary-Tint-2, #d8dffb);
        box-shadow: 0px 4px 12px 0px rgba(47, 61, 83, 0.11);
    }
`;

export const ContentContainer = styled.div`
    height: 36px;
    width: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #f0f3f9;
    border-radius: 8px;
`;

export const SystemName = styled.span`
    padding-left: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #2f426c;
    &.selected {
        font-weight: 600;
    }
`;

export const CardSelected = styled.div`
    width: 0;
    height: 0;
    background: #f5f7fe;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 24px solid transparent;
    border-top: 24px solid #3b61eb;
`;

export const CardSelectedIcon = styled.span`
    padding-left: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #2f426c;
    &.selected {
        font-weight: 600;
    }
`;

export const FooterContainer = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
`;

export const StyledSearch = styled.div<{ viewFlag: boolean }>`
    margin-bottom: 16px;
    margin-top: ${(props) => (props.viewFlag ? '24px' : '0')};
    gap: 0.75rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
