import styled from 'styled-components';

export const StyledTitleDiv = styled.div`
    margin-bottom: 24px;
`;

export const StyledPageTitleLabel = styled.label`
    font-family: 'ProximaNova';
    color: #7680a3;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
`;
