import * as yup from 'yup';
import { isValidPath, validateDomainAndPath } from '../validate';

yup.addMethod(yup.array, 'uniquePath', function (key, message) {
    return this.test('unique', message, function (list) {
        if (!list) {
            return true;
        }
        const mapper = (x) => x;
        const set = [...new Set(list)];
        const isUnique = list.length === set.length;
        if (isUnique) {
            return true;
        }

        const idx = list.findIndex((l, i) => {
            return mapper(l) !== set[i];
        });
        return this.createError({
            path: `${key}[${idx}]`,
            message: message,
        });
    });
});

export const domainSchema = yup.object().shape({
    name: yup.string().max(255, 'Integration Name must be at most 255 characters').required('Required'),
    settings: yup.object({
        startingUrl: yup
            .string()
            .max(500, 'Url must be at most 500 characters')
            .required('Required')
            .test('Valid url', 'Please enter valid url', (value) => (value ? validateDomainAndPath(value) : true)),
        // @ts-ignore
        allowPath: yup.array().when('controlDomains', {
            is: (value) => value,
            then: yup
                .array()
                .of(
                    yup
                        .string()
                        .required('required')
                        .max(500)
                        .test('Valid path', 'Please enter path', (value) => (value ? isValidPath(value) : true))
                )
                // @ts-ignore
                .uniquePath('settings.allowPath', 'Must be unique'),
        }),
        denyPath: yup.array().when('controlDomains', {
            is: (value) => value,
            then: yup
                .array()
                .of(
                    yup
                        .string()
                        .required('')
                        .max(500)
                        .test('Valid path', 'Please enter path', (value) => (value ? isValidPath(value) : true))
                )
                // @ts-ignore
                .uniquePath('settings.denyPath', 'Must be unique'),
        }),
    }),
});
