import { AppData } from '@walkme-admin-center/libs/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWebSystem, WebSystemsState } from '../redux/web-systems.slice';
import { GetSystemResult } from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';

export const useWebSystem = (systemUserId: number, forceLoad = false) => {
    const webSystemAppData: AppData<GetSystemResult> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.webSystem
    );

    const dispatch = useDispatch();
    useEffect(() => {
        if (systemUserId) {
            dispatch(getWebSystem(systemUserId, forceLoad));
        }
    }, [dispatch]);

    return { webSystemAppData };
};

export default useWebSystem;
