import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    dialog: {
        maxWidth: '570px',
    },
    dialogTitleContainer: {
        margin: '24px 32px 16px 32px',
        padding: 0,
    },
    dialogTitleBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    dialogTitleText: {
        fontFamily: 'Poppins',
        color: '#2F426C',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
    },
    dialogCloseBtn: {
        width: '28px',
        height: '28px',
        '& img': {
            width: '11px',
            height: '11px',
        },
    },
    dialogContentContainer: {
        padding: 0,
    },
    dialogButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '16px 32px 32px 32px',
        padding: 0,
    },
    rightButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
}));

export { useStyles };
