import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { WMIconLogo } from '@walkme-admin-center/libs/ui-components';
import { WMButton, WMSnackbar, WMSnackbarVariant, WMTextField } from '@walkme/wm-ui';
import { useLocation, useParams } from 'react-router-dom';
import { AppData, AppToUrlMapping } from '@walkme-admin-center/libs/types';
import { useDispatch } from 'react-redux';
import { StyledBackgroundImage, StyledPageGrid } from './open-app-screen.styles';

const DownloadUrl = `https://www.walkme.com/download`;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OpenAppScreenProps {}

const DefaultApp = AppToUrlMapping.editorElectron;

export const OpenAppScreen = (props: OpenAppScreenProps) => {
    const dispatch = useDispatch();

    const launchEditor = (validRedirectUrl: string) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.id = 'walkmeEditor';
        iframe.src = validRedirectUrl;
        document.body.appendChild(iframe);
    };

    const validRedirectUrl = (() => {
        try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const search = useLocation().search;
            const defaultAppToOpen = new URLSearchParams(search).get('defaultAppToOpen');
            const redirectUrlParamObject = AppToUrlMapping[defaultAppToOpen] || DefaultApp;
            return redirectUrlParamObject;
        } catch (e) {
            return DefaultApp;
        }
    })();

    const shouldShowScreen = validRedirectUrl === AppToUrlMapping.editorElectron;

    if (shouldShowScreen) {
        launchEditor(validRedirectUrl);
    } else {
        window.location.href = validRedirectUrl;
    }

    return shouldShowScreen ? (
        <div>
            <StyledPageGrid>
                <Grid container direction='column' alignItems='center' justifyContent='flex-start'>
                    <Grid item className={'logoGrid'}>
                        <WMIconLogo />
                    </Grid>
                    <Grid item className={'titleGrid'}>
                        <span className={'title'}>Start creating great content with WalkMe!</span>
                    </Grid>
                    <Grid item className={'subTitleGrid'}>
                        <span className={'subTitle'}>
                            Click Open WalkMe on the dialog shown by your browser
                            <br />
                            If you don't see a dialog, click here:
                        </span>
                    </Grid>
                    <Grid item className={'buttonGrid'}>
                        <WMButton
                            onClick={() => {
                                launchEditor(validRedirectUrl);
                            }}>
                            Open WalkMe Editor
                        </WMButton>
                    </Grid>
                    <Grid item className={'downloadGrid'}>
                        <span className={'downloadText'}>Don't have WalkMe Editor installed? </span>
                        <span
                            className={'downloadTextColor'}
                            onClick={() => {
                                window.location.href = DownloadUrl;
                            }}>
                            Download Now
                        </span>
                    </Grid>
                </Grid>
                <div className={'imageGrid'}>
                    <img className={'imageTag'} src='assets/signin/emails-image.png' alt='Open App Image' />
                </div>
            </StyledPageGrid>
            <StyledBackgroundImage src='assets/signin/open-app-background.svg' alt={'background image'} />
        </div>
    ) : (
        <></>
    );
};

export default OpenAppScreen;
