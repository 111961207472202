import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { WMButton, WMMessage } from '@walkme/wm-ui';
import { Button } from '@walkme/ui-core';
import { CodeBlock } from '../../../../self-hosting/src/lib/components/common/code-block';

export const StyledDialog = styled(Dialog)`
    .MuiDialog-container {
        background: #2f426c9c;
    }

    .MuiPaper-root {
        border-radius: 15px;
        overflow-y: hidden;
        box-shadow: 0px 36px 52px 0px #2f3d531c;
    }

    .dialog-content {
        color: #637191;
        overflow-y: visible;
        margin: 0 32px 0 32px;
        padding: 0;
    }
`;

export const StyledTextButton = styled(WMButton)`
    color: #637191;

    &:hover {
        color: #637191;
        font-weight: bold;
    }
`;

export const StyledCTAButton = styled(Button)`
    color: #385feb;
    background-color: inherit;
    border: 1px solid #3b61eb33;
    border-radius: 40px;

    &:hover {
        background-color: #0000000a;
    }
`;

export const StyledSuccessMessage = styled(WMMessage)`
    display: flex;
    justify-content: space-between;
    padding: 8px 12px 8px 16px;
    background-color: #e4f5f3;
    border-radius: 4px;
    border: 1px solid #41b7a6;

    & .WMMessage-message {
        color: #2f426c;
    }

    svg {
        color: #41b7a6;
    }
`;

export const StyledCodeBlock = styled(CodeBlock)`
    border-radius: 8px;

    .react-syntax-highlighter-line-number {
        color: #637191 !important;
    }
`;
