import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    StyledContractDetailsContainer,
    StyledDefaultContractDetails,
    StyledEmptyResultsIcon,
    StyledContractSystems,
    StyledContractTable,
    StyledMaxSystemsOverflowMessage,
    StyledContractSystemsContent,
    ReachedToBottomLine,
} from './contract-details.styles';
import { useSelector } from 'react-redux';
import { Contract, CreateSystemState, useSystems } from '@walkme-admin-center/libs/state-management-systems';
import { ContractAccordion, ContractsConfig } from '@walkme/accounts-contracts-ui';
import { getContractNumOfPurchasedSystems, getContractNumOfSystemsInUse } from '../contract-list/contract-list.lib';
import { getContractSystemsTableConfig } from './contract-details.lib';
import { Toaster, ToasterVariant } from '@walkme/ui-core';
import { SystemPurpose } from 'wm-accounts-sdk/dist/lib/accounts-sdk/types';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { shouldDisplayContracts } from '../select-contract.lib';

export const ContractDetails = () => {
    const { t, rt } = useTranslation('general');
    const contractsData: any = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.contractsData.data);
    const selectedContract: Contract = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedContract.data
    );
    const purpose: SystemPurpose = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.purpose.data);
    const { systemsAppData } = useSystems();
    const systemsData = systemsAppData?.data;

    const selectedContractsWithSystems = contractsData?.contracts.map((contract) => {
        const relatedSystems = contractsData.systems
            ?.filter((system) => system.contracts.includes(contract.sfId))
            .map((relevantSystem) => {
                const formatGuid = (guid) => guid.replace(/-/g, '');
                const extraSystemData = systemsData?.find((system) => formatGuid(relevantSystem.guid) === formatGuid(system.guid));
                return {
                    ...relevantSystem,
                    ...extraSystemData,
                };
            });

        return {
            ...contract,
            relatedSystems,
        };
    });

    const selectedContractWithSystems = selectedContractsWithSystems?.find((contract) => contract.sfId === selectedContract?.sfId);
    const numOfSystemsInUse = getContractNumOfSystemsInUse(selectedContractWithSystems, contractsData?.systems);
    const numOfPurchasedSystems = getContractNumOfPurchasedSystems(selectedContractWithSystems, rt);
    const isTestingMode = window.localStorage.getItem('testingNewSystemCreation');
    const { loggedInUserAppData } = useLoggedInUser();
    const accountTypes = loggedInUserAppData?.data?.account?.types;
    const isStepDisabled = !shouldDisplayContracts({ purpose, accountTypes, isTestingMode: isTestingMode === 'true' });
    const [fadeEffectIsVisible, setFadeEffectIsVisible] = useState<boolean>(true);
    const reachedToBottomRef = useRef(null);

    const getContractDetailsBody = () => {
        if (isStepDisabled) {
            return (
                <StyledDefaultContractDetails>
                    <StyledEmptyResultsIcon />
                    <div className='description'>{t('systems-tab.new-system-form.contract-description')}</div>
                </StyledDefaultContractDetails>
            );
        }
        if (selectedContract) {
            return (
                <StyledContractSystems
                    numOfSystems={selectedContractWithSystems?.relatedSystems?.length || 0}
                    fadeEffect={selectedContractWithSystems?.relatedSystems?.length > 0 && fadeEffectIsVisible}>
                    <div className='title'>{t('systems-tab.new-system-form.contract-title')}</div>
                    <StyledContractSystemsContent>
                        {typeof numOfPurchasedSystems === 'number' && numOfSystemsInUse > numOfPurchasedSystems ? (
                            <StyledMaxSystemsOverflowMessage
                                icon
                                variant={ToasterVariant.Warning}
                                message={t('systems-tab.new-system-form.contract-warning')}
                                width={'auto'}
                            />
                        ) : null}
                        <StyledContractTable id='contractSystems' key={selectedContract.sfId}>
                            <ContractAccordion
                                contractData={selectedContractWithSystems}
                                getContracts={() => null}
                                disconnectSystemFromContract={() => null}
                                connectSystemToContract={() => null}
                                config={getContractSystemsTableConfig(selectedContractWithSystems)}
                            />
                        </StyledContractTable>
                        <ReachedToBottomLine ref={reachedToBottomRef} />
                    </StyledContractSystemsContent>
                </StyledContractSystems>
            );
        }
        //determine later option is selected
        if (selectedContract === undefined) {
            return (
                <StyledDefaultContractDetails>
                    <StyledEmptyResultsIcon />
                    <div
                        className='description'
                        dangerouslySetInnerHTML={{ __html: t('systems-tab.new-system-form.contract-description-2') }}
                    />
                </StyledDefaultContractDetails>
            );
        }

        // empty state
        if (selectedContract === null) {
            return (
                <StyledDefaultContractDetails>
                    <StyledEmptyResultsIcon />
                    <div className='description'>{t('systems-tab.new-system-form.contract-description-3')}</div>
                </StyledDefaultContractDetails>
            );
        }
    };

    const applyFadeEffectObserver = () => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                setFadeEffectIsVisible(false);
            } else {
                setFadeEffectIsVisible(true);
            }
        });

        if (reachedToBottomRef.current) {
            observer.observe(reachedToBottomRef.current);
        }

        return () => {
            if (reachedToBottomRef.current) {
                observer.unobserve(reachedToBottomRef.current);
            }
        };
    };

    useEffect(applyFadeEffectObserver, [selectedContract]);

    return <StyledContractDetailsContainer>{getContractDetailsBody()}</StyledContractDetailsContainer>;
};
