import styled from 'styled-components';
import { InputSearch } from '@walkme/ui-core';
import { Filter } from '@walkme/ui-icons';
import { SortIcon } from '../../assets/icons/sort';

export const StyledSelectApplicationContainer = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow-y: hidden;
`;
export const StyledChangeToCustomContainer = styled.div<{ isEmptySearch: boolean }>`
    display: flex;
    font-weight: bold;
    font-size: 14px;
    padding: 20px 32px;
    align-items: center;
    border-top: 1px solid #ebeff7;
    border-bottom: 1px solid #ebeff7;
    border-color: ${({ isEmptySearch }) => (isEmptySearch ? '#385feb' : '#ebeff7')};
    background-color: ${({ isEmptySearch }) => (isEmptySearch ? '#edf1fd' : 'white')};

    .changeToCustomText {
        margin: 0 8px;
        color: var(--Typography-Typography, #2f426c);
        font-weight: 600;
    }
`;

export const StyledAppsFilterContainer = styled.div`
    padding: 24px 32px 12px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeff7;
`;

export const StyledFilterInputs = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledInputSearch = styled(InputSearch)`
    width: 300px;
    margin-right: 12px;
`;
export const StyledFilterSelections = styled.div`
    padding: 12px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeff7;

    .resetFiltersText {
        cursor: pointer;
        color: #767676;
    }
`;

export const StyledFilterIcon = styled(Filter)`
    cursor: pointer;
`;

export const StyledSortContainer = styled.div`
    display: flex;
`;

export const StyledSortIcon = styled(SortIcon)`
    cursor: pointer;
    margin-left: 12px;
`;

export const StyledApplicationFoundText = styled.div`
    color: var(--Color-Semantic-Text-text-secondary, #637191);
    font-family: 'Proxima Nova';
`;

export const StyledFilterIsClosedContainer = styled.div`
    width: 32px;
    margin-right: 8px;
`;

export const StyledFilterIsOpenContainer = styled.div`
    border-radius: 100px;
    background: var(--blue-primary-primary-main, #385feb);
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    margin-right: 8px;
`;
