import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Chip,
    Dialog,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { WMButton, WMButtonVariant, WMSnackbarVariant, WMIconRemove, WMIconClose, WMCircularProgress, WMIconWarning } from '@walkme/wm-ui';
import { useRoles, usersSlice, useActions } from '@walkme-admin-center/libs/state-management-users';
import { partnersSlice, addPartners, PartnersState } from '@walkme-admin-center/libs/state-management-partners';
import { useSystems } from '@walkme-admin-center/libs/state-management-systems';
import {
    StyledWMSelect,
    StyledGrid,
    AddPartnerFooterText,
    StyledAddPartnerContentElements,
    StyledCloseDialogImage,
    StyledChipInput,
    DeleteIcon,
    StyledChip,
    StyledAddPartnerTableBody,
    StyledEmailContainer,
    StyledWizardDialogButtons,
    StyledAddPartnerDialog,
} from './styles/styles';
import ChipInput from 'material-ui-chip-input';
import { CreatePartnerDetailsDto, NotificationMessage } from '@walkme-admin-center/libs/types';
import { validateEmail, mailRegExp } from '../../../users-roles/src/lib/utils/mail';
import { mapRoleToRow } from './utils/roles-rows';
import { mapSystemToRow } from './utils/systems-rows';
import { extractMailInitials } from './utils/mail-initials';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export interface AddPartnerProps {
    onCancel?: () => void;
    onFinish: () => void;
    showDialog?: boolean;
    tableStyles?: any;
    titleText?: string;
    hideTitle?: boolean;
}

export const AddPartner = ({ showDialog, onCancel, onFinish, titleText, hideTitle = false, tableStyles = {} }: AddPartnerProps) => {
    const { t, rt } = useTranslation('general');
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState([]);
    const [newEmailsChips, setNewEmailsChips] = useState([]);
    const [chipInputValue, setChipInputValue] = useState('');
    const [selectedPartnerEmail, setSelectedPartnerEmail] = useState<string>(null);
    const [newPartnersData, setNewPartnersData] = useState<CreatePartnerDetailsDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const title = titleText || t('partners-tab.partners-sub-tab.invite-form.title');

    const addPartnerAppData = useSelector((state: { partnersState: PartnersState }) => state.partnersState.addPartners);

    const { rolesAppData } = useRoles();
    const { systemsAppData } = useSystems();
    const { actionsAppData } = useActions();
    const selectRef = React.useRef(null);

    const setNotificationMessage = ({ text, variant, isOpen }: NotificationMessage) => {
        dispatch(partnersSlice.actions.setNotificationMessage({ text, variant, isOpen }));
    };

    const setNewPartnerDetails = (email: string): CreatePartnerDetailsDto => {
        return {
            email: email,
            roleId: null,
            systemIds: [],
        };
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter' && (newEmailsChips.length > 0 || chipInputValue)) {
                addNewPartnersData();
            }
        },
        [newEmailsChips, chipInputValue, systemsAppData]
    );

    const addNewPartnersData = () => {
        if (newEmailsChips.length > 0 || chipInputValue) {
            const isInputExistsAndValid = chipInputValue && onBeforeAdd(chipInputValue);
            const newEmailBeforeAdd = isInputExistsAndValid ? chipInputValue : null;
            const newEmailsChipsToUpdate = newEmailBeforeAdd ? [...new Set([...newEmailsChips, newEmailBeforeAdd])] : newEmailsChips;
            const newUniqueResults: CreatePartnerDetailsDto[] = newEmailsChipsToUpdate.map((newEmailChip) =>
                setNewPartnerDetails(newEmailChip)
            );

            setNewPartnersData([...newPartnersData, ...newUniqueResults]);
            if (isInputExistsAndValid) {
                setChipInputValue('');
            }
            setNewEmailsChips([]);
        }
    };

    const onAddChip = (newChip: string) => {
        setChipInputValue('');
        setNewEmailsChips([...newEmailsChips, newChip]);
    };

    const onDeleteChip = (removedChip: string) => {
        const newChips = newEmailsChips.filter((chip, chipIndex: number) => chip !== removedChip);
        setNewEmailsChips(newChips);
    };

    const onUpdateChipInput = (value: string) => {
        const chip = value && value.trim();
        setChipInputValue(chip);
    };

    const onBeforeAdd = (chip: string): boolean => {
        if (!chip.trim()) return true;
        if (!validateEmail(chip)) {
            setNotificationMessage({ text: 'Invalid email', variant: WMSnackbarVariant.Error, isOpen: true });
            return false;
        }
        return true;
    };

    const onPartnerFieldsChanged = useCallback(
        (partnerEmailIdentifier: string, createPartnerUpdatedDetails: Partial<CreatePartnerDetailsDto>) => {
            const objectIndex = newPartnersData.findIndex((partnerDetails) => partnerDetails.email === partnerEmailIdentifier);
            if (objectIndex === -1) return;
            const updatedNewPartnersData: CreatePartnerDetailsDto[] = Object.assign([], newPartnersData);
            const currentPartnerData: CreatePartnerDetailsDto = Object.assign({}, updatedNewPartnersData[objectIndex]);
            updatedNewPartnersData[objectIndex] = {
                ...currentPartnerData,
                ...createPartnerUpdatedDetails,
            };
            setNewPartnersData(updatedNewPartnersData);
        },
        [newPartnersData]
    );

    const handleRowClick = useCallback(
        (event, newPartnerData: CreatePartnerDetailsDto) => {
            setSelectedPartnerEmail(newPartnerData.email);
        },
        [selectedPartnerEmail]
    );

    const handleOnRemove = (newPartnerData: CreatePartnerDetailsDto) => {
        const newSelected = selectedItems.filter((item) => item !== newPartnerData);
        setSelectedItems(newSelected);
        const newPartnersDataUpdated = newPartnersData.filter((newPartner) => newPartnerData.email !== newPartner.email);
        if (selectedPartnerEmail === newPartnerData.email && newPartnersDataUpdated.length > 0) {
            setSelectedPartnerEmail(newPartnersDataUpdated[0].email);
        }
        if (newPartnersDataUpdated.length === 0) {
            setSelectedPartnerEmail(null);
        }
        setNewPartnersData(newPartnersDataUpdated);
    };

    const onSendInvite = useCallback(async () => {
        const newPartnersWithNoRoles = newPartnersData.filter((newPartnerData) => !newPartnerData.roleId);
        if (newPartnersWithNoRoles.length > 0) {
            setNotificationMessage({
                text: t('partners-tab.partners-sub-tab.invite-form.no-role-error', { email: newPartnersWithNoRoles[0].email }),
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return;
        }

        const newPartnersWithNoSystems = newPartnersData.filter((newPartnerData) => newPartnerData.systemIds.length === 0);
        if (newPartnersWithNoSystems.length > 0) {
            setNotificationMessage({
                text: t('partners-tab.partners-sub-tab.invite-form.no-system-error', { email: newPartnersWithNoSystems[0].email }),
                variant: WMSnackbarVariant.Error,
                isOpen: true,
            });
            return;
        }

        await addPartners(dispatch, newPartnersData);
    }, [newPartnersData]);

    const contentElements = (
        <StyledAddPartnerContentElements>
            <Grid className={'titleGrid'} item xs={12}>
                <Grid container className={'titleContainer'} direction='row' alignItems='flex-start' justifyContent='space-between'>
                    <Grid item>
                        <span hidden={hideTitle} className={'dialogTitle'}>
                            {title}
                        </span>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='flex-end' alignItems='baseline'>
                            <IconButton onClick={() => onCancel()}>
                                <StyledCloseDialogImage src='assets/icons/close.svg' alt='close' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'wizardDialogContent'} item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <StyledGrid>
                            <div className={newPartnersData.length === 0 ? 'keywordChipsDiv' : ''}>
                                <StyledChipInput
                                    id='chip-input'
                                    onPaste={(event) => {
                                        setLoading(true);
                                        event.preventDefault();
                                        let newChips = [];
                                        newChips = event.clipboardData.getData('Text').match(mailRegExp);

                                        if (!newChips) {
                                            setNotificationMessage({
                                                text: `${t('errors.invalid-email')} ${event.clipboardData.getData('Text')}`,
                                                variant: WMSnackbarVariant.Error,
                                                isOpen: true,
                                            });
                                            setLoading(false);
                                            return;
                                        }

                                        if (newChips.length === 1) {
                                            onBeforeAdd(newChips[0]);
                                            onUpdateChipInput(newChips[0]);
                                        } else {
                                            const validChips = [];
                                            const invalidChips = [];
                                            for (const chip of newChips) {
                                                const isValid = onBeforeAdd(chip);
                                                if (isValid) validChips.push(chip);
                                                else invalidChips.push(chip);
                                            }
                                            if (invalidChips.length > 0) {
                                                setNotificationMessage({
                                                    text: `Invalid ${invalidChips.length == 1 ? 'email' : 'emails'}: ${invalidChips.join(
                                                        ', '
                                                    )}`,
                                                    variant: WMSnackbarVariant.Error,
                                                    isOpen: true,
                                                });
                                            }
                                            const uniqueValidChips = [...new Set(validChips)];
                                            setNewEmailsChips([...newEmailsChips, ...uniqueValidChips]);
                                        }
                                        setLoading(false);
                                    }}
                                    inputValue={chipInputValue}
                                    classes={{
                                        root: 'wmKeywordChips',
                                        inputRoot: 'inputRoot',
                                        input: 'input',
                                        chipContainer: 'chipContainer',
                                    }}
                                    fullWidth
                                    placeholder={t('partners-tab.partners-sub-tab.invite-form.email-placeholder')}
                                    value={newEmailsChips}
                                    onAdd={(chip) => onAddChip(chip)}
                                    onDelete={(chip, index) => onDeleteChip(chip)}
                                    onBeforeAdd={onBeforeAdd}
                                    onKeyDown={handleKeyDown}
                                    onUpdateInput={(event) => {
                                        onUpdateChipInput(event.target && event.target.value);
                                    }}
                                    chipRenderer={(args, key) => (
                                        <StyledChip
                                            deleteIcon={
                                                <DeleteIcon>
                                                    <WMIconClose size={9} />
                                                </DeleteIcon>
                                            }
                                            classes={{
                                                root: 'chip',
                                                label: 'chipLabel',
                                            }}
                                            label={args.value}
                                            key={key}
                                            onDelete={args.handleDelete}
                                            disabled={args.isDisabled}
                                        />
                                    )}
                                    disableUnderline
                                    newChipKeys={['SPACE']}
                                    newChipKeyCodes={[32]}
                                    InputProps={{
                                        autoFocus: true,
                                        endAdornment: loading ? (
                                            <WMCircularProgress size={18} />
                                        ) : (
                                            <InputAdornment position='end'>
                                                <WMButton
                                                    variant={WMButtonVariant.Text}
                                                    disabled={newEmailsChips.length === 0 && !chipInputValue}
                                                    style={{ padding: '10px' }}
                                                    onClick={() => addNewPartnersData()}>
                                                    {t('buttons.add')}
                                                </WMButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className={'keywordChipsAddTextDiv'}>
                                    <span
                                        className={'keywordChipsAddText'}
                                        dangerouslySetInnerHTML={{
                                            __html: t('partners-tab.partners-sub-tab.invite-form.description'),
                                        }}></span>
                                </div>
                            </div>
                            {rolesAppData.data.length > 0 && !systemsAppData.loading && newPartnersData.length > 0 && (
                                <TableContainer className={'usersTable'} style={{ ...tableStyles }} component={Paper} elevation={0}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('common.email')}</TableCell>
                                                <TableCell>{t('common.role')}</TableCell>
                                                <TableCell>{t('common.systems')}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <StyledAddPartnerTableBody>
                                            {newPartnersData.map((newPartnerData, index) => (
                                                <TableRow
                                                    key={newPartnerData.email}
                                                    hover
                                                    classes={{ selected: 'tableRowSelected' }}
                                                    onClick={(event) => handleRowClick(event, newPartnerData)}>
                                                    <TableCell width={'25%'}>
                                                        <StyledEmailContainer>
                                                            <div className={'emailDiv'}>
                                                                <div className={'userImage'}>
                                                                    <span className={'userImageText'}>
                                                                        {extractMailInitials(newPartnerData.email)}
                                                                    </span>
                                                                </div>
                                                                <span className={'emailText'}>{newPartnerData.email}</span>
                                                            </div>
                                                        </StyledEmailContainer>
                                                    </TableCell>
                                                    <TableCell width='20%'>
                                                        <div ref={selectRef}>
                                                            <StyledWMSelect
                                                                onChange={(selectedItem) => {
                                                                    onPartnerFieldsChanged(newPartnerData.email, {
                                                                        roleId: selectedItem.value,
                                                                    });
                                                                }}
                                                                options={rolesAppData.data.map((role) =>
                                                                    mapRoleToRow(rt, role, anchorEl, actionsAppData.data)
                                                                )}
                                                                maxMenuHeight={300}
                                                                menuPortalTarget={null}
                                                                isSearchable={false}
                                                                placeholder={t('wm-select.placeholder')}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell width='20%'>
                                                        <StyledWMSelect
                                                            maxMenuHeight={300}
                                                            allowSelectAll
                                                            groupValues
                                                            isMulti
                                                            isPopout
                                                            disablePortal
                                                            itemsName='systems'
                                                            onChange={(selectedItems) => {
                                                                onPartnerFieldsChanged(newPartnerData.email, {
                                                                    systemIds: selectedItems.map((selectedItem) => selectedItem.value),
                                                                });
                                                            }}
                                                            options={systemsAppData.data.map((system) => mapSystemToRow(system))}
                                                            placeholder={t('wm-select.placeholder')}
                                                            allOptionsLabel={t('common.select-all')}
                                                            popoutSelectProps={{
                                                                searchPlaceholder: t('wm-select.search-placeholder'),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell width='5%'>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}>
                                                            <WMButton
                                                                variant={WMButtonVariant.Text}
                                                                isIconButton
                                                                iconComponent={<WMIconRemove color='var(--wmGrayDark)' />}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleOnRemove(newPartnerData);
                                                                }}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </StyledAddPartnerTableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </StyledGrid>
                    </Grid>
                </Grid>
            </Grid>
            <StyledWizardDialogButtons item xs={12}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Grid container direction='row' item style={{ marginLeft: '30px' }} alignItems='center'>
                        <WMIconWarning size={12} color='#2F426C' style={{ marginRight: '5px' }} />
                        <AddPartnerFooterText>{t('partners-tab.partners-sub-tab.invite-form.info')}</AddPartnerFooterText>
                    </Grid>
                    <Grid item container direction='row' spacing={1} justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <WMButton variant={WMButtonVariant.Text} className={'wizardDialogSecondaryButton'} onClick={onCancel}>
                                {t('buttons.cancel')}
                            </WMButton>
                        </Grid>
                        <Grid item>
                            <WMButton
                                onClick={() => onSendInvite()}
                                disabled={newPartnersData.length === 0 || addPartnerAppData.loading}
                                loading={addPartnerAppData.loading}>
                                {t('buttons.send-invitation')}
                            </WMButton>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledWizardDialogButtons>
        </StyledAddPartnerContentElements>
    );

    useEffect(
        () => () => {
            dispatch(usersSlice.actions.createUsersCleanup());
        },
        []
    );

    useEffect(() => {
        if (selectRef.current != null) {
            setAnchorEl(selectRef.current);
        }
    });

    useEffect(() => {
        if (newPartnersData.length > 0 && !addPartnerAppData.loading && addPartnerAppData?.data) {
            setNewPartnersData([]);
            onFinish();
        }
    }, [addPartnerAppData]);

    return (
        <div>
            <StyledAddPartnerDialog
                onClose={() => onCancel()}
                open={showDialog}
                classes={{ paper: 'addPartnerModal' }}
                fullWidth={true}
                maxWidth={'lg'}>
                <Grid container direction='row' alignItems='stretch' justifyContent='flex-start'>
                    {contentElements}
                </Grid>
            </StyledAddPartnerDialog>
        </div>
    );
};

export default AddPartner;
