import React from 'react';
import LoginStep from './login-step';
import styled from 'styled-components';
import CreateAzureBot from './create-azure-bot';
import CreateAzureBot2 from './create-azure-bot2';
import SetTeamsChannel from './set-teams-channel';
import SetEndpoint from './set-endpoint';
import CreateBotSecret from './create-bot-secret';

const ScrollContainer = styled.div`
    display: flex;
    width: 767px;
    padding: 32px 0px;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
`;

const Line = styled.div`
    border-left: 1.5px dashed #6c89f0;
    height: 32px;
`;

const TeamsConfigurationStep = ({ guid }) => {
    return (
        <ScrollContainer>
            <LoginStep />
            <Line />
            <CreateAzureBot />
            <Line />
            <CreateAzureBot2 />
            <Line />
            <SetTeamsChannel />
            <Line />
            <SetEndpoint guid={guid} />
            <Line />
            <CreateBotSecret />
        </ScrollContainer>
    );
};

export default TeamsConfigurationStep;
