import React from 'react';
import Pluralize from 'react-pluralize';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { styledTheme } from '@walkme-admin-center/libs/shared-styles';
import { IconInfo } from "@walkme-admin-center/libs/common";

const GreyText = styled.span`
    color: #78868F;
`;

interface TableFooterSuccessMessageProps {
    text: string;
    numOfApps: number;
};

const TableFooterSuccessMessage = ({ text, numOfApps } : TableFooterSuccessMessageProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            successMessage: {
                display: 'flex',
                flex: 1,
                width: '693px',
                marginTop: '10px'
            },
            successIcon: {
              marginRight: '5px',
              marginTop: '3px'
            }
        })
    );

    const classes = useStyles();

    return (
        <div className={classes.successMessage}>
            <IconInfo className={classes.successIcon} htmlColor={styledTheme.colors.successColor}/>
            <Typography>
                <GreyText>
                    <b>
                        <Pluralize singular="app" count={numOfApps} />
                    </b>
                    &nbsp;{ numOfApps > 1 ? 'were' : 'was' }
                    &nbsp;{text} successfully, this will take effect after publishing to Production via the editor app
                </GreyText>
            </Typography>
        </div>
    );
};

export default TableFooterSuccessMessage;
