import styled from 'styled-components';
import { styledTheme } from '../ui-theme/ui-theme';

const {
    colors: { main },
} = styledTheme;

export const RouteHeader = styled.div`
    font-size: 12px;
    font-weight: 800;
    color: ${main.mainTitle};
`;
