import { IconButton, Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import { Button, InputSearch, Tag, ToasterVariant, Tooltip, Typography } from '@walkme/ui-core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Check } from '@walkme/ui-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { WMAgGridWrapper } from '@walkme/wm-ui';
import { Role, System, Users, UsersBulkMutationResult } from 'wm-accounts-sdk';
import { UsersState, getUsers, usersSlice } from '@walkme-admin-center/libs/state-management-users';
import { useSelector } from 'react-redux';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import { AppData } from '@walkme-admin-center/libs/types';

const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
        height: 100%;
        max-width: none;
        width: 1072px;
        border-radius: 12px;
        &.MuiStepper-root {
            padding: 0;
        }
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    padding: 24px 28px 0px 28px;
`;

const StyledDialogActions = styled(DialogActions)`
    padding: 16px 32px 32px 32px;
    justify-content: space-between;
`;

const MiniHeader = styled.span`
    color: var(--typography-typography-tint-2, #8d97ae);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const DialogHeader = styled.span`
    color: var(--typography-typography, #2f426c);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

const StyledStepper = styled(Stepper)`
    justify-content: center;
    width: 100%;
    &.MuiStepper-root {
        padding: none;
        width: 500px;
    }
    & .MuiStepLabel-label {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #2f426c !important;
    }
`;

const StyledConnector = styled(StepConnector)`
    max-width: 20px;
    & .MuiStepConnector-line {
        border: 1px solid #e6ecf8;
    }
`;

const StyledStepLabel = styled(StepLabel)`
    & .Mui-active {
        color: #385feb;
    }
    & .Mui-disabled {
        & .MuiStepIcon-root {
            color: #e4e9fc;
        }
        & .MuiStepIcon-text {
            fill: #8d97ae;
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

const StyledIconeStep = styled.div`
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8d97ae;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #e4e9fc;
    border-radius: 80px;
`;

const StyledIconeStepActive = styled.div`
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #3b61eb;
    border-radius: 80px;
`;

const StyledIconeStepCompleted = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #e4e9fc;
    border: 1px solid #3b61eb;
    border-radius: 80px;
`;

const StyledSpanNoResults = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    text-align: center;
    /* Texts/t2/walk-me-t2-2-br0 */
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const StyledSpanNoResultsText = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    text-align: center;

    /* Texts/t2/walk-me-t2-br0 */
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;
const StyledSpanNumberSelected = styled.span`
    color: var(--blue-primary-primary-main, #385feb);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
`;
const StyledSpanSelected = styled.span`
    color: var(--typography-typography-main-primary-main, #2f426c);
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
`;

const StyledCheck = styled(Check)`
    color: #385feb;
`;

const StyledStepOne = styled.div`
    height: 100%;

    .header {
        padding: 16px 0px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .search-input {
        display: flex;
        gap: 16px;
        width: 325px;
    }

    .ag-theme-wm-ui {
        height: calc(100% - 80px) !important;
    }
`;

export interface SystemNewUsersProps {
    isDrawerOpen: boolean;
    onClose: () => void;
    selectedSystemId?: number;
    RemoveUsersFromSystem?: (detailedUSersToRemove) => void;
    AddUsersToSystem?: (detailedUSersToAdd, allUsers) => void;
    users?: Users;
    selectedSystemName?: string;
    loading?: boolean;
}

export interface IStepProps {
    stepTitle: JSX.Element | string;
    content: React.ReactNode;
    shouldSkip?: () => boolean;
}

export interface ManageUsersRow {
    status: boolean;
    id: number;
    email: string;
    role: Role;
    name: string;
}

export function SystemNewUsers(props: SystemNewUsersProps) {
    const { t, rt } = useTranslation('general');
    const [selectedUsers, setSelectedUsers] = useState<ManageUsersRow[]>([]);

    const [activeStep, setActiveStep] = useState(0);
    const [savedClicked, setSavedClicked] = useState(false);
    const [query, setQuery] = useState('');
    const gridApiRef = useRef(null);

    const updatedSystemsAppData: AppData<UsersBulkMutationResult> = useSelector(
        (state: { usersState: UsersState }) => state.usersState.updateUsers
    );

    const CustomStepIcon = (props) => {
        const { icon, active, completed } = props;
        if (completed)
            return (
                <StyledIconeStepCompleted>
                    <StyledCheck />
                </StyledIconeStepCompleted>
            );
        if (active) return <StyledIconeStepActive>{icon}</StyledIconeStepActive>;
        return <StyledIconeStep>{icon}</StyledIconeStep>;
    };

    const stepper = useCallback((steps: IStepProps[], activeStep: number) => {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StyledStepper activeStep={activeStep} connector={<StyledConnector />}>
                        {steps.map((step, index) => {
                            const stepProps = {};
                            return (
                                <Step key={index} {...stepProps}>
                                    <StyledStepLabel StepIconComponent={CustomStepIcon}>{step.stepTitle}</StyledStepLabel>
                                </Step>
                            );
                        })}
                    </StyledStepper>
                </div>
            </div>
        );
    }, []);

    const onSelectionChanged = (selectionEvent: any) => {
        const newSelectedRows = selectionEvent.api.getSelectedRows();
        setSelectedUsers(newSelectedRows);
    };

    useEffect(() => {
        if (gridApiRef.current && selectedUsers.length > 0) {
            const gridApi = gridApiRef.current.api;

            const nodeIdsToSelect = selectedUsers.map((selectedUser) => selectedUser.id);

            gridApi.forEachNode((node) => {
                const isSelected = nodeIdsToSelect.includes(node.data.id);
                node.setSelected(isSelected);
            });
        }
    }, [selectedUsers, activeStep]);

    const generateRowData = useCallback((): ManageUsersRow[] => {
        const users = props.users;
        const systemUsers = props.users.filter((user) => user.systems.find((system) => system.id === props.selectedSystemId));
        return users.map((user) => {
            return {
                status: systemUsers.includes(user),
                id: user.id,
                email: user.email,
                role: user.role,
                name: user.firstName + ' ' + user.lastName,
            };
        });
    }, [props]);

    useEffect(() => {
        if (updatedSystemsAppData.data?.users.length > 0) {
            handleClose();
        }
    }, [updatedSystemsAppData.data]);

    const updateUsers = useCallback(
        (rows: ManageUsersRow[]) => {
            props.AddUsersToSystem(rows, props.users);
        },
        [props]
    );

    const filteredUsersNumber = useCallback(() => {
        return props.users.filter((user) => {
            const fullName = user.firstName + ' ' + user.lastName;
            return user.email.toLowerCase().includes(query.toLowerCase()) || fullName.toLowerCase().includes(query.toLowerCase());
        })?.length;
    }, [query, props.users]);

    useEffect(() => {
        if (savedClicked) {
            updateUsers(selectedUsers);
        }
    }, [savedClicked]);

    const stepOne = useCallback(() => {
        return (
            <StyledStepOne key='stepOne'>
                <div className={'header'}>
                    <Typography variant='H40'>{t('systems-tab.all-systems-page.assign-users-form.step-1-title')}</Typography>
                    <div className={'search-input'}>
                        <InputSearch
                            value={query}
                            placeholder={t('systems-tab.all-systems-page.assign-users-form.search-placeholder')}
                            onChange={(query) => setQuery(query)}
                        />
                    </div>
                </div>
                {filteredUsersNumber() && filteredUsersNumber() > 0 ? (
                    <WMAgGridWrapper
                        quickFilterText={query}
                        rowData={generateRowData()}
                        rowSelection='multiple'
                        suppressCellFocus
                        suppressRowClickSelection
                        noRowsOverlayComponent={<div>Test</div>}
                        gridOptions={{
                            onSelectionChanged: onSelectionChanged,
                            isRowSelectable: (params) => {
                                return params.data && !params.data.status;
                            },
                            onGridReady: (params) => (gridApiRef.current = params),
                        }}
                        defaultColDef={{
                            flex: 1,
                            headerClass: 'header-black',
                            cellClass: 'header-black',
                        }}
                        columnDefs={[
                            {
                                field: 'name',
                                headerName: t('common.name'),
                                headerCheckboxSelection: true,
                                checkboxSelection: true,
                                showDisabledCheckboxes: true,
                                sortable: true,
                                cellStyle: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'var(--typography-typography-main-primary-main, #2F426C)',
                                    fontFamily: 'Proxima Nova',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            },
                            {
                                field: 'email',
                                headerName: t('common.email'),
                                cellStyle: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'var(--typography-typography, #2F426C)',
                                },
                                cellRenderer: function (params) {
                                    const value = params.value;
                                    return value.length > 35 ? <Tooltip title={value}>{value}</Tooltip> : value;
                                },
                            },
                            {
                                field: 'role',
                                headerName: t('common.role'),
                                cellStyle: {
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                                cellRenderer: function (params) {
                                    return params.value && <Tag label={rt(params.value.displayName)} />;
                                },
                            },
                            {
                                field: 'status',
                                headerName: t('common.status'),
                                sortable: true,
                                cellRenderer: function (params) {
                                    return params.value && <Tag label={t('common.assigned')} />;
                                },
                                cellStyle: {
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            },
                        ]}
                    />
                ) : (
                    <div
                        style={{
                            height: 'calc(100% - 60px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                        }}>
                        <img alt={'more'} src={`/assets/No results.svg`} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <StyledSpanNoResults>{t('common.no-results')}</StyledSpanNoResults>
                            <StyledSpanNoResultsText>{t('common.no-results-text')}</StyledSpanNoResultsText>
                        </div>
                    </div>
                )}
            </StyledStepOne>
        );
    }, [generateRowData, query]);

    const stepTwo = useCallback(() => {
        return (
            <div style={{ height: '100%' }} key='stepTwo'>
                <div
                    style={{
                        padding: '16px 0px 12px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Typography variant='H40'>{t('buttons.assign-users')}</Typography>
                </div>
                <WMAgGridWrapper
                    rowData={selectedUsers}
                    suppressCellFocus
                    suppressRowClickSelection
                    defaultColDef={{
                        flex: 1,
                        headerClass: 'header-black',
                        cellClass: 'header-black',
                    }}
                    columnDefs={[
                        {
                            field: 'name',
                            headerName: t('common.name'),
                            cellStyle: {
                                display: 'flex',
                                alignItems: 'center',
                                color: 'var(--typography-typography-main-primary-main, #2F426C)',
                                fontFamily: 'Proxima Nova',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '16px',
                            },
                        },
                        {
                            field: 'email',
                            headerName: t('common.email'),
                            cellStyle: {
                                display: 'flex',
                                alignItems: 'center',
                                color: 'var(--typography-typography, #2F426C)',
                            },
                        },
                        {
                            field: 'role',
                            headerName: t('common.role'),
                            cellStyle: {
                                display: 'flex',
                                alignItems: 'center',
                            },
                            cellRenderer: function (params) {
                                return params.value && <Tag label={t(params.value.displayName)} />;
                            },
                        },
                    ]}
                />
            </div>
        );
    }, [selectedUsers]);

    const steps: IStepProps[] = [
        {
            stepTitle: t('systems-tab.all-systems-page.assign-users-form.step-1-title'),
            content: stepOne(),
        },
        {
            stepTitle: t('systems-tab.all-systems-page.assign-users-form.step-2-title'),
            content: stepTwo(),
        },
    ];
    const clickedBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handleClose = () => {
        setQuery('');
        setSelectedUsers([]);
        setActiveStep(0);
        props.onClose();
    };

    return (
        <StyledDialog open={props.isDrawerOpen} onClose={() => props.onClose()}>
            <StyledDialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <DialogHeader>
                            {t('systems-tab.all-systems-page.assign-users-form.new-title')} {props.selectedSystemName}
                        </DialogHeader>
                        <MiniHeader>{t('systems-tab.all-systems-page.assign-users-form.sub-title')}</MiniHeader>
                    </div>
                    {stepper(steps, activeStep)}
                    <IconButton onClick={handleClose}>
                        <img src='assets/icons/close.svg' alt='close' style={{ width: 9 }} />
                    </IconButton>
                </div>
            </StyledDialogTitle>
            <DialogContent>{steps && steps[activeStep] && steps[activeStep].content}</DialogContent>
            <StyledDialogActions>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}>
                    {selectedUsers.length > 0 && <StyledSpanNumberSelected>{selectedUsers.length}</StyledSpanNumberSelected>}
                    {selectedUsers.length > 0 && (
                        <StyledSpanSelected>
                            {selectedUsers.length > 1
                                ? t('systems-tab.all-systems-page.assign-users-form.users-selected')
                                : t('systems-tab.all-systems-page.assign-users-form.user-selected')}
                        </StyledSpanSelected>
                    )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <Button onClick={() => (activeStep === 0 ? handleClose() : clickedBack())} variant='text' style={{ color: '#637191' }}>
                        {activeStep === 0 ? t('buttons.cancel') : t('buttons.back')}
                    </Button>
                    <Button
                        onClick={() => (activeStep === 0 ? setActiveStep(activeStep + 1) : setSavedClicked(true))}
                        variant='solid'
                        loading={props.loading}
                        disabled={selectedUsers.length === 0 || props.loading}>
                        {activeStep === 0 ? t('buttons.next') : t('buttons.assign')}
                    </Button>
                </div>
            </StyledDialogActions>
        </StyledDialog>
    );
}

export default SystemNewUsers;
