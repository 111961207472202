import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        introduction: {
            fontfamily: 'Proxima Nova',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#637191',
            marginBotton: '0px',
        },

        mainHeader: {
            color: 'var(--typography-typography-main-primary-main,#2f426c)',
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '28px',
            font: '400 14px ProximaNova',
        },

        subTitle: {
            color: 'var(--typography-typography-tint-2, #8d97ae)',
            fontFamily: 'Proxima Nova',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },

        downloadInfo: {
            fontFamily: 'Proxima Nova',
            marginTop: '40px',
            fontSize: '14px',
            color: '#637191',
        },

        downloadHeader: {
            marginButtom: '12px',
            fontWeight: 'bold',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            height: '20px',
            color: '#2F426C',
        },

        isDownloadedButton: {
            margin: '-7px 5px',
        },

        buttonWrapper: {
            margin: '15px 0px',
        },

        fileButton: {
            width: '225px',
        },

        sortText: {
            textOverflow: 'ellipsis',
            width: '230px',
            wordWrap: 'break-word',
        },

        uploadBox: {
            border: '1px dashed #B1C0F7',
            borderRadius: '8px',
            background: '#F5F8FC',
            color: '#2F426C',
            padding: '25px 20px',
            fontFamily: 'Proxima Nova',
            display: 'flex',
            justifyContent: 'space-around',
        },

        reportIssueButton: {
            margin: '5px 0px',
            position: 'absolute',
            right: '35px',
            color: '#6d7a98 !important',
            fontSize: 'smaller !important',
        },

        uploadHistory: {
            marginTop: '85px',
        },

        footer: {
            right: '20px',
            bottom: '20px',
            position: 'fixed',
            borderRadius: '20px !important',
            height: '40px !important',
            marginLeft: '12px',
            width: '90px',
            fontFamily: 'ProximaNova',
            boxShadow: 'none',
        },

        newFooter: {
            width: '90px',
            height: '40px !important',
            float: 'right',
            boxShadow: 'none',
            fontFamily: 'ProximaNova',
            marginLeft: '12px',
            borderRadius: '20px !important',
            margin: '20px 0px',
        },

        uploadModalContent: {
            margin: '10px 32px',
        },

        footerModal: {
            textAlign: 'end',
            marginTop: '20px',
        },

        uploadModalCloseBtn: {
            margin: '0px 20px',
        },

        duplicatesModal: {
            maxWidth: '1000px !important',
            width: '1000px !important',
        },

        duplicationTd: {
            display: 'table-cell',
            padding: '8px 16px',
            fontSize: '0.875rem',
            textAlign: 'left',
            fontFamily: 'ProximaNova',
            lineHeight: '1.43',
            verticalAlign: 'inherit',
        },

        actionsWarpper: {
            alignItems: 'center',
        },

        selected: {
            borderRadius: '4px',
            background: '#EDF1FD',
            border: '1px solid #385FEB',
            width: '85px',
            height: '28px',
            color: '#385FEB',
            alignItems: 'center',
            padding: '0px',
            gap: '8px',
            fontFamily: 'Proxima Nova',
            fontStyle: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            cursor: 'pointer',
        },

        unselected: {
            width: '85px',
            fontFamily: 'Proxima Nova',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            background: 'white',
            border: '1px solid #8D97AE',
            height: '28px',
            borderRadius: '4px',
            color: '#8D97AE',
            cursor: 'pointer',
        },

        approveIcon: {
            position: 'relative',
            top: '2px',
            right: '3px',
        },

        skipIcon: {
            position: 'relative',
            right: '4px',
        },

        tag: {
            position: 'relative',
            right: '8px',
            width: 'fit-content !important',
            height: '20px',
            top: '-3px;',
            borderRadius: '4px',
            padding: '7px 9px',
        },

        phrase: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100px',
            height: '1.2em',
            whiteSpace: 'nowrap',
        },

        translation: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100px',
            height: '1.2em',
            whiteSpace: 'nowrap',
            display: 'inline-block',
        },

        translationArrow: {
            transform: 'rotate(180deg)',
            position: 'relative',
            right: '25px',
            top: '3px',
            color: '#AFBACE',
            fontSize: '12px',
        },

        issue: {
            width: '130px',
        },

        summaryContatier: {
            display: 'flex',
            flexDirection: 'row',
            margin: '30px 0px',
            justifyContent: 'space-between',
        },

        summaryItem: {
            display: 'inline',
            color: '#637191',
        },

        summaryTitle: {
            fontSize: '25px',
            fontfamily: 'Poppins',
            color: '#2F426C',
            fontWeight: 'bold',
        },

        duplicateRow: {
            background: '#FEF5F5',
            boxSizing: 'border-box',
        },

        lastTd: {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            display: 'table-cell',
            padding: '8px 16px',
            fontSize: '0.875rem',
            textAlign: 'left',
            fontFamily: 'ProximaNova',
            lineHeight: '1.43',
            verticalAlign: 'inherit',
        },

        verifiedTranslation: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100px',
            height: '1.2em',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            fontStyle: 'italic',
        },
    })
);

export { useStyles };
