import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@walkme/ui-core';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'apps/home/src/localization';
import URLMatchPattern from './url-match-pattern';
import { URLMatchData } from '../url-verifier/values';

const UrlsTitle = styled('span')`
    color: var(--typography-typography, #2f426c);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-right: 10px;
`;

const MatchPatternsContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const MatchPatternsItemsContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const UrlTopBarControlContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
`;

const UrlCounterContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

const StyledChip = styled(Chip)`
    background: var(--Infographic-Blue-Blue-tint-4, #eceffa);
    color: var(--infographic-blue-blue-shade-1, #162a69);
`;

interface URLMatchPatternsProps {
    urls: string[];
    setAreUrlsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setUrls: (newUrls: string[]) => void;
    shouldShowNewLine: boolean;
    onAddUrlClicked: () => void;
    onAddNewUrlClosed: () => void;
    onAddNewUrlSaved: () => void;
    urlVerifierMatchData?: URLMatchData[];
}

const URLMatchPatterns = ({
    urls,
    setAreUrlsValid,
    setUrls,
    shouldShowNewLine,
    onAddUrlClicked,
    onAddNewUrlClosed,
    onAddNewUrlSaved,
    urlVerifierMatchData,
}: URLMatchPatternsProps) => {
    const { t } = useTranslation('general');
    const [addUrlClicked, setAddUrlClicked] = useState(false);
    const urlMatchPattensValidityArray = useRef<boolean[]>(Array(urls.length).fill(true));

    const onAddUrlClick = () => {
        setAddUrlClicked(true);
        onAddUrlClicked();
    };

    const getUrlsTopBarControl = () => {
        const shouldShowAddUrlButton = !shouldShowNewLine;

        return (
            <UrlTopBarControlContainer>
                <UrlCounterContainer>
                    <UrlsTitle>{t('common.urls')}</UrlsTitle>
                    <StyledChip size='small' label={urls.length.toString()} />
                </UrlCounterContainer>
                {shouldShowAddUrlButton ? (
                    <Button variant='text' onClick={onAddUrlClick} disabled={addUrlClicked}>
                        {t('buttons.add-url')}
                    </Button>
                ) : null}
            </UrlTopBarControlContainer>
        );
    };

    const handleDelete = (index: number) => {
        const newUrls: string[] = [...urls];
        newUrls.splice(index, 1);
        setUrls(newUrls);

        const wasUrlValid = urlMatchPattensValidityArray.current[index];
        const newUrlValidityArray = [...urlMatchPattensValidityArray.current];
        newUrlValidityArray.splice(index, 1);

        if (!wasUrlValid) {
            const areAllUrlsValid = newUrlValidityArray.every((isValid) => isValid);
            if (areAllUrlsValid) {
                setAreUrlsValid(true);
            }
        }

        urlMatchPattensValidityArray.current = newUrlValidityArray;
    };

    const handleSave = (value: string, index?: number): void => {
        const newUrls: string[] = [...urls];
        if (index !== undefined) {
            newUrls[index] = value;
        } else {
            newUrls.push(value);
            setAddUrlClicked(false);
            onAddNewUrlSaved();
        }

        setUrls(newUrls);
    };

    const handleUrlValidityChange = (isValid: boolean, index?: number): void => {
        const areAllOtherUrlsValid = urlMatchPattensValidityArray.current.every(
            (isValid, urlMatchPattensValidityIndex) => isValid || index === urlMatchPattensValidityIndex
        );

        if (areAllOtherUrlsValid) {
            setAreUrlsValid(isValid);
        }

        if (index !== undefined) {
            const newUrlValidityArray = [...urlMatchPattensValidityArray.current];
            newUrlValidityArray[index] = isValid;
            urlMatchPattensValidityArray.current = newUrlValidityArray;
        }
    };

    const onAddNewUrlClose = () => {
        setAddUrlClicked(false);
        onAddNewUrlClosed();
    };

    const newUrlMatchPatternControl = (
        <URLMatchPattern
            value=''
            handleSave={handleSave}
            //TODO: enable close any url, not just new one (except for default new line that's shown for top url if specific method selected)
            handleClose={!shouldShowNewLine ? onAddNewUrlClose : null}
        />
    );

    const urlMatchPatternsControl = (
        <MatchPatternsItemsContainerDiv>
            {urls.map((url: string, index: number) => {
                const isMatchingURLVerifier = urlVerifierMatchData?.find((matchData) => matchData.selfDomainOrRegex === url)?.isMatching;

                return (
                    <URLMatchPattern
                        key={`${url}_${index}`}
                        index={index}
                        value={url}
                        handleDelete={urls.length > 1 ? handleDelete : null}
                        handleSave={handleSave}
                        setAreUrlsValid={handleUrlValidityChange}
                        isMatchingURLVerifier={isMatchingURLVerifier}
                    />
                );
            })}
        </MatchPatternsItemsContainerDiv>
    );

    return (
        <MatchPatternsContainerDiv>
            {getUrlsTopBarControl()}
            {addUrlClicked || shouldShowNewLine ? newUrlMatchPatternControl : null}
            {urlMatchPatternsControl}
        </MatchPatternsContainerDiv>
    );
};

export default URLMatchPatterns;
