import styled from 'styled-components';

export const StyledConfigureContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(20vw, 0.23308270676fr) 1fr;
    height: calc(100% - 80px);
    overflow: auto;
`;

export const ConfigureContentWrapper = styled.div`
    background: var(--Primaries-Background-Background-tint-1, #f0f3f9);
    overflow: hidden !important;
`;
