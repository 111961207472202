import React from 'react';
import { BoldText, SmallBoldText, StepContainer, SubtitleText, TitleContainer, TitleText, VideoStepNumber } from './slack-steps-styles';
import { retriveYourAppsVideo } from '@walkme-admin-center/libs/common';
import VideoContainer from './video-container';

const YourAppsStep = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>2.</VideoStepNumber>Click the <BoldText>Your Apps</BoldText> buttons
                </TitleText>
                <SubtitleText>
                    Then click the <SmallBoldText>Manage your apps</SmallBoldText> button
                </SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveYourAppsVideo()} />
        </StepContainer>
    );
};

export default YourAppsStep;
