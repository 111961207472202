import styled from 'styled-components';
import { Button, Toaster } from '@walkme/ui-core';

export const StyledExtensionContainer = styled.div`
    border-bottom: 1px solid #f1f3f9;
    padding-bottom: 24px;

    .main-title {
        font-weight: 600;
        margin-bottom: 8px;
        color: #30426e;
    }

    .sub-title {
        color: var(--Color-Local-Semantic-Text-secondary, #637191);
        margin-bottom: 8px;
    }
`;

export const StyledDataSettingsAndAccessibilityContainer = styled.div`
    .description {
        color: var(--Color-Local-Semantic-Text-secondary, #637191);
        margin-bottom: 16px;
    }
`;

export const StyledPublishTitle = styled.div`
    color: var(--Color-Local-Semantic-Text-secondary, #637191);
    margin-bottom: 16px;
`;

export const StyledSaveSections = styled.div`
    margin-bottom: 12px;

    .data-collection-accessibility {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`;
export const StyledSaveSection = styled.div`
    margin-bottom: 8px;

    .description-content {
        display: flex;
        gap: 8px;

        .check {
            margin-top: 2px;
        }

        .text {
            .main-title {
                font-weight: 600;
                margin-bottom: 4px;
                color: #30426e;
            }

            .sub-title {
                color: var(--Color-Local-Semantic-Text-secondary, #637191);
            }
        }
    }
`;

export const StyledPrimaryButton = styled(Button)`
    margin-left: 20px !important;
`;

export const StyledSelectEnvironment = styled.div`
    .main-title {
        font-weight: 600;
        margin-bottom: 8px;
        color: #30426e;
    }
`;

export const StyledToasterContainer = styled(Toaster)`
    width: 90%;
    box-shadow: none;

    &.uuid {
        margin-top: 8px;
    }
`;
