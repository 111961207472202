import styled, { css } from 'styled-components';
import { LoadingSpinnerIcon } from './icons/icons';

export const CssIntegrationCard = styled.div<{ isExecuting: boolean; selected?: boolean; warning?: boolean }>`
    background: ${(p) => (p.isExecuting ? `var(--Primaries-Sky-4, #F9FBFD)` : `var(--LS-Color-Text-On-dark-primary-inverted, #FFF)`)};
    border-radius: 0.5rem;
    ${(p) =>
        p.warning
            ? css`
                  border: 1px solid var(--Color-Notifications-alerts-stroke-warning, #f7b500);
              `
            : css`
                  border: 1px solid var(--ls-color-input-dropdown-stroke-primary-default, #e3e9fc);
              `}
    display: flex;
    flex-direction: column;
    ${(p) =>
        p.selected
            ? css`
                  box-shadow: 0px 4px 12px 0px rgba(47, 66, 108, 0.11);
              `
            : ''}
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 4px 12px 0px rgba(47, 66, 108, 0.11);
    }
`;

export const CssIntegrationCardTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 12px;
`;

export const CssIntegrationCardBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 0.75rem;
`;

export const TopRowContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const SystemContainer = styled.div`
    position: relative;
    height: inherit;
    width: 3.75rem;
`;

export const IntegrationName = styled.span`
    height: 32px;
    font-size: 20px;
    color: #2f426c;
    font-weight: 600;
    line-height: 28px;
    font-family: Poppins;
`;

export const LastExecute = styled.span`
    color: var(--Typography-Typography-Main, #2f426c);
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const FooterContainer = styled.div`
    margin-top: 16px;
`;

export const ExecuteContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 20px 0px 16px;
`;

export const DataContainer = styled.div`
    height: 1.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px 16px;
`;

export const SystemAvatarContainer = styled.div<{ index: number }>`
    position: absolute;
    top: 0;
    left: ${(p) => (p.index === 0 ? '0px' : `${17 * p.index}px`)};

    & > span {
        display: block !important;
    }
`;

export const SystemAvatar = styled.div`
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--LS-Color-Text-primary-inverted, #fff);
    box-shadow: inset 0 0 0 0.5px #d7dffb;
    border-radius: 6.25rem;
    position: relative;
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    gap: 0.25rem;
`;

export const StatusIcon = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 4px;
    &.pending {
        background: #f7b500;
    }
    &.ready {
        background: #41b7a6;
    }
    &.failed {
        background: #f44336;
    }
`;

export const StatusFont = styled.span`
    font-family: 'Proxima Nova';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.5rem; /* 66.667% */
`;

export const StatusText = styled(StatusFont)`
    color: var(--LS-Color-Tag-content-primary, #2f426c);
    text-transform: capitalize;
    font-weight: 400;
`;

export const StatusDismiss = styled(StatusFont)`
    color: var(--LS-Color-Text-On-light-secondary, #637191);
`;

export const LightText = styled.span`
    font-size: 12px;
    color: #8d97ae;
    flex: 1 0 0;
`;

export const SpinnerLoader = styled(LoadingSpinnerIcon)({
    animation: 'spin 1s linear infinite', // 1 second per rotation, linear speed, repeat forever
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});
