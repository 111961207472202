import { WMhttpClient, wmhttpClientSharedInstance } from '@walkme-admin-center/libs/http-service';
import { SystemSelfHosted } from '../types/self-hosted.type';
import get from 'lodash/get';
import { DownloadPackageDto } from '../types/download-package.dto';
import FileSaver from 'file-saver';
import { SelfHostedSettingsResponseDto } from '../types/self-hosted-settings-response.dto';
import { SaveEnvironmentSettingsDto } from '../types/save-environment-settings.dto';
import { GetExposureSettingsDto } from '../types/get-exposure-settings.dto';
import { ExposureSettingsResponseDto } from '../types/exposure-settings-response.dto';
import { SaveExtensionPathDto } from '../types/save-extension-path.dto';

const FILENAME_EXTRACT_REGEX = /filename=((['"]).*?\2|[^;\n]*)/;
const DEFAULT_SELFHOSTED_FILENAME = 'selfhosted.zip';

interface AnalyticsErrorResponse {
    error: string;
}

class SelfHostedApi {
    constructor(private httpClient: WMhttpClient) {}

    async getAreSystemSelfHosted(): Promise<SystemSelfHosted[]> {
        try {
            const response = await this.httpClient.instance.post<SystemSelfHosted[]>(`/analytics/SelfHosted/AreSystemsSelfHosted`, {});
            const error = get(response, 'data.error');

            if (error) {
                return Promise.reject('Failed to get systems self-hosted data');
            }
            return response.data;
        } catch (e) {
            return [];
        }
    }

    async downloadPackage(payload: DownloadPackageDto): Promise<void> {
        const { data, headers } = await this.httpClient.instance.post('/publish-self-hosted/download', payload, {
            responseType: 'arraybuffer',
        });

        const contentDisposition = headers['content-disposition'];
        const fileName = SelfHostedApi.extractFileNameFromHeader(contentDisposition, DEFAULT_SELFHOSTED_FILENAME);

        const fileBlob = new Blob([data], { type: 'application/zip' });
        FileSaver.saveAs(fileBlob, fileName);
    }

    async getSettings(systemId: number): Promise<SelfHostedSettingsResponseDto> {
        const { data } = await this.httpClient.instance.post<SelfHostedSettingsResponseDto | AnalyticsErrorResponse>(
            '/analytics/SelfHosted/GetSelfHostedData',
            {
                systemId,
            }
        );

        if ('error' in data) {
            throw new Error(data.error);
        }

        return data;
    }

    async getExposureSettings(payload: GetExposureSettingsDto): Promise<ExposureSettingsResponseDto> {
        const { data } = await this.httpClient.instance.post<ExposureSettingsResponseDto>(
            '/extensions/webSystems/getSystemMatcherSelfHostedPath',
            payload
        );

        return data;
    }

    async saveEnvironmentSettings(payload: SaveEnvironmentSettingsDto): Promise<void> {
        const { data } = await this.httpClient.instance.post<{ Result: boolean } | AnalyticsErrorResponse>(
            '/analytics/SelfHosted/SaveDeploymentUrlAndMethod',
            payload
        );

        if ('error' in data) {
            throw new Error(data.error);
        }
    }

    async saveExtensionPath(payload: SaveExtensionPathDto): Promise<void> {
        await this.httpClient.instance.post('/extensions/webSystems/setSystemMatcherSelfHostedPath', payload);
    }

    private static extractFileNameFromHeader(header: string, defaultName: string): string {
        let fileName = defaultName;

        if (header && header.startsWith('attachment')) {
            const matches = header.match(FILENAME_EXTRACT_REGEX);

            if (matches && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
        }

        return fileName;
    }
}

export const selfHostedApi = new SelfHostedApi(wmhttpClientSharedInstance);
