import { combineReducers } from '@reduxjs/toolkit';
import {
    systemsSlice,
    uuidSlice,
    createSystemSlice,
    systemAppsConfigsSlice,
    impersonationSlice,
} from '@walkme-admin-center/libs/state-management-systems';
import { usersSlice, accountSlice, accountIdpsSlice } from '@walkme-admin-center/libs/state-management-users';
import { partnersSlice } from '@walkme-admin-center/libs/state-management-partners';
import { ssoConfigurationSlice } from '@walkme-admin-center/libs/state-management-sso-configuration';
import { dataIntegrationsSlice } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { aiHubDashboardSlice } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { aiFilterSlice } from '@walkme-admin-center/libs/state-mangment-data-integration';
import { tableSlice } from '@walkme-admin-center/libs/ui-components';
import { auditLogsSlice } from '@walkme-admin-center/libs/state-management-auditlog';
import { desktopTrackedAppsSlice } from 'packages/libs/state-management-desktop-tracked-apps/src/lib/redux/desktop-tracked-apps.slice';
import { desktopPredefinedAppsSlice, desktopSystemsExistsSlice } from '@walkme-admin-center/libs/state-management-desktop-tracked-apps';
import { extensionsSlice, webSystemsSlice } from '@walkme-admin-center/libs/state-management-extensions';
import { applicationsSlice } from '@walkme-admin-center/libs/state-management-applications';
import { deployablesSlice } from '@walkme-admin-center/libs/state-management-deployables';
import { selfHostedSlice } from '@walkme-admin-center/self-hosting';

export const rootReducer = combineReducers({
    tableState: tableSlice.reducer,
    systemsState: systemsSlice.reducer,
    uuidState: uuidSlice.reducer,
    createSystemState: createSystemSlice.reducer,
    usersState: usersSlice.reducer,
    partnersState: partnersSlice.reducer,
    accountState: accountSlice.reducer,
    accountIdpsState: accountIdpsSlice.reducer,
    desktopPredefinedAppsState: desktopPredefinedAppsSlice.reducer,
    desktopTrackedAppsState: desktopTrackedAppsSlice.reducer,
    desktopSystemsExistsState: desktopSystemsExistsSlice.reducer,
    auditLogState: auditLogsSlice.reducer,
    ssoConfigurationState: ssoConfigurationSlice.reducer,
    dataIntegrationsState: dataIntegrationsSlice.reducer,
    aiHubDashboardState: aiHubDashboardSlice.reducer,
    aiFilterState: aiFilterSlice.reducer,
    extensionState: extensionsSlice.reducer,
    applicationsSlice: applicationsSlice.reducer,
    webSystemsState: webSystemsSlice.reducer,
    deployablesState: deployablesSlice.reducer,
    selfHostedState: selfHostedSlice.reducer,
    systemAppsConfigsState: systemAppsConfigsSlice.reducer,
    impersonationState: impersonationSlice.reducer,
});

export default rootReducer;
