import { SystemMatcher, PartialSystemData } from 'packages/libs/exposure-api/src/lib/new-web-systems-api/types';

const ALL_URLS_MATCH_PATTERN: string[] = ['http://*/*', 'https://*/*'];

const setEmptyTopUrlsWithDefaultForMatchPatternModeMatchers = (systemData: PartialSystemData): void => {
    Object.values(systemData.matchers).forEach((envMatchers: SystemMatcher[]) => {
        envMatchers.forEach((systemMatcher: SystemMatcher) => {
            if (!systemMatcher.isRegex && systemMatcher.topDomains.length === 0) {
                systemMatcher.topDomains = ALL_URLS_MATCH_PATTERN;
            }
        });
    });
};

export { setEmptyTopUrlsWithDefaultForMatchPatternModeMatchers };
