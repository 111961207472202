import React from 'react';
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

export const IconCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon data-testid='IconCheck' {...props}>
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon-check-circle" transform="translate(-1127.000000, -314.000000)">
          <g transform="translate(1127.500000, 314.000000)">
            <path d="M12,2.25 C17.3847763,2.25 21.75,6.61522369 21.75,12 C21.75,17.3847763 17.3847763,21.75 12,21.75 C6.61522369,21.75 2.25,17.3847763 2.25,12 C2.25,6.61522369 6.61522369,2.25 12,2.25 Z M12,3.75 C7.44365081,3.75 3.75,7.44365081 3.75,12 C3.75,16.5563492 7.44365081,20.25 12,20.25 C16.5563492,20.25 20.25,16.5563492 20.25,12 C20.25,7.44365081 16.5563492,3.75 12,3.75 Z M16.4609504,8.78246283 C16.7622153,9.00837261 16.845137,9.41741851 16.6710216,9.73890272 L16.6110372,9.83245043 L12.2529542,15.6442268 C11.9263847,16.0785553 11.4275717,16.3499903 10.885521,16.3883316 C10.4036982,16.4224128 9.92977357,16.2693461 9.56018607,15.9649867 L9.42666991,15.8438301 L7.17666991,13.5938301 C6.8837767,13.3009369 6.8837767,12.8260631 7.17666991,12.5331699 C7.44293648,12.2669034 7.85960016,12.2426973 8.15321165,12.4605518 L8.23733009,12.5331699 L10.4870398,14.7828798 C10.5641767,14.8599323 10.6709279,14.8997628 10.7796847,14.89207 C10.8612522,14.8863005 10.93794,14.854224 10.9985824,14.8021102 L11.0534628,14.7435496 L15.4109628,8.93254957 C15.6594636,8.60115819 16.1295591,8.53396207 16.4609504,8.78246283 Z"
                  id="Combined-Shape"
                  fill={props.htmlColor || '#44BC67'}
                  fillRule="nonzero" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
