import { useQuery } from 'react-query';
import { deployablesApi } from '@walkme-admin-center/libs/deployables-api';
import { ITags } from 'packages/libs/state-mangment-data-integration/src/lib/types';
import { useLoggedInUser } from '@walkme-admin-center/libs/state-management-users';

const useGetTags = (systemId: number) => {
    const {
        loggedInUserAppData: {
            data: { id: userId, impersonator },
        },
    } = useLoggedInUser();
    return useQuery<ITags, Error>(
        ['getTags', systemId],
        () => deployablesApi.getTags({ userId, isImpersonated: !!impersonator, systemId }),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
};

export default useGetTags;
