import {
    Box,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useSystems, useSystemsSettings } from '@walkme-admin-center/libs/state-management-systems';
import { WMButton, WMButtonVariant, WMChip, WMDivider, WMLoader, WMSearchFilter, WMTextField } from '@walkme/wm-ui';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { SystemSettings } from 'wm-accounts-sdk';
import { retrieveSystemIcon } from '@walkme-admin-center/libs/common';
import { Button } from '@walkme/ui-core';
import { useTranslation } from 'apps/home/src/localization/localizationBase';
import {
    StyledBox,
    StyledCardsContainer,
    StyledCardsPlatformItems,
    StyledCardsSystemItems,
    StyledCategoryContainer,
    StyledContent,
    StyledDialog,
    StyledPaper,
    StyledPlatform,
    StyledPlatformItem,
    StyledPlatformWeb,
    StyledSystemContent,
    StyledSystemItem,
} from './new-system-form.styles';

export interface NewSystemFormProps {
    open: boolean;
    onClose: () => void;
    createSystem: (selectedSystem, appName, systemTypeKey) => void;
    loading: boolean;
}

const NewSystemForm = ({ open, onClose, createSystem, loading }: NewSystemFormProps) => {
    const { t } = useTranslation('general');
    const [selectedSystemTypeKey, setSelectedSystemTypeKey] = useState('Web');
    const [selectedAppType, setSelectedAppType] = useState(null);
    const [selectedAppIcon, setSelectedAppIcon] = useState(null);
    const [selectedAppId, setSelectedAppId] = useState(null);
    const [selectedAppTypeDisplayName, setSelectedAppTypeDisplayName] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [query, setQuery] = useState('');
    const [hover, setHover] = useState(false);
    const [clicked, setclicked] = useState(false);
    const { systemsSettingsAppData } = useSystemsSettings();
    const numOfApps = 100;
    const [index, setIndex] = useState(0);
    const [loadingApps, setLoadingApps] = useState(false);

    const initialMobileApps: SystemSettings[] = systemsSettingsAppData.data.filter(
        (systemApp) => systemApp.name === 'android' || systemApp.name === 'ios'
    );
    const mobileApps: SystemSettings[] =
        initialMobileApps.length > 0
            ? initialMobileApps
            : [
                  {
                      displayName: t('systems-tab.new-system-form.andriod'),
                      name: 'android',
                      id: '-1',
                      regexp: '',
                      category: { id: undefined, type: undefined, displayName: undefined },
                      icons: { highRes: null },
                      showInAdminCenter: true,
                  },
                  {
                      displayName: t('systems-tab.new-system-form.ios'),
                      name: 'ios',
                      id: '-2',
                      regexp: '',
                      category: { id: undefined, type: undefined, displayName: undefined },
                      icons: { highRes: null },
                      showInAdminCenter: true,
                  },
              ];

    const unUsedSystems = systemsSettingsAppData.data.filter((searchSystem) => !mobileApps.find((system) => searchSystem.id == system.id));
    const fromIndex = unUsedSystems.findIndex((elem) => elem.name === 'unknown');
    if (fromIndex != -1) {
        const element = unUsedSystems[fromIndex];
        unUsedSystems.splice(fromIndex, 1);
        unUsedSystems.splice(unUsedSystems.length, 0, element);
    }

    const [filteredUnUsedSystems, setFilteredUnusedSystems] = useState(unUsedSystems);

    const [appName, setAppName] = useState('');

    const handleNext = async () => {
        activeStep == 0
            ? setActiveStep((prevActiveStep) => {
                  return prevActiveStep < 1 ? prevActiveStep + 1 : prevActiveStep;
              })
            : createSystem(appName, selectedAppId, selectedSystemTypeKey);
    };

    const handleBack = async () => {
        setActiveStep((prevActiveStep) => {
            setSelectedAppTypeDisplayName(null);
            setSelectedAppType(null);
            setSelectedSystemTypeKey(null);
            setSelectedAppIcon(null);
            setSelectedAppId(null);
            setAppName('');
            return prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep;
        });
    };

    useEffect(() => {
        setLoadingApps(true);
        const systemShowInAdmin = unUsedSystems.filter((s) => s.showInAdminCenter);
        const numberOfItems = numOfApps * (index + 1);
        const showSystemSlicing =
            query === ''
                ? systemShowInAdmin.slice(0, numberOfItems)
                : systemShowInAdmin.filter(
                      (system) => system.displayName.includes(query) || system.displayName.toLowerCase().includes(query)
                  );
        setFilteredUnusedSystems(showSystemSlicing);
        setLoadingApps(false);
    }, [query, systemsSettingsAppData.data, index]);

    const changeSystem = (system) => {
        setSelectedAppType(system.name);
        setAppName(system.displayName);
        setSelectedAppTypeDisplayName(system.displayName);
        setSelectedAppIcon(retrieveSystemIcon(system));
        setSelectedAppId(system.id);
    };

    const changeMobile = (mobile) => {
        setSelectedAppType(mobile.toLowerCase());
        setAppName(mobile);
        setSelectedAppTypeDisplayName(mobile);
    };

    const handleClickAway = () => {
        setHover(false);
        setclicked(false);
    };

    const stepOne = () => {
        return (
            <StyledBox>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        color: '#2F426C',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        marginBottom: '25px',
                    }}>
                    {t('systems-tab.new-system-form.label')}
                </Typography>
                <Box>
                    <StyledContent>
                        <StyledCategoryContainer>
                            <StyledCategoryContainer>
                                <StyledCardsPlatformItems>
                                    {['Web', 'Workstation'].map((platform) => (
                                        <StyledPlatformItem
                                            key={platform}
                                            selected={selectedSystemTypeKey === platform}
                                            onClick={() => setSelectedSystemTypeKey(platform)}>
                                            <StyledPlatform>
                                                <Box display='flex' flexDirection='column' alignItems='center'>
                                                    <div style={{ height: '40px' }}></div>
                                                    <img src={`/assets/icons/${platform}.svg`} alt='image' height='52px' />
                                                    <Typography
                                                        style={{
                                                            fontFamily: 'Proxima Nova',
                                                            color: '#2F426C',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: 400,
                                                            marginTop: '20px',
                                                        }}>
                                                        {t(`systems-tab.systems-table.${platform.toLowerCase()}`)}
                                                    </Typography>
                                                    <div style={{ height: '40px' }}></div>
                                                </Box>
                                            </StyledPlatform>
                                        </StyledPlatformItem>
                                    ))}
                                </StyledCardsPlatformItems>
                            </StyledCategoryContainer>
                        </StyledCategoryContainer>
                    </StyledContent>
                </Box>
            </StyledBox>
        );
    };

    const stepTwo = () => {
        return selectedSystemTypeKey == 'Web' ? stepTwoWeb() : selectedSystemTypeKey == 'Mobile' ? stepTwoMobile() : stepTwoWorkstation;
    };

    const stepTwoWeb = () => {
        const countSystems = filteredUnUsedSystems.length.toString();
        const totalSystems = unUsedSystems.filter((s) => s.showInAdminCenter).length.toString();
        return (
            <Box>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        color: '#2F426C',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        marginBottom: '15px',
                    }}>
                    {t('systems-tab.new-system-form.system-details')}
                </Typography>
                <Box display='flex' flexDirection='row'>
                    <img src={selectedAppIcon || '/assets/icons/custom_type.png'} alt='image' style={{ width: '40px', height: '40px' }} />
                    <Box display='flex' flexDirection='column' flexGrow={1} marginLeft='20px'>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#8D97AE',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}>
                            {selectedAppTypeDisplayName ? selectedAppTypeDisplayName : t('systems-tab.new-system-form.applicaton')}
                        </Typography>
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Box
                                display={'flex'}
                                style={{ maxHeight: '18px' }}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => (clicked ? null : setHover(false))}>
                                <WMTextField
                                    value={appName ? appName : t('systems-tab.new-system-form.system-name')}
                                    InputProps={{
                                        style: {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAppName(event.target.value)}
                                    onClick={() => setclicked(true)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') handleClickAway();
                                    }}
                                    inputProps={{
                                        style: {
                                            fontFamily: 'ProximaNova',
                                            color: '#2F426C',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            border: 0,
                                            padding: 0,
                                        },
                                    }}
                                />
                            </Box>
                        </ClickAwayListener>
                    </Box>
                    <Box>
                        <WMChip label={t(`systems-tab.systems-table.${selectedSystemTypeKey.toLowerCase()}`)} rounded />
                    </Box>
                </Box>

                <Box marginTop='20px'>
                    <Box display='flex' justifyContent='space-between'>
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'ProximaNova',
                                    color: '#2F426C',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                }}>
                                {t('systems-tab.new-system-form.select-application')}
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'ProximaNova',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    marginBottom: '15px',
                                    lineHeight: '16px',
                                    color: '#637191',
                                }}>
                                {t('systems-tab.new-system-form.showing-systems', { countSystems, totalSystems })}
                            </Typography>
                        </div>
                        <WMSearchFilter
                            ds2
                            value={query}
                            onSearch={(value) => setQuery(value)}
                            maxWidth='240px'
                            style={{ marginBottom: '20px', width: '240px' }}
                            onPointerEnterCapture={() => null}
                            onPointerLeaveCapture={() => null}
                        />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} style={{ gap: '30px' }}>
                        <StyledSystemContent>
                            <StyledCardsContainer>
                                <StyledCategoryContainer>
                                    <StyledCardsSystemItems>
                                        {filteredUnUsedSystems.map((system) => (
                                            <StyledSystemItem
                                                key={system.id}
                                                onClick={(item) => changeSystem(system)}
                                                selected={selectedAppType === system.name}>
                                                <StyledPlatformWeb>
                                                    <Box display='flex' flexDirection='row' style={{ marginLeft: '32px' }}>
                                                        <img
                                                            src={retrieveSystemIcon(system)}
                                                            alt='image'
                                                            style={{ width: '20px', height: '20px' }}
                                                        />
                                                        <Typography
                                                            style={{
                                                                fontFamily: 'Proxima Nova',
                                                                color: '#2F426C',
                                                                fontSize: '14px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 400,
                                                                marginLeft: '15px',
                                                            }}>
                                                            {system.displayName}
                                                        </Typography>
                                                    </Box>
                                                </StyledPlatformWeb>
                                            </StyledSystemItem>
                                        ))}
                                    </StyledCardsSystemItems>
                                </StyledCategoryContainer>
                            </StyledCardsContainer>
                        </StyledSystemContent>
                        {query === '' && unUsedSystems.filter((s) => s.showInAdminCenter).length !== filteredUnUsedSystems.length && (
                            <Button variant='text' loading={loadingApps} disabled={loadingApps} onClick={() => setIndex(index + 1)}>
                                {t('buttons.load-more')}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    };

    const stepTwoMobile = () => {
        return (
            <Box>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        color: '#2F426C',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        marginBottom: '25px',
                    }}>
                    {t('systems-tab.new-system-form.system-details')}
                </Typography>
                <Box display='flex' flexDirection='row'>
                    <img
                        src={selectedAppType ? `/assets/icons/${selectedAppType}.png` : `/assets/icons/custom_type.png`}
                        alt='image'
                        style={{ width: '40px', height: '40px' }}
                    />
                    <Box display='flex' flexDirection='column' flexGrow={1} marginLeft='20px'>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#8D97AE',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}>
                            {selectedAppTypeDisplayName ? selectedAppTypeDisplayName : 'System Type'}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#2F426C',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}>
                            {appName ? appName : t('systems-tab.new-system-form.system-name')}
                        </Typography>
                    </Box>
                    <Box>
                        <WMChip label={t(`systems-tab.systems-table.${selectedSystemTypeKey.toLowerCase()}`)} rounded />
                    </Box>
                </Box>
                <Box marginTop='20px'>
                    <Typography
                        style={{
                            fontFamily: 'ProximaNova',
                            color: '#2F426C',
                            fontSize: '14px',
                            fontWeight: 400,
                        }}>
                        {t('systems-tab.new-system-form.system-name')}
                    </Typography>
                    <WMTextField
                        style={{ width: '50%' }}
                        value={appName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setAppName(event.target.value)}></WMTextField>
                </Box>
                <Box marginTop='20px'>
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#2F426C',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}>
                            Select System Type
                        </Typography>
                        <WMSearchFilter
                            ds2
                            value={query}
                            onSearch={(value) => setQuery(value)}
                            style={{ marginBottom: '20px' }}
                            onPointerEnterCapture={() => null}
                            onPointerLeaveCapture={() => null}
                        />
                    </Box>
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#2F426C',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}>
                            Select type
                        </Typography>
                        <StyledSystemContent>
                            <StyledCardsContainer>
                                <StyledCategoryContainer>
                                    <StyledCardsSystemItems>
                                        {mobileApps.map((mobile) => (
                                            <StyledSystemItem
                                                onClick={(item) => changeSystem(mobile)}
                                                selected={selectedAppType == mobile.name}>
                                                <StyledPlatform>
                                                    <Box display='flex' flexDirection='row' style={{ marginLeft: '32px' }}>
                                                        <img
                                                            src={`/assets/icons/${mobile.name}.png`}
                                                            alt='image'
                                                            style={{ width: '20px', height: '20px' }}
                                                        />
                                                        <Typography
                                                            style={{
                                                                fontFamily: 'Proxima Nova',
                                                                color: '#2F426C',
                                                                fontSize: '14px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 400,
                                                                marginLeft: '15px',
                                                            }}>
                                                            {mobile.displayName}
                                                        </Typography>
                                                    </Box>
                                                </StyledPlatform>
                                            </StyledSystemItem>
                                        ))}
                                    </StyledCardsSystemItems>
                                </StyledCategoryContainer>
                            </StyledCardsContainer>
                        </StyledSystemContent>
                    </Box>
                </Box>
            </Box>
        );
    };

    const stepTwoWorkstation = useMemo(() => {
        if (selectedSystemTypeKey !== 'Workstation') {
            return;
        }
        const workstationApp = systemsSettingsAppData.data.find((app) => app.name === 'workstation');
        setSelectedAppId(workstationApp.id);
        return (
            <Box>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        color: '#2F426C',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        marginBottom: '25px',
                    }}>
                    {t('systems-tab.new-system-form.system-details')}
                </Typography>
                <Box display='flex' flexDirection='row'>
                    <img src={`/assets/icons/Workstation.png`} alt='image' style={{ width: '40px', height: '40px' }} />
                    <Box display='flex' flexDirection='column' flexGrow={1} marginLeft='20px'>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#8D97AE',
                                fontSize: '14px',
                                fontWeight: 400,
                            }}>
                            {t('systems-tab.systems-table.workstation')}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'ProximaNova',
                                color: '#2F426C',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}>
                            {appName ? appName : t('systems-tab.new-system-form.system-name')}
                        </Typography>
                    </Box>
                    <Box>
                        <WMChip label={t(`systems-tab.systems-table.${selectedSystemTypeKey.toLowerCase()}`)} rounded />
                    </Box>
                </Box>
                <Box marginTop='20px'>
                    <Typography
                        style={{
                            fontFamily: 'ProximaNova',
                            color: '#2F426C',
                            fontSize: '14px',
                            fontWeight: 400,
                        }}>
                        {t('systems-tab.new-system-form.system-name')}
                    </Typography>
                    <WMTextField
                        style={{ width: '50%' }}
                        value={appName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setAppName(event.target.value)}></WMTextField>
                </Box>
            </Box>
        );
    }, [selectedSystemTypeKey]);

    const stepperAction = [stepOne, stepTwo];

    return (
        <StyledDialog onClose={() => onClose()} open={open} fullWidth={true} maxWidth={'md'}>
            {systemsSettingsAppData.loading ? (
                <WMLoader
                    style={{
                        width: '30%',
                        maxWidth: '300px',
                        margin: 'auto',
                    }}></WMLoader>
            ) : (
                <>
                    <DialogTitle>
                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins',
                                    color: '#2F426C',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                }}>
                                {t('systems-tab.new-system-form.title')}
                            </Typography>
                            <IconButton onClick={onClose}>
                                <img src='assets/icons/close.svg' alt='close' />
                            </IconButton>
                        </Box>
                        <WMDivider style={{ marginTop: '16px' }} />
                    </DialogTitle>
                    <StyledPaper>{stepperAction[activeStep]()}</StyledPaper>
                    <DialogActions style={{ margin: '32px' }}>
                        {activeStep === 1 && (
                            <WMButton
                                style={{ width: '80px', height: '40px', borderRadius: '40px' }}
                                variant={WMButtonVariant.Tertiary}
                                onClick={() => handleBack()}
                                disabled={loading}>
                                {t('buttons.back')}
                            </WMButton>
                        )}
                        <WMButton
                            style={{ width: '80px', height: '40px', borderRadius: '40px' }}
                            variant={WMButtonVariant.Primary}
                            onClick={() => handleNext()}
                            disabled={
                                !selectedSystemTypeKey ||
                                (activeStep == 1 && ((!selectedAppType && selectedSystemTypeKey != 'Workstation') || loading))
                            }
                            loading={loading}>
                            {activeStep == 0 ? t('buttons.next') : t('buttons.create-system')}
                        </WMButton>
                    </DialogActions>
                </>
            )}
        </StyledDialog>
    );
};

export default NewSystemForm;
