import React, { useEffect, useState } from 'react';
import { IdpSystem, IdpSystems } from '@walkme-admin-center/libs/state-management-sso-configuration';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { SearchContainer, StyledAccordion, StyledAccordionSummary, StyledTextField } from './systems-list-style';
import { expandIcon, getSystemEnvsWithOutAjax } from '../../../common/helpers';
import System from './system/system';
import EnvsList from './system/envs-list/envs-list';
import { Environments } from 'wm-accounts-sdk';
import { useTranslation } from 'apps/home/src/localization';

export interface SystemListProps {
    systems: IdpSystems;
    providerExpand: string;
    isImpersonator: boolean;
    systemsEnvs: Map<string, Environments>;
}

export const SystemList = ({ systems, providerExpand, isImpersonator, systemsEnvs }: SystemListProps) => {
    const [expanded, setExpanded] = useState(null);
    const [allSystemEnvs, setAllSystemEnvs] = useState<Environments>([]);
    const [search, setSearch] = useState('');

    const { t } = useTranslation('general');

    useEffect(() => {
        setExpanded(null);
        setSearch('');
    }, [providerExpand]);

    const searchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearch(event.target.value);
    };

    const handleChange =
        (system: IdpSystem) =>
        async (event: React.ChangeEvent, isExpanded: boolean): Promise<void> => {
            setAllSystemEnvs([]);
            if (system.envs && system.envs.length) {
                setExpanded(isExpanded ? system.guid : null);
                const envs = getSystemEnvsWithOutAjax(systemsEnvs.get(system.guid), isImpersonator);
                setAllSystemEnvs(envs || []);
            }
        };

    const filterSystems = systems
        .filter((system) => {
            return system.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        })
        .sort((firstElement: IdpSystem, secondElement: IdpSystem) => {
            return firstElement.displayName.localeCompare(secondElement.displayName);
        });

    return (
        <>
            <SearchContainer>
                <StyledTextField
                    className={'alias-text'}
                    variant='outlined'
                    placeholder={t('idp-tab.table.system-list.search')}
                    onChange={searchChange}
                    value={search}
                    inputProps={{ style: { height: '6px' } }}
                />
            </SearchContainer>
            {filterSystems.map((system) => {
                return (
                    <StyledAccordion
                        classes={{ root: 'root', expanded: 'expanded', rounded: 'rounded', disabled: 'disabled' }}
                        key={system.guid}
                        expanded={expanded === system.guid}
                        onChange={handleChange(system)}>
                        <StyledAccordionSummary classes={{ root: 'root', content: 'content' }} expandIcon={expandIcon}>
                            <System system={system} />
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            <EnvsList allSystemEnvs={allSystemEnvs} envs={system.envs} />
                        </AccordionDetails>
                    </StyledAccordion>
                );
            })}
        </>
    );
};

export default SystemList;
