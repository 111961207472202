import { retriveVerifyAppVideo } from '@walkme-admin-center/libs/common';
import React from 'react';
import { StepContainer, TitleContainer, TitleText, SubtitleText, VideoStepNumber, SmallBoldText } from './slack-steps-styles';
import VideoContainer from './video-container';

const VerifyApp = () => {
    return (
        <StepContainer>
            <TitleContainer>
                <TitleText>
                    <VideoStepNumber>7.</VideoStepNumber>Verify your app
                </TitleText>
                <SubtitleText>
                    Click <SmallBoldText>App Manifest</SmallBoldText> on the left navigation bar. Then click{' '}
                    <SmallBoldText>Click here to verify</SmallBoldText> and wait for confirmation message
                </SubtitleText>
            </TitleContainer>
            <VideoContainer video={retriveVerifyAppVideo()} />
        </StepContainer>
    );
};
export default VerifyApp;
