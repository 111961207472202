import React, { useRef, useState } from 'react';
import {
    CssExpanded,
    ExpandButtonContainer,
    Image,
    ImageContainer,
    StepContainer,
    SubtitleText,
    TitleContainer,
    TitleText,
    VideoStepNumber,
} from './slack-steps-styles';
import { retriveLoginImage } from '@walkme-admin-center/libs/common/lib/icons';
import MinMaxIcon from 'packages/libs/common/assets/icons/data-integrations/min-max-size-icon.svg';
import styled from 'styled-components';
import { CssModal } from 'packages/pages/home/data-integrations/src/lib/data-integrations-page-style';
import { CloseIcon } from 'packages/pages/home/data-integrations/src/lib/common/consts';

const LinkStyle = styled.a`
    &:visited {
        color: #385feb;
    }
`;

const LoginStep = () => {
    const imageRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    const expandVideo = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <CssModal
                open={expanded}
                onClose={expandVideo}
                BackdropProps={{ onClick: () => {} }}
                children={
                    <CssExpanded>
                        <ImageContainer>
                            <Image className='expanded' src={retriveLoginImage()} ref={imageRef} />
                            <ExpandButtonContainer className='close' onClick={expandVideo}>
                                <img src={CloseIcon} />
                            </ExpandButtonContainer>
                        </ImageContainer>
                    </CssExpanded>
                }
            />
            <StepContainer>
                <TitleContainer>
                    <TitleText>
                        <VideoStepNumber>1.</VideoStepNumber>Navigate to
                        <LinkStyle href='https://api.slack.com' target='_blank' rel='noreferrer'>
                            api.slack.com
                        </LinkStyle>
                    </TitleText>
                    <SubtitleText className={'flex-row'}>and login to your admin user account</SubtitleText>
                </TitleContainer>
                <ImageContainer>
                    <Image src={retriveLoginImage()} ref={imageRef} />
                    <ExpandButtonContainer className='expand' onClick={expandVideo}>
                        <img src={MinMaxIcon} />
                    </ExpandButtonContainer>
                </ImageContainer>
            </StepContainer>
        </>
    );
};

export default LoginStep;
