import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconClose = (props: SvgIconProps) => {
    return (
        <SvgIcon width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M15.5 8.5L8.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M8.5 8.5L15.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        </SvgIcon>
    );
};

export { IconClose };
