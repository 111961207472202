import React, { useEffect } from 'react';
import { Field } from 'react-final-form';

const FieldAdapter = ({ onChange, input, children }) => {
    useEffect(() => {
        onChange(input.value);
    }, [onChange, input, input.value]);

    return children;
};

export default ({ name, onChange, ...rest }) => (
    <Field
        name={name}
        subscription={{ value: true, active: true }}
        render={(props) => (
            <FieldAdapter {...props} onChange={onChange}>
                <Field name={name} {...rest} />
            </FieldAdapter>
        )}
    />
);
