import { AppData } from '@walkme-admin-center/libs/types';
import { useSelector } from 'react-redux';
import { WebSystemsState } from '../redux/web-systems.slice';

export const useLinkSystemToExtension = () => {
    const linkSystemToExtensionAppData: AppData<number | undefined> = useSelector(
        (state: { webSystemsState: WebSystemsState }) => state.webSystemsState.linkSystemToExtension
    );

    return { linkSystemToExtensionAppData };
};

export default useLinkSystemToExtension;
