import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';

interface SearchProps {
    data?: unknown;
    handleChangeFunc?: (e: unknown) => void;
    value?: string;
    handleKeyPressFunc?: (e: unknown) => void;
    onKeyDownCapture?: (e) => Promise<void>;
    placeholder?: string;
    width?: string;
    className?: string;
    flipIcon?: boolean;
}

const StyledInput = styled(InputBase)`
    padding: 2px 8px 8px 0px;
`;

const StyledSearchBox = styled.div`
    border: 1px solid #c6c6c6;
    position: relative;
    border-radius: 2px;
    margin-right: 20px;
    height: 34px;
    text-indent: 41px;

    &.flipped {
        text-indent: 10px;
    }

    .searchIcon {
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }
`;

export const Search = ({
    value,
    handleChangeFunc,
    handleKeyPressFunc,
    onKeyDownCapture,
    placeholder,
    width,
    className,
    flipIcon,
}: SearchProps) => {
    return (
        <StyledSearchBox className={`${className} ${flipIcon && 'flipped'}`}>
            <div className={`searchIcon ${flipIcon ? 'right' : 'left'}`}>
                <SearchIcon />
            </div>
            <StyledInput
                onKeyDownCapture={onKeyDownCapture}
                value={value}
                onChange={handleChangeFunc}
                onKeyPress={handleKeyPressFunc}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                style={{ width }}
            />
        </StyledSearchBox>
    );
};

export default Search;
