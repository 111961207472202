import { ProviderConfig } from './provider-config';
import { Fields } from './field';

export interface Provider {
    id: number;
    config: ProviderConfig;
    fields?: Fields;
    systems?: IdpSystems;
    name: string;
    providerGroupId?: number;
    userIdentifier?: string;
    hasCertificate?: boolean;
}

export interface SubmitProvider {
    candidateId: number;
    config?: ProviderConfig;
    name?: string;
    fields: Fields;
    userIdentifier: string;
}

export interface IdpSystemFormValue {
    guid: string;
    active?: boolean;
    displayName?: string;
    id?: number;
    wmEnv?: number;
    enforceSso?: boolean;
    typeKey?: string;
    providerId?: number;
}

export interface ProviderFormValues {
    config: {
        type: string;
        clientSecret?: string;
        domain?: string;
        clientId?: string;
        directoryId?: string;
        domainHint?: string;
        scope?: string;
        discoveryEndpoint?: string;
        cspDomains?: string;
        providerName?: string;
        encryptAssertion?: boolean;
        signAuthRequest?: boolean;
        pullGroups?: boolean;
        enforceSSOIframeFlow?: boolean;
        useIdTokenForProperties?: boolean;
        certificateId?: number;
    };
    clientId?: string;
    name: string;
    fields: Fields;
    systems: IdpSystemsFormValue;
    userIdentifier: string;
    certificateId?: number;
}

export interface AuthUrlResponse {
    candidateId?: number;
    authUrl?: string;
    error?: string;
}

export interface IdpEnv {
    id: number;
    enforceSso?: boolean;
    providerName?: string;
    systemGuid?: string;
}

export interface IdpSystem {
    guid: string;
    envs: IdpEnvs;
    id?: number;
    displayName?: string;
    typeKey?: string;
}

export interface OneId {
    oneIdProvider: number;
}

export interface AccountConfig {
    providerId?: number;
    enforceSso?: boolean;
}

export interface Certificate {
    certificateId: number;
}

export declare type Providers = Provider[];

export declare type IdpSystems = IdpSystem[];

export declare type IdpSystemsFormValue = IdpSystemFormValue[];

export declare type IdpEnvs = IdpEnv[];
