import { ThunkAction } from 'redux-thunk';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Actions, AuditLogSdk } from 'wm-accounts-sdk';
import { AppData } from '@walkme-admin-center/libs/types';
import { AuditLogQueryParams } from '../hooks/use-auditlogs';
import { AuditLog, GetAuditLogsDto } from 'wm-accounts-sdk/dist/lib/audit-log-sdk/types/audit-logs-types';

export type AuditLogStateType = ReturnType<typeof auditLogsSlice.reducer>;
type AppThunk = ThunkAction<void, AuditLogStateType, unknown, Action<string>>;

export interface AuditLogsState {
    auditLogs: AppData<AuditLog[]>;
    actions: AppData<Actions>;
}

export const initialAuditLogsState: AuditLogsState = {
    auditLogs: {
        loading: false,
        error: null,
        data: [],
    },
    actions: {
        loading: false,
        error: null,
        data: [],
    },
};

const auditLogsSlice = createSlice({
    name: 'auditLogsSlice',
    initialState: initialAuditLogsState,
    reducers: {
        getAuditLogsStart(state: AuditLogsState) {
            state.auditLogs.data = [];
            state.auditLogs.error = null;
            state.auditLogs.loading = true;
            return state;
        },
        getAuditLogsSuccess(state: AuditLogsState, action: PayloadAction<AuditLog[]>) {
            state.auditLogs.data = action.payload;
            state.auditLogs.loading = false;
            state.auditLogs.error = null;
            return state;
        },
        getAuditLogsFailed(state: AuditLogsState, action: PayloadAction<string>) {
            state.auditLogs.loading = false;
            state.auditLogs.error = action.payload;
            return state;
        },
    },
});

export { auditLogsSlice };
const { getAuditLogsStart, getAuditLogsSuccess, getAuditLogsFailed } = auditLogsSlice.actions;

export const getAuditLogs = (params: GetAuditLogsDto): AppThunk => async (dispatch) => {
    try {
        dispatch(getAuditLogsStart());
        const auditLogsResponse = await AuditLogSdk.getInstance().getAccountLogs({ to: params.to, from: params.from });

        const auditLogs: AuditLog[] = auditLogsResponse.events;

        dispatch(getAuditLogsSuccess(auditLogs));
    } catch (err) {
        dispatch(getAuditLogsFailed(err.message));
        return;
    }
};
