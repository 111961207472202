import Base from './base';
import { IdpSystemsFormValue, Provider, ProviderFormValues } from '@walkme-admin-center/libs/state-management-sso-configuration';
import get from 'lodash/get';
import { oAuth2OIDCSettingsSchema } from '../validation';
import { sharedFields } from '../shared-fields';
const OIDCIDPPdf = require('../../../common/pdfFiles/idp_oidc_instructions.pdf');

export class Oidc extends Base {
    constructor() {
        super('OIDC', oAuth2OIDCSettingsSchema, OIDCIDPPdf);
    }

    getFormFields(): Array<any> {
        return [
            sharedFields.idpName,
            sharedFields.clientId,
            sharedFields.clientSecret,
            sharedFields.idpDiscoveryUrl,
            sharedFields.scope,
            sharedFields.cspDomains,
            sharedFields.providerName,
            sharedFields.useIdTokenForProperties,
            sharedFields.enforceSSOIframeFlow,
        ];
    }

    initForm(provider: Provider, assignSystemsForm: IdpSystemsFormValue): ProviderFormValues {
        return {
            config: {
                type: this.getProtocol(),
                clientId: get(provider, 'config.clientId', ''),
                clientSecret: get(provider, 'config.clientSecret', ''),
                discoveryEndpoint: get(provider, 'config.discoveryEndpoint', ''),
                scope: get(provider, 'config.scope', 'openid,profile,email'),
                cspDomains: get(provider, 'config.cspDomains', ''),
                providerName: get(provider, 'config.providerName', ''),
                useIdTokenForProperties: get(provider, 'config.useIdTokenForProperties', false),
                enforceSSOIframeFlow: get(provider, 'config.enforceSSOIframeFlow', false),
            },
            name: get(provider, 'name', ''),
            fields: get(provider, 'fields', []),
            userIdentifier: get(provider, 'userIdentifier', ''),
            systems: this.prepareSystems(get(provider, 'id', null), get(provider, 'systems', []), assignSystemsForm),
        };
    }
}
