import React, { useCallback, useEffect, useMemo } from 'react';
import { Checkbox, ToasterVariant, Tooltip } from '@walkme/ui-core';
import {
    StyledActiveStatusContainer,
    StyledActiveStatusWrapper,
    StyledExtensionMethodContainer,
    StyledSelfDomainsMatcherControlContainer,
    ControlHeaderWithTooltipContainer,
} from './extension-method.styles';
import { useDispatch, useSelector } from 'react-redux';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import {
    ControlHeader,
    ControlSubHeader,
    StyledTooltipText,
} from '../../../../../../../extension-pages/src/lib/new-design/shared-styled-components';
import { getTooltipIcon } from '@walkme-admin-center/pages/home/extension-pages';
import { WMSwitch } from '@walkme/wm-ui';
import { StyledLoadFromExtensionWarning } from '../select-deployment.styles';
import { useTranslation } from '../../../../../../../../../../apps/home/src/localization/localizationBase';
import URLMatchPatterns from '../utils/url-match-patterns';
import { ExtensionStatus } from '@walkme-admin-center/libs/exposure-api';
import useSystemExtensionData from '../../../../../../../../../libs/state-management-systems/src/lib/hooks/use-system-extension-data';
import cloneDeep from 'lodash/cloneDeep';
import Regex from '../../../../../../../extension-pages/src/lib/new-design/regex';
import { EnvironmentSelectorControl } from '../utils/environment-selector-control';
import { getMatchPatternValidationResult, ValidationErrorType } from 'packages/pages/util/validation';

export const ExtensionMethod = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('general');
    const selectedSystemApp = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedSystemApp.data
    );

    const createdSystem = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.createdSystem.data);
    const { systemExtensionData } = useSystemExtensionData(createdSystem?.id);
    const extensionAsDefaultSelected = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.extensionAsDefaultSelected
    );
    const environmentMatchers = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.environmentMatchers
    );
    const stepErrors = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.stepErrors);

    const selectedEnvironment = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedEnvironment
    );
    const selectedEnvMatcher = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedEnvironmentMatcher
    );
    const appUrlsData = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.appUrls.data);
    const isCustomMode = useSelector((state: { createSystemState: CreateSystemState }) => state.createSystemState.isCustomMode.data);

    const canLinkSystemToExtension =
        !systemExtensionData?.isSystemLinked && systemExtensionData?.extensionStatus === ExtensionStatus.Exists;

    const setExtensionAsDefaultSelected = (isDefault: boolean) => {
        dispatch(createSystemSlice.actions.setExtensionAsDefaultSelected(isDefault));
    };

    const updateExtensionUrlsAreValid = (areValid: boolean) => {
        dispatch(createSystemSlice.actions.setExtensionSelfDomainsAreValid(areValid));
    };

    const handleSelfDomainsMatcherControl = useCallback(
        (newUrls: string[]) => {
            let clonedEnvMatchers = cloneDeep(environmentMatchers);
            clonedEnvMatchers[selectedEnvironment?.value][0].selfDomains = newUrls;
            dispatch(createSystemSlice.actions.setEnvironmentMatchers(clonedEnvMatchers));
            dispatch(createSystemSlice.actions.setSelectedEnvironmentMatcher(clonedEnvMatchers[selectedEnvironment?.value][0]));
        },
        [selectedEnvironment, environmentMatchers]
    );

    const handleSelfRegexesMatcherControl = useCallback(
        (newValue: string) => {
            if (newValue !== selectedEnvMatcher.selfRegex) {
                let clonedEnvMatchers = cloneDeep(environmentMatchers);
                clonedEnvMatchers[selectedEnvironment?.value][0].selfRegex = newValue;
                dispatch(createSystemSlice.actions.setEnvironmentMatchers(clonedEnvMatchers));
                dispatch(createSystemSlice.actions.setSelectedEnvironmentMatcher(clonedEnvMatchers[selectedEnvironment?.value][0]));
            }
        },
        [selectedEnvironment, environmentMatchers]
    );

    const handleToggleMatcherActivity = () => {
        let clonedEnvMatchers = cloneDeep(environmentMatchers);
        const currentMatcherIsActive = clonedEnvMatchers[selectedEnvironment?.value][0].isActive;
        clonedEnvMatchers[selectedEnvironment?.value][0].isActive = !currentMatcherIsActive;
        dispatch(createSystemSlice.actions.setEnvironmentMatchers(clonedEnvMatchers));
        dispatch(createSystemSlice.actions.setSelectedEnvironmentMatcher(clonedEnvMatchers[selectedEnvironment?.value][0]));
    };

    const handleSelfRegexIsNotValid = useCallback(
        (isValid: boolean) => {
            // NOTICE: This is a temporary solution until we have a better way to handle errors
            // const currentStepErrors= cloneDeep(stepErrors);
            // currentStepErrors[CreateSystemSteps.CONFIGURE_SYSTEM][selectedEnvMatcher?._id]=isValid;
            // dispatch(createSystemSlice.actions.setStepErrors(currentStepErrors));
        },
        [selectedEnvMatcher]
    );

    const activeStatusControl = useMemo(
        () => (
            <StyledActiveStatusContainer isActive={selectedEnvMatcher?.isActive} key={selectedEnvironment?.value}>
                <StyledActiveStatusWrapper>
                    <ControlHeaderWithTooltipContainer>
                        <ControlHeader>{t('systems-tab.all-systems-page.extension-page.basic-title')}</ControlHeader>
                        <Tooltip
                            title={
                                <StyledTooltipText>
                                    {t('systems-tab.new-system-form.deployment.load-walkme-from-extension.main-title')}
                                </StyledTooltipText>
                            }
                            arrow>
                            {getTooltipIcon()}
                        </Tooltip>
                    </ControlHeaderWithTooltipContainer>
                    <WMSwitch disabled={false} checked={selectedEnvMatcher?.isActive} onChange={handleToggleMatcherActivity} />
                </StyledActiveStatusWrapper>
                <ControlSubHeader>
                    <div>{t('systems-tab.new-system-form.deployment.load-walkme-from-extension.sub-title')}</div>
                </ControlSubHeader>
                {!selectedEnvMatcher?.isActive ? (
                    <StyledLoadFromExtensionWarning
                        variant={ToasterVariant.Warning}
                        message={t('systems-tab.new-system-form.deployment.load-walkme-from-extension.warning')}
                        width='auto'
                        icon={true}
                    />
                ) : null}
            </StyledActiveStatusContainer>
        ),
        [selectedEnvironment, selectedEnvMatcher]
    );

    const selfRegexesMatcherControl = (
        <StyledSelfDomainsMatcherControlContainer>
            <ControlHeader>{t('systems-tab.all-systems-page.extension-page.regex-title')}</ControlHeader>
            <Regex
                setIsValid={handleSelfRegexIsNotValid}
                value={selectedEnvMatcher?.selfRegex || ''}
                setValue={handleSelfRegexesMatcherControl}
            />
        </StyledSelfDomainsMatcherControlContainer>
    );

    const getCustomUrl = () => {
        const validationResult = getMatchPatternValidationResult(appUrlsData[0].url, t);
        if (!validationResult.isValid && validationResult.errorType === ValidationErrorType.EMPTY_PATH) {
            return [appUrlsData[0].url + '/*'];
        }
        return [appUrlsData[0].url];
    };

    const getUrls = () => {
        if (isCustomMode && appUrlsData[0]?.url?.length > 0 && selectedEnvironment?.label === 'Production') {
            return getCustomUrl();
        }
        return selectedEnvMatcher?.selfDomains || [];
    };

    const selfDomainsMatcherControl = (
        <StyledSelfDomainsMatcherControlContainer>
            <ControlHeader>{t('systems-tab.all-systems-page.extension-page.env-urls')}</ControlHeader>
            <URLMatchPatterns
                urls={getUrls()}
                setAreUrlsValid={updateExtensionUrlsAreValid}
                setUrls={handleSelfDomainsMatcherControl}
                shouldShowNewLine={false}
                onAddUrlClicked={() => null}
                onAddNewUrlClosed={() => null}
                onAddNewUrlSaved={() => null}
            />
        </StyledSelfDomainsMatcherControlContainer>
    );

    const setSelectedEnvironmentMatcher = () => {
        const matcher = environmentMatchers?.[selectedEnvironment?.value]?.[0];
        dispatch(createSystemSlice.actions.setSelectedEnvironmentMatcher(matcher));
    };

    useEffect(setSelectedEnvironmentMatcher, [selectedEnvironment, environmentMatchers]);

    return (
        <StyledExtensionMethodContainer>
            <div className='extension-as-default'>
                <Checkbox
                    checked={extensionAsDefaultSelected}
                    onChange={() => setExtensionAsDefaultSelected(!extensionAsDefaultSelected)}
                />
                <div className='description'>{t('systems-tab.new-system-form.deployment.extension-as-default')}</div>
            </div>
            <EnvironmentSelectorControl />
            {activeStatusControl}
            {selectedEnvMatcher?.isActive && (selectedEnvMatcher?.isRegex ? selfRegexesMatcherControl : selfDomainsMatcherControl)}
        </StyledExtensionMethodContainer>
    );
};
