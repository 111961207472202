import styled from 'styled-components';

export const StyledAppCard = styled.div<{ selected: boolean }>`
    display: flex;
    padding: 20px;
    border: 1px solid;
    border-color: ${(props) => (props.selected ? '#385feb' : 'rgba(56, 95, 235, 0.2)')};
    border-radius: 8px;
    background-color: ${(props) => (props.selected ? '#f7f9fc' : 'white')};
    color: #2f426c;
    cursor: pointer;
    align-items: center;
    max-height: 42px;

    .displayName {
        max-width: 60%;

        span[class^='Tooltip'] {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
        }
    }

    &:hover {
        box-shadow: 0px 4px 12px 0px rgba(47, 61, 83, 0.11);
        border: 1px solid var(--color-semantic-input-dropdown-primary-stroke-primary-hover, #92a7f4);
    }
`;

export const StyledAppIconContainer = styled.div`
    width: 40px;
    height: 40px;
    background-color: #e9f1f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 12px;
`;
