import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationsState, getAllAvailableScopes } from '../redux/applications.slice';

export const useScopes = () => {
    const actionsAppData = useSelector((state: { applicationsSlice: ApplicationsState }) => state.applicationsSlice.availableScopes);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllAvailableScopes());
    }, [dispatch]);

    return {
        actionsAppData,
    };
};

export default useScopes;
