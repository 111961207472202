import React, { useRef, useState } from 'react';
import {
    ExpandButtonContainer,
    PlayButtonContainer,
    Video,
    CssVideoContainer,
    ExpandedVideo,
    VideoOverlay,
    CssExpanded,
} from './slack-steps-styles';
import MinMaxIcon from 'packages/libs/common/assets/icons/data-integrations/min-max-size-icon.svg';
import PlayButton from 'packages/libs/common/assets/icons/data-integrations/integrations-icons/play-button.svg';
import { CssModal } from 'packages/pages/home/data-integrations/src/lib/data-integrations-page-style';
import { CloseIcon } from 'packages/pages/home/data-integrations/src/lib/common/consts';

const VideoContainer = ({ video }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlay = () => {
        if (isPlaying) {
            videoRef.current?.pause();
        } else {
            videoRef.current?.play();
        }
        setIsPlaying(!isPlaying);
    };

    const expandVideo = () => {
        setIsPlaying(false);
        setExpanded(!expanded);
    };

    return (
        <>
            <CssModal
                open={expanded}
                BackdropProps={{ onClick: () => {} }}
                children={
                    <CssExpanded>
                        <CssVideoContainer>
                            <ExpandedVideo onClick={handlePlay} ref={videoRef} onEnded={() => setIsPlaying(false)}>
                                <source src={video} type='video/mp4' />
                            </ExpandedVideo>
                            {!isPlaying && (
                                <>
                                    <VideoOverlay isPlaying={isPlaying} onClick={handlePlay} />
                                    <PlayButtonContainer className='exapnded' onClick={handlePlay}>
                                        <img src={PlayButton} />
                                    </PlayButtonContainer>
                                </>
                            )}
                            <ExpandButtonContainer className='close' onClick={expandVideo}>
                                <img src={CloseIcon} />
                            </ExpandButtonContainer>
                        </CssVideoContainer>
                    </CssExpanded>
                }
            />
            <CssVideoContainer>
                {!expanded && (
                    <>
                        <Video ref={videoRef} onEnded={() => setIsPlaying(false)} onClick={handlePlay}>
                            <source src={video} type='video/mp4' />
                        </Video>
                        {!isPlaying && (
                            <>
                                <VideoOverlay isPlaying={isPlaying} onClick={handlePlay} />
                                <PlayButtonContainer onClick={handlePlay}>
                                    <img src={PlayButton} />
                                </PlayButtonContainer>
                            </>
                        )}
                    </>
                )}
                <ExpandButtonContainer className='expand' onClick={expandVideo}>
                    <img src={MinMaxIcon} />
                </ExpandButtonContainer>
            </CssVideoContainer>
        </>
    );
};

export default VideoContainer;
