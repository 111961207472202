import React from 'react';
import { StyledEmptyStateDescription, StyledEmptyStateSearch } from './empty-state-search.styles';
import { SearchingIcon } from '../../../assets/icons/searching';
import { createSystemSlice, CreateSystemState } from '@walkme-admin-center/libs/state-management-systems';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessDomain, useBusinessDomains } from '@walkme-admin-center/libs/state-management-users';
import { useTranslation } from 'apps/home/src/localization/localizationBase';

export const EmptyStateSearch = () => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const allBusinessDomainsData = useBusinessDomains();
    const allBusinessDomains = allBusinessDomainsData?.businessDomains as BusinessDomain[];
    const selectedAppFilters = useSelector(
        (state: { createSystemState: CreateSystemState }) => state.createSystemState.selectedAppFilters.data
    );
    const handleClearFilters = () => {
        const updatedSelectedAppFilters = { ...selectedAppFilters };
        const updatedCategoryFilter = { ...selectedAppFilters?.category };
        delete updatedCategoryFilter.type;

        if (Object.keys(updatedCategoryFilter).length === 0) {
            delete updatedSelectedAppFilters.category;
        } else {
            updatedSelectedAppFilters.category = updatedCategoryFilter;
        }

        dispatch(createSystemSlice.actions.setAppCategoryFilter(updatedSelectedAppFilters));
        dispatch(createSystemSlice.actions.setSelectedBusinessDomains(allBusinessDomains));
        dispatch(createSystemSlice.actions.setSearchFilterText(''));
        dispatch(createSystemSlice.actions.setFilterSelectionsAreVisible(false));
    };

    const clearSearchText = (
        <span onClick={handleClearFilters} className='clearFilterText'>
            {t('common.clear-search')}
        </span>
    );

    return (
        <StyledEmptyStateSearch>
            <SearchingIcon />
            <StyledEmptyStateDescription>
                <div className='emptyStateTitle'>{t('systems-tab.new-system-form.empty-search-title')}</div>
                <div className='emptyStateDescription'>
                    {t('systems-tab.new-system-form.empty-search-text-1')}
                    {clearSearchText}
                    {t('systems-tab.new-system-form.empty-search-text-2')}
                </div>
            </StyledEmptyStateDescription>
        </StyledEmptyStateSearch>
    );
};
