import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystem, SystemsState } from '../redux/systems.slice';

export const useSystem = (id: number) => {
    const currentSystemAppData = useSelector((state: { systemsState: SystemsState }) => state.systemsState.currentSystem);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(getSystem(id));
    }, [dispatch, id]);

    return {
        currentSystemAppData,
    };
};

export default useSystem;
