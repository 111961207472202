import styled from 'styled-components';
import { Toaster } from '@walkme/ui-core';
import { WMLoader } from '@walkme/wm-ui';

export const StyledDataCollectionWrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 9.25fr 0.75fr;
`;
export const StyledDataCollectionContainer = styled.div`
    height: 100%;
    padding: 0 24px;
    background-color: white;
    overflow-y: auto;

    .dataCollectionToaster {
        width: unset !important;
    }
`;

export const StyledDataCollectionTitle = styled.div`
    color: var(--Color-Semantic-Text-text-primary, #2f426c);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 32px 0 8px 0;

    .main-title {
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 24px;
        font-weight: 600;
    }
`;

export const StyledDataCollectionBody = styled.div`
    margin-bottom: 20px;
`;

export const StyledToasterContainer = styled(Toaster)`
    margin-bottom: 20px;
`;

export const StyledDataCollectionToasterMessage = styled.div`
    a {
        color: var(--Blue-Primary-Primary-Main, #385feb) !important;
        text-decoration: none;
    }
`;

export const StyledSaveAndPublish = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 32px;

    .save-and-publish {
        margin-left: 8px;
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    background: white;
    border-radius: 12px;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const StyledLoader = styled(WMLoader)`
    width: 30%;
`;
